@import url("https://fonts.googleapis.com/css?family=Poppins");
@import url("https://fonts.googleapis.com/css?family=Montserrat");
body.page-edgehome2k18 {
  font-family: "Poppins", sans-serif;
  padding: 0;
}

.main {
  position: relative;
  max-width: 1600px;
  margin: auto;
}

*,
:after,
:before {
  box-sizing: border-box;
}

.SearchboxBackdrop {
  position: fixed;
  opacity: 0.35;
  background-color: #000;
  z-index: 19;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: none;
}

#hero-banner {
  padding: 145px 0 50px;
  z-index: auto;
  background-color: rgba(2, 2, 2, 0.35);
  margin-top: -72px;
  height: auto;
  background-position: center 0;
  background-size: auto;
  box-shadow: inset 0 -130px 100px -100px #000;
  position: relative;
  background-repeat: no-repeat;
  background-image: url("/home/4.jpg");
  &.event-theme{
    // background: use background as inline style
    background-size:cover;
    @media (min-width: 1500px) {
      padding: 145px 0 130px;
    }
  }
}

#hero-banner:not(.with-placeholder) .lazyload-container {
  opacity: 0;
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

#hero-banner .lazyload-container .lazyload.critical-ready,
.become-a-host-btn.become-a-host-btn__new > .text-container > a.hovered span {
  transition: none;
}

#hero-banner .lazyload-container img,
#hero-banner .lazyload-container video {
  width: 100%;
}

#hero-banner .lazyload-container:after {
  content: " ";
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  box-shadow: inset 0 -130px 100px -100px #000;
}

#hero-banner .hero-banner-content.critical-ready {
  opacity: 1;
  transition: none;
  animation-duration: 1s;
  animation-fill-mode: none;
}

#hero-banner .hero-banner-content {
  /* padding-bottom: 200px; */
  min-height: 400px;
}

#hero-banner #hero-banner-header2,
#hero-banner #hero-banner-header1 {
  position: relative;
  font-family: "Montserrat", sans-serif;
}

#hero-banner #hero-banner-header1 {
  margin: 5px 0;
  color: #fff;
  text-align: center;
}

#hero-banner.h1,
#hero-banner h1 {
  font-weight: 200;
  line-height: 1.2;
  letter-spacing: 0.03em;
}

#hero-banner h2 {
  font-size: 19px;
  text-align: center;
  font-weight: 500;
  margin: 10px 0;
  color: #fff;
  font-weight: 100;
}

/*************************************SEARCH **********************************************/

#hero-banner #searchbox-root {
  min-height: 133px;
  margin-top: 50px;
}

#hero-banner .hero-banner-container .SearchBoxContainer {
  margin-top: 15px;
}

#hero-banner .SearchBoxContainer {
  position: relative;
  width: 1045px;
  margin: 0 auto;
}

#hero-banner .SearchBoxContainer--backdrop {
  z-index: 20;
}

#hero-banner .SearchBoxTabs {
  display: flex;
}

#hero-banner .SearchBoxTabs__tab {
  font-size: 1.5rem;
  color: #fff;
  background-color: rgba(0, 0, 0, 0.6);
  transition: color 0.2s ease-in-out, background-color 0.2s ease-in-out;
  padding: 10px 25px;
  margin-right: 2px;
  cursor: pointer;
  -webkit-user-select: none;
  user-select: none;
  display: block;
  float: left;
  letter-spacing: 1px;
}

#hero-banner .SearchBoxTabs__tab.isActive {
  color: #000;
  background-color: hsla(0, 0%, 100%, 0.8);
  cursor: default;
}

#hero-banner .SearchBoxContainer--Flights .FlightsFrameContainer {
  -webkit-animation: SearchBoxlightsTab 0.3s;
  animation: SearchBoxlightsTab 0.3s;
  background-color: hsla(0, 0%, 100%, 0.8);
  position: relative;
  max-height: 140px;
  height: 140px;
  width: 100%;
  padding: 0 7px;
  z-index: 1;
}

#hero-banner .SearchBoxContainer--Flights .FlightsFrameContainer--widget {
  height: 140px !important;
  width: 100% !important;
}

#hero-banner .Searchbox {
  padding: 13px 0;
  background-color: hsla(0, 0%, 100%, 0.8);
  width: 100%;
  max-height: 92px;
}

#hero-banner .search-redirect {
  height: 68px;
  display: none;
  position: relative;
}

#hero-banner .search-redirect img {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

#hero-banner .Searchbox__wrapper {
  position: relative;
}

#hero-banner .Searchbox__wrapper label {
  font-weight: normal;
}

#hero-banner .IconBox {
  display: inline-block;
  vertical-align: top;
  position: relative;
  margin: 0 5px 0 0;
  background-color: #fff;
  cursor: pointer;
  padding: 5px 5px;
  box-shadow: 0 4px 10px 0 rgba(0, 0, 0, 0.07);
  outline: none;
}

#hero-banner .SearchBoxContainer--horizontal .IconBox--autocomplete {
  width: 300px;
  height: 66px;
  display: inline-block;
  vertical-align: top;
  position: relative;
  margin: 0 5px 0 0;
  background-color: #fff;
  cursor: pointer;
  padding: 10px 5px;
  box-shadow: 0 4px 10px 0 rgba(0, 0, 0, 0.07);
}

#hero-banner .IconBox__icon {
  padding: 13px;
  margin-bottom: 0;
  display: inline-block;
  vertical-align: middle;
  font-family: icons;
  line-height: 1;
  font-weight: 400;
  font-style: normal;
  speak: none;
  text-decoration: inherit;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  font-size: 20px;
}

#hero-banner .ficon,
button,
select {
  text-transform: none;
}

#hero-banner .Searchbox .IconBox--autocomplete .IconBox__child {
  vertical-align: middle;
  display: inline-block;
  width: calc(100% - 60px);
}

#hero-banner .SearchBoxTextEditor--autocomplete {
  width: 100%;
  line-height: 1.25;
  padding: 0;
  margin: 0;
  outline: none;
}

#hero-banner .SearchBoxTextEditor {
  margin-top: 12px;
  font-size: 14px;
  color: #333;
  border: none;
  text-overflow: ellipsis;
  cursor: pointer;
  border-radius: 4px;
  background-color: #fff;
}

#hero-banner .SearchBoxContainer--horizontal .IconBox--checkIn,
.SearchBoxContainer--horizontal .IconBox--checkOut {
  width: 164px;
}

#hero-banner .Searchbox .IconBox--autocomplete,
.Searchbox .IconBox--checkIn,
.Searchbox .IconBox--checkOut,
.Searchbox .IconBox--occupancy {
  height: 66px;
}

#hero-banner .Searchbox__searchButton--active {
  z-index: 0;
}

#hero-banner .Searchbox__searchButton {
  position: relative;
  overflow: hidden;
  width: 130px;
  height: 66px;
  border-radius: 3px;
  background-color: $link-color-19;
  font-size: 14px;
  color: #fff;
  text-transform: uppercase;
  box-shadow: 0 4px 10px 3px rgba(0, 0, 0, 0.2);
  border: none;
  cursor: pointer;
  padding: 10px 0;
}

#hero-banner .Searchbox__searchButton--active:before {
  background: #84b5fe;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: scale(0);
  transform: scale(0);
  width: 5%;
  padding-top: 5%;
  border-radius: 50%;
  transform: translate(-50%, -50%) scale(0);
  -moz-transform: translate(-50%, -50%) scale(0);
  -o-transform: translate(-50%, -50%) scale(0);
  -ms-transform: translate(-50%, -50%) scale(0);
  -webkit-transform: translate(-50%, -50%) scale(0);
  opacity: 0;
  transition: opacity 0.3s ease-out, -webkit-transform 0.3s ease-out;
  transition: transform 0.3s ease-out, opacity 0.3s ease-out;
  transition: transform 0.3s ease-out, opacity 0.3s ease-out,
    -webkit-transform 0.3s ease-out;
}

#hero-banner .Searchbox__searchButton--active > * {
  position: relative;
}

#hero-banner .Searchbox__searchButton__text {
  font-weight: 400;
}

#hero-banner select {
  border: 0;
  background: transparent;
  width: 150px;
  padding: 5px 35px 5px 5px;
  height: 54px;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background: url(idropdown.png) 96% / 9% no-repeat;
  font-size: 14px;
  color: #333;
  outline: none;
}

#hero-banner .searchboxSale select option[data-type="rent"] {
  display: none;
}

#hero-banner .searchboxSale select option[data-type="sale"] {
  display: block;
}

#hero-banner .searchboxRent select option[data-type="rent"] {
  display: block;
}

#hero-banner .searchboxRent select option[data-type="sale"] {
  display: none;
}

#hero-banner #price-grp {
  font-size: 14px;
  color: #333;
  padding-top: 16px;
}

/* CAUTION: IE hackery ahead */

#hero-banner select::-ms-expand {
  display: none;
  /* remove default arrow on ie10 and ie11 */
}

#hero-banner .iconbox-state {
  margin-left: 13px;
}

#hero-banner i.fa-search {
  float: left;
  padding-top: 10px;
}

#hero-banner i.fa-search:before {
  font-size: 19px;
  padding: 5px;
  color: #333;
}

.mrt-button {
  position: absolute;
  right: 7px;
  top: 11px;
  background: rgba(72, 139, 248, 0.2);
}

.mrt-button:hover {
  background: rgba(72, 139, 248, 0.4);
}

.gin {
  position: absolute;
  width: 100%;
  height: 66px;
  top: 54px;
  overflow: hidden;
}

.child-gin {
  background: rgba(238, 238, 238, 0.7);
  width: 40%;
  width: 40%;
  height: 100%;
  margin-left: -40%;
}

#hero-banner .dropdown {
  width: 35rem;
  padding: 0.5rem;
  background-color: #fff;
  position: absolute;
  top: 100%;
  right: 0;
  z-index: 99;
  opacity: 0;
  visibility: hidden;
  -webkit-box-shadow: 0 0 0 0.1rem rgba(0, 0, 0, 0.04),
    0 0.3rem 1rem -0.2rem rgba(0, 0, 0, 0.1);
  box-shadow: 0 0 0 0.1rem rgba(0, 0, 0, 0.04),
    0 0.3rem 1rem -0.2rem rgba(0, 0, 0, 0.1);
}

#hero-banner .dropdown.active {
  opacity: 1;
  visibility: visible;
  margin-top: 13px;
  padding-left: 10px;
}

#hero-banner .dropdown:before {
  border-color: transparent transparent #fff;
  content: "";
  display: block;
  width: 0;
  border-width: 0.7rem;
  border-style: solid;
  position: absolute;
  bottom: 100%;
  right: 1rem;
}

#hero-banner .i-row {
  margin-left: -0.8rem;
  margin-right: -0.8rem;
}

#hero-banner .i-row > .i-col {
  padding-left: 0.8rem;
  padding-right: 0.8rem;
}

#hero-banner .Searchbox .IconBox--price {
  width: 226px;
  padding: 5px 10px;
}

.IconBox select,
.IconBox i,
.IconBox input,
.IconBox .input,
.IconBox a,
.Searchbox__searchButton__text {
  opacity: 0;
}

.IconBox.nogin select,
.IconBox.nogin i,
.IconBox.nogin input,
.IconBox.nogin .input,
.IconBox.nogin a,
.Searchbox__searchButton__text.nogin {
  opacity: 1;
}

/*************************FILTER********************************/

#hero-banner .SearchBoxFilters {
  margin-bottom: 0;
  transition: margin-bottom 0.3s ease-in-out;
  animation: SearchBoxotelsTab 0.3s;
  position: relative;
  width: 1045px;
  margin: 0 auto;
}

#hero-banner .SearchBoxFilters__slide {
  height: 0;
  padding: 0 5px;
  overflow: hidden;
  background-image: linear-gradient(
    180deg,
    hsla(0, 0%, 80%, 0.8),
    hsla(0, 0%, 88%, 0.8)
  );
  border-radius: 0 0 3px 3px;
  transition: height 0.3s ease-in-out;
}

#hero-banner .SearchBoxFilters--opened .SearchBoxFilters__slide {
  height: 135px;
}

#hero-banner .SearchBoxFilters__content {
  display: table;
  table-layout: fixed;
  width: 100%;
  padding: 23px 0 0 51px;
  margin-top: 0;
}

#hero-banner .SearchBoxFilters__item {
  font-size: 14px;
  display: block;
  list-style-type: none;
  opacity: 1;
  transition: height 0.1s ease-in-out, opacity 0.1s ease-in-out;
  text-align: center;
  padding-bottom: 25px;
}

#hero-banner .SearchBoxFilters__toggle {
  cursor: pointer;
  display: block;
  width: 270px;
  margin: 0 auto;
  padding: 6px 0;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  color: #555;
  text-align: center;
  font-size: 13px;
  font-weight: 400;
  background: hsla(0, 0%, 87%, 0.8) url(idropdown.png) 80% / 5% no-repeat;
  border-radius: 0 0 3px 3px;
}

#hero-banner .SearchBoxFilters--opened .SearchBoxFilters__toggle {
  background: hsla(0, 0%, 87%, 0.8) url(idropup.png) 80% / 5% no-repeat;
}

#hero-banner .SearchBoxFilters__item .bedFilter__title,
#hero-banner .SearchBoxFilters__item .bathFilter__title {
  color: #000;
  font-weight: bolder;
}

#hero-banner .SearchBoxFilters__item .filter-menu {
  margin-bottom: 5px;
}

/*************** Refine filter *****************/

#hero-banner .radios.single {
  margin-bottom: 0;
  width: 100%;
}

#hero-banner .radio {
  display: inline-block;
}

#hero-banner .radio input {
  display: none;
  visibility: hidden;
}

#hero-banner .radio label {
  padding-left: 2.8rem;
  padding-right: 2rem;
  font-size: small;
  position: relative;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  font-weight: 400;
  color: #333;
  line-height: 1.5;
}

#hero-banner .radio label:before {
  border-radius: 100%;
  content: "";
  display: block;
  width: 1.5rem;
  height: 1.5rem;
  background-color: #fff;
  border: 0.1rem solid #dededf;
  position: absolute;
  top: 0;
  left: 0;
  -webkit-transition: background 200ms ease;
  transition: background 200ms ease;
}

#hero-banner .radio input:checked + label:after {
  content: "";
  width: 1rem;
  height: 1rem;
  margin: 0.25rem;
  background-color: #3b839c;
  border-radius: 100%;
  text-align: center;
  position: absolute;
  line-height: 1.5rem;
  top: 0;
  left: 0;
  display: block;
}

/**********************Suggestion Popup ******************/

#hero-banner .Popup.Autocomplete {
  left: 13px;
  top: 100%;
  transition: left 0.3s ease-out;
  position: absolute;
  display: none;
  z-index: 1;
}

#hero-banner .Popup:before {
  display: block;
  width: 0;
  height: 0;
  border-left: 11px solid transparent;
  border-right: 11px solid transparent;
  border-bottom: 11px solid #fff;
}

#hero-banner .Popup.Autocomplete:before {
  content: "";
  margin-left: 307px;
  transition: margin-left 0.3s ease-out;
}

#hero-banner .Popup.Autocomplete .Popup__content {
  width: 693px;
  left: -2px;
  background-color: #fff;
  transition: width 0.3s ease-out, height 0.3s ease-out,
    max-height 0.3s ease-out;
}

#hero-banner .AutocompleteList {
  background-color: #fff;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

#hero-banner .Suggestion {
  overflow: hidden;
  padding: 10px 12px;
  font-weight: 400;
  white-space: nowrap;
  letter-spacing: normal;
  cursor: pointer;
  font-size: 14px;
  line-height: 24px;
  list-style: none;
}

#hero-banner .Suggestion:hover {
  background-color: #0283df;
}

#hero-banner .Suggestion__child {
  float: right;
  max-width: 375px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

#hero-banner .Suggestion__child-Info__count {
  margin-right: 3px;
  color: #0283df;
}

#hero-banner .Suggestion__child-Info__text {
  color: #333;
}

#hero-banner .Suggestion__icon {
  float: left;
  margin-right: 5px;
  width: 20px;
}

#hero-banner .Suggestion__icon i {
  font-size: 20px;
}

#hero-banner .Suggestion__icon i::before {
  padding-top: 2px;
}

#hero-banner .Suggestion__text {
  color: #333;
}

#hero-banner .Suggestion__subText--normal {
  color: #333;
}

#hero-banner .Suggestion__subText {
  display: inline-block;
  max-width: 500px;
}

#hero-banner .Suggestion:hover .Suggestion__child-Info__count,
#hero-banner .Suggestion:hover .Suggestion__child-Info__text,
#hero-banner .Suggestion:hover .Suggestion__subText--normal,
#hero-banner .Suggestion:hover {
  color: #fff;
}

/******************Static Blocks *********/

.home_st_cnt {
  max-width: 890px;
  margin: 20px auto;
  position: relative;
  margin-left: 19%;
}

.home_st_cnt .blk_header {
  padding: 10px 0px;
  font-size: 1.8rem;
  font-weight: 700;
  cursor: pointer;
  color: #000;
  letter-spacing: 1px;
}

.home_st_cnt .content_wrapper {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}

.home_st_cnt .most_row {
  display: inline-flex;
}

.home_st_cnt .cell {
  box-shadow: 0 1px 37px 0 rgba(165, 165, 165, 0.29);
  margin: 18px;
  width: 273px;
  height: 229px;
  -webkit-transition: 0.2s;
  -o-transition: 0.2s;
  transition: 0.2s;
  display: table;
  flex-direction: row;
  position: relative;
}

.home_st_cnt.most_popular_wrapper .cell {
  color: #fff;
  font-size: 1.7rem;
  text-align: center;
}

.home_st_cnt.most_popular_wrapper .cell:hover {
  cursor: pointer;
}

.home_st_cnt .cell .cell-content {
  width: 100%;
  padding: 1.071em;
  background-color: #f7f6f6;
  position: absolute;
  left: 0;
  bottom: 0;
}

.home_st_cnt .cell .cell-title {
  margin-bottom: 0.833em;
  font-size: 1.143em;
  margin-top: 0;
}

.home_st_cnt .cell .cell-sub {
  margin-top: 0.727em;
  font-size: 0.714em;
  line-height: 1.364em;
  text-transform: uppercase;
  color: #252525;
}

.home_st_cnt .cell img {
  width: 100%;
  height: auto;
  max-height: 250px;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

/*************Range Slider ********/

.slidecontainer {
  width: 30%;
  float: left;
  margin-left: 14px;
}

.slider {
  -webkit-appearance: none;
  width: 100%;
  height: 3px;
  border-radius: 5px;
  background: $link-color-19;
  outline: none;
  /* opacity: 0.7;
    -webkit-transition: .2s;
    transition: opacity .2s; */
}

.slider:hover {
  opacity: 1;
}

.slider::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 25px;
  height: 25px;
  border-radius: 50%;
  background: #fff;
  cursor: pointer;
  border: 1px solid $link-color-19;
}

.slider::-moz-range-thumb {
  width: 25px;
  height: 25px;
  border-radius: 50%;
  border: 1px;
  background: #fff;
  cursor: pointer;
  border: 1px solid $link-color-19;
}

.slidecontainer span {
  margin-left: 10px;
  font-weight: 500;
}

.slidecontainer p {
  margin-bottom: 2rem;
}

.search-nouislider {
  margin: 0 20px;
  height: 6px;
}

.search-nouislider .noUi-connect {
  background: $link-color-19;
}

.search-nouislider .noUi-handle {
  top: -11px;
}

.zing-cat-card {
  position: relative;
  width: 50%;
  height: 220px;
  flex: 1 1 0;
  padding: 0 15px 20px;
  &.col-1 {
    flex-basis: 100/3 * 1%;
  }
  &.col-2 {
    flex-basis: 100/3 * 2%;
  }
  &-wrapper {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }
  .image {
    opacity: 1;
    display: block;
    width: 100%;
    height: 100%;
    transition: 0.3s ease;
    backface-visibility: hidden;
    background-position: center;
    background-size: cover;
    border-radius: 5px;
    overflow: hidden;
    .dark-gradient {
      opacity: 0;
      position: absolute;
      background-color: transparentize(#000, 0.6);
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      transition: 0.3s ease;
    }
  }
  .middle {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
    width: 80%;
  }
  .cat-name {
    color: white;
    font-size: 16px;
    flex-grow: 1;
    font-size: 25px;
    line-height: 35px;
  }
  .cat-desc {
    color: white;
    font-size: 16px;
    padding: 10px 32px;
    transition: 0.5s ease;
    opacity: 0;
    transform: scale(0, 0);
    height: 0;
    font-size: 15px;
    line-height: 22px;
  }
  &:hover {
    .cat-desc {
      opacity: 1;
      transform: scale(1, 1);
      height: 80px;
    }
    .dark-gradient {
      opacity: 1;
    }
  }
}
//====================================
// 2k18 Frontpage Yellow Label
//====================================
.agt-registration {
  background-color: #f4eb49;
  position: absolute;
  top: 10rem;
  right: -180px;
  max-width: 180px;
  font-size: 1rem;
  letter-spacing: 0.03em;
  transition: all .3s ease-in-out;
  &.in {
    right: 0px;
    transition: all .3s ease-in-out;
  }
  a {
    color: black;
    display: block;
    padding: .6rem 1rem;
    &:hover, &:active, &:focus {
      text-decoration: none;
    }
  }
}
//====================================
// 2k18 Frontpage Mobile Styling
//====================================
.home-carousel {
  .flickity-prev-next-button {
    width: 30px;
    height: 30px;
  }
}
.front-mobile-carousel {
  &.carousel-cell {
    width: 180px;
    min-height: 100%;
    display: table;
    margin-right: 15px;
    .flickity-news-boxes {
      overflow: hidden;
      border: 1px solid #dedede;
      display: table-cell;
      background-color: #f7f6f6;
      .news-boxes-inner {
        > a {
          display:  block;
          width: 100%;
          height: 120px;
          > img{
            width: 100%;
            height: 100%;
            object-fit: cover;
          }
        }
      }
    }
    .flickity-news-content {
      padding: 1.2rem .8rem;
      width: 100%;
      .news-title {
        margin-top: 0;
        font-size: 1.2rem;
        font-weight: 400;
        a:hover {
          text-decoration: none;
        }
      }
      .news-date {
        font-size: 0.8rem;
      }
    }
    &.discover-more {
      > a {
        width: 100%;
        position: absolute;
        top:0;
        bottom:0;
        > img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }
    }
  }
  @media screen and ( min-width: 468px ) {
    &.carousel-cell {
      width: 250px;
      padding: 10px 24px 10px 0px;
      .flickity-news-boxes {
        .news-boxes-inner {
          > a {
            height: 190px;
          }
        }
      }
    }
  }
  @media screen and ( min-width: 768px ) {
    &.carousel-cell {
      width: 350px;
      padding: 10px 30px 10px 0px;
      .flickity-news-boxes {
        .news-boxes-inner {
          > a {
            height: 280px;
          }
        }
      }
    }
  }
}
.block-showcase {
  // .front-mobile-carousel {
  //   &.carousel-cell {
  //   }
  // }
  .front-mobile-carousel {
    .flickity-news-boxes {
      .caption {
        font-size: 1.8rem;
        position:absolute;
        bottom:0;
        padding: 0 1rem .5rem 1rem;
        color: white;
        font-weight: 500;
        letter-spacing: .03em;
        text-shadow: 0px 0px 5px #333;
        width: calc(100% - 2rem);
      }
      .news-boxes-inner {
        > a {
          height: auto;
          img {
            max-width: 183px;
            height: auto;
          }
        }
      }
    }
    @media screen and ( min-width: 468px ) {
      // &.carousel-cell {
      //   width: 210px;
      // }
      .flickity-news-boxes {
        .news-boxes-inner > a {
          height: 280px;
        }
      }
    }
    @media screen and ( min-width: 768px ) {
      .flickity-news-boxes {
        .news-boxes-inner {
          > a {
            height: 300px;
          }
        }
      }
    }
  }
}

.extra-links {
  list-style:  none;
  padding: 0;
  &-header {
    margin: 0;
    padding: 10px 8px;
    color: white;
    background: #021f54;
    border: 1px solid #021f54;
  }
  li {
    border-top: 1px solid #8b8b8b;
    border-left: 1px solid #8b8b8b;
    border-right: 1px solid #8b8b8b;
    &:last-child {
      border-bottom: 1px solid #8b8b8b;
    }
    a {
      display: block;
      padding: 10px 8px;
      font-size: 1.2rem;
      letter-spacing: 0.03em;
      cursor: pointer;
      &:hover, &:focus, &:active, &:visited {
        text-decoration: none;
      }
      .title{
        font-weight: 600;
        color: $link-color-19;
      }
      .brief{
        color: black;
      }
    }
  }
}
