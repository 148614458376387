@charset "UTF-8";
@import url("https://fonts.googleapis.com/css?family=Poppins:300,400,500,600,700|Material+Icons");
@import url("https://fonts.googleapis.com/css?family=Poppins");
@import url("https://fonts.googleapis.com/css?family=Montserrat");
@import url("https://fonts.googleapis.com/css2?family=Rubik:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,500&display=swap");
@import url("https://fonts.googleapis.com/css2?family=IBM+Plex+Serif:wght@200;300;400;500;700&display=swap");
@import url("https://fonts.googleapis.com/css?family=Chivo:400,700,900");
@import url("https://fonts.googleapis.com/css?family=Inknut+Antiqua");
@import url("https://fonts.googleapis.com/css2?family=Eczar:wght@400;700&display=swap");
@import url("https://fonts.googleapis.com/css?family=Noto+Serif:400,700");
@import url("https://fonts.googleapis.com/css?family=Noto+Serif:400,700");
.center-mini {
  width: 100%;
  margin: 0 auto;
  text-align: center;
}

.center-mini.m-t-20 {
  margin-top: 20px;
}

.center-mini.m-t-30 {
  margin-top: 30px;
}

.center-mini.m-b-30 {
  margin-bottom: 30px;
}

.center-mini.m-b-20 {
  margin-bottom: 20px;
}

.m-t-0 {
  margin-top: 0;
}

.m-l-0 {
  margin-left: 0 !important;
}

.m-b-10 {
  margin-bottom: 10px;
}

.m-lr-0 {
  margin-right: 0 !important;
  margin-left: 0 !important;
}

@media (min-width: 992px) {
  .p-r-0 {
    padding-right: 0;
  }
}

@media (min-width: 992px) {
  .p-l-0 {
    padding-left: 0;
  }
}

.p-0 {
  padding: 0;
}

.padder-top {
  height: 29px;
}

.padder-top-buyvsrent {
  height: 68px;
}

@media (max-width: 768px) {
  .p-m-20 {
    padding-right: 20px;
    padding-left: 20px;
  }
}

.cursor-disabled {
  cursor: not-allowed !important;
}

.cursor-pointer, .accordion-wrap .accordion-js-panel .accordion__header {
  cursor: pointer;
}

img.cursor-pointer, .accordion-wrap .accordion-js-panel img.accordion__header {
  cursor: pointer;
}

.max-w250 {
  width: 100%;
  max-width: 250px;
}

.max-w1000 {
  width: 100%;
  max-width: 1000px;
  margin: 0 auto;
}

.border-right-no {
  border-right: 0 !important;
}

@media (max-width: 768px) {
  .border-right-0-sm {
    border-right: 0 !important;
  }
}

.float-none {
  float: none;
}

.sides-auto {
  float: none;
  margin-left: auto !important;
  margin-right: auto !important;
}

.bg-dark {
  background: #464a3c;
  color: #fff;
}

.overflow-hidden {
  overflow: hidden;
}

.overflow-auto {
  overflow: auto;
  clear: both;
}

@media (max-width: 768px) and (min-width: 768px) {
  .hidden-tablet-only {
    display: none;
  }
}

@media (max-width: 768px) {
  .tab-none {
    display: none !important;
  }
}

@media (max-width: 767px) {
  .sm-text-center {
    text-align: center;
  }
}

.hash-offset {
  display: block;
  position: relative;
  top: -70px;
  visibility: hidden;
}

@media (max-width: 768px) {
  .hash-offset {
    top: -100px;
  }
}

.pos-top {
  top: 0 !important;
  margin: auto !important;
}

.pos-top-content {
  top: 0 !important;
  margin: auto !important;
}

.pos-fixed {
  position: fixed !important;
  width: 100%;
}

@media (max-width: 577px) {
  .m-only {
    display: none;
  }
}

@media (max-width: 576px) {
  .m-only {
    display: block;
  }
}

.el-show {
  display: block;
}

.el-hide {
  display: none;
}

.justify-content-center {
  justify-content: center !important;
}

a.menu-title-link h4:hover {
  color: inherit;
  text-decoration: underline;
}

.m-hide {
  display: block;
}

@media (max-width: 767px) {
  .m-hide {
    display: none !important;
  }
}

.m-show {
  display: none;
}

@media (max-width: 767px) {
  .m-show {
    display: block !important;
  }
}

.sm-hide {
  display: block !important;
}

@media (max-width: 1023px) {
  .sm-hide {
    display: none !important;
  }
}

.sm-show {
  display: none !important;
}

@media (max-width: 1023px) {
  .sm-show {
    display: block !important;
  }
}

.mobile-micro-masthead img {
  width: 100%;
  height: auto;
}

.desktop-micro-masthead {
  margin: 0 auto;
  text-align: center;
}

.desktop-micro-masthead img {
  width: 100%;
  height: auto;
  max-width: 1440px;
}

.m-long-menu {
  white-space: inherit;
  line-height: 1.3;
}

@media (min-width: 768px) {
  .t-hide {
    display: none;
  }
}

.text-white {
  color: #fff;
}

@media (max-width: 768px) {
  .padding-mobile {
    padding: 0 20px;
  }
}

.bg-alt {
  background: #efefef !important;
}

.no-gutters {
  margin-right: 0;
  margin-left: 0;
}

.display-initial {
  display: initial !important;
}

.flex {
  display: -webkit-box;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}

.global-loader {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background: #fff url(https://upload.wikimedia.org/wikipedia/commons/b/b1/Loading_icon.gif) no-repeat center center;
}

.d-text-break {
  display: block;
}

.rainbow {
  -webkit-text-fill-color: transparent;
  -webkit-background-clip: text;
  background-image: linear-gradient(90deg, #ffac1b -2.58%, #e10b90 45.27%);
  background-position: 50%;
  background-repeat: no-repeat;
  background-size: cover;
  color: #fff;
}

.font-weight-700 {
  font-weight: 700;
}

body {
  background: #fff;
  color: #252525;
  font-size: 1.3rem;
  font-family: "Poppins", sans-serif;
  overflow-x: hidden;
}

body.no-header {
  padding-top: 0;
}

@media (min-width: 1024px) and (max-width: 1024px) {
  body {
    padding-top: 4rem;
  }
}

body.page-news p {
  font-family: 'Noto Serif SC', serif;
  color: #4a4a4a;
  font-size: 15px;
  line-height: 24px;
}

h1, h2, h3, h5, h6,
.h1, .h2, .h3, .h4, .h5, .h6 {
  font-family: "Poppins", sans-serif;
  line-height: 1.4;
}

a {
  color: #2e3a52;
}

a:hover {
  color: #1c2331;
}

a:focus {
  color: #2e3a52;
}

p {
  margin-bottom: 1.05rem;
}

.font-sans {
  font-family: "Poppins", sans-serif;
}

.font-serif {
  font-family: "Georgia", "Times New Roman", Times, serif;
}

.font-opensans {
  font-family: "Arial", sans-serif;
}

.font-w200 {
  font-weight: 200;
}

.font-w300 {
  font-weight: 300;
}

.font-w400 {
  font-weight: 400;
}

.font-w500 {
  font-weight: 500;
}

.font-w600 {
  font-weight: 600;
}

.font-w700 {
  font-weight: 700;
}

.text-xxsmall {
  font-size: xx-small;
}

.text-xsmall {
  font-size: x-small;
}

.text-small {
  font-size: small;
}

.text-smaller {
  font-size: smaller;
}

.text-medium {
  font-size: medium;
}

.text-larger {
  font-size: larger;
}

.text-large {
  font-size: large;
}

.text-xlarge {
  font-size: x-large;
}

.text-xxlarge {
  font-size: xx-large;
}

.text-theme {
  color: #2e3a52;
}

.text-theme-alt {
  color: #3b839c;
}

.text-grey {
  color: #a5a5a5;
}

.text-lightgrey {
  color: #cbcbcb;
}

.text-bold {
  font-weight: bold;
}

.text-strike {
  text-decoration: line-through;
}

.text-ellipsis {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.letter-s {
  letter-spacing: 0.08rem;
}

.letter-s-1 {
  letter-spacing: 0.1rem;
}

.letter-s-2 {
  letter-spacing: 0.2rem;
}

.bg-theme {
  background-color: #2e3a52;
}

.bg-theme-alt {
  background-color: #3b839c;
}

.bg-white {
  background-color: #ffffff;
}

.bg-black {
  background-color: #000000;
}

.bg-base {
  background-color: #252525;
}

.color-theme {
  color: #2e3a52;
}

.color-theme-alt {
  color: #3b839c;
}

.color-white {
  color: #ffffff;
}

.color-black {
  color: #000000;
}

.color-base {
  color: #252525;
}

.color-grey {
  color: #a5a5a5;
}

.color-dark {
  color: #656565;
}

.color-even {
  color: #f7f6f6;
}

.color-odd {
  color: #ffffff;
}

.ion-icon {
  line-height: 1;
}

.ion-smaller {
  font-size: 1.2rem;
}

.ion-small {
  font-size: 1.4rem;
}

.ion-medium {
  font-size: 2rem;
}

.ion-large {
  font-size: 3rem;
}

.ion-xlarge {
  font-size: 6rem;
}

.ion-xxlarge {
  font-size: 12rem;
}

.material-icons {
  font-size: 1.714em;
  vertical-align: middle;
}

.material-icons + span {
  vertical-align: middle;
}

.img-center img {
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.link-tabs > li > a {
  text-decoration: underline;
}

.link-tabs > li.active > a {
  color: #656565;
  font-weight: bold;
  text-decoration: none;
  cursor: default;
}

.link-inline > li > a {
  text-decoration: none;
}

.link-inline > li.active {
  text-decoration: underline;
  cursor: default;
}

.more-link {
  padding: 0.5rem 1.5rem;
}

.more-link.default {
  background-color: rgba(0, 0, 0, 0.03);
  border-top: 0.1rem solid rgba(0, 0, 0, 0.08);
}

.more-link.clean {
  background-color: transparent;
  border-top-color: rgba(0, 0, 0, 0.1);
}

.more-link.theme-alt a {
  color: #3b839c;
}

.more-link.absolute {
  position: absolute;
  right: 0;
  top: -4.5rem;
}

.more-link.reset {
  padding: 0;
}

.more-link .ion-icon {
  vertical-align: middle;
}

@media (max-width: 767px) {
  .more-link.absolute {
    position: relative;
    right: auto;
    top: -2rem;
  }
}

.tags {
  margin-top: 1.429em;
  margin-bottom: 1.429em;
}

body.page-content .tags {
  margin-top: 0;
}

.tags .tag {
  display: inline-block;
  margin-right: 0.571em;
  margin-bottom: 0.429em;
  font-family: "Poppins", sans-serif;
  letter-spacing: 0.043em;
}

.tags .tag > a {
  display: block;
  padding: 0.429em 0.857em 0.429em 0.643em;
  background-color: #f4f4f4;
  color: inherit;
  border-radius: 0.3rem;
  transition: all 200ms ease-in-out;
}

.tags .tag > a:focus, .tags .tag > a:hover {
  text-decoration: none;
}

.tags .tag > a:hover {
  border-color: transparent;
  background-color: #d6d8dc;
}

[data-toggle='nav']:focus {
  text-decoration: none;
}

[data-toggle='nav']:not(.button):not(.btn):hover, [data-toggle='nav']:not(.button):not(.btn):focus:hover {
  text-decoration: underline;
}

[data-toggle='expandable'] {
  height: 20rem;
  padding-bottom: 3rem;
  overflow: hidden;
  position: relative;
}

[data-toggle='expandable']:before, [data-toggle='expandable']:after {
  content: '';
  display: table;
}

[data-toggle='expandable']:after {
  clear: both;
}

[data-toggle='expandable']:after {
  content: '';
  display: block;
  width: 100%;
  height: 5rem;
  background: none;
  background: linear-gradient(to bottom, rgba(255, 255, 255, 0) 0%, white 45%, white 100%);
  position: absolute;
  bottom: 0;
  left: 0;
}

.cleanmode [data-toggle='expandable']:after {
  display: none;
}

[data-toggle='expandable'] .readmore {
  color: #3b839c;
  font-size: 0.93em;
  font-weight: 600;
  position: absolute;
  bottom: 0;
  left: 0;
  z-index: 1;
  user-select: none;
}

[data-toggle='expandable'] .readmore .material-icons {
  font-size: 1.6em;
  position: relative;
  top: -0.06em;
}

[data-toggle='expandable'] .readmore:hover {
  color: #2e3a52;
}

[data-toggle='expandable'] .readmore:focus, [data-toggle='expandable'] .readmore:hover {
  text-decoration: none;
}

[data-toggle='expandable'].active {
  height: auto;
  overflow: visible;
}

[data-toggle='expandable'].active:after {
  display: none;
}

.collapsible-trigger {
  display: inline-block;
}

.collapsible-trigger > i {
  margin-left: 0.357em;
  margin-right: 0.357em;
}

.collapsible-wrapper {
  padding-top: 1em;
  padding-bottom: 1em;
}

.disclaimer {
  margin-top: 2rem;
  margin-bottom: 2rem;
  color: #a5a5a5;
  font-size: 1.1rem;
}

.disclaimer.area {
  padding-top: 2rem;
  padding-bottom: 2rem;
  border-top: 0.1rem solid #dedede;
  border-bottom: 0.1rem solid #dedede;
}

.disclaimer:not(.area) > .title {
  float: left;
  margin: 0 0.5rem 0 0;
  font-size: inherit;
  font-weight: 600;
}

.disclaimer:not(.area) > .title:after {
  content: ':';
}

.definition-button:focus, .definition-button:hover {
  text-decoration: none;
}

.definition-button:active {
  color: #000000;
}

.country-selector img {
  max-width: 100%;
  height: auto;
}

.country-flag {
  margin-bottom: 1.8rem;
}

.country-flag:before, .country-flag:after {
  content: '';
  display: table;
}

.country-flag:after {
  clear: both;
}

.country-flag > a {
  display: block;
  float: left;
  width: 4.5rem;
}

.country-flag > a + a {
  margin-left: 0.5rem;
}

@media (min-width: 768px) {
  .country-flag {
    width: 25%;
    margin-bottom: 0;
    position: absolute;
    top: -5.5rem;
    right: 0;
  }
}

.social-media {
  margin: 0;
  padding: 0;
  list-style: none;
}

.social-media li {
  display: inline-block;
  vertical-align: middle;
}

.social-media li + li {
  margin-left: 0.3rem;
}

.social-media li a {
  display: block;
  width: 3.8rem;
  height: 3.8rem;
  background-color: #2e3a52;
  color: #ffffff;
  font-size: 2rem;
  line-height: 3.8rem;
  text-align: center;
  border-radius: 0.3rem;
  box-shadow: 0 0.1rem 0 0 rgba(255, 255, 255, 0.1) inset;
  transition: all 300ms ease-in-out;
}

.social-media li a svg:not(:root) {
  margin: 0.9rem;
}

.social-media li a:hover {
  color: inherit;
}

.social-media li.facebook a {
  background-color: #3b5998;
}

.social-media li.twitter a {
  background-color: #1da1f2;
}

.social-media li.google-plus a {
  background-color: #dd4b39;
}

.social-media li.linkedin a {
  background-color: #0077b5;
}

.social-media li.instagram a {
  background-color: #405de6;
}

.social-media li.youtube a {
  background-color: #cd201f;
}

.social-media li.pinterest a {
  background-color: #bd081c;
}

.social-media li.messenger a {
  background-color: #0084ff;
}

.social-media li.weixin a {
  background-color: #7bb32e;
}

.social-media li.whatsapp a {
  background-color: #25d366;
}

.social-media li.github a {
  background-color: #6e5494;
}

.social-media li.gitlab a {
  background-color: #e24329;
}

.social-media li.bitbucket a {
  background-color: #205081;
}

.social-media li.codepen a {
  background-color: #353535;
}

.social-media.circle li a {
  border-radius: 100%;
}

.social-media.transparent li + li {
  margin-left: 0;
}

.social-media.transparent li a {
  background-color: transparent;
  box-shadow: none;
}

.social-media.hovered li a {
  background-color: #c9c9c9;
}

.social-media.hovered li a:hover {
  background-color: #969696;
  color: #ffffff;
}

.social-media.hovered li.facebook a:hover {
  background-color: #3b5998;
}

.social-media.hovered li.twitter a:hover {
  background-color: #1da1f2;
}

.social-media.hovered li.google-plus a:hover {
  background-color: #dd4b39;
}

.social-media.hovered li.linkedin a:hover {
  background-color: #0077b5;
}

.social-media.hovered li.pinterest a:hover {
  background-color: #bd081c;
}

.social-media.hovered li.whatsapp a:hover {
  background-color: #25d366;
}

@media (max-width: 479px) {
  .social-media li + li {
    margin-left: 0;
  }
}

.social-sharer {
  position: relative;
}

.social-sharer .social-button {
  width: 5rem;
  height: 5rem;
  line-height: 5rem;
  text-align: center;
  background-color: #2e3a52;
  color: #ffffff;
  border-radius: 50%;
  box-shadow: 0 0.3rem 1.2rem -0.2rem rgba(37, 37, 37, 0.5), 0 0.2rem 0.3rem -0.1rem rgba(37, 37, 37, 0.5);
}

.social-sharer .social-button:hover {
  background-color: #3b839c;
}

.social-sharer .social-media {
  display: block;
  float: none;
  min-width: auto;
  background-color: transparent;
  border: 0;
  left: 50%;
  bottom: 100%;
  top: auto;
  z-index: 1;
  opacity: 0;
  visibility: hidden;
  box-shadow: none;
  transform: translateX(-50%);
  transition: all 300ms cubic-bezier(0.65, -0.13, 0.34, 1.15);
}

.social-sharer .social-media > li {
  display: block;
  margin-bottom: 1rem;
}

.social-sharer .social-media > li + li {
  margin-left: 0;
}

.social-sharer .social-media > li > a {
  display: block;
  padding: 0;
}

.social-sharer .social-media > li > a:focus, .social-sharer .social-media > li > a:hover {
  color: #ffffff;
}

.social-sharer.open .social-media {
  visibility: visible;
  opacity: 1;
}

.highcharts-contextmenu hr {
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
}

.embed-frame {
  position: relative;
  height: 0;
  overflow: hidden;
}

.embed-frame iframe {
  border: 0;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.embed-frame-full {
  padding-bottom: 45.61404%;
}

.notify-top .header {
  top: 2.8rem;
}

.notify-top-wrapper {
  display: none;
  width: 100%;
  background-color: #f4b124;
  color: #252525;
  font-family: "Arial", sans-serif;
  font-weight: 600;
  text-align: center;
  line-height: 2.8rem;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 100;
  transform: translate3d(0, 0, 0);
}

.notify-top .notify-top-wrapper {
  display: block;
}

@media (max-width: 1024px) {
  .notify-top {
    padding-top: 7.8rem;
  }
  .notify-top .header-nav {
    top: 7.8rem;
    height: calc(100% - 7.8rem);
  }
  .notify-top-wrapper {
    font-size: 1.3rem;
  }
}

@media (min-width: 1025px) {
  .notify-top {
    padding-top: 10rem;
  }
  .notify-top .front-wrapper .hero {
    top: 10rem;
    height: calc(100vh - 10rem);
  }
  .notify-top .front-wrapper .hero.sticky {
    margin-top: 2.8rem;
  }
}

.content-top {
  background-color: #f2f2f2;
  position: relative;
}

.content-area {
  background-color: #ffffff;
  position: relative;
}

.content-bottom {
  border-top: 0.1rem solid #e8e9eb;
  background-color: #f7f6f6;
  position: relative;
}

@media (max-width: 991px) {
  .content-area .sidebar {
    margin-top: 3rem;
  }
}

[v-cloak] {
  display: none !important;
}

.m-b-05 {
  margin-bottom: 5px !important;
}

/* Breadcrumb */
.breadcrumb {
  padding: 0.5rem 0;
  margin-bottom: 0;
  background-color: transparent;
  font-size: x-small;
  border-radius: 0;
}

.breadcrumb > li:first-child > a {
  font-size: 0;
}

.breadcrumb > li:first-child > a:before {
  content: '\f144';
  display: block;
  float: left;
  margin-top: -0.1rem;
  color: #535353;
  font-size: 1.8rem;
  font-family: "Ionicons";
  line-height: 1;
  position: relative;
  font-size: 1.4rem;
}

.breadcrumb > li + li:before {
  content: '\f3d3\00a0';
  font-family: "Ionicons";
  line-height: 1;
}

.breadcrumb > li > a {
  color: #252525;
}

/* Button */
.btn-default.active, .btn-default.active:hover, .btn-default.active:focus {
  background-color: #2e3a52;
  border-color: #2e3a52;
  color: #ffffff;
  box-shadow: none;
}

.btn-default.active:active {
  background-color: #1c2331;
}

.btn.active.focus, .btn.active:focus, .btn.focus, .btn:active.focus, .btn:active:focus, .btn:focus,
[role='button'].active.focus,
[role='button'].active:focus,
[role='button'].focus,
[role='button']:active.focus,
[role='button']:active:focus,
[role='button']:focus {
  outline: none;
}

/* Container */
@media (min-width: 1200px) {
  .container {
    width: 99rem;
  }
}

/* Form Input */
.form-control:not(:focus) {
  box-shadow: none;
}

.form-control:focus {
  border-color: #2e3a52;
  box-shadow: 0 0 0.6rem -0.1rem rgba(46, 58, 82, 0.36);
}

/* Input Group */
.input-group-addon.clean {
  background-color: transparent;
  border-color: transparent;
}

.input-group-addon.clean + .form-control {
  border-radius: 0.4rem;
}

/* List */
.list-inline.dots > li {
  position: relative;
}

.list-inline.dots > li + li:before {
  content: '';
  display: block;
  width: 0.2rem;
  height: 0.2rem;
  border-radius: 2rem;
  background-color: #a5a5a5;
  position: absolute;
  left: -0.2rem;
  top: 50%;
  border-radius: 1rem;
  transform: translateY(-50%);
}

/* Label */
.label.rounded {
  padding: 0.286em 1.429em;
  border-radius: 2em;
}

.label.block {
  display: block;
  padding-top: 0.476em;
  padding-bottom: 0.476em;
}

/* Dropdown */
.dropdown-toggle > .ion-icon {
  font-size: 1.1em;
}

.dropdown-menu:not(.social-media) .ion-icon {
  display: inline-block;
  width: 2rem;
  margin-left: -0.5rem;
  margin-right: 0.3rem;
  font-size: 1.8rem;
  text-align: center;
  position: relative;
  top: 0.1rem;
}

.dropdown-menu:not(.social-media) .ion-caret {
  float: right;
  font-size: 1.2rem;
}

.dropdown-theme {
  margin-top: 0.7rem;
  border: 0.1rem solid rgba(46, 58, 82, 0.1);
  border-radius: 0.3rem;
  box-shadow: 0 0.2rem 0.8rem -0.2rem rgba(37, 37, 37, 0.3);
}

.dropdown-theme:before, .dropdown-theme:after {
  content: '';
  display: block;
  width: 0;
  height: 0;
  border-width: 0.5rem;
  border-style: solid;
  border-color: transparent transparent #ffffff;
  position: absolute;
  bottom: 100%;
  left: 1.5rem;
}

.dropdown-theme:after {
  border-width: 0.6rem;
  border-color: transparent transparent rgba(46, 58, 82, 0.2);
  left: 1.4rem;
  z-index: -1;
}

.dropdown-theme .ion-icon {
  margin-left: 0;
  top: 0.2rem;
}

.dropdown-theme > li > a {
  padding: 0.7rem 1.5rem;
  font-size: 0.929em;
}

.dropdown-theme > li > a:hover {
  background-color: #3b839c;
  color: #ffffff;
}

.dropdown-theme > .active > a {
  background-color: transparent;
  color: #3b839c;
}

.dropdown-theme > .active > a:hover, .dropdown-theme > .active > a:focus {
  background-color: #3b839c;
  color: #ffffff;
}

.dropdown-theme > .logout {
  margin-bottom: -0.5rem;
}

.dropdown-theme > .logout > a {
  color: #969696;
  background-color: #f7f6f6;
  border-top: 0.1rem solid rgba(37, 37, 37, 0.15);
  border-radius: 0 0 0.3rem 0.3rem;
}

.dropdown-right {
  left: auto;
  right: 0;
}

.dropdown-right:before {
  left: auto;
  right: 1.5rem;
}

.dropdown-right:after {
  left: auto;
  right: 1.4rem;
}

/* Page Header */
.page-header {
  margin-top: 0.3em;
  padding-bottom: 0;
  border-bottom: 0;
  font-size: 2.8rem;
  font-weight: 600;
  color: #2e3a52;
}

@media (max-width: 767px) {
  .page-header {
    font-size: 2.2rem;
  }
}

.button.white {
  border-radius: 5px;
  line-height: 1.3em;
  text-align: center;
  text-decoration: none;
  background: transparent;
  background-clip: padding-box;
  cursor: pointer;
  transition: border-color,background,box-shadow,color,fill .1s ease;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  font-weight: 400;
  font-style: normal;
  padding: 10px 20px;
  font-size: 1.3rem;
  border: 1px solid rgba(48, 51, 57, 0.15);
  background-color: #fff;
  color: #005c98;
}

.button.white:hover {
  border-color: rgba(48, 51, 57, 0.3);
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.1);
  background-color: linear-gradient(180deg, #fff, #f4f5f9);
  background-image: linear-gradient(180deg, #fff, #f4f5f9);
}

.animate.waving {
  animation: waveHand 4.5s infinite;
}

.animate.waving.left {
  transform-origin: center left;
}

.animate.waving.right {
  transform-origin: center right;
}

.animate.marquee-grey {
  background: linear-gradient(270deg, #f4f4f4 8%, #dedede 16%, #f4f4f4 24%);
  background-size: 200% 100%;
  background-attachment: fixed;
  animation: marquee 2s linear infinite;
  border-radius: 0.3rem;
}

@keyframes waveHand {
  0% {
    transform: rotate(0deg);
  }
  7.5% {
    transform: rotate(3deg);
  }
  15% {
    transform: rotate(0deg);
  }
  22.5% {
    transform: rotate(3deg);
  }
  30% {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(0deg);
  }
}

@keyframes marquee {
  0% {
    background-position: 0 0;
  }
  100% {
    background-position: -200% 0;
  }
}

.button {
  display: inline-block;
  padding: 1rem 1.8rem;
  background-color: transparent;
  border: 0.1rem solid transparent;
  text-align: center;
  cursor: pointer;
  position: relative;
  border-radius: 0.3rem;
  transition: background-color 200ms ease-in-out, color 200ms ease-in-out, border-color 200ms ease-in-out;
  user-select: none;
}

.button:hover, .button:active, .button:focus {
  text-decoration: none;
  outline: none;
}

.button:active {
  box-shadow: 0 0.3rem 1rem -0.3rem rgba(0, 0, 0, 0.3) inset;
}

.button[disabled] {
  cursor: not-allowed;
}

.button.theme {
  background-color: #2e3a52;
  color: #ffffff;
  border-color: #252e42;
}

.button.theme:hover {
  background-color: #1f2838;
  border-color: #181e2b;
}

.button.theme.ghost {
  border-color: #2e3a52;
  color: #2e3a52;
}

.button.theme-alt {
  background-color: #005c98;
  color: #ffffff;
  border-color: #347389;
}

.button.theme-alt:hover {
  background-color: #306a7e;
  border-color: #2a5e70;
}

.button.theme-alt.ghost {
  border-color: #3b839c;
  color: #3b839c;
}

.button.default {
  background-color: #ffffff;
  color: #252525;
  border-color: #d6d6d6;
}

.button.default:hover {
  background-color: #f2f2f2;
  border-color: #cccccc;
}

.button.default.ghost {
  border-color: #ffffff;
  color: #ffffff;
}

.button.default.ghost:hover {
  background-color: #ffffff;
  color: #252525;
}

.button.default.ghost.dark {
  border-color: #252525;
  color: #252525;
}

.button.default.ghost.dark:hover {
  background-color: #252525;
  color: #ffffff;
}

.button.primary {
  background-color: #1ca8dd;
  color: #ffffff;
  border-color: #1993c2;
}

.button.primary:hover {
  background-color: #178cb9;
  border-color: #137499;
}

.button.primary.ghost {
  color: #1ca8dd;
}

.button.success {
  background-color: #1cd496;
  color: #ffffff;
  border-color: #19bd86;
}

.button.success:hover {
  background-color: #17b07d;
  border-color: #159e70;
}

.button.success.ghost {
  color: #1cd496;
}

.button.info {
  background-color: #12dbe2;
  color: #ffffff;
  border-color: #0eadb3;
}

.button.info:hover {
  background-color: #0c9296;
  border-color: #096d71;
}

.button.info.ghost {
  color: #12dbe2;
}

.button.warning {
  background-color: #f9c51f;
  color: #ffffff;
  border-color: #e9b306;
}

.button.warning:hover {
  background-color: #e9b306;
  border-color: #d5a406;
}

.button.warning.ghost {
  color: #f9c51f;
}

.button.danger {
  background-color: #ff5a5f;
  color: #ffffff;
  border-color: #ff272e;
}

.button.danger:hover {
  background-color: #ff0e15;
  border-color: #da0007;
}

.button.danger.ghost {
  color: #ff5a5f;
}

.button.ghost {
  background-color: transparent;
  border-width: 0.2rem;
}

.button.ghost:hover {
  border-color: transparent;
  color: #ffffff;
}

.button.block {
  display: block;
  width: 100%;
}

.button.nowrap {
  white-space: nowrap;
}

.button.huge {
  padding: 2rem 3rem;
  font-size: larger;
}

.button.large {
  padding: 1.5rem 2.5rem;
  font-size: large;
}

.button.small {
  padding: 0.7rem 1.4rem;
  font-size: small;
}

.button.tiny {
  padding: 0.4rem 1rem;
  font-size: smaller;
}

.button.action {
  padding-top: 1.25rem;
  padding-bottom: 1.25rem;
  border: 0;
  background: linear-gradient(to bottom, #ff5122 0%, #f4b124 100%);
  color: #ffffff;
}

.button.round {
  border-radius: 5rem;
}

.button.icon {
  padding-left: 5.8rem;
  text-align: left;
}

.button.icon > .fa,
.button.icon > .ion-icon {
  width: 4rem;
  height: 100%;
  border-right-width: 0.1rem;
  border-right-style: solid;
  border-right-color: inherit;
  position: absolute;
  top: 0;
  left: 0;
}

.button.icon > .fa:before,
.button.icon > .ion-icon:before {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.button.icon.right {
  padding-left: 1.8rem;
  padding-right: 5.8rem;
  text-align: right;
}

.button.icon.right > .fa,
.button.icon.right > .ion-icon {
  border-right: 0;
  border-left-width: 0.1rem;
  border-left-style: solid;
  border-left-color: inherit;
  left: auto;
  right: 0;
}

.button.icon.block.center {
  padding-left: 5rem;
  padding-right: 5rem;
  text-align: center;
}

.button.icon.block.text-left {
  text-align: left;
}

.button.icon.block.text-right {
  text-align: right;
}

.button ~ .button.block {
  margin-top: 0.8rem;
}

.button ~ .button:not(.block) {
  margin-left: 0.3rem;
}

.button.facebook {
  background-color: #3b5998;
  border-color: #2d4373;
  color: #ffffff;
}

.button.twitter {
  background-color: #1da1f2;
  border-color: #0c85d0;
  color: #ffffff;
}

.button.instagram {
  background-color: #405de6;
  border-color: #1c3dd7;
  color: #ffffff;
}

.button.linkedin {
  background-color: #0077b5;
  border-color: #005582;
  color: #ffffff;
}

.button.google {
  background-color: #4285f4;
  border-color: #1266f1;
  color: #ffffff;
}

.button-wall .button {
  margin: 0.3rem;
}

.button-list .button {
  display: block;
  border: 0;
  border-bottom: 0.1rem solid #f7f6f6;
  text-align: left;
  border-radius: 0;
  transition: none;
}

.button-list .button ~ .button {
  margin-left: 0;
}

.button-list .button:last-child {
  border-bottom: 0;
}

.button-list .button:hover {
  border-color: transparent;
}

.button-list .button:active {
  box-shadow: none;
}

.ratings {
  margin-bottom: 0.3rem;
  overflow: hidden;
  vertical-align: middle;
}

.ratings .fill {
  display: block;
  float: left;
  height: 100%;
  background-position: 0 -2.4rem;
  background-size: 2.4rem;
}

.ratings.red .fill {
  background-position: 0 -4.8rem;
}

.ratings.green .fill {
  background-position: 0 -7.2rem;
}

.ratings.blue .fill {
  background-position: 0 -9.6rem;
}

.ratings.dblue .fill {
  background-position: 0 -12rem;
}

.ratings.purple .fill {
  background-position: 0 -14.4rem;
}

.ratings.pink .fill {
  background-position: 0 -16.8rem;
}

.ratings.gold .fill {
  background-position: 0 -19.2rem;
}

.ratings.black .fill {
  background-position: 0 100%;
}

.ratings.star, .ratings.love, .ratings.poo {
  display: inline-block;
  width: 12rem;
  height: 2.4rem;
  background-repeat: repeat-x;
  background-size: 2.4rem;
}

.ratings.star,
.ratings.star .fill {
  background-image: url("../img/rating/star.png");
}

.ratings.love,
.ratings.love .fill {
  background-image: url("../img/rating/love.png");
}

.ratings.poo,
.ratings.poo .fill {
  background-image: url("../img/rating/dabian.png");
}

.ratings.label {
  padding: 0 0.5rem;
  color: #252525;
  font-size: 1.4rem;
  font-weight: normal;
}

.i-form label {
  font-size: small;
  font-weight: 400;
  color: #88898a;
  line-height: 1.5;
}

.i-form label .required {
  color: #ff5a5f;
  font-weight: 700;
  font-size: 0.9em;
}

.i-form label sup {
  top: -0.3em;
}

.i-form .input,
.i-form .file,
.i-form .select {
  width: 100%;
  min-height: 4.5rem;
  margin: 0 0 1.5rem;
  background-color: #ffffff;
  border: 0.1rem solid #dededf;
  border-radius: 0.3rem;
  position: relative;
}

.i-form .input.single,
.i-form .file.single,
.i-form .select.single {
  margin-bottom: 0;
}

.i-form .input label {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0 2rem;
  line-height: 4.3rem;
  opacity: 1;
  position: absolute;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  pointer-events: none;
  transform: translate3d(0, 0, 0);
}

.i-form .input input,
.i-form .input textarea {
  height: 100%;
  min-height: 4.3rem;
  border: 0;
  background: none;
  color: #88898a;
  outline: none;
  appearance: none;
}

.i-form .input input {
  width: 100%;
  padding: 1rem 2rem 0 2rem;
}

.i-form .input textarea {
  width: calc(100% - 3rem);
  margin: 1.4rem 2rem;
  vertical-align: middle;
  resize: vertical;
}

.i-form .input.animate label {
  transition: all 300ms ease;
}

.i-form .input.faded input {
  padding-top: 0;
}

.i-form .input.focused:not(.faded) label {
  opacity: 0.4;
  transform: translate3d(0, -1.4rem, 0);
}

.i-form .input.focused.faded label {
  opacity: 0;
}

.i-form .input.error {
  border-color: #ff5a5f;
}

.i-form .input.error label {
  color: #ff5a5f;
}

.i-form .input[data-toggle="nav"] {
  cursor: pointer;
}

.i-form .input[data-toggle="nav"] > label > .fa,
.i-form .input[data-toggle="nav"] > label > .ion-icon {
  position: absolute;
  top: 50%;
  right: 1rem;
  color: #3b839c;
  transform: translateY(-50%);
}

.i-form .input + .dropdown {
  width: 32rem;
  padding: 1rem;
  background-color: #ffffff;
  position: absolute;
  top: 100%;
  right: 0.5rem;
  z-index: 99;
  opacity: 0;
  visibility: hidden;
  box-shadow: 0 0 0 0.1rem rgba(0, 0, 0, 0.04), 0 0.3rem 1rem -0.2rem rgba(0, 0, 0, 0.1);
}

.i-form .input + .dropdown:before, .i-form .input + .dropdown:after {
  content: '';
  display: block;
  width: 0;
  border-width: 0.7rem;
  border-style: solid;
  position: absolute;
  bottom: 100%;
  right: 1rem;
}

.i-form .input + .dropdown:before {
  border-color: transparent transparent #ffffff;
}

.i-form .input + .dropdown:after {
  border-width: 0.8rem;
  border-color: transparent transparent #c9c9c9;
  right: 0.9rem;
  z-index: -1;
}

.i-form .input + .dropdown.active {
  opacity: 1;
  visibility: visible;
}

.i-form .file label {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0 2rem;
  font-size: small;
  font-weight: 400;
  line-height: 4.3rem;
  background-color: #ffffff;
  color: #88898a;
  opacity: 1;
  position: absolute;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  pointer-events: none;
  transform: translate3d(0, 0, 0);
  transition: opacity 300ms ease-in-out;
}

.i-form .file label:after {
  content: '\f255';
  display: block;
  width: 5rem;
  height: 3rem;
  background-color: #e0e0e0;
  color: #ffffff;
  font-size: 3rem;
  font-family: "Ionicons";
  line-height: 3rem;
  text-align: center;
  position: absolute;
  top: 50%;
  right: 1.5rem;
  border-radius: 0.3rem;
  transform: translateY(-50%);
}

.i-form .file input[type='file'] {
  width: 100%;
  height: 100%;
  min-height: 4.3rem;
  padding: 0.9rem 2rem;
  border: 0;
  background: none;
  color: #88898a;
  font-size: small;
  cursor: pointer;
  outline: none;
  appearance: none;
}

.i-form .file input[type='file']::-webkit-file-upload-button {
  padding: 0.5rem 1.5rem;
  border: 0;
  background-color: #e6e6e6;
  color: #88898a;
  cursor: pointer;
  border-radius: 0.3rem;
  -webkit-appearance: none;
}

.i-form .file.focused label {
  opacity: 0;
}

.i-form .select:after {
  content: '';
  display: block;
  width: 0;
  height: 0;
  border-width: 0.6rem;
  border-style: solid;
  border-color: #3b839c transparent transparent;
  position: absolute;
  top: 1.91667rem;
  right: 1.5rem;
  z-index: 1;
  pointer-events: none;
}

.i-form .select label {
  display: none;
}

.i-form .select select {
  width: 100%;
  height: 100%;
  min-height: 4.3rem;
  padding: 1rem 2rem;
  border: 0;
  background: none;
  color: #88898a;
  font-size: small;
  line-height: 100%;
  outline: none;
  appearance: none;
}

.i-form .select select::-ms-expand {
  display: none;
}

.i-form .select.focused:after {
  border-color: #a7c7d2 transparent transparent;
}

.i-form .checkbox label,
.i-form .radio label {
  padding-left: 3rem;
  font-size: small;
  position: relative;
  user-select: none;
}

.i-form .checkbox label:before,
.i-form .radio label:before {
  content: '';
  display: block;
  width: 2rem;
  height: 2rem;
  background-color: #ffffff;
  border: 0.1rem solid #dededf;
  position: absolute;
  top: 0;
  left: 0;
  transition: background 200ms ease;
}

.i-form .checkbox input,
.i-form .radio input {
  display: none;
  visibility: hidden;
}

.i-form .checkbox input:checked + label:after,
.i-form .radio input:checked + label:after {
  display: block;
  width: 2rem;
  height: 2rem;
  line-height: 2rem;
  text-align: center;
  position: absolute;
  top: 0;
  left: 0;
}

.i-form .checkbox label:before {
  border-radius: 0.3rem;
}

.i-form .checkbox label:hover:before {
  background-color: #f7f7f7;
}

.i-form .checkbox input:checked + label:before {
  background-color: #3b839c;
  border-color: transparent;
}

.i-form .checkbox input:checked + label:after {
  content: '\f122';
  color: #ffffff;
  font-family: "Ionicons";
  font-weight: normal;
  font-style: normal;
}

.i-form .checkbox input:disabled + label {
  cursor: not-allowed;
}

.i-form .checkbox input:disabled + label:before {
  background-color: #ececed;
  border-color: #dededf;
}

.i-form .checkbox.toggle label {
  padding-left: 5.5rem;
}

.i-form .checkbox.toggle label:before {
  width: 4rem;
  border-radius: 5rem;
}

.i-form .checkbox.toggle label:after {
  content: '';
  display: block;
  width: 2rem;
  height: 2rem;
  background-color: #909096;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
  border-radius: 100%;
  transition: margin 200ms ease-in-out;
}

.i-form .checkbox.toggle input:checked + label:before {
  background-color: #ffffff;
  border-color: #dededf;
}

.i-form .checkbox.toggle input:checked + label:after {
  content: '';
  width: 2rem;
  height: 2rem;
  margin-left: 2rem;
  background-color: #3b839c;
}

.i-form .checkbox.toggle input:disabled + label:after {
  background-color: #c5c5c8;
}

.i-form .checkbox.toggle input:disabled:checked + label:after {
  background-color: #79a8b8;
}

.i-form .radio label:before {
  border-radius: 100%;
}

.i-form .radio label:hover:before {
  background-color: #f7f7f7;
}

.i-form .radio input:checked + label:after {
  content: '';
  width: 1rem;
  height: 1rem;
  margin: 0.5rem;
  background-color: #3b839c;
  border-radius: 100%;
}

.i-form .checkboxes,
.i-form .radios {
  width: 100%;
  margin-bottom: 1.5rem;
}

.i-form .checkboxes.single,
.i-form .checkboxes .radio:last-child,
.i-form .radios.single,
.i-form .radios .radio:last-child {
  margin-bottom: 0;
}

.i-form .checkboxes.inline .checkbox {
  display: inline-block;
}

.i-form .checkboxes.inline .checkbox + .checkbox {
  margin-left: 1.5rem;
}

.i-form .radios.inline .radio {
  display: inline-block;
}

.i-form .radios.inline .radio + .radio {
  margin-left: 1.5rem;
}

.i-form .input.range {
  background-color: transparent;
  border: 0;
}

.i-form .input.range label {
  display: inline-block;
  width: auto;
  height: auto;
  line-height: 1;
}

.i-form .input.range label,
.i-form .input.range input[type='range'] {
  padding-left: 0;
  padding-right: 0;
}

.i-form .input.range input[type='range'] {
  min-height: 4.5rem;
  padding-top: 0;
}

.i-form .input.range input[type='range']::-webkit-slider-thumb {
  width: 1.5rem;
  height: 1.5rem;
  margin-top: -0.5rem;
  background-color: #3b839c;
  border-color: transparent;
  cursor: pointer;
  border-radius: 100%;
  box-shadow: 0 0 0 0.3rem #ffffff;
  -webkit-appearance: none;
}

.i-form .input.range input[type='range']::-moz-range-thumb {
  width: 1.5rem;
  height: 1.5rem;
  margin-top: -0.5rem;
  background-color: #3b839c;
  border-color: transparent;
  cursor: pointer;
  border-radius: 100%;
  box-shadow: 0 0 0 0.3rem #ffffff;
  -moz-appearance: none;
}

.i-form .input.range input[type='range']::-ms-thumb {
  width: 1.15rem;
  height: 1.15rem;
  margin-top: 0;
  background-color: #3b839c;
  border-color: transparent;
  cursor: pointer;
  border-radius: 100%;
  box-shadow: 0 0 0 0.3rem #ffffff;
  -ms-appearance: none;
}

.i-form .input.range input[type='range']::-webkit-slider-runnable-track {
  height: 0.5rem;
  background-color: #f2f2f2;
  border-radius: 2rem;
}

.i-form .input.range input[type='range']::-moz-range-track {
  height: 0.5rem;
  background-color: #f2f2f2;
  border-radius: 2rem;
}

.i-form .input.range input[type='range']::-ms-track {
  height: 0.25rem;
  background-color: #f2f2f2;
  border-radius: 2rem;
}

.i-form .input.range input[type='range']::-moz-focusring {
  outline: 1px solid #fff;
  outline-offset: -1;
}

.i-form .input.range input[type='range']::-ms-fill-upper {
  border-radius: 2rem;
}

.i-form .input.range input[type='range']::-ms-fill-lower {
  border-radius: 2rem;
}

.i-form .input.range.indicator > label {
  display: block;
  position: relative;
  overflow: visible;
}

.i-form .input.range.indicator > label:after {
  content: attr(data-prefix) "\a" attr(data-num) "\a" attr(data-suffix);
  float: right;
}

.i-form .helper.inline {
  display: inline-block;
  vertical-align: middle;
}

.i-form .description {
  font-style: italic;
  color: #656565;
}

.i-form .group .input input {
  padding: 0 1.5rem;
}

.i-form .group .input textarea {
  margin-left: 1.5rem;
  margin-right: 1.5rem;
}

.i-form .group .file input {
  padding-left: 1.5rem;
  padding-right: 1.5rem;
}

.i-form .group .select:after {
  right: 1rem;
}

.i-form .group .select select {
  padding: 0 1.5rem;
}

.i-form .form-control:focus {
  box-shadow: none;
}

.i-form.form-horizontal .form-group .input, .i-form.form-horizontal .form-group .select, .i-form.form-horizontal .form-group .file {
  margin-bottom: 0;
}

.i-form.form-horizontal .control-label {
  padding-top: 1.4rem;
  line-height: 1.25;
}

.i-form.form-horizontal.label-left .control-label {
  text-align: left;
}

.i-form .actions .button:not(.action) {
  padding-top: 1.15rem;
  padding-bottom: 1.15rem;
}

.i-menu {
  display: flex;
  max-width: 100%;
  margin: 0;
  background-color: #fff;
  border: 0.1rem solid #e7e7e8;
  overflow-x: auto;
  border-radius: 0.3rem;
}

.i-menu .item {
  padding: 1.3rem 1.5rem;
  line-height: 1;
  text-decoration: none;
  position: relative;
  vertical-align: middle;
  -webkit-tap-highlight-color: transparent;
  -webkit-box-flex: 0;
  flex: 0 0 auto;
  user-select: none;
}

.i-menu .item:before {
  content: '';
  width: 0.1rem;
  height: 100%;
  background-color: #e7e7e7;
  position: absolute;
  top: 0;
  right: 0;
}

.i-menu .item:hover {
  background-color: rgba(46, 58, 82, 0.05);
}

.i-menu .item.active {
  background-color: rgba(46, 58, 82, 0.15);
}

.i-menu .item > .ion-icon,
.i-menu .item > .fa {
  width: 2rem;
  font-size: 1.6rem;
  text-align: center;
}

.i-menu:not(.vertical) .item {
  display: flex;
  align-items: center;
}

.i-menu:not(.vertical) .item.active:after {
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  height: 0.3rem;
  background-color: #2e3a52;
}

.i-menu.vertical {
  display: block;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  flex-direction: column;
}

.i-menu.vertical .item {
  display: block;
  border-top: 0;
}

.i-menu.vertical .item:before {
  width: 100%;
  height: 0.1rem;
  left: 0;
}

.i-menu.vertical .item:after {
  content: '\f3d3';
  display: block;
  float: right;
  margin-left: 0.5rem;
  font-family: "Ionicons";
  font-weight: 400;
  text-transform: none;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  zoom: 1;
}

.i-menu.vertical .item:first-child:before {
  display: none;
}

.i-menu.phat .item {
  padding: 1.8rem 2rem;
}

.i-list {
  padding-left: 0;
  list-style: none;
}

.i-list > li {
  margin-top: -0.1rem;
  padding: 1rem;
  border: 0.1rem solid #f7f6f6;
  position: relative;
}

.i-list > li:before, .i-list > li:after {
  content: '';
  display: table;
}

.i-list > li:after {
  clear: both;
}

.i-list-label {
  color: #b1b1b1;
}

.i-list-data {
  color: #2e3a52;
  font-weight: 500;
}

.i-list-data > span {
  font-weight: normal;
}

.i-list-icon {
  float: left;
  margin-left: 0.4rem;
  color: #3b839c;
  line-height: 1;
}

.i-list-icon .material-icons,
.i-list-icon .fa,
.i-list-icon .ion-icon {
  display: inline-block;
  width: 1em;
  margin-top: -0.06em;
  font-size: 1.5em;
  text-align: center;
}

.i-list-icon + .i-list-data {
  margin-left: 3.8rem;
}

.i-list.double {
  margin-left: -0.3rem;
  margin-right: -0.3rem;
}

.i-list.double:before, .i-list.double:after {
  content: '';
  display: table;
}

.i-list.double:after {
  clear: both;
}

.i-list.double > li {
  float: left;
  width: calc(50% - 0.6rem);
  margin: 0 0.3rem 0.6rem;
}

.i-list.clean > li {
  padding-left: 0;
  padding-right: 0;
  border-left: 0;
  border-right: 0;
}

.i-list.clean .i-list-label:before {
  display: none;
}

.i-list.landscape > li {
  display: inline-block;
  text-align: center;
  width: calc(25% - 3px);
  vertical-align: top;
  margin-bottom: 0.4rem;
}

.i-list.landscape .i-list-label {
  float: none;
  padding: 0;
  width: 100%;
}

.i-list.landscape .i-list-label:before {
  display: none;
}

.i-list.landscape .i-list-label + .i-list-data {
  margin-left: 0;
  padding: 0;
}

@media (max-width: 767px) {
  .i-list.landscape > li {
    width: calc(50% - 2px);
  }
}

@media (max-width: 479px) {
  .i-list.landscape > li {
    width: 100%;
  }
}

@media (min-width: 767px) {
  .i-list-label {
    float: left;
    width: 20rem;
    padding-right: 1rem;
  }
  .i-list-label:before {
    content: '';
    border-right: 0.1rem solid #f7f6f6;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 20.9rem;
  }
  .i-list-label + .i-list-data {
    margin-left: 20rem;
    padding-left: 1rem;
  }
}

.i-carousel {
  padding-left: 2rem;
  padding-right: 2rem;
}

.i-carousel .item {
  position: relative;
}

.i-carousel .item > .caption {
  width: 100%;
  padding: 1.5rem;
  background-color: rgba(37, 37, 37, 0.65);
  color: #ffffff;
  position: absolute;
  bottom: 0;
  left: 0;
}

.i-carousel .i-card {
  border: 0.1rem solid #ddd;
  box-shadow: none;
}

.i-carousel .i-card > .image {
  transform: translate3d(0, 0, 0);
}

.i-carousel .i-card:hover {
  box-shadow: none;
}

.i-carousel .owl-nav i {
  display: block;
}

.i-carousel .owl-nav .owl-prev,
.i-carousel .owl-nav .owl-next {
  width: 2rem;
  height: 3.8rem;
  line-height: 3.8rem;
  font-size: 2.8rem;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}

.i-carousel .owl-nav .owl-prev {
  left: 0;
}

.i-carousel .owl-nav .owl-next {
  right: 0;
  text-align: right;
}

.i-carousel .owl-nav .disabled {
  opacity: 0;
  visibility: hidden;
}

.i-carousel.nise {
  padding-left: 0;
  padding-right: 0;
  overflow-x: auto;
  overflow-y: hidden;
  white-space: nowrap;
}

.i-carousel.nise::-webkit-scrollbar {
  display: none;
}

.i-carousel.nise > .i-card {
  display: inline-block;
  max-width: 46.5%;
  margin-bottom: 0.1rem;
  margin-left: 0.2rem;
  touch-action: manipulation;
}

.i-carousel.nise > .i-card:first-child {
  margin-left: 0;
}

.i-pagination {
  display: inline-flex;
  max-width: 100%;
  margin: 2rem 0;
  padding: 0;
  background-color: #fff;
  list-style: none;
  overflow-x: auto;
  box-shadow: 0 0 0 0.1rem #f7f6f6;
  border-radius: 0.3rem;
}

.i-pagination li {
  position: relative;
}

.i-pagination li:after {
  display: block;
  width: 0.1rem;
  height: 100%;
  background-color: #f7f6f6;
  position: absolute;
  top: 0;
  right: 0;
  z-index: 1;
}

.i-pagination li:last-child:after {
  display: none;
}

.i-pagination li a {
  display: block;
  padding: 0.8rem 1.6rem;
  color: #3b839c;
}

.i-pagination li a:focus, .i-pagination li a:hover {
  text-decoration: none;
}

.i-pagination li a:hover {
  background-color: rgba(59, 131, 156, 0.1);
}

.i-pagination li.active a {
  background-color: rgba(59, 131, 156, 0.85);
  color: #ffffff;
}

.i-pagination li.disabled a {
  color: #dedede;
  cursor: not-allowed;
  background-color: #ffffff;
}

.i-pagination.lite {
  background-color: transparent;
  box-shadow: none;
  border-radius: 0;
}

.i-pagination.lite li {
  margin-left: 0.5rem;
  margin-right: 0.5rem;
}

.i-pagination.lite li a {
  padding: 0.5rem;
  color: #a5a5a5;
  text-transform: uppercase;
}

.i-pagination.lite li a:hover {
  background-color: transparent;
  color: #3b839c;
}

.i-pagination.lite li:first-child {
  margin-left: 0;
}

.i-pagination.lite li:first-child a {
  padding-left: 0;
}

.i-pagination.lite li:last-child {
  margin-right: 0;
}

.i-pagination.lite li:last-child a {
  padding-right: 0;
}

.i-pagination.lite li.active a {
  background-color: transparent;
  color: #3b839c;
}

.i-pagination.lite li.disabled a {
  background-color: transparent;
  color: #c9c9c9;
}

.i-scrollbar {
  height: 32rem;
}

.i-table {
  margin-bottom: 0;
  border: 0.1rem solid #f7f6f6;
}

.i-table th,
.i-table td {
  padding: 1rem;
}

.i-table > thead > tr > th {
  background-color: #fafafa;
  color: #2e3a52;
  border-bottom: 0;
  font-weight: 600;
}

.i-table > tbody > tr > td {
  border-top-color: #f7f6f6;
}

.i-table > tbody > tr:nth-child(odd) > td {
  background-color: #ffffff;
}

.i-table > tbody > tr:nth-child(even) > td {
  background-color: #fffffa;
}

.i-tabs {
  margin-bottom: 1rem;
  background-color: #fafafa;
  border: 0.1rem solid #f7f6f6;
  border-radius: 0.4rem;
}

.i-tabs > li > a {
  margin-right: 0;
  padding-top: 1.2em;
  padding-bottom: 1.2em;
  border-radius: 0;
}

.i-tabs > li > a:focus, .i-tabs > li > a:hover {
  background-color: transparent;
  border-color: transparent;
  color: #3b839c;
}

.i-tabs > li.active > a,
.i-tabs > li.active > a:focus,
.i-tabs > li.active > a:hover {
  border-top: 0;
  border-left-color: #f7f6f6;
  border-right-color: #f7f6f6;
  color: #3b839c;
}

.i-tabs > li.active:first-child > a {
  border-left-color: transparent;
}

.i-tabs > li.active:last-child > a {
  border-right-color: transparent;
}

.i-tabs > li.dropdown .dropdown-menu {
  border-radius: 0.3rem;
}

.i-tabs > li.dropdown.open > a {
  border-color: transparent;
  background-color: #f4f4f4;
}

.i-tabs.nav-justified {
  border-bottom: 0.1rem solid #f7f6f6;
  border-radius: 0.4rem;
}

.i-tabs.nav-justified > li {
  vertical-align: bottom;
}

.i-tabs.nav-justified > li > a {
  margin-bottom: 0;
  border-bottom: 0;
  border-radius: 0;
  white-space: nowrap;
}

.i-tabs.nav-justified > li.active > a,
.i-tabs.nav-justified > li.active > a:focus,
.i-tabs.nav-justified > li.active > a:hover {
  border-top: 0;
  border-left-color: #f7f6f6;
  border-right-color: #f7f6f6;
  color: #3b839c;
}

.i-tabs.nav-justified > li.active:first-child > a {
  border-left-color: transparent;
  border-radius: 0.4rem 0 0 0.4rem;
}

.i-tabs.nav-justified > li.active:last-child > a {
  border-right-color: transparent;
  border-radius: 0 0.4rem 0.4rem 0;
}

.i-tabs.nestle, .i-tabs[class*='m-b-0'] {
  margin-bottom: -0.1rem;
  border-bottom: 0;
  border-radius: 0.4rem 0.4rem 0 0;
}

.i-tabs.nestle li.active:first-child > a, .i-tabs[class*='m-b-0'] li.active:first-child > a {
  border-radius: 0.4rem 0 0 0;
}

.i-tabs.nestle li.active:last-child > a, .i-tabs[class*='m-b-0'] li.active:last-child > a {
  border-radius: 0 0.4rem 0 0;
}

.i-tabs ~ .tab-content .tab-pane {
  padding: 1.5rem;
  border: 0.1rem solid #f7f6f6;
}

@media (max-width: 767px) {
  .i-tabs.nav-justified > li {
    float: left;
  }
  .i-tabs.nav-justified > li.active > a,
  .i-tabs.nav-justified > li.active > a:focus,
  .i-tabs.nav-justified > li.active > a:hover {
    border-bottom-color: transparent;
  }
}

.i-price {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  width: 100%;
  margin: 0 auto 2em;
}

.i-price .pricing-item {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  text-align: center;
  flex: 0 1 24rem;
}

.i-price .pricing-action {
  color: inherit;
  border: none;
  background: none;
}

.i-price .pricing-action:focus {
  outline: none;
}

.i-price.theme .pricing-item {
  margin: 1em -0.5em;
}

.i-price.theme .pricing-deco {
  padding: 2em 0 6em;
  background-color: #2e3a52;
  color: #ffffff;
  border-radius: 0.6em 0.6em 0 0;
  position: relative;
}

.i-price.theme .pricing-list .list-item {
  padding-top: 0.6em;
  padding-bottom: 0.6em;
}

.area-outlook-detail .transaction-table,
.area-outlook-detail .listing-datatable,
.area-outlook-detail .listing-table,
.area-outlook-detail .watable,
.area-outlook-detail .i-table,
.page-property-detail .transaction-table,
.page-property-detail .listing-datatable,
.page-property-detail .listing-table,
.page-property-detail .watable,
.page-property-detail .i-table,
.page-project-outlook .transaction-table,
.page-project-outlook .listing-datatable,
.page-project-outlook .listing-table,
.page-project-outlook .watable,
.page-project-outlook .i-table {
  font-size: 1.2rem;
}

.area-outlook-detail .transaction-table .radios.inline .radio label,
.area-outlook-detail .listing-datatable .radios.inline .radio label,
.area-outlook-detail .listing-table .radios.inline .radio label,
.area-outlook-detail .watable .radios.inline .radio label,
.area-outlook-detail .i-table .radios.inline .radio label,
.page-property-detail .transaction-table .radios.inline .radio label,
.page-property-detail .listing-datatable .radios.inline .radio label,
.page-property-detail .listing-table .radios.inline .radio label,
.page-property-detail .watable .radios.inline .radio label,
.page-property-detail .i-table .radios.inline .radio label,
.page-project-outlook .transaction-table .radios.inline .radio label,
.page-project-outlook .listing-datatable .radios.inline .radio label,
.page-project-outlook .listing-table .radios.inline .radio label,
.page-project-outlook .watable .radios.inline .radio label,
.page-project-outlook .i-table .radios.inline .radio label {
  font-weight: 400;
  text-transform: capitalize;
}

.area-outlook-detail .transaction-table th,
.area-outlook-detail .listing-datatable th,
.area-outlook-detail .listing-table th,
.area-outlook-detail .watable th,
.area-outlook-detail .i-table th,
.page-property-detail .transaction-table th,
.page-property-detail .listing-datatable th,
.page-property-detail .listing-table th,
.page-property-detail .watable th,
.page-property-detail .i-table th,
.page-project-outlook .transaction-table th,
.page-project-outlook .listing-datatable th,
.page-project-outlook .listing-table th,
.page-project-outlook .watable th,
.page-project-outlook .i-table th {
  font-weight: 600;
}

.area-outlook-detail .listing-datatable .text-small,
.page-property-detail .listing-datatable .text-small,
.page-project-outlook .listing-datatable .text-small {
  font-size: 1.2rem;
}

.area-outlook-detail .listing-datatable .listing-past-trx h3.block-title,
.page-property-detail .listing-datatable .listing-past-trx h3.block-title,
.page-project-outlook .listing-datatable .listing-past-trx h3.block-title {
  font-size: 1.3rem;
  font-weight: 500;
}

.area-outlook-detail .listing-datatable .map-list-card .cp-card .card-info > div:first-of-type,
.page-property-detail .listing-datatable .map-list-card .cp-card .card-info > div:first-of-type,
.page-project-outlook .listing-datatable .map-list-card .cp-card .card-info > div:first-of-type {
  font-size: 13px !important;
}

.area-outlook-detail .transaction-table.project-trx .transaction-group .transaction-row:nth-child(even),
.page-property-detail .transaction-table.project-trx .transaction-group .transaction-row:nth-child(even),
.page-project-outlook .transaction-table.project-trx .transaction-group .transaction-row:nth-child(even) {
  background: #fafafa;
}

.area-outlook-detail .transaction-table .transaction-date,
.page-property-detail .transaction-table .transaction-date,
.page-project-outlook .transaction-table .transaction-date {
  font-weight: 400;
}

.area-outlook-detail .transaction-table .transaction-date.head-weight,
.page-property-detail .transaction-table .transaction-date.head-weight,
.page-project-outlook .transaction-table .transaction-date.head-weight {
  font-weight: 600;
}

.image {
  position: relative;
  display: inline-block;
  vertical-align: middle;
  max-width: 100%;
  background-color: transparent;
}

.image img {
  display: block;
  max-width: 100%;
  height: auto;
}

.image.tiny {
  width: 6.5rem;
}

.image.small {
  width: 9rem;
}

.image.medium {
  width: 12rem;
}

.image.big {
  width: 16.5rem;
}

.image.large {
  width: 18rem;
}

.image.xlarge {
  width: 20rem;
}

.image.xxlarge {
  width: 24rem;
}

.image.rounded img {
  border-radius: 0.4rem;
}

.image.circle img {
  border-radius: 100%;
}

.image.frame {
  border-width: 0.3rem;
  border-style: solid;
  border-color: #ffffff;
}

.image.carousel {
  min-width: 12rem;
}

.image.carousel .owl-nav i {
  display: block;
}

.image.carousel .owl-nav .owl-prev,
.image.carousel .owl-nav .owl-next {
  width: 3rem;
  height: 3.8rem;
  line-height: 3.8rem;
  color: #ffffff;
  text-shadow: 0 0.1rem 0 rgba(0, 0, 0, 0.6);
  font-size: 2.8rem;
  text-align: center;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}

.image.carousel .owl-nav .owl-prev {
  left: 0;
}

.image.carousel .owl-nav .owl-next {
  right: 0;
}

.image.carousel .owl-nav .disabled {
  opacity: 0.1;
}

.image .indicator {
  line-height: 1;
  position: absolute;
  z-index: 2;
}

.image .indicator.top {
  top: 0;
}

.image .indicator.bottom {
  bottom: 0;
}

.image .indicator.left {
  left: 0;
}

.image .indicator.right {
  right: 0;
}

.image .indicator.center {
  left: 50%;
  right: auto;
  transform: translate(-50%, 0);
}

.image .indicator.middle {
  top: 50%;
  bottom: auto;
  transform: translate(0, -50%);
}

.image .indicator.middle.center {
  left: 50%;
  transform: translate(-50%, -50%);
}

.image .indicator.white {
  color: #ffffff;
}

.image > .wrapper {
  position: relative;
}

.module {
  padding-top: 8rem;
  padding-bottom: 8rem;
  position: relative;
}

.module.odd {
  background-color: #ffffff;
}

.module.even {
  background-color: #f7f6f6;
}

.module-full {
  padding-top: 0;
  padding-bottom: 0;
}

.module-title {
  margin-top: 0;
  margin-bottom: 1em;
}

.module-title:after {
  content: '';
  display: block;
  width: 3em;
  height: 0.1em;
  margin-top: 0.5em;
  background-color: #2e3a52;
}

.module-title .module-subtitle {
  letter-spacing: 0.03em;
  font-size: 0.6em;
}

.module-subtitle {
  display: block;
  margin-top: 0.4em;
  margin-bottom: 1.5em;
  font-family: "Arial", sans-serif;
  color: #9d9d9d;
}

.module-title.center, .module-subtitle.center {
  text-align: center;
}

.module-title.center:after, .module-subtitle.center:after {
  margin-left: auto;
  margin-right: auto;
}

.module-title.right, .module-subtitle.right {
  text-align: right;
}

.module-title.right:after, .module-subtitle.right:after {
  margin-left: auto;
}

.module-bg {
  background-color: #f7f6f6;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: -1;
}

.module-border-top {
  border-top: 0.1rem solid #ebe9e9;
}

.module-border-bottom {
  border-bottom: 0.1rem solid #ebe9e9;
}

.i-row {
  margin-left: -0.8rem;
  margin-right: -0.8rem;
}

.i-row > .i-col {
  padding-left: 0.8rem;
  padding-right: 0.8rem;
}

.i-row > .i-col.sidebar.left {
  padding-right: 1.6rem;
}

.i-row > .i-col.sidebar.right {
  padding-left: 1.6rem;
}

.i-row > .i-col > .input,
.i-row > .i-col > .select {
  margin-bottom: 0;
}

.i-row.narrow {
  margin-left: -0.4rem;
  margin-right: -0.4rem;
}

.i-row.narrow > .i-col {
  padding-left: 0.4rem;
  padding-right: 0.4rem;
}

.i-row.zero {
  margin-left: 0;
  margin-right: 0;
}

.i-row.zero > .i-col {
  padding-left: 0;
  padding-right: 0;
}

@media (max-width: 479px) {
  .i-row:not(.reset) > .i-col {
    float: none;
    width: auto;
    margin-left: auto;
    margin-right: auto;
  }
}

.i-block {
  position: relative;
}

.i-block + .i-block:not([class*="m-t"]) {
  margin-top: 2.5rem;
}

.i-block .block-title, .i-block .area-outlook-detail h3.block-title, .area-outlook-detail .i-block h3.block-title,
.i-block .page-project-outlook h3.block-title, .page-project-outlook .i-block h3.block-title {
  margin-top: 0;
  margin-bottom: 1.8rem;
  color: #2e3a52;
  font-size: 2rem;
  font-weight: 600;
}

.i-block .block-content {
  position: relative;
}

.i-block.frame {
  padding: 1.5rem;
  border: 0.1rem solid #f7f6f6;
}

body.front .i-block .block-title {
  margin-bottom: 0;
  font-size: 2.2rem;
}

.i-card {
  background-color: #fff;
  border: 0.1rem solid #dedede;
  border-radius: 0.4rem;
  transition: box-shadow 300ms ease-in-out;
}

.i-card > .image {
  display: block;
  padding: 0;
  background-color: rgba(0, 0, 0, 0.05);
  position: relative;
}

.i-card > .image img {
  display: block;
  width: 100%;
  height: auto;
  border: 0;
  border-radius: inherit;
}

.i-card > .image:first-child {
  border-top-right-radius: inherit;
  border-top-left-radius: inherit;
}

.i-card > .content {
  flex-grow: 1;
  padding: 1.5rem;
}

.i-card > .content > .title {
  margin-top: -0.42rem;
  margin-bottom: 0.42rem;
  line-height: 1.2;
}

.i-card > .content > .meta {
  color: rgba(0, 0, 0, 0.4);
}

.i-card > .content > .meta a {
  color: rgba(0, 0, 0, 0.6);
}

.i-card > .content > .meta a:hover {
  color: rgba(0, 0, 0, 0.8);
}

.i-card > .content > .description {
  margin-top: 0.8rem;
  color: rgba(0, 0, 0, 0.68);
}

.i-card > .content > .description :last-child {
  margin-bottom: 0;
}

.i-card > .buttons {
  border-top: 0.1rem solid rgba(0, 0, 0, 0.08);
}

.i-card > .buttons:before, .i-card > .buttons:after {
  content: '';
  display: table;
}

.i-card > .buttons:after {
  clear: both;
}

.i-card > .buttons .button {
  display: block;
  float: left;
  width: 100%;
  margin: 0;
  padding: 1.4rem 1rem;
  border: 0;
  text-align: center;
  border-radius: 0 0 0.4rem 0.4rem;
}

.i-card > .buttons.double .button {
  width: 50%;
}

.i-card > .buttons.triple .button {
  width: 33.33334%;
}

.i-card > .buttons.quadruple .button {
  width: 25%;
}

.i-card > .buttons.double .button, .i-card > .buttons.triple .button, .i-card > .buttons.quadruple .button {
  border-radius: 0;
}

.i-card > .buttons.double .button:first-child, .i-card > .buttons.triple .button:first-child, .i-card > .buttons.quadruple .button:first-child {
  border-radius: 0 0 0 0.4rem;
}

.i-card > .buttons.double .button:last-child, .i-card > .buttons.triple .button:last-child, .i-card > .buttons.quadruple .button:last-child {
  border-radius: 0 0 0.4rem 0;
}

.i-card > .extra {
  padding: 1rem 1.5rem;
  border-top: 0.1rem solid rgba(0, 0, 0, 0.08);
}

.i-card:hover {
  box-shadow: 0 0.3rem 1rem -0.1rem rgba(0, 0, 0, 0.15);
}

.i-card.clean {
  background-color: transparent;
  border: 0;
  border-radius: 0;
  box-shadow: none;
  transition: none;
}

.i-card.clean > .content {
  padding-left: 0;
  padding-right: 0;
}

.i-card.clean > .extra {
  padding-top: 0;
  padding-left: 0;
  padding-right: 0;
  border-top: none;
}

.i-card.flat {
  border: 0;
  border-radius: 0;
  box-shadow: none;
  transition: none;
}

.i-card.price {
  text-align: center;
}

.i-card.price > .image {
  padding: 1.5rem 0;
  background: linear-gradient(140deg, #0fb8ad 0%, #1fc8db 51%, #2cb5e8 75%);
}

.i-card.price > .content ul {
  margin-bottom: 0;
  padding-left: 0;
  list-style: none;
}

.i-card.price > .content ul li {
  padding-top: 0.8rem;
  padding-bottom: 0.8rem;
}

.i-card.price > .content ul li + li {
  border-top: 0.1rem solid #f7f6f6;
}

.i-card.price > .content ul li.text-strike {
  color: #c9c9c9;
}

.i-card.price .package .name {
  font-size: 2rem;
  font-weight: 600;
  color: #ffffff;
}

.i-card.price .package .price {
  font-size: 2.8rem;
}

.i-card.price .package .unit {
  font-size: 1.2rem;
}

.i-card[role='button'] {
  display: block;
  text-decoration: none;
}

.i-items {
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;
}

.i-items:first-child {
  margin-top: 0;
}

.i-items:last-child {
  margin-bottom: 0;
}

.i-items > .item {
  display: flex;
  width: 100%;
  min-height: 0;
  margin: 1rem 0;
  padding: 0;
}

.i-items > .item:first-child {
  margin-top: 0;
}

.i-items > .item:last-child {
  margin-bottom: 0;
}

.i-items > .item > .image {
  display: block;
  float: none;
  position: relative;
  flex: 0 0 auto;
  align-self: top;
}

.i-items > .item > .image > img {
  width: 100%;
}

.i-items > .item > .image + .content {
  display: block;
  width: auto;
  min-width: 0;
  margin-left: 0;
  padding-left: 1.5rem;
  align-self: top;
}

.i-items > .item > .content {
  display: block;
  flex: 1 1 auto;
  line-height: 1.16;
}

.i-items > .item > .content > .title {
  display: inline-block;
  margin: 0;
}

.i-items > .item > .content > .meta {
  margin: 0.3rem 0 0.5rem;
  font-size: small;
  color: #a5a5a5;
}

.i-items > .item > .content > .description {
  margin-top: 0.5rem;
}

.i-items > .item > .content > .extra {
  font-size: small;
}

.i-items > .item > .content.full {
  width: 100%;
}

.i-items > .item > .content.middle {
  align-self: center;
}

.i-items > .item > .content.bottom {
  align-self: flex-end;
}

.i-items.divided > .item {
  margin: 0;
  padding: 1rem 0;
  border-top: 0.1rem solid rgba(37, 37, 37, 0.1);
}

.i-items.divided > .item:first-child {
  margin-top: 0;
  padding-top: 0;
  border-top: 0;
}

.i-items.divided > .item:last-child {
  margin-bottom: 0;
  padding-bottom: 0;
}

.i-items.divided.boxes {
  margin-top: 0;
  margin-bottom: 0;
}

.i-items.divided.boxes > .item {
  padding: 1.5rem;
}

.i-segment {
  background-color: #fff;
  border: none;
  border-radius: 0.4rem;
  box-shadow: 0 0 0 0.1rem rgba(37, 37, 37, 0.15);
}

.i-segment > .head {
  margin: 0;
  padding: 1rem 1.5rem;
  border-bottom: 0.1rem solid rgba(0, 0, 0, 0.1);
  background-color: #f8f9fb;
  font-size: 1.8rem;
  font-weight: 500;
  border-radius: 0.4rem 0.4rem 0 0;
}

.i-segment > .head > .close {
  display: block;
  width: 4.5rem;
  height: 4.5rem;
  margin-top: -1rem;
  margin-right: -1.5rem;
  background-color: transparent;
  font-size: 3rem;
  line-height: 4.5rem;
  text-align: center;
  text-shadow: none;
  opacity: 0.4;
  border-radius: 0 0.4rem 0 0;
}

.i-segment > .head > .close:hover {
  opacity: 0.8;
  background-color: transparent;
}

.i-segment > .head + .extra {
  margin-top: -0.1rem;
  border-top: 0;
}

.i-segment > .extra {
  background-color: #f8f9fb;
  border-top: 0.1rem solid rgba(37, 37, 37, 0.1);
}

.i-segment > .extra > .nav {
  padding: 1rem;
}

.i-segment > .extra > .nav-tabs {
  padding-bottom: 0;
}

.i-segment > .extra + .foot {
  border-top: 0;
}

.i-segment > .body {
  padding: 1.5rem;
}

.i-segment > .body > header {
  margin-bottom: 4.8rem;
}

.i-segment > .body.reset {
  padding: 0;
}

.i-segment > .body + .extra {
  margin-bottom: -0.1rem;
}

.i-segment > .body + .extra > .nav {
  padding: 1rem;
}

.i-segment > .body + .extra > .nav-tabs {
  padding-top: 0;
}

.i-segment > .body + .extra > .nav-tabs > li {
  margin-top: -0.1rem;
  margin-bottom: 0;
}

.i-segment > .body + .extra > .nav-tabs > li > a {
  border-radius: 0 0 0.4rem 0.4rem;
}

.i-segment > .body + .extra > .nav-tabs > li > a:hover {
  border-color: #ddd #eee #eee;
}

.i-segment > .body + .extra > .nav-tabs > li.active > a {
  border-top-color: transparent;
  border-bottom-color: #ddd;
}

.i-segment > .body + .extra:last-child > .nav-tabs {
  margin-bottom: 0;
  border-bottom: 0;
}

.i-segment > .foot {
  padding: 1rem 1.5rem;
  background-color: #f8f9fb;
  border-top: 0.1rem solid rgba(37, 37, 37, 0.1);
  font-size: 90%;
  color: rgba(37, 37, 37, 0.45);
  border-radius: 0 0 0.4rem 0.4rem;
}

.i-segment > .thumb {
  position: relative;
}

.i-segment > .thumb > img {
  width: 100%;
  height: auto;
  transition: opacity 450ms ease-in-out;
}

.i-segment > .thumb:first-child img {
  border-radius: 0.4rem 0.4rem 0 0;
}

.i-segment > .thumb .actions {
  position: absolute;
  z-index: 1;
  opacity: 0;
  visibility: hidden;
  transition: all 300ms ease-in-out;
}

.i-segment > .thumb.middle .actions {
  top: 50%;
  left: 50%;
  transform: translate3d(-50%, -50%, 0);
}

.i-segment > .thumb.center .actions {
  left: 50%;
  transform: translate3d(-50%, 0, 0);
}

.i-segment > .thumb.top .actions {
  top: 1rem;
}

.i-segment > .thumb.bottom .actions {
  bottom: 1rem;
}

.i-segment > .thumb.left .actions {
  left: 1rem;
}

.i-segment > .thumb.right .actions {
  right: 1rem;
}

.i-segment > .thumb:hover > img {
  opacity: 0.25;
}

.i-segment > .thumb:hover .actions {
  opacity: 1;
  visibility: visible;
}

.i-segment + .i-segment {
  margin-top: 1.5rem;
}

.i-segment.clean > .head {
  margin-bottom: -0.5rem;
  padding-bottom: 0.5rem;
  background-color: transparent;
  border-bottom: 0;
}

.i-segment.clean > .extra {
  background-color: transparent;
}

.i-segment.clean > .foot {
  padding-top: 0.5rem;
  background-color: transparent;
  border-top: 0;
}

.i-segment.clean .more-link {
  background-color: transparent;
}

.i-segment.phat > .head {
  padding: 1.25rem 2rem;
}

.i-segment.phat > .body {
  padding: 2rem;
}

.i-segment.phat > .foot {
  padding: 1.25rem 2rem;
}

.i-segment.bar > .head,
.i-segment.bar > .body,
.i-segment.bar > .foot {
  padding: 0.5rem;
}

.i-segment.bar > .head {
  font-size: 1.4rem;
}

.i-segment.bar > .foot {
  font-size: 1.2rem;
}

.i-segment.shadow {
  box-shadow: 0 0.2rem 1rem -0.3rem rgba(0, 0, 0, 0.3), 0 0 0 0.1rem #e1e1ea;
}

.i-segment.shadow.shadow-large {
  box-shadow: 0 0.5rem 2rem -0.5rem rgba(0, 0, 0, 0.3), 0 0 0 0.1rem #e1e1ea;
}

.i-segment.theme .nav-tabs {
  border-bottom: 0;
}

.i-segment.theme .nav-tabs > li > a {
  border-color: transparent;
  color: #ffffff;
}

.i-segment.theme .nav-tabs > li > a:hover, .i-segment.theme .nav-tabs > li > a:focus {
  background-color: rgba(255, 255, 255, 0.3);
}

.i-segment.theme .nav-tabs > li.active > a {
  background-color: #ffffff;
  color: #252525;
}

.i-segment.theme.default {
  box-shadow: 0 0 0 0.1rem #2e3a52;
}

.i-segment.theme.default > .head,
.i-segment.theme.default > .head + .extra {
  background-color: #2e3a52;
  color: #ffffff;
}

.i-segment.theme.primary {
  box-shadow: 0 0 0 0.1rem #1ca8dd;
}

.i-segment.theme.primary > .head,
.i-segment.theme.primary > .head + .extra {
  background-color: #1ca8dd;
  color: #ffffff;
}

.i-segment.theme.success {
  box-shadow: 0 0 0 0.1rem #1cd496;
}

.i-segment.theme.success > .head,
.i-segment.theme.success > .head + .extra {
  background-color: #1cd496;
  color: #ffffff;
}

.i-segment.theme.info {
  box-shadow: 0 0 0 0.1rem #12dbe2;
}

.i-segment.theme.info > .head,
.i-segment.theme.info > .head + .extra {
  background-color: #12dbe2;
  color: #ffffff;
}

.i-segment.theme.warning {
  box-shadow: 0 0 0 0.1rem #f9c51f;
}

.i-segment.theme.warning > .head,
.i-segment.theme.warning > .head + .extra {
  background-color: #f9c51f;
  color: #ffffff;
}

.i-segment.theme.danger {
  box-shadow: 0 0 0 0.1rem #ff5a5f;
}

.i-segment.theme.danger > .head,
.i-segment.theme.danger > .head + .extra {
  background-color: #ff5a5f;
  color: #ffffff;
}

.i-segment > .jumbotron {
  position: relative;
  margin-bottom: 0;
  border-radius: 0;
}

.i-segment > .jumbotron h1, .i-segment > .jumbotron h2, .i-segment > .jumbotron h3 {
  margin-top: 0;
  margin-bottom: 0;
}

.i-segment > .jumbotron h1 {
  font-size: 6.4rem;
}

.i-segment > .jumbotron h2 {
  font-size: 5.2rem;
}

.i-segment > .jumbotron h3 {
  font-size: 4.0rem;
}

.i-segment > .jumbotron .jumbotron-bg {
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 0;
  border-radius: 0.3rem 0.3rem 0 0;
}

@media (max-width: 767px) {
  .i-segment > .jumbotron h1 {
    font-size: 5.12rem;
  }
  .i-segment > .jumbotron h2 {
    font-size: 4.16rem;
  }
  .i-segment > .jumbotron h3 {
    font-size: 3.2rem;
  }
}

@media (min-width: 768px) {
  .i-segment > .jumbotron + .body {
    padding-left: 6rem;
    padding-right: 6rem;
    padding-top: 1.5rem;
    padding-bottom: 3rem;
  }
}

.search-ace {
  letter-spacing: 0.1rem;
}

.search-ace .search-bar {
  background-color: rgba(46, 58, 82, 0.65);
  position: relative;
}

.search-ace .search-bar:after {
  content: "";
  display: block;
  background-image: url("../img/sample/search-ace.jpeg");
  background-position: center center;
  background-size: cover;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: -1;
}

.search-ace .search-bar .ion-icon {
  vertical-align: middle;
}

.search-ace .search-advanced {
  padding-top: 2rem;
  padding-bottom: 2rem;
  display: none;
}

.search-ace .search-advanced.active {
  display: block;
}

.search-ace .search-advanced-link {
  color: #fff;
}

.search-ace .search-advanced .form-group {
  margin-bottom: 1rem;
}

.search-ace .search-advanced .form-group:last-child {
  margin-top: 2rem;
}

.search-ace .search-advanced .form-group > label {
  padding-top: 1.25rem;
  font-weight: 600;
  line-height: 1.6;
  text-align: left;
}

.search-ace .search-advanced .form-group > label .material-icons {
  margin-top: -0.12em;
  margin-right: 0.24em;
  font-size: 1.846em;
}

.search-ace .search-advanced .form-group .input,
.search-ace .search-advanced .form-group .select,
.search-ace .search-advanced .form-group .inline {
  margin-bottom: 0;
}

.search-ace .search-advanced .form-group .inline > .checkbox,
.search-ace .search-advanced .form-group .inline > .radio {
  padding-top: 1.25rem;
  padding-bottom: 1.25rem;
  line-height: 1;
}

.search-ace .i-form label {
  color: inherit;
}

.search-ace .i-form .input,
.search-ace .i-form .select {
  background-color: transparent;
  border: 0.1rem solid #fff;
  border-radius: 0.2rem;
}

.search-ace .i-form .input input,
.search-ace .i-form .input label,
.search-ace .i-form .select select,
.search-ace .i-form .select label,
.search-ace .i-form .input[data-toggle="nav"] .fa {
  color: #fff;
}

.search-ace .i-form .input + .dropdown .select {
  border-color: #dededf;
  color: #88898a;
}

.search-ace .i-form .input + .dropdown .select:after {
  border-top-color: #3b839c;
}

.search-ace .i-form .input + .dropdown .select select {
  color: #88898a;
}

.search-ace .i-form .select:after {
  border-color: #fff transparent transparent;
}

.search-ace .i-form .select option {
  color: #2e3a52;
}

.search-ace .i-form .helper a {
  color: #7c7c7c;
}

.search-ace .i-form .helper .popover {
  color: #252525;
}

.search-ace .i-form .text-center > .control-label {
  text-align: center;
}

.search-ace .i-form .button.theme {
  min-width: 16rem;
  background-color: #3b839c;
  border-color: #3b839c;
  border-radius: 0.2rem;
}

@media (max-width: 479px) {
  .search-ace .search-advanced-link {
    margin-top: 2rem;
    margin-left: 0;
    display: block;
  }
}

@media (max-width: 991px) {
  .search-ace .search-advanced .form-group > label .material-icons {
    font-size: 1.385em;
  }
}

.search-link > .item {
  display: inline-block;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
}

.search-link > .item + .item {
  padding-left: 0;
}

.search-link > .item + .item:before {
  content: ".";
  display: inline-block;
  margin-right: 0.8rem;
  line-height: 1;
  vertical-align: top;
}

.search-link.comma > .item {
  padding-left: 0;
}

.search-link.comma > .item:after {
  content: ",";
  vertical-align: baseline;
}

.search-link.comma > .item:before, .search-link.comma > .item:last-child:after {
  display: none;
}

.search-outlook .search-wrapper {
  padding-top: 12rem;
  padding-bottom: 12rem;
  background-image: linear-gradient(rgba(3, 48, 118, 0.45), rgba(3, 48, 118, 0.45)), url(https://media.edgeprop.my/assets/images/area-outlook-bg.png);
  background-position: center center;
  background-repeat: no-repeat;
}

.search-outlook .search-header h2 {
  margin-top: 0;
  margin-bottom: 0.6em;
  font-size: 2.5rem;
  line-height: 1.2;
  color: #ffffff;
}

.search-outlook .i-form .input {
  background-color: transparent;
  border-color: #ffffff;
}

.search-outlook .i-form .input label,
.search-outlook .i-form .input input {
  color: #ffffff;
}

.search-outlook .i-form .input label .material-icons {
  margin-top: -0.2em;
}

.search-outlook .i-form .twitter-typeahead .tt-menu {
  width: 100%;
}

@media (max-width: 479px) {
  .search-outlook .search-wrapper {
    padding: 6rem 0;
  }
}

@media (min-width: 1600px) {
  .search-outlook .search-header h2 {
    font-size: 3.6rem;
  }
}

.twitter-typeahead {
  width: 100%;
}

.twitter-typeahead .tt-menu {
  width: 42rem;
  background-color: #ffffff;
  position: relative;
  box-shadow: 0 0 0 0.1rem rgba(0, 0, 0, 0.04), 0 0.3rem 1rem -0.2rem rgba(0, 0, 0, 0.1);
}

.twitter-typeahead .tt-menu:before, .twitter-typeahead .tt-menu:after {
  content: "";
  display: block;
  width: 0;
  border-width: 0.7rem;
  border-style: solid;
  border-color: transparent transparent #ffffff;
  position: absolute;
  left: 1.5rem;
  bottom: 100%;
  z-index: 1;
}

.twitter-typeahead .tt-menu:after {
  border-width: 0.8rem;
  border-color: transparent transparent #dedede;
  left: 1.4rem;
  z-index: 0;
}

.twitter-typeahead .tt-head {
  padding: 1rem;
  background-color: #f7f6f6;
  color: #2e3a52;
}

.twitter-typeahead .tt-head h5 {
  margin: 0;
}

.twitter-typeahead .tt-wrap {
  position: relative;
}

.twitter-typeahead .tt-title {
  margin-right: 10rem;
}

.twitter-typeahead .tt-type {
  color: #c0c4cb;
  font-size: smaller;
  font-style: italic;
  text-transform: capitalize;
  position: absolute;
  top: 50%;
  right: 0;
  transform: translateY(-50%);
}

.twitter-typeahead .tt-highlight {
  color: #3b839c;
}

.twitter-typeahead .tt-suggestion {
  padding: 1rem 1.5rem;
  font-size: small;
}

.twitter-typeahead .tt-suggestion:hover, .twitter-typeahead .tt-suggestion.tt-cursor {
  background-color: #3b839c;
  color: #ffffff;
}

.twitter-typeahead .tt-suggestion:hover .tt-icon, .twitter-typeahead .tt-suggestion:hover .tt-type, .twitter-typeahead .tt-suggestion.tt-cursor .tt-icon, .twitter-typeahead .tt-suggestion.tt-cursor .tt-type {
  color: #fff;
}

.twitter-typeahead .tt-suggestion:hover .tt-highlight, .twitter-typeahead .tt-suggestion.tt-cursor .tt-highlight {
  color: white;
}

.twitter-typeahead .empty-message {
  padding: 1.5rem;
  color: #9a9b9d;
  font-size: 0.9em;
  text-align: center;
}

.twitter-typeahead .empty-message .ion-icon {
  display: block;
  margin-bottom: 0.5rem;
  font-size: 3.2em;
  color: #ccc;
}

.twitter-typeahead .empty-message p {
  font-size: 1em;
}

.twitter-typeahead .empty-message p:last-child {
  margin-bottom: 0.5rem;
}

.map-searchbar {
  width: 100%;
  padding: 0.5rem 1.5rem;
  background-color: #f4f4f4;
  box-shadow: 0 0.2rem 0.5rem -0.1rem rgba(37, 37, 37, 0.2);
  position: absolute;
  top: 0;
  left: 0;
  z-index: 2;
}

.map-searchbar .search-form .input,
.map-searchbar .search-form .select {
  min-height: 4rem;
  margin-bottom: 0;
}

.map-searchbar .search-form .input > label,
.map-searchbar .search-form .select > label {
  font-size: inherit;
  line-height: 3.8rem;
}

.map-searchbar .search-form .input input,
.map-searchbar .search-form .select select {
  min-height: 3.8rem;
}

.map-searchbar .search-form .select:after {
  top: 1.7rem;
}

.map-searchbar .search-form .actions .button {
  padding-top: 0.9rem;
  padding-bottom: 0.9rem;
}

.map-searchbar .autoHeader {
  padding: 0.2rem 1rem;
  position: relative;
}

.map-searchbar .autoHeadertext {
  display: inline-block;
  padding-right: 1rem;
  background-color: #ffffff;
  color: #3b93d0;
  font-weight: 700;
  font-size: 1.4rem;
}

.map-searchbar .autoHeaderline {
  width: 100%;
  height: 0.1rem;
  background-color: #ffffff;
  position: absolute;
  top: 1.5rem;
}

.map-search, .map-container, .map-wrapper, .map-gmap {
  height: 100%;
}

.map-search.admin-menu.adminimal-menu {
  padding-top: 10.1rem;
}

.map-search.admin-menu.adminimal-menu:before {
  display: none;
}

.map-container {
  padding-top: 5rem;
  position: relative;
}

.map-container > .block-system {
  height: 100%;
}

.map-wrapper {
  width: 100%;
  position: relative;
}

.map-list {
  width: 38rem;
  height: 100%;
  padding-top: 6.5rem;
  background-color: #ffffff;
  position: absolute;
  left: 0;
  top: 0;
  z-index: 1;
  transform: translate3d(-100%, 0, 0);
  transition: all 200ms ease-in-out;
}

.map-list-toggle {
  position: absolute;
  top: 50%;
  left: 100%;
  z-index: 1;
  transform: translateY(-50%);
}

.map-list-toggle > .btn {
  padding: 1rem 0;
  border: 0;
  background-color: #ffffff;
  border-radius: 0 0.5rem 0.5rem 0;
  box-shadow: 0.1rem 0.1rem 0 0 rgba(37, 37, 37, 0.2);
}

.map-list-toggle > .btn:active {
  background-color: #f2f2f2;
  box-shadow: none;
}

.map-list-header {
  padding: 1rem;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
}

.map-list-header .list-amount {
  margin-top: 1.6rem;
  font-weight: 600;
  line-height: 1;
  text-align: center;
}

.map-list-header .list-amount > span {
  font-weight: normal;
  color: #b1b1b1;
}

.map-list-header .list-button {
  padding-left: 1rem;
  padding-right: 1rem;
}

.map-list-body {
  height: 100%;
}

.map-list-body .listing-items .listing-title {
  font-size: 1.3rem;
  text-transform: capitalize;
}

.map-list-body .listing-items .listing-description {
  font-size: 1.2rem;
}

.map-list-boxes {
  padding: 1rem;
}

.map-list.active {
  transform: translate3d(0, 0, 0);
}

.map-gmap .map-frame {
  width: 100%;
  height: 100%;
  position: relative;
}

.map-gmap .map-frame iframe {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.map-gmap .js-info-bubble-close {
  opacity: 0.5;
  transform: translate3d(0.4rem, -0.4rem, 0) scale(0.5);
}

.map-gmap .js-info-bubble-close:hover {
  opacity: 1;
}

.map-card {
  margin: 0.5rem;
  border: 0;
  box-shadow: 0 0.2rem 0.8rem -0.1rem rgba(0, 0, 0, 0.2), 0 0 0 0.1rem rgba(0, 0, 0, 0.08);
}

.map-card > .content {
  padding: 1rem;
  background-color: #2e3a52;
  color: #ffffff;
  text-align: center;
  border-radius: 0.3rem 0.3rem 0 0;
}

.map-card > .image {
  background-color: transparent;
  border-top: 0.2rem solid #3b839c;
  border-bottom: 0.2rem solid #3b839c;
}

.map-card > .buttons {
  font-size: 1.2rem;
  border-top: 0;
}

.map-card > .buttons > .button {
  cursor: default;
}

.map-card > .buttons > .button:active {
  box-shadow: none;
}

.map-wrapper .outlook-banner-wrapper {
  flex: 0 0 155px;
  padding: 25px 20px 20px;
  background-color: #f4f5f9;
  border-bottom: 0.1rem solid #ddd;
}

@media (max-width: 992px) {
  .map-wrapper .outlook-banner-wrapper {
    display: none;
  }
}

.map-wrapper .outlook-banner {
  height: 155px;
  border: 1px solid #ddd;
  display: flex;
  align-items: center;
  padding-right: 20px;
  background: #fff;
  position: relative;
}

.map-wrapper .outlook-banner:hover {
  text-decoration: none;
}

.map-wrapper .outlook-banner-img {
  padding-top: 5px;
  padding-left: 10px;
  padding-right: 15px;
}

.map-wrapper .outlook-banner-img,
.map-wrapper .outlook-banner-img img {
  height: 100%;
}

.map-wrapper .outlook-banner-tag {
  background: #444444;
  border-radius: 3px 0 0 3px;
  color: #fff;
  display: inline-block;
  height: 120px;
  padding: 0 0 0 15px;
  position: relative;
  margin: 0;
  text-decoration: none;
  transition: color 0.2s;
  display: flex;
  justify-content: center;
}

.map-wrapper .outlook-banner-tag-arrow {
  position: relative;
  top: -1px;
  border: 1px solid #fff;
  border-left-color: #444444;
  flex: 0 0 40px;
}

.map-wrapper .outlook-banner-tag-arrow:after {
  background: #fff;
  border-bottom: 60px solid transparent;
  border-left: 40px solid #444444;
  border-top: 60px solid transparent;
  content: "";
  position: absolute;
  right: 0;
  top: 0;
  left: 0;
  bottom: 0;
}

.map-wrapper .outlook-banner-tag:hover {
  background-color: #000;
}

.map-wrapper .outlook-banner-tag:hover .outlook-banner-tag-arrow, .map-wrapper .outlook-banner-tag:hover .outlook-banner-tag-arrow:after {
  border-left-color: #000;
}

.map-wrapper .outlook-banner-tag-text-wrapper {
  flex: 1 1 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.map-wrapper .outlook-banner-tag-title, .map-wrapper .outlook-banner-tag-description {
  position: relative;
  z-index: 1;
}

.map-wrapper .outlook-banner-tag-title {
  margin: 10px 0 5px;
  font-size: 17px;
  font-weight: 700;
}

.map-wrapper .outlook-banner-tag-description {
  margin: 0 0 10px;
  font-size: 14px;
}

.map-wrapper .outlook-banner-close {
  position: absolute;
  right: 0;
  top: 0;
  padding: 3px 10px;
}

.map-wrapper .outlook-banner-close > i {
  display: block;
  font-size: 20px;
  color: #444444;
}

.map-wrapper .outlook-banner:hover .outlook-banner-close i {
  color: #000;
}

@media (max-width: 991px) {
  .map-list-header .list-amount {
    margin-top: 1rem;
  }
  .map-list-header .list-amount > span {
    display: block;
    margin-top: 0.2rem;
    font-size: 1.3rem;
  }
}

@media (max-width: 767px) and (orientation: portrait) {
  .map-searchbar {
    display: none;
  }
  .map-container {
    padding-top: 0;
  }
  .map-list {
    width: 100%;
    height: 58%;
    padding-top: 5rem;
    top: 42%;
    transform: translate3d(0, 0, 0);
  }
  .map-list-toggle {
    display: none;
    transform: none;
    position: static;
  }
  .map-list-header {
    padding: 0.5rem;
    background-color: #3b839c;
  }
  .map-list-header .list-amount {
    margin-top: 0.6rem;
    color: #ffffff;
  }
  .map-list-header .list-amount > span {
    color: #bad4dc;
  }
  .map-list-header .list-filter {
    min-height: 4rem;
    border: 0;
  }
  .map-list-header .list-filter:after {
    top: 1.3em;
  }
  .map-list-header .list-filter select {
    min-height: 4rem;
    padding-left: 1rem;
    padding-right: 1rem;
  }
  .map-list-header .list-button {
    padding-top: 0.9rem;
    padding-bottom: 0.9rem;
    border: 0;
  }
  .map-list-header .list-button .material-icons {
    line-height: 0.9;
  }
  .map-list-body .listing-items .image {
    display: none;
  }
  .map-list-body .listing-items .listing-description .listing-rooms {
    margin-top: 1.03em;
  }
  .map-list-body .listing-items .listing-price {
    margin-top: -1.4rem;
  }
  .map-gmap {
    height: 42%;
  }
}

@media (min-width: 1200px) {
  .map-list {
    width: 48rem;
  }
  .map-list + .map-gmap {
    transition: padding 200ms ease-in-out;
  }
  .map-list.active + .map-gmap {
    padding-left: 48rem;
  }
}

.map-search-2018 {
  overflow-y: hidden;
  height: 100%;
}

@media (min-width: 689px) {
  .map-search-2018 {
    padding-top: 0;
  }
  .map-search-2018 > .header {
    display: none;
  }
}

.map-search-2018 .map-container, .map-search-2018 .map-wrapper {
  height: 100%;
}

.map-search-2018 .map-container {
  padding-top: 0;
  display: flex;
  flex-flow: column nowrap;
}

.map-search-2018 .map-container.mobfilter-active {
  z-index: 99;
}

.map-search-2018 .map-container.mobfilter-active .nanobar {
  position: fixed !important;
  top: 0;
}

.map-search-2018 .map-searchbar {
  display: block;
  padding: 0;
  background-color: white;
  border-bottom: 0.1rem solid #dededf;
  position: relative;
  top: auto;
  left: auto;
  z-index: 3;
  box-shadow: none;
}

.map-search-2018 .map-search-form {
  display: flex;
  flex-flow: row nowrap;
  align-items: stretch;
  padding-left: 2rem;
  padding-right: 2rem;
}

.map-search-2018 .map-wrapper {
  display: flex;
  flex-direction: row;
  flex-grow: 1;
  overflow: hidden;
}

@media (min-width: 689px) {
  .map-search-2018 .map-wrapper {
    height: 100%;
  }
}

.map-search-2018 .map-list, .map-search-2018 .map-gmap {
  flex: auto;
  height: auto;
  padding-left: 0;
  position: relative;
  top: auto;
  opacity: 1;
  visibility: visible;
  transform: none;
  display: flex;
  flex-direction: column;
}

.map-search-2018 .map-gmap {
  flex: 0 0 33%;
}

.map-search-2018 .map-list {
  display: flex;
  flex-flow: column nowrap;
  width: auto;
  min-width: 0;
  padding-top: 0;
  position: relative;
  flex: 1 1 0;
}

.map-search-2018 .map-list-header {
  padding: 1rem 2.5rem;
  background-color: #eaebf0;
  position: relative;
  top: auto;
  left: auto;
  right: auto;
  z-index: 5;
}

.map-search-2018 .map-list-body {
  display: flex;
  flex: 1 1 auto;
  flex-flow: column;
  height: auto;
  background-color: #f4f5f9;
  position: relative;
}

.map-search-2018 .map-list-body .map-list-boxes {
  display: flex;
  flex-flow: column nowrap;
  padding: 0;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.map-search-2018 .map-list-body .map-list-boxes .i-scrollbar {
  height: auto;
}

.map-search-2018 .map-list-body .map-list-boxes .i-paginator {
  display: flex;
  flex-wrap: nowrap;
  flex-shrink: 0;
  align-items: center;
  margin: 0;
  padding: 1rem 2.2rem;
  border-top: 0.1rem solid #d3d7dc;
  background-color: #eaebf0;
  overflow: unset;
  border-radius: 0;
  box-shadow: none;
}

.map-search-2018 .map-list-body .map-list-boxes .i-paginator .btn {
  padding: 0.4rem 0;
  line-height: 0;
  border-radius: 0.2rem;
}

.map-search-2018 .map-list-body .map-list-boxes .i-paginator .btn svg {
  width: 2rem;
  height: 1.6rem;
  fill: #2e3a52;
}

.map-search-2018 .map-list-body .map-list-boxes .i-paginator .btn .fa {
  font-size: 18px;
  margin-left: 3px;
  margin-right: -3px;
}

.map-search-2018 .map-list-body .map-list-boxes .i-paginator .btn-clean {
  background-color: transparent;
  box-shadow: none;
}

.map-search-2018 .map-list-body .map-list-boxes .i-paginator .btn-default {
  margin-left: 1.5rem;
  margin-right: 1.5rem;
  padding-left: 0.6rem;
  padding-right: 0.6rem;
}

.map-search-2018 .map-list-body .map-list-boxes .i-paginator .btn-default:focus {
  background-color: #ffffff;
  border-color: #c9c9c9;
  box-shadow: none;
}

.map-search-2018 .map-list-body .map-list-boxes .i-paginator .btn-default:hover {
  background-color: #ffffff;
  border-color: #b0b0b0;
  box-shadow: 0 0.1rem 0.4rem -0.05rem rgba(37, 37, 37, 0.2);
}

.map-search-2018 .map-list-body .map-list-boxes .i-paginator .btn-default:active, .map-search-2018 .map-list-body .map-list-boxes .i-paginator .btn-default:active:hover {
  box-shadow: 0 0.3rem 0.4rem -0.2rem rgba(37, 37, 37, 0.15) inset;
}

.map-search-2018 .map-list-body .map-list-boxes .i-paginator .paginator-text {
  font-size: 1.3rem;
}

.map-search-2018 .map-list-body .map-list-boxes .i-paginator .paginator-text span {
  margin-left: 0.2em;
  margin-right: 0.2em;
}

.map-search-2018 .map-list-body .map-list-boxes .i-paginator.i-paginator-m {
  position: fixed;
  bottom: 0;
  width: 100%;
  z-index: 999;
}

.map-search-2018 .map-list-body .map-list-card {
  display: flex;
  flex-wrap: wrap;
  padding: 3rem 1rem;
}

.map-search-2018 .map-list-body .map-list-card .no-result {
  flex: 1 1 100%;
  text-align: center;
  font-size: 24px;
  padding: 0 20px;
}

.map-search-2018 .map-list-filters {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  font-size: 1.3rem;
}

.map-search-2018 .map-list-filters .list-item {
  padding: 0;
}

.map-search-2018 .map-list-filters .list-item svg {
  display: block;
  fill: #2e3a52;
}

.map-search-2018 .map-list-filters .list-item .ion-icon {
  display: block;
  color: #2e3a52;
  font-size: inherit;
  line-height: 0;
}

.map-search-2018 .map-list-filters .list-item .btn-group {
  display: flex;
}

.map-search-2018 .map-list-filters .list-item .btn-group + .btn-group {
  margin-left: 1rem;
}

.map-search-2018 .map-list-filters .list-item .btn-group .btn-first {
  border-radius: 0.2rem 0 0 0.2rem;
}

.map-search-2018 .map-list-filters .list-item .btn-group .btn-last {
  border-radius: 0 0.2rem 0.2rem 0;
}

.map-search-2018 .map-list-filters .list-item .btn-group.open .dropdown-toggle {
  background-color: #cdced4;
  color: #252525;
  box-shadow: 0 0.2rem 0.1rem -0.05rem rgba(37, 37, 37, 0.1) inset;
  border-color: #a5a5a5;
}

.map-search-2018 .map-list-filters .list-item .btn {
  padding-left: 1.5rem;
  padding-right: 1.5rem;
  font-size: 1.6rem;
  position: relative;
  border-radius: 0.2rem;
}

.map-search-2018 .map-list-filters .list-item .btn:focus {
  background-color: #ffffff;
  border-color: #c9c9c9;
  box-shadow: none;
}

.map-search-2018 .map-list-filters .list-item .btn:hover {
  background-color: #ffffff;
  border-color: #b0b0b0;
  box-shadow: 0 0.1rem 0.4rem -0.05rem rgba(37, 37, 37, 0.2);
}

.map-search-2018 .map-list-filters .list-item .btn:active, .map-search-2018 .map-list-filters .list-item .btn:active:hover {
  box-shadow: 0 0.3rem 0.4rem -0.2rem rgba(37, 37, 37, 0.15) inset;
}

.map-search-2018 .map-list-filters .list-item .btn-combobox {
  line-height: 0;
  pointer-events: none;
}

.map-search-2018 .map-list-filters .list-item .btn-combobox label {
  display: block;
  width: 1.6rem;
  height: 1.6rem;
  margin-bottom: 0;
  border: 0.1rem solid #d1d1d2;
  background-color: #ffffff;
  cursor: pointer;
  pointer-events: auto;
  position: relative;
  border-radius: 0.3rem;
}

.map-search-2018 .map-list-filters .list-item .btn-combobox label:hover {
  border-color: #b7b7b9;
}

.map-search-2018 .map-list-filters .list-item .btn-combobox input[type="checkbox"] {
  display: none;
}

.map-search-2018 .map-list-filters .list-item .btn-combobox input[type="checkbox"]:checked + label {
  border-color: #2e3a52;
  background-color: #2e3a52;
  color: #ffffff;
}

.map-search-2018 .map-list-filters .list-item .btn-combobox input[type="checkbox"]:checked + label:before {
  content: "\f3fd";
  display: inline-block;
  color: inherit;
  font-size: 2.2rem;
  font-family: "Ionicons";
  position: absolute;
  top: 0.7rem;
  left: 0.3rem;
  text-align: center;
}

.map-search-2018 .map-list-filters .list-item .btn-combobox.half-checked label:before {
  content: "";
  display: block;
  background-color: #2e3a52;
  position: absolute;
  top: calc(50% - 1px);
  bottom: calc(50% - 1px);
  left: 0.3rem;
  right: 0.3rem;
  opacity: 1;
}

.map-search-2018 .map-list-filters .list-item .btn.active {
  background-color: #cdced4;
  color: #252525;
  box-shadow: 0 0.2rem 0.1rem -0.05rem rgba(37, 37, 37, 0.1) inset;
  border-color: #a5a5a5;
}

.map-search-2018 .map-list-filters .list-item .btn.disabled, .map-search-2018 .map-list-filters .list-item .btn[disabled] {
  border-color: #ccc;
  box-shadow: none;
}

.map-search-2018 .map-list-filters .list-item .btn.disabled:active, .map-search-2018 .map-list-filters .list-item .btn[disabled]:active {
  box-shadow: none;
}

.map-search-2018 .map-list-filters .list-item [data-tooltip]:before, .map-search-2018 .map-list-filters .list-item [data-tooltip]:after {
  display: block;
  background-color: #252525;
  color: #ffffff;
  font-size: 1.2rem;
  position: absolute;
  top: 100%;
  left: 50%;
  z-index: 1;
  white-space: nowrap;
  visibility: hidden;
}

.map-search-2018 .map-list-filters .list-item [data-tooltip]:before {
  content: "";
  width: 1rem;
  height: 1rem;
  margin-top: 0.9rem;
  transform: rotate(45deg) translateX(-50%);
}

.map-search-2018 .map-list-filters .list-item [data-tooltip]:after {
  content: attr(data-tooltip);
  margin-top: 1rem;
  padding: 0.5rem 1rem;
  transform: translateX(-50%);
}

.map-search-2018 .map-list-filters .list-item [data-tooltip]:hover:before, .map-search-2018 .map-list-filters .list-item [data-tooltip]:hover:after {
  visibility: visible;
}

.map-search-2018 .map-list-filters .list-combo {
  display: flex;
  justify-content: flex-end;
  margin-right: 1rem;
}

.map-search-2018 .map-list-filters .list-combo .btn {
  padding-left: 0.9rem;
  padding-right: 0.9rem;
}

.map-search-2018 .map-list-filters .list-combo .dropdown-toggle {
  padding-left: 0.6rem;
  padding-right: 0.6rem;
}

.map-search-2018 .map-list-filters .list-combo .save-listings,
.map-search-2018 .map-list-filters .list-combo .save-listings-divider {
  display: none;
}

.map-search-2018 .map-list-filters .list-custom {
  display: flex;
  flex-flow: row nowrap;
}

.map-search-2018 .map-list-filters .list-custom .btn {
  font-size: inherit;
  line-height: 1.3;
}

.map-search-2018 .map-list-filters .list-custom .btn svg,
.map-search-2018 .map-list-filters .list-custom .btn .ion-icon {
  float: left;
  margin-top: -0.1rem;
  margin-right: 0.3em;
  font-size: 1.6rem;
}

.map-search-2018 .map-list-filters .list-results {
  flex-grow: 1;
  margin-left: 1rem;
  margin-right: 1rem;
  font-size: 1.1rem;
  letter-spacing: 0.03em;
  color: #a9afb6;
}

.map-search-2018 .map-list-filters .list-sort .btn-sort {
  display: none;
}

.map-search-2018 .map-list-filters .list-sort .form-select {
  max-width: 16rem;
  padding: 0.3rem 1rem;
  height: 3rem;
  font-size: inherit;
  border-radius: 0.2rem;
  box-shadow: none;
  transition: none;
  appearance: none;
}

.map-search-2018 .map-list-filters .list-sort .form-select:focus {
  background-color: #ffffff;
  border-color: #c9c9c9;
  box-shadow: none;
}

.map-search-2018 .map-list-filters .list-sort .form-select:hover {
  background-color: #ffffff;
  border-color: #b0b0b0;
  box-shadow: 0 0.1rem 0.4rem -0.05rem rgba(37, 37, 37, 0.2);
}

.map-search-2018 .map-list-filters .list-sort .form-select-wrapper {
  position: relative;
}

.map-search-2018 .map-list-filters .list-sort .form-select-wrapper .indicator {
  display: flex;
  align-items: center;
  padding: 0 0.7rem 0 1.5rem;
  background-image: linear-gradient(to right, rgba(255, 255, 255, 0) 0%, white 30%);
  position: absolute;
  top: 0.1rem;
  right: 0.1rem;
  bottom: 0.1rem;
  border-radius: 0 0.2rem 0.2rem 0;
  pointer-events: none;
}

.map-search-2018 .map-list-filters .list-sort-mini .btn-sort {
  display: block;
  border-radius: 0.2rem !important;
}

.map-search-2018 .map-list-filters .list-sort-mini .form-select {
  opacity: 0;
  cursor: pointer;
}

.map-search-2018 .map-list-filters .list-sort-mini .form-select-wrapper {
  width: 4.8rem;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
}

.map-search-2018 .map-list-filters .list-sort-mini .form-select-wrapper:hover + .btn-sort:before, .map-search-2018 .map-list-filters .list-sort-mini .form-select-wrapper:hover + .btn-sort:after {
  visibility: visible;
}

.map-search-2018 .map-list-filters .list-sort-mini .form-select-wrapper .indicator {
  display: none;
}

.map-search-2018 .map-list-filters .list-views {
  display: flex;
  justify-content: flex-end;
  margin-left: 1rem;
}

.map-search-2018 .map-list-filters .dropdown-menu {
  padding-top: 1rem;
  padding-bottom: 1rem;
  font-size: inherit;
  border-radius: 0.2rem;
  box-shadow: 0 0.1rem 0.1rem rgba(0, 0, 0, 0.1);
}

.map-search-2018 .map-list-filters .dropdown-menu > li > a {
  padding: 0.6rem 1rem;
}

.map-search-2018 .map-list-filters .results-selected .badge {
  float: right;
  background-color: #ff5a5f;
}

.map-search-2018 .map-list-filters .results-selected.disabled .badge {
  display: none;
}

.map-search-2018 .map-list-filters.mini .list-item .btn {
  padding-left: 0.7rem;
  padding-right: 0.7rem;
}

.map-search-2018 .map-list-filters.mini .list-combo {
  margin-right: 0.7rem;
}

.map-search-2018 .map-list-filters.mini .list-combo .save-listings,
.map-search-2018 .map-list-filters.mini .list-combo .save-listings-divider {
  display: block;
}

.map-search-2018 .map-list-filters.mini .list-results {
  margin-left: 0;
  margin-right: 0.7rem;
}

.map-search-2018 .map-list-filters.mini .list-views {
  margin-left: 0.7rem;
}

.map-search-2018 .map-list-filters.mini .list-views .btn-group + .btn-group {
  margin-left: 0.7rem;
}

.map-search-2018 .map-gmap {
  border-left: 0.1rem solid #dededf;
  transition: none;
}

.map-search-2018 .map-gmap .map-frame-wrapper {
  width: 100%;
  height: 100%;
  position: relative;
  flex: 1 1 100%;
}

@media screen and (max-width: 689px) {
  .map-search-2018 .map-gmap .map-frame-wrapper {
    position: absolute;
    top: 0px;
  }
}

.map-search-2018 .map-gmap .map-frame {
  width: 100%;
  height: 100%;
}

.map-search-2018 .map-gmap .map-frame iframe {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.map-search-2018 .map-gmap .js-info-bubble-close {
  opacity: 0.5;
  transform: translate3d(0.4rem, -0.4rem, 0) scale(0.5);
}

.map-search-2018 .map-gmap .js-info-bubble-close:hover {
  opacity: 1;
}

.map-search-2018 .map-gmap .search-by-container-desktop {
  position: absolute;
  top: 20px;
  background-color: #f3f6f9;
  padding: 0 8px;
  border-radius: 4px;
  border: #565656;
  box-shadow: 0 1px 4px -1px rgba(0, 0, 0, 0.3);
  display: block;
  margin-left: 10px;
}

.map-search-2018 .map-gmap .search-by-container-desktop .search-by {
  margin: 10px 0;
}

.map-search-2018 .map-gmap .search-by-container-desktop .button {
  cursor: pointer;
  background-color: #ffe204;
  color: white;
  width: 50px;
  border-radius: 5px;
  border: 1px solid transparent;
  font-size: 14px;
  font-weight: 300;
  padding: 0px;
}

.map-search-2018 .map-gmap .search-by-container-desktop .button > img {
  height: 48px;
}

.map-search-2018 .map-detail {
  display: flex;
  flex-flow: column nowrap;
  flex-basis: 70%;
  background-color: #ffffff;
  border-left: 0.1rem solid #dededf;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  transition: all 300ms cubic-bezier(0.23, 0.18, 0.32, 1.02);
  transform: translate3d(100%, 0, 0);
}

.map-search-2018 .map-detail.on {
  transform: translate3d(0, 0, 0);
  position: initial;
}

.map-search-2018 .map-detail-navi {
  display: flex;
  background-color: #f4f5f9;
  border-bottom: 1px solid #ddd;
}

.map-search-2018 .map-detail-frame {
  flex: 1 1 auto;
  position: relative;
  -webkit-overflow-scrolling: touch;
  overflow-y: scroll;
}

.map-search-2018 .map-detail-frame iframe {
  width: 100%;
  height: 100%;
  border: 0;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.map-search-2018 .map-detail-frame .loading-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.85);
}

.map-search-2018 .map-detail-frame .loading-overlay img {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.map-search-2018 .map-card {
  margin: 0.5rem;
  border: 0;
  box-shadow: 0 0.2rem 0.8rem -0.1rem rgba(0, 0, 0, 0.2), 0 0 0 0.1rem rgba(0, 0, 0, 0.08);
}

.map-search-2018 .map-card > .content {
  padding: 1rem;
  background-color: #2e3a52;
  color: #ffffff;
  text-align: center;
  border-radius: 0.3rem 0.3rem 0 0;
}

.map-search-2018 .map-card > .image {
  background-color: transparent;
  border-top: 0.2rem solid #3b839c;
  border-bottom: 0.2rem solid #3b839c;
}

.map-search-2018 .map-card > .buttons {
  font-size: 1.2rem;
  border-top: 0;
}

.map-search-2018 .map-card > .buttons > .button {
  cursor: default;
}

.map-search-2018 .map-card > .buttons > .button:active {
  box-shadow: none;
}

.map-search-2018 .map-scrollbar {
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
}

.map-search-2018 .map-scrollbar .scroll-element_track {
  background-color: transparent;
}

.map-search-2018 .map-scrollbar .scroll-bar {
  background-color: rgba(0, 10, 15, 0.3);
  opacity: 1;
}

.map-search-2018 .map-scrollbar .scroll-bar:hover {
  opacity: 1;
}

@media screen and (min-width: 690px) {
  .map-search-2018 .map-list-filters .list-item .btn.active.disable-map {
    display: none;
  }
}

.map-search-2018 .map-offscreen {
  position: fixed !important;
  top: auto !important;
  right: auto !important;
  bottom: auto !important;
  left: -1000vw !important;
  max-width: 1000vw !important;
  overflow: hidden !important;
  opacity: 0 !important;
  pointer-events: none !important;
}

.map-search-2018 .checkboxes.flex {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
}

.map-search-2018 .checkboxes.flex > .checkbox {
  flex: 0 1 33.3333%;
  margin-top: 1rem;
  margin-bottom: 1rem;
  padding-right: 1.5rem;
}

.map-search-2018 .radios.flex {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
}

.map-search-2018 .radios.flex > .radio {
  flex: 0 1 33.3333%;
  margin-top: 1rem;
  margin-bottom: 1rem;
  padding-right: 1.5rem;
}

.map-search-2018 .msf-logo {
  display: none;
}

@media (min-width: 689px) {
  .map-search-2018 .msf-logo {
    display: flex;
    align-items: center;
  }
  .map-search-2018 .msf-logo img {
    height: 25px;
    padding-right: 10px;
  }
}

.map-search-2018 .msf-states, .map-search-2018 .msf-mobile-listing-type {
  display: flex;
  flex-flow: row nowrap;
  align-items: stretch;
  position: relative;
}

@media (max-width: 689px) {
  .map-search-2018 .msf-states, .map-search-2018 .msf-mobile-listing-type {
    position: absolute;
    top: -60%;
    left: auto;
    right: 35%;
  }
}

.map-search-2018 .msf-states .msf-menu-toggle, .map-search-2018 .msf-mobile-listing-type .msf-menu-toggle {
  margin-left: 1.5rem;
  margin-right: 1rem;
  padding: 0.35rem 1rem;
  border: 0.1rem solid #ddd;
  border-radius: 0.2rem;
}

@media (min-width: 690px) {
  .map-search-2018 .msf-states .msf-menu-toggle .fa, .map-search-2018 .msf-mobile-listing-type .msf-menu-toggle .fa {
    display: none;
  }
}

@media (max-width: 689px) {
  .map-search-2018 .msf-states .msf-menu-toggle, .map-search-2018 .msf-mobile-listing-type .msf-menu-toggle {
    padding: 2px 10px;
    font-size: 12px;
    border-color: #005c98;
    font-weight: 500;
  }
  .map-search-2018 .msf-states .msf-menu-toggle svg, .map-search-2018 .msf-mobile-listing-type .msf-menu-toggle svg {
    fill: #000;
    stroke: #000;
    display: none;
  }
  .map-search-2018 .msf-states .msf-menu-toggle .fa, .map-search-2018 .msf-mobile-listing-type .msf-menu-toggle .fa {
    margin-left: 6px;
    font-size: 18px;
  }
}

@media (max-width: 320px) {
  .map-search-2018 .msf-states .msf-menu-toggle, .map-search-2018 .msf-mobile-listing-type .msf-menu-toggle {
    margin: 3px 0 0 -14px;
    font-size: 10px;
  }
}

@media (max-width: 916px) {
  .map-search-2018 .msf-states .msf-droplet, .map-search-2018 .msf-mobile-listing-type .msf-droplet {
    display: flex;
  }
}

.map-search-2018 .msf-states .msf-droplet.open .msf-menu-toggle, .map-search-2018 .msf-mobile-listing-type .msf-droplet.open .msf-menu-toggle {
  background-color: #f2f2f5;
}

@media (max-width: 689px) {
  .map-search-2018 .msf-states .msf-droplet.open .msf-menu-toggle, .map-search-2018 .msf-mobile-listing-type .msf-droplet.open .msf-menu-toggle {
    background-color: transparent;
    color: #fff;
  }
  .map-search-2018 .msf-states .msf-droplet.open .msf-menu-toggle svg, .map-search-2018 .msf-mobile-listing-type .msf-droplet.open .msf-menu-toggle svg {
    fill: #fff;
  }
}

.map-search-2018 .msf-states .invisible-select, .map-search-2018 .msf-mobile-listing-type .invisible-select {
  display: none;
}

@media (max-width: 689px) {
  .map-search-2018 .msf-states .invisible-select, .map-search-2018 .msf-mobile-listing-type .invisible-select {
    display: block;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    opacity: 0;
  }
}

.map-search-2018 .msf-states .dropdown-menu, .map-search-2018 .msf-mobile-listing-type .dropdown-menu {
  top: 99%;
  left: 0;
}

@media (max-width: 689px) {
  .map-search-2018 .msf-mobile-listing-type {
    right: 10%;
    left: auto;
  }
}

@media (min-width: 689px) {
  .map-search-2018 .msf-mobile-listing-type {
    display: none;
  }
}

.map-search-2018 .msf-omnibox {
  display: flex;
  align-items: center;
  flex-grow: 1;
  padding-right: 2.8rem;
  padding-left: 1.4rem;
  margin-right: 1.4rem;
  border-right: 0.1rem solid #ddd;
}

.map-search-2018 .msf-omnibox > label {
  margin-bottom: 0;
  margin-right: 0.5rem;
  font-size: 3rem;
  line-height: 1;
}

.map-search-2018 .msf-omnibox > label svg {
  display: block;
}

.map-search-2018 .msf-omnibox .form-control {
  height: 4.5rem;
  border: 0;
  color: #252525;
  font-size: 1.3rem;
  font-weight: 300;
  box-shadow: none;
  flex-grow: 1;
  flex-basis: 100px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.map-search-2018 .msf-omnibox .form-control::placeholder {
  color: #252525;
}

.map-search-2018 .msf-omnibox .form-control:focus::placeholder {
  color: #a5a5a5;
}

.map-search-2018 .msf-omnibox-tag {
  background: #eee;
  padding: 7px 15px;
  margin-right: 10px;
  border-radius: 20px;
  display: flex;
  align-items: center;
  max-width: 145px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
  white-space: nowrap;
  font-size: 12px;
}

.map-search-2018 .msf-omnibox-tag .remove {
  font-size: 9px;
  padding: 5px;
  margin-left: 4px;
  margin-right: -5px;
  background: #d0d0d0;
  color: #777;
  border-radius: 50%;
  line-height: 0;
  align-items: center;
  cursor: pointer;
  display: none;
}

.map-search-2018 .msf-omnibox-tag-more {
  padding: 0 10px;
  font-weight: bold;
}

@media (max-width: 768px) {
  .map-search-2018 .msf-omnibox-tag, .map-search-2018 .msf-omnibox-tag-more {
    display: none !important;
    position: absolute;
    left: -100000px;
  }
}

.map-search-2018 .msf-omnibox-rightside {
  display: flex;
  align-items: center;
  flex-grow: 1;
  background: #fff;
}

.map-search-2018 .msf-omnibox-rightside-wrapper {
  flex-grow: 1;
  align-self: center;
}

.map-search-2018 .msf-omnibox .expanded {
  flex-basis: 100%;
  position: relative;
}

.map-search-2018 .msf-omnibox .expanded .msf-omnibox-rightside {
  flex-wrap: wrap;
  position: absolute;
  top: -22px;
  width: 100%;
}

.map-search-2018 .msf-omnibox .expanded .msf-omnibox-tag {
  margin-top: 5px;
  margin-bottom: 5px;
}

.map-search-2018 .msf-omnibox .expanded .msf-omnibox-tag .remove {
  display: flex;
}

.map-search-2018 .msf-omnibox .expanded .msf-omnibox-tag-text {
  max-width: 90%;
  display: block;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.map-search-2018 .msf-mobile {
  display: none;
}

.map-search-2018 .msf-mobile .msf-menu-toggle > span {
  display: inline-flex;
  flex-direction: row;
  align-items: center;
  padding: 1.4rem 1.5rem;
  color: #252525;
  font-size: 1.3rem;
  font-weight: 400;
  line-height: 2.1;
  text-decoration: none;
  user-select: none;
  margin-left: 1.5rem;
  margin-right: 1rem;
  padding: .35rem 1rem;
  border: .1rem solid #ddd;
  border-radius: .2rem;
  padding: 2px 10px;
  font-size: 12px;
  border-color: #005c98;
  font-weight: 500;
  margin: 0;
}

.map-search-2018 .msf-droplet {
  display: flex;
  align-items: center;
  position: static;
}

.map-search-2018 .msf-droplet.open .msf-menu-toggle {
  color: #005c98;
}

.map-search-2018 .msf-droplet.open .msf-menu-toggle svg {
  fill: #005c98;
}

@media (min-width: 689px) {
  .map-search-2018 .msf-droplet-states {
    border-left: 0.1rem solid #ddd;
    border-right: 0.1rem solid #ddd;
  }
  .map-search-2018 .msf-droplet-states .msf-menu-toggle {
    border: none;
    margin: 0;
    height: 100%;
    padding-left: 15px;
    padding-right: 12px;
  }
}

.map-search-2018 .msf-menu-toggle {
  display: inline-flex;
  flex-direction: row;
  align-items: center;
  padding: 1.4rem 1.5rem;
  color: #252525;
  font-size: 1.3rem;
  font-weight: 400;
  line-height: 2.1;
  text-decoration: none;
  user-select: none;
}

.map-search-2018 .msf-menu-toggle > .ion-icon {
  font-size: 0.8em;
  margin-left: 1.25em;
}

.map-search-2018 .msf-menu-dismiss {
  display: none;
  padding: 1rem;
}

.map-search-2018 .msf-menu-dismiss svg {
  display: block;
}

.map-search-2018 .msf-menu-dropdown {
  max-height: calc(100vh - 13.5rem);
  margin-top: 0;
  padding: 0;
  left: auto;
  top: 5.9rem;
  right: 0.3rem;
  overflow-y: auto;
  box-shadow: 0 0.3rem 1.2rem -0.4rem rgba(0, 0, 0, 0.15);
  border-radius: 0.2rem;
}

.map-search-2018 .msf-menu-form {
  padding: 1.5rem 2.5rem;
}

.map-search-2018 .msf-menu-price .group-flex {
  display: flex;
}

.map-search-2018 .msf-menu-price .group-separator {
  align-self: flex-end;
  padding-left: 1rem;
  padding-right: 1rem;
  padding-bottom: 1.2rem;
}

.map-search-2018 .msf-menu-locations {
  padding: 0;
}

.map-search-2018 .msf-menu-filters, .map-search-2018 .msf-menu-beds, .map-search-2018 .msf-menu-baths {
  padding-top: 2.5rem;
  padding-bottom: 1rem;
}

.map-search-2018 .msf-menu-filters .group, .map-search-2018 .msf-menu-beds .group, .map-search-2018 .msf-menu-baths .group {
  margin-bottom: 2rem;
}

.map-search-2018 .msf-menu-filters .group-label, .map-search-2018 .msf-menu-beds .group-label, .map-search-2018 .msf-menu-baths .group-label {
  margin-top: 0;
  margin-bottom: 0.5rem;
  font-size: 1.3rem;
}

.map-search-2018 .msf-menu-filters .group-flex, .map-search-2018 .msf-menu-beds .group-flex, .map-search-2018 .msf-menu-baths .group-flex {
  display: flex;
  align-items: center;
}

.map-search-2018 .msf-menu-filters .group-flex, .map-search-2018 .msf-menu-filters .group-single, .map-search-2018 .msf-menu-beds .group-flex, .map-search-2018 .msf-menu-beds .group-single, .map-search-2018 .msf-menu-baths .group-flex, .map-search-2018 .msf-menu-baths .group-single {
  margin-top: 1.5rem;
}

.map-search-2018 .msf-menu-filters .group-separator, .map-search-2018 .msf-menu-beds .group-separator, .map-search-2018 .msf-menu-baths .group-separator {
  padding-left: 1rem;
  padding-right: 1rem;
}

.map-search-2018 .msf-menu-filters .checkboxes.flex > .checkbox, .map-search-2018 .msf-menu-beds .checkboxes.flex > .checkbox, .map-search-2018 .msf-menu-baths .checkboxes.flex > .checkbox {
  flex-basis: 25%;
}

.map-search-2018 .msf-menu-filters .checkboxes.flex.flex-by-3 > .checkbox, .map-search-2018 .msf-menu-beds .checkboxes.flex.flex-by-3 > .checkbox, .map-search-2018 .msf-menu-baths .checkboxes.flex.flex-by-3 > .checkbox {
  flex-basis: 33.33333%;
}

.map-search-2018 .msf-menu-filters .checkboxes.flex.free-width > .checkbox, .map-search-2018 .msf-menu-beds .checkboxes.flex.free-width > .checkbox, .map-search-2018 .msf-menu-baths .checkboxes.flex.free-width > .checkbox {
  flex-basis: auto;
  flex-grow: 0;
}

.map-search-2018 .msf-menu-filters .radios.flex > .radio, .map-search-2018 .msf-menu-beds .radios.flex > .radio, .map-search-2018 .msf-menu-baths .radios.flex > .radio {
  flex-basis: 25%;
}

.map-search-2018 .msf-menu-filters .radios.flex.flex-by-3 > .radio, .map-search-2018 .msf-menu-beds .radios.flex.flex-by-3 > .radio, .map-search-2018 .msf-menu-baths .radios.flex.flex-by-3 > .radio {
  flex-basis: 33.33333%;
}

.map-search-2018 .msf-menu-filters .radios.flex.free-width > .radio, .map-search-2018 .msf-menu-beds .radios.flex.free-width > .radio, .map-search-2018 .msf-menu-baths .radios.flex.free-width > .radio {
  flex-basis: auto;
  flex-grow: 0;
}

.map-search-2018 .msf-menu-locations, .map-search-2018 .msf-menu-filters {
  width: 100vw;
  min-width: 32rem;
  max-width: 70rem;
}

.map-search-2018 .msf-menu-baths {
  padding-top: 0;
}

.map-search-2018 .msf-accordion-header {
  margin: 0;
  padding: 1rem 1rem 1rem 2rem;
  background-color: #f6f7fa;
  font-size: 1.5rem;
  position: sticky;
  top: 0;
  z-index: 1;
}

.map-search-2018 .msf-accordion-body {
  padding: 1rem 2rem;
}

.map-search-2018 .msf-mobile.active {
  position: absolute;
}

.map-search-2018 .msf-mobile.active .msf-menu-dismiss {
  display: block;
  padding: 1rem;
  position: fixed;
  top: 0;
  right: 0;
  z-index: 101;
}

.map-search-2018 .msf-wrapper {
  display: flex;
  flex-flow: row nowrap;
  align-items: stretch;
}

.map-search-2018 .msf-wrapper.active {
  flex-flow: column nowrap;
  width: 100%;
  height: 100%;
  background-color: #ffffff;
  max-height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 100;
  -webkit-overflow-scrolling: touch;
}

.map-search-2018 .msf-wrapper.active .checkboxes > .checkbox {
  display: block;
  flex-basis: 100%;
  margin-left: 0;
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
  padding-right: 0;
}

.map-search-2018 .msf-wrapper.active .checkboxes > .checkbox > label {
  display: block;
}

.map-search-2018 .msf-wrapper.active .radios > .radio {
  display: block;
  flex-basis: 100%;
  margin-left: 0;
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
}

.map-search-2018 .msf-wrapper.active .radios > .radio > label {
  display: block;
}

.map-search-2018 .msf-wrapper.active .msf-droplet {
  display: block;
}

.map-search-2018 .msf-wrapper.active .msf-menu-toggle {
  display: flex;
  background-color: #f4f4f4;
}

.map-search-2018 .msf-wrapper.active .msf-menu-dropdown {
  float: none;
  min-width: auto;
  max-height: none;
  border: 0;
  position: relative;
  top: auto;
  left: auto;
  right: auto;
  bottom: auto;
  box-shadow: none;
}

@media (min-width: 916px) {
  .map-search-2018 .msf-wrapper.active .msf-menu-dropdown {
    display: block;
  }
}

.map-search-2018 .msf-wrapper.active .msf-menu-form {
  max-width: none;
  padding-left: 1.5rem;
  padding-right: 1.5rem;
}

.map-search-2018 .msf-wrapper.active .msf-menu-locations {
  padding-left: 0;
  padding-right: 0;
}

.map-search-2018 .msf-suggest {
  position: absolute;
  top: 105%;
  left: 5px;
  background: #fff;
  margin-top: 5px;
  padding: 0;
  box-shadow: 0 3px 8px #888888;
  width: 100%;
}

.map-search-2018 .msf-suggest-default {
  padding: 15px 15px 20px;
}

.map-search-2018 .msf-suggest-default .icon i {
  font-size: 24px;
}

.map-search-2018 .msf-suggest-default .desc {
  font-size: 12px;
  margin-bottom: 15px;
}

.map-search-2018 .msf-suggest-default .example-title {
  color: #999;
  font-size: 12px;
}

.map-search-2018 .msf-suggest-default .example-tags {
  display: flex;
  flex-wrap: wrap;
}

.map-search-2018 .msf-suggest-default .example-tag {
  margin: 3px 6px 3px 0;
  padding: 1px 5px;
  font-size: 12px;
}

.map-search-2018 .msf-suggest-default .example-tag span {
  background: #eee;
}

.map-search-2018 .msf-suggest-results {
  max-height: 400px;
  overflow-y: auto;
}

.map-search-2018 .msf-suggest-results-row {
  padding: 13px;
}

.map-search-2018 .msf-suggest-results-row.rlabel {
  font-weight: bold;
  padding-left: 20px;
  background: #eee;
  border-bottom: 1px solid rgba(48, 51, 57, 0.1);
  font-size: 12px;
}

.map-search-2018 .msf-suggest-results-row.rlabel > span.tips {
  float: right;
  font-weight: normal;
}

.map-search-2018 .msf-suggest-results-row.rname {
  padding-left: 65px;
  font-size: 14px;
}

.map-search-2018 .msf-suggest-results-row.rname:hover {
  background-color: #d3d7dc;
}

.map-search-2018 .msf-suggest-selected-filters {
  padding: 15px 25px 20px;
  max-height: 80vh;
  overflow: auto;
}

.map-search-2018 .msf-suggest-selected-filters-tags-wrapper {
  display: flex;
  flex-wrap: wrap;
}

.map-search-2018 .msf-suggest-selected-filters .msf-omnibox-tag {
  max-width: 100%;
  display: flex;
}

.map-search-2018 .mdn-btn {
  display: flex;
  align-items: center;
  padding: 0.5rem 2rem;
  border: 0;
  border-right: 1px solid #ddd;
  background-color: transparent;
  font-size: 1.2rem;
  line-height: 2em;
  touch-action: manipulation;
  user-select: none;
}

.map-search-2018 .mdn-btn:active {
  background-color: #eaecf4;
}

.map-search-2018 .mdn-btn svg {
  display: block;
  margin-left: 0.3rem;
  margin-right: 0.3rem;
  fill: #2e3a52;
}

.map-search-2018 .mdn-btn[disabled] {
  opacity: 0.3;
}

.map-search-2018 .mdn-current-listing:active {
  background-color: transparent;
}

.map-search-2018 .mdn-current-listing input {
  display: none;
}

.map-search-2018 .mdn-current-listing label {
  padding-left: 2.4rem;
  margin-bottom: 0;
  font-weight: normal;
  line-height: 1.4;
  position: relative;
  cursor: pointer;
}

.map-search-2018 .mdn-current-listing label:before {
  content: "";
  display: block;
  width: 1.6rem;
  height: 1.6rem;
  margin-bottom: 0;
  border: 0.1rem solid #d1d1d2;
  background-color: #ffffff;
  position: absolute;
  left: 0;
  top: 0;
  border-radius: 0.3rem;
}

.map-search-2018 .mdn-current-listing input:checked + label:before {
  background-color: #2e3a52;
  border-color: #252e42;
}

.map-search-2018 .mdn-current-listing input:checked + label:after {
  content: "\f122";
  font-size: 1rem;
  font-family: Ionicons;
  color: #ffffff;
  position: absolute;
  top: 0.1em;
  left: 0.4em;
}

.map-search-2018 .mdn-prev-listing {
  margin-left: auto;
  border-left: 1px solid #ddd;
}

@media (max-width: 1023px) {
  .map-search-2018 .map-search-form {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
  }
  .map-search-2018 .map-list-header {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
  }
  .map-search-2018 .map-list-body .map-list-boxes .i-paginator {
    justify-content: center;
    padding: 0.8rem 1.5rem;
  }
  .map-search-2018 .map-list-body .map-list-card {
    padding: 2rem;
  }
  .map-search-2018 .map-list-body .map-list-card .cp-card {
    padding: 0;
  }
  .map-search-2018 .map-list-filters .list-combo .btn-group .btn {
    border-radius: 0.2rem;
  }
  .map-search-2018 .map-list-filters .list-combo .btn-combobox {
    display: none;
  }
  .map-search-2018 .map-list-filters .list-custom {
    display: none;
  }
}

@media (max-width: 929px) {
  .map-search-2018 .mdn-btn {
    padding-left: 0.5rem;
    padding-right: 0.5rem;
  }
  .map-search-2018 .mdn-btn svg {
    width: 2rem;
    height: 2rem;
    margin: 0;
  }
  .map-search-2018 .mdn-btn .fa {
    font-size: 18px;
    margin-left: 3px;
    margin-right: -3px;
  }
  .map-search-2018 .mdn-btn span {
    display: none;
  }
  .map-search-2018 .mdn-current-listing {
    padding-left: 1rem;
    padding-right: 1rem;
  }
}

@media (max-width: 916px) {
  .map-search-2018 .msf-omnibox {
    padding-right: 0;
    border-right: 0;
    border-left: 0;
  }
  .map-search-2018 .msf-mobile {
    display: flex;
    align-items: center;
  }
  .map-search-2018 .msf-droplet {
    display: none;
  }
}

@media (max-width: 689px) {
  .map-search-2018 {
    overflow-y: auto;
    height: auto;
  }
  .map-search-2018 .map-search-form {
    padding-right: 0;
  }
  .map-search-2018 .msf-omnibox {
    padding-left: 0;
  }
  .map-search-2018 .map-container, .map-search-2018 .map-wrapper {
    height: auto;
  }
  .map-search-2018 .map-wrapper:not(.map-gmap-off) .map-list {
    display: block;
  }
  .map-search-2018 .map-wrapper:not(.map-gmap-off) .map-list.on {
    display: none;
  }
  .map-search-2018 .map-wrapper:not(.map-gmap-off) .map-gmap {
    left: 0;
    overflow: visible;
    opacity: 1;
    pointer-events: initial;
    height: calc(100vh - 10.6rem);
    position: absolute;
    width: 100%;
    z-index: 2;
    top: 50px;
    height: 430px;
  }
}

@media screen and (max-width: 689px) and (min-width: 769px) {
  .map-search-2018 .map-wrapper:not(.map-gmap-off) .map-gmap.xs-show {
    display: none;
    background: red;
  }
}

@media screen and (max-width: 689px) and (max-width: 768px) {
  .map-search-2018 .map-wrapper:not(.map-gmap-off) .map-gmap.xs-show {
    display: block;
  }
}

@media (max-width: 689px) {
  .map-search-2018 .map-wrapper.map-gmap-off .map-list {
    display: block;
  }
}

@media screen and (max-width: 689px) and (max-width: 689px) {
  .map-search-2018 .map-wrapper.map-gmap-off .map-list {
    margin-top: 50px;
  }
}

@media (max-width: 689px) {
  .map-search-2018 .map-wrapper.map-gmap-off .map-gmap {
    position: fixed;
    left: -1000vh;
    overflow: hidden;
    opacity: 0;
    pointer-events: none;
  }
  .map-search-2018 .map-searchbar {
    position: sticky;
    top: 4rem;
    z-index: 100;
  }
  .map-search-2018 .map-list-body .map-list-boxes {
    position: static;
  }
  .map-search-2018 .map-detail {
    flex-basis: 100%;
    height: calc(100vh - 106px);
    border-left: 0;
    position: fixed;
    top: 106px;
    z-index: 1;
  }
}

@media (min-width: 1186px) and (max-width: 1390px) {
  .map-search-2018 .mdn-btn {
    padding-left: 0.5rem;
    padding-right: 0.5rem;
  }
  .map-search-2018 .mdn-btn svg {
    width: 2rem;
    height: 2rem;
    margin: 0;
  }
  .map-search-2018 .mdn-btn .fa {
    font-size: 18px;
    margin-left: 3px;
    margin-right: -3px;
  }
  .map-search-2018 .mdn-current-listing {
    padding-left: 1rem;
    padding-right: 1rem;
  }
}

@media (min-width: 768px) and (max-width: 929px) {
  .map-search-2018 .map-gmap-off .map-list-card {
    padding-left: 1rem;
    padding-right: 1rem;
  }
  .map-search-2018 .map-gmap-off .map-list-card .cp-card {
    padding-left: 1rem;
    padding-right: 1rem;
  }
}

@media (min-width: 930px) and (max-width: 1023px) {
  .map-search-2018 .map-gmap {
    flex-basis: 70%;
    flex-grow: 0;
    flex-shrink: 0;
  }
  .map-search-2018 .map-gmap-off .map-list-card {
    padding-left: 1rem;
    padding-right: 1rem;
  }
  .map-search-2018 .map-detail {
    width: 70%;
  }
}

@media (min-width: 1024px) and (max-width: 1390px) {
  .map-search-2018 .map-gmap {
    flex-basis: 33%;
    flex-grow: 0;
    flex-shrink: 0;
  }
  .map-search-2018 .map-detail {
    width: 70%;
  }
}

.map-search-2018 .map-list-card.one-col .cp-card {
  width: 100%;
}

.map-search-2018 .map-list-card.two-col .cp-card {
  width: calc(100% / 2);
}

.map-search-2018 .map-list-card.three-col .cp-card {
  width: calc(100% / 3);
}

.map-search-2018 .map-list-card.four-col .cp-card {
  width: calc(100% / 4);
}

.map-list-card {
  display: flex;
  flex-flow: row wrap;
  justify-content: flex-start;
}

.map-list-card .cp-card {
  position: relative;
  margin-bottom: 2rem;
  display: flex;
  flex-flow: column nowrap;
  flex-basis: auto;
  padding: 0 1.5rem;
  width: 100%;
}

.map-list-card .cp-card .card-content {
  background: white;
}

.map-list-card .cp-card .card-img {
  position: relative;
}

.map-list-card .cp-card .card-img .input.checkbox {
  position: relative;
  z-index: 2;
}

.map-list-card .cp-card .card-img .input.checkbox label {
  position: absolute;
  top: 0;
  right: 0;
  background: white;
  border: 1px solid rgba(48, 51, 57, 0.15);
  border-radius: 2px;
  width: 1.6rem;
  min-height: 1.6rem;
  margin: 1rem;
  cursor: pointer;
}

.map-list-card .cp-card .card-img .input.checkbox label:after {
  font-family: Ionicons;
  font-size: 1rem;
  content: "\f122";
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  opacity: 0;
  width: 100%;
  height: 100%;
  text-align: center;
  border-radius: 2px;
  color: white;
}

.map-list-card .cp-card .card-img .input.checkbox input[type="checkbox"] {
  display: none;
}

.map-list-card .cp-card .card-img .input.checkbox input[type="checkbox"]:checked + label {
  background: #2e3a52;
}

.map-list-card .cp-card .card-img .input.checkbox input[type="checkbox"]:checked + label:after {
  opacity: 1;
}

.map-list-card .cp-card .agent-info {
  position: absolute;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  width: 100%;
  color: #fff;
  padding: 4px 10px 3px;
  font-size: 11px;
  display: flex;
  align-items: center;
  cursor: pointer;
}

.map-list-card .cp-card .agent-info .fa {
  font-size: 18px;
  margin-right: 5px;
}

.map-list-card .cp-card .hide-agent {
  position: absolute;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.5);
  color: #fff;
  font-size: 12px;
  padding: 5px 10px;
  cursor: pointer;
  display: none;
  justify-content: space-between;
  align-items: center;
}

.map-list-card .cp-card .hide-agent i {
  font-size: 16px;
  margin-right: 10px;
}

.map-list-card .cp-card:hover .hide-agent {
  display: flex;
}

.map-list-card .cp-card .card-info {
  padding: 1rem;
  cursor: pointer;
}

.map-list-card .cp-card .card-info ul {
  margin: 0;
  padding: 0;
}

.map-list-card .cp-card .card-info ul li {
  list-style: none;
  padding: 0;
  display: inline-block;
}

.map-list-card .cp-card .card-info ul li:after {
  content: "|";
  padding: 5px;
}

.map-list-card .cp-card .card-info ul li:last-child:after {
  content: "";
}

.map-list-card .cp-card .card-info > div:first-of-type {
  font-size: 15px;
  font-weight: 500;
  cursor: pointer;
}

.map-list-card .cp-card .card-info > div:nth-of-type(2) {
  font-size: 11px;
  color: #999;
}

.map-list-card .cp-card .card-info > div:nth-of-type(3) {
  margin-top: 10px;
  font-size: 14px;
  font-weight: 500;
  margin-bottom: 4px;
}

.map-list-card .cp-card .card-info > div:nth-of-type(4) {
  font-size: 11px;
}

.map-list-card .cp-card .card-action {
  display: flex;
  padding: 0.8rem 1rem;
  border-top: 1px solid #eee;
  cursor: pointer;
  background: #2e3a52;
}

.map-list-card .cp-card .card-action .btn {
  display: flex;
  align-items: center;
  border: 0;
  padding: 0.3rem 0;
  color: #fff;
  border-radius: 0;
  box-shadow: none;
  font-size: 13px;
  background: #2e3a52;
}

.map-list-card .cp-card .card-action .btn:focus, .map-list-card .cp-card .card-action .btn:hover, .map-list-card .cp-card .card-action .btn:focus:hover, .map-list-card .cp-card .card-action .btn:active {
  background-color: transparent;
}

.map-list-card .cp-card .card-action .btn svg {
  margin-top: -0.2rem;
  fill: #a5a5a5;
}

.map-list-card .cp-card .card-action .btn svg + span {
  margin-left: 0.3em;
}

.map-list-card .cp-card .as-r-3-2 {
  background-color: #eaebf0;
  position: relative;
  width: 100%;
  padding-top: 66.66%;
}

.map-list-card .cp-card .as-r-3-2 > * {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
}

.map-list-card .cp-card .flickity-carousel,
.map-list-card .cp-card .flickity-carousel-cell {
  height: 100%;
  cursor: pointer;
}

.map-list-card .cp-card .flickity-carousel-cell {
  overflow: hidden;
}

.map-list-card .cp-card .flickity-carousel-cell img {
  filter: brightness(120%);
}

.map-list-card .cp-card .flickity-enabled .flickity-prev-next-button {
  background-color: rgba(0, 0, 0, 0);
}

@media (min-width: 689px) {
  .map-list-card .cp-card .flickity-enabled .flickity-prev-next-button {
    display: none;
  }
}

.map-list-card .cp-card .flickity-enabled .flickity-prev-next-button .arrow {
  fill: #fff;
}

.map-list-card .cp-card .flickity-enabled .flickity-prev-next-button.previous {
  left: 0;
}

.map-list-card .cp-card .flickity-enabled .flickity-prev-next-button.next {
  right: 0;
}

.map-list-card .cp-card .flickity-enabled:hover .flickity-prev-next-button {
  display: block;
}

.map-list-card .cp-card .flickity-enabled.only-1-image .flickity-prev-next-button {
  display: none;
}

.map-list-card .cp-card.highlight .card-content {
  transition: all 0.3s ease-in-out;
  outline: none;
  box-shadow: 0 0 10px #2e3a52;
}

.map-list-card .cp-card .indicator .highlight {
  background: #ff5122;
  padding: 3px 9px;
  font-size: smaller;
}

.mono .col-p-0 {
  padding: 0;
}

.mono.i-form .input,
.mono.i-form .select {
  border-radius: 0;
  border-width: 0.1rem 0 0.1rem 0.1rem;
}

.mono.i-form .input label {
  padding: 0 1.2rem;
}

.mono.i-form .select select {
  padding: 1rem 1.2rem;
}

.mono.i-form .select:after {
  right: 0.8rem;
}

.mono.i-form .button {
  border-radius: 0;
}

.map-scrollbar.scrollbar-inner.scroll-content {
  height: 100% !important;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  -ms-box-sizing: border-box;
  -o-box-sizing: border-box;
  box-sizing: border-box;
}

.all_clear {
  width: 32px;
  right: 0;
  margin-right: -15px;
}

.map-search-2018 #block-system-main {
  flex: 1 1 100%;
  height: auto;
}

@media (min-width: 689px) {
  .map-search-2018 #block-system-main {
    height: calc(100% - 54px);
  }
}

#block-system-main .map-wrapper .map-list-body.top-list {
  top: 480px;
}

#block-system-main .map-wrapper .map-list-header.header-m {
  display: none;
}

#block-system-main .map-wrapper .map-list-header.header-m .list-item [data-tooltip] hover:after, #block-system-main .map-wrapper .map-list-header.header-m .list-item [data-tooltip] hover:before {
  display: none;
  visibility: hidden;
}

#block-system-main .map-wrapper .map-list-header.header-m .list-item [data-tooltip]:after, #block-system-main .map-wrapper .map-list-header.header-m .list-item [data-tooltip]:before {
  display: none;
}

@media screen and (max-width: 689px) {
  #block-system-main .map-wrapper .map-list-header.header-m {
    display: block;
    flex: none;
    width: 100%;
    height: 49px;
    position: absolute;
  }
}

#block-system-main .map-wrapper .map-list-header.header-m.detail-on {
  display: none;
}

#block-system-main .map-wrapper .map-list-header.hide-m {
  display: block;
}

@media screen and (max-width: 689px) {
  #block-system-main .map-wrapper .map-list-header.hide-m {
    display: none !important;
  }
}

#block-system-main .map-wrapper.map-gmap-off .map-list-body.top-list {
  top: 0;
}

.price-slider {
  flex: 0 0 250px;
  align-self: center;
  margin-right: 20px;
  margin-left: 20px;
}

.price-slider .vue-slider-component .vue-slider-dot.vue-slider-always:first-child {
  z-index: 5;
}

.price-slider .vue-slider-component .vue-slider-dot.vue-slider-always:focus, .price-slider .vue-slider-component .vue-slider-dot.vue-slider-always:active {
  z-index: 6;
}

.price-slider .vue-slider-component .vue-slider-dot.vue-slider-always .vue-slider-tooltip-wrap {
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: auto;
}

.price-slider .vue-slider-component .vue-slider-tooltip {
  user-select: none;
  width: 60px;
  height: 40px;
  line-height: 280%;
  text-align: center;
  background-color: #fff;
  border: 1px solid #dfe8ee;
  border-radius: 20px;
  cursor: pointer;
  color: #000;
  padding-left: 15px;
  position: relative;
  font-size: 12px;
}

.price-slider .vue-slider-component .vue-slider-tooltip.vue-slider-tooltip.vue-slider-tooltip:before {
  content: "RM ";
  display: block;
  position: absolute;
  left: auto;
  top: 0px;
  border: none;
  background: none;
  font-size: 8px;
  right: 88%;
}

.price-slider .vue-slider-component .vue-slider-piecewise {
  background-color: #fff;
  border: 1px solid #dfe8ee;
}

.price-slider .vue-slider-component.vue-slider-horizontal .vue-slider-process {
  background-color: #dfe8ee;
}

.caricon {
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAB8AAAAKCAYAAABMtYURAAAAAXNSR0IArs4c6QAAAg9JREFUOBGlk89rE1EQx/s2m91E9BDiD9qLB1EQFC8ePHoRWs+ittA9JfGkf4C0ZxEtKIg2G9I26EGilxQqPYi0tReFHgseFIVCpSBpEELZ3WTXzyx9sCQSiA4MM/Od+c7Mvn1PjQyQarV6qdvtXo+i6KxSyseepnzbMIwmvo3mwT2w5VKptD6g1V9Tqhctl8tpGt6m8T1yv1Kp1HPLsj44jtMmt8Dgt8Vi8Z3m1et1q9VqTVF/DewNSzTgRzo/yKparZb3PO8ChBM0uIw6EEYZMs2QV0my67oPiY8w4G4SF58ljrLER/gpuLNwG701vbGqVCq3wjB83ZdQ6juNfrCUHLdPPkDPocfBZKlAcAbp/AjxI3At+9T9JthDd9CvxJ/RtUKh0JQixdcUIbkSaKEAKOoSmxr7X0tPWdKiT4i/YZrmTRNgPNmYxDP0E9hJrMepzOHbyZphfHrIPdng4h7Dtuj3hJlXiSdMklcI4n748s8O0JcCYH+i98FlgaEF3hYDmwxaEjK9dsFmsC/QxwbYe0kcygpJuXBaRnH2dTCspdcqg29oHgPHtI/dNNLp9B2KJgnm2HIT+00XgEc8Nbkw/yQMy0H80kOWr28wy+1759z+84fHMkbBU/7RIuSLvPW9TqdzYNt2/I/woyAIYj+TycR+NpuNY9/3I9FcLue32+1T1M3T4wy6wDN9oJf5A4mvDe+PsMLPAAAAAElFTkSuQmCC);
  background-position: 0 0;
  background-repeat: no-repeat;
  display: inline-block;
  height: 16px;
  margin: 5px 6px 0 5px;
  padding: 0;
  vertical-align: middle;
  width: 35px;
}

.outlook-banner-slide-fade-enter-active,
.outlook-banner-slide-fade-leave-active {
  transition: all 0.5s ease;
}

.outlook-banner-slide-fade-enter,
.outlook-banner-slide-fade-leave-to {
  height: 0 !important;
  flex-basis: 0 !important;
  padding-top: 0 !important;
  padding-bottom: 0 !important;
  opacity: 0;
}

.enquiry-modal {
  text-align: center;
  padding: 0 !important;
}

.enquiry-modal:before {
  content: "";
  display: inline-block;
  height: 100%;
  vertical-align: middle;
  margin-right: -4px;
}

.enquiry-modal .modal-dialog {
  display: inline-block;
  text-align: left;
  vertical-align: middle;
  max-width: 500px;
}

.enquiry-modal .modal-content {
  background-color: #005c98;
  color: #fff;
}

.enquiry-modal .modal-body .title {
  font-weight: 800;
  font-size: 19px;
  margin-bottom: 25px;
  letter-spacing: 0;
  text-transform: uppercase;
}

.enquiry-modal .left-col {
  padding-right: 20px;
}

.enquiry-modal .left-col .agent-pic {
  margin-bottom: 15px;
}

.enquiry-modal .left-col .agent-pic img {
  width: 100px;
}

.enquiry-modal .left-col .agent-name,
.enquiry-modal .left-col .agent-id,
.enquiry-modal .left-col .agent-company,
.enquiry-modal .left-col .agent-phone {
  font-size: 15px;
}

.enquiry-modal .left-col .agent-name {
  font-weight: 800;
  font-size: 15px;
  margin-bottom: 5px;
}

.enquiry-modal .left-col .agent-company {
  text-transform: uppercase;
}

.enquiry-modal .left-col .agent-phone {
  margin-top: 20px;
}

.enquiry-modal .right-col .form-group {
  margin-bottom: 18px;
}

.enquiry-modal .right-col .form-group .form-control {
  border: none;
  border-radius: 0;
  padding: 4px 4px;
  font-size: 11px;
  height: auto;
  line-height: 100%;
}

.enquiry-modal .right-col .form-group textarea.form-control {
  padding: 8px 4px;
  line-height: 130%;
}

.enquiry-modal .right-col .submit {
  color: #fff;
  background-color: #222222;
  border: none;
  outline: 0;
  font-size: 15px;
  padding: 10px 20px;
  margin-left: 2px;
  border-radius: 2px;
}

.enquiry-modal .right-col .has-error .control-label {
  color: yellow;
  font-size: 11px;
}

.enquiry-modal .close {
  position: absolute;
  right: 6px;
  top: 5px;
  color: #fff;
  filter: none;
  opacity: 1;
  background: #727272;
  border-radius: 50%;
  line-height: normal;
  font-weight: normal;
  float: none;
  font-size: 12px;
  padding: 2px 6px;
  text-shadow: none;
}

.enquiry-modal .response-msg {
  margin: 100px 0;
}

.i-comments .comment {
  padding-left: 6.5rem;
  position: relative;
}

.i-comments .comment > .image {
  position: absolute;
  left: 0;
  top: 0;
}

.i-comments .comment > .author {
  margin-bottom: 0.6rem;
}

.i-comments .comment > .author .name {
  font-size: 1.6rem;
  color: #2e3a52;
}

.i-comments .comment > .author .meta {
  font-size: small;
  color: #a5a5a5;
}

.i-comments .comment > .content {
  margin-top: 0.6rem;
  margin-bottom: 1rem;
  font-size: small;
}

.i-comments .comment > .extra {
  margin-top: 0.8rem;
  margin-bottom: 0.8rem;
}

.i-comments .comment > .extra a {
  color: #1c2331;
}

.i-comments .comment > .extra a:hover {
  text-decoration: none;
}

.i-comments .replied {
  margin-top: 3rem;
}

.i-comments .replied .comment {
  padding-left: 5.5rem;
}

.i-comments .replied .comment + .comment {
  margin-top: 3rem;
}

@media (max-width: 479px) {
  .i-comments .comment {
    padding-left: 4.6rem;
  }
  .i-comments .comment > .image {
    width: 3.6rem;
  }
  .i-comments .comment > .author .meta {
    font-size: smaller;
  }
  .i-comments .comment > .content {
    font-size: smaller;
  }
  .i-comments .replied .comment {
    padding-left: 4rem;
  }
  .i-comments .replied .comment > .image {
    width: 3rem;
  }
}

/* User */
.user-bar {
  float: left;
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;
  transition: transform 300ms ease-in-out;
}

.user-bar > li {
  position: relative;
}

.user-bar > li + li {
  margin-left: 0;
}

.user-bar > li > a {
  padding-top: 1rem;
  padding-bottom: 1rem;
  color: #252525;
  color: rgba(37, 37, 37, 0.75);
  transition: all 200ms ease-in-out;
}

.user-bar > li > a:hover, .user-bar > li > a:focus {
  background-color: transparent;
  color: #252525;
}

.user-bar > li > a.ghost {
  border: 0.1rem solid;
  font-size: 1.3rem;
  line-height: 2rem;
  text-transform: uppercase;
  border-radius: 0;
  color: rgba(255, 255, 255, 0.8);
}

.user-bar > li > a.ghost .ion-person {
  display: none;
}

.user-bar > li > a.icon {
  font-size: 2.2rem;
  line-height: 0;
}

.user-bar > li > a.icon .ion-icon {
  line-height: inherit;
}

.user-bar .open > a,
.user-bar .open > a:hover,
.user-bar .open > a:focus {
  background-color: transparent;
  border-color: #252525;
  color: #252525;
}

.user-wrap {
  margin-left: auto;
  margin-right: auto;
}

.user-wrap.user-signup {
  max-width: 48rem;
}

.user-wrap.user-signin {
  max-width: 36rem;
}

@media (max-width: 1024px) {
  .user-bar {
    float: none;
    margin-top: 0;
    margin-bottom: 0;
    margin-right: -1.5rem;
    transition: none;
  }
  .user-bar > li > a {
    padding-top: 1rem;
    padding-bottom: 1rem;
  }
  .user-bar > li > a.ghost {
    padding-left: 1rem;
    padding-right: 1rem;
    border: 0;
    line-height: 1;
  }
  .user-bar > li > a.ghost .ion-icon {
    display: inline-block;
    vertical-align: middle;
    font-size: 2rem;
    color: #252525;
  }
  .user-bar > li > a.ghost .ion-person + span {
    display: none;
  }
  .user-bar > li > a.icon {
    width: 5rem;
    height: 5rem;
    padding: 0;
    line-height: 5rem;
    text-align: center;
  }
  .user-bar .dropdown-menu {
    margin-top: 0;
    top: 5rem;
    border-radius: 0;
  }
  .user-bar .dropdown-menu > li > a {
    padding-top: 1rem;
    padding-bottom: 1rem;
  }
  .user-bar .dropdown-menu > li:first-child > a, .user-bar .dropdown-menu > li:last-child > a {
    border-radius: 0;
  }
}

@media (min-width: 1025px) {
  .user-bar .dropdown-menu {
    left: auto;
    right: 0;
  }
}

/* User Property (My Property) */
.mp-module {
  padding-top: 5rem;
  padding-bottom: 5rem;
}

.mp-module-full {
  padding-top: 0;
  padding-bottom: 0;
}

.mp-module .module-title {
  font-size: 2.4rem;
}

.mp-module .module-title:after {
  display: none;
}

.mp-module .module-subtitle {
  margin-top: 0;
  font-size: 1.5rem;
}

.mp-module .module-title + .module-subtitle {
  margin-top: -0.5em;
}

.mp-breadcrumb {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}

.mp-breadcrumb .breadcrumb {
  padding-top: 1.35rem;
  padding-bottom: 1.35rem;
}

.mp-quicklink .mp-bar {
  width: 100%;
  background-color: #fafafa;
  box-shadow: 0 0 0 0.1rem rgba(37, 37, 37, 0.1);
  position: relative;
  z-index: 1;
}

.mp-quicklink .mp-bar .i-tabs {
  margin-bottom: 0;
  border: 0;
  background-color: transparent;
}

.mp-quicklink .mp-bar .i-tabs > li + li:before {
  content: '';
  display: block;
  width: 0.1rem;
  background-color: #dedede;
  position: absolute;
  top: 0.7rem;
  bottom: 0.7rem;
}

.mp-quicklink .mp-bar .i-tabs > li > a {
  margin: 0;
  padding: 1rem;
  border: 0;
  background-color: transparent;
}

.mp-quicklink .mp-bar .i-tabs > li.active > a,
.mp-quicklink .mp-bar .i-tabs > li.active > a:hover,
.mp-quicklink .mp-bar .i-tabs > li.active > a:focus {
  border: 0;
}

.mp-quicklink .mp-spacer {
  display: none;
}

.mp-quicklink.mp-sticky .mp-bar {
  position: fixed;
  z-index: 3;
  box-shadow: 0 0.2rem 0.6rem 0 rgba(37, 37, 37, 0.1), 0 0.1rem 0 0 rgba(37, 37, 37, 0.08);
  transform: translate3d(0, 0, 0);
}

.mp-slider {
  background-color: #252525;
}

.mp-summary {
  padding-top: 2rem;
  padding-bottom: 2rem;
}

.mp-summary .property-summary {
  min-height: 18rem;
  position: relative;
}

.mp-summary .property-details {
  padding-right: 34rem;
}

.mp-summary .property-details .title {
  margin-top: 0;
  font-weight: 300;
}

.mp-summary .property-details .title span {
  display: block;
  margin-top: 0.5em;
  color: #a5a5a5;
  font-size: 1.6rem;
}

.mp-summary .property-details .icon {
  font-size: 1.6rem;
}

.mp-summary .property-details .icon .fa {
  color: #656565;
}

.mp-summary .property-details .claim-home {
  color: #656565;
}

.mp-summary .property-details .claim-home a {
  color: inherit;
  text-decoration: underline;
}

.mp-summary .property-details .claim-home a:hover {
  text-decoration: none;
}

.mp-summary .property-value-card {
  width: 32rem;
  padding-bottom: 0.5rem;
  background-color: #f6f7f9;
  position: absolute;
  top: 0;
  right: 0;
  border-radius: 0.3rem;
}

.mp-summary .property-value-card header {
  padding: 2rem 2rem 1rem;
  position: relative;
}

.mp-summary .property-value-card header h2 {
  margin: 0.3rem 0 0.5rem;
  color: #2e3a52;
  font-size: 2.2rem;
  font-weight: 400;
}

.mp-summary .property-value-card header .value-estimated,
.mp-summary .property-value-card header .value-label,
.mp-summary .property-value-card header .value-price {
  font-size: 1.2rem;
  color: #656565;
}

.mp-summary .property-value-card header .value-estimated {
  margin: 0;
  text-transform: uppercase;
  letter-spacing: 0.1em;
}

.mp-summary .property-value-card header .value-price {
  display: block;
  float: right;
  margin-top: 2px;
  letter-spacing: 0.05em;
}

.mp-summary .property-value-card footer {
  display: flex;
  margin: 0.5rem 0.5rem 0;
  padding: 1.5rem;
  background-color: #ffffff;
  border-radius: 0 0 0.3rem 0.3rem;
  justify-content: space-between;
  align-items: center;
}

.mp-summary .property-value-card footer .find-agents-info {
  width: 16rem;
  font-size: 1.2rem;
  line-height: 1.6;
  color: #656565;
}

.mp-summary .property-value-card footer .find-agents-btn {
  padding: 0.7rem 1rem;
}

.mp-summary .property-value-card .estimated-feedback {
  display: none;
  font-size: 1.2rem;
  position: absolute;
  bottom: -2.8rem;
}

.mp-summary .property-value-card .estimated-feedback span + span {
  margin-left: 0.5rem;
}

.mp-about .i-list-data {
  text-align: right;
}

.mp-location .map-gmap {
  height: 58rem;
  border: 0.1rem solid #dedede;
  border-radius: 0.4rem;
}

.mp-insights .valuation {
  min-height: 7rem;
}

.mp-insights .valuation .value-container {
  display: flex;
}

.mp-insights .valuation .value-label {
  color: #a5a5a5;
  font-size: smaller;
}

.mp-insights .valuation .value-display {
  min-width: 9rem;
  font-size: 3.2rem;
  font-weight: 300;
  line-height: 1;
}

.mp-insights .valuation .value-measurement {
  margin-left: 0.571em;
  font-size: 0.929em;
}

.mp-insights .valuation .value-measurement span {
  display: block;
}

.mp-insights .valuation .value-measurement span + span {
  color: #a5a5a5;
  font-size: 0.714em;
  text-transform: uppercase;
  letter-spacing: 0.08em;
}

.mp-insights .i-card > .content > .title {
  font-weight: 600;
}

.mp-insights .i-card > .extra {
  padding-top: 1.5rem;
  border-top: 0;
  background-color: #f0f3f6;
  border-radius: 0 0 0.3rem 0.3rem;
}

.mp-insights .i-items {
  margin: 0 -1rem -0.5rem;
  padding: 1rem 2.5rem;
  background-color: #ffffff;
}

.mp-insights .i-items > .item > .image.circle {
  width: 5rem;
  height: 5rem;
  line-height: 4.6rem;
  font-size: 1.4em;
  font-weight: 300;
  text-align: center;
  letter-spacing: -0.05em;
  color: #ff5a5f;
  border: 0.2rem solid #ff5a5f;
  border-radius: 50%;
}

.mp-agents .agent-profile {
  height: 9rem;
  padding: 1em 0;
  border-bottom: 0.1rem solid #f7f6f6;
  text-align: center;
}

.mp-agents .agent-profile:first-child {
  border-top: 0.1rem solid #f7f6f6;
}

.mp-agents .agent-picture, .mp-agents .agent-details, .mp-agents .agent-button {
  display: inline-block;
  vertical-align: middle;
}

.mp-agents .agent-picture {
  border: 0.1rem solid #eee;
  border-radius: 50%;
  overflow: hidden;
}

.mp-agents .agent-details {
  width: 65%;
  padding-left: 1.5rem;
  padding-right: 1.5rem;
  text-align: left;
}

.mp-agents .agent-details .agent-name,
.mp-agents .agent-details .agency-name,
.mp-agents .agent-details .recent-sales {
  display: inline-block;
  width: 30%;
  padding-right: 2em;
  vertical-align: middle;
}

.mp-agents .agent-details .agent-name,
.mp-agents .agent-details .agency-name {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.mp-agents .agent-details .agent-name {
  font-weight: 600;
}

.mp-agents .agent-details .agency-name {
  width: 40%;
}

.mp-agents .agent-details .recent-sales {
  padding-right: 0;
}

.mp-tabs {
  margin-bottom: 2rem;
  border: 0;
  background-color: transparent;
  text-align: center;
  font-size: 0;
}

.mp-tabs > li {
  display: inline-block;
  float: none;
  margin-bottom: 0;
  font-size: 1.4rem;
}

.mp-tabs > li > a {
  min-width: 12rem;
  margin: 0;
  padding-top: 1em;
  padding-bottom: 1em;
  border: 0;
  background-color: transparent;
  border-radius: 0;
  position: relative;
  transition: all 300ms ease-in-out;
}

.mp-tabs > li > a:after {
  content: '';
  display: block;
  width: 100%;
  height: 0;
  background-color: transparent;
  position: absolute;
  left: 0;
  bottom: 0;
  transition: inherit;
}

.mp-tabs > li.active > a,
.mp-tabs > li.active > a:hover,
.mp-tabs > li.active > a:focus {
  background-color: transparent;
  border: 0;
}

.mp-tabs > li.active > a:after {
  height: 0.3rem;
  background-color: #3b839c;
}

.mp-items > .item {
  margin: 0;
  padding: 1rem;
  border-bottom: 0.1rem solid #dedede;
  transition: background 250ms ease-in-out, box-shadow 250ms ease-in-out, border-color 250ms ease-in-out;
}

.mp-items > .item:first-child {
  border-top: 0.1rem solid #dedede;
}

.mp-items > .item:focus {
  text-decoration: none;
}

.mp-items > .item:hover {
  border-color: #fff;
  background-color: #fff;
  text-decoration: none;
  position: relative;
  box-shadow: 0 0.2rem 1.2rem -0.1rem rgba(37, 37, 37, 0.2), 0 0 0 0.1rem rgba(37, 37, 37, 0.1);
}

.mp-items > .item > .content .title {
  display: block;
}

.mp-items > .item > .content .meta {
  margin-top: 0.5em;
  margin-bottom: 0;
  color: #a5a5a5;
}

.mp-items > .item > .arrow {
  min-width: 3rem;
  align-self: center;
  text-align: right;
}

.mp-items > .item > .arrow i {
  font-size: 1.8rem;
}

.mp-items .property-details {
  padding-right: 15%;
  position: relative;
}

.mp-items .property-pane {
  text-align: right;
  position: absolute;
  top: 50%;
  right: 0;
  transform: translateY(-50%);
}

.mp-items .property-price {
  font-weight: 600;
}

.mp-items .property-price + span {
  margin-top: 0.3em;
}

.mp-items .property-sold-date, .mp-items .property-authority-type, .mp-items .property-available-date {
  display: block;
  color: #a5a5a5;
  font-size: 95%;
}

.mp-table > thead > tr > th {
  border-bottom: 0;
}

.mp-table > tbody > tr > td {
  padding: 1.5rem 0.5rem;
}

@media (max-width: 767px) {
  .mp-module {
    padding-top: 2rem;
    padding-bottom: 2rem;
  }
  .mp-module .module-title {
    font-size: 2rem;
    font-weight: 600;
  }
  .mp-module .module-subtitle {
    font-size: 1.3rem;
  }
  .mp-summary .property-summary {
    min-height: none;
  }
  .mp-summary .property-details {
    padding-right: 0;
  }
  .mp-summary .property-value-card {
    width: auto;
    margin-left: -1.5rem;
    margin-right: -1.5rem;
    position: relative;
    top: auto;
    right: auto;
  }
  .mp-about .i-list-label {
    float: left;
  }
  .mp-quicklink .mp-bar .i-tabs > li + li:before {
    display: none;
  }
  .mp-tabs > li > a {
    min-width: auto;
  }
}

.profile-interests .interests-heading {
  margin-top: 0;
  margin-bottom: 1.273em;
  font-size: 1.286em;
  font-weight: 600;
  text-align: center;
}

.profile-interests .interests-board {
  position: relative;
}

.profile-interests .interests-card, .profile-interests .interests-card-block {
  background-color: #f5f5f6;
  color: #2e3a52;
  text-align: center;
  position: relative;
  cursor: pointer;
  border-radius: 0.857em;
  transition: box-shadow 150ms ease-in-out;
}

.profile-interests .interests-card-block {
  padding: 0.857em;
}

.profile-interests .interests-card-block .material-icons {
  margin-top: -0.08em;
  margin-bottom: -0.08em;
  margin-right: 0.2em;
  color: #dedede;
}

.profile-interests .interests-card-block.selected .material-icons {
  color: #3b839c;
}

.profile-interests .interests-content {
  padding-top: 1.429em;
  padding-bottom: 0.714em;
}

.profile-interests .interests-triangle {
  width: 0;
  height: 0;
  border-top: 1.929em solid #dedede;
  border-right: 1.929em solid #dedede;
  border-left: 1.929em solid transparent;
  border-bottom: 1.929em solid transparent;
  border-top-right-radius: 0.857em;
  position: absolute;
  top: 0;
  right: 0;
}

.profile-interests .interests-triangle .material-icons {
  margin-top: -1.786em;
  color: #ffffff;
}

.profile-interests .interests-triangle.border {
  border-top: 2.071em solid #ffffff;
  border-right: 2.071em solid #ffffff;
  border-left: 2.071em solid transparent;
  border-bottom: 2.071em solid transparent;
}

.profile-interests .interests-icon {
  font-size: 1.714em;
  transition: color 150ms ease-in-out;
}

.profile-interests .interests-label {
  margin-top: 0.429em;
  font-size: 1em;
  color: #252525;
}

.profile-interests .interests-timeframe {
  margin-top: 0.714em;
  font-size: 0.857em;
  color: #a5a5a5;
  visibility: hidden;
}

.profile-interests .interests-card:hover, .profile-interests .interests-card-block:hover {
  color: #3b839c;
  box-shadow: 0 0 0 0.2rem rgba(46, 58, 82, 0.2);
}

.profile-interests .interests-modal {
  padding: 2.857em;
  background-color: #f5f5f6;
  color: #2e3a52;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border-radius: 0.857em;
  box-shadow: 0 0 0 0.2rem rgba(0, 0, 0, 0.07), 0 0.1rem 0.8rem 0 rgba(0, 0, 0, 0.1);
  transition: all 300ms ease-in-out;
}

.profile-interests .interests-modal-header {
  margin-bottom: 2em;
  color: #2e3a52;
  font-weight: 600;
}

.profile-interests .interests-modal-header h2 {
  margin-top: 0.357em;
  margin-bottom: 1em;
  font-size: 1.714em;
}

.profile-interests .interests-modal-header p {
  font-size: 1.143em;
}

.profile-interests .interests-modal-header .interests-icon {
  margin-top: 0.857em;
  color: #3b839c;
}

.profile-interests .interests-modal-content .i-form .input,
.profile-interests .interests-modal-content .i-form .select {
  border-width: 0.2rem;
}

.profile-interests .interests-modal-close {
  padding: 0.857em;
  cursor: pointer;
  position: absolute;
  top: 0;
  right: 0;
}

.profile-interests .interests-modal.off {
  visibility: hidden;
  opacity: 0;
  transform: scale(0);
}

.profile-interests .selected .interests-triangle:not(.border) {
  border-right-color: #3b839c;
  border-top-color: #3b839c;
}

.profile-interests .selected .interests-icon {
  color: #3b839c;
}

.profile-interests .selected .interests-timeframe {
  visibility: visible;
}

.profile-details {
  padding-top: 2em;
  border-top: 0.1rem solid #f4f4f4;
}

.profile-details .block-title, .profile-details .area-outlook-detail h3.block-title, .area-outlook-detail .profile-details h3.block-title,
.profile-details .page-project-outlook h3.block-title, .page-project-outlook .profile-details h3.block-title {
  font-size: 1.571em;
  text-align: center;
}

.profile-details .i-form h4 {
  margin-bottom: 0.4em;
  font-size: 1.143em;
  font-weight: 600;
}

.profile-details .i-form .input,
.profile-details .i-form .select {
  border-width: 0.2rem;
}

.profile-details .i-form .checkbox,
.profile-details .i-form .radio {
  margin-top: 1.2rem;
  margin-bottom: 1.2rem;
}

.profile-details .i-form .checkbox label:before,
.profile-details .i-form .radio label:before {
  border-width: 0.2rem;
}

@media (min-width: 768px) {
  .profile-interests .interests-heading {
    font-size: 1.571em;
  }
  .profile-interests .interests-content {
    padding-top: 2.857em;
    padding-bottom: 1.429em;
  }
  .profile-interests .interests-icon {
    font-size: 2.5em;
  }
  .profile-interests .interests-label {
    font-size: 1.286em;
  }
}

.page-profile .page-header {
  padding-bottom: 1.2em;
  text-align: center;
  position: relative;
}

.page-profile .page-header:after {
  content: '';
  width: 30%;
  height: 0.1rem;
  margin-left: -15%;
  background-color: #f4f4f4;
  position: absolute;
  left: 50%;
  bottom: 0.5rem;
}

/***** ABANDON *****/
.password-edit {
  display: none;
}

.password-edit.active {
  display: block;
}

.password {
  display: block;
}

.password.active {
  display: none;
}

.email-edit {
  display: none;
}

.email-edit.active {
  display: block;
}

.email {
  display: block;
}

.email.active {
  display: none;
}

.edit-col {
  background-color: #eee;
  padding: 2rem 5rem 5rem 5rem;
}

.edit-header {
  position: relative;
}

.edit-join {
  position: absolute;
  bottom: 0;
  right: 0;
  margin-bottom: 10px;
}

.user-profile hr {
  border-top: 1px solid #d6d6d6;
}

.user-profile input[type="file"] {
  display: none;
}

.user-profile .i-form .input input.narrow {
  min-height: 3.5rem;
}

.user-profile .i-form .input.narrow {
  min-height: 3.7rem;
}

.user-profile .i-form .select select.narrow {
  min-height: 3.5rem;
}

.user-profile .i-form .select.narrow {
  min-height: 3.7rem;
}

.user-profile .i-form .select.narrow:after {
  top: 1.51667rem;
}

.user-profile .i-segment.seg-grey {
  background-color: #eee;
}

.profile.i-menu {
  border-radius: 0;
  border-right: 0;
}

.profile.i-menu i {
  margin-left: 1.5rem;
  margin-right: 1.5rem;
}

.profile.i-menu a {
  color: #333;
}

.profile.i-menu a.active {
  background-color: #eee;
}

.profile.i-menu a.item:after {
  content: '';
}

.profile-upload {
  display: inline-block;
  padding: 0;
  cursor: pointer;
  color: #c0c0c0;
  background-color: #eee;
  text-align: center;
  width: 100%;
  height: 100%;
  position: relative;
  text-align: center;
  padding-top: 20%;
}

.display {
  height: 12rem;
  width: 12rem;
  border: 1px dashed #ccc;
  overflow: hidden;
  position: relative;
  display: block;
}

.display img {
  margin: auto;
  position: absolute;
  top: -50%;
  bottom: -50%;
  left: -50%;
  right: -50%;
  z-index: 1;
  pointer-events: none;
}

.display .landscape {
  max-height: 100%;
}

.display .portrait {
  max-width: 100%;
}

/*New Nav Bar*/
.nav-new {
  margin: 0;
}

.fixed-top-new {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
}

.z-index-class-new {
  z-index: 1030;
}

.navbar-new {
  padding: 40px 10px;
  background-color: #fff;
  border-bottom: 0.5px solid #E6E6E6;
  height: 52px;
  border-radius: 0;
  display: flex;
  align-items: center;
}

@media (max-width: 1144px) {
  .navbar-new {
    padding: 0px 10px;
  }
}

@media (max-width: 320px) {
  .navbar-new {
    padding: 0px;
  }
}

@media (max-width: 991px) {
  .navbar-new {
    height: 71px;
  }
}

@media (min-width: 1025px) {
  .mobile-header-items-new {
    display: none;
  }
}

.navbar-new > .container-full-new {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.container-full-new {
  width: 100%;
  height: 100%;
  margin-right: auto;
  margin-left: auto;
}

.container-full-new .nav-header {
  font-family: "Poppins", sans-serif;
  font-size: 15px;
  font-weight: 600;
  margin-top: 10px;
  margin-bottom: 10px;
  line-height: 1.2;
}

.container-full-new .menu-agent p {
  font-family: "Poppins", sans-serif;
}

.navbar-expand-lg-new > .container-full-new {
  flex-wrap: nowrap;
}

@media (min-width: 1200px) {
  .container-full-new {
    max-width: 1350px;
    padding: 0px 0px;
  }
}

@media (min-width: 1025px) {
  .container-full-new {
    padding: 0px 0px;
  }
}

.navbar-brand-new {
  white-space: nowrap;
  flex: 0 0 15%;
  text-align: center;
}

@media (max-width: 1144px) {
  .navbar-brand-new {
    flex: 0 0 10%;
  }
}

.navbar-brand-new img {
  height: 30px !important;
  max-width: 119px !important;
}

@media (min-width: 1120px) {
  .navbar-brand-new img {
    height: 40px !important;
    max-width: 100% !important;
  }
}

.navbar-light-new .navbar-brand-new {
  color: rgba(0, 0, 0, 0.9);
}

.navbar-collapse-new {
  flex-grow: 1;
  align-items: center;
}

@media (min-width: 1025px) {
  .navbar-collapse-new {
    display: flex !important;
    flex-basis: auto;
    flex: 0 0 45%;
  }
  .navbar-collapse-new.nav-left {
    justify-content: flex-start;
    flex: 0 0 38%;
  }
  .navbar-collapse-new.nav-right {
    justify-content: flex-end;
    flex: 0 0 50%;
  }
  .navbar-collapse-new .dropdown-menu {
    top: 84%;
  }
}

.ml-auto-new, .mx-auto-new {
  margin-left: auto !important;
}

@media (min-width: 1025px) {
  .navbar-expand-lg-new .navbar-nav-new {
    flex-direction: row;
  }
}

.navbar-nav-new {
  display: flex;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}

.mega-dropdown {
  position: static;
}

.mega-dropdown:hover .dropdown-menu {
  padding: 20px 0;
  margin: 0;
  border: 0;
  min-width: 100%;
  display: block;
  border-radius: 0;
  box-shadow: 0px 10px 10px rgba(0, 0, 0, 0.06);
}

.mega-dropdown .menu-container {
  width: 100% !important;
  padding: 0 35px;
  /* safari fix */
}

.mega-dropdown .menu-container > .row {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}

.mega-dropdown .menu-container .row:before, .mega-dropdown .menu-container .row:after {
  display: none !important;
}

.mega-dropdown .menu-sub-section {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 20%;
  flex: 0 0 20%;
  max-width: 20%;
  border-right: 1px solid rgba(0, 0, 0, 0.1);
}

.mega-dropdown .menu-sub-section .dropdown-item {
  font-size: 13px;
  color: #414141;
  padding: 7px 5px;
  display: block;
  white-space: unset !important;
  transition: all 0.5s ease;
}

.mega-dropdown .menu-sub-section .dropdown-item:hover {
  padding-left: 7px;
  text-decoration: none;
  background-color: #e1e1e1;
  border-radius: 5px;
}

.mega-dropdown .menu-sub-section--no-title {
  padding-top: 28px;
}

.mega-dropdown .menu-sub-section:last-child {
  border-right: 0;
}

.nav-link-new {
  font-weight: 400;
  /*color : rgba(60, 71, 85, 1);*/
  color: #000 !important;
  transition: all 0.1s ease;
  border-bottom: 2px solid transparent;
}

@media (min-width: 1368px) {
  .nav-link-new {
    font-size: 14.5px;
  }
}

.nav-link-new.active, .nav-link-new:hover:not(.active) {
  color: #2056ab !important;
  border-bottom: 2px solid #2056ab;
  border-radius: 0;
}

@media (min-width: 1025px) {
  .nav-link-new {
    padding: 8px 0px !important;
    margin: 8px 17px;
    display: block;
  }
}

.nav-link-new.webui-news-search {
  padding: 8px 20px !important;
}

.nav-link-new.active {
  text-decoration: none;
}

.nav-link-new:focus {
  text-decoration: none;
}

.separator-class-new {
  width: 3px;
  -ms-flex: 0 0 1px;
  flex: 0 0 1px;
  background-color: #c1c1c3;
  margin: 8px 0;
  pointer-events: none;
}

.btn-menu-new {
  background-color: #fff;
  width: 35px !important;
  height: 49px !important;
  font-size: 160% !important;
}

.btn-menu-decoration-new {
  font-size: 30px;
  color: #808080ba;
}

#sidebarCollapse-new.btn:active {
  box-shadow: none;
}

.fa-navicon:before, .fa-reorder:before, .fa-bars:before {
  font-size: 115%;
}

.padding-4 {
  padding: 4px;
}

.shortlistbar-button-new {
  background-color: transparent;
  border: 0;
  padding: 4px 8px;
}

.fav-size-head-new {
  padding-top: 0;
  font-size: 22px;
  vertical-align: middle;
  line-height: 22px;
  color: #ff5a60;
}

.savedsearch-link-new {
  font-family: 'Poppins', sans-serif;
  font-size: 14.5px;
  vertical-align: middle;
  color: rgba(0, 0, 0, 0.7) !important;
  background-color: transparent;
  text-decoration: none;
  padding: 0 10px;
}

.savedsearch-link-new:hover {
  text-decoration: none;
}

.iconColor {
  color: #ccc;
  cursor: pointer;
  padding-top: 0;
  font-size: 26px;
  vertical-align: middle;
  line-height: 26px;
}

.drpdown-position-new {
  position: absolute;
}

.dropdown-menu-bar-new {
  position: absolute;
  float: none;
  top: -178%;
  z-index: 1000;
  display: none;
  min-width: 16rem;
  margin-right: -38px;
  /*padding: 0.5rem 0;*/
  /*margin: 0.125rem 0 0;*/
  /*font-size: 1rem;*/
  color: #212529;
  text-align: left;
  list-style: none;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 0.25rem;
}

.dropdown-menu-right-new {
  margin-top: 15px;
  right: 0;
  left: auto;
}

.dropdown-divider-new {
  height: 0;
  margin: 0.5rem 0;
  overflow: hidden;
  border-top: 1px solid #e9ecef;
}

.dropdown-item-bar-new {
  display: block;
  width: 100%;
  padding: 1.10rem 2.5rem;
  clear: both;
  font-weight: 400;
  font-size: 15px;
  color: #212529;
  text-align: inherit;
  white-space: nowrap;
  background-color: transparent;
  border: 0;
}

.dropdown-item-bar-new:hover {
  color: #16181b;
  text-decoration: none;
  background-color: #f8f9fa;
}

.drop-user-active {
  display: block;
}

.btn-logout-new {
  font-size: 14px;
  text-align: center;
  font-weight: 600;
  padding-left: 0;
  padding-right: 0;
  line-height: 25px;
}

/*Side Bar*/
.no-gutters-new {
  margin-right: 0;
  margin-left: 0;
}

#sidebar-new {
  width: 293px;
  position: fixed;
  top: 0;
  right: -720px;
  height: 100vh;
  z-index: 9999;
  background: #FFFFFF;
  color: #fff;
  transition: all 0.3s;
  box-shadow: 3px 3px 3px rgba(0, 0, 0, 0.2);
}

.overlay-new {
  top: 0;
  position: fixed;
  /* full screen */
  width: 100vw;
  height: 100vh;
  /* transparent black */
  background: rgba(0, 0, 0, 0.7);
  /* middle layer, i.e. appears below the sidebar */
  z-index: 9998;
  opacity: 1;
  /* animate the transition */
  transition: all 0.5s ease-in-out;
}

.overlay-new-transparent {
  top: 0;
  position: fixed;
  /* full screen */
  width: 100vw;
  height: 100vh;
  /* transparent black */
  background: transparent;
  /* middle layer, i.e. appears below the sidebar */
  z-index: 9998;
  opacity: 1;
  /* animate the transition */
  transition: all 0.5s ease-in-out;
}

#sidebar-new.active {
  right: 0;
}

.sidebar-component-new {
  color: #414141;
}

.sidebar-main-new {
  padding: 0 0 20px 0;
}

.sidebar-component-new .sidebar-head-new {
  border-bottom: 1px solid #ccc;
}

.sidebar-close-new {
  color: #414141;
  text-align: right;
  padding: 10px 10px 10px 10px;
}

.sidebar-close-new-icon {
  font-size: 25px;
  color: #488BF8;
  vertical-align: middle;
}

.sidebar-close-new-icon:hover {
  color: #495057;
  text-decoration: none;
  background-color: #f8f9fa;
  cursor: pointer;
}

.sidebar-close-new:hover {
  color: #495057;
  text-decoration: none;
  background-color: #f8f9fa;
  cursor: pointer;
}

.sidebar-getstarted-new {
  color: #414141;
  text-align: right;
  padding: 5px 10px 0px 0px;
}

.sidebar-getstarted-new .right-div-new {
  margin-right: 5px;
}

.sidebar-getstarted-new .right-div-new .power-btn {
  vertical-align: middle;
  font-size: 22px;
  line-height: 14px;
}

.sidebar-getstarted-new .login {
  text-transform: uppercase;
  font-size: 14px;
  color: #488BF8;
  font-weight: 600;
  vertical-align: middle;
  line-height: 25px;
}

.sidebar-getstarted-new .signup {
  text-transform: uppercase;
  font-size: 14px;
  color: #488BF8;
  font-weight: 600;
  vertical-align: middle;
  line-height: 25px;
}

.sidebar-getstarted-new .btn-link-login-new {
  font-weight: 600;
  padding-left: 0;
  padding-right: 0;
  font-size: 14px;
  line-height: 25px;
}

.sidebar-getstarted-new .btn-link-signup-new {
  font-weight: 600;
  padding-left: 0;
  padding-right: 0;
  font-size: 14px;
  line-height: 25px;
}

.sidebar-getstarted-new .btn-link-new {
  color: #488BF8;
  font-weight: 600;
  background-color: #fff;
}

.menu-group-new {
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
}

.menu-group-item-new {
  position: relative;
  display: block;
  font-size: 14px;
  line-height: 13px;
  color: #414141;
  padding: 1.75rem 2rem;
  margin-bottom: -1px;
}

.menu-group-item-new.active {
  z-index: 2;
  color: #fff;
  background-color: #488BF8;
  border-color: #488BF8;
}

.menu-group-item-new:hover {
  color: #495057;
  text-decoration: none;
  background-color: #f8f9fa;
}

div.dropdown-menu-new {
  position: static !important;
  transform: none !important;
  width: 100%;
  font-size: 14px;
  line-height: 13px;
  color: #414141;
  padding: 0px 0px;
  margin-bottom: -1px;
  box-shadow: none;
  border: none;
}

.dropdown-item-new {
  padding: 1.3rem 1.25rem !important;
}

.menu-group-item-new {
  text-transform: uppercase;
  font-weight: 600;
  color: #414141;
}

a#sidebar-dropdown-new.dropdown-toggle-new:after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.35em solid;
  border-right: 0.35em solid transparent;
  border-bottom: 0;
  border-left: 0.35em solid transparent;
  float: right;
  position: relative;
  top: 8px;
  left: 14px;
}

#sidebar-dropdown-new {
  font-size: 14px;
  font-weight: 600;
  color: #414141;
  text-transform: uppercase;
  padding: 17px 1.25rem !important;
  display: block !important;
  text-decoration: none;
  /*margin-bottom: -1px;  */
}

#sidebar-dropdown-new.active {
  z-index: 2;
  color: #fff;
  background-color: #488BF8;
  border-color: #488BF8;
  display: block !important;
  text-decoration: none;
}

#sidebar-dropdown-new:hover {
  color: #495057 !important;
  text-decoration: none;
  background-color: #f8f9fa;
  display: block !important;
  text-decoration: none;
}

.nav-item-new {
  padding: 7px 0px;
  padding-right: 0px;
}

a.dropdown-item-new {
  font-size: 13px;
  background-color: #fafafa;
  display: block !important;
  padding-left: 20px !important;
}

a.dropdown-item-new:hover {
  text-decoration: none;
  color: #16181b;
}

/*.dropdown-menu.show {
  border-radius: 0px;
  border : 0px;
  padding: 0px 0px;
  a:active {
    color: #212529;
  }
}*/
a#sidebar-dropdown-new {
  margin-bottom: -2px;
  padding-left: 20px !important;
  padding-right: 20px !important;
}

.sidebar-component-new .dropdown-new.nav-item-new {
  padding: 1px 0px;
  padding-right: 0px;
}

.dropdown-new {
  position: relative;
}

.show-menu {
  display: block;
}

.hide-menu-new {
  display: none;
}

.scroll-vertical-new {
  overflow-x: hidden;
  overflow-y: auto;
  max-height: 100%;
}

.sidebar-close-icon-new,
.sidebar-close-button {
  font-size: 25px;
  color: #488BF8;
  vertical-align: middle;
}

a#list-settings-list {
  color: rgba(0, 0, 0, 0.7);
}

.ham-btn {
  width: 20px;
  height: 24px;
  vertical-align: middle;
  content: "";
  background: no-repeat center center;
  background-size: 100% 100%;
  background-image: url(https://media.edgeprop.my/assets/icons/hamburger-btn.svg);
  margin-top: -2px;
  display: block;
}

@media (min-width: 1025px) {
  #sidebarCollapse-new {
    position: relative;
    top: 11px;
  }
  .flex-fill.bd-highlight {
    position: relative;
    top: 7px;
  }
  .savedsearch-link-new {
    position: relative;
    top: 11px;
  }
  .dropdown-new.nav-item-new {
    position: relative;
    top: 23px;
    left: -40px;
  }
}

.avatar-img {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  object-fit: cover;
  object-position: center;
}

body .subs-news {
  display: none;
}

@media (max-width: 768px) {
  body.page-news .container-full-new.header-mobile-view, body.node-type-tep-blogs .container-full-new.header-mobile-view, body.page-guides .container-full-new.header-mobile-view, body.page-edgeproptv .container-full-new.header-mobile-view, body.page-pullout .container-full-new.header-mobile-view, body.page-events .container-full-new.header-mobile-view, body.page-event .container-full-new.header-mobile-view, body.page-tags .container-full-new.header-mobile-view, body.page-node-29 .container-full-new.header-mobile-view, body.page-node-34 .container-full-new.header-mobile-view, body.page-content .container-full-new.header-mobile-view {
    padding: 0 10px;
  }
}

@media (max-width: 1024px) {
  body.page-news .container-full-new.header-mobile-view .navbar-brand-new .logo-mobile, body.node-type-tep-blogs .container-full-new.header-mobile-view .navbar-brand-new .logo-mobile, body.page-guides .container-full-new.header-mobile-view .navbar-brand-new .logo-mobile, body.page-edgeproptv .container-full-new.header-mobile-view .navbar-brand-new .logo-mobile, body.page-pullout .container-full-new.header-mobile-view .navbar-brand-new .logo-mobile, body.page-events .container-full-new.header-mobile-view .navbar-brand-new .logo-mobile, body.page-event .container-full-new.header-mobile-view .navbar-brand-new .logo-mobile, body.page-tags .container-full-new.header-mobile-view .navbar-brand-new .logo-mobile, body.page-node-29 .container-full-new.header-mobile-view .navbar-brand-new .logo-mobile, body.page-node-34 .container-full-new.header-mobile-view .navbar-brand-new .logo-mobile, body.page-content .container-full-new.header-mobile-view .navbar-brand-new .logo-mobile {
    position: relative;
    left: 100%;
  }
}

@media (max-width: 912px) {
  body.page-news .container-full-new.header-mobile-view .navbar-brand-new .logo-mobile, body.node-type-tep-blogs .container-full-new.header-mobile-view .navbar-brand-new .logo-mobile, body.page-guides .container-full-new.header-mobile-view .navbar-brand-new .logo-mobile, body.page-edgeproptv .container-full-new.header-mobile-view .navbar-brand-new .logo-mobile, body.page-pullout .container-full-new.header-mobile-view .navbar-brand-new .logo-mobile, body.page-events .container-full-new.header-mobile-view .navbar-brand-new .logo-mobile, body.page-event .container-full-new.header-mobile-view .navbar-brand-new .logo-mobile, body.page-tags .container-full-new.header-mobile-view .navbar-brand-new .logo-mobile, body.page-node-29 .container-full-new.header-mobile-view .navbar-brand-new .logo-mobile, body.page-node-34 .container-full-new.header-mobile-view .navbar-brand-new .logo-mobile, body.page-content .container-full-new.header-mobile-view .navbar-brand-new .logo-mobile {
    left: 84%;
  }
}

@media (max-width: 820px) {
  body.page-news .container-full-new.header-mobile-view .navbar-brand-new .logo-mobile, body.node-type-tep-blogs .container-full-new.header-mobile-view .navbar-brand-new .logo-mobile, body.page-guides .container-full-new.header-mobile-view .navbar-brand-new .logo-mobile, body.page-edgeproptv .container-full-new.header-mobile-view .navbar-brand-new .logo-mobile, body.page-pullout .container-full-new.header-mobile-view .navbar-brand-new .logo-mobile, body.page-events .container-full-new.header-mobile-view .navbar-brand-new .logo-mobile, body.page-event .container-full-new.header-mobile-view .navbar-brand-new .logo-mobile, body.page-tags .container-full-new.header-mobile-view .navbar-brand-new .logo-mobile, body.page-node-29 .container-full-new.header-mobile-view .navbar-brand-new .logo-mobile, body.page-node-34 .container-full-new.header-mobile-view .navbar-brand-new .logo-mobile, body.page-content .container-full-new.header-mobile-view .navbar-brand-new .logo-mobile {
    left: 65%;
  }
}

@media (max-width: 767px) {
  body.page-news .container-full-new.header-mobile-view .navbar-brand-new .logo-mobile, body.node-type-tep-blogs .container-full-new.header-mobile-view .navbar-brand-new .logo-mobile, body.page-guides .container-full-new.header-mobile-view .navbar-brand-new .logo-mobile, body.page-edgeproptv .container-full-new.header-mobile-view .navbar-brand-new .logo-mobile, body.page-pullout .container-full-new.header-mobile-view .navbar-brand-new .logo-mobile, body.page-events .container-full-new.header-mobile-view .navbar-brand-new .logo-mobile, body.page-event .container-full-new.header-mobile-view .navbar-brand-new .logo-mobile, body.page-tags .container-full-new.header-mobile-view .navbar-brand-new .logo-mobile, body.page-node-29 .container-full-new.header-mobile-view .navbar-brand-new .logo-mobile, body.page-node-34 .container-full-new.header-mobile-view .navbar-brand-new .logo-mobile, body.page-content .container-full-new.header-mobile-view .navbar-brand-new .logo-mobile {
    left: 13%;
  }
}

@media (max-width: 1024px) {
  body.page-news .container-full-new.header-mobile-view .m-news-search .header-search-mazada, body.node-type-tep-blogs .container-full-new.header-mobile-view .m-news-search .header-search-mazada, body.page-guides .container-full-new.header-mobile-view .m-news-search .header-search-mazada, body.page-edgeproptv .container-full-new.header-mobile-view .m-news-search .header-search-mazada, body.page-pullout .container-full-new.header-mobile-view .m-news-search .header-search-mazada, body.page-events .container-full-new.header-mobile-view .m-news-search .header-search-mazada, body.page-event .container-full-new.header-mobile-view .m-news-search .header-search-mazada, body.page-tags .container-full-new.header-mobile-view .m-news-search .header-search-mazada, body.page-node-29 .container-full-new.header-mobile-view .m-news-search .header-search-mazada, body.page-node-34 .container-full-new.header-mobile-view .m-news-search .header-search-mazada, body.page-content .container-full-new.header-mobile-view .m-news-search .header-search-mazada {
    font-size: 20px;
    right: -218px;
  }
}

@media (max-width: 946px) {
  body.page-news .container-full-new.header-mobile-view .m-news-search .header-search-mazada, body.node-type-tep-blogs .container-full-new.header-mobile-view .m-news-search .header-search-mazada, body.page-guides .container-full-new.header-mobile-view .m-news-search .header-search-mazada, body.page-edgeproptv .container-full-new.header-mobile-view .m-news-search .header-search-mazada, body.page-pullout .container-full-new.header-mobile-view .m-news-search .header-search-mazada, body.page-events .container-full-new.header-mobile-view .m-news-search .header-search-mazada, body.page-event .container-full-new.header-mobile-view .m-news-search .header-search-mazada, body.page-tags .container-full-new.header-mobile-view .m-news-search .header-search-mazada, body.page-node-29 .container-full-new.header-mobile-view .m-news-search .header-search-mazada, body.page-node-34 .container-full-new.header-mobile-view .m-news-search .header-search-mazada, body.page-content .container-full-new.header-mobile-view .m-news-search .header-search-mazada {
    right: -200px;
  }
}

@media (max-width: 900px) {
  body.page-news .container-full-new.header-mobile-view .m-news-search .header-search-mazada, body.node-type-tep-blogs .container-full-new.header-mobile-view .m-news-search .header-search-mazada, body.page-guides .container-full-new.header-mobile-view .m-news-search .header-search-mazada, body.page-edgeproptv .container-full-new.header-mobile-view .m-news-search .header-search-mazada, body.page-pullout .container-full-new.header-mobile-view .m-news-search .header-search-mazada, body.page-events .container-full-new.header-mobile-view .m-news-search .header-search-mazada, body.page-event .container-full-new.header-mobile-view .m-news-search .header-search-mazada, body.page-tags .container-full-new.header-mobile-view .m-news-search .header-search-mazada, body.page-node-29 .container-full-new.header-mobile-view .m-news-search .header-search-mazada, body.page-node-34 .container-full-new.header-mobile-view .m-news-search .header-search-mazada, body.page-content .container-full-new.header-mobile-view .m-news-search .header-search-mazada {
    right: -189px;
  }
}

@media (max-width: 860px) {
  body.page-news .container-full-new.header-mobile-view .m-news-search .header-search-mazada, body.node-type-tep-blogs .container-full-new.header-mobile-view .m-news-search .header-search-mazada, body.page-guides .container-full-new.header-mobile-view .m-news-search .header-search-mazada, body.page-edgeproptv .container-full-new.header-mobile-view .m-news-search .header-search-mazada, body.page-pullout .container-full-new.header-mobile-view .m-news-search .header-search-mazada, body.page-events .container-full-new.header-mobile-view .m-news-search .header-search-mazada, body.page-event .container-full-new.header-mobile-view .m-news-search .header-search-mazada, body.page-tags .container-full-new.header-mobile-view .m-news-search .header-search-mazada, body.page-node-29 .container-full-new.header-mobile-view .m-news-search .header-search-mazada, body.page-node-34 .container-full-new.header-mobile-view .m-news-search .header-search-mazada, body.page-content .container-full-new.header-mobile-view .m-news-search .header-search-mazada {
    right: -169px;
  }
}

@media (max-width: 820px) {
  body.page-news .container-full-new.header-mobile-view .m-news-search .header-search-mazada, body.node-type-tep-blogs .container-full-new.header-mobile-view .m-news-search .header-search-mazada, body.page-guides .container-full-new.header-mobile-view .m-news-search .header-search-mazada, body.page-edgeproptv .container-full-new.header-mobile-view .m-news-search .header-search-mazada, body.page-pullout .container-full-new.header-mobile-view .m-news-search .header-search-mazada, body.page-events .container-full-new.header-mobile-view .m-news-search .header-search-mazada, body.page-event .container-full-new.header-mobile-view .m-news-search .header-search-mazada, body.page-tags .container-full-new.header-mobile-view .m-news-search .header-search-mazada, body.page-node-29 .container-full-new.header-mobile-view .m-news-search .header-search-mazada, body.page-node-34 .container-full-new.header-mobile-view .m-news-search .header-search-mazada, body.page-content .container-full-new.header-mobile-view .m-news-search .header-search-mazada {
    right: -160px;
  }
}

@media (max-width: 768px) {
  body.page-news .container-full-new.header-mobile-view .m-news-search .header-search-mazada, body.node-type-tep-blogs .container-full-new.header-mobile-view .m-news-search .header-search-mazada, body.page-guides .container-full-new.header-mobile-view .m-news-search .header-search-mazada, body.page-edgeproptv .container-full-new.header-mobile-view .m-news-search .header-search-mazada, body.page-pullout .container-full-new.header-mobile-view .m-news-search .header-search-mazada, body.page-events .container-full-new.header-mobile-view .m-news-search .header-search-mazada, body.page-event .container-full-new.header-mobile-view .m-news-search .header-search-mazada, body.page-tags .container-full-new.header-mobile-view .m-news-search .header-search-mazada, body.page-node-29 .container-full-new.header-mobile-view .m-news-search .header-search-mazada, body.page-node-34 .container-full-new.header-mobile-view .m-news-search .header-search-mazada, body.page-content .container-full-new.header-mobile-view .m-news-search .header-search-mazada {
    right: -137px;
  }
}

@media (max-width: 740px) {
  body.page-news .container-full-new.header-mobile-view .m-news-search .header-search-mazada, body.node-type-tep-blogs .container-full-new.header-mobile-view .m-news-search .header-search-mazada, body.page-guides .container-full-new.header-mobile-view .m-news-search .header-search-mazada, body.page-edgeproptv .container-full-new.header-mobile-view .m-news-search .header-search-mazada, body.page-pullout .container-full-new.header-mobile-view .m-news-search .header-search-mazada, body.page-events .container-full-new.header-mobile-view .m-news-search .header-search-mazada, body.page-event .container-full-new.header-mobile-view .m-news-search .header-search-mazada, body.page-tags .container-full-new.header-mobile-view .m-news-search .header-search-mazada, body.page-node-29 .container-full-new.header-mobile-view .m-news-search .header-search-mazada, body.page-node-34 .container-full-new.header-mobile-view .m-news-search .header-search-mazada, body.page-content .container-full-new.header-mobile-view .m-news-search .header-search-mazada {
    right: -129px;
  }
}

@media (max-width: 720px) {
  body.page-news .container-full-new.header-mobile-view .m-news-search .header-search-mazada, body.node-type-tep-blogs .container-full-new.header-mobile-view .m-news-search .header-search-mazada, body.page-guides .container-full-new.header-mobile-view .m-news-search .header-search-mazada, body.page-edgeproptv .container-full-new.header-mobile-view .m-news-search .header-search-mazada, body.page-pullout .container-full-new.header-mobile-view .m-news-search .header-search-mazada, body.page-events .container-full-new.header-mobile-view .m-news-search .header-search-mazada, body.page-event .container-full-new.header-mobile-view .m-news-search .header-search-mazada, body.page-tags .container-full-new.header-mobile-view .m-news-search .header-search-mazada, body.page-node-29 .container-full-new.header-mobile-view .m-news-search .header-search-mazada, body.page-node-34 .container-full-new.header-mobile-view .m-news-search .header-search-mazada, body.page-content .container-full-new.header-mobile-view .m-news-search .header-search-mazada {
    right: -120px;
  }
}

@media (max-width: 700px) {
  body.page-news .container-full-new.header-mobile-view .m-news-search .header-search-mazada, body.node-type-tep-blogs .container-full-new.header-mobile-view .m-news-search .header-search-mazada, body.page-guides .container-full-new.header-mobile-view .m-news-search .header-search-mazada, body.page-edgeproptv .container-full-new.header-mobile-view .m-news-search .header-search-mazada, body.page-pullout .container-full-new.header-mobile-view .m-news-search .header-search-mazada, body.page-events .container-full-new.header-mobile-view .m-news-search .header-search-mazada, body.page-event .container-full-new.header-mobile-view .m-news-search .header-search-mazada, body.page-tags .container-full-new.header-mobile-view .m-news-search .header-search-mazada, body.page-node-29 .container-full-new.header-mobile-view .m-news-search .header-search-mazada, body.page-node-34 .container-full-new.header-mobile-view .m-news-search .header-search-mazada, body.page-content .container-full-new.header-mobile-view .m-news-search .header-search-mazada {
    right: -110px;
  }
}

@media (max-width: 680px) {
  body.page-news .container-full-new.header-mobile-view .m-news-search .header-search-mazada, body.node-type-tep-blogs .container-full-new.header-mobile-view .m-news-search .header-search-mazada, body.page-guides .container-full-new.header-mobile-view .m-news-search .header-search-mazada, body.page-edgeproptv .container-full-new.header-mobile-view .m-news-search .header-search-mazada, body.page-pullout .container-full-new.header-mobile-view .m-news-search .header-search-mazada, body.page-events .container-full-new.header-mobile-view .m-news-search .header-search-mazada, body.page-event .container-full-new.header-mobile-view .m-news-search .header-search-mazada, body.page-tags .container-full-new.header-mobile-view .m-news-search .header-search-mazada, body.page-node-29 .container-full-new.header-mobile-view .m-news-search .header-search-mazada, body.page-node-34 .container-full-new.header-mobile-view .m-news-search .header-search-mazada, body.page-content .container-full-new.header-mobile-view .m-news-search .header-search-mazada {
    right: -100px;
  }
}

@media (max-width: 650px) {
  body.page-news .container-full-new.header-mobile-view .m-news-search .header-search-mazada, body.node-type-tep-blogs .container-full-new.header-mobile-view .m-news-search .header-search-mazada, body.page-guides .container-full-new.header-mobile-view .m-news-search .header-search-mazada, body.page-edgeproptv .container-full-new.header-mobile-view .m-news-search .header-search-mazada, body.page-pullout .container-full-new.header-mobile-view .m-news-search .header-search-mazada, body.page-events .container-full-new.header-mobile-view .m-news-search .header-search-mazada, body.page-event .container-full-new.header-mobile-view .m-news-search .header-search-mazada, body.page-tags .container-full-new.header-mobile-view .m-news-search .header-search-mazada, body.page-node-29 .container-full-new.header-mobile-view .m-news-search .header-search-mazada, body.page-node-34 .container-full-new.header-mobile-view .m-news-search .header-search-mazada, body.page-content .container-full-new.header-mobile-view .m-news-search .header-search-mazada {
    right: -90px;
  }
}

@media (max-width: 610px) {
  body.page-news .container-full-new.header-mobile-view .m-news-search .header-search-mazada, body.node-type-tep-blogs .container-full-new.header-mobile-view .m-news-search .header-search-mazada, body.page-guides .container-full-new.header-mobile-view .m-news-search .header-search-mazada, body.page-edgeproptv .container-full-new.header-mobile-view .m-news-search .header-search-mazada, body.page-pullout .container-full-new.header-mobile-view .m-news-search .header-search-mazada, body.page-events .container-full-new.header-mobile-view .m-news-search .header-search-mazada, body.page-event .container-full-new.header-mobile-view .m-news-search .header-search-mazada, body.page-tags .container-full-new.header-mobile-view .m-news-search .header-search-mazada, body.page-node-29 .container-full-new.header-mobile-view .m-news-search .header-search-mazada, body.page-node-34 .container-full-new.header-mobile-view .m-news-search .header-search-mazada, body.page-content .container-full-new.header-mobile-view .m-news-search .header-search-mazada {
    right: -78px;
  }
}

@media (max-width: 550px) {
  body.page-news .container-full-new.header-mobile-view .m-news-search .header-search-mazada, body.node-type-tep-blogs .container-full-new.header-mobile-view .m-news-search .header-search-mazada, body.page-guides .container-full-new.header-mobile-view .m-news-search .header-search-mazada, body.page-edgeproptv .container-full-new.header-mobile-view .m-news-search .header-search-mazada, body.page-pullout .container-full-new.header-mobile-view .m-news-search .header-search-mazada, body.page-events .container-full-new.header-mobile-view .m-news-search .header-search-mazada, body.page-event .container-full-new.header-mobile-view .m-news-search .header-search-mazada, body.page-tags .container-full-new.header-mobile-view .m-news-search .header-search-mazada, body.page-node-29 .container-full-new.header-mobile-view .m-news-search .header-search-mazada, body.page-node-34 .container-full-new.header-mobile-view .m-news-search .header-search-mazada, body.page-content .container-full-new.header-mobile-view .m-news-search .header-search-mazada {
    right: -68px;
  }
}

@media (max-width: 425px) {
  body.page-news .container-full-new.header-mobile-view .m-news-search .header-search-mazada, body.node-type-tep-blogs .container-full-new.header-mobile-view .m-news-search .header-search-mazada, body.page-guides .container-full-new.header-mobile-view .m-news-search .header-search-mazada, body.page-edgeproptv .container-full-new.header-mobile-view .m-news-search .header-search-mazada, body.page-pullout .container-full-new.header-mobile-view .m-news-search .header-search-mazada, body.page-events .container-full-new.header-mobile-view .m-news-search .header-search-mazada, body.page-event .container-full-new.header-mobile-view .m-news-search .header-search-mazada, body.page-tags .container-full-new.header-mobile-view .m-news-search .header-search-mazada, body.page-node-29 .container-full-new.header-mobile-view .m-news-search .header-search-mazada, body.page-node-34 .container-full-new.header-mobile-view .m-news-search .header-search-mazada, body.page-content .container-full-new.header-mobile-view .m-news-search .header-search-mazada {
    right: -36px;
  }
}

@media (max-width: 510px) {
  body.page-news .container-full-new.header-mobile-view .m-news-search .header-search-mazada, body.node-type-tep-blogs .container-full-new.header-mobile-view .m-news-search .header-search-mazada, body.page-guides .container-full-new.header-mobile-view .m-news-search .header-search-mazada, body.page-edgeproptv .container-full-new.header-mobile-view .m-news-search .header-search-mazada, body.page-pullout .container-full-new.header-mobile-view .m-news-search .header-search-mazada, body.page-events .container-full-new.header-mobile-view .m-news-search .header-search-mazada, body.page-event .container-full-new.header-mobile-view .m-news-search .header-search-mazada, body.page-tags .container-full-new.header-mobile-view .m-news-search .header-search-mazada, body.page-node-29 .container-full-new.header-mobile-view .m-news-search .header-search-mazada, body.page-node-34 .container-full-new.header-mobile-view .m-news-search .header-search-mazada, body.page-content .container-full-new.header-mobile-view .m-news-search .header-search-mazada {
    right: -48px;
  }
}

@media (max-width: 414px) {
  body.page-news .container-full-new.header-mobile-view .m-news-search .header-search-mazada, body.node-type-tep-blogs .container-full-new.header-mobile-view .m-news-search .header-search-mazada, body.page-guides .container-full-new.header-mobile-view .m-news-search .header-search-mazada, body.page-edgeproptv .container-full-new.header-mobile-view .m-news-search .header-search-mazada, body.page-pullout .container-full-new.header-mobile-view .m-news-search .header-search-mazada, body.page-events .container-full-new.header-mobile-view .m-news-search .header-search-mazada, body.page-event .container-full-new.header-mobile-view .m-news-search .header-search-mazada, body.page-tags .container-full-new.header-mobile-view .m-news-search .header-search-mazada, body.page-node-29 .container-full-new.header-mobile-view .m-news-search .header-search-mazada, body.page-node-34 .container-full-new.header-mobile-view .m-news-search .header-search-mazada, body.page-content .container-full-new.header-mobile-view .m-news-search .header-search-mazada {
    right: -27px;
  }
}

@media (max-width: 390px) {
  body.page-news .container-full-new.header-mobile-view .m-news-search .header-search-mazada, body.node-type-tep-blogs .container-full-new.header-mobile-view .m-news-search .header-search-mazada, body.page-guides .container-full-new.header-mobile-view .m-news-search .header-search-mazada, body.page-edgeproptv .container-full-new.header-mobile-view .m-news-search .header-search-mazada, body.page-pullout .container-full-new.header-mobile-view .m-news-search .header-search-mazada, body.page-events .container-full-new.header-mobile-view .m-news-search .header-search-mazada, body.page-event .container-full-new.header-mobile-view .m-news-search .header-search-mazada, body.page-tags .container-full-new.header-mobile-view .m-news-search .header-search-mazada, body.page-node-29 .container-full-new.header-mobile-view .m-news-search .header-search-mazada, body.page-node-34 .container-full-new.header-mobile-view .m-news-search .header-search-mazada, body.page-content .container-full-new.header-mobile-view .m-news-search .header-search-mazada {
    right: -22px;
  }
}

@media (max-width: 375px) {
  body.page-news .container-full-new.header-mobile-view .m-news-search .header-search-mazada, body.node-type-tep-blogs .container-full-new.header-mobile-view .m-news-search .header-search-mazada, body.page-guides .container-full-new.header-mobile-view .m-news-search .header-search-mazada, body.page-edgeproptv .container-full-new.header-mobile-view .m-news-search .header-search-mazada, body.page-pullout .container-full-new.header-mobile-view .m-news-search .header-search-mazada, body.page-events .container-full-new.header-mobile-view .m-news-search .header-search-mazada, body.page-event .container-full-new.header-mobile-view .m-news-search .header-search-mazada, body.page-tags .container-full-new.header-mobile-view .m-news-search .header-search-mazada, body.page-node-29 .container-full-new.header-mobile-view .m-news-search .header-search-mazada, body.page-node-34 .container-full-new.header-mobile-view .m-news-search .header-search-mazada, body.page-content .container-full-new.header-mobile-view .m-news-search .header-search-mazada {
    right: -17px;
  }
}

@media (max-width: 320px) {
  body.page-news .container-full-new.header-mobile-view .m-news-search .header-search-mazada, body.node-type-tep-blogs .container-full-new.header-mobile-view .m-news-search .header-search-mazada, body.page-guides .container-full-new.header-mobile-view .m-news-search .header-search-mazada, body.page-edgeproptv .container-full-new.header-mobile-view .m-news-search .header-search-mazada, body.page-pullout .container-full-new.header-mobile-view .m-news-search .header-search-mazada, body.page-events .container-full-new.header-mobile-view .m-news-search .header-search-mazada, body.page-event .container-full-new.header-mobile-view .m-news-search .header-search-mazada, body.page-tags .container-full-new.header-mobile-view .m-news-search .header-search-mazada, body.page-node-29 .container-full-new.header-mobile-view .m-news-search .header-search-mazada, body.page-node-34 .container-full-new.header-mobile-view .m-news-search .header-search-mazada, body.page-content .container-full-new.header-mobile-view .m-news-search .header-search-mazada {
    right: -10px;
  }
}

body.page-news .container-full-new.header-mobile-view .subs-news, body.node-type-tep-blogs .container-full-new.header-mobile-view .subs-news, body.page-guides .container-full-new.header-mobile-view .subs-news, body.page-edgeproptv .container-full-new.header-mobile-view .subs-news, body.page-pullout .container-full-new.header-mobile-view .subs-news, body.page-events .container-full-new.header-mobile-view .subs-news, body.page-event .container-full-new.header-mobile-view .subs-news, body.page-tags .container-full-new.header-mobile-view .subs-news, body.page-node-29 .container-full-new.header-mobile-view .subs-news, body.page-node-34 .container-full-new.header-mobile-view .subs-news, body.page-content .container-full-new.header-mobile-view .subs-news {
  display: none;
}

@media (max-width: 1024px) {
  body.page-news .container-full-new.header-mobile-view .subs-news, body.node-type-tep-blogs .container-full-new.header-mobile-view .subs-news, body.page-guides .container-full-new.header-mobile-view .subs-news, body.page-edgeproptv .container-full-new.header-mobile-view .subs-news, body.page-pullout .container-full-new.header-mobile-view .subs-news, body.page-events .container-full-new.header-mobile-view .subs-news, body.page-event .container-full-new.header-mobile-view .subs-news, body.page-tags .container-full-new.header-mobile-view .subs-news, body.page-node-29 .container-full-new.header-mobile-view .subs-news, body.page-node-34 .container-full-new.header-mobile-view .subs-news, body.page-content .container-full-new.header-mobile-view .subs-news {
    cursor: pointer;
    display: inline;
    border-radius: 5px;
    color: #fff;
    background: #000;
    font-size: 11px;
    font-weight: 600;
    height: 25px;
    line-height: 25px;
    margin-left: 0;
    padding: 4px 9px;
    text-align: center;
    user-select: none;
    width: 73px;
    position: relative;
    top: 2px;
  }
}

@media (max-width: 768px) {
  .navbar-new {
    position: fixed;
    transition: all .3s .2s ease-in-out;
  }
  .navbar-new.in, .navbar-new.out {
    cursor: pointer;
    transition: all .3s .2s ease-in-out;
  }
  .navbar-new.in {
    top: 0;
  }
  .navbar-new.out {
    top: -71px;
  }
}

.page-newspages .content-area {
  background-color: #fff;
}

@media (min-width: 1200px) {
  .page-newspages .content-area .container {
    width: auto;
    max-width: 121rem;
  }
  .page-newspages .content-column {
    padding-right: 3rem;
  }
}

.news-container {
  padding: 2rem;
  border: 0.1rem solid #f7f6f6;
}

.news-item {
  position: relative;
}

.news-thumb img {
  display: block;
  max-width: 100%;
  height: auto;
  margin-left: auto;
  margin-right: auto;
}

.news-title {
  margin-top: 0;
  font-size: 2.4rem;
}

.news-meta span {
  display: inline-block;
}

.news-meta span + span {
  margin-left: 1.2rem;
}

.news-details {
  margin-bottom: 4rem;
}

@media (min-width: 768px) and (max-width: 768px) {
  .news-details {
    padding: 10px;
  }
}

@media (min-width: 992px) {
  .news-details {
    max-width: 610px;
    margin: 0 0 4rem;
  }
}

@media (max-width: 490px) {
  .news-details {
    padding: 0 5px;
  }
}

.news-details img {
  max-width: 100%;
  height: auto !important;
}

.news-details figure {
  margin-top: 0;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 1.34em;
  position: relative;
}

.news-details figure figcaption {
  margin-top: 0.6em;
  font-size: 1.2rem;
  line-height: 1.4;
  color: body-color;
}

.news-details figure figcaption:before {
  font-family: 'FontAwesome';
  content: '\f05a';
  margin-right: 4px;
  font-style: normal;
  font-size: 1.3rem;
}

.news-details figure.pull-left {
  margin-right: 2rem;
}

.news-details figure.pull-right {
  margin-left: 2rem;
}

.news-details figure.first img {
  display: block;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
}

.news-details figure.embed-frame {
  padding-bottom: 53.33333%;
}

.news-details .news-body p a {
  color: #488BF8;
}

.news-details .news-body p a:hover {
  color: #488BF8;
}

.news-details .news-action {
  margin-top: 1.429em;
  margin-bottom: 2.143em;
}

.news-details .news-action:last-child {
  margin-top: 2.143em;
}

.news-details .news-action .social-media > li {
  margin-bottom: 0.2143em;
}

.news-details .news-action .social-media > li.active:not(.close-toggle) {
  display: none;
}

.news-details .news-action .close-toggle .ion-icon {
  display: inline-block;
  font-size: 2.8rem;
  line-height: 1.45;
}

.news-details .news-share {
  display: none;
  margin-left: 0;
  margin-bottom: 0;
  padding-left: 0.5em;
  list-style: none;
}

.news-details .news-share > li {
  padding-left: 0;
  padding-right: 0;
}

.news-details .news-share > li > a {
  display: block;
  min-width: 3.8rem;
  height: 3.8rem;
  padding-left: 1rem;
  padding-right: 1rem;
  background-color: #c9c9c9;
  color: #ffffff;
  line-height: 3.8rem;
  text-align: center;
  border-radius: 3rem;
}

.news-details .news-share > li > a:hover, .news-details .news-share > li > a:focus {
  text-decoration: none;
}

.news-details .news-share > li > a > span {
  margin-left: 0.5rem;
  font-family: "Poppins", sans-serif;
}

.news-details .news-date {
  color: #b1b1b1;
}

.news-details .news-title {
  color: #252525;
  font-size: 4rem;
  font-weight: 600;
  line-height: 1.28;
  text-align: center;
}

.news-details .news-author {
  margin-top: 0.5em;
  margin-bottom: 0.5em;
  font-weight: 400;
}

.news-details .news-author a, .news-details .news-author span {
  font-weight: 600;
}

.news-details .news-cover {
  width: auto;
  margin-top: 2rem;
  margin-bottom: 2rem;
  padding: 0;
}

.news-details .news-cover figcaption .material-icons.info {
  margin-top: -0.2rem;
}

.news-details .news-content {
  padding: 0;
  background-color: #ffffff;
  color: rgba(0, 0, 0, 0.8);
  font-size: 2rem;
  line-height: 1.6;
}

.news-details .news-content a {
  color: #3b839c;
  text-decoration: none;
  border-bottom: 0.1rem solid white;
}

.news-details .news-content a:hover {
  color: #2d6477;
}

.news-details .news-content a:visited {
  color: #1f4552;
  border-bottom-color: #969696;
}

.news-details .news-content p {
  margin-bottom: 1.34em;
}

.news-details .news-content p:first-of-type:first-letter {
  float: left;
  margin-right: 0.16em;
  margin-bottom: -0.2em;
  font-size: 500%;
  font-weight: bold;
  line-height: 1;
  text-transform: uppercase;
}

.news-details .news-aside {
  display: none;
  float: left;
  width: 15rem;
  margin-right: 0;
  font-size: 1.4rem;
  font-family: "Poppins", sans-serif;
  clear: both;
}

.news-details .news-aside a {
  border-bottom: 0;
  color: #252525;
}

.news-details .news-aside figure {
  margin-bottom: 1em;
}

.news-details .news-aside-head {
  margin-bottom: 0.6em;
  font-weight: 600;
  text-transform: uppercase;
}

.news-details .news-aside-body {
  display: block;
  padding-top: 1.3em;
  border-top: 0.1rem solid #a5a5a5;
}

.news-details .news-aside-author {
  display: block;
}

.news-details .news-aside .news-date {
  font-size: 1.2rem;
  line-height: 1.2;
  letter-spacing: 0.01em;
  color: #000000;
}

.news-details .news-aside .news-title {
  margin-top: 0;
  font-size: 1.6rem;
  font-weight: 600;
  line-height: 1.28;
  color: #2e3a52;
  text-align: left;
}

.news-details .news-aside .news-author {
  color: #252525;
}

.news-details .news-tags {
  text-transform: uppercase;
}

@media only screen and (max-width: 768px) {
  .news-details .news-tags {
    margin-top: 0px;
  }
}

.news-details .news-tags .tag > a {
  background-color: #dedede;
}

.news-details .news-tags .tag > a:hover {
  background-color: #c9c9c9;
}

.news-row:before, .news-row:after {
  content: '';
  display: table;
}

.news-row:after {
  clear: both;
}

.news-row .news-thumb {
  display: block;
  float: left;
  width: 12rem;
}

.news-row .news-content {
  margin-left: 14rem;
}

.news-row .news-content p:last-child {
  margin-bottom: 0;
}

.news-row.mini .news-thumb {
  float: left;
  width: 6rem;
}

.news-row.mini .news-content {
  margin-left: 8rem;
}

.news-list {
  position: relative;
}

.news-list .news-item {
  padding-top: 2.5rem;
  padding-bottom: 2.5rem;
  border-top: 0.1rem solid #f0efef;
}

.news-list .news-item.first {
  padding-top: 0;
  border-top: 0;
}

.news-list .news-title {
  margin-bottom: 0.5rem;
  font-weight: 600;
}

.news-list .news-meta {
  margin-bottom: 1rem;
  color: #969696;
  font-size: 1.2rem;
  font-weight: 400;
  text-transform: uppercase;
}

.news-list .news-meta .author a {
  color: inherit;
}

.news-list .news-meta .category {
  color: #3b839c;
}

.news-list .events-content .news-meta {
  color: #ff4146;
  font-size: 1.4rem;
  font-weight: 700;
  letter-spacing: 0.05rem;
}

.news-infinite-scroll .news-loading {
  margin-top: 2em;
  color: #a5a5a5;
  font-size: 2.1rem;
  letter-spacing: 0.02em;
  text-align: center;
}

.news-infinite-scroll .news-row {
  max-width: 32rem;
  margin: 1em auto;
}

.news-infinite-scroll .news-row .news-thumb {
  width: 8rem;
  height: 8rem;
}

.news-infinite-scroll .news-row .news-content {
  margin-top: 1rem;
  margin-left: 9.5rem;
}

.news-infinite-scroll .news-row .news-title {
  height: 1em;
}

.news-infinite-scroll .news-row .news-meta {
  text-align: left;
  line-height: 1;
}

.news-infinite-scroll .news-row .news-meta span {
  width: 3em;
  height: 0.8em;
}

.news-infinite-scroll .news-row .news-thumb, .news-infinite-scroll .news-row .news-title,
.news-infinite-scroll .news-row .news-meta > span {
  background-color: #dedede;
  border-radius: 0.3rem;
}

@media (max-width: 1199px) {
  .news-details .news-aside-author {
    display: block;
    float: none;
    width: auto;
    margin-top: -0.3em;
    margin-bottom: 2em;
    text-align: center;
  }
  .news-details .news-aside-author .news-avatar {
    display: none;
  }
  .news-details .news-aside-author .news-author {
    margin-top: 0;
    margin-bottom: 0.2em;
  }
  .news-details .news-aside-author .news-date br {
    display: none;
  }
}

@media (max-width: 479px) {
  .news-container {
    padding-left: 0;
    padding-right: 0;
    border-width: 0;
  }
  .news-details figure.pull-left, .news-details figure.pull-right {
    float: none !important;
    width: auto;
    max-width: none !important;
    margin-left: auto !important;
    margin-right: auto !important;
  }
  .news-details .news-action {
    margin-bottom: 0.429em;
  }
  .news-details .news-title {
    font-size: 2.5rem;
    letter-spacing: -0.01em;
  }
  .news-details .news-content {
    margin-left: -1.5rem;
    margin-right: -1.5rem;
    padding: 1.5rem;
    font-size: 1.6rem;
  }
  .news-list .news-thumb {
    float: none;
    width: auto;
    margin-bottom: 1.5rem;
  }
  .news-list .news-content {
    margin-left: auto;
  }
}

@media (min-width: 1200px) {
  .news-details .news-title {
    font-size: 4.5rem;
  }
  .news-details .news-aside {
    display: block;
    margin-top: 0;
    margin-left: -19.5rem;
  }
}

.news-filter .control-label {
  color: #7c7c7c;
}

.news-sortby {
  padding-bottom: 2rem;
  border-bottom: 0.1rem solid #f7f6f6;
}

.news-sortby .link-tabs {
  margin-bottom: 0;
}

.news-sortby .link-tabs > li > a {
  color: #b1b1b1;
  text-decoration: none;
  text-transform: uppercase;
}

.news-sortby .link-tabs > li.active > a {
  border-bottom: 0.2rem solid #3b839c;
  color: #3b839c;
  font-weight: 600;
}

.news-related .i-row > .i-col:nth-child(2n+3) {
  clear: both;
}

.news-related .news-row {
  display: flex;
}

.news-related .news-thumb {
  float: none;
  width: 9rem;
  flex: 0 0 auto;
}

.news-related .news-content {
  width: auto;
  margin-left: 0;
  padding-left: 1.5rem;
  flex: 1 1 auto;
}

.news-related .news-title {
  font-size: 1.8rem;
  font-weight: 600;
}

.news-related .news-meta {
  font-size: 1.3rem;
  font-weight: 400;
  line-height: 1.2;
  color: #969696;
  white-space: nowrap;
}

.news-related .news-meta span + span {
  margin-left: 1rem;
  padding-left: 1rem;
  border-left: 0.1rem solid #c9c9c9;
}

.news-mostviewed .news-list .news-item {
  border-top: 0.1rem dashed #dedede;
}

.news-mostviewed .news-list .news-item:first-child {
  border-top: 0;
}

.news-mostviewed .news-list .news-item:last-child {
  border-bottom: 0;
}

.news-mostviewed .news-list .news-item:hover .news-count {
  color: #7c7c7c;
}

.news-mostviewed .news-list .news-count {
  float: left;
  width: 5rem;
  color: #c9c9c9;
  font-size: 3.6rem;
  line-height: 1;
  text-align: center;
}

.news-mostviewed .news-list .news-content {
  margin-left: 6rem;
}

.news-owl {
  margin-bottom: 30px;
}

.news-owl .item > .img {
  background-position: center center;
  background-size: contain;
  background-repeat: no-repeat;
  background-color: #f4f4f4;
  height: 350px;
}

@media (max-width: 479px) {
  .news-owl .item > .img {
    height: 250px;
  }
}

.news-owl .item .caption {
  margin-top: 0.6em;
  line-height: 1.4;
  font-size: 14px;
  color: #212529;
  font-weight: 600;
  padding: 0 20px;
}

.news-owl .item .caption:before {
  font-family: 'FontAwesome';
  content: '\f05a';
  margin-right: 4px;
  font-style: normal;
  font-size: 1.3rem;
}

.news-owl .owl-nav {
  position: absolute;
  top: 45%;
  width: 100%;
  transform: translateY(-50%);
  margin: 0;
  padding: 0 15px;
}

.news-owl .owl-nav .owl-prev,
.news-owl .owl-nav .owl-next {
  background: none;
  margin: 0;
  position: relative;
}

.news-owl .owl-nav .owl-prev:before,
.news-owl .owl-nav .owl-next:before {
  font-family: FontAwesome;
  color: #869791;
  font-size: 30px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 10px;
}

.news-owl .owl-nav .owl-prev:hover,
.news-owl .owl-nav .owl-next:hover {
  background: none;
}

.news-owl .owl-nav .owl-prev:hover:before,
.news-owl .owl-nav .owl-next:hover:before {
  color: #000;
}

.news-owl .owl-nav .owl-prev {
  float: left;
}

.news-owl .owl-nav .owl-prev:before {
  content: "\f053";
}

.news-owl .owl-nav .owl-next {
  float: right;
}

.news-owl .owl-nav .owl-next:before {
  content: "\f054";
}

.news-owl .owl-dots {
  display: none;
}

.video-grid {
  display: flex;
  flex-wrap: wrap;
  margin: 0 -0.8rem;
  padding: 0;
  list-style: none;
}

.video-item {
  display: flex;
  float: left;
  width: 33.3333%;
}

.video-item img {
  width: 100%;
  height: auto;
}

.video-wrap {
  width: 100%;
  margin: 0.8rem;
}

.video-thumb {
  position: relative;
  background-color: #000000;
}

.video-thumb > a {
  display: block;
  height: 16.8rem;
  overflow: hidden;
  position: relative;
}

.video-thumb > a img {
  position: absolute;
  top: 50%;
  left: 0;
  transform: translate3d(0, -50%, 0);
}

.video-title {
  font-size: 1em;
}

.video-video {
  line-height: 1.2;
}

@media (max-width: 767px) {
  .video-item {
    width: 50%;
  }
}

@media (max-width: 479px) {
  .video-grid {
    margin-left: -0.4rem;
    margin-right: -0.4rem;
  }
  .video-wrap {
    margin: 0.4rem;
  }
  .video-thumb > a {
    height: 12rem;
  }
}

body.page-event {
  font-family: 'Roboto', Arial;
  font-size: 16px;
  line-height: 28px;
}

.event-wope-theme .event-details, .event-wope-theme .event-location {
  background-color: #edf1f5;
  border: 1px solid #d2d6da;
  padding: 1.5rem 2.5rem;
}

.event-wope-theme .event-details h3, .event-wope-theme .event-location h3 {
  margin: 0;
}

.event-wope-theme .event-prev-next {
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  font-size: 14px;
  font-weight: 500;
}

.event-wope-theme .event-prev-next .e-prev {
  text-align: left;
  flex: 1 1 50%;
}

.event-wope-theme .event-prev-next .e-next {
  text-align: right;
  flex: 1 1 50%;
}

.event-wope-theme .details-info, .event-wope-theme .venue-info {
  display: flex;
  flex-flow: row nowrap;
}

.event-wope-theme .details-info .column, .event-wope-theme .venue-info .column {
  flex: 1 1 50%;
  padding: 1rem 1rem 1rem 0;
}

.event-wope-theme .details-info .column .info, .event-wope-theme .venue-info .column .info {
  margin: 1rem 1rem 1rem 0;
}

.event-wope-theme .details-info .column .info span, .event-wope-theme .venue-info .column .info span {
  width: 100%;
}

.event-wope-theme .details-info .column .info span.title, .event-wope-theme .venue-info .column .info span.title {
  font-weight: 600;
  margin-bottom: 5px;
}

.event-wope-theme .details-info .column .info iframe, .event-wope-theme .venue-info .column .info iframe {
  border: 1px solid #d2d6da;
}

.event-wope-theme .details-info .column:last-child, .event-wope-theme .venue-info .column:last-child {
  padding: 1rem 0;
}

.pullout {
  display: flex;
  flex-flow: row wrap;
  margin-left: -3rem;
  margin-right: -3rem;
}

@media screen and (max-width: 719px) {
  .pullout {
    margin-left: -1.5rem;
    margin-right: -1.5rem;
  }
}

@media screen and (max-width: 347px) {
  .pullout {
    margin-left: 0;
    margin-right: 0;
  }
}

.pullout .card {
  flex: 1 1 calc(100% / 3);
  padding: 1rem 3rem;
  text-align: center;
  min-width: 10rem;
}

@media screen and (max-width: 719px) {
  .pullout .card {
    flex: 1 1 calc(100% / 2);
    padding: 1rem 1.5rem;
  }
}

@media screen and (max-width: 347px) {
  .pullout .card {
    flex: 1 1 calc(100% / 1);
    padding: 1rem 0;
  }
}

.pullout .card > .content {
  margin-bottom: 0;
}

.pullout .card > .content .image {
  position: relative;
}

.pullout .card > .content .image img {
  border: 1px solid #eee;
  filter: contrast(115%);
}

.pullout .card > .content .image > a:before {
  content: "";
  background: linear-gradient(to right, rgba(0, 0, 0, 0.25), rgba(255, 255, 255, 0.08) 5%, rgba(255, 255, 255, 0.35) 12%, rgba(255, 255, 255, 0.2) 18%, rgba(30, 30, 30, 0.15) 100%);
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
}

.pullout .card > .content .publication {
  padding: 10px;
  font-family: 'roboto', arial;
  font-size: 16px;
}

.listing-filter .i-segment {
  background-color: #f7f6f6;
  box-shadow: none;
}

.listing-items .list-inline {
  margin-bottom: 0.385em;
  padding-bottom: 0.16em;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.listing-items .list-inline:last-child {
  margin-bottom: 0;
}

.listing-items .list-inline > li {
  display: inline;
}

.listing-items .listing-row,
.listing-items .listing-col {
  height: 100%;
  position: relative;
}

.listing-items .listing-title {
  margin-top: 0;
  margin-bottom: 0.5rem;
  font-size: inherit;
  font-weight: 600;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.listing-items .listing-description {
  font-size: small;
}

.listing-items .listing-footer {
  margin-top: 1.7146em;
  font-size: smaller;
  color: #a5a5a5;
}

.listing-items .listing-price {
  margin-top: 0;
  margin-bottom: 0;
  font-weight: 600;
  color: #2e3a52;
  text-align: right;
}

.listing-items .listing-panel {
  position: absolute;
  bottom: 0;
  right: 0;
  z-index: 1;
}

.listing-items .listing-btn {
  text-align: right;
  font-size: 1.7rem;
  white-space: nowrap;
}

.listing-items .listing-btn > span {
  display: inline-block;
  width: 1.7146em;
  height: 1.7146em;
  line-height: 1.7146em;
  text-align: center;
  color: #7c7c7c;
  vertical-align: middle;
}

.listing-items .listing-btn > span .tooltip {
  white-space: nowrap;
}

.listing-items .listing-btn > span .agent-phone-number {
  display: none;
  font-size: 0.64em;
}

.listing-items .listing-btn > span.active {
  width: auto;
  margin-left: -5rem;
}

.listing-items .listing-btn > span.active [data-toggle],
.listing-items .listing-btn > span.active .agent-phone-number {
  display: block;
  float: left;
}

.listing-items .listing-share {
  position: relative;
}

.listing-items .listing-share > a {
  color: #7c7c7c;
}

.listing-items .listing-share .dropdown-menu {
  min-width: auto;
  margin-top: 0;
  padding: 0.5rem;
  font-size: 0;
  line-height: 0;
}

.listing-items .listing-share .dropdown-menu > li {
  display: inline-block;
  font-size: 1.5rem;
}

.listing-items .listing-share .dropdown-menu > li .ion-icon {
  margin-right: 0;
}

.listing-items .listing-share .dropdown-menu:before {
  right: 0.8rem;
}

.listing-items .listing-share .dropdown-menu:after {
  right: 0.7rem;
}

.listing-items .i-items > .item > .image {
  background-color: #fafafa;
}

.listing-items .i-items > .item > .content {
  align-self: stretch;
  line-height: 1;
}

.listing-enquire-form {
  padding-left: 2rem;
  padding-right: 2rem;
  padding-bottom: 2rem;
}

.listing-enquire-form > header {
  margin-bottom: 3rem;
}

.listing-enquire-form > header h2 {
  margin-top: 0;
  margin-bottom: 0.5rem;
  font-size: 2.2rem;
  color: #2e3a52;
  letter-spacing: 0.08rem;
}

.listing-enquire-form > header .list-inline {
  color: #969696;
}

.listing-enquire-form > header .list-inline .material-icons {
  font-size: 1.286em;
  position: relative;
  top: -0.111em;
}

.listing-enquire-form > header .list-inline > li {
  padding-left: 0;
  padding-right: 0;
}

.listing-enquire-form > header .list-inline > li + li:before {
  content: '|';
  margin-left: 0.6rem;
  margin-right: 0.9rem;
}

.listing-enquire-form .form-group > label {
  text-align: left;
}

@media (max-width: 767px) {
  .listing-items {
    margin-left: -0.8rem;
    margin-right: -0.8rem;
    overflow: hidden;
  }
  .listing-items .listing-col {
    position: relative;
  }
  .listing-items .listing-col:last-child {
    width: 100%;
    height: auto;
    position: absolute;
    bottom: 0;
    left: 0;
  }
  .listing-items .listing-description {
    font-size: smaller;
  }
  .listing-items .listing-description .list-inline {
    margin-bottom: 0.2em;
  }
  .listing-items .listing-description .listing-rooms {
    margin-top: 2.06em;
    margin-bottom: 0;
  }
  .listing-items .listing-description .listing-rooms > li {
    padding-right: 0;
  }
  .listing-items .listing-description .listing-rooms > li + li:before {
    display: none !important;
  }
  .listing-items .listing-price {
    line-height: 1;
  }
  .listing-items .i-items > .item > .image {
    width: 14rem;
  }
  .listing-items .i-items > .item > .image + .content {
    padding-left: 0.5rem;
  }
}

@media (min-width: 992px) {
  .listing-items .listing-row {
    display: flex;
  }
  .listing-items .listing-col {
    height: auto;
  }
}

.listing-slideshow .ls-carousel .owl-caption {
  padding: 1rem 1.3rem;
  background-color: rgba(37, 37, 37, 0.7);
  color: #ffffff;
  position: absolute;
  right: 2rem;
  bottom: 2rem;
  opacity: 0;
  transition: opacity 800ms ease-out;
}

.listing-slideshow .ls-carousel .owl-item {
  height: 45rem;
}

.listing-slideshow .ls-carousel .owl-item .item {
  height: 100%;
  background-color: #f4f4f4;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
}

.listing-slideshow .ls-carousel .owl-item.active .owl-caption {
  opacity: 1;
}

.listing-slideshow .ls-carousel .owl-nav {
  color: #ffffff;
}

.listing-slideshow .ls-carousel .owl-nav i {
  display: block;
  text-align: center;
}

.listing-slideshow .ls-carousel .owl-nav .owl-prev,
.listing-slideshow .ls-carousel .owl-nav .owl-next {
  width: 4.2rem;
  height: 4.2rem;
  padding-left: 1rem;
  padding-right: 1rem;
  line-height: 4.2rem;
  font-size: 1.8rem;
  background-color: rgba(46, 58, 82, 0.8);
  color: #3b839c;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}

.listing-slideshow .ls-carousel .owl-nav .owl-prev {
  left: 0;
}

.listing-slideshow .ls-carousel .owl-nav .owl-next {
  right: 0;
  text-align: right;
}

.listing-slideshow .ls-carousel .owl-nav .disabled {
  opacity: 0;
  visibility: hidden;
}

.listing-slideshow .ls-pagination {
  margin-top: 0.5rem;
}

.listing-slideshow .ls-pagination .owl-item {
  height: 6.4rem;
  cursor: pointer;
  transition: opacity 300ms ease-in-out;
}

.listing-slideshow .ls-pagination .owl-item .item {
  height: 100%;
  background-color: #252525;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: contain;
}

.listing-slideshow .ls-pagination .owl-item.current {
  border: 0.3rem solid #3b839c;
}

.listing-detail .listing-pane {
  padding: 2rem;
  border: 0.1rem solid #f7f6f6;
  background-color: #ffffff;
}

.listing-detail .listing-header {
  margin-top: 0;
  margin-bottom: 0.8em;
}

.listing-detail .listing-info {
  margin: 0 -2.1rem;
}

.listing-detail .listing-info:first-child {
  margin-top: -2.1rem;
}

.listing-detail .listing-info a {
  color: #3b839c;
  text-decoration: none;
}

.listing-detail .listing-info a:hover {
  color: #2e3a52;
}

.listing-detail .listing-info .li-row {
  display: table;
  width: 100%;
  border: 0.1rem solid #f7f6f6;
}

.listing-detail .listing-info .li-row-1 {
  text-align: center;
}

.listing-detail .listing-info .li-row-1 > .li-col {
  border-left: 0.1rem solid #f7f6f6;
}

.listing-detail .listing-info .li-row-1 > .li-col:first-child {
  border-left: 0;
}

.listing-detail .listing-info .li-row-2 {
  margin-top: -0.1rem;
}

.listing-detail .listing-info .li-col {
  display: table-cell;
  padding: 1.5rem 2rem;
  vertical-align: middle;
}

.listing-detail .listing-info .listing-price {
  text-align: left;
}

.listing-detail .listing-info .listing-price h2 {
  margin-top: 0;
  margin-bottom: 0.3em;
  color: #3b839c;
  font-weight: 600;
  line-height: 1;
}

.listing-detail .listing-info .listing-price small {
  display: block;
  color: #a5a5a5;
  font-size: small;
}

.listing-detail .listing-info .listing-beds span,
.listing-detail .listing-info .listing-bath span,
.listing-detail .listing-info .listing-size span {
  display: block;
  line-height: 1;
}

.listing-detail .listing-info .listing-beds span + span,
.listing-detail .listing-info .listing-bath span + span,
.listing-detail .listing-info .listing-size span + span {
  margin-top: 0.5em;
}

.listing-detail .listing-info .listing-share {
  width: 9rem;
  padding: 0;
  position: relative;
}

.listing-detail .listing-info .listing-share > a {
  display: block;
  width: 100%;
  height: 100%;
  padding-top: 3.5rem;
  background-color: transparent;
  line-height: 1;
  position: absolute;
  top: 0;
  left: 0;
  box-shadow: none;
  border-radius: 0;
}

.listing-detail .listing-info .listing-share > a:hover {
  background-color: #fafafa;
  color: #3b839c;
}

.listing-detail .listing-info .listing-share .social-media > li > a {
  color: #ffffff;
}

.listing-detail .listing-info .listing-address {
  margin-top: 0;
  margin-bottom: 0;
  font-size: 1.124em;
  font-style: italic;
  letter-spacing: 0.03em;
}

.listing-detail .listing-info .listing-address .material-icons {
  font-size: 1.36em;
}

.listing-detail .listing-info .listing-addbookmark {
  text-align: right;
}

.listing-detail .listing-info .listing-addbookmark a {
  font-weight: 700;
}

.listing-detail .listing-meta > h4 {
  margin-top: 2rem;
  font-size: 2.2rem;
}

.listing-detail .listing-meta .listing-label {
  float: left;
  width: 18rem;
  position: relative;
  color: #b1b1b1;
}

.listing-detail .listing-meta .listing-label:before {
  content: '';
  border-right: 0.1rem solid #f7f6f6;
  position: absolute;
  top: -1rem;
  bottom: -1rem;
  right: 0;
}

.listing-detail .listing-meta .listing-label .material-icons {
  margin-right: 0.278em;
  color: inherit;
  font-size: 1.286em;
  position: relative;
  top: -0.111em;
}

.listing-detail .listing-meta .listing-data {
  margin-left: 18rem;
  padding-left: 1rem;
}

.listing-detail .listing-meta .listing-list {
  margin-bottom: 0;
  padding-left: 0;
  list-style: none;
}

.listing-detail .listing-meta .listing-list > li {
  margin-top: -0.1rem;
  padding: 1rem;
  border: 0.1rem solid #f7f6f6;
}

.listing-detail .listing-meta .listing-list > li:before, .listing-detail .listing-meta .listing-list > li:after {
  content: '';
  display: table;
}

.listing-detail .listing-meta .listing-list > li:after {
  clear: both;
}

.listing-detail .listing-facilities {
  margin-top: -0.1rem;
  padding: 1.5rem;
  border: 0.1rem solid #f7f6f6;
}

.listing-detail .listing-facilities .listing-list {
  margin-bottom: 0;
  padding-left: 0;
  list-style: none;
  line-height: 2rem;
}

.listing-detail .listing-facilities .listing-list:before, .listing-detail .listing-facilities .listing-list:after {
  content: '';
  display: table;
}

.listing-detail .listing-facilities .listing-list:after {
  clear: both;
}

.listing-detail .listing-facilities .listing-list .material-icons {
  color: #3b839c;
  font-size: 2rem;
  position: absolute;
  top: 0.3rem;
  left: 0;
}

.listing-detail .listing-facilities .listing-list > li {
  float: left;
  width: 33.3333%;
  padding: 0.3rem 1rem 0.3rem 3rem;
  position: relative;
}

.listing-detail .listing-description {
  margin-top: -0.1rem;
  padding: 1.5rem;
  border: 0.1rem solid #f7f6f6;
}

.listing-detail .listing-description .listing-expandable {
  font-size: 1.3rem;
}

.listing-detail .listing-description .listing-expandable.active {
  height: auto !important;
}

.cleanmode .listing-detail .listing-description .listing-expandable {
  height: auto;
}

.cleanmode .listing-detail .listing-description .listing-expandable [data-toggle='expandable']:after {
  display: none;
}

.listing-datatable {
  padding: 2.5rem 2rem;
  border: 0.1rem solid #f7f6f6;
  background-color: #ffffff;
}

.listing-datatable .listing-block + .listing-block {
  margin-top: 4rem;
}

.listing-datatable .listing-display {
  margin-top: -1.5rem;
}

.listing-datatable .listing-display .form-group {
  margin-left: -0.5rem;
  margin-right: -0.5rem;
}

.listing-datatable .listing-display .form-group > [class*="col"] {
  padding-left: 0.5em;
  padding-right: 0.5em;
  margin-bottom: 0;
}

.listing-datatable .listing-display .control-label {
  width: 9rem;
  color: #2e3a52;
  font-weight: 600;
  line-height: 1.4286;
  text-align: left;
}

.listing-datatable .listing-display .control-label + div {
  float: none;
  width: auto;
  margin-left: 9rem;
}

.listing-datatable .listing-display .radios.inline {
  margin-bottom: 0;
}

.listing-datatable .listing-display .radios.inline > .radio {
  padding-top: 1.25rem;
  padding-bottom: 1.25rem;
}

.listing-datatable .listing-display .radios.inline > .radio label {
  color: #2e3a52;
  font-weight: 700;
  text-transform: uppercase;
}

.area-outlook-detail .listing-datatable .listing-display .radios.inline > .radio label,
.page-property-detail .listing-datatable .listing-display .radios.inline > .radio label,
.page-project-outlook .listing-datatable .listing-display .radios.inline > .radio label {
  font-weight: 400;
  text-transform: capitalize;
}

.listing-datatable .listing-display .radios.inline > .radio label:before {
  border-color: #2e3a52;
}

.listing-datatable .listing-display .radios.inline > .radio + .radio {
  margin-left: 1rem;
}

.listing-agent-contact {
  padding: 2rem;
  border: 0.1rem solid #f7f6f6;
  background-color: #ffffff;
}

.listing-agent-contact .block-title, .listing-agent-contact .area-outlook-detail h3.block-title, .area-outlook-detail .listing-agent-contact h3.block-title,
.listing-agent-contact .page-project-outlook h3.block-title, .page-project-outlook .listing-agent-contact h3.block-title {
  margin-top: -0.2rem;
  margin-bottom: 1.6rem;
  line-height: 1;
}

.listing-agent-contact .listing-info {
  margin: -2rem -2rem 1.5rem;
  padding: 2rem;
  border-bottom: 0.1rem solid #f1f1f1;
}

.listing-agent-contact .listing-info h3 {
  margin-top: 0;
  margin-bottom: 0.5rem;
  font-size: 2.4rem;
  font-weight: 600;
  line-height: 1;
  color: #2e3a52;
}

.listing-agent-contact .listing-info p {
  margin-bottom: 0;
  font-size: small;
  font-weight: 600;
  text-transform: uppercase;
  color: #3b839c;
}

.listing-agent-contact .agent-info {
  margin-top: 1.6rem;
  margin-bottom: 1.6rem;
  padding-top: 1.4rem;
  padding-bottom: 1.4rem;
  border-top: 0.1rem solid #f7f6f6;
  border-bottom: 0.1rem solid #f7f6f6;
}

.listing-agent-contact .agent-name {
  margin-top: 0;
  margin-bottom: 0.8rem;
  font-size: 1.5rem;
  font-weight: 600;
  line-height: 1;
}

.listing-agent-contact .agent-id, .listing-agent-contact .agent-company {
  font-size: 1.3rem;
}

.listing-agent-contact .agent-phone-number {
  margin-top: 0.8rem;
  font-size: 1.2rem;
}

.listing-agent-contact .agent-tools {
  display: none;
}

.listing-agent-contact .avatar-text {
  width: 7.5rem;
  height: 7.5rem;
  line-height: 7.5rem;
  font-size: 2.5rem;
  font-weight: 600;
  text-align: center;
  letter-spacing: 0.2rem;
  color: #ffffff;
  background-color: #2e3a52;
  border-radius: 0.5rem;
}

.listing-agent-contact .avatar-image {
  position: absolute;
  top: 0;
  left: 0;
  width: 7.5rem;
  height: 7.5rem;
  z-index: 1;
}

.listing-agent-contact .avatar-image img {
  max-width: 100%;
  height: auto;
}

.listing-agent-contact .i-form .input {
  min-height: 4.2rem;
  margin-bottom: 1rem;
}

.listing-agent-contact .i-form .input > label {
  font-size: inherit;
  line-height: 4.2rem;
  color: #252525;
}

.listing-agent-contact .i-form .input > input {
  min-height: 4.2rem;
  color: #252525;
}

.listing-agent-contact .i-form .input > textarea {
  padding: 0;
  color: #252525;
}

.listing-agent-contact-form {
  padding: 3rem;
  background-color: #2e3a52;
  color: #ffffff;
}

.listing-agent-contact-form .block-title, .listing-agent-contact-form .area-outlook-detail h3.block-title, .area-outlook-detail .listing-agent-contact-form h3.block-title,
.listing-agent-contact-form .page-project-outlook h3.block-title, .page-project-outlook .listing-agent-contact-form h3.block-title {
  color: inherit;
}

.listing-agent-contact-form .i-form .input,
.listing-agent-contact-form .i-form .checkbox label:before {
  background-color: transparent;
}

.listing-agent-contact-form .i-form label,
.listing-agent-contact-form .i-form input,
.listing-agent-contact-form .i-form textarea,
.listing-agent-contact-form .i-form .help-block {
  color: #ffffff;
}

.listing-agent-contact .float-agent-contact {
  display: none;
  width: 100%;
  border-top: 0.1rem solid #ebe9e9;
  background-color: #363a94;
  color: #ffffff;
  text-align: center;
  overflow: hidden;
  position: fixed;
  left: 0;
  bottom: 0;
  z-index: 98;
}

.listing-agent-contact .float-agent-contact .button {
  float: left;
  width: 50%;
  margin: 0.7rem 0;
  padding: 0.8rem 0;
  border: 0;
  color: inherit;
  font-size: 1.4rem;
  font-weight: 600;
  letter-spacing: 0.02em;
  line-height: 1;
  border-radius: 0;
}

.listing-agent-contact .float-agent-contact .button:active {
  box-shadow: none;
}

.listing-agent-contact .float-agent-contact .button + .button {
  margin-left: 0;
  border-left: 0.1rem solid #ebe9e9;
}

.listing-agent-contact .float-agent-contact .button .fa {
  margin-right: 0.1rem;
}

.listing-agent-contact .float-agent-contact .button.call .fa {
  color: #ffffff;
}

.listing-agent-contact .float-agent-contact .button.sms .fa {
  color: #ff5122;
}

.listing-agent-contact .float-agent-contact .button.whatsapp .fa {
  color: #27ae60;
}

.listing-social-sharing {
  padding-top: 0.2rem;
  padding-bottom: 0.2rem;
}

.listing-social-sharing:before, .listing-social-sharing:after {
  content: '';
  display: table;
}

.listing-social-sharing:after {
  clear: both;
}

.listing-similiar-property .listing-price {
  margin-top: 0;
  margin-bottom: 0.35em;
  color: #3b839c;
  font-size: 1.6em;
  line-height: 1;
}

.listing-similiar-property .listing-price a {
  color: inherit;
  text-decoration: none;
}

.listing-similiar-property .listing-price a:hover {
  color: #265464;
}

.listing-similiar-property .listing-location, .listing-similiar-property .listing-date {
  margin-top: 0;
  margin-bottom: 0;
  padding-left: 2rem;
  position: relative;
}

.listing-similiar-property .listing-location .material-icons, .listing-similiar-property .listing-date .material-icons {
  margin-top: -0.4em;
  font-size: inherit;
  position: absolute;
  left: 0;
  top: 50%;
}

.listing-similiar-property .listing-location {
  margin-bottom: 0.2em;
  font-size: 1em;
}

.listing-similiar-property .listing-date {
  font-size: 0.9em;
  color: #a5a5a5;
}

.listing-similiar-property .listing-buttons {
  display: table;
  width: 100%;
  border-top: 0.1rem solid #e4e0e0;
}

.listing-similiar-property .listing-item {
  display: table-cell;
  width: 25%;
  padding: 0.5rem;
  text-align: center;
  vertical-align: middle;
}

.listing-similiar-property .listing-item:last-child {
  padding: 0;
}

.listing-similiar-property .listing-item + .listing-item {
  border-left: 0.1rem solid #e4e0e0;
}

.listing-similiar-property .listing-item .button {
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  border: 0;
  border-radius: 0;
}

.listing-similiar-property .listing-item .button:active {
  background-color: #f7f6f6;
  box-shadow: none;
}

.listing-similiar-property .agent-modal .modal-header .close {
  margin-top: 0;
  margin-right: 0.3em;
  font-size: 2.5rem;
}

.listing-similiar-property .agent-modal .modal-body {
  background-color: #fafafa;
  border: 0.1rem solid #f7f6f6;
}

.listing-similiar-property .agent-modal .modal-body header {
  margin-bottom: 1.5rem;
  text-align: center;
}

.listing-similiar-property .agent-modal .modal-body header h4 {
  margin-top: 0;
  margin-bottom: 0.5em;
  font-weight: 600;
  line-height: 1.2;
}

.listing-similiar-property .agent-modal .modal-body header p {
  font-size: 1.6rem;
}

.listing-similiar-property .agent-modal .i-form .checkbox {
  min-height: auto;
  padding-top: 0;
}

.listing-similiar-property .agent-modal .i-form .button.theme-alt[disabled], .listing-similiar-property .agent-modal .i-form .button.theme-alt[disabled]:hover {
  background-color: #60aac3;
  border-color: #4ea0bc;
}

@media (max-width: 991px) {
  .listing-agent-contact {
    margin-top: 2rem;
  }
}

@media (max-width: 767px) {
  .listing-slideshow .ls-carousel .owl-caption {
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #252525;
    opacity: 1;
  }
  .listing-slideshow .ls-pagination {
    margin-top: 0.5rem;
  }
  .listing-detail .listing-pane {
    padding: 0;
  }
  .listing-detail .listing-header {
    margin-bottom: 0;
    padding: 1.5rem;
    line-height: 1.2;
  }
  .listing-detail .listing-info {
    margin: 0;
  }
  .listing-detail .listing-info .li-row {
    border-left: 0;
    border-right: 0;
  }
  .listing-detail .listing-info .li-row-1 > .li-col {
    width: 25%;
    padding: 1.5rem;
  }
  .listing-detail .listing-info .li-row-1 > .li-col:first-child {
    display: table-caption;
    width: 100%;
    border-top: 0.1rem solid #f7f6f6;
  }
  .listing-detail .listing-info .li-row-1 > .li-col:nth-child(2) {
    border-left: 0;
  }
  .listing-detail .listing-info .li-row-2 {
    display: block;
  }
  .listing-detail .listing-info .li-row-2 > .li-col {
    display: block;
    padding: 1.5rem;
  }
  .listing-detail .listing-info .listing-share > a {
    padding-top: 2.4rem;
  }
  .listing-detail .listing-info .listing-address {
    font-size: 0.92em;
  }
  .listing-detail .listing-info .listing-addbookmark {
    padding: 0 !important;
    border-top: 0.1rem solid #f7f6f6;
    background-color: #fafafa;
    text-align: center;
  }
  .listing-detail .listing-info .listing-addbookmark a {
    display: block;
    padding: 1rem 1.5rem;
  }
  .listing-detail .listing-meta h4 {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
  }
  .listing-detail .listing-meta .listing-label {
    width: 12.5rem;
  }
  .listing-detail .listing-meta .listing-label .material-icons {
    margin-right: 0;
  }
  .listing-detail .listing-meta .listing-data {
    margin-left: 12.5rem;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .listing-detail .listing-facilities .listing-list > li {
    width: 50%;
    padding-left: 2.5rem;
  }
  .listing-detail .listing-facilities, .listing-detail .listing-description {
    padding-left: 1rem;
    padding-right: 1rem;
    border-left: 0;
    border-right: 0;
  }
  .listing-datatable {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
  }
  .listing-datatable .listing-display .control-label,
  .listing-datatable .listing-display .control-label + div {
    float: none;
    margin-left: 0;
    padding-top: 0;
  }
  .listing-datatable .listing-display .control-label {
    bottom: -0.5rem;
    color: #a5a5a5;
  }
  .listing-agent-contact {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
  }
  .listing-agent-contact .agent-tools {
    display: block;
  }
  .listing-agent-contact .agent-tools .nav > li > a {
    padding-top: 1.1rem;
    padding-bottom: 1.2rem;
    color: #ffffff;
  }
  .listing-agent-contact .agent-tools .nav > li > a,
  .listing-agent-contact .agent-tools .nav > li > a:focus,
  .listing-agent-contact .agent-tools .nav > li > a:hover {
    background-color: transparent;
  }
  .listing-agent-contact .agent-tools .nav > li.open > a,
  .listing-agent-contact .agent-tools .nav > li.open > a:focus,
  .listing-agent-contact .agent-tools .nav > li.open > a:hover {
    background-color: rgba(37, 37, 37, 0.25);
  }
  .listing-agent-contact .agent-tools .dropdown-menu {
    min-width: 24rem;
    margin: 0;
    padding: 0.5rem;
    border: 0;
    border-radius: 0;
  }
  .listing-agent-contact .float-agent-contact {
    display: block;
  }
}

@media (min-width: 992px) {
  .listing-column:not(.reset) {
    width: calc(100% - 33rem);
  }
  .listing-sidebar {
    width: 33rem;
  }
  .listing-sidebar .sidebar-affix {
    width: 30rem;
    top: 0;
  }
  .listing-sidebar .sidebar-affix.affix {
    position: fixed !important;
    top: 6.5rem;
  }
}

@media (min-width: 1025px) {
  .listing-sidebar .sidebar-affix.affix {
    top: 8.7rem;
  }
}

.page-property-detail .content-top {
  background-color: #2e3a52;
}

.page-property-detail .content-area {
  background-color: #f2f2f2;
}

.page-property-detail .content-area .i-tabs {
  border-color: #dfdbdb;
}

.page-property-detail .content-area .i-tabs ~ .tab-content .tab-pane {
  background-color: #ffffff;
}

.page-property-detail .content-area .i-tabs.nav-justified > li > a,
.page-property-detail .content-area .i-tabs.nav-justified > li.active > a {
  border-top: 0;
  border-bottom: 0;
}

.page-property-detail .content-area .i-tabs.nav-justified > li.active > a,
.page-property-detail .content-area .i-tabs.nav-justified > li.active > a:focus,
.page-property-detail .content-area .i-tabs.nav-justified > li.active > a:hover {
  border-left-color: #dfdbdb;
  border-right-color: #dfdbdb;
}

.page-property-detail .content-area .i-tabs.nav-justified > li.active:first-child > a {
  border-left-color: transparent;
}

.page-property-detail .content-area .i-tabs.nav-justified > li.active:last-child > a {
  border-right-color: transparent;
}

.page-property-detail .listing-slideshow .ls-carousel,
.page-property-detail .listing-detail .listing-pane,
.page-property-detail .listing-agent-contact,
.page-property-detail .listing-datatable,
.page-property-detail .block-mortgage-calculator,
.page-property-detail .content-area .i-tabs ~ .tab-content .tab-pane {
  border-color: #dfdbdb;
  box-shadow: 0 0.1rem 0.2rem 0 rgba(37, 37, 37, 0.1);
}

.page-property-detail .listing-slideshow .ls-carousel {
  border-width: 0.1rem;
  border-style: solid;
  background-color: #ffffff;
}

.page-property-detail .listing-slideshow .ls-carousel .owl-item > .item {
  background-color: transparent;
}

.page-property-detail .listing-slideshow .ls-pagination {
  margin-top: 1rem;
}

.page-property-detail .listing-detail .listing-info {
  margin-left: -2rem;
  margin-right: -2rem;
}

.page-property-detail .listing-detail .listing-info .li-row {
  border-left: 0;
  border-right: 0;
}

@media (max-width: 767px) {
  .page-property-detail {
    padding-bottom: 4.6rem;
  }
  .page-property-detail .listing-detail .listing-info {
    margin: 0;
  }
  .page-property-detail .listing-detail .listing-meta .listing-list > li {
    border-left: 0;
    border-right: 0;
  }
}

@media (max-width: 479px) {
  .listing-items {
    margin-left: 0;
    margin-right: 0;
  }
  .listing-items .listing-title {
    font-size: 1.2em;
    white-space: normal;
    text-overflow: unset;
    overflow: visible;
  }
  .listing-items .listing-price {
    color: #3b839c;
  }
  .listing-items .i-items > .item {
    display: block;
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;
  }
  .listing-items .i-items > .item > .image {
    width: auto;
  }
  .listing-items .i-items > .item > .image + .content {
    padding-top: 0.6rem;
    padding-left: 0;
  }
  .listing-items .i-items > .item:first-child {
    padding-top: 0;
  }
  .listing-items .i-items > .item:last-child {
    padding-bottom: 0;
  }
}

@media (max-width: 767px) {
  .page-property-detail .listing-slideshow .ls-carousel {
    border: 0;
  }
  .page-property-detail .listing-slideshow + .listing-detail {
    margin-top: 1rem;
  }
  .page-property-detail .content-top,
  .page-property-detail .listing-list,
  .page-property-detail .listing-expandable,
  .page-property-detail .listing-map > .block-content,
  .page-property-detail .listing-agent-contact > .block-title,
  .page-property-detail .area-outlook-detail .listing-agent-contact > h3.block-title,
  .page-property-detail .page-project-outlook .listing-agent-contact > h3.block-title,
  .page-property-detail .block-mortgage-calculator > .block-content,
  .page-property-detail .listing-datatable .listing-block > .block-content,
  .page-property-detail .listing-datatable .listing-display,
  .page-property-detail .listing-datatable .listing-table {
    display: none;
  }
  .page-property-detail .listing-detail .listing-pane {
    border: 0;
  }
  .page-property-detail .listing-meta > h4,
  .page-property-detail .listing-facilities > h5,
  .page-property-detail .listing-description > h5,
  .page-property-detail .listing-map > h3,
  .page-property-detail .block-mortgage-calculator > .block-title,
  .page-property-detail .area-outlook-detail .block-mortgage-calculator > h3.block-title,
  .page-property-detail .page-project-outlook .block-mortgage-calculator > h3.block-title,
  .page-property-detail .listing-datatable .block-title {
    padding: 1rem;
    font-size: 1.5rem;
    font-weight: 500;
    color: #2e3a52;
  }
  .page-property-detail .listing-meta > h4,
  .page-property-detail .listing-facilities > h5,
  .page-property-detail .listing-description > h5 {
    margin: -1.5rem -1rem;
  }
  .page-property-detail .listing-meta > h4 {
    margin: 0;
  }
  .page-property-detail .listing-agent-contact {
    margin: 0;
    padding: 0;
    border: 0;
    box-shadow: none;
  }
}

@media (max-width: 767px) and (max-width: 480px) {
  .page-property-detail .listing-agent-contact .agent-info {
    margin: 0;
    padding: 0;
    border: 0;
    background: linear-gradient(to right, #005c98 0%, #363a94 100%);
    background-position-x: -5rem;
    background-size: 150%;
    position: fixed;
    top: 0;
    left: 10rem;
    right: 0;
    z-index: 100;
  }
  .page-property-detail .listing-agent-contact .agent-info .image {
    width: 3.2rem;
    height: 3.2rem;
    margin: auto 0.6rem;
    border: 0.2rem solid #ffffff;
    overflow: hidden;
    border-radius: 50%;
  }
  .page-property-detail .listing-agent-contact .agent-info .image .avatar-text,
  .page-property-detail .listing-agent-contact .agent-info .image .avatar-image {
    width: 100%;
    height: 100%;
  }
  .page-property-detail .listing-agent-contact .agent-info .image .avatar-text {
    line-height: 3.6rem;
    font-size: 2rem;
    letter-spacing: 0;
  }
  .page-property-detail .listing-agent-contact .agent-info .content {
    padding-left: 0;
    color: white;
  }
  .page-property-detail .listing-agent-contact .agent-info .content .agent-id,
  .page-property-detail .listing-agent-contact .agent-info .content .agent-company,
  .page-property-detail .listing-agent-contact .agent-info .content .agent-phone-number {
    display: none;
  }
  .page-property-detail .listing-agent-contact .agent-info .content .agent-name {
    margin-bottom: 0;
    padding-top: 1rem;
    padding-bottom: 1rem;
    color: #ffffff;
    font-size: 1.3rem;
    font-weight: 300;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .page-property-detail .listing-agent-contact .agent-info .content .agent-name:before {
    content: 'Contact ';
  }
  .page-property-detail .listing-agent-contact .agent-info .content .agent-name a {
    color: inherit;
  }
}

@media (max-width: 767px) and (max-width: 767px) {
  .page-property-detail .listing-agent-contact #contact-form {
    padding: 20px 10px;
    background-color: #f4f7fa;
  }
}

@media (max-width: 767px) {
  .page-property-detail .listing-map {
    background-color: #ffffff;
  }
  .page-property-detail .listing-map > .block-title, .page-property-detail .area-outlook-detail .listing-map > h3.block-title,
  .page-property-detail .page-project-outlook .listing-map > h3.block-title {
    margin-bottom: 0;
  }
  .page-property-detail .block-mortgage-calculator {
    border-left: 0;
    border-right: 0;
    border-color: #f7f6f6;
  }
  
  .page-property-detail .block-mortgage-calculator > .block-title,
  .page-property-detail .area-outlook-detail .block-mortgage-calculator > h3.block-title,
  .page-property-detail .page-project-outlook .block-mortgage-calculator > h3.block-title {
    margin: -1.5rem;
  }
  .page-property-detail .listing-datatable {
    margin-top: -0.1rem !important;
    padding: 0;
    border-color: #f7f6f6;
    border-left: 0;
    border-right: 0;
  }
  .page-property-detail .listing-datatable .listing-block > .block-title, .page-property-detail .listing-datatable .area-outlook-detail .listing-block > h3.block-title,
  .page-property-detail .listing-datatable .page-project-outlook .listing-block > h3.block-title {
    margin-bottom: 0;
  }
  .page-property-detail .listing-datatable .listing-block > .block-content {
    padding-left: 1rem;
    padding-right: 1rem;
  }
  .page-property-detail .listing-datatable .listing-block > .listing-block,
  .page-property-detail .listing-datatable .listing-block + .listing-block {
    margin-top: 0;
  }
  .page-property-detail .listing-datatable .listing-block > .listing-block {
    border-top: 0;
  }
  .page-property-detail .listing-datatable .listing-display {
    margin-top: -1rem;
    margin-left: 1rem;
    margin-right: 1rem;
  }
  .page-property-detail .listing-datatable .listing-display .form-group {
    margin-bottom: 0;
  }
  .page-property-detail .listing-datatable > .block-content > .block-title, .page-property-detail .area-outlook-detail .listing-datatable > .block-content > h3.block-title,
  .page-property-detail .page-project-outlook .listing-datatable > .block-content > h3.block-title {
    margin-bottom: 0;
  }
  .page-property-detail .listing-similiar-property {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
  }
  .page-property-detail .listing-similiar-property .i-card > .content {
    padding: 1rem;
  }
  .page-property-detail .listing-similiar-property .listing-price {
    font-size: 1.5rem;
  }
  .page-property-detail .listing-similiar-property .listing-price > a {
    display: block;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }
  .page-property-detail .listing-similiar-property .listing-location,
  .page-property-detail .listing-similiar-property .listing-date {
    padding-left: 0;
    line-height: 1.08333;
    font-size: 1.2rem;
  }
  .page-property-detail .listing-similiar-property .listing-location > .text-ellipsis,
  .page-property-detail .listing-similiar-property .listing-date > .text-ellipsis {
    overflow: visible;
    text-overflow: unset;
    white-space: normal;
  }
  .page-property-detail .listing-similiar-property .listing-date {
    margin-top: 0.6em;
    font-size: 1.1rem;
  }
  .page-property-detail .content-area .container:nth-child(2) .row > .listing-column {
    padding-left: 0;
    padding-right: 0;
  }
  .page-property-detail .content-area .container:nth-child(2) .row-ld-1 > .listing-sidebar {
    min-height: 0;
  }
  .page-property-detail .listing-meta.active > .listing-list,
  .page-property-detail .listing-facilities.active > .listing-list,
  .page-property-detail .listing-description.active > .listing-expandable,
  .page-property-detail .listing-map.active > .block-content,
  .page-property-detail .block-mortgage-calculator.active > .block-content,
  .page-property-detail .listing-datatable .active .block-content,
  .page-property-detail .listing-datatable .active .listing-display,
  .page-property-detail .listing-datatable .active .listing-table {
    display: block;
  }
  .page-property-detail .listing-facilities.active > .listing-list,
  .page-property-detail .listing-description.active > .listing-expandable,
  .page-property-detail .block-mortgage-calculator.active > .block-content {
    margin-top: 1.5rem;
  }
  .page-property-detail .listing-description.active > .listing-expandable {
    height: auto;
    padding-bottom: 0.5rem;
  }
  .page-property-detail .listing-description.active > .listing-expandable:after {
    display: none;
  }
  .page-property-detail .listing-description.active > .listing-expandable > .readmore {
    display: none;
  }
  .page-property-detail .zing-block {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
    margin-bottom: 2.5rem;
  }
}

@media (max-width: 479px) {
  .page-search-property .listing-items {
    overflow: visible;
  }
}

.ipad .listing-items .listing-row {
  display: table;
  width: 100%;
}

.ipad .listing-items .listing-col {
  height: 100%;
}

.height-initial {
  height: inherit !important;
}

.new-launch {
  margin: 0;
  padding: 0;
  position: relative;
  top: 0;
  left: 0;
  visibility: visible;
  opacity: 1;
}

.new-launch.loaded {
  visibility: visible;
  opacity: 1;
}

.nl-slides {
  position: relative;
}

.nl-slides .slides-container {
  margin: 0;
  padding: 0;
  list-style: none;
}

.nl-slides .slides-item > a {
  display: block;
  width: 100%;
  height: 100%;
  position: relative;
}

.nl-slides .slides-item > a:before {
  content: '';
  display: block;
  background-image: linear-gradient(205deg, rgba(0, 0, 0, 0) 45%, rgba(0, 0, 0, 0.4) 80%, rgba(0, 0, 0, 0.8) 100%);
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
}

.nl-slides .slides-caption {
  max-width: 40rem;
  background-color: transparent;
  color: #ffffff;
  text-shadow: 0 0.1rem 0.1rem rgba(37, 37, 37, 0.3), 0 0.1rem 0.1rem rgba(37, 37, 37, 0.7);
  position: absolute;
  left: 5rem;
  bottom: 5rem;
  z-index: 6;
}

.nl-slides .slides-caption h3 {
  margin-top: 0;
  margin-bottom: 0.2em;
  line-height: 1.2;
}

.nl-slides .slides-caption p {
  margin-bottom: 0;
}

.nl-slides .slides-navigation a {
  display: block;
  font-size: 5rem;
  line-height: 1;
  color: #ffffff;
  opacity: 0.5;
  position: absolute;
  top: 50%;
  z-index: 2;
  transform: translateY(-50%);
}

.nl-slides .slides-navigation a.prev {
  left: 0;
}

.nl-slides .slides-navigation a.next {
  right: 0;
}

.nl-slides .slides-navigation a:hover {
  opacity: 1;
}

.nl-slides .slides-pagination {
  position: absolute;
  bottom: 1rem;
  left: 50%;
  z-index: 3;
  transform: translateX(-50%);
}

.nl-slides .slides-pagination a {
  display: block;
  float: left;
  width: 5rem;
  height: 0.5rem;
  margin: 1rem 0.5rem;
  background-color: #ffffff;
  background-color: rgba(255, 255, 255, 0.25);
  color: #252525;
  font-size: 0;
  text-align: center;
  text-decoration: none;
  border-radius: 1rem;
}

.nl-slides .slides-pagination a:hover {
  background-color: #dedede;
  background-color: rgba(222, 222, 222, 0.75);
  color: #ffffff;
}

.nl-slides .slides-pagination a.current {
  background-color: #3b839c;
  background-color: rgba(59, 131, 156, 0.85);
  color: #ffffff;
}

@media (max-width: 767px) {
  .nl-slides .slides-caption {
    width: 100%;
    padding: 2rem;
    text-align: center;
    left: 0;
    bottom: 2.5rem;
  }
  .nl-slides .slides-navigation a {
    font-size: 3rem;
    opacity: 1;
  }
  .nl-slides .slides-navigation a.prev {
    left: -1rem;
  }
  .nl-slides .slides-navigation a.next {
    right: -1rem;
  }
  .nl-slides .slides-pagination {
    text-align: center;
    left: 0;
    right: 0;
    bottom: 0;
    transform: none;
  }
  .nl-slides .slides-pagination a {
    display: inline-block;
    float: none;
    width: 2rem;
  }
}

.nl-featured {
  padding-top: 5rem;
  padding-bottom: 5rem;
}

.nl-featured .nl-title {
  margin-top: 0;
  margin-bottom: 1em;
  font-size: 3rem;
  font-weight: 600;
  text-align: center;
}

.nl-featured .nl-title:after {
  content: '';
  display: block;
  width: 8rem;
  height: 0.3rem;
  margin-top: 0.333em;
  margin-left: auto;
  margin-right: auto;
  background-color: #2e3a52;
}

@media (max-width: 767px) {
  .nl-featured .nl-title {
    font-size: 2.4rem;
  }
}

.nl-card > .buttons {
  border-top: 0;
}

.nl-card > .buttons .button {
  padding: 0;
  line-height: 4.8rem;
}

.nl-card > .buttons .material-icons {
  font-size: 1.571em;
}

.nl-card > .buttons .social-sharer {
  padding: 0;
}

.nl-card > .buttons .social-sharer .social-button {
  width: auto;
  height: auto;
  line-height: 4.8rem;
  background-color: transparent;
  color: inherit;
  border-radius: 0;
  box-shadow: none;
}

@media (min-width: 768px) and (max-width: 991px) {
  .nl-cards > .nl-col:nth-child(2n+3) {
    clear: both;
  }
}

@media (min-width: 992px) {
  .nl-cards > .nl-col:nth-child(3n+4) {
    clear: both;
  }
}

.leaderboard {
  position: relative;
}

.leaderboard-wrapper {
  margin-left: auto;
  margin-right: auto;
}

.leaderboard-wrapper img {
  max-width: 100%;
  height: auto;
  margin-left: auto;
  margin-right: auto;
}

.leaderboard-1 {
  background-color: #f8f9f9;
}

div[id^='div-gpt-ad'] {
  margin: auto;
}

.block-adbutton > div[id^='div-gpt-ad']:first-of-type {
  margin-bottom: 2.5rem;
}

.admin-menu.adminimal-menu .banderole {
  top: 2.9rem;
}

.banderole {
  height: 100vh;
  background: rgba(0, 0, 0, 0.65);
  z-index: 3000;
  position: fixed;
  top: 0;
  width: 100%;
  left: -100%;
  transition: all .5s .2s ease-in-out;
}

.banderole.in {
  left: 0;
  transition: all .5s .2s ease-in-out;
}

.banderole.in .slide-trigger {
  left: -120px;
  transition: all .5s ease-in-out;
}

.banderole-content {
  position: relative;
}

.banderole-content > div[id^='div-gpt-ad'] {
  display: table;
  margin: 35px auto;
}

.banderole .slide-trigger {
  position: absolute;
  top: 50%;
  left: 100%;
  height: 250px;
  width: 100px;
  box-shadow: 1px 1px 5px rgba(25, 25, 25, 0.5);
  transform: translate(0%, -50%);
  cursor: pointer;
  opacity: 0;
  transition: all .5s .55s ease-in-out;
}

.banderole .slide-trigger.on {
  opacity: 1;
}

.banderole .slide-close {
  position: absolute;
  top: 0;
  color: white;
  right: calc((100% - 980px) / 2);
  padding: 0 10px;
  font-size: 28px;
  z-index: 1;
  cursor: pointer;
}

@media (max-width: 1061px) {
  .banderole {
    height: 569px;
  }
  .banderole-content > div[id^='div-gpt-ad'] {
    margin: 150px auto;
  }
  .banderole .slide-close {
    right: calc((100% - 580px) / 2);
  }
}

@media (max-width: 767px) {
  .admin-menu.adminimal-menu .banderole {
    top: 0;
  }
}

@media (max-width: 619px) {
  .banderole {
    height: 320px;
  }
  .banderole-content > div[id^='div-gpt-ad'] {
    margin: 35px auto;
  }
  .banderole .slide-close {
    right: calc((100% - 300px) / 2);
  }
}

.textAds {
  position: absolute;
  bottom: 0;
  width: 100%;
  text-align: center;
  color: white;
  background-color: rgba(0, 92, 152, 0.5);
  height: 50px;
  line-height: 50px;
  font-size: 1.4rem;
  letter-spacing: 0.05em;
}

.textAds > * {
  padding-right: 1rem;
}

.textAds i {
  border: 2px solid;
  border-radius: 50%;
  padding: .5rem;
  width: 3.2rem;
  height: 3.2rem;
  margin-right: 1rem;
}

.textAds img {
  max-width: 4rem;
}

.textAds button {
  background-color: #488BF8;
  color: #ffffff;
  padding: 0;
}

.textAds button > a {
  color: rgba(255, 255, 255, 0.8);
  display: block;
  padding: .6rem 1.2rem;
}

.textAds button > a:hover {
  color: white;
  text-decoration: none;
}

.textAds button > a:active, .textAds button > a:focus {
  text-decoration: none;
}

.agt-registration {
  background-color: #f4eb49;
  position: absolute;
  top: 10rem;
  right: -180px;
  max-width: 180px;
  font-size: 1rem;
  letter-spacing: 0.03em;
  transition: all .3s ease-in-out;
}

.agt-registration.in {
  right: 0px;
  transition: all .3s ease-in-out;
}

.agt-registration a {
  color: black;
  display: block;
  padding: .6rem 1rem;
}

.agt-registration a:hover, .agt-registration a:active, .agt-registration a:focus {
  text-decoration: none;
}

#hero-banner.hero-banner-container {
  padding: 145px 0 50px;
}

.agent-phone {
  display: table;
  position: relative;
  border-collapse: separate;
  font-weight: 400;
  line-height: 1.5;
}

.agent-phone .agent-phone-addon {
  display: table-cell;
  width: 1%;
  padding: 0.5rem 1rem;
  white-space: nowrap;
  vertical-align: middle;
  border: 0.1rem solid #ddd;
  background-color: #eee;
  border-radius: 0.3rem;
}

.agent-phone .agent-phone-addon:first-child {
  border-right: 0;
  border-radius: 0.3rem 0 0 0.3rem;
}

.agent-phone .agent-phone-addon:last-child {
  border-left: 0;
  border-radius: 0 0.3rem 0.3rem 0;
}

.agent-phone .agent-phone-number {
  display: table-cell;
  float: left;
  width: 100%;
  border: 0.1rem solid #ddd;
  text-align: left;
  overflow: hidden;
  position: relative;
  z-index: 2;
  border-radius: 0.3rem;
}

.agent-phone .agent-phone-number:first-child {
  border-radius: 0.3rem 0 0 0.3rem;
}

.agent-phone .agent-phone-number:last-child {
  border-radius: 0 0.3rem 0.3rem 0;
}

.agent-phone .agent-phone-mask {
  display: block;
  padding: 0.5rem 1rem;
  background-color: #eee;
  color: #999;
  white-space: nowrap;
  cursor: pointer;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 4.8rem;
  right: 0;
  z-index: 1;
  box-shadow: 0 0 1.5rem 1rem #eee;
  transition: left 300ms ease-in-out;
}

.agent-phone .agent-phone-masked > a {
  display: block;
  padding: 0.5rem 1rem;
  background-color: #fff;
  color: #666;
  text-decoration: none;
  white-space: nowrap;
}

.agent .i-segment {
  border: 0.1rem solid #f7f6f6;
  box-shadow: 0 0 0 0;
}

.agent .content {
  color: #2e3a52;
}

.agent .title {
  font-weight: 600;
}

.agent .divided {
  border: 0.1rem solid #f7f6f6;
  padding: 1.5rem;
  margin: 0 0 1.2rem 0;
}

.agent .meta .data {
  display: inline-block;
  padding-right: 0.3rem;
  color: #2e3a52;
}

.agent .meta .data + .data:before {
  content: '|';
  vertical-align: baseline;
  padding-right: 0.6rem;
}

.agent .item:hover {
  background-color: #fafafa;
}

.agent img {
  border: 0.1rem solid #f1f1f1;
}

@media (max-width: 479px) {
  .agent .i-segment {
    box-shadow: 0 0 0 0;
    border: none;
  }
  .agent .i-segment .body {
    padding: 0;
  }
  .agent .divided {
    padding: 1rem 0.8rem;
  }
}

.searchResult {
  color: #3b839c;
  padding-left: 1.5rem;
  font-size: 1.2rem;
  font-weight: 600;
}

.unMask {
  padding-left: 0.2rem;
  font-weight: 600;
}

.search-agent {
  background-image: linear-gradient(rgba(3, 48, 118, 0.45), rgba(3, 48, 118, 0.45)), url(https://media.edgeprop.my/assets/images/area-outlook-bg.png);
  background-position: center center;
  background-repeat: no-repeat;
}

.search-agent .search-wrapper {
  padding: 10rem 0;
}

.search-agent .search-header h2 {
  font-size: 2.5rem;
  color: white;
}

.search-agent .i-form .input,
.search-agent .i-form .select {
  min-height: auto;
  background-color: transparent;
  border: 0.1rem solid #fff;
  border-radius: 0.2rem;
}

.search-agent .i-form .input label,
.search-agent .i-form .select label {
  color: #fff;
  line-height: 4rem;
}

.search-agent .i-form .input input,
.search-agent .i-form .select select {
  min-height: 4rem;
  color: #fff;
}

.search-agent .i-form .select:after {
  content: '\f123';
  width: auto;
  border: 0;
  font-family: "Ionicons";
  font-size: 1rem;
  top: 1.3rem;
  right: 1rem;
  color: #fff;
}

.search-agent .i-form .select option {
  color: #2e3a52;
}

@media (max-width: 767px) {
  .search-agent .search-wrapper {
    padding: 5.5rem 0;
  }
  .search-agent .search-header h2 {
    margin-top: 0;
    font-size: 1.7rem;
  }
}

@media (min-width: 1600px) {
  .search-agent .search-header h2 {
    font-size: 3.6rem;
  }
}

.block-agent-region .card-box {
  width: 23.5rem;
  height: 15rem;
  margin-right: 0.5rem;
  margin-bottom: 0.5rem;
  float: left;
  position: relative;
  background-repeat: no-repeat;
  background-position: center;
}

.block-agent-region .card-box:first-child {
  height: 30.5rem;
  background-size: 46rem;
}

.block-agent-region .card-box:nth-child(n+2) {
  background-size: 23.5rem;
}

.block-agent-region .card-box:nth-child(n+8) {
  display: none;
}

.block-agent-region .card-box:nth-child(1) {
  background-image: url("http://s3-ap-southeast-1.amazonaws.com/www.theedgeproperty.com.my/assets/images/regions/KL.jpg");
}

.block-agent-region .card-box:nth-child(2) {
  background-image: url("http://s3-ap-southeast-1.amazonaws.com/www.theedgeproperty.com.my/assets/images/regions/Selangor.jpg");
}

.block-agent-region .card-box:nth-child(3) {
  background-image: url("http://s3-ap-southeast-1.amazonaws.com/www.theedgeproperty.com.my/assets/images/regions/Penang.jpg");
}

.block-agent-region .card-box:nth-child(4) {
  background-image: url("http://s3-ap-southeast-1.amazonaws.com/www.theedgeproperty.com.my/assets/images/regions/Johor.jpg");
}

.block-agent-region .card-box:nth-child(5) {
  background-image: url("http://s3-ap-southeast-1.amazonaws.com/www.theedgeproperty.com.my/assets/images/regions/Putrajaya.jpg");
}

.block-agent-region .card-box:nth-child(6) {
  background-image: url("http://s3-ap-southeast-1.amazonaws.com/www.theedgeproperty.com.my/assets/images/regions/Ipoh.jpg");
}

.block-agent-region .card-box:nth-child(7) {
  background-image: url("http://s3-ap-southeast-1.amazonaws.com/www.theedgeproperty.com.my/assets/images/regions/Kedah.jpg");
}

.block-agent-region .card-box:nth-child(8) {
  background-image: url("http://s3-ap-southeast-1.amazonaws.com/www.theedgeproperty.com.my/assets/images/regions/Pahang.jpg");
}

.block-agent-region .card-box:nth-child(9) {
  background-image: url("http://s3-ap-southeast-1.amazonaws.com/www.theedgeproperty.com.my/assets/images/regions/NSembilan.jpg");
}

.block-agent-region .card-box:nth-child(10) {
  background-image: url("http://s3-ap-southeast-1.amazonaws.com/www.theedgeproperty.com.my/assets/images/regions/Melaka.jpg");
}

.block-agent-region .card-box:nth-child(11) {
  background-image: url("http://s3-ap-southeast-1.amazonaws.com/www.theedgeproperty.com.my/assets/images/regions/Terengganu.jpg");
}

.block-agent-region .card-box:nth-child(12) {
  background-image: url("http://s3-ap-southeast-1.amazonaws.com/www.theedgeproperty.com.my/assets/images/regions/Perlis.jpg");
}

.block-agent-region .card-box:nth-child(13) {
  background-image: url("http://s3-ap-southeast-1.amazonaws.com/www.theedgeproperty.com.my/assets/images/regions/Kelantan.jpg");
}

.block-agent-region .card-box:nth-child(14) {
  background-image: url("http://s3-ap-southeast-1.amazonaws.com/www.theedgeproperty.com.my/assets/images/regions/Sabah.jpg");
}

.block-agent-region .card-box:nth-child(15) {
  background-image: url("http://s3-ap-southeast-1.amazonaws.com/www.theedgeproperty.com.my/assets/images/regions/Sarawak.jpg");
}

.block-agent-region .card-label {
  background-color: rgba(0, 0, 0, 0.3);
  color: white;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 2rem;
}

.block-agent-region .cover {
  width: 23.5rem;
  height: 15rem;
  margin-right: 0.5rem;
  margin-bottom: 0.5rem;
  float: left;
  position: relative;
  background-repeat: no-repeat;
  background-position: center;
}

@media (max-width: 1125px) {
  .block-agent-region .card-box, .block-agent-region .cover {
    width: 23rem;
  }
  .block-agent-region .card-box:first-child {
    background-size: 46rem;
  }
  .block-agent-region .card-box:nth-child(n+2) {
    background-size: 23rem;
  }
}

@media (max-width: 991px) {
  .block-agent-region .card-box, .block-agent-region .cover {
    width: 35.5rem;
  }
  .block-agent-region .card-box:first-child {
    background-size: 46rem;
  }
  .block-agent-region .card-box:nth-child(n+2) {
    background-size: 35.5rem;
  }
}

@media (max-width: 767px) {
  .block-agent-region .card-box, .block-agent-region .cover {
    width: 100%;
  }
  .block-agent-region .card-box:first-child {
    height: 15rem;
    background-size: 100%;
  }
  .block-agent-region .card-box:nth-child(n) {
    background-size: 100%;
  }
}

.block-feature-agent .i-segment {
  border-radius: 0;
  background-color: #fafafa;
  box-shadow: 0 0 0 0.1rem #f7f6f6;
}

.block-feature-agent .i-segment .content .title {
  padding-bottom: 0.2rem;
}

.block-feature-agent .i-segment .content ul {
  list-style: none;
  padding-left: 0;
  margin-bottom: 0;
}

.block-feature-agent .i-segment .content ul li {
  padding-bottom: 0.2rem;
  color: #2e3a52;
}

.block-specialist-agent .i-segment {
  border-radius: 0;
  border: 0.1rem solid #f7f6f6;
  box-shadow: 0 0 0 0;
}

.block-specialist-agent .i-segment .content .title {
  padding-bottom: 0.2rem;
}

.block-specialist-agent .i-segment .content ul {
  list-style: none;
  padding-left: 0;
  margin-bottom: 0;
}

.block-specialist-agent .i-segment .content ul li {
  padding-bottom: 0.2rem;
  color: #2e3a52;
}

.block-specialist-agent .button.wide {
  padding: 1.5rem 3rem;
  border: 0.1rem solid;
  text-align: left;
  border-radius: 0;
}

.block-specialist-agent .button.wide .material-icons {
  color: #2e3a52;
  float: right;
  font-size: 1.8rem;
}

.block-specialist-agent .button.ghost:hover .material-icons {
  color: white;
}

.agent-listing-content .owl-loading {
  opacity: 1 !important;
}

.area-container {
  padding: 2rem;
  border: 0.1rem solid #f7f6f6;
}

.area-panel {
  margin-bottom: 1rem;
  border: 0.1rem solid #f7f6f6;
  background-color: #ffffff;
  border-radius: 0;
  box-shadow: none;
}

.area-panel .area-heading a {
  display: block;
  padding: 1.4rem 1.5rem;
  font-size: 1.3rem;
}

.area-panel .area-heading a:hover {
  background-color: #3b839c;
  text-decoration: none;
}

.area-panel .area-heading a:focus {
  text-decoration: none;
}

.area-panel .active {
  background-color: #3b839c;
}

.area-panel .active a {
  text-decoration: none;
}

.area-panel-body {
  padding: 1.4rem 1.5rem;
  font-size: 1.2rem;
}

.area-panel-body ul {
  padding: 0;
  list-style: none;
  margin-bottom: 0;
  line-height: 2.6rem;
  color: #3b839c;
}

.area-panel-body ul a {
  display: inline-block;
  color: #3b839c;
}

@media (max-width: 479px) {
  .area-container {
    padding: 2rem 1rem;
  }
}

.nearby {
  margin-bottom: 3rem;
  border-radius: 0;
  border: 0.1rem solid #f7f6f6;
  box-shadow: 0 0 0 0;
}

.nearby .head {
  background-color: #275075;
  color: #fff;
  font-size: 1.15rem;
  font-weight: 600;
  padding: 1.45rem 2rem;
  border-bottom: 0.1rem solid #f7f6f6;
}

.nearby .body {
  padding: 0 2rem;
  background: #fffffa;
}

.nearby .body .title {
  font-weight: 600;
}

.nearby .i-items.divided > .item {
  padding: 1.5rem 0;
  border-top: 0.1rem solid #f7f6f6;
}

.nearby .i-items.divided > .item:first-child {
  border-top: none;
}

.star-rating .i-segment {
  border-radius: 0;
  margin-bottom: 0.8rem;
  border: 0.1rem solid #f7f6f6;
  box-shadow: 0 0 0 0;
}

.nearby-list .i-segment {
  border-radius: 0;
  margin-bottom: 0.8rem;
  border: 0.1rem solid #f7f6f6;
  box-shadow: 0 0 0 0;
}

.nearby-list .category {
  padding-top: 0.5rem;
  padding-bottom: 2rem;
}

.nearby-list i {
  padding-right: 1rem;
  color: #3b839c;
}

.nearby-list .nearby-title {
  color: #3b839c;
  padding: 2rem 0;
  position: absolute;
  bottom: 0;
}

.nearby-list .nearby-title a {
  color: #3b839c;
}

.nearby-list .wrap {
  position: relative;
  padding-bottom: 5rem;
}

.nearby-list .info .material-icons {
  font-size: 1.4rem;
}

.area-transaction-filter .filter-col {
  padding: 0 0.4rem;
}

.area-price.listing-datatable {
  padding: 0;
  box-shadow: none;
}

@media screen and (min-width: 768px) {
  .area-price.listing-datatable {
    border: none;
  }
}

.trx-link a {
  color: #3b839c;
  transition: color 200ms ease-in-out;
}

@media (max-width: 767px) {
  .trx-link {
    background-color: transparent;
    transition: background-color 200ms ease-in-out;
  }
  .trx-link a {
    padding: 0.7rem 1.4rem;
    border: 0.2rem solid #3b839c;
    border-radius: 0.3rem;
    display: block;
    text-align: center;
  }
  .trx-link a:before {
    content: 'View all ';
    font-size: 1.2rem;
    color: #3b839c;
    transition: color 200ms ease-in-out;
  }
  .trx-link a:after {
    content: ' transactions ';
    font-size: 1.2rem;
    color: #3b839c;
    transition: color 200ms ease-in-out;
  }
  .trx-link:hover {
    background-color: #3b839c;
    border-radius: 0.3rem;
    transition: background-color 200ms ease-in-out;
  }
  .trx-link:hover a, .trx-link:hover a:before, .trx-link:hover a:after {
    color: #fff;
    transition: color 200ms ease-in-out;
    text-decoration: none;
  }
  .transaction-table .transaction-body .transaction-section-2 .transaction-item.area-trx {
    font-size: 1.6rem;
  }
}

.rateStar {
  float: left;
  padding-right: 2rem;
}

.team {
  display: flex;
  align-items: baseline;
}

.star-rating .title {
  font-weight: 700;
  color: #2e3a52;
}

.project-nearby .i-segment {
  border: 1px solid #f7f6f6;
  box-shadow: none;
}

.project-nearby .more-link {
  font-size: 1em;
  font-weight: 600;
  text-transform: uppercase;
}

.project-nearby .more-link a {
  text-decoration: none;
}

.project-trend .highchart-chart {
  height: 30rem;
}

@media (max-width: 479px) {
  .project-nearby {
    margin-left: -1.6rem;
    margin-right: -1.6rem;
    margin-bottom: -1.6rem;
    background-color: rgba(37, 37, 37, 0.03);
  }
  .project-nearby .i-list.double > li {
    float: none;
    width: auto;
  }
  .project-nearby .i-segment {
    border-radius: 0;
  }
  .project-nearby .i-segment + .i-segment {
    margin-top: 0.5rem;
  }
  .project-trend > h3.color-theme {
    font-size: 1.8rem;
    font-weight: 600;
  }
  .project-trend .highchart {
    margin-left: -3rem;
    margin-right: -3rem;
    background-color: #fff;
    border-top: 0.1rem solid #f7f6f6;
    border-bottom: 0.1rem solid #f7f6f6;
  }
  .project-trend .highchart-chart {
    height: 25rem;
  }
}

.transaction-input h2 {
  margin-top: 0;
  margin-bottom: 0.6em;
  font-size: 2.5rem;
  line-height: 1.2;
}

.transaction-input .i-form .input {
  border-color: #f7f6f6;
}

.transaction-input .i-form .input input {
  color: #2e3a52;
  font-weight: 600;
}

.transaction-input .i-form .input input::-webkit-input-placeholder {
  color: #2e3a52;
}

.transaction-input .i-form .input input:-moz-placeholder {
  color: #2e3a52;
}

.transaction-input .i-form .input input::-moz-placeholder {
  color: #2e3a52;
}

.transaction-input .i-form .input input:-ms-input-placeholder {
  color: #2e3a52;
}

.transaction-input .i-form .input .input-group-addon {
  border-width: 0;
  background-color: transparent;
  color: #2e3a52;
}

.transaction-input .i-form .twitter-typeahead .tt-menu {
  width: 100%;
}

.transaction-input.invert h2,
.transaction-input.invert .i-form input,
.transaction-input.invert .i-form .input-group-addon {
  color: #ffffff;
}

.transaction-input.invert .i-form input::-webkit-input-placeholder {
  color: #ffffff;
}

.transaction-input.invert .i-form input:-moz-placeholder {
  color: #ffffff;
}

.transaction-input.invert .i-form input::-moz-placeholder {
  color: #ffffff;
}

.transaction-input.invert .i-form input:-ms-input-placeholder {
  color: #ffffff;
}

.transaction-input.invert .i-form .input {
  border-color: #ffffff;
  background-color: transparent;
}

@media (min-width: 1600px) {
  .transaction-input h2 {
    font-size: 3.6rem;
  }
}

.transaction-filter .i-form .select {
  border-color: #f7f6f6;
}

.transaction-filter .i-form .select select {
  padding-left: 1rem;
  padding-right: 1rem;
  color: #2e3a52;
  font-weight: 600;
}

.transaction-filter .i-form .select:after {
  content: '\f123';
  display: inline-block;
  width: auto;
  height: auto;
  border: 0;
  font-family: "Ionicons";
  font-size: 1rem;
  line-height: 1;
  top: 1.7rem;
  right: 1rem;
}

.transaction-filter .i-form .input-group > .group:before, .transaction-filter .i-form .input-group > .group:after {
  content: '';
  display: table;
}

.transaction-filter .i-form .input-group > .group:after {
  clear: both;
}

.transaction-filter .i-form .input-group > .group > .select {
  float: left;
  width: 50%;
}

.transaction-filter .i-form .input-group > .group > .select:first-child {
  border-right: 0;
  border-radius: 0.4rem 0 0 0.4rem;
}

.transaction-filter .i-form .input-group > .group > .select:last-child {
  border-left: 0;
  border-radius: 0 0.4rem 0.4rem 0;
}

.transaction-scoreboard {
  color: #ffffff;
}

.transaction-scoreboard .search-link .item a {
  color: #3b839c;
}

.transaction-chart {
  background: #275075;
  color: #fff;
}

.transaction-chart .transaction-title {
  font-size: 2rem;
  font-weight: 600;
}

.transaction-chart .transaction-clabel {
  font-size: 1.5rem;
}

.transaction-chart .transaction-ctitle {
  font-size: 2rem;
  font-weight: 600;
}

@media (max-width: 767px) {
  .transaction-chart .transaction-clabel {
    font-size: 1.3rem;
  }
  .transaction-chart .transaction-ctitle {
    font-size: 1.6rem;
    font-weight: 700;
  }
  .transaction-chart .i-items > .item {
    display: block;
  }
  .transaction-chart .i-items > .item > .image {
    margin-left: auto;
    margin-right: auto;
  }
  .transaction-chart .i-items > .item > .image + .content {
    padding-left: 0;
    text-align: center;
  }
}

.transaction-table .transaction-name {
  color: #2e3a52;
  font-weight: 600;
}

.transaction-table .transaction-date {
  color: #2e3a52;
  font-weight: 600;
}

.transaction-table .transaction-head {
  display: none;
}

.transaction-table .transaction-body .transaction-name > small {
  display: block;
  font-weight: 400;
  color: #b1b1b1;
}

@media (max-width: 767px) {
  .transaction-table .transaction-name {
    font-size: 2rem;
    line-height: 1.2;
  }
  .transaction-table .transaction-date {
    font-size: 2rem;
    line-height: 1.2;
  }
  .transaction-table .transaction-address {
    color: #999;
  }
  .transaction-table .transaction-type {
    color: #999;
  }
  .transaction-table .transaction-psf:before {
    content: 'RM';
    margin-right: 0.5rem;
  }
  .transaction-table .transaction-psf:after {
    content: 'psf';
    margin-left: 0.5rem;
  }
  .transaction-table .transaction-sqft:after {
    content: 'sqft';
    margin-left: 0.5rem;
  }
  .transaction-table .transaction-price:before {
    content: 'RM';
    margin-right: 0.5rem;
  }
  .transaction-table .transaction-body .transaction-row {
    margin: 1.5rem 0;
    padding: 1.5rem;
    background-color: #fafafa;
    border: 0.1rem solid #f7f6f6;
    border-radius: 0.4rem;
  }
  .transaction-table .transaction-body .transaction-region-2 {
    margin-top: 1rem;
  }
  .transaction-table .transaction-body .transaction-section-2 {
    margin-top: 1rem;
  }
  .transaction-table .transaction-body .transaction-section-2:before, .transaction-table .transaction-body .transaction-section-2:after {
    content: '';
    display: table;
  }
  .transaction-table .transaction-body .transaction-section-2:after {
    clear: both;
  }
  .transaction-table .transaction-body .transaction-section-2 .transaction-item {
    float: left;
    width: 33.3%;
    font-size: 1.6rem;
    font-weight: 600;
    color: #565765;
  }
  .page-area-outlook .transaction-table .transaction-body .transaction-section-2 .transaction-item {
    width: 50%;
  }
  .transaction-table .transaction-body .transaction-section-2 .transaction-price {
    font-size: 1.6rem;
    text-align: right;
  }
  .transaction-table .transaction-body .transaction-section-4 .transaction-item {
    font-size: 2rem;
    font-weight: 600;
  }
}

@media (min-width: 768px) {
  .transaction-table {
    width: 100%;
  }
  .transaction-table .transaction-title, .transaction-table .transaction-item {
    float: left;
    padding: 0.8rem;
  }
  .transaction-table .transaction-title {
    color: #fff;
    font-weight: 500;
    letter-spacing: 0.02rem;
  }
  .transaction-table .transaction-title > a {
    color: #fff;
  }
  .transaction-table .transaction-region, .transaction-table .transaction-section {
    float: left;
  }
  .transaction-table .transaction-head {
    display: block;
    border: 0.1rem solid #f7f6f6;
    background: #275075;
  }
  .transaction-table .transaction-head .transaction-row:before, .transaction-table .transaction-head .transaction-row:after {
    content: '';
    display: table;
  }
  .transaction-table .transaction-head .transaction-row:after {
    clear: both;
  }
  .transaction-table .transaction-head .transaction-name {
    width: 21%;
  }
  .transaction-table .transaction-head .transaction-type {
    width: 34%;
  }
  .transaction-table .transaction-head .transaction-date {
    width: 10%;
  }
  .transaction-table .transaction-head .transaction-address {
    width: 30%;
  }
  .transaction-table .transaction-head .transaction-type2 {
    width: 15%;
  }
  .transaction-table .transaction-head .transaction-lotsize {
    width: 15%;
  }
  .transaction-table .transaction-head .transaction-psf {
    width: 15%;
  }
  .transaction-table .transaction-head .transaction-price {
    width: 15%;
  }
  .transaction-table .transaction-head .transaction-filed {
    width: 15%;
  }
  .transaction-table .transaction-head .transaction-title:not(.transaction-name):not(.transaction-address) {
    text-align: left;
  }
  .transaction-table .transaction-body .transaction-row:before, .transaction-table .transaction-body .transaction-row:after, .transaction-table .transaction-body .transaction-region:before, .transaction-table .transaction-body .transaction-region:after {
    content: '';
    display: table;
  }
  .transaction-table .transaction-body .transaction-row:after, .transaction-table .transaction-body .transaction-region:after {
    clear: both;
  }
  .transaction-table .transaction-body .transaction-row {
    margin-top: 0;
    padding-top: 0;
    padding-bottom: 0;
    border: 1px solid #f7f6f6;
  }
  .transaction-table .transaction-body .transaction-row:nth-child(even) {
    background-color: #fffffa;
  }
  .transaction-table .transaction-body .transaction-region-1 {
    width: 85%;
  }
  .transaction-table .transaction-body .transaction-region-2 {
    width: 15%;
  }
  .transaction-table .transaction-body .transaction-section-1 {
    width: 64.7%;
  }
  .transaction-table .transaction-body .transaction-section-2 {
    width: 35.3%;
  }
  .transaction-table .transaction-body .transaction-section-1 .transaction-name {
    width: 38%;
  }
  .transaction-table .transaction-body .transaction-section-1 .transaction-type {
    width: 62%;
  }
  .transaction-table .transaction-body .transaction-section-1 .transaction-date {
    width: 18.2%;
  }
  .transaction-table .transaction-body .transaction-section-1 .transaction-address {
    width: 54.6%;
  }
  .transaction-table .transaction-body .transaction-section-1 .transaction-type2 {
    width: 27.2%;
  }
  .transaction-table .transaction-body .transaction-section-1 .transaction-date, .transaction-table .transaction-body .transaction-section-1 .transaction-type, .transaction-table .transaction-body .transaction-section-1 .transaction-type2 {
    text-align: left;
  }
  .transaction-table .transaction-body .transaction-section-2 .transaction-item {
    width: 33%;
    text-align: left;
  }
  .transaction-table .transaction-body .transaction-section-2 .transaction-item:nth-child(2) {
    width: 35%;
  }
  .transaction-table .transaction-body .transaction-section-2 .transaction-item:nth-child(3) {
    width: 32%;
  }
  .transaction-table .transaction-body .transaction-section-3 {
    float: none;
    padding: 0.8rem;
    text-align: center;
  }
  .transaction-table .transaction-body .transaction-section-4 {
    float: none;
    text-align: center;
  }
  .transaction-table .transaction-body .transaction-section-4 .transaction-item {
    float: none;
  }
  .transaction-table.project-trx .transaction-head .transaction-date {
    width: 15%;
  }
  .transaction-table.project-trx .transaction-head .transaction-address {
    width: 34.6%;
  }
  .transaction-table.project-trx .transaction-head .transaction-sqft {
    width: 16.5%;
  }
  .transaction-table.project-trx .transaction-head .transaction-psf {
    width: 18%;
  }
  .transaction-table.project-trx .transaction-head .transaction-price {
    width: 15%;
  }
  .transaction-table.project-trx .transaction-body .transaction-region-1 {
    width: 100%;
  }
  .transaction-table.project-trx .transaction-body .transaction-section-1 {
    width: 50%;
  }
  .transaction-table.project-trx .transaction-body .transaction-section-2 {
    width: 50%;
  }
  .transaction-table.project-trx .transaction-body .transaction-section-1 .transaction-date {
    width: 30%;
  }
  .transaction-table.project-trx .transaction-body .transaction-section-1 .transaction-address {
    width: 70%;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  .transaction-table .transaction-body .transaction-section-1 .transaction-item {
    text-align: left;
  }
  .transaction-table .transaction-body .transaction-section-1 .transaction-name,
  .transaction-table .transaction-body .transaction-section-1 .transaction-type {
    padding: .8rem;
  }
  .transaction-table .transaction-body .transaction-section-1 .transaction-date,
  .transaction-table .transaction-body .transaction-section-1 .transaction-address,
  .transaction-table .transaction-body .transaction-section-1 .transaction-type2 {
    padding: .8rem;
  }
}

.transaction-svg {
  transform: rotate(-90deg);
}

.transaction-svg .default,
.transaction-svg .circle {
  fill: none;
  stroke-width: 1.6rem;
}

.transaction-svg .default {
  stroke: #f5f5f5;
}

.transaction-svg .circle {
  stroke: #f4b124;
  stroke-dasharray: 440;
  stroke-dashoffset: 440;
  stroke-linecap: round;
}

.transaction-svg .circle.quarter {
  animation: quarter 1s cubic-bezier(0.4, 0, 0.2, 1) forwards;
}

.transaction-svg .circle.half {
  animation: half 1s cubic-bezier(0.4, 0, 0.2, 1) forwards;
}

.transaction-svg .circle.three-quarter {
  animation: three-quarter 1s cubic-bezier(0.4, 0, 0.2, 1) forwards;
}

@keyframes quarter {
  to {
    stroke-dashoffset: 330;
  }
}

@keyframes half {
  to {
    stroke-dashoffset: 220;
  }
}

@keyframes three-quarter {
  to {
    stroke-dashoffset: 110;
  }
}

.erp-popup {
  display: none;
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 100;
  background-color: rgba(0, 0, 0, 0.5);
}

.erp-popuptxt {
  display: none;
  width: 28rem;
  height: 5rem;
  line-height: 5rem;
  margin-top: -10rem;
  margin-left: -14rem;
  background-color: rgba(0, 0, 0, 0.95);
  color: #ffffff;
  font-size: 2rem;
  text-align: center;
  position: fixed;
  top: 50%;
  left: 50%;
  z-index: 200;
  border-radius: 0.5rem;
}

.erp-form .input input:focus {
  box-shadow: none;
}

.erp-form .group .input-group-addon {
  border: 0;
  background-color: transparent;
  color: #c9c9c9;
  box-shadow: none;
}

.erp-form .actions .button {
  border-width: 0.1rem;
}

.erp-table .i-table th {
  position: relative;
}

.erp-table .i-table th > .pos-right {
  white-space: nowrap;
  position: absolute;
  bottom: 0.8rem;
  right: 0.8rem;
}

.hero-tron {
  margin-bottom: 0;
  padding-top: 8rem;
  padding-bottom: 6rem;
  background-color: transparent;
  color: #ffffff;
  position: relative;
}

.hero-tron .container {
  position: relative;
}

.hero-tron h1 {
  margin-top: 8rem;
  margin-bottom: 0;
  line-height: 1;
}

.hero-apps, .hero-social {
  margin-top: 0.5rem;
}

.hero-bg {
  background-position: center bottom;
  background-repeat: no-repeat;
  background-size: contain;
  background-color: #f5f6f8;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: -2;
}

.hero-bg.fixed {
  background-attachment: fixed;
}

.hero-bg.cover {
  background-size: cover;
}

@media only screen and (min-width: 768px) and (max-width: 1023px) {
  .hero-bg.cover.event-tab {
    background-image: url(https://media.edgeprop.my/cover/cny19/cny19-t3.jpg) !important;
  }
}

@media (min-width: 320px) and (max-width: 480px) {
  .hero-bg.cover.event-tab {
    background-image: url(https://media.edgeprop.my/cover/cny19/cny19-m3.jpg) !important;
  }
}

.hero-gradient {
  width: 100%;
  height: 100%;
  background-size: cover;
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
}

@media (max-width: 767px) {
  .hero-tron {
    padding-top: 4rem;
    padding-bottom: 4rem;
  }
  .hero-apps {
    margin-top: 0;
    text-align: center;
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
  }
  .hero-social {
    display: none;
    margin-top: 0.5rem;
    text-align: center;
  }
}

@media (min-width: 768px) and (max-width: 1199px) {
  .hero-apps, .hero-social {
    margin-top: 0;
    position: absolute;
    bottom: 0;
  }
  .hero-apps {
    left: 0.6rem;
  }
  .hero-social {
    right: 0.6rem;
  }
}

@media (min-width: 1200px) {
  .hero-apps, .hero-social {
    position: absolute;
    bottom: 1rem;
  }
  .hero-apps {
    left: 1rem;
  }
  .hero-social {
    right: 1rem;
  }
}

.footer-new {
  background-color: #f5f5f5;
  color: #333;
  padding: 50px 0px;
  position: relative;
  z-index: 2;
}

.footer-new ul {
  list-style: none;
  padding: 0px;
  padding-inline-start: 0px;
  font-size: 12px;
  line-height: 22px;
}

.footer-new ul li {
  line-height: 18px;
}

.footer-new h3 {
  font-size: 14px !important;
  font-weight: 600 !important;
  margin-top: 0px !important;
}

@media (max-width: 767.98px) {
  .footer-new {
    padding-bottom: 25%;
  }
}

@media (max-width: 575.98px) {
  .footer-new {
    padding: 40px 40px;
    text-align: center;
  }
}

.footer-new .intro-new {
  font-size: 11px;
  color: #333;
}

.footer-new .intro-new p {
  margin-bottom: 2.05rem;
}

.pre-footer-new {
  background-color: #fff;
  padding: 50px 0px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.25);
  position: relative;
  z-index: 2;
}

.pre-footer-new h3 {
  font-size: 14px !important;
  font-weight: 600 !important;
  margin-bottom: 6px !important;
  margin-top: 21px !important;
}

.pre-footer-new ul {
  list-style: none;
  padding: 0px;
  padding-inline-start: 0px;
  font-size: 12px;
  line-height: 22px;
}

.pre-footer-new-app-info {
  background: #dbdbdb;
  background: -moz-linear-gradient(0deg, #dbdbdb 0%, rgba(255, 255, 255, 0) 100%);
  background: -webkit-linear-gradient(0deg, #dbdbdb 0%, rgba(255, 255, 255, 0) 100%);
  background: linear-gradient(0deg, #dbdbdb 0%, rgba(255, 255, 255, 0) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#dbdbdb",endColorstr="#ffffff",GradientType=1);
  margin-bottom: 50px;
}

@media (min-width: 1280px) {
  .pre-footer-new-app-info .container {
    max-width: 1000px;
  }
}

.pre-footer-new-app-info .phone-mock {
  width: 500px;
  max-width: none;
  height: auto;
}

@media (max-width: 991.98px) {
  .pre-footer-new-app-info .phone-mock {
    display: none;
  }
}

.pre-footer-new-app-info .google-rating {
  display: block;
  margin-left: auto;
  margin-right: auto;
  height: 100px;
}

.pre-footer-new-app-info .app-info-left {
  padding: 60px 60px 0 0;
}

@media (max-width: 991.98px) {
  .pre-footer-new-app-info .app-info-left {
    padding: 0;
  }
}

.pre-footer-new-app-info .most-loved {
  font-family: 'Montserrat', sans-serif;
  font-size: 23px;
  color: #000;
  font-weight: bold;
  margin-top: 80px;
  margin-bottom: 90px;
  line-height: 1.4;
  letter-spacing: 0.8px;
  text-align: center;
}

@media (max-width: 991.98px) {
  .pre-footer-new-app-info .most-loved {
    margin: 20px 0 20px 0;
    font-size: 21px;
  }
}

.pre-footer-new-app-info h5 {
  font-size: 19px;
  text-align: center;
}

.pre-footer-new-app-info .app-desc {
  font-size: 14px;
  text-align: center;
}

@media (max-width: 991.98px) {
  .pre-footer-new-app-info .app-desc {
    font-size: 11px;
  }
}

@media (max-width: 991.98px) {
  .pre-footer-new-app-info .app-info-inner {
    margin-bottom: 40px;
  }
}

.pre-footer-new-app-info .app-info-inner .app-info-icons {
  margin: 12px 0;
  text-align: center;
}

.pre-footer-new-app-info .app-info-inner .app-info-icons a {
  width: 185px;
  display: inline-block;
  margin-right: 10px;
}

.pre-footer-new-app-info .app-info-inner .app-info-icons .mobile-logo {
  height: 48px;
}

@media (max-width: 991.98px) {
  .pre-footer-new-app-info .app-info-inner .app-info-icons .mobile-logo {
    margin-bottom: 20px;
  }
}

@media (max-width: 575.98px) {
  .pre-footer-new {
    padding: 50px 0px;
    text-align: center;
  }
}

h3.head-new {
  margin-top: 0px !important;
}

.pre-footer-new a.showMore-new {
  font-size: 14px;
  font-weight: 600;
  color: #1093fb !important;
  cursor: pointer;
}

.pre-footer-new a.showMore-new:hover {
  text-decoration: none;
}

.log-margin-new {
  margin-bottom: 2.5rem !important;
  padding-left: 16px;
}

.logo-height {
  height: 30px !important;
}

.logo-flag-height {
  height: 45px !important;
}

.d-inline-block-new {
  display: inline-block !important;
}

.align-top-new {
  vertical-align: top !important;
}

.image-left-pad {
  padding-right: 16px;
}

.copyright-new {
  font-size: 11px;
  color: #333;
  padding-left: 16px;
}

.news-letter-component #mc_embed_signup {
  background: none;
  clear: left;
  font: 14px Helvetica,Arial,sans-serif;
  width: 100%;
}

input#mce-EMAIL.email {
  font-family: "Open Sans","Helvetica Neue",Arial,Helvetica,Verdana,sans-serif;
  font-size: 15px;
  border: 1px solid #ABB0B2;
  border-right: 0px;
  color: #343434;
  background-color: #fff;
  box-sizing: border-box;
  height: 40px;
  padding: 0px 0.4em;
  display: inline-block;
  margin: 0;
  width: 72%;
  vertical-align: top;
  border-top-left-radius: 3px;
  border-bottom-left-radius: 3px;
}

@media (max-width: 768px) {
  input#mce-EMAIL.email {
    width: 100%;
  }
}

#mc_embed_signup .button {
  font-size: 13px;
  border: none;
  border-radius: 3px;
  letter-spacing: .03em;
  color: #fff;
  background-color: #2056AB;
  box-sizing: border-box;
  height: 40px;
  line-height: 32px;
  padding: 0 18px;
  display: inline-block;
  margin: 0;
  transition: all 0.23s ease-in-out 0s;
}

@media (max-width: 768px) {
  #mc_embed_signup .button {
    width: 100%;
  }
}

#mc_embed_signup label {
  display: block;
  font-size: 14px;
  color: #333333;
  padding-bottom: 10px;
  font-weight: bold;
  /* border-bottom: 1px solid #ddd; */
  width: 87%;
  margin-bottom: 0px;
  font-weight: 600;
}

@media (max-width: 768px) {
  #mc_embed_signup label {
    width: 100%;
  }
}

.styleMailChi-new {
  position: absolute;
  left: -5000px;
}

#mc_embed_signup .clear-new {
  display: inline-block;
  position: absolute;
  margin-left: -2px;
}

@media (max-width: 768px) {
  #mc_embed_signup .clear-new {
    display: block;
    position: static;
    margin-left: 0px;
  }
}

#mc_embed_signup form {
  padding: 10px 0 10px 0;
}

.container-footer {
  width: 100% !important;
}

.container-footer div, .container-footer h3, .container-footer p {
  font-family: "Poppins", sans-serif !important;
}

@media (min-width: 576px) {
  .container {
    max-width: 540px;
  }
}

@media (min-width: 768px) {
  .container {
    max-width: 720px;
  }
}

@media (min-width: 992px) {
  .container {
    max-width: 960px;
  }
}

@media (min-width: 1200px) {
  .container {
    max-width: 1140px;
  }
}

@media (min-width: 1200px) {
  .container {
    max-width: 1280px;
  }
}

.footer-22 {
  background-color: #ebecf0;
  color: #333;
  padding: 45px 0px 15px 0px;
  display: block;
}

.footer-22 div, .footer-22 h3, .footer-22 p {
  font-family: "Poppins", sans-serif !important;
}

.footer-22 .footer-layer2 {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}

@media (min-width: 575.98px) {
  .footer-22 .footer-layer2 {
    max-width: 640px;
  }
}

@media (min-width: 767.98px) {
  .footer-22 .footer-layer2 {
    max-width: 920px;
  }
}

@media (min-width: 991.98px) {
  .footer-22 .footer-layer2 {
    max-width: 1024px;
  }
}

@media (min-width: 1199.98px) {
  .footer-22 .footer-layer2 {
    max-width: 1170px;
  }
}

.footer-22 .footer-layer2 .footerRow {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-flex-wrap: wrap;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px;
  height: 100%;
  color: #333333;
}

.footer-22 .footer-layer2 .footerRow .footerCol {
  -webkit-flex: 0 0 50%;
  -ms-flex: 0 0 50%;
  flex: 0 0 50%;
  max-width: 50%;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
}

.footer-22 .footer-layer2 .footerRow .footerCol .imageWrapper {
  width: 130px;
  height: 30px;
  margin-bottom: 25px;
}

.footer-22 .footer-layer2 .footerRow .footerCol .textBlock {
  max-width: 620px;
}

.footer-22 .footer-layer2 .footerRow .footerCol .textBlock a {
  font-weight: 600;
}

.footer-22 .footer-layer2 .footerRow .footerCol .textBlock p {
  font-size: 10px;
  color: #3b4d5d;
  margin-top: 0;
  margin-bottom: 1rem;
  line-height: 15px;
}

.footer-22 .footer-layer2 .footerRow .footerCol h3 {
  font-size: 14px;
  font-weight: 600 !important;
  color: #140c40;
  margin-bottom: 25px;
}

.footer-22 .footer-layer2 .footerRow .footerCol ul {
  padding-left: 0;
}

.footer-22 .footer-layer2 .footerRow .footerCol ul li {
  list-style-type: none;
  margin-bottom: 10px;
  position: relative;
  line-height: 2;
}

@media (min-width: 770px) {
  .footer-22 .footer-layer2 .footerRow .footerCol ul li {
    margin-bottom: 0px;
  }
}

.footer-22 .footer-layer2 .footerRow .footerCol ul li:before {
  content: '';
  position: absolute;
  height: 1px;
  width: 0px;
  top: 13px;
  background: #2f80ed;
  margin-right: 20px;
  -webkit-transition: all ease-in-out 0.35s;
  transition: all ease-in-out 0.35s;
}

.footer-22 .footer-layer2 .footerRow .footerCol ul li a {
  color: #3b4d5d;
  font-size: 12px;
  transition: all 0.35s ease;
}

.footer-22 .footer-layer2 .footerRow .footerCol ul li a:hover {
  color: #2f80ed;
  -webkit-text-decoration: none;
  text-decoration: none;
}

.footer-22 .footer-layer2 .footerRow .footerCol ul li:hover a {
  -webkit-transition: all ease-in-out 0.35s;
  transition: all ease-in-out 0.35s;
  margin-left: 15px;
}

.footer-22 .footer-layer2 .footerRow .footerCol ul li:hover:before {
  width: 10px;
}

.footer-22 .footer-layer2 .footerRow .footerCol .subscribe-txt {
  line-height: 1.5;
  margin-bottom: 15px;
  color: #3b4d5d;
  font-size: 12px;
  letter-spacing: .03em;
}

@media (min-width: 768px) {
  .footer-22 .footer-layer2 .footerRow .footerCol .subscribe-txt {
    width: 90%;
  }
}

@media (min-width: 900px) {
  .footer-22 .footer-layer2 .footerRow .footerCol .subscribe-txt {
    width: 80%;
  }
}

@media (min-width: 1100px) {
  .footer-22 .footer-layer2 .footerRow .footerCol .subscribe-txt {
    width: 70%;
  }
}

.footer-22 .footer-layer2 .footerRow .footerCol .subscribe-btn {
  margin-top: 5px;
  margin-bottom: 15px;
}

.footer-22 .footer-layer2 .footerRow .footerCol .subscribe-btn a {
  background: #2056AB;
  color: white;
  padding: 6px 35px;
  border-radius: 3px;
  cursor: pointer;
  letter-spacing: .03em;
  line-height: 32px;
  font-size: 16px;
}

@media (max-width: 767px) {
  .footer-22 .footer-layer2 .footerRow .footerCol {
    flex: 0 0 100%;
    max-width: 100%;
  }
}

.footer-22 .footer-layer2 .footerRow .fillCol {
  -webkit-flex: 0 0 100%;
  -ms-flex: 0 0 100%;
  flex: 0 0 100%;
  max-width: 100%;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
}

.footer-22 .footer-layer2 .footerRow .fillCol .bottomWrap {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  justify-content: space-between;
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  width: 100%;
  border-top: 1px solid #b8bdcc;
  padding-top: 15px;
}

@media (max-width: 767px) {
  .footer-22 .footer-layer2 .footerRow .fillCol .bottomWrap {
    display: block;
  }
}

.footer-22 .footer-layer2 .footerRow .socialWrap {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.footer-22 .footer-layer2 .footerRow .socialWrap h3 {
  margin-right: 25px;
  margin-bottom: 0;
  margin-top: 2px;
  font-weight: 500 !important;
  color: #3b4d5d;
}

.footer-22 .footer-layer2 .footerRow .socialWrap ul {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-bottom: 0;
}

.footer-22 .footer-layer2 .footerRow .socialWrap ul li {
  margin-right: 20px;
  margin-bottom: 0;
}

.footer-22 .footer-layer2 .footerRow .socialWrap ul li:hover:before {
  width: 0;
}

.footer-22 .footer-layer2 .footerRow .socialWrap ul li:hover a {
  margin-left: 0px;
}

.footer-22 .footer-layer2 .footerRow .webWrap {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
}

.footer-22 .footer-layer2 .footerRow .webWrap a {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-bottom: 10px;
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-transition: all ease-in-out 0.3s;
  transition: all ease-in-out 0.3s;
}

.footer-22 .footer-layer2 .footerRow .webWrap a img {
  vertical-align: middle;
}

.footer-22 .footer-layer2 .footerRow .webWrap a p {
  color: #3b4d5d;
  font-size: 12px;
  margin-bottom: 0;
  margin-left: 10px;
  margin-top: 0;
}

.footer-22 .footer-layer2 .footerRow .cpWrap p {
  font-size: 10px;
  color: #3b4d5d;
  margin-bottom: 0px;
}

@media (max-width: 767px) {
  .footer-22 .footer-layer2 .footerRow .cpWrap {
    margin-top: 15px;
  }
}

body.page-edgehome2k18 {
  font-family: "Poppins", sans-serif;
  padding: 0;
}

.main {
  position: relative;
  max-width: 1600px;
  margin: auto;
}

*,
:after,
:before {
  box-sizing: border-box;
}

.SearchboxBackdrop {
  position: fixed;
  opacity: 0.35;
  background-color: #000;
  z-index: 19;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: none;
}

#hero-banner {
  padding: 145px 0 50px;
  z-index: auto;
  background-color: rgba(2, 2, 2, 0.35);
  margin-top: -72px;
  height: auto;
  background-position: center 0;
  background-size: auto;
  box-shadow: inset 0 -130px 100px -100px #000;
  position: relative;
  background-repeat: no-repeat;
  background-image: url("/home/4.jpg");
}

#hero-banner.event-theme {
  background-size: cover;
}

@media (min-width: 1500px) {
  #hero-banner.event-theme {
    padding: 145px 0 130px;
  }
}

#hero-banner:not(.with-placeholder) .lazyload-container {
  opacity: 0;
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

#hero-banner .lazyload-container .lazyload.critical-ready,
.become-a-host-btn.become-a-host-btn__new > .text-container > a.hovered span {
  transition: none;
}

#hero-banner .lazyload-container img,
#hero-banner .lazyload-container video {
  width: 100%;
}

#hero-banner .lazyload-container:after {
  content: " ";
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  box-shadow: inset 0 -130px 100px -100px #000;
}

#hero-banner .hero-banner-content.critical-ready {
  opacity: 1;
  transition: none;
  animation-duration: 1s;
  animation-fill-mode: none;
}

#hero-banner .hero-banner-content {
  /* padding-bottom: 200px; */
  min-height: 400px;
}

#hero-banner #hero-banner-header2,
#hero-banner #hero-banner-header1 {
  position: relative;
  font-family: "Montserrat", sans-serif;
}

#hero-banner #hero-banner-header1 {
  margin: 5px 0;
  color: #fff;
  text-align: center;
}

#hero-banner.h1,
#hero-banner h1 {
  font-weight: 200;
  line-height: 1.2;
  letter-spacing: 0.03em;
}

#hero-banner h2 {
  font-size: 19px;
  text-align: center;
  font-weight: 500;
  margin: 10px 0;
  color: #fff;
  font-weight: 100;
}

/*************************************SEARCH **********************************************/
#hero-banner #searchbox-root {
  min-height: 133px;
  margin-top: 50px;
}

#hero-banner .hero-banner-container .SearchBoxContainer {
  margin-top: 15px;
}

#hero-banner .SearchBoxContainer {
  position: relative;
  width: 1045px;
  margin: 0 auto;
}

#hero-banner .SearchBoxContainer--backdrop {
  z-index: 20;
}

#hero-banner .SearchBoxTabs {
  display: flex;
}

#hero-banner .SearchBoxTabs__tab {
  font-size: 1.5rem;
  color: #fff;
  background-color: rgba(0, 0, 0, 0.6);
  transition: color 0.2s ease-in-out, background-color 0.2s ease-in-out;
  padding: 10px 25px;
  margin-right: 2px;
  cursor: pointer;
  -webkit-user-select: none;
  user-select: none;
  display: block;
  float: left;
  letter-spacing: 1px;
}

#hero-banner .SearchBoxTabs__tab.isActive {
  color: #000;
  background-color: rgba(255, 255, 255, 0.8);
  cursor: default;
}

#hero-banner .SearchBoxContainer--Flights .FlightsFrameContainer {
  -webkit-animation: SearchBoxlightsTab 0.3s;
  animation: SearchBoxlightsTab 0.3s;
  background-color: rgba(255, 255, 255, 0.8);
  position: relative;
  max-height: 140px;
  height: 140px;
  width: 100%;
  padding: 0 7px;
  z-index: 1;
}

#hero-banner .SearchBoxContainer--Flights .FlightsFrameContainer--widget {
  height: 140px !important;
  width: 100% !important;
}

#hero-banner .Searchbox {
  padding: 13px 0;
  background-color: rgba(255, 255, 255, 0.8);
  width: 100%;
  max-height: 92px;
}

#hero-banner .search-redirect {
  height: 68px;
  display: none;
  position: relative;
}

#hero-banner .search-redirect img {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

#hero-banner .Searchbox__wrapper {
  position: relative;
}

#hero-banner .Searchbox__wrapper label {
  font-weight: normal;
}

#hero-banner .IconBox {
  display: inline-block;
  vertical-align: top;
  position: relative;
  margin: 0 5px 0 0;
  background-color: #fff;
  cursor: pointer;
  padding: 5px 5px;
  box-shadow: 0 4px 10px 0 rgba(0, 0, 0, 0.07);
  outline: none;
}

#hero-banner .SearchBoxContainer--horizontal .IconBox--autocomplete {
  width: 300px;
  height: 66px;
  display: inline-block;
  vertical-align: top;
  position: relative;
  margin: 0 5px 0 0;
  background-color: #fff;
  cursor: pointer;
  padding: 10px 5px;
  box-shadow: 0 4px 10px 0 rgba(0, 0, 0, 0.07);
}

#hero-banner .IconBox__icon {
  padding: 13px;
  margin-bottom: 0;
  display: inline-block;
  vertical-align: middle;
  font-family: icons;
  line-height: 1;
  font-weight: 400;
  font-style: normal;
  speak: none;
  text-decoration: inherit;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  font-size: 20px;
}

#hero-banner .ficon,
button,
select {
  text-transform: none;
}

#hero-banner .Searchbox .IconBox--autocomplete .IconBox__child {
  vertical-align: middle;
  display: inline-block;
  width: calc(100% - 60px);
}

#hero-banner .SearchBoxTextEditor--autocomplete {
  width: 100%;
  line-height: 1.25;
  padding: 0;
  margin: 0;
  outline: none;
}

#hero-banner .SearchBoxTextEditor {
  margin-top: 12px;
  font-size: 14px;
  color: #333;
  border: none;
  text-overflow: ellipsis;
  cursor: pointer;
  border-radius: 4px;
  background-color: #fff;
}

#hero-banner .SearchBoxContainer--horizontal .IconBox--checkIn,
.SearchBoxContainer--horizontal .IconBox--checkOut {
  width: 164px;
}

#hero-banner .Searchbox .IconBox--autocomplete,
.Searchbox .IconBox--checkIn,
.Searchbox .IconBox--checkOut,
.Searchbox .IconBox--occupancy {
  height: 66px;
}

#hero-banner .Searchbox__searchButton--active {
  z-index: 0;
}

#hero-banner .Searchbox__searchButton {
  position: relative;
  overflow: hidden;
  width: 130px;
  height: 66px;
  border-radius: 3px;
  background-color: #488BF8;
  font-size: 14px;
  color: #fff;
  text-transform: uppercase;
  box-shadow: 0 4px 10px 3px rgba(0, 0, 0, 0.2);
  border: none;
  cursor: pointer;
  padding: 10px 0;
}

#hero-banner .Searchbox__searchButton--active:before {
  background: #84b5fe;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: scale(0);
  transform: scale(0);
  width: 5%;
  padding-top: 5%;
  border-radius: 50%;
  transform: translate(-50%, -50%) scale(0);
  -moz-transform: translate(-50%, -50%) scale(0);
  -o-transform: translate(-50%, -50%) scale(0);
  -ms-transform: translate(-50%, -50%) scale(0);
  -webkit-transform: translate(-50%, -50%) scale(0);
  opacity: 0;
  transition: opacity 0.3s ease-out, -webkit-transform 0.3s ease-out;
  transition: transform 0.3s ease-out, opacity 0.3s ease-out;
  transition: transform 0.3s ease-out, opacity 0.3s ease-out, -webkit-transform 0.3s ease-out;
}

#hero-banner .Searchbox__searchButton--active > * {
  position: relative;
}

#hero-banner .Searchbox__searchButton__text {
  font-weight: 400;
}

#hero-banner select {
  border: 0;
  background: transparent;
  width: 150px;
  padding: 5px 35px 5px 5px;
  height: 54px;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background: url(idropdown.png) 96%/9% no-repeat;
  font-size: 14px;
  color: #333;
  outline: none;
}

#hero-banner .searchboxSale select option[data-type="rent"] {
  display: none;
}

#hero-banner .searchboxSale select option[data-type="sale"] {
  display: block;
}

#hero-banner .searchboxRent select option[data-type="rent"] {
  display: block;
}

#hero-banner .searchboxRent select option[data-type="sale"] {
  display: none;
}

#hero-banner #price-grp {
  font-size: 14px;
  color: #333;
  padding-top: 16px;
}

/* CAUTION: IE hackery ahead */
#hero-banner select::-ms-expand {
  display: none;
  /* remove default arrow on ie10 and ie11 */
}

#hero-banner .iconbox-state {
  margin-left: 13px;
}

#hero-banner i.fa-search {
  float: left;
  padding-top: 10px;
}

#hero-banner i.fa-search:before {
  font-size: 19px;
  padding: 5px;
  color: #333;
}

.mrt-button {
  position: absolute;
  right: 7px;
  top: 11px;
  background: rgba(72, 139, 248, 0.2);
}

.mrt-button:hover {
  background: rgba(72, 139, 248, 0.4);
}

.gin {
  position: absolute;
  width: 100%;
  height: 66px;
  top: 54px;
  overflow: hidden;
}

.child-gin {
  background: rgba(238, 238, 238, 0.7);
  width: 40%;
  width: 40%;
  height: 100%;
  margin-left: -40%;
}

#hero-banner .dropdown {
  width: 35rem;
  padding: 0.5rem;
  background-color: #fff;
  position: absolute;
  top: 100%;
  right: 0;
  z-index: 99;
  opacity: 0;
  visibility: hidden;
  -webkit-box-shadow: 0 0 0 0.1rem rgba(0, 0, 0, 0.04), 0 0.3rem 1rem -0.2rem rgba(0, 0, 0, 0.1);
  box-shadow: 0 0 0 0.1rem rgba(0, 0, 0, 0.04), 0 0.3rem 1rem -0.2rem rgba(0, 0, 0, 0.1);
}

#hero-banner .dropdown.active {
  opacity: 1;
  visibility: visible;
  margin-top: 13px;
  padding-left: 10px;
}

#hero-banner .dropdown:before {
  border-color: transparent transparent #fff;
  content: "";
  display: block;
  width: 0;
  border-width: 0.7rem;
  border-style: solid;
  position: absolute;
  bottom: 100%;
  right: 1rem;
}

#hero-banner .i-row {
  margin-left: -0.8rem;
  margin-right: -0.8rem;
}

#hero-banner .i-row > .i-col {
  padding-left: 0.8rem;
  padding-right: 0.8rem;
}

#hero-banner .Searchbox .IconBox--price {
  width: 226px;
  padding: 5px 10px;
}

.IconBox select,
.IconBox i,
.IconBox input,
.IconBox .input,
.IconBox a,
.Searchbox__searchButton__text {
  opacity: 0;
}

.IconBox.nogin select,
.IconBox.nogin i,
.IconBox.nogin input,
.IconBox.nogin .input,
.IconBox.nogin a,
.Searchbox__searchButton__text.nogin {
  opacity: 1;
}

/*************************FILTER********************************/
#hero-banner .SearchBoxFilters {
  margin-bottom: 0;
  transition: margin-bottom 0.3s ease-in-out;
  animation: SearchBoxotelsTab 0.3s;
  position: relative;
  width: 1045px;
  margin: 0 auto;
}

#hero-banner .SearchBoxFilters__slide {
  height: 0;
  padding: 0 5px;
  overflow: hidden;
  background-image: linear-gradient(180deg, rgba(204, 204, 204, 0.8), rgba(224, 224, 224, 0.8));
  border-radius: 0 0 3px 3px;
  transition: height 0.3s ease-in-out;
}

#hero-banner .SearchBoxFilters--opened .SearchBoxFilters__slide {
  height: 135px;
}

#hero-banner .SearchBoxFilters__content {
  display: table;
  table-layout: fixed;
  width: 100%;
  padding: 23px 0 0 51px;
  margin-top: 0;
}

#hero-banner .SearchBoxFilters__item {
  font-size: 14px;
  display: block;
  list-style-type: none;
  opacity: 1;
  transition: height 0.1s ease-in-out, opacity 0.1s ease-in-out;
  text-align: center;
  padding-bottom: 25px;
}

#hero-banner .SearchBoxFilters__toggle {
  cursor: pointer;
  display: block;
  width: 270px;
  margin: 0 auto;
  padding: 6px 0;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  color: #555;
  text-align: center;
  font-size: 13px;
  font-weight: 400;
  background: rgba(222, 222, 222, 0.8) url(idropdown.png) 80%/5% no-repeat;
  border-radius: 0 0 3px 3px;
}

#hero-banner .SearchBoxFilters--opened .SearchBoxFilters__toggle {
  background: rgba(222, 222, 222, 0.8) url(idropup.png) 80%/5% no-repeat;
}

#hero-banner .SearchBoxFilters__item .bedFilter__title,
#hero-banner .SearchBoxFilters__item .bathFilter__title {
  color: #000;
  font-weight: bolder;
}

#hero-banner .SearchBoxFilters__item .filter-menu {
  margin-bottom: 5px;
}

/*************** Refine filter *****************/
#hero-banner .radios.single {
  margin-bottom: 0;
  width: 100%;
}

#hero-banner .radio {
  display: inline-block;
}

#hero-banner .radio input {
  display: none;
  visibility: hidden;
}

#hero-banner .radio label {
  padding-left: 2.8rem;
  padding-right: 2rem;
  font-size: small;
  position: relative;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  font-weight: 400;
  color: #333;
  line-height: 1.5;
}

#hero-banner .radio label:before {
  border-radius: 100%;
  content: "";
  display: block;
  width: 1.5rem;
  height: 1.5rem;
  background-color: #fff;
  border: 0.1rem solid #dededf;
  position: absolute;
  top: 0;
  left: 0;
  -webkit-transition: background 200ms ease;
  transition: background 200ms ease;
}

#hero-banner .radio input:checked + label:after {
  content: "";
  width: 1rem;
  height: 1rem;
  margin: 0.25rem;
  background-color: #3b839c;
  border-radius: 100%;
  text-align: center;
  position: absolute;
  line-height: 1.5rem;
  top: 0;
  left: 0;
  display: block;
}

/**********************Suggestion Popup ******************/
#hero-banner .Popup.Autocomplete {
  left: 13px;
  top: 100%;
  transition: left 0.3s ease-out;
  position: absolute;
  display: none;
  z-index: 1;
}

#hero-banner .Popup:before {
  display: block;
  width: 0;
  height: 0;
  border-left: 11px solid transparent;
  border-right: 11px solid transparent;
  border-bottom: 11px solid #fff;
}

#hero-banner .Popup.Autocomplete:before {
  content: "";
  margin-left: 307px;
  transition: margin-left 0.3s ease-out;
}

#hero-banner .Popup.Autocomplete .Popup__content {
  width: 693px;
  left: -2px;
  background-color: #fff;
  transition: width 0.3s ease-out, height 0.3s ease-out, max-height 0.3s ease-out;
}

#hero-banner .AutocompleteList {
  background-color: #fff;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

#hero-banner .Suggestion {
  overflow: hidden;
  padding: 10px 12px;
  font-weight: 400;
  white-space: nowrap;
  letter-spacing: normal;
  cursor: pointer;
  font-size: 14px;
  line-height: 24px;
  list-style: none;
}

#hero-banner .Suggestion:hover {
  background-color: #0283df;
}

#hero-banner .Suggestion__child {
  float: right;
  max-width: 375px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

#hero-banner .Suggestion__child-Info__count {
  margin-right: 3px;
  color: #0283df;
}

#hero-banner .Suggestion__child-Info__text {
  color: #333;
}

#hero-banner .Suggestion__icon {
  float: left;
  margin-right: 5px;
  width: 20px;
}

#hero-banner .Suggestion__icon i {
  font-size: 20px;
}

#hero-banner .Suggestion__icon i::before {
  padding-top: 2px;
}

#hero-banner .Suggestion__text {
  color: #333;
}

#hero-banner .Suggestion__subText--normal {
  color: #333;
}

#hero-banner .Suggestion__subText {
  display: inline-block;
  max-width: 500px;
}

#hero-banner .Suggestion:hover .Suggestion__child-Info__count,
#hero-banner .Suggestion:hover .Suggestion__child-Info__text,
#hero-banner .Suggestion:hover .Suggestion__subText--normal,
#hero-banner .Suggestion:hover {
  color: #fff;
}

/******************Static Blocks *********/
.home_st_cnt {
  max-width: 890px;
  margin: 20px auto;
  position: relative;
  margin-left: 19%;
}

.home_st_cnt .blk_header {
  padding: 10px 0px;
  font-size: 1.8rem;
  font-weight: 700;
  cursor: pointer;
  color: #000;
  letter-spacing: 1px;
}

.home_st_cnt .content_wrapper {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}

.home_st_cnt .most_row {
  display: inline-flex;
}

.home_st_cnt .cell {
  box-shadow: 0 1px 37px 0 rgba(165, 165, 165, 0.29);
  margin: 18px;
  width: 273px;
  height: 229px;
  -webkit-transition: 0.2s;
  -o-transition: 0.2s;
  transition: 0.2s;
  display: table;
  flex-direction: row;
  position: relative;
}

.home_st_cnt.most_popular_wrapper .cell {
  color: #fff;
  font-size: 1.7rem;
  text-align: center;
}

.home_st_cnt.most_popular_wrapper .cell:hover {
  cursor: pointer;
}

.home_st_cnt .cell .cell-content {
  width: 100%;
  padding: 1.071em;
  background-color: #f7f6f6;
  position: absolute;
  left: 0;
  bottom: 0;
}

.home_st_cnt .cell .cell-title {
  margin-bottom: 0.833em;
  font-size: 1.143em;
  margin-top: 0;
}

.home_st_cnt .cell .cell-sub {
  margin-top: 0.727em;
  font-size: 0.714em;
  line-height: 1.364em;
  text-transform: uppercase;
  color: #252525;
}

.home_st_cnt .cell img {
  width: 100%;
  height: auto;
  max-height: 250px;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

/*************Range Slider ********/
.slidecontainer {
  width: 30%;
  float: left;
  margin-left: 14px;
}

.slider {
  -webkit-appearance: none;
  width: 100%;
  height: 3px;
  border-radius: 5px;
  background: #488BF8;
  outline: none;
  /* opacity: 0.7;
    -webkit-transition: .2s;
    transition: opacity .2s; */
}

.slider:hover {
  opacity: 1;
}

.slider::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 25px;
  height: 25px;
  border-radius: 50%;
  background: #fff;
  cursor: pointer;
  border: 1px solid #488BF8;
}

.slider::-moz-range-thumb {
  width: 25px;
  height: 25px;
  border-radius: 50%;
  border: 1px;
  background: #fff;
  cursor: pointer;
  border: 1px solid #488BF8;
}

.slidecontainer span {
  margin-left: 10px;
  font-weight: 500;
}

.slidecontainer p {
  margin-bottom: 2rem;
}

.search-nouislider {
  margin: 0 20px;
  height: 6px;
}

.search-nouislider .noUi-connect {
  background: #488BF8;
}

.search-nouislider .noUi-handle {
  top: -11px;
}

.zing-cat-card {
  position: relative;
  width: 50%;
  height: 220px;
  flex: 1 1 0;
  padding: 0 15px 20px;
}

.zing-cat-card.col-1 {
  flex-basis: 33.33333%;
}

.zing-cat-card.col-2 {
  flex-basis: 66.66667%;
}

.zing-cat-card-wrapper {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.zing-cat-card .image {
  opacity: 1;
  display: block;
  width: 100%;
  height: 100%;
  transition: 0.3s ease;
  backface-visibility: hidden;
  background-position: center;
  background-size: cover;
  border-radius: 5px;
  overflow: hidden;
}

.zing-cat-card .image .dark-gradient {
  opacity: 0;
  position: absolute;
  background-color: rgba(0, 0, 0, 0.4);
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  transition: 0.3s ease;
}

.zing-cat-card .middle {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  width: 80%;
}

.zing-cat-card .cat-name {
  color: white;
  font-size: 16px;
  flex-grow: 1;
  font-size: 25px;
  line-height: 35px;
}

.zing-cat-card .cat-desc {
  color: white;
  font-size: 16px;
  padding: 10px 32px;
  transition: 0.5s ease;
  opacity: 0;
  transform: scale(0, 0);
  height: 0;
  font-size: 15px;
  line-height: 22px;
}

.zing-cat-card:hover .cat-desc {
  opacity: 1;
  transform: scale(1, 1);
  height: 80px;
}

.zing-cat-card:hover .dark-gradient {
  opacity: 1;
}

.agt-registration {
  background-color: #f4eb49;
  position: absolute;
  top: 10rem;
  right: -180px;
  max-width: 180px;
  font-size: 1rem;
  letter-spacing: 0.03em;
  transition: all .3s ease-in-out;
}

.agt-registration.in {
  right: 0px;
  transition: all .3s ease-in-out;
}

.agt-registration a {
  color: black;
  display: block;
  padding: .6rem 1rem;
}

.agt-registration a:hover, .agt-registration a:active, .agt-registration a:focus {
  text-decoration: none;
}

.home-carousel .flickity-prev-next-button {
  width: 30px;
  height: 30px;
}

.front-mobile-carousel.carousel-cell {
  width: 180px;
  min-height: 100%;
  display: table;
  margin-right: 15px;
}

.front-mobile-carousel.carousel-cell .flickity-news-boxes {
  overflow: hidden;
  border: 1px solid #dedede;
  display: table-cell;
  background-color: #f7f6f6;
}

.front-mobile-carousel.carousel-cell .flickity-news-boxes .news-boxes-inner > a {
  display: block;
  width: 100%;
  height: 120px;
}

.front-mobile-carousel.carousel-cell .flickity-news-boxes .news-boxes-inner > a > img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.front-mobile-carousel.carousel-cell .flickity-news-content {
  padding: 1.2rem .8rem;
  width: 100%;
}

.front-mobile-carousel.carousel-cell .flickity-news-content .news-title {
  margin-top: 0;
  font-size: 1.2rem;
  font-weight: 400;
}

.front-mobile-carousel.carousel-cell .flickity-news-content .news-title a:hover {
  text-decoration: none;
}

.front-mobile-carousel.carousel-cell .flickity-news-content .news-date {
  font-size: 0.8rem;
}

.front-mobile-carousel.carousel-cell.discover-more > a {
  width: 100%;
  position: absolute;
  top: 0;
  bottom: 0;
}

.front-mobile-carousel.carousel-cell.discover-more > a > img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

@media screen and (min-width: 468px) {
  .front-mobile-carousel.carousel-cell {
    width: 250px;
    padding: 10px 24px 10px 0px;
  }
  .front-mobile-carousel.carousel-cell .flickity-news-boxes .news-boxes-inner > a {
    height: 190px;
  }
}

@media screen and (min-width: 768px) {
  .front-mobile-carousel.carousel-cell {
    width: 350px;
    padding: 10px 30px 10px 0px;
  }
  .front-mobile-carousel.carousel-cell .flickity-news-boxes .news-boxes-inner > a {
    height: 280px;
  }
}

.block-showcase .front-mobile-carousel .flickity-news-boxes .caption {
  font-size: 1.8rem;
  position: absolute;
  bottom: 0;
  padding: 0 1rem .5rem 1rem;
  color: white;
  font-weight: 500;
  letter-spacing: .03em;
  text-shadow: 0px 0px 5px #333;
  width: calc(100% - 2rem);
}

.block-showcase .front-mobile-carousel .flickity-news-boxes .news-boxes-inner > a {
  height: auto;
}

.block-showcase .front-mobile-carousel .flickity-news-boxes .news-boxes-inner > a img {
  max-width: 183px;
  height: auto;
}

@media screen and (min-width: 468px) {
  .block-showcase .front-mobile-carousel .flickity-news-boxes .news-boxes-inner > a {
    height: 280px;
  }
}

@media screen and (min-width: 768px) {
  .block-showcase .front-mobile-carousel .flickity-news-boxes .news-boxes-inner > a {
    height: 300px;
  }
}

.extra-links {
  list-style: none;
  padding: 0;
}

.extra-links-header {
  margin: 0;
  padding: 10px 8px;
  color: white;
  background: #021f54;
  border: 1px solid #021f54;
}

.extra-links li {
  border-top: 1px solid #8b8b8b;
  border-left: 1px solid #8b8b8b;
  border-right: 1px solid #8b8b8b;
}

.extra-links li:last-child {
  border-bottom: 1px solid #8b8b8b;
}

.extra-links li a {
  display: block;
  padding: 10px 8px;
  font-size: 1.2rem;
  letter-spacing: 0.03em;
  cursor: pointer;
}

.extra-links li a:hover, .extra-links li a:focus, .extra-links li a:active, .extra-links li a:visited {
  text-decoration: none;
}

.extra-links li a .title {
  font-weight: 600;
  color: #488BF8;
}

.extra-links li a .brief {
  color: black;
}

.m-reset {
  margin: 0 !important;
}

.m-lr {
  margin-left: 0 !important;
  margin-right: 0 !important;
}

.m-t-0 {
  margin-top: 0;
}

.m-t-5 {
  margin-top: 0.5rem;
}

.m-t-10 {
  margin-top: 1rem;
}

.m-t-15 {
  margin-top: 1.5rem;
}

.m-t-20 {
  margin-top: 2rem;
}

.m-t-25 {
  margin-top: 2.5rem;
}

.m-t-30 {
  margin-top: 3rem;
}

.m-t-35 {
  margin-top: 3.5rem;
}

.m-t-40 {
  margin-top: 4rem;
}

.m-t-45 {
  margin-top: 4.5rem;
}

.m-t-50 {
  margin-top: 5rem;
}

.m-t-55 {
  margin-top: 5.5rem;
}

.m-t-60 {
  margin-top: 6rem;
}

.m-t-65 {
  margin-top: 6.5rem;
}

.m-t-70 {
  margin-top: 7rem;
}

.m-t-75 {
  margin-top: 7.5rem;
}

.m-t-80 {
  margin-top: 8rem;
}

.m-t-85 {
  margin-top: 8.5rem;
}

.m-t-90 {
  margin-top: 9rem;
}

.m-t-95 {
  margin-top: 9.5rem;
}

.m-t-100 {
  margin-top: 10rem;
}

.m-b-0 {
  margin-bottom: 0;
}

.m-b-5 {
  margin-bottom: 0.5rem;
}

.m-b-10 {
  margin-bottom: 1rem;
}

.m-b-15 {
  margin-bottom: 1.5rem;
}

.m-b-20 {
  margin-bottom: 2rem;
}

.m-b-25 {
  margin-bottom: 2.5rem;
}

.m-b-30 {
  margin-bottom: 3rem;
}

.m-b-35 {
  margin-bottom: 3.5rem;
}

.m-b-40 {
  margin-bottom: 4rem;
}

.m-b-45 {
  margin-bottom: 4.5rem;
}

.m-b-50 {
  margin-bottom: 5rem;
}

.m-b-55 {
  margin-bottom: 5.5rem;
}

.m-b-60 {
  margin-bottom: 6rem;
}

.m-b-65 {
  margin-bottom: 6.5rem;
}

.m-b-70 {
  margin-bottom: 7rem;
}

.m-b-75 {
  margin-bottom: 7.5rem;
}

.m-b-80 {
  margin-bottom: 8rem;
}

.m-b-85 {
  margin-bottom: 8.5rem;
}

.m-b-90 {
  margin-bottom: 9rem;
}

.m-b-95 {
  margin-bottom: 9.5rem;
}

.m-b-100 {
  margin-bottom: 10rem;
}

.m-l-0 {
  margin-left: 0;
}

.m-l-5 {
  margin-left: 0.5rem;
}

.m-l-10 {
  margin-left: 1rem;
}

.m-l-15 {
  margin-left: 1.5rem;
}

.m-l-20 {
  margin-left: 2rem;
}

.m-l-25 {
  margin-left: 2.5rem;
}

.m-l-30 {
  margin-left: 3rem;
}

.m-l-35 {
  margin-left: 3.5rem;
}

.m-l-40 {
  margin-left: 4rem;
}

.m-l-45 {
  margin-left: 4.5rem;
}

.m-l-50 {
  margin-left: 5rem;
}

.m-l-55 {
  margin-left: 5.5rem;
}

.m-l-60 {
  margin-left: 6rem;
}

.m-l-65 {
  margin-left: 6.5rem;
}

.m-l-70 {
  margin-left: 7rem;
}

.m-l-75 {
  margin-left: 7.5rem;
}

.m-l-80 {
  margin-left: 8rem;
}

.m-l-85 {
  margin-left: 8.5rem;
}

.m-l-90 {
  margin-left: 9rem;
}

.m-l-95 {
  margin-left: 9.5rem;
}

.m-l-100 {
  margin-left: 10rem;
}

.m-r-0 {
  margin-right: 0;
}

.m-r-5 {
  margin-right: 0.5rem;
}

.m-r-10 {
  margin-right: 1rem;
}

.m-r-15 {
  margin-right: 1.5rem;
}

.m-r-20 {
  margin-right: 2rem;
}

.m-r-25 {
  margin-right: 2.5rem;
}

.m-r-30 {
  margin-right: 3rem;
}

.m-r-35 {
  margin-right: 3.5rem;
}

.m-r-40 {
  margin-right: 4rem;
}

.m-r-45 {
  margin-right: 4.5rem;
}

.m-r-50 {
  margin-right: 5rem;
}

.m-r-55 {
  margin-right: 5.5rem;
}

.m-r-60 {
  margin-right: 6rem;
}

.m-r-65 {
  margin-right: 6.5rem;
}

.m-r-70 {
  margin-right: 7rem;
}

.m-r-75 {
  margin-right: 7.5rem;
}

.m-r-80 {
  margin-right: 8rem;
}

.m-r-85 {
  margin-right: 8.5rem;
}

.m-r-90 {
  margin-right: 9rem;
}

.m-r-95 {
  margin-right: 9.5rem;
}

.m-r-100 {
  margin-right: 10rem;
}

.p-reset {
  padding: 0 !important;
}

.p-lr {
  padding-left: 0 !important;
  padding-right: 0 !important;
}

.p-t-0 {
  padding-top: 0;
}

.p-t-5 {
  padding-top: 0.5rem;
}

.p-t-10 {
  padding-top: 1rem;
}

.p-t-15 {
  padding-top: 1.5rem;
}

.p-t-20 {
  padding-top: 2rem;
}

.p-t-25 {
  padding-top: 2.5rem;
}

.p-t-30 {
  padding-top: 3rem;
}

.p-t-35 {
  padding-top: 3.5rem;
}

.p-t-40 {
  padding-top: 4rem;
}

.p-t-45 {
  padding-top: 4.5rem;
}

.p-t-50 {
  padding-top: 5rem;
}

.p-t-55 {
  padding-top: 5.5rem;
}

.p-t-60 {
  padding-top: 6rem;
}

.p-t-65 {
  padding-top: 6.5rem;
}

.p-t-70 {
  padding-top: 7rem;
}

.p-t-75 {
  padding-top: 7.5rem;
}

.p-t-80 {
  padding-top: 8rem;
}

.p-t-85 {
  padding-top: 8.5rem;
}

.p-t-90 {
  padding-top: 9rem;
}

.p-t-95 {
  padding-top: 9.5rem;
}

.p-t-100 {
  padding-top: 10rem;
}

.p-b-0 {
  padding-bottom: 0;
}

.p-b-5 {
  padding-bottom: 0.5rem;
}

.p-b-10 {
  padding-bottom: 1rem;
}

.p-b-15 {
  padding-bottom: 1.5rem;
}

.p-b-20 {
  padding-bottom: 2rem;
}

.p-b-25 {
  padding-bottom: 2.5rem;
}

.p-b-30 {
  padding-bottom: 3rem;
}

.p-b-35 {
  padding-bottom: 3.5rem;
}

.p-b-40 {
  padding-bottom: 4rem;
}

.p-b-45 {
  padding-bottom: 4.5rem;
}

.p-b-50 {
  padding-bottom: 5rem;
}

.p-b-55 {
  padding-bottom: 5.5rem;
}

.p-b-60 {
  padding-bottom: 6rem;
}

.p-b-65 {
  padding-bottom: 6.5rem;
}

.p-b-70 {
  padding-bottom: 7rem;
}

.p-b-75 {
  padding-bottom: 7.5rem;
}

.p-b-80 {
  padding-bottom: 8rem;
}

.p-b-85 {
  padding-bottom: 8.5rem;
}

.p-b-90 {
  padding-bottom: 9rem;
}

.p-b-95 {
  padding-bottom: 9.5rem;
}

.p-b-100 {
  padding-bottom: 10rem;
}

.p-l-0 {
  padding-left: 0;
}

.p-l-5 {
  padding-left: 0.5rem;
}

.p-l-10 {
  padding-left: 1rem;
}

.p-l-15 {
  padding-left: 1.5rem;
}

.p-l-20 {
  padding-left: 2rem;
}

.p-l-25 {
  padding-left: 2.5rem;
}

.p-l-30 {
  padding-left: 3rem;
}

.p-l-35 {
  padding-left: 3.5rem;
}

.p-l-40 {
  padding-left: 4rem;
}

.p-l-45 {
  padding-left: 4.5rem;
}

.p-l-50 {
  padding-left: 5rem;
}

.p-l-55 {
  padding-left: 5.5rem;
}

.p-l-60 {
  padding-left: 6rem;
}

.p-l-65 {
  padding-left: 6.5rem;
}

.p-l-70 {
  padding-left: 7rem;
}

.p-l-75 {
  padding-left: 7.5rem;
}

.p-l-80 {
  padding-left: 8rem;
}

.p-l-85 {
  padding-left: 8.5rem;
}

.p-l-90 {
  padding-left: 9rem;
}

.p-l-95 {
  padding-left: 9.5rem;
}

.p-l-100 {
  padding-left: 10rem;
}

.p-r-0 {
  padding-right: 0;
}

.p-r-5 {
  padding-right: 0.5rem;
}

.p-r-10 {
  padding-right: 1rem;
}

.p-r-15 {
  padding-right: 1.5rem;
}

.p-r-20 {
  padding-right: 2rem;
}

.p-r-25 {
  padding-right: 2.5rem;
}

.p-r-30 {
  padding-right: 3rem;
}

.p-r-35 {
  padding-right: 3.5rem;
}

.p-r-40 {
  padding-right: 4rem;
}

.p-r-45 {
  padding-right: 4.5rem;
}

.p-r-50 {
  padding-right: 5rem;
}

.p-r-55 {
  padding-right: 5.5rem;
}

.p-r-60 {
  padding-right: 6rem;
}

.p-r-65 {
  padding-right: 6.5rem;
}

.p-r-70 {
  padding-right: 7rem;
}

.p-r-75 {
  padding-right: 7.5rem;
}

.p-r-80 {
  padding-right: 8rem;
}

.p-r-85 {
  padding-right: 8.5rem;
}

.p-r-90 {
  padding-right: 9rem;
}

.p-r-95 {
  padding-right: 9.5rem;
}

.p-r-100 {
  padding-right: 10rem;
}

@media (max-width: 1199px) {
  .m-t-md-0 {
    margin-top: 0 !important;
  }
  .m-t-md-5 {
    margin-top: 0.5rem !important;
  }
  .m-t-md-10 {
    margin-top: 1rem !important;
  }
  .m-t-md-15 {
    margin-top: 1.5rem !important;
  }
  .m-t-md-20 {
    margin-top: 2rem !important;
  }
  .m-t-md-25 {
    margin-top: 2.5rem !important;
  }
  .m-t-md-30 {
    margin-top: 3rem !important;
  }
  .m-t-md-35 {
    margin-top: 3.5rem !important;
  }
  .m-t-md-40 {
    margin-top: 4rem !important;
  }
  .m-t-md-45 {
    margin-top: 4.5rem !important;
  }
  .m-t-md-50 {
    margin-top: 5rem !important;
  }
  .m-t-md-55 {
    margin-top: 5.5rem !important;
  }
  .m-t-md-60 {
    margin-top: 6rem !important;
  }
  .m-t-md-65 {
    margin-top: 6.5rem !important;
  }
  .m-t-md-70 {
    margin-top: 7rem !important;
  }
  .m-t-md-75 {
    margin-top: 7.5rem !important;
  }
  .m-t-md-80 {
    margin-top: 8rem !important;
  }
  .m-t-md-85 {
    margin-top: 8.5rem !important;
  }
  .m-t-md-90 {
    margin-top: 9rem !important;
  }
  .m-t-md-95 {
    margin-top: 9.5rem !important;
  }
  .m-t-md-100 {
    margin-top: 10rem !important;
  }
  .m-b-md-0 {
    margin-bottom: 0 !important;
  }
  .m-b-md-5 {
    margin-bottom: 0.5rem !important;
  }
  .m-b-md-10 {
    margin-bottom: 1rem !important;
  }
  .m-b-md-15 {
    margin-bottom: 1.5rem !important;
  }
  .m-b-md-20 {
    margin-bottom: 2rem !important;
  }
  .m-b-md-25 {
    margin-bottom: 2.5rem !important;
  }
  .m-b-md-30 {
    margin-bottom: 3rem !important;
  }
  .m-b-md-35 {
    margin-bottom: 3.5rem !important;
  }
  .m-b-md-40 {
    margin-bottom: 4rem !important;
  }
  .m-b-md-45 {
    margin-bottom: 4.5rem !important;
  }
  .m-b-md-50 {
    margin-bottom: 5rem !important;
  }
  .m-b-md-55 {
    margin-bottom: 5.5rem !important;
  }
  .m-b-md-60 {
    margin-bottom: 6rem !important;
  }
  .m-b-md-65 {
    margin-bottom: 6.5rem !important;
  }
  .m-b-md-70 {
    margin-bottom: 7rem !important;
  }
  .m-b-md-75 {
    margin-bottom: 7.5rem !important;
  }
  .m-b-md-80 {
    margin-bottom: 8rem !important;
  }
  .m-b-md-85 {
    margin-bottom: 8.5rem !important;
  }
  .m-b-md-90 {
    margin-bottom: 9rem !important;
  }
  .m-b-md-95 {
    margin-bottom: 9.5rem !important;
  }
  .m-b-md-100 {
    margin-bottom: 10rem !important;
  }
  .p-t-md-0 {
    padding-top: 0 !important;
  }
  .p-t-md-5 {
    padding-top: 0.5rem !important;
  }
  .p-t-md-10 {
    padding-top: 1rem !important;
  }
  .p-t-md-15 {
    padding-top: 1.5rem !important;
  }
  .p-t-md-20 {
    padding-top: 2rem !important;
  }
  .p-t-md-25 {
    padding-top: 2.5rem !important;
  }
  .p-t-md-30 {
    padding-top: 3rem !important;
  }
  .p-t-md-35 {
    padding-top: 3.5rem !important;
  }
  .p-t-md-40 {
    padding-top: 4rem !important;
  }
  .p-t-md-45 {
    padding-top: 4.5rem !important;
  }
  .p-t-md-50 {
    padding-top: 5rem !important;
  }
  .p-t-md-55 {
    padding-top: 5.5rem !important;
  }
  .p-t-md-60 {
    padding-top: 6rem !important;
  }
  .p-t-md-65 {
    padding-top: 6.5rem !important;
  }
  .p-t-md-70 {
    padding-top: 7rem !important;
  }
  .p-t-md-75 {
    padding-top: 7.5rem !important;
  }
  .p-t-md-80 {
    padding-top: 8rem !important;
  }
  .p-t-md-85 {
    padding-top: 8.5rem !important;
  }
  .p-t-md-90 {
    padding-top: 9rem !important;
  }
  .p-t-md-95 {
    padding-top: 9.5rem !important;
  }
  .p-t-md-100 {
    padding-top: 10rem !important;
  }
  .p-b-md-0 {
    padding-bottom: 0 !important;
  }
  .p-b-md-5 {
    padding-bottom: 0.5rem !important;
  }
  .p-b-md-10 {
    padding-bottom: 1rem !important;
  }
  .p-b-md-15 {
    padding-bottom: 1.5rem !important;
  }
  .p-b-md-20 {
    padding-bottom: 2rem !important;
  }
  .p-b-md-25 {
    padding-bottom: 2.5rem !important;
  }
  .p-b-md-30 {
    padding-bottom: 3rem !important;
  }
  .p-b-md-35 {
    padding-bottom: 3.5rem !important;
  }
  .p-b-md-40 {
    padding-bottom: 4rem !important;
  }
  .p-b-md-45 {
    padding-bottom: 4.5rem !important;
  }
  .p-b-md-50 {
    padding-bottom: 5rem !important;
  }
  .p-b-md-55 {
    padding-bottom: 5.5rem !important;
  }
  .p-b-md-60 {
    padding-bottom: 6rem !important;
  }
  .p-b-md-65 {
    padding-bottom: 6.5rem !important;
  }
  .p-b-md-70 {
    padding-bottom: 7rem !important;
  }
  .p-b-md-75 {
    padding-bottom: 7.5rem !important;
  }
  .p-b-md-80 {
    padding-bottom: 8rem !important;
  }
  .p-b-md-85 {
    padding-bottom: 8.5rem !important;
  }
  .p-b-md-90 {
    padding-bottom: 9rem !important;
  }
  .p-b-md-95 {
    padding-bottom: 9.5rem !important;
  }
  .p-b-md-100 {
    padding-bottom: 10rem !important;
  }
}

@media (max-width: 991px) {
  .m-t-sm-0 {
    margin-top: 0 !important;
  }
  .m-t-sm-5 {
    margin-top: 0.5rem !important;
  }
  .m-t-sm-10 {
    margin-top: 1rem !important;
  }
  .m-t-sm-15 {
    margin-top: 1.5rem !important;
  }
  .m-t-sm-20 {
    margin-top: 2rem !important;
  }
  .m-t-sm-25 {
    margin-top: 2.5rem !important;
  }
  .m-t-sm-30 {
    margin-top: 3rem !important;
  }
  .m-t-sm-35 {
    margin-top: 3.5rem !important;
  }
  .m-t-sm-40 {
    margin-top: 4rem !important;
  }
  .m-t-sm-45 {
    margin-top: 4.5rem !important;
  }
  .m-t-sm-50 {
    margin-top: 5rem !important;
  }
  .m-t-sm-55 {
    margin-top: 5.5rem !important;
  }
  .m-t-sm-60 {
    margin-top: 6rem !important;
  }
  .m-t-sm-65 {
    margin-top: 6.5rem !important;
  }
  .m-t-sm-70 {
    margin-top: 7rem !important;
  }
  .m-t-sm-75 {
    margin-top: 7.5rem !important;
  }
  .m-t-sm-80 {
    margin-top: 8rem !important;
  }
  .m-t-sm-85 {
    margin-top: 8.5rem !important;
  }
  .m-t-sm-90 {
    margin-top: 9rem !important;
  }
  .m-t-sm-95 {
    margin-top: 9.5rem !important;
  }
  .m-t-sm-100 {
    margin-top: 10rem !important;
  }
  .m-b-sm-0 {
    margin-bottom: 0 !important;
  }
  .m-b-sm-5 {
    margin-bottom: 0.5rem !important;
  }
  .m-b-sm-10 {
    margin-bottom: 1rem !important;
  }
  .m-b-sm-15 {
    margin-bottom: 1.5rem !important;
  }
  .m-b-sm-20 {
    margin-bottom: 2rem !important;
  }
  .m-b-sm-25 {
    margin-bottom: 2.5rem !important;
  }
  .m-b-sm-30 {
    margin-bottom: 3rem !important;
  }
  .m-b-sm-35 {
    margin-bottom: 3.5rem !important;
  }
  .m-b-sm-40 {
    margin-bottom: 4rem !important;
  }
  .m-b-sm-45 {
    margin-bottom: 4.5rem !important;
  }
  .m-b-sm-50 {
    margin-bottom: 5rem !important;
  }
  .m-b-sm-55 {
    margin-bottom: 5.5rem !important;
  }
  .m-b-sm-60 {
    margin-bottom: 6rem !important;
  }
  .m-b-sm-65 {
    margin-bottom: 6.5rem !important;
  }
  .m-b-sm-70 {
    margin-bottom: 7rem !important;
  }
  .m-b-sm-75 {
    margin-bottom: 7.5rem !important;
  }
  .m-b-sm-80 {
    margin-bottom: 8rem !important;
  }
  .m-b-sm-85 {
    margin-bottom: 8.5rem !important;
  }
  .m-b-sm-90 {
    margin-bottom: 9rem !important;
  }
  .m-b-sm-95 {
    margin-bottom: 9.5rem !important;
  }
  .m-b-sm-100 {
    margin-bottom: 10rem !important;
  }
  .p-t-sm-0 {
    padding-top: 0 !important;
  }
  .p-t-sm-5 {
    padding-top: 0.5rem !important;
  }
  .p-t-sm-10 {
    padding-top: 1rem !important;
  }
  .p-t-sm-15 {
    padding-top: 1.5rem !important;
  }
  .p-t-sm-20 {
    padding-top: 2rem !important;
  }
  .p-t-sm-25 {
    padding-top: 2.5rem !important;
  }
  .p-t-sm-30 {
    padding-top: 3rem !important;
  }
  .p-t-sm-35 {
    padding-top: 3.5rem !important;
  }
  .p-t-sm-40 {
    padding-top: 4rem !important;
  }
  .p-t-sm-45 {
    padding-top: 4.5rem !important;
  }
  .p-t-sm-50 {
    padding-top: 5rem !important;
  }
  .p-t-sm-55 {
    padding-top: 5.5rem !important;
  }
  .p-t-sm-60 {
    padding-top: 6rem !important;
  }
  .p-t-sm-65 {
    padding-top: 6.5rem !important;
  }
  .p-t-sm-70 {
    padding-top: 7rem !important;
  }
  .p-t-sm-75 {
    padding-top: 7.5rem !important;
  }
  .p-t-sm-80 {
    padding-top: 8rem !important;
  }
  .p-t-sm-85 {
    padding-top: 8.5rem !important;
  }
  .p-t-sm-90 {
    padding-top: 9rem !important;
  }
  .p-t-sm-95 {
    padding-top: 9.5rem !important;
  }
  .p-t-sm-100 {
    padding-top: 10rem !important;
  }
  .p-b-sm-0 {
    padding-bottom: 0 !important;
  }
  .p-b-sm-5 {
    padding-bottom: 0.5rem !important;
  }
  .p-b-sm-10 {
    padding-bottom: 1rem !important;
  }
  .p-b-sm-15 {
    padding-bottom: 1.5rem !important;
  }
  .p-b-sm-20 {
    padding-bottom: 2rem !important;
  }
  .p-b-sm-25 {
    padding-bottom: 2.5rem !important;
  }
  .p-b-sm-30 {
    padding-bottom: 3rem !important;
  }
  .p-b-sm-35 {
    padding-bottom: 3.5rem !important;
  }
  .p-b-sm-40 {
    padding-bottom: 4rem !important;
  }
  .p-b-sm-45 {
    padding-bottom: 4.5rem !important;
  }
  .p-b-sm-50 {
    padding-bottom: 5rem !important;
  }
  .p-b-sm-55 {
    padding-bottom: 5.5rem !important;
  }
  .p-b-sm-60 {
    padding-bottom: 6rem !important;
  }
  .p-b-sm-65 {
    padding-bottom: 6.5rem !important;
  }
  .p-b-sm-70 {
    padding-bottom: 7rem !important;
  }
  .p-b-sm-75 {
    padding-bottom: 7.5rem !important;
  }
  .p-b-sm-80 {
    padding-bottom: 8rem !important;
  }
  .p-b-sm-85 {
    padding-bottom: 8.5rem !important;
  }
  .p-b-sm-90 {
    padding-bottom: 9rem !important;
  }
  .p-b-sm-95 {
    padding-bottom: 9.5rem !important;
  }
  .p-b-sm-100 {
    padding-bottom: 10rem !important;
  }
}

@media (max-width: 767px) {
  .m-t-xs-0 {
    margin-top: 0 !important;
  }
  .m-t-xs-5 {
    margin-top: 0.5rem !important;
  }
  .m-t-xs-10 {
    margin-top: 1rem !important;
  }
  .m-t-xs-15 {
    margin-top: 1.5rem !important;
  }
  .m-t-xs-20 {
    margin-top: 2rem !important;
  }
  .m-t-xs-25 {
    margin-top: 2.5rem !important;
  }
  .m-t-xs-30 {
    margin-top: 3rem !important;
  }
  .m-t-xs-35 {
    margin-top: 3.5rem !important;
  }
  .m-t-xs-40 {
    margin-top: 4rem !important;
  }
  .m-t-xs-45 {
    margin-top: 4.5rem !important;
  }
  .m-t-xs-50 {
    margin-top: 5rem !important;
  }
  .m-t-xs-55 {
    margin-top: 5.5rem !important;
  }
  .m-t-xs-60 {
    margin-top: 6rem !important;
  }
  .m-t-xs-65 {
    margin-top: 6.5rem !important;
  }
  .m-t-xs-70 {
    margin-top: 7rem !important;
  }
  .m-t-xs-75 {
    margin-top: 7.5rem !important;
  }
  .m-t-xs-80 {
    margin-top: 8rem !important;
  }
  .m-t-xs-85 {
    margin-top: 8.5rem !important;
  }
  .m-t-xs-90 {
    margin-top: 9rem !important;
  }
  .m-t-xs-95 {
    margin-top: 9.5rem !important;
  }
  .m-t-xs-100 {
    margin-top: 10rem !important;
  }
  .m-b-xs-0 {
    margin-bottom: 0 !important;
  }
  .m-b-xs-5 {
    margin-bottom: 0.5rem !important;
  }
  .m-b-xs-10 {
    margin-bottom: 1rem !important;
  }
  .m-b-xs-15 {
    margin-bottom: 1.5rem !important;
  }
  .m-b-xs-20 {
    margin-bottom: 2rem !important;
  }
  .m-b-xs-25 {
    margin-bottom: 2.5rem !important;
  }
  .m-b-xs-30 {
    margin-bottom: 3rem !important;
  }
  .m-b-xs-35 {
    margin-bottom: 3.5rem !important;
  }
  .m-b-xs-40 {
    margin-bottom: 4rem !important;
  }
  .m-b-xs-45 {
    margin-bottom: 4.5rem !important;
  }
  .m-b-xs-50 {
    margin-bottom: 5rem !important;
  }
  .m-b-xs-55 {
    margin-bottom: 5.5rem !important;
  }
  .m-b-xs-60 {
    margin-bottom: 6rem !important;
  }
  .m-b-xs-65 {
    margin-bottom: 6.5rem !important;
  }
  .m-b-xs-70 {
    margin-bottom: 7rem !important;
  }
  .m-b-xs-75 {
    margin-bottom: 7.5rem !important;
  }
  .m-b-xs-80 {
    margin-bottom: 8rem !important;
  }
  .m-b-xs-85 {
    margin-bottom: 8.5rem !important;
  }
  .m-b-xs-90 {
    margin-bottom: 9rem !important;
  }
  .m-b-xs-95 {
    margin-bottom: 9.5rem !important;
  }
  .m-b-xs-100 {
    margin-bottom: 10rem !important;
  }
  .p-t-xs-0 {
    padding-top: 0 !important;
  }
  .p-t-xs-5 {
    padding-top: 0.5rem !important;
  }
  .p-t-xs-10 {
    padding-top: 1rem !important;
  }
  .p-t-xs-15 {
    padding-top: 1.5rem !important;
  }
  .p-t-xs-20 {
    padding-top: 2rem !important;
  }
  .p-t-xs-25 {
    padding-top: 2.5rem !important;
  }
  .p-t-xs-30 {
    padding-top: 3rem !important;
  }
  .p-t-xs-35 {
    padding-top: 3.5rem !important;
  }
  .p-t-xs-40 {
    padding-top: 4rem !important;
  }
  .p-t-xs-45 {
    padding-top: 4.5rem !important;
  }
  .p-t-xs-50 {
    padding-top: 5rem !important;
  }
  .p-t-xs-55 {
    padding-top: 5.5rem !important;
  }
  .p-t-xs-60 {
    padding-top: 6rem !important;
  }
  .p-t-xs-65 {
    padding-top: 6.5rem !important;
  }
  .p-t-xs-70 {
    padding-top: 7rem !important;
  }
  .p-t-xs-75 {
    padding-top: 7.5rem !important;
  }
  .p-t-xs-80 {
    padding-top: 8rem !important;
  }
  .p-t-xs-85 {
    padding-top: 8.5rem !important;
  }
  .p-t-xs-90 {
    padding-top: 9rem !important;
  }
  .p-t-xs-95 {
    padding-top: 9.5rem !important;
  }
  .p-t-xs-100 {
    padding-top: 10rem !important;
  }
  .p-b-xs-0 {
    padding-bottom: 0 !important;
  }
  .p-b-xs-5 {
    padding-bottom: 0.5rem !important;
  }
  .p-b-xs-10 {
    padding-bottom: 1rem !important;
  }
  .p-b-xs-15 {
    padding-bottom: 1.5rem !important;
  }
  .p-b-xs-20 {
    padding-bottom: 2rem !important;
  }
  .p-b-xs-25 {
    padding-bottom: 2.5rem !important;
  }
  .p-b-xs-30 {
    padding-bottom: 3rem !important;
  }
  .p-b-xs-35 {
    padding-bottom: 3.5rem !important;
  }
  .p-b-xs-40 {
    padding-bottom: 4rem !important;
  }
  .p-b-xs-45 {
    padding-bottom: 4.5rem !important;
  }
  .p-b-xs-50 {
    padding-bottom: 5rem !important;
  }
  .p-b-xs-55 {
    padding-bottom: 5.5rem !important;
  }
  .p-b-xs-60 {
    padding-bottom: 6rem !important;
  }
  .p-b-xs-65 {
    padding-bottom: 6.5rem !important;
  }
  .p-b-xs-70 {
    padding-bottom: 7rem !important;
  }
  .p-b-xs-75 {
    padding-bottom: 7.5rem !important;
  }
  .p-b-xs-80 {
    padding-bottom: 8rem !important;
  }
  .p-b-xs-85 {
    padding-bottom: 8.5rem !important;
  }
  .p-b-xs-90 {
    padding-bottom: 9rem !important;
  }
  .p-b-xs-95 {
    padding-bottom: 9.5rem !important;
  }
  .p-b-xs-100 {
    padding-bottom: 10rem !important;
  }
}

@media (max-width: 479px) {
  .m-t-xxs-0 {
    margin-top: 0 !important;
  }
  .m-t-xxs-5 {
    margin-top: 0.5rem !important;
  }
  .m-t-xxs-10 {
    margin-top: 1rem !important;
  }
  .m-t-xxs-15 {
    margin-top: 1.5rem !important;
  }
  .m-t-xxs-20 {
    margin-top: 2rem !important;
  }
  .m-t-xxs-25 {
    margin-top: 2.5rem !important;
  }
  .m-t-xxs-30 {
    margin-top: 3rem !important;
  }
  .m-t-xxs-35 {
    margin-top: 3.5rem !important;
  }
  .m-t-xxs-40 {
    margin-top: 4rem !important;
  }
  .m-t-xxs-45 {
    margin-top: 4.5rem !important;
  }
  .m-t-xxs-50 {
    margin-top: 5rem !important;
  }
  .m-t-xxs-55 {
    margin-top: 5.5rem !important;
  }
  .m-t-xxs-60 {
    margin-top: 6rem !important;
  }
  .m-t-xxs-65 {
    margin-top: 6.5rem !important;
  }
  .m-t-xxs-70 {
    margin-top: 7rem !important;
  }
  .m-t-xxs-75 {
    margin-top: 7.5rem !important;
  }
  .m-t-xxs-80 {
    margin-top: 8rem !important;
  }
  .m-t-xxs-85 {
    margin-top: 8.5rem !important;
  }
  .m-t-xxs-90 {
    margin-top: 9rem !important;
  }
  .m-t-xxs-95 {
    margin-top: 9.5rem !important;
  }
  .m-t-xxs-100 {
    margin-top: 10rem !important;
  }
  .m-b-xxs-0 {
    margin-bottom: 0 !important;
  }
  .m-b-xxs-5 {
    margin-bottom: 0.5rem !important;
  }
  .m-b-xxs-10 {
    margin-bottom: 1rem !important;
  }
  .m-b-xxs-15 {
    margin-bottom: 1.5rem !important;
  }
  .m-b-xxs-20 {
    margin-bottom: 2rem !important;
  }
  .m-b-xxs-25 {
    margin-bottom: 2.5rem !important;
  }
  .m-b-xxs-30 {
    margin-bottom: 3rem !important;
  }
  .m-b-xxs-35 {
    margin-bottom: 3.5rem !important;
  }
  .m-b-xxs-40 {
    margin-bottom: 4rem !important;
  }
  .m-b-xxs-45 {
    margin-bottom: 4.5rem !important;
  }
  .m-b-xxs-50 {
    margin-bottom: 5rem !important;
  }
  .m-b-xxs-55 {
    margin-bottom: 5.5rem !important;
  }
  .m-b-xxs-60 {
    margin-bottom: 6rem !important;
  }
  .m-b-xxs-65 {
    margin-bottom: 6.5rem !important;
  }
  .m-b-xxs-70 {
    margin-bottom: 7rem !important;
  }
  .m-b-xxs-75 {
    margin-bottom: 7.5rem !important;
  }
  .m-b-xxs-80 {
    margin-bottom: 8rem !important;
  }
  .m-b-xxs-85 {
    margin-bottom: 8.5rem !important;
  }
  .m-b-xxs-90 {
    margin-bottom: 9rem !important;
  }
  .m-b-xxs-95 {
    margin-bottom: 9.5rem !important;
  }
  .m-b-xxs-100 {
    margin-bottom: 10rem !important;
  }
  .p-t-xxs-0 {
    padding-top: 0 !important;
  }
  .p-t-xxs-5 {
    padding-top: 0.5rem !important;
  }
  .p-t-xxs-10 {
    padding-top: 1rem !important;
  }
  .p-t-xxs-15 {
    padding-top: 1.5rem !important;
  }
  .p-t-xxs-20 {
    padding-top: 2rem !important;
  }
  .p-t-xxs-25 {
    padding-top: 2.5rem !important;
  }
  .p-t-xxs-30 {
    padding-top: 3rem !important;
  }
  .p-t-xxs-35 {
    padding-top: 3.5rem !important;
  }
  .p-t-xxs-40 {
    padding-top: 4rem !important;
  }
  .p-t-xxs-45 {
    padding-top: 4.5rem !important;
  }
  .p-t-xxs-50 {
    padding-top: 5rem !important;
  }
  .p-t-xxs-55 {
    padding-top: 5.5rem !important;
  }
  .p-t-xxs-60 {
    padding-top: 6rem !important;
  }
  .p-t-xxs-65 {
    padding-top: 6.5rem !important;
  }
  .p-t-xxs-70 {
    padding-top: 7rem !important;
  }
  .p-t-xxs-75 {
    padding-top: 7.5rem !important;
  }
  .p-t-xxs-80 {
    padding-top: 8rem !important;
  }
  .p-t-xxs-85 {
    padding-top: 8.5rem !important;
  }
  .p-t-xxs-90 {
    padding-top: 9rem !important;
  }
  .p-t-xxs-95 {
    padding-top: 9.5rem !important;
  }
  .p-t-xxs-100 {
    padding-top: 10rem !important;
  }
  .p-b-xxs-0 {
    padding-bottom: 0 !important;
  }
  .p-b-xxs-5 {
    padding-bottom: 0.5rem !important;
  }
  .p-b-xxs-10 {
    padding-bottom: 1rem !important;
  }
  .p-b-xxs-15 {
    padding-bottom: 1.5rem !important;
  }
  .p-b-xxs-20 {
    padding-bottom: 2rem !important;
  }
  .p-b-xxs-25 {
    padding-bottom: 2.5rem !important;
  }
  .p-b-xxs-30 {
    padding-bottom: 3rem !important;
  }
  .p-b-xxs-35 {
    padding-bottom: 3.5rem !important;
  }
  .p-b-xxs-40 {
    padding-bottom: 4rem !important;
  }
  .p-b-xxs-45 {
    padding-bottom: 4.5rem !important;
  }
  .p-b-xxs-50 {
    padding-bottom: 5rem !important;
  }
  .p-b-xxs-55 {
    padding-bottom: 5.5rem !important;
  }
  .p-b-xxs-60 {
    padding-bottom: 6rem !important;
  }
  .p-b-xxs-65 {
    padding-bottom: 6.5rem !important;
  }
  .p-b-xxs-70 {
    padding-bottom: 7rem !important;
  }
  .p-b-xxs-75 {
    padding-bottom: 7.5rem !important;
  }
  .p-b-xxs-80 {
    padding-bottom: 8rem !important;
  }
  .p-b-xxs-85 {
    padding-bottom: 8.5rem !important;
  }
  .p-b-xxs-90 {
    padding-bottom: 9rem !important;
  }
  .p-b-xxs-95 {
    padding-bottom: 9.5rem !important;
  }
  .p-b-xxs-100 {
    padding-bottom: 10rem !important;
  }
}

.area-outlook-detail .glance-box,
.page-project-outlook .glance-box,
.page-property-detail .glance-box {
  display: none;
}

.area-outlook-detail .glance-box .glance-box-inner,
.page-project-outlook .glance-box .glance-box-inner,
.page-property-detail .glance-box .glance-box-inner {
  padding: 15px;
  background: #222;
  color: #fff;
}

.area-outlook-detail .glance-box .glance-box-inner span,
.page-project-outlook .glance-box .glance-box-inner span,
.page-property-detail .glance-box .glance-box-inner span {
  display: block;
  margin-bottom: 20px;
}

.area-outlook-detail .glance-box .glance-box-inner span:first-child,
.page-project-outlook .glance-box .glance-box-inner span:first-child,
.page-property-detail .glance-box .glance-box-inner span:first-child {
  font-size: 5.5rem;
}

.area-outlook-detail .glance-box .glance-box-inner span:first-child:after,
.page-project-outlook .glance-box .glance-box-inner span:first-child:after,
.page-property-detail .glance-box .glance-box-inner span:first-child:after {
  content: "%";
  color: #eee;
  font-size: 3rem;
  vertical-align: middle;
}

.area-outlook-detail .glance-box .glance-box-inner span:nth-child(2),
.page-project-outlook .glance-box .glance-box-inner span:nth-child(2),
.page-property-detail .glance-box .glance-box-inner span:nth-child(2) {
  font-size: 1.5rem;
  text-transform: capitalize;
  color: #fff;
}

.area-outlook-detail .glance-box .glance-box-inner span:nth-child(3),
.page-project-outlook .glance-box .glance-box-inner span:nth-child(3),
.page-property-detail .glance-box .glance-box-inner span:nth-child(3) {
  font-size: 1.2rem;
  color: #ccc;
}

.area-outlook-detail .glance-box .glance-box-inner span:nth-child(4),
.page-project-outlook .glance-box .glance-box-inner span:nth-child(4),
.page-property-detail .glance-box .glance-box-inner span:nth-child(4) {
  font-size: 1.1rem;
  margin-bottom: 0;
  color: #ccc;
}

.area-outlook-detail .outlook-middle-box,
.page-project-outlook .outlook-middle-box,
.page-property-detail .outlook-middle-box {
  margin-bottom: 25px;
}

.area-outlook-detail .outlook-middle-box .middle-box,
.page-project-outlook .outlook-middle-box .middle-box,
.page-property-detail .outlook-middle-box .middle-box {
  overflow: hidden;
  background: #275075;
  color: #fff;
  text-align: center;
}

.area-outlook-detail .outlook-middle-box .middle-box span,
.page-project-outlook .outlook-middle-box .middle-box span,
.page-property-detail .outlook-middle-box .middle-box span {
  display: block;
  text-align: center;
  margin-bottom: 20px;
}

.area-outlook-detail .outlook-middle-box .middle-box span:first-child,
.page-project-outlook .outlook-middle-box .middle-box span:first-child,
.page-property-detail .outlook-middle-box .middle-box span:first-child {
  font-size: 3rem;
  padding: 25px 15px 15px;
  color: #aaa;
}

.area-outlook-detail .outlook-middle-box .middle-box img.icon-outlook,
.page-project-outlook .outlook-middle-box .middle-box img.icon-outlook,
.page-property-detail .outlook-middle-box .middle-box img.icon-outlook {
  width: 100%;
  max-width: 60px;
  margin: 30px 0 20px;
}

.area-outlook-detail .outlook-middle-box .middle-box img.icon-outlook.listing,
.page-project-outlook .outlook-middle-box .middle-box img.icon-outlook.listing,
.page-property-detail .outlook-middle-box .middle-box img.icon-outlook.listing {
  max-width: 80px;
}

@media screen and (max-width: 767px) {
  .area-outlook-detail .outlook-middle-box .middle-box,
  .page-project-outlook .outlook-middle-box .middle-box,
  .page-property-detail .outlook-middle-box .middle-box {
    margin-bottom: 20px;
  }
}

.area-outlook-detail .outlook-footnote,
.page-project-outlook .outlook-footnote,
.page-property-detail .outlook-footnote {
  background: #3e4247;
  width: 100%;
}

.area-outlook-detail .outlook-footnote .outlook-footnote-inner,
.page-project-outlook .outlook-footnote .outlook-footnote-inner,
.page-property-detail .outlook-footnote .outlook-footnote-inner {
  font-size: 1.1rem;
  padding: 20px 0;
  margin: 0 auto;
  color: #fff;
  max-width: 990px;
  width: 100%;
}

.area-outlook-detail .boxmax,
.page-project-outlook .boxmax,
.page-property-detail .boxmax {
  width: 100vw;
  position: relative;
  margin-left: -50vw;
  left: 50%;
}

.area-outlook-detail .transaction-datatab,
.page-project-outlook .transaction-datatab,
.page-property-detail .transaction-datatab {
  margin-top: 20px;
}

.area-outlook-detail .amenities-group-wrap.narrow-search,
.page-project-outlook .amenities-group-wrap.narrow-search,
.page-property-detail .amenities-group-wrap.narrow-search {
  font-size: 1.3rem;
  margin-top: 20px;
}

.area-outlook-detail .amenities-group-wrap.narrow-search .group-heading,
.page-project-outlook .amenities-group-wrap.narrow-search .group-heading,
.page-property-detail .amenities-group-wrap.narrow-search .group-heading {
  font-weight: 500;
  margin-bottom: 10px;
}

.area-outlook-detail .overview,
.page-project-outlook .overview,
.page-property-detail .overview {
  line-height: 2;
}

.area-outlook-detail .overview h3,
.page-project-outlook .overview h3,
.page-property-detail .overview h3 {
  font-size: 1.6rem;
  font-weight: 600;
}

.area-outlook-detail .overview h3 strong,
.page-project-outlook .overview h3 strong,
.page-property-detail .overview h3 strong {
  font-weight: 600;
}

.area-outlook-detail h3,
.page-project-outlook h3,
.page-property-detail h3 {
  margin-top: 0;
  margin-bottom: 1.8rem;
  color: #2e3a52;
  font-size: 1.6rem;
  font-weight: 600;
}

.area-outlook-detail .project-trend .highchart,
.page-project-outlook .project-trend .highchart,
.page-property-detail .project-trend .highchart {
  margin-bottom: 25px;
}

.area-outlook-detail span.disclaimer,
.page-project-outlook span.disclaimer,
.page-property-detail span.disclaimer {
  margin: 20px 0 30px;
  display: block;
}

.page-property-detail .transaction-datatab,
.area-outlook-detail .transaction-datatab {
  margin-top: 40px;
}

@media screen and (min-width: 768px) {
  .page-property-detail .transaction-datatab .transaction-table .transaction-head .transaction-row .transaction-title.transaction-name,
  .area-outlook-detail .transaction-datatab .transaction-table .transaction-head .transaction-row .transaction-title.transaction-name {
    width: 21%;
  }
  .page-property-detail .transaction-datatab .transaction-table .transaction-head .transaction-row .transaction-title.transaction-type,
  .area-outlook-detail .transaction-datatab .transaction-table .transaction-head .transaction-row .transaction-title.transaction-type {
    width: 30%;
  }
  .page-property-detail .transaction-datatab .transaction-table .transaction-head .transaction-row .transaction-title.transaction-psf,
  .area-outlook-detail .transaction-datatab .transaction-table .transaction-head .transaction-row .transaction-title.transaction-psf {
    width: 17%;
  }
  .page-property-detail .transaction-datatab .transaction-table .transaction-head .transaction-row .transaction-title.transaction-price,
  .area-outlook-detail .transaction-datatab .transaction-table .transaction-head .transaction-row .transaction-title.transaction-price {
    width: 17%;
  }
  .page-property-detail .transaction-datatab .transaction-table .transaction-head .transaction-row .transaction-title.transaction-filed,
  .area-outlook-detail .transaction-datatab .transaction-table .transaction-head .transaction-row .transaction-title.transaction-filed {
    width: 15%;
  }
  .page-property-detail .transaction-datatab .transaction-table .transaction-body .transaction-region.transaction-region-1 .transaction-section-1,
  .area-outlook-detail .transaction-datatab .transaction-table .transaction-body .transaction-region.transaction-region-1 .transaction-section-1 {
    width: 60%;
  }
  .page-property-detail .transaction-datatab .transaction-table .transaction-body .transaction-region.transaction-region-1 .transaction-section-1 .transaction-type,
  .area-outlook-detail .transaction-datatab .transaction-table .transaction-body .transaction-region.transaction-region-1 .transaction-section-1 .transaction-type {
    width: 57%;
  }
  .page-property-detail .transaction-datatab .transaction-table .transaction-body .transaction-region.transaction-region-1 .transaction-section-2,
  .area-outlook-detail .transaction-datatab .transaction-table .transaction-body .transaction-region.transaction-region-1 .transaction-section-2 {
    width: 39%;
  }
  .page-property-detail .transaction-datatab .transaction-table .transaction-body .transaction-region.transaction-region-1 .transaction-section-2 .transaction-item.transaction-psf,
  .area-outlook-detail .transaction-datatab .transaction-table .transaction-body .transaction-region.transaction-region-1 .transaction-section-2 .transaction-item.transaction-psf {
    width: 52%;
  }
  .page-property-detail .transaction-datatab .transaction-table .transaction-body .transaction-region.transaction-region-1 .transaction-section-2 .transaction-item.transaction-price.area-trx,
  .area-outlook-detail .transaction-datatab .transaction-table .transaction-body .transaction-region.transaction-region-1 .transaction-section-2 .transaction-item.transaction-price.area-trx {
    width: 48%;
  }
  .page-property-detail .transaction-datatab .transaction-table .transaction-body .transaction-region.transaction-region-2 .transaction-section-3,
  .area-outlook-detail .transaction-datatab .transaction-table .transaction-body .transaction-region.transaction-region-2 .transaction-section-3 {
    text-align: left;
  }
}

.page-property-detail .overview h3,
.area-outlook-detail .overview h3 {
  margin-top: 30px;
}

.page-property-detail .outlook-middle-box,
.area-outlook-detail .outlook-middle-box {
  margin-top: 25px;
}

.area-outlook-detail h1.page-header,
.page-project-outlook h1.page-header,
.page-transaction h1.page-header {
  font-size: 2rem;
  margin-bottom: 0;
}

.area-outlook-detail .definition,
.page-project-outlook .definition,
.page-transaction .definition {
  text-align: center;
}

.area-outlook-detail .definition .definition-button,
.page-project-outlook .definition .definition-button,
.page-transaction .definition .definition-button {
  font-size: 1.1rem;
  font-weight: 500;
}

.area-outlook-detail .i-table > thead > tr > th,
.page-project-outlook .i-table > thead > tr > th,
.page-transaction .i-table > thead > tr > th {
  background: #275075;
  color: #fff;
  font-weight: 500;
}

.page-project-outlook span.disclaimer {
  margin-top: 0;
}

.page-project-outlook h2.page-header,
.page-area-outlook h2.page-header {
  display: none;
}

body.node-type-page .header-banner #leaderboard_1_newspage, body.page-pullout .header-banner #leaderboard_1_newspage, body.news-details-page .header-banner #leaderboard_1_newspage {
  display: block !important;
}

.most_popular_wrapper .image {
  opacity: 1;
  display: block;
  width: 100%;
  height: auto;
  transition: .5s ease;
  backface-visibility: hidden;
}

.most_popular_wrapper .middle {
  transition: .5s ease;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  text-align: center;
  width: 100%;
  height: 100%;
}

.home_st_cnt.most_popular_wrapper .cell .text {
  color: white;
  font-size: 25px;
  padding: 5px;
  border-radius: 5px;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all .2s ease-in-out;
}

.home_st_cnt.most_popular_wrapper .cell:hover .text {
  background-color: rgba(0, 0, 0, 0.4);
  transition: all .2s ease-in-out;
}

.showcase_wrapper {
  width: 890px;
  margin: 20px auto;
  position: relative;
  margin-left: 19%;
}

.showcase_wrapper .blk_header {
  padding-left: 0;
}

.showcase {
  margin-top: 15px;
}

.showcase .showcell {
  width: 100%;
  height: 500px;
}

.showcase .showcell img {
  width: 100%;
}

.showcase-ad-container {
  margin: 0 auto;
  position: relative;
  left: -4px;
  top: -4px;
}

@media (min-width: 991px) {
  .showcase-ad-container {
    margin-left: 19%;
    width: 890px;
    height: 500px;
  }
  .showcase-ad-container iframe body {
    margin: 0 !important;
  }
}

@media (max-width: 768px) {
  .showcase-ad-container {
    margin-left: -1%;
    width: 632px;
    height: 355px;
    margin-bottom: 20px;
  }
}

@media (max-width: 425px) {
  .showcase-ad-container {
    margin-left: 12.2%;
    width: 300px;
    height: 169px;
  }
}

@media (max-width: 375px) {
  .showcase-ad-container {
    margin-left: 22px;
  }
}

@media (max-width: 320px) {
  .showcase-ad-container {
    margin-left: -5px;
  }
}

.wrapper-home-carousel {
  max-width: 65%;
  margin: 20px auto;
  position: relative;
}

.wrapper-home-carousel .flickity-prev-next-button {
  position: absolute;
  top: 50%;
  width: 44px;
  height: 44px;
  border: none;
  border-radius: 50%;
  background: white;
  background: rgba(255, 255, 255, 0.75);
  cursor: pointer;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
}

.wrapper-home-carousel .flickity-prev-next-button.previous {
  left: -450px;
  display: none;
}

.wrapper-home-carousel .flickity-prev-next-button.next {
  right: -150px;
}

.wrapper-home-carousel .flickity-viewport {
  overflow: initial;
  max-height: 550px;
}

.wrapper-home-carousel .flickity-slider {
  position: absolute;
  width: 100%;
  height: 100%;
}

.wrapper-home-carousel .carousel-cell {
  position: relative;
  display: inline-block;
  background-color: #fff;
  opacity: 0.3;
}

.wrapper-home-carousel .carousel-cell.is-selected,
.wrapper-home-carousel .carousel-cell.is-selected + div {
  opacity: 1;
}

.wrapper-home-carousel .carousel-cell img {
  width: 100%;
  height: auto;
  max-height: 250px;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

.wrapper-home-carousel .carousel-cell .pcell,
.wrapper-home-carousel .carousel-cell .view_more {
  box-shadow: 0 1px 37px 0 rgba(165, 165, 165, 0.29);
  margin: 18px;
  width: 300px;
  height: 250px;
  -webkit-transition: .2s;
  -o-transition: .2s;
  transition: .2s;
  display: table;
  flex-direction: row;
  position: relative;
}

.wrapper-home-carousel .carousel-cell .view_more {
  height: 515px;
  font-size: 14px;
  color: #fff;
  text-transform: uppercase;
  cursor: pointer;
  padding: 10px 0;
  text-align: center;
  line-height: 35;
  background-position: center;
  background-image: url("https://media.edgeprop.my/s3fs-public/2k18home/discover_more/more_news.jpg");
  background-repeat: no-repeat;
}

.wrapper-home-carousel .carousel-cell .view_more:hover:before {
  content: "";
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background: rgba(255, 255, 255, 0.2);
}

.wrapper-home-carousel .carousel-cell.more a {
  text-decoration: none;
}

.wrapper-home-carousel .carousel-cell .pcell .news-content {
  width: 100%;
  padding: 1.071em;
  background-color: #f7f6f6;
  position: absolute;
  left: 0;
  bottom: 0;
}

.wrapper-home-carousel .carousel-cell .pcell .news-title {
  margin-bottom: 5px;
  font-size: 1.143em;
}

.wrapper-home-carousel .carousel-cell .pcell .news-date {
  font-size: .714em;
  line-height: 1.364em;
  text-transform: uppercase;
}

.wrapper-home-carousel .carousel-cell .pcell.featured .news-cover:before {
  content: 'Featured';
  display: block;
  padding: .5rem 1rem;
  background-color: #c94d51;
  color: #fff;
  font-size: 1.2rem;
  letter-spacing: .08rem;
  position: absolute;
  top: 1rem;
  left: -.1rem;
  z-index: 1;
}

.wrapper-home-carousel .dfp_news_cell,
.wrapper-home-carousel .dfp_list_cell {
  position: absolute;
  top: 48px;
}

.wrapper-home-carousel .dfp_news_cell .pcell,
.wrapper-home-carousel .dfp_list_cell .pcell {
  box-shadow: 0 1px 37px 0 rgba(165, 165, 165, 0.29);
  margin: 18px;
  width: 300px;
  height: 250px;
  -webkit-transition: .2s;
  -o-transition: .2s;
  transition: .2s;
  display: table;
  flex-direction: row;
  position: relative;
}

.wrapper-home-carousel .slider_tab {
  list-style: none;
  overflow: hidden;
  margin: 0;
  padding-left: 18px;
}

.wrapper-home-carousel .slider_tab .tabs {
  float: left;
  padding: 10px 0px;
  font-size: 1.8rem;
  font-weight: 700;
  cursor: pointer;
  color: #AAAAAA;
  margin-right: 45px;
  letter-spacing: 1px;
}

.wrapper-home-carousel .slider_tab .tabs:hover {
  color: #7b7575;
}

.wrapper-home-carousel .slider_tab .tabs.active {
  color: #000;
}

.property-main-container .pcell a.image .indicator {
  padding: 1rem 1.5rem;
  background-color: #3b839c;
  color: #fff;
}

.property-main-container .pcell .i-card .content {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  background: #f7f6f6;
}

.property-main-container .pcell .i-card .content .title {
  margin-bottom: .7rem;
  font-size: 1.4rem;
  font-weight: 600;
}

.property-main-container .pcell .i-card.flat {
  width: 300px;
  height: 250px;
  position: relative;
}

.property-main-container .carousel-cell .view_more.prop {
  background-image: url("https://media.edgeprop.my/s3fs-public/2k18home/discover_more/more_prop.jpg");
}

.property-main-container .dfp_list_cell .pcell.btnAds {
  background: transparent;
  box-shadow: none;
}

.wrapper-newlaunch-carousel {
  max-width: 65%;
  margin: 20px auto;
  position: relative;
  /* added css */
}

.wrapper-newlaunch-carousel .carousel-cell {
  position: relative;
  display: inline-block;
  background-color: #fff;
  opacity: 0.3;
}

.wrapper-newlaunch-carousel .carousel-cell.is-selected,
.wrapper-newlaunch-carousel .carousel-cell.is-selected + div {
  opacity: 1;
}

.wrapper-newlaunch-carousel .carousel-cell img {
  width: 100%;
  height: auto;
  max-height: 250px;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

.wrapper-newlaunch-carousel .carousel-cell .pcell,
.wrapper-newlaunch-carousel .carousel-cell .view_more {
  box-shadow: 0 1px 37px 0 rgba(165, 165, 165, 0.29);
  margin: 18px;
  width: 300px;
  height: 250px;
  -webkit-transition: .2s;
  -o-transition: .2s;
  transition: .2s;
  display: table;
  flex-direction: row;
  position: relative;
}

.wrapper-newlaunch-carousel .carousel-cell .view_more {
  height: 515px;
  font-size: 14px;
  color: #fff;
  text-transform: uppercase;
  cursor: pointer;
  padding: 10px 0;
  text-align: center;
  line-height: 35;
  background-position: center;
  background-image: url("https://media.edgeprop.my/s3fs-public/2k18home/discover_more/more_prop.jpg");
  background-repeat: no-repeat;
}

.wrapper-newlaunch-carousel .carousel-cell .view_more:hover:before {
  content: "";
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background: rgba(255, 255, 255, 0.2);
}

.wrapper-newlaunch-carousel .carousel-cell.more a {
  text-decoration: none;
}

.wrapper-newlaunch-carousel .dfp_vpx_cell .pcell,
.wrapper-newlaunch-carousel .dfp_may_cell .pcell {
  box-shadow: 0 1px 37px 0 rgba(165, 165, 165, 0.29);
  margin: 18px;
  width: 300px;
  height: 250px;
  -webkit-transition: .2s;
  -o-transition: .2s;
  transition: .2s;
  display: table;
  flex-direction: row;
  position: relative;
}

.wrapper-newlaunch-carousel .pcell .news-content {
  width: 100%;
  padding: 1.071em;
  background-color: #f7f6f6;
  position: absolute;
  left: 0;
  bottom: 0;
}

.wrapper-newlaunch-carousel .pcell .news-title {
  margin-bottom: 5px;
  font-size: 1.143em;
}

.wrapper-newlaunch-carousel .pcell .news-date {
  font-size: .714em;
  line-height: 1.364em;
  text-transform: uppercase;
}

.wrapper-newlaunch-carousel .pcell.featured .news-cover:before {
  content: 'Featured';
  display: block;
  padding: .5rem 1rem;
  background-color: #c94d51;
  color: #fff;
  font-size: 1.2rem;
  letter-spacing: .08rem;
  position: absolute;
  top: 1rem;
  left: -.1rem;
  z-index: 1;
}

.wrapper-newlaunch-carousel .flickity-prev-next-button {
  position: absolute;
  top: 50%;
  width: 44px;
  height: 44px;
  border: none;
  border-radius: 50%;
  background: white;
  background: rgba(255, 255, 255, 0.75);
  cursor: pointer;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
}

.wrapper-newlaunch-carousel .flickity-prev-next-button.previous {
  left: -450px;
  display: none;
}

.wrapper-newlaunch-carousel .flickity-prev-next-button.next {
  right: -150px;
}

.wrapper-newlaunch-carousel .flickity-viewport {
  overflow: initial;
  max-height: 550px;
}

.wrapper-newlaunch-carousel .flickity-slider {
  position: absolute;
  width: 100%;
  height: 100%;
}

.wrapper-newlaunch-carousel .slider_tab {
  list-style: none;
  overflow: hidden;
  margin: 0;
  padding-left: 18px;
}

.wrapper-newlaunch-carousel .slider_tab .tabs {
  float: left;
  padding: 10px 0px;
  font-size: 1.8rem;
  font-weight: 700;
  cursor: pointer;
  color: #AAAAAA;
  margin-right: 45px;
  letter-spacing: 1px;
}

.wrapper-newlaunch-carousel .slider_tab .tabs:hover {
  color: #7b7575;
}

.wrapper-newlaunch-carousel .slider_tab .tabs.active {
  color: #000;
}

.wrapper-newlaunch-carousel .dfp_vpx_cell,
.wrapper-newlaunch-carousel .dfp_may_cell {
  position: absolute;
  top: 48px;
}

.wrapper-newlaunch-carousel .glass-holder.stage {
  height: 180px;
  border: 0;
}

.wrapper-newlaunch-carousel .nl-module.i-card .content {
  position: absolute;
  bottom: 0;
  height: 70px;
  width: 100%;
}

.header-news {
  font-family: 'Poppins', sans-serif;
  background: #fff;
  width: 100%;
  height: 52px;
  z-index: 2;
  border-bottom: 1px solid #eee;
  /* 4.3 Header Main */
  /* 4.5 Mega Menu */
}

.header-news .header-widget.content {
  margin-bottom: 0;
}

.header-news .header-widget p img {
  margin-bottom: -9px;
}

.header-news ul.nav-news-menu {
  padding: 0;
  margin: 0;
}

.header-news ul.nav-news-menu li {
  text-transform: uppercase;
  padding: 2px 10px;
  font-weight: 400;
  line-height: 1.5;
  font-size: 1.3rem;
  color: rgba(0, 0, 0, 0.7);
  display: inline-block;
}

.header-news ul.nav-news-menu li:hover {
  background-color: #488BF8;
  text-decoration: none;
  color: #FFFFFF !important;
  cursor: pointer;
}

.header-news header {
  position: relative;
  z-index: 995;
}

.header-news header .header-menu {
  position: relative;
  width: 100%;
  margin: 0 0 2rem 0;
}

.header-news header .header-menu ul#mega-menu-main-menu li.active {
  background-color: #488BF8;
  color: #FFFFFF !important;
}

.header-news .header-logo {
  padding: 10px 0;
  width: 22%;
}

@media (min-width: 1024px) and (max-width: 1335px) {
  .header-news .header-logo {
    width: 11%;
  }
}

.header-news .site-logo {
  line-height: 0;
}

.header-news .site-logo .logo-image {
  display: inline-block;
  line-height: 0;
}

.header-news .site-logo .logo-image img {
  position: relative;
  display: inline-block;
  height: 31.5px;
  width: auto;
}

.header-news .colum-iblock .mobile-header-search {
  float: right;
  display: none;
}

.header-news .mobile-header-search .header-search-mazada i {
  line-height: 75px;
  padding: 0 0 0 14px;
}

.header-news .header-menu .header-main-menu,
.header-news .header-menu .mega-menu-wrap {
  float: left;
}

.header-news .header-search-mazada {
  float: right;
  position: relative;
  right: 23px;
}

body.page-events .header-news .header-search-mazada {
  display: none;
}

.header-news .header-menu ul ul {
  width: 250px;
  padding: 20px;
  left: -26px;
}

.header-news .header-menu ul ul li {
  float: none;
}

.header-news .header-menu ul ul li a {
  font-weight: normal;
  display: block;
  font-size: 1.5rem;
  line-height: 18px;
  margin-bottom: 8px;
  color: rgba(0, 0, 0, 0.7);
  text-align: left;
  padding: 6px;
  text-transform: capitalize;
  text-decoration: none;
}

.header-news .header-menu ul ul ul {
  border-top: 0;
  top: -20px;
  left: 100%;
}

.header-news .header-menu ul li {
  float: left;
  position: relative;
}

.header-news .header-menu ul li a {
  font-size: 1.5rem;
  color: rgba(0, 0, 0, 0.7);
  text-transform: uppercase;
  padding: 15px;
  font-weight: 400;
  line-height: 1.5;
  font-size: 1.3rem;
  background-color: inherit;
}

.header-news .header-menu ul li a:hover:not(.active), .header-news .header-menu ul li a.active {
  background-color: #488BF8;
  color: #FFFFFF !important;
}

.header-news .header-menu ul li a.active {
  background-color: #2056ab;
}

@media (min-width: 1024px) and (max-width: 1128px) {
  .header-news .header-menu ul li a {
    padding: 15px;
  }
}

.header-news .header-menu ul li.mega-menu-megamenu {
  position: initial;
}

.header-news .header-menu ul li.active {
  background-color: #488BF8;
  color: #FFFFFF !important;
}

.header-news .header-menu ul ul > li:last-of-type > a {
  margin-bottom: 0;
}

.header-news .header-menu ul li.current-menu-item a,
.header-news .header-menu ul > li:hover > a,
.header-news .mega-menu-wrap ul li.mega-current-menu-item > a {
  color: #808080;
  text-decoration: none;
}

.header-news .header-search-mazada i {
  display: block;
  width: 37px;
  font-size: 16px;
  line-height: 18px;
  padding: 14px 0 14px 22px;
  cursor: pointer;
}

.header-news .header-search-mazada .fa-times,
.header-news .header-search-open .fa-search {
  display: none;
}

.header-news .header-search-open .fa-times {
  display: block;
}

.header-news .header-search-popup,
.header-news .header-menu ul ul {
  visibility: hidden;
  opacity: 0;
  position: absolute;
  top: 100%;
  right: 50px;
  background: #fff;
  border-top: 1px solid transparent;
  -webkit-background-clip: padding-box;
  /* for Safari */
  background-clip: padding-box;
  /* for IE9+, Firefox 4+, Opera, Chrome */
  z-index: 9999;
  padding: 22px 18px;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.2);
  -webkit-transition: all .2s ease-out;
  -moz-transition: all .2s ease-out;
  -o-transition: all .2s ease-out;
  transition: all .2s ease-out;
  transform: translate3d(0, 20px, 0);
  -webkit-transform: translate3d(0, 20px, 0);
  -moz-transform: translate3d(0, 20px, 0);
  -ms-transform: translate3d(0, 20px, 0);
  -o-transform: translate3d(0, 20px, 0);
}

.header-news .header-search-popup {
  top: 0%;
  padding: 7px 18px;
  box-shadow: none;
}

.header-news .header-menu ul li.mega-menu-megamenu .mega-sub-menu {
  width: 100%;
  padding: 0;
  left: 0;
}

.header-news .header-search-popup.header-search-popup-open,
.header-news .header-menu ul li:hover > ul {
  visibility: visible;
  opacity: 1;
  transform: translate3d(0, 0, 0);
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  -o-transform: translate3d(0, 0, 0);
}

.header-news .mega-menu-wrap .mega-menu-flyout .mega-sub-menu li:hover > a,
.header-news .header-main-menu .sub-menu li:hover > a {
  color: #1a1a1a;
}

.header-news .header-search-popup .content {
  margin-bottom: 0;
}

.header-news .header-search-popup .search-input,
.header-news .header-search-popup .search-form.content button {
  font-size: 14px;
  line-height: 18px;
  margin-bottom: 0;
}

.header-news .megamenu-posts-last,
.header-news .megamenu-categories {
  padding: 24px 12px;
}

.header-news .megamenu-categories {
  min-height: 255px;
}

.header-news .mega-block-title {
  display: none;
}

.header-news .megamenu-categories-container {
  position: relative;
}

.header-news .megamenu-article-box {
  position: absolute;
  right: 0;
  top: 0;
  width: 80%;
  opacity: 0;
  z-index: 1;
  background-color: #fff;
  -webkit-transition: all .3s ease;
  -moz-transition: all .3s ease;
  -o-transition: all .3s ease;
  transition: all .3s ease;
  transform: translate3d(0, 12px, 0);
  -webkit-transform: translate3d(0, 12px, 0);
  -moz-transform: translate3d(0, 12px, 0);
  -ms-transform: translate3d(0, 12px, 0);
  -o-transform: translate3d(0, 12px, 0);
}

.header-news .megamenu-article-box-box-open {
  opacity: 1;
  z-index: 2;
  transform: translate3d(0, 0, 0);
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  -o-transform: translate3d(0, 0, 0);
}

.header-news .megamenu-post-entry {
  float: left;
  padding: 0 12px;
}

.header-news .megamenu-cat-box {
  padding: 0 12px;
}

.header-news .mega-menu-wrap .megamenu-cat-box a {
  font-size: 14px;
  line-height: 18px;
  margin-bottom: 8px;
  color: #808080;
  display: block;
  text-align: right;
  padding: 6px;
  text-decoration: none;
  -webkit-transition: all .3s ease;
  -moz-transition: all .3s ease;
  -o-transition: all .3s ease;
  transition: all .3s ease;
}

.header-news .mega-menu-wrap ul li .megamenu-cat-box-hover a {
  background-color: #f2f2f2;
  color: #1a1a1a;
}

.header-news .widget-post-4 {
  width: 25%;
}

.header-news .megamenu-cat-box,
.header-news .widget-post-5 {
  width: 20%;
}

.header-news .widget-post-6 {
  width: 16.66%;
}

.header-news .megamenu-post-entry h5 {
  font-size: 14px;
  line-height: 18px;
}

.header-news .megamenu-post-entry .entry-thumb {
  margin-bottom: 18px;
  position: relative;
}

.header-news .megamenu-post-entry .cat-meta {
  position: absolute;
  bottom: 28px;
  left: 28px;
}

.header-news #scroll-header {
  width: 100%;
  /*height: 60px; original, comment for fmh notice*/
  position: fixed;
  z-index: 2001;
  top: 0;
  -webkit-box-shadow: 0px 5px 10px 0px rgba(0, 0, 0, 0.1);
  -moz-box-shadow: 0px 5px 10px 0px rgba(0, 0, 0, 0.1);
  box-shadow: 0px 5px 10px 0px rgba(0, 0, 0, 0.1);
  -webkit-background-clip: padding-box;
  /* for Safari */
  background-clip: padding-box;
  /* for IE9+, Firefox 4+, Opera, Chrome */
  background-color: #fff;
  display: none;
}

.header-news #scroll-header .header-scroll-logo {
  width: 20%;
  vertical-align: middle;
}

.header-news #scroll-header .site-logo img {
  max-height: 60px;
}

.header-news #scroll-header .header-menu ul ul {
  left: initial;
  right: 0;
  border-top: 0;
}

.header-news #scroll-header .header-menu ul ul ul {
  right: 100%;
}

.header-news #scroll-header .header-search-mazada i {
  padding: 19px 0 19px 22px;
}

.header-news #scroll-header .header-main-menu,
.header-news #scroll-header .mega-menu-wrap {
  float: right;
}

.header-news #scroll-header .header-main-menu,
.header-news #scroll-header .mega-menu-flyout > .mega-sub-menu {
  right: 0;
}

.header-news .admin-bar #scroll-header {
  top: 32px;
}

@media (max-width: 800px) {
  .header-news {
    display: none;
  }
}

.widget-tem-search {
  margin-bottom: 32px;
  border: none;
  width: 300px;
  height: 373px;
}

.widget-tem-search.news-banner-ad {
  height: 543px;
}

@media (max-width: 1023px) {
  .widget-tem-search.news-banner-ad {
    margin-top: -10px;
  }
}

@media (min-width: 1025px) {
  .widget-tem-search {
    margin-left: 8px;
  }
}

@media (max-width: 1024px) {
  .widget-tem-search {
    margin-left: 0;
  }
}

@media (max-width: 820px) {
  .widget-tem-search {
    margin-left: 25%;
  }
}

@media (max-width: 767px) {
  .widget-tem-search {
    margin-left: 11.4%;
  }
}

@media (max-width: 480px) {
  .widget-tem-search {
    margin-left: 18.4%;
  }
}

@media (max-width: 425px) {
  .widget-tem-search {
    margin-left: 15%;
  }
}

@media (max-width: 414px) {
  .widget-tem-search {
    margin-left: 14%;
  }
}

@media (max-width: 375px) {
  .widget-tem-search {
    margin-left: 10%;
  }
}

@media (max-width: 360px) {
  .widget-tem-search {
    margin-left: 8%;
  }
}

@media (max-width: 320px) {
  .widget-tem-search {
    margin-left: 2.4%;
  }
}

body.page-redirectcredit {
  background: #f5f5f5;
}

.redirect-credit {
  margin: 8% auto;
  width: 100%;
  min-width: 300px;
  max-width: 600px;
  text-align: center;
  /* background: #fafafa; */
  font-size: 1.5rem;
  /* padding: 30px; */
}

@media (min-width: 768px) {
  #form-agent {
    max-width: 500px;
    margin: 0 auto;
  }
}

@media (max-width: 767px) {
  #form-agent {
    width: 92%;
    margin: 0 auto;
  }
}

#form-agent .form-group {
  margin-bottom: 20px;
}

#form-agent .form-group .form-control {
  font-size: 14px;
}

#form-agent .form-group label {
  text-transform: uppercase;
  font-size: 1rem;
}

#form-agent .form-check {
  padding-left: 0;
}

#form-agent .form-check label {
  font-size: 13px;
}

@media (max-width: 991px) {
  #form-agent .form-check label {
    font-size: 11px;
  }
}

#form-agent .label-pdpa {
  position: relative;
  right: -6px;
  top: -3px;
}

@media (max-width: 320px) {
  #form-agent .label-pdpa {
    margin-right: 10px;
  }
}

@media (max-width: 425px) {
  #form-agent .label-pdpa {
    margin-right: 4px;
  }
}

@media (min-width: 991px) {
  #form-agent .label-pdpa {
    position: relative;
    right: -1px;
    top: -1px;
  }
}

.general-form-check {
  display: inline-block;
  width: 17px;
  float: left;
}

.general-form-label {
  font-size: 11px;
  display: inline-block;
  width: 94%;
  float: right;
  text-transform: inherit !important;
  position: relative;
  top: 2px;
}

#tem-cond-error {
  position: relative;
  left: 10px;
  top: 3px;
}

.agent-form-overlay {
  position: fixed;
  bottom: -160%;
  height: 100vh;
  width: 100vw;
  background: rgba(0, 0, 0, 0.5);
  z-index: 9999;
  left: 0;
  transition: all .5s .2s ease-in-out;
}

.agent-form-overlay .agent-title {
  font-size: 2.4rem;
  margin-bottom: 25px;
}

@media (min-width: 768px) {
  .agent-form-overlay .agent-title {
    width: 80%;
    margin: 0 auto 25px;
  }
}

.agent-form-overlay .sub-agent-title {
  font-size: 1.8rem;
}

.agent-form-overlay .agent-form-close {
  position: absolute;
  top: 0;
  background: rgba(0, 0, 0, 0.18);
  color: white;
  right: calc((100% - 500px) / 2);
  padding: 0 10px;
  font-size: 28px;
  z-index: 1;
  cursor: pointer;
}

@media (max-width: 991px) {
  .agent-form-overlay .agent-form-close {
    right: calc((100% - 300px) / 2);
  }
}

.agent-form-overlay .agent-form-close:hover {
  background: rgba(20, 20, 20, 0.18);
}

.agent-form-overlay-content {
  background: #fff;
  box-shadow: 0 4px 11px -5px #bfbfbf;
  border: 1px solid #ddd;
  text-align: center;
  margin: 50px auto 0;
  min-height: 220px;
  padding: 25px 20px 20px;
  width: 600px;
  right: calc((100% - 500px) / 2);
}

@media (max-width: 991px) {
  .agent-form-overlay-content {
    margin: 20% auto 0;
    width: 300px;
    right: calc((100% - 300px) / 2);
    padding: 20px;
  }
}

.agent-form-overlay.in {
  bottom: 0;
  transition: all .5s .2s ease-in-out;
}

.general-tq-overlay {
  position: fixed;
  bottom: -160%;
  height: 100vh;
  width: 100vw;
  background: rgba(0, 0, 0, 0.5);
  transition: all .5s .2s ease-in-out;
}

.general-tq-overlay .post-title {
  font-size: 2.4rem;
  margin-bottom: 25px;
}

@media (min-width: 768px) {
  .general-tq-overlay .post-title {
    width: 80%;
    margin: 0 auto 25px;
  }
}

.general-tq-overlay .sub-post-title {
  font-size: 1.8rem;
}

.general-tq-overlay .post-form-close {
  position: absolute;
  top: 0;
  background: rgba(0, 0, 0, 0.18);
  color: white;
  right: calc((100% - 500px) / 2);
  padding: 0 10px;
  font-size: 28px;
  z-index: 1;
  cursor: pointer;
}

@media (max-width: 991px) {
  .general-tq-overlay .post-form-close {
    right: calc((100% - 300px) / 2);
  }
}

.general-tq-overlay .post-form-close:hover {
  background: rgba(20, 20, 20, 0.18);
}

.general-tq-overlay-content {
  background: #fff;
  box-shadow: 0 4px 11px -5px #bfbfbf;
  border: 1px solid #ddd;
  text-align: center;
  margin: 13% auto 0;
  min-height: 220px;
  padding: 25px 20px 20px;
  width: 500px;
  right: calc((100% - 500px) / 2);
}

@media (max-width: 991px) {
  .general-tq-overlay-content {
    margin: 20% auto 0;
    width: 300px;
    right: calc((100% - 300px) / 2);
    padding: 20px;
  }
}

.general-tq-overlay.in {
  bottom: 0;
  transition: all .5s .2s ease-in-out;
}

@font-face {
  font-family: "TiemposTextWeb";
  src: url("fonts/tiempostextweb/TiemposTextWeb-Regular-subset.woff2") format("woff2");
  font-weight: normal;
}

@font-face {
  font-family: "TiemposTextWeb";
  src: url("fonts/tiempostextweb/TiemposTextWeb-Bold-subset.woff2") format("woff2");
  font-weight: bold;
}

@font-face {
  font-family: "TiemposTextWeb";
  src: url("fonts/tiempostextweb/TiemposTextWeb-BoldItalic-subset.woff2") format("woff2");
  font-weight: bold;
  font-style: italic;
}

@font-face {
  font-family: "TiemposTextWeb";
  src: url("fonts/tiempostextweb/TiemposTextWeb-RegularItalic-subset.woff2") format("woff2");
  font-style: italic;
}

/* ---------------------------------------- */
/*  Custom:Drupal
/* ---------------------------------------- */
@media (min-width: 992px) {
  .content-area .content-column {
    width: calc(100% - 33rem);
  }
  .content-area .sidebar {
    width: 33rem;
  }
}

@media (min-width: 992px) {
  .one-sidebar.sidebar-first .content-area .content-column {
    left: 33rem;
  }
  .one-sidebar.sidebar-first .content-area .sidebar-first {
    right: calc(100% - 33rem);
  }
}

@media (min-width: 992px) {
  .two-sidebars .content-area .content-column {
    width: calc(100% - 44rem);
    left: 22rem;
  }
  .two-sidebars .content-area .sidebar {
    width: 22rem;
  }
  .two-sidebars .content-area .sidebar-first {
    right: calc(100% - 44rem);
  }
}

@media (min-width: 992px) {
  .no-sidebars .content-area .content-column {
    width: 100%;
  }
}

.header-top > .container,
.header-nav > .container,
.footer-top > .container,
.footer-bottom > .container {
  width: 100%;
}

.module-dev {
  font-size: 1.2rem;
}

.module-dev .alert-block {
  margin-bottom: 0;
  border-radius: 0;
}

.module-dev .alert-block .placeholder {
  font-weight: 500;
  color: #650e0e;
}

.module-dev .alert-block ul {
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}

.module-dev .alert-block ul > li {
  margin-bottom: 0.5em;
  padding-left: 1em;
  position: relative;
}

.module-dev .alert-block ul > li:before {
  content: '\f3d1';
  display: inline-block;
  font-family: "Ionicons";
  font-weight: normal;
  line-height: 1;
  position: absolute;
  top: 0.25em;
  left: 0;
}

.admin-menu.adminimal-menu .header {
  top: 2.9rem;
}

@media (max-width: 1024px) {
  .admin-menu .user-bar .dropdown-menu {
    margin-top: 0;
  }
}

@media (max-width: 767px) {
  #admin-menu,
  body.admin-menu.adminimal-menu:before {
    display: none;
  }
  .admin-menu.adminimal-menu .header {
    top: 0;
  }
}

/* ---------------------------------------- */
/*  Custom:General
/* ---------------------------------------- */
/* Block */
.block-newsletter-signup {
  margin: 3em 0;
}

.block-newsletter-signup .block-content {
  padding: 2em;
  background: linear-gradient(to right, #005c98, #363a94);
  color: #ffffff;
  border: 0.1rem solid transparent;
  transition: all 200ms ease-in-out;
}

.block-newsletter-signup .i-row {
  display: block;
}

.block-newsletter-signup .i-row + .i-row {
  display: none;
}

.block-newsletter-signup .nls-title {
  margin-top: 1.6rem;
  margin-bottom: 0.4rem;
  line-height: 2.5rem;
  position: relative;
}

.block-newsletter-signup .nls-title small {
  margin-bottom: 0.35em;
  color: #46b8da;
  position: absolute;
  left: 0;
  bottom: 100%;
}

.block-newsletter-signup.active .block-content {
  background: #f4f4f4;
  color: #252525;
  border-color: #dedede;
}

.block-newsletter-signup.active .i-row {
  display: none;
}

.block-newsletter-signup.active .i-row + .i-row {
  display: block;
}

.block-mortgage-calculator {
  padding: 2rem;
  border: 0.1rem solid #f7f6f6;
  background-color: #ffffff;
}

.block-mortgage-calculator .i-form hr {
  margin: 1.5rem 0;
}

.block-mortgage-calculator .i-form .input {
  border-color: #f7f6f6;
}

.block-mortgage-calculator .i-form .input input {
  font-weight: 600;
  color: #2e3a52;
}

.block-mortgage-calculator .i-form .input label:before, .block-mortgage-calculator .i-form .input label:after {
  font-weight: 600;
  color: #2e3a52;
}

.block-mortgage-calculator .i-form .input.deposit > label:before {
  content: "RM\a" attr(data-compute);
  float: right;
}

.block-mortgage-calculator .i-form .input.deposit > label:after {
  content: attr(data-prefix) "\a(" attr(data-num) attr(data-suffix) "):";
  float: none;
}

.block-mortgage-calculator .i-form .input:last-child {
  margin-bottom: 0;
}

.block-mortgage-calculator .i-segment {
  padding: 2rem;
  background-color: #2e3a52;
  color: #ffffff;
  border-radius: 0;
  box-shadow: none;
}

.block-mortgage-calculator .i-segment > .body {
  padding-top: 6rem;
  padding-bottom: 6rem;
  border: 0.2rem solid #3b839c;
  border-radius: 0.2rem;
}

.block-mortgage-calculator .i-segment > .body h2 {
  line-height: 1;
}

.block-mortgage-calculator .i-segment > .body h2 span {
  display: inline-block;
  vertical-align: middle;
  color: inherit;
}

.block-mortgage-calculator .i-segment > .body h2 span + span {
  margin-left: 0.8rem;
}

.block-mortgage-calculator .flex-center {
  display: flex;
  align-items: center;
}

.block-train-map {
  width: 100%;
  height: 100%;
  margin-top: 0 !important;
  padding: 4rem;
  background-color: #ffffff;
  opacity: 0;
  visibility: hidden;
  overflow-x: auto;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 100;
  transition: all 200ms ease-in-out;
}

.block-train-map .block-title, .block-train-map .area-outlook-detail h3.block-title, .area-outlook-detail .block-train-map h3.block-title,
.block-train-map .page-project-outlook h3.block-title, .page-project-outlook .block-train-map h3.block-title {
  font-size: 2.8rem;
  text-align: center;
}

.block-train-map .tm-line-row {
  display: flex;
  max-height: 290rem;
  flex-direction: columns;
  flex-flow: column wrap;
  align-content: left;
  align-items: left;
  justify-content: flex-start;
}

.block-train-map .tm-line-col {
  width: 33.333%;
  margin-bottom: 3rem;
}

.block-train-map .tm-station {
  padding-top: 0.4rem;
  padding-bottom: 0.4rem;
  clear: both;
}

.block-train-map .tm-station-prefix {
  float: left;
  width: 45%;
  padding-right: 1rem;
  position: relative;
}

.block-train-map .tm-station-label {
  min-height: auto;
  margin: 0 0 0 45%;
  padding-top: 0.2rem;
  padding-bottom: 0.2rem;
  line-height: 1;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.block-train-map .tm-station-label label {
  line-height: 1.6;
}

.block-train-map .tm-station-label label:before {
  box-shadow: 0 0.2rem 0.5rem -0.2rem rgba(37, 37, 37, 0.12), 0 -0.1rem 0.2rem 0 rgba(37, 37, 37, 0.08) inset;
}

.block-train-map .tm-station-label input:checked + label:before {
  background-color: transparent;
  border-color: #dededf;
}

.block-train-map .tm-station-label input:checked + label:after {
  color: #2e3a52;
}

.block-train-map .tm-station-label input[type="checkbox"]:checked + label:after {
  top: 0.1rem;
}

.block-train-map .tm-actions {
  width: 100%;
  padding: 1rem;
  background-color: rgba(255, 255, 255, 0.9);
  text-align: center;
  position: fixed;
  left: 0;
  bottom: 0;
  box-shadow: 0 -0.6rem 1rem -0.2rem rgba(37, 37, 37, 0.05), 0 -0.1rem 0 0 rgba(37, 37, 37, 0.1);
}

.block-train-map .station-linkage {
  display: block;
  border-left: 0.2rem solid #2e3a52;
  border-right: 0.2rem solid #2e3a52;
  position: absolute;
  top: -1.5rem;
  bottom: -1rem;
  right: 3.2rem;
  z-index: -1;
}

.block-train-map .station-link {
  display: block;
  float: right;
  font-size: 0;
  line-height: 1;
  text-align: right;
  white-space: nowrap;
}

.block-train-map .station-code {
  display: inline-block;
  min-width: 5rem;
  padding: 0.5rem;
  border-top: 0.1rem solid #ffffff;
  border-bottom: 0.1rem solid #ffffff;
  background-color: #2e3a52;
  color: #ffffff;
  font-size: small;
  line-height: 1.2rem;
  text-align: center;
  text-transform: uppercase;
}

.block-train-map .station-code:first-child {
  border-top-left-radius: 5rem;
  border-bottom-left-radius: 5rem;
}

.block-train-map .station-code:last-child {
  border-top-right-radius: 5rem;
  border-bottom-right-radius: 5rem;
}

.block-train-map .tm-header {
  clear: both;
}

.block-train-map .tm-header h4 {
  max-width: 80%;
  margin-top: 0;
  margin-left: 10%;
  margin-bottom: 0.3em;
  padding: 0.5em 1em;
  background-color: #2e3a52;
  color: #ffffff;
  font-size: 1.5rem;
  font-weight: 600;
  text-align: center;
  border-radius: 5rem;
}

.block-train-map .tm-header h4 > small {
  color: rgba(255, 255, 255, 0.5);
}

.block-train-map .tm-header .tm-station-label label {
  font-weight: 700;
}

.block-train-map .tm-lines > .tm-station:last-child .station-linkage {
  bottom: 0;
}

.block-train-map.active {
  opacity: 1;
  visibility: visible;
}

.block-train-map .tm-line-sb .tm-station-label.radio input:checked + label:before,
.block-train-map .tm-line-sb .station-linkage {
  border-color: #1e4d2b;
}

.block-train-map .tm-line-sb .tm-header h4,
.block-train-map .tm-line-sb .tm-station-label.radio input:checked + label:after {
  background-color: #1e4d2b;
}

.block-train-map .tm-line-kj .tm-station-label.radio input:checked + label:before,
.block-train-map .tm-line-kj .station-linkage {
  border-color: #e0115f;
}

.block-train-map .tm-line-kj .tm-header h4,
.block-train-map .tm-line-kj .tm-station-label.radio input:checked + label:after {
  background-color: #e0115f;
}

.block-train-map .tm-line-ag .tm-station-label.radio input:checked + label:before,
.block-train-map .tm-line-ag .station-linkage {
  border-color: #fe8e10;
}

.block-train-map .tm-line-ag .tm-header h4,
.block-train-map .tm-line-ag .tm-station-label.radio input:checked + label:after {
  background-color: #fe8e10;
}

.block-train-map .tm-line-sp .tm-station-label.radio input:checked + label:before,
.block-train-map .tm-line-sp .station-linkage {
  border-color: #721422;
}

.block-train-map .tm-line-sp .tm-header h4,
.block-train-map .tm-line-sp .tm-station-label.radio input:checked + label:after {
  background-color: #721422;
}

.block-train-map .tm-line-mr .tm-station-label.radio input:checked + label:before,
.block-train-map .tm-line-mr .station-linkage {
  border-color: #7dba00;
}

.block-train-map .tm-line-mr .tm-header h4,
.block-train-map .tm-line-mr .tm-station-label.radio input:checked + label:after {
  background-color: #7dba00;
}

.block-train-map .tm-line-rkf .tm-station-label.radio input:checked + label:before,
.block-train-map .tm-line-rkf .station-linkage {
  border-color: #ff5722;
}

.block-train-map .tm-line-rkf .tm-header h4,
.block-train-map .tm-line-rkf .tm-station-label.radio input:checked + label:after {
  background-color: #ff5722;
}

.block-train-map .tm-line-sbk .tm-station-label.radio input:checked + label:before,
.block-train-map .tm-line-sbk .station-linkage {
  border-color: #008000;
}

.block-train-map .tm-line-sbk .tm-header h4,
.block-train-map .tm-line-sbk .tm-station-label.radio input:checked + label:after {
  background-color: #008000;
}

.block-train-map .tm-line-ke .tm-station-label.radio input:checked + label:before,
.block-train-map .tm-line-ke .station-linkage {
  border-color: #800080;
}

.block-train-map .tm-line-ke .tm-header h4,
.block-train-map .tm-line-ke .tm-station-label.radio input:checked + label:after {
  background-color: #800080;
}

.block-train-map .tm-line-kt .tm-station-label.radio input:checked + label:before,
.block-train-map .tm-line-kt .station-linkage {
  border-color: #139593;
}

.block-train-map .tm-line-kt .tm-header h4,
.block-train-map .tm-line-kt .tm-station-label.radio input:checked + label:after {
  background-color: #139593;
}

.block-train-map .tm-line-ka .tm-station-label.radio input:checked + label:before,
.block-train-map .tm-line-ka .station-linkage {
  border-color: #dc241f;
}

.block-train-map .tm-line-ka .tm-header h4,
.block-train-map .tm-line-ka .tm-station-label.radio input:checked + label:after {
  background-color: #dc241f;
}

.block-train-map .tm-line-kd .tm-station-label.radio input:checked + label:before,
.block-train-map .tm-line-kd .station-linkage {
  border-color: #dc241f;
}

.block-train-map .tm-line-kd .tm-header h4,
.block-train-map .tm-line-kd .tm-station-label.radio input:checked + label:after {
  background-color: #dc241f;
}

.block-train-map .tm-line-kb .tm-station-label.radio input:checked + label:before,
.block-train-map .tm-line-kb .station-linkage {
  border-color: #1964b7;
}

.block-train-map .tm-line-kb .tm-header h4,
.block-train-map .tm-line-kb .tm-station-label.radio input:checked + label:after {
  background-color: #1964b7;
}

.block-train-map .tm-line-kc .tm-station-label.radio input:checked + label:before,
.block-train-map .tm-line-kc .station-linkage {
  border-color: #1964b7;
}

.block-train-map .tm-line-kc .tm-header h4,
.block-train-map .tm-line-kc .tm-station-label.radio input:checked + label:after {
  background-color: #1964b7;
}

.block-train-map .tm-lines .color-sb {
  background-color: #1e4d2b;
}

.block-train-map .tm-lines .color-kj {
  background-color: #e0115f;
}

.block-train-map .tm-lines .color-ag {
  background-color: #fe8e10;
}

.block-train-map .tm-lines .color-sp {
  background-color: #721422;
}

.block-train-map .tm-lines .color-mr {
  background-color: #7dba00;
}

.block-train-map .tm-lines .color-rkf {
  background-color: #ff5722;
}

.block-train-map .tm-lines .color-sbk {
  background-color: #008000;
}

.block-train-map .tm-lines .color-ke {
  background-color: #800080;
}

.block-train-map .tm-lines .color-kt {
  background-color: #139593;
}

.block-train-map .tm-lines .color-ka {
  background-color: #dc241f;
}

.block-train-map .tm-lines .color-kd {
  background-color: #dc241f;
}

.block-train-map .tm-lines .color-kb {
  background-color: #1964b7;
}

.block-train-map .tm-lines .color-kc {
  background-color: #1964b7;
}

.block-train-map-sg .block-title, .block-train-map-sg .area-outlook-detail h3.block-title, .area-outlook-detail .block-train-map-sg h3.block-title,
.block-train-map-sg .page-project-outlook h3.block-title, .page-project-outlook .block-train-map-sg h3.block-title {
  font-size: 2.8rem;
  text-align: center;
}

.block-train-map-sg .block-content {
  display: flex;
  flex-flow: row nowrap;
  height: calc(100vh - 72px);
}

.block-train-map-sg .block-content .trainmap-form .selection .checkbox.line input:checked + label.tm-line-sb,
.block-train-map-sg .block-content .tm-line-content .tm-line-row .tm-line-col .tm-lines .tm-header h5.tm-line-sb {
  background-color: #1e4d2b;
}

.block-train-map-sg .block-content .tm-header i.color-sb,
.block-train-map-sg .block-content .tm-line-content .tm-line-row .tm-line-col .tm-lines .tm-station.color-sb:before,
.block-train-map-sg .block-content .station-code.color-sb {
  color: #1e4d2b;
}

.block-train-map-sg .block-content .tm-line-content .tm-line-row .tm-line-col .tm-lines .tm-station.color-sb {
  border-top-color: #1e4d2b;
}

.block-train-map-sg .block-content .trainmap-form .selection .checkbox.line input:checked + label.tm-line-kj,
.block-train-map-sg .block-content .tm-line-content .tm-line-row .tm-line-col .tm-lines .tm-header h5.tm-line-kj {
  background-color: #e0115f;
}

.block-train-map-sg .block-content .tm-header i.color-kj,
.block-train-map-sg .block-content .tm-line-content .tm-line-row .tm-line-col .tm-lines .tm-station.color-kj:before,
.block-train-map-sg .block-content .station-code.color-kj {
  color: #e0115f;
}

.block-train-map-sg .block-content .tm-line-content .tm-line-row .tm-line-col .tm-lines .tm-station.color-kj {
  border-top-color: #e0115f;
}

.block-train-map-sg .block-content .trainmap-form .selection .checkbox.line input:checked + label.tm-line-ag,
.block-train-map-sg .block-content .tm-line-content .tm-line-row .tm-line-col .tm-lines .tm-header h5.tm-line-ag {
  background-color: #fe8e10;
}

.block-train-map-sg .block-content .tm-header i.color-ag,
.block-train-map-sg .block-content .tm-line-content .tm-line-row .tm-line-col .tm-lines .tm-station.color-ag:before,
.block-train-map-sg .block-content .station-code.color-ag {
  color: #fe8e10;
}

.block-train-map-sg .block-content .tm-line-content .tm-line-row .tm-line-col .tm-lines .tm-station.color-ag {
  border-top-color: #fe8e10;
}

.block-train-map-sg .block-content .trainmap-form .selection .checkbox.line input:checked + label.tm-line-sp,
.block-train-map-sg .block-content .tm-line-content .tm-line-row .tm-line-col .tm-lines .tm-header h5.tm-line-sp {
  background-color: #721422;
}

.block-train-map-sg .block-content .tm-header i.color-sp,
.block-train-map-sg .block-content .tm-line-content .tm-line-row .tm-line-col .tm-lines .tm-station.color-sp:before,
.block-train-map-sg .block-content .station-code.color-sp {
  color: #721422;
}

.block-train-map-sg .block-content .tm-line-content .tm-line-row .tm-line-col .tm-lines .tm-station.color-sp {
  border-top-color: #721422;
}

.block-train-map-sg .block-content .trainmap-form .selection .checkbox.line input:checked + label.tm-line-mr,
.block-train-map-sg .block-content .tm-line-content .tm-line-row .tm-line-col .tm-lines .tm-header h5.tm-line-mr {
  background-color: #7dba00;
}

.block-train-map-sg .block-content .tm-header i.color-mr,
.block-train-map-sg .block-content .tm-line-content .tm-line-row .tm-line-col .tm-lines .tm-station.color-mr:before,
.block-train-map-sg .block-content .station-code.color-mr {
  color: #7dba00;
}

.block-train-map-sg .block-content .tm-line-content .tm-line-row .tm-line-col .tm-lines .tm-station.color-mr {
  border-top-color: #7dba00;
}

.block-train-map-sg .block-content .trainmap-form .selection .checkbox.line input:checked + label.tm-line-rkf,
.block-train-map-sg .block-content .tm-line-content .tm-line-row .tm-line-col .tm-lines .tm-header h5.tm-line-rkf {
  background-color: #ff5722;
}

.block-train-map-sg .block-content .tm-header i.color-rkf,
.block-train-map-sg .block-content .tm-line-content .tm-line-row .tm-line-col .tm-lines .tm-station.color-rkf:before,
.block-train-map-sg .block-content .station-code.color-rkf {
  color: #ff5722;
}

.block-train-map-sg .block-content .tm-line-content .tm-line-row .tm-line-col .tm-lines .tm-station.color-rkf {
  border-top-color: #ff5722;
}

.block-train-map-sg .block-content .trainmap-form .selection .checkbox.line input:checked + label.tm-line-sbk,
.block-train-map-sg .block-content .tm-line-content .tm-line-row .tm-line-col .tm-lines .tm-header h5.tm-line-sbk {
  background-color: #008000;
}

.block-train-map-sg .block-content .tm-header i.color-sbk,
.block-train-map-sg .block-content .tm-line-content .tm-line-row .tm-line-col .tm-lines .tm-station.color-sbk:before,
.block-train-map-sg .block-content .station-code.color-sbk {
  color: #008000;
}

.block-train-map-sg .block-content .tm-line-content .tm-line-row .tm-line-col .tm-lines .tm-station.color-sbk {
  border-top-color: #008000;
}

.block-train-map-sg .block-content .trainmap-form .selection .checkbox.line input:checked + label.tm-line-ke,
.block-train-map-sg .block-content .tm-line-content .tm-line-row .tm-line-col .tm-lines .tm-header h5.tm-line-ke {
  background-color: #800080;
}

.block-train-map-sg .block-content .tm-header i.color-ke,
.block-train-map-sg .block-content .tm-line-content .tm-line-row .tm-line-col .tm-lines .tm-station.color-ke:before,
.block-train-map-sg .block-content .station-code.color-ke {
  color: #800080;
}

.block-train-map-sg .block-content .tm-line-content .tm-line-row .tm-line-col .tm-lines .tm-station.color-ke {
  border-top-color: #800080;
}

.block-train-map-sg .block-content .trainmap-form .selection .checkbox.line input:checked + label.tm-line-kt,
.block-train-map-sg .block-content .tm-line-content .tm-line-row .tm-line-col .tm-lines .tm-header h5.tm-line-kt {
  background-color: #139593;
}

.block-train-map-sg .block-content .tm-header i.color-kt,
.block-train-map-sg .block-content .tm-line-content .tm-line-row .tm-line-col .tm-lines .tm-station.color-kt:before,
.block-train-map-sg .block-content .station-code.color-kt {
  color: #139593;
}

.block-train-map-sg .block-content .tm-line-content .tm-line-row .tm-line-col .tm-lines .tm-station.color-kt {
  border-top-color: #139593;
}

.block-train-map-sg .block-content .trainmap-form .selection .checkbox.line input:checked + label.tm-line-ka,
.block-train-map-sg .block-content .tm-line-content .tm-line-row .tm-line-col .tm-lines .tm-header h5.tm-line-ka {
  background-color: #dc241f;
}

.block-train-map-sg .block-content .tm-header i.color-ka,
.block-train-map-sg .block-content .tm-line-content .tm-line-row .tm-line-col .tm-lines .tm-station.color-ka:before,
.block-train-map-sg .block-content .station-code.color-ka {
  color: #dc241f;
}

.block-train-map-sg .block-content .tm-line-content .tm-line-row .tm-line-col .tm-lines .tm-station.color-ka {
  border-top-color: #dc241f;
}

.block-train-map-sg .block-content .trainmap-form .selection .checkbox.line input:checked + label.tm-line-kd,
.block-train-map-sg .block-content .tm-line-content .tm-line-row .tm-line-col .tm-lines .tm-header h5.tm-line-kd {
  background-color: #dc241f;
}

.block-train-map-sg .block-content .tm-header i.color-kd,
.block-train-map-sg .block-content .tm-line-content .tm-line-row .tm-line-col .tm-lines .tm-station.color-kd:before,
.block-train-map-sg .block-content .station-code.color-kd {
  color: #dc241f;
}

.block-train-map-sg .block-content .tm-line-content .tm-line-row .tm-line-col .tm-lines .tm-station.color-kd {
  border-top-color: #dc241f;
}

.block-train-map-sg .block-content .trainmap-form .selection .checkbox.line input:checked + label.tm-line-kb,
.block-train-map-sg .block-content .tm-line-content .tm-line-row .tm-line-col .tm-lines .tm-header h5.tm-line-kb {
  background-color: #1964b7;
}

.block-train-map-sg .block-content .tm-header i.color-kb,
.block-train-map-sg .block-content .tm-line-content .tm-line-row .tm-line-col .tm-lines .tm-station.color-kb:before,
.block-train-map-sg .block-content .station-code.color-kb {
  color: #1964b7;
}

.block-train-map-sg .block-content .tm-line-content .tm-line-row .tm-line-col .tm-lines .tm-station.color-kb {
  border-top-color: #1964b7;
}

.block-train-map-sg .block-content .trainmap-form .selection .checkbox.line input:checked + label.tm-line-kc,
.block-train-map-sg .block-content .tm-line-content .tm-line-row .tm-line-col .tm-lines .tm-header h5.tm-line-kc {
  background-color: #1964b7;
}

.block-train-map-sg .block-content .tm-header i.color-kc,
.block-train-map-sg .block-content .tm-line-content .tm-line-row .tm-line-col .tm-lines .tm-station.color-kc:before,
.block-train-map-sg .block-content .station-code.color-kc {
  color: #1964b7;
}

.block-train-map-sg .block-content .tm-line-content .tm-line-row .tm-line-col .tm-lines .tm-station.color-kc {
  border-top-color: #1964b7;
}

.block-train-map-sg .block-content .trainmap-form {
  background-color: #f4f7fa;
  flex: 0 0 30%;
  padding: 3rem 1.5rem;
  min-width: 250px;
  max-width: 250px;
}

.block-train-map-sg .block-content .trainmap-form .dropdown {
  margin-bottom: 10px;
}

.block-train-map-sg .block-content .trainmap-form .dropdown .button {
  width: 100%;
  text-align: left;
  padding: 5px 10px;
}

.block-train-map-sg .block-content .trainmap-form .dropdown .button i {
  position: absolute;
  right: 10px;
  bottom: 8px;
}

.block-train-map-sg .block-content .trainmap-form .dropdown .button-trainmap {
  background-color: white;
  border: 1px solid #979797;
}

.block-train-map-sg .block-content .trainmap-form .dropdown .button-trainmap:active {
  box-shadow: none;
}

.block-train-map-sg .block-content .trainmap-form .dropdown ul.dropdown-menu {
  border-radius: 0;
  box-shadow: 5px 5px 12px 2px rgba(0, 0, 0, 0.5);
  margin: 0 10px;
  right: 0;
  left: 0;
}

.block-train-map-sg .block-content .trainmap-form .dropdown ul.dropdown-menu > li {
  padding: 5px 10px;
}

.block-train-map-sg .block-content .trainmap-form .dropdown ul.dropdown-menu > li:hover {
  background-color: #f5f5f5;
}

.block-train-map-sg .block-content .trainmap-form hr {
  margin-left: -15px;
  margin-right: -15px;
}

.block-train-map-sg .block-content .trainmap-form .selection .checkbox {
  display: inline-block;
}

.block-train-map-sg .block-content .trainmap-form .selection .checkbox label {
  background-color: #eee;
  padding: 2px 12px;
  border-radius: 20px;
}

.block-train-map-sg .block-content .trainmap-form .selection .checkbox label:before {
  display: none;
}

.block-train-map-sg .block-content .trainmap-form .selection .checkbox input:checked + label {
  background-color: #455a64;
  color: white;
}

.block-train-map-sg .block-content .trainmap-form .selection .checkbox input:checked + label:before, .block-train-map-sg .block-content .trainmap-form .selection .checkbox input:checked + label:after {
  display: none;
}

.block-train-map-sg .block-content .tm-line-content {
  flex: 1 1 70%;
  display: flex;
  flex-flow: column nowrap;
  box-shadow: inset 20px 0px 20px -20px grey;
  overflow: hidden;
}

.block-train-map-sg .block-content .tm-line-content .title {
  padding: 30px 0 10px 40px;
  font-size: 16px;
}

.block-train-map-sg .block-content .tm-line-content .tm-line-row {
  overflow-y: scroll;
  overflow-x: hidden;
  width: calc(100% + 20px);
  height: 100%;
  /* Safari 4.0 - 8.0 */
  /* Standard syntax */
}

.block-train-map-sg .block-content .tm-line-content .tm-line-row .tm-line-empty {
  height: 100%;
  display: none;
  flex-flow: column nowrap;
  justify-content: center;
}

.block-train-map-sg .block-content .tm-line-content .tm-line-row .tm-line-empty img.img-responsive {
  max-width: 42%;
  margin: 0 auto;
}

.block-train-map-sg .block-content .tm-line-content .tm-line-row .tm-line-empty.active {
  display: flex;
}

.block-train-map-sg .block-content .tm-line-content .tm-line-row .tm-line-col {
  padding-left: 4rem;
  display: none;
}

.block-train-map-sg .block-content .tm-line-content .tm-line-row .tm-line-col:last-child {
  box-shadow: inset 0px -20px 20px -20px grey;
}

.block-train-map-sg .block-content .tm-line-content .tm-line-row .tm-line-col .tm-lines {
  display: flex;
  flex-flow: row wrap;
  justify-content: flex-start;
}

.block-train-map-sg .block-content .tm-line-content .tm-line-row .tm-line-col .tm-lines .tm-header {
  flex-basis: 100%;
  padding-bottom: 5px;
}

.block-train-map-sg .block-content .tm-line-content .tm-line-row .tm-line-col .tm-lines .tm-header i {
  font-size: 18px;
  padding-right: 8px;
  vertical-align: text-bottom;
}

.block-train-map-sg .block-content .tm-line-content .tm-line-row .tm-line-col .tm-lines .tm-header h5 {
  background-color: #455a64;
  width: fit-content;
  color: white;
  padding: 2px 25px;
  font-weight: 400;
  border-radius: 20px;
  display: inline-block;
}

.block-train-map-sg .block-content .tm-line-content .tm-line-row .tm-line-col .tm-lines .tm-header .tm-station-label {
  display: inline-block;
}

.block-train-map-sg .block-content .tm-line-content .tm-line-row .tm-line-col .tm-lines .tm-header .tm-station-label input[type="checkbox"] {
  display: none;
}

.block-train-map-sg .block-content .tm-line-content .tm-line-row .tm-line-col .tm-lines .tm-header .tm-station-label.checkbox {
  position: relative;
}

.block-train-map-sg .block-content .tm-line-content .tm-line-row .tm-line-col .tm-lines .tm-header .tm-station-label.checkbox label {
  padding-left: 35px;
}

.block-train-map-sg .block-content .tm-line-content .tm-line-row .tm-line-col .tm-lines .tm-header .tm-station-label.checkbox label:before {
  content: "";
  border: 1px solid #bfbebe;
  background-color: white;
  width: 18px;
  height: 18px;
  display: block;
  position: absolute;
  left: 0;
  margin: 1px 8px;
  border-radius: 5px;
}

.block-train-map-sg .block-content .tm-line-content .tm-line-row .tm-line-col .tm-lines .tm-header .tm-station-label.checkbox input:checked + label:after {
  content: "";
  background-color: #ff5122;
  padding: 2px;
  border: 2px solid white;
  position: absolute;
  left: 0;
  top: 0;
  border-radius: 5px;
  width: 16px;
  height: 16px;
  margin: 2px 9px;
}

.block-train-map-sg .block-content .tm-line-content .tm-line-row .tm-line-col .tm-lines .tm-station {
  border-top: 2px solid orange;
  position: relative;
  flex: 1 1 15%;
  min-width: 150px;
  max-width: 150px;
}

.block-train-map-sg .block-content .tm-line-content .tm-line-row .tm-line-col .tm-lines .tm-station:before {
  font-family: FontAwesome;
  content: "\f111";
  color: orange;
  position: absolute;
  top: -10px;
  text-shadow: -1px 0 #fff, 0 1px #fff, 1px 0 #fff, 0 -1px #fff;
}

.block-train-map-sg .block-content .tm-line-content .tm-line-row .tm-line-col .tm-lines .tm-station:last-child {
  border-top: 2px solid transparent;
}

.block-train-map-sg .block-content .tm-line-content .tm-line-row .tm-line-col .tm-lines .tm-station .station-code {
  padding-right: 4px;
  font-weight: 600;
}

.block-train-map-sg .block-content .tm-line-content .tm-line-row .tm-line-col .tm-lines .tm-station .tm-station-label {
  padding: 5px 0;
}

.block-train-map-sg .block-content .tm-line-content .tm-line-row .tm-line-col .tm-lines .tm-station .tm-station-label input[type="checkbox"] {
  display: none;
}

.block-train-map-sg .block-content .tm-line-content .tm-line-row .tm-line-col .tm-lines .tm-station .tm-station-label.checkbox {
  position: relative;
}

.block-train-map-sg .block-content .tm-line-content .tm-line-row .tm-line-col .tm-lines .tm-station .tm-station-label.checkbox label {
  padding-left: 35px;
  font-size: 0.86em;
}

.block-train-map-sg .block-content .tm-line-content .tm-line-row .tm-line-col .tm-lines .tm-station .tm-station-label.checkbox label .avg-price {
  font-weight: 600;
}

.block-train-map-sg .block-content .tm-line-content .tm-line-row .tm-line-col .tm-lines .tm-station .tm-station-label.checkbox label:before {
  content: "";
  border: 1px solid #bfbebe;
  background-color: white;
  width: 18px;
  height: 18px;
  display: block;
  position: absolute;
  left: 0;
  top: 10px;
  margin: 1px 8px;
  border-radius: 5px;
}

.block-train-map-sg .block-content .tm-line-content .tm-line-row .tm-line-col .tm-lines .tm-station .tm-station-label.checkbox input:checked + label:after {
  content: "";
  background-color: #ff5122;
  padding: 2px;
  border: 2px solid white;
  position: absolute;
  left: 0;
  top: 10px;
  border-radius: 5px;
  width: 16px;
  height: 16px;
  margin: 2px 9px;
}

.block-train-map-sg .block-content .tm-line-content .tm-line-row .tm-line-col .tm-lines .tm-station .tm-station-label.checkbox:hover {
  background-color: #f5f5f5;
  border-radius: 5px;
}

.block-train-map-sg .block-content .tm-line-content .tm-line-row .tm-line-col.active {
  display: block;
  animation: slide-left .2s ease-in-out;
}

@-webkit-keyframes slide-left {
  0% {
    opacity: 0;
    -webkit-transform: translateX(50%);
  }
  100% {
    opacity: 1;
    -webkit-transform: translateX(0);
  }
}

@keyframes slide-left {
  0% {
    opacity: 0;
    -webkit-transform: translateX(50%);
  }
  100% {
    opacity: 1;
    -webkit-transform: translateX(0);
  }
}

.block-train-map-sg .block-content .tm-line-content .tm-actions {
  width: 100%;
}

.block-train-map-sg .block-content .tm-line-content .tm-actions .actions {
  margin: auto;
  width: fit-content;
}

.block-train-map-sg .block-content .tm-line-content .tm-actions .actions a {
  display: none;
}

.block-train-map-sg .block-content .tm-line-content .tm-actions i {
  font-size: 2rem;
  padding-right: 8px;
  vertical-align: text-bottom;
  cursor: pointer;
}

.block-train-map-sg .block-content .tm-line-content .tm-actions .button {
  cursor: not-allowed;
  background-color: rgba(181, 181, 181, 0.8);
  color: white;
  width: 200px;
  border-radius: 100px;
  font-size: 14px;
  font-weight: 300;
  padding: 8px 30px;
}

.block-train-map-sg .block-content .tm-line-content .tm-actions .button.active {
  cursor: pointer;
  background-color: #ff5122;
}

.block-train-map-sg .block-content .tm-actions {
  padding: 4px;
  margin: auto;
}

.block-train-map-sg .block-content .tm-actions .actions > * {
  display: inline-block;
}

@media (max-width: 1024px) {
  .block-train-map-sg .block-content {
    height: calc(100vh - 50px);
  }
}

@media (max-width: 676px) {
  .block-train-map-sg .block-content {
    flex-flow: column nowrap;
    height: auto;
  }
  .block-train-map-sg .block-content .trainmap-form {
    max-width: 100%;
    min-width: 100%;
    padding: 1rem .8rem 1.5rem .8rem;
  }
  .block-train-map-sg .block-content .trainmap-form hr {
    display: none;
  }
  .block-train-map-sg .block-content .trainmap-form .trainmap-filter {
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
  }
  .block-train-map-sg .block-content .trainmap-form .trainmap-filter .dropdown {
    flex: 0 1 49%;
  }
  .block-train-map-sg .block-content .trainmap-form .trainmap-filter .dropdown:last-child {
    flex: 1 1 100%;
  }
  .block-train-map-sg .block-content .tm-line-content {
    box-shadow: none;
    overflow: visible;
  }
  .block-train-map-sg .block-content .tm-line-content .title {
    padding: .8rem;
  }
  .block-train-map-sg .block-content .tm-line-content .tm-line-row .tm-line-col {
    padding: 0 .8rem;
  }
  .block-train-map-sg .block-content .tm-line-content .tm-actions {
    position: sticky;
    bottom: 0;
    background-color: #f8f8f8;
    border-top: 1px solid #cacaca;
  }
  .block-train-map-sg .block-content .tm-line-content .tm-actions .actions {
    position: relative;
    width: 100%;
    padding: 0 20px;
  }
  .block-train-map-sg .block-content .tm-line-content .tm-actions .actions > div:first-of-type {
    width: 100%;
    text-align: center;
    padding-bottom: 5px;
  }
  .block-train-map-sg .block-content .tm-line-content .tm-actions .actions button {
    margin: 0;
    width: 100%;
  }
  .block-train-map-sg .block-content .tm-line-content .tm-actions .actions a {
    display: block;
    position: absolute;
    top: -25px;
    right: 20px;
    border-radius: 50%;
    background: #46B8DA;
    color: white;
    text-align: center;
    width: 35px;
    height: 35px;
    line-height: 32px;
  }
  .block-train-map-sg .block-content .tm-line-content .tm-actions .actions a i {
    padding: 0;
  }
}

.block-school-map-sg .block-title, .block-school-map-sg .area-outlook-detail h3.block-title, .area-outlook-detail .block-school-map-sg h3.block-title,
.block-school-map-sg .page-project-outlook h3.block-title, .page-project-outlook .block-school-map-sg h3.block-title {
  font-size: 2.8rem;
  text-align: center;
}

.block-school-map-sg .block-content {
  display: flex;
  flex-flow: row nowrap;
  height: calc(100vh - 72px);
}

.block-school-map-sg .block-content .trainmap-form .selection .checkbox input:checked + label.tm-line-sb,
.block-school-map-sg .block-content .tm-line-content .tm-line-row .tm-line-col .tm-lines .tm-header h5.tm-line-sb {
  background-color: #1e4d2b;
}

.block-school-map-sg .block-content .tm-header i.color-sb,
.block-school-map-sg .block-content .tm-line-content .tm-line-row .tm-line-col .tm-lines .tm-station.color-sb:before,
.block-school-map-sg .block-content .station-code.color-sb {
  color: #1e4d2b;
}

.block-school-map-sg .block-content .tm-line-content .tm-line-row .tm-line-col .tm-lines .tm-station.color-sb {
  border-top-color: #1e4d2b;
}

.block-school-map-sg .block-content .trainmap-form .selection .checkbox input:checked + label.tm-line-kj,
.block-school-map-sg .block-content .tm-line-content .tm-line-row .tm-line-col .tm-lines .tm-header h5.tm-line-kj {
  background-color: #e0115f;
}

.block-school-map-sg .block-content .tm-header i.color-kj,
.block-school-map-sg .block-content .tm-line-content .tm-line-row .tm-line-col .tm-lines .tm-station.color-kj:before,
.block-school-map-sg .block-content .station-code.color-kj {
  color: #e0115f;
}

.block-school-map-sg .block-content .tm-line-content .tm-line-row .tm-line-col .tm-lines .tm-station.color-kj {
  border-top-color: #e0115f;
}

.block-school-map-sg .block-content .trainmap-form .selection .checkbox input:checked + label.tm-line-ag,
.block-school-map-sg .block-content .tm-line-content .tm-line-row .tm-line-col .tm-lines .tm-header h5.tm-line-ag {
  background-color: #fe8e10;
}

.block-school-map-sg .block-content .tm-header i.color-ag,
.block-school-map-sg .block-content .tm-line-content .tm-line-row .tm-line-col .tm-lines .tm-station.color-ag:before,
.block-school-map-sg .block-content .station-code.color-ag {
  color: #fe8e10;
}

.block-school-map-sg .block-content .tm-line-content .tm-line-row .tm-line-col .tm-lines .tm-station.color-ag {
  border-top-color: #fe8e10;
}

.block-school-map-sg .block-content .trainmap-form .selection .checkbox input:checked + label.tm-line-sp,
.block-school-map-sg .block-content .tm-line-content .tm-line-row .tm-line-col .tm-lines .tm-header h5.tm-line-sp {
  background-color: #721422;
}

.block-school-map-sg .block-content .tm-header i.color-sp,
.block-school-map-sg .block-content .tm-line-content .tm-line-row .tm-line-col .tm-lines .tm-station.color-sp:before,
.block-school-map-sg .block-content .station-code.color-sp {
  color: #721422;
}

.block-school-map-sg .block-content .tm-line-content .tm-line-row .tm-line-col .tm-lines .tm-station.color-sp {
  border-top-color: #721422;
}

.block-school-map-sg .block-content .trainmap-form .selection .checkbox input:checked + label.tm-line-mr,
.block-school-map-sg .block-content .tm-line-content .tm-line-row .tm-line-col .tm-lines .tm-header h5.tm-line-mr {
  background-color: #7dba00;
}

.block-school-map-sg .block-content .tm-header i.color-mr,
.block-school-map-sg .block-content .tm-line-content .tm-line-row .tm-line-col .tm-lines .tm-station.color-mr:before,
.block-school-map-sg .block-content .station-code.color-mr {
  color: #7dba00;
}

.block-school-map-sg .block-content .tm-line-content .tm-line-row .tm-line-col .tm-lines .tm-station.color-mr {
  border-top-color: #7dba00;
}

.block-school-map-sg .block-content .trainmap-form .selection .checkbox input:checked + label.tm-line-rkf,
.block-school-map-sg .block-content .tm-line-content .tm-line-row .tm-line-col .tm-lines .tm-header h5.tm-line-rkf {
  background-color: #ff5722;
}

.block-school-map-sg .block-content .tm-header i.color-rkf,
.block-school-map-sg .block-content .tm-line-content .tm-line-row .tm-line-col .tm-lines .tm-station.color-rkf:before,
.block-school-map-sg .block-content .station-code.color-rkf {
  color: #ff5722;
}

.block-school-map-sg .block-content .tm-line-content .tm-line-row .tm-line-col .tm-lines .tm-station.color-rkf {
  border-top-color: #ff5722;
}

.block-school-map-sg .block-content .trainmap-form .selection .checkbox input:checked + label.tm-line-sbk,
.block-school-map-sg .block-content .tm-line-content .tm-line-row .tm-line-col .tm-lines .tm-header h5.tm-line-sbk {
  background-color: #008000;
}

.block-school-map-sg .block-content .tm-header i.color-sbk,
.block-school-map-sg .block-content .tm-line-content .tm-line-row .tm-line-col .tm-lines .tm-station.color-sbk:before,
.block-school-map-sg .block-content .station-code.color-sbk {
  color: #008000;
}

.block-school-map-sg .block-content .tm-line-content .tm-line-row .tm-line-col .tm-lines .tm-station.color-sbk {
  border-top-color: #008000;
}

.block-school-map-sg .block-content .trainmap-form .selection .checkbox input:checked + label.tm-line-ke,
.block-school-map-sg .block-content .tm-line-content .tm-line-row .tm-line-col .tm-lines .tm-header h5.tm-line-ke {
  background-color: #800080;
}

.block-school-map-sg .block-content .tm-header i.color-ke,
.block-school-map-sg .block-content .tm-line-content .tm-line-row .tm-line-col .tm-lines .tm-station.color-ke:before,
.block-school-map-sg .block-content .station-code.color-ke {
  color: #800080;
}

.block-school-map-sg .block-content .tm-line-content .tm-line-row .tm-line-col .tm-lines .tm-station.color-ke {
  border-top-color: #800080;
}

.block-school-map-sg .block-content .trainmap-form .selection .checkbox input:checked + label.tm-line-kt,
.block-school-map-sg .block-content .tm-line-content .tm-line-row .tm-line-col .tm-lines .tm-header h5.tm-line-kt {
  background-color: #139593;
}

.block-school-map-sg .block-content .tm-header i.color-kt,
.block-school-map-sg .block-content .tm-line-content .tm-line-row .tm-line-col .tm-lines .tm-station.color-kt:before,
.block-school-map-sg .block-content .station-code.color-kt {
  color: #139593;
}

.block-school-map-sg .block-content .tm-line-content .tm-line-row .tm-line-col .tm-lines .tm-station.color-kt {
  border-top-color: #139593;
}

.block-school-map-sg .block-content .trainmap-form .selection .checkbox input:checked + label.tm-line-ka,
.block-school-map-sg .block-content .tm-line-content .tm-line-row .tm-line-col .tm-lines .tm-header h5.tm-line-ka {
  background-color: #dc241f;
}

.block-school-map-sg .block-content .tm-header i.color-ka,
.block-school-map-sg .block-content .tm-line-content .tm-line-row .tm-line-col .tm-lines .tm-station.color-ka:before,
.block-school-map-sg .block-content .station-code.color-ka {
  color: #dc241f;
}

.block-school-map-sg .block-content .tm-line-content .tm-line-row .tm-line-col .tm-lines .tm-station.color-ka {
  border-top-color: #dc241f;
}

.block-school-map-sg .block-content .trainmap-form .selection .checkbox input:checked + label.tm-line-kd,
.block-school-map-sg .block-content .tm-line-content .tm-line-row .tm-line-col .tm-lines .tm-header h5.tm-line-kd {
  background-color: #dc241f;
}

.block-school-map-sg .block-content .tm-header i.color-kd,
.block-school-map-sg .block-content .tm-line-content .tm-line-row .tm-line-col .tm-lines .tm-station.color-kd:before,
.block-school-map-sg .block-content .station-code.color-kd {
  color: #dc241f;
}

.block-school-map-sg .block-content .tm-line-content .tm-line-row .tm-line-col .tm-lines .tm-station.color-kd {
  border-top-color: #dc241f;
}

.block-school-map-sg .block-content .trainmap-form .selection .checkbox input:checked + label.tm-line-kb,
.block-school-map-sg .block-content .tm-line-content .tm-line-row .tm-line-col .tm-lines .tm-header h5.tm-line-kb {
  background-color: #1964b7;
}

.block-school-map-sg .block-content .tm-header i.color-kb,
.block-school-map-sg .block-content .tm-line-content .tm-line-row .tm-line-col .tm-lines .tm-station.color-kb:before,
.block-school-map-sg .block-content .station-code.color-kb {
  color: #1964b7;
}

.block-school-map-sg .block-content .tm-line-content .tm-line-row .tm-line-col .tm-lines .tm-station.color-kb {
  border-top-color: #1964b7;
}

.block-school-map-sg .block-content .trainmap-form .selection .checkbox input:checked + label.tm-line-kc,
.block-school-map-sg .block-content .tm-line-content .tm-line-row .tm-line-col .tm-lines .tm-header h5.tm-line-kc {
  background-color: #1964b7;
}

.block-school-map-sg .block-content .tm-header i.color-kc,
.block-school-map-sg .block-content .tm-line-content .tm-line-row .tm-line-col .tm-lines .tm-station.color-kc:before,
.block-school-map-sg .block-content .station-code.color-kc {
  color: #1964b7;
}

.block-school-map-sg .block-content .tm-line-content .tm-line-row .tm-line-col .tm-lines .tm-station.color-kc {
  border-top-color: #1964b7;
}

.block-school-map-sg .block-content .school-form {
  background-color: #f4f7fa;
  flex: 0 0 30%;
  padding: 3rem 1.5rem;
  min-width: 250px;
  max-width: 250px;
}

.block-school-map-sg .block-content .school-form .dropdown {
  margin-bottom: 10px;
}

.block-school-map-sg .block-content .school-form .dropdown .button {
  width: 100%;
  text-align: left;
  padding: 5px 10px;
}

.block-school-map-sg .block-content .school-form .dropdown .button i {
  position: absolute;
  right: 10px;
  bottom: 8px;
}

.block-school-map-sg .block-content .school-form .dropdown .button-schoolmap {
  background-color: white;
  border: 1px solid #979797;
}

.block-school-map-sg .block-content .school-form .dropdown .button-schoolmap:active {
  box-shadow: none;
}

.block-school-map-sg .block-content .school-form .dropdown ul.dropdown-menu {
  border-radius: 0;
  box-shadow: 5px 5px 12px 2px rgba(0, 0, 0, 0.5);
  margin: 0 10px;
  right: 0;
  left: 0;
}

.block-school-map-sg .block-content .school-form .dropdown ul.dropdown-menu > li > a {
  padding: 5px 10px;
}

.block-school-map-sg .block-content .school-form hr {
  margin-left: -15px;
  margin-right: -15px;
}

.block-school-map-sg .block-content .school-form .selection {
  display: flex;
  flex-flow: row wrap;
  justify-content: flex-start;
}

.block-school-map-sg .block-content .school-form .selection .checkbox.school {
  flex: 1 1 50%;
}

.block-school-map-sg .block-content .school-form .selection .checkbox.school label:before {
  content: "";
  border: 1px solid #bfbebe;
  background-color: white;
  width: 18px;
  height: 18px;
  display: block;
  border-radius: 5px;
}

.block-school-map-sg .block-content .school-form .selection .checkbox.school input:checked + label:before {
  background-color: white;
  border: 1px solid #bfbebe;
}

.block-school-map-sg .block-content .school-form .selection .checkbox.school input:checked + label:after {
  content: "";
  background-color: #ff5122;
  width: 12px;
  height: 12px;
  border-radius: 3px;
  position: absolute;
  left: 3px;
  top: 3px;
}

.block-school-map-sg .block-content .school-form .selection .checkbox.school label {
  padding-left: 2.2rem;
}

.block-school-map-sg .block-content .school-form .selection .checkbox.school label span {
  font-size: 1.2rem;
  color: #262626;
}

.block-school-map-sg .block-content .school-content {
  flex: 1 1 70%;
  display: flex;
  flex-flow: column nowrap;
  box-shadow: inset 20px 0px 20px -20px grey;
  overflow: hidden;
}

.block-school-map-sg .block-content .school-content .title {
  padding: 30px 0 10px 40px;
  font-size: 16px;
}

.block-school-map-sg .block-content .school-content .school-row {
  overflow-y: scroll;
  overflow-x: hidden;
  width: calc(100% + 20px);
  display: flex;
  flex-flow: column nowrap;
  height: 100%;
  /* Safari 4.0 - 8.0 */
  /* Standard syntax */
}

.block-school-map-sg .block-content .school-content .school-row .school-result {
  padding-left: 4rem;
  display: none;
}

.block-school-map-sg .block-content .school-content .school-row .school-result.active {
  display: block;
}

.block-school-map-sg .block-content .school-content .school-row .school-result .showing .desc {
  margin-right: 1.5rem;
  display: inline-block;
}

.block-school-map-sg .block-content .school-content .school-row .school-result .clear-filter {
  text-decoration: underline;
  font-weight: 500;
  display: inline-block;
  cursor: pointer;
}

.block-school-map-sg .block-content .school-content .school-row .school-empty {
  height: 100%;
  display: none;
  flex-flow: column nowrap;
  justify-content: center;
}

.block-school-map-sg .block-content .school-content .school-row .school-empty img.img-responsive {
  max-width: 42%;
  margin: 0 auto;
}

.block-school-map-sg .block-content .school-content .school-row .school-empty.active {
  display: flex;
}

.block-school-map-sg .block-content .school-content .school-row .sm-school-col {
  padding-left: 4rem;
  display: none;
  box-shadow: inset 0px -20px 20px -20px grey;
}

.block-school-map-sg .block-content .school-content .school-row .sm-school-col .sm-state {
  display: flex;
  flex-flow: row wrap;
  justify-content: flex-start;
}

.block-school-map-sg .block-content .school-content .school-row .sm-school-col .sm-state .sm-header {
  flex-basis: 100%;
  padding-bottom: 5px;
}

.block-school-map-sg .block-content .school-content .school-row .sm-school-col .sm-state .sm-header i {
  font-size: 18px;
  padding-right: 8px;
  vertical-align: text-bottom;
}

.block-school-map-sg .block-content .school-content .school-row .sm-school-col .sm-state .sm-header h5 {
  background-color: #455a64;
  width: fit-content;
  color: white;
  padding: 2px 25px;
  font-weight: 400;
  border-radius: 20px;
  display: inline-block;
}

.block-school-map-sg .block-content .school-content .school-row .sm-school-col .sm-state .sm-header .sm-state-label {
  display: inline-block;
}

.block-school-map-sg .block-content .school-content .school-row .sm-school-col .sm-state .sm-header .sm-state-label input[type="checkbox"] {
  display: none;
}

.block-school-map-sg .block-content .school-content .school-row .sm-school-col .sm-state .sm-header .sm-state-label label {
  padding-left: 0px;
}

.block-school-map-sg .block-content .school-content .school-row .sm-school-col .sm-state .sm-header .sm-state-label.checkbox label:before {
  content: "";
  width: 18px;
  height: 18px;
  display: inline-block;
  border: 1px solid #bfbebe;
  border-radius: 5px;
  vertical-align: top;
  margin-right: 5px;
}

.block-school-map-sg .block-content .school-content .school-row .sm-school-col .sm-state .sm-header .sm-state-label.checkbox input:checked + label:after {
  content: "";
  width: 12px;
  height: 12px;
  display: block;
  background-color: #ff5122;
  border-radius: 3px;
  position: absolute;
  top: 3px;
  left: 3px;
}

.block-school-map-sg .block-content .school-content .school-row .sm-school-col .sm-state .sm-school {
  flex: 1 1 15%;
  min-width: 250px;
  max-width: 250px;
  display: none;
}

.block-school-map-sg .block-content .school-content .school-row .sm-school-col .sm-state .sm-school:last-child {
  border-color: transparent;
}

.block-school-map-sg .block-content .school-content .school-row .sm-school-col .sm-state .sm-school .sm-school-label {
  padding: 5px 0;
  font-size: 12px;
}

.block-school-map-sg .block-content .school-content .school-row .sm-school-col .sm-state .sm-school .sm-school-label input[type="checkbox"] {
  display: none;
}

.block-school-map-sg .block-content .school-content .school-row .sm-school-col .sm-state .sm-school .sm-school-label label {
  padding-left: 0px;
}

.block-school-map-sg .block-content .school-content .school-row .sm-school-col .sm-state .sm-school .sm-school-label.checkbox label:before {
  content: "";
  width: 18px;
  height: 18px;
  display: inline-block;
  border: 1px solid #bfbebe;
  border-radius: 5px;
  vertical-align: top;
}

.block-school-map-sg .block-content .school-content .school-row .sm-school-col .sm-state .sm-school .sm-school-label.checkbox input:checked + label:after {
  content: "";
  width: 12px;
  height: 12px;
  display: block;
  background-color: #ff5122;
  border-radius: 3px;
  position: absolute;
  top: 8px;
  left: 3px;
}

.block-school-map-sg .block-content .school-content .school-row .sm-school-col .sm-state .sm-school .sm-school-label.checkbox:hover {
  background-color: #f5f5f5;
  border-radius: 5px;
}

.block-school-map-sg .block-content .school-content .school-row .sm-school-col .sm-state .sm-school .sm-school-label .sm-school-name {
  font-weight: 600;
  max-width: 220px;
  display: inline-block;
}

.block-school-map-sg .block-content .school-content .school-row .sm-school-col .sm-state .sm-school .sm-school-label .sm-school-name .school-type {
  font-weight: 400;
}

.block-school-map-sg .block-content .school-content .school-row .sm-school-col .sm-state .sm-school.active {
  display: block;
}

.block-school-map-sg .block-content .school-content .school-row .sm-school-col.active {
  display: block;
  animation: slide-left .2s ease-in-out;
  min-height: 100%;
}

@-webkit-keyframes slide-left {
  0% {
    opacity: 0;
    -webkit-transform: translateX(50%);
  }
  100% {
    opacity: 1;
    -webkit-transform: translateX(0);
  }
}

@keyframes slide-left {
  0% {
    opacity: 0;
    -webkit-transform: translateX(50%);
  }
  100% {
    opacity: 1;
    -webkit-transform: translateX(0);
  }
}

.block-school-map-sg .block-content .school-content .sm-actions {
  width: 100%;
  padding: 4px;
  margin: auto;
}

.block-school-map-sg .block-content .school-content .sm-actions .actions {
  margin: auto;
  width: fit-content;
}

.block-school-map-sg .block-content .school-content .sm-actions .actions a {
  display: none;
}

.block-school-map-sg .block-content .school-content .sm-actions .actions > * {
  display: inline-block;
}

.block-school-map-sg .block-content .school-content .sm-actions i {
  font-size: 2rem;
  padding-right: 8px;
  vertical-align: text-bottom;
  cursor: pointer;
}

.block-school-map-sg .block-content .school-content .sm-actions .button {
  cursor: not-allowed;
  background-color: rgba(181, 181, 181, 0.8);
  color: white;
  width: 200px;
  border-radius: 100px;
  font-size: 14px;
  font-weight: 300;
  padding: 8px 30px;
}

.block-school-map-sg .block-content .school-content .sm-actions .button.active {
  cursor: pointer;
  background-color: #ff5122;
}

@media (max-width: 1024px) {
  .block-school-map-sg .block-content {
    height: calc(100vh - 50px);
  }
}

@media (max-width: 676px) {
  .block-school-map-sg .block-content {
    flex-flow: column nowrap;
    height: auto;
  }
  .block-school-map-sg .block-content .school-form {
    max-width: 100%;
    min-width: 100%;
    padding: 1rem .8rem 1.5rem .8rem;
  }
  .block-school-map-sg .block-content .school-form hr {
    display: none;
  }
  .block-school-map-sg .block-content .school-form .schoolmap-filter {
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
  }
  .block-school-map-sg .block-content .school-form .schoolmap-filter .dropdown {
    flex: 0 1 49%;
  }
  .block-school-map-sg .block-content .school-form .schoolmap-filter .dropdown:last-child {
    flex: 1 1 100%;
  }
  .block-school-map-sg .block-content .school-content {
    box-shadow: none;
    overflow: visible;
  }
  .block-school-map-sg .block-content .school-content .title {
    padding: .8rem;
  }
  .block-school-map-sg .block-content .school-content .school-row .school-result {
    padding-left: 8px;
  }
  .block-school-map-sg .block-content .school-content .school-row .school-result .clear-filter {
    display: block;
  }
  .block-school-map-sg .block-content .school-content .school-row .sm-school-col {
    padding: 0 .8rem;
  }
  .block-school-map-sg .block-content .school-content .sm-actions {
    position: sticky;
    bottom: 0;
    background-color: #f8f8f8;
    border-top: 1px solid #cacaca;
  }
  .block-school-map-sg .block-content .school-content .sm-actions .actions {
    position: relative;
    width: 100%;
    padding: 0 20px;
  }
  .block-school-map-sg .block-content .school-content .sm-actions .actions > div:first-of-type {
    width: 100%;
    text-align: center;
    padding-bottom: 5px;
  }
  .block-school-map-sg .block-content .school-content .sm-actions .actions button {
    margin: 0;
    width: 100%;
  }
  .block-school-map-sg .block-content .school-content .sm-actions .actions a {
    display: block;
    position: absolute;
    top: -25px;
    right: 20px;
    border-radius: 50%;
    background: #46B8DA;
    color: white;
    text-align: center;
    width: 35px;
    height: 35px;
    line-height: 32px;
    cursor: pointer;
  }
  .block-school-map-sg .block-content .school-content .sm-actions .actions a i {
    padding: 0;
  }
}

.block-school-explore {
  margin-top: 0 !important;
}

.block-school-explore .block-title, .block-school-explore .area-outlook-detail h3.block-title, .area-outlook-detail .block-school-explore h3.block-title,
.block-school-explore .page-project-outlook h3.block-title, .page-project-outlook .block-school-explore h3.block-title {
  font-size: 2.8rem;
  text-align: center;
}

.block-school-explore .tt-menu {
  width: 100%;
  max-height: 42rem;
  margin-top: 0.1rem;
  overflow: auto;
  box-shadow: 0 0 0 0.1rem #dededf, 0 0.4rem 0.5rem -0.15rem rgba(37, 37, 37, 0.1);
}

.block-school-explore .tt-menu:after {
  border-bottom-color: #dededf;
}

.block-school-explore .se-form .input label {
  padding-left: 1.5rem;
  padding-right: 1.5rem;
}

.block-school-explore .se-form .input input {
  padding-left: 1.5rem;
  padding-right: 1.5rem;
}

.block-school-explore .se-bar,
.block-school-explore .se-content {
  max-width: 72rem;
  margin-left: auto;
  margin-right: auto;
  padding-left: 1.5rem;
  padding-right: 1.5rem;
}

.block-school-explore .se-bar .input-group-btn .button {
  font-size: 1.4rem;
  border-radius: 0 0.3rem 0.3rem 0;
}

.block-school-explore .se-bar .input-group .input {
  border-right: 0;
  border-radius: 0.3rem 0 0 0.3rem;
}

.block-school-explore .se-content .input-group-btn .button {
  padding-top: 1.4rem;
  padding-bottom: 1.4rem;
  font-size: 1.3rem;
  border-radius: 0 0.3rem 0.3rem 0;
}

.block-school-explore .se-content .input-group .input {
  min-height: 4.8rem;
  border-right: 0;
  border-radius: 0.3rem 0 0 0.3rem;
}

.block-school-explore .se-content .input-group .input label {
  line-height: 4.6rem;
}

.block-school-explore .se-content .input-group .input input {
  height: 4.6rem;
}

.block-school-explore .se-actions {
  width: 100%;
  padding: 1rem;
  background-color: rgba(255, 255, 255, 0.9);
  text-align: center;
  position: fixed;
  left: 0;
  bottom: 0;
  z-index: 5;
  box-shadow: 0 -0.6rem 1rem -0.2rem rgba(37, 37, 37, 0.05), 0 -0.1rem 0 0 rgba(37, 37, 37, 0.1);
}

.block-school-explore.search-integrated {
  width: 100%;
  height: 100%;
  padding: 4rem 4rem 6.5rem;
  background-color: #ffffff;
  opacity: 0;
  visibility: hidden;
  overflow-x: auto;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 100;
  transition: all 200ms ease-in-out;
}

.block-school-explore.search-integrated.active {
  opacity: 1;
  visibility: visible;
}

@media (max-width: 767px) {
  .block-newsletter-signup .block-content {
    padding: 2.2em 1.5em;
  }
  .block-newsletter-signup .nls-title {
    font-size: 1.7em;
  }
  .block-mortgage-calculator {
    padding: 1.5rem;
  }
  .block-train-map .tm-line-row {
    display: block;
    max-height: none;
  }
  .block-train-map .tm-line-col {
    width: 100%;
  }
}

@media (min-width: 768px) and (max-width: 1199px) {
  .block-train-map {
    padding: 3rem 5rem;
  }
  .block-train-map .block-titlte {
    font-size: 1.8rem;
  }
  .block-train-map .tm-line-row {
    max-height: 430rem;
  }
  .block-train-map .tm-line-col {
    width: 50%;
  }
}

/* Get More */
.get-more .block-title, .get-more .area-outlook-detail h3.block-title, .area-outlook-detail .get-more h3.block-title,
.get-more .page-project-outlook h3.block-title, .page-project-outlook .get-more h3.block-title {
  text-align: center;
}

.get-more .icon-box {
  display: block;
  min-height: 12rem;
  color: #aaaaaa;
  text-decoration: none;
  text-align: center;
}

.get-more .icon-box figure {
  padding-top: 1em;
  font-size: 1.2em;
}

.get-more .icon-box h5 {
  margin-top: 1.2em;
  margin-bottom: 0;
  color: inherit;
  font-size: 1em;
  font-weight: 500;
  text-transform: uppercase;
  line-height: 1.1;
  letter-spacing: 0.08em;
}

.get-more .icon-box:hover {
  color: #252525;
}

@media (min-width: 768px) {
  .get-more .block-title, .get-more .area-outlook-detail h3.block-title, .area-outlook-detail .get-more h3.block-title,
  .get-more .page-project-outlook h3.block-title, .page-project-outlook .get-more h3.block-title {
    font-size: 3rem;
  }
}

/* Explorer TEP */
.explorer-tep .block-title, .explorer-tep .area-outlook-detail h3.block-title, .area-outlook-detail .explorer-tep h3.block-title,
.explorer-tep .page-project-outlook h3.block-title, .page-project-outlook .explorer-tep h3.block-title {
  color: inherit;
}

.explorer-tep h5 {
  font-size: 1.3rem;
  font-weight: 500;
  color: #ffffff;
}

@media (min-width: 768px) {
  .explorer-tep .block-title, .explorer-tep .area-outlook-detail h3.block-title, .area-outlook-detail .explorer-tep h3.block-title,
  .explorer-tep .page-project-outlook h3.block-title, .page-project-outlook .explorer-tep h3.block-title {
    font-size: 3rem;
  }
}

body.admin-menu.adminimal-menu .block-train-map-sg .block-content {
  height: calc(100vh - 101px);
}

@media (max-width: 1024px) {
  body.admin-menu.adminimal-menu .block-train-map-sg .block-content {
    height: calc(100vh - 79px);
  }
}

@media (max-width: 767px) {
  body.admin-menu.adminimal-menu .block-train-map-sg .block-content {
    height: calc(100vh - 50px);
  }
}

/* ---------------------------------------- */
/*  Custom:Front
/* ---------------------------------------- */
/* Layout */
.front-wrapper .hero-tron {
  display: table;
  width: 100%;
  min-height: 52rem;
  padding: 0;
}

.front-wrapper .hero-tron > .container {
  display: table-cell;
  width: auto;
  padding-top: 20vh;
  vertical-align: top;
}

.front-wrapper .hero-bg {
  background-color: #6092c3;
}

.front-wrapper .hero-gradient {
  background-image: linear-gradient(180deg, rgba(37, 37, 37, 0.3) 80%, rgba(37, 37, 37, 0.8) 100%);
}

.front-wrapper .hero.sticky {
  position: absolute;
}

@media (max-width: 767px) {
  .front-wrapper .hero-tron {
    min-height: auto;
  }
  .front-wrapper .hero-tron > .container {
    padding-top: 0;
    padding-bottom: 0;
  }
}

@media (min-width: 768px) and (max-width: 1199px) {
  .front-wrapper .main .container {
    width: 64.6rem;
  }
}

@media (min-width: 1200px) {
  .front-wrapper .hero {
    position: fixed;
    top: 7.2rem;
    left: 0;
    width: calc(100% - 64.6rem);
    height: calc(100vh - 7.2rem);
    transform: translate(0, 0);
  }
  .front-wrapper .hero-tron {
    height: 100%;
    min-height: auto;
  }
  .front-wrapper .main {
    max-width: 64.6rem;
    margin-left: auto;
    margin-right: 0;
    position: relative;
  }
  .front-wrapper .main .container {
    width: 100%;
    max-width: 80rem;
  }
  .front .footer {
    position: relative;
    z-index: 2;
  }
}

.front-wrapper .premium-landscape > .container {
  background-color: rgba(0, 0, 0, 0.85);
}

.front-wrapper .premium-landscape .hero-bg {
  top: 11rem;
  z-index: 1;
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  background-color: transparent;
}

.front-wrapper .premium-landscape .hero-bg-clone {
  display: block;
  background-repeat: repeat-x;
  background-size: contain;
  z-index: 0;
  filter: grayscale(90%) blur(0.2rem);
}

.front-wrapper .premium-landscape [data-link] {
  user-select: none;
  cursor: pointer;
}

@media (max-width: 767px) {
  .front-wrapper .premium-landscape {
    min-height: 32rem;
  }
  .front-wrapper .premium-landscape > .container {
    display: block;
    padding-left: 0;
    padding-right: 0;
  }
  .front-wrapper .premium-landscape .hero-apps, .front-wrapper .premium-landscape .hero-social {
    display: none;
  }
  .front-wrapper .premium-landscape .hero-bg {
    top: 6rem;
  }
  .front-wrapper .premium-landscape .hero-gradient {
    display: none;
  }
  .front-wrapper .premium-landscape .search-fakebar {
    margin: 0.6rem auto;
  }
}

@media (min-width: 768px) {
  .front-wrapper .premium-landscape > .container {
    display: block;
    padding: 0;
  }
  .front-wrapper .premium-landscape .search-wrapper {
    max-width: none;
    padding: 1.7rem 1.5rem 1rem;
    border-radius: 0;
  }
  .front-wrapper .premium-landscape .search-header {
    display: none;
  }
  .front-wrapper .premium-landscape .hero-apps, .front-wrapper .premium-landscape .hero-social {
    display: none;
  }
}

@media (min-width: 768px) and (max-width: 1199px) {
  .front-wrapper .premium-landscape .search-wrapper {
    padding-top: 1.8rem;
  }
  .front-wrapper .premium-landscape .hero-bg {
    top: 10.5rem;
  }
}

/* Search Hero */
.search-hero .search-wrapper {
  padding: 3rem 5rem;
  background-color: rgba(46, 58, 82, 0.7);
}

.search-hero .search-header h2 {
  font-size: 2.5rem;
}

.search-hero .search-footer {
  padding-top: 1rem;
}

.search-hero .search-footer .search-link > .item + .item:before {
  content: '|';
  vertical-align: baseline;
}

.search-hero .search-footer .ion-icon {
  margin-left: 0.2em;
  margin-right: 0.2em;
  font-size: 2em;
  position: relative;
  top: -0.075em;
  vertical-align: middle;
}

.search-hero .search-footer a {
  color: #fff;
  text-decoration: none;
}

.search-hero .search-footer a:hover {
  color: #3b839c;
}

.search-hero .i-form .input,
.search-hero .i-form .select {
  min-height: auto;
  background-color: transparent;
  border: 0.1rem solid #fff;
  border-radius: 0.2rem;
}

.search-hero .i-form .input label,
.search-hero .i-form .select label {
  color: #fff;
  line-height: 4rem;
}

.search-hero .i-form .input input,
.search-hero .i-form .select select {
  min-height: 4rem;
  color: #fff;
}

.search-hero .i-form .select:after {
  border-color: #fff transparent transparent;
}

.search-hero .i-form .select select {
  color: #fff;
  line-height: normal;
}

.search-hero .i-form .select option {
  color: #2e3a52;
}

.search-hero .i-form .checkbox label,
.search-hero .i-form .radio label {
  color: #fff;
  font-weight: 600;
  text-transform: uppercase;
}

.search-hero .i-form .checkbox label:before,
.search-hero .i-form .radio label:before {
  background-color: transparent;
  border-width: 0.2rem;
}

.search-hero .i-form .button.theme {
  background-color: #3b839c;
  border-color: #3b839c;
  border-radius: 0.2rem;
}

.search-hero .twitter-typeahead .tt-menu {
  width: 100%;
  color: #252525;
}

.search-hero .twitter-typeahead .empty-message {
  font-size: 1.4rem;
}

@media (max-width: 479px) {
  .search-hero .search-wrapper {
    padding-left: 1.8rem;
    padding-right: 1.8rem;
  }
  .search-hero .search-header h2 {
    margin-top: 0;
    font-size: 1.7rem;
  }
}

@media (min-width: 1600px) {
  .search-hero .search-header h2 {
    font-size: 3.6rem;
  }
}

/* Search Tiny */
.search-tiny .search-fakebar-wrapper {
  margin: 12vh auto 10vh;
  text-align: center;
  font-weight: 400;
}

.search-tiny .search-fakebar-wrapper h4 {
  font-size: 1.5rem;
}

.search-tiny .search-fakebar-wrapper h5 {
  font-size: 0.8rem;
}

.search-tiny .search-fakebar {
  max-width: 29rem;
  margin: 0 auto;
  padding: 1.4rem 1.5rem 1.4rem 3.5rem;
  background-color: #ffffff;
  font-size: small;
  line-height: 2rem;
  text-decoration: none !important;
  color: #252525;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  position: relative;
  border-radius: 0.3rem;
}

.search-tiny .search-fakebar .ion-icon {
  font-size: 2rem;
  margin-top: -1rem;
  position: absolute;
  top: 50%;
  left: 1.5rem;
}

.search-tiny .search-wrapper {
  max-width: 64.6rem;
  margin-left: auto;
  margin-right: auto;
  padding: 1.5rem 2rem 1rem;
  background-color: transparent;
  font-family: "Arial", sans-serif;
  border-radius: 0.5rem;
}

.search-tiny .search-header h3 {
  margin-bottom: 0.8em;
  font-size: 2rem;
  font-weight: 400;
  line-height: 1;
  letter-spacing: 0.02em;
  text-align: center;
}

.search-tiny .search-form {
  text-align: left;
}

.search-tiny .search-form .onebar .input-group {
  width: 100%;
  border: 0;
  background-color: #ffffff;
  color: #252525;
  border-radius: 0.3rem 0 0 0.3rem;
}

.search-tiny .search-form .onebar .input-group .input {
  border: 0;
}

.search-tiny .search-form .onebar .input-group .input label,
.search-tiny .search-form .onebar .input-group .input input {
  padding-left: 1rem;
  padding-right: 1rem;
  color: #252525;
  min-height: 4.2rem;
  line-height: 4.2rem;
}

.search-tiny .search-form .onebar .input-group .input .ion-icon {
  font-size: 1.4em;
  position: relative;
  bottom: -0.06em;
}

.search-tiny .search-form .onebar .input-group .button {
  margin: 0;
  padding: 1.1rem 1rem;
  border: 0;
  border-right: 0.1rem solid #dedede;
  border-radius: 0;
  font-size: 1.4rem;
}

.search-tiny .search-form .onebar .input-group .button .ion-icon {
  font-size: 1.5rem;
  line-height: 1.2;
  vertical-align: middle;
}

.search-tiny .search-form .onebar .input-group .button .ion-navicon {
  display: none;
}

.search-tiny .search-form .onebar .actions .button {
  margin-top: 0;
  padding: 1.1rem 0.5rem;
  border: 0;
  border-radius: 0 0.3rem 0.3rem 0;
}

.search-tiny .search-form .herotiny-dropdown {
  width: 100%;
  margin: 0;
  padding: 0;
  border: 0;
  border-top: 0.1rem solid #dededf;
  border-radius: 0;
  box-shadow: 0 0.2rem 1rem -0.1rem rgba(37, 37, 37, 0.05) inset, 0 0.5rem 1rem 0 rgba(37, 37, 37, 0.2);
}

.search-tiny .search-form .herotiny-dropdown > li {
  padding: 1rem 1.2rem;
  cursor: pointer;
}

.search-tiny .search-form .herotiny-dropdown > li:hover {
  background-color: #3b839c;
  color: #ffffff;
}

.search-tiny .search-form .maps-link {
  float: right;
  padding-top: 0.3rem;
  font-size: 1.3rem;
}

.search-tiny .search-form .maps-link .ion-icon {
  display: inline-block;
  margin-top: -0.2rem;
  font-size: 2rem;
  position: relative;
  top: 0.2rem;
}

.search-tiny .search-form .maps-link a:focus, .search-tiny .search-form .maps-link a:hover {
  text-decoration: none;
  color: #dedede;
}

.search-tiny .search-form .maps-link span {
  display: none;
}

.search-tiny .search-footer {
  margin: 0.75rem -2rem -2rem;
  padding-top: 0;
  padding-bottom: 0.5rem;
}

@media (max-width: 767px) {
  .search-tiny .search-wrapper {
    padding: 5rem 1.5rem 6.5rem;
    background-color: #ffffff;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 100;
    opacity: 0;
    visibility: hidden;
    transition: all 250ms ease-in-out;
    transform: translateY(12.5%);
    border-radius: 0.5rem;
    box-shadow: 0 0.2rem 0 0.2rem #252525;
  }
  .search-tiny .search-dismiss {
    padding: 1rem 1.5rem;
    font-size: 2em;
    color: #252525;
    line-height: 0;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
  }
  .search-tiny .search-header {
    display: none;
    color: #252525;
  }
  .search-tiny .search-form {
    height: 100%;
    overflow-x: hidden;
    overflow-y: auto;
  }
  .search-tiny .search-form .onebar .radios label {
    color: #252525;
  }
  .search-tiny .search-form .onebar .radios label:before {
    border-width: 0.1rem;
    border-color: #252525;
  }
  .search-tiny .search-form .onebar .radios label:after {
    background-color: #252525;
  }
  .search-tiny .search-form .onebar .input-group {
    background-color: transparent;
    border-radius: 0;
  }
  .search-tiny .search-form .onebar .input-group .input {
    border: 0.1rem solid #252525;
  }
  .search-tiny .search-form .onebar .input-group .input label,
  .search-tiny .search-form .onebar .input-group .input input {
    min-height: 4.6rem;
    line-height: 4.6rem;
  }
  .search-tiny .search-form .onebar .actions {
    padding: 0.9rem 1.5rem;
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 10;
  }
  .search-tiny .search-form .onebar .actions .button {
    border-radius: 0.3rem;
  }
  .search-tiny .search-form .minimal .select {
    margin-bottom: 1rem;
    border-color: #252525;
    color: #252525;
  }
  .search-tiny .search-form .minimal .select:after {
    display: none;
    border-color: #252525 transparent transparent;
  }
  .search-tiny .search-form .minimal .select select {
    min-height: 4.6rem;
    color: inherit;
  }
  .search-tiny .search-form .minimal .select.character {
    margin-top: 2.5rem;
  }
  .search-tiny .search-form .minimal .select.character:before {
    content: 'State';
    display: block;
    padding-bottom: 0.35em;
    font-weight: 600;
    line-height: 1.2;
    position: absolute;
    left: -0.2rem;
    bottom: 100%;
  }
  .search-tiny .search-form .minimal .select.character.area:before {
    content: 'Area';
  }
  .search-tiny .search-form .minimal .select.character + .character {
    margin-top: 3.5rem;
  }
  .search-tiny .search-form .minimal .select.numeric {
    float: left;
    width: 48.5%;
    margin-top: 2.5rem;
  }
  .search-tiny .search-form .minimal .select.numeric:before {
    content: 'Price Min';
    display: block;
    padding-bottom: 0.35em;
    font-weight: 600;
    line-height: 1.2;
    position: absolute;
    left: -0.2rem;
    bottom: 100%;
  }
  .search-tiny .search-form .minimal .select.numeric + .numeric {
    margin-left: 3%;
  }
  .search-tiny .search-form .minimal .select.numeric + .numeric:before {
    content: 'Price Max';
  }
  .search-tiny.active .search-wrapper {
    opacity: 1;
    visibility: visible;
    transform: translateY(0);
  }
}

@media (min-width: 768px) {
  .search-tiny .search-fakebar-wrapper, .search-tiny .search-fakebar {
    display: none;
  }
  .search-tiny .search-form .minimal {
    margin-left: -0.5rem;
    margin-right: -0.5rem;
  }
  .search-tiny .search-form .minimal > .i-col {
    padding-left: 0.5rem;
    padding-right: 0.5rem;
  }
  .search-tiny .search-form .minimal .select {
    display: inline-block;
    width: auto;
    border: 0;
    padding-right: 1.2rem;
    margin-right: 1.2rem;
  }
  .search-tiny .search-form .minimal .select:after {
    margin-top: -0.2rem;
    top: 50%;
    right: 0;
  }
  .search-tiny .search-form .minimal .select select {
    min-height: auto;
    line-height: normal;
    padding: 0.5rem 0;
  }
}

@media (min-width: 1200px) {
  .search-tiny .search-wrapper {
    max-width: none;
    padding: 2rem 2.5rem 1.3rem;
  }
  .search-tiny .search-header h3 {
    margin-bottom: 1em;
    font-size: 2.6rem;
  }
  .search-tiny .search-form .onebar .input-group .input label,
  .search-tiny .search-form .onebar .input-group .input input {
    min-height: 4.8rem;
    line-height: 4.8rem;
    font-size: 1.1em;
    width: 100%;
  }
  .search-tiny .search-form .onebar .input-group .button {
    padding: 1.4rem 1.8rem;
  }
  .search-tiny .search-form .onebar .input-group .button span {
    font-size: 1.2em;
    line-height: 1;
  }
  .search-tiny .search-form .onebar .actions .button {
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;
    font-size: 1.2em;
    line-height: 1.1;
  }
  .search-tiny .search-form .maps-link span {
    display: inline;
  }
}

/* Regular Homepage Block */
.block-front-navi .block-title, .block-front-navi .area-outlook-detail h3.block-title, .area-outlook-detail .block-front-navi h3.block-title,
.block-front-navi .page-project-outlook h3.block-title, .page-project-outlook .block-front-navi h3.block-title {
  margin-top: 0;
  margin-bottom: 0;
  padding-top: 2rem;
  padding-bottom: 2rem;
  line-height: 1;
  text-align: center;
}

.block-front-navi .nav-anchor {
  background-color: #969696;
}

.block-front-navi .nav-anchor:before, .block-front-navi .nav-anchor:after {
  content: '';
  display: table;
}

.block-front-navi .nav-anchor:after {
  clear: both;
}

.block-front-navi .nav-anchor > li {
  float: left;
  width: 33.33333%;
  border-left: 1px solid rgba(255, 255, 255, 0.5);
}

.block-front-navi .nav-anchor > li:first-child {
  border-left: 0;
}

.block-front-navi .nav-anchor > li > a {
  padding: 1rem;
  border: 0;
  background-color: #969696;
  color: #ffffff;
  font-weight: 400;
  line-height: 1.3;
  border-radius: 0;
}

.block-front-navi .nav-anchor > li > a:hover {
  background-color: #676d77;
}

.block-front-navi .nav-anchor > li.active > a {
  background-color: #3b839c;
  color: #ffffff;
}

.block-front-navi.nav-sticky .nav-anchor {
  width: 61.6rem;
  position: fixed;
  z-index: 9;
  transform: translate3d(0, 0, 0);
}

.block-front-navi .navi-icon {
  display: inline-block;
  width: 3rem;
  height: 3rem;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: 100%;
}

.block-front-navi .navi-icon.news {
  background-image: url("../img/navi/news-active.png");
}

.block-front-navi .navi-icon.listings {
  background-image: url("../img/navi/listings-active.png");
}

.block-front-navi .navi-icon.research {
  background-image: url("../img/navi/research-active.png");
}

@media (max-width: 767px) {
  .block-front-navi.nav-sticky .nav-anchor {
    width: 100%;
    left: 0;
  }
  .block-front-navi .navi-icon {
    float: left;
    width: 2rem;
    height: 2rem;
  }
  .block-front-navi .navi-text {
    padding-left: 2.7rem;
    line-height: 2rem;
    text-align: left;
  }
}

.block-editors-pick .i-row > .i-col {
  margin-bottom: 1.6rem;
}

.block-editors-pick .i-row > .col-sm-6 {
  max-height: 25rem;
}

.block-editors-pick .news-boxes {
  position: relative;
  padding-bottom: 5.8rem;
  border: 1px solid #dedede;
  box-shadow: 0 0.2rem 0.6rem -0.3rem rgba(37, 37, 37, 0.2);
}

.block-editors-pick .news-boxes + .news-boxes {
  margin-top: 1.6rem;
}

.block-editors-pick .news-cover {
  display: block;
}

.block-editors-pick .news-cover figure {
  height: 100%;
}

.block-editors-pick .news-cover img {
  width: 100%;
  height: auto;
}

.block-editors-pick .news-content {
  width: 100%;
  padding: 1.071em;
  background-color: #f7f6f6;
  position: absolute;
  left: 0;
  bottom: 0;
}

.block-editors-pick .news-category {
  display: none;
  margin-top: 0;
  margin-bottom: 0.8em;
  font-size: 0.714em;
  text-transform: uppercase;
  letter-spacing: 0.06em;
}

.block-editors-pick .news-category:after {
  content: '/';
  margin-left: 0.54em;
}

.block-editors-pick .news-title {
  margin-bottom: 0.833em;
  font-size: 1.143em;
}

.block-editors-pick .news-title > a {
  color: inherit;
}

.block-editors-pick .news-date {
  margin-top: 0.727em;
  font-size: 0.714em;
  line-height: 1.364em;
  text-transform: uppercase;
}

.block-editors-pick .news-boxes.no-image {
  padding-bottom: 0;
}

.block-editors-pick .news-boxes.no-image .news-cover {
  display: none;
}

.block-editors-pick .news-boxes.no-image .news-content {
  position: relative;
}

.block-editors-pick .news-boxes.no-image .news-title {
  height: 4.8rem;
  overflow: hidden;
}

.block-editors-pick .news-boxes.large .news-title {
  height: 6.5rem;
  margin-bottom: 0;
}

.block-editors-pick .news-boxes.highlight {
  border-color: #3b839c;
  box-shadow: 0 0.2rem 0.6rem -0.3rem rgba(59, 131, 156, 0.2);
}

.block-editors-pick .news-boxes.highlight .news-content {
  background-color: #3b839c;
}

.block-editors-pick .news-boxes.featured .news-cover:before {
  content: 'Featured';
  display: block;
  padding: 0.5rem 1rem;
  background-color: #c94d51;
  color: #ffffff;
  font-size: 1.2rem;
  letter-spacing: 0.08rem;
  position: absolute;
  top: 1rem;
  left: -0.1rem;
  z-index: 1;
}

@media (max-width: 767px) {
  .block-editors-pick .i-row > .col-sm-6 {
    max-height: none;
  }
  .block-editors-pick .news-boxes {
    padding-bottom: 0;
  }
  .block-editors-pick .news-boxes:before, .block-editors-pick .news-boxes:after {
    content: '';
    display: table;
  }
  .block-editors-pick .news-boxes:after {
    clear: both;
  }
  .block-editors-pick .news-cover {
    width: 11em;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
    overflow: hidden;
  }
  .block-editors-pick .news-cover img {
    width: 140%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate3d(-50%, -50%, 0) scale(1.3);
  }
  .block-editors-pick .news-content {
    width: auto;
    padding-left: 12em;
    position: relative;
    left: auto;
    bottom: auto;
  }
  .block-editors-pick .news-boxes.no-image .news-cover {
    display: block;
  }
}

@media (max-width: 479px) {
  .block-editors-pick .news-cover {
    width: 7.5em;
  }
  .block-editors-pick .news-content {
    padding-left: 8.5em;
  }
  .block-editors-pick .news-title {
    font-size: 0.9em;
  }
  .block-editors-pick .news-boxes.no-image .news-title {
    height: auto;
    overflow: visible;
  }
}

@media (min-width: 768px) {
  .block-editors-pick .news-boxes.side-image {
    display: flex;
    padding-bottom: 0;
  }
  .block-editors-pick .news-boxes.side-image .news-cover {
    width: 100%;
    height: 10.5rem;
    position: relative;
    overflow: hidden;
  }
  .block-editors-pick .news-boxes.side-image .news-cover img {
    width: 130%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate3d(-50%, -50%, 0);
  }
  .block-editors-pick .news-boxes.side-image .news-content {
    position: relative;
    left: auto;
    bottom: auto;
  }
  .block-editors-pick .news-boxes.large .news-cover {
    height: 11.5rem;
  }
}

.block-newsletter .nl-cover {
  display: none;
}

.block-newsletter .nl-boxes {
  padding: 2rem;
  background-color: #2e3a52;
  color: #ffffff;
  text-align: center;
}

.block-newsletter .nl-boxes h3 {
  margin-top: 0;
  margin-bottom: 0.8em;
  font-size: 1.8rem;
  font-weight: 500;
}

.block-newsletter .nl-boxes .i-form .input {
  margin-bottom: 0.8rem;
  background-color: transparent;
}

.block-newsletter .nl-boxes .i-form .input label {
  color: #ffffff;
}

.block-newsletter .nl-boxes .i-form .button {
  border: 0;
}

@media (min-width: 768px) {
  .block-newsletter .nl-wrapper {
    display: flex;
  }
  .block-newsletter .nl-cover {
    display: flex;
  }
  .block-newsletter .nl-cover-wrap {
    min-width: 27rem;
    min-height: 100%;
    position: relative;
    overflow: hidden;
  }
  .block-newsletter .nl-cover-wrap img {
    min-width: 32rem;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate3d(-50%, -50%, 0);
  }
  .block-newsletter .nl-boxes {
    padding: 2rem 3rem;
  }
}

.block-trending .i-row > .i-col:nth-child(2n+3) {
  clear: both;
}

.block-trending .i-items > .item {
  padding: 1rem;
  border: 0.2rem solid #f7f6f6;
}

.block-trending .content .title {
  color: #2e3a52;
  font-size: 1.2rem;
  font-weight: 600;
  line-height: 1.4;
  letter-spacing: 0.1rem;
}

.block-trending .content .list-inline {
  margin-top: 0.727em;
  margin-bottom: 0;
  font-size: 0.786em;
  text-transform: uppercase;
}

.block-trending .content .tag {
  color: #3b839c;
}

.block-trending .content.icon {
  flex: none;
  margin-right: 1rem;
  padding-top: 1.8rem;
  padding-bottom: 1.8rem;
  color: #a5a5a5;
  line-height: 0;
}

.block-trending svg {
  fill: #3b839c;
}

.block-showcase .showcase-title {
  margin: 0;
  padding: 0.7rem 1.5rem;
  background-color: rgba(37, 37, 37, 0.75);
  color: #ffffff;
  font-size: 1.5rem;
  text-transform: uppercase;
  position: absolute;
  left: 10rem;
  top: 6rem;
  z-index: 2;
}

.block-showcase .i-carousel .item > .caption {
  padding-top: 1rem;
  padding-bottom: 1rem;
  background-color: rgba(46, 58, 82, 0.8);
}

.block-showcase .i-carousel .item > .caption :last-child {
  margin-bottom: 0;
}

.block-showcase .i-carousel .owl-nav {
  color: #ffffff;
}

.block-showcase .i-carousel .owl-nav i {
  text-align: center;
}

.block-showcase .i-carousel .owl-nav .owl-prev,
.block-showcase .i-carousel .owl-nav .owl-next {
  width: 4.2rem;
  height: 4.2rem;
  padding-left: 1rem;
  padding-right: 1rem;
  line-height: 4.2rem;
  font-size: 1.8rem;
  background-color: rgba(46, 58, 82, 0.8);
  color: #3b839c;
}

@media (max-width: 767px) {
  .block-showcase .i-carousel .item > .caption {
    font-size: 95%;
    line-height: 1.1;
  }
}

.block-news .block-title, .block-news .area-outlook-detail h3.block-title, .area-outlook-detail .block-news h3.block-title,
.block-news .page-project-outlook h3.block-title, .page-project-outlook .block-news h3.block-title {
  padding: 1.2rem 1.5rem;
  background-color: #2e3a52;
  color: #ffffff;
  font-size: 1.3rem;
  text-transform: uppercase;
  letter-spacing: 0.3rem;
}

.block-news .more-link.absolute {
  padding-top: 0.7rem;
  padding-bottom: 0.7rem;
  color: #ffffff;
  font-size: 2.5rem;
  line-height: 1;
  top: -6rem;
}

.block-news .i-card {
  position: relative;
}

.block-news .i-card > .content {
  border: 0.2rem solid #f7f6f6;
}

.block-news .i-card > .content > .title {
  margin-top: 0.5rem;
  margin-bottom: 0.8rem;
  font-size: 1.5rem;
  font-weight: 500;
  line-height: 1.4;
}

.block-news .i-card > .content > .meta {
  font-size: 1.1rem;
  color: inherit;
}

.block-news .i-card > .content > .meta.category {
  font-size: 1rem;
  text-transform: uppercase;
}

@media (max-width: 991px) {
  .block-news .more-link.absolute {
    position: absolute;
    right: 0;
  }
}

.block-listing a.image {
  display: block;
  overflow: hidden;
}

.block-listing a.image img {
  width: 100%;
  transition: all 250ms ease;
}

.block-listing a.image:hover img {
  transform: scale(1.06);
}

.block-listing a.image .indicator {
  padding: 1rem 1.5rem;
  background-color: #3b839c;
  color: #ffffff;
}

.block-listing .i-card > .content > .title {
  margin-bottom: 0.7rem;
  font-size: 1.4rem;
  font-weight: 600;
}

.block-edgetv .i-card > .content > .category {
  margin-top: -0.2em;
  margin-bottom: 0.6em;
  font-weight: 600;
  color: #3b839c;
}

.block-edgetv .i-card > .content > .title {
  margin-top: 0;
  margin-bottom: 0.5em;
  font-size: 1.4rem;
}

.block-event-calendar .event-item:before, .block-event-calendar .event-item:after {
  content: '';
  display: table;
}

.block-event-calendar .event-item:after {
  clear: both;
}

.block-event-calendar .event-item > .date {
  float: left;
  width: 7.5rem;
  background-color: #3b839c;
  color: #ffffff;
  text-align: center;
}

.block-event-calendar .event-item > .date > span {
  display: block;
  padding: 0.6rem 0.5rem;
  font-size: 1rem;
  text-transform: uppercase;
}

.block-event-calendar .event-item > .date > span.day {
  padding: 0 0.5rem 0.5rem;
  font-size: 2.8rem;
  line-height: 1;
}

.block-event-calendar .event-item > .date > span.text {
  background-color: #252525;
}

.block-event-calendar .event-item > .content {
  margin-left: 8.5rem;
}

.block-event-calendar .event-item > .content > .title {
  margin-top: 0;
  font-size: 1.3rem;
  font-weight: 600;
  line-height: 1.2;
}

.block-event-calendar .event-item > .content > .time {
  font-size: 1.1rem;
  color: #a5a5a5;
}

.block-research .block-title, .block-research .area-outlook-detail h3.block-title, .area-outlook-detail .block-research h3.block-title,
.block-research .page-project-outlook h3.block-title, .page-project-outlook .block-research h3.block-title {
  margin-top: -0.5rem;
}

.block-research .i-row > .i-col {
  margin-bottom: 1.6rem;
}

.block-research a.image {
  display: block;
  overflow: hidden;
}

.block-research a.image img {
  width: 100%;
  transition: all 250ms ease;
}

.block-research a.image:hover img {
  transform: scale(1.06);
}

.block-tem .embed-tem {
  padding-bottom: 56.81818182%;
}

@media (max-width: 479px) {
  .block-tem .embed-tem {
    padding-bottom: 73.5rem;
  }
}

.block-mrec, .block-adbutton {
  max-width: 30rem;
  margin-left: auto;
  margin-right: auto;
}

/* ---------------------------------------- */
/*  Custom:Misc
/* ---------------------------------------- */
.mpea-2017 .mpea-title {
  margin-top: 0;
  margin-bottom: 0;
  font-size: 2rem;
  font-weight: 600;
  color: #252525;
}

.mpea-2017 .mpea-subtitle {
  margin-top: 0;
  margin-bottom: 1.8rem;
  font-size: 1.5rem;
  color: #2e3a52;
}

.mpea-2017 .mpea-boxes {
  display: flex;
  margin-left: -0.5rem;
  margin-right: -0.5rem;
}

.mpea-2017 .mpea-card {
  display: block;
  width: 33.3333%;
  margin-left: 0.5rem;
  margin-right: 0.5rem;
  border: 0.1rem solid #d9dce0;
  background-color: #ffffff;
  box-shadow: 0 0.2rem 1rem -0.5rem rgba(46, 58, 82, 0.5);
  transition: all 150ms linear;
}

.mpea-2017 .mpea-card .content {
  padding: 1.5rem;
}

.mpea-2017 .mpea-card .title {
  margin-top: 0;
  margin-bottom: 0.5em;
  color: #2e3a52;
  font-size: 115%;
  font-weight: 500;
  line-height: 1.34;
}

.mpea-2017 .mpea-card .description {
  color: #656565;
  font-size: 95%;
  line-height: 1.3;
}

.mpea-2017 .mpea-card:hover {
  border-color: rgba(46, 58, 82, 0.5);
  box-shadow: 0 0.2rem 1rem -0.5rem rgba(46, 58, 82, 0.5), 0 0 0 0.1rem rgba(46, 58, 82, 0.5);
}

.mpea-2017 .mpea-card:focus, .mpea-2017 .mpea-card:hover {
  text-decoration: none;
}

@media (max-width: 479px) {
  .mpea-2017 .mpea-boxes {
    display: block;
    margin-left: 0;
    margin-right: 0;
  }
  .mpea-2017 .mpea-card {
    display: block;
    width: 100%;
    margin: 0 0 1.5rem;
  }
  .mpea-2017 .mpea-card:last-child {
    margin-bottom: 0;
  }
}

.zing-card {
  background-color: white;
  border: 1px solid #ddd;
}

.zing-card .image {
  display: block;
  margin-bottom: 0.1rem;
}

.zing-card .image img {
  width: 100%;
}

.zing-card .thumbnails {
  display: flex;
  margin-left: -0.1rem;
  padding-right: 0.3rem;
}

.zing-card .thumbnails .thumb {
  flex: 0 0 33.33334%;
  margin-left: 0.1rem;
  position: relative;
}

.zing-card .thumbnails .thumb:first-child {
  border-left: 0;
}

.zing-card .thumbnails .thumb:last-child {
  border-right: 0;
}

.zing-card .thumbnails .thumb:before {
  content: '';
  display: block;
  width: 100%;
  padding-bottom: 66.6666%;
}

.zing-card .thumbnails .thumb img {
  display: block;
  width: 100%;
  height: auto;
  margin-left: auto;
  margin-right: auto;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.zing-card .thumbnails .thumb .count {
  width: 100%;
  height: 100%;
  padding-top: 20%;
  padding-bottom: 20%;
  background-color: rgba(0, 0, 0, 0.2);
  color: white;
  font-size: 2rem;
  font-weight: 400;
  line-height: 1;
  text-align: center;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
}

.zing-card .thumbnails .thumb .count:before {
  content: '+';
  display: inline;
  margin-right: 0.12em;
}

.zing-card .content {
  padding: 1rem 1.2rem;
}

.zing-card .content .title {
  display: -webkit-box;
  margin-top: 0;
  margin-bottom: 0.5rem;
  font-size: 0.9em;
  font-weight: 600;
  text-overflow: ellipsis;
  overflow: hidden;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
}

.zing-card .content .title a {
  display: block;
}

.zing-card .content .company {
  font-weight: 400;
  line-height: 1.55;
  font-size: 11px;
  color: #999;
}

.zing-cards {
  display: flex;
  flex-wrap: wrap;
}

.zing-cards .zing-card {
  width: 100%;
  margin-bottom: 2rem;
}

.zing-cards .zing-card:last-child {
  margin-bottom: 0;
}

.zing-carousel {
  padding-left: 0;
  padding-right: 0;
}

.zing-carousel .zing-card {
  margin-bottom: 0.5rem;
}

.zing-carousel .zing-card .content {
  height: 7.7rem;
}

.zing-carousel .owl-nav .owl-prev,
.zing-carousel .owl-nav .owl-next {
  width: 3rem;
  height: 3rem;
  background-color: #fff;
  line-height: 3rem;
  text-align: center;
  overflow: hidden;
  position: absolute;
  top: -3.7rem;
  right: 0;
  transform: translateY(0);
  box-shadow: 0 0.1rem 0.3rem -0.1rem rgba(0, 0, 0, 0.3);
  border-radius: 0.3rem;
}

.zing-carousel .owl-nav .owl-prev i,
.zing-carousel .owl-nav .owl-next i {
  line-height: 3.3rem;
  font-size: 24px;
}

.zing-carousel .owl-nav .owl-prev.disabled,
.zing-carousel .owl-nav .owl-next.disabled {
  visibility: visible;
  opacity: 0.3;
  cursor: default;
}

.zing-carousel .owl-nav .owl-prev:active:not(.disabled),
.zing-carousel .owl-nav .owl-next:active:not(.disabled) {
  background-color: #f7f6f6;
}

.zing-carousel .owl-nav .owl-prev {
  left: auto;
  right: 3.4rem;
}

.zing-block .block-title, .zing-block .area-outlook-detail h3.block-title, .area-outlook-detail .zing-block h3.block-title,
.zing-block .page-project-outlook h3.block-title, .page-project-outlook .zing-block h3.block-title {
  margin-bottom: 0.7rem;
  font-size: 1.6rem;
}

@media (min-width: 480px) {
  .zing-cards {
    margin-left: -0.8rem;
    margin-right: -0.8rem;
  }
  .zing-cards .zing-card {
    width: calc(33.33334% - 1.6rem);
    margin-left: 0.8rem;
    margin-right: 0.8rem;
    margin-bottom: 0;
  }
}

.zing-view-more {
  position: relative;
}

.zing-view-more .overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: #fff;
  display: flex;
  cursor: pointer;
}

.zing-view-more .overlay .center {
  flex: 1 1 100%;
  align-self: center;
}

.zing-view-more .overlay .center img {
  width: 150px;
  display: block;
  margin: 0 auto 20px;
}

.zing-as-3-2 {
  position: relative;
}

.zing-as-3-2:before {
  display: block;
  content: "";
  width: 100%;
  padding-bottom: 66.66667%;
}

.zing-as-3-2 > * {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.listing-items .i-items.divided > .zing-item {
  flex-wrap: wrap;
  padding-top: 2.5rem;
  padding-bottom: 2.5rem;
}

.listing-items .i-items.divided > .zing-item .zing-title {
  flex: 0 0 100%;
  margin: -1rem 0 0.7rem;
  font-size: 1.5rem;
  line-height: 2;
}

.listing-items .i-items.divided > .zing-item .zing-carousel .zing-card {
  margin-left: 0.2rem;
  margin-right: 0.2rem;
}

@media (max-width: 479px) {
  .listing-items .i-items.divided > .zing-item {
    width: auto;
    margin-left: -1.5rem;
    margin-right: -1.5rem;
    padding-left: 1.5rem;
    padding-right: 1.5rem;
    background-color: #f7f6f6;
  }
}

#stickyNav {
  background-color: #333;
  border-color: rgba(34, 34, 34, 0.05);
  background-color: #000;
  -webkit-transition: all .3s;
  -moz-transition: all .3s;
  transition: all .3s;
  font-weight: 200;
  letter-spacing: 1px;
  border-radius: 0;
  margin-bottom: 0;
}

#stickyNav.stick {
  margin-top: 0 !important;
  width: 100%;
  position: fixed;
  top: 0;
  z-index: 999;
}

#stickyNav .navbar {
  border-radius: 0 !important;
}

#stickyNav .navbar-brand {
  color: #ef60a3;
  font-weight: 200;
  letter-spacing: 1px;
}

#stickyNav .navbar-brand:focus, #stickyNav .navbar-brand:hover {
  color: #b96700;
}

#stickyNav .navbar-toggler {
  font-size: 12px;
  padding: 8px 10px;
  color: #fff;
  position: absolute;
  right: 10px;
  top: 10px;
}

#stickyNav .navbar-nav > li > a {
  font-weight: 500;
  text-transform: uppercase;
  margin-right: 20px;
  color: #fff;
}

@media (min-width: 1024px) {
  #stickyNav .navbar-nav > li > a {
    font-size: 1.4rem;
    margin-right: 5px;
  }
}

@media (max-width: 767px) {
  #stickyNav .navbar-nav > li > a {
    padding: 6px 0;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  #stickyNav .navbar-nav > li > a {
    font-size: 1.1rem;
    line-height: 1;
    margin-right: 0;
  }
}

#stickyNav .navbar-nav > li > a.active {
  color: #ef60a3 !important;
  background-color: transparent;
}

#stickyNav .navbar-nav > li > a.active:hover {
  background-color: transparent;
}

#stickyNav .navbar-nav > li > a:focus {
  color: #fff;
}

#stickyNav .navbar-nav > li > a:focus:hover {
  color: #ef60a3;
}

#stickyNav .navbar-nav > li > a:hover {
  color: #ef60a3;
}

@media (min-width: 768px) and (max-width: 768px) {
  #stickyNav .navbar-nav li:nth-child(4) {
    width: 70px;
  }
}

.nav-pills .nav-link.active {
  border: 1px solid #ef60a3;
  color: #ef60a3;
  background-color: transparent;
}

@media (min-width: 992px) {
  .navbar-expand-lg .navbar-nav .nav-link {
    padding-right: 2rem;
  }
}

.section-nl .content-area, .section-vpex .content-area {
  background-color: #e9e9ea;
}

.section-nl .block-content, .section-vpex .block-content {
  padding: 0 5rem;
}

.nl-module.i-card {
  border-radius: 0;
}

.nl-module.i-card .content {
  padding: 1.5rem 2rem;
}

.nl-module .meta {
  height: 4.5rem;
  overflow: hidden;
}

.nl-module .project-name {
  color: #2e3a52;
  font-size: 1.4rem;
  font-weight: 500;
}

.nl-module .developer-name {
  margin-bottom: 10px;
  color: #777;
  font-size: 1.2rem;
  font-weight: 500;
}

.nl-module .label-default {
  background: #f5f5f5;
  font-weight: 400;
  font-size: 11px;
  color: #888;
  padding: 5px;
  margin-bottom: 0.4rem;
  display: inline-block;
}

.nl-module .image {
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}

.nl-module a:hover {
  text-decoration: none;
}

.nl-module .price-desc {
  position: absolute;
  top: 0.1rem;
  left: 0.1rem;
  z-index: 88;
  background: rgba(0, 0, 0, 0.65);
  padding: 10px;
  color: #fff;
  font-weight: 500;
  font-size: 1.4rem;
  line-height: 1em;
}

.nl-module .price-desc span {
  display: block;
  text-transform: uppercase;
  font-size: 1rem;
  letter-spacing: 0.05em;
}

.nl-listing .material-icons, .nl-module .material-icons {
  font-size: initial;
  position: relative;
  top: -2px;
  padding-right: 7px;
}

.nl-vpex-thumb {
  display: block;
  position: relative;
}

button.btn-vpex-list {
  width: 100%;
}

.image.thumb {
  display: block;
}

.thumb-img {
  border: solid;
  border-width: 0 1px 1px 0;
  border-color: white;
}

.thumb-img:first-child {
  border-left: 1px solid white;
}

.glass-holder.stage {
  background: rgba(0, 0, 0, 0);
  border: 1px solid white;
  height: 13.5rem;
  cursor: -webkit-zoom-in;
}

.glass-holder.stage:hover {
  background: rgba(0, 0, 0, 0.12);
  -webkit-transition: opacity .15s ease-out;
  -o-transition: opacity .15s ease-out;
  -moz-transition: opacity .15s ease-out;
  transition: opacity .15s ease-out;
}

.glass-holder.thumb {
  background: #000;
  opacity: .1;
  cursor: -webkit-zoom-in;
  height: 4.2rem;
}

.glass-holder.thumb:hover {
  opacity: .3;
  -webkit-transition: opacity .15s ease-out;
  -o-transition: opacity .15s ease-out;
  -moz-transition: opacity .15s ease-out;
  transition: opacity .15s ease-out;
}

.view_more {
  width: 25%;
  margin: auto;
}

.view_more .button {
  background: transparent;
  color: #2e3a52;
  border: 2px solid #2e3a52;
  font-weight: 600;
  letter-spacing: 0.05em;
}

.view_more .button:hover {
  color: white;
  background: #2e3a52;
  border: 2px solid #2e3a52;
}

.vpex-overlay {
  background: rgba(0, 0, 0, 0.65);
  z-index: 89;
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  border: solid;
  border-color: white;
  border-width: 0 1px 1px 1px;
}

.vpex-list-col .social-sharer {
  color: #fff;
  height: 100%;
}

.vpex-list-col .social-sharer .button.block {
  height: 100%;
  font-size: 1.5rem;
}

.mrec {
  min-width: 316px;
}

.foto-slider .container-bg-dark {
  background: #000;
}

.newlaunch-content-area span.view-all-btn {
  font-size: small;
  font-weight: 600;
  text-transform: uppercase;
  float: right;
  position: relative;
  top: 11px;
}

.newlaunch-content-area span.view-all-btn a:hover {
  text-decoration: underline;
}

.newlaunch-content-area span.view-all-btn i {
  margin-left: 5px;
}

.newlaunch-content-area a {
  color: #2e3a52;
}

.newlaunch .social-sharer .social-media, .newlaunch-vpex .social-sharer .social-media {
  min-width: 100%;
  text-align: center;
  bottom: 60px;
}

.newlaunch .vpex-list-col .social-sharer .button.block, .newlaunch-vpex .vpex-list-col .social-sharer .button.block {
  font-size: 1.3rem;
}

.newlaunch .vpex-list-col .social-sharer li, .newlaunch-vpex .vpex-list-col .social-sharer li {
  display: inline-block;
  margin: 0 10px;
}

.p-l-r-0 {
  padding-left: 0;
  padding-right: 0;
}

.centered {
  text-align: center;
  font-size: 0;
}

.centered div {
  float: none;
  display: inline-block;
  text-align: left;
  font-size: 12px;
  vertical-align: top;
}

/* 
fotorama css
*/
.foto-slider {
  background: transparent;
  font-family: inherit;
}

#nl-slider .fotorama__nav-wrap {
  margin-top: -40px;
}

#nl-slider .fotorama__nav-wrap .fotorama__nav__shaft {
  background: rgba(0, 0, 0, 0.75);
  border: 4px solid transparent;
  border-top: 0;
  border-bottom: 0;
  box-shadow: 0 4px 10px 2px rgba(0, 0, 0, 0.3);
}

#nl-slider .fotorama__stage__frame img {
  height: 100% !important;
  width: 100% !important;
  top: 0 !important;
  object-fit: cover !important;
}

#nl-slider .fotorama__thumb-border {
  border-color: #3b839c;
}

#nl-slider .fotorama__stage__shaft .fotorama__loaded .fotorama__img {
  opacity: .25;
  transition: opacity 0.3s;
}

#nl-slider .fotorama__stage__shaft .fotorama__active.fotorama__loaded .fotorama__img {
  opacity: 1;
}

#nl-slider .fotorama__active .fotorama__caption {
  opacity: 1;
  height: 0;
}

#nl-slider .fotorama__active .fotorama__caption__wrap {
  opacity: 1;
}

#nl-slider.fotorama--fullscreen .fotorama__caption {
  opacity: 1;
}

#nl-slider .fotorama__arr {
  font-family: FontAwesome;
  font-size: 4rem;
  color: white;
}

#nl-slider .fotorama__arr.fotorama__arr--next {
  right: 2%;
  text-align: center;
  background: none;
}

#nl-slider .fotorama__arr.fotorama__arr--next:before {
  content: '\f054';
}

#nl-slider .fotorama__arr.fotorama__arr--prev {
  left: 2%;
  text-align: center;
  background: none;
}

#nl-slider .fotorama__arr.fotorama__arr--prev:before {
  content: '\f053';
}

#nl-slider .fotorama__stage__frame.fotorama__loaded.fotorama__loaded--img {
  background: black;
}

#nl-slider .fotorama__html div, #nl-slider .fotorama__html a {
  display: block;
  height: 100%;
}

#nl-slider .fotorama__caption {
  top: 0;
}

#nl-slider .fotorama__caption__wrap {
  background: rgba(0, 0, 0, 0.65);
  color: white;
  opacity: 0;
  font-size: 2.4rem;
  line-height: 1.2em;
  font-weight: 600;
  /*letter-spacing: 0.02em;*/
  padding: 1.2rem;
  text-transform: uppercase;
  font-family: 'Poppins';
}

#nl-slider .fotorama__caption__wrap span {
  display: block;
  font-size: 1.2rem;
  font-weight: 700;
  letter-spacing: 0.05em;
  line-height: 1em;
}

.nl-slider.activeCaption {
  padding: 1.2rem 1rem 2rem;
  font-size: 0;
  color: white;
  text-align: center;
}

.nl-slider.activeCaption .areaCaption:before {
  content: '•';
  padding: 8px;
}

.nl-slider .projectCaption {
  font-weight: 600;
  font-size: 3rem;
  margin-bottom: 1.2rem;
}

.nl-slider .areaCaption, .nl-slider .devCaption {
  font-size: 1.4rem;
  font-weight: 400;
  display: inline-block;
  margin-top: 1.8rem;
}

.nl-slider.deco {
  width: 10rem;
  height: 2.5px;
  border: none;
  background-color: white;
  position: absolute;
  left: calc(50% - 5rem);
  margin: 0;
}

.jumbotron.newlaunch-search-container {
  margin-bottom: 20px;
  padding: 30px 0;
  margin-top: 72px;
}

.jumbotron.newlaunch-search-container .search-tiny .search-fakebar {
  max-width: 100%;
}

.jumbotron.newlaunch-search-container .search-tiny .search-wrapper {
  max-width: 100%;
  font-family: inherit;
  padding: 0;
}

.jumbotron.newlaunch-search-container .search-tiny .search-form .onebar .input-group .button {
  border-left: .1rem solid #dedede;
  border-right: 0;
  font-size: 1.2rem;
}

.jumbotron.newlaunch-search-container .dropdown-theme {
  max-height: 300px;
  overflow: auto;
  margin-top: 0;
  padding: 0;
}

.newlaunch-news .news-item {
  margin-bottom: 20px;
}

.newlaunch-news .news-body {
  font-size: 1.2rem;
}

.newlaunch-news .news-meta {
  margin-bottom: 1rem;
  color: #969696;
  font-size: 1.2rem;
  font-weight: 300;
  white-space: nowrap;
}

.newlaunch-news .news-meta span + span {
  margin-left: 1rem;
  padding-left: 1rem;
  border-left: .1rem solid #c9c9c9;
}

.newlaunch-news h1.news-title {
  margin-bottom: .3em;
  font-size: 1.4rem;
  font-weight: 500;
  line-height: 1.3;
}

.newlaunch-news h1.news-title a {
  color: #23527c;
}

@media screen and (max-width: 479px) {
  .nl-module .price-desc {
    font-size: 2.6rem;
  }
  .nl-module .price-desc span {
    font-size: 1.4rem;
  }
  .glass-holder.stage {
    height: 22rem;
  }
  .glass-holder.thumb {
    height: 7.5rem;
  }
  .i-row:not(.reset) > .i-col {
    width: 100%;
  }
  .nl-slider .areaCaption, .nl-slider .devCaption {
    display: block;
    margin-top: 2.8rem;
  }
  .nl-slider .areaCaption {
    margin-top: 0;
  }
  .nl-slider.activeCaption .areaCaption:before {
    content: '';
    padding: 0;
  }
  .newlaunch-news .news-row .news-thumb {
    width: 100%;
    display: block;
    float: none;
  }
  .newlaunch-news .news-row .news-content {
    margin-left: 0;
    display: block;
  }
  .newlaunch-news h1.news-title {
    margin-top: 10px;
  }
  .newlaunch-news .news-thumb img {
    width: 100%;
  }
}

@media screen and (min-width: 480px) and (max-width: 590px) {
  .glass-holder.stage {
    height: 18.5rem;
  }
  .glass-holder.thumb {
    height: 7.5rem;
  }
}

@media screen and (min-width: 591px) and (max-width: 767px) {
  .glass-holder.stage {
    height: 20.5rem;
  }
  .glass-holder.thumb {
    height: 7.5rem;
  }
  .jumbotron.newlaunch-search-container .search-tiny .search-form {
    overflow-y: inherit;
  }
}

@media screen and (min-width: 480px) and (max-width: 767px) {
  .vpex-list-col:nth-child(2n+1) {
    clear: both;
  }
}

@media screen and (max-width: 767px) {
  .jumbotron.newlaunch-search-container {
    padding: 5px 0;
    margin-top: 50px;
  }
  .jumbotron.newlaunch-search-container .search-tiny .search-fakebar {
    margin: 4vh auto;
  }
  .jumbotron.newlaunch-search-container .search-tiny .search-form {
    padding: 50px 15px 15px;
  }
  .newlaunch .social-sharer .social-media, .newlaunch-vpex .social-sharer .social-media {
    bottom: 100px;
  }
}

@media screen and (min-width: 768px) and (max-width: 991px) {
  .vpex-list-col:nth-child(3n+1) {
    clear: both;
  }
  .section-nl .block-content, .section-vpex .block-content {
    padding: 0;
  }
}

@media screen and (min-width: 991px) {
  .jumbotron.newlaunch-search-container .container {
    padding-left: 0;
    padding-right: 0;
  }
  .newlaunch-content-area .container {
    padding-left: 0;
    padding-right: 0;
  }
}

@media screen and (max-width: 1024px) {
  .jumbotron.newlaunch-search-container {
    margin-top: 50px;
  }
}

@media screen and (max-width: 769px) {
  .newlaunch-content-area span.view-all-btn {
    display: block;
    float: none;
    position: inherit;
  }
  .jumbotron.newlaunch-search-container .search-tiny .minimal .select.character {
    margin-top: 0;
    margin-bottom: 2.5rem;
  }
  .jumbotron.newlaunch-search-container .search-tiny .minimal .select.character:before {
    content: none;
  }
  .jumbotron.newlaunch-search-container .search-tiny .minimal .select.character.nl-last-select {
    margin-bottom: 5rem;
  }
  .nl-select-title {
    display: block;
    clear: both;
    font-size: 1.4rem;
    margin-bottom: 5px;
  }
}

.state_card .card-box {
  width: 23.5rem;
  height: 15rem;
  margin-right: 0.5rem;
  margin-bottom: 0.5rem;
  float: left;
  position: relative;
  background-repeat: no-repeat;
  background-position: center;
}

.state_card .card-box:first-child {
  height: 30.5rem;
  background-size: 46rem;
}

.state_card .card-box:nth-child(n+2) {
  background-size: 23.5rem;
}

.state_card .card-box:nth-child(n+8) {
  display: none;
}

.state_card .card-label {
  background-color: rgba(0, 0, 0, 0.3);
  color: white;
  width: 100%;
  height: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  font-size: 2rem;
}

.state_card .cover {
  width: 23.5rem;
  height: 15rem;
  margin-right: 0.5rem;
  margin-bottom: 0.5rem;
  float: left;
  position: relative;
  background-repeat: no-repeat;
  background-position: center;
}

@media (max-width: 1125px) {
  .state_card .card-box, .state_card .cover {
    width: 23rem;
  }
  .state_card .card-box:first-child {
    background-size: 46rem;
  }
  .state_card .card-box:nth-child(n+2) {
    background-size: 23rem;
  }
}

@media (max-width: 991px) {
  .state_card .card-box, .state_card .cover {
    width: 35.5rem;
  }
  .state_card .card-box:first-child {
    background-size: 46rem;
  }
  .state_card .card-box:nth-child(n+2) {
    background-size: 35.5rem;
  }
}

@media (max-width: 767px) {
  .state_card .card-box, .state_card .cover {
    width: 100%;
  }
  .state_card .card-box:first-child {
    height: 15rem;
    background-size: 100%;
  }
  .state_card .card-box:nth-child(n) {
    background-size: 100%;
  }
}

.state_card .card-box,
.state_card .covers {
  cursor: pointer;
}

.state_card .card-box:nth-child(1) {
  background-image: url("https://s3-ap-southeast-1.amazonaws.com/www.theedgeproperty.com.my/assets/images/regions/smaller/KL.jpg");
}

.state_card .card-box:nth-child(2) {
  background-image: url("https://s3-ap-southeast-1.amazonaws.com/www.theedgeproperty.com.my/assets/images/regions/smaller/Selangor.jpg");
}

.state_card .card-box:nth-child(3) {
  background-image: url("https://s3-ap-southeast-1.amazonaws.com/www.theedgeproperty.com.my/assets/images/regions/smaller/Penang.jpg");
}

.state_card .card-box:nth-child(4) {
  background-image: url("https://s3-ap-southeast-1.amazonaws.com/www.theedgeproperty.com.my/assets/images/regions/smaller/Johor.jpg");
}

.state_card .card-box:nth-child(5) {
  background-image: url("https://s3-ap-southeast-1.amazonaws.com/www.theedgeproperty.com.my/assets/images/regions/smaller/Putrajaya.jpg");
}

.state_card .card-box:nth-child(6) {
  background-image: url("https://s3-ap-southeast-1.amazonaws.com/www.theedgeproperty.com.my/assets/images/regions/smaller/Ipoh.jpg");
}

.state_card .card-box:nth-child(7) {
  background-image: url("https://s3-ap-southeast-1.amazonaws.com/www.theedgeproperty.com.my/assets/images/regions/smaller/Kedah.jpg");
}

.state_card .card-box:nth-child(8) {
  background-image: url("https://s3-ap-southeast-1.amazonaws.com/www.theedgeproperty.com.my/assets/images/regions/smaller/Pahang.jpg");
}

.state_card .card-box:nth-child(9) {
  background-image: url("https://s3-ap-southeast-1.amazonaws.com/www.theedgeproperty.com.my/assets/images/regions/smaller/NSembilan.jpg");
}

.state_card .card-box:nth-child(10) {
  background-image: url("https://s3-ap-southeast-1.amazonaws.com/www.theedgeproperty.com.my/assets/images/regions/smaller/Melaka.jpg");
}

.state_card .card-box:nth-child(11) {
  background-image: url("https://s3-ap-southeast-1.amazonaws.com/www.theedgeproperty.com.my/assets/images/regions/smaller/Terengganu.jpg");
}

.state_card .card-box:nth-child(12) {
  background-image: url("https://s3-ap-southeast-1.amazonaws.com/www.theedgeproperty.com.my/assets/images/regions/smaller/Perlis.jpg");
}

.state_card .card-box:nth-child(13) {
  background-image: url("https://s3-ap-southeast-1.amazonaws.com/www.theedgeproperty.com.my/assets/images/regions/smaller/Kelantan.jpg");
}

.state_card .card-box:nth-child(14) {
  background-image: url("https://s3-ap-southeast-1.amazonaws.com/www.theedgeproperty.com.my/assets/images/regions/smaller/Sabah.jpg");
}

.state_card .card-box:nth-child(15) {
  background-image: url("https://s3-ap-southeast-1.amazonaws.com/www.theedgeproperty.com.my/assets/images/regions/smaller/Sarawak.jpg");
}

.state_card .card-box:first-child {
  background-size: 46rem;
  background-repeat: no-repeat;
}

.state_card .card-box:nth-child(n+2) {
  background-size: 23.5rem;
  background-repeat: no-repeat;
}

.state_card .covers {
  width: 23.5rem;
  height: 15rem;
  margin-right: 0.5rem;
  margin-bottom: 0.5rem;
  float: left;
  position: relative;
  background-size: 23.5rem;
  background-repeat: no-repeat;
  background-position: center;
  background-image: url("https://s3-ap-southeast-1.amazonaws.com/www.theedgeproperty.com.my/assets/images/regions/smaller/Kedah.jpg");
}

.state_card .covers .card-label {
  width: 100%;
  height: 100%;
  font-size: 2rem;
  display: flex;
  justify-content: center;
  align-items: center;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.3);
}

.state_card .covers .revert {
  /*width: 100%;
  padding: 1rem 1.5rem;
  bottom: 2rem;
  height:4rem;*/
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.3);
  font-size: 2rem;
}

@media (max-width: 1125px) {
  .state_card .covers {
    width: 23rem;
    background-size: 23rem;
    background-repeat: no-repeat;
  }
  .state_card .card-box:first-child {
    background-size: 46rem;
    background-repeat: no-repeat;
  }
  .state_card .card-box:nth-child(n+2) {
    background-size: 23rem;
    background-repeat: no-repeat;
  }
}

@media (max-width: 991px) {
  .state_card .covers {
    width: 35.5rem;
    background-size: 35.5rem;
    background-repeat: no-repeat;
  }
  .state_card .card-box:first-child {
    background-size: 46rem;
    background-repeat: no-repeat;
  }
  .state_card .card-box:nth-child(n+2) {
    background-size: 35.5rem;
    background-repeat: no-repeat;
  }
}

@media (max-width: 767px) {
  .state_card .covers {
    width: 100%;
    background-size: 100%;
    background-repeat: no-repeat;
  }
  .state_card .card-box:first-child {
    background-size: 100%;
    background-repeat: no-repeat;
    height: 15rem;
  }
  .state_card .card-box:nth-child(n+2) {
    background-size: 100%;
    background-repeat: no-repeat;
  }
}

.state_card .covers a {
  display: block;
}

/*temporary*/
.state_card .card-box .card-label {
  background-color: rgba(0, 0, 0, 0.3);
  bottom: 0;
  color: #fff;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 2rem;
}

#homeadvisor .bglax {
  background: 0 0;
}

#homeadvisor .m-break {
  display: none;
}

#homeadvisor .btn,
#homeadvisor .d-break {
  display: block;
}

#homeadvisor .no-border {
  border: none !important;
}

#homeadvisor .margin-center {
  float: none;
  overflow: hidden;
  margin: 0 auto;
}

#homeadvisor .m-t-35 {
  margin-top: 35px;
}

#homeadvisor .m-b-30 {
  margin-bottom: 30px;
  overflow: hidden;
}

#homeadvisor .show-none {
  display: none;
}

#homeadvisor .float-right {
  float: right;
}

#homeadvisor .float-left {
  float: left;
}

#homeadvisor .btn {
  position: relative;
  min-width: 200px;
  overflow: hidden;
  color: #fff;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;
  padding: 15px 0;
  text-indent: 0;
  font-size: inherit;
  line-height: 1;
  font-style: normal;
  text-align: center;
  font-weight: 500;
  background-color: #005c98;
  text-transform: uppercase;
}

#homeadvisor .btn:hover {
  color: #fff !important;
  background-color: #004979;
  text-decoration: none !important;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
}

#homeadvisor .btn.white {
  color: #005c98;
  background-color: #fff;
}

#homeadvisor .btn.white:hover {
  background-color: transparent;
  border: 1px solid #fff;
  color: #fff;
}

#homeadvisor .btn.small {
  min-width: inherit;
  min-width: auto;
  min-width: unset;
  height: 40px;
  padding: 8px 10px 0;
}

#homeadvisor .btn.small.outline {
  border-width: 1px;
}

#homeadvisor .btn.block {
  display: block;
}

#homeadvisor .page .home .section-intro .hero-image {
  margin-left: -15px;
  margin-right: -15px;
  margin-bottom: 30px;
}

#homeadvisor .page .home .section-intro .hero-image .hero-image-inner {
  position: relative;
  width: 100%;
  height: 400px;
  background-color: #e2eff5;
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
}

@media screen and (min-width: 992px) {
  #homeadvisor .page .home .section-intro .hero-image .hero-image-inner {
    background-size: cover;
    background-position: bottom center;
    height: 100%;
  }
}

@media screen and (min-width: 480px) {
  #homeadvisor .page .home .section-intro .hero-image .hero-image-inner {
    background-size: contain;
    background-position: bottom center;
  }
}

@media screen and (min-width: 992px) {
  #homeadvisor .page .home .section-intro .hero-image {
    width: 100%;
    height: 520px;
  }
}

@media screen and (min-width: 768px) {
  #homeadvisor .page .home .section-intro .hero-image {
    margin-bottom: 0;
  }
}

#homeadvisor .page .home .section-intro .hero-image img {
  width: 418px;
  height: auto;
}

@media screen and (min-width: 768px) {
  #homeadvisor .page .home .section-intro .hero-image img {
    width: 418px;
    float: right;
  }
}

@media screen and (max-width: 991px) {
  #homeadvisor .page .home .section-intro .hero-image img {
    width: 325px;
  }
}

@media screen and (max-width: 425px) {
  #homeadvisor .page .home .section-intro .hero-image img {
    width: 100%;
    height: auto;
  }
}

#homeadvisor .page article.social .equity.hero-image:before, #homeadvisor .page article.social.equity.hero-image:before {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  content: '';
  display: block;
  background-color: rgba(0, 65, 101, 0.2);
}

#homeadvisor .page article.social .equity.hero-image .row:first-child, #homeadvisor .page article.social.equity.hero-image .row:first-child {
  background-size: cover;
  background-position: top left;
  background-repeat: no-repeat;
}

#homeadvisor .page article.social .equity.hero-image .row:first-child:before, #homeadvisor .page article.social.equity.hero-image .row:first-child:before {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  content: '';
  display: block;
  background-color: rgba(0, 65, 101, 0.2);
}

@media screen and (min-width: 768px) {
  #homeadvisor .page article.social .equity.hero-image .row:first-child:before, #homeadvisor .page article.social.equity.hero-image .row:first-child:before {
    display: none;
  }
}

@media screen and (min-width: 768px) {
  #homeadvisor .page article.social .equity.hero-image .row:first-child, #homeadvisor .page article.social.equity.hero-image .row:first-child {
    background-image: none;
  }
}

@media screen and (min-width: 768px) {
  #homeadvisor .page article.social .equity.hero-image, #homeadvisor .page article.social.equity.hero-image {
    background-size: cover;
    background-position: top center;
    background-repeat: no-repeat;
  }
}

body.homeadvisor-form {
  padding-top: 0;
}

body.homeadvisor-form #homeadvisor .frame {
  width: 100%;
  margin: 0 auto;
}

@media only screen and (min-width: 768px) {
  body.homeadvisor-form #homeadvisor .frame {
    max-width: 90%;
  }
}

@media only screen and (min-width: 1164px) {
  body.homeadvisor-form #homeadvisor .frame {
    max-width: 1134px;
  }
}

body.homeadvisor-form #homeadvisor .hidden {
  display: none;
}

@media only screen and (min-width: 768px) {
  body.homeadvisor-form #homeadvisor main {
    background-color: transparent;
  }
}

body.homeadvisor-form #homeadvisor .background {
  position: fixed;
  display: block;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: -2;
  background-color: #5d91db;
  background-image: url("https://s3-ap-southeast-1.amazonaws.com/files.theedgeproperty.com.my/public/images/homeadvisor/bg/homeadvisor-bg.jpg");
  background-size: cover;
}

@media screen and (max-width: 775px) {
  body.homeadvisor-form #homeadvisor .background {
    background-image: none;
  }
}

body.homeadvisor-form #homeadvisor fieldset {
  border: 0;
  margin: 0;
  padding: 0;
}

body.homeadvisor-form #homeadvisor .required__star {
  position: absolute;
  content: "*";
  padding-left: 3px;
  color: red;
}

@media only screen and (min-width: 768px) {
  body.homeadvisor-form #homeadvisor .required__star {
    right: 38px;
  }
}

body.homeadvisor-form #homeadvisor .form__wrapper {
  position: relative;
  z-index: 10;
  border-radius: 4px;
  padding: 7px;
  background-color: rgba(255, 255, 255, 0);
}

body.homeadvisor-form #homeadvisor .form__wrapper .form__block {
  border: 0;
  position: relative;
  margin-bottom: 15px;
  background-color: white;
}

body.homeadvisor-form #homeadvisor .form__wrapper .form__block .form__block__wrapper {
  display: inline-block;
  width: 100%;
  padding: 20px 10px 20px 10px;
}

@media only screen and (min-width: 768px) {
  body.homeadvisor-form #homeadvisor .form__wrapper .form__block .form__block__wrapper {
    padding: 38px;
  }
}

body.homeadvisor-form #homeadvisor .form__wrapper .form__block .form__block__wrapper h3 {
  margin-bottom: 10px;
  font-family: "Poppins", sans-serif;
  font-weight: normal;
  font-weight: lighter;
  font-size: 2rem;
  color: #363a94;
}

body.homeadvisor-form #homeadvisor .form__wrapper .form__block .form__block__wrapper h3:first-letter {
  text-transform: uppercase;
}

body.homeadvisor-form #homeadvisor .form__wrapper .form__block .form__block__wrapper h3 .block__sub__title {
  display: block;
  margin-top: 10px;
  font-size: 14px;
  font-family: "Poppins", sans-serif;
  font-weight: normal;
  text-decoration: inherit;
  color: inherit;
}

@media only screen and (min-width: 768px) {
  body.homeadvisor-form #homeadvisor .form__wrapper .form__block .form__block__wrapper h3 .block__sub__title {
    display: inline-block;
    margin-left: 5px;
  }
}

@media only screen and (max-width: 768px) {
  body.homeadvisor-form #homeadvisor .form__wrapper .form__block .form__block__wrapper h3 {
    margin-bottom: 32px;
    text-align: center;
  }
}

@media only screen and (min-width: 960px) {
  body.homeadvisor-form #homeadvisor .form__wrapper .form__block .form__block__wrapper .form__item .inputs {
    width: 54%;
  }
}

body.homeadvisor-form #homeadvisor .form__wrapper .form__block .form__block__wrapper .form__item .inputs .input__phone-type {
  display: inline-block;
  width: calc(50% - 5px);
  float: left;
}

@media only screen and (min-width: 960px) {
  body.homeadvisor-form #homeadvisor .form__wrapper .form__block .form__block__wrapper .form__item .inputs .input__phone-type {
    width: 115px;
  }
}

@media only screen and (min-width: 769px) {
  body.homeadvisor-form #homeadvisor .form__wrapper .form__block .form__block__wrapper .form__item .inputs .input__phone-type {
    width: 95px;
    float: none;
    margin-right: 10px;
  }
  body.homeadvisor-form #homeadvisor .form__wrapper .form__block .form__block__wrapper .form__item .inputs .input__phone-type select {
    width: inherit;
  }
}

@media only screen and (max-width: 425px) {
  body.homeadvisor-form #homeadvisor .form__wrapper .form__block .form__block__wrapper .form__item .inputs .input__phone-type {
    width: 48%;
    float: none;
    margin-right: 10px;
  }
  body.homeadvisor-form #homeadvisor .form__wrapper .form__block .form__block__wrapper .form__item .inputs .input__phone-type select {
    width: 79%;
  }
}

body.homeadvisor-form #homeadvisor .form__wrapper .form__block .form__block__wrapper .form__item .inputs .input__region-number {
  display: inline-block;
  width: calc(50% - 5px);
  float: right;
}

@media only screen and (min-width: 960px) {
  body.homeadvisor-form #homeadvisor .form__wrapper .form__block .form__block__wrapper .form__item .inputs .input__region-number {
    width: 90px;
  }
}

@media only screen and (min-width: 768px) {
  body.homeadvisor-form #homeadvisor .form__wrapper .form__block .form__block__wrapper .form__item .inputs .input__region-number {
    width: 70px;
    float: none;
    margin-right: 10px;
  }
}

body.homeadvisor-form #homeadvisor .form__wrapper .form__block .form__block__wrapper .form__item .inputs .input__phone-number,
body.homeadvisor-form #homeadvisor .form__wrapper .form__block .form__block__wrapper .form__item .inputs .input__mobile-number {
  display: inline-block;
  width: 100%;
  margin-top: 10px;
}

@media only screen and (min-width: 960px) {
  body.homeadvisor-form #homeadvisor .form__wrapper .form__block .form__block__wrapper .form__item .inputs .input__phone-number {
    width: calc(100% - 225px);
    max-width: 175px;
  }
}

@media only screen and (min-width: 769px) {
  body.homeadvisor-form #homeadvisor .form__wrapper .form__block .form__block__wrapper .form__item .inputs .input__phone-number {
    width: calc(100% - 185px);
    margin-top: 0;
    max-width: 210px;
  }
}

@media only screen and (max-width: 425px) {
  body.homeadvisor-form #homeadvisor .form__wrapper .form__block .form__block__wrapper .form__item .inputs .input__phone-number {
    width: calc(100% - 185px);
    width: 100%;
  }
}

@media only screen and (min-width: 769px) {
  body.homeadvisor-form #homeadvisor .form__wrapper .form__block .form__block__wrapper .form__item .inputs .input__mobile-number {
    margin-top: 0;
    max-width: 100%;
  }
}

@media only screen and (max-width: 768px) {
  body.homeadvisor-form #homeadvisor .form__wrapper .form__block .form__block__wrapper .form__item .inputs .input__mobile-number {
    margin-top: 0;
    width: 100%;
  }
}

body.homeadvisor-form #homeadvisor .form__wrapper .form__block .form__block__wrapper .form__item .inputs span.hide {
  display: none;
}

@media only screen and (min-width: 960px) {
  body.homeadvisor-form #homeadvisor .form__wrapper .form__block .form__block__wrapper .form__item .inputs input[type='radio'] + label.btn__radio {
    margin-right: 40px;
  }
}

@media only screen and (min-width: 480px) {
  body.homeadvisor-form #homeadvisor .form__wrapper .form__block .form__block__wrapper .form__item .inputs input[type='radio'] + label.btn__radio {
    font-size: 17px;
    position: relative;
    margin: 0 40px 0 30px;
    cursor: pointer;
    display: inline-block;
    padding: 0;
    border: 0;
    text-align: left;
    background-color: #fff;
    color: #494949;
  }
}

@media only screen and (min-width: 480px) {
  body.homeadvisor-form #homeadvisor .form__wrapper .form__block .form__block__wrapper .form__item .inputs input[type='radio'] + label.btn__radio.btn__radio--half {
    width: auto;
  }
}

@media only screen and (min-width: 480px) {
  body.homeadvisor-form #homeadvisor .form__wrapper .form__block .form__block__wrapper .form__item .inputs input[type='radio'] + label.btn__radio.btn__radio--half.btn__float {
    float: none;
  }
}

@media only screen and (min-width: 480px) {
  body.homeadvisor-form #homeadvisor .form__wrapper .form__block .form__block__wrapper .form__item .inputs input[type='radio'] + label.btn__radio.btn__radio--half.btn__radio--blocked {
    display: block;
    margin-bottom: 10px;
    margin-right: 0;
  }
}

@media only screen and (min-width: 480px) {
  body.homeadvisor-form #homeadvisor .form__wrapper .form__block .form__block__wrapper .form__item .inputs input[type='radio']:checked + label.btn__radio {
    border: 0;
    background-color: #fff;
    color: #494949;
  }
}

@media only screen and (min-width: 480px) {
  body.homeadvisor-form #homeadvisor .form__wrapper .form__block .form__block__wrapper .form__item .inputs.inputs__error input[type='radio'] + label.btn__radio {
    border: 0;
  }
}

@media only screen and (min-width: 480px) {
  body.homeadvisor-form #homeadvisor .form__wrapper .form__block .form__block__wrapper .form__item .inputs.inputs__error.btn__radio--half.btn__float {
    float: none;
  }
}

body.homeadvisor-form #homeadvisor .form__wrapper .form__block.transparent {
  background-image: none;
  background-color: transparent;
}

body.homeadvisor-form #homeadvisor .form__wrapper .form__block .form__item {
  position: relative;
  font-size: 0;
  margin: 15px auto 10px auto;
  float: left;
  width: 100%;
}

@media only screen and (min-width: 768px) {
  body.homeadvisor-form #homeadvisor .form__wrapper .form__block .form__item {
    margin: 15px auto;
  }
}

body.homeadvisor-form #homeadvisor .form__wrapper .form__block .form__item:after {
  content: "";
  display: table;
  clear: both;
}

body.homeadvisor-form #homeadvisor .form__wrapper .form__block .form__item .inputs {
  display: block;
  width: 100%;
}

@media only screen and (min-width: 769px) {
  body.homeadvisor-form #homeadvisor .form__wrapper .form__block .form__item .inputs {
    display: inline-table;
    width: 50%;
  }
}

@media only screen and (max-width: 768px) {
  body.homeadvisor-form #homeadvisor .form__wrapper .form__block .form__item .inputs {
    display: block;
    width: 100%;
  }
}

body.homeadvisor-form #homeadvisor .form__wrapper .form__block .form__item .inputs.inputs__radio {
  vertical-align: top;
}

body.homeadvisor-form #homeadvisor .form__wrapper .form__block .form__item .inputs.inputs__error select.error__style {
  border: 1px solid red;
}

body.homeadvisor-form #homeadvisor .form__wrapper .form__block .form__item .inputs.inputs__error input.error__style {
  border: 1px solid red;
}

body.homeadvisor-form #homeadvisor .form__wrapper .form__block .form__item .inputs.inputs__error input[type='radio'] + label.btn {
  border: 1px solid red;
}

body.homeadvisor-form #homeadvisor .form__wrapper .form__block .form__item .inputs.inputs__error input[type='radio'] + label.btn__radio--half.btn__float {
  float: right;
}

@media only screen and (min-width: 768px) {
  body.homeadvisor-form #homeadvisor .form__wrapper .form__block .form__item .inputs.inputs__error input[type='radio'] + label.btn__radio {
    margin-right: 25px;
  }
}

body.homeadvisor-form #homeadvisor .form__wrapper .form__block .form__item .inputs.inputs__error .error {
  position: relative;
  display: block;
  font-size: 17px;
  font-size: 1rem;
  color: red;
  margin-top: 5px;
  margin-left: 23px;
}

body.homeadvisor-form #homeadvisor .form__wrapper .form__block .form__item .inputs.inputs__error .error a {
  color: red;
}

@media only screen and (min-width: 768px) {
  body.homeadvisor-form #homeadvisor .form__wrapper .form__block .form__item .inputs.inputs__error .error a {
    text-decoration: none;
    cursor: default;
  }
}

@media only screen and (min-width: 768px) {
  body.homeadvisor-form #homeadvisor .form__wrapper .form__block .form__item .inputs.inputs__error .error {
    max-width: 380px;
  }
}

body.homeadvisor-form #homeadvisor .form__wrapper .form__block .form__item .label {
  position: relative;
  display: block;
  font-size: 17px;
  margin-bottom: 10px;
  font-family: "Poppins", sans-serif;
  font-weight: normal;
  color: #555;
}

@media only screen and (min-width: 769px) {
  body.homeadvisor-form #homeadvisor .form__wrapper .form__block .form__item .label {
    display: inline-block;
    width: 46%;
    text-align: right;
    padding-right: 50px;
    margin-bottom: 0;
    font-family: "Poppins", sans-serif;
    font-weight: normal;
  }
}

@media only screen and (max-width: 768px) {
  body.homeadvisor-form #homeadvisor .form__wrapper .form__block .form__item .label {
    width: 100%;
    text-align: center;
    display: block;
  }
}

body.homeadvisor-form #homeadvisor .form__wrapper .form__block .form__item .label:before {
  content: "";
  display: none;
}

body.homeadvisor-form #homeadvisor .form__wrapper .form__block .form__item .label.required:after {
  content: "*";
  padding-left: 3px;
  color: red;
}

@media only screen and (min-width: 768px) {
  body.homeadvisor-form #homeadvisor .form__wrapper .form__block .form__item .label.required:after {
    right: 38px;
  }
}

body.homeadvisor-form #homeadvisor .form__wrapper .form__block .form__item .label:first-letter {
  text-transform: uppercase;
}

body.homeadvisor-form #homeadvisor .form__wrapper .form__block .form__item .label span.required:after {
  position: absolute;
  content: "*";
  padding-left: 3px;
  color: red;
}

@media only screen and (min-width: 768px) {
  body.homeadvisor-form #homeadvisor .form__wrapper .form__block .form__item .label span.required:after {
    right: 38px;
  }
}

body.homeadvisor-form #homeadvisor .form__wrapper .form__block .form__item .label .item__sub__title {
  font-size: 12px;
  display: block;
  margin-top: 8px;
  margin-bottom: 20px;
  width: 95%;
  color: #747678;
  font-family: "Poppins", sans-serif;
  font-weight: normal;
}

@media screen and (min-width: 768px) {
  body.homeadvisor-form #homeadvisor .form__wrapper .form__block .form__item .label .item__sub__title {
    float: right;
    margin-bottom: 0;
  }
}

@media screen and (max-width: 425px) {
  body.homeadvisor-form #homeadvisor .form__wrapper .form__block .form__item .label .item__sub__title {
    display: none !important;
  }
}

body.homeadvisor-form #homeadvisor .form__wrapper .form__block .form__item fieldset .float__left {
  float: none;
}

@media only screen and (min-width: 768px) {
  body.homeadvisor-form #homeadvisor .form__wrapper .form__block .form__item fieldset .float__left {
    float: left;
  }
}

@media only screen and (min-width: 769px) {
  body.homeadvisor-form #homeadvisor .form__wrapper .form__block .form__item select {
    max-width: 400px;
  }
}

@media only screen and (man-width: 768px) {
  body.homeadvisor-form #homeadvisor .form__wrapper .form__block .form__item select {
    max-width: 100%;
  }
}

@media only screen and (min-width: 769px) {
  body.homeadvisor-form #homeadvisor .form__wrapper .form__block .form__item input[type='text'], body.homeadvisor-form #homeadvisor .form__wrapper .form__block .form__item input[type='tel'], body.homeadvisor-form #homeadvisor .form__wrapper .form__block .form__item input[type='number'], body.homeadvisor-form #homeadvisor .form__wrapper .form__block .form__item input[type='email'] {
    max-width: 400px;
  }
}

@media only screen and (max-width: 768px) {
  body.homeadvisor-form #homeadvisor .form__wrapper .form__block .form__item input[type='text'], body.homeadvisor-form #homeadvisor .form__wrapper .form__block .form__item input[type='tel'], body.homeadvisor-form #homeadvisor .form__wrapper .form__block .form__item input[type='number'], body.homeadvisor-form #homeadvisor .form__wrapper .form__block .form__item input[type='email'] {
    max-width: 100%;
  }
}

@media only screen and (min-width: 768px) {
  body.homeadvisor-form #homeadvisor .form__wrapper {
    padding: 17px;
    background-color: rgba(255, 255, 255, 0.7);
  }
}

body.homeadvisor-form #homeadvisor .block__plus {
  display: block;
  position: relative;
  margin-right: 10px;
}

body.homeadvisor-form #homeadvisor .block__plus .action__plus {
  cursor: pointer;
}

body.homeadvisor-form #homeadvisor .block__plus .action__plus span {
  position: absolute;
  width: 25px;
  height: 3px;
  background: #007dba;
  right: 0;
  bottom: 8px;
}

body.homeadvisor-form #homeadvisor .block__plus .action__plus span:last-child {
  -webkit-transform: rotate(90deg);
  -moz-transform: rotate(90deg);
  -ms-transform: rotate(90deg);
  transform: rotate(90deg);
}

@media only screen and (min-width: 768px) {
  body.homeadvisor-form #homeadvisor .block__plus {
    display: none;
  }
}

body.homeadvisor-form #homeadvisor .form_actions {
  display: none;
}

body.homeadvisor-form #homeadvisor .form_actions button {
  display: inline-block;
  width: 100px;
  padding: 10px;
  font-size: 17px;
  text-align: center;
  border-radius: 4px;
  cursor: pointer;
}

body.homeadvisor-form #homeadvisor .form_actions button.action__delete {
  margin-left: calc((60% - 100px)/2);
  color: white;
  background-color: #ca4d49;
  border: 1px solid #ca4d49;
}

@media only screen and (min-width: 768px) {
  body.homeadvisor-form #homeadvisor .form_actions button.action__delete {
    border: 0;
    color: #494949;
    background-color: #e5e5e5;
    margin: 0;
  }
}

body.homeadvisor-form #homeadvisor .form_actions button.action__confirm {
  margin-right: calc((60% - 100px)/2);
  color: white;
  background-color: #007dba;
  border: 1px solid #007dba;
}

@media only screen and (min-width: 768px) {
  body.homeadvisor-form #homeadvisor .form_actions button.action__confirm {
    border: 0;
    color: #494949;
    background-color: #e5e5e5;
    margin: 0;
  }
}

body.homeadvisor-form #homeadvisor .form_actions button:last-child {
  float: right;
}

@media only screen and (min-width: 768px) {
  body.homeadvisor-form #homeadvisor .form_actions button {
    width: calc(50% - 1px);
    padding: 12px;
    border-radius: 0;
    border: 0;
    color: #494949;
    background-color: #e5e5e5;
    font-weight: 600;
  }
  body.homeadvisor-form #homeadvisor .form_actions button:hover {
    color: #363a94;
    background-color: #d8d9f1;
  }
  body.homeadvisor-form #homeadvisor .form_actions button.single {
    width: 100%;
  }
}

@media only screen and (min-width: 768px) {
  body.homeadvisor-form #homeadvisor .form_actions {
    display: block;
  }
}

body.homeadvisor-form #homeadvisor .form__block .disclaimer {
  margin-top: 10px;
  color: #747678;
}

body.homeadvisor-form #homeadvisor .form__block .disclaimer p {
  margin-bottom: 10px;
}

body.homeadvisor-form #homeadvisor .form__block .disclaimer p:last-child {
  margin-bottom: 0;
}

@media only screen and (min-width: 768px) {
  body.homeadvisor-form #homeadvisor .form__block .disclaimer {
    margin-top: 0;
  }
}

body.homeadvisor-form #homeadvisor .header_title {
  text-align: center;
  margin: 25px 0 20px 0;
}

body.homeadvisor-form #homeadvisor .header_title_text .header_title_text_wrapper {
  width: 80%;
  margin: 0 auto;
  font-family: "Poppins", sans-serif;
  font-weight: normal;
  font-weight: lighter;
}

body.homeadvisor-form #homeadvisor .header_title_text .header_title_text_wrapper .text_primary {
  font-size: 26px;
  text-transform: capitalize;
  color: #fff;
  line-height: 40px;
  font-weight: 600;
  margin: 0;
}

body.homeadvisor-form #homeadvisor .header_title_text .header_title_text_wrapper .text_primary span {
  display: block;
  font-size: 17px;
  font-weight: 400;
  line-height: 24px;
  text-transform: initial;
}

@media only screen and (min-width: 768px) {
  body.homeadvisor-form #homeadvisor .header_title_text .header_title_text_wrapper {
    width: 600px;
    margin: 0 0 0 15px;
  }
}

@media only screen and (min-width: 768px) {
  body.homeadvisor-form #homeadvisor .header_title {
    background-color: transparent;
    text-align: left;
    margin: 40px 0;
  }
}

body.homeadvisor-form #homeadvisor .errorpage_container {
  height: calc(100vh - 500px);
  text-align: center;
  display: table;
  width: 100%;
  min-height: 400px;
  position: relative;
  z-index: 10;
}

body.homeadvisor-form #homeadvisor .errorpage_container .errorpage_wrapper {
  display: table-cell;
  vertical-align: middle;
}

body.homeadvisor-form #homeadvisor .errorpage_container .errorpage_wrapper .errorpage_title {
  margin-bottom: 15px;
}

@media only screen and (min-width: 768px) {
  body.homeadvisor-form #homeadvisor .errorpage_container .errorpage_wrapper .errorpage_title {
    margin-bottom: 30px;
  }
}

body.homeadvisor-form #homeadvisor .errorpage_container .errorpage_wrapper .errorpage_discription {
  width: 83%;
  margin: 0 auto;
  margin-bottom: 35px;
}

@media only screen and (min-width: 768px) {
  body.homeadvisor-form #homeadvisor .errorpage_container .errorpage_wrapper .errorpage_discription {
    margin-bottom: 60px;
  }
}

body.homeadvisor-form #homeadvisor .errorpage_container .errorpage_wrapper .errorpage_backlink {
  color: #007dba;
  text-decoration: inherit;
  display: inline-block;
  border: 0;
  background: inherit;
  cursor: pointer;
}

body.homeadvisor-form #homeadvisor .errorpage_container .errorpage_wrapper .errorpage_backlink:focus {
  color: #007dba;
}

@media only screen and (min-width: 768px) {
  body.homeadvisor-form #homeadvisor .errorpage_container .errorpage_wrapper .errorpage_backlink {
    margin-bottom: 45px;
  }
}

@media only screen and (min-width: 768px) {
  body.homeadvisor-form #homeadvisor .errorpage_container {
    height: calc(100vh - 250px);
  }
}

@media only screen and (min-width: 960px) {
  body.homeadvisor-form #homeadvisor .errorpage_container {
    height: calc(100vh - 262px);
  }
}

body.homeadvisor-form #homeadvisor .form__wrapper {
  padding: 25px 20px 10px 20px;
}

body.homeadvisor-form #homeadvisor .form__wrapper .form__block .form__success {
  text-align: center;
}

body.homeadvisor-form #homeadvisor .form__wrapper .form__block .form__success h3 {
  text-align: center;
  color: #363a94;
}

body.homeadvisor-form #homeadvisor .form__wrapper .form__block .form__success p, body.homeadvisor-form #homeadvisor .form__wrapper .form__block .form__success .normal_buyer, body.homeadvisor-form #homeadvisor .form__wrapper .form__block .form__success .fhb_buyer {
  text-align: center;
  margin: 20px auto 0 auto;
}

@media only screen and (min-width: 480px) {
  body.homeadvisor-form #homeadvisor .form__wrapper .form__block .form__success p {
    width: 80%;
  }
}

@media only screen and (min-width: 768px) {
  body.homeadvisor-form #homeadvisor .form__wrapper .form__block .form__success p {
    width: 80%;
  }
}

@media only screen and (min-width: 1164px) {
  body.homeadvisor-form #homeadvisor .form__wrapper .form__block .form__success p {
    width: 50%;
  }
}

@media only screen and (min-width: 480px) {
  body.homeadvisor-form #homeadvisor .form__wrapper .form__block .form__success .normal_buyer, body.homeadvisor-form #homeadvisor .form__wrapper .form__block .form__success .fhb_buyer {
    width: 80%;
  }
}

@media only screen and (min-width: 768px) {
  body.homeadvisor-form #homeadvisor .form__wrapper .form__block .form__success .normal_buyer, body.homeadvisor-form #homeadvisor .form__wrapper .form__block .form__success .fhb_buyer {
    width: 80%;
  }
}

@media only screen and (min-width: 1164px) {
  body.homeadvisor-form #homeadvisor .form__wrapper .form__block .form__success .normal_buyer, body.homeadvisor-form #homeadvisor .form__wrapper .form__block .form__success .fhb_buyer {
    width: 50%;
  }
}

body.homeadvisor-form #homeadvisor .form__wrapper .form__block .form--confirmation {
  width: 50px;
  height: 50px;
  margin: 25px auto;
}

body.homeadvisor-form #homeadvisor .form__wrapper .form__block .form__error h3 {
  text-align: center;
  color: #363a94;
}

body.homeadvisor-form #homeadvisor .form__wrapper .form__block .form__error p {
  text-align: center;
  margin: 20px auto 10px auto;
}

body.homeadvisor-form #homeadvisor .form__wrapper .form__block .form__error p a {
  white-space: nowrap;
}

@media only screen and (min-width: 320px) {
  body.homeadvisor-form #homeadvisor .form__wrapper .form__block .form__error p {
    width: 78%;
  }
}

@media only screen and (min-width: 768px) {
  body.homeadvisor-form #homeadvisor .form__wrapper .form__block .form__error p {
    width: 80%;
  }
}

@media only screen and (min-width: 1164px) {
  body.homeadvisor-form #homeadvisor .form__wrapper .form__block .form__error p {
    width: 37%;
  }
}

body.homeadvisor-form #homeadvisor .form__wrapper .form__block .form__error a {
  color: #007dba;
  text-decoration: none;
}

body.homeadvisor-form #homeadvisor .form__wrapper .form__block .form__error a:hover {
  text-decoration: underline;
}

body.homeadvisor-form #homeadvisor .form__wrapper .form__block .form--error {
  width: 50px;
  height: 50px;
  margin: 25px auto;
}

body.homeadvisor-form #homeadvisor .error__actions button {
  width: 50%;
  border: 0;
  display: inline-block;
  background-color: #e4e4e4;
  padding: 15px;
  text-align: center;
  cursor: pointer;
  color: #313131;
  font-size: 18px;
}

body.homeadvisor-form #homeadvisor .error__actions.return__action {
  text-align: center;
  padding: 25px 0 10px 0;
}

body.homeadvisor-form #homeadvisor .error__actions.return__action .error_button {
  text-align: center;
  cursor: pointer;
  display: inline-block;
  border-style: solid;
  border-width: thin;
  text-decoration: none;
  border-color: #007dba;
  background-color: white;
  color: #007dba;
  border-radius: 4px;
  padding: 10px;
  width: 120px;
  max-width: 80%;
}

body.homeadvisor-form #homeadvisor .address__ui {
  display: inline-block;
  width: 100%;
  position: relative;
}

@media only screen and (min-width: 769px) {
  body.homeadvisor-form #homeadvisor .address__ui {
    display: inline-table;
    width: 50%;
  }
}

@media only screen and (max-width: 768px) {
  body.homeadvisor-form #homeadvisor .address__ui {
    display: block;
    width: 100%;
  }
}

@media only screen and (min-width: 960px) {
  body.homeadvisor-form #homeadvisor .address__ui {
    width: 54%;
  }
}

body.homeadvisor-form #homeadvisor .report-success h3, body.homeadvisor-form #homeadvisor .report-success span, body.homeadvisor-form #homeadvisor .report-success p {
  text-align: center;
}

body.homeadvisor-form #homeadvisor .report-success h3 {
  margin-bottom: 20px;
}

@media screen and (min-width: 991px) {
  body.homeadvisor-form #homeadvisor .report-success p {
    width: 50%;
    margin: 0 auto;
  }
}

body.homeadvisor-form #homeadvisor .report-success span.confirmed {
  background: #30ac30;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  color: #fff;
  font-size: 21px;
  padding-top: 13px;
  margin: 0 auto 20px;
  display: block;
}

body.homeadvisor-form #homeadvisor .general-block {
  font-size: 17px;
  color: #555;
}

body.homeadvisor-form #homeadvisor .general-block .small {
  margin-top: 10px;
  display: block;
  font-size: 12px;
  color: #747678;
}

body.homeadvisor-form #homeadvisor .general-block label {
  cursor: pointer;
}

body.homeadvisor-form #homeadvisor .submit__action {
  text-align: right;
}

@media screen and (max-width: 425px) {
  body.homeadvisor-form #homeadvisor .submit__action .btn {
    width: 100%;
  }
}

body.homeadvisor-form #homeadvisor label {
  font-size: 17px;
}

body.homeadvisor-form #homeadvisor input[type='text'], body.homeadvisor-form #homeadvisor input[type='tel'], body.homeadvisor-form #homeadvisor input[type='number'], body.homeadvisor-form #homeadvisor input[type='email'] {
  width: 100%;
  padding: 8px;
  font-size: 17px;
}

body.homeadvisor-form #homeadvisor select {
  width: 100%;
  font-size: 17px;
  padding: 9px 10px;
  border: 1px solid #bcb8b8;
}

body.homeadvisor-form #homeadvisor #hiddenLanded {
  font-size: 14px;
  color: black;
}

body span.logo-home-advisor img {
  max-width: 210px;
  margin: 0 0 10px 0;
}

#homeadvisor .property-listing .listing-box {
  margin-bottom: 25px;
}

#homeadvisor .property-listing .listing-box span {
  display: block;
  font-size: 12px;
  color: #666;
}

#homeadvisor .property-listing .listing-box span.img-listing img {
  width: 100%;
}

#homeadvisor .property-listing .listing-box span.prop-name {
  font-weight: 600;
  font-size: 13px;
  margin-bottom: 10px;
}

#homeadvisor .property-listing .listing-box span.location {
  color: #888;
}

#homeadvisor .property-listing .listing-box span.type {
  color: #888;
}

#homeadvisor .property-listing .listing-box span.price {
  font-weight: 600;
  font-size: 13px;
  margin: 10px 0;
}

#homeadvisor .property-listing .listing-box span.price span.permonth {
  font-size: 11px;
  display: inline-block;
  font-weight: 200;
}

#homeadvisor .property-listing .listing-box-info {
  padding: 15px;
  background: #f5f5f5;
}

@media screen and (max-width: 425px) {
  #homeadvisor .property-listing .col-xs-6 {
    width: 100%;
  }
}

@media screen and (min-width: 992px) and (max-width: 1170px) {
  #homeadvisor .property-listing .col-md-3 {
    width: 33.333%;
  }
}

@media screen and (min-width: 769px) and (max-width: 815px) {
  #homeadvisor .property-listing .col-sm-4 {
    width: 50%;
  }
}

#homeadvisor .address-wrapper {
  float: left;
  width: 400px;
}

@media screen and (max-width: 768px) {
  #homeadvisor .address-wrapper {
    width: 100%;
  }
}

#homeadvisor #results,
#homeadvisor #nonlanded-results,
#homeadvisor #area-results {
  display: none;
  position: absolute;
  background-color: #f1f1f1;
  min-width: 400px;
  max-width: 400px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
}

@media screen and (min-width: 1024px) {
  #homeadvisor #results,
  #homeadvisor #nonlanded-results,
  #homeadvisor #area-results {
    top: 42px;
  }
}

@media screen and (max-width: 768px) {
  #homeadvisor #results,
  #homeadvisor #nonlanded-results,
  #homeadvisor #area-results {
    max-width: 100%;
    width: 100%;
    min-width: 100%;
  }
}

#homeadvisor #results li,
#homeadvisor #nonlanded-results li,
#homeadvisor #area-results li {
  color: black;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
}

#homeadvisor #results li.list-search,
#homeadvisor #nonlanded-results li.list-search,
#homeadvisor #area-results li.list-search {
  list-style-type: none;
  padding: 3px;
  margin: 5px;
  font-size: 14px;
}

#homeadvisor #results li.list-search:hover,
#homeadvisor #nonlanded-results li.list-search:hover,
#homeadvisor #area-results li.list-search:hover {
  background: #363a94;
  cursor: pointer;
  color: #fff;
}

#homeadvisor {
  margin-top: 81px;
}

#homeadvisor body {
  background-color: #fff;
  background-position: top center;
  background-repeat: no-repeat;
  overflow-y: scroll;
  font-size: 1rem;
  line-height: 1.5rem;
  font-family: 'Montserrat', sans-serif;
}

#homeadvisor h1,
#homeadvisor h2,
#homeadvisor h3,
#homeadvisor h4 {
  font-weight: 500;
}

#homeadvisor h1,
#homeadvisor h2,
#homeadvisor h3,
#homeadvisor h4,
#homeadvisor h5,
#homeadvisor h6 {
  margin: 0;
  padding: 0;
  -webkit-animation: fader .5s ease-in;
  -o-animation: fader .5s ease-in;
  animation: fader .5s ease-in;
}

#homeadvisor h2,
#homeadvisor h3,
#homeadvisor h4 {
  margin-bottom: calc(1.3380909902vw + 10.72px);
}

#homeadvisor h1 {
  font-size: calc(1.6057091882vw + 28.86px);
  margin-bottom: calc(1.6057091882vw + 6.86px);
}

@media screen and (max-width: 767px) {
  #homeadvisor h1 {
    font-size: 2.4rem;
  }
}

#homeadvisor h2 {
  font-size: calc(1.6057091882vw + 28.86px);
}

#homeadvisor h3 {
  font-size: calc(1.0704727921vw + 18.57px);
}

#homeadvisor h4 {
  font-size: calc(1.0704727921vw + 12.57px);
  font-weight: 500;
}

#homeadvisor h5 {
  font-size: calc(.6244424621vw + 11px);
  margin-bottom: calc(.356824264vw + 4.86px);
  font-weight: 400;
}

#homeadvisor h6 {
  font-size: calc(.267618198vw + 12.14px);
  font-weight: 100;
}

#homeadvisor .btn,
#homeadvisor a.non,
#homeadvisor a.non:active,
#homeadvisor a.non:focus,
#homeadvisor a.non:hover {
  text-decoration: none;
}

#homeadvisor .container-fluid.padd-both-sm,
#homeadvisor .container.padd-both-sm,
#homeadvisor .row.padd-both-sm,
#homeadvisor [class^=col-].padd-both-sm {
  padding-top: calc(1.3380909902vw + 10.72px);
  padding-bottom: calc(1.3380909902vw + 10.72px);
}

#homeadvisor .container-fluid.padd-both-md,
#homeadvisor .container.padd-both-md,
#homeadvisor .row.padd-both-md,
#homeadvisor [class^=col-].padd-both-md {
  padding-top: calc(2.2301516503vw + 17.86px);
  padding-bottom: calc(2.2301516503vw + 17.86px);
}

#homeadvisor .container-fluid.padd-both-lg,
#homeadvisor .container.padd-both-lg,
#homeadvisor .row.padd-both-lg,
#homeadvisor [class^=col-].padd-both-lg {
  padding-top: calc(3.5682426405vw + 28.58px);
  padding-bottom: calc(3.5682426405vw + 28.58px);
}

#homeadvisor .container-fluid.padd-top-sm,
#homeadvisor .container.padd-top-sm,
#homeadvisor .row.padd-top-sm,
#homeadvisor [class^=col-].padd-top-sm {
  padding-top: calc(1.3380909902vw + 10.72px);
}

#homeadvisor .container-fluid.padd-top-md,
#homeadvisor .container.padd-top-md,
#homeadvisor .row.padd-top-md,
#homeadvisor [class^=col-].padd-top-md {
  padding-top: calc(2.2301516503vw + 17.86px);
}

#homeadvisor .container-fluid.padd-top-lg,
#homeadvisor .container.padd-top-lg,
#homeadvisor .row.padd-top-lg,
#homeadvisor [class^=col-].padd-top-lg {
  padding-top: calc(3.5682426405vw + 28.58px);
}

#homeadvisor .container-fluid.padd-bottom-sm,
#homeadvisor .container.padd-bottom-sm,
#homeadvisor .row.padd-bottom-sm,
#homeadvisor [class^=col-].padd-bottom-sm {
  padding-bottom: calc(1.3380909902vw + 10.72px);
}

#homeadvisor .container-fluid.padd-bottom-md,
#homeadvisor .container.padd-bottom-md,
#homeadvisor .row.padd-bottom-md,
#homeadvisor [class^=col-].padd-bottom-md {
  padding-bottom: calc(2.2301516503vw + 17.86px);
}

#homeadvisor .container-fluid.padd-bottom-lg,
#homeadvisor .container.padd-bottom-lg,
#homeadvisor .row.padd-bottom-lg,
#homeadvisor [class^=col-].padd-bottom-lg {
  padding-bottom: calc(3.5682426405vw + 28.58px);
}

#homeadvisor a {
  color: #005c98;
  cursor: pointer;
}

#homeadvisor a:active, #homeadvisor a:focus, #homeadvisor a:hover {
  color: #004979;
  -moz-transition-property: background-color, border-color;
  -o-transition-property: background-color, border-color;
  -webkit-transition-property: background-color, border-color;
  transition-property: background-color, border-color;
  -moz-transition-timing-function: ease-in-out;
  -o-transition-timing-function: ease-in-out;
  -webkit-transition-timing-function: ease-in-out;
  transition-timing-function: ease-in-out;
  -moz-transition-duration: .2s;
  -o-transition-duration: .2s;
  -webkit-transition-duration: .2s;
  transition-duration: .2s;
}

#homeadvisor a:focus {
  outline: 0;
}

#homeadvisor a:focus.key-focus {
  outline-offset: -1px;
  outline: #bce9fe solid 1px !important;
  border-color: #bce9fe;
  box-shadow: 0 0 10px #bce9fe;
}

#homeadvisor a.link {
  color: #005c98;
  text-decoration: underline;
}

#homeadvisor a.link.white {
  color: #fff;
}

#homeadvisor a.link.blue {
  color: #005c98;
}

#homeadvisor nav .branding .title,
#homeadvisor nav .nav-list a {
  text-transform: uppercase;
}

#homeadvisor .page .home .cont-four ul,
#homeadvisor .page .home .cont-four ul li,
#homeadvisor .page .home .section-intro ul,
#homeadvisor .page .home .section-intro ul li,
#homeadvisor .page .home .cont-seven ul,
#homeadvisor .page .home .cont-seven ul li,
#homeadvisor nav ol.nav-list,
#homeadvisor nav ol.nav-list li,
#homeadvisor nav ul.nav-list,
#homeadvisor nav ul.nav-list li,
#homeadvisor nav.sub ol.navbar-nav,
#homeadvisor nav.sub ol.navbar-nav li,
#homeadvisor nav.sub ul.navbar-nav,
#homeadvisor nav.sub ul.navbar-nav li,
#homeadvisor ol.nostyle,
#homeadvisor ol.nostyle li,
#homeadvisor ul.nostyle,
#homeadvisor ul.nostyle li {
  list-style-type: none;
  margin: 0;
  padding: 0;
}

#homeadvisor article,
#homeadvisor section {
  border-top: 6px solid #fff;
  z-index: 10;
}

#homeadvisor article {
  color: #fff;
  overflow: hidden;
}

#homeadvisor section {
  background-color: #fff;
}

#homeadvisor .page {
  height: 100%;
}

#homeadvisor .page .home h4 {
  color: #005c98;
}

#homeadvisor .page .home .photo-one {
  height: 350px;
  background-color: transparent;
}

#homeadvisor .page .home .section-intro {
  color: #666;
  background-color: #fff;
}

#homeadvisor .page .home .section-intro .report-point {
  margin: -1px 10px 0 0;
  font-size: 1.1rem;
  color: #005c98;
}

#homeadvisor .page .home .section-intro ul {
  margin-bottom: 20px;
}

#homeadvisor .page .home .section-intro ul li {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  -moz-align-items: center;
  align-items: center;
  padding: 10px 0;
  font-weight: 400;
}

#homeadvisor .page .home .section-intro ul li .icon {
  color: #005c98;
  font-size: 180%;
  margin: 0 15px;
}

#homeadvisor .page .home .section-general {
  color: #666;
  background-color: #f5f5f5;
  z-index: 1;
}

#homeadvisor .page .home .section-general .sectbox {
  -moz-transition: opacity .22s ease-in-out;
  -o-transition: opacity .22s ease-in-out;
  -webkit-transition: opacity .22s ease-in-out;
  transition: opacity .22s ease-in-out;
}

#homeadvisor .page .home .section-general .sectbox .sect-block {
  position: relative;
  background-color: #fff;
  height: calc(.8920606601vw + 387.15px);
  padding-top: calc(2.6761819804vw + 11.44px);
  padding-bottom: calc(2.6761819804vw + 11.44px);
  padding-left: calc(1.7841213202vw + 4.29px);
  padding-right: calc(1.7841213202vw + 4.29px);
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  border-radius: 10px;
  -webkit-box-shadow: 1px 1px 12px 4px rgba(0, 0, 0, 0.1);
  -moz-box-shadow: 1px 1px 12px 4px rgba(0, 0, 0, 0.1);
  box-shadow: 1px 1px 12px 4px rgba(0, 0, 0, 0.1);
  -moz-transition: all .22s ease-in-out;
  -o-transition: all .22s ease-in-out;
  -webkit-transition: all .22s ease-in-out;
  transition: all .22s ease-in-out;
  -webkit-opacity: 1;
  -moz-opacity: 1;
  opacity: 1;
  filter: alpha(opacity=100);
  margin-bottom: 30px;
}

#homeadvisor .page .home .section-general .sectbox .sect-block .icon {
  color: #005c98;
  font-size: calc(.7136485281vw + 21.72px);
  margin-bottom: calc(.4460303301vw + 13.57px);
}

#homeadvisor .page .home .section-general .sectbox .sect-block h4 {
  font-size: calc(.4460303301vw + 14.57px);
}

#homeadvisor .page .home .section-general .sectbox .sect-block h4 span {
  display: block;
}

#homeadvisor .page .home .section-general .sectbox .sect-block .btn {
  position: absolute;
  bottom: calc(2.6761819804vw + 11.44px);
  left: 50%;
  margin-left: -90px;
  min-width: 180px;
}

#homeadvisor .page .home .section-general .sectbox .sect-block.hover {
  -webkit-transform: scale(1.03);
  -moz-transform: scale(1.03);
  -ms-transform: scale(1.03);
  -o-transform: scale(1.03);
  transform: scale(1.03);
}

#homeadvisor .page .home .section-general .sectbox .sect-block.hover.opacity {
  -webkit-opacity: 1;
  -moz-opacity: 1;
  opacity: 1;
  filter: alpha(opacity=100);
}

#homeadvisor .page .home .section-general .sectbox .sect-block.opacity {
  -webkit-opacity: .5;
  -moz-opacity: .5;
  opacity: .5;
  filter: alpha(opacity=50);
}

#homeadvisor .page .home .section-general.bg-white {
  background: #fff;
}

#homeadvisor .page article.home {
  color: #fff;
  border-top: 0;
  margin-bottom: 30px;
}

#homeadvisor .page article.home [class^=col-] {
  height: 195px;
  padding: 0;
}

#homeadvisor .page article.home [class^=col-] > .row {
  margin: 0;
}

#homeadvisor .page article.home [class^=col-] > a:not(.btn) {
  position: relative;
  display: block;
  width: 100%;
  height: 100%;
  padding: 0;
  color: #fff;
  text-decoration: none;
  overflow: hidden;
  z-index: 1;
}

#homeadvisor .page article.home [class^=col-] > a:not(.btn):hover .menu-image {
  -webkit-transform: scale(1.04);
  -moz-transform: scale(1.04);
  -ms-transform: scale(1.04);
  -o-transform: scale(1.04);
  transform: scale(1.04);
}

#homeadvisor .page article.home [class^=col-] .menu-bg {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.43);
  z-index: 2;
  -webkit-opacity: 0;
  -moz-opacity: 0;
  opacity: 0;
  filter: alpha(opacity=0);
  -moz-transition: all .3s ease-in-out;
  -o-transition: all .3s ease-in-out;
  -webkit-transition: all .3s ease-in-out;
  transition: all .3s ease-in-out;
}

#homeadvisor .page article.home [class^=col-] .menu-image {
  position: relative;
  width: 100%;
  height: 100%;
  -webkit-transform: scale(1);
  -moz-transform: scale(1);
  -ms-transform: scale(1);
  -o-transform: scale(1);
  transform: scale(1);
  -moz-transition: all .3s ease-in-out;
  -o-transition: all .3s ease-in-out;
  -webkit-transition: all .3s ease-in-out;
  transition: all .3s ease-in-out;
  overflow: hidden;
  z-index: 1;
}

#homeadvisor .page article.home [class^=col-] .menu-text {
  position: absolute;
  left: 15px;
  bottom: 0;
  width: 80%;
  color: #fff;
  padding-bottom: 30px;
  -moz-transition: all .3s ease-in-out;
  -o-transition: all .3s ease-in-out;
  -webkit-transition: all .3s ease-in-out;
  transition: all .3s ease-in-out;
  z-index: 2;
}

#homeadvisor .page article.home [class^=col-] .menu-text h1 {
  color: #fff;
  line-height: 1.1;
}

#homeadvisor .page article.home [class^=col-] .menu-text h5 {
  display: none;
  margin-bottom: calc(1.3380909902vw + 5.72px);
}

#homeadvisor .page article.home [class^=col-] .menu-text h4 {
  color: #fff;
  margin-bottom: calc(.4460303301vw + 25px);
  text-shadow: 0 0 8px rgba(0, 0, 0, 0.3);
  line-height: 1.1;
}

@media screen and (max-width: 425px) {
  #homeadvisor .page article.home [class^=col-] .menu-text h4 {
    margin-bottom: calc(.4460303301vw + 40px);
  }
}

@media screen and (max-width: 991px) {
  #homeadvisor .page article.home [class^=col-] .menu-text h4 {
    margin-bottom: calc(.4460303301vw + 52px);
  }
}

#homeadvisor .page article.home [class^=col-] .menu-text h6 {
  position: absolute;
  -moz-transition: all .3s ease-in-out;
  -o-transition: all .3s ease-in-out;
  -webkit-transition: all .3s ease-in-out;
  transition: all .3s ease-in-out;
}

@media screen and (max-width: 425px) {
  #homeadvisor .page article.home [class^=col-] .menu-text h6 {
    bottom: 20px;
  }
}

#homeadvisor .page article.home [class^=col-] .menu-text h6.icon-angle-right:before {
  position: absolute;
  top: 2px;
  right: -20px;
  bottom: auto;
  left: auto;
}

#homeadvisor .page article.home .first {
  height: 220px;
}

#homeadvisor .page article.home .first .menu-text {
  left: 15px;
}

#homeadvisor .page article.home.complete .menu-bg {
  -webkit-opacity: .5;
  -moz-opacity: .5;
  opacity: .5;
  filter: alpha(opacity=50);
}

#homeadvisor .page article.home .box-1 {
  background-color: #a5a9ac;
}

#homeadvisor .page article.home .box-1 .menu-image {
  background-image: url("https://media.edgeprop.my/page/home-advisor/ha-main-1.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}

#homeadvisor .page article.home .box-1 h1 span.main-1-sub {
  display: block;
  font-size: 3rem;
  margin-top: 15px;
}

@media screen and (max-width: 767px) {
  #homeadvisor .page article.home .box-1 h1 span.main-1-sub {
    font-size: 1.4rem;
  }
}

#homeadvisor .page article.home .box-2 {
  background-color: #bdb1b1;
}

#homeadvisor .page article.home .box-2 .menu-image {
  background-image: url("https://media.edgeprop.my/page/home-advisor/ha-main-2.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}

#homeadvisor .page article.home .box-5 {
  background-color: #c1e672;
}

#homeadvisor .page article.home .box-5 .menu-image {
  background-image: url("https://media.edgeprop.my/page/home-advisor/ha-main-5.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}

#homeadvisor .page article.home .box-3 {
  background-color: #ddd;
}

#homeadvisor .page article.home .box-3 .menu-image {
  background-image: url("https://media.edgeprop.my/page/home-advisor/ha-main-3.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}

#homeadvisor .page article.home .box-6 {
  background-color: #005c98;
}

#homeadvisor .page article.home .box-6 .menu-image {
  background-image: url("https://media.edgeprop.my/page/home-advisor/ha-main-6.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}

#homeadvisor .page article.home .box-4 {
  background-color: #cbd4d1;
}

#homeadvisor .page article.home .box-4 h5 {
  display: block !important;
}

#homeadvisor .page article.home .box-4 .menu-image {
  background-image: url("https://media.edgeprop.my/page/home-advisor/ha-main-4.jpg");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

#homeadvisor .page article.home .box-4.first {
  height: 320px;
}

#homeadvisor .page article.social {
  background-color: #005c98;
  color: #fff;
  border-top: 0;
  margin-top: 60px;
}

#homeadvisor .page article.social .container {
  position: relative;
  z-index: 3;
}

#homeadvisor .page article.social .container h5 {
  position: relative;
  display: inline-block;
  font-size: calc(.5352363961vw + 16.29px);
  margin: 0;
  font-weight: 100;
}

#homeadvisor .page article.social .container h6 {
  position: relative;
  display: inline-block;
}

#homeadvisor .page article.social .container h5.icon-angle-right:before {
  position: absolute;
  top: 2px;
  right: -20px;
  bottom: auto;
  left: auto;
}

#homeadvisor .page article.social .container h6.icon-angle-right:before {
  position: absolute;
  top: 2px;
  right: -20px;
  bottom: auto;
  left: auto;
}

#homeadvisor .page article.social .container .copy {
  margin-top: calc(7.136485281vw + 37.16px);
  margin-bottom: calc(1.7841213202vw + 54.29px);
  -webkit-animation: fader .8s forwards;
  -o-animation: fader .8s forwards;
  animation: fader .8s forwards;
  opacity: 0;
  -moz-transition: opacity .3s ease-in-out;
  -o-transition: opacity .3s ease-in-out;
  -webkit-transition: opacity .3s ease-in-out;
  transition: opacity .3s ease-in-out;
}

#homeadvisor .page article.social .container .copy:nth-of-type(1) {
  -webkit-animation-delay: .8s;
  animation-delay: .8s;
}

#homeadvisor .page article.social .container .copy:nth-of-type(2) {
  -webkit-animation-delay: 1.6s;
  animation-delay: 1.6s;
}

#homeadvisor .page article.social .container .copy:nth-of-type(3) {
  -webkit-animation-delay: 2.4s;
  animation-delay: 2.4s;
}

#homeadvisor .page article.social .container .copy:nth-of-type(4) {
  -webkit-animation-delay: 3.2s;
  animation-delay: 3.2s;
}

#homeadvisor .page article.social .container .copy:nth-of-type(5) {
  -webkit-animation-delay: 4s;
  animation-delay: 4s;
}

#homeadvisor .page article.social .container .copy:nth-of-type(6) {
  -webkit-animation-delay: 4.8s;
  animation-delay: 4.8s;
}

#homeadvisor .page article.social .container .copy:nth-of-type(7) {
  -webkit-animation-delay: 5.6s;
  animation-delay: 5.6s;
}

#homeadvisor .page article.social .container .copy:nth-of-type(8) {
  -webkit-animation-delay: 6.4s;
  animation-delay: 6.4s;
}

#homeadvisor .page article.social .container .copy:nth-of-type(9) {
  -webkit-animation-delay: 7.2s;
  animation-delay: 7.2s;
}

#homeadvisor .page article.social .container .copy:nth-of-type(10) {
  -webkit-animation-delay: 8s;
  animation-delay: 8s;
}

#homeadvisor .page article.social .container .copy.delay-1 {
  -webkit-animation-delay: .8s;
  animation-delay: .8s;
}

#homeadvisor .page article.social .container .copy.delay-2 {
  -webkit-animation-delay: 1.6s;
  animation-delay: 1.6s;
}

#homeadvisor .page article.social .container .copy.delay-3 {
  -webkit-animation-delay: 2.4s;
  animation-delay: 2.4s;
}

#homeadvisor .page article.social .container .copy.delay-4 {
  -webkit-animation-delay: 3.2s;
  animation-delay: 3.2s;
}

#homeadvisor .page article.social .container .copy.delay-5 {
  -webkit-animation-delay: 4s;
  animation-delay: 4s;
}

#homeadvisor .page article.social .container .copy.delay-6 {
  -webkit-animation-delay: 4.8s;
  animation-delay: 4.8s;
}

#homeadvisor .page article.social .container .copy.delay-7 {
  -webkit-animation-delay: 5.6s;
  animation-delay: 5.6s;
}

#homeadvisor .page article.social .container .copy.delay-8 {
  -webkit-animation-delay: 6.4s;
  animation-delay: 6.4s;
}

#homeadvisor .page article.social .container .copy.delay-9 {
  -webkit-animation-delay: 7.2s;
  animation-delay: 7.2s;
}

#homeadvisor .page article.social .container .copy.delay-10 {
  -webkit-animation-delay: 8s;
  animation-delay: 8s;
}

#homeadvisor .page article.social .tile {
  height: calc(4.4603033006vw + 185.73px);
  padding: 0;
}

#homeadvisor .page article.social .tile > a {
  overflow: hidden;
  position: relative;
  display: block;
  width: 100%;
  height: 100%;
  padding: 0;
  color: #fff;
  text-decoration: none;
}

#homeadvisor .page article.social .tile > a:hover {
  text-decoration: none;
  border: 0;
}

#homeadvisor .page article.social .tile > a:hover .menu-image {
  -webkit-transform: scale(1.04);
  -moz-transform: scale(1.04);
  -ms-transform: scale(1.04);
  -o-transform: scale(1.04);
  transform: scale(1.04);
}

#homeadvisor .page article.social .tile .menu-bg {
  width: 100%;
  -moz-transition: all .3s ease-in-out;
  -o-transition: all .3s ease-in-out;
  -webkit-transition: all .3s ease-in-out;
  position: absolute;
  top: 0;
  right: auto;
  bottom: auto;
  left: 0;
  height: 100%;
  z-index: 2;
  -webkit-opacity: .3;
  -moz-opacity: .3;
  opacity: .3;
  filter: alpha(opacity=30);
  transition: all .3s ease-in-out;
}

#homeadvisor .page article.social .tile .menu-image {
  width: 100%;
  -moz-transition: all .3s ease-in-out;
  -o-transition: all .3s ease-in-out;
  -webkit-transition: all .3s ease-in-out;
  position: relative;
  height: 100%;
  -webkit-transform: scale(1);
  -moz-transform: scale(1);
  -ms-transform: scale(1);
  -o-transform: scale(1);
  transform: scale(1);
  transition: all .3s ease-in-out;
  overflow: hidden;
  z-index: 1;
}

#homeadvisor .page article.social .tile .menu-text {
  -moz-transition: all .3s ease-in-out;
  -o-transition: all .3s ease-in-out;
  -webkit-transition: all .3s ease-in-out;
  position: absolute;
  top: auto;
  right: auto;
  bottom: 0;
  left: 15px;
  width: 60%;
  color: #fff;
  padding-bottom: 30px;
  transition: all .3s ease-in-out;
  z-index: 2;
}

#homeadvisor .page article.social .tile .menu-text h6 {
  -moz-transition: all .3s ease-in-out;
  -o-transition: all .3s ease-in-out;
  -webkit-transition: all .3s ease-in-out;
  position: absolute;
  margin: 0;
  font-weight: 100;
  transition: all .3s ease-in-out;
}

#homeadvisor .page article.social .tile .menu-text h1 {
  color: #fff;
}

#homeadvisor .page article.social .tile .menu-text h4 {
  color: #fff;
  font-weight: 400;
}

#homeadvisor .container_overlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(3, 127, 184, 0.5);
  z-index: 2;
}

#homeadvisor .container_overlay.article {
  background-color: rgba(0, 65, 101, 0.2);
}

#homeadvisor .ie8 .anmtn, #homeadvisor .ie9 .anmtn {
  -webkit-opacity: 1 !important;
  -moz-opacity: 1 !important;
  opacity: 1 !important;
  filter: alpha(opacity=100) !important;
}

#homeadvisor .ie8 nav.sub a:before, #homeadvisor .ie9 nav.sub a:before {
  display: none !important;
}

#homeadvisor .ie10 .page .home ol strong,
#homeadvisor .ie10 .page .home ul strong,
#homeadvisor .ie10 .paragraph strong,
#homeadvisor .ie10 p strong,
#homeadvisor .page .home .ie10 ol strong,
#homeadvisor .page .home .ie10 ul strong {
  letter-spacing: 1.2px;
  letter-spacing: .06em;
}

#homeadvisor .no-js .anmtn {
  -webkit-opacity: 1 !important;
  -moz-opacity: 1 !important;
  opacity: 1 !important;
  filter: alpha(opacity=100) !important;
}

#homeadvisor .twolinks span {
  display: block;
  font-size: 1.4rem;
}

#homeadvisor .twolinks span:first-child {
  margin-bottom: 10px;
}

#homeadvisor .twolinks span a {
  color: #fff;
}

#homeadvisor .twolinks span a:hover {
  color: inherit;
}

@media screen and (min-width: 1200px) {
  #homeadvisor .container-homeadvisor {
    width: 1170px;
  }
}

@media (min-width: 1441px) {
  #homeadvisor .container-fluid.padd-both-sm {
    padding-top: 30px;
    padding-bottom: 30px;
  }
  #homeadvisor .container.padd-both-sm {
    padding-top: 30px;
    padding-bottom: 30px;
  }
  #homeadvisor .row.padd-both-sm {
    padding-top: 30px;
    padding-bottom: 30px;
  }
  #homeadvisor [class^=col-].padd-both-sm {
    padding-top: 30px;
    padding-bottom: 30px;
  }
  #homeadvisor .container-fluid.padd-both-md {
    padding-top: 50px;
    padding-bottom: 50px;
  }
  #homeadvisor .container.padd-both-md {
    padding-top: 50px;
    padding-bottom: 50px;
  }
  #homeadvisor .row.padd-both-md {
    padding-top: 50px;
    padding-bottom: 50px;
  }
  #homeadvisor [class^=col-].padd-both-md {
    padding-top: 50px;
    padding-bottom: 50px;
  }
  #homeadvisor .container-fluid.padd-both-lg {
    padding-top: 80px;
    padding-bottom: 80px;
  }
  #homeadvisor .container.padd-both-lg {
    padding-top: 80px;
    padding-bottom: 80px;
  }
  #homeadvisor .row.padd-both-lg {
    padding-top: 80px;
    padding-bottom: 80px;
  }
  #homeadvisor [class^=col-].padd-both-lg {
    padding-top: 80px;
    padding-bottom: 80px;
  }
  #homeadvisor .container-fluid.padd-top-sm {
    padding-top: 30px;
  }
  #homeadvisor .container.padd-top-sm {
    padding-top: 30px;
  }
  #homeadvisor .row.padd-top-sm {
    padding-top: 30px;
  }
  #homeadvisor [class^=col-].padd-top-sm {
    padding-top: 30px;
  }
  #homeadvisor .container-fluid.padd-top-md {
    padding-top: 50px;
  }
  #homeadvisor .container.padd-top-md {
    padding-top: 50px;
  }
  #homeadvisor .row.padd-top-md {
    padding-top: 50px;
  }
  #homeadvisor [class^=col-].padd-top-md {
    padding-top: 50px;
  }
  #homeadvisor .container-fluid.padd-top-lg {
    padding-top: 80px;
  }
  #homeadvisor .container.padd-top-lg {
    padding-top: 80px;
  }
  #homeadvisor .row.padd-top-lg {
    padding-top: 80px;
  }
  #homeadvisor [class^=col-].padd-top-lg {
    padding-top: 80px;
  }
  #homeadvisor .container-fluid.padd-bottom-sm {
    padding-bottom: 30px;
  }
  #homeadvisor .container.padd-bottom-sm {
    padding-bottom: 30px;
  }
  #homeadvisor .row.padd-bottom-sm {
    padding-bottom: 30px;
  }
  #homeadvisor [class^=col-].padd-bottom-sm {
    padding-bottom: 30px;
  }
  #homeadvisor .container-fluid.padd-bottom-md {
    padding-bottom: 50px;
  }
  #homeadvisor .container.padd-bottom-md {
    padding-bottom: 50px;
  }
  #homeadvisor .row.padd-bottom-md {
    padding-bottom: 50px;
  }
  #homeadvisor [class^=col-].padd-bottom-md {
    padding-bottom: 50px;
  }
  #homeadvisor .container-fluid.padd-bottom-lg {
    padding-bottom: 80px;
  }
  #homeadvisor .container.padd-bottom-lg {
    padding-bottom: 80px;
  }
  #homeadvisor .row.padd-bottom-lg {
    padding-bottom: 80px;
  }
  #homeadvisor [class^=col-].padd-bottom-lg {
    padding-bottom: 80px;
  }
  #homeadvisor h1 {
    font-size: 52px;
    margin-bottom: 30px;
  }
  #homeadvisor h2 {
    font-size: 52px;
    margin-bottom: 30px;
  }
  #homeadvisor h3 {
    font-size: 34px;
    margin-bottom: 30px;
  }
  #homeadvisor h4 {
    font-size: 28px;
    margin-bottom: 30px;
  }
  #homeadvisor h5 {
    font-size: 20px;
    margin-bottom: 10px;
  }
  #homeadvisor h6 {
    font-size: 16px;
  }
  #homeadvisor a.arrow:after {
    font-size: 22px;
  }
  #homeadvisor .cont-disclaimer li {
    font-size: 14px;
  }
  #homeadvisor .cont-disclaimer ol {
    font-size: 14px;
  }
  #homeadvisor .cont-disclaimer p {
    font-size: 14px;
  }
  #homeadvisor .cont-disclaimer .title h5 {
    font-size: 20px;
  }
  #homeadvisor .page article.home [class^=col-] .menu-text h5 {
    margin-bottom: 25px;
  }
  #homeadvisor .page .home .section-general .sectbox sect-block {
    height: 400px;
    padding: 50px 30px;
  }
  #homeadvisor .page .home .section-general .sectbox sect-block .icon {
    font-size: 32px;
    margin-bottom: 20px;
  }
  #homeadvisor .page .home .section-general .sectbox sect-block h4 {
    font-size: 21px;
  }
  #homeadvisor .page .home .section-general .sectbox sect-block .btn {
    bottom: 50px;
  }
  #homeadvisor .page .home .cont-nine .videos .item .photo {
    height: 150px;
  }
  #homeadvisor .page .home .cont-ten h3 span {
    font-size: 24px;
  }
  #homeadvisor .page article.social .container h5 {
    font-size: 24px;
  }
  #homeadvisor .page article.social .container .copy {
    margin-top: 140px;
    margin-bottom: 80px;
  }
  #homeadvisor .page article.social .tile {
    height: 250px;
  }
  #homeadvisor .page .social h1 {
    font-size: 80px;
  }
  #homeadvisor .page .social h2 {
    font-size: 44px;
  }
  #homeadvisor .page .social h3 {
    font-size: 34px;
  }
  #homeadvisor .page .social h6 {
    font-size: 18px;
    margin-bottom: 15px;
  }
  #homeadvisor .page .social .cont-five h5 {
    font-size: 24px;
  }
  #homeadvisor .page .social .cont-five .row:nth-child(1) .copy {
    margin-top: 40px;
    margin-bottom: 40px;
  }
  #homeadvisor .page .social .cont-five .row:nth-child(2) .copy {
    margin-top: 160px;
    margin-bottom: 160px;
  }
  #homeadvisor .page .social .cont-five .row:nth-child(3) {
    padding-top: 15px;
  }
  #homeadvisor .page .social .cont-five .copy .icon {
    width: 160px;
    height: 160px;
    font-size: 80px;
  }
  #homeadvisor .page .social .cont-five .copy .icon:before {
    line-height: 160px;
  }
  #homeadvisor .page .social .cont-four h5 {
    font-size: 24px;
  }
  #homeadvisor .page .social .cont-four .row:nth-child(1) .copy {
    margin-top: 40px;
    margin-bottom: 40px;
  }
  #homeadvisor .page .social .cont-four .row:nth-child(2) .copy {
    margin-top: 160px;
    margin-bottom: 160px;
  }
  #homeadvisor .page .social .cont-four .row:nth-child(3) {
    padding-top: 15px;
  }
  #homeadvisor .page .social .cont-four .copy .icon {
    width: 160px;
    height: 160px;
    font-size: 80px;
  }
  #homeadvisor .page .social .cont-four .copy .icon:before {
    line-height: 160px;
  }
  #homeadvisor .page .social .section-intro h5 {
    font-size: 24px;
  }
  #homeadvisor .page .social .section-intro .row:nth-child(1) .copy {
    margin-top: 40px;
    margin-bottom: 40px;
  }
  #homeadvisor .page .social .section-intro .row:nth-child(2) .copy {
    margin-top: 160px;
    margin-bottom: 160px;
  }
  #homeadvisor .page .social .section-intro .row:nth-child(3) {
    padding-top: 15px;
  }
  #homeadvisor .page .social .section-intro .copy .icon {
    width: 160px;
    height: 160px;
    font-size: 80px;
  }
  #homeadvisor .page .social .section-intro .copy .icon:before {
    line-height: 160px;
  }
  #homeadvisor .page .social .cont-six h5 {
    font-size: 24px;
  }
  #homeadvisor .page .social .cont-six .row:nth-child(1) .copy {
    margin-top: 40px;
    margin-bottom: 40px;
  }
  #homeadvisor .page .social .cont-six .row:nth-child(2) .copy {
    margin-top: 160px;
    margin-bottom: 160px;
  }
  #homeadvisor .page .social .cont-six .row:nth-child(3) {
    padding-top: 15px;
  }
  #homeadvisor .page .social .cont-six .copy .icon {
    width: 160px;
    height: 160px;
    font-size: 80px;
  }
  #homeadvisor .page .social .cont-six .copy .icon:before {
    line-height: 160px;
  }
  #homeadvisor .page .social .section-general h5 {
    font-size: 24px;
  }
  #homeadvisor .page .social .section-general .row:nth-child(1) .copy {
    margin-top: 40px;
    margin-bottom: 40px;
  }
  #homeadvisor .page .social .section-general .row:nth-child(2) .copy {
    margin-top: 160px;
    margin-bottom: 160px;
  }
  #homeadvisor .page .social .section-general .row:nth-child(3) {
    padding-top: 15px;
  }
  #homeadvisor .page .social .section-general .copy .icon {
    width: 160px;
    height: 160px;
    font-size: 80px;
  }
  #homeadvisor .page .social .section-general .copy .icon:before {
    line-height: 160px;
  }
  #homeadvisor .page .social .cont-three h6 {
    min-height: 50px;
  }
  #homeadvisor .page .social .cont-three .icon {
    width: 110px;
    height: 110px;
    font-size: 55px;
    margin-bottom: 20px;
  }
  #homeadvisor .page .social .cont-three .icon:before {
    line-height: 110px;
  }
}

@media (min-width: 768px) {
  #homeadvisor .container-fluid.flex {
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flexbox;
    display: flex;
  }
  #homeadvisor .container.flex {
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flexbox;
    display: flex;
  }
  #homeadvisor .row.flex {
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flexbox;
    display: flex;
  }
  #homeadvisor [class^=col-].flex {
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flexbox;
    display: flex;
  }
  #homeadvisor .bgfix {
    background-attachment: fixed;
  }
  #homeadvisor .page article.home [class^=col-] {
    height: 212px;
  }
  #homeadvisor .page article.home [class^=col-] > a:not(.btn):hover .menu-bg {
    -webkit-opacity: .7;
    -moz-opacity: .7;
    opacity: .7;
    filter: alpha(opacity=70);
  }
  #homeadvisor .page article.home [class^=col-] > a:not(.btn).learn-more:hover .menu-text {
    bottom: 20px;
  }
  #homeadvisor .page article.home [class^=col-] > a:not(.btn).learn-more:hover .menu-text h6 {
    bottom: 0;
  }
  #homeadvisor .page article.home [class^=col-] .menu-text {
    left: 30px;
    bottom: 0;
    width: 70%;
  }
  #homeadvisor .page article.home [class^=col-] .menu-text h6 {
    bottom: -50px;
  }
}

@media screen and (min-width: 768px) and (max-width: 991px) {
  #homeadvisor .page article.home [class^=col-] .menu-text h6 {
    bottom: 10px;
  }
}

@media (min-width: 768px) {
  #homeadvisor .page article.home .first {
    height: 424px;
  }
  #homeadvisor .page article.home .first .menu-text {
    left: 40px;
    bottom: 0;
  }
  #homeadvisor .page article.home .ser .menu-text {
    width: 100%;
  }
  #homeadvisor .page article.home .ser .menu-text h4 {
    width: 70%;
  }
  #homeadvisor .page article.home .conv.first {
    height: 424px;
  }
  #homeadvisor .page article.home .fhb.first {
    height: 424px;
  }
  #homeadvisor .page article.home.complete .menu-bg {
    -webkit-opacity: .7;
    -moz-opacity: .7;
    opacity: .7;
    filter: alpha(opacity=70);
  }
  #homeadvisor .page .home .cont-three .container {
    background-color: #363a94;
  }
  #homeadvisor .page .home .cont-three .button-group .btn {
    margin-right: 8px;
  }
  #homeadvisor .page .home .cont-five .container {
    text-align: right;
    background-color: #363a94;
  }
  #homeadvisor .page .home .cont-eight h5 {
    margin: 0;
  }
  #homeadvisor .page .home .cont-nine .videos .item:hover {
    -webkit-transform: scale(1.04);
    -moz-transform: scale(1.04);
    -ms-transform: scale(1.04);
    -o-transform: scale(1.04);
    transform: scale(1.04);
    -webkit-box-shadow: 3px 4px 30px 0 rgba(0, 0, 0, 0.04);
    -moz-box-shadow: 3px 4px 30px 0 rgba(0, 0, 0, 0.04);
    box-shadow: 3px 4px 30px 0 rgba(0, 0, 0, 0.04);
  }
  #homeadvisor .page .home .cont-ten .container {
    background-color: #363a94;
  }
  #homeadvisor .page .home .cont-ten h3:before {
    left: 0;
  }
  #homeadvisor .page .home .cont-ten p {
    width: 80%;
  }
  #homeadvisor .page .home .cont-ten .button-group .btn {
    margin-right: 8px;
  }
  #homeadvisor .page article.social .tile {
    padding-left: 15px;
    padding-right: 15px;
    margin-bottom: 20px;
  }
  #homeadvisor .page article.social .tile > a.learn-more:hover .menu-text {
    bottom: 20px;
  }
  #homeadvisor .page article.social .tile > a.learn-more:hover .menu-text h6 {
    bottom: 0;
  }
  #homeadvisor .page article.social .tile .menu-bg {
    -webkit-opacity: .5;
    -moz-opacity: .5;
    opacity: .5;
    filter: alpha(opacity=50);
    background-color: #fff;
  }
  #homeadvisor .page article.social .tile .menu-text {
    position: absolute;
    top: auto;
    right: auto;
    bottom: 0;
    left: 20px;
    width: 80%;
  }
  #homeadvisor .page article.social .tile .menu-text h4 {
    margin: 0;
  }
  #homeadvisor .page article.social .tile .menu-text h6 {
    bottom: -40px;
  }
  #homeadvisor .page .social h2 {
    font-size: calc(2.1409455843vw + 37.15px);
    margin-bottom: calc(0vw + 10px);
  }
  #homeadvisor .page .social .cont-five .row:nth-child(1) {
    color: #fff;
    background-color: #363a94;
  }
  #homeadvisor .page .social .cont-five .row:nth-child(1) .copy {
    margin-top: calc(5.3523639607vw + 102.87px);
    margin-bottom: calc(5.3523639607vw + 102.87px);
  }
  #homeadvisor .page .social .cont-five .row:nth-child(3) {
    background-color: #363a94;
  }
  #homeadvisor .page .social .cont-five .copy .icon {
    position: relative;
    display: block;
    width: calc(3.5682426405vw + 28.58px);
    height: calc(3.5682426405vw + 28.58px);
    font-size: calc(1.7841213202vw + 14.29px);
    -webkit-border-radius: 100%;
    -moz-border-radius: 100%;
    border-radius: 100%;
    margin: auto;
    text-align: center;
    color: #fff;
    border: 1px solid #fff;
    -moz-transition: all .3s ease-in-out;
    -o-transition: all .3s ease-in-out;
    -webkit-transition: all .3s ease-in-out;
    transition: all .3s ease-in-out;
    margin-bottom: calc(0vw + 20px);
  }
  #homeadvisor .page .social .cont-five .copy .icon:before {
    line-height: calc(3.5682426405vw + 28.58px);
  }
  #homeadvisor .page .social .cont-four .row:nth-child(1) {
    color: #fff;
    background-color: #363a94;
  }
  #homeadvisor .page .social .cont-four .row:nth-child(1) .copy {
    margin-top: calc(5.3523639607vw + 102.87px);
    margin-bottom: calc(5.3523639607vw + 102.87px);
  }
  #homeadvisor .page .social .cont-four .row:nth-child(3) {
    background-color: #363a94;
  }
  #homeadvisor .page .social .cont-four .copy .icon {
    position: relative;
    display: block;
    width: calc(3.5682426405vw + 28.58px);
    height: calc(3.5682426405vw + 28.58px);
    font-size: calc(1.7841213202vw + 14.29px);
    -webkit-border-radius: 100%;
    -moz-border-radius: 100%;
    border-radius: 100%;
    margin: auto;
    text-align: center;
    color: #fff;
    border: 1px solid #fff;
    -moz-transition: all .3s ease-in-out;
    -o-transition: all .3s ease-in-out;
    -webkit-transition: all .3s ease-in-out;
    transition: all .3s ease-in-out;
    margin-bottom: calc(0vw + 20px);
  }
  #homeadvisor .page .social .cont-four .copy .icon:before {
    line-height: calc(3.5682426405vw + 28.58px);
  }
  #homeadvisor .page .social .section-intro .row:nth-child(1) {
    color: #fff;
    background-color: #363a94;
  }
  #homeadvisor .page .social .section-intro .row:nth-child(1) .copy {
    margin-top: calc(5.3523639607vw + 102.87px);
    margin-bottom: calc(5.3523639607vw + 102.87px);
  }
  #homeadvisor .page .social .section-intro .row:nth-child(3) {
    background-color: #363a94;
  }
  #homeadvisor .page .social .section-intro .copy .icon {
    position: relative;
    display: block;
    width: calc(3.5682426405vw + 28.58px);
    height: calc(3.5682426405vw + 28.58px);
    font-size: calc(1.7841213202vw + 14.29px);
    -webkit-border-radius: 100%;
    -moz-border-radius: 100%;
    border-radius: 100%;
    margin: auto;
    text-align: center;
    color: #fff;
    border: 1px solid #fff;
    -moz-transition: all .3s ease-in-out;
    -o-transition: all .3s ease-in-out;
    -webkit-transition: all .3s ease-in-out;
    transition: all .3s ease-in-out;
    margin-bottom: calc(0vw + 20px);
  }
  #homeadvisor .page .social .section-intro .copy .icon:before {
    line-height: calc(3.5682426405vw + 28.58px);
  }
  #homeadvisor .page .social .cont-six .row:nth-child(1) {
    color: #fff;
    background-color: #363a94;
  }
  #homeadvisor .page .social .cont-six .row:nth-child(1) .copy {
    margin-top: calc(5.3523639607vw + 102.87px);
    margin-bottom: calc(5.3523639607vw + 102.87px);
  }
  #homeadvisor .page .social .cont-six .row:nth-child(3) {
    background-color: #363a94;
  }
  #homeadvisor .page .social .cont-six .copy .icon {
    position: relative;
    display: block;
    width: calc(3.5682426405vw + 28.58px);
    height: calc(3.5682426405vw + 28.58px);
    font-size: calc(1.7841213202vw + 14.29px);
    -webkit-border-radius: 100%;
    -moz-border-radius: 100%;
    border-radius: 100%;
    margin: auto;
    text-align: center;
    color: #fff;
    border: 1px solid #fff;
    -moz-transition: all .3s ease-in-out;
    -o-transition: all .3s ease-in-out;
    -webkit-transition: all .3s ease-in-out;
    transition: all .3s ease-in-out;
    margin-bottom: calc(0vw + 20px);
  }
  #homeadvisor .page .social .cont-six .copy .icon:before {
    line-height: calc(3.5682426405vw + 28.58px);
  }
  #homeadvisor .page .social .section-general .row:nth-child(1) {
    color: #fff;
    background-color: #363a94;
  }
  #homeadvisor .page .social .section-general .row:nth-child(1) .copy {
    margin-top: calc(5.3523639607vw + 102.87px);
    margin-bottom: calc(5.3523639607vw + 102.87px);
  }
  #homeadvisor .page .social .section-general .row:nth-child(3) {
    background-color: #363a94;
  }
  #homeadvisor .page .social .section-general .copy .icon {
    position: relative;
    display: block;
    width: calc(3.5682426405vw + 28.58px);
    height: calc(3.5682426405vw + 28.58px);
    font-size: calc(1.7841213202vw + 14.29px);
    -webkit-border-radius: 100%;
    -moz-border-radius: 100%;
    border-radius: 100%;
    margin: auto;
    text-align: center;
    color: #fff;
    border: 1px solid #fff;
    -moz-transition: all .3s ease-in-out;
    -o-transition: all .3s ease-in-out;
    -webkit-transition: all .3s ease-in-out;
    transition: all .3s ease-in-out;
    margin-bottom: calc(0vw + 20px);
  }
  #homeadvisor .page .social .section-general .copy .icon:before {
    line-height: calc(3.5682426405vw + 28.58px);
  }
  #homeadvisor .page .social .cont-three .col-xs-6:nth-child(odd) {
    clear: none;
  }
}

@media (min-width: 480px) {
  #homeadvisor .btn {
    display: inline-block;
  }
}

@media (max-width: 390px) {
  #homeadvisor .btn.small {
    padding: 8px 8px 0;
  }
}

@media (max-width: 479px) {
  #homeadvisor .telephone {
    display: block;
    margin: 15px 0;
    text-align: center;
  }
  #homeadvisor .page .home .section-general .sectbox .col-xs-6 {
    width: 100%;
    margin-bottom: 20px;
  }
  #homeadvisor .page .home .section-general .sectbox .col-xs-6 sect-block {
    height: 310px;
  }
  #homeadvisor .page .home .cont-ten h3 span br {
    display: none;
  }
}

@media (max-width: 767px) {
  #homeadvisor .page .home .cont-eight .text-left {
    text-align: center;
  }
  #homeadvisor .page .home .cont-eight .text-right {
    text-align: center;
  }
}

@media (min-width: 768px) and (max-width: 320px) {
  #homeadvisor .page .social h2 {
    font-size: 44px;
    margin-bottom: 10px;
  }
  #homeadvisor .page .social .cont-five .row:nth-child(1) .copy {
    margin-top: 120px;
    margin-bottom: 120px;
  }
  #homeadvisor .page .social .cont-five .copy .icon {
    width: 40px;
    height: 40px;
    font-size: 20px;
    margin-bottom: 20px;
  }
  #homeadvisor .page .social .cont-five .copy .icon:before {
    line-height: 40px;
  }
  #homeadvisor .page .social .cont-four .row:nth-child(1) .copy {
    margin-top: 120px;
    margin-bottom: 120px;
  }
  #homeadvisor .page .social .cont-four .copy .icon {
    width: 40px;
    height: 40px;
    font-size: 20px;
    margin-bottom: 20px;
  }
  #homeadvisor .page .social .cont-four .copy .icon:before {
    line-height: 40px;
  }
  #homeadvisor .page .social .section-intro .row:nth-child(1) .copy {
    margin-top: 120px;
    margin-bottom: 120px;
  }
  #homeadvisor .page .social .section-intro .copy .icon {
    width: 40px;
    height: 40px;
    font-size: 20px;
    margin-bottom: 20px;
  }
  #homeadvisor .page .social .section-intro .copy .icon:before {
    line-height: 40px;
  }
  #homeadvisor .page .social .cont-six .row:nth-child(1) .copy {
    margin-top: 120px;
    margin-bottom: 120px;
  }
  #homeadvisor .page .social .cont-six .copy .icon {
    width: 40px;
    height: 40px;
    font-size: 20px;
    margin-bottom: 20px;
  }
  #homeadvisor .page .social .cont-six .copy .icon:before {
    line-height: 40px;
  }
  #homeadvisor .page .social .section-general .row:nth-child(1) .copy {
    margin-top: 120px;
    margin-bottom: 120px;
  }
  #homeadvisor .page .social .section-general .copy .icon {
    width: 40px;
    height: 40px;
    font-size: 20px;
    margin-bottom: 20px;
  }
  #homeadvisor .page .social .section-general .copy .icon:before {
    line-height: 40px;
  }
}

@media (min-width: 768px) and (min-width: 1441px) {
  #homeadvisor .page .social h2 {
    font-size: 68px;
    margin-bottom: 10px;
  }
  #homeadvisor .page .social .cont-five .row:nth-child(1) .copy {
    margin-top: 180px;
    margin-bottom: 180px;
  }
  #homeadvisor .page .social .cont-five .copy .icon {
    width: 80px;
    height: 80px;
    font-size: 40px;
    margin-bottom: 20px;
  }
  #homeadvisor .page .social .cont-five .copy .icon:before {
    line-height: 80px;
  }
  #homeadvisor .page .social .cont-four .row:nth-child(1) .copy {
    margin-top: 180px;
    margin-bottom: 180px;
  }
  #homeadvisor .page .social .cont-four .copy .icon {
    width: 80px;
    height: 80px;
    font-size: 40px;
    margin-bottom: 20px;
  }
  #homeadvisor .page .social .cont-four .copy .icon:before {
    line-height: 80px;
  }
  #homeadvisor .page .social .section-intro .row:nth-child(1) .copy {
    margin-top: 180px;
    margin-bottom: 180px;
  }
  #homeadvisor .page .social .section-intro .copy .icon {
    width: 80px;
    height: 80px;
    font-size: 40px;
    margin-bottom: 20px;
  }
  #homeadvisor .page .social .section-intro .copy .icon:before {
    line-height: 80px;
  }
  #homeadvisor .page .social .cont-six .row:nth-child(1) .copy {
    margin-top: 180px;
    margin-bottom: 180px;
  }
  #homeadvisor .page .social .cont-six .copy .icon {
    width: 80px;
    height: 80px;
    font-size: 40px;
    margin-bottom: 20px;
  }
  #homeadvisor .page .social .cont-six .copy .icon:before {
    line-height: 80px;
  }
  #homeadvisor .page .social .section-general .row:nth-child(1) .copy {
    margin-top: 180px;
    margin-bottom: 180px;
  }
  #homeadvisor .page .social .section-general .copy .icon {
    width: 80px;
    height: 80px;
    font-size: 40px;
    margin-bottom: 20px;
  }
  #homeadvisor .page .social .section-general .copy .icon:before {
    line-height: 80px;
  }
}

legend {
  border-bottom: 0;
}

select, input[type="text"] {
  border: 1px solid #bcbcbc;
}

div,
span,
button,
p,
label,
select,
input[type="text"],
input {
  font-family: "Poppins", sans-serif;
}

label {
  font-weight: 400;
}

.page-node-29 .post-entry .entry-content,
.page-news .post-entry .entry-content {
  display: none;
}

@media (min-width: 769px) {
  .page-node-29 .post-entry .entry-content,
  .page-news .post-entry .entry-content {
    display: block;
    font-size: 14px;
    line-height: 21px;
    margin: 12px 0;
    color: #3b4d5d;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
  }
}

.page-node-29 .post-entry .entry-meta .date-published,
.page-news .post-entry .entry-meta .date-published {
  color: #757575;
  font-size: 12px;
  margin-top: auto;
}

.epiq-container {
  color: #777;
}

.epiq-container h2 {
  font-weight: 100;
  margin-bottom: 30px;
  max-width: 700px;
  line-height: initial;
  margin: 0 auto 30px;
}

.epiq-container h3 {
  max-width: 590px;
  width: 100%;
  text-align: center;
  margin: 0 auto;
  line-height: 4.6rem;
  font-size: 3.1rem;
}

@media screen and (max-width: 425px) {
  .epiq-container h3 {
    line-height: 3rem;
    font-size: 2rem;
  }
}

.epiq-container h4 {
  font-size: 2.2rem;
  font-weight: 600;
  margin-bottom: 5px;
}

.epiq-container p {
  font-size: 1.7rem;
  line-height: 1.8;
}

.epiq-container ul {
  margin: 0;
  padding: 0 0 10px 20px;
}

.epiq-container li {
  list-style-type: none;
  padding: 7px 0;
}

.epiq-container li:before {
  color: #bcc161;
  content: "\f00c";
  font-family: FontAwesome;
  display: inline-block;
  margin-left: -1.3em;
  width: 1.3em;
}

.epiq-container h1, .epiq-container h3 {
  color: #2e3a52;
}

.epiq-container h2, .epiq-container h4 {
  color: #363a94;
}

.epiq-container .jumbotron {
  color: #fff;
  padding: 40px 25px 70px;
  background: url(https://files.theedgeproperty.com.my/public/images/epiq/bg-epiq.png) no-repeat fixed;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  border-bottom: 1px solid #cce2e7;
}

#epiq-page {
  /*.item h4 {
      font-size: 19px;
      line-height: 1.375em;
      font-weight: 400;
      font-style: italic;
      margin: 70px 0;
  }
  .item span {
      font-style: normal;
  }
  */
  /*utilities*/
}

#epiq-page h1.epiq-tq {
  font-weight: 900;
  font-size: 6rem;
}

#epiq-page span.h1-epic-span {
  display: block;
  font-size: 2.3rem;
  padding: 10px 0 20px;
}

#epiq-page .img-responsive {
  max-width: 310px;
  width: 100%;
}

#epiq-page .container-fluid {
  padding: 40px 50px;
}

#epiq-page .slideanim {
  visibility: hidden;
}

#epiq-page .slide {
  animation-name: slide;
  -webkit-animation-name: slide;
  animation-duration: 1s;
  -webkit-animation-duration: 1s;
  visibility: visible;
}

@keyframes slide {
  0% {
    opacity: 0;
    transform: translateY(70%);
  }
  100% {
    opacity: 1;
    transform: translateY(0%);
  }
}

@-webkit-keyframes slide {
  0% {
    opacity: 0;
    -webkit-transform: translateY(70%);
  }
  100% {
    opacity: 1;
    -webkit-transform: translateY(0%);
  }
}

@media screen and (max-width: 768px) {
  #epiq-page .col-sm-4 {
    text-align: center;
    margin: 25px 0;
  }
}

#epiq-page .m-t-20 {
  margin-top: 20px;
}

#epiq-page .m-t-30 {
  margin-top: 30px;
}

#epiq-page .m-b-20 {
  margin-bottom: 20px;
}

#epiq-page .epiq-bg-0 {
  background: #feffe6;
}

#epiq-page .epiq-bg-1 {
  background: #f7f7f7;
}

#epiq-page .epiq-bg-2 {
  background: #e8f1f9;
}

#epiq-page .epiq-bg-3 {
  background: #ffea35;
  padding: 7px 15px;
}

#epiq-page .epiq-bg-tq {
  background: url(../images/bg-epiq-tq.png) no-repeat;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
}

#epiq-page .epic-colr-1 {
  color: #2e3a52;
}

#epiq-page .bg-text {
  padding: 10px;
  background: rgba(46, 58, 82, 0.55);
  display: initial;
  color: #fff !important;
}

#epiq-page .sub-style-1 {
  display: block;
  font-weight: 700;
  color: #E91E63;
  font-size: larger;
}

#epiq-page .sub-style-2 {
  font-weight: 700;
  color: #333;
}

#epiq-page .sub-style-3 {
  background: #2e3a52;
  padding: 12px 0;
  color: #fff;
}

#epiq-page h3.t-a-left {
  text-align: left;
}

.page-forum ul.action-links {
  margin: 0 0 20px 0;
  padding: 0;
}

.page-forum ul.action-links li {
  display: inherit;
}

#forum table {
  width: 100%;
}

#forum table td.title {
  padding-bottom: 20px;
}

#forum table td.title div a {
  color: #363a94;
}

#forum table td.title div span.submitted {
  font-size: 1.0rem;
}

#forum table th.active {
  float: right;
}

#forum table th.active img {
  float: right;
  margin-left: 5px;
}

body.page-proppoints div.lp-pom-root .lp-pom-form-field.legacy {
  position: absolute;
  left: 0;
  top: 0;
  margin-bottom: 12px;
}

body.page-proppoints div.lp-pom-root .lp-pom-form-field.legacy label {
  position: absolute;
  left: 0;
  top: 0;
}

body.page-proppoints div.lp-pom-root .lp-pom-form-field.legacy select {
  position: absolute;
  left: 0;
  top: 0;
  padding: 0;
  margin: 0;
  border: 1px solid #666;
}

body.page-proppoints div.lp-pom-root .lp-pom-form-field.legacy select option {
  padding: 0.2em;
}

body.page-proppoints div.lp-pom-root .lp-pom-form-field.legacy textarea,
body.page-proppoints div.lp-pom-root .lp-pom-form-field.legacy input[type=text] {
  position: absolute;
  left: 0;
  top: 0;
  padding: 0;
  margin: 0;
  border: 1px solid #666;
}

body.page-proppoints div.lp-pom-root .lp-pom-form-field.legacy input[type=text]::-moz-focus-inner {
  padding: 0;
  border: none;
}

body.page-proppoints div.lp-pom-root .lp-pom-form-field.legacy select::-moz-focus-inner {
  padding: 0;
  border: none;
}

body.page-proppoints div.lp-pom-root .lp-pom-form-field.legacy .optionsList {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  padding: 0;
  margin: 0;
}

body.page-proppoints div.lp-pom-root .lp-pom-form-field.legacy .option {
  position: relative;
  margin-bottom: 6px;
}

body.page-proppoints div.lp-pom-root .lp-pom-form-field.legacy .option input {
  position: absolute;
}

body.page-proppoints div.lp-pom-root .lp-pom-form-field.legacy .option label {
  position: relative;
  display: block;
}

body.page-proppoints div.lp-pom-root .lp-pom-form input.submit {
  position: absolute;
  left: 0;
  top: 0;
}

body.page-proppoints .lp-pom-form.has-axis * {
  box-sizing: border-box;
}

body.page-proppoints .lp-pom-form.has-axis form {
  position: static !important;
}

body.page-proppoints .lp-pom-form.has-axis .fields {
  display: flex;
  justify-content: flex-start;
}

body.page-proppoints .lp-pom-form.has-axis input[type=text],
body.page-proppoints .lp-pom-form.has-axis select,
body.page-proppoints .lp-pom-form.has-axis textarea {
  width: 100%;
}

body.page-proppoints .lp-pom-form.has-axis select {
  padding: 0.2em;
}

body.page-proppoints .lp-pom-form.has-axis input[type=text]::-moz-focus-inner {
  padding: 0;
  border: none;
}

body.page-proppoints .lp-pom-form.has-axis select::-moz-focus-inner {
  padding: 0;
  border: none;
}

body.page-proppoints .lp-pom-form.has-axis .option {
  align-items: center;
  margin-bottom: 6px;
  padding-left: 20px;
  padding-right: 8px;
}

body.page-proppoints .lp-pom-form.has-axis .option input {
  margin-right: 8px;
  margin-left: -20px;
}

body.page-proppoints html {
  color: #000;
  background: transparent;
}

body.page-proppoints body,
body.page-proppoints div,
body.page-proppoints dl,
body.page-proppoints dt,
body.page-proppoints dd,
body.page-proppoints ul,
body.page-proppoints ol,
body.page-proppoints li,
body.page-proppoints h1,
body.page-proppoints h2,
body.page-proppoints h3,
body.page-proppoints h4,
body.page-proppoints h5,
body.page-proppoints h6,
body.page-proppoints pre,
body.page-proppoints code,
body.page-proppoints form,
body.page-proppoints fieldset,
body.page-proppoints legend,
body.page-proppoints input,
body.page-proppoints button,
body.page-proppoints textarea,
body.page-proppoints p,
body.page-proppoints blockquote,
body.page-proppoints th,
body.page-proppoints td {
  margin: 0;
  padding: 0;
}

body.page-proppoints button::-moz-focus-inner {
  padding: 0;
  border: none;
}

body.page-proppoints table {
  border-collapse: collapse;
  border-spacing: 0;
}

body.page-proppoints fieldset,
body.page-proppoints img {
  border: 0;
}

body.page-proppoints address,
body.page-proppoints caption,
body.page-proppoints cite,
body.page-proppoints code,
body.page-proppoints dfn,
body.page-proppoints em,
body.page-proppoints strong,
body.page-proppoints th,
body.page-proppoints var,
body.page-proppoints optgroup {
  font-style: inherit;
  font-weight: inherit;
}

body.page-proppoints del,
body.page-proppoints ins {
  text-decoration: none;
}

body.page-proppoints li {
  list-style: none;
}

body.page-proppoints caption,
body.page-proppoints th {
  text-align: left;
}

body.page-proppoints h1,
body.page-proppoints h2,
body.page-proppoints h3,
body.page-proppoints h4,
body.page-proppoints h5,
body.page-proppoints h6 {
  font-size: 100%;
  font-weight: normal;
}

body.page-proppoints q:before,
body.page-proppoints q:after {
  content: '';
}

body.page-proppoints abbr,
body.page-proppoints acronym {
  border: 0;
  font-variant: normal;
}

body.page-proppoints sup {
  vertical-align: baseline;
}

body.page-proppoints sub {
  vertical-align: baseline;
}

body.page-proppoints legend {
  color: #000;
}

body.page-proppoints input,
body.page-proppoints button,
body.page-proppoints textarea,
body.page-proppoints select,
body.page-proppoints optgroup,
body.page-proppoints option {
  font-family: inherit;
  font-size: inherit;
  font-style: inherit;
  font-weight: inherit;
}

body.page-proppoints :focus {
  outline: 0;
}

body.page-proppoints blockquote,
body.page-proppoints q {
  quotes: none;
}

body.page-proppoints .clearfix:after {
  content: "";
  display: table;
  clear: both;
}

body.page-proppoints html {
  opacity: 0;
}

body.page-proppoints html {
  opacity: 1;
  transition: transform .4s ease-in-out, opacity .4s ease-in-out;
}

body.page-proppoints html,
body.page-proppoints body,
body.page-proppoints .lp-pom-body {
  height: 100%;
}

body.page-proppoints body.lp-pom-body.lp-sub-page {
  -webkit-transform: translateZ(0);
}

body.page-proppoints body.lp-pom-body:not(.lp-convertable-page) {
  background-color: #fff;
}

body.page-proppoints body.lp-pom-body.lp-convertable-page {
  overflow: hidden;
}

@media only screen and (max-width: 480px), only screen and (max-device-width: 480px) {
  body.page-proppoints * {
    -webkit-text-size-adjust: none;
  }
}

body.page-proppoints div.lp-pom-root {
  min-height: 100%;
  position: relative;
  top: 0;
}

body.page-proppoints div.lp-pom-root,
body.page-proppoints .cke-text {
  font-size: 14px;
  line-height: 17px;
}

body.page-proppoints .lp-positioned-content {
  position: absolute;
  left: 50%;
  top: 0;
  height: 0;
  z-index: 1;
}

body.page-proppoints .lp-modal-screen {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  z-index: 2;
}

body.page-proppoints .lp-modal {
  overflow-y: auto;
}

body.page-proppoints .lp-modal-content {
  position: absolute;
  left: 50%;
  height: 0;
  z-index: 1;
}

body.page-proppoints .lp-pom-block-content {
  position: relative;
  height: 100%;
}

body.page-proppoints div.lp-pom-root .lp-pom-button {
  text-decoration: none;
  display: block;
  cursor: pointer;
}

body.page-proppoints div.lp-pom-root .lp-pom-button.disabled {
  opacity: 0.6;
  pointer-events: none;
}

body.page-proppoints div.lp-pom-root .lp-pom-button span {
  position: absolute;
  top: 50%;
  display: block;
  text-align: center;
  left: 0;
  right: 0;
  padding: 0 8px 0 8px;
  cursor: pointer;
}

body.page-proppoints div.lp-pom-root .lp-pom-button {
  *background-image: url(/images/transparent.gif);
  _background-image: url(/images/transparent.gif);
  background-image: url(/images/transparent.gif);
  background: transparent;
}

body.page-proppoints .lp-pom-button .label strong {
  font-weight: bolder;
}

body.page-proppoints .lp-pom-button .label em {
  font-style: italic;
}

body.page-proppoints .lp-pom-text span[style~="color:"] a {
  color: inherit;
}

body.page-proppoints div.lp-pom-root .lp-pom-text.inline span {
  line-height: 1;
}

body.page-proppoints div.lp-pom-root .lp-pom-text p,
body.page-proppoints .cke-text p {
  font-size: 14px;
  line-height: 17px;
}

body.page-proppoints div.lp-pom-root .lp-pom-text.nlh p,
body.page-proppoints .cke-text.nlh p {
  font-size: 14px;
  line-height: 22px;
}

body.page-proppoints div.lp-pom-root .lp-pom-text a,
body.page-proppoints .cke-text a {
  text-decoration: underline;
}

body.page-proppoints div.lp-pom-root .lp-pom-text h1,
body.page-proppoints .cke-text h1 {
  font-size: 28px;
  margin-bottom: 19px;
  line-height: 34px;
}

body.page-proppoints div.lp-pom-root .lp-pom-text h2,
body.page-proppoints .cke-text h2 {
  font-size: 21px;
  margin-bottom: 16px;
  line-height: 25px;
}

body.page-proppoints div.lp-pom-root .lp-pom-text h3,
body.page-proppoints .cke-text h3 {
  font-size: 16px;
  margin-bottom: 15px;
  line-height: 19px;
}

body.page-proppoints div.lp-pom-root .lp-pom-text h4,
body.page-proppoints .cke-text h4 {
  font-size: 14px;
  margin-bottom: 14px;
  line-height: 17px;
}

body.page-proppoints div.lp-pom-root .lp-pom-text h5,
body.page-proppoints .cke-text h5 {
  font-size: 12px;
  margin-bottom: 13px;
  line-height: 14px;
}

body.page-proppoints div.lp-pom-root .lp-pom-text h6,
body.page-proppoints .cke-text h6 {
  font-size: 10px;
  margin-bottom: 12px;
  line-height: 12px;
}

body.page-proppoints div.lp-pom-root .lp-pom-text .lplh-8,
body.page-proppoints div.lp-pom-root .lp-pom-text.nlh .lplh-8,
body.page-proppoints .cke-text .lplh-8,
body.page-proppoints .cke-text.nlh .lplh-8 {
  line-height: 8px;
}

body.page-proppoints div.lp-pom-root .lp-pom-text .lplh-9,
body.page-proppoints div.lp-pom-root .lp-pom-text.nlh .lplh-9,
body.page-proppoints .cke-text .lplh-9,
body.page-proppoints .cke-text.nlh .lplh-9 {
  line-height: 9px;
}

body.page-proppoints div.lp-pom-root .lp-pom-text .lplh-10,
body.page-proppoints div.lp-pom-root .lp-pom-text.nlh .lplh-10,
body.page-proppoints .cke-text .lplh-10,
body.page-proppoints .cke-text.nlh .lplh-10 {
  line-height: 10px;
}

body.page-proppoints div.lp-pom-root .lp-pom-text .lplh-11,
body.page-proppoints div.lp-pom-root .lp-pom-text.nlh .lplh-11,
body.page-proppoints .cke-text .lplh-11,
body.page-proppoints .cke-text.nlh .lplh-11 {
  line-height: 11px;
}

body.page-proppoints div.lp-pom-root .lp-pom-text .lplh-12,
body.page-proppoints div.lp-pom-root .lp-pom-text.nlh .lplh-12,
body.page-proppoints .cke-text .lplh-12,
body.page-proppoints .cke-text.nlh .lplh-12 {
  line-height: 12px;
}

body.page-proppoints div.lp-pom-root .lp-pom-text .lplh-13,
body.page-proppoints div.lp-pom-root .lp-pom-text.nlh .lplh-13,
body.page-proppoints .cke-text .lplh-13,
body.page-proppoints .cke-text.nlh .lplh-13 {
  line-height: 13px;
}

body.page-proppoints div.lp-pom-root .lp-pom-text .lplh-14,
body.page-proppoints div.lp-pom-root .lp-pom-text.nlh .lplh-14,
body.page-proppoints .cke-text .lplh-14,
body.page-proppoints .cke-text.nlh .lplh-14 {
  line-height: 14px;
}

body.page-proppoints div.lp-pom-root .lp-pom-text .lplh-15,
body.page-proppoints div.lp-pom-root .lp-pom-text.nlh .lplh-15,
body.page-proppoints .cke-text .lplh-15,
body.page-proppoints .cke-text.nlh .lplh-15 {
  line-height: 15px;
}

body.page-proppoints div.lp-pom-root .lp-pom-text .lplh-16,
body.page-proppoints div.lp-pom-root .lp-pom-text.nlh .lplh-16,
body.page-proppoints .cke-text .lplh-16,
body.page-proppoints .cke-text.nlh .lplh-16 {
  line-height: 16px;
}

body.page-proppoints div.lp-pom-root .lp-pom-text .lplh-17,
body.page-proppoints div.lp-pom-root .lp-pom-text.nlh .lplh-17,
body.page-proppoints .cke-text .lplh-17,
body.page-proppoints .cke-text.nlh .lplh-17 {
  line-height: 17px;
}

body.page-proppoints div.lp-pom-root .lp-pom-text .lplh-18,
body.page-proppoints div.lp-pom-root .lp-pom-text.nlh .lplh-18,
body.page-proppoints .cke-text .lplh-18,
body.page-proppoints .cke-text.nlh .lplh-18 {
  line-height: 18px;
}

body.page-proppoints div.lp-pom-root .lp-pom-text .lplh-19,
body.page-proppoints div.lp-pom-root .lp-pom-text.nlh .lplh-19,
body.page-proppoints .cke-text .lplh-19,
body.page-proppoints .cke-text.nlh .lplh-19 {
  line-height: 19px;
}

body.page-proppoints div.lp-pom-root .lp-pom-text .lplh-20,
body.page-proppoints div.lp-pom-root .lp-pom-text.nlh .lplh-20,
body.page-proppoints .cke-text .lplh-20,
body.page-proppoints .cke-text.nlh .lplh-20 {
  line-height: 20px;
}

body.page-proppoints div.lp-pom-root .lp-pom-text .lplh-22,
body.page-proppoints div.lp-pom-root .lp-pom-text.nlh .lplh-22,
body.page-proppoints .cke-text .lplh-22,
body.page-proppoints .cke-text.nlh .lplh-22 {
  line-height: 22px;
}

body.page-proppoints div.lp-pom-root .lp-pom-text .lplh-24,
body.page-proppoints div.lp-pom-root .lp-pom-text.nlh .lplh-24,
body.page-proppoints .cke-text .lplh-24,
body.page-proppoints .cke-text.nlh .lplh-24 {
  line-height: 24px;
}

body.page-proppoints div.lp-pom-root .lp-pom-text .lplh-26,
body.page-proppoints div.lp-pom-root .lp-pom-text.nlh .lplh-26,
body.page-proppoints .cke-text .lplh-26,
body.page-proppoints .cke-text.nlh .lplh-26 {
  line-height: 26px;
}

body.page-proppoints div.lp-pom-root .lp-pom-text .lplh-29,
body.page-proppoints div.lp-pom-root .lp-pom-text.nlh .lplh-29,
body.page-proppoints .cke-text .lplh-29,
body.page-proppoints .cke-text.nlh .lplh-29 {
  line-height: 29px;
}

body.page-proppoints div.lp-pom-root .lp-pom-text .lplh-31,
body.page-proppoints div.lp-pom-root .lp-pom-text.nlh .lplh-31,
body.page-proppoints .cke-text .lplh-31,
body.page-proppoints .cke-text.nlh .lplh-31 {
  line-height: 31px;
}

body.page-proppoints div.lp-pom-root .lp-pom-text .lplh-32,
body.page-proppoints div.lp-pom-root .lp-pom-text.nlh .lplh-32,
body.page-proppoints .cke-text .lplh-32,
body.page-proppoints .cke-text.nlh .lplh-32 {
  line-height: 32px;
}

body.page-proppoints div.lp-pom-root .lp-pom-text .lplh-34,
body.page-proppoints div.lp-pom-root .lp-pom-text.nlh .lplh-34,
body.page-proppoints .cke-text .lplh-34,
body.page-proppoints .cke-text.nlh .lplh-34 {
  line-height: 34px;
}

body.page-proppoints div.lp-pom-root .lp-pom-text .lplh-35,
body.page-proppoints div.lp-pom-root .lp-pom-text.nlh .lplh-35,
body.page-proppoints .cke-text .lplh-35,
body.page-proppoints .cke-text.nlh .lplh-35 {
  line-height: 35px;
}

body.page-proppoints div.lp-pom-root .lp-pom-text .lplh-38,
body.page-proppoints div.lp-pom-root .lp-pom-text.nlh .lplh-38,
body.page-proppoints .cke-text .lplh-38,
body.page-proppoints .cke-text.nlh .lplh-38 {
  line-height: 38px;
}

body.page-proppoints div.lp-pom-root .lp-pom-text .lplh-40,
body.page-proppoints div.lp-pom-root .lp-pom-text.nlh .lplh-40,
body.page-proppoints .cke-text .lplh-40,
body.page-proppoints .cke-text.nlh .lplh-40 {
  line-height: 40px;
}

body.page-proppoints div.lp-pom-root .lp-pom-text .lplh-42,
body.page-proppoints div.lp-pom-root .lp-pom-text.nlh .lplh-42,
body.page-proppoints .cke-text .lplh-42,
body.page-proppoints .cke-text.nlh .lplh-42 {
  line-height: 42px;
}

body.page-proppoints div.lp-pom-root .lp-pom-text .lplh-43,
body.page-proppoints div.lp-pom-root .lp-pom-text.nlh .lplh-43,
body.page-proppoints .cke-text .lplh-43,
body.page-proppoints .cke-text.nlh .lplh-43 {
  line-height: 43px;
}

body.page-proppoints div.lp-pom-root .lp-pom-text .lplh-45,
body.page-proppoints div.lp-pom-root .lp-pom-text.nlh .lplh-45,
body.page-proppoints .cke-text .lplh-45,
body.page-proppoints .cke-text.nlh .lplh-45 {
  line-height: 45px;
}

body.page-proppoints div.lp-pom-root .lp-pom-text .lplh-58,
body.page-proppoints div.lp-pom-root .lp-pom-text.nlh .lplh-58,
body.page-proppoints .cke-text .lplh-58,
body.page-proppoints .cke-text.nlh .lplh-58 {
  line-height: 58px;
}

body.page-proppoints div.lp-pom-root .lp-pom-text .lplh-77,
body.page-proppoints div.lp-pom-root .lp-pom-text.nlh .lplh-77,
body.page-proppoints .cke-text .lplh-77,
body.page-proppoints .cke-text.nlh .lplh-77 {
  line-height: 77px;
}

body.page-proppoints div.lp-pom-root .lp-pom-text .lplh-86,
body.page-proppoints div.lp-pom-root .lp-pom-text.nlh .lplh-86,
body.page-proppoints .cke-text .lplh-86,
body.page-proppoints .cke-text.nlh .lplh-86 {
  line-height: 86px;
}

body.page-proppoints div.lp-pom-root .lp-pom-text .lplh-87,
body.page-proppoints div.lp-pom-root .lp-pom-text.nlh .lplh-87,
body.page-proppoints .cke-text .lplh-87,
body.page-proppoints .cke-text.nlh .lplh-87 {
  line-height: 87px;
}

body.page-proppoints div.lp-pom-root .lp-pom-text .lplh-115,
body.page-proppoints div.lp-pom-root .lp-pom-text.nlh .lplh-115,
body.page-proppoints .cke-text .lplh-115,
body.page-proppoints .cke-text.nlh .lplh-115 {
  line-height: 115px;
}

body.page-proppoints div.lp-pom-root .lp-pom-text p,
body.page-proppoints .cke-text p,
body.page-proppoints div.lp-pom-root .lp-pom-text blockquote,
body.page-proppoints .cke-text blockquote,
body.page-proppoints div.lp-pom-root .lp-pom-text ul,
body.page-proppoints .cke-text ul,
body.page-proppoints div.lp-pom-root .lp-pom-text dl,
body.page-proppoints .cke-text dl {
  margin-bottom: 16px;
}

body.page-proppoints div.lp-pom-root .lp-pom-text > p:last-child,
body.page-proppoints div.lp-pom-root .lp-pom-text > blockquote:last-child,
body.page-proppoints div.lp-pom-root .lp-pom-text > ul:last-child,
body.page-proppoints div.lp-pom-root .lp-pom-text > dl:last-child,
body.page-proppoints div.lp-pom-root .lp-pom-text > h1:last-child,
body.page-proppoints div.lp-pom-root .lp-pom-text > h2:last-child,
body.page-proppoints div.lp-pom-root .lp-pom-text > h3:last-child,
body.page-proppoints div.lp-pom-root .lp-pom-text > h4:last-child,
body.page-proppoints div.lp-pom-root .lp-pom-text > h5:last-child,
body.page-proppoints div.lp-pom-root .lp-pom-text > h6:last-child,
body.page-proppoints div.lp-pom-root .lp-pom-text .text-content > p:last-child,
body.page-proppoints div.lp-pom-root .lp-pom-text .text-content > blockquote:last-child,
body.page-proppoints div.lp-pom-root .lp-pom-text .text-content > ul:last-child,
body.page-proppoints div.lp-pom-root .lp-pom-text .text-content > dl:last-child,
body.page-proppoints div.lp-pom-root .lp-pom-text .text-content > h1:last-child,
body.page-proppoints div.lp-pom-root .lp-pom-text .text-content > h2:last-child,
body.page-proppoints div.lp-pom-root .lp-pom-text .text-content > h3:last-child,
body.page-proppoints div.lp-pom-root .lp-pom-text .text-content > h4:last-child,
body.page-proppoints div.lp-pom-root .lp-pom-text .text-content > h5:last-child,
body.page-proppoints div.lp-pom-root .lp-pom-text .text-content > h6:last-child {
  margin-bottom: 0;
}

body.page-proppoints div.lp-pom-root .lp-pom-text h1,
body.page-proppoints .cke-text h1,
body.page-proppoints div.lp-pom-root .lp-pom-text h2,
body.page-proppoints .cke-text h2,
body.page-proppoints div.lp-pom-root .lp-pom-text h3,
body.page-proppoints .cke-text h3,
body.page-proppoints div.lp-pom-root .lp-pom-text h4,
body.page-proppoints .cke-text h4,
body.page-proppoints div.lp-pom-root .lp-pom-text h5,
body.page-proppoints .cke-text h5,
body.page-proppoints div.lp-pom-root .lp-pom-text h6,
body.page-proppoints .cke-text h6 {
  font-weight: bolder;
}

body.page-proppoints div.lp-pom-root .lp-pom-text b,
body.page-proppoints .cke-text b,
body.page-proppoints div.lp-pom-root .lp-pom-text strong,
body.page-proppoints .cke-text strong {
  font-weight: bolder;
}

body.page-proppoints div.lp-pom-root .lp-pom-text optgroup,
body.page-proppoints .cke-text optgroup {
  font-weight: normal;
}

body.page-proppoints div.lp-pom-root .lp-pom-text abbr,
body.page-proppoints .cke-text abbr,
body.page-proppoints div.lp-pom-root .lp-pom-text acronym,
body.page-proppoints .cke-text acronym {
  border-bottom: 1px dotted #000;
  cursor: help;
}

body.page-proppoints div.lp-pom-root .lp-pom-text em,
body.page-proppoints .cke-text em {
  font-style: italic;
}

body.page-proppoints div.lp-pom-root .lp-pom-text del,
body.page-proppoints .cke-text del {
  text-decoration: line-through;
}

body.page-proppoints div.lp-pom-root .lp-pom-text blockquote,
body.page-proppoints .cke-text blockquote,
body.page-proppoints div.lp-pom-root .lp-pom-text ul,
body.page-proppoints .cke-text ul,
body.page-proppoints div.lp-pom-root .lp-pom-text ol,
body.page-proppoints .cke-text ol,
body.page-proppoints div.lp-pom-root .lp-pom-text dl,
body.page-proppoints .cke-text dl {
  margin: 14px;
  font-size: 14px;
  line-height: 17px;
}

body.page-proppoints div.lp-pom-root .lp-pom-text ul,
body.page-proppoints .cke-text ul {
  margin-left: 16px;
}

body.page-proppoints div.lp-pom-root .lp-pom-text ol,
body.page-proppoints .cke-text ol {
  margin-left: 25px;
}

body.page-proppoints div.lp-pom-root .lp-pom-text li,
body.page-proppoints .cke-text li {
  display: list-item;
}

body.page-proppoints div.lp-pom-root .lp-pom-text ol li,
body.page-proppoints .cke-text ol li {
  list-style: decimal outside;
}

body.page-proppoints div.lp-pom-root .lp-pom-text ul li,
body.page-proppoints .cke-text ul li {
  list-style: disc outside;
}

body.page-proppoints div.lp-pom-root .lp-pom-text dl dd,
body.page-proppoints .cke-text dl dd {
  margin-left: 14px;
}

body.page-proppoints div.lp-pom-root .lp-pom-text th,
body.page-proppoints .cke-text th,
body.page-proppoints div.lp-pom-root .lp-pom-text td,
body.page-proppoints .cke-text td {
  border: 1px solid #000;
  padding: 7px;
}

body.page-proppoints div.lp-pom-root .lp-pom-text th,
body.page-proppoints .cke-text th {
  font-weight: bold;
  text-align: center;
}

body.page-proppoints div.lp-pom-root .lp-pom-text caption,
body.page-proppoints .cke-text caption {
  margin-bottom: 7px;
  text-align: center;
}

body.page-proppoints div.lp-pom-root .lp-pom-text sup,
body.page-proppoints .cke-text sup {
  vertical-align: super;
}

body.page-proppoints div.lp-pom-root .lp-pom-text sub,
body.page-proppoints .cke-text sub {
  vertical-align: sub;
}

body.page-proppoints div.lp-pom-root #powered-by-unbounce {
  display: block !important;
  position: absolute !important;
  margin: 0 !important;
  padding: 0 !important;
  visibility: visible !important;
  text-indent: 0 !important;
  bottom: 0 !important;
  width: 100% !important;
  height: 48px !important;
  overflow: hidden !important;
  background: #0098db !important;
  z-index: 8675309 !important;
  text-align: center !important;
  font-size: 11px !important;
  color: #666 !important;
  font-weight: bold !important;
  transform: rotate(0deg) !important;
}

body.page-proppoints div.lp-pom-root #powered-by-unbounce a {
  display: block !important;
  position: static !important;
  visibility: visible !important;
  z-index: 18885159161 !important;
  text-indent: 0 !important;
  height: 44px !important;
}

body.page-proppoints div.lp-pom-root #powered-by-unbounce img {
  display: inline !important;
  position: static !important;
  visibility: visible !important;
  max-width: 440px;
  margin-top: 7px;
  width: 85% !important;
  vertical-align: bottom !important;
}

body.page-proppoints .lp-form-errors {
  position: absolute;
  display: none;
  z-index: 999999;
}

body.page-proppoints .lp-form-errors div.content {
  background-color: #ccc;
}

body.page-proppoints .lp-form-errors div.content div.error {
  background-color: #DF3935;
  padding: 5px 20px;
  font-size: 12px;
  font-weight: bold;
  color: #FFFFFF;
}

body.page-proppoints .lp-form-errors div.content div.error .error-msg {
  padding-right: 50px;
}

body.page-proppoints .lp-form-errors div.content div.error .error-button {
  position: absolute;
  right: 20px;
  top: 5px;
}

body.page-proppoints .lp-form-errors div.content div.error .error-button .error-toggle {
  float: right;
  margin-right: 3px;
  text-decoration: underline;
  cursor: pointer;
  color: #FFFFFF;
}

body.page-proppoints .lp-form-errors div.content div.error .error-button .error-toggle-arrow {
  float: right;
  width: 9px;
  height: 10px;
  margin-top: 2px;
  cursor: pointer;
  background-image: url("/images/triangle-toggle.png");
  background-repeat: no-repeat;
  background-position: 0px 1px;
  background-size: cover;
}

body.page-proppoints .lp-form-errors div.content ul {
  display: block !important;
  background-color: #FFD0D0;
  padding: 8px 15px;
  font-size: 12px;
  line-height: 16px;
  font-weight: normal;
  color: #DF3935;
}

body.page-proppoints .lp-form-errors div.content ul.hide {
  display: none !important;
}

body.page-proppoints .lp-form-errors div.content li {
  list-style: square;
  margin-left: 20px;
  margin-bottom: 2px;
}

body.page-proppoints div.lp-pom-root .lp-element.lp-code {
  overflow: hidden;
}

body.page-proppoints .lp-pom-root .lp-pom-image img {
  vertical-align: top;
}

body.page-proppoints .lp-pom-root .lp-pom-image a img {
  cursor: pointer;
}

body.page-proppoints .lp-pom-social-widget .horizontal {
  margin-left: 0;
  padding: 0;
}

body.page-proppoints .lp-pom-social-widget .horizontal .facebook_container {
  margin-left: 0;
  overflow: hidden;
}

body.page-proppoints .lp-pom-social-widget .widget {
  padding-right: 5px;
}

body.page-proppoints .lp-pom-social-widget .fb_edge_widget_with_comment span.fb_edge_comment_widget iframe.fb_ltr {
  display: none !important;
}

body.page-proppoints .lp-pom-social-widget iframe.fb_ltr {
  display: none !important;
}

body.page-proppoints .fb_edge_widget_with_comment span {
  width: 120px !important;
}

body.page-proppoints .fb_recommend .fb_edge_widget_with_comment iframe {
  width: 97px !important;
}

body.page-proppoints body.ms-windows .fb_recommend {
  overflow: hidden;
  width: 88px;
}

body.page-proppoints body.ms-windows .fb_like {
  overflow: hidden;
  width: 48px !important;
}

body.page-proppoints body.ms-windows .lp-editor .fb_recommend {
  overflow: hidden;
  width: 90px;
}

body.page-proppoints body.ms-windows .lp-editor .fb_like {
  overflow: hidden;
  width: 50px !important;
}

@-moz-document url-prefix() {
  body.page-proppoints body.ms-windows .fb_recommend {
    width: 86px !important;
  }
  body.page-proppoints body.ms-windows .lp-editor .fb_recommend {
    width: 90px !important;
  }
  body.page-proppoints body.ms-windows .lp-editor .fb_like {
    width: 50px !important;
  }
}

body.page-proppoints .fb_like .fb_edge_widget_with_comment iframe {
  width: 50px !important;
}

body.page-proppoints .lp-pom-social-widget .vertical {
  margin-left: 0;
  overflow: hidden;
}

body.page-proppoints .lp-pom-social-widget .horizontal.widget_container {
  margin-right: -5px;
}

body.page-proppoints .lp-pom-social-widget .widget.horizontal.container {
  margin-right: 0;
  white-space: nowrap;
  float: left;
}

body.page-proppoints .lp-pom-social-widget .facebook_container.horizontal.container {
  margin: 0;
}

body.page-proppoints .lp-pom-social-widget .widget_container.horizontal.top .twitter_container {
  margin-top: 40px;
}

body.page-proppoints .lp-pom-social-widget .widget_container.horizontal.top.widget-count-1 .twitter_container {
  margin-top: 0;
}

body.page-proppoints div.lp-social-widget-overlay {
  position: absolute;
  z-index: 9999;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #000000;
}

body.page-proppoints #ubpoverlay-outer {
  height: auto !important;
}

@media only screen and (max-width: 600px) {
  body.page-proppoints #ubpoverlay-content {
    overflow: auto !important;
    -webkit-overflow-scrolling: touch !important;
  }
}

body.page-proppoints {
  padding-top: 0 !important;
  color: #4a646f;
}

body.page-proppoints a {
  color: #0000ff;
  text-decoration: none;
}

body.page-proppoints #lp-pom-image-128-holder {
  background-image: url(https://www.edgeprop.my/sites/all/themes/markone/logo.png);
  position: absolute;
  left: -1000000;
  visibility: none;
}

body.page-proppoints #lp-pom-image-144-holder {
  background-image: url(http://s3-ap-southeast-1.amazonaws.com/files.theedgeproperty.com.my/public/images/proppoints/card1.jpg);
  position: absolute;
  left: -1000000;
  visibility: none;
}

body.page-proppoints #lp-pom-image-152-holder {
  background-image: url(http://s3-ap-southeast-1.amazonaws.com/files.theedgeproperty.com.my/public/images/proppoints/card2.jpg);
  position: absolute;
  left: -1000000;
  visibility: none;
}

body.page-proppoints #lp-pom-image-157-holder {
  background-image: url(http://s3-ap-southeast-1.amazonaws.com/files.theedgeproperty.com.my/public/images/proppoints/card3.jpg);
  position: absolute;
  left: -1000000;
  visibility: none;
}

body.page-proppoints #lp-pom-image-162-holder {
  background-image: url(http://s3-ap-southeast-1.amazonaws.com/files.theedgeproperty.com.my/public/images/proppoints/card4.jpg);
  position: absolute;
  left: -1000000;
  visibility: none;
}

body.page-proppoints #lp-pom-image-174-holder {
  background-image: url(http://s3-ap-southeast-1.amazonaws.com/files.theedgeproperty.com.my/public/images/proppoints/table_tabs.png);
  position: absolute;
  left: -1000000;
  visibility: none;
}

body.page-proppoints #lp-pom-root {
  display: block;
  background: #e6e7e8;
  -pie-background: #e6e7e8;
  border-style: none;
  margin: auto;
  padding-top: 0px;
  behavior: url(http://d9hhrg4mnvzow.cloudfront.netPIE.htc);
  border-radius: 0px;
  min-width: 1280px;
  height: auto;
}

body.page-proppoints #lp-pom-block-8 {
  display: block;
  background: #80bada;
  background-image: url(http://s3-ap-southeast-1.amazonaws.com/files.theedgeproperty.com.my/public/images/proppoints/proppoints-v2.jpg);
  background-repeat: no-repeat;
  background-position: center bottom;
  -pie-background: #80bada url(http://s3-ap-southeast-1.amazonaws.com/files.theedgeproperty.com.my/public/images/proppoints/proppoints-v2.jpg) no-repeat center bottom;
  border-style: none;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 0px;
  behavior: url(http://d9hhrg4mnvzow.cloudfront.netPIE.htc);
  border-radius: 0px;
  width: 100%;
  height: 602px;
  position: relative;
}

body.page-proppoints #lp-pom-text-11 {
  display: block;
  background: rgba(255, 255, 255, 0);
  -pie-background: rgba(255, 255, 255, 0);
  border-style: none;
  behavior: url(http://d9hhrg4mnvzow.cloudfront.netPIE.htc);
  border-radius: 0px;
  right: 141px;
  top: 179px;
  z-index: 1;
  width: 325px;
  height: 52px;
  position: absolute;
}

body.page-proppoints #lp-pom-text-12 {
  display: block;
  background: rgba(255, 255, 255, 0);
  -pie-background: rgba(255, 255, 255, 0);
  border-style: none;
  behavior: url(http://d9hhrg4mnvzow.cloudfront.netPIE.htc);
  border-radius: 0px;
  right: 127px;
  top: 122px;
  z-index: 2;
  width: 341px;
  height: 43px;
  position: absolute;
}

body.page-proppoints #lp-pom-image-128 {
  display: block;
  background: rgba(255, 255, 255, 0);
  -pie-background: rgba(255, 255, 255, 0);
  right: 526px;
  top: 46px;
  z-index: 10;
  position: absolute;
}

body.page-proppoints #lp-pom-block-21 {
  display: block;
  background: white;
  -pie-background: white;
  border-style: none;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 0px;
  behavior: url(http://d9hhrg4mnvzow.cloudfront.netPIE.htc);
  border-radius: 0px;
  width: 100%;
  height: 358px;
  position: relative;
}

body.page-proppoints #lp-pom-box-25 {
  display: block;
  background: white;
  -pie-background: white;
  border-style: solid;
  border-width: 1px;
  border-color: #c1cfd4;
  behavior: url(http://d9hhrg4mnvzow.cloudfront.netPIE.htc);
  border-radius: 0px;
  left: 39px;
  top: 733px;
  z-index: 3;
  width: 278px;
  height: 458px;
  position: absolute;
}

body.page-proppoints #lp-pom-text-26 {
  display: block;
  background: rgba(255, 255, 255, 0);
  -pie-background: rgba(255, 255, 255, 0);
  border-style: none;
  behavior: url(http://d9hhrg4mnvzow.cloudfront.netPIE.htc);
  border-radius: 0px;
  left: 23px;
  top: 274px;
  z-index: 4;
  width: 226px;
  height: 44px;
  position: absolute;
}

body.page-proppoints #lp-pom-text-27 {
  display: block;
  background: rgba(255, 255, 255, 0);
  -pie-background: rgba(255, 255, 255, 0);
  border-style: none;
  behavior: url(http://d9hhrg4mnvzow.cloudfront.netPIE.htc);
  border-radius: 0px;
  left: 23px;
  top: 234px;
  z-index: 5;
  width: 226px;
  height: 24px;
  position: absolute;
}

body.page-proppoints #lp-pom-text-133 {
  display: block;
  background: rgba(255, 81, 34, 0);
  -pie-background: rgba(255, 81, 34, 0);
  border-style: none;
  behavior: url(http://d9hhrg4mnvzow.cloudfront.netPIE.htc);
  border-radius: 0px;
  left: 23px;
  top: 412px;
  z-index: 6;
  width: 226px;
  height: 22px;
  position: absolute;
}

body.page-proppoints #lp-pom-image-144 {
  display: block;
  background: rgba(255, 255, 255, 0);
  -pie-background: rgba(255, 255, 255, 0);
  left: 0px;
  top: 0px;
  z-index: 7;
  position: absolute;
}

body.page-proppoints #lp-pom-text-62 {
  display: block;
  background: rgba(255, 255, 255, 0);
  -pie-background: rgba(255, 255, 255, 0);
  border-style: none;
  behavior: url(http://d9hhrg4mnvzow.cloudfront.netPIE.htc);
  border-radius: 0px;
  left: 0px;
  top: 671px;
  z-index: 8;
  width: 1280px;
  height: 29px;
  position: absolute;
}

body.page-proppoints #lp-pom-box-148 {
  display: block;
  background: white;
  -pie-background: white;
  border-style: solid;
  border-width: 1px;
  border-color: #c1cfd4;
  behavior: url(http://d9hhrg4mnvzow.cloudfront.netPIE.htc);
  border-radius: 0px;
  left: 345px;
  top: 733px;
  z-index: 27;
  width: 278px;
  height: 458px;
  position: absolute;
}

body.page-proppoints #lp-pom-text-149 {
  display: block;
  background: rgba(255, 255, 255, 0);
  -pie-background: rgba(255, 255, 255, 0);
  border-style: none;
  behavior: url(http://d9hhrg4mnvzow.cloudfront.netPIE.htc);
  border-radius: 0px;
  left: 23px;
  top: 273px;
  z-index: 28;
  width: 226px;
  height: 44px;
  position: absolute;
}

body.page-proppoints #lp-pom-text-150 {
  display: block;
  background: rgba(255, 255, 255, 0);
  -pie-background: rgba(255, 255, 255, 0);
  border-style: none;
  behavior: url(http://d9hhrg4mnvzow.cloudfront.netPIE.htc);
  border-radius: 0px;
  left: 23px;
  top: 234px;
  z-index: 28;
  width: 226px;
  height: 24px;
  position: absolute;
}

body.page-proppoints #lp-pom-text-151 {
  display: block;
  background: rgba(255, 81, 34, 0);
  -pie-background: rgba(255, 81, 34, 0);
  border-style: none;
  behavior: url(http://d9hhrg4mnvzow.cloudfront.netPIE.htc);
  border-radius: 0px;
  left: 23px;
  top: 412px;
  z-index: 29;
  width: 226px;
  height: 22px;
  position: absolute;
}

body.page-proppoints #lp-pom-image-152 {
  display: block;
  background: rgba(255, 255, 255, 0);
  -pie-background: rgba(255, 255, 255, 0);
  left: 0px;
  top: 0px;
  z-index: 30;
  position: absolute;
}

body.page-proppoints #lp-pom-box-153 {
  display: block;
  background: white;
  -pie-background: white;
  border-style: solid;
  border-width: 1px;
  border-color: #c1cfd4;
  behavior: url(http://d9hhrg4mnvzow.cloudfront.netPIE.htc);
  border-radius: 0px;
  left: 653px;
  top: 733px;
  z-index: 21;
  width: 278px;
  height: 458px;
  position: absolute;
}

body.page-proppoints #lp-pom-text-154 {
  display: block;
  background: rgba(255, 255, 255, 0);
  -pie-background: rgba(255, 255, 255, 0);
  border-style: none;
  behavior: url(http://d9hhrg4mnvzow.cloudfront.netPIE.htc);
  border-radius: 0px;
  left: 23px;
  top: 274px;
  z-index: 22;
  width: 226px;
  height: 44px;
  position: absolute;
}

body.page-proppoints #lp-pom-text-155 {
  display: block;
  background: rgba(255, 255, 255, 0);
  -pie-background: rgba(255, 255, 255, 0);
  border-style: none;
  behavior: url(http://d9hhrg4mnvzow.cloudfront.netPIE.htc);
  border-radius: 0px;
  left: 23px;
  top: 234px;
  z-index: 23;
  width: 226px;
  height: 24px;
  position: absolute;
}

body.page-proppoints #lp-pom-text-156 {
  display: block;
  background: rgba(255, 81, 34, 0);
  -pie-background: rgba(255, 81, 34, 0);
  border-style: none;
  behavior: url(http://d9hhrg4mnvzow.cloudfront.netPIE.htc);
  border-radius: 0px;
  left: 23px;
  top: 415px;
  z-index: 24;
  width: 226px;
  height: 22px;
  position: absolute;
}

body.page-proppoints #lp-pom-image-157 {
  display: block;
  background: rgba(255, 255, 255, 0);
  -pie-background: rgba(255, 255, 255, 0);
  left: 0px;
  top: 0px;
  z-index: 25;
  position: absolute;
}

body.page-proppoints #lp-pom-box-158 {
  display: block;
  background: white;
  -pie-background: white;
  border-style: solid;
  border-width: 1px;
  border-color: #c1cfd4;
  behavior: url(http://d9hhrg4mnvzow.cloudfront.netPIE.htc);
  border-radius: 0px;
  left: 962px;
  top: 733px;
  z-index: 16;
  width: 278px;
  height: 458px;
  position: absolute;
}

body.page-proppoints #lp-pom-text-159 {
  display: block;
  background: rgba(255, 255, 255, 0);
  -pie-background: rgba(255, 255, 255, 0);
  border-style: none;
  behavior: url(http://d9hhrg4mnvzow.cloudfront.netPIE.htc);
  border-radius: 0px;
  left: 23px;
  top: 273px;
  z-index: 17;
  width: 226px;
  height: 132px;
  position: absolute;
}

body.page-proppoints #lp-pom-text-160 {
  display: block;
  background: rgba(255, 255, 255, 0);
  -pie-background: rgba(255, 255, 255, 0);
  border-style: none;
  behavior: url(http://d9hhrg4mnvzow.cloudfront.netPIE.htc);
  border-radius: 0px;
  left: 23px;
  top: 234px;
  z-index: 18;
  width: 226px;
  height: 24px;
  position: absolute;
}

body.page-proppoints #lp-pom-text-161 {
  display: block;
  background: rgba(255, 81, 34, 0);
  -pie-background: rgba(255, 81, 34, 0);
  border-style: none;
  behavior: url(http://d9hhrg4mnvzow.cloudfront.netPIE.htc);
  border-radius: 0px;
  left: 23px;
  top: 417px;
  z-index: 19;
  width: 226px;
  height: 22px;
  position: absolute;
}

body.page-proppoints #lp-pom-image-162 {
  display: block;
  background: rgba(255, 255, 255, 0);
  -pie-background: rgba(255, 255, 255, 0);
  left: 0px;
  top: 0px;
  z-index: 20;
  position: absolute;
}

body.page-proppoints #lp-pom-block-22 {
  display: block;
  background: #e6e7e8;
  -pie-background: #e6e7e8;
  border-style: solid none none none;
  border-width: 1px;
  border-color: #c1cfd4;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 0px;
  behavior: url(http://d9hhrg4mnvzow.cloudfront.netPIE.htc);
  border-radius: 0px;
  width: 1280px;
  height: 385px;
  position: relative;
}

body.page-proppoints #lp-code-138 {
  display: block;
  left: 143px;
  top: 1133px;
  z-index: 11;
  width: 994px;
  height: 497px;
  position: absolute;
}

body.page-proppoints #lp-code-139 {
  display: block;
  left: 687px;
  top: 1070px;
  z-index: 12;
  width: 473px;
  height: 77px;
  position: absolute;
}

body.page-proppoints #lp-pom-text-163 {
  display: block;
  background: rgba(255, 255, 255, 0);
  -pie-background: rgba(255, 255, 255, 0);
  border-style: none;
  behavior: url(http://d9hhrg4mnvzow.cloudfront.netPIE.htc);
  border-radius: 0px;
  left: 0px;
  top: 1000px;
  z-index: 13;
  width: 1280px;
  height: 29px;
  position: absolute;
}

body.page-proppoints #lp-pom-image-174 {
  display: none;
  background: rgba(255, 255, 255, 0);
  -pie-background: rgba(255, 255, 255, 0);
  left: 90px;
  top: 1348px;
  z-index: 31;
  position: absolute;
}

body.page-proppoints #lp-pom-block-23 {
  display: block;
  background: white;
  -pie-background: white;
  border-style: none;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 2px;
  behavior: url(http://d9hhrg4mnvzow.cloudfront.netPIE.htc);
  border-radius: 0px;
  width: 100%;
  height: 1673px;
  position: relative;
}

body.page-proppoints #lp-pom-text-165 {
  display: block;
  background: rgba(255, 255, 255, 0);
  -pie-background: rgba(255, 255, 255, 0);
  border-style: none;
  behavior: url(http://d9hhrg4mnvzow.cloudfront.netPIE.htc);
  border-radius: 0px;
  left: 0px;
  top: 1380px;
  z-index: 14;
  width: 1280px;
  height: 29px;
  position: absolute;
}

body.page-proppoints #lp-code-168 {
  display: block;
  left: 143px;
  top: 1378px;
  z-index: 15;
  width: 994px;
  height: auto;
  position: absolute;
}

body.page-proppoints #lp-pom-block-24 {
  display: block;
  background: #0f314c;
  -pie-background: #0f314c;
  border-style: none;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 1px;
  behavior: url(http://d9hhrg4mnvzow.cloudfront.netPIE.htc);
  border-radius: 0px;
  width: 100%;
  height: 95px;
  position: relative;
  z-index: 9;
}

body.page-proppoints #lp-pom-text-76 {
  display: block;
  background: rgba(255, 255, 255, 0);
  -pie-background: rgba(255, 255, 255, 0);
  border-style: none;
  behavior: url(http://d9hhrg4mnvzow.cloudfront.netPIE.htc);
  border-radius: 0px;
  left: 0px;
  top: 3328px;
  z-index: 9;
  width: 1280px;
  height: 22px;
  position: absolute;
}

body.page-proppoints #lp-pom-root .lp-positioned-content {
  top: 0px;
  width: 1280px;
  margin-left: -640px;
}

body.page-proppoints #lp-pom-block-8 .lp-pom-block-content {
  margin-left: auto;
  margin-right: auto;
  width: 1280px;
  height: 602px;
}

body.page-proppoints #lp-pom-block-21 .lp-pom-block-content {
  margin-left: auto;
  margin-right: auto;
  width: 1280px;
  height: auto;
}

body.page-proppoints #lp-pom-block-22 .lp-pom-block-content {
  margin-left: auto;
  margin-right: auto;
  width: 1280px;
  height: 693px;
}

body.page-proppoints #lp-pom-block-23 .lp-pom-block-content {
  margin-left: auto;
  margin-right: auto;
  width: 1280px;
  height: auto;
}

body.page-proppoints #lp-pom-block-24 .lp-pom-block-content {
  margin-left: auto;
  margin-right: auto;
  width: 1280px;
  height: 95px;
}

body.page-proppoints #lp-pom-image-128 .lp-pom-image-container {
  border-style: none;
  behavior: url(http://d9hhrg4mnvzow.cloudfront.netPIE.htc);
  border-radius: 0px;
  width: 141px;
  height: 51px;
}

body.page-proppoints #lp-pom-image-128 .lp-pom-image-container img {
  width: 110px;
  height: auto;
}

body.page-proppoints #lp-pom-image-144 .lp-pom-image-container {
  border-style: none;
  behavior: url(http://d9hhrg4mnvzow.cloudfront.netPIE.htc);
  border-radius: 0px;
  width: 278px;
  height: 208px;
}

body.page-proppoints #lp-pom-image-144 .lp-pom-image-container img {
  width: 278px;
  height: 208px;
}

body.page-proppoints #lp-pom-image-152 .lp-pom-image-container {
  border-style: none;
  behavior: url(http://d9hhrg4mnvzow.cloudfront.netPIE.htc);
  border-radius: 0px;
  width: 278px;
  height: 208px;
}

body.page-proppoints #lp-pom-image-152 .lp-pom-image-container img {
  width: 278px;
  height: 208px;
}

body.page-proppoints #lp-pom-image-157 .lp-pom-image-container {
  border-style: none;
  behavior: url(http://d9hhrg4mnvzow.cloudfront.netPIE.htc);
  border-radius: 0px;
  width: 278px;
  height: 208px;
}

body.page-proppoints #lp-pom-image-157 .lp-pom-image-container img {
  width: 278px;
  height: 208px;
}

body.page-proppoints #lp-pom-image-162 .lp-pom-image-container {
  border-style: none;
  behavior: url(http://d9hhrg4mnvzow.cloudfront.netPIE.htc);
  border-radius: 0px;
  width: 278px;
  height: 208px;
}

body.page-proppoints #lp-pom-image-162 .lp-pom-image-container img {
  width: 278px;
  height: 208px;
}

body.page-proppoints #lp-pom-image-174 .lp-pom-image-container {
  border-style: none;
  behavior: url(http://d9hhrg4mnvzow.cloudfront.netPIE.htc);
  border-radius: 0px;
  width: 203px;
  height: 23px;
}

body.page-proppoints #lp-pom-image-174 .lp-pom-image-container img {
  width: 203px;
  height: 23px;
}

body.page-proppoints .lp-pom-image-container {
  overflow: hidden;
}

body.page-proppoints #lp-pom-root-color-overlay {
  position: absolute;
  background: none;
  top: 0;
  width: 100%;
  height: auto;
  min-height: 100%;
}

body.page-proppoints #lp-pom-block-8-color-overlay {
  position: absolute;
  background: none;
  height: 602px;
  width: 100%;
}

body.page-proppoints #lp-pom-block-21-color-overlay {
  position: absolute;
  background: none;
  height: auto;
  width: 100%;
}

body.page-proppoints #lp-pom-block-22-color-overlay {
  position: absolute;
  background: none;
  height: 693px;
  width: 1280px;
}

body.page-proppoints #lp-pom-block-23-color-overlay {
  position: absolute;
  background: none;
  height: auto;
  width: 100%;
}

body.page-proppoints #lp-pom-block-24-color-overlay {
  position: absolute;
  background: none;
  height: 95px;
  width: 100%;
}

body.page-proppoints #lp-pom-box-25-color-overlay {
  position: absolute;
  background: none;
  behavior: url(http://d9hhrg4mnvzow.cloudfront.netPIE.htc);
  height: 458px;
  width: 278px;
}

body.page-proppoints #lp-pom-box-148-color-overlay {
  position: absolute;
  background: none;
  behavior: url(http://d9hhrg4mnvzow.cloudfront.netPIE.htc);
  height: 458px;
  width: 278px;
}

body.page-proppoints #lp-pom-box-153-color-overlay {
  position: absolute;
  background: none;
  behavior: url(http://d9hhrg4mnvzow.cloudfront.netPIE.htc);
  height: 458px;
  width: 278px;
}

body.page-proppoints #lp-pom-box-158-color-overlay {
  position: absolute;
  background: none;
  behavior: url(http://d9hhrg4mnvzow.cloudfront.netPIE.htc);
  height: 458px;
  width: 278px;
}

body.page-proppoints .brand-color {
  color: #ff5122;
}

body.page-proppoints .brand-blue {
  color: #363a94;
}

body.page-proppoints .lplh-22 span.boxtext {
  font-size: 16px;
}

body.page-proppoints .lplh-24 a span.pointsy {
  font-size: 24px;
  font-weight: 100;
  color: #0f314c;
}

body.page-proppoints #title-faq-1, body.page-proppoints #title-faq-2, body.page-proppoints #title-faq-3, body.page-proppoints #title-faq-4, body.page-proppoints #title-faq-5, body.page-proppoints #title-faq-6, body.page-proppoints #title-faq-7, body.page-proppoints #title-faq-8, body.page-proppoints #title-faq-9, body.page-proppoints #title-faq-10 {
  color: black;
  padding: 25px;
  font-size: 20px;
}

body.page-proppoints * {
  -webkit-box-sizing: unset;
  -moz-box-sizing: unset;
  box-sizing: unset;
}

body.page-proppoints .lc-boxes .col-md-6 {
  padding: 0;
  width: 48%;
}

body.page-proppoints .lc-boxes h3 {
  text-align: center;
  font-weight: 400;
  font-size: 24px;
  margin-bottom: 30px;
  padding-top: 40px;
}

body.page-proppoints .lc-boxes-box {
  border: 1px solid #c1cfd4;
}

@media screen and (min-width: 601px) {
  body.page-proppoints .lc-boxes-box.float-desktop-only {
    float: right;
  }
}

body.page-proppoints .lc-boxes-box span {
  display: block;
  font-size: 13px;
  line-height: 19px;
  padding: 15px 0 10px;
}

body.page-proppoints .lc-boxes-box span.maintext {
  font-size: 16px;
  color: #ff5122;
}

body.page-proppoints .lc-boxes-box span.maintext a {
  color: #ff5122;
  position: relative;
  bottom: -39px;
}

body.page-proppoints .lc-boxes-box span.title {
  font-size: 24px;
  font-weight: 400;
  color: #0f314c;
}

@media screen and (min-width: 601px) and (max-width: 991px) {
  body.page-proppoints .lc-boxes-box {
    height: 207px;
  }
  body.page-proppoints .lc-boxes-box .col-md-6.tablet-only {
    position: relative;
    top: -204px;
    right: -303px;
  }
}

@media screen and (max-width: 600px) {
  body.page-proppoints .lc-boxes .col-md-6 {
    padding: 0;
    width: 100%;
  }
  body.page-proppoints .lc-boxes-box {
    height: 444px;
    margin-bottom: 30px;
  }
  body.page-proppoints .lc-boxes-box .col-md-6 img {
    width: 100%;
  }
  body.page-proppoints .lc-boxes-box span {
    padding: 15px 15px 5px;
  }
}

body.page-proppoints .img-proppoints-faq9 {
  max-width: 450px;
  float: right;
  margin-right: 31px;
  margin-bottom: 31px;
}

@media screen and (max-width: 425px) {
  body.page-proppoints .img-proppoints-faq9 {
    max-width: 90%;
    float: none;
    margin-right: 0;
    margin: 0 auto;
    margin-left: 15px;
  }
}

body.page-proppoints .footprint-tabler {
  font-size: 1rem;
  font-style: italic;
  position: relative;
  left: 9px;
  top: 9px;
}

@media screen and (max-width: 425px) {
  body.page-proppoints .footprint-tabler {
    left: 4px;
    top: 7px;
  }
}

body.page-proppoints .m-faq-m {
  margin-top: 60px;
}

@media screen and (max-width: 425px) {
  body.page-proppoints .m-faq-m {
    margin-top: 260px;
  }
}

body.page-proppoints ul.simple-bullet li {
  list-style-type: circle !important;
  margin-bottom: 5px;
  font-size: 1.2rem;
  margin-left: 15px;
}

body.page-proppoints ul.simple-bullet li:before {
  content: "";
}

body.page-proppoints ul.simple-bullet li:first-child {
  margin-top: 10px;
}

@media screen and (max-width: 425px) {
  body.page-proppoints ul.simple-bullet li:first-child {
    margin-top: 3px;
  }
}

@media screen and (max-width: 425px) {
  body.page-proppoints ul.simple-bullet li {
    font-size: 1rem;
    margin-bottom: 3px;
  }
}

@media only screen and (max-width: 600px) {
  body.page-proppoints #lp-pom-image-128-holder {
    background-image: url(https://www.edgeprop.my/sites/all/themes/markone/logo.png);
    position: absolute;
    left: -1000000;
    visibility: none;
  }
  body.page-proppoints #lp-pom-image-144-holder {
    background-image: url(http://s3-ap-southeast-1.amazonaws.com/files.theedgeproperty.com.my/public/images/proppoints/card1.jpg);
    position: absolute;
    left: -1000000;
    visibility: none;
  }
  body.page-proppoints #lp-pom-image-152-holder {
    background-image: url(http://s3-ap-southeast-1.amazonaws.com/files.theedgeproperty.com.my/public/images/proppoints/card2.jpg);
    position: absolute;
    left: -1000000;
    visibility: none;
  }
  body.page-proppoints #lp-pom-image-157-holder {
    background-image: url(http://s3-ap-southeast-1.amazonaws.com/files.theedgeproperty.com.my/public/images/proppoints/card3.jpg);
    position: absolute;
    left: -1000000;
    visibility: none;
  }
  body.page-proppoints #lp-pom-image-162-holder {
    background-image: url(http://s3-ap-southeast-1.amazonaws.com/files.theedgeproperty.com.my/public/images/proppoints/card4.jpg);
    position: absolute;
    left: -1000000;
    visibility: none;
  }
  body.page-proppoints #lp-pom-image-174-holder {
    background-image: url(http://s3-ap-southeast-1.amazonaws.com/files.theedgeproperty.com.my/public/images/proppoints/table_tabs.png);
    position: absolute;
    left: -1000000;
    visibility: none;
  }
  body.page-proppoints #lp-pom-text-11 {
    left: 35px;
    top: 198px;
    display: block;
    background: rgba(255, 255, 255, 0);
    -pie-background: rgba(255, 255, 255, 0);
    border-style: none;
    behavior: url(http://d9hhrg4mnvzow.cloudfront.net/PIE.htc);
    border-radius: 0px;
    z-index: 1;
    width: 259px;
    height: 78px;
    transform: scale(0.9);
    transform-origin: 0 0;
    -webkit-transform: scale(0.9);
    -webkit-transform-origin: 0 0;
    position: absolute;
  }
  body.page-proppoints #lp-pom-text-12 {
    left: 35px;
    top: 148px;
    display: block;
    background: rgba(255, 255, 255, 0);
    -pie-background: rgba(255, 255, 255, 0);
    border-style: none;
    behavior: url(http://d9hhrg4mnvzow.cloudfront.net/PIE.htc);
    border-radius: 0px;
    z-index: 2;
    width: 359px;
    height: 43px;
    transform: scale(0.75);
    transform-origin: 0 0;
    -webkit-transform: scale(0.75);
    -webkit-transform-origin: 0 0;
    position: absolute;
  }
  body.page-proppoints #lp-pom-root {
    display: block;
    background: #e6e7e8;
    -pie-background: #e6e7e8;
    border-style: none;
    margin: auto;
    padding-top: 0px;
    behavior: url(http://d9hhrg4mnvzow.cloudfront.net/PIE.htc);
    border-radius: 0px;
    min-width: 320px;
    height: auto;
  }
  body.page-proppoints #lp-pom-block-8 {
    display: block;
    background: #80bada;
    background-image: url(http://s3-ap-southeast-1.amazonaws.com/files.theedgeproperty.com.my/public/images/proppoints/proppoints_main_mobile.jpg);
    background-repeat: no-repeat;
    background-position: center bottom;
    -pie-background: #80bada url(http://s3-ap-southeast-1.amazonaws.com/files.theedgeproperty.com.my/public/images/proppoints/proppoints_main_mobile.jpg) no-repeat center bottom;
    border-style: none;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 0px;
    behavior: url(http://d9hhrg4mnvzow.cloudfront.net/PIE.htc);
    border-radius: 0px;
    width: 100%;
    height: 568px;
    position: relative;
  }
  body.page-proppoints #lp-pom-image-128 {
    display: block;
    background: rgba(255, 255, 255, 0);
    -pie-background: rgba(255, 255, 255, 0);
    left: 35px;
    top: 51px;
    z-index: 10;
    position: absolute;
  }
  body.page-proppoints #lp-pom-block-21 {
    display: block;
    background: white;
    -pie-background: white;
    border-style: none;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 0px;
    behavior: url(http://d9hhrg4mnvzow.cloudfront.net/PIE.htc);
    border-radius: 0px;
    width: 100%;
    height: 1086px;
    position: relative;
  }
  body.page-proppoints #lp-pom-box-25 {
    display: block;
    background: white;
    -pie-background: white;
    border-style: solid;
    border-width: 1px;
    border-color: #c1cfd4;
    behavior: url(http://d9hhrg4mnvzow.cloudfront.net/PIE.htc);
    border-radius: 0px;
    left: 20px;
    top: 670px;
    z-index: 3;
    width: 278px;
    height: 458px;
    position: absolute;
  }
  body.page-proppoints #lp-pom-text-26 {
    display: block;
    background: rgba(255, 255, 255, 0);
    -pie-background: rgba(255, 255, 255, 0);
    border-style: none;
    behavior: url(http://d9hhrg4mnvzow.cloudfront.net/PIE.htc);
    border-radius: 0px;
    top: 274px;
    z-index: 4;
    width: 226px;
    height: 44px;
    position: absolute;
  }
  body.page-proppoints #lp-pom-text-27 {
    display: block;
    background: rgba(255, 255, 255, 0);
    -pie-background: rgba(255, 255, 255, 0);
    border-style: none;
    behavior: url(http://d9hhrg4mnvzow.cloudfront.net/PIE.htc);
    border-radius: 0px;
    top: 234px;
    z-index: 5;
    width: 226px;
    height: 24px;
    position: absolute;
  }
  body.page-proppoints #lp-pom-box-26,
  body.page-proppoints #lp-pom-box-28 {
    left: 6px;
  }
  body.page-proppoints #lp-pom-text-133 {
    display: block;
    background: rgba(255, 81, 34, 0);
    -pie-background: rgba(255, 81, 34, 0);
    border-style: none;
    behavior: url(http://d9hhrg4mnvzow.cloudfront.net/PIE.htc);
    border-radius: 0px;
    left: 23px;
    top: 412px;
    z-index: 6;
    width: 226px;
    height: 22px;
    position: absolute;
  }
  body.page-proppoints #lp-pom-image-144 {
    display: block;
    background: rgba(255, 255, 255, 0);
    -pie-background: rgba(255, 255, 255, 0);
    left: 0px;
    top: 0px;
    z-index: 7;
    position: absolute;
  }
  body.page-proppoints #lp-pom-text-62 {
    display: block;
    background: rgba(255, 255, 255, 0);
    -pie-background: rgba(255, 255, 255, 0);
    border-style: none;
    behavior: url(http://d9hhrg4mnvzow.cloudfront.net/PIE.htc);
    border-radius: 0px;
    left: 0px;
    top: 599px;
    z-index: 8;
    width: 320px;
    height: 29px;
    position: absolute;
  }
  body.page-proppoints #lp-pom-box-148 {
    display: block;
    background: white;
    -pie-background: white;
    border-style: solid;
    border-width: 1px;
    border-color: #c1cfd4;
    behavior: url(http://d9hhrg4mnvzow.cloudfront.net/PIE.htc);
    border-radius: 0px;
    left: 20px;
    top: 1162px;
    z-index: 27;
    width: 278px;
    height: 458px;
    position: absolute;
  }
  body.page-proppoints #lp-pom-text-149 {
    display: block;
    background: rgba(255, 255, 255, 0);
    -pie-background: rgba(255, 255, 255, 0);
    border-style: none;
    behavior: url(http://d9hhrg4mnvzow.cloudfront.net/PIE.htc);
    border-radius: 0px;
    left: 23px;
    top: 273px;
    z-index: 28;
    width: 226px;
    height: 66px;
    position: absolute;
  }
  body.page-proppoints #lp-pom-text-150 {
    display: block;
    background: rgba(255, 255, 255, 0);
    -pie-background: rgba(255, 255, 255, 0);
    border-style: none;
    behavior: url(http://d9hhrg4mnvzow.cloudfront.net/PIE.htc);
    border-radius: 0px;
    left: 23px;
    top: 234px;
    z-index: 28;
    width: 226px;
    height: 24px;
    position: absolute;
  }
  body.page-proppoints #lp-pom-text-151 {
    display: block;
    background: rgba(255, 81, 34, 0);
    -pie-background: rgba(255, 81, 34, 0);
    border-style: none;
    behavior: url(http://d9hhrg4mnvzow.cloudfront.net/PIE.htc);
    border-radius: 0px;
    left: 23px;
    top: 416px;
    z-index: 29;
    width: 226px;
    height: 22px;
    position: absolute;
  }
  body.page-proppoints #lp-pom-image-152 {
    display: block;
    background: rgba(255, 255, 255, 0);
    -pie-background: rgba(255, 255, 255, 0);
    left: 0px;
    top: 0px;
    z-index: 30;
    position: absolute;
  }
  body.page-proppoints #lp-pom-box-153 {
    display: block;
    background: white;
    -pie-background: white;
    border-style: solid;
    border-width: 1px;
    border-color: #c1cfd4;
    behavior: url(http://d9hhrg4mnvzow.cloudfront.net/PIE.htc);
    border-radius: 0px;
    left: 20px;
    top: 1651px;
    z-index: 21;
    width: 278px;
    height: 458px;
    position: absolute;
  }
  body.page-proppoints #lp-pom-text-154 {
    display: block;
    background: rgba(255, 255, 255, 0);
    -pie-background: rgba(255, 255, 255, 0);
    border-style: none;
    behavior: url(http://d9hhrg4mnvzow.cloudfront.net/PIE.htc);
    border-radius: 0px;
    left: 23px;
    top: 274px;
    z-index: 22;
    width: 226px;
    height: 66px;
    position: absolute;
  }
  body.page-proppoints #lp-pom-text-155 {
    display: block;
    background: rgba(255, 255, 255, 0);
    -pie-background: rgba(255, 255, 255, 0);
    border-style: none;
    behavior: url(http://d9hhrg4mnvzow.cloudfront.net/PIE.htc);
    border-radius: 0px;
    left: 23px;
    top: 234px;
    z-index: 23;
    width: 226px;
    height: 24px;
    position: absolute;
  }
  body.page-proppoints #lp-pom-text-156 {
    display: block;
    background: rgba(255, 81, 34, 0);
    -pie-background: rgba(255, 81, 34, 0);
    border-style: none;
    behavior: url(http://d9hhrg4mnvzow.cloudfront.net/PIE.htc);
    border-radius: 0px;
    left: 23px;
    top: 418px;
    z-index: 24;
    width: 226px;
    height: 22px;
    position: absolute;
  }
  body.page-proppoints #lp-pom-image-157 {
    display: block;
    background: rgba(255, 255, 255, 0);
    -pie-background: rgba(255, 255, 255, 0);
    left: 0px;
    top: 0px;
    z-index: 25;
    position: absolute;
  }
  body.page-proppoints #lp-pom-box-158 {
    display: block;
    background: white;
    -pie-background: white;
    border-style: solid;
    border-width: 1px;
    border-color: #c1cfd4;
    behavior: url(http://d9hhrg4mnvzow.cloudfront.net/PIE.htc);
    border-radius: 0px;
    left: 20px;
    top: 2138px;
    z-index: 16;
    width: 278px;
    height: 458px;
    position: absolute;
  }
  body.page-proppoints #lp-pom-text-159 {
    display: block;
    background: rgba(255, 255, 255, 0);
    -pie-background: rgba(255, 255, 255, 0);
    border-style: none;
    behavior: url(http://d9hhrg4mnvzow.cloudfront.net/PIE.htc);
    border-radius: 0px;
    left: 23px;
    top: 273px;
    z-index: 17;
    width: 226px;
    height: 132px;
    position: absolute;
  }
  body.page-proppoints #lp-pom-text-160 {
    display: block;
    background: rgba(255, 255, 255, 0);
    -pie-background: rgba(255, 255, 255, 0);
    border-style: none;
    behavior: url(http://d9hhrg4mnvzow.cloudfront.net/PIE.htc);
    border-radius: 0px;
    left: 23px;
    top: 234px;
    z-index: 18;
    width: 226px;
    height: 24px;
    position: absolute;
  }
  body.page-proppoints #lp-pom-text-161 {
    display: block;
    background: rgba(255, 81, 34, 0);
    -pie-background: rgba(255, 81, 34, 0);
    border-style: none;
    behavior: url(http://d9hhrg4mnvzow.cloudfront.net/PIE.htc);
    border-radius: 0px;
    left: 23px;
    top: 417px;
    z-index: 19;
    width: 226px;
    height: 22px;
    position: absolute;
  }
  body.page-proppoints #lp-pom-image-162 {
    display: block;
    background: rgba(255, 255, 255, 0);
    -pie-background: rgba(255, 255, 255, 0);
    left: 0px;
    top: 0px;
    z-index: 20;
    position: absolute;
  }
  body.page-proppoints #lp-pom-block-22 {
    display: block;
    background: #e6e7e8;
    -pie-background: #e6e7e8;
    border-style: solid none none none;
    border-width: 1px;
    border-color: #c1cfd4;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 0px;
    behavior: url(http://d9hhrg4mnvzow.cloudfront.net/PIE.htc);
    border-radius: 0px;
    width: 320px;
    height: 545px;
    position: relative;
  }
  body.page-proppoints #lp-code-138 {
    display: block;
    left: 4px;
    top: 1766px;
    z-index: 11;
    width: 312px;
    height: 915px;
    position: absolute;
  }
  body.page-proppoints #lp-code-139 {
    display: none;
    left: 107px;
    top: 2738px;
    z-index: 12;
    width: 209px;
    height: 47px;
    position: absolute;
  }
  body.page-proppoints #lp-pom-text-163 {
    display: block;
    background: rgba(255, 255, 255, 0);
    -pie-background: rgba(255, 255, 255, 0);
    border-style: none;
    behavior: url(http://d9hhrg4mnvzow.cloudfront.net/PIE.htc);
    border-radius: 0px;
    left: 0px;
    top: 1690px;
    z-index: 13;
    width: 320px;
    height: 29px;
    position: absolute;
  }
  body.page-proppoints #lp-pom-image-174 {
    display: block;
    background: rgba(255, 255, 255, 0);
    -pie-background: rgba(255, 255, 255, 0);
    left: 116px;
    top: 1743px;
    z-index: 31;
    position: absolute;
  }
  body.page-proppoints #lp-pom-block-23 {
    display: block;
    background: white;
    -pie-background: white;
    border-style: none;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 2px;
    behavior: url(http://d9hhrg4mnvzow.cloudfront.net/PIE.htc);
    border-radius: 0px;
    width: 100%;
    height: 2852px;
    position: relative;
  }
  body.page-proppoints #lp-pom-text-165 {
    display: block;
    background: rgba(255, 255, 255, 0);
    -pie-background: rgba(255, 255, 255, 0);
    border-style: none;
    behavior: url(http://d9hhrg4mnvzow.cloudfront.net/PIE.htc);
    border-radius: 0px;
    left: 0px;
    top: 2220px;
    z-index: 14;
    width: 320px;
    height: 29px;
    position: absolute;
  }
  body.page-proppoints #lp-code-168 {
    display: block;
    left: 8px;
    top: 2009px;
    z-index: 15;
    width: 304px;
    height: auto;
    position: absolute;
  }
  body.page-proppoints #lp-pom-block-24 {
    display: block;
    background: #0f314c;
    -pie-background: #0f314c;
    border-style: none;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 1px;
    behavior: url(http://d9hhrg4mnvzow.cloudfront.net/PIE.htc);
    border-radius: 0px;
    width: 100%;
    height: 95px;
    position: relative;
    z-index: 9;
  }
  body.page-proppoints #lp-pom-text-76 {
    display: block;
    background: rgba(255, 255, 255, 0);
    -pie-background: rgba(255, 255, 255, 0);
    border-style: none;
    behavior: url(http://d9hhrg4mnvzow.cloudfront.net/PIE.htc);
    border-radius: 0px;
    left: 0px;
    top: 5387px;
    z-index: 9;
    width: 325px;
    height: 22px;
    position: absolute;
  }
  body.page-proppoints body {
    color: #4a646f;
  }
  body.page-proppoints a {
    color: #0000ff;
    text-decoration: none;
  }
  body.page-proppoints #lp-pom-root .lp-positioned-content {
    top: 0px;
    width: 320px;
    margin-left: -160px;
  }
  body.page-proppoints #lp-pom-block-8 .lp-pom-block-content {
    margin-left: auto;
    margin-right: auto;
    width: 320px;
    height: 568px;
  }
  body.page-proppoints #lp-pom-block-21 .lp-pom-block-content {
    margin-left: auto;
    margin-right: auto;
    width: 320px;
    height: auto;
  }
  body.page-proppoints #lp-pom-block-22 .lp-pom-block-content {
    margin-left: auto;
    margin-right: auto;
    width: 320px;
    height: 1044px;
  }
  body.page-proppoints #lp-pom-block-23 .lp-pom-block-content {
    margin-left: auto;
    margin-right: auto;
    width: 320px;
    height: auto;
  }
  body.page-proppoints #lp-pom-block-24 .lp-pom-block-content {
    margin-left: auto;
    margin-right: auto;
    width: 320px;
    height: 95px;
  }
  body.page-proppoints #lp-pom-image-128 .lp-pom-image-container {
    border-style: none;
    behavior: url(http://d9hhrg4mnvzow.cloudfront.net/PIE.htc);
    border-radius: 0px;
    width: 141px;
    height: 51px;
  }
  body.page-proppoints #lp-pom-image-144 .lp-pom-image-container {
    border-style: none;
    behavior: url(http://d9hhrg4mnvzow.cloudfront.net/PIE.htc);
    border-radius: 0px;
    width: 278px;
    height: 208px;
  }
  body.page-proppoints #lp-pom-image-144 .lp-pom-image-container img {
    width: 278px;
    height: 208px;
  }
  body.page-proppoints #lp-pom-image-152 .lp-pom-image-container {
    border-style: none;
    behavior: url(http://d9hhrg4mnvzow.cloudfront.net/PIE.htc);
    border-radius: 0px;
    width: 278px;
    height: 208px;
  }
  body.page-proppoints #lp-pom-image-152 .lp-pom-image-container img {
    width: 278px;
    height: 208px;
  }
  body.page-proppoints #lp-pom-image-157 .lp-pom-image-container {
    border-style: none;
    behavior: url(http://d9hhrg4mnvzow.cloudfront.net/PIE.htc);
    border-radius: 0px;
    width: 278px;
    height: 208px;
  }
  body.page-proppoints #lp-pom-image-157 .lp-pom-image-container img {
    width: 278px;
    height: 208px;
  }
  body.page-proppoints #lp-pom-image-162 .lp-pom-image-container {
    border-style: none;
    behavior: url(http://d9hhrg4mnvzow.cloudfront.net/PIE.htc);
    border-radius: 0px;
    width: 278px;
    height: 208px;
  }
  body.page-proppoints #lp-pom-image-162 .lp-pom-image-container img {
    width: 278px;
    height: 208px;
  }
  body.page-proppoints #lp-pom-image-174 .lp-pom-image-container {
    border-style: none;
    behavior: url(http://d9hhrg4mnvzow.cloudfront.net/PIE.htc);
    border-radius: 0px;
    width: 201px;
    height: 23px;
  }
  body.page-proppoints #lp-pom-image-174 .lp-pom-image-container img {
    width: 201px;
    height: 23px;
  }
  body.page-proppoints #lp-pom-root-color-overlay {
    position: absolute;
    background: none;
    top: 0;
    width: 100%;
    height: auto;
    min-height: 100%;
  }
  body.page-proppoints #lp-pom-block-8-color-overlay {
    position: absolute;
    background: none;
    height: 568px;
    width: 100%;
  }
  body.page-proppoints #lp-pom-block-21-color-overlay {
    position: absolute;
    background: none;
    height: auto;
    width: 100%;
  }
  body.page-proppoints #lp-pom-block-22-color-overlay {
    position: absolute;
    background: none;
    height: 1044px;
    width: 320px;
  }
  body.page-proppoints #lp-pom-block-23-color-overlay {
    position: absolute;
    background: none;
    height: auto;
    width: 100%;
  }
  body.page-proppoints #lp-pom-block-24-color-overlay {
    position: absolute;
    background: none;
    height: 95px;
    width: 100%;
  }
  body.page-proppoints #lp-pom-box-25-color-overlay {
    position: absolute;
    background: none;
    behavior: url(http://d9hhrg4mnvzow.cloudfront.net/PIE.htc);
    height: 458px;
    width: 278px;
  }
  body.page-proppoints #lp-pom-box-148-color-overlay {
    position: absolute;
    background: none;
    behavior: url(http://d9hhrg4mnvzow.cloudfront.net/PIE.htc);
    height: 458px;
    width: 278px;
  }
  body.page-proppoints #lp-pom-box-153-color-overlay {
    position: absolute;
    background: none;
    behavior: url(http://d9hhrg4mnvzow.cloudfront.net/PIE.htc);
    height: 458px;
    width: 278px;
  }
  body.page-proppoints #lp-pom-box-158-color-overlay {
    position: absolute;
    background: none;
    behavior: url(http://d9hhrg4mnvzow.cloudfront.net/PIE.htc);
    height: 458px;
    width: 278px;
  }
}

body.page-proppoints .p-points {
  font-family: 'Montserrat';
}

body.page-proppoints .p-points.positive, body.page-proppoints .p-points.negative {
  position: relative;
  margin: 0;
  top: 30px;
  display: inline;
  background-color: #27AE60;
  color: #ffffff;
  padding-left: 85px;
  padding-right: 85px;
  padding-top: 15px;
  padding-bottom: 15px;
  border-top-left-radius: .5em;
}

body.page-proppoints .p-points.negative {
  background: #f44336;
  border-top-left-radius: 0;
  border-top-right-radius: .5em;
  left: -3px;
}

body.page-proppoints .faq-wrapper {
  border-radius: 10px;
  overflow: hidden;
  border: 1px solid;
  border-color: #cccccc;
}

body.page-proppoints .faq-wrapper .line {
  height: 60px;
}

body.page-proppoints .faq-wrapper .line #title-here img {
  width: 15px;
  padding: 25px;
}

body.page-proppoints .faq-wrapper p {
  padding: 5px 25px 25px;
  font-size: 14px;
  color: #000000;
  font-weight: 400;
  line-height: 20px;
}

body.page-proppoints .faq-wrapper #1-faq p {
  padding: 5px 25px;
  font-size: 14px;
  color: #000000;
  font-weight: 400;
  line-height: 20px;
}

body.page-proppoints .faq-wrapper #1-faq ul {
  list-style-type: circle;
  padding: 5px 25px 5px 40px;
  font-size: 14px;
  color: #000000;
}

body.page-proppoints.blueTable {
  background-color: #ffffff;
  width: 100%;
  text-align: left;
  border-collapse: collapse;
}

body.page-proppoints.blueTable td.td-1 {
  padding-left: 10px;
  padding-top: 10px;
  padding-bottom: 10px;
}

@media screen and (min-width: 800px) {
  body.page-proppoints.blueTable td.td-1 {
    padding-left: 51px;
    width: 55%;
  }
}

body.page-proppoints.blueTable td.td-2 {
  padding: 10px 40px;
  text-align: center;
}

body.page-proppoints table.blueTable td, body.page-proppoints table.blueTable th {
  border: none !important;
  padding: 3px 2px;
}

body.page-proppoints table.blueTable tbody td {
  font-size: 13px;
}

body.page-proppoints table.blueTable tr:nth-child(even) {
  background: #E6F1F4;
}

body.page-proppoints table.blueTable thead {
  background: #1C6EA4;
  background: -moz-linear-gradient(top, #5592bb 0%, #327cad 66%, #1C6EA4 100%);
  background: -webkit-linear-gradient(top, #5592bb 0%, #327cad 66%, #1C6EA4 100%);
  background: linear-gradient(to bottom, #5592bb 0%, #327cad 66%, #1C6EA4 100%);
  border-bottom: 2px solid #444444;
}

body.page-proppoints table.blueTable thead th {
  font-size: 15px;
  font-weight: bold;
  color: #FFFFFF;
  border-left: 2px solid #D0E4F5;
}

body.page-proppoints table.blueTable thead th:first-child {
  border-left: none;
}

body.page-proppoints table.blueTable tfoot {
  font-size: 14px;
  font-weight: bold;
  color: #FFFFFF;
  background: #D0E4F5;
  background: -moz-linear-gradient(top, #dcebf7 0%, #d4e6f6 66%, #D0E4F5 100%);
  background: -webkit-linear-gradient(top, #dcebf7 0%, #d4e6f6 66%, #D0E4F5 100%);
  background: linear-gradient(to bottom, #dcebf7 0%, #d4e6f6 66%, #D0E4F5 100%);
  border-top: 2px solid #444444;
}

body.page-proppoints table.blueTable tfoot td {
  font-size: 14px;
}

body.page-proppoints table.blueTable tfoot .links {
  text-align: right;
}

body.page-proppoints table.blueTable tfoot .links a {
  display: inline-block;
  background: #1C6EA4;
  color: #FFFFFF;
  padding: 2px 8px;
  border-radius: 5px;
}

body.page-proppoints table tr:last-child td:first-child {
  border-bottom-left-radius: 10px;
}

body.page-proppoints table tr:last-child td:last-child {
  border-bottom-right-radius: 10px;
}

body.page-proppoints table.blueTable {
  background-color: #ffffff;
  width: 100%;
  text-align: left;
  border-collapse: collapse;
}

body.page-proppoints table.blueTable td, body.page-proppoints table.blueTable th {
  border: 1px solid #AAAAAA;
  padding: 3px 2px;
}

body.page-proppoints table.blueTable tbody td {
  font-size: 13px;
}

body.page-proppoints table.blueTable tr:nth-child(even) {
  background: #E6F1F4;
}

body.page-proppoints table.blueTable thead {
  background: #1C6EA4;
  background: -moz-linear-gradient(top, #5592bb 0%, #327cad 66%, #1C6EA4 100%);
  background: -webkit-linear-gradient(top, #5592bb 0%, #327cad 66%, #1C6EA4 100%);
  background: linear-gradient(to bottom, #5592bb 0%, #327cad 66%, #1C6EA4 100%);
  border-bottom: 2px solid #444444;
}

body.page-proppoints table.blueTable thead th {
  font-size: 15px;
  font-weight: bold;
  color: #FFFFFF;
  border-left: 2px solid #D0E4F5;
}

body.page-proppoints table.blueTable thead th:first-child {
  border-left: none;
}

body.page-proppoints table.blueTable tfoot {
  font-size: 14px;
  font-weight: bold;
  color: #FFFFFF;
  background: #D0E4F5;
  background: -moz-linear-gradient(top, #dcebf7 0%, #d4e6f6 66%, #D0E4F5 100%);
  background: -webkit-linear-gradient(top, #dcebf7 0%, #d4e6f6 66%, #D0E4F5 100%);
  background: linear-gradient(to bottom, #dcebf7 0%, #d4e6f6 66%, #D0E4F5 100%);
  border-top: 2px solid #444444;
}

body.page-proppoints table.blueTable tfoot td {
  font-size: 14px;
}

body.page-proppoints table.blueTable tfoot .links {
  text-align: right;
}

body.page-proppoints table.blueTable tfoot .links a {
  display: inline-block;
  background: #1C6EA4;
  color: #FFFFFF;
  padding: 2px 8px;
  border-radius: 5px;
}

body.page-proppoints table tr:last-child td:first-child {
  border-bottom-left-radius: 10px;
}

body.page-proppoints table tr:last-child td:last-child {
  border-bottom-right-radius: 10px;
}

body.page-proppoints table.blueTable tr td {
  padding: 10px;
}

body.page-proppoints table.blueTable tr td.td-1 {
  width: 55%;
}

@media screen and (max-width: 425px) {
  body.page-proppoints table.blueTable tr td.td-1 {
    width: 40%;
  }
}

body.page-proppoints table.blueTable tr td.td-2 {
  width: 22.5%;
  text-align: center;
}

@media screen and (max-width: 425px) {
  body.page-proppoints table.blueTable tr td.td-2 {
    width: 30%;
  }
}

body.page-proppoints ul li {
  list-style-type: none !important;
  position: relative;
  margin-bottom: 15px;
}

body.page-proppoints ul li:before {
  content: '\f0a9';
  font-family: FontAwesome;
  position: absolute;
  top: 2px;
  left: -18px;
  color: #005c98;
  font-size: inherit;
  line-height: 1;
}

body.page-proppoints ul li.admin-menu-toolbar-category.expandable:before, body.page-proppoints ul li.admin-menu-action.admin-menu-users:before {
  content: none;
}

body.page-proppoints #title-faq-1:hover, body.page-proppoints #arrow-1:hover,
body.page-proppoints #title-faq-2:hover, body.page-proppoints #arrow-2:hover,
body.page-proppoints #title-faq-3:hover, body.page-proppoints #arrow-3:hover,
body.page-proppoints #title-faq-4:hover, body.page-proppoints #arrow-4:hover,
body.page-proppoints #title-faq-5:hover, body.page-proppoints #arrow-5:hover,
body.page-proppoints #title-faq-6:hover, body.page-proppoints #arrow-6:hover,
body.page-proppoints #title-faq-7:hover, body.page-proppoints #arrow-7:hover,
body.page-proppoints #title-faq-8:hover, body.page-proppoints #arrow-8:hover,
body.page-proppoints #title-faq-9:hover, body.page-proppoints #arrow-9:hover,
body.page-proppoints #title-faq-10:hover, body.page-proppoints #arrow-10:hover {
  cursor: pointer;
}

body.page-transaction .i-form {
  padding: 13px 0;
  background-color: rgba(255, 255, 255, 0.8);
  width: 100%;
  max-height: 92px;
}

body.page-transaction .i-form .group {
  position: relative;
}

body.page-transaction .i-form .group i.fa-search {
  float: left;
  padding-top: 10px;
}

body.page-transaction .i-form .group i.fa-search:before {
  font-size: 15px;
  padding: 10px;
  color: #333;
}

body.page-transaction .i-form .group select {
  border: 0;
  background: transparent;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  font-size: 14px;
  color: #333;
  outline: none;
  text-transform: none;
}

body.page-transaction .i-form .group .wrap-search {
  height: 66px;
  display: inline-block;
  vertical-align: top;
  position: relative;
  margin: 0 10px 0 10px;
  background-color: #fff;
  cursor: pointer;
  padding: 10px 10px;
  box-shadow: 0 4px 10px 0 rgba(0, 0, 0, 0.07);
}

body.page-transaction .i-form .group .wrap-search .single {
  vertical-align: middle;
  display: inline-block;
  width: calc(100% - 60px);
}

body.page-transaction .i-form .group .wrap-search .single .dropdown {
  width: 100%;
}

body.page-transaction .i-form .group .wrap-search .single #edit-projects {
  margin-top: 12px;
  font-size: 14px;
  color: #333;
  border: none;
  text-overflow: ellipsis;
  cursor: pointer;
  border-radius: 4px;
  background-color: #fff;
  width: 100%;
  line-height: 1.25;
  padding: 0;
  margin: 0;
}

body.page-transaction ::-webkit-input-placeholder {
  color: #666 !important;
}

body.page-transaction ::-moz-placeholder {
  color: #666 !important;
}

body.page-transaction ::-ms-placeholder {
  color: #666 !important;
}

body.page-transaction ::placeholder {
  color: #666 !important;
}

body.page-transaction .wrap {
  display: inline-block;
  vertical-align: top;
  position: relative;
  margin: 0 5px 0 0;
  background-color: #fff;
  cursor: pointer;
  padding: 5px 5px;
  box-shadow: 0 4px 10px 0 rgba(0, 0, 0, 0.07);
  outline: none;
}

body.page-transaction .transaction-input .ficon,
body.page-transaction .transaction-input button,
body.page-transaction .transaction-input select {
  text-transform: none;
}

body.page-transaction .transaction-input #hero-banner-header1 {
  margin: 5px 0;
  color: #fff;
  text-align: center;
}

body.page-transaction .transaction-input #hero-banner-header2,
body.page-transaction .transaction-input #hero-banner-header1 {
  position: relative;
  font-family: montserrat,sans-serif;
}

body.page-transaction .transaction-input.h1,
body.page-transaction .transaction-input h1 {
  font-weight: 200;
  line-height: 1.2;
  letter-spacing: .03em;
}

body.page-transaction .transaction-input #hero-banner-header3 {
  margin-bottom: 40px;
}

body.page-transaction .transaction-input h2 {
  font-size: 19px;
  text-align: center;
  font-weight: 500;
  margin: 10px 0;
  color: #fff;
  font-weight: 100;
}

body.page-transaction .wrap-state {
  width: 164px;
  margin-left: 10px;
  height: 66px;
}

body.page-transaction .transaction-scoreboard .search-link .item a {
  color: #fff;
  font-weight: 400;
}

body.page-transaction .main .content-area {
  box-shadow: inset 0 -100px 100px -100px #000;
}

body.page-transaction .main .content-area .page-header {
  display: none;
}

body.page-transaction .main .content-area .breadcrumb {
  color: #252525;
}

body.page-transaction .main .content-area .breadcrumb li a,
body.page-transaction .main .content-area .breadcrumb li:first-child > a:before {
  color: inherit;
}

body.page-transaction h1,
body.page-transaction h2,
body.page-transaction h3,
body.page-transaction h4,
body.page-transaction h5,
body.page-transaction h6,
body.page-transaction .h1,
body.page-transaction .h2,
body.page-transaction .h3,
body.page-transaction .h4,
body.page-transaction .h5,
body.page-transaction .h6 {
  font-family: poppins,sans-serif;
  line-height: 1.4;
}

@media screen and (max-width: 768px) {
  body.page-transaction .transaction-input {
    width: 100%;
  }
  body.page-transaction .input-group-addon {
    float: right;
    display: none;
  }
}

@media screen and (max-width: 320px) {
  body.page-transaction .i-form .group .wrap-search {
    padding: 10px 5px;
  }
  body.page-transaction .i-form .group .wrap-search .single #edit-projects {
    font-size: 12px;
  }
}

body .subs-news {
  display: none;
}

body.page-news, body.node-type-tep-blogs, body.page-guides, body.page-edgeproptv, body.page-pullout, body.page-events, body.page-event, body.page-tags, body.page-node-29, body.page-node-34, body.page-content {
  color: #212529;
  padding-top: 0;
  /* HTML5 display-role reset for older browsers */
  /* ignore ios input style */
  /* Common CSS */
  /* 2. Common Styles */
  /* typography */
  /* typography */
  /*
        * For IE 6/7 only
        * Include this rule to trigger hasLayout and contain floats.
        */
  /***** 3. Parent Structure *****/
  /*TOGGLE MENU*/
  /***** 4. Header *****/
  /* WordPress CSS */
  /* 5. Body */
  /* 5.1 Page */
  /*5.2 Post*/
  /*Social network share*/
  /**POST SINGLE**/
  /*5.3 Category Page*/
  /*5.4 Page Builder*/
  /*COLUM*/
  /*CONTAINER ELEMENT*/
  /*Page builder widgets*/
  /*Color*/
  /* EMBED CODE */
  /* youtube */
  /* vimeo */
  /*5.5 Widget*/
  /*** Default widget ***/
  /* table */
  /*** ExpMag widget ***/
  /*AccessPress Social Counter*/
  /*Wope categories*/
  /* MailChimp for WP */
  /* 5.6 Shortcodes */
  /* Tabs */
  /* Accordion */
  /* Gallery */
  /* Info Boxes */
  /* Dropcap */
  /* Custom list */
  /* Social Icon */
  /* Embed Code */
  /* youtube */
  /* vimeo */
  /* Button */
  /* author bio */
  /* post relative */
  /* post search */
  /* comment section */
  /* comment form */
  /* 6. Footer */
  /* .footer-social a{
            display: inline-block;
            padding-right: 20px;
            font-size: 14px;
            line-height: 18px;
            color: #999;
        }
        .footer-social a:hover{
            color: #fff;
        } */
  /* .footer-bottom{
            background-color: #000;
        } */
  /* .footer-bottom ul{
            float: right;
        }
        .footer-bottom ul li{
            display: inline-block;
        }
        .footer-bottom ul li a{
            font-family: 'Roboto', Arial;
            font-size: 12px;
            line-height: 24px;
            font-weight: normal;
            color: #999;
            text-transform: uppercase;
            padding: 14px 0 14px 16px;
        } */
  /* back to button */
  /* Customize */
  /*event listing  */
  /* Responsive */
  /* Social Css */
  /*accesspress social counter front end css*/
  /********************************************Theme 2 css*******************************************************/
  /********************************************Theme 3 css*******************************************************/
  /*.apsc-theme-3 .apsc-each-profile a:hover .apsc-count{
            height: 32px;
            min-width: 0;
            opacity: 0;
            padding: 0;
            width: 0;
        }*/
  /********************************************Theme 4 css*******************************************************/
  /********************************************Theme 5 css*******************************************************/
  /************************************************responsive*************************************************************/
}

body.page-news .news-end-quickaccess ul.content-bullet li, body.node-type-tep-blogs .news-end-quickaccess ul.content-bullet li, body.page-guides .news-end-quickaccess ul.content-bullet li, body.page-edgeproptv .news-end-quickaccess ul.content-bullet li, body.page-pullout .news-end-quickaccess ul.content-bullet li, body.page-events .news-end-quickaccess ul.content-bullet li, body.page-event .news-end-quickaccess ul.content-bullet li, body.page-tags .news-end-quickaccess ul.content-bullet li, body.page-node-29 .news-end-quickaccess ul.content-bullet li, body.page-node-34 .news-end-quickaccess ul.content-bullet li, body.page-content .news-end-quickaccess ul.content-bullet li {
  font-size: inherit;
  margin-bottom: 15px;
}

body.page-news ul.content-bullet-list, body.node-type-tep-blogs ul.content-bullet-list, body.page-guides ul.content-bullet-list, body.page-edgeproptv ul.content-bullet-list, body.page-pullout ul.content-bullet-list, body.page-events ul.content-bullet-list, body.page-event ul.content-bullet-list, body.page-tags ul.content-bullet-list, body.page-node-29 ul.content-bullet-list, body.page-node-34 ul.content-bullet-list, body.page-content ul.content-bullet-list {
  font-family: "Rubik", Helvetica, Arial, sans-serif !important;
  line-height: 1.4;
  margin: 0 14px;
  padding-left: 30px !important;
  padding-right: 15px;
}

@media (max-width: 767px) {
  body.page-news ul.content-bullet-list, body.node-type-tep-blogs ul.content-bullet-list, body.page-guides ul.content-bullet-list, body.page-edgeproptv ul.content-bullet-list, body.page-pullout ul.content-bullet-list, body.page-events ul.content-bullet-list, body.page-event ul.content-bullet-list, body.page-tags ul.content-bullet-list, body.page-node-29 ul.content-bullet-list, body.page-node-34 ul.content-bullet-list, body.page-content ul.content-bullet-list {
    margin: 0;
  }
}

@media (min-width: 1024px) {
  body.page-news ul.content-bullet-list, body.node-type-tep-blogs ul.content-bullet-list, body.page-guides ul.content-bullet-list, body.page-edgeproptv ul.content-bullet-list, body.page-pullout ul.content-bullet-list, body.page-events ul.content-bullet-list, body.page-event ul.content-bullet-list, body.page-tags ul.content-bullet-list, body.page-node-29 ul.content-bullet-list, body.page-node-34 ul.content-bullet-list, body.page-content ul.content-bullet-list {
    padding-left: 8px !important;
  }
}

body.page-news ul.content-bullet-list li, body.node-type-tep-blogs ul.content-bullet-list li, body.page-guides ul.content-bullet-list li, body.page-edgeproptv ul.content-bullet-list li, body.page-pullout ul.content-bullet-list li, body.page-events ul.content-bullet-list li, body.page-event ul.content-bullet-list li, body.page-tags ul.content-bullet-list li, body.page-node-29 ul.content-bullet-list li, body.page-node-34 ul.content-bullet-list li, body.page-content ul.content-bullet-list li {
  font-weight: 500;
  list-style-type: none;
  margin: 0;
  padding-bottom: 15px;
  padding-left: 0;
  padding-top: 15px;
  position: relative;
  font-size: 18px;
  color: #000;
}

@media (max-width: 767px) {
  body.page-news ul.content-bullet-list li, body.node-type-tep-blogs ul.content-bullet-list li, body.page-guides ul.content-bullet-list li, body.page-edgeproptv ul.content-bullet-list li, body.page-pullout ul.content-bullet-list li, body.page-events ul.content-bullet-list li, body.page-event ul.content-bullet-list li, body.page-tags ul.content-bullet-list li, body.page-node-29 ul.content-bullet-list li, body.page-node-34 ul.content-bullet-list li, body.page-content ul.content-bullet-list li {
    padding: 15px 10px 15px 5px;
  }
}

body.page-news ul.content-bullet-list li:before, body.node-type-tep-blogs ul.content-bullet-list li:before, body.page-guides ul.content-bullet-list li:before, body.page-edgeproptv ul.content-bullet-list li:before, body.page-pullout ul.content-bullet-list li:before, body.page-events ul.content-bullet-list li:before, body.page-event ul.content-bullet-list li:before, body.page-tags ul.content-bullet-list li:before, body.page-node-29 ul.content-bullet-list li:before, body.page-node-34 ul.content-bullet-list li:before, body.page-content ul.content-bullet-list li:before {
  background: #0e6bc5;
  content: "";
  height: 7px;
  left: -21px;
  position: absolute;
  top: 31px;
  width: 7px;
}

@media (max-width: 767px) {
  body.page-news ul.content-bullet-list li:before, body.node-type-tep-blogs ul.content-bullet-list li:before, body.page-guides ul.content-bullet-list li:before, body.page-edgeproptv ul.content-bullet-list li:before, body.page-pullout ul.content-bullet-list li:before, body.page-events ul.content-bullet-list li:before, body.page-event ul.content-bullet-list li:before, body.page-tags ul.content-bullet-list li:before, body.page-node-29 ul.content-bullet-list li:before, body.page-node-34 ul.content-bullet-list li:before, body.page-content ul.content-bullet-list li:before {
    left: -13px;
  }
}

body.page-news .container-fluid.in-content-container, body.node-type-tep-blogs .container-fluid.in-content-container, body.page-guides .container-fluid.in-content-container, body.page-edgeproptv .container-fluid.in-content-container, body.page-pullout .container-fluid.in-content-container, body.page-events .container-fluid.in-content-container, body.page-event .container-fluid.in-content-container, body.page-tags .container-fluid.in-content-container, body.page-node-29 .container-fluid.in-content-container, body.page-node-34 .container-fluid.in-content-container, body.page-content .container-fluid.in-content-container {
  padding: 0;
}

body.page-news .container-fluid.in-content-container .ads-in-content, body.node-type-tep-blogs .container-fluid.in-content-container .ads-in-content, body.page-guides .container-fluid.in-content-container .ads-in-content, body.page-edgeproptv .container-fluid.in-content-container .ads-in-content, body.page-pullout .container-fluid.in-content-container .ads-in-content, body.page-events .container-fluid.in-content-container .ads-in-content, body.page-event .container-fluid.in-content-container .ads-in-content, body.page-tags .container-fluid.in-content-container .ads-in-content, body.page-node-29 .container-fluid.in-content-container .ads-in-content, body.page-node-34 .container-fluid.in-content-container .ads-in-content, body.page-content .container-fluid.in-content-container .ads-in-content {
  text-align: center;
}

body.page-news .sidebar-component-new.overlay-new, body.node-type-tep-blogs .sidebar-component-new.overlay-new, body.page-guides .sidebar-component-new.overlay-new, body.page-edgeproptv .sidebar-component-new.overlay-new, body.page-pullout .sidebar-component-new.overlay-new, body.page-events .sidebar-component-new.overlay-new, body.page-event .sidebar-component-new.overlay-new, body.page-tags .sidebar-component-new.overlay-new, body.page-node-29 .sidebar-component-new.overlay-new, body.page-node-34 .sidebar-component-new.overlay-new, body.page-content .sidebar-component-new.overlay-new {
  font-family: "Poppins", sans-serif !important;
}

body.page-news .sidebar-component-new.overlay-new div, body.page-news .sidebar-component-new.overlay-new h6, body.page-news .sidebar-component-new.overlay-new a, body.node-type-tep-blogs .sidebar-component-new.overlay-new div, body.node-type-tep-blogs .sidebar-component-new.overlay-new h6, body.node-type-tep-blogs .sidebar-component-new.overlay-new a, body.page-guides .sidebar-component-new.overlay-new div, body.page-guides .sidebar-component-new.overlay-new h6, body.page-guides .sidebar-component-new.overlay-new a, body.page-edgeproptv .sidebar-component-new.overlay-new div, body.page-edgeproptv .sidebar-component-new.overlay-new h6, body.page-edgeproptv .sidebar-component-new.overlay-new a, body.page-pullout .sidebar-component-new.overlay-new div, body.page-pullout .sidebar-component-new.overlay-new h6, body.page-pullout .sidebar-component-new.overlay-new a, body.page-events .sidebar-component-new.overlay-new div, body.page-events .sidebar-component-new.overlay-new h6, body.page-events .sidebar-component-new.overlay-new a, body.page-event .sidebar-component-new.overlay-new div, body.page-event .sidebar-component-new.overlay-new h6, body.page-event .sidebar-component-new.overlay-new a, body.page-tags .sidebar-component-new.overlay-new div, body.page-tags .sidebar-component-new.overlay-new h6, body.page-tags .sidebar-component-new.overlay-new a, body.page-node-29 .sidebar-component-new.overlay-new div, body.page-node-29 .sidebar-component-new.overlay-new h6, body.page-node-29 .sidebar-component-new.overlay-new a, body.page-node-34 .sidebar-component-new.overlay-new div, body.page-node-34 .sidebar-component-new.overlay-new h6, body.page-node-34 .sidebar-component-new.overlay-new a, body.page-content .sidebar-component-new.overlay-new div, body.page-content .sidebar-component-new.overlay-new h6, body.page-content .sidebar-component-new.overlay-new a {
  font-family: "Poppins", sans-serif !important;
}

body.page-news h1, body.page-news h2, body.page-news h3, body.page-news h5, body.page-news h6, body.node-type-tep-blogs h1, body.node-type-tep-blogs h2, body.node-type-tep-blogs h3, body.node-type-tep-blogs h5, body.node-type-tep-blogs h6, body.page-guides h1, body.page-guides h2, body.page-guides h3, body.page-guides h5, body.page-guides h6, body.page-edgeproptv h1, body.page-edgeproptv h2, body.page-edgeproptv h3, body.page-edgeproptv h5, body.page-edgeproptv h6, body.page-pullout h1, body.page-pullout h2, body.page-pullout h3, body.page-pullout h5, body.page-pullout h6, body.page-events h1, body.page-events h2, body.page-events h3, body.page-events h5, body.page-events h6, body.page-event h1, body.page-event h2, body.page-event h3, body.page-event h5, body.page-event h6, body.page-tags h1, body.page-tags h2, body.page-tags h3, body.page-tags h5, body.page-tags h6, body.page-node-29 h1, body.page-node-29 h2, body.page-node-29 h3, body.page-node-29 h5, body.page-node-29 h6, body.page-node-34 h1, body.page-node-34 h2, body.page-node-34 h3, body.page-node-34 h5, body.page-node-34 h6, body.page-content h1, body.page-content h2, body.page-content h3, body.page-content h5, body.page-content h6 {
  font-weight: 500 !important;
  font-family: "Poppins", sans-serif !important;
}

body.page-news article, body.page-news aside, body.page-news details, body.page-news figcaption, body.page-news figure, body.page-news header,
body.page-news hgroup, body.page-news menu, body.page-news nav, body.page-news section, body.node-type-tep-blogs article, body.node-type-tep-blogs aside, body.node-type-tep-blogs details, body.node-type-tep-blogs figcaption, body.node-type-tep-blogs figure, body.node-type-tep-blogs header,
body.node-type-tep-blogs hgroup, body.node-type-tep-blogs menu, body.node-type-tep-blogs nav, body.node-type-tep-blogs section, body.page-guides article, body.page-guides aside, body.page-guides details, body.page-guides figcaption, body.page-guides figure, body.page-guides header,
body.page-guides hgroup, body.page-guides menu, body.page-guides nav, body.page-guides section, body.page-edgeproptv article, body.page-edgeproptv aside, body.page-edgeproptv details, body.page-edgeproptv figcaption, body.page-edgeproptv figure, body.page-edgeproptv header,
body.page-edgeproptv hgroup, body.page-edgeproptv menu, body.page-edgeproptv nav, body.page-edgeproptv section, body.page-pullout article, body.page-pullout aside, body.page-pullout details, body.page-pullout figcaption, body.page-pullout figure, body.page-pullout header,
body.page-pullout hgroup, body.page-pullout menu, body.page-pullout nav, body.page-pullout section, body.page-events article, body.page-events aside, body.page-events details, body.page-events figcaption, body.page-events figure, body.page-events header,
body.page-events hgroup, body.page-events menu, body.page-events nav, body.page-events section, body.page-event article, body.page-event aside, body.page-event details, body.page-event figcaption, body.page-event figure, body.page-event header,
body.page-event hgroup, body.page-event menu, body.page-event nav, body.page-event section, body.page-tags article, body.page-tags aside, body.page-tags details, body.page-tags figcaption, body.page-tags figure, body.page-tags header,
body.page-tags hgroup, body.page-tags menu, body.page-tags nav, body.page-tags section, body.page-node-29 article, body.page-node-29 aside, body.page-node-29 details, body.page-node-29 figcaption, body.page-node-29 figure, body.page-node-29 header,
body.page-node-29 hgroup, body.page-node-29 menu, body.page-node-29 nav, body.page-node-29 section, body.page-node-34 article, body.page-node-34 aside, body.page-node-34 details, body.page-node-34 figcaption, body.page-node-34 figure, body.page-node-34 header,
body.page-node-34 hgroup, body.page-node-34 menu, body.page-node-34 nav, body.page-node-34 section, body.page-content article, body.page-content aside, body.page-content details, body.page-content figcaption, body.page-content figure, body.page-content header,
body.page-content hgroup, body.page-content menu, body.page-content nav, body.page-content section {
  display: block;
}

body.page-news input,
body.page-news textarea, body.node-type-tep-blogs input,
body.node-type-tep-blogs textarea, body.page-guides input,
body.page-guides textarea, body.page-edgeproptv input,
body.page-edgeproptv textarea, body.page-pullout input,
body.page-pullout textarea, body.page-events input,
body.page-events textarea, body.page-event input,
body.page-event textarea, body.page-tags input,
body.page-tags textarea, body.page-node-29 input,
body.page-node-29 textarea, body.page-node-34 input,
body.page-node-34 textarea, body.page-content input,
body.page-content textarea {
  -webkit-border-radius: 0;
  border-radius: 0;
}

body.page-news ol,
body.page-news ul, body.node-type-tep-blogs ol,
body.node-type-tep-blogs ul, body.page-guides ol,
body.page-guides ul, body.page-edgeproptv ol,
body.page-edgeproptv ul, body.page-pullout ol,
body.page-pullout ul, body.page-events ol,
body.page-events ul, body.page-event ol,
body.page-event ul, body.page-tags ol,
body.page-tags ul, body.page-node-29 ol,
body.page-node-29 ul, body.page-node-34 ol,
body.page-node-34 ul, body.page-content ol,
body.page-content ul {
  list-style: none;
  padding: 0;
}

body.page-news blockquote,
body.page-news q, body.node-type-tep-blogs blockquote,
body.node-type-tep-blogs q, body.page-guides blockquote,
body.page-guides q, body.page-edgeproptv blockquote,
body.page-edgeproptv q, body.page-pullout blockquote,
body.page-pullout q, body.page-events blockquote,
body.page-events q, body.page-event blockquote,
body.page-event q, body.page-tags blockquote,
body.page-tags q, body.page-node-29 blockquote,
body.page-node-29 q, body.page-node-34 blockquote,
body.page-node-34 q, body.page-content blockquote,
body.page-content q {
  quotes: none;
}

body.page-news blockquote:before, body.page-news blockquote:after, body.page-news q:before, body.page-news q:after, body.node-type-tep-blogs blockquote:before, body.node-type-tep-blogs blockquote:after, body.node-type-tep-blogs q:before, body.node-type-tep-blogs q:after, body.page-guides blockquote:before, body.page-guides blockquote:after, body.page-guides q:before, body.page-guides q:after, body.page-edgeproptv blockquote:before, body.page-edgeproptv blockquote:after, body.page-edgeproptv q:before, body.page-edgeproptv q:after, body.page-pullout blockquote:before, body.page-pullout blockquote:after, body.page-pullout q:before, body.page-pullout q:after, body.page-events blockquote:before, body.page-events blockquote:after, body.page-events q:before, body.page-events q:after, body.page-event blockquote:before, body.page-event blockquote:after, body.page-event q:before, body.page-event q:after, body.page-tags blockquote:before, body.page-tags blockquote:after, body.page-tags q:before, body.page-tags q:after, body.page-node-29 blockquote:before, body.page-node-29 blockquote:after, body.page-node-29 q:before, body.page-node-29 q:after, body.page-node-34 blockquote:before, body.page-node-34 blockquote:after, body.page-node-34 q:before, body.page-node-34 q:after, body.page-content blockquote:before, body.page-content blockquote:after, body.page-content q:before, body.page-content q:after {
  content: '';
  content: none;
}

body.page-news table, body.node-type-tep-blogs table, body.page-guides table, body.page-edgeproptv table, body.page-pullout table, body.page-events table, body.page-event table, body.page-tags table, body.page-node-29 table, body.page-node-34 table, body.page-content table {
  border-collapse: collapse;
  border-spacing: 0;
}

body.page-news b,
body.page-news strong, body.node-type-tep-blogs b,
body.node-type-tep-blogs strong, body.page-guides b,
body.page-guides strong, body.page-edgeproptv b,
body.page-edgeproptv strong, body.page-pullout b,
body.page-pullout strong, body.page-events b,
body.page-events strong, body.page-event b,
body.page-event strong, body.page-tags b,
body.page-tags strong, body.page-node-29 b,
body.page-node-29 strong, body.page-node-34 b,
body.page-node-34 strong, body.page-content b,
body.page-content strong {
  font-weight: bold;
}

body.page-news em, body.node-type-tep-blogs em, body.page-guides em, body.page-edgeproptv em, body.page-pullout em, body.page-events em, body.page-event em, body.page-tags em, body.page-node-29 em, body.page-node-34 em, body.page-content em {
  font-style: italic;
}

body.page-news a, body.node-type-tep-blogs a, body.page-guides a, body.page-edgeproptv a, body.page-pullout a, body.page-events a, body.page-event a, body.page-tags a, body.page-node-29 a, body.page-node-34 a, body.page-content a {
  text-decoration: none;
}

body.page-news *:focus, body.node-type-tep-blogs *:focus, body.page-guides *:focus, body.page-edgeproptv *:focus, body.page-pullout *:focus, body.page-events *:focus, body.page-event *:focus, body.page-tags *:focus, body.page-node-29 *:focus, body.page-node-34 *:focus, body.page-content *:focus {
  outline: none;
}

body.page-news .cleared, body.node-type-tep-blogs .cleared, body.page-guides .cleared, body.page-edgeproptv .cleared, body.page-pullout .cleared, body.page-events .cleared, body.page-event .cleared, body.page-tags .cleared, body.page-node-29 .cleared, body.page-node-34 .cleared, body.page-content .cleared {
  clear: both;
}

body.page-news .center, body.node-type-tep-blogs .center, body.page-guides .center, body.page-edgeproptv .center, body.page-pullout .center, body.page-events .center, body.page-event .center, body.page-tags .center, body.page-node-29 .center, body.page-node-34 .center, body.page-content .center {
  text-align: center;
}

body.page-news .hidden, body.node-type-tep-blogs .hidden, body.page-guides .hidden, body.page-edgeproptv .hidden, body.page-pullout .hidden, body.page-events .hidden, body.page-event .hidden, body.page-tags .hidden, body.page-node-29 .hidden, body.page-node-34 .hidden, body.page-content .hidden {
  display: none;
}

body.page-news .content h1, body.page-news .content h2, body.page-news .content h3,
body.page-news .content h4, body.page-news .content h5, body.page-news .content h6, body.node-type-tep-blogs .content h1, body.node-type-tep-blogs .content h2, body.node-type-tep-blogs .content h3,
body.node-type-tep-blogs .content h4, body.node-type-tep-blogs .content h5, body.node-type-tep-blogs .content h6, body.page-guides .content h1, body.page-guides .content h2, body.page-guides .content h3,
body.page-guides .content h4, body.page-guides .content h5, body.page-guides .content h6, body.page-edgeproptv .content h1, body.page-edgeproptv .content h2, body.page-edgeproptv .content h3,
body.page-edgeproptv .content h4, body.page-edgeproptv .content h5, body.page-edgeproptv .content h6, body.page-pullout .content h1, body.page-pullout .content h2, body.page-pullout .content h3,
body.page-pullout .content h4, body.page-pullout .content h5, body.page-pullout .content h6, body.page-events .content h1, body.page-events .content h2, body.page-events .content h3,
body.page-events .content h4, body.page-events .content h5, body.page-events .content h6, body.page-event .content h1, body.page-event .content h2, body.page-event .content h3,
body.page-event .content h4, body.page-event .content h5, body.page-event .content h6, body.page-tags .content h1, body.page-tags .content h2, body.page-tags .content h3,
body.page-tags .content h4, body.page-tags .content h5, body.page-tags .content h6, body.page-node-29 .content h1, body.page-node-29 .content h2, body.page-node-29 .content h3,
body.page-node-29 .content h4, body.page-node-29 .content h5, body.page-node-29 .content h6, body.page-node-34 .content h1, body.page-node-34 .content h2, body.page-node-34 .content h3,
body.page-node-34 .content h4, body.page-node-34 .content h5, body.page-node-34 .content h6, body.page-content .content h1, body.page-content .content h2, body.page-content .content h3,
body.page-content .content h4, body.page-content .content h5, body.page-content .content h6 {
  color: #1a1a1a;
}

body.page-news .content, body.node-type-tep-blogs .content, body.page-guides .content, body.page-edgeproptv .content, body.page-pullout .content, body.page-events .content, body.page-event .content, body.page-tags .content, body.page-node-29 .content, body.page-node-34 .content, body.page-content .content {
  color: #666666;
}

body.page-news h1, body.node-type-tep-blogs h1, body.page-guides h1, body.page-edgeproptv h1, body.page-pullout h1, body.page-events h1, body.page-event h1, body.page-tags h1, body.page-node-29 h1, body.page-node-34 h1, body.page-content h1 {
  font-size: 36px;
}

body.page-news h2, body.node-type-tep-blogs h2, body.page-guides h2, body.page-edgeproptv h2, body.page-pullout h2, body.page-events h2, body.page-event h2, body.page-tags h2, body.page-node-29 h2, body.page-node-34 h2, body.page-content h2 {
  font-size: 28px;
}

body.page-news h3, body.node-type-tep-blogs h3, body.page-guides h3, body.page-edgeproptv h3, body.page-pullout h3, body.page-events h3, body.page-event h3, body.page-tags h3, body.page-node-29 h3, body.page-node-34 h3, body.page-content h3 {
  font-size: 24px;
}

body.page-news h4, body.node-type-tep-blogs h4, body.page-guides h4, body.page-edgeproptv h4, body.page-pullout h4, body.page-events h4, body.page-event h4, body.page-tags h4, body.page-node-29 h4, body.page-node-34 h4, body.page-content h4 {
  font-size: 22px;
}

body.page-news h5, body.node-type-tep-blogs h5, body.page-guides h5, body.page-edgeproptv h5, body.page-pullout h5, body.page-events h5, body.page-event h5, body.page-tags h5, body.page-node-29 h5, body.page-node-34 h5, body.page-content h5 {
  font-size: 20px;
}

body.page-news h6, body.node-type-tep-blogs h6, body.page-guides h6, body.page-edgeproptv h6, body.page-pullout h6, body.page-events h6, body.page-event h6, body.page-tags h6, body.page-node-29 h6, body.page-node-34 h6, body.page-content h6 {
  font-size: 18px;
}

body.page-news blockquote, body.node-type-tep-blogs blockquote, body.page-guides blockquote, body.page-edgeproptv blockquote, body.page-pullout blockquote, body.page-events blockquote, body.page-event blockquote, body.page-tags blockquote, body.page-node-29 blockquote, body.page-node-34 blockquote, body.page-content blockquote {
  font-size: 18px;
  line-height: 24px;
  font-weight: bold;
}

body.page-news blockquote span, body.node-type-tep-blogs blockquote span, body.page-guides blockquote span, body.page-edgeproptv blockquote span, body.page-pullout blockquote span, body.page-events blockquote span, body.page-event blockquote span, body.page-tags blockquote span, body.page-node-29 blockquote span, body.page-node-34 blockquote span, body.page-content blockquote span {
  font-size: 150px;
  line-height: 48px;
  font-weight: 400;
  font-style: normal;
}

body.page-news .slider-position.stick-top, body.node-type-tep-blogs .slider-position.stick-top, body.page-guides .slider-position.stick-top, body.page-edgeproptv .slider-position.stick-top, body.page-pullout .slider-position.stick-top, body.page-events .slider-position.stick-top, body.page-event .slider-position.stick-top, body.page-tags .slider-position.stick-top, body.page-node-29 .slider-position.stick-top, body.page-node-34 .slider-position.stick-top, body.page-content .slider-position.stick-top {
  position: sticky;
  position: -webkit-sticky;
  top: 5.2rem;
  z-index: 2002;
}

body.page-news .slider-position.stick-top.top-zero, body.node-type-tep-blogs .slider-position.stick-top.top-zero, body.page-guides .slider-position.stick-top.top-zero, body.page-edgeproptv .slider-position.stick-top.top-zero, body.page-pullout .slider-position.stick-top.top-zero, body.page-events .slider-position.stick-top.top-zero, body.page-event .slider-position.stick-top.top-zero, body.page-tags .slider-position.stick-top.top-zero, body.page-node-29 .slider-position.stick-top.top-zero, body.page-node-34 .slider-position.stick-top.top-zero, body.page-content .slider-position.stick-top.top-zero {
  top: -1px;
  padding-top: 15px;
  background: white;
}

body.page-news .slider-position.stick-top .container-fluid, body.node-type-tep-blogs .slider-position.stick-top .container-fluid, body.page-guides .slider-position.stick-top .container-fluid, body.page-edgeproptv .slider-position.stick-top .container-fluid, body.page-pullout .slider-position.stick-top .container-fluid, body.page-events .slider-position.stick-top .container-fluid, body.page-event .slider-position.stick-top .container-fluid, body.page-tags .slider-position.stick-top .container-fluid, body.page-node-29 .slider-position.stick-top .container-fluid, body.page-node-34 .slider-position.stick-top .container-fluid, body.page-content .slider-position.stick-top .container-fluid {
  box-shadow: 0px 5px 10px 0px rgba(0, 0, 0, 0.1);
}

body.page-news .slider-position.stick-top #scroll-header, body.node-type-tep-blogs .slider-position.stick-top #scroll-header, body.page-guides .slider-position.stick-top #scroll-header, body.page-edgeproptv .slider-position.stick-top #scroll-header, body.page-pullout .slider-position.stick-top #scroll-header, body.page-events .slider-position.stick-top #scroll-header, body.page-event .slider-position.stick-top #scroll-header, body.page-tags .slider-position.stick-top #scroll-header, body.page-node-29 .slider-position.stick-top #scroll-header, body.page-node-34 .slider-position.stick-top #scroll-header, body.page-content .slider-position.stick-top #scroll-header {
  position: fixed;
  top: 0px;
  z-index: 2002;
  width: 100%;
}

body.page-news span, body.node-type-tep-blogs span, body.page-guides span, body.page-edgeproptv span, body.page-pullout span, body.page-events span, body.page-event span, body.page-tags span, body.page-node-29 span, body.page-node-34 span, body.page-content span {
  display: inline-block;
}

body.page-news div, body.page-news span, body.page-news label, body.node-type-tep-blogs div, body.node-type-tep-blogs span, body.node-type-tep-blogs label, body.page-guides div, body.page-guides span, body.page-guides label, body.page-edgeproptv div, body.page-edgeproptv span, body.page-edgeproptv label, body.page-pullout div, body.page-pullout span, body.page-pullout label, body.page-events div, body.page-events span, body.page-events label, body.page-event div, body.page-event span, body.page-event label, body.page-tags div, body.page-tags span, body.page-tags label, body.page-node-29 div, body.page-node-29 span, body.page-node-29 label, body.page-node-34 div, body.page-node-34 span, body.page-node-34 label, body.page-content div, body.page-content span, body.page-content label {
  font-family: "Poppins", sans-serif !important;
}

body.page-news .navbar-new h4, body.page-news .navbar-new div,
body.page-news .dropdown.mega-dropdown h4,
body.page-news .dropdown.mega-dropdown div,
body.page-news .dropdown-menu h4,
body.page-news .dropdown-menu div, body.node-type-tep-blogs .navbar-new h4, body.node-type-tep-blogs .navbar-new div,
body.node-type-tep-blogs .dropdown.mega-dropdown h4,
body.node-type-tep-blogs .dropdown.mega-dropdown div,
body.node-type-tep-blogs .dropdown-menu h4,
body.node-type-tep-blogs .dropdown-menu div, body.page-guides .navbar-new h4, body.page-guides .navbar-new div,
body.page-guides .dropdown.mega-dropdown h4,
body.page-guides .dropdown.mega-dropdown div,
body.page-guides .dropdown-menu h4,
body.page-guides .dropdown-menu div, body.page-edgeproptv .navbar-new h4, body.page-edgeproptv .navbar-new div,
body.page-edgeproptv .dropdown.mega-dropdown h4,
body.page-edgeproptv .dropdown.mega-dropdown div,
body.page-edgeproptv .dropdown-menu h4,
body.page-edgeproptv .dropdown-menu div, body.page-pullout .navbar-new h4, body.page-pullout .navbar-new div,
body.page-pullout .dropdown.mega-dropdown h4,
body.page-pullout .dropdown.mega-dropdown div,
body.page-pullout .dropdown-menu h4,
body.page-pullout .dropdown-menu div, body.page-events .navbar-new h4, body.page-events .navbar-new div,
body.page-events .dropdown.mega-dropdown h4,
body.page-events .dropdown.mega-dropdown div,
body.page-events .dropdown-menu h4,
body.page-events .dropdown-menu div, body.page-event .navbar-new h4, body.page-event .navbar-new div,
body.page-event .dropdown.mega-dropdown h4,
body.page-event .dropdown.mega-dropdown div,
body.page-event .dropdown-menu h4,
body.page-event .dropdown-menu div, body.page-tags .navbar-new h4, body.page-tags .navbar-new div,
body.page-tags .dropdown.mega-dropdown h4,
body.page-tags .dropdown.mega-dropdown div,
body.page-tags .dropdown-menu h4,
body.page-tags .dropdown-menu div, body.page-node-29 .navbar-new h4, body.page-node-29 .navbar-new div,
body.page-node-29 .dropdown.mega-dropdown h4,
body.page-node-29 .dropdown.mega-dropdown div,
body.page-node-29 .dropdown-menu h4,
body.page-node-29 .dropdown-menu div, body.page-node-34 .navbar-new h4, body.page-node-34 .navbar-new div,
body.page-node-34 .dropdown.mega-dropdown h4,
body.page-node-34 .dropdown.mega-dropdown div,
body.page-node-34 .dropdown-menu h4,
body.page-node-34 .dropdown-menu div, body.page-content .navbar-new h4, body.page-content .navbar-new div,
body.page-content .dropdown.mega-dropdown h4,
body.page-content .dropdown.mega-dropdown div,
body.page-content .dropdown-menu h4,
body.page-content .dropdown-menu div {
  font-family: "Poppins", sans-serif !important;
}

body.page-news a, body.node-type-tep-blogs a, body.page-guides a, body.page-edgeproptv a, body.page-pullout a, body.page-events a, body.page-event a, body.page-tags a, body.page-node-29 a, body.page-node-34 a, body.page-content a {
  cursor: pointer;
  text-decoration: none;
  color: #1a1a1a;
  display: inline-block;
  transition: all 200ms ease-out;
  /*safari and chrome */
  -webkit-transition: all 200ms ease-out;
  /*safari and chrome */
}

body.page-news img, body.node-type-tep-blogs img, body.page-guides img, body.page-edgeproptv img, body.page-pullout img, body.page-events img, body.page-event img, body.page-tags img, body.page-node-29 img, body.page-node-34 img, body.page-content img {
  max-width: 100%;
  height: auto;
}

body.page-news .content img, body.node-type-tep-blogs .content img, body.page-guides .content img, body.page-edgeproptv .content img, body.page-pullout .content img, body.page-events .content img, body.page-event .content img, body.page-tags .content img, body.page-node-29 .content img, body.page-node-34 .content img, body.page-content .content img {
  max-width: 100% !important;
}

body.page-news iframe, body.page-news embed, body.page-news video, body.page-news audio, body.node-type-tep-blogs iframe, body.node-type-tep-blogs embed, body.node-type-tep-blogs video, body.node-type-tep-blogs audio, body.page-guides iframe, body.page-guides embed, body.page-guides video, body.page-guides audio, body.page-edgeproptv iframe, body.page-edgeproptv embed, body.page-edgeproptv video, body.page-edgeproptv audio, body.page-pullout iframe, body.page-pullout embed, body.page-pullout video, body.page-pullout audio, body.page-events iframe, body.page-events embed, body.page-events video, body.page-events audio, body.page-event iframe, body.page-event embed, body.page-event video, body.page-event audio, body.page-tags iframe, body.page-tags embed, body.page-tags video, body.page-tags audio, body.page-node-29 iframe, body.page-node-29 embed, body.page-node-29 video, body.page-node-29 audio, body.page-node-34 iframe, body.page-node-34 embed, body.page-node-34 video, body.page-node-34 audio, body.page-content iframe, body.page-content embed, body.page-content video, body.page-content audio {
  max-width: 100%;
}

body.page-news *:focus, body.node-type-tep-blogs *:focus, body.page-guides *:focus, body.page-edgeproptv *:focus, body.page-pullout *:focus, body.page-events *:focus, body.page-event *:focus, body.page-tags *:focus, body.page-node-29 *:focus, body.page-node-34 *:focus, body.page-content *:focus {
  outline: none;
}

body.page-news *, body.page-news *:before, body.page-news *:after, body.node-type-tep-blogs *, body.node-type-tep-blogs *:before, body.node-type-tep-blogs *:after, body.page-guides *, body.page-guides *:before, body.page-guides *:after, body.page-edgeproptv *, body.page-edgeproptv *:before, body.page-edgeproptv *:after, body.page-pullout *, body.page-pullout *:before, body.page-pullout *:after, body.page-events *, body.page-events *:before, body.page-events *:after, body.page-event *, body.page-event *:before, body.page-event *:after, body.page-tags *, body.page-tags *:before, body.page-tags *:after, body.page-node-29 *, body.page-node-29 *:before, body.page-node-29 *:after, body.page-node-34 *, body.page-node-34 *:before, body.page-node-34 *:after, body.page-content *, body.page-content *:before, body.page-content *:after {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

body.page-news .news-content-wrapper, body.node-type-tep-blogs .news-content-wrapper, body.page-guides .news-content-wrapper, body.page-edgeproptv .news-content-wrapper, body.page-pullout .news-content-wrapper, body.page-events .news-content-wrapper, body.page-event .news-content-wrapper, body.page-tags .news-content-wrapper, body.page-node-29 .news-content-wrapper, body.page-node-34 .news-content-wrapper, body.page-content .news-content-wrapper {
  overflow-x: hidden;
}

body.page-news .wrap, body.node-type-tep-blogs .wrap, body.page-guides .wrap, body.page-edgeproptv .wrap, body.page-pullout .wrap, body.page-events .wrap, body.page-event .wrap, body.page-tags .wrap, body.page-node-29 .wrap, body.page-node-34 .wrap, body.page-content .wrap {
  width: 1200px;
  margin: auto;
  position: relative;
  top: 116px;
  margin-bottom: 120px;
  z-index: 2;
}

@media (max-width: 991px) {
  body.page-news .wrap, body.node-type-tep-blogs .wrap, body.page-guides .wrap, body.page-edgeproptv .wrap, body.page-pullout .wrap, body.page-events .wrap, body.page-event .wrap, body.page-tags .wrap, body.page-node-29 .wrap, body.page-node-34 .wrap, body.page-content .wrap {
    top: 0;
    margin-bottom: 0;
  }
}

body.page-news .wrap.news-wope-theme, body.node-type-tep-blogs .wrap.news-wope-theme, body.page-guides .wrap.news-wope-theme, body.page-edgeproptv .wrap.news-wope-theme, body.page-pullout .wrap.news-wope-theme, body.page-events .wrap.news-wope-theme, body.page-event .wrap.news-wope-theme, body.page-tags .wrap.news-wope-theme, body.page-node-29 .wrap.news-wope-theme, body.page-node-34 .wrap.news-wope-theme, body.page-content .wrap.news-wope-theme {
  width: 970px;
  background: #fff;
  top: 0;
}

body.page-news .wrap-top-0, body.node-type-tep-blogs .wrap-top-0, body.page-guides .wrap-top-0, body.page-edgeproptv .wrap-top-0, body.page-pullout .wrap-top-0, body.page-events .wrap-top-0, body.page-event .wrap-top-0, body.page-tags .wrap-top-0, body.page-node-29 .wrap-top-0, body.page-node-34 .wrap-top-0, body.page-content .wrap-top-0 {
  top: 0;
}

body.page-news .wrap-colum, body.node-type-tep-blogs .wrap-colum, body.page-guides .wrap-colum, body.page-edgeproptv .wrap-colum, body.page-pullout .wrap-colum, body.page-events .wrap-colum, body.page-event .wrap-colum, body.page-tags .wrap-colum, body.page-node-29 .wrap-colum, body.page-node-34 .wrap-colum, body.page-content .wrap-colum {
  width: 1240px;
  margin: auto;
  position: relative;
}

body.page-news .clearfix:before, body.page-news .clearfix:after, body.node-type-tep-blogs .clearfix:before, body.node-type-tep-blogs .clearfix:after, body.page-guides .clearfix:before, body.page-guides .clearfix:after, body.page-edgeproptv .clearfix:before, body.page-edgeproptv .clearfix:after, body.page-pullout .clearfix:before, body.page-pullout .clearfix:after, body.page-events .clearfix:before, body.page-events .clearfix:after, body.page-event .clearfix:before, body.page-event .clearfix:after, body.page-tags .clearfix:before, body.page-tags .clearfix:after, body.page-node-29 .clearfix:before, body.page-node-29 .clearfix:after, body.page-node-34 .clearfix:before, body.page-node-34 .clearfix:after, body.page-content .clearfix:before, body.page-content .clearfix:after {
  content: " ";
  /* 1 */
  display: table;
  /* 2 */
}

body.page-news .clearfix, body.node-type-tep-blogs .clearfix, body.page-guides .clearfix, body.page-edgeproptv .clearfix, body.page-pullout .clearfix, body.page-events .clearfix, body.page-event .clearfix, body.page-tags .clearfix, body.page-node-29 .clearfix, body.page-node-34 .clearfix, body.page-content .clearfix {
  *zoom: 1;
}

body.page-news .clearfix:after, body.node-type-tep-blogs .clearfix:after, body.page-guides .clearfix:after, body.page-edgeproptv .clearfix:after, body.page-pullout .clearfix:after, body.page-events .clearfix:after, body.page-event .clearfix:after, body.page-tags .clearfix:after, body.page-node-29 .clearfix:after, body.page-node-34 .clearfix:after, body.page-content .clearfix:after {
  clear: both;
}

body.page-news figure, body.node-type-tep-blogs figure, body.page-guides figure, body.page-edgeproptv figure, body.page-pullout figure, body.page-events figure, body.page-event figure, body.page-tags figure, body.page-node-29 figure, body.page-node-34 figure, body.page-content figure {
  font-size: 0;
  margin-bottom: 35px;
}

body.page-news figure.first, body.node-type-tep-blogs figure.first, body.page-guides figure.first, body.page-edgeproptv figure.first, body.page-pullout figure.first, body.page-events figure.first, body.page-event figure.first, body.page-tags figure.first, body.page-node-29 figure.first, body.page-node-34 figure.first, body.page-content figure.first {
  margin-bottom: 0;
}

body.page-news figcaption, body.node-type-tep-blogs figcaption, body.page-guides figcaption, body.page-edgeproptv figcaption, body.page-pullout figcaption, body.page-events figcaption, body.page-event figcaption, body.page-tags figcaption, body.page-node-29 figcaption, body.page-node-34 figcaption, body.page-content figcaption {
  font-size: 14px;
  color: #212529;
  font-weight: 600;
}

body.page-news .col-2-1, body.node-type-tep-blogs .col-2-1, body.page-guides .col-2-1, body.page-edgeproptv .col-2-1, body.page-pullout .col-2-1, body.page-events .col-2-1, body.page-event .col-2-1, body.page-tags .col-2-1, body.page-node-29 .col-2-1, body.page-node-34 .col-2-1, body.page-content .col-2-1 {
  float: left;
  width: 50%;
}

body.page-news .col-4-1, body.node-type-tep-blogs .col-4-1, body.page-guides .col-4-1, body.page-edgeproptv .col-4-1, body.page-pullout .col-4-1, body.page-events .col-4-1, body.page-event .col-4-1, body.page-tags .col-4-1, body.page-node-29 .col-4-1, body.page-node-34 .col-4-1, body.page-content .col-4-1 {
  float: left;
  width: 25%;
}

body.page-news .col-3-1, body.node-type-tep-blogs .col-3-1, body.page-guides .col-3-1, body.page-edgeproptv .col-3-1, body.page-pullout .col-3-1, body.page-events .col-3-1, body.page-event .col-3-1, body.page-tags .col-3-1, body.page-node-29 .col-3-1, body.page-node-34 .col-3-1, body.page-content .col-3-1 {
  float: left;
  width: 33.33%;
}

body.page-news .col-3-2, body.node-type-tep-blogs .col-3-2, body.page-guides .col-3-2, body.page-edgeproptv .col-3-2, body.page-pullout .col-3-2, body.page-events .col-3-2, body.page-event .col-3-2, body.page-tags .col-3-2, body.page-node-29 .col-3-2, body.page-node-34 .col-3-2, body.page-content .col-3-2 {
  float: left;
  width: 66.66%;
}

body.page-news .colum-iblock,
body.page-news .iblock-2, body.node-type-tep-blogs .colum-iblock,
body.node-type-tep-blogs .iblock-2, body.page-guides .colum-iblock,
body.page-guides .iblock-2, body.page-edgeproptv .colum-iblock,
body.page-edgeproptv .iblock-2, body.page-pullout .colum-iblock,
body.page-pullout .iblock-2, body.page-events .colum-iblock,
body.page-events .iblock-2, body.page-event .colum-iblock,
body.page-event .iblock-2, body.page-tags .colum-iblock,
body.page-tags .iblock-2, body.page-node-29 .colum-iblock,
body.page-node-29 .iblock-2, body.page-node-34 .colum-iblock,
body.page-node-34 .iblock-2, body.page-content .colum-iblock,
body.page-content .iblock-2 {
  letter-spacing: -5px;
}

body.page-news .colum-iblock > div, body.page-news .colum-iblock > article, body.page-news .iblock-2 > *, body.node-type-tep-blogs .colum-iblock > div, body.node-type-tep-blogs .colum-iblock > article, body.node-type-tep-blogs .iblock-2 > *, body.page-guides .colum-iblock > div, body.page-guides .colum-iblock > article, body.page-guides .iblock-2 > *, body.page-edgeproptv .colum-iblock > div, body.page-edgeproptv .colum-iblock > article, body.page-edgeproptv .iblock-2 > *, body.page-pullout .colum-iblock > div, body.page-pullout .colum-iblock > article, body.page-pullout .iblock-2 > *, body.page-events .colum-iblock > div, body.page-events .colum-iblock > article, body.page-events .iblock-2 > *, body.page-event .colum-iblock > div, body.page-event .colum-iblock > article, body.page-event .iblock-2 > *, body.page-tags .colum-iblock > div, body.page-tags .colum-iblock > article, body.page-tags .iblock-2 > *, body.page-node-29 .colum-iblock > div, body.page-node-29 .colum-iblock > article, body.page-node-29 .iblock-2 > *, body.page-node-34 .colum-iblock > div, body.page-node-34 .colum-iblock > article, body.page-node-34 .iblock-2 > *, body.page-content .colum-iblock > div, body.page-content .colum-iblock > article, body.page-content .iblock-2 > * {
  letter-spacing: 0;
  display: inline-block;
  float: none;
  vertical-align: middle;
}

body.page-news .iblock-2 article, body.node-type-tep-blogs .iblock-2 article, body.page-guides .iblock-2 article, body.page-edgeproptv .iblock-2 article, body.page-pullout .iblock-2 article, body.page-events .iblock-2 article, body.page-event .iblock-2 article, body.page-tags .iblock-2 article, body.page-node-29 .iblock-2 article, body.page-node-34 .iblock-2 article, body.page-content .iblock-2 article {
  width: 50%;
}

body.page-news .iblock-2 article:nth-of-type(2n+1), body.node-type-tep-blogs .iblock-2 article:nth-of-type(2n+1), body.page-guides .iblock-2 article:nth-of-type(2n+1), body.page-edgeproptv .iblock-2 article:nth-of-type(2n+1), body.page-pullout .iblock-2 article:nth-of-type(2n+1), body.page-events .iblock-2 article:nth-of-type(2n+1), body.page-event .iblock-2 article:nth-of-type(2n+1), body.page-tags .iblock-2 article:nth-of-type(2n+1), body.page-node-29 .iblock-2 article:nth-of-type(2n+1), body.page-node-34 .iblock-2 article:nth-of-type(2n+1), body.page-content .iblock-2 article:nth-of-type(2n+1) {
  padding-right: 19px;
}

body.page-news .iblock-2 article:nth-of-type(2n+2), body.node-type-tep-blogs .iblock-2 article:nth-of-type(2n+2), body.page-guides .iblock-2 article:nth-of-type(2n+2), body.page-edgeproptv .iblock-2 article:nth-of-type(2n+2), body.page-pullout .iblock-2 article:nth-of-type(2n+2), body.page-events .iblock-2 article:nth-of-type(2n+2), body.page-event .iblock-2 article:nth-of-type(2n+2), body.page-tags .iblock-2 article:nth-of-type(2n+2), body.page-node-29 .iblock-2 article:nth-of-type(2n+2), body.page-node-34 .iblock-2 article:nth-of-type(2n+2), body.page-content .iblock-2 article:nth-of-type(2n+2) {
  padding-left: 19px;
}

body.page-news .small-column, body.node-type-tep-blogs .small-column, body.page-guides .small-column, body.page-edgeproptv .small-column, body.page-pullout .small-column, body.page-events .small-column, body.page-event .small-column, body.page-tags .small-column, body.page-node-29 .small-column, body.page-node-34 .small-column, body.page-content .small-column {
  width: 27.5%;
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  /* Firefox */
  -webkit-box-sizing: border-box;
  position: relative;
  z-index: 2;
  display: table-cell;
  vertical-align: top;
}

body.page-news .big-column, body.node-type-tep-blogs .big-column, body.page-guides .big-column, body.page-edgeproptv .big-column, body.page-pullout .big-column, body.page-events .big-column, body.page-event .big-column, body.page-tags .big-column, body.page-node-29 .big-column, body.page-node-34 .big-column, body.page-content .big-column {
  width: 72.5%;
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  /* Firefox */
  -webkit-box-sizing: border-box;
  position: relative;
  z-index: 3;
  display: table-cell;
  vertical-align: top;
}

body.page-news .left, body.node-type-tep-blogs .left, body.page-guides .left, body.page-edgeproptv .left, body.page-pullout .left, body.page-events .left, body.page-event .left, body.page-tags .left, body.page-node-29 .left, body.page-node-34 .left, body.page-content .left {
  float: left;
}

body.page-news .right, body.node-type-tep-blogs .right, body.page-guides .right, body.page-edgeproptv .right, body.page-pullout .right, body.page-events .right, body.page-event .right, body.page-tags .right, body.page-node-29 .right, body.page-node-34 .right, body.page-content .right {
  float: right !important;
}

body.page-news .column1, body.page-news .column1_1, body.node-type-tep-blogs .column1, body.node-type-tep-blogs .column1_1, body.page-guides .column1, body.page-guides .column1_1, body.page-edgeproptv .column1, body.page-edgeproptv .column1_1, body.page-pullout .column1, body.page-pullout .column1_1, body.page-events .column1, body.page-events .column1_1, body.page-event .column1, body.page-event .column1_1, body.page-tags .column1, body.page-tags .column1_1, body.page-node-29 .column1, body.page-node-29 .column1_1, body.page-node-34 .column1, body.page-node-34 .column1_1, body.page-content .column1, body.page-content .column1_1 {
  float: left;
  width: 100%;
}

body.page-news .column1_2, body.node-type-tep-blogs .column1_2, body.page-guides .column1_2, body.page-edgeproptv .column1_2, body.page-pullout .column1_2, body.page-events .column1_2, body.page-event .column1_2, body.page-tags .column1_2, body.page-node-29 .column1_2, body.page-node-34 .column1_2, body.page-content .column1_2 {
  float: left;
  width: 48%;
  margin-right: 4%;
}

body.page-news .column1_3, body.node-type-tep-blogs .column1_3, body.page-guides .column1_3, body.page-edgeproptv .column1_3, body.page-pullout .column1_3, body.page-events .column1_3, body.page-event .column1_3, body.page-tags .column1_3, body.page-node-29 .column1_3, body.page-node-34 .column1_3, body.page-content .column1_3 {
  float: left;
  width: 31%;
  margin-right: 4%;
}

body.page-news .column1_3.column-last, body.node-type-tep-blogs .column1_3.column-last, body.page-guides .column1_3.column-last, body.page-edgeproptv .column1_3.column-last, body.page-pullout .column1_3.column-last, body.page-events .column1_3.column-last, body.page-event .column1_3.column-last, body.page-tags .column1_3.column-last, body.page-node-29 .column1_3.column-last, body.page-node-34 .column1_3.column-last, body.page-content .column1_3.column-last {
  width: 30%;
}

body.page-news .column1_4, body.node-type-tep-blogs .column1_4, body.page-guides .column1_4, body.page-edgeproptv .column1_4, body.page-pullout .column1_4, body.page-events .column1_4, body.page-event .column1_4, body.page-tags .column1_4, body.page-node-29 .column1_4, body.page-node-34 .column1_4, body.page-content .column1_4 {
  float: left;
  width: 22%;
  margin-right: 4%;
}

body.page-news .column2_3, body.node-type-tep-blogs .column2_3, body.page-guides .column2_3, body.page-edgeproptv .column2_3, body.page-pullout .column2_3, body.page-events .column2_3, body.page-event .column2_3, body.page-tags .column2_3, body.page-node-29 .column2_3, body.page-node-34 .column2_3, body.page-content .column2_3 {
  float: left;
  width: 66%;
  margin-right: 4%;
}

body.page-news .column2_3.column-last, body.node-type-tep-blogs .column2_3.column-last, body.page-guides .column2_3.column-last, body.page-edgeproptv .column2_3.column-last, body.page-pullout .column2_3.column-last, body.page-events .column2_3.column-last, body.page-event .column2_3.column-last, body.page-tags .column2_3.column-last, body.page-node-29 .column2_3.column-last, body.page-node-34 .column2_3.column-last, body.page-content .column2_3.column-last {
  width: 65%;
}

body.page-news .column2_4, body.node-type-tep-blogs .column2_4, body.page-guides .column2_4, body.page-edgeproptv .column2_4, body.page-pullout .column2_4, body.page-events .column2_4, body.page-event .column2_4, body.page-tags .column2_4, body.page-node-29 .column2_4, body.page-node-34 .column2_4, body.page-content .column2_4 {
  float: left;
  width: 48%;
  margin-right: 4%;
}

body.page-news .column3_4, body.node-type-tep-blogs .column3_4, body.page-guides .column3_4, body.page-edgeproptv .column3_4, body.page-pullout .column3_4, body.page-events .column3_4, body.page-event .column3_4, body.page-tags .column3_4, body.page-node-29 .column3_4, body.page-node-34 .column3_4, body.page-content .column3_4 {
  float: left;
  width: 74%;
  margin-right: 4%;
}

body.page-news .column-last, body.node-type-tep-blogs .column-last, body.page-guides .column-last, body.page-edgeproptv .column-last, body.page-pullout .column-last, body.page-events .column-last, body.page-event .column-last, body.page-tags .column-last, body.page-node-29 .column-last, body.page-node-34 .column-last, body.page-content .column-last {
  margin-right: 0%;
}

body.page-news .no_margin, body.node-type-tep-blogs .no_margin, body.page-guides .no_margin, body.page-edgeproptv .no_margin, body.page-pullout .no_margin, body.page-events .no_margin, body.page-event .no_margin, body.page-tags .no_margin, body.page-node-29 .no_margin, body.page-node-34 .no_margin, body.page-content .no_margin {
  margin-bottom: 0px;
}

body.page-news #back_top, body.node-type-tep-blogs #back_top, body.page-guides #back_top, body.page-edgeproptv #back_top, body.page-pullout #back_top, body.page-events #back_top, body.page-event #back_top, body.page-tags #back_top, body.page-node-29 #back_top, body.page-node-34 #back_top, body.page-content #back_top {
  display: none;
  width: 50px;
  height: 50px;
  position: fixed;
  bottom: 25px;
  right: 0px;
  margin-right: 26px;
  z-index: 100;
  transition: all 200ms ease-out;
  /*safari and chrome */
  -webkit-transition: all 200ms ease-out;
  /*safari and chrome */
  cursor: pointer;
  text-align: center;
}

body.page-news #back_top i, body.node-type-tep-blogs #back_top i, body.page-guides #back_top i, body.page-edgeproptv #back_top i, body.page-pullout #back_top i, body.page-events #back_top i, body.page-event #back_top i, body.page-tags #back_top i, body.page-node-29 #back_top i, body.page-node-34 #back_top i, body.page-content #back_top i {
  line-height: 50px;
  font-size: 30px;
  transition: all 200ms ease-out;
  /*safari and chrome */
  -webkit-transition: all 200ms ease-out;
  /*safari and chrome */
}

body.page-news .toggle-menu-button, body.node-type-tep-blogs .toggle-menu-button, body.page-guides .toggle-menu-button, body.page-edgeproptv .toggle-menu-button, body.page-pullout .toggle-menu-button, body.page-events .toggle-menu-button, body.page-event .toggle-menu-button, body.page-tags .toggle-menu-button, body.page-node-29 .toggle-menu-button, body.page-node-34 .toggle-menu-button, body.page-content .toggle-menu-button {
  height: 73px;
  display: none;
  cursor: pointer;
  text-align: right;
  float: right;
  letter-spacing: 0;
}

body.page-news .toggle-menu-button i, body.node-type-tep-blogs .toggle-menu-button i, body.page-guides .toggle-menu-button i, body.page-edgeproptv .toggle-menu-button i, body.page-pullout .toggle-menu-button i, body.page-events .toggle-menu-button i, body.page-event .toggle-menu-button i, body.page-tags .toggle-menu-button i, body.page-node-29 .toggle-menu-button i, body.page-node-34 .toggle-menu-button i, body.page-content .toggle-menu-button i {
  font-size: 23px;
  line-height: 75px;
  color: #999;
}

body.page-news .toggle-menu-top, body.node-type-tep-blogs .toggle-menu-top, body.page-guides .toggle-menu-top, body.page-edgeproptv .toggle-menu-top, body.page-pullout .toggle-menu-top, body.page-events .toggle-menu-top, body.page-event .toggle-menu-top, body.page-tags .toggle-menu-top, body.page-node-29 .toggle-menu-top, body.page-node-34 .toggle-menu-top, body.page-content .toggle-menu-top {
  text-align: left;
  padding-bottom: 10px;
}

body.page-news .toggle-menu-close, body.node-type-tep-blogs .toggle-menu-close, body.page-guides .toggle-menu-close, body.page-edgeproptv .toggle-menu-close, body.page-pullout .toggle-menu-close, body.page-events .toggle-menu-close, body.page-event .toggle-menu-close, body.page-tags .toggle-menu-close, body.page-node-29 .toggle-menu-close, body.page-node-34 .toggle-menu-close, body.page-content .toggle-menu-close {
  width: 30px;
  height: 30px;
  text-align: center;
  border-radius: 20px;
  display: inline-block;
  cursor: pointer;
  transition: all 200ms ease-out;
  /*safari and chrome */
  -webkit-transition: all 200ms ease-out;
  /*safari and chrome */
  margin-bottom: 10px;
}

body.page-news .toggle-menu-close i, body.node-type-tep-blogs .toggle-menu-close i, body.page-guides .toggle-menu-close i, body.page-edgeproptv .toggle-menu-close i, body.page-pullout .toggle-menu-close i, body.page-events .toggle-menu-close i, body.page-event .toggle-menu-close i, body.page-tags .toggle-menu-close i, body.page-node-29 .toggle-menu-close i, body.page-node-34 .toggle-menu-close i, body.page-content .toggle-menu-close i {
  font-size: 24;
  line-height: 30px;
  color: rgba(255, 255, 255, 0.5);
}

body.page-news #main-menu-toggle, body.node-type-tep-blogs #main-menu-toggle, body.page-guides #main-menu-toggle, body.page-edgeproptv #main-menu-toggle, body.page-pullout #main-menu-toggle, body.page-events #main-menu-toggle, body.page-event #main-menu-toggle, body.page-tags #main-menu-toggle, body.page-node-29 #main-menu-toggle, body.page-node-34 #main-menu-toggle, body.page-content #main-menu-toggle {
  background-color: #2e2e2e;
  position: absolute;
  top: 0px;
  left: -300px;
  width: 300px;
  min-height: 100%;
  padding: 40px 30px 40px;
  z-index: 9999;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  transition: all 0.3s ease;
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  /* Firefox */
}

body.page-news #main-menu-toggle a, body.node-type-tep-blogs #main-menu-toggle a, body.page-guides #main-menu-toggle a, body.page-edgeproptv #main-menu-toggle a, body.page-pullout #main-menu-toggle a, body.page-events #main-menu-toggle a, body.page-event #main-menu-toggle a, body.page-tags #main-menu-toggle a, body.page-node-29 #main-menu-toggle a, body.page-node-34 #main-menu-toggle a, body.page-content #main-menu-toggle a {
  font-size: 12px;
  line-height: 24px;
  font-style: normal;
  font-weight: 500;
  color: #ffffff;
  letter-spacing: 1px;
  padding: 10px 0px 10px 0px;
  display: block;
  transition: all 200ms ease-out;
  /*safari and chrome */
  -webkit-transition: all 200ms ease-out;
  /*safari and chrome */
}

body.page-news #main-menu-toggle.toggle-menu-open, body.node-type-tep-blogs #main-menu-toggle.toggle-menu-open, body.page-guides #main-menu-toggle.toggle-menu-open, body.page-edgeproptv #main-menu-toggle.toggle-menu-open, body.page-pullout #main-menu-toggle.toggle-menu-open, body.page-events #main-menu-toggle.toggle-menu-open, body.page-event #main-menu-toggle.toggle-menu-open, body.page-tags #main-menu-toggle.toggle-menu-open, body.page-node-29 #main-menu-toggle.toggle-menu-open, body.page-node-34 #main-menu-toggle.toggle-menu-open, body.page-content #main-menu-toggle.toggle-menu-open {
  left: 0px;
}

body.page-news #main-menu-toggle ul ul.sub-menu li, body.node-type-tep-blogs #main-menu-toggle ul ul.sub-menu li, body.page-guides #main-menu-toggle ul ul.sub-menu li, body.page-edgeproptv #main-menu-toggle ul ul.sub-menu li, body.page-pullout #main-menu-toggle ul ul.sub-menu li, body.page-events #main-menu-toggle ul ul.sub-menu li, body.page-event #main-menu-toggle ul ul.sub-menu li, body.page-tags #main-menu-toggle ul ul.sub-menu li, body.page-node-29 #main-menu-toggle ul ul.sub-menu li, body.page-node-34 #main-menu-toggle ul ul.sub-menu li, body.page-content #main-menu-toggle ul ul.sub-menu li {
  padding-left: 30px;
}

body.page-news #main-menu-toggle ul ul.sub-menu ul.sub-menu li, body.node-type-tep-blogs #main-menu-toggle ul ul.sub-menu ul.sub-menu li, body.page-guides #main-menu-toggle ul ul.sub-menu ul.sub-menu li, body.page-edgeproptv #main-menu-toggle ul ul.sub-menu ul.sub-menu li, body.page-pullout #main-menu-toggle ul ul.sub-menu ul.sub-menu li, body.page-events #main-menu-toggle ul ul.sub-menu ul.sub-menu li, body.page-event #main-menu-toggle ul ul.sub-menu ul.sub-menu li, body.page-tags #main-menu-toggle ul ul.sub-menu ul.sub-menu li, body.page-node-29 #main-menu-toggle ul ul.sub-menu ul.sub-menu li, body.page-node-34 #main-menu-toggle ul ul.sub-menu ul.sub-menu li, body.page-content #main-menu-toggle ul ul.sub-menu ul.sub-menu li {
  padding-left: 60px;
}

body.page-news #page #header, body.node-type-tep-blogs #page #header, body.page-guides #page #header, body.page-edgeproptv #page #header, body.page-pullout #page #header, body.page-events #page #header, body.page-event #page #header, body.page-tags #page #header, body.page-node-29 #page #header, body.page-node-34 #page #header, body.page-content #page #header {
  left: 0px;
}

body.page-news #top-bar, body.node-type-tep-blogs #top-bar, body.page-guides #top-bar, body.page-edgeproptv #top-bar, body.page-pullout #top-bar, body.page-events #top-bar, body.page-event #top-bar, body.page-tags #top-bar, body.page-node-29 #top-bar, body.page-node-34 #top-bar, body.page-content #top-bar {
  -webkit-box-shadow: 0px 5px 10px 0px rgba(0, 0, 0, 0.1);
  -moz-box-shadow: 0px 5px 10px 0px rgba(0, 0, 0, 0.1);
  box-shadow: 0px 5px 10px 0px rgba(0, 0, 0, 0.1);
}

body.page-news #top-bar .wrap, body.node-type-tep-blogs #top-bar .wrap, body.page-guides #top-bar .wrap, body.page-edgeproptv #top-bar .wrap, body.page-pullout #top-bar .wrap, body.page-events #top-bar .wrap, body.page-event #top-bar .wrap, body.page-tags #top-bar .wrap, body.page-node-29 #top-bar .wrap, body.page-node-34 #top-bar .wrap, body.page-content #top-bar .wrap {
  z-index: 996;
}

body.page-news .topbar-menu,
body.page-news .menu-top-bar-container, body.node-type-tep-blogs .topbar-menu,
body.node-type-tep-blogs .menu-top-bar-container, body.page-guides .topbar-menu,
body.page-guides .menu-top-bar-container, body.page-edgeproptv .topbar-menu,
body.page-edgeproptv .menu-top-bar-container, body.page-pullout .topbar-menu,
body.page-pullout .menu-top-bar-container, body.page-events .topbar-menu,
body.page-events .menu-top-bar-container, body.page-event .topbar-menu,
body.page-event .menu-top-bar-container, body.page-tags .topbar-menu,
body.page-tags .menu-top-bar-container, body.page-node-29 .topbar-menu,
body.page-node-29 .menu-top-bar-container, body.page-node-34 .topbar-menu,
body.page-node-34 .menu-top-bar-container, body.page-content .topbar-menu,
body.page-content .menu-top-bar-container {
  float: left;
}

body.page-news .topbar-menu, body.node-type-tep-blogs .topbar-menu, body.page-guides .topbar-menu, body.page-edgeproptv .topbar-menu, body.page-pullout .topbar-menu, body.page-events .topbar-menu, body.page-event .topbar-menu, body.page-tags .topbar-menu, body.page-node-29 .topbar-menu, body.page-node-34 .topbar-menu, body.page-content .topbar-menu {
  width: 60%;
}

body.page-news .topbar-menu ul li, body.node-type-tep-blogs .topbar-menu ul li, body.page-guides .topbar-menu ul li, body.page-edgeproptv .topbar-menu ul li, body.page-pullout .topbar-menu ul li, body.page-events .topbar-menu ul li, body.page-event .topbar-menu ul li, body.page-tags .topbar-menu ul li, body.page-node-29 .topbar-menu ul li, body.page-node-34 .topbar-menu ul li, body.page-content .topbar-menu ul li {
  position: relative;
}

body.page-news .topbar-menu ul li .sub-menu, body.node-type-tep-blogs .topbar-menu ul li .sub-menu, body.page-guides .topbar-menu ul li .sub-menu, body.page-edgeproptv .topbar-menu ul li .sub-menu, body.page-pullout .topbar-menu ul li .sub-menu, body.page-events .topbar-menu ul li .sub-menu, body.page-event .topbar-menu ul li .sub-menu, body.page-tags .topbar-menu ul li .sub-menu, body.page-node-29 .topbar-menu ul li .sub-menu, body.page-node-34 .topbar-menu ul li .sub-menu, body.page-content .topbar-menu ul li .sub-menu {
  position: absolute;
  width: 250px;
  padding: 20px;
  left: -26px;
  background-color: #000;
  display: none;
}

body.page-news .topbar-social-network, body.node-type-tep-blogs .topbar-social-network, body.page-guides .topbar-social-network, body.page-edgeproptv .topbar-social-network, body.page-pullout .topbar-social-network, body.page-events .topbar-social-network, body.page-event .topbar-social-network, body.page-tags .topbar-social-network, body.page-node-29 .topbar-social-network, body.page-node-34 .topbar-social-network, body.page-content .topbar-social-network {
  float: right;
  width: 40%;
}

body.page-news .topbar-social-network ul, body.node-type-tep-blogs .topbar-social-network ul, body.page-guides .topbar-social-network ul, body.page-edgeproptv .topbar-social-network ul, body.page-pullout .topbar-social-network ul, body.page-events .topbar-social-network ul, body.page-event .topbar-social-network ul, body.page-tags .topbar-social-network ul, body.page-node-29 .topbar-social-network ul, body.page-node-34 .topbar-social-network ul, body.page-content .topbar-social-network ul {
  float: right;
}

body.page-news .topbar-social-network ul li:hover > .sub-menu, body.node-type-tep-blogs .topbar-social-network ul li:hover > .sub-menu, body.page-guides .topbar-social-network ul li:hover > .sub-menu, body.page-edgeproptv .topbar-social-network ul li:hover > .sub-menu, body.page-pullout .topbar-social-network ul li:hover > .sub-menu, body.page-events .topbar-social-network ul li:hover > .sub-menu, body.page-event .topbar-social-network ul li:hover > .sub-menu, body.page-tags .topbar-social-network ul li:hover > .sub-menu, body.page-node-29 .topbar-social-network ul li:hover > .sub-menu, body.page-node-34 .topbar-social-network ul li:hover > .sub-menu, body.page-content .topbar-social-network ul li:hover > .sub-menu {
  display: block;
}

body.page-news .topbar-menu ul li,
body.page-news .social-network-list li,
body.page-news .topbar-datetime, body.node-type-tep-blogs .topbar-menu ul li,
body.node-type-tep-blogs .social-network-list li,
body.node-type-tep-blogs .topbar-datetime, body.page-guides .topbar-menu ul li,
body.page-guides .social-network-list li,
body.page-guides .topbar-datetime, body.page-edgeproptv .topbar-menu ul li,
body.page-edgeproptv .social-network-list li,
body.page-edgeproptv .topbar-datetime, body.page-pullout .topbar-menu ul li,
body.page-pullout .social-network-list li,
body.page-pullout .topbar-datetime, body.page-events .topbar-menu ul li,
body.page-events .social-network-list li,
body.page-events .topbar-datetime, body.page-event .topbar-menu ul li,
body.page-event .social-network-list li,
body.page-event .topbar-datetime, body.page-tags .topbar-menu ul li,
body.page-tags .social-network-list li,
body.page-tags .topbar-datetime, body.page-node-29 .topbar-menu ul li,
body.page-node-29 .social-network-list li,
body.page-node-29 .topbar-datetime, body.page-node-34 .topbar-menu ul li,
body.page-node-34 .social-network-list li,
body.page-node-34 .topbar-datetime, body.page-content .topbar-menu ul li,
body.page-content .social-network-list li,
body.page-content .topbar-datetime {
  float: left;
}

body.page-news .topbar-menu ul li ul li:hover > .sub-menu, body.node-type-tep-blogs .topbar-menu ul li ul li:hover > .sub-menu, body.page-guides .topbar-menu ul li ul li:hover > .sub-menu, body.page-edgeproptv .topbar-menu ul li ul li:hover > .sub-menu, body.page-pullout .topbar-menu ul li ul li:hover > .sub-menu, body.page-events .topbar-menu ul li ul li:hover > .sub-menu, body.page-event .topbar-menu ul li ul li:hover > .sub-menu, body.page-tags .topbar-menu ul li ul li:hover > .sub-menu, body.page-node-29 .topbar-menu ul li ul li:hover > .sub-menu, body.page-node-34 .topbar-menu ul li ul li:hover > .sub-menu, body.page-content .topbar-menu ul li ul li:hover > .sub-menu {
  top: -20px;
  left: 100%;
}

body.page-news .topbar-menu ul li .sub-menu li, body.node-type-tep-blogs .topbar-menu ul li .sub-menu li, body.page-guides .topbar-menu ul li .sub-menu li, body.page-edgeproptv .topbar-menu ul li .sub-menu li, body.page-pullout .topbar-menu ul li .sub-menu li, body.page-events .topbar-menu ul li .sub-menu li, body.page-event .topbar-menu ul li .sub-menu li, body.page-tags .topbar-menu ul li .sub-menu li, body.page-node-29 .topbar-menu ul li .sub-menu li, body.page-node-34 .topbar-menu ul li .sub-menu li, body.page-content .topbar-menu ul li .sub-menu li {
  float: none;
  display: block;
}

body.page-news .topbar-menu ul li .sub-menu li a, body.node-type-tep-blogs .topbar-menu ul li .sub-menu li a, body.page-guides .topbar-menu ul li .sub-menu li a, body.page-edgeproptv .topbar-menu ul li .sub-menu li a, body.page-pullout .topbar-menu ul li .sub-menu li a, body.page-events .topbar-menu ul li .sub-menu li a, body.page-event .topbar-menu ul li .sub-menu li a, body.page-tags .topbar-menu ul li .sub-menu li a, body.page-node-29 .topbar-menu ul li .sub-menu li a, body.page-node-34 .topbar-menu ul li .sub-menu li a, body.page-content .topbar-menu ul li .sub-menu li a {
  color: #fff;
}

body.page-news .topbar-menu ul li a,
body.page-news .topbar-datetime, body.node-type-tep-blogs .topbar-menu ul li a,
body.node-type-tep-blogs .topbar-datetime, body.page-guides .topbar-menu ul li a,
body.page-guides .topbar-datetime, body.page-edgeproptv .topbar-menu ul li a,
body.page-edgeproptv .topbar-datetime, body.page-pullout .topbar-menu ul li a,
body.page-pullout .topbar-datetime, body.page-events .topbar-menu ul li a,
body.page-events .topbar-datetime, body.page-event .topbar-menu ul li a,
body.page-event .topbar-datetime, body.page-tags .topbar-menu ul li a,
body.page-tags .topbar-datetime, body.page-node-29 .topbar-menu ul li a,
body.page-node-29 .topbar-datetime, body.page-node-34 .topbar-menu ul li a,
body.page-node-34 .topbar-datetime, body.page-content .topbar-menu ul li a,
body.page-content .topbar-datetime {
  display: block;
  text-transform: uppercase;
  color: #1a1a1a;
  font-size: 12px;
  font-weight: normal;
  line-height: 14px;
  padding: 10px 22px 10px 0;
}

body.page-news .social-network-list li a, body.node-type-tep-blogs .social-network-list li a, body.page-guides .social-network-list li a, body.page-edgeproptv .social-network-list li a, body.page-pullout .social-network-list li a, body.page-events .social-network-list li a, body.page-event .social-network-list li a, body.page-tags .social-network-list li a, body.page-node-29 .social-network-list li a, body.page-node-34 .social-network-list li a, body.page-content .social-network-list li a {
  display: block;
  color: #1a1a1a;
  font-size: 14px;
  font-weight: normal;
  line-height: 18px;
  padding: 8px 0 8px 22px;
}

body.page-news .topbar-menu ul li a:hover,
body.page-news .social-network-list li a:hover, body.node-type-tep-blogs .topbar-menu ul li a:hover,
body.node-type-tep-blogs .social-network-list li a:hover, body.page-guides .topbar-menu ul li a:hover,
body.page-guides .social-network-list li a:hover, body.page-edgeproptv .topbar-menu ul li a:hover,
body.page-edgeproptv .social-network-list li a:hover, body.page-pullout .topbar-menu ul li a:hover,
body.page-pullout .social-network-list li a:hover, body.page-events .topbar-menu ul li a:hover,
body.page-events .social-network-list li a:hover, body.page-event .topbar-menu ul li a:hover,
body.page-event .social-network-list li a:hover, body.page-tags .topbar-menu ul li a:hover,
body.page-tags .social-network-list li a:hover, body.page-node-29 .topbar-menu ul li a:hover,
body.page-node-29 .social-network-list li a:hover, body.page-node-34 .topbar-menu ul li a:hover,
body.page-node-34 .social-network-list li a:hover, body.page-content .topbar-menu ul li a:hover,
body.page-content .social-network-list li a:hover {
  color: #808080;
  text-decoration: none;
}

body.page-news .site-logo h1, body.node-type-tep-blogs .site-logo h1, body.page-guides .site-logo h1, body.page-edgeproptv .site-logo h1, body.page-pullout .site-logo h1, body.page-events .site-logo h1, body.page-event .site-logo h1, body.page-tags .site-logo h1, body.page-node-29 .site-logo h1, body.page-node-34 .site-logo h1, body.page-content .site-logo h1 {
  font-size: 30px;
  line-height: 36px;
  font-weight: 900;
  color: #1a1a1a;
  text-transform: uppercase;
}

body.page-news .site-logo p, body.node-type-tep-blogs .site-logo p, body.page-guides .site-logo p, body.page-edgeproptv .site-logo p, body.page-pullout .site-logo p, body.page-events .site-logo p, body.page-event .site-logo p, body.page-tags .site-logo p, body.page-node-29 .site-logo p, body.page-node-34 .site-logo p, body.page-content .site-logo p {
  font-size: 12px;
  line-height: 18px;
  letter-spacing: 1px;
  color: #999;
  text-transform: uppercase;
}

body.page-news .site-banner, body.node-type-tep-blogs .site-banner, body.page-guides .site-banner, body.page-edgeproptv .site-banner, body.page-pullout .site-banner, body.page-events .site-banner, body.page-event .site-banner, body.page-tags .site-banner, body.page-node-29 .site-banner, body.page-node-34 .site-banner, body.page-content .site-banner {
  position: relative;
}

body.page-news .widget_media_image.content, body.node-type-tep-blogs .widget_media_image.content, body.page-guides .widget_media_image.content, body.page-edgeproptv .widget_media_image.content, body.page-pullout .widget_media_image.content, body.page-events .widget_media_image.content, body.page-event .widget_media_image.content, body.page-tags .widget_media_image.content, body.page-node-29 .widget_media_image.content, body.page-node-34 .widget_media_image.content, body.page-content .widget_media_image.content {
  line-height: 0;
}

body.page-news .alignleft,
body.page-news img.alignleft, body.node-type-tep-blogs .alignleft,
body.node-type-tep-blogs img.alignleft, body.page-guides .alignleft,
body.page-guides img.alignleft, body.page-edgeproptv .alignleft,
body.page-edgeproptv img.alignleft, body.page-pullout .alignleft,
body.page-pullout img.alignleft, body.page-events .alignleft,
body.page-events img.alignleft, body.page-event .alignleft,
body.page-event img.alignleft, body.page-tags .alignleft,
body.page-tags img.alignleft, body.page-node-29 .alignleft,
body.page-node-29 img.alignleft, body.page-node-34 .alignleft,
body.page-node-34 img.alignleft, body.page-content .alignleft,
body.page-content img.alignleft {
  float: left;
  padding: 5px 20px 10px 0px;
}

body.page-news .alignright,
body.page-news img.alignright, body.node-type-tep-blogs .alignright,
body.node-type-tep-blogs img.alignright, body.page-guides .alignright,
body.page-guides img.alignright, body.page-edgeproptv .alignright,
body.page-edgeproptv img.alignright, body.page-pullout .alignright,
body.page-pullout img.alignright, body.page-events .alignright,
body.page-events img.alignright, body.page-event .alignright,
body.page-event img.alignright, body.page-tags .alignright,
body.page-tags img.alignright, body.page-node-29 .alignright,
body.page-node-29 img.alignright, body.page-node-34 .alignright,
body.page-node-34 img.alignright, body.page-content .alignright,
body.page-content img.alignright {
  float: right;
  padding: 5px 0px 10px 20px;
}

body.page-news .aligncenter, body.node-type-tep-blogs .aligncenter, body.page-guides .aligncenter, body.page-edgeproptv .aligncenter, body.page-pullout .aligncenter, body.page-events .aligncenter, body.page-event .aligncenter, body.page-tags .aligncenter, body.page-node-29 .aligncenter, body.page-node-34 .aligncenter, body.page-content .aligncenter {
  text-align: center;
}

body.page-news img.aligncenter, body.node-type-tep-blogs img.aligncenter, body.page-guides img.aligncenter, body.page-edgeproptv img.aligncenter, body.page-pullout img.aligncenter, body.page-events img.aligncenter, body.page-event img.aligncenter, body.page-tags img.aligncenter, body.page-node-29 img.aligncenter, body.page-node-34 img.aligncenter, body.page-content img.aligncenter {
  clear: both;
  display: block;
  margin-left: auto;
  margin-right: auto;
}

body.page-news .wp-caption p.wp-caption-text, body.node-type-tep-blogs .wp-caption p.wp-caption-text, body.page-guides .wp-caption p.wp-caption-text, body.page-edgeproptv .wp-caption p.wp-caption-text, body.page-pullout .wp-caption p.wp-caption-text, body.page-events .wp-caption p.wp-caption-text, body.page-event .wp-caption p.wp-caption-text, body.page-tags .wp-caption p.wp-caption-text, body.page-node-29 .wp-caption p.wp-caption-text, body.page-node-34 .wp-caption p.wp-caption-text, body.page-content .wp-caption p.wp-caption-text {
  font-style: italic;
  font-size: 14px;
  line-height: 28px;
}

body.page-news .alignnone, body.page-news img.alignnone, body.page-news .wp-caption, body.page-news .wp-caption img, body.page-news .wp-smiley, body.page-news .gallery dl,
body.page-news .gallery dt, body.page-news .gallery dd, body.page-news .gallery dl a, body.page-news .gallery dl img, body.page-news .size-full, body.page-news .size-large, body.page-news .size-medium,
body.page-news .size-thumbnail, body.page-news .bypostauthor, body.page-news .screen-reader-text:focus, body.node-type-tep-blogs .alignnone, body.node-type-tep-blogs img.alignnone, body.node-type-tep-blogs .wp-caption, body.node-type-tep-blogs .wp-caption img, body.node-type-tep-blogs .wp-smiley, body.node-type-tep-blogs .gallery dl,
body.node-type-tep-blogs .gallery dt, body.node-type-tep-blogs .gallery dd, body.node-type-tep-blogs .gallery dl a, body.node-type-tep-blogs .gallery dl img, body.node-type-tep-blogs .size-full, body.node-type-tep-blogs .size-large, body.node-type-tep-blogs .size-medium,
body.node-type-tep-blogs .size-thumbnail, body.node-type-tep-blogs .bypostauthor, body.node-type-tep-blogs .screen-reader-text:focus, body.page-guides .alignnone, body.page-guides img.alignnone, body.page-guides .wp-caption, body.page-guides .wp-caption img, body.page-guides .wp-smiley, body.page-guides .gallery dl,
body.page-guides .gallery dt, body.page-guides .gallery dd, body.page-guides .gallery dl a, body.page-guides .gallery dl img, body.page-guides .size-full, body.page-guides .size-large, body.page-guides .size-medium,
body.page-guides .size-thumbnail, body.page-guides .bypostauthor, body.page-guides .screen-reader-text:focus, body.page-edgeproptv .alignnone, body.page-edgeproptv img.alignnone, body.page-edgeproptv .wp-caption, body.page-edgeproptv .wp-caption img, body.page-edgeproptv .wp-smiley, body.page-edgeproptv .gallery dl,
body.page-edgeproptv .gallery dt, body.page-edgeproptv .gallery dd, body.page-edgeproptv .gallery dl a, body.page-edgeproptv .gallery dl img, body.page-edgeproptv .size-full, body.page-edgeproptv .size-large, body.page-edgeproptv .size-medium,
body.page-edgeproptv .size-thumbnail, body.page-edgeproptv .bypostauthor, body.page-edgeproptv .screen-reader-text:focus, body.page-pullout .alignnone, body.page-pullout img.alignnone, body.page-pullout .wp-caption, body.page-pullout .wp-caption img, body.page-pullout .wp-smiley, body.page-pullout .gallery dl,
body.page-pullout .gallery dt, body.page-pullout .gallery dd, body.page-pullout .gallery dl a, body.page-pullout .gallery dl img, body.page-pullout .size-full, body.page-pullout .size-large, body.page-pullout .size-medium,
body.page-pullout .size-thumbnail, body.page-pullout .bypostauthor, body.page-pullout .screen-reader-text:focus, body.page-events .alignnone, body.page-events img.alignnone, body.page-events .wp-caption, body.page-events .wp-caption img, body.page-events .wp-smiley, body.page-events .gallery dl,
body.page-events .gallery dt, body.page-events .gallery dd, body.page-events .gallery dl a, body.page-events .gallery dl img, body.page-events .size-full, body.page-events .size-large, body.page-events .size-medium,
body.page-events .size-thumbnail, body.page-events .bypostauthor, body.page-events .screen-reader-text:focus, body.page-event .alignnone, body.page-event img.alignnone, body.page-event .wp-caption, body.page-event .wp-caption img, body.page-event .wp-smiley, body.page-event .gallery dl,
body.page-event .gallery dt, body.page-event .gallery dd, body.page-event .gallery dl a, body.page-event .gallery dl img, body.page-event .size-full, body.page-event .size-large, body.page-event .size-medium,
body.page-event .size-thumbnail, body.page-event .bypostauthor, body.page-event .screen-reader-text:focus, body.page-tags .alignnone, body.page-tags img.alignnone, body.page-tags .wp-caption, body.page-tags .wp-caption img, body.page-tags .wp-smiley, body.page-tags .gallery dl,
body.page-tags .gallery dt, body.page-tags .gallery dd, body.page-tags .gallery dl a, body.page-tags .gallery dl img, body.page-tags .size-full, body.page-tags .size-large, body.page-tags .size-medium,
body.page-tags .size-thumbnail, body.page-tags .bypostauthor, body.page-tags .screen-reader-text:focus, body.page-node-29 .alignnone, body.page-node-29 img.alignnone, body.page-node-29 .wp-caption, body.page-node-29 .wp-caption img, body.page-node-29 .wp-smiley, body.page-node-29 .gallery dl,
body.page-node-29 .gallery dt, body.page-node-29 .gallery dd, body.page-node-29 .gallery dl a, body.page-node-29 .gallery dl img, body.page-node-29 .size-full, body.page-node-29 .size-large, body.page-node-29 .size-medium,
body.page-node-29 .size-thumbnail, body.page-node-29 .bypostauthor, body.page-node-29 .screen-reader-text:focus, body.page-node-34 .alignnone, body.page-node-34 img.alignnone, body.page-node-34 .wp-caption, body.page-node-34 .wp-caption img, body.page-node-34 .wp-smiley, body.page-node-34 .gallery dl,
body.page-node-34 .gallery dt, body.page-node-34 .gallery dd, body.page-node-34 .gallery dl a, body.page-node-34 .gallery dl img, body.page-node-34 .size-full, body.page-node-34 .size-large, body.page-node-34 .size-medium,
body.page-node-34 .size-thumbnail, body.page-node-34 .bypostauthor, body.page-node-34 .screen-reader-text:focus, body.page-content .alignnone, body.page-content img.alignnone, body.page-content .wp-caption, body.page-content .wp-caption img, body.page-content .wp-smiley, body.page-content .gallery dl,
body.page-content .gallery dt, body.page-content .gallery dd, body.page-content .gallery dl a, body.page-content .gallery dl img, body.page-content .size-full, body.page-content .size-large, body.page-content .size-medium,
body.page-content .size-thumbnail, body.page-content .bypostauthor, body.page-content .screen-reader-text:focus {
  position: initial;
}

body.page-news .gallery-caption, body.node-type-tep-blogs .gallery-caption, body.page-guides .gallery-caption, body.page-edgeproptv .gallery-caption, body.page-pullout .gallery-caption, body.page-events .gallery-caption, body.page-event .gallery-caption, body.page-tags .gallery-caption, body.page-node-29 .gallery-caption, body.page-node-34 .gallery-caption, body.page-content .gallery-caption {
  font-size: 16px;
  line-height: 24px;
  padding-top: 10px;
}

body.page-news .sticky, body.node-type-tep-blogs .sticky, body.page-guides .sticky, body.page-edgeproptv .sticky, body.page-pullout .sticky, body.page-events .sticky, body.page-event .sticky, body.page-tags .sticky, body.page-node-29 .sticky, body.page-node-34 .sticky, body.page-content .sticky {
  padding: 24px;
  background-color: #e6e6e6;
}

body.page-news .post-layout .sticky .entry-thumb, body.node-type-tep-blogs .post-layout .sticky .entry-thumb, body.page-guides .post-layout .sticky .entry-thumb, body.page-edgeproptv .post-layout .sticky .entry-thumb, body.page-pullout .post-layout .sticky .entry-thumb, body.page-events .post-layout .sticky .entry-thumb, body.page-event .post-layout .sticky .entry-thumb, body.page-tags .post-layout .sticky .entry-thumb, body.page-node-29 .post-layout .sticky .entry-thumb, body.page-node-34 .post-layout .sticky .entry-thumb, body.page-content .post-layout .sticky .entry-thumb {
  margin-bottom: 0;
}

body.page-news .box-thumb, body.node-type-tep-blogs .box-thumb, body.page-guides .box-thumb, body.page-edgeproptv .box-thumb, body.page-pullout .box-thumb, body.page-events .box-thumb, body.page-event .box-thumb, body.page-tags .box-thumb, body.page-node-29 .box-thumb, body.page-node-34 .box-thumb, body.page-content .box-thumb {
  float: left;
  position: relative;
}

body.page-news .e-right, body.node-type-tep-blogs .e-right, body.page-guides .e-right, body.page-edgeproptv .e-right, body.page-pullout .e-right, body.page-events .e-right, body.page-event .e-right, body.page-tags .e-right, body.page-node-29 .e-right, body.page-node-34 .e-right, body.page-content .e-right {
  float: right;
}

body.page-news pre, body.node-type-tep-blogs pre, body.page-guides pre, body.page-edgeproptv pre, body.page-pullout pre, body.page-events pre, body.page-event pre, body.page-tags pre, body.page-node-29 pre, body.page-node-34 pre, body.page-content pre {
  padding: 12px;
}

body.page-news #background, body.node-type-tep-blogs #background, body.page-guides #background, body.page-edgeproptv #background, body.page-pullout #background, body.page-events #background, body.page-event #background, body.page-tags #background, body.page-node-29 #background, body.page-node-34 #background, body.page-content #background {
  position: relative;
  z-index: 1;
  line-height: 20px;
  font-size: 14px;
  overflow: hidden;
  width: 100%;
}

body.page-news #page, body.node-type-tep-blogs #page, body.page-guides #page, body.page-edgeproptv #page, body.page-pullout #page, body.page-events #page, body.page-event #page, body.page-tags #page, body.page-node-29 #page, body.page-node-34 #page, body.page-content #page {
  background-color: #fff;
  margin: auto;
  position: relative;
}

body.page-news #page.layout-boxed, body.node-type-tep-blogs #page.layout-boxed, body.page-guides #page.layout-boxed, body.page-edgeproptv #page.layout-boxed, body.page-pullout #page.layout-boxed, body.page-events #page.layout-boxed, body.page-event #page.layout-boxed, body.page-tags #page.layout-boxed, body.page-node-29 #page.layout-boxed, body.page-node-34 #page.layout-boxed, body.page-content #page.layout-boxed {
  padding: 0px 0px 0px 0px;
  margin-top: 30px;
  margin-bottom: 30px;
  width: 1280px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
}

body.page-news .text-404, body.node-type-tep-blogs .text-404, body.page-guides .text-404, body.page-edgeproptv .text-404, body.page-pullout .text-404, body.page-events .text-404, body.page-event .text-404, body.page-tags .text-404, body.page-node-29 .text-404, body.page-node-34 .text-404, body.page-content .text-404 {
  text-align: center;
  font-size: 150px;
  line-height: 204px;
  font-weight: 400;
  text-transform: uppercase;
}

body.page-news .page-heading, body.node-type-tep-blogs .page-heading, body.page-guides .page-heading, body.page-edgeproptv .page-heading, body.page-pullout .page-heading, body.page-events .page-heading, body.page-event .page-heading, body.page-tags .page-heading, body.page-node-29 .page-heading, body.page-node-34 .page-heading, body.page-content .page-heading {
  font-size: 36px;
  line-height: 48px;
  font-weight: bold;
  color: #1a1a1a;
  padding: 24px 0 46px 0;
  margin: 0;
}

body.page-news .error404 .page-heading, body.node-type-tep-blogs .error404 .page-heading, body.page-guides .error404 .page-heading, body.page-edgeproptv .error404 .page-heading, body.page-pullout .error404 .page-heading, body.page-events .error404 .page-heading, body.page-event .error404 .page-heading, body.page-tags .error404 .page-heading, body.page-node-29 .error404 .page-heading, body.page-node-34 .error404 .page-heading, body.page-content .error404 .page-heading {
  text-align: center;
}

body.page-news .error404 .main-content, body.node-type-tep-blogs .error404 .main-content, body.page-guides .error404 .main-content, body.page-edgeproptv .error404 .main-content, body.page-pullout .error404 .main-content, body.page-events .error404 .main-content, body.page-event .error404 .main-content, body.page-tags .error404 .main-content, body.page-node-29 .error404 .main-content, body.page-node-34 .error404 .main-content, body.page-content .error404 .main-content {
  padding: 100px 0;
}

body.page-news .content ul, body.page-news .content ol, body.node-type-tep-blogs .content ul, body.node-type-tep-blogs .content ol, body.page-guides .content ul, body.page-guides .content ol, body.page-edgeproptv .content ul, body.page-edgeproptv .content ol, body.page-pullout .content ul, body.page-pullout .content ol, body.page-events .content ul, body.page-events .content ol, body.page-event .content ul, body.page-event .content ol, body.page-tags .content ul, body.page-tags .content ol, body.page-node-29 .content ul, body.page-node-29 .content ol, body.page-node-34 .content ul, body.page-node-34 .content ol, body.page-content .content ul, body.page-content .content ol {
  list-style-type: disc;
  padding-left: 30px;
  margin-bottom: 22px;
}

body.page-news .content ul li, body.page-news .content ol li, body.node-type-tep-blogs .content ul li, body.node-type-tep-blogs .content ol li, body.page-guides .content ul li, body.page-guides .content ol li, body.page-edgeproptv .content ul li, body.page-edgeproptv .content ol li, body.page-pullout .content ul li, body.page-pullout .content ol li, body.page-events .content ul li, body.page-events .content ol li, body.page-event .content ul li, body.page-event .content ol li, body.page-tags .content ul li, body.page-tags .content ol li, body.page-node-29 .content ul li, body.page-node-29 .content ol li, body.page-node-34 .content ul li, body.page-node-34 .content ol li, body.page-content .content ul li, body.page-content .content ol li {
  margin-bottom: 14px;
}

body.page-news .content ul li:last-of-type, body.page-news .content ol li:last-of-type, body.node-type-tep-blogs .content ul li:last-of-type, body.node-type-tep-blogs .content ol li:last-of-type, body.page-guides .content ul li:last-of-type, body.page-guides .content ol li:last-of-type, body.page-edgeproptv .content ul li:last-of-type, body.page-edgeproptv .content ol li:last-of-type, body.page-pullout .content ul li:last-of-type, body.page-pullout .content ol li:last-of-type, body.page-events .content ul li:last-of-type, body.page-events .content ol li:last-of-type, body.page-event .content ul li:last-of-type, body.page-event .content ol li:last-of-type, body.page-tags .content ul li:last-of-type, body.page-tags .content ol li:last-of-type, body.page-node-29 .content ul li:last-of-type, body.page-node-29 .content ol li:last-of-type, body.page-node-34 .content ul li:last-of-type, body.page-node-34 .content ol li:last-of-type, body.page-content .content ul li:last-of-type, body.page-content .content ol li:last-of-type {
  margin-bottom: 0;
}

body.page-news .content ul a, body.page-news .content ol a, body.node-type-tep-blogs .content ul a, body.node-type-tep-blogs .content ol a, body.page-guides .content ul a, body.page-guides .content ol a, body.page-edgeproptv .content ul a, body.page-edgeproptv .content ol a, body.page-pullout .content ul a, body.page-pullout .content ol a, body.page-events .content ul a, body.page-events .content ol a, body.page-event .content ul a, body.page-event .content ol a, body.page-tags .content ul a, body.page-tags .content ol a, body.page-node-29 .content ul a, body.page-node-29 .content ol a, body.page-node-34 .content ul a, body.page-node-34 .content ol a, body.page-content .content ul a, body.page-content .content ol a {
  color: #488BF8;
}

body.page-news .content .menu, body.node-type-tep-blogs .content .menu, body.page-guides .content .menu, body.page-edgeproptv .content .menu, body.page-pullout .content .menu, body.page-events .content .menu, body.page-event .content .menu, body.page-tags .content .menu, body.page-node-29 .content .menu, body.page-node-34 .content .menu, body.page-content .content .menu {
  list-style-type: none;
  padding-left: 0;
  margin-bottom: 0;
}

body.page-news .content blockquote p, body.node-type-tep-blogs .content blockquote p, body.page-guides .content blockquote p, body.page-edgeproptv .content blockquote p, body.page-pullout .content blockquote p, body.page-events .content blockquote p, body.page-event .content blockquote p, body.page-tags .content blockquote p, body.page-node-29 .content blockquote p, body.page-node-34 .content blockquote p, body.page-content .content blockquote p {
  position: relative;
  font-size: 18px;
  font-weight: bold;
  line-height: 28px;
  color: #1a1a1a;
  padding-bottom: 0;
}

body.page-news .content blockquote:before, body.node-type-tep-blogs .content blockquote:before, body.page-guides .content blockquote:before, body.page-edgeproptv .content blockquote:before, body.page-pullout .content blockquote:before, body.page-events .content blockquote:before, body.page-event .content blockquote:before, body.page-tags .content blockquote:before, body.page-node-29 .content blockquote:before, body.page-node-34 .content blockquote:before, body.page-content .content blockquote:before {
  content: '\201C';
  position: absolute;
  top: 68px;
  left: 30px;
  font-size: 150.1px;
  line-height: 28px;
  font-weight: normal;
  color: #ccc;
}

body.page-news .content h1, body.page-news .content h2, body.page-news .content h3,
body.page-news .content h4, body.page-news .content h5, body.page-news .content h6, body.node-type-tep-blogs .content h1, body.node-type-tep-blogs .content h2, body.node-type-tep-blogs .content h3,
body.node-type-tep-blogs .content h4, body.node-type-tep-blogs .content h5, body.node-type-tep-blogs .content h6, body.page-guides .content h1, body.page-guides .content h2, body.page-guides .content h3,
body.page-guides .content h4, body.page-guides .content h5, body.page-guides .content h6, body.page-edgeproptv .content h1, body.page-edgeproptv .content h2, body.page-edgeproptv .content h3,
body.page-edgeproptv .content h4, body.page-edgeproptv .content h5, body.page-edgeproptv .content h6, body.page-pullout .content h1, body.page-pullout .content h2, body.page-pullout .content h3,
body.page-pullout .content h4, body.page-pullout .content h5, body.page-pullout .content h6, body.page-events .content h1, body.page-events .content h2, body.page-events .content h3,
body.page-events .content h4, body.page-events .content h5, body.page-events .content h6, body.page-event .content h1, body.page-event .content h2, body.page-event .content h3,
body.page-event .content h4, body.page-event .content h5, body.page-event .content h6, body.page-tags .content h1, body.page-tags .content h2, body.page-tags .content h3,
body.page-tags .content h4, body.page-tags .content h5, body.page-tags .content h6, body.page-node-29 .content h1, body.page-node-29 .content h2, body.page-node-29 .content h3,
body.page-node-29 .content h4, body.page-node-29 .content h5, body.page-node-29 .content h6, body.page-node-34 .content h1, body.page-node-34 .content h2, body.page-node-34 .content h3,
body.page-node-34 .content h4, body.page-node-34 .content h5, body.page-node-34 .content h6, body.page-content .content h1, body.page-content .content h2, body.page-content .content h3,
body.page-content .content h4, body.page-content .content h5, body.page-content .content h6 {
  padding: 18px 0px 28px 0px;
}

body.page-news .content .entry-title, body.node-type-tep-blogs .content .entry-title, body.page-guides .content .entry-title, body.page-edgeproptv .content .entry-title, body.page-pullout .content .entry-title, body.page-events .content .entry-title, body.page-event .content .entry-title, body.page-tags .content .entry-title, body.page-node-29 .content .entry-title, body.page-node-34 .content .entry-title, body.page-content .content .entry-title {
  padding: 0;
}

body.page-news .content ol, body.node-type-tep-blogs .content ol, body.page-guides .content ol, body.page-edgeproptv .content ol, body.page-pullout .content ol, body.page-events .content ol, body.page-event .content ol, body.page-tags .content ol, body.page-node-29 .content ol, body.page-node-34 .content ol, body.page-content .content ol {
  list-style-type: decimal;
  padding: 2px 0px 6px 30px;
}

@media (max-width: 991px) {
  body.page-news .content ol, body.node-type-tep-blogs .content ol, body.page-guides .content ol, body.page-edgeproptv .content ol, body.page-pullout .content ol, body.page-events .content ol, body.page-event .content ol, body.page-tags .content ol, body.page-node-29 .content ol, body.page-node-34 .content ol, body.page-content .content ol {
    padding: 2px 0px 6px 38px;
  }
}

@media (max-width: 991px) {
  body.page-news .content ul, body.node-type-tep-blogs .content ul, body.page-guides .content ul, body.page-edgeproptv .content ul, body.page-pullout .content ul, body.page-events .content ul, body.page-event .content ul, body.page-tags .content ul, body.page-node-29 .content ul, body.page-node-34 .content ul, body.page-content .content ul {
    padding: 2px 0px 6px 38px;
  }
}

body.page-news .wp-caption-text, body.node-type-tep-blogs .wp-caption-text, body.page-guides .wp-caption-text, body.page-edgeproptv .wp-caption-text, body.page-pullout .wp-caption-text, body.page-events .wp-caption-text, body.page-event .wp-caption-text, body.page-tags .wp-caption-text, body.page-node-29 .wp-caption-text, body.page-node-34 .wp-caption-text, body.page-content .wp-caption-text {
  font-size: 14px;
  line-height: 28px;
  font-style: italic;
}

body.page-news blockquote, body.node-type-tep-blogs blockquote, body.page-guides blockquote, body.page-edgeproptv blockquote, body.page-pullout blockquote, body.page-events blockquote, body.page-event blockquote, body.page-tags blockquote, body.page-node-29 blockquote, body.page-node-34 blockquote, body.page-content blockquote {
  position: relative;
  margin: 0px 50px 30px;
  background-color: #f2f2f2;
  padding: 32px 50px;
}

body.page-news .entry-title a:hover, body.node-type-tep-blogs .entry-title a:hover, body.page-guides .entry-title a:hover, body.page-edgeproptv .entry-title a:hover, body.page-pullout .entry-title a:hover, body.page-events .entry-title a:hover, body.page-event .entry-title a:hover, body.page-tags .entry-title a:hover, body.page-node-29 .entry-title a:hover, body.page-node-34 .entry-title a:hover, body.page-content .entry-title a:hover {
  color: #808080;
  text-decoration: none;
}

body.page-news .top1-entry-post-share, body.node-type-tep-blogs .top1-entry-post-share, body.page-guides .top1-entry-post-share, body.page-edgeproptv .top1-entry-post-share, body.page-pullout .top1-entry-post-share, body.page-events .top1-entry-post-share, body.page-event .top1-entry-post-share, body.page-tags .top1-entry-post-share, body.page-node-29 .top1-entry-post-share, body.page-node-34 .top1-entry-post-share, body.page-content .top1-entry-post-share {
  margin-top: 10px;
  margin-bottom: 20px;
}

body.page-news .top2-entry-post-share, body.node-type-tep-blogs .top2-entry-post-share, body.page-guides .top2-entry-post-share, body.page-edgeproptv .top2-entry-post-share, body.page-pullout .top2-entry-post-share, body.page-events .top2-entry-post-share, body.page-event .top2-entry-post-share, body.page-tags .top2-entry-post-share, body.page-node-29 .top2-entry-post-share, body.page-node-34 .top2-entry-post-share, body.page-content .top2-entry-post-share {
  margin-bottom: 13px;
}

@media (min-width: 768px) {
  body.page-news .top2-entry-post-share, body.node-type-tep-blogs .top2-entry-post-share, body.page-guides .top2-entry-post-share, body.page-edgeproptv .top2-entry-post-share, body.page-pullout .top2-entry-post-share, body.page-events .top2-entry-post-share, body.page-event .top2-entry-post-share, body.page-tags .top2-entry-post-share, body.page-node-29 .top2-entry-post-share, body.page-node-34 .top2-entry-post-share, body.page-content .top2-entry-post-share {
    margin-bottom: 0;
  }
}

body.page-news .bottom-entry-post-share, body.node-type-tep-blogs .bottom-entry-post-share, body.page-guides .bottom-entry-post-share, body.page-edgeproptv .bottom-entry-post-share, body.page-pullout .bottom-entry-post-share, body.page-events .bottom-entry-post-share, body.page-event .bottom-entry-post-share, body.page-tags .bottom-entry-post-share, body.page-node-29 .bottom-entry-post-share, body.page-node-34 .bottom-entry-post-share, body.page-content .bottom-entry-post-share {
  display: none;
  padding: 12px 28px;
  padding-bottom: 0;
  border: 1px solid #1a1a1a;
  margin-bottom: 50px;
}

body.page-news .bottom-entry-post-share span, body.node-type-tep-blogs .bottom-entry-post-share span, body.page-guides .bottom-entry-post-share span, body.page-edgeproptv .bottom-entry-post-share span, body.page-pullout .bottom-entry-post-share span, body.page-events .bottom-entry-post-share span, body.page-event .bottom-entry-post-share span, body.page-tags .bottom-entry-post-share span, body.page-node-29 .bottom-entry-post-share span, body.page-node-34 .bottom-entry-post-share span, body.page-content .bottom-entry-post-share span {
  font-size: 18px;
  font-weight: bold;
  letter-spacing: 1px;
  line-height: 24px;
  color: #1a1a1a;
  margin-right: 20px;
}

body.page-news .wope-social-share, body.node-type-tep-blogs .wope-social-share, body.page-guides .wope-social-share, body.page-edgeproptv .wope-social-share, body.page-pullout .wope-social-share, body.page-events .wope-social-share, body.page-event .wope-social-share, body.page-tags .wope-social-share, body.page-node-29 .wope-social-share, body.page-node-34 .wope-social-share, body.page-content .wope-social-share {
  display: inline-block;
}

body.page-news .wope-social-share a, body.node-type-tep-blogs .wope-social-share a, body.page-guides .wope-social-share a, body.page-edgeproptv .wope-social-share a, body.page-pullout .wope-social-share a, body.page-events .wope-social-share a, body.page-event .wope-social-share a, body.page-tags .wope-social-share a, body.page-node-29 .wope-social-share a, body.page-node-34 .wope-social-share a, body.page-content .wope-social-share a {
  font-size: 14px;
  line-height: 18px;
  padding: 8.5px 12px;
  margin-bottom: 12px;
  color: #fff;
  margin-right: 8px;
  text-align: center;
  /* Safari 3-4, iOS 1-3.2, Android 1.6- */
  -webkit-border-radius: 3px;
  /* Firefox 1-3.6 */
  -moz-border-radius: 3px;
  /* Opera 10.5, IE 9, Safari 5, Chrome, Firefox 4, iOS 4, Android 2.1+ */
  border-radius: 3px;
}

body.page-news .wope-social-share a:hover, body.node-type-tep-blogs .wope-social-share a:hover, body.page-guides .wope-social-share a:hover, body.page-edgeproptv .wope-social-share a:hover, body.page-pullout .wope-social-share a:hover, body.page-events .wope-social-share a:hover, body.page-event .wope-social-share a:hover, body.page-tags .wope-social-share a:hover, body.page-node-29 .wope-social-share a:hover, body.page-node-34 .wope-social-share a:hover, body.page-content .wope-social-share a:hover {
  text-decoration: none;
}

body.page-news .wope-social-share a:last-child, body.node-type-tep-blogs .wope-social-share a:last-child, body.page-guides .wope-social-share a:last-child, body.page-edgeproptv .wope-social-share a:last-child, body.page-pullout .wope-social-share a:last-child, body.page-events .wope-social-share a:last-child, body.page-event .wope-social-share a:last-child, body.page-tags .wope-social-share a:last-child, body.page-node-29 .wope-social-share a:last-child, body.page-node-34 .wope-social-share a:last-child, body.page-content .wope-social-share a:last-child {
  margin-right: 0;
}

body.page-news .wope-social-share a i, body.node-type-tep-blogs .wope-social-share a i, body.page-guides .wope-social-share a i, body.page-edgeproptv .wope-social-share a i, body.page-pullout .wope-social-share a i, body.page-events .wope-social-share a i, body.page-event .wope-social-share a i, body.page-tags .wope-social-share a i, body.page-node-29 .wope-social-share a i, body.page-node-34 .wope-social-share a i, body.page-content .wope-social-share a i {
  font-size: 15px;
  line-height: 18px;
}

body.page-news .wope-social-share.theme_2022 .round-i-media, body.node-type-tep-blogs .wope-social-share.theme_2022 .round-i-media, body.page-guides .wope-social-share.theme_2022 .round-i-media, body.page-edgeproptv .wope-social-share.theme_2022 .round-i-media, body.page-pullout .wope-social-share.theme_2022 .round-i-media, body.page-events .wope-social-share.theme_2022 .round-i-media, body.page-event .wope-social-share.theme_2022 .round-i-media, body.page-tags .wope-social-share.theme_2022 .round-i-media, body.page-node-29 .wope-social-share.theme_2022 .round-i-media, body.page-node-34 .wope-social-share.theme_2022 .round-i-media, body.page-content .wope-social-share.theme_2022 .round-i-media {
  border: 1px solid #666;
  border-radius: 50%;
  height: 33px;
  width: 33px;
  padding: 8px;
  line-height: 1.5;
}

body.page-news .wope-social-share.theme_2022 .round-i-media span, body.node-type-tep-blogs .wope-social-share.theme_2022 .round-i-media span, body.page-guides .wope-social-share.theme_2022 .round-i-media span, body.page-edgeproptv .wope-social-share.theme_2022 .round-i-media span, body.page-pullout .wope-social-share.theme_2022 .round-i-media span, body.page-events .wope-social-share.theme_2022 .round-i-media span, body.page-event .wope-social-share.theme_2022 .round-i-media span, body.page-tags .wope-social-share.theme_2022 .round-i-media span, body.page-node-29 .wope-social-share.theme_2022 .round-i-media span, body.page-node-34 .wope-social-share.theme_2022 .round-i-media span, body.page-content .wope-social-share.theme_2022 .round-i-media span {
  height: 16px;
  width: 16px;
}

body.page-news .wope-social-share.theme_2022 .round-i-media span.fb, body.node-type-tep-blogs .wope-social-share.theme_2022 .round-i-media span.fb, body.page-guides .wope-social-share.theme_2022 .round-i-media span.fb, body.page-edgeproptv .wope-social-share.theme_2022 .round-i-media span.fb, body.page-pullout .wope-social-share.theme_2022 .round-i-media span.fb, body.page-events .wope-social-share.theme_2022 .round-i-media span.fb, body.page-event .wope-social-share.theme_2022 .round-i-media span.fb, body.page-tags .wope-social-share.theme_2022 .round-i-media span.fb, body.page-node-29 .wope-social-share.theme_2022 .round-i-media span.fb, body.page-node-34 .wope-social-share.theme_2022 .round-i-media span.fb, body.page-content .wope-social-share.theme_2022 .round-i-media span.fb {
  background: url("https://media.edgeprop.my/icon/social-media/fb.png") center;
}

body.page-news .wope-social-share.theme_2022 .round-i-media span.twitter, body.node-type-tep-blogs .wope-social-share.theme_2022 .round-i-media span.twitter, body.page-guides .wope-social-share.theme_2022 .round-i-media span.twitter, body.page-edgeproptv .wope-social-share.theme_2022 .round-i-media span.twitter, body.page-pullout .wope-social-share.theme_2022 .round-i-media span.twitter, body.page-events .wope-social-share.theme_2022 .round-i-media span.twitter, body.page-event .wope-social-share.theme_2022 .round-i-media span.twitter, body.page-tags .wope-social-share.theme_2022 .round-i-media span.twitter, body.page-node-29 .wope-social-share.theme_2022 .round-i-media span.twitter, body.page-node-34 .wope-social-share.theme_2022 .round-i-media span.twitter, body.page-content .wope-social-share.theme_2022 .round-i-media span.twitter {
  background: url("https://media.edgeprop.my/icon/social-media/twitter.png") center;
}

body.page-news .wope-social-share.theme_2022 .round-i-media span.whatsapp, body.node-type-tep-blogs .wope-social-share.theme_2022 .round-i-media span.whatsapp, body.page-guides .wope-social-share.theme_2022 .round-i-media span.whatsapp, body.page-edgeproptv .wope-social-share.theme_2022 .round-i-media span.whatsapp, body.page-pullout .wope-social-share.theme_2022 .round-i-media span.whatsapp, body.page-events .wope-social-share.theme_2022 .round-i-media span.whatsapp, body.page-event .wope-social-share.theme_2022 .round-i-media span.whatsapp, body.page-tags .wope-social-share.theme_2022 .round-i-media span.whatsapp, body.page-node-29 .wope-social-share.theme_2022 .round-i-media span.whatsapp, body.page-node-34 .wope-social-share.theme_2022 .round-i-media span.whatsapp, body.page-content .wope-social-share.theme_2022 .round-i-media span.whatsapp {
  background: url("https://media.edgeprop.my/icon/social-media/wa.png") center;
}

body.page-news .wope-social-share.theme_2022 .round-i-media span.pinterest, body.node-type-tep-blogs .wope-social-share.theme_2022 .round-i-media span.pinterest, body.page-guides .wope-social-share.theme_2022 .round-i-media span.pinterest, body.page-edgeproptv .wope-social-share.theme_2022 .round-i-media span.pinterest, body.page-pullout .wope-social-share.theme_2022 .round-i-media span.pinterest, body.page-events .wope-social-share.theme_2022 .round-i-media span.pinterest, body.page-event .wope-social-share.theme_2022 .round-i-media span.pinterest, body.page-tags .wope-social-share.theme_2022 .round-i-media span.pinterest, body.page-node-29 .wope-social-share.theme_2022 .round-i-media span.pinterest, body.page-node-34 .wope-social-share.theme_2022 .round-i-media span.pinterest, body.page-content .wope-social-share.theme_2022 .round-i-media span.pinterest {
  background: url("https://media.edgeprop.my/icon/social-media/pin.png") center;
}

body.page-news .wope-social-share.theme_2022 .round-i-media span.linkedin, body.node-type-tep-blogs .wope-social-share.theme_2022 .round-i-media span.linkedin, body.page-guides .wope-social-share.theme_2022 .round-i-media span.linkedin, body.page-edgeproptv .wope-social-share.theme_2022 .round-i-media span.linkedin, body.page-pullout .wope-social-share.theme_2022 .round-i-media span.linkedin, body.page-events .wope-social-share.theme_2022 .round-i-media span.linkedin, body.page-event .wope-social-share.theme_2022 .round-i-media span.linkedin, body.page-tags .wope-social-share.theme_2022 .round-i-media span.linkedin, body.page-node-29 .wope-social-share.theme_2022 .round-i-media span.linkedin, body.page-node-34 .wope-social-share.theme_2022 .round-i-media span.linkedin, body.page-content .wope-social-share.theme_2022 .round-i-media span.linkedin {
  background: url("https://media.edgeprop.my/icon/social-media/in.png") center;
}

body.page-news .wope-social-share.theme_2022 .round-i-media span.email, body.node-type-tep-blogs .wope-social-share.theme_2022 .round-i-media span.email, body.page-guides .wope-social-share.theme_2022 .round-i-media span.email, body.page-edgeproptv .wope-social-share.theme_2022 .round-i-media span.email, body.page-pullout .wope-social-share.theme_2022 .round-i-media span.email, body.page-events .wope-social-share.theme_2022 .round-i-media span.email, body.page-event .wope-social-share.theme_2022 .round-i-media span.email, body.page-tags .wope-social-share.theme_2022 .round-i-media span.email, body.page-node-29 .wope-social-share.theme_2022 .round-i-media span.email, body.page-node-34 .wope-social-share.theme_2022 .round-i-media span.email, body.page-content .wope-social-share.theme_2022 .round-i-media span.email {
  background: url("https://media.edgeprop.my/icon/social-media/mail.png") center;
}

body.page-news .wope-social-share.theme_2022 .round-i-media span.print, body.node-type-tep-blogs .wope-social-share.theme_2022 .round-i-media span.print, body.page-guides .wope-social-share.theme_2022 .round-i-media span.print, body.page-edgeproptv .wope-social-share.theme_2022 .round-i-media span.print, body.page-pullout .wope-social-share.theme_2022 .round-i-media span.print, body.page-events .wope-social-share.theme_2022 .round-i-media span.print, body.page-event .wope-social-share.theme_2022 .round-i-media span.print, body.page-tags .wope-social-share.theme_2022 .round-i-media span.print, body.page-node-29 .wope-social-share.theme_2022 .round-i-media span.print, body.page-node-34 .wope-social-share.theme_2022 .round-i-media span.print, body.page-content .wope-social-share.theme_2022 .round-i-media span.print {
  background: url("https://media.edgeprop.my/icon/social-media/print.png") center;
}

body.page-news .wope-social-share.theme_2022 .round-i-media:hover, body.node-type-tep-blogs .wope-social-share.theme_2022 .round-i-media:hover, body.page-guides .wope-social-share.theme_2022 .round-i-media:hover, body.page-edgeproptv .wope-social-share.theme_2022 .round-i-media:hover, body.page-pullout .wope-social-share.theme_2022 .round-i-media:hover, body.page-events .wope-social-share.theme_2022 .round-i-media:hover, body.page-event .wope-social-share.theme_2022 .round-i-media:hover, body.page-tags .wope-social-share.theme_2022 .round-i-media:hover, body.page-node-29 .wope-social-share.theme_2022 .round-i-media:hover, body.page-node-34 .wope-social-share.theme_2022 .round-i-media:hover, body.page-content .wope-social-share.theme_2022 .round-i-media:hover {
  border-color: #096ffa;
}

body.page-news .fb-ss,
body.page-news .apsc-facebook-icon, body.node-type-tep-blogs .fb-ss,
body.node-type-tep-blogs .apsc-facebook-icon, body.page-guides .fb-ss,
body.page-guides .apsc-facebook-icon, body.page-edgeproptv .fb-ss,
body.page-edgeproptv .apsc-facebook-icon, body.page-pullout .fb-ss,
body.page-pullout .apsc-facebook-icon, body.page-events .fb-ss,
body.page-events .apsc-facebook-icon, body.page-event .fb-ss,
body.page-event .apsc-facebook-icon, body.page-tags .fb-ss,
body.page-tags .apsc-facebook-icon, body.page-node-29 .fb-ss,
body.page-node-29 .apsc-facebook-icon, body.page-node-34 .fb-ss,
body.page-node-34 .apsc-facebook-icon, body.page-content .fb-ss,
body.page-content .apsc-facebook-icon {
  background-color: #3b5998;
}

body.page-news .fb-ss:hover,
body.page-news .apsc-facebook-icon:hover, body.node-type-tep-blogs .fb-ss:hover,
body.node-type-tep-blogs .apsc-facebook-icon:hover, body.page-guides .fb-ss:hover,
body.page-guides .apsc-facebook-icon:hover, body.page-edgeproptv .fb-ss:hover,
body.page-edgeproptv .apsc-facebook-icon:hover, body.page-pullout .fb-ss:hover,
body.page-pullout .apsc-facebook-icon:hover, body.page-events .fb-ss:hover,
body.page-events .apsc-facebook-icon:hover, body.page-event .fb-ss:hover,
body.page-event .apsc-facebook-icon:hover, body.page-tags .fb-ss:hover,
body.page-tags .apsc-facebook-icon:hover, body.page-node-29 .fb-ss:hover,
body.page-node-29 .apsc-facebook-icon:hover, body.page-node-34 .fb-ss:hover,
body.page-node-34 .apsc-facebook-icon:hover, body.page-content .fb-ss:hover,
body.page-content .apsc-facebook-icon:hover {
  background-color: #6a7999;
}

body.page-news .i-ss, body.node-type-tep-blogs .i-ss, body.page-guides .i-ss, body.page-edgeproptv .i-ss, body.page-pullout .i-ss, body.page-events .i-ss, body.page-event .i-ss, body.page-tags .i-ss, body.page-node-29 .i-ss, body.page-node-34 .i-ss, body.page-content .i-ss {
  width: 37px;
}

body.page-news .fb-ss i, body.node-type-tep-blogs .fb-ss i, body.page-guides .fb-ss i, body.page-edgeproptv .fb-ss i, body.page-pullout .fb-ss i, body.page-events .fb-ss i, body.page-event .fb-ss i, body.page-tags .fb-ss i, body.page-node-29 .fb-ss i, body.page-node-34 .fb-ss i, body.page-content .fb-ss i {
  padding-right: 12px;
  border-right: 1px solid #4d69a2;
  margin-right: 11px;
}

body.page-news .twitter-ss,
body.page-news .apsc-twitter-icon, body.node-type-tep-blogs .twitter-ss,
body.node-type-tep-blogs .apsc-twitter-icon, body.page-guides .twitter-ss,
body.page-guides .apsc-twitter-icon, body.page-edgeproptv .twitter-ss,
body.page-edgeproptv .apsc-twitter-icon, body.page-pullout .twitter-ss,
body.page-pullout .apsc-twitter-icon, body.page-events .twitter-ss,
body.page-events .apsc-twitter-icon, body.page-event .twitter-ss,
body.page-event .apsc-twitter-icon, body.page-tags .twitter-ss,
body.page-tags .apsc-twitter-icon, body.page-node-29 .twitter-ss,
body.page-node-29 .apsc-twitter-icon, body.page-node-34 .twitter-ss,
body.page-node-34 .apsc-twitter-icon, body.page-content .twitter-ss,
body.page-content .apsc-twitter-icon {
  background-color: #1da1f2;
}

body.page-news .twitter-ss:hover,
body.page-news .apsc-twitter-icon:hover, body.node-type-tep-blogs .twitter-ss:hover,
body.node-type-tep-blogs .apsc-twitter-icon:hover, body.page-guides .twitter-ss:hover,
body.page-guides .apsc-twitter-icon:hover, body.page-edgeproptv .twitter-ss:hover,
body.page-edgeproptv .apsc-twitter-icon:hover, body.page-pullout .twitter-ss:hover,
body.page-pullout .apsc-twitter-icon:hover, body.page-events .twitter-ss:hover,
body.page-events .apsc-twitter-icon:hover, body.page-event .twitter-ss:hover,
body.page-event .apsc-twitter-icon:hover, body.page-tags .twitter-ss:hover,
body.page-tags .apsc-twitter-icon:hover, body.page-node-29 .twitter-ss:hover,
body.page-node-29 .apsc-twitter-icon:hover, body.page-node-34 .twitter-ss:hover,
body.page-node-34 .apsc-twitter-icon:hover, body.page-content .twitter-ss:hover,
body.page-content .apsc-twitter-icon:hover {
  background-color: #66bcf2;
}

body.page-news .twitter-ss i, body.node-type-tep-blogs .twitter-ss i, body.page-guides .twitter-ss i, body.page-edgeproptv .twitter-ss i, body.page-pullout .twitter-ss i, body.page-events .twitter-ss i, body.page-event .twitter-ss i, body.page-tags .twitter-ss i, body.page-node-29 .twitter-ss i, body.page-node-34 .twitter-ss i, body.page-content .twitter-ss i {
  padding-right: 12px;
  border-right: 1px solid #35abf4;
  margin-right: 11px;
}

body.page-news .ws-ss, body.node-type-tep-blogs .ws-ss, body.page-guides .ws-ss, body.page-edgeproptv .ws-ss, body.page-pullout .ws-ss, body.page-events .ws-ss, body.page-event .ws-ss, body.page-tags .ws-ss, body.page-node-29 .ws-ss, body.page-node-34 .ws-ss, body.page-content .ws-ss {
  background-color: #25d366;
}

body.page-news .ws-ss:hover, body.node-type-tep-blogs .ws-ss:hover, body.page-guides .ws-ss:hover, body.page-edgeproptv .ws-ss:hover, body.page-pullout .ws-ss:hover, body.page-events .ws-ss:hover, body.page-event .ws-ss:hover, body.page-tags .ws-ss:hover, body.page-node-29 .ws-ss:hover, body.page-node-34 .ws-ss:hover, body.page-content .ws-ss:hover {
  background-color: #57e28b;
}

body.page-news .gp-ss,
body.page-news .apsc-google-plus-icon, body.node-type-tep-blogs .gp-ss,
body.node-type-tep-blogs .apsc-google-plus-icon, body.page-guides .gp-ss,
body.page-guides .apsc-google-plus-icon, body.page-edgeproptv .gp-ss,
body.page-edgeproptv .apsc-google-plus-icon, body.page-pullout .gp-ss,
body.page-pullout .apsc-google-plus-icon, body.page-events .gp-ss,
body.page-events .apsc-google-plus-icon, body.page-event .gp-ss,
body.page-event .apsc-google-plus-icon, body.page-tags .gp-ss,
body.page-tags .apsc-google-plus-icon, body.page-node-29 .gp-ss,
body.page-node-29 .apsc-google-plus-icon, body.page-node-34 .gp-ss,
body.page-node-34 .apsc-google-plus-icon, body.page-content .gp-ss,
body.page-content .apsc-google-plus-icon {
  background-color: #dd4b39;
}

body.page-news .gp-ss:hover,
body.page-news .apsc-google-plus-icon:hover, body.node-type-tep-blogs .gp-ss:hover,
body.node-type-tep-blogs .apsc-google-plus-icon:hover, body.page-guides .gp-ss:hover,
body.page-guides .apsc-google-plus-icon:hover, body.page-edgeproptv .gp-ss:hover,
body.page-edgeproptv .apsc-google-plus-icon:hover, body.page-pullout .gp-ss:hover,
body.page-pullout .apsc-google-plus-icon:hover, body.page-events .gp-ss:hover,
body.page-events .apsc-google-plus-icon:hover, body.page-event .gp-ss:hover,
body.page-event .apsc-google-plus-icon:hover, body.page-tags .gp-ss:hover,
body.page-tags .apsc-google-plus-icon:hover, body.page-node-29 .gp-ss:hover,
body.page-node-29 .apsc-google-plus-icon:hover, body.page-node-34 .gp-ss:hover,
body.page-node-34 .apsc-google-plus-icon:hover, body.page-content .gp-ss:hover,
body.page-content .apsc-google-plus-icon:hover {
  background-color: #de887c;
}

body.page-news .pinterest-ss, body.node-type-tep-blogs .pinterest-ss, body.page-guides .pinterest-ss, body.page-edgeproptv .pinterest-ss, body.page-pullout .pinterest-ss, body.page-events .pinterest-ss, body.page-event .pinterest-ss, body.page-tags .pinterest-ss, body.page-node-29 .pinterest-ss, body.page-node-34 .pinterest-ss, body.page-content .pinterest-ss {
  background-color: #bd081c;
}

body.page-news .pinterest-ss:hover, body.node-type-tep-blogs .pinterest-ss:hover, body.page-guides .pinterest-ss:hover, body.page-edgeproptv .pinterest-ss:hover, body.page-pullout .pinterest-ss:hover, body.page-events .pinterest-ss:hover, body.page-event .pinterest-ss:hover, body.page-tags .pinterest-ss:hover, body.page-node-29 .pinterest-ss:hover, body.page-node-34 .pinterest-ss:hover, body.page-content .pinterest-ss:hover {
  background-color: #bd404f;
}

body.page-news .linkedin-ss, body.node-type-tep-blogs .linkedin-ss, body.page-guides .linkedin-ss, body.page-edgeproptv .linkedin-ss, body.page-pullout .linkedin-ss, body.page-events .linkedin-ss, body.page-event .linkedin-ss, body.page-tags .linkedin-ss, body.page-node-29 .linkedin-ss, body.page-node-34 .linkedin-ss, body.page-content .linkedin-ss {
  background-color: #0077b5;
}

body.page-news .tumblr-ss, body.node-type-tep-blogs .tumblr-ss, body.page-guides .tumblr-ss, body.page-edgeproptv .tumblr-ss, body.page-pullout .tumblr-ss, body.page-events .tumblr-ss, body.page-event .tumblr-ss, body.page-tags .tumblr-ss, body.page-node-29 .tumblr-ss, body.page-node-34 .tumblr-ss, body.page-content .tumblr-ss {
  background-color: #35465c;
}

body.page-news .tumblr-ss:hover, body.node-type-tep-blogs .tumblr-ss:hover, body.page-guides .tumblr-ss:hover, body.page-edgeproptv .tumblr-ss:hover, body.page-pullout .tumblr-ss:hover, body.page-events .tumblr-ss:hover, body.page-event .tumblr-ss:hover, body.page-tags .tumblr-ss:hover, body.page-node-29 .tumblr-ss:hover, body.page-node-34 .tumblr-ss:hover, body.page-content .tumblr-ss:hover {
  background-color: #51565c;
}

body.page-news .reddit-ss, body.node-type-tep-blogs .reddit-ss, body.page-guides .reddit-ss, body.page-edgeproptv .reddit-ss, body.page-pullout .reddit-ss, body.page-events .reddit-ss, body.page-event .reddit-ss, body.page-tags .reddit-ss, body.page-node-29 .reddit-ss, body.page-node-34 .reddit-ss, body.page-content .reddit-ss {
  background-color: #ff4500;
}

body.page-news .reddit-ss:hover, body.node-type-tep-blogs .reddit-ss:hover, body.page-guides .reddit-ss:hover, body.page-edgeproptv .reddit-ss:hover, body.page-pullout .reddit-ss:hover, body.page-events .reddit-ss:hover, body.page-event .reddit-ss:hover, body.page-tags .reddit-ss:hover, body.page-node-29 .reddit-ss:hover, body.page-node-34 .reddit-ss:hover, body.page-content .reddit-ss:hover {
  background-color: #ff7c4c;
}

body.page-news .email-ss,
body.page-news .print-ss, body.node-type-tep-blogs .email-ss,
body.node-type-tep-blogs .print-ss, body.page-guides .email-ss,
body.page-guides .print-ss, body.page-edgeproptv .email-ss,
body.page-edgeproptv .print-ss, body.page-pullout .email-ss,
body.page-pullout .print-ss, body.page-events .email-ss,
body.page-events .print-ss, body.page-event .email-ss,
body.page-event .print-ss, body.page-tags .email-ss,
body.page-tags .print-ss, body.page-node-29 .email-ss,
body.page-node-29 .print-ss, body.page-node-34 .email-ss,
body.page-node-34 .print-ss, body.page-content .email-ss,
body.page-content .print-ss {
  background-color: #969696;
}

body.page-news .email-ss:hover,
body.page-news .print-ss:hover, body.node-type-tep-blogs .email-ss:hover,
body.node-type-tep-blogs .print-ss:hover, body.page-guides .email-ss:hover,
body.page-guides .print-ss:hover, body.page-edgeproptv .email-ss:hover,
body.page-edgeproptv .print-ss:hover, body.page-pullout .email-ss:hover,
body.page-pullout .print-ss:hover, body.page-events .email-ss:hover,
body.page-events .print-ss:hover, body.page-event .email-ss:hover,
body.page-event .print-ss:hover, body.page-tags .email-ss:hover,
body.page-tags .print-ss:hover, body.page-node-29 .email-ss:hover,
body.page-node-29 .print-ss:hover, body.page-node-34 .email-ss:hover,
body.page-node-34 .print-ss:hover, body.page-content .email-ss:hover,
body.page-content .print-ss:hover {
  background-color: #c9c9c9;
}

body.page-news .apsc-instagram-icon, body.node-type-tep-blogs .apsc-instagram-icon, body.page-guides .apsc-instagram-icon, body.page-edgeproptv .apsc-instagram-icon, body.page-pullout .apsc-instagram-icon, body.page-events .apsc-instagram-icon, body.page-event .apsc-instagram-icon, body.page-tags .apsc-instagram-icon, body.page-node-29 .apsc-instagram-icon, body.page-node-34 .apsc-instagram-icon, body.page-content .apsc-instagram-icon {
  background-color: #e4405f;
}

body.page-news .apsc-instagram-icon:hover, body.node-type-tep-blogs .apsc-instagram-icon:hover, body.page-guides .apsc-instagram-icon:hover, body.page-edgeproptv .apsc-instagram-icon:hover, body.page-pullout .apsc-instagram-icon:hover, body.page-events .apsc-instagram-icon:hover, body.page-event .apsc-instagram-icon:hover, body.page-tags .apsc-instagram-icon:hover, body.page-node-29 .apsc-instagram-icon:hover, body.page-node-34 .apsc-instagram-icon:hover, body.page-content .apsc-instagram-icon:hover {
  background-color: #e38495;
}

body.page-news .apsc-soundcloud-icon, body.node-type-tep-blogs .apsc-soundcloud-icon, body.page-guides .apsc-soundcloud-icon, body.page-edgeproptv .apsc-soundcloud-icon, body.page-pullout .apsc-soundcloud-icon, body.page-events .apsc-soundcloud-icon, body.page-event .apsc-soundcloud-icon, body.page-tags .apsc-soundcloud-icon, body.page-node-29 .apsc-soundcloud-icon, body.page-node-34 .apsc-soundcloud-icon, body.page-content .apsc-soundcloud-icon {
  background-color: #ff8800;
}

body.page-news .apsc-soundcloud-icon:hover, body.node-type-tep-blogs .apsc-soundcloud-icon:hover, body.page-guides .apsc-soundcloud-icon:hover, body.page-edgeproptv .apsc-soundcloud-icon:hover, body.page-pullout .apsc-soundcloud-icon:hover, body.page-events .apsc-soundcloud-icon:hover, body.page-event .apsc-soundcloud-icon:hover, body.page-tags .apsc-soundcloud-icon:hover, body.page-node-29 .apsc-soundcloud-icon:hover, body.page-node-34 .apsc-soundcloud-icon:hover, body.page-content .apsc-soundcloud-icon:hover {
  background-color: #ffac4c;
}

body.page-news .apsc-youtube-icon, body.node-type-tep-blogs .apsc-youtube-icon, body.page-guides .apsc-youtube-icon, body.page-edgeproptv .apsc-youtube-icon, body.page-pullout .apsc-youtube-icon, body.page-events .apsc-youtube-icon, body.page-event .apsc-youtube-icon, body.page-tags .apsc-youtube-icon, body.page-node-29 .apsc-youtube-icon, body.page-node-34 .apsc-youtube-icon, body.page-content .apsc-youtube-icon {
  background-color: #cd201f;
}

body.page-news .apsc-youtube-icon:hover, body.node-type-tep-blogs .apsc-youtube-icon:hover, body.page-guides .apsc-youtube-icon:hover, body.page-edgeproptv .apsc-youtube-icon:hover, body.page-pullout .apsc-youtube-icon:hover, body.page-events .apsc-youtube-icon:hover, body.page-event .apsc-youtube-icon:hover, body.page-tags .apsc-youtube-icon:hover, body.page-node-29 .apsc-youtube-icon:hover, body.page-node-34 .apsc-youtube-icon:hover, body.page-content .apsc-youtube-icon:hover {
  background-color: #cc5c5c;
}

body.page-news .apsc-dribbble-icon, body.node-type-tep-blogs .apsc-dribbble-icon, body.page-guides .apsc-dribbble-icon, body.page-edgeproptv .apsc-dribbble-icon, body.page-pullout .apsc-dribbble-icon, body.page-events .apsc-dribbble-icon, body.page-event .apsc-dribbble-icon, body.page-tags .apsc-dribbble-icon, body.page-node-29 .apsc-dribbble-icon, body.page-node-34 .apsc-dribbble-icon, body.page-content .apsc-dribbble-icon {
  background-color: #ea4c89;
}

body.page-news .apsc-dribbble-icon:hover, body.node-type-tep-blogs .apsc-dribbble-icon:hover, body.page-guides .apsc-dribbble-icon:hover, body.page-edgeproptv .apsc-dribbble-icon:hover, body.page-pullout .apsc-dribbble-icon:hover, body.page-events .apsc-dribbble-icon:hover, body.page-event .apsc-dribbble-icon:hover, body.page-tags .apsc-dribbble-icon:hover, body.page-node-29 .apsc-dribbble-icon:hover, body.page-node-34 .apsc-dribbble-icon:hover, body.page-content .apsc-dribbble-icon:hover {
  background-color: #eb91b4;
}

body.page-news .single-post .main-content, body.node-type-tep-blogs .single-post .main-content, body.page-guides .single-post .main-content, body.page-edgeproptv .single-post .main-content, body.page-pullout .single-post .main-content, body.page-events .single-post .main-content, body.page-event .single-post .main-content, body.page-tags .single-post .main-content, body.page-node-29 .single-post .main-content, body.page-node-34 .single-post .main-content, body.page-content .single-post .main-content {
  margin-bottom: 64px;
}

body.page-news .main-content, body.node-type-tep-blogs .main-content, body.page-guides .main-content, body.page-edgeproptv .main-content, body.page-pullout .main-content, body.page-events .main-content, body.page-event .main-content, body.page-tags .main-content, body.page-node-29 .main-content, body.page-node-34 .main-content, body.page-content .main-content {
  float: left;
  width: 67.25%;
  padding: 10px;
}

@media (min-width: 992px) {
  body.page-news .main-content, body.node-type-tep-blogs .main-content, body.page-guides .main-content, body.page-edgeproptv .main-content, body.page-pullout .main-content, body.page-events .main-content, body.page-event .main-content, body.page-tags .main-content, body.page-node-29 .main-content, body.page-node-34 .main-content, body.page-content .main-content {
    padding: 0 0 10px;
  }
}

body.page-news .no-sidebar .main-content, body.node-type-tep-blogs .no-sidebar .main-content, body.page-guides .no-sidebar .main-content, body.page-edgeproptv .no-sidebar .main-content, body.page-pullout .no-sidebar .main-content, body.page-events .no-sidebar .main-content, body.page-event .no-sidebar .main-content, body.page-tags .no-sidebar .main-content, body.page-node-29 .no-sidebar .main-content, body.page-node-34 .no-sidebar .main-content, body.page-content .no-sidebar .main-content {
  float: none;
  width: 100%;
}

body.page-news .no-sidebar .sidebar, body.node-type-tep-blogs .no-sidebar .sidebar, body.page-guides .no-sidebar .sidebar, body.page-edgeproptv .no-sidebar .sidebar, body.page-pullout .no-sidebar .sidebar, body.page-events .no-sidebar .sidebar, body.page-event .no-sidebar .sidebar, body.page-tags .no-sidebar .sidebar, body.page-node-29 .no-sidebar .sidebar, body.page-node-34 .no-sidebar .sidebar, body.page-content .no-sidebar .sidebar {
  display: none;
  float: none;
}

body.page-news .sidebar, body.node-type-tep-blogs .sidebar, body.page-guides .sidebar, body.page-edgeproptv .sidebar, body.page-pullout .sidebar, body.page-events .sidebar, body.page-event .sidebar, body.page-tags .sidebar, body.page-node-29 .sidebar, body.page-node-34 .sidebar, body.page-content .sidebar {
  float: right;
  width: 32.75%;
  padding: 10px 0px 10px 0;
}

@media (min-width: 992px) {
  body.page-news .sidebar, body.node-type-tep-blogs .sidebar, body.page-guides .sidebar, body.page-edgeproptv .sidebar, body.page-pullout .sidebar, body.page-events .sidebar, body.page-event .sidebar, body.page-tags .sidebar, body.page-node-29 .sidebar, body.page-node-34 .sidebar, body.page-content .sidebar {
    padding-top: 0;
  }
}

body.page-news .entry-categories a, body.node-type-tep-blogs .entry-categories a, body.page-guides .entry-categories a, body.page-edgeproptv .entry-categories a, body.page-pullout .entry-categories a, body.page-events .entry-categories a, body.page-event .entry-categories a, body.page-tags .entry-categories a, body.page-node-29 .entry-categories a, body.page-node-34 .entry-categories a, body.page-content .entry-categories a {
  font-weight: bold;
  color: #0e6bc5;
  line-height: 14px;
  text-transform: uppercase;
  display: inline-block;
  font-size: 12px;
  margin-right: 8px;
}

body.page-news .entry-categories a:hover, body.node-type-tep-blogs .entry-categories a:hover, body.page-guides .entry-categories a:hover, body.page-edgeproptv .entry-categories a:hover, body.page-pullout .entry-categories a:hover, body.page-events .entry-categories a:hover, body.page-event .entry-categories a:hover, body.page-tags .entry-categories a:hover, body.page-node-29 .entry-categories a:hover, body.page-node-34 .entry-categories a:hover, body.page-content .entry-categories a:hover {
  text-decoration: none;
}

body.page-news .header_post_style2 .entry-categories a:hover,
body.page-news .header_post_style3 .entry-categories a:hover, body.node-type-tep-blogs .header_post_style2 .entry-categories a:hover,
body.node-type-tep-blogs .header_post_style3 .entry-categories a:hover, body.page-guides .header_post_style2 .entry-categories a:hover,
body.page-guides .header_post_style3 .entry-categories a:hover, body.page-edgeproptv .header_post_style2 .entry-categories a:hover,
body.page-edgeproptv .header_post_style3 .entry-categories a:hover, body.page-pullout .header_post_style2 .entry-categories a:hover,
body.page-pullout .header_post_style3 .entry-categories a:hover, body.page-events .header_post_style2 .entry-categories a:hover,
body.page-events .header_post_style3 .entry-categories a:hover, body.page-event .header_post_style2 .entry-categories a:hover,
body.page-event .header_post_style3 .entry-categories a:hover, body.page-tags .header_post_style2 .entry-categories a:hover,
body.page-tags .header_post_style3 .entry-categories a:hover, body.page-node-29 .header_post_style2 .entry-categories a:hover,
body.page-node-29 .header_post_style3 .entry-categories a:hover, body.page-node-34 .header_post_style2 .entry-categories a:hover,
body.page-node-34 .header_post_style3 .entry-categories a:hover, body.page-content .header_post_style2 .entry-categories a:hover,
body.page-content .header_post_style3 .entry-categories a:hover {
  background-color: #fff;
  color: #1a1a1a;
  text-decoration: none;
}

body.page-news .entry-meta,
body.page-news .entry-meta a, body.node-type-tep-blogs .entry-meta,
body.node-type-tep-blogs .entry-meta a, body.page-guides .entry-meta,
body.page-guides .entry-meta a, body.page-edgeproptv .entry-meta,
body.page-edgeproptv .entry-meta a, body.page-pullout .entry-meta,
body.page-pullout .entry-meta a, body.page-events .entry-meta,
body.page-events .entry-meta a, body.page-event .entry-meta,
body.page-event .entry-meta a, body.page-tags .entry-meta,
body.page-tags .entry-meta a, body.page-node-29 .entry-meta,
body.page-node-29 .entry-meta a, body.page-node-34 .entry-meta,
body.page-node-34 .entry-meta a, body.page-content .entry-meta,
body.page-content .entry-meta a {
  font-size: 13px !important;
  text-transform: initial;
  color: #212529;
  font-family: "Poppins", sans-serif !important;
}

body.page-news .entry-meta a, body.node-type-tep-blogs .entry-meta a, body.page-guides .entry-meta a, body.page-edgeproptv .entry-meta a, body.page-pullout .entry-meta a, body.page-events .entry-meta a, body.page-event .entry-meta a, body.page-tags .entry-meta a, body.page-node-29 .entry-meta a, body.page-node-34 .entry-meta a, body.page-content .entry-meta a {
  font-weight: bold;
}

body.page-news .entry-meta a:hover, body.node-type-tep-blogs .entry-meta a:hover, body.page-guides .entry-meta a:hover, body.page-edgeproptv .entry-meta a:hover, body.page-pullout .entry-meta a:hover, body.page-events .entry-meta a:hover, body.page-event .entry-meta a:hover, body.page-tags .entry-meta a:hover, body.page-node-29 .entry-meta a:hover, body.page-node-34 .entry-meta a:hover, body.page-content .entry-meta a:hover {
  color: #808080;
  text-decoration: none;
}

body.page-news h2.entry-title,
body.page-news h1.entry-title, body.node-type-tep-blogs h2.entry-title,
body.node-type-tep-blogs h1.entry-title, body.page-guides h2.entry-title,
body.page-guides h1.entry-title, body.page-edgeproptv h2.entry-title,
body.page-edgeproptv h1.entry-title, body.page-pullout h2.entry-title,
body.page-pullout h1.entry-title, body.page-events h2.entry-title,
body.page-events h1.entry-title, body.page-event h2.entry-title,
body.page-event h1.entry-title, body.page-tags h2.entry-title,
body.page-tags h1.entry-title, body.page-node-29 h2.entry-title,
body.page-node-29 h1.entry-title, body.page-node-34 h2.entry-title,
body.page-node-34 h1.entry-title, body.page-content h2.entry-title,
body.page-content h1.entry-title {
  font-weight: 600 !important;
  font-size: 44px;
  line-height: 1.21;
  color: #1a1a1a;
  padding: 28px 0 15px;
  border-bottom: 1px solid #ccc;
  margin: 0;
}

@media (min-width: 1260px) {
  body.page-news h2.entry-title,
  body.page-news h1.entry-title, body.node-type-tep-blogs h2.entry-title,
  body.node-type-tep-blogs h1.entry-title, body.page-guides h2.entry-title,
  body.page-guides h1.entry-title, body.page-edgeproptv h2.entry-title,
  body.page-edgeproptv h1.entry-title, body.page-pullout h2.entry-title,
  body.page-pullout h1.entry-title, body.page-events h2.entry-title,
  body.page-events h1.entry-title, body.page-event h2.entry-title,
  body.page-event h1.entry-title, body.page-tags h2.entry-title,
  body.page-tags h1.entry-title, body.page-node-29 h2.entry-title,
  body.page-node-29 h1.entry-title, body.page-node-34 h2.entry-title,
  body.page-node-34 h1.entry-title, body.page-content h2.entry-title,
  body.page-content h1.entry-title {
    margin-bottom: 11px;
  }
}

@media (max-width: 768px) {
  body.page-news h2.entry-title,
  body.page-news h1.entry-title, body.node-type-tep-blogs h2.entry-title,
  body.node-type-tep-blogs h1.entry-title, body.page-guides h2.entry-title,
  body.page-guides h1.entry-title, body.page-edgeproptv h2.entry-title,
  body.page-edgeproptv h1.entry-title, body.page-pullout h2.entry-title,
  body.page-pullout h1.entry-title, body.page-events h2.entry-title,
  body.page-events h1.entry-title, body.page-event h2.entry-title,
  body.page-event h1.entry-title, body.page-tags h2.entry-title,
  body.page-tags h1.entry-title, body.page-node-29 h2.entry-title,
  body.page-node-29 h1.entry-title, body.page-node-34 h2.entry-title,
  body.page-node-34 h1.entry-title, body.page-content h2.entry-title,
  body.page-content h1.entry-title {
    font-size: 2.4rem;
    line-height: 1.2;
    margin: 0 0 15px;
    padding: 0 10px 15px;
    letter-spacing: -.3px;
  }
}

body.page-news .entry-thumb,
body.page-news .post-header, body.node-type-tep-blogs .entry-thumb,
body.node-type-tep-blogs .post-header, body.page-guides .entry-thumb,
body.page-guides .post-header, body.page-edgeproptv .entry-thumb,
body.page-edgeproptv .post-header, body.page-pullout .entry-thumb,
body.page-pullout .post-header, body.page-events .entry-thumb,
body.page-events .post-header, body.page-event .entry-thumb,
body.page-event .post-header, body.page-tags .entry-thumb,
body.page-tags .post-header, body.page-node-29 .entry-thumb,
body.page-node-29 .post-header, body.page-node-34 .entry-thumb,
body.page-node-34 .post-header, body.page-content .entry-thumb,
body.page-content .post-header {
  margin-bottom: 28px;
  overflow: hidden;
  position: relative;
}

body.page-news .post-header-media, body.node-type-tep-blogs .post-header-media, body.page-guides .post-header-media, body.page-edgeproptv .post-header-media, body.page-pullout .post-header-media, body.page-events .post-header-media, body.page-event .post-header-media, body.page-tags .post-header-media, body.page-node-29 .post-header-media, body.page-node-34 .post-header-media, body.page-content .post-header-media {
  margin-bottom: 8px;
}

body.page-news .header_post_style2 .post-header, body.node-type-tep-blogs .header_post_style2 .post-header, body.page-guides .header_post_style2 .post-header, body.page-edgeproptv .header_post_style2 .post-header, body.page-pullout .header_post_style2 .post-header, body.page-events .header_post_style2 .post-header, body.page-event .header_post_style2 .post-header, body.page-tags .header_post_style2 .post-header, body.page-node-29 .header_post_style2 .post-header, body.page-node-34 .header_post_style2 .post-header, body.page-content .header_post_style2 .post-header {
  background-repeat: no-repeat;
  background-size: cover;
  padding: 16% 0;
  text-align: center;
  margin-top: -25px;
}

body.page-news .container-background, body.node-type-tep-blogs .container-background, body.page-guides .container-background, body.page-edgeproptv .container-background, body.page-pullout .container-background, body.page-events .container-background, body.page-event .container-background, body.page-tags .container-background, body.page-node-29 .container-background, body.page-node-34 .container-background, body.page-content .container-background {
  margin-top: -25px;
  background-color: #222;
  margin-bottom: 28px;
}

body.page-news .header_post_style2 .entry-heading, body.node-type-tep-blogs .header_post_style2 .entry-heading, body.page-guides .header_post_style2 .entry-heading, body.page-edgeproptv .header_post_style2 .entry-heading, body.page-pullout .header_post_style2 .entry-heading, body.page-events .header_post_style2 .entry-heading, body.page-event .header_post_style2 .entry-heading, body.page-tags .header_post_style2 .entry-heading, body.page-node-29 .header_post_style2 .entry-heading, body.page-node-34 .header_post_style2 .entry-heading, body.page-content .header_post_style2 .entry-heading {
  padding: 0 16%;
}

body.page-news .header_post_style2 .entry-title, body.node-type-tep-blogs .header_post_style2 .entry-title, body.page-guides .header_post_style2 .entry-title, body.page-edgeproptv .header_post_style2 .entry-title, body.page-pullout .header_post_style2 .entry-title, body.page-events .header_post_style2 .entry-title, body.page-event .header_post_style2 .entry-title, body.page-tags .header_post_style2 .entry-title, body.page-node-29 .header_post_style2 .entry-title, body.page-node-34 .header_post_style2 .entry-title, body.page-content .header_post_style2 .entry-title {
  color: #fff;
  padding: 24px 0;
  font-size: 42px;
}

body.page-news .header_post_style3 .post-header, body.node-type-tep-blogs .header_post_style3 .post-header, body.page-guides .header_post_style3 .post-header, body.page-edgeproptv .header_post_style3 .post-header, body.page-pullout .header_post_style3 .post-header, body.page-events .header_post_style3 .post-header, body.page-event .header_post_style3 .post-header, body.page-tags .header_post_style3 .post-header, body.page-node-29 .header_post_style3 .post-header, body.page-node-34 .header_post_style3 .post-header, body.page-content .header_post_style3 .post-header {
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  padding-top: 34%;
  padding-bottom: 3.5%;
}

body.page-news .header_post_style3 .entry-heading, body.node-type-tep-blogs .header_post_style3 .entry-heading, body.page-guides .header_post_style3 .entry-heading, body.page-edgeproptv .header_post_style3 .entry-heading, body.page-pullout .header_post_style3 .entry-heading, body.page-events .header_post_style3 .entry-heading, body.page-event .header_post_style3 .entry-heading, body.page-tags .header_post_style3 .entry-heading, body.page-node-29 .header_post_style3 .entry-heading, body.page-node-34 .header_post_style3 .entry-heading, body.page-content .header_post_style3 .entry-heading {
  padding: 0 4.5%;
}

body.page-news .header_post_style3 .entry-title, body.node-type-tep-blogs .header_post_style3 .entry-title, body.page-guides .header_post_style3 .entry-title, body.page-edgeproptv .header_post_style3 .entry-title, body.page-pullout .header_post_style3 .entry-title, body.page-events .header_post_style3 .entry-title, body.page-event .header_post_style3 .entry-title, body.page-tags .header_post_style3 .entry-title, body.page-node-29 .header_post_style3 .entry-title, body.page-node-34 .header_post_style3 .entry-title, body.page-content .header_post_style3 .entry-title {
  color: #fff;
  font-size: 42px;
  padding: 28px 0;
}

body.page-news .header_post_style4 .entry-heading, body.node-type-tep-blogs .header_post_style4 .entry-heading, body.page-guides .header_post_style4 .entry-heading, body.page-edgeproptv .header_post_style4 .entry-heading, body.page-pullout .header_post_style4 .entry-heading, body.page-events .header_post_style4 .entry-heading, body.page-event .header_post_style4 .entry-heading, body.page-tags .header_post_style4 .entry-heading, body.page-node-29 .header_post_style4 .entry-heading, body.page-node-34 .header_post_style4 .entry-heading, body.page-content .header_post_style4 .entry-heading {
  text-align: center;
}

body.page-news .background-media, body.node-type-tep-blogs .background-media, body.page-guides .background-media, body.page-edgeproptv .background-media, body.page-pullout .background-media, body.page-events .background-media, body.page-event .background-media, body.page-tags .background-media, body.page-node-29 .background-media, body.page-node-34 .background-media, body.page-content .background-media {
  padding-bottom: 48px;
  padding-top: 48px;
}

body.page-news #pingbacks, body.node-type-tep-blogs #pingbacks, body.page-guides #pingbacks, body.page-edgeproptv #pingbacks, body.page-pullout #pingbacks, body.page-events #pingbacks, body.page-event #pingbacks, body.page-tags #pingbacks, body.page-node-29 #pingbacks, body.page-node-34 #pingbacks, body.page-content #pingbacks {
  margin-bottom: 22px;
}

body.page-news .pingbacklist .comment-author, body.node-type-tep-blogs .pingbacklist .comment-author, body.page-guides .pingbacklist .comment-author, body.page-edgeproptv .pingbacklist .comment-author, body.page-pullout .pingbacklist .comment-author, body.page-events .pingbacklist .comment-author, body.page-event .pingbacklist .comment-author, body.page-tags .pingbacklist .comment-author, body.page-node-29 .pingbacklist .comment-author, body.page-node-34 .pingbacklist .comment-author, body.page-content .pingbacklist .comment-author {
  padding: 18px 0;
}

body.page-news .pingbacklist .comment-author img, body.node-type-tep-blogs .pingbacklist .comment-author img, body.page-guides .pingbacklist .comment-author img, body.page-edgeproptv .pingbacklist .comment-author img, body.page-pullout .pingbacklist .comment-author img, body.page-events .pingbacklist .comment-author img, body.page-event .pingbacklist .comment-author img, body.page-tags .pingbacklist .comment-author img, body.page-node-29 .pingbacklist .comment-author img, body.page-node-34 .pingbacklist .comment-author img, body.page-content .pingbacklist .comment-author img {
  margin-right: 12px;
}

body.page-news .category .page-heading, body.node-type-tep-blogs .category .page-heading, body.page-guides .category .page-heading, body.page-edgeproptv .category .page-heading, body.page-pullout .category .page-heading, body.page-events .category .page-heading, body.page-event .category .page-heading, body.page-tags .category .page-heading, body.page-node-29 .category .page-heading, body.page-node-34 .category .page-heading, body.page-content .category .page-heading {
  padding: 18px 0 22px 0;
}

body.page-news .horizontal-layout, body.node-type-tep-blogs .horizontal-layout, body.page-guides .horizontal-layout, body.page-edgeproptv .horizontal-layout, body.page-pullout .horizontal-layout, body.page-events .horizontal-layout, body.page-event .horizontal-layout, body.page-tags .horizontal-layout, body.page-node-29 .horizontal-layout, body.page-node-34 .horizontal-layout, body.page-content .horizontal-layout {
  padding-bottom: 0;
}

body.page-news .list-cat-child, body.node-type-tep-blogs .list-cat-child, body.page-guides .list-cat-child, body.page-edgeproptv .list-cat-child, body.page-pullout .list-cat-child, body.page-events .list-cat-child, body.page-event .list-cat-child, body.page-tags .list-cat-child, body.page-node-29 .list-cat-child, body.page-node-34 .list-cat-child, body.page-content .list-cat-child {
  padding: 12px 0;
}

body.page-news .list-cat-child a, body.node-type-tep-blogs .list-cat-child a, body.page-guides .list-cat-child a, body.page-edgeproptv .list-cat-child a, body.page-pullout .list-cat-child a, body.page-events .list-cat-child a, body.page-event .list-cat-child a, body.page-tags .list-cat-child a, body.page-node-29 .list-cat-child a, body.page-node-34 .list-cat-child a, body.page-content .list-cat-child a {
  font-size: 14px;
  line-height: 16px;
  color: #666;
  padding: 4px 8px;
  border: 1px solid #e5e5e5;
  margin-right: 10px;
  margin-bottom: 8px;
}

body.page-news .list-cat-child a:hover, body.node-type-tep-blogs .list-cat-child a:hover, body.page-guides .list-cat-child a:hover, body.page-edgeproptv .list-cat-child a:hover, body.page-pullout .list-cat-child a:hover, body.page-events .list-cat-child a:hover, body.page-event .list-cat-child a:hover, body.page-tags .list-cat-child a:hover, body.page-node-29 .list-cat-child a:hover, body.page-node-34 .list-cat-child a:hover, body.page-content .list-cat-child a:hover {
  background-color: #2884ec;
  border: 1px solid #2884ec;
  color: #fff;
}

body.page-news .horizontal-layout .entry-thumb,
body.page-news .horizontal-layout .entry-details, body.node-type-tep-blogs .horizontal-layout .entry-thumb,
body.node-type-tep-blogs .horizontal-layout .entry-details, body.page-guides .horizontal-layout .entry-thumb,
body.page-guides .horizontal-layout .entry-details, body.page-edgeproptv .horizontal-layout .entry-thumb,
body.page-edgeproptv .horizontal-layout .entry-details, body.page-pullout .horizontal-layout .entry-thumb,
body.page-pullout .horizontal-layout .entry-details, body.page-events .horizontal-layout .entry-thumb,
body.page-events .horizontal-layout .entry-details, body.page-event .horizontal-layout .entry-thumb,
body.page-event .horizontal-layout .entry-details, body.page-tags .horizontal-layout .entry-thumb,
body.page-tags .horizontal-layout .entry-details, body.page-node-29 .horizontal-layout .entry-thumb,
body.page-node-29 .horizontal-layout .entry-details, body.page-node-34 .horizontal-layout .entry-thumb,
body.page-node-34 .horizontal-layout .entry-details, body.page-content .horizontal-layout .entry-thumb,
body.page-content .horizontal-layout .entry-details {
  float: left;
  width: 50%;
}

body.page-news .horizontal-layout .entry-thumb, body.node-type-tep-blogs .horizontal-layout .entry-thumb, body.page-guides .horizontal-layout .entry-thumb, body.page-edgeproptv .horizontal-layout .entry-thumb, body.page-pullout .horizontal-layout .entry-thumb, body.page-events .horizontal-layout .entry-thumb, body.page-event .horizontal-layout .entry-thumb, body.page-tags .horizontal-layout .entry-thumb, body.page-node-29 .horizontal-layout .entry-thumb, body.page-node-34 .horizontal-layout .entry-thumb, body.page-content .horizontal-layout .entry-thumb {
  padding-right: 20px;
}

body.page-news .horizontal-layout .entry-details, body.node-type-tep-blogs .horizontal-layout .entry-details, body.page-guides .horizontal-layout .entry-details, body.page-edgeproptv .horizontal-layout .entry-details, body.page-pullout .horizontal-layout .entry-details, body.page-events .horizontal-layout .entry-details, body.page-event .horizontal-layout .entry-details, body.page-tags .horizontal-layout .entry-details, body.page-node-29 .horizontal-layout .entry-details, body.page-node-34 .horizontal-layout .entry-details, body.page-content .horizontal-layout .entry-details {
  padding-left: 20px;
}

body.page-news .horizontal-layout .entry-meta, body.node-type-tep-blogs .horizontal-layout .entry-meta, body.page-guides .horizontal-layout .entry-meta, body.page-edgeproptv .horizontal-layout .entry-meta, body.page-pullout .horizontal-layout .entry-meta, body.page-events .horizontal-layout .entry-meta, body.page-event .horizontal-layout .entry-meta, body.page-tags .horizontal-layout .entry-meta, body.page-node-29 .horizontal-layout .entry-meta, body.page-node-34 .horizontal-layout .entry-meta, body.page-content .horizontal-layout .entry-meta {
  padding: 6px 0;
}

body.page-news .read-more-button, body.node-type-tep-blogs .read-more-button, body.page-guides .read-more-button, body.page-edgeproptv .read-more-button, body.page-pullout .read-more-button, body.page-events .read-more-button, body.page-event .read-more-button, body.page-tags .read-more-button, body.page-node-29 .read-more-button, body.page-node-34 .read-more-button, body.page-content .read-more-button {
  font-size: 14px;
  line-height: 16px;
  font-weight: bold;
  letter-spacing: 1px;
  color: #fff;
  background-color: #1a1a1a;
  padding: 5px 15px;
  border: 2px solid #1a1a1a;
  margin-top: 22px;
}

body.page-news .read-more-button:hover, body.node-type-tep-blogs .read-more-button:hover, body.page-guides .read-more-button:hover, body.page-edgeproptv .read-more-button:hover, body.page-pullout .read-more-button:hover, body.page-events .read-more-button:hover, body.page-event .read-more-button:hover, body.page-tags .read-more-button:hover, body.page-node-29 .read-more-button:hover, body.page-node-34 .read-more-button:hover, body.page-content .read-more-button:hover {
  background-color: #fff;
  color: #1a1a1a;
  text-decoration: none;
}

body.page-news .content-column1_1, body.node-type-tep-blogs .content-column1_1, body.page-guides .content-column1_1, body.page-edgeproptv .content-column1_1, body.page-pullout .content-column1_1, body.page-events .content-column1_1, body.page-event .content-column1_1, body.page-tags .content-column1_1, body.page-node-29 .content-column1_1, body.page-node-34 .content-column1_1, body.page-content .content-column1_1 {
  float: left;
  width: 100%;
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  /* Firefox */
}

body.page-news .content-column2_1, body.node-type-tep-blogs .content-column2_1, body.page-guides .content-column2_1, body.page-edgeproptv .content-column2_1, body.page-pullout .content-column2_1, body.page-events .content-column2_1, body.page-event .content-column2_1, body.page-tags .content-column2_1, body.page-node-29 .content-column2_1, body.page-node-34 .content-column2_1, body.page-content .content-column2_1 {
  float: left;
  width: 50%;
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  /* Firefox */
}

body.page-news .content-column2_1:nth-of-type(2n+1), body.node-type-tep-blogs .content-column2_1:nth-of-type(2n+1), body.page-guides .content-column2_1:nth-of-type(2n+1), body.page-edgeproptv .content-column2_1:nth-of-type(2n+1), body.page-pullout .content-column2_1:nth-of-type(2n+1), body.page-events .content-column2_1:nth-of-type(2n+1), body.page-event .content-column2_1:nth-of-type(2n+1), body.page-tags .content-column2_1:nth-of-type(2n+1), body.page-node-29 .content-column2_1:nth-of-type(2n+1), body.page-node-34 .content-column2_1:nth-of-type(2n+1), body.page-content .content-column2_1:nth-of-type(2n+1) {
  padding-right: 19px;
}

body.page-news .content-column2_1:nth-of-type(2n), body.node-type-tep-blogs .content-column2_1:nth-of-type(2n), body.page-guides .content-column2_1:nth-of-type(2n), body.page-edgeproptv .content-column2_1:nth-of-type(2n), body.page-pullout .content-column2_1:nth-of-type(2n), body.page-events .content-column2_1:nth-of-type(2n), body.page-event .content-column2_1:nth-of-type(2n), body.page-tags .content-column2_1:nth-of-type(2n), body.page-node-29 .content-column2_1:nth-of-type(2n), body.page-node-34 .content-column2_1:nth-of-type(2n), body.page-content .content-column2_1:nth-of-type(2n) {
  padding-left: 19px;
}

body.page-news .post-layout, body.node-type-tep-blogs .post-layout, body.page-guides .post-layout, body.page-edgeproptv .post-layout, body.page-pullout .post-layout, body.page-events .post-layout, body.page-event .post-layout, body.page-tags .post-layout, body.page-node-29 .post-layout, body.page-node-34 .post-layout, body.page-content .post-layout {
  padding-bottom: 12px;
}

body.page-news .post-layout article, body.node-type-tep-blogs .post-layout article, body.page-guides .post-layout article, body.page-edgeproptv .post-layout article, body.page-pullout .post-layout article, body.page-events .post-layout article, body.page-event .post-layout article, body.page-tags .post-layout article, body.page-node-29 .post-layout article, body.page-node-34 .post-layout article, body.page-content .post-layout article {
  position: relative;
  margin-bottom: 25px;
}

body.page-news .post-layout h4.entry-title, body.node-type-tep-blogs .post-layout h4.entry-title, body.page-guides .post-layout h4.entry-title, body.page-edgeproptv .post-layout h4.entry-title, body.page-pullout .post-layout h4.entry-title, body.page-events .post-layout h4.entry-title, body.page-event .post-layout h4.entry-title, body.page-tags .post-layout h4.entry-title, body.page-node-29 .post-layout h4.entry-title, body.page-node-34 .post-layout h4.entry-title, body.page-content .post-layout h4.entry-title {
  position: relative;
  font-size: 18px;
  line-height: 24px;
  margin-bottom: 8px;
  margin-top: 0;
}

body.page-news .post-layout h5.entry-title, body.node-type-tep-blogs .post-layout h5.entry-title, body.page-guides .post-layout h5.entry-title, body.page-edgeproptv .post-layout h5.entry-title, body.page-pullout .post-layout h5.entry-title, body.page-events .post-layout h5.entry-title, body.page-event .post-layout h5.entry-title, body.page-tags .post-layout h5.entry-title, body.page-node-29 .post-layout h5.entry-title, body.page-node-34 .post-layout h5.entry-title, body.page-content .post-layout h5.entry-title {
  font-size: 14px;
  line-height: 18px;
  margin-bottom: 12px;
  margin-top: 0;
}

body.page-news .post-layout .entry-meta, body.node-type-tep-blogs .post-layout .entry-meta, body.page-guides .post-layout .entry-meta, body.page-edgeproptv .post-layout .entry-meta, body.page-pullout .post-layout .entry-meta, body.page-events .post-layout .entry-meta, body.page-event .post-layout .entry-meta, body.page-tags .post-layout .entry-meta, body.page-node-29 .post-layout .entry-meta, body.page-node-34 .post-layout .entry-meta, body.page-content .post-layout .entry-meta {
  font-size: 12px;
  line-height: 14px;
}

body.page-news .post-layout .entry-content, body.node-type-tep-blogs .post-layout .entry-content, body.page-guides .post-layout .entry-content, body.page-edgeproptv .post-layout .entry-content, body.page-pullout .post-layout .entry-content, body.page-events .post-layout .entry-content, body.page-event .post-layout .entry-content, body.page-tags .post-layout .entry-content, body.page-node-29 .post-layout .entry-content, body.page-node-34 .post-layout .entry-content, body.page-content .post-layout .entry-content {
  font-size: 14px;
  line-height: 18px;
  color: #666;
  margin-top: 12px;
}

body.page-news .post-layout .entry-thumb, body.node-type-tep-blogs .post-layout .entry-thumb, body.page-guides .post-layout .entry-thumb, body.page-edgeproptv .post-layout .entry-thumb, body.page-pullout .post-layout .entry-thumb, body.page-events .post-layout .entry-thumb, body.page-event .post-layout .entry-thumb, body.page-tags .post-layout .entry-thumb, body.page-node-29 .post-layout .entry-thumb, body.page-node-34 .post-layout .entry-thumb, body.page-content .post-layout .entry-thumb {
  margin-bottom: 18px;
}

body.page-news .post-layout .box-details, body.node-type-tep-blogs .post-layout .box-details, body.page-guides .post-layout .box-details, body.page-edgeproptv .post-layout .box-details, body.page-pullout .post-layout .box-details, body.page-events .post-layout .box-details, body.page-event .post-layout .box-details, body.page-tags .post-layout .box-details, body.page-node-29 .post-layout .box-details, body.page-node-34 .post-layout .box-details, body.page-content .post-layout .box-details {
  margin-left: 138px;
}

body.page-news .no-thumb .entry-thumb, body.node-type-tep-blogs .no-thumb .entry-thumb, body.page-guides .no-thumb .entry-thumb, body.page-edgeproptv .no-thumb .entry-thumb, body.page-pullout .no-thumb .entry-thumb, body.page-events .no-thumb .entry-thumb, body.page-event .no-thumb .entry-thumb, body.page-tags .no-thumb .entry-thumb, body.page-node-29 .no-thumb .entry-thumb, body.page-node-34 .no-thumb .entry-thumb, body.page-content .no-thumb .entry-thumb {
  margin-bottom: 0;
}

body.page-news .no-thumb .entry-details, body.node-type-tep-blogs .no-thumb .entry-details, body.page-guides .no-thumb .entry-details, body.page-edgeproptv .no-thumb .entry-details, body.page-pullout .no-thumb .entry-details, body.page-events .no-thumb .entry-details, body.page-event .no-thumb .entry-details, body.page-tags .no-thumb .entry-details, body.page-node-29 .no-thumb .entry-details, body.page-node-34 .no-thumb .entry-details, body.page-content .no-thumb .entry-details {
  padding-left: 0;
}

body.page-news .link-post-details, body.node-type-tep-blogs .link-post-details, body.page-guides .link-post-details, body.page-edgeproptv .link-post-details, body.page-pullout .link-post-details, body.page-events .link-post-details, body.page-event .link-post-details, body.page-tags .link-post-details, body.page-node-29 .link-post-details, body.page-node-34 .link-post-details, body.page-content .link-post-details {
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
}

body.page-news .post-entry-overlay, body.node-type-tep-blogs .post-entry-overlay, body.page-guides .post-entry-overlay, body.page-edgeproptv .post-entry-overlay, body.page-pullout .post-entry-overlay, body.page-events .post-entry-overlay, body.page-event .post-entry-overlay, body.page-tags .post-entry-overlay, body.page-node-29 .post-entry-overlay, body.page-node-34 .post-entry-overlay, body.page-content .post-entry-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #000;
  /* For browsers that do not support gradients */
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=30)";
  /* This works in IE 8 & 9 too */
  /* ... but also 5, 6, 7 */
  filter: alpha(opacity=30);
  /* Older than Firefox 0.9 */
  -moz-opacity: 0.3;
  /* Safari 1.x (pre WebKit!) */
  -khtml-opacity: 0.3;
  /* Modern!
                /* Firefox 0.9+, Safari 2?, Chrome any?
                /* Opera 9+, IE 9+ */
  opacity: 0.3;
}

body.page-news .header_post_style4 .post-entry-overlay, body.node-type-tep-blogs .header_post_style4 .post-entry-overlay, body.page-guides .header_post_style4 .post-entry-overlay, body.page-edgeproptv .header_post_style4 .post-entry-overlay, body.page-pullout .header_post_style4 .post-entry-overlay, body.page-events .header_post_style4 .post-entry-overlay, body.page-event .header_post_style4 .post-entry-overlay, body.page-tags .header_post_style4 .post-entry-overlay, body.page-node-29 .header_post_style4 .post-entry-overlay, body.page-node-34 .header_post_style4 .post-entry-overlay, body.page-content .header_post_style4 .post-entry-overlay {
  display: none;
}

body.page-news .top-post-layout .post-entry-overlay, body.node-type-tep-blogs .top-post-layout .post-entry-overlay, body.page-guides .top-post-layout .post-entry-overlay, body.page-edgeproptv .top-post-layout .post-entry-overlay, body.page-pullout .top-post-layout .post-entry-overlay, body.page-events .top-post-layout .post-entry-overlay, body.page-event .top-post-layout .post-entry-overlay, body.page-tags .top-post-layout .post-entry-overlay, body.page-node-29 .top-post-layout .post-entry-overlay, body.page-node-34 .top-post-layout .post-entry-overlay, body.page-content .top-post-layout .post-entry-overlay {
  background: #000;
  /* For browsers that do not support gradients */
}

body.page-news .top-posts-page-container, body.node-type-tep-blogs .top-posts-page-container, body.page-guides .top-posts-page-container, body.page-edgeproptv .top-posts-page-container, body.page-pullout .top-posts-page-container, body.page-events .top-posts-page-container, body.page-event .top-posts-page-container, body.page-tags .top-posts-page-container, body.page-node-29 .top-posts-page-container, body.page-node-34 .top-posts-page-container, body.page-content .top-posts-page-container {
  margin-bottom: 42px;
}

body.page-news .top-post-entry, body.node-type-tep-blogs .top-post-entry, body.page-guides .top-post-entry, body.page-edgeproptv .top-post-entry, body.page-pullout .top-post-entry, body.page-events .top-post-entry, body.page-event .top-post-entry, body.page-tags .top-post-entry, body.page-node-29 .top-post-entry, body.page-node-34 .top-post-entry, body.page-content .top-post-entry {
  position: relative;
}

body.page-news .top-post-thumb a, body.node-type-tep-blogs .top-post-thumb a, body.page-guides .top-post-thumb a, body.page-edgeproptv .top-post-thumb a, body.page-pullout .top-post-thumb a, body.page-events .top-post-thumb a, body.page-event .top-post-thumb a, body.page-tags .top-post-thumb a, body.page-node-29 .top-post-thumb a, body.page-node-34 .top-post-thumb a, body.page-content .top-post-thumb a {
  display: block;
  line-height: 0;
}

body.page-news .top-post-details, body.node-type-tep-blogs .top-post-details, body.page-guides .top-post-details, body.page-edgeproptv .top-post-details, body.page-pullout .top-post-details, body.page-events .top-post-details, body.page-event .top-post-details, body.page-tags .top-post-details, body.page-node-29 .top-post-details, body.page-node-34 .top-post-details, body.page-content .top-post-details {
  position: absolute;
  left: 0;
  bottom: 0;
  padding: 9%;
  overflow: hidden;
}

body.page-news .top-post-title, body.node-type-tep-blogs .top-post-title, body.page-guides .top-post-title, body.page-edgeproptv .top-post-title, body.page-pullout .top-post-title, body.page-events .top-post-title, body.page-event .top-post-title, body.page-tags .top-post-title, body.page-node-29 .top-post-title, body.page-node-34 .top-post-title, body.page-content .top-post-title {
  font-size: 18px;
  line-height: 24px;
  font-weight: bold;
  position: relative;
}

body.page-news .top-post-title a, body.node-type-tep-blogs .top-post-title a, body.page-guides .top-post-title a, body.page-edgeproptv .top-post-title a, body.page-pullout .top-post-title a, body.page-events .top-post-title a, body.page-event .top-post-title a, body.page-tags .top-post-title a, body.page-node-29 .top-post-title a, body.page-node-34 .top-post-title a, body.page-content .top-post-title a {
  color: #fff;
}

body.page-news .top-post-title a:hover, body.node-type-tep-blogs .top-post-title a:hover, body.page-guides .top-post-title a:hover, body.page-edgeproptv .top-post-title a:hover, body.page-pullout .top-post-title a:hover, body.page-events .top-post-title a:hover, body.page-event .top-post-title a:hover, body.page-tags .top-post-title a:hover, body.page-node-29 .top-post-title a:hover, body.page-node-34 .top-post-title a:hover, body.page-content .top-post-title a:hover {
  color: rgba(255, 255, 255, 0.7);
  text-decoration: none;
}

body.page-news .top-post-cat, body.node-type-tep-blogs .top-post-cat, body.page-guides .top-post-cat, body.page-edgeproptv .top-post-cat, body.page-pullout .top-post-cat, body.page-events .top-post-cat, body.page-event .top-post-cat, body.page-tags .top-post-cat, body.page-node-29 .top-post-cat, body.page-node-34 .top-post-cat, body.page-content .top-post-cat {
  position: relative;
  font-size: 14px;
  font-weight: bold;
  line-height: 16px;
  color: #fff;
  padding: 7px 20px;
  text-transform: uppercase;
  background-color: #000;
  /* Safari 3-4, iOS 1-3.2, Android 1.6- */
  -webkit-border-radius: 21px;
  /* Firefox 1-3.6 */
  -moz-border-radius: 21px;
  /* Opera 10.5, IE 9, Safari 5, Chrome, Firefox 4, iOS 4, Android 2.1+ */
  border-radius: 21px;
  margin-bottom: 21px;
}

body.page-news .top-post-cat.black-color, body.node-type-tep-blogs .top-post-cat.black-color, body.page-guides .top-post-cat.black-color, body.page-edgeproptv .top-post-cat.black-color, body.page-pullout .top-post-cat.black-color, body.page-events .top-post-cat.black-color, body.page-event .top-post-cat.black-color, body.page-tags .top-post-cat.black-color, body.page-node-29 .top-post-cat.black-color, body.page-node-34 .top-post-cat.black-color, body.page-content .top-post-cat.black-color {
  background-color: #000;
}

body.page-news .top-post-cat.blue-color, body.node-type-tep-blogs .top-post-cat.blue-color, body.page-guides .top-post-cat.blue-color, body.page-edgeproptv .top-post-cat.blue-color, body.page-pullout .top-post-cat.blue-color, body.page-events .top-post-cat.blue-color, body.page-event .top-post-cat.blue-color, body.page-tags .top-post-cat.blue-color, body.page-node-29 .top-post-cat.blue-color, body.page-node-34 .top-post-cat.blue-color, body.page-content .top-post-cat.blue-color {
  background-color: #46c4f3;
}

body.page-news .top-post-cat.green-color, body.node-type-tep-blogs .top-post-cat.green-color, body.page-guides .top-post-cat.green-color, body.page-edgeproptv .top-post-cat.green-color, body.page-pullout .top-post-cat.green-color, body.page-events .top-post-cat.green-color, body.page-event .top-post-cat.green-color, body.page-tags .top-post-cat.green-color, body.page-node-29 .top-post-cat.green-color, body.page-node-34 .top-post-cat.green-color, body.page-content .top-post-cat.green-color {
  background-color: #8bc527;
}

body.page-news .top-post-cat.pink-color, body.node-type-tep-blogs .top-post-cat.pink-color, body.page-guides .top-post-cat.pink-color, body.page-edgeproptv .top-post-cat.pink-color, body.page-pullout .top-post-cat.pink-color, body.page-events .top-post-cat.pink-color, body.page-event .top-post-cat.pink-color, body.page-tags .top-post-cat.pink-color, body.page-node-29 .top-post-cat.pink-color, body.page-node-34 .top-post-cat.pink-color, body.page-content .top-post-cat.pink-color {
  background-color: #f570d4;
}

body.page-news .top-post-cat.purple-color, body.node-type-tep-blogs .top-post-cat.purple-color, body.page-guides .top-post-cat.purple-color, body.page-edgeproptv .top-post-cat.purple-color, body.page-pullout .top-post-cat.purple-color, body.page-events .top-post-cat.purple-color, body.page-event .top-post-cat.purple-color, body.page-tags .top-post-cat.purple-color, body.page-node-29 .top-post-cat.purple-color, body.page-node-34 .top-post-cat.purple-color, body.page-content .top-post-cat.purple-color {
  background-color: #772edb;
}

body.page-news .top-post-cat.orange-color, body.node-type-tep-blogs .top-post-cat.orange-color, body.page-guides .top-post-cat.orange-color, body.page-edgeproptv .top-post-cat.orange-color, body.page-pullout .top-post-cat.orange-color, body.page-events .top-post-cat.orange-color, body.page-event .top-post-cat.orange-color, body.page-tags .top-post-cat.orange-color, body.page-node-29 .top-post-cat.orange-color, body.page-node-34 .top-post-cat.orange-color, body.page-content .top-post-cat.orange-color {
  background-color: #fc9322;
}

body.page-news .top-post-cat.light-red-color, body.node-type-tep-blogs .top-post-cat.light-red-color, body.page-guides .top-post-cat.light-red-color, body.page-edgeproptv .top-post-cat.light-red-color, body.page-pullout .top-post-cat.light-red-color, body.page-events .top-post-cat.light-red-color, body.page-event .top-post-cat.light-red-color, body.page-tags .top-post-cat.light-red-color, body.page-node-29 .top-post-cat.light-red-color, body.page-node-34 .top-post-cat.light-red-color, body.page-content .top-post-cat.light-red-color {
  background-color: #f45454;
}

body.page-news .top-post-cat.dark-blue-color, body.node-type-tep-blogs .top-post-cat.dark-blue-color, body.page-guides .top-post-cat.dark-blue-color, body.page-edgeproptv .top-post-cat.dark-blue-color, body.page-pullout .top-post-cat.dark-blue-color, body.page-events .top-post-cat.dark-blue-color, body.page-event .top-post-cat.dark-blue-color, body.page-tags .top-post-cat.dark-blue-color, body.page-node-29 .top-post-cat.dark-blue-color, body.page-node-34 .top-post-cat.dark-blue-color, body.page-content .top-post-cat.dark-blue-color {
  background-color: #2664d5;
}

body.page-news .top-post-cat.dark-yellow-color, body.node-type-tep-blogs .top-post-cat.dark-yellow-color, body.page-guides .top-post-cat.dark-yellow-color, body.page-edgeproptv .top-post-cat.dark-yellow-color, body.page-pullout .top-post-cat.dark-yellow-color, body.page-events .top-post-cat.dark-yellow-color, body.page-event .top-post-cat.dark-yellow-color, body.page-tags .top-post-cat.dark-yellow-color, body.page-node-29 .top-post-cat.dark-yellow-color, body.page-node-34 .top-post-cat.dark-yellow-color, body.page-content .top-post-cat.dark-yellow-color {
  background-color: #f7c201;
}

body.page-news .top-post-cat.dark-pink-color, body.node-type-tep-blogs .top-post-cat.dark-pink-color, body.page-guides .top-post-cat.dark-pink-color, body.page-edgeproptv .top-post-cat.dark-pink-color, body.page-pullout .top-post-cat.dark-pink-color, body.page-events .top-post-cat.dark-pink-color, body.page-event .top-post-cat.dark-pink-color, body.page-tags .top-post-cat.dark-pink-color, body.page-node-29 .top-post-cat.dark-pink-color, body.page-node-34 .top-post-cat.dark-pink-color, body.page-content .top-post-cat.dark-pink-color {
  background-color: #dc127a;
}

body.page-news .top-post-cat.dark-green-color, body.node-type-tep-blogs .top-post-cat.dark-green-color, body.page-guides .top-post-cat.dark-green-color, body.page-edgeproptv .top-post-cat.dark-green-color, body.page-pullout .top-post-cat.dark-green-color, body.page-events .top-post-cat.dark-green-color, body.page-event .top-post-cat.dark-green-color, body.page-tags .top-post-cat.dark-green-color, body.page-node-29 .top-post-cat.dark-green-color, body.page-node-34 .top-post-cat.dark-green-color, body.page-content .top-post-cat.dark-green-color {
  background-color: #50b36c;
}

body.page-news .top-post-cat:hover, body.node-type-tep-blogs .top-post-cat:hover, body.page-guides .top-post-cat:hover, body.page-edgeproptv .top-post-cat:hover, body.page-pullout .top-post-cat:hover, body.page-events .top-post-cat:hover, body.page-event .top-post-cat:hover, body.page-tags .top-post-cat:hover, body.page-node-29 .top-post-cat:hover, body.page-node-34 .top-post-cat:hover, body.page-content .top-post-cat:hover {
  background-color: #fff;
  color: #1a1a1a;
}

body.page-news .wpsg-image-cover .top-post-title, body.node-type-tep-blogs .wpsg-image-cover .top-post-title, body.page-guides .wpsg-image-cover .top-post-title, body.page-edgeproptv .wpsg-image-cover .top-post-title, body.page-pullout .wpsg-image-cover .top-post-title, body.page-events .wpsg-image-cover .top-post-title, body.page-event .wpsg-image-cover .top-post-title, body.page-tags .wpsg-image-cover .top-post-title, body.page-node-29 .wpsg-image-cover .top-post-title, body.page-node-34 .wpsg-image-cover .top-post-title, body.page-content .wpsg-image-cover .top-post-title {
  font-size: 30px;
  line-height: 36px;
}

body.page-news .list-categories, body.node-type-tep-blogs .list-categories, body.page-guides .list-categories, body.page-edgeproptv .list-categories, body.page-pullout .list-categories, body.page-events .list-categories, body.page-event .list-categories, body.page-tags .list-categories, body.page-node-29 .list-categories, body.page-node-34 .list-categories, body.page-content .list-categories {
  border-bottom: 2px solid #1a1a1a;
  padding-bottom: 12px;
  margin-bottom: 22px;
  text-align: right;
}

body.page-news .list-categories a, body.node-type-tep-blogs .list-categories a, body.page-guides .list-categories a, body.page-edgeproptv .list-categories a, body.page-pullout .list-categories a, body.page-events .list-categories a, body.page-event .list-categories a, body.page-tags .list-categories a, body.page-node-29 .list-categories a, body.page-node-34 .list-categories a, body.page-content .list-categories a {
  font-size: 14px;
  line-height: 18px;
  padding-bottom: 6px;
  margin-left: 24px;
  text-transform: capitalize;
  color: #666;
}

body.page-news .list-categories a:hover, body.node-type-tep-blogs .list-categories a:hover, body.page-guides .list-categories a:hover, body.page-edgeproptv .list-categories a:hover, body.page-pullout .list-categories a:hover, body.page-events .list-categories a:hover, body.page-event .list-categories a:hover, body.page-tags .list-categories a:hover, body.page-node-29 .list-categories a:hover, body.page-node-34 .list-categories a:hover, body.page-content .list-categories a:hover {
  color: #1a1a1a;
}

body.page-news .list-categories a:first-of-type, body.node-type-tep-blogs .list-categories a:first-of-type, body.page-guides .list-categories a:first-of-type, body.page-edgeproptv .list-categories a:first-of-type, body.page-pullout .list-categories a:first-of-type, body.page-events .list-categories a:first-of-type, body.page-event .list-categories a:first-of-type, body.page-tags .list-categories a:first-of-type, body.page-node-29 .list-categories a:first-of-type, body.page-node-34 .list-categories a:first-of-type, body.page-content .list-categories a:first-of-type {
  float: left;
  font-weight: 600;
  margin-left: 0;
  font-size: 20px;
  letter-spacing: 1px;
  text-transform: uppercase;
  color: #fff;
  text-decoration: none;
}

body.page-news .custom-navigation, body.node-type-tep-blogs .custom-navigation, body.page-guides .custom-navigation, body.page-edgeproptv .custom-navigation, body.page-pullout .custom-navigation, body.page-events .custom-navigation, body.page-event .custom-navigation, body.page-tags .custom-navigation, body.page-node-29 .custom-navigation, body.page-node-34 .custom-navigation, body.page-content .custom-navigation {
  float: right;
}

body.page-news .custom-navigation span, body.node-type-tep-blogs .custom-navigation span, body.page-guides .custom-navigation span, body.page-edgeproptv .custom-navigation span, body.page-pullout .custom-navigation span, body.page-events .custom-navigation span, body.page-event .custom-navigation span, body.page-tags .custom-navigation span, body.page-node-29 .custom-navigation span, body.page-node-34 .custom-navigation span, body.page-content .custom-navigation span {
  cursor: pointer;
  font-size: 14px;
  padding: 3px 9px;
  background-color: #e6e6e6;
  color: #666;
  margin-left: 10px;
}

body.page-news .custom-navigation span:hover, body.node-type-tep-blogs .custom-navigation span:hover, body.page-guides .custom-navigation span:hover, body.page-edgeproptv .custom-navigation span:hover, body.page-pullout .custom-navigation span:hover, body.page-events .custom-navigation span:hover, body.page-event .custom-navigation span:hover, body.page-tags .custom-navigation span:hover, body.page-node-29 .custom-navigation span:hover, body.page-node-34 .custom-navigation span:hover, body.page-content .custom-navigation span:hover {
  background-color: #1a1a1a;
  color: #fff;
}

body.page-news .post-layout-2 .post-layout, body.node-type-tep-blogs .post-layout-2 .post-layout, body.page-guides .post-layout-2 .post-layout, body.page-edgeproptv .post-layout-2 .post-layout, body.page-pullout .post-layout-2 .post-layout, body.page-events .post-layout-2 .post-layout, body.page-event .post-layout-2 .post-layout, body.page-tags .post-layout-2 .post-layout, body.page-node-29 .post-layout-2 .post-layout, body.page-node-34 .post-layout-2 .post-layout, body.page-content .post-layout-2 .post-layout {
  letter-spacing: -5px;
}

body.page-news .post-layout-2 article, body.node-type-tep-blogs .post-layout-2 article, body.page-guides .post-layout-2 article, body.page-edgeproptv .post-layout-2 article, body.page-pullout .post-layout-2 article, body.page-events .post-layout-2 article, body.page-event .post-layout-2 article, body.page-tags .post-layout-2 article, body.page-node-29 .post-layout-2 article, body.page-node-34 .post-layout-2 article, body.page-content .post-layout-2 article {
  letter-spacing: 0;
  display: inline-block;
  width: 50%;
  vertical-align: top;
}

body.page-news .post-layout-2 article:nth-of-type(2n+1), body.node-type-tep-blogs .post-layout-2 article:nth-of-type(2n+1), body.page-guides .post-layout-2 article:nth-of-type(2n+1), body.page-edgeproptv .post-layout-2 article:nth-of-type(2n+1), body.page-pullout .post-layout-2 article:nth-of-type(2n+1), body.page-events .post-layout-2 article:nth-of-type(2n+1), body.page-event .post-layout-2 article:nth-of-type(2n+1), body.page-tags .post-layout-2 article:nth-of-type(2n+1), body.page-node-29 .post-layout-2 article:nth-of-type(2n+1), body.page-node-34 .post-layout-2 article:nth-of-type(2n+1), body.page-content .post-layout-2 article:nth-of-type(2n+1) {
  padding-right: 19px;
}

body.page-news .post-layout-2 article:nth-of-type(2n+2), body.node-type-tep-blogs .post-layout-2 article:nth-of-type(2n+2), body.page-guides .post-layout-2 article:nth-of-type(2n+2), body.page-edgeproptv .post-layout-2 article:nth-of-type(2n+2), body.page-pullout .post-layout-2 article:nth-of-type(2n+2), body.page-events .post-layout-2 article:nth-of-type(2n+2), body.page-event .post-layout-2 article:nth-of-type(2n+2), body.page-tags .post-layout-2 article:nth-of-type(2n+2), body.page-node-29 .post-layout-2 article:nth-of-type(2n+2), body.page-node-34 .post-layout-2 article:nth-of-type(2n+2), body.page-content .post-layout-2 article:nth-of-type(2n+2) {
  padding-left: 19px;
}

body.page-news .col-in-col, body.node-type-tep-blogs .col-in-col, body.page-guides .col-in-col, body.page-edgeproptv .col-in-col, body.page-pullout .col-in-col, body.page-events .col-in-col, body.page-event .col-in-col, body.page-tags .col-in-col, body.page-node-29 .col-in-col, body.page-node-34 .col-in-col, body.page-content .col-in-col {
  letter-spacing: -5px;
}

body.page-news .col-in-col article, body.node-type-tep-blogs .col-in-col article, body.page-guides .col-in-col article, body.page-edgeproptv .col-in-col article, body.page-pullout .col-in-col article, body.page-events .col-in-col article, body.page-event .col-in-col article, body.page-tags .col-in-col article, body.page-node-29 .col-in-col article, body.page-node-34 .col-in-col article, body.page-content .col-in-col article {
  letter-spacing: 0;
  display: inline-block;
  vertical-align: top;
  width: 50%;
}

body.page-news .col-in-col article:nth-of-type(2n+1), body.node-type-tep-blogs .col-in-col article:nth-of-type(2n+1), body.page-guides .col-in-col article:nth-of-type(2n+1), body.page-edgeproptv .col-in-col article:nth-of-type(2n+1), body.page-pullout .col-in-col article:nth-of-type(2n+1), body.page-events .col-in-col article:nth-of-type(2n+1), body.page-event .col-in-col article:nth-of-type(2n+1), body.page-tags .col-in-col article:nth-of-type(2n+1), body.page-node-29 .col-in-col article:nth-of-type(2n+1), body.page-node-34 .col-in-col article:nth-of-type(2n+1), body.page-content .col-in-col article:nth-of-type(2n+1) {
  padding-right: 13px;
}

body.page-news .col-in-col article:nth-of-type(2n), body.node-type-tep-blogs .col-in-col article:nth-of-type(2n), body.page-guides .col-in-col article:nth-of-type(2n), body.page-edgeproptv .col-in-col article:nth-of-type(2n), body.page-pullout .col-in-col article:nth-of-type(2n), body.page-events .col-in-col article:nth-of-type(2n), body.page-event .col-in-col article:nth-of-type(2n), body.page-tags .col-in-col article:nth-of-type(2n), body.page-node-29 .col-in-col article:nth-of-type(2n), body.page-node-34 .col-in-col article:nth-of-type(2n), body.page-content .col-in-col article:nth-of-type(2n) {
  padding-left: 13px;
}

body.page-news .post-layout-3 .col-in-col .entry-title, body.node-type-tep-blogs .post-layout-3 .col-in-col .entry-title, body.page-guides .post-layout-3 .col-in-col .entry-title, body.page-edgeproptv .post-layout-3 .col-in-col .entry-title, body.page-pullout .post-layout-3 .col-in-col .entry-title, body.page-events .post-layout-3 .col-in-col .entry-title, body.page-event .post-layout-3 .col-in-col .entry-title, body.page-tags .post-layout-3 .col-in-col .entry-title, body.page-node-29 .post-layout-3 .col-in-col .entry-title, body.page-node-34 .post-layout-3 .col-in-col .entry-title, body.page-content .post-layout-3 .col-in-col .entry-title {
  margin-bottom: 0;
}

body.page-news .post-layout-4 .post-layout, body.node-type-tep-blogs .post-layout-4 .post-layout, body.page-guides .post-layout-4 .post-layout, body.page-edgeproptv .post-layout-4 .post-layout, body.page-pullout .post-layout-4 .post-layout, body.page-events .post-layout-4 .post-layout, body.page-event .post-layout-4 .post-layout, body.page-tags .post-layout-4 .post-layout, body.page-node-29 .post-layout-4 .post-layout, body.page-node-34 .post-layout-4 .post-layout, body.page-content .post-layout-4 .post-layout {
  letter-spacing: -5px;
}

body.page-news .post-layout-4 article, body.node-type-tep-blogs .post-layout-4 article, body.page-guides .post-layout-4 article, body.page-edgeproptv .post-layout-4 article, body.page-pullout .post-layout-4 article, body.page-events .post-layout-4 article, body.page-event .post-layout-4 article, body.page-tags .post-layout-4 article, body.page-node-29 .post-layout-4 article, body.page-node-34 .post-layout-4 article, body.page-content .post-layout-4 article {
  letter-spacing: 0;
  display: inline-block;
  width: 50%;
  vertical-align: top;
}

body.page-news .post-layout-4 article:nth-of-type(2n+1), body.node-type-tep-blogs .post-layout-4 article:nth-of-type(2n+1), body.page-guides .post-layout-4 article:nth-of-type(2n+1), body.page-edgeproptv .post-layout-4 article:nth-of-type(2n+1), body.page-pullout .post-layout-4 article:nth-of-type(2n+1), body.page-events .post-layout-4 article:nth-of-type(2n+1), body.page-event .post-layout-4 article:nth-of-type(2n+1), body.page-tags .post-layout-4 article:nth-of-type(2n+1), body.page-node-29 .post-layout-4 article:nth-of-type(2n+1), body.page-node-34 .post-layout-4 article:nth-of-type(2n+1), body.page-content .post-layout-4 article:nth-of-type(2n+1) {
  padding-left: 19px;
}

body.page-news .post-layout-4 article:nth-of-type(2n+2), body.node-type-tep-blogs .post-layout-4 article:nth-of-type(2n+2), body.page-guides .post-layout-4 article:nth-of-type(2n+2), body.page-edgeproptv .post-layout-4 article:nth-of-type(2n+2), body.page-pullout .post-layout-4 article:nth-of-type(2n+2), body.page-events .post-layout-4 article:nth-of-type(2n+2), body.page-event .post-layout-4 article:nth-of-type(2n+2), body.page-tags .post-layout-4 article:nth-of-type(2n+2), body.page-node-29 .post-layout-4 article:nth-of-type(2n+2), body.page-node-34 .post-layout-4 article:nth-of-type(2n+2), body.page-content .post-layout-4 article:nth-of-type(2n+2) {
  padding-right: 19px;
}

body.page-news .post-layout-4 article:first-of-type, body.node-type-tep-blogs .post-layout-4 article:first-of-type, body.page-guides .post-layout-4 article:first-of-type, body.page-edgeproptv .post-layout-4 article:first-of-type, body.page-pullout .post-layout-4 article:first-of-type, body.page-events .post-layout-4 article:first-of-type, body.page-event .post-layout-4 article:first-of-type, body.page-tags .post-layout-4 article:first-of-type, body.page-node-29 .post-layout-4 article:first-of-type, body.page-node-34 .post-layout-4 article:first-of-type, body.page-content .post-layout-4 article:first-of-type {
  width: 100%;
  padding: 0 !important;
}

body.page-news .post-layout-4 article:first-of-type .box-thumb .post-thumb, body.node-type-tep-blogs .post-layout-4 article:first-of-type .box-thumb .post-thumb, body.page-guides .post-layout-4 article:first-of-type .box-thumb .post-thumb, body.page-edgeproptv .post-layout-4 article:first-of-type .box-thumb .post-thumb, body.page-pullout .post-layout-4 article:first-of-type .box-thumb .post-thumb, body.page-events .post-layout-4 article:first-of-type .box-thumb .post-thumb, body.page-event .post-layout-4 article:first-of-type .box-thumb .post-thumb, body.page-tags .post-layout-4 article:first-of-type .box-thumb .post-thumb, body.page-node-29 .post-layout-4 article:first-of-type .box-thumb .post-thumb, body.page-node-34 .post-layout-4 article:first-of-type .box-thumb .post-thumb, body.page-content .post-layout-4 article:first-of-type .box-thumb .post-thumb {
  width: 244px;
}

body.page-news .post-layout-4 article:first-of-type .box-details, body.node-type-tep-blogs .post-layout-4 article:first-of-type .box-details, body.page-guides .post-layout-4 article:first-of-type .box-details, body.page-edgeproptv .post-layout-4 article:first-of-type .box-details, body.page-pullout .post-layout-4 article:first-of-type .box-details, body.page-events .post-layout-4 article:first-of-type .box-details, body.page-event .post-layout-4 article:first-of-type .box-details, body.page-tags .post-layout-4 article:first-of-type .box-details, body.page-node-29 .post-layout-4 article:first-of-type .box-details, body.page-node-34 .post-layout-4 article:first-of-type .box-details, body.page-content .post-layout-4 article:first-of-type .box-details {
  margin-left: 276px;
  padding-top: 16px;
}

body.page-news .post-layout-6 article:first-of-type .entry-details, body.node-type-tep-blogs .post-layout-6 article:first-of-type .entry-details, body.page-guides .post-layout-6 article:first-of-type .entry-details, body.page-edgeproptv .post-layout-6 article:first-of-type .entry-details, body.page-pullout .post-layout-6 article:first-of-type .entry-details, body.page-events .post-layout-6 article:first-of-type .entry-details, body.page-event .post-layout-6 article:first-of-type .entry-details, body.page-tags .post-layout-6 article:first-of-type .entry-details, body.page-node-29 .post-layout-6 article:first-of-type .entry-details, body.page-node-34 .post-layout-6 article:first-of-type .entry-details, body.page-content .post-layout-6 article:first-of-type .entry-details {
  position: absolute;
  left: 0;
  bottom: 0;
  padding: 34px 32px;
  z-index: 2;
}

body.page-news .post-layout-6 article .entry-meta a:hover, body.node-type-tep-blogs .post-layout-6 article .entry-meta a:hover, body.page-guides .post-layout-6 article .entry-meta a:hover, body.page-edgeproptv .post-layout-6 article .entry-meta a:hover, body.page-pullout .post-layout-6 article .entry-meta a:hover, body.page-events .post-layout-6 article .entry-meta a:hover, body.page-event .post-layout-6 article .entry-meta a:hover, body.page-tags .post-layout-6 article .entry-meta a:hover, body.page-node-29 .post-layout-6 article .entry-meta a:hover, body.page-node-34 .post-layout-6 article .entry-meta a:hover, body.page-content .post-layout-6 article .entry-meta a:hover {
  /* Theoretically for IE 8 & 9 (more valid) */
  /* ...but not required as filter works too */
  /* should come BEFORE filter */
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
  /* This works in IE 8 & 9 too */
  /* ... but also 5, 6, 7 */
  filter: alpha(opacity=100);
  /* Older than Firefox 0.9 */
  -moz-opacity: 1;
  /* Safari 1.x (pre WebKit!) */
  -khtml-opacity: 1;
  /* Modern!
                                /* Firefox 0.9+, Safari 2?, Chrome any?
                                /* Opera 9+, IE 9+ */
  opacity: 1;
}

body.page-news .post-latest .entry-thumb, body.node-type-tep-blogs .post-latest .entry-thumb, body.page-guides .post-latest .entry-thumb, body.page-edgeproptv .post-latest .entry-thumb, body.page-pullout .post-latest .entry-thumb, body.page-events .post-latest .entry-thumb, body.page-event .post-latest .entry-thumb, body.page-tags .post-latest .entry-thumb, body.page-node-29 .post-latest .entry-thumb, body.page-node-34 .post-latest .entry-thumb, body.page-content .post-latest .entry-thumb {
  position: relative;
}

body.page-news .post-latest .cat-meta, body.node-type-tep-blogs .post-latest .cat-meta, body.page-guides .post-latest .cat-meta, body.page-edgeproptv .post-latest .cat-meta, body.page-pullout .post-latest .cat-meta, body.page-events .post-latest .cat-meta, body.page-event .post-latest .cat-meta, body.page-tags .post-latest .cat-meta, body.page-node-29 .post-latest .cat-meta, body.page-node-34 .post-latest .cat-meta, body.page-content .post-latest .cat-meta {
  position: absolute;
  bottom: 26px;
  left: 28px;
}

body.page-news .cat-meta a, body.node-type-tep-blogs .cat-meta a, body.page-guides .cat-meta a, body.page-edgeproptv .cat-meta a, body.page-pullout .cat-meta a, body.page-events .cat-meta a, body.page-event .cat-meta a, body.page-tags .cat-meta a, body.page-node-29 .cat-meta a, body.page-node-34 .cat-meta a, body.page-content .cat-meta a {
  font-size: 12px;
  line-height: 14px;
  text-transform: uppercase;
  font-weight: bold;
  padding: 5px 10.5px;
  background-color: #e6e6e6;
  /* Safari 3-4, iOS 1-3.2, Android 1.6- */
  -webkit-border-radius: 25px;
  /* Firefox 1-3.6 */
  -moz-border-radius: 25px;
  /* Opera 10.5, IE 9, Safari 5, Chrome, Firefox 4, iOS 4, Android 2.1+ */
  border-radius: 25px;
}

body.page-news .cat-meta a:hover, body.node-type-tep-blogs .cat-meta a:hover, body.page-guides .cat-meta a:hover, body.page-edgeproptv .cat-meta a:hover, body.page-pullout .cat-meta a:hover, body.page-events .cat-meta a:hover, body.page-event .cat-meta a:hover, body.page-tags .cat-meta a:hover, body.page-node-29 .cat-meta a:hover, body.page-node-34 .cat-meta a:hover, body.page-content .cat-meta a:hover {
  background-color: #fff;
  text-decoration: none;
}

body.page-news .paginate, body.node-type-tep-blogs .paginate, body.page-guides .paginate, body.page-edgeproptv .paginate, body.page-pullout .paginate, body.page-events .paginate, body.page-event .paginate, body.page-tags .paginate, body.page-node-29 .paginate, body.page-node-34 .paginate, body.page-content .paginate {
  padding-bottom: 18px;
  margin-bottom: 40px;
}

body.page-news .page-links a, body.node-type-tep-blogs .page-links a, body.page-guides .page-links a, body.page-edgeproptv .page-links a, body.page-pullout .page-links a, body.page-events .page-links a, body.page-event .page-links a, body.page-tags .page-links a, body.page-node-29 .page-links a, body.page-node-34 .page-links a, body.page-content .page-links a {
  display: inline-block;
}

body.page-news .page-links > span, body.node-type-tep-blogs .page-links > span, body.page-guides .page-links > span, body.page-edgeproptv .page-links > span, body.page-pullout .page-links > span, body.page-events .page-links > span, body.page-event .page-links > span, body.page-tags .page-links > span, body.page-node-29 .page-links > span, body.page-node-34 .page-links > span, body.page-content .page-links > span {
  display: inline-block;
}

body.page-news .page-numbers,
body.page-news .page-links > span,
body.page-news .page-links a, body.node-type-tep-blogs .page-numbers,
body.node-type-tep-blogs .page-links > span,
body.node-type-tep-blogs .page-links a, body.page-guides .page-numbers,
body.page-guides .page-links > span,
body.page-guides .page-links a, body.page-edgeproptv .page-numbers,
body.page-edgeproptv .page-links > span,
body.page-edgeproptv .page-links a, body.page-pullout .page-numbers,
body.page-pullout .page-links > span,
body.page-pullout .page-links a, body.page-events .page-numbers,
body.page-events .page-links > span,
body.page-events .page-links a, body.page-event .page-numbers,
body.page-event .page-links > span,
body.page-event .page-links a, body.page-tags .page-numbers,
body.page-tags .page-links > span,
body.page-tags .page-links a, body.page-node-29 .page-numbers,
body.page-node-29 .page-links > span,
body.page-node-29 .page-links a, body.page-node-34 .page-numbers,
body.page-node-34 .page-links > span,
body.page-node-34 .page-links a, body.page-content .page-numbers,
body.page-content .page-links > span,
body.page-content .page-links a {
  font-size: 14px;
  line-height: 16px;
  font-weight: bold;
  padding: 10px 15px;
  margin-right: 6px;
  margin-bottom: 6px;
}

body.page-news .page-numbers:hover,
body.page-news .page-links a:hover, body.node-type-tep-blogs .page-numbers:hover,
body.node-type-tep-blogs .page-links a:hover, body.page-guides .page-numbers:hover,
body.page-guides .page-links a:hover, body.page-edgeproptv .page-numbers:hover,
body.page-edgeproptv .page-links a:hover, body.page-pullout .page-numbers:hover,
body.page-pullout .page-links a:hover, body.page-events .page-numbers:hover,
body.page-events .page-links a:hover, body.page-event .page-numbers:hover,
body.page-event .page-links a:hover, body.page-tags .page-numbers:hover,
body.page-tags .page-links a:hover, body.page-node-29 .page-numbers:hover,
body.page-node-29 .page-links a:hover, body.page-node-34 .page-numbers:hover,
body.page-node-34 .page-links a:hover, body.page-content .page-numbers:hover,
body.page-content .page-links a:hover {
  background-color: #e6e6e6;
}

body.page-news .current,
body.page-news .page-links > span, body.node-type-tep-blogs .current,
body.node-type-tep-blogs .page-links > span, body.page-guides .current,
body.page-guides .page-links > span, body.page-edgeproptv .current,
body.page-edgeproptv .page-links > span, body.page-pullout .current,
body.page-pullout .page-links > span, body.page-events .current,
body.page-events .page-links > span, body.page-event .current,
body.page-event .page-links > span, body.page-tags .current,
body.page-tags .page-links > span, body.page-node-29 .current,
body.page-node-29 .page-links > span, body.page-node-34 .current,
body.page-node-34 .page-links > span, body.page-content .current,
body.page-content .page-links > span {
  background-color: #488BF8;
  color: #fff;
}

body.page-news .element-box .blue-color, body.node-type-tep-blogs .element-box .blue-color, body.page-guides .element-box .blue-color, body.page-edgeproptv .element-box .blue-color, body.page-pullout .element-box .blue-color, body.page-events .element-box .blue-color, body.page-event .element-box .blue-color, body.page-tags .element-box .blue-color, body.page-node-29 .element-box .blue-color, body.page-node-34 .element-box .blue-color, body.page-content .element-box .blue-color {
  border-bottom-color: #46c4f3;
}

body.page-news .blue-color a:first-of-type, body.node-type-tep-blogs .blue-color a:first-of-type, body.page-guides .blue-color a:first-of-type, body.page-edgeproptv .blue-color a:first-of-type, body.page-pullout .blue-color a:first-of-type, body.page-events .blue-color a:first-of-type, body.page-event .blue-color a:first-of-type, body.page-tags .blue-color a:first-of-type, body.page-node-29 .blue-color a:first-of-type, body.page-node-34 .blue-color a:first-of-type, body.page-content .blue-color a:first-of-type {
  color: #46c4f3;
}

body.page-news .element-box .black-color, body.node-type-tep-blogs .element-box .black-color, body.page-guides .element-box .black-color, body.page-edgeproptv .element-box .black-color, body.page-pullout .element-box .black-color, body.page-events .element-box .black-color, body.page-event .element-box .black-color, body.page-tags .element-box .black-color, body.page-node-29 .element-box .black-color, body.page-node-34 .element-box .black-color, body.page-content .element-box .black-color {
  border-bottom-color: #000;
}

body.page-news .black-color a:first-of-type, body.node-type-tep-blogs .black-color a:first-of-type, body.page-guides .black-color a:first-of-type, body.page-edgeproptv .black-color a:first-of-type, body.page-pullout .black-color a:first-of-type, body.page-events .black-color a:first-of-type, body.page-event .black-color a:first-of-type, body.page-tags .black-color a:first-of-type, body.page-node-29 .black-color a:first-of-type, body.page-node-34 .black-color a:first-of-type, body.page-content .black-color a:first-of-type {
  color: #000;
}

body.page-news .element-box .green-color, body.node-type-tep-blogs .element-box .green-color, body.page-guides .element-box .green-color, body.page-edgeproptv .element-box .green-color, body.page-pullout .element-box .green-color, body.page-events .element-box .green-color, body.page-event .element-box .green-color, body.page-tags .element-box .green-color, body.page-node-29 .element-box .green-color, body.page-node-34 .element-box .green-color, body.page-content .element-box .green-color {
  border-bottom-color: #8bc527;
}

body.page-news .green-color a:first-of-type, body.node-type-tep-blogs .green-color a:first-of-type, body.page-guides .green-color a:first-of-type, body.page-edgeproptv .green-color a:first-of-type, body.page-pullout .green-color a:first-of-type, body.page-events .green-color a:first-of-type, body.page-event .green-color a:first-of-type, body.page-tags .green-color a:first-of-type, body.page-node-29 .green-color a:first-of-type, body.page-node-34 .green-color a:first-of-type, body.page-content .green-color a:first-of-type {
  color: #8bc527;
}

body.page-news .element-box .pink-color, body.node-type-tep-blogs .element-box .pink-color, body.page-guides .element-box .pink-color, body.page-edgeproptv .element-box .pink-color, body.page-pullout .element-box .pink-color, body.page-events .element-box .pink-color, body.page-event .element-box .pink-color, body.page-tags .element-box .pink-color, body.page-node-29 .element-box .pink-color, body.page-node-34 .element-box .pink-color, body.page-content .element-box .pink-color {
  border-bottom-color: #f570d4;
}

body.page-news .pink-color a:first-of-type, body.node-type-tep-blogs .pink-color a:first-of-type, body.page-guides .pink-color a:first-of-type, body.page-edgeproptv .pink-color a:first-of-type, body.page-pullout .pink-color a:first-of-type, body.page-events .pink-color a:first-of-type, body.page-event .pink-color a:first-of-type, body.page-tags .pink-color a:first-of-type, body.page-node-29 .pink-color a:first-of-type, body.page-node-34 .pink-color a:first-of-type, body.page-content .pink-color a:first-of-type {
  color: #f570d4;
}

body.page-news .show-more-latest, body.node-type-tep-blogs .show-more-latest, body.page-guides .show-more-latest, body.page-edgeproptv .show-more-latest, body.page-pullout .show-more-latest, body.page-events .show-more-latest, body.page-event .show-more-latest, body.page-tags .show-more-latest, body.page-node-29 .show-more-latest, body.page-node-34 .show-more-latest, body.page-content .show-more-latest {
  color: #f570d4 !important;
}

body.page-news .show-more-indepth, body.node-type-tep-blogs .show-more-indepth, body.page-guides .show-more-indepth, body.page-edgeproptv .show-more-indepth, body.page-pullout .show-more-indepth, body.page-events .show-more-indepth, body.page-event .show-more-indepth, body.page-tags .show-more-indepth, body.page-node-29 .show-more-indepth, body.page-node-34 .show-more-indepth, body.page-content .show-more-indepth {
  color: #46c4f3 !important;
}

body.page-news .show-more-epick, body.node-type-tep-blogs .show-more-epick, body.page-guides .show-more-epick, body.page-edgeproptv .show-more-epick, body.page-pullout .show-more-epick, body.page-events .show-more-epick, body.page-event .show-more-epick, body.page-tags .show-more-epick, body.page-node-29 .show-more-epick, body.page-node-34 .show-more-epick, body.page-content .show-more-epick {
  color: #f7c201 !important;
}

body.page-news .alignment, body.node-type-tep-blogs .alignment, body.page-guides .alignment, body.page-edgeproptv .alignment, body.page-pullout .alignment, body.page-events .alignment, body.page-event .alignment, body.page-tags .alignment, body.page-node-29 .alignment, body.page-node-34 .alignment, body.page-content .alignment {
  text-decoration: none !important;
  font-weight: 600;
  border: 1px solid;
  padding: 5px;
  margin-bottom: 5px;
}

body.page-news .one-line, body.node-type-tep-blogs .one-line, body.page-guides .one-line, body.page-edgeproptv .one-line, body.page-pullout .one-line, body.page-events .one-line, body.page-event .one-line, body.page-tags .one-line, body.page-node-29 .one-line, body.page-node-34 .one-line, body.page-content .one-line {
  display: flex;
}

body.page-news .newside, body.node-type-tep-blogs .newside, body.page-guides .newside, body.page-edgeproptv .newside, body.page-pullout .newside, body.page-events .newside, body.page-event .newside, body.page-tags .newside, body.page-node-29 .newside, body.page-node-34 .newside, body.page-content .newside {
  width: 50%;
  text-align: left;
}

body.page-news .element-box .purple-color, body.node-type-tep-blogs .element-box .purple-color, body.page-guides .element-box .purple-color, body.page-edgeproptv .element-box .purple-color, body.page-pullout .element-box .purple-color, body.page-events .element-box .purple-color, body.page-event .element-box .purple-color, body.page-tags .element-box .purple-color, body.page-node-29 .element-box .purple-color, body.page-node-34 .element-box .purple-color, body.page-content .element-box .purple-color {
  border-bottom-color: #772edb;
}

body.page-news .purple-color a:first-of-type, body.node-type-tep-blogs .purple-color a:first-of-type, body.page-guides .purple-color a:first-of-type, body.page-edgeproptv .purple-color a:first-of-type, body.page-pullout .purple-color a:first-of-type, body.page-events .purple-color a:first-of-type, body.page-event .purple-color a:first-of-type, body.page-tags .purple-color a:first-of-type, body.page-node-29 .purple-color a:first-of-type, body.page-node-34 .purple-color a:first-of-type, body.page-content .purple-color a:first-of-type {
  color: #772edb;
}

body.page-news .element-box .orange-color, body.node-type-tep-blogs .element-box .orange-color, body.page-guides .element-box .orange-color, body.page-edgeproptv .element-box .orange-color, body.page-pullout .element-box .orange-color, body.page-events .element-box .orange-color, body.page-event .element-box .orange-color, body.page-tags .element-box .orange-color, body.page-node-29 .element-box .orange-color, body.page-node-34 .element-box .orange-color, body.page-content .element-box .orange-color {
  border-bottom-color: #fc9322;
}

body.page-news .orange-color a:first-of-type, body.node-type-tep-blogs .orange-color a:first-of-type, body.page-guides .orange-color a:first-of-type, body.page-edgeproptv .orange-color a:first-of-type, body.page-pullout .orange-color a:first-of-type, body.page-events .orange-color a:first-of-type, body.page-event .orange-color a:first-of-type, body.page-tags .orange-color a:first-of-type, body.page-node-29 .orange-color a:first-of-type, body.page-node-34 .orange-color a:first-of-type, body.page-content .orange-color a:first-of-type {
  color: #fc9322;
}

body.page-news .element-box .light-red-color, body.node-type-tep-blogs .element-box .light-red-color, body.page-guides .element-box .light-red-color, body.page-edgeproptv .element-box .light-red-color, body.page-pullout .element-box .light-red-color, body.page-events .element-box .light-red-color, body.page-event .element-box .light-red-color, body.page-tags .element-box .light-red-color, body.page-node-29 .element-box .light-red-color, body.page-node-34 .element-box .light-red-color, body.page-content .element-box .light-red-color {
  border-bottom-color: #f45454;
}

body.page-news .light-red-color a:first-of-type, body.node-type-tep-blogs .light-red-color a:first-of-type, body.page-guides .light-red-color a:first-of-type, body.page-edgeproptv .light-red-color a:first-of-type, body.page-pullout .light-red-color a:first-of-type, body.page-events .light-red-color a:first-of-type, body.page-event .light-red-color a:first-of-type, body.page-tags .light-red-color a:first-of-type, body.page-node-29 .light-red-color a:first-of-type, body.page-node-34 .light-red-color a:first-of-type, body.page-content .light-red-color a:first-of-type {
  color: #f45454;
}

body.page-news .element-box .dark-blue-color, body.node-type-tep-blogs .element-box .dark-blue-color, body.page-guides .element-box .dark-blue-color, body.page-edgeproptv .element-box .dark-blue-color, body.page-pullout .element-box .dark-blue-color, body.page-events .element-box .dark-blue-color, body.page-event .element-box .dark-blue-color, body.page-tags .element-box .dark-blue-color, body.page-node-29 .element-box .dark-blue-color, body.page-node-34 .element-box .dark-blue-color, body.page-content .element-box .dark-blue-color {
  border-bottom-color: #2664d5;
}

body.page-news .dark-blue-color a:first-of-type, body.node-type-tep-blogs .dark-blue-color a:first-of-type, body.page-guides .dark-blue-color a:first-of-type, body.page-edgeproptv .dark-blue-color a:first-of-type, body.page-pullout .dark-blue-color a:first-of-type, body.page-events .dark-blue-color a:first-of-type, body.page-event .dark-blue-color a:first-of-type, body.page-tags .dark-blue-color a:first-of-type, body.page-node-29 .dark-blue-color a:first-of-type, body.page-node-34 .dark-blue-color a:first-of-type, body.page-content .dark-blue-color a:first-of-type {
  color: #2664d5;
}

body.page-news .element-box .dark-yellow-color, body.node-type-tep-blogs .element-box .dark-yellow-color, body.page-guides .element-box .dark-yellow-color, body.page-edgeproptv .element-box .dark-yellow-color, body.page-pullout .element-box .dark-yellow-color, body.page-events .element-box .dark-yellow-color, body.page-event .element-box .dark-yellow-color, body.page-tags .element-box .dark-yellow-color, body.page-node-29 .element-box .dark-yellow-color, body.page-node-34 .element-box .dark-yellow-color, body.page-content .element-box .dark-yellow-color {
  border-bottom-color: #f7c201;
}

body.page-news .dark-yellow-color a:first-of-type, body.node-type-tep-blogs .dark-yellow-color a:first-of-type, body.page-guides .dark-yellow-color a:first-of-type, body.page-edgeproptv .dark-yellow-color a:first-of-type, body.page-pullout .dark-yellow-color a:first-of-type, body.page-events .dark-yellow-color a:first-of-type, body.page-event .dark-yellow-color a:first-of-type, body.page-tags .dark-yellow-color a:first-of-type, body.page-node-29 .dark-yellow-color a:first-of-type, body.page-node-34 .dark-yellow-color a:first-of-type, body.page-content .dark-yellow-color a:first-of-type {
  color: #f7c201;
}

body.page-news .element-box .dark-pink-color, body.node-type-tep-blogs .element-box .dark-pink-color, body.page-guides .element-box .dark-pink-color, body.page-edgeproptv .element-box .dark-pink-color, body.page-pullout .element-box .dark-pink-color, body.page-events .element-box .dark-pink-color, body.page-event .element-box .dark-pink-color, body.page-tags .element-box .dark-pink-color, body.page-node-29 .element-box .dark-pink-color, body.page-node-34 .element-box .dark-pink-color, body.page-content .element-box .dark-pink-color {
  border-bottom-color: #dc127a;
}

body.page-news .dark-pink-color a:first-of-type, body.node-type-tep-blogs .dark-pink-color a:first-of-type, body.page-guides .dark-pink-color a:first-of-type, body.page-edgeproptv .dark-pink-color a:first-of-type, body.page-pullout .dark-pink-color a:first-of-type, body.page-events .dark-pink-color a:first-of-type, body.page-event .dark-pink-color a:first-of-type, body.page-tags .dark-pink-color a:first-of-type, body.page-node-29 .dark-pink-color a:first-of-type, body.page-node-34 .dark-pink-color a:first-of-type, body.page-content .dark-pink-color a:first-of-type {
  color: #dc127a;
}

body.page-news .element-box .dark-green-color, body.node-type-tep-blogs .element-box .dark-green-color, body.page-guides .element-box .dark-green-color, body.page-edgeproptv .element-box .dark-green-color, body.page-pullout .element-box .dark-green-color, body.page-events .element-box .dark-green-color, body.page-event .element-box .dark-green-color, body.page-tags .element-box .dark-green-color, body.page-node-29 .element-box .dark-green-color, body.page-node-34 .element-box .dark-green-color, body.page-content .element-box .dark-green-color {
  border-bottom-color: #50b36c;
}

body.page-news .dark-green-color a:first-of-type, body.node-type-tep-blogs .dark-green-color a:first-of-type, body.page-guides .dark-green-color a:first-of-type, body.page-edgeproptv .dark-green-color a:first-of-type, body.page-pullout .dark-green-color a:first-of-type, body.page-events .dark-green-color a:first-of-type, body.page-event .dark-green-color a:first-of-type, body.page-tags .dark-green-color a:first-of-type, body.page-node-29 .dark-green-color a:first-of-type, body.page-node-34 .dark-green-color a:first-of-type, body.page-content .dark-green-color a:first-of-type {
  color: #50b36c;
}

body.page-news .embed_youtube, body.node-type-tep-blogs .embed_youtube, body.page-guides .embed_youtube, body.page-edgeproptv .embed_youtube, body.page-pullout .embed_youtube, body.page-events .embed_youtube, body.page-event .embed_youtube, body.page-tags .embed_youtube, body.page-node-29 .embed_youtube, body.page-node-34 .embed_youtube, body.page-content .embed_youtube {
  position: relative;
  /* keeps the aspect ratio */
  padding-bottom: 56.25%;
  height: 0;
  overflow: hidden;
}

body.page-news .embed_youtube iframe,
body.page-news .embed_youtube object,
body.page-news .embed_youtube embed, body.node-type-tep-blogs .embed_youtube iframe,
body.node-type-tep-blogs .embed_youtube object,
body.node-type-tep-blogs .embed_youtube embed, body.page-guides .embed_youtube iframe,
body.page-guides .embed_youtube object,
body.page-guides .embed_youtube embed, body.page-edgeproptv .embed_youtube iframe,
body.page-edgeproptv .embed_youtube object,
body.page-edgeproptv .embed_youtube embed, body.page-pullout .embed_youtube iframe,
body.page-pullout .embed_youtube object,
body.page-pullout .embed_youtube embed, body.page-events .embed_youtube iframe,
body.page-events .embed_youtube object,
body.page-events .embed_youtube embed, body.page-event .embed_youtube iframe,
body.page-event .embed_youtube object,
body.page-event .embed_youtube embed, body.page-tags .embed_youtube iframe,
body.page-tags .embed_youtube object,
body.page-tags .embed_youtube embed, body.page-node-29 .embed_youtube iframe,
body.page-node-29 .embed_youtube object,
body.page-node-29 .embed_youtube embed, body.page-node-34 .embed_youtube iframe,
body.page-node-34 .embed_youtube object,
body.page-node-34 .embed_youtube embed, body.page-content .embed_youtube iframe,
body.page-content .embed_youtube object,
body.page-content .embed_youtube embed {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

body.page-news .embed_vimeo, body.node-type-tep-blogs .embed_vimeo, body.page-guides .embed_vimeo, body.page-edgeproptv .embed_vimeo, body.page-pullout .embed_vimeo, body.page-events .embed_vimeo, body.page-event .embed_vimeo, body.page-tags .embed_vimeo, body.page-node-29 .embed_vimeo, body.page-node-34 .embed_vimeo, body.page-content .embed_vimeo {
  position: relative;
  /* keeps the aspect ratio */
  padding-bottom: 56.25%;
  /* fine tunes the video positioning */
  height: 0;
  overflow: hidden;
}

body.page-news .embed_vimeo iframe,
body.page-news .embed_vimeo object,
body.page-news .embed_vimeotainer embed, body.node-type-tep-blogs .embed_vimeo iframe,
body.node-type-tep-blogs .embed_vimeo object,
body.node-type-tep-blogs .embed_vimeotainer embed, body.page-guides .embed_vimeo iframe,
body.page-guides .embed_vimeo object,
body.page-guides .embed_vimeotainer embed, body.page-edgeproptv .embed_vimeo iframe,
body.page-edgeproptv .embed_vimeo object,
body.page-edgeproptv .embed_vimeotainer embed, body.page-pullout .embed_vimeo iframe,
body.page-pullout .embed_vimeo object,
body.page-pullout .embed_vimeotainer embed, body.page-events .embed_vimeo iframe,
body.page-events .embed_vimeo object,
body.page-events .embed_vimeotainer embed, body.page-event .embed_vimeo iframe,
body.page-event .embed_vimeo object,
body.page-event .embed_vimeotainer embed, body.page-tags .embed_vimeo iframe,
body.page-tags .embed_vimeo object,
body.page-tags .embed_vimeotainer embed, body.page-node-29 .embed_vimeo iframe,
body.page-node-29 .embed_vimeo object,
body.page-node-29 .embed_vimeotainer embed, body.page-node-34 .embed_vimeo iframe,
body.page-node-34 .embed_vimeo object,
body.page-node-34 .embed_vimeotainer embed, body.page-content .embed_vimeo iframe,
body.page-content .embed_vimeo object,
body.page-content .embed_vimeotainer embed {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

body.page-news .content select, body.node-type-tep-blogs .content select, body.page-guides .content select, body.page-edgeproptv .content select, body.page-pullout .content select, body.page-events .content select, body.page-event .content select, body.page-tags .content select, body.page-node-29 .content select, body.page-node-34 .content select, body.page-content .content select {
  padding: 12px 0px 10px 12px;
  max-width: 100%;
  margin-bottom: 10px;
}

body.page-news .widget_text .content select, body.node-type-tep-blogs .widget_text .content select, body.page-guides .widget_text .content select, body.page-edgeproptv .widget_text .content select, body.page-pullout .widget_text .content select, body.page-events .widget_text .content select, body.page-event .widget_text .content select, body.page-tags .widget_text .content select, body.page-node-29 .widget_text .content select, body.page-node-34 .widget_text .content select, body.page-content .widget_text .content select {
  margin-bottom: 32px;
}

body.page-news .sidebar .content select, body.node-type-tep-blogs .sidebar .content select, body.page-guides .sidebar .content select, body.page-edgeproptv .sidebar .content select, body.page-pullout .sidebar .content select, body.page-events .sidebar .content select, body.page-event .sidebar .content select, body.page-tags .sidebar .content select, body.page-node-29 .sidebar .content select, body.page-node-34 .sidebar .content select, body.page-content .sidebar .content select {
  width: 100%;
}

body.page-news .sidebar-widget ul, body.node-type-tep-blogs .sidebar-widget ul, body.page-guides .sidebar-widget ul, body.page-edgeproptv .sidebar-widget ul, body.page-pullout .sidebar-widget ul, body.page-events .sidebar-widget ul, body.page-event .sidebar-widget ul, body.page-tags .sidebar-widget ul, body.page-node-29 .sidebar-widget ul, body.page-node-34 .sidebar-widget ul, body.page-content .sidebar-widget ul {
  list-style-type: none;
  padding: 0;
}

body.page-news .sidebar-widget ul li, body.node-type-tep-blogs .sidebar-widget ul li, body.page-guides .sidebar-widget ul li, body.page-edgeproptv .sidebar-widget ul li, body.page-pullout .sidebar-widget ul li, body.page-events .sidebar-widget ul li, body.page-event .sidebar-widget ul li, body.page-tags .sidebar-widget ul li, body.page-node-29 .sidebar-widget ul li, body.page-node-34 .sidebar-widget ul li, body.page-content .sidebar-widget ul li {
  margin-bottom: 0;
}

body.page-news .sidebar-widget ul li a, body.node-type-tep-blogs .sidebar-widget ul li a, body.page-guides .sidebar-widget ul li a, body.page-edgeproptv .sidebar-widget ul li a, body.page-pullout .sidebar-widget ul li a, body.page-events .sidebar-widget ul li a, body.page-event .sidebar-widget ul li a, body.page-tags .sidebar-widget ul li a, body.page-node-29 .sidebar-widget ul li a, body.page-node-34 .sidebar-widget ul li a, body.page-content .sidebar-widget ul li a {
  font-size: 14px;
  line-height: 18px;
  font-weight: normal;
  padding-bottom: 10px;
  color: #999;
}

body.page-news .sidebar-widget ul li .children, body.node-type-tep-blogs .sidebar-widget ul li .children, body.page-guides .sidebar-widget ul li .children, body.page-edgeproptv .sidebar-widget ul li .children, body.page-pullout .sidebar-widget ul li .children, body.page-events .sidebar-widget ul li .children, body.page-event .sidebar-widget ul li .children, body.page-tags .sidebar-widget ul li .children, body.page-node-29 .sidebar-widget ul li .children, body.page-node-34 .sidebar-widget ul li .children, body.page-content .sidebar-widget ul li .children {
  padding-left: 20px;
  margin-bottom: 0;
}

body.page-news .widget_nav_menu .sub-menu, body.node-type-tep-blogs .widget_nav_menu .sub-menu, body.page-guides .widget_nav_menu .sub-menu, body.page-edgeproptv .widget_nav_menu .sub-menu, body.page-pullout .widget_nav_menu .sub-menu, body.page-events .widget_nav_menu .sub-menu, body.page-event .widget_nav_menu .sub-menu, body.page-tags .widget_nav_menu .sub-menu, body.page-node-29 .widget_nav_menu .sub-menu, body.page-node-34 .widget_nav_menu .sub-menu, body.page-content .widget_nav_menu .sub-menu {
  padding-left: 20px;
  margin-bottom: 0;
}

body.page-news .widget_text .textwidget, body.node-type-tep-blogs .widget_text .textwidget, body.page-guides .widget_text .textwidget, body.page-edgeproptv .widget_text .textwidget, body.page-pullout .widget_text .textwidget, body.page-events .widget_text .textwidget, body.page-event .widget_text .textwidget, body.page-tags .widget_text .textwidget, body.page-node-29 .widget_text .textwidget, body.page-node-34 .widget_text .textwidget, body.page-content .widget_text .textwidget {
  margin-bottom: -26px;
}

body.page-news .widget_text .custom-html-widget, body.node-type-tep-blogs .widget_text .custom-html-widget, body.page-guides .widget_text .custom-html-widget, body.page-edgeproptv .widget_text .custom-html-widget, body.page-pullout .widget_text .custom-html-widget, body.page-events .widget_text .custom-html-widget, body.page-event .widget_text .custom-html-widget, body.page-tags .widget_text .custom-html-widget, body.page-node-29 .widget_text .custom-html-widget, body.page-node-34 .widget_text .custom-html-widget, body.page-content .widget_text .custom-html-widget {
  margin-bottom: 0;
}

body.page-news .widget_text .widget-media-y, body.node-type-tep-blogs .widget_text .widget-media-y, body.page-guides .widget_text .widget-media-y, body.page-edgeproptv .widget_text .widget-media-y, body.page-pullout .widget_text .widget-media-y, body.page-events .widget_text .widget-media-y, body.page-event .widget_text .widget-media-y, body.page-tags .widget_text .widget-media-y, body.page-node-29 .widget_text .widget-media-y, body.page-node-34 .widget_text .widget-media-y, body.page-content .widget_text .widget-media-y {
  width: 100%;
  height: 227px;
}

body.page-news .widget_recent_entries ul li, body.node-type-tep-blogs .widget_recent_entries ul li, body.page-guides .widget_recent_entries ul li, body.page-edgeproptv .widget_recent_entries ul li, body.page-pullout .widget_recent_entries ul li, body.page-events .widget_recent_entries ul li, body.page-event .widget_recent_entries ul li, body.page-tags .widget_recent_entries ul li, body.page-node-29 .widget_recent_entries ul li, body.page-node-34 .widget_recent_entries ul li, body.page-content .widget_recent_entries ul li {
  padding-bottom: 12px;
  margin-bottom: 14px;
  border-bottom: 1px solid #d9d9d9;
}

body.page-news .widget_recent_entries ul li:last-of-type, body.node-type-tep-blogs .widget_recent_entries ul li:last-of-type, body.page-guides .widget_recent_entries ul li:last-of-type, body.page-edgeproptv .widget_recent_entries ul li:last-of-type, body.page-pullout .widget_recent_entries ul li:last-of-type, body.page-events .widget_recent_entries ul li:last-of-type, body.page-event .widget_recent_entries ul li:last-of-type, body.page-tags .widget_recent_entries ul li:last-of-type, body.page-node-29 .widget_recent_entries ul li:last-of-type, body.page-node-34 .widget_recent_entries ul li:last-of-type, body.page-content .widget_recent_entries ul li:last-of-type {
  border-bottom: 0;
  margin-bottom: 0;
}

body.page-news .widget_recent_entries ul li span, body.node-type-tep-blogs .widget_recent_entries ul li span, body.page-guides .widget_recent_entries ul li span, body.page-edgeproptv .widget_recent_entries ul li span, body.page-pullout .widget_recent_entries ul li span, body.page-events .widget_recent_entries ul li span, body.page-event .widget_recent_entries ul li span, body.page-tags .widget_recent_entries ul li span, body.page-node-29 .widget_recent_entries ul li span, body.page-node-34 .widget_recent_entries ul li span, body.page-content .widget_recent_entries ul li span {
  font-size: 12px;
  line-height: 14px;
  color: #4d4d4d;
}

body.page-news .widget_tag_cloud a, body.node-type-tep-blogs .widget_tag_cloud a, body.page-guides .widget_tag_cloud a, body.page-edgeproptv .widget_tag_cloud a, body.page-pullout .widget_tag_cloud a, body.page-events .widget_tag_cloud a, body.page-event .widget_tag_cloud a, body.page-tags .widget_tag_cloud a, body.page-node-29 .widget_tag_cloud a, body.page-node-34 .widget_tag_cloud a, body.page-content .widget_tag_cloud a {
  font-weight: bold;
  color: #fff;
  line-height: 14px;
  text-transform: uppercase;
  background-color: #488BF8;
  display: inline-block;
  padding: 5px 15px;
  letter-spacing: 1px;
  font-size: 12px !important;
  border-radius: 15px;
  margin-right: 8px;
  margin-bottom: 12px;
}

body.page-news .widget_tag_cloud a:hover, body.node-type-tep-blogs .widget_tag_cloud a:hover, body.page-guides .widget_tag_cloud a:hover, body.page-edgeproptv .widget_tag_cloud a:hover, body.page-pullout .widget_tag_cloud a:hover, body.page-events .widget_tag_cloud a:hover, body.page-event .widget_tag_cloud a:hover, body.page-tags .widget_tag_cloud a:hover, body.page-node-29 .widget_tag_cloud a:hover, body.page-node-34 .widget_tag_cloud a:hover, body.page-content .widget_tag_cloud a:hover {
  background-color: #000;
}

body.page-news .widget_calendar, body.node-type-tep-blogs .widget_calendar, body.page-guides .widget_calendar, body.page-edgeproptv .widget_calendar, body.page-pullout .widget_calendar, body.page-events .widget_calendar, body.page-event .widget_calendar, body.page-tags .widget_calendar, body.page-node-29 .widget_calendar, body.page-node-34 .widget_calendar, body.page-content .widget_calendar {
  padding-bottom: 10px;
}

body.page-news #wp-calendar caption, body.node-type-tep-blogs #wp-calendar caption, body.page-guides #wp-calendar caption, body.page-edgeproptv #wp-calendar caption, body.page-pullout #wp-calendar caption, body.page-events #wp-calendar caption, body.page-event #wp-calendar caption, body.page-tags #wp-calendar caption, body.page-node-29 #wp-calendar caption, body.page-node-34 #wp-calendar caption, body.page-content #wp-calendar caption {
  color: white;
  border: 3px solid #2e2e2e;
  border-bottom: 0px;
  background-color: #2e2e2e;
  font-weight: bold;
  font-weight: 700;
  text-transform: uppercase;
  padding: 10px 0px 5px 0px;
}

body.page-news .content thead td, body.page-news .content thead th, body.page-news .content tfoot td, body.node-type-tep-blogs .content thead td, body.node-type-tep-blogs .content thead th, body.node-type-tep-blogs .content tfoot td, body.page-guides .content thead td, body.page-guides .content thead th, body.page-guides .content tfoot td, body.page-edgeproptv .content thead td, body.page-edgeproptv .content thead th, body.page-edgeproptv .content tfoot td, body.page-pullout .content thead td, body.page-pullout .content thead th, body.page-pullout .content tfoot td, body.page-events .content thead td, body.page-events .content thead th, body.page-events .content tfoot td, body.page-event .content thead td, body.page-event .content thead th, body.page-event .content tfoot td, body.page-tags .content thead td, body.page-tags .content thead th, body.page-tags .content tfoot td, body.page-node-29 .content thead td, body.page-node-29 .content thead th, body.page-node-29 .content tfoot td, body.page-node-34 .content thead td, body.page-node-34 .content thead th, body.page-node-34 .content tfoot td, body.page-content .content thead td, body.page-content .content thead th, body.page-content .content tfoot td {
  padding: 5px;
}

body.page-news .content tbody td, body.node-type-tep-blogs .content tbody td, body.page-guides .content tbody td, body.page-edgeproptv .content tbody td, body.page-pullout .content tbody td, body.page-events .content tbody td, body.page-event .content tbody td, body.page-tags .content tbody td, body.page-node-29 .content tbody td, body.page-node-34 .content tbody td, body.page-content .content tbody td {
  padding: 5px;
}

body.page-news .content #wp-calendar, body.node-type-tep-blogs .content #wp-calendar, body.page-guides .content #wp-calendar, body.page-edgeproptv .content #wp-calendar, body.page-pullout .content #wp-calendar, body.page-events .content #wp-calendar, body.page-event .content #wp-calendar, body.page-tags .content #wp-calendar, body.page-node-29 .content #wp-calendar, body.page-node-34 .content #wp-calendar, body.page-content .content #wp-calendar {
  margin-bottom: 20px;
  width: 100%;
}

body.page-news .content #wp-calendar td, body.page-news .content #wp-calendar th, body.node-type-tep-blogs .content #wp-calendar td, body.node-type-tep-blogs .content #wp-calendar th, body.page-guides .content #wp-calendar td, body.page-guides .content #wp-calendar th, body.page-edgeproptv .content #wp-calendar td, body.page-edgeproptv .content #wp-calendar th, body.page-pullout .content #wp-calendar td, body.page-pullout .content #wp-calendar th, body.page-events .content #wp-calendar td, body.page-events .content #wp-calendar th, body.page-event .content #wp-calendar td, body.page-event .content #wp-calendar th, body.page-tags .content #wp-calendar td, body.page-tags .content #wp-calendar th, body.page-node-29 .content #wp-calendar td, body.page-node-29 .content #wp-calendar th, body.page-node-34 .content #wp-calendar td, body.page-node-34 .content #wp-calendar th, body.page-content .content #wp-calendar td, body.page-content .content #wp-calendar th {
  text-align: center;
}

body.page-news .content #wp-calendar table, body.node-type-tep-blogs .content #wp-calendar table, body.page-guides .content #wp-calendar table, body.page-edgeproptv .content #wp-calendar table, body.page-pullout .content #wp-calendar table, body.page-events .content #wp-calendar table, body.page-event .content #wp-calendar table, body.page-tags .content #wp-calendar table, body.page-node-29 .content #wp-calendar table, body.page-node-34 .content #wp-calendar table, body.page-content .content #wp-calendar table {
  color: #333333;
  border: 3px solid #ccc;
}

body.page-news .content #wp-calendar thead td, body.page-news .content #wp-calendar thead th, body.page-news .content #wp-calendar tfoot td, body.node-type-tep-blogs .content #wp-calendar thead td, body.node-type-tep-blogs .content #wp-calendar thead th, body.node-type-tep-blogs .content #wp-calendar tfoot td, body.page-guides .content #wp-calendar thead td, body.page-guides .content #wp-calendar thead th, body.page-guides .content #wp-calendar tfoot td, body.page-edgeproptv .content #wp-calendar thead td, body.page-edgeproptv .content #wp-calendar thead th, body.page-edgeproptv .content #wp-calendar tfoot td, body.page-pullout .content #wp-calendar thead td, body.page-pullout .content #wp-calendar thead th, body.page-pullout .content #wp-calendar tfoot td, body.page-events .content #wp-calendar thead td, body.page-events .content #wp-calendar thead th, body.page-events .content #wp-calendar tfoot td, body.page-event .content #wp-calendar thead td, body.page-event .content #wp-calendar thead th, body.page-event .content #wp-calendar tfoot td, body.page-tags .content #wp-calendar thead td, body.page-tags .content #wp-calendar thead th, body.page-tags .content #wp-calendar tfoot td, body.page-node-29 .content #wp-calendar thead td, body.page-node-29 .content #wp-calendar thead th, body.page-node-29 .content #wp-calendar tfoot td, body.page-node-34 .content #wp-calendar thead td, body.page-node-34 .content #wp-calendar thead th, body.page-node-34 .content #wp-calendar tfoot td, body.page-content .content #wp-calendar thead td, body.page-content .content #wp-calendar thead th, body.page-content .content #wp-calendar tfoot td {
  color: #808080;
  border: 1px solid #ccc;
  font-weight: bold;
  font-weight: 700;
  text-transform: uppercase;
}

body.page-news .content #wp-calendar tbody td, body.node-type-tep-blogs .content #wp-calendar tbody td, body.page-guides .content #wp-calendar tbody td, body.page-edgeproptv .content #wp-calendar tbody td, body.page-pullout .content #wp-calendar tbody td, body.page-events .content #wp-calendar tbody td, body.page-event .content #wp-calendar tbody td, body.page-tags .content #wp-calendar tbody td, body.page-node-29 .content #wp-calendar tbody td, body.page-node-34 .content #wp-calendar tbody td, body.page-content .content #wp-calendar tbody td {
  color: #999999;
  border: 1px solid #ccc;
}

body.page-news .content table, body.node-type-tep-blogs .content table, body.page-guides .content table, body.page-edgeproptv .content table, body.page-pullout .content table, body.page-events .content table, body.page-event .content table, body.page-tags .content table, body.page-node-29 .content table, body.page-node-34 .content table, body.page-content .content table {
  border-collapse: collapse;
}

body.page-news .content table, body.page-news .content th, body.page-news .content td, body.node-type-tep-blogs .content table, body.node-type-tep-blogs .content th, body.node-type-tep-blogs .content td, body.page-guides .content table, body.page-guides .content th, body.page-guides .content td, body.page-edgeproptv .content table, body.page-edgeproptv .content th, body.page-edgeproptv .content td, body.page-pullout .content table, body.page-pullout .content th, body.page-pullout .content td, body.page-events .content table, body.page-events .content th, body.page-events .content td, body.page-event .content table, body.page-event .content th, body.page-event .content td, body.page-tags .content table, body.page-tags .content th, body.page-tags .content td, body.page-node-29 .content table, body.page-node-29 .content th, body.page-node-29 .content td, body.page-node-34 .content table, body.page-node-34 .content th, body.page-node-34 .content td, body.page-content .content table, body.page-content .content th, body.page-content .content td {
  border: 1px solid #000;
  padding: 5px;
}

body.page-news .sidebar-widget label, body.node-type-tep-blogs .sidebar-widget label, body.page-guides .sidebar-widget label, body.page-edgeproptv .sidebar-widget label, body.page-pullout .sidebar-widget label, body.page-events .sidebar-widget label, body.page-event .sidebar-widget label, body.page-tags .sidebar-widget label, body.page-node-29 .sidebar-widget label, body.page-node-34 .sidebar-widget label, body.page-content .sidebar-widget label {
  display: block;
}

body.page-news .sidebar-widget .screen-reader-text, body.node-type-tep-blogs .sidebar-widget .screen-reader-text, body.page-guides .sidebar-widget .screen-reader-text, body.page-edgeproptv .sidebar-widget .screen-reader-text, body.page-pullout .sidebar-widget .screen-reader-text, body.page-events .sidebar-widget .screen-reader-text, body.page-event .sidebar-widget .screen-reader-text, body.page-tags .sidebar-widget .screen-reader-text, body.page-node-29 .sidebar-widget .screen-reader-text, body.page-node-34 .sidebar-widget .screen-reader-text, body.page-content .sidebar-widget .screen-reader-text {
  display: none;
}

body.page-news .comments-are-closed, body.node-type-tep-blogs .comments-are-closed, body.page-guides .comments-are-closed, body.page-edgeproptv .comments-are-closed, body.page-pullout .comments-are-closed, body.page-events .comments-are-closed, body.page-event .comments-are-closed, body.page-tags .comments-are-closed, body.page-node-29 .comments-are-closed, body.page-node-34 .comments-are-closed, body.page-content .comments-are-closed {
  font-style: italic;
}

body.page-news .sidebar-widget, body.node-type-tep-blogs .sidebar-widget, body.page-guides .sidebar-widget, body.page-edgeproptv .sidebar-widget, body.page-pullout .sidebar-widget, body.page-events .sidebar-widget, body.page-event .sidebar-widget, body.page-tags .sidebar-widget, body.page-node-29 .sidebar-widget, body.page-node-34 .sidebar-widget, body.page-content .sidebar-widget {
  position: relative;
}

body.page-news .search-form.content, body.node-type-tep-blogs .search-form.content, body.page-guides .search-form.content, body.page-edgeproptv .search-form.content, body.page-pullout .search-form.content, body.page-events .search-form.content, body.page-event .search-form.content, body.page-tags .search-form.content, body.page-node-29 .search-form.content, body.page-node-34 .search-form.content, body.page-content .search-form.content {
  width: 100%;
  max-width: 905px;
  margin: 0 auto;
}

@media (min-width: 1564px) {
  body.page-news .search-form.content, body.node-type-tep-blogs .search-form.content, body.page-guides .search-form.content, body.page-edgeproptv .search-form.content, body.page-pullout .search-form.content, body.page-events .search-form.content, body.page-event .search-form.content, body.page-tags .search-form.content, body.page-node-29 .search-form.content, body.page-node-34 .search-form.content, body.page-content .search-form.content {
    max-width: 1100px;
  }
}

@media (min-width: 1440px) {
  body.page-news .search-form.content, body.node-type-tep-blogs .search-form.content, body.page-guides .search-form.content, body.page-edgeproptv .search-form.content, body.page-pullout .search-form.content, body.page-events .search-form.content, body.page-event .search-form.content, body.page-tags .search-form.content, body.page-node-29 .search-form.content, body.page-node-34 .search-form.content, body.page-content .search-form.content {
    max-width: 989px;
  }
}

@media (min-width: 991px) {
  body.page-news .search-form.content, body.node-type-tep-blogs .search-form.content, body.page-guides .search-form.content, body.page-edgeproptv .search-form.content, body.page-pullout .search-form.content, body.page-events .search-form.content, body.page-event .search-form.content, body.page-tags .search-form.content, body.page-node-29 .search-form.content, body.page-node-34 .search-form.content, body.page-content .search-form.content {
    padding: 30px 0 50px;
  }
}

body.page-news .search-form.content .search-input, body.node-type-tep-blogs .search-form.content .search-input, body.page-guides .search-form.content .search-input, body.page-edgeproptv .search-form.content .search-input, body.page-pullout .search-form.content .search-input, body.page-events .search-form.content .search-input, body.page-event .search-form.content .search-input, body.page-tags .search-form.content .search-input, body.page-node-29 .search-form.content .search-input, body.page-node-34 .search-form.content .search-input, body.page-content .search-form.content .search-input {
  padding: 8px 18px;
  max-width: 100%;
  border: 0;
  border-bottom: 1px solid;
  width: 100%;
}

body.page-news .search-form.content button, body.node-type-tep-blogs .search-form.content button, body.page-guides .search-form.content button, body.page-edgeproptv .search-form.content button, body.page-pullout .search-form.content button, body.page-events .search-form.content button, body.page-event .search-form.content button, body.page-tags .search-form.content button, body.page-node-29 .search-form.content button, body.page-node-34 .search-form.content button, body.page-content .search-form.content button {
  font-size: 36px;
  line-height: 1;
  border: 0;
  color: #000;
  padding: 0;
  background-color: #fff;
  cursor: pointer;
  width: 10% !important;
  float: right;
  position: relative;
  top: -57px;
  right: -19px;
}

@media (max-width: 768px) {
  body.page-news .search-form.content button, body.node-type-tep-blogs .search-form.content button, body.page-guides .search-form.content button, body.page-edgeproptv .search-form.content button, body.page-pullout .search-form.content button, body.page-events .search-form.content button, body.page-event .search-form.content button, body.page-tags .search-form.content button, body.page-node-29 .search-form.content button, body.page-node-34 .search-form.content button, body.page-content .search-form.content button {
    right: 0;
  }
}

body.page-news .content .apsc-theme-1 .apsc-each-profile,
body.page-news .content .apsc-theme-2 .apsc-each-profile, body.node-type-tep-blogs .content .apsc-theme-1 .apsc-each-profile,
body.node-type-tep-blogs .content .apsc-theme-2 .apsc-each-profile, body.page-guides .content .apsc-theme-1 .apsc-each-profile,
body.page-guides .content .apsc-theme-2 .apsc-each-profile, body.page-edgeproptv .content .apsc-theme-1 .apsc-each-profile,
body.page-edgeproptv .content .apsc-theme-2 .apsc-each-profile, body.page-pullout .content .apsc-theme-1 .apsc-each-profile,
body.page-pullout .content .apsc-theme-2 .apsc-each-profile, body.page-events .content .apsc-theme-1 .apsc-each-profile,
body.page-events .content .apsc-theme-2 .apsc-each-profile, body.page-event .content .apsc-theme-1 .apsc-each-profile,
body.page-event .content .apsc-theme-2 .apsc-each-profile, body.page-tags .content .apsc-theme-1 .apsc-each-profile,
body.page-tags .content .apsc-theme-2 .apsc-each-profile, body.page-node-29 .content .apsc-theme-1 .apsc-each-profile,
body.page-node-29 .content .apsc-theme-2 .apsc-each-profile, body.page-node-34 .content .apsc-theme-1 .apsc-each-profile,
body.page-node-34 .content .apsc-theme-2 .apsc-each-profile, body.page-content .content .apsc-theme-1 .apsc-each-profile,
body.page-content .content .apsc-theme-2 .apsc-each-profile {
  width: 30%;
  margin: 0 5% 5% 0;
}

body.page-news .content .apsc-theme-1 .apsc-each-profile:nth-of-type(3n+3),
body.page-news .content .apsc-theme-2 .apsc-each-profile:nth-of-type(3n+3), body.node-type-tep-blogs .content .apsc-theme-1 .apsc-each-profile:nth-of-type(3n+3),
body.node-type-tep-blogs .content .apsc-theme-2 .apsc-each-profile:nth-of-type(3n+3), body.page-guides .content .apsc-theme-1 .apsc-each-profile:nth-of-type(3n+3),
body.page-guides .content .apsc-theme-2 .apsc-each-profile:nth-of-type(3n+3), body.page-edgeproptv .content .apsc-theme-1 .apsc-each-profile:nth-of-type(3n+3),
body.page-edgeproptv .content .apsc-theme-2 .apsc-each-profile:nth-of-type(3n+3), body.page-pullout .content .apsc-theme-1 .apsc-each-profile:nth-of-type(3n+3),
body.page-pullout .content .apsc-theme-2 .apsc-each-profile:nth-of-type(3n+3), body.page-events .content .apsc-theme-1 .apsc-each-profile:nth-of-type(3n+3),
body.page-events .content .apsc-theme-2 .apsc-each-profile:nth-of-type(3n+3), body.page-event .content .apsc-theme-1 .apsc-each-profile:nth-of-type(3n+3),
body.page-event .content .apsc-theme-2 .apsc-each-profile:nth-of-type(3n+3), body.page-tags .content .apsc-theme-1 .apsc-each-profile:nth-of-type(3n+3),
body.page-tags .content .apsc-theme-2 .apsc-each-profile:nth-of-type(3n+3), body.page-node-29 .content .apsc-theme-1 .apsc-each-profile:nth-of-type(3n+3),
body.page-node-29 .content .apsc-theme-2 .apsc-each-profile:nth-of-type(3n+3), body.page-node-34 .content .apsc-theme-1 .apsc-each-profile:nth-of-type(3n+3),
body.page-node-34 .content .apsc-theme-2 .apsc-each-profile:nth-of-type(3n+3), body.page-content .content .apsc-theme-1 .apsc-each-profile:nth-of-type(3n+3),
body.page-content .content .apsc-theme-2 .apsc-each-profile:nth-of-type(3n+3) {
  margin-right: 0;
}

body.page-news .content .apsc-each-profile:nth-last-of-type(1),
body.page-news .content .apsc-each-profile:nth-last-of-type(2),
body.page-news .content .apsc-each-profile:nth-last-of-type(3), body.node-type-tep-blogs .content .apsc-each-profile:nth-last-of-type(1),
body.node-type-tep-blogs .content .apsc-each-profile:nth-last-of-type(2),
body.node-type-tep-blogs .content .apsc-each-profile:nth-last-of-type(3), body.page-guides .content .apsc-each-profile:nth-last-of-type(1),
body.page-guides .content .apsc-each-profile:nth-last-of-type(2),
body.page-guides .content .apsc-each-profile:nth-last-of-type(3), body.page-edgeproptv .content .apsc-each-profile:nth-last-of-type(1),
body.page-edgeproptv .content .apsc-each-profile:nth-last-of-type(2),
body.page-edgeproptv .content .apsc-each-profile:nth-last-of-type(3), body.page-pullout .content .apsc-each-profile:nth-last-of-type(1),
body.page-pullout .content .apsc-each-profile:nth-last-of-type(2),
body.page-pullout .content .apsc-each-profile:nth-last-of-type(3), body.page-events .content .apsc-each-profile:nth-last-of-type(1),
body.page-events .content .apsc-each-profile:nth-last-of-type(2),
body.page-events .content .apsc-each-profile:nth-last-of-type(3), body.page-event .content .apsc-each-profile:nth-last-of-type(1),
body.page-event .content .apsc-each-profile:nth-last-of-type(2),
body.page-event .content .apsc-each-profile:nth-last-of-type(3), body.page-tags .content .apsc-each-profile:nth-last-of-type(1),
body.page-tags .content .apsc-each-profile:nth-last-of-type(2),
body.page-tags .content .apsc-each-profile:nth-last-of-type(3), body.page-node-29 .content .apsc-each-profile:nth-last-of-type(1),
body.page-node-29 .content .apsc-each-profile:nth-last-of-type(2),
body.page-node-29 .content .apsc-each-profile:nth-last-of-type(3), body.page-node-34 .content .apsc-each-profile:nth-last-of-type(1),
body.page-node-34 .content .apsc-each-profile:nth-last-of-type(2),
body.page-node-34 .content .apsc-each-profile:nth-last-of-type(3), body.page-content .content .apsc-each-profile:nth-last-of-type(1),
body.page-content .content .apsc-each-profile:nth-last-of-type(2),
body.page-content .content .apsc-each-profile:nth-last-of-type(3) {
  margin-bottom: 10px;
}

body.page-news .content .apsc-theme-2 .apsc-inner-block, body.node-type-tep-blogs .content .apsc-theme-2 .apsc-inner-block, body.page-guides .content .apsc-theme-2 .apsc-inner-block, body.page-edgeproptv .content .apsc-theme-2 .apsc-inner-block, body.page-pullout .content .apsc-theme-2 .apsc-inner-block, body.page-events .content .apsc-theme-2 .apsc-inner-block, body.page-event .content .apsc-theme-2 .apsc-inner-block, body.page-tags .content .apsc-theme-2 .apsc-inner-block, body.page-node-29 .content .apsc-theme-2 .apsc-inner-block, body.page-node-34 .content .apsc-theme-2 .apsc-inner-block, body.page-content .content .apsc-theme-2 .apsc-inner-block {
  padding: 15px 8px 8px 8px;
}

body.page-news .apsc-theme-1 .apsc-each-profile a, body.node-type-tep-blogs .apsc-theme-1 .apsc-each-profile a, body.page-guides .apsc-theme-1 .apsc-each-profile a, body.page-edgeproptv .apsc-theme-1 .apsc-each-profile a, body.page-pullout .apsc-theme-1 .apsc-each-profile a, body.page-events .apsc-theme-1 .apsc-each-profile a, body.page-event .apsc-theme-1 .apsc-each-profile a, body.page-tags .apsc-theme-1 .apsc-each-profile a, body.page-node-29 .apsc-theme-1 .apsc-each-profile a, body.page-node-34 .apsc-theme-1 .apsc-each-profile a, body.page-content .apsc-theme-1 .apsc-each-profile a {
  border-radius: 0;
  transform: none;
  -webkit-transform: none;
  -ms-transform: none;
  -ms-filter: none;
  -moz-box-shadow: none;
  -webkit-box-shadow: none;
  box-shadow: none;
  filter: initial;
}

body.page-news .apsc-theme-1 .apsc-each-profile a:hover, body.node-type-tep-blogs .apsc-theme-1 .apsc-each-profile a:hover, body.page-guides .apsc-theme-1 .apsc-each-profile a:hover, body.page-edgeproptv .apsc-theme-1 .apsc-each-profile a:hover, body.page-pullout .apsc-theme-1 .apsc-each-profile a:hover, body.page-events .apsc-theme-1 .apsc-each-profile a:hover, body.page-event .apsc-theme-1 .apsc-each-profile a:hover, body.page-tags .apsc-theme-1 .apsc-each-profile a:hover, body.page-node-29 .apsc-theme-1 .apsc-each-profile a:hover, body.page-node-34 .apsc-theme-1 .apsc-each-profile a:hover, body.page-content .apsc-theme-1 .apsc-each-profile a:hover {
  transform: none;
  -webkit-transform: none;
  -ms-transform: none;
}

body.page-news .apsc-theme-1 .apsc-each-profile a:before, body.node-type-tep-blogs .apsc-theme-1 .apsc-each-profile a:before, body.page-guides .apsc-theme-1 .apsc-each-profile a:before, body.page-edgeproptv .apsc-theme-1 .apsc-each-profile a:before, body.page-pullout .apsc-theme-1 .apsc-each-profile a:before, body.page-events .apsc-theme-1 .apsc-each-profile a:before, body.page-event .apsc-theme-1 .apsc-each-profile a:before, body.page-tags .apsc-theme-1 .apsc-each-profile a:before, body.page-node-29 .apsc-theme-1 .apsc-each-profile a:before, body.page-node-34 .apsc-theme-1 .apsc-each-profile a:before, body.page-content .apsc-theme-1 .apsc-each-profile a:before {
  display: none;
}

body.page-news .apsc-theme-2 .apsc-each-profile a, body.node-type-tep-blogs .apsc-theme-2 .apsc-each-profile a, body.page-guides .apsc-theme-2 .apsc-each-profile a, body.page-edgeproptv .apsc-theme-2 .apsc-each-profile a, body.page-pullout .apsc-theme-2 .apsc-each-profile a, body.page-events .apsc-theme-2 .apsc-each-profile a, body.page-event .apsc-theme-2 .apsc-each-profile a, body.page-tags .apsc-theme-2 .apsc-each-profile a, body.page-node-29 .apsc-theme-2 .apsc-each-profile a, body.page-node-34 .apsc-theme-2 .apsc-each-profile a, body.page-content .apsc-theme-2 .apsc-each-profile a {
  border-radius: 0;
  transform: none;
  -webkit-transform: none;
  -ms-transform: none;
  -ms-filter: none;
  -moz-box-shadow: none;
  -webkit-box-shadow: none;
  box-shadow: none;
  filter: initial;
  display: block;
  position: relative;
}

body.page-news .apsc-theme-2 .apsc-each-profile a:hover, body.node-type-tep-blogs .apsc-theme-2 .apsc-each-profile a:hover, body.page-guides .apsc-theme-2 .apsc-each-profile a:hover, body.page-edgeproptv .apsc-theme-2 .apsc-each-profile a:hover, body.page-pullout .apsc-theme-2 .apsc-each-profile a:hover, body.page-events .apsc-theme-2 .apsc-each-profile a:hover, body.page-event .apsc-theme-2 .apsc-each-profile a:hover, body.page-tags .apsc-theme-2 .apsc-each-profile a:hover, body.page-node-29 .apsc-theme-2 .apsc-each-profile a:hover, body.page-node-34 .apsc-theme-2 .apsc-each-profile a:hover, body.page-content .apsc-theme-2 .apsc-each-profile a:hover {
  transform: none;
  -webkit-transform: none;
  -ms-transform: none;
}

body.page-news .apsc-theme-2 .apsc-inner-block, body.node-type-tep-blogs .apsc-theme-2 .apsc-inner-block, body.page-guides .apsc-theme-2 .apsc-inner-block, body.page-edgeproptv .apsc-theme-2 .apsc-inner-block, body.page-pullout .apsc-theme-2 .apsc-inner-block, body.page-events .apsc-theme-2 .apsc-inner-block, body.page-event .apsc-theme-2 .apsc-inner-block, body.page-tags .apsc-theme-2 .apsc-inner-block, body.page-node-29 .apsc-theme-2 .apsc-inner-block, body.page-node-34 .apsc-theme-2 .apsc-inner-block, body.page-content .apsc-theme-2 .apsc-inner-block {
  padding: 0;
  border-radius: 0;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
}

body.page-news .apsc-theme-1 .social-icon .fa,
body.page-news .apsc-theme-2 .social-icon .fa, body.node-type-tep-blogs .apsc-theme-1 .social-icon .fa,
body.node-type-tep-blogs .apsc-theme-2 .social-icon .fa, body.page-guides .apsc-theme-1 .social-icon .fa,
body.page-guides .apsc-theme-2 .social-icon .fa, body.page-edgeproptv .apsc-theme-1 .social-icon .fa,
body.page-edgeproptv .apsc-theme-2 .social-icon .fa, body.page-pullout .apsc-theme-1 .social-icon .fa,
body.page-pullout .apsc-theme-2 .social-icon .fa, body.page-events .apsc-theme-1 .social-icon .fa,
body.page-events .apsc-theme-2 .social-icon .fa, body.page-event .apsc-theme-1 .social-icon .fa,
body.page-event .apsc-theme-2 .social-icon .fa, body.page-tags .apsc-theme-1 .social-icon .fa,
body.page-tags .apsc-theme-2 .social-icon .fa, body.page-node-29 .apsc-theme-1 .social-icon .fa,
body.page-node-29 .apsc-theme-2 .social-icon .fa, body.page-node-34 .apsc-theme-1 .social-icon .fa,
body.page-node-34 .apsc-theme-2 .social-icon .fa, body.page-content .apsc-theme-1 .social-icon .fa,
body.page-content .apsc-theme-2 .social-icon .fa {
  font-size: 30px;
  line-height: 36px;
  margin-bottom: 6px;
}

body.page-news .widget_apsc_widget .social-icon, body.node-type-tep-blogs .widget_apsc_widget .social-icon, body.page-guides .widget_apsc_widget .social-icon, body.page-edgeproptv .widget_apsc_widget .social-icon, body.page-pullout .widget_apsc_widget .social-icon, body.page-events .widget_apsc_widget .social-icon, body.page-event .widget_apsc_widget .social-icon, body.page-tags .widget_apsc_widget .social-icon, body.page-node-29 .widget_apsc_widget .social-icon, body.page-node-34 .widget_apsc_widget .social-icon, body.page-content .widget_apsc_widget .social-icon {
  display: block;
  width: initial;
  margin-right: 0;
  margin-bottom: 0;
  height: initial;
  text-align: center;
  line-height: initial;
}

body.page-news .apsc-theme-1 .apsc-count,
body.page-news .apsc-theme-2 .apsc-count, body.node-type-tep-blogs .apsc-theme-1 .apsc-count,
body.node-type-tep-blogs .apsc-theme-2 .apsc-count, body.page-guides .apsc-theme-1 .apsc-count,
body.page-guides .apsc-theme-2 .apsc-count, body.page-edgeproptv .apsc-theme-1 .apsc-count,
body.page-edgeproptv .apsc-theme-2 .apsc-count, body.page-pullout .apsc-theme-1 .apsc-count,
body.page-pullout .apsc-theme-2 .apsc-count, body.page-events .apsc-theme-1 .apsc-count,
body.page-events .apsc-theme-2 .apsc-count, body.page-event .apsc-theme-1 .apsc-count,
body.page-event .apsc-theme-2 .apsc-count, body.page-tags .apsc-theme-1 .apsc-count,
body.page-tags .apsc-theme-2 .apsc-count, body.page-node-29 .apsc-theme-1 .apsc-count,
body.page-node-29 .apsc-theme-2 .apsc-count, body.page-node-34 .apsc-theme-1 .apsc-count,
body.page-node-34 .apsc-theme-2 .apsc-count, body.page-content .apsc-theme-1 .apsc-count,
body.page-content .apsc-theme-2 .apsc-count {
  font-size: 18px;
  line-height: 24px;
  font-weight: bold;
  margin-bottom: 4px;
}

body.page-news .apsc-theme-2 .apsc-media-type, body.node-type-tep-blogs .apsc-theme-2 .apsc-media-type, body.page-guides .apsc-theme-2 .apsc-media-type, body.page-edgeproptv .apsc-theme-2 .apsc-media-type, body.page-pullout .apsc-theme-2 .apsc-media-type, body.page-events .apsc-theme-2 .apsc-media-type, body.page-event .apsc-theme-2 .apsc-media-type, body.page-tags .apsc-theme-2 .apsc-media-type, body.page-node-29 .apsc-theme-2 .apsc-media-type, body.page-node-34 .apsc-theme-2 .apsc-media-type, body.page-content .apsc-theme-2 .apsc-media-type {
  font-size: 12px;
  line-height: 14px;
  opacity: 0.5;
}

body.page-news .widget-post, body.node-type-tep-blogs .widget-post, body.page-guides .widget-post, body.page-edgeproptv .widget-post, body.page-pullout .widget-post, body.page-events .widget-post, body.page-event .widget-post, body.page-tags .widget-post, body.page-node-29 .widget-post, body.page-node-34 .widget-post, body.page-content .widget-post {
  margin-bottom: 25px;
}

body.page-news .widget-post:last-of-type, body.node-type-tep-blogs .widget-post:last-of-type, body.page-guides .widget-post:last-of-type, body.page-edgeproptv .widget-post:last-of-type, body.page-pullout .widget-post:last-of-type, body.page-events .widget-post:last-of-type, body.page-event .widget-post:last-of-type, body.page-tags .widget-post:last-of-type, body.page-node-29 .widget-post:last-of-type, body.page-node-34 .widget-post:last-of-type, body.page-content .widget-post:last-of-type {
  padding-bottom: 10px;
}

body.page-news .box-thumb .post-thumb, body.node-type-tep-blogs .box-thumb .post-thumb, body.page-guides .box-thumb .post-thumb, body.page-edgeproptv .box-thumb .post-thumb, body.page-pullout .box-thumb .post-thumb, body.page-events .box-thumb .post-thumb, body.page-event .box-thumb .post-thumb, body.page-tags .box-thumb .post-thumb, body.page-node-29 .box-thumb .post-thumb, body.page-node-34 .box-thumb .post-thumb, body.page-content .box-thumb .post-thumb {
  width: 113px;
}

body.page-news .entry-thumb > a,
body.page-news .post-thumb > a, body.node-type-tep-blogs .entry-thumb > a,
body.node-type-tep-blogs .post-thumb > a, body.page-guides .entry-thumb > a,
body.page-guides .post-thumb > a, body.page-edgeproptv .entry-thumb > a,
body.page-edgeproptv .post-thumb > a, body.page-pullout .entry-thumb > a,
body.page-pullout .post-thumb > a, body.page-events .entry-thumb > a,
body.page-events .post-thumb > a, body.page-event .entry-thumb > a,
body.page-event .post-thumb > a, body.page-tags .entry-thumb > a,
body.page-tags .post-thumb > a, body.page-node-29 .entry-thumb > a,
body.page-node-29 .post-thumb > a, body.page-node-34 .entry-thumb > a,
body.page-node-34 .post-thumb > a, body.page-content .entry-thumb > a,
body.page-content .post-thumb > a {
  display: block;
  line-height: 0;
}

body.page-news .widget-post .box-details, body.node-type-tep-blogs .widget-post .box-details, body.page-guides .widget-post .box-details, body.page-edgeproptv .widget-post .box-details, body.page-pullout .widget-post .box-details, body.page-events .widget-post .box-details, body.page-event .widget-post .box-details, body.page-tags .widget-post .box-details, body.page-node-29 .widget-post .box-details, body.page-node-34 .widget-post .box-details, body.page-content .widget-post .box-details {
  margin-left: 138px;
}

body.page-news .post-editor-picks .cwp, body.node-type-tep-blogs .post-editor-picks .cwp, body.page-guides .post-editor-picks .cwp, body.page-edgeproptv .post-editor-picks .cwp, body.page-pullout .post-editor-picks .cwp, body.page-events .post-editor-picks .cwp, body.page-event .post-editor-picks .cwp, body.page-tags .post-editor-picks .cwp, body.page-node-29 .post-editor-picks .cwp, body.page-node-34 .post-editor-picks .cwp, body.page-content .post-editor-picks .cwp {
  position: absolute;
  top: 0;
  left: 0;
  font-size: 18px;
  line-height: 24px;
  color: #fff;
  font-weight: 600;
  background-color: #488BF8;
  padding: 4px 12px;
  text-align: center;
}

body.page-news .post-trending, body.node-type-tep-blogs .post-trending, body.page-guides .post-trending, body.page-edgeproptv .post-trending, body.page-pullout .post-trending, body.page-events .post-trending, body.page-event .post-trending, body.page-tags .post-trending, body.page-node-29 .post-trending, body.page-node-34 .post-trending, body.page-content .post-trending {
  margin-bottom: 14px;
  border-bottom: 1px solid #d9d9d9;
  padding-bottom: 12px;
}

body.page-news .post-trending:last-of-type, body.node-type-tep-blogs .post-trending:last-of-type, body.page-guides .post-trending:last-of-type, body.page-edgeproptv .post-trending:last-of-type, body.page-pullout .post-trending:last-of-type, body.page-events .post-trending:last-of-type, body.page-event .post-trending:last-of-type, body.page-tags .post-trending:last-of-type, body.page-node-29 .post-trending:last-of-type, body.page-node-34 .post-trending:last-of-type, body.page-content .post-trending:last-of-type {
  border-bottom: 0;
  margin-bottom: 0;
  padding-bottom: 10px;
}

body.page-news .post-trending .cwp, body.node-type-tep-blogs .post-trending .cwp, body.page-guides .post-trending .cwp, body.page-edgeproptv .post-trending .cwp, body.page-pullout .post-trending .cwp, body.page-events .post-trending .cwp, body.page-event .post-trending .cwp, body.page-tags .post-trending .cwp, body.page-node-29 .post-trending .cwp, body.page-node-34 .post-trending .cwp, body.page-content .post-trending .cwp {
  width: 40px;
  font-weight: 600;
  font-size: 30px;
  line-height: 36px;
  color: #212529;
}

body.page-news .post-trending .box-details, body.node-type-tep-blogs .post-trending .box-details, body.page-guides .post-trending .box-details, body.page-edgeproptv .post-trending .box-details, body.page-pullout .post-trending .box-details, body.page-events .post-trending .box-details, body.page-event .post-trending .box-details, body.page-tags .post-trending .box-details, body.page-node-29 .post-trending .box-details, body.page-node-34 .post-trending .box-details, body.page-content .post-trending .box-details {
  margin-left: 40px;
}

body.page-news .widget-post-title, body.node-type-tep-blogs .widget-post-title, body.page-guides .widget-post-title, body.page-edgeproptv .widget-post-title, body.page-pullout .widget-post-title, body.page-events .widget-post-title, body.page-event .widget-post-title, body.page-tags .widget-post-title, body.page-node-29 .widget-post-title, body.page-node-34 .widget-post-title, body.page-content .widget-post-title {
  font-size: 16px;
  font-weight: 500;
  line-height: 18px;
  margin-bottom: 10px;
  line-height: 1.5;
  font-family: "Rubik", Helvetica, Arial, sans-serif !important;
}

body.page-news .widget-post-title a:hover, body.node-type-tep-blogs .widget-post-title a:hover, body.page-guides .widget-post-title a:hover, body.page-edgeproptv .widget-post-title a:hover, body.page-pullout .widget-post-title a:hover, body.page-events .widget-post-title a:hover, body.page-event .widget-post-title a:hover, body.page-tags .widget-post-title a:hover, body.page-node-29 .widget-post-title a:hover, body.page-node-34 .widget-post-title a:hover, body.page-content .widget-post-title a:hover {
  color: #808080;
  text-decoration: none;
}

body.page-news .widget-post-meta, body.node-type-tep-blogs .widget-post-meta, body.page-guides .widget-post-meta, body.page-edgeproptv .widget-post-meta, body.page-pullout .widget-post-meta, body.page-events .widget-post-meta, body.page-event .widget-post-meta, body.page-tags .widget-post-meta, body.page-node-29 .widget-post-meta, body.page-node-34 .widget-post-meta, body.page-content .widget-post-meta {
  font-size: 12px;
  line-height: 14px;
  font-weight: normal;
  color: #4d4d4d;
}

body.page-news .widget-post-meta span, body.node-type-tep-blogs .widget-post-meta span, body.page-guides .widget-post-meta span, body.page-edgeproptv .widget-post-meta span, body.page-pullout .widget-post-meta span, body.page-events .widget-post-meta span, body.page-event .widget-post-meta span, body.page-tags .widget-post-meta span, body.page-node-29 .widget-post-meta span, body.page-node-34 .widget-post-meta span, body.page-content .widget-post-meta span {
  color: #4d4d4d;
}

body.page-news .categries-list-widget ul li a, body.node-type-tep-blogs .categries-list-widget ul li a, body.page-guides .categries-list-widget ul li a, body.page-edgeproptv .categries-list-widget ul li a, body.page-pullout .categries-list-widget ul li a, body.page-events .categries-list-widget ul li a, body.page-event .categries-list-widget ul li a, body.page-tags .categries-list-widget ul li a, body.page-node-29 .categries-list-widget ul li a, body.page-node-34 .categries-list-widget ul li a, body.page-content .categries-list-widget ul li a {
  padding-bottom: 12px;
  float: left;
}

body.page-news .categries-list-widget ul li span, body.node-type-tep-blogs .categries-list-widget ul li span, body.page-guides .categries-list-widget ul li span, body.page-edgeproptv .categries-list-widget ul li span, body.page-pullout .categries-list-widget ul li span, body.page-events .categries-list-widget ul li span, body.page-event .categries-list-widget ul li span, body.page-tags .categries-list-widget ul li span, body.page-node-29 .categries-list-widget ul li span, body.page-node-34 .categries-list-widget ul li span, body.page-content .categries-list-widget ul li span {
  float: right;
}

body.page-news .widget_mc4wp_form_widget, body.node-type-tep-blogs .widget_mc4wp_form_widget, body.page-guides .widget_mc4wp_form_widget, body.page-edgeproptv .widget_mc4wp_form_widget, body.page-pullout .widget_mc4wp_form_widget, body.page-events .widget_mc4wp_form_widget, body.page-event .widget_mc4wp_form_widget, body.page-tags .widget_mc4wp_form_widget, body.page-node-29 .widget_mc4wp_form_widget, body.page-node-34 .widget_mc4wp_form_widget, body.page-content .widget_mc4wp_form_widget {
  background-color: #e6e6e6;
  padding: 40px 28px 0;
}

body.page-news .widget_mc4wp_form_widget .sidebar-widget-title, body.node-type-tep-blogs .widget_mc4wp_form_widget .sidebar-widget-title, body.page-guides .widget_mc4wp_form_widget .sidebar-widget-title, body.page-edgeproptv .widget_mc4wp_form_widget .sidebar-widget-title, body.page-pullout .widget_mc4wp_form_widget .sidebar-widget-title, body.page-events .widget_mc4wp_form_widget .sidebar-widget-title, body.page-event .widget_mc4wp_form_widget .sidebar-widget-title, body.page-tags .widget_mc4wp_form_widget .sidebar-widget-title, body.page-node-29 .widget_mc4wp_form_widget .sidebar-widget-title, body.page-node-34 .widget_mc4wp_form_widget .sidebar-widget-title, body.page-content .widget_mc4wp_form_widget .sidebar-widget-title {
  position: relative;
  background-color: transparent;
  height: auto;
  margin-top: 62px;
  color: #1a1a1a;
  font-size: 18px;
  text-transform: uppercase;
  line-height: 24px;
  text-align: center;
  display: block;
  padding: 16px 0 16px;
  margin-bottom: 0;
  border: 0;
  color: #111;
}

body.page-news .widget_mc4wp_form_widget .sidebar-widget-title:before, body.node-type-tep-blogs .widget_mc4wp_form_widget .sidebar-widget-title:before, body.page-guides .widget_mc4wp_form_widget .sidebar-widget-title:before, body.page-edgeproptv .widget_mc4wp_form_widget .sidebar-widget-title:before, body.page-pullout .widget_mc4wp_form_widget .sidebar-widget-title:before, body.page-events .widget_mc4wp_form_widget .sidebar-widget-title:before, body.page-event .widget_mc4wp_form_widget .sidebar-widget-title:before, body.page-tags .widget_mc4wp_form_widget .sidebar-widget-title:before, body.page-node-29 .widget_mc4wp_form_widget .sidebar-widget-title:before, body.page-node-34 .widget_mc4wp_form_widget .sidebar-widget-title:before, body.page-content .widget_mc4wp_form_widget .sidebar-widget-title:before {
  font-family: FontAwesome;
  content: "\f2b7";
  font-size: 60px;
  line-height: 72px;
  font-weight: normal;
  letter-spacing: 1px;
  background-color: transparent;
  height: auto;
  bottom: 100%;
  left: 0;
  top: initial;
  display: inline-block;
  text-align: center;
  position: absolute;
  width: 100%;
}

body.page-news .content .mc4wp-form-fields input, body.node-type-tep-blogs .content .mc4wp-form-fields input, body.page-guides .content .mc4wp-form-fields input, body.page-edgeproptv .content .mc4wp-form-fields input, body.page-pullout .content .mc4wp-form-fields input, body.page-events .content .mc4wp-form-fields input, body.page-event .content .mc4wp-form-fields input, body.page-tags .content .mc4wp-form-fields input, body.page-node-29 .content .mc4wp-form-fields input, body.page-node-34 .content .mc4wp-form-fields input, body.page-content .content .mc4wp-form-fields input {
  line-height: 18px;
  width: 62%;
  border: 2px solid #1a1a1a;
  padding: 9px 14px;
  background-color: transparent;
  vertical-align: top;
}

body.page-news .content .mc4wp-form-fields input[type=email], body.node-type-tep-blogs .content .mc4wp-form-fields input[type=email], body.page-guides .content .mc4wp-form-fields input[type=email], body.page-edgeproptv .content .mc4wp-form-fields input[type=email], body.page-pullout .content .mc4wp-form-fields input[type=email], body.page-events .content .mc4wp-form-fields input[type=email], body.page-event .content .mc4wp-form-fields input[type=email], body.page-tags .content .mc4wp-form-fields input[type=email], body.page-node-29 .content .mc4wp-form-fields input[type=email], body.page-node-34 .content .mc4wp-form-fields input[type=email], body.page-content .content .mc4wp-form-fields input[type=email] {
  margin-right: -6px;
}

body.page-news .content .mc4wp-form-fields input[type=submit], body.node-type-tep-blogs .content .mc4wp-form-fields input[type=submit], body.page-guides .content .mc4wp-form-fields input[type=submit], body.page-edgeproptv .content .mc4wp-form-fields input[type=submit], body.page-pullout .content .mc4wp-form-fields input[type=submit], body.page-events .content .mc4wp-form-fields input[type=submit], body.page-event .content .mc4wp-form-fields input[type=submit], body.page-tags .content .mc4wp-form-fields input[type=submit], body.page-node-29 .content .mc4wp-form-fields input[type=submit], body.page-node-34 .content .mc4wp-form-fields input[type=submit], body.page-content .content .mc4wp-form-fields input[type=submit] {
  font-size: 14px;
  line-height: 16px;
  margin-right: 0;
  font-weight: normal;
  letter-spacing: 1px;
  width: 38%;
  color: #fff;
  padding: 10px 6px;
  border: 2px solid #1a1a1a;
}

body.page-news .content .mc4wp-form-fields p, body.node-type-tep-blogs .content .mc4wp-form-fields p, body.page-guides .content .mc4wp-form-fields p, body.page-edgeproptv .content .mc4wp-form-fields p, body.page-pullout .content .mc4wp-form-fields p, body.page-events .content .mc4wp-form-fields p, body.page-event .content .mc4wp-form-fields p, body.page-tags .content .mc4wp-form-fields p, body.page-node-29 .content .mc4wp-form-fields p, body.page-node-34 .content .mc4wp-form-fields p, body.page-content .content .mc4wp-form-fields p {
  text-align: center;
}

body.page-news .widget_mc4wp_form_widget,
body.page-news #wope_posts_widget-5, body.node-type-tep-blogs .widget_mc4wp_form_widget,
body.node-type-tep-blogs #wope_posts_widget-5, body.page-guides .widget_mc4wp_form_widget,
body.page-guides #wope_posts_widget-5, body.page-edgeproptv .widget_mc4wp_form_widget,
body.page-edgeproptv #wope_posts_widget-5, body.page-pullout .widget_mc4wp_form_widget,
body.page-pullout #wope_posts_widget-5, body.page-events .widget_mc4wp_form_widget,
body.page-events #wope_posts_widget-5, body.page-event .widget_mc4wp_form_widget,
body.page-event #wope_posts_widget-5, body.page-tags .widget_mc4wp_form_widget,
body.page-tags #wope_posts_widget-5, body.page-node-29 .widget_mc4wp_form_widget,
body.page-node-29 #wope_posts_widget-5, body.page-node-34 .widget_mc4wp_form_widget,
body.page-node-34 #wope_posts_widget-5, body.page-content .widget_mc4wp_form_widget,
body.page-content #wope_posts_widget-5 {
  max-width: 300px;
  margin: 0 auto 32px;
}

body.page-news .tab, body.node-type-tep-blogs .tab, body.page-guides .tab, body.page-edgeproptv .tab, body.page-pullout .tab, body.page-events .tab, body.page-event .tab, body.page-tags .tab, body.page-node-29 .tab, body.page-node-34 .tab, body.page-content .tab {
  margin-bottom: 20px;
}

body.page-news .tab-title i, body.node-type-tep-blogs .tab-title i, body.page-guides .tab-title i, body.page-edgeproptv .tab-title i, body.page-pullout .tab-title i, body.page-events .tab-title i, body.page-event .tab-title i, body.page-tags .tab-title i, body.page-node-29 .tab-title i, body.page-node-34 .tab-title i, body.page-content .tab-title i {
  margin-right: 5px;
}

body.page-news .tab-top, body.node-type-tep-blogs .tab-top, body.page-guides .tab-top, body.page-edgeproptv .tab-top, body.page-pullout .tab-top, body.page-events .tab-top, body.page-event .tab-top, body.page-tags .tab-top, body.page-node-29 .tab-top, body.page-node-34 .tab-top, body.page-content .tab-top {
  letter-spacing: -3px;
}

body.page-news .tab-title, body.node-type-tep-blogs .tab-title, body.page-guides .tab-title, body.page-edgeproptv .tab-title, body.page-pullout .tab-title, body.page-events .tab-title, body.page-event .tab-title, body.page-tags .tab-title, body.page-node-29 .tab-title, body.page-node-34 .tab-title, body.page-content .tab-title {
  letter-spacing: 0px;
  display: inline-block;
  cursor: pointer;
  transition: all 200ms ease-out;
  /*safari and chrome */
  -webkit-transition: all 200ms ease-out;
  /*safari and chrome */
  padding: 10px 30px;
  margin-right: 10px;
  border-radius: 50px;
}

body.page-news .tab-id, body.node-type-tep-blogs .tab-id, body.page-guides .tab-id, body.page-edgeproptv .tab-id, body.page-pullout .tab-id, body.page-events .tab-id, body.page-event .tab-id, body.page-tags .tab-id, body.page-node-29 .tab-id, body.page-node-34 .tab-id, body.page-content .tab-id {
  display: none;
}

body.page-news .tab-bottom, body.node-type-tep-blogs .tab-bottom, body.page-guides .tab-bottom, body.page-edgeproptv .tab-bottom, body.page-pullout .tab-bottom, body.page-events .tab-bottom, body.page-event .tab-bottom, body.page-tags .tab-bottom, body.page-node-29 .tab-bottom, body.page-node-34 .tab-bottom, body.page-content .tab-bottom {
  padding: 30px 30px;
  margin-top: 15px;
}

body.page-news .tab-content, body.node-type-tep-blogs .tab-content, body.page-guides .tab-content, body.page-edgeproptv .tab-content, body.page-pullout .tab-content, body.page-events .tab-content, body.page-event .tab-content, body.page-tags .tab-content, body.page-node-29 .tab-content, body.page-node-34 .tab-content, body.page-content .tab-content {
  display: none;
}

body.page-news .tab-content p:last-child, body.node-type-tep-blogs .tab-content p:last-child, body.page-guides .tab-content p:last-child, body.page-edgeproptv .tab-content p:last-child, body.page-pullout .tab-content p:last-child, body.page-events .tab-content p:last-child, body.page-event .tab-content p:last-child, body.page-tags .tab-content p:last-child, body.page-node-29 .tab-content p:last-child, body.page-node-34 .tab-content p:last-child, body.page-content .tab-content p:last-child {
  padding-bottom: 0px;
}

body.page-news .tab-content-current, body.node-type-tep-blogs .tab-content-current, body.page-guides .tab-content-current, body.page-edgeproptv .tab-content-current, body.page-pullout .tab-content-current, body.page-events .tab-content-current, body.page-event .tab-content-current, body.page-tags .tab-content-current, body.page-node-29 .tab-content-current, body.page-node-34 .tab-content-current, body.page-content .tab-content-current {
  display: block;
}

body.page-news .tab.tab-post .tab-bottom, body.node-type-tep-blogs .tab.tab-post .tab-bottom, body.page-guides .tab.tab-post .tab-bottom, body.page-edgeproptv .tab.tab-post .tab-bottom, body.page-pullout .tab.tab-post .tab-bottom, body.page-events .tab.tab-post .tab-bottom, body.page-event .tab.tab-post .tab-bottom, body.page-tags .tab.tab-post .tab-bottom, body.page-node-29 .tab.tab-post .tab-bottom, body.page-node-34 .tab.tab-post .tab-bottom, body.page-content .tab.tab-post .tab-bottom {
  margin-top: -1px;
  padding: 0px;
}

body.page-news .accordion, body.node-type-tep-blogs .accordion, body.page-guides .accordion, body.page-edgeproptv .accordion, body.page-pullout .accordion, body.page-events .accordion, body.page-event .accordion, body.page-tags .accordion, body.page-node-29 .accordion, body.page-node-34 .accordion, body.page-content .accordion {
  line-height: 0px;
  margin-bottom: 20px;
}

body.page-news .accor-title, body.node-type-tep-blogs .accor-title, body.page-guides .accor-title, body.page-edgeproptv .accor-title, body.page-pullout .accor-title, body.page-events .accor-title, body.page-event .accor-title, body.page-tags .accor-title, body.page-node-29 .accor-title, body.page-node-34 .accor-title, body.page-content .accor-title {
  padding: 10px 45px 10px 30px;
  cursor: pointer;
  position: relative;
  transition: all 200ms ease-out;
  /*safari and chrome */
  -webkit-transition: all 200ms ease-out;
  /*safari and chrome */
  margin-bottom: 10px;
  border-radius: 50px;
}

body.page-news .accor-title i, body.node-type-tep-blogs .accor-title i, body.page-guides .accor-title i, body.page-edgeproptv .accor-title i, body.page-pullout .accor-title i, body.page-events .accor-title i, body.page-event .accor-title i, body.page-tags .accor-title i, body.page-node-29 .accor-title i, body.page-node-34 .accor-title i, body.page-content .accor-title i {
  margin-right: 5px;
  font-size: 16px;
}

body.page-news .accor-title-icon, body.node-type-tep-blogs .accor-title-icon, body.page-guides .accor-title-icon, body.page-edgeproptv .accor-title-icon, body.page-pullout .accor-title-icon, body.page-events .accor-title-icon, body.page-event .accor-title-icon, body.page-tags .accor-title-icon, body.page-node-29 .accor-title-icon, body.page-node-34 .accor-title-icon, body.page-content .accor-title-icon {
  position: absolute;
  width: 50px;
  height: 38px;
  right: 0px;
  top: 0px;
  padding-top: 12px;
  text-align: center;
}

body.page-news .accor-title-icon i, body.node-type-tep-blogs .accor-title-icon i, body.page-guides .accor-title-icon i, body.page-edgeproptv .accor-title-icon i, body.page-pullout .accor-title-icon i, body.page-events .accor-title-icon i, body.page-event .accor-title-icon i, body.page-tags .accor-title-icon i, body.page-node-29 .accor-title-icon i, body.page-node-34 .accor-title-icon i, body.page-content .accor-title-icon i {
  font-size: 24px;
  line-height: 24px;
  margin-right: 0px;
}

body.page-news .ui-state-active i, body.node-type-tep-blogs .ui-state-active i, body.page-guides .ui-state-active i, body.page-edgeproptv .ui-state-active i, body.page-pullout .ui-state-active i, body.page-events .ui-state-active i, body.page-event .ui-state-active i, body.page-tags .ui-state-active i, body.page-node-29 .ui-state-active i, body.page-node-34 .ui-state-active i, body.page-content .ui-state-active i {
  vertical-align: 4px;
}

body.page-news .ui-state-focus, body.node-type-tep-blogs .ui-state-focus, body.page-guides .ui-state-focus, body.page-edgeproptv .ui-state-focus, body.page-pullout .ui-state-focus, body.page-events .ui-state-focus, body.page-event .ui-state-focus, body.page-tags .ui-state-focus, body.page-node-29 .ui-state-focus, body.page-node-34 .ui-state-focus, body.page-content .ui-state-focus {
  outline: 0px;
}

body.page-news .accor-content, body.node-type-tep-blogs .accor-content, body.page-guides .accor-content, body.page-edgeproptv .accor-content, body.page-pullout .accor-content, body.page-events .accor-content, body.page-event .accor-content, body.page-tags .accor-content, body.page-node-29 .accor-content, body.page-node-34 .accor-content, body.page-content .accor-content {
  padding: 10px 30px 30px 30px;
  margin-top: 20px;
}

body.page-news .accor-content p:last-child, body.node-type-tep-blogs .accor-content p:last-child, body.page-guides .accor-content p:last-child, body.page-edgeproptv .accor-content p:last-child, body.page-pullout .accor-content p:last-child, body.page-events .accor-content p:last-child, body.page-event .accor-content p:last-child, body.page-tags .accor-content p:last-child, body.page-node-29 .accor-content p:last-child, body.page-node-34 .accor-content p:last-child, body.page-content .accor-content p:last-child {
  padding-bottom: 0px;
}

body.page-news .gallery-image-lightbox, body.node-type-tep-blogs .gallery-image-lightbox, body.page-guides .gallery-image-lightbox, body.page-edgeproptv .gallery-image-lightbox, body.page-pullout .gallery-image-lightbox, body.page-events .gallery-image-lightbox, body.page-event .gallery-image-lightbox, body.page-tags .gallery-image-lightbox, body.page-node-29 .gallery-image-lightbox, body.page-node-34 .gallery-image-lightbox, body.page-content .gallery-image-lightbox {
  position: relative;
  line-height: 0px;
  font-size: 0px;
}

body.page-news .gallery-image-lightbox a, body.node-type-tep-blogs .gallery-image-lightbox a, body.page-guides .gallery-image-lightbox a, body.page-edgeproptv .gallery-image-lightbox a, body.page-pullout .gallery-image-lightbox a, body.page-events .gallery-image-lightbox a, body.page-event .gallery-image-lightbox a, body.page-tags .gallery-image-lightbox a, body.page-node-29 .gallery-image-lightbox a, body.page-node-34 .gallery-image-lightbox a, body.page-content .gallery-image-lightbox a {
  line-height: 0px;
  font-size: 0px;
}

body.page-news .gallery-image-bg, body.node-type-tep-blogs .gallery-image-bg, body.page-guides .gallery-image-bg, body.page-edgeproptv .gallery-image-bg, body.page-pullout .gallery-image-bg, body.page-events .gallery-image-bg, body.page-event .gallery-image-bg, body.page-tags .gallery-image-bg, body.page-node-29 .gallery-image-bg, body.page-node-34 .gallery-image-bg, body.page-content .gallery-image-bg {
  position: absolute;
  left: 0px;
  top: 0px;
  width: 100%;
  height: 100%;
  content: '';
  opacity: 0;
  filter: alpha(opacity=0);
  /* For IE8 and earlier */
  transition: all 300ms ease-out;
  /*safari and chrome */
  -webkit-transition: all 300ms ease-out;
  /*safari and chrome */
}

body.page-news .gallery-image-icon, body.node-type-tep-blogs .gallery-image-icon, body.page-guides .gallery-image-icon, body.page-edgeproptv .gallery-image-icon, body.page-pullout .gallery-image-icon, body.page-events .gallery-image-icon, body.page-event .gallery-image-icon, body.page-tags .gallery-image-icon, body.page-node-29 .gallery-image-icon, body.page-node-34 .gallery-image-icon, body.page-content .gallery-image-icon {
  display: inline-block;
  position: absolute;
  left: 50%;
  top: 50%;
  margin-left: -30px;
  margin-top: -30px;
  width: 60px;
  height: 60px;
  content: '';
  text-align: center;
  border-radius: 5px;
  opacity: 0;
  filter: alpha(opacity=0);
  /* For IE8 and earlier */
  transition: all 300ms ease-out;
  /*safari and chrome */
  -webkit-transition: all 300ms ease-out;
  /*safari and chrome */
  transform: scale(0);
  -ms-transform: scale(0);
  /* IE 9 */
  -webkit-transform: scale(0);
  /* Safari and Chrome */
}

body.page-news .gallery-image-icon i, body.node-type-tep-blogs .gallery-image-icon i, body.page-guides .gallery-image-icon i, body.page-edgeproptv .gallery-image-icon i, body.page-pullout .gallery-image-icon i, body.page-events .gallery-image-icon i, body.page-event .gallery-image-icon i, body.page-tags .gallery-image-icon i, body.page-node-29 .gallery-image-icon i, body.page-node-34 .gallery-image-icon i, body.page-content .gallery-image-icon i {
  font-size: 30px;
  line-height: 60px;
}

body.page-news .gallery-image-lightbox:hover .gallery-image-bg, body.node-type-tep-blogs .gallery-image-lightbox:hover .gallery-image-bg, body.page-guides .gallery-image-lightbox:hover .gallery-image-bg, body.page-edgeproptv .gallery-image-lightbox:hover .gallery-image-bg, body.page-pullout .gallery-image-lightbox:hover .gallery-image-bg, body.page-events .gallery-image-lightbox:hover .gallery-image-bg, body.page-event .gallery-image-lightbox:hover .gallery-image-bg, body.page-tags .gallery-image-lightbox:hover .gallery-image-bg, body.page-node-29 .gallery-image-lightbox:hover .gallery-image-bg, body.page-node-34 .gallery-image-lightbox:hover .gallery-image-bg, body.page-content .gallery-image-lightbox:hover .gallery-image-bg {
  opacity: 0.8;
  filter: alpha(opacity=80);
  /* For IE8 and earlier */
}

body.page-news .gallery-image-lightbox:hover .gallery-image-icon, body.node-type-tep-blogs .gallery-image-lightbox:hover .gallery-image-icon, body.page-guides .gallery-image-lightbox:hover .gallery-image-icon, body.page-edgeproptv .gallery-image-lightbox:hover .gallery-image-icon, body.page-pullout .gallery-image-lightbox:hover .gallery-image-icon, body.page-events .gallery-image-lightbox:hover .gallery-image-icon, body.page-event .gallery-image-lightbox:hover .gallery-image-icon, body.page-tags .gallery-image-lightbox:hover .gallery-image-icon, body.page-node-29 .gallery-image-lightbox:hover .gallery-image-icon, body.page-node-34 .gallery-image-lightbox:hover .gallery-image-icon, body.page-content .gallery-image-lightbox:hover .gallery-image-icon {
  opacity: 1;
  filter: alpha(opacity=100);
  /* For IE8 and earlier */
  transform: scale(1);
  -ms-transform: scale(1);
  /* IE 9 */
  -webkit-transform: scale(1);
  /* Safari and Chrome */
}

body.page-news .gallery-image-lightbox .gallery-image-icon:hover, body.node-type-tep-blogs .gallery-image-lightbox .gallery-image-icon:hover, body.page-guides .gallery-image-lightbox .gallery-image-icon:hover, body.page-edgeproptv .gallery-image-lightbox .gallery-image-icon:hover, body.page-pullout .gallery-image-lightbox .gallery-image-icon:hover, body.page-events .gallery-image-lightbox .gallery-image-icon:hover, body.page-event .gallery-image-lightbox .gallery-image-icon:hover, body.page-tags .gallery-image-lightbox .gallery-image-icon:hover, body.page-node-29 .gallery-image-lightbox .gallery-image-icon:hover, body.page-node-34 .gallery-image-lightbox .gallery-image-icon:hover, body.page-content .gallery-image-lightbox .gallery-image-icon:hover {
  opacity: 1;
  filter: alpha(opacity=100);
  /* For IE8 and earlier */
}

body.page-news .image-left img, body.node-type-tep-blogs .image-left img, body.page-guides .image-left img, body.page-edgeproptv .image-left img, body.page-pullout .image-left img, body.page-events .image-left img, body.page-event .image-left img, body.page-tags .image-left img, body.page-node-29 .image-left img, body.page-node-34 .image-left img, body.page-content .image-left img {
  float: left;
}

body.page-news .image-right img, body.node-type-tep-blogs .image-right img, body.page-guides .image-right img, body.page-edgeproptv .image-right img, body.page-pullout .image-right img, body.page-events .image-right img, body.page-event .image-right img, body.page-tags .image-right img, body.page-node-29 .image-right img, body.page-node-34 .image-right img, body.page-content .image-right img {
  float: right;
}

body.page-news .image-center, body.node-type-tep-blogs .image-center, body.page-guides .image-center, body.page-edgeproptv .image-center, body.page-pullout .image-center, body.page-events .image-center, body.page-event .image-center, body.page-tags .image-center, body.page-node-29 .image-center, body.page-node-34 .image-center, body.page-content .image-center {
  text-align: center;
}

body.page-news div.pp_default .pp_description, body.node-type-tep-blogs div.pp_default .pp_description, body.page-guides div.pp_default .pp_description, body.page-edgeproptv div.pp_default .pp_description, body.page-pullout div.pp_default .pp_description, body.page-events div.pp_default .pp_description, body.page-event div.pp_default .pp_description, body.page-tags div.pp_default .pp_description, body.page-node-29 div.pp_default .pp_description, body.page-node-34 div.pp_default .pp_description, body.page-content div.pp_default .pp_description {
  color: #808080;
  padding: 0px 0px 0px 12px;
}

body.page-news .gallery-grid, body.node-type-tep-blogs .gallery-grid, body.page-guides .gallery-grid, body.page-edgeproptv .gallery-grid, body.page-pullout .gallery-grid, body.page-events .gallery-grid, body.page-event .gallery-grid, body.page-tags .gallery-grid, body.page-node-29 .gallery-grid, body.page-node-34 .gallery-grid, body.page-content .gallery-grid {
  line-height: 0px;
  font-size: 0px;
}

body.page-news .gallery-thumb-main, body.node-type-tep-blogs .gallery-thumb-main, body.page-guides .gallery-thumb-main, body.page-edgeproptv .gallery-thumb-main, body.page-pullout .gallery-thumb-main, body.page-events .gallery-thumb-main, body.page-event .gallery-thumb-main, body.page-tags .gallery-thumb-main, body.page-node-29 .gallery-thumb-main, body.page-node-34 .gallery-thumb-main, body.page-content .gallery-thumb-main {
  line-height: 0px;
  font-size: 0px;
  overflow: hidden;
}

body.page-news .gallery-thumb-bottom .gallery-thumb-column, body.node-type-tep-blogs .gallery-thumb-bottom .gallery-thumb-column, body.page-guides .gallery-thumb-bottom .gallery-thumb-column, body.page-edgeproptv .gallery-thumb-bottom .gallery-thumb-column, body.page-pullout .gallery-thumb-bottom .gallery-thumb-column, body.page-events .gallery-thumb-bottom .gallery-thumb-column, body.page-event .gallery-thumb-bottom .gallery-thumb-column, body.page-tags .gallery-thumb-bottom .gallery-thumb-column, body.page-node-29 .gallery-thumb-bottom .gallery-thumb-column, body.page-node-34 .gallery-thumb-bottom .gallery-thumb-column, body.page-content .gallery-thumb-bottom .gallery-thumb-column {
  position: relative;
  line-height: 0px;
  font-size: 0px;
  cursor: pointer;
}

body.page-news .gallery-thumb-bottom .gallery-thumb-border, body.node-type-tep-blogs .gallery-thumb-bottom .gallery-thumb-border, body.page-guides .gallery-thumb-bottom .gallery-thumb-border, body.page-edgeproptv .gallery-thumb-bottom .gallery-thumb-border, body.page-pullout .gallery-thumb-bottom .gallery-thumb-border, body.page-events .gallery-thumb-bottom .gallery-thumb-border, body.page-event .gallery-thumb-bottom .gallery-thumb-border, body.page-tags .gallery-thumb-bottom .gallery-thumb-border, body.page-node-29 .gallery-thumb-bottom .gallery-thumb-border, body.page-node-34 .gallery-thumb-bottom .gallery-thumb-border, body.page-content .gallery-thumb-bottom .gallery-thumb-border {
  position: absolute;
  width: 100%;
  height: 100%;
  content: '';
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  /* Firefox */
  transition: all 200ms ease-out;
  /*safari and chrome */
  -webkit-transition: all 200ms ease-out;
  /*safari and chrome */
  -webkit-box-sizing: border-box;
}

body.page-news .gallery-thumb-main .gallery-thumb-each, body.node-type-tep-blogs .gallery-thumb-main .gallery-thumb-each, body.page-guides .gallery-thumb-main .gallery-thumb-each, body.page-edgeproptv .gallery-thumb-main .gallery-thumb-each, body.page-pullout .gallery-thumb-main .gallery-thumb-each, body.page-events .gallery-thumb-main .gallery-thumb-each, body.page-event .gallery-thumb-main .gallery-thumb-each, body.page-tags .gallery-thumb-main .gallery-thumb-each, body.page-node-29 .gallery-thumb-main .gallery-thumb-each, body.page-node-34 .gallery-thumb-main .gallery-thumb-each, body.page-content .gallery-thumb-main .gallery-thumb-each {
  height: 0px;
  overflow: hidden;
}

body.page-news .gallery-thumb-main .gallery-current, body.node-type-tep-blogs .gallery-thumb-main .gallery-current, body.page-guides .gallery-thumb-main .gallery-current, body.page-edgeproptv .gallery-thumb-main .gallery-current, body.page-pullout .gallery-thumb-main .gallery-current, body.page-events .gallery-thumb-main .gallery-current, body.page-event .gallery-thumb-main .gallery-current, body.page-tags .gallery-thumb-main .gallery-current, body.page-node-29 .gallery-thumb-main .gallery-current, body.page-node-34 .gallery-thumb-main .gallery-current, body.page-content .gallery-thumb-main .gallery-current {
  height: auto;
}

body.page-news .info-box, body.node-type-tep-blogs .info-box, body.page-guides .info-box, body.page-edgeproptv .info-box, body.page-pullout .info-box, body.page-events .info-box, body.page-event .info-box, body.page-tags .info-box, body.page-node-29 .info-box, body.page-node-34 .info-box, body.page-content .info-box {
  position: relative;
  padding: 30px 60px 30px 40px;
  margin-bottom: 20px;
  transition: all 200ms ease-out;
  /*safari and chrome */
  -webkit-transition: all 200ms ease-out;
  /*safari and chrome */
}

body.page-news .info-box p:last-child, body.node-type-tep-blogs .info-box p:last-child, body.page-guides .info-box p:last-child, body.page-edgeproptv .info-box p:last-child, body.page-pullout .info-box p:last-child, body.page-events .info-box p:last-child, body.page-event .info-box p:last-child, body.page-tags .info-box p:last-child, body.page-node-29 .info-box p:last-child, body.page-node-34 .info-box p:last-child, body.page-content .info-box p:last-child {
  padding-bottom: 0px;
}

body.page-news .info-box-remove, body.node-type-tep-blogs .info-box-remove, body.page-guides .info-box-remove, body.page-edgeproptv .info-box-remove, body.page-pullout .info-box-remove, body.page-events .info-box-remove, body.page-event .info-box-remove, body.page-tags .info-box-remove, body.page-node-29 .info-box-remove, body.page-node-34 .info-box-remove, body.page-content .info-box-remove {
  position: absolute;
  right: 0px;
  top: 0px;
  margin-right: 8px;
  margin-top: 8px;
  text-align: center;
  opacity: 0.9;
  text-align: center;
  opacity: 0.5;
  filter: alpha(opacity=50);
  /* For IE8 and earlier */
  transition: all 200ms ease-out;
  /*safari and chrome */
  -webkit-transition: all 200ms ease-out;
  /*safari and chrome */
  cursor: pointer;
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  /* Firefox */
}

body.page-news .info-box-remove i, body.node-type-tep-blogs .info-box-remove i, body.page-guides .info-box-remove i, body.page-edgeproptv .info-box-remove i, body.page-pullout .info-box-remove i, body.page-events .info-box-remove i, body.page-event .info-box-remove i, body.page-tags .info-box-remove i, body.page-node-29 .info-box-remove i, body.page-node-34 .info-box-remove i, body.page-content .info-box-remove i {
  font-size: 18px;
  line-height: 18px;
  vertical-align: 4px;
}

body.page-news .info-box:hover .info-box-remove, body.node-type-tep-blogs .info-box:hover .info-box-remove, body.page-guides .info-box:hover .info-box-remove, body.page-edgeproptv .info-box:hover .info-box-remove, body.page-pullout .info-box:hover .info-box-remove, body.page-events .info-box:hover .info-box-remove, body.page-event .info-box:hover .info-box-remove, body.page-tags .info-box:hover .info-box-remove, body.page-node-29 .info-box:hover .info-box-remove, body.page-node-34 .info-box:hover .info-box-remove, body.page-content .info-box:hover .info-box-remove {
  opacity: 1;
  filter: alpha(opacity=100);
  /* For IE8 and earlier */
}

body.page-news .dropcap, body.node-type-tep-blogs .dropcap, body.page-guides .dropcap, body.page-edgeproptv .dropcap, body.page-pullout .dropcap, body.page-events .dropcap, body.page-event .dropcap, body.page-tags .dropcap, body.page-node-29 .dropcap, body.page-node-34 .dropcap, body.page-content .dropcap {
  float: left;
  text-transform: uppercase;
  width: 40px;
  height: 40px;
  padding: 0px;
  text-align: center;
  margin: 8px 10px 0px 0px;
  border-radius: 50%;
}

body.page-news .content ul.custom-list, body.node-type-tep-blogs .content ul.custom-list, body.page-guides .content ul.custom-list, body.page-edgeproptv .content ul.custom-list, body.page-pullout .content ul.custom-list, body.page-events .content ul.custom-list, body.page-event .content ul.custom-list, body.page-tags .content ul.custom-list, body.page-node-29 .content ul.custom-list, body.page-node-34 .content ul.custom-list, body.page-content .content ul.custom-list {
  list-style-type: none;
  margin: 0px;
  padding: 0px 0px 20px 0px;
}

body.page-news .content ul.custom-list i, body.node-type-tep-blogs .content ul.custom-list i, body.page-guides .content ul.custom-list i, body.page-edgeproptv .content ul.custom-list i, body.page-pullout .content ul.custom-list i, body.page-events .content ul.custom-list i, body.page-event .content ul.custom-list i, body.page-tags .content ul.custom-list i, body.page-node-29 .content ul.custom-list i, body.page-node-34 .content ul.custom-list i, body.page-content .content ul.custom-list i {
  margin-right: 5px;
  padding: 5px 0px;
  font-size: 14px;
  line-height: 14px;
  width: 24px;
  border-radius: 16px;
  margin-right: 5px;
  text-align: center;
  vertical-align: 1px;
}

body.page-news .social-icon, body.node-type-tep-blogs .social-icon, body.page-guides .social-icon, body.page-edgeproptv .social-icon, body.page-pullout .social-icon, body.page-events .social-icon, body.page-event .social-icon, body.page-tags .social-icon, body.page-node-29 .social-icon, body.page-node-34 .social-icon, body.page-content .social-icon {
  display: inline-block;
  width: 50px;
  height: 50px;
  line-height: 50px;
  transition: all 200ms ease-out;
  /*safari and chrome */
  -webkit-transition: all 200ms ease-out;
  /*safari and chrome */
  border-radius: 50%;
  text-align: center;
  margin-right: 10px;
  margin-bottom: 13px;
}

body.page-news .social-icon i, body.node-type-tep-blogs .social-icon i, body.page-guides .social-icon i, body.page-edgeproptv .social-icon i, body.page-pullout .social-icon i, body.page-events .social-icon i, body.page-event .social-icon i, body.page-tags .social-icon i, body.page-node-29 .social-icon i, body.page-node-34 .social-icon i, body.page-content .social-icon i {
  transition: all 200ms ease-out;
  /*safari and chrome */
  -webkit-transition: all 200ms ease-out;
  /*safari and chrome */
  font-size: 20px;
  line-height: 50px;
}

body.page-news .youtube-container, body.node-type-tep-blogs .youtube-container, body.page-guides .youtube-container, body.page-edgeproptv .youtube-container, body.page-pullout .youtube-container, body.page-events .youtube-container, body.page-event .youtube-container, body.page-tags .youtube-container, body.page-node-29 .youtube-container, body.page-node-34 .youtube-container, body.page-content .youtube-container {
  position: relative;
  /* keeps the aspect ratio */
  padding-bottom: 56.25%;
  /* fine tunes the video positioning */
  overflow: hidden;
}

body.page-news .youtube-container iframe,
body.page-news .youtube-container object,
body.page-news .youtube-container embed, body.node-type-tep-blogs .youtube-container iframe,
body.node-type-tep-blogs .youtube-container object,
body.node-type-tep-blogs .youtube-container embed, body.page-guides .youtube-container iframe,
body.page-guides .youtube-container object,
body.page-guides .youtube-container embed, body.page-edgeproptv .youtube-container iframe,
body.page-edgeproptv .youtube-container object,
body.page-edgeproptv .youtube-container embed, body.page-pullout .youtube-container iframe,
body.page-pullout .youtube-container object,
body.page-pullout .youtube-container embed, body.page-events .youtube-container iframe,
body.page-events .youtube-container object,
body.page-events .youtube-container embed, body.page-event .youtube-container iframe,
body.page-event .youtube-container object,
body.page-event .youtube-container embed, body.page-tags .youtube-container iframe,
body.page-tags .youtube-container object,
body.page-tags .youtube-container embed, body.page-node-29 .youtube-container iframe,
body.page-node-29 .youtube-container object,
body.page-node-29 .youtube-container embed, body.page-node-34 .youtube-container iframe,
body.page-node-34 .youtube-container object,
body.page-node-34 .youtube-container embed, body.page-content .youtube-container iframe,
body.page-content .youtube-container object,
body.page-content .youtube-container embed {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

body.page-news .post-fullwidth-top .vimeo-container, body.node-type-tep-blogs .post-fullwidth-top .vimeo-container, body.page-guides .post-fullwidth-top .vimeo-container, body.page-edgeproptv .post-fullwidth-top .vimeo-container, body.page-pullout .post-fullwidth-top .vimeo-container, body.page-events .post-fullwidth-top .vimeo-container, body.page-event .post-fullwidth-top .vimeo-container, body.page-tags .post-fullwidth-top .vimeo-container, body.page-node-29 .post-fullwidth-top .vimeo-container, body.page-node-34 .post-fullwidth-top .vimeo-container, body.page-content .post-fullwidth-top .vimeo-container {
  padding-bottom: 55%;
  /* fine tunes the video positioning */
}

body.page-news .vimeo-container, body.node-type-tep-blogs .vimeo-container, body.page-guides .vimeo-container, body.page-edgeproptv .vimeo-container, body.page-pullout .vimeo-container, body.page-events .vimeo-container, body.page-event .vimeo-container, body.page-tags .vimeo-container, body.page-node-29 .vimeo-container, body.page-node-34 .vimeo-container, body.page-content .vimeo-container {
  position: relative;
  /* keeps the aspect ratio */
  padding-bottom: 53%;
  /* fine tunes the video positioning */
  overflow: hidden;
}

body.page-news .vimeo-container iframe,
body.page-news .vimeo-container object,
body.page-news .vimeo-container embed, body.node-type-tep-blogs .vimeo-container iframe,
body.node-type-tep-blogs .vimeo-container object,
body.node-type-tep-blogs .vimeo-container embed, body.page-guides .vimeo-container iframe,
body.page-guides .vimeo-container object,
body.page-guides .vimeo-container embed, body.page-edgeproptv .vimeo-container iframe,
body.page-edgeproptv .vimeo-container object,
body.page-edgeproptv .vimeo-container embed, body.page-pullout .vimeo-container iframe,
body.page-pullout .vimeo-container object,
body.page-pullout .vimeo-container embed, body.page-events .vimeo-container iframe,
body.page-events .vimeo-container object,
body.page-events .vimeo-container embed, body.page-event .vimeo-container iframe,
body.page-event .vimeo-container object,
body.page-event .vimeo-container embed, body.page-tags .vimeo-container iframe,
body.page-tags .vimeo-container object,
body.page-tags .vimeo-container embed, body.page-node-29 .vimeo-container iframe,
body.page-node-29 .vimeo-container object,
body.page-node-29 .vimeo-container embed, body.page-node-34 .vimeo-container iframe,
body.page-node-34 .vimeo-container object,
body.page-node-34 .vimeo-container embed, body.page-content .vimeo-container iframe,
body.page-content .vimeo-container object,
body.page-content .vimeo-container embed {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

body.page-news .content .small-button,
body.page-news .content a.small-button, body.node-type-tep-blogs .content .small-button,
body.node-type-tep-blogs .content a.small-button, body.page-guides .content .small-button,
body.page-guides .content a.small-button, body.page-edgeproptv .content .small-button,
body.page-edgeproptv .content a.small-button, body.page-pullout .content .small-button,
body.page-pullout .content a.small-button, body.page-events .content .small-button,
body.page-events .content a.small-button, body.page-event .content .small-button,
body.page-event .content a.small-button, body.page-tags .content .small-button,
body.page-tags .content a.small-button, body.page-node-29 .content .small-button,
body.page-node-29 .content a.small-button, body.page-node-34 .content .small-button,
body.page-node-34 .content a.small-button, body.page-content .content .small-button,
body.page-content .content a.small-button {
  padding: 0px 20px;
  margin-bottom: 10px;
  margin-right: 10px;
  display: inline-block;
  cursor: pointer;
  transition: all 300ms ease-out;
  /*safari and chrome */
  -webkit-transition: all 300ms ease-out;
  /*safari and chrome */
}

body.page-news .small-button i, body.node-type-tep-blogs .small-button i, body.page-guides .small-button i, body.page-edgeproptv .small-button i, body.page-pullout .small-button i, body.page-events .small-button i, body.page-event .small-button i, body.page-tags .small-button i, body.page-node-29 .small-button i, body.page-node-34 .small-button i, body.page-content .small-button i {
  padding-right: 5px;
  text-decoration: none;
}

body.page-news .content input[type=submit],
body.page-news .content button,
body.page-news .content .normal-button,
body.page-news .content a.normal-button,
body.page-news .woocommerce #respond input#submit.alt,
body.page-news .woocommerce a.button.alt,
body.page-news .woocommerce button.button.alt,
body.page-news .woocommerce input.button.alt, body.node-type-tep-blogs .content input[type=submit],
body.node-type-tep-blogs .content button,
body.node-type-tep-blogs .content .normal-button,
body.node-type-tep-blogs .content a.normal-button,
body.node-type-tep-blogs .woocommerce #respond input#submit.alt,
body.node-type-tep-blogs .woocommerce a.button.alt,
body.node-type-tep-blogs .woocommerce button.button.alt,
body.node-type-tep-blogs .woocommerce input.button.alt, body.page-guides .content input[type=submit],
body.page-guides .content button,
body.page-guides .content .normal-button,
body.page-guides .content a.normal-button,
body.page-guides .woocommerce #respond input#submit.alt,
body.page-guides .woocommerce a.button.alt,
body.page-guides .woocommerce button.button.alt,
body.page-guides .woocommerce input.button.alt, body.page-edgeproptv .content input[type=submit],
body.page-edgeproptv .content button,
body.page-edgeproptv .content .normal-button,
body.page-edgeproptv .content a.normal-button,
body.page-edgeproptv .woocommerce #respond input#submit.alt,
body.page-edgeproptv .woocommerce a.button.alt,
body.page-edgeproptv .woocommerce button.button.alt,
body.page-edgeproptv .woocommerce input.button.alt, body.page-pullout .content input[type=submit],
body.page-pullout .content button,
body.page-pullout .content .normal-button,
body.page-pullout .content a.normal-button,
body.page-pullout .woocommerce #respond input#submit.alt,
body.page-pullout .woocommerce a.button.alt,
body.page-pullout .woocommerce button.button.alt,
body.page-pullout .woocommerce input.button.alt, body.page-events .content input[type=submit],
body.page-events .content button,
body.page-events .content .normal-button,
body.page-events .content a.normal-button,
body.page-events .woocommerce #respond input#submit.alt,
body.page-events .woocommerce a.button.alt,
body.page-events .woocommerce button.button.alt,
body.page-events .woocommerce input.button.alt, body.page-event .content input[type=submit],
body.page-event .content button,
body.page-event .content .normal-button,
body.page-event .content a.normal-button,
body.page-event .woocommerce #respond input#submit.alt,
body.page-event .woocommerce a.button.alt,
body.page-event .woocommerce button.button.alt,
body.page-event .woocommerce input.button.alt, body.page-tags .content input[type=submit],
body.page-tags .content button,
body.page-tags .content .normal-button,
body.page-tags .content a.normal-button,
body.page-tags .woocommerce #respond input#submit.alt,
body.page-tags .woocommerce a.button.alt,
body.page-tags .woocommerce button.button.alt,
body.page-tags .woocommerce input.button.alt, body.page-node-29 .content input[type=submit],
body.page-node-29 .content button,
body.page-node-29 .content .normal-button,
body.page-node-29 .content a.normal-button,
body.page-node-29 .woocommerce #respond input#submit.alt,
body.page-node-29 .woocommerce a.button.alt,
body.page-node-29 .woocommerce button.button.alt,
body.page-node-29 .woocommerce input.button.alt, body.page-node-34 .content input[type=submit],
body.page-node-34 .content button,
body.page-node-34 .content .normal-button,
body.page-node-34 .content a.normal-button,
body.page-node-34 .woocommerce #respond input#submit.alt,
body.page-node-34 .woocommerce a.button.alt,
body.page-node-34 .woocommerce button.button.alt,
body.page-node-34 .woocommerce input.button.alt, body.page-content .content input[type=submit],
body.page-content .content button,
body.page-content .content .normal-button,
body.page-content .content a.normal-button,
body.page-content .woocommerce #respond input#submit.alt,
body.page-content .woocommerce a.button.alt,
body.page-content .woocommerce button.button.alt,
body.page-content .woocommerce input.button.alt {
  padding: 6px 36px 4px;
  margin-bottom: 10px;
  margin-right: 10px;
  display: inline-block;
  cursor: pointer;
  transition: all 300ms ease-out;
  /*safari and chrome */
  -webkit-transition: all 300ms ease-out;
  /*safari and chrome */
}

body.page-news .normal-button i, body.node-type-tep-blogs .normal-button i, body.page-guides .normal-button i, body.page-edgeproptv .normal-button i, body.page-pullout .normal-button i, body.page-events .normal-button i, body.page-event .normal-button i, body.page-tags .normal-button i, body.page-node-29 .normal-button i, body.page-node-34 .normal-button i, body.page-content .normal-button i {
  padding-right: 8px;
  text-decoration: none;
}

body.page-news a.big-button, body.node-type-tep-blogs a.big-button, body.page-guides a.big-button, body.page-edgeproptv a.big-button, body.page-pullout a.big-button, body.page-events a.big-button, body.page-event a.big-button, body.page-tags a.big-button, body.page-node-29 a.big-button, body.page-node-34 a.big-button, body.page-content a.big-button {
  padding: 7px 40px;
  margin-bottom: 10px;
  margin-right: 10px;
  display: inline-block;
  cursor: pointer;
  transition: all 200ms ease-out;
  /*safari and chrome */
  -webkit-transition: all 200ms ease-out;
  /*safari and chrome */
}

body.page-news a.big-button i, body.node-type-tep-blogs a.big-button i, body.page-guides a.big-button i, body.page-edgeproptv a.big-button i, body.page-pullout a.big-button i, body.page-events a.big-button i, body.page-event a.big-button i, body.page-tags a.big-button i, body.page-node-29 a.big-button i, body.page-node-34 a.big-button i, body.page-content a.big-button i {
  padding-right: 10px;
  text-decoration: none;
}

body.page-news .sidebar-button, body.node-type-tep-blogs .sidebar-button, body.page-guides .sidebar-button, body.page-edgeproptv .sidebar-button, body.page-pullout .sidebar-button, body.page-events .sidebar-button, body.page-event .sidebar-button, body.page-tags .sidebar-button, body.page-node-29 .sidebar-button, body.page-node-34 .sidebar-button, body.page-content .sidebar-button {
  padding: 2px 8px;
  border-radius: 5px;
  display: inline-block;
  cursor: pointer;
  transition: all 200ms ease-out;
  /*safari and chrome */
  -webkit-transition: all 200ms ease-out;
  /*safari and chrome */
}

body.page-news .no-border, body.node-type-tep-blogs .no-border, body.page-guides .no-border, body.page-edgeproptv .no-border, body.page-pullout .no-border, body.page-events .no-border, body.page-event .no-border, body.page-tags .no-border, body.page-node-29 .no-border, body.page-node-34 .no-border, body.page-content .no-border {
  border-bottom: 0px;
}

body.page-news .author-bio-single, body.node-type-tep-blogs .author-bio-single, body.page-guides .author-bio-single, body.page-edgeproptv .author-bio-single, body.page-pullout .author-bio-single, body.page-events .author-bio-single, body.page-event .author-bio-single, body.page-tags .author-bio-single, body.page-node-29 .author-bio-single, body.page-node-34 .author-bio-single, body.page-content .author-bio-single {
  padding-top: 60px;
  border-bottom: 1px solid #E8E8E8;
}

body.page-news .author-bio, body.node-type-tep-blogs .author-bio, body.page-guides .author-bio, body.page-edgeproptv .author-bio, body.page-pullout .author-bio, body.page-events .author-bio, body.page-event .author-bio, body.page-tags .author-bio, body.page-node-29 .author-bio, body.page-node-34 .author-bio, body.page-content .author-bio {
  margin-bottom: 50px;
}

body.page-news .author-bio a, body.node-type-tep-blogs .author-bio a, body.page-guides .author-bio a, body.page-edgeproptv .author-bio a, body.page-pullout .author-bio a, body.page-events .author-bio a, body.page-event .author-bio a, body.page-tags .author-bio a, body.page-node-29 .author-bio a, body.page-node-34 .author-bio a, body.page-content .author-bio a {
  transition: all 200ms ease-out;
  /*safari and chrome */
  -webkit-transition: all 200ms ease-out;
  /*safari and chrome */
}

body.page-news .author-bio-avatar, body.node-type-tep-blogs .author-bio-avatar, body.page-guides .author-bio-avatar, body.page-edgeproptv .author-bio-avatar, body.page-pullout .author-bio-avatar, body.page-events .author-bio-avatar, body.page-event .author-bio-avatar, body.page-tags .author-bio-avatar, body.page-node-29 .author-bio-avatar, body.page-node-34 .author-bio-avatar, body.page-content .author-bio-avatar {
  float: left;
  width: 100px;
}

body.page-news .author-bio-avatar img, body.node-type-tep-blogs .author-bio-avatar img, body.page-guides .author-bio-avatar img, body.page-edgeproptv .author-bio-avatar img, body.page-pullout .author-bio-avatar img, body.page-events .author-bio-avatar img, body.page-event .author-bio-avatar img, body.page-tags .author-bio-avatar img, body.page-node-29 .author-bio-avatar img, body.page-node-34 .author-bio-avatar img, body.page-content .author-bio-avatar img {
  height: 100%;
}

body.page-news .author-bio-details, body.node-type-tep-blogs .author-bio-details, body.page-guides .author-bio-details, body.page-edgeproptv .author-bio-details, body.page-pullout .author-bio-details, body.page-events .author-bio-details, body.page-event .author-bio-details, body.page-tags .author-bio-details, body.page-node-29 .author-bio-details, body.page-node-34 .author-bio-details, body.page-content .author-bio-details {
  margin-left: 130px;
}

body.page-news .author-bio-title a, body.node-type-tep-blogs .author-bio-title a, body.page-guides .author-bio-title a, body.page-edgeproptv .author-bio-title a, body.page-pullout .author-bio-title a, body.page-events .author-bio-title a, body.page-event .author-bio-title a, body.page-tags .author-bio-title a, body.page-node-29 .author-bio-title a, body.page-node-34 .author-bio-title a, body.page-content .author-bio-title a {
  font-size: 14px;
  line-height: 18px;
  letter-spacing: 1px;
  color: #fff;
  font-weight: bold;
  padding: 3px 12px;
  position: relative;
  background-color: #1a1a1a;
  margin-bottom: 22px;
  text-transform: uppercase;
  /* Safari 3-4, iOS 1-3.2, Android 1.6- */
  -webkit-border-radius: 3px;
  /* Firefox 1-3.6 */
  -moz-border-radius: 3px;
  /* Opera 10.5, IE 9, Safari 5, Chrome, Firefox 4, iOS 4, Android 2.1+ */
  border-radius: 3px;
}

body.page-news .author-bio-description, body.node-type-tep-blogs .author-bio-description, body.page-guides .author-bio-description, body.page-edgeproptv .author-bio-description, body.page-pullout .author-bio-description, body.page-events .author-bio-description, body.page-event .author-bio-description, body.page-tags .author-bio-description, body.page-node-29 .author-bio-description, body.page-node-34 .author-bio-description, body.page-content .author-bio-description {
  font-weight: normal;
  font-size: 16px;
  line-height: 28px;
  color: #666;
  margin-bottom: 15px;
}

body.page-news a.author-bio-url, body.node-type-tep-blogs a.author-bio-url, body.page-guides a.author-bio-url, body.page-edgeproptv a.author-bio-url, body.page-pullout a.author-bio-url, body.page-events a.author-bio-url, body.page-event a.author-bio-url, body.page-tags a.author-bio-url, body.page-node-29 a.author-bio-url, body.page-node-34 a.author-bio-url, body.page-content a.author-bio-url {
  padding: 4px 10px 6px 10px;
  border-radius: 3px;
}

body.page-news .related-article, body.node-type-tep-blogs .related-article, body.page-guides .related-article, body.page-edgeproptv .related-article, body.page-pullout .related-article, body.page-events .related-article, body.page-event .related-article, body.page-tags .related-article, body.page-node-29 .related-article, body.page-node-34 .related-article, body.page-content .related-article {
  font-family: "Poppins", sans-serif !important;
  margin-top: 0;
  margin-bottom: 14px;
  border-bottom: 1px solid #d9d9d9;
  padding-bottom: 12px;
}

body.page-news .related-article h6, body.node-type-tep-blogs .related-article h6, body.page-guides .related-article h6, body.page-edgeproptv .related-article h6, body.page-pullout .related-article h6, body.page-events .related-article h6, body.page-event .related-article h6, body.page-tags .related-article h6, body.page-node-29 .related-article h6, body.page-node-34 .related-article h6, body.page-content .related-article h6 {
  margin: 5px 0;
  font-size: 16px;
  font-weight: bold;
  line-height: 18px;
  color: #1a1a1a;
}

body.page-news .related-article h6:hover, body.node-type-tep-blogs .related-article h6:hover, body.page-guides .related-article h6:hover, body.page-edgeproptv .related-article h6:hover, body.page-pullout .related-article h6:hover, body.page-events .related-article h6:hover, body.page-event .related-article h6:hover, body.page-tags .related-article h6:hover, body.page-node-29 .related-article h6:hover, body.page-node-34 .related-article h6:hover, body.page-content .related-article h6:hover {
  color: #666;
}

body.page-news .last-element, body.node-type-tep-blogs .last-element, body.page-guides .last-element, body.page-edgeproptv .last-element, body.page-pullout .last-element, body.page-events .last-element, body.page-event .last-element, body.page-tags .last-element, body.page-node-29 .last-element, body.page-node-34 .last-element, body.page-content .last-element {
  margin-right: 0 !important;
}

body.page-news .related-post, body.node-type-tep-blogs .related-post, body.page-guides .related-post, body.page-edgeproptv .related-post, body.page-pullout .related-post, body.page-events .related-post, body.page-event .related-post, body.page-tags .related-post, body.page-node-29 .related-post, body.page-node-34 .related-post, body.page-content .related-post {
  margin-bottom: 32px;
}

body.page-news h3#reply-title,
body.page-news .post-section-title h5,
body.page-news .sidebar-widget-title, body.node-type-tep-blogs h3#reply-title,
body.node-type-tep-blogs .post-section-title h5,
body.node-type-tep-blogs .sidebar-widget-title, body.page-guides h3#reply-title,
body.page-guides .post-section-title h5,
body.page-guides .sidebar-widget-title, body.page-edgeproptv h3#reply-title,
body.page-edgeproptv .post-section-title h5,
body.page-edgeproptv .sidebar-widget-title, body.page-pullout h3#reply-title,
body.page-pullout .post-section-title h5,
body.page-pullout .sidebar-widget-title, body.page-events h3#reply-title,
body.page-events .post-section-title h5,
body.page-events .sidebar-widget-title, body.page-event h3#reply-title,
body.page-event .post-section-title h5,
body.page-event .sidebar-widget-title, body.page-tags h3#reply-title,
body.page-tags .post-section-title h5,
body.page-tags .sidebar-widget-title, body.page-node-29 h3#reply-title,
body.page-node-29 .post-section-title h5,
body.page-node-29 .sidebar-widget-title, body.page-node-34 h3#reply-title,
body.page-node-34 .post-section-title h5,
body.page-node-34 .sidebar-widget-title, body.page-content h3#reply-title,
body.page-content .post-section-title h5,
body.page-content .sidebar-widget-title {
  display: block;
  color: #1a1a1a;
  font-weight: 600;
  text-transform: uppercase;
  font-size: 13px;
  border-bottom: 1px solid #dceaec;
  padding-bottom: 8px;
  margin: 0 0 12px;
  letter-spacing: 2px;
  line-height: 20px;
}

body.page-news h3#reply-title, body.node-type-tep-blogs h3#reply-title, body.page-guides h3#reply-title, body.page-edgeproptv h3#reply-title, body.page-pullout h3#reply-title, body.page-events h3#reply-title, body.page-event h3#reply-title, body.page-tags h3#reply-title, body.page-node-29 h3#reply-title, body.page-node-34 h3#reply-title, body.page-content h3#reply-title {
  margin: 22px 0;
}

body.page-news .covid-19-widget, body.node-type-tep-blogs .covid-19-widget, body.page-guides .covid-19-widget, body.page-edgeproptv .covid-19-widget, body.page-pullout .covid-19-widget, body.page-events .covid-19-widget, body.page-event .covid-19-widget, body.page-tags .covid-19-widget, body.page-node-29 .covid-19-widget, body.page-node-34 .covid-19-widget, body.page-content .covid-19-widget {
  pointer-events: all !important;
}

@media only screen and (max-width: 768px) {
  body.page-news .covid-19-widget, body.node-type-tep-blogs .covid-19-widget, body.page-guides .covid-19-widget, body.page-edgeproptv .covid-19-widget, body.page-pullout .covid-19-widget, body.page-events .covid-19-widget, body.page-event .covid-19-widget, body.page-tags .covid-19-widget, body.page-node-29 .covid-19-widget, body.page-node-34 .covid-19-widget, body.page-content .covid-19-widget {
    padding: 5px 10px 15px;
  }
}

body.page-news .covid-19-widget .post-section-title h5, body.node-type-tep-blogs .covid-19-widget .post-section-title h5, body.page-guides .covid-19-widget .post-section-title h5, body.page-edgeproptv .covid-19-widget .post-section-title h5, body.page-pullout .covid-19-widget .post-section-title h5, body.page-events .covid-19-widget .post-section-title h5, body.page-event .covid-19-widget .post-section-title h5, body.page-tags .covid-19-widget .post-section-title h5, body.page-node-29 .covid-19-widget .post-section-title h5, body.page-node-34 .covid-19-widget .post-section-title h5, body.page-content .covid-19-widget .post-section-title h5 {
  margin-bottom: 0;
}

body.page-news .covid-19-widget .covid-19-count-box, body.node-type-tep-blogs .covid-19-widget .covid-19-count-box, body.page-guides .covid-19-widget .covid-19-count-box, body.page-edgeproptv .covid-19-widget .covid-19-count-box, body.page-pullout .covid-19-widget .covid-19-count-box, body.page-events .covid-19-widget .covid-19-count-box, body.page-event .covid-19-widget .covid-19-count-box, body.page-tags .covid-19-widget .covid-19-count-box, body.page-node-29 .covid-19-widget .covid-19-count-box, body.page-node-34 .covid-19-widget .covid-19-count-box, body.page-content .covid-19-widget .covid-19-count-box {
  padding: 20px;
}

@media only screen and (max-width: 768px) {
  body.page-news .covid-19-widget .covid-19-count-box, body.node-type-tep-blogs .covid-19-widget .covid-19-count-box, body.page-guides .covid-19-widget .covid-19-count-box, body.page-edgeproptv .covid-19-widget .covid-19-count-box, body.page-pullout .covid-19-widget .covid-19-count-box, body.page-events .covid-19-widget .covid-19-count-box, body.page-event .covid-19-widget .covid-19-count-box, body.page-tags .covid-19-widget .covid-19-count-box, body.page-node-29 .covid-19-widget .covid-19-count-box, body.page-node-34 .covid-19-widget .covid-19-count-box, body.page-content .covid-19-widget .covid-19-count-box {
    padding: 20px 0;
  }
}

body.page-news .covid-19-widget .covid-19-count-box .total, body.node-type-tep-blogs .covid-19-widget .covid-19-count-box .total, body.page-guides .covid-19-widget .covid-19-count-box .total, body.page-edgeproptv .covid-19-widget .covid-19-count-box .total, body.page-pullout .covid-19-widget .covid-19-count-box .total, body.page-events .covid-19-widget .covid-19-count-box .total, body.page-event .covid-19-widget .covid-19-count-box .total, body.page-tags .covid-19-widget .covid-19-count-box .total, body.page-node-29 .covid-19-widget .covid-19-count-box .total, body.page-node-34 .covid-19-widget .covid-19-count-box .total, body.page-content .covid-19-widget .covid-19-count-box .total {
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 0px;
  margin-bottom: 0px;
  background: none;
  padding: 0px;
}

@media only screen and (max-width: 768px) {
  body.page-news .covid-19-widget .covid-19-count-box .total, body.node-type-tep-blogs .covid-19-widget .covid-19-count-box .total, body.page-guides .covid-19-widget .covid-19-count-box .total, body.page-edgeproptv .covid-19-widget .covid-19-count-box .total, body.page-pullout .covid-19-widget .covid-19-count-box .total, body.page-events .covid-19-widget .covid-19-count-box .total, body.page-event .covid-19-widget .covid-19-count-box .total, body.page-tags .covid-19-widget .covid-19-count-box .total, body.page-node-29 .covid-19-widget .covid-19-count-box .total, body.page-node-34 .covid-19-widget .covid-19-count-box .total, body.page-content .covid-19-widget .covid-19-count-box .total {
    font-size: 10px;
  }
}

body.page-news .covid-19-widget .covid-19-count-box .total-count, body.node-type-tep-blogs .covid-19-widget .covid-19-count-box .total-count, body.page-guides .covid-19-widget .covid-19-count-box .total-count, body.page-edgeproptv .covid-19-widget .covid-19-count-box .total-count, body.page-pullout .covid-19-widget .covid-19-count-box .total-count, body.page-events .covid-19-widget .covid-19-count-box .total-count, body.page-event .covid-19-widget .covid-19-count-box .total-count, body.page-tags .covid-19-widget .covid-19-count-box .total-count, body.page-node-29 .covid-19-widget .covid-19-count-box .total-count, body.page-node-34 .covid-19-widget .covid-19-count-box .total-count, body.page-content .covid-19-widget .covid-19-count-box .total-count {
  font-size: 42px;
  margin-bottom: 10px;
  margin-bottom: 0px;
  background: none;
  padding: 0px;
}

@media only screen and (max-width: 768px) {
  body.page-news .covid-19-widget .covid-19-count-box .total-count, body.node-type-tep-blogs .covid-19-widget .covid-19-count-box .total-count, body.page-guides .covid-19-widget .covid-19-count-box .total-count, body.page-edgeproptv .covid-19-widget .covid-19-count-box .total-count, body.page-pullout .covid-19-widget .covid-19-count-box .total-count, body.page-events .covid-19-widget .covid-19-count-box .total-count, body.page-event .covid-19-widget .covid-19-count-box .total-count, body.page-tags .covid-19-widget .covid-19-count-box .total-count, body.page-node-29 .covid-19-widget .covid-19-count-box .total-count, body.page-node-34 .covid-19-widget .covid-19-count-box .total-count, body.page-content .covid-19-widget .covid-19-count-box .total-count {
    font-size: 36px;
  }
}

body.page-news .covid-19-widget .covid-19-count-box .change, body.node-type-tep-blogs .covid-19-widget .covid-19-count-box .change, body.page-guides .covid-19-widget .covid-19-count-box .change, body.page-edgeproptv .covid-19-widget .covid-19-count-box .change, body.page-pullout .covid-19-widget .covid-19-count-box .change, body.page-events .covid-19-widget .covid-19-count-box .change, body.page-event .covid-19-widget .covid-19-count-box .change, body.page-tags .covid-19-widget .covid-19-count-box .change, body.page-node-29 .covid-19-widget .covid-19-count-box .change, body.page-node-34 .covid-19-widget .covid-19-count-box .change, body.page-content .covid-19-widget .covid-19-count-box .change {
  font-size: 14px;
  margin-bottom: 0px;
  margin-bottom: 0px !important;
  background: none;
  padding: 0px;
}

@media only screen and (max-width: 768px) {
  body.page-news .covid-19-widget .covid-19-count-box .change, body.node-type-tep-blogs .covid-19-widget .covid-19-count-box .change, body.page-guides .covid-19-widget .covid-19-count-box .change, body.page-edgeproptv .covid-19-widget .covid-19-count-box .change, body.page-pullout .covid-19-widget .covid-19-count-box .change, body.page-events .covid-19-widget .covid-19-count-box .change, body.page-event .covid-19-widget .covid-19-count-box .change, body.page-tags .covid-19-widget .covid-19-count-box .change, body.page-node-29 .covid-19-widget .covid-19-count-box .change, body.page-node-34 .covid-19-widget .covid-19-count-box .change, body.page-content .covid-19-widget .covid-19-count-box .change {
    font-size: 10px;
  }
}

body.page-news .covid-19-widget .covid-19-count-box .change-count, body.node-type-tep-blogs .covid-19-widget .covid-19-count-box .change-count, body.page-guides .covid-19-widget .covid-19-count-box .change-count, body.page-edgeproptv .covid-19-widget .covid-19-count-box .change-count, body.page-pullout .covid-19-widget .covid-19-count-box .change-count, body.page-events .covid-19-widget .covid-19-count-box .change-count, body.page-event .covid-19-widget .covid-19-count-box .change-count, body.page-tags .covid-19-widget .covid-19-count-box .change-count, body.page-node-29 .covid-19-widget .covid-19-count-box .change-count, body.page-node-34 .covid-19-widget .covid-19-count-box .change-count, body.page-content .covid-19-widget .covid-19-count-box .change-count {
  font-size: 32px;
  margin-bottom: 5px;
  margin-bottom: 0px;
  background: none;
  padding: 0px;
}

@media only screen and (max-width: 768px) {
  body.page-news .covid-19-widget .covid-19-count-box .change-count, body.node-type-tep-blogs .covid-19-widget .covid-19-count-box .change-count, body.page-guides .covid-19-widget .covid-19-count-box .change-count, body.page-edgeproptv .covid-19-widget .covid-19-count-box .change-count, body.page-pullout .covid-19-widget .covid-19-count-box .change-count, body.page-events .covid-19-widget .covid-19-count-box .change-count, body.page-event .covid-19-widget .covid-19-count-box .change-count, body.page-tags .covid-19-widget .covid-19-count-box .change-count, body.page-node-29 .covid-19-widget .covid-19-count-box .change-count, body.page-node-34 .covid-19-widget .covid-19-count-box .change-count, body.page-content .covid-19-widget .covid-19-count-box .change-count {
    font-size: 24px;
  }
}

body.page-news .covid-19-widget .covid-19-count-box .ft-red, body.node-type-tep-blogs .covid-19-widget .covid-19-count-box .ft-red, body.page-guides .covid-19-widget .covid-19-count-box .ft-red, body.page-edgeproptv .covid-19-widget .covid-19-count-box .ft-red, body.page-pullout .covid-19-widget .covid-19-count-box .ft-red, body.page-events .covid-19-widget .covid-19-count-box .ft-red, body.page-event .covid-19-widget .covid-19-count-box .ft-red, body.page-tags .covid-19-widget .covid-19-count-box .ft-red, body.page-node-29 .covid-19-widget .covid-19-count-box .ft-red, body.page-node-34 .covid-19-widget .covid-19-count-box .ft-red, body.page-content .covid-19-widget .covid-19-count-box .ft-red {
  color: #b80100;
}

body.page-news .covid-19-widget .covid-19-count-box .ft-blue, body.node-type-tep-blogs .covid-19-widget .covid-19-count-box .ft-blue, body.page-guides .covid-19-widget .covid-19-count-box .ft-blue, body.page-edgeproptv .covid-19-widget .covid-19-count-box .ft-blue, body.page-pullout .covid-19-widget .covid-19-count-box .ft-blue, body.page-events .covid-19-widget .covid-19-count-box .ft-blue, body.page-event .covid-19-widget .covid-19-count-box .ft-blue, body.page-tags .covid-19-widget .covid-19-count-box .ft-blue, body.page-node-29 .covid-19-widget .covid-19-count-box .ft-blue, body.page-node-34 .covid-19-widget .covid-19-count-box .ft-blue, body.page-content .covid-19-widget .covid-19-count-box .ft-blue {
  color: #1380a1;
}

body.page-news .covid-19-widget .find-out, body.node-type-tep-blogs .covid-19-widget .find-out, body.page-guides .covid-19-widget .find-out, body.page-edgeproptv .covid-19-widget .find-out, body.page-pullout .covid-19-widget .find-out, body.page-events .covid-19-widget .find-out, body.page-event .covid-19-widget .find-out, body.page-tags .covid-19-widget .find-out, body.page-node-29 .covid-19-widget .find-out, body.page-node-34 .covid-19-widget .find-out, body.page-content .covid-19-widget .find-out {
  margin-top: 10px;
  font-weight: 600;
  font-style: italic;
  padding-bottom: 0;
}

body.page-news .covid-19-widget .covid-19-title-header, body.node-type-tep-blogs .covid-19-widget .covid-19-title-header, body.page-guides .covid-19-widget .covid-19-title-header, body.page-edgeproptv .covid-19-widget .covid-19-title-header, body.page-pullout .covid-19-widget .covid-19-title-header, body.page-events .covid-19-widget .covid-19-title-header, body.page-event .covid-19-widget .covid-19-title-header, body.page-tags .covid-19-widget .covid-19-title-header, body.page-node-29 .covid-19-widget .covid-19-title-header, body.page-node-34 .covid-19-widget .covid-19-title-header, body.page-content .covid-19-widget .covid-19-title-header {
  background: #1380a1;
  padding: 0 20px;
}

body.page-news .covid-19-widget .covid-19-title-header h5, body.node-type-tep-blogs .covid-19-widget .covid-19-title-header h5, body.page-guides .covid-19-widget .covid-19-title-header h5, body.page-edgeproptv .covid-19-widget .covid-19-title-header h5, body.page-pullout .covid-19-widget .covid-19-title-header h5, body.page-events .covid-19-widget .covid-19-title-header h5, body.page-event .covid-19-widget .covid-19-title-header h5, body.page-tags .covid-19-widget .covid-19-title-header h5, body.page-node-29 .covid-19-widget .covid-19-title-header h5, body.page-node-34 .covid-19-widget .covid-19-title-header h5, body.page-content .covid-19-widget .covid-19-title-header h5 {
  color: #fff;
}

body.page-news .covid-19-widget .covid-19-body, body.node-type-tep-blogs .covid-19-widget .covid-19-body, body.page-guides .covid-19-widget .covid-19-body, body.page-edgeproptv .covid-19-widget .covid-19-body, body.page-pullout .covid-19-widget .covid-19-body, body.page-events .covid-19-widget .covid-19-body, body.page-event .covid-19-widget .covid-19-body, body.page-tags .covid-19-widget .covid-19-body, body.page-node-29 .covid-19-widget .covid-19-body, body.page-node-34 .covid-19-widget .covid-19-body, body.page-content .covid-19-widget .covid-19-body {
  background: #f2f2f2;
  padding: 0 20px 20px 20px;
}

body.page-news .covid-19-widget .covid-19-body .enter-postal, body.node-type-tep-blogs .covid-19-widget .covid-19-body .enter-postal, body.page-guides .covid-19-widget .covid-19-body .enter-postal, body.page-edgeproptv .covid-19-widget .covid-19-body .enter-postal, body.page-pullout .covid-19-widget .covid-19-body .enter-postal, body.page-events .covid-19-widget .covid-19-body .enter-postal, body.page-event .covid-19-widget .covid-19-body .enter-postal, body.page-tags .covid-19-widget .covid-19-body .enter-postal, body.page-node-29 .covid-19-widget .covid-19-body .enter-postal, body.page-node-34 .covid-19-widget .covid-19-body .enter-postal, body.page-content .covid-19-widget .covid-19-body .enter-postal {
  color: #6e6e73;
  font-size: 16px;
  margin: 0 0 10px 0;
}

body.page-news .covid-19-widget .covid-19-body .covid-result .success, body.node-type-tep-blogs .covid-19-widget .covid-19-body .covid-result .success, body.page-guides .covid-19-widget .covid-19-body .covid-result .success, body.page-edgeproptv .covid-19-widget .covid-19-body .covid-result .success, body.page-pullout .covid-19-widget .covid-19-body .covid-result .success, body.page-events .covid-19-widget .covid-19-body .covid-result .success, body.page-event .covid-19-widget .covid-19-body .covid-result .success, body.page-tags .covid-19-widget .covid-19-body .covid-result .success, body.page-node-29 .covid-19-widget .covid-19-body .covid-result .success, body.page-node-34 .covid-19-widget .covid-19-body .covid-result .success, body.page-content .covid-19-widget .covid-19-body .covid-result .success {
  color: #106078;
  font-size: 18px;
  margin: 10px 0 0 0;
}

body.page-news .covid-19-widget .covid-19-body .covid-result .success .RED, body.node-type-tep-blogs .covid-19-widget .covid-19-body .covid-result .success .RED, body.page-guides .covid-19-widget .covid-19-body .covid-result .success .RED, body.page-edgeproptv .covid-19-widget .covid-19-body .covid-result .success .RED, body.page-pullout .covid-19-widget .covid-19-body .covid-result .success .RED, body.page-events .covid-19-widget .covid-19-body .covid-result .success .RED, body.page-event .covid-19-widget .covid-19-body .covid-result .success .RED, body.page-tags .covid-19-widget .covid-19-body .covid-result .success .RED, body.page-node-29 .covid-19-widget .covid-19-body .covid-result .success .RED, body.page-node-34 .covid-19-widget .covid-19-body .covid-result .success .RED, body.page-content .covid-19-widget .covid-19-body .covid-result .success .RED {
  color: #900;
  font-weight: 600;
}

body.page-news .covid-19-widget .covid-19-body .covid-result .success .YELLOW, body.node-type-tep-blogs .covid-19-widget .covid-19-body .covid-result .success .YELLOW, body.page-guides .covid-19-widget .covid-19-body .covid-result .success .YELLOW, body.page-edgeproptv .covid-19-widget .covid-19-body .covid-result .success .YELLOW, body.page-pullout .covid-19-widget .covid-19-body .covid-result .success .YELLOW, body.page-events .covid-19-widget .covid-19-body .covid-result .success .YELLOW, body.page-event .covid-19-widget .covid-19-body .covid-result .success .YELLOW, body.page-tags .covid-19-widget .covid-19-body .covid-result .success .YELLOW, body.page-node-29 .covid-19-widget .covid-19-body .covid-result .success .YELLOW, body.page-node-34 .covid-19-widget .covid-19-body .covid-result .success .YELLOW, body.page-content .covid-19-widget .covid-19-body .covid-result .success .YELLOW {
  color: #FFAA1d;
  font-weight: 600;
}

body.page-news .covid-19-widget .covid-19-body .covid-result .success .GREEN, body.node-type-tep-blogs .covid-19-widget .covid-19-body .covid-result .success .GREEN, body.page-guides .covid-19-widget .covid-19-body .covid-result .success .GREEN, body.page-edgeproptv .covid-19-widget .covid-19-body .covid-result .success .GREEN, body.page-pullout .covid-19-widget .covid-19-body .covid-result .success .GREEN, body.page-events .covid-19-widget .covid-19-body .covid-result .success .GREEN, body.page-event .covid-19-widget .covid-19-body .covid-result .success .GREEN, body.page-tags .covid-19-widget .covid-19-body .covid-result .success .GREEN, body.page-node-29 .covid-19-widget .covid-19-body .covid-result .success .GREEN, body.page-node-34 .covid-19-widget .covid-19-body .covid-result .success .GREEN, body.page-content .covid-19-widget .covid-19-body .covid-result .success .GREEN {
  color: #008000;
  font-weight: 600;
}

body.page-news .covid-19-widget .covid-19-body .covid-result .fail, body.node-type-tep-blogs .covid-19-widget .covid-19-body .covid-result .fail, body.page-guides .covid-19-widget .covid-19-body .covid-result .fail, body.page-edgeproptv .covid-19-widget .covid-19-body .covid-result .fail, body.page-pullout .covid-19-widget .covid-19-body .covid-result .fail, body.page-events .covid-19-widget .covid-19-body .covid-result .fail, body.page-event .covid-19-widget .covid-19-body .covid-result .fail, body.page-tags .covid-19-widget .covid-19-body .covid-result .fail, body.page-node-29 .covid-19-widget .covid-19-body .covid-result .fail, body.page-node-34 .covid-19-widget .covid-19-body .covid-result .fail, body.page-content .covid-19-widget .covid-19-body .covid-result .fail {
  color: #900;
  font-size: 18px;
  margin: 10px 0 0 0;
}

body.page-news .covid-19-widget .covid-19-body .covid-result .source, body.node-type-tep-blogs .covid-19-widget .covid-19-body .covid-result .source, body.page-guides .covid-19-widget .covid-19-body .covid-result .source, body.page-edgeproptv .covid-19-widget .covid-19-body .covid-result .source, body.page-pullout .covid-19-widget .covid-19-body .covid-result .source, body.page-events .covid-19-widget .covid-19-body .covid-result .source, body.page-event .covid-19-widget .covid-19-body .covid-result .source, body.page-tags .covid-19-widget .covid-19-body .covid-result .source, body.page-node-29 .covid-19-widget .covid-19-body .covid-result .source, body.page-node-34 .covid-19-widget .covid-19-body .covid-result .source, body.page-content .covid-19-widget .covid-19-body .covid-result .source {
  color: #252525;
  font-size: 14px;
  font-weight: 500;
  margin: 2px 0 0 0;
}

body.page-news .covid-19-widget .covid-19-body .covid-note, body.node-type-tep-blogs .covid-19-widget .covid-19-body .covid-note, body.page-guides .covid-19-widget .covid-19-body .covid-note, body.page-edgeproptv .covid-19-widget .covid-19-body .covid-note, body.page-pullout .covid-19-widget .covid-19-body .covid-note, body.page-events .covid-19-widget .covid-19-body .covid-note, body.page-event .covid-19-widget .covid-19-body .covid-note, body.page-tags .covid-19-widget .covid-19-body .covid-note, body.page-node-29 .covid-19-widget .covid-19-body .covid-note, body.page-node-34 .covid-19-widget .covid-19-body .covid-note, body.page-content .covid-19-widget .covid-19-body .covid-note {
  color: #252525;
  font-size: 16px;
  margin: 10px 0 0 0;
}

body.page-news .covid-19-widget .covid-19-body .covid-note .mb10, body.node-type-tep-blogs .covid-19-widget .covid-19-body .covid-note .mb10, body.page-guides .covid-19-widget .covid-19-body .covid-note .mb10, body.page-edgeproptv .covid-19-widget .covid-19-body .covid-note .mb10, body.page-pullout .covid-19-widget .covid-19-body .covid-note .mb10, body.page-events .covid-19-widget .covid-19-body .covid-note .mb10, body.page-event .covid-19-widget .covid-19-body .covid-note .mb10, body.page-tags .covid-19-widget .covid-19-body .covid-note .mb10, body.page-node-29 .covid-19-widget .covid-19-body .covid-note .mb10, body.page-node-34 .covid-19-widget .covid-19-body .covid-note .mb10, body.page-content .covid-19-widget .covid-19-body .covid-note .mb10 {
  margin-bottom: 10px;
}

body.page-news .covid-19-widget .covid-19-body .covid-note .covid-explorer-link, body.node-type-tep-blogs .covid-19-widget .covid-19-body .covid-note .covid-explorer-link, body.page-guides .covid-19-widget .covid-19-body .covid-note .covid-explorer-link, body.page-edgeproptv .covid-19-widget .covid-19-body .covid-note .covid-explorer-link, body.page-pullout .covid-19-widget .covid-19-body .covid-note .covid-explorer-link, body.page-events .covid-19-widget .covid-19-body .covid-note .covid-explorer-link, body.page-event .covid-19-widget .covid-19-body .covid-note .covid-explorer-link, body.page-tags .covid-19-widget .covid-19-body .covid-note .covid-explorer-link, body.page-node-29 .covid-19-widget .covid-19-body .covid-note .covid-explorer-link, body.page-node-34 .covid-19-widget .covid-19-body .covid-note .covid-explorer-link, body.page-content .covid-19-widget .covid-19-body .covid-note .covid-explorer-link {
  color: #FF5A5F;
  font-weight: 600;
}

body.page-news .covid-19-widget .covid-19-body .covid-source p, body.node-type-tep-blogs .covid-19-widget .covid-19-body .covid-source p, body.page-guides .covid-19-widget .covid-19-body .covid-source p, body.page-edgeproptv .covid-19-widget .covid-19-body .covid-source p, body.page-pullout .covid-19-widget .covid-19-body .covid-source p, body.page-events .covid-19-widget .covid-19-body .covid-source p, body.page-event .covid-19-widget .covid-19-body .covid-source p, body.page-tags .covid-19-widget .covid-19-body .covid-source p, body.page-node-29 .covid-19-widget .covid-19-body .covid-source p, body.page-node-34 .covid-19-widget .covid-19-body .covid-source p, body.page-content .covid-19-widget .covid-19-body .covid-source p {
  border-top: 1px solid #aeaeb5;
  font-size: 14px;
  padding-top: 10px;
  color: #252525;
  font-size: 14px;
  font-weight: 500;
  margin: 0 0 0 0;
}

body.page-news .post-search-entry, body.node-type-tep-blogs .post-search-entry, body.page-guides .post-search-entry, body.page-edgeproptv .post-search-entry, body.page-pullout .post-search-entry, body.page-events .post-search-entry, body.page-event .post-search-entry, body.page-tags .post-search-entry, body.page-node-29 .post-search-entry, body.page-node-34 .post-search-entry, body.page-content .post-search-entry {
  padding-bottom: 40px;
}

body.page-news .entry-section .post-search-entry:last-child, body.node-type-tep-blogs .entry-section .post-search-entry:last-child, body.page-guides .entry-section .post-search-entry:last-child, body.page-edgeproptv .entry-section .post-search-entry:last-child, body.page-pullout .entry-section .post-search-entry:last-child, body.page-events .entry-section .post-search-entry:last-child, body.page-event .entry-section .post-search-entry:last-child, body.page-tags .entry-section .post-search-entry:last-child, body.page-node-29 .entry-section .post-search-entry:last-child, body.page-node-34 .entry-section .post-search-entry:last-child, body.page-content .entry-section .post-search-entry:last-child {
  padding-bottom: 60px;
}

body.page-news #comment-section, body.node-type-tep-blogs #comment-section, body.page-guides #comment-section, body.page-edgeproptv #comment-section, body.page-pullout #comment-section, body.page-events #comment-section, body.page-event #comment-section, body.page-tags #comment-section, body.page-node-29 #comment-section, body.page-node-34 #comment-section, body.page-content #comment-section {
  margin-bottom: 46px;
}

body.page-news #comment-container a, body.node-type-tep-blogs #comment-container a, body.page-guides #comment-container a, body.page-edgeproptv #comment-container a, body.page-pullout #comment-container a, body.page-events #comment-container a, body.page-event #comment-container a, body.page-tags #comment-container a, body.page-node-29 #comment-container a, body.page-node-34 #comment-container a, body.page-content #comment-container a {
  transition: all 200ms ease-out;
  /*safari and chrome */
  -webkit-transition: all 200ms ease-out;
  /*safari and chrome */
}

body.page-news #comment-container ul, body.node-type-tep-blogs #comment-container ul, body.page-guides #comment-container ul, body.page-edgeproptv #comment-container ul, body.page-pullout #comment-container ul, body.page-events #comment-container ul, body.page-event #comment-container ul, body.page-tags #comment-container ul, body.page-node-29 #comment-container ul, body.page-node-34 #comment-container ul, body.page-content #comment-container ul {
  padding: 0px;
  list-style-type: none;
}

body.page-news h3#reply-title, body.node-type-tep-blogs h3#reply-title, body.page-guides h3#reply-title, body.page-edgeproptv h3#reply-title, body.page-pullout h3#reply-title, body.page-events h3#reply-title, body.page-event h3#reply-title, body.page-tags h3#reply-title, body.page-node-29 h3#reply-title, body.page-node-34 h3#reply-title, body.page-content h3#reply-title {
  position: static;
}

body.page-news #cancel-comment-reply-link, body.node-type-tep-blogs #cancel-comment-reply-link, body.page-guides #cancel-comment-reply-link, body.page-edgeproptv #cancel-comment-reply-link, body.page-pullout #cancel-comment-reply-link, body.page-events #cancel-comment-reply-link, body.page-event #cancel-comment-reply-link, body.page-tags #cancel-comment-reply-link, body.page-node-29 #cancel-comment-reply-link, body.page-node-34 #cancel-comment-reply-link, body.page-content #cancel-comment-reply-link {
  position: absolute;
  top: 22px;
  right: 0;
  padding: 3px 8px;
  background-color: #1a1a1a;
  color: #fff;
  border-radius: 3px;
}

body.page-news .comment-respond, body.node-type-tep-blogs .comment-respond, body.page-guides .comment-respond, body.page-edgeproptv .comment-respond, body.page-pullout .comment-respond, body.page-events .comment-respond, body.page-event .comment-respond, body.page-tags .comment-respond, body.page-node-29 .comment-respond, body.page-node-34 .comment-respond, body.page-content .comment-respond {
  position: relative;
}

body.page-news .comment-entry, body.node-type-tep-blogs .comment-entry, body.page-guides .comment-entry, body.page-edgeproptv .comment-entry, body.page-pullout .comment-entry, body.page-events .comment-entry, body.page-event .comment-entry, body.page-tags .comment-entry, body.page-node-29 .comment-entry, body.page-node-34 .comment-entry, body.page-content .comment-entry {
  position: relative;
}

body.page-news .comment-info, body.node-type-tep-blogs .comment-info, body.page-guides .comment-info, body.page-edgeproptv .comment-info, body.page-pullout .comment-info, body.page-events .comment-info, body.page-event .comment-info, body.page-tags .comment-info, body.page-node-29 .comment-info, body.page-node-34 .comment-info, body.page-content .comment-info {
  padding-left: 102px;
}

body.page-news .comment-top, body.node-type-tep-blogs .comment-top, body.page-guides .comment-top, body.page-edgeproptv .comment-top, body.page-pullout .comment-top, body.page-events .comment-top, body.page-event .comment-top, body.page-tags .comment-top, body.page-node-29 .comment-top, body.page-node-34 .comment-top, body.page-content .comment-top {
  margin-bottom: 10px;
}

body.page-news .comment-author, body.node-type-tep-blogs .comment-author, body.page-guides .comment-author, body.page-edgeproptv .comment-author, body.page-pullout .comment-author, body.page-events .comment-author, body.page-event .comment-author, body.page-tags .comment-author, body.page-node-29 .comment-author, body.page-node-34 .comment-author, body.page-content .comment-author {
  font-weight: bold;
  display: inline-block;
  color: #1a1a1a;
  font-size: 18px;
  line-height: 28px;
  margin-right: 20px;
}

body.page-news .comment-date, body.node-type-tep-blogs .comment-date, body.page-guides .comment-date, body.page-edgeproptv .comment-date, body.page-pullout .comment-date, body.page-events .comment-date, body.page-event .comment-date, body.page-tags .comment-date, body.page-node-29 .comment-date, body.page-node-34 .comment-date, body.page-content .comment-date {
  font-weight: normal;
  font-size: 12px;
  color: #666;
  line-height: 14px;
  display: inline-block;
}

body.page-news .comment-date a, body.node-type-tep-blogs .comment-date a, body.page-guides .comment-date a, body.page-edgeproptv .comment-date a, body.page-pullout .comment-date a, body.page-events .comment-date a, body.page-event .comment-date a, body.page-tags .comment-date a, body.page-node-29 .comment-date a, body.page-node-34 .comment-date a, body.page-content .comment-date a {
  color: #999;
}

body.page-news .comment-content.content p, body.node-type-tep-blogs .comment-content.content p, body.page-guides .comment-content.content p, body.page-edgeproptv .comment-content.content p, body.page-pullout .comment-content.content p, body.page-events .comment-content.content p, body.page-event .comment-content.content p, body.page-tags .comment-content.content p, body.page-node-29 .comment-content.content p, body.page-node-34 .comment-content.content p, body.page-content .comment-content.content p {
  line-height: 24px;
}

body.page-news .comment-content.content p:last-child, body.node-type-tep-blogs .comment-content.content p:last-child, body.page-guides .comment-content.content p:last-child, body.page-edgeproptv .comment-content.content p:last-child, body.page-pullout .comment-content.content p:last-child, body.page-events .comment-content.content p:last-child, body.page-event .comment-content.content p:last-child, body.page-tags .comment-content.content p:last-child, body.page-node-29 .comment-content.content p:last-child, body.page-node-34 .comment-content.content p:last-child, body.page-content .comment-content.content p:last-child {
  padding-bottom: 0px;
}

body.page-news .comment-reply, body.node-type-tep-blogs .comment-reply, body.page-guides .comment-reply, body.page-edgeproptv .comment-reply, body.page-pullout .comment-reply, body.page-events .comment-reply, body.page-event .comment-reply, body.page-tags .comment-reply, body.page-node-29 .comment-reply, body.page-node-34 .comment-reply, body.page-content .comment-reply {
  float: right;
}

body.page-news .comment-reply a, body.node-type-tep-blogs .comment-reply a, body.page-guides .comment-reply a, body.page-edgeproptv .comment-reply a, body.page-pullout .comment-reply a, body.page-events .comment-reply a, body.page-event .comment-reply a, body.page-tags .comment-reply a, body.page-node-29 .comment-reply a, body.page-node-34 .comment-reply a, body.page-content .comment-reply a {
  font-weight: bold;
  font-size: 12px;
  line-height: 15px;
  letter-spacing: 1px;
  padding: 3px 8px;
  background-color: #1a1a1a;
  color: #fff;
  /* Safari 3-4, iOS 1-3.2, Android 1.6- */
  -webkit-border-radius: 3px;
  /* Firefox 1-3.6 */
  -moz-border-radius: 3px;
  /* Opera 10.5, IE 9, Safari 5, Chrome, Firefox 4, iOS 4, Android 2.1+ */
  border-radius: 3px;
  display: inline-block;
}

body.page-news .comment-navigation, body.node-type-tep-blogs .comment-navigation, body.page-guides .comment-navigation, body.page-edgeproptv .comment-navigation, body.page-pullout .comment-navigation, body.page-events .comment-navigation, body.page-event .comment-navigation, body.page-tags .comment-navigation, body.page-node-29 .comment-navigation, body.page-node-34 .comment-navigation, body.page-content .comment-navigation {
  margin-bottom: 18px;
}

body.page-news .bypostauthor .comment-author a, body.node-type-tep-blogs .bypostauthor .comment-author a, body.page-guides .bypostauthor .comment-author a, body.page-edgeproptv .bypostauthor .comment-author a, body.page-pullout .bypostauthor .comment-author a, body.page-events .bypostauthor .comment-author a, body.page-event .bypostauthor .comment-author a, body.page-tags .bypostauthor .comment-author a, body.page-node-29 .bypostauthor .comment-author a, body.page-node-34 .bypostauthor .comment-author a, body.page-content .bypostauthor .comment-author a {
  padding-bottom: 1px;
  display: inline-block;
}

body.page-news .comment-avatar, body.node-type-tep-blogs .comment-avatar, body.page-guides .comment-avatar, body.page-edgeproptv .comment-avatar, body.page-pullout .comment-avatar, body.page-events .comment-avatar, body.page-event .comment-avatar, body.page-tags .comment-avatar, body.page-node-29 .comment-avatar, body.page-node-34 .comment-avatar, body.page-content .comment-avatar {
  position: absolute;
  width: 72px;
  height: 72px;
}

body.page-news .comment-avatar img, body.node-type-tep-blogs .comment-avatar img, body.page-guides .comment-avatar img, body.page-edgeproptv .comment-avatar img, body.page-pullout .comment-avatar img, body.page-events .comment-avatar img, body.page-event .comment-avatar img, body.page-tags .comment-avatar img, body.page-node-29 .comment-avatar img, body.page-node-34 .comment-avatar img, body.page-content .comment-avatar img {
  max-width: 100%;
  max-height: 100%;
}

body.page-news .depth-2, body.node-type-tep-blogs .depth-2, body.page-guides .depth-2, body.page-edgeproptv .depth-2, body.page-pullout .depth-2, body.page-events .depth-2, body.page-event .depth-2, body.page-tags .depth-2, body.page-node-29 .depth-2, body.page-node-34 .depth-2, body.page-content .depth-2 {
  margin-left: 72px;
}

body.page-news .depth-3, body.node-type-tep-blogs .depth-3, body.page-guides .depth-3, body.page-edgeproptv .depth-3, body.page-pullout .depth-3, body.page-events .depth-3, body.page-event .depth-3, body.page-tags .depth-3, body.page-node-29 .depth-3, body.page-node-34 .depth-3, body.page-content .depth-3 {
  margin-left: 144px;
}

body.page-news .depth-4, body.node-type-tep-blogs .depth-4, body.page-guides .depth-4, body.page-edgeproptv .depth-4, body.page-pullout .depth-4, body.page-events .depth-4, body.page-event .depth-4, body.page-tags .depth-4, body.page-node-29 .depth-4, body.page-node-34 .depth-4, body.page-content .depth-4 {
  margin-left: 216px;
}

body.page-news .depth-5, body.node-type-tep-blogs .depth-5, body.page-guides .depth-5, body.page-edgeproptv .depth-5, body.page-pullout .depth-5, body.page-events .depth-5, body.page-event .depth-5, body.page-tags .depth-5, body.page-node-29 .depth-5, body.page-node-34 .depth-5, body.page-content .depth-5 {
  margin-left: 288px;
}

body.page-news .content textarea,
body.page-news .contact-form textarea,
body.page-news .content input, body.node-type-tep-blogs .content textarea,
body.node-type-tep-blogs .contact-form textarea,
body.node-type-tep-blogs .content input, body.page-guides .content textarea,
body.page-guides .contact-form textarea,
body.page-guides .content input, body.page-edgeproptv .content textarea,
body.page-edgeproptv .contact-form textarea,
body.page-edgeproptv .content input, body.page-pullout .content textarea,
body.page-pullout .contact-form textarea,
body.page-pullout .content input, body.page-events .content textarea,
body.page-events .contact-form textarea,
body.page-events .content input, body.page-event .content textarea,
body.page-event .contact-form textarea,
body.page-event .content input, body.page-tags .content textarea,
body.page-tags .contact-form textarea,
body.page-tags .content input, body.page-node-29 .content textarea,
body.page-node-29 .contact-form textarea,
body.page-node-29 .content input, body.page-node-34 .content textarea,
body.page-node-34 .contact-form textarea,
body.page-node-34 .content input, body.page-content .content textarea,
body.page-content .contact-form textarea,
body.page-content .content input {
  color: #808080;
  border: 1px solid #999;
  padding: 10px 18px;
  margin-bottom: 10px;
  font-size: 16px;
  line-height: 28px;
}

body.page-news .content input[type=submit], body.node-type-tep-blogs .content input[type=submit], body.page-guides .content input[type=submit], body.page-edgeproptv .content input[type=submit], body.page-pullout .content input[type=submit], body.page-events .content input[type=submit], body.page-event .content input[type=submit], body.page-tags .content input[type=submit], body.page-node-29 .content input[type=submit], body.page-node-34 .content input[type=submit], body.page-content .content input[type=submit] {
  cursor: pointer;
  border: 0;
  font-size: 14px;
  padding: 11px 18px;
  line-height: 28px;
  background-color: #1a1a1a;
  transition: all 200ms ease-out;
  /*safari and chrome */
  -webkit-transition: all 200ms ease-out;
  /*safari and chrome */
  color: #fff;
}

body.page-news .comment-form-comment, body.node-type-tep-blogs .comment-form-comment, body.page-guides .comment-form-comment, body.page-edgeproptv .comment-form-comment, body.page-pullout .comment-form-comment, body.page-events .comment-form-comment, body.page-event .comment-form-comment, body.page-tags .comment-form-comment, body.page-node-29 .comment-form-comment, body.page-node-34 .comment-form-comment, body.page-content .comment-form-comment {
  padding-bottom: 12px;
}

body.page-news .comment-form-left, body.node-type-tep-blogs .comment-form-left, body.page-guides .comment-form-left, body.page-edgeproptv .comment-form-left, body.page-pullout .comment-form-left, body.page-events .comment-form-left, body.page-event .comment-form-left, body.page-tags .comment-form-left, body.page-node-29 .comment-form-left, body.page-node-34 .comment-form-left, body.page-content .comment-form-left {
  padding-bottom: 8px;
}

body.page-news #comment-form label, body.node-type-tep-blogs #comment-form label, body.page-guides #comment-form label, body.page-edgeproptv #comment-form label, body.page-pullout #comment-form label, body.page-events #comment-form label, body.page-event #comment-form label, body.page-tags #comment-form label, body.page-node-29 #comment-form label, body.page-node-34 #comment-form label, body.page-content #comment-form label {
  font-size: 14px;
  line-height: 16px;
  padding-bottom: 15px;
  color: #1a1a1a;
  display: inline-block;
}

body.page-news .comment-form-left .comment-form-author,
body.page-news .comment-form-left .comment-form-email,
body.page-news .comment-form-left .comment-form-url, body.node-type-tep-blogs .comment-form-left .comment-form-author,
body.node-type-tep-blogs .comment-form-left .comment-form-email,
body.node-type-tep-blogs .comment-form-left .comment-form-url, body.page-guides .comment-form-left .comment-form-author,
body.page-guides .comment-form-left .comment-form-email,
body.page-guides .comment-form-left .comment-form-url, body.page-edgeproptv .comment-form-left .comment-form-author,
body.page-edgeproptv .comment-form-left .comment-form-email,
body.page-edgeproptv .comment-form-left .comment-form-url, body.page-pullout .comment-form-left .comment-form-author,
body.page-pullout .comment-form-left .comment-form-email,
body.page-pullout .comment-form-left .comment-form-url, body.page-events .comment-form-left .comment-form-author,
body.page-events .comment-form-left .comment-form-email,
body.page-events .comment-form-left .comment-form-url, body.page-event .comment-form-left .comment-form-author,
body.page-event .comment-form-left .comment-form-email,
body.page-event .comment-form-left .comment-form-url, body.page-tags .comment-form-left .comment-form-author,
body.page-tags .comment-form-left .comment-form-email,
body.page-tags .comment-form-left .comment-form-url, body.page-node-29 .comment-form-left .comment-form-author,
body.page-node-29 .comment-form-left .comment-form-email,
body.page-node-29 .comment-form-left .comment-form-url, body.page-node-34 .comment-form-left .comment-form-author,
body.page-node-34 .comment-form-left .comment-form-email,
body.page-node-34 .comment-form-left .comment-form-url, body.page-content .comment-form-left .comment-form-author,
body.page-content .comment-form-left .comment-form-email,
body.page-content .comment-form-left .comment-form-url {
  float: left;
  width: 30.6666%;
  margin-right: 4%;
  box-sizing: border-box;
}

body.page-news .comment-form-left .comment-form-url, body.node-type-tep-blogs .comment-form-left .comment-form-url, body.page-guides .comment-form-left .comment-form-url, body.page-edgeproptv .comment-form-left .comment-form-url, body.page-pullout .comment-form-left .comment-form-url, body.page-events .comment-form-left .comment-form-url, body.page-event .comment-form-left .comment-form-url, body.page-tags .comment-form-left .comment-form-url, body.page-node-29 .comment-form-left .comment-form-url, body.page-node-34 .comment-form-left .comment-form-url, body.page-content .comment-form-left .comment-form-url {
  margin-right: 0%;
}

body.page-news .content .comment-form-author input[type=text],
body.page-news .content .comment-form-email input[type=text],
body.page-news .content .comment-form-url input[type=text],
body.page-news .content .form-submit input[type=submit], body.node-type-tep-blogs .content .comment-form-author input[type=text],
body.node-type-tep-blogs .content .comment-form-email input[type=text],
body.node-type-tep-blogs .content .comment-form-url input[type=text],
body.node-type-tep-blogs .content .form-submit input[type=submit], body.page-guides .content .comment-form-author input[type=text],
body.page-guides .content .comment-form-email input[type=text],
body.page-guides .content .comment-form-url input[type=text],
body.page-guides .content .form-submit input[type=submit], body.page-edgeproptv .content .comment-form-author input[type=text],
body.page-edgeproptv .content .comment-form-email input[type=text],
body.page-edgeproptv .content .comment-form-url input[type=text],
body.page-edgeproptv .content .form-submit input[type=submit], body.page-pullout .content .comment-form-author input[type=text],
body.page-pullout .content .comment-form-email input[type=text],
body.page-pullout .content .comment-form-url input[type=text],
body.page-pullout .content .form-submit input[type=submit], body.page-events .content .comment-form-author input[type=text],
body.page-events .content .comment-form-email input[type=text],
body.page-events .content .comment-form-url input[type=text],
body.page-events .content .form-submit input[type=submit], body.page-event .content .comment-form-author input[type=text],
body.page-event .content .comment-form-email input[type=text],
body.page-event .content .comment-form-url input[type=text],
body.page-event .content .form-submit input[type=submit], body.page-tags .content .comment-form-author input[type=text],
body.page-tags .content .comment-form-email input[type=text],
body.page-tags .content .comment-form-url input[type=text],
body.page-tags .content .form-submit input[type=submit], body.page-node-29 .content .comment-form-author input[type=text],
body.page-node-29 .content .comment-form-email input[type=text],
body.page-node-29 .content .comment-form-url input[type=text],
body.page-node-29 .content .form-submit input[type=submit], body.page-node-34 .content .comment-form-author input[type=text],
body.page-node-34 .content .comment-form-email input[type=text],
body.page-node-34 .content .comment-form-url input[type=text],
body.page-node-34 .content .form-submit input[type=submit], body.page-content .content .comment-form-author input[type=text],
body.page-content .content .comment-form-email input[type=text],
body.page-content .content .comment-form-url input[type=text],
body.page-content .content .form-submit input[type=submit] {
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  /* Firefox */
  width: 100%;
}

body.page-news .comment-form-author,
body.page-news .comment-form-email,
body.page-news .form-submit, body.node-type-tep-blogs .comment-form-author,
body.node-type-tep-blogs .comment-form-email,
body.node-type-tep-blogs .form-submit, body.page-guides .comment-form-author,
body.page-guides .comment-form-email,
body.page-guides .form-submit, body.page-edgeproptv .comment-form-author,
body.page-edgeproptv .comment-form-email,
body.page-edgeproptv .form-submit, body.page-pullout .comment-form-author,
body.page-pullout .comment-form-email,
body.page-pullout .form-submit, body.page-events .comment-form-author,
body.page-events .comment-form-email,
body.page-events .form-submit, body.page-event .comment-form-author,
body.page-event .comment-form-email,
body.page-event .form-submit, body.page-tags .comment-form-author,
body.page-tags .comment-form-email,
body.page-tags .form-submit, body.page-node-29 .comment-form-author,
body.page-node-29 .comment-form-email,
body.page-node-29 .form-submit, body.page-node-34 .comment-form-author,
body.page-node-34 .comment-form-email,
body.page-node-34 .form-submit, body.page-content .comment-form-author,
body.page-content .comment-form-email,
body.page-content .form-submit {
  float: left;
  width: 29%;
  margin-right: 6.5%;
}

body.page-news .form-submit, body.node-type-tep-blogs .form-submit, body.page-guides .form-submit, body.page-edgeproptv .form-submit, body.page-pullout .form-submit, body.page-events .form-submit, body.page-event .form-submit, body.page-tags .form-submit, body.page-node-29 .form-submit, body.page-node-34 .form-submit, body.page-content .form-submit {
  margin-right: 0;
  margin-top: 32px;
}

body.page-news .content .comment-form-comment textarea, body.node-type-tep-blogs .content .comment-form-comment textarea, body.page-guides .content .comment-form-comment textarea, body.page-edgeproptv .content .comment-form-comment textarea, body.page-pullout .content .comment-form-comment textarea, body.page-events .content .comment-form-comment textarea, body.page-event .content .comment-form-comment textarea, body.page-tags .content .comment-form-comment textarea, body.page-node-29 .content .comment-form-comment textarea, body.page-node-34 .content .comment-form-comment textarea, body.page-content .content .comment-form-comment textarea {
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  /* Firefox */
  width: 100%;
  outline: none;
  margin-bottom: 0px;
}

body.page-news .form-allowed-tags, body.node-type-tep-blogs .form-allowed-tags, body.page-guides .form-allowed-tags, body.page-edgeproptv .form-allowed-tags, body.page-pullout .form-allowed-tags, body.page-events .form-allowed-tags, body.page-event .form-allowed-tags, body.page-tags .form-allowed-tags, body.page-node-29 .form-allowed-tags, body.page-node-34 .form-allowed-tags, body.page-content .form-allowed-tags {
  display: none;
}

body.page-news .entry-tags, body.node-type-tep-blogs .entry-tags, body.page-guides .entry-tags, body.page-edgeproptv .entry-tags, body.page-pullout .entry-tags, body.page-events .entry-tags, body.page-event .entry-tags, body.page-tags .entry-tags, body.page-node-29 .entry-tags, body.page-node-34 .entry-tags, body.page-content .entry-tags {
  margin-bottom: 50px;
}

body.page-news .entry-tags a,
body.page-news .entry-tags .text-tags, body.node-type-tep-blogs .entry-tags a,
body.node-type-tep-blogs .entry-tags .text-tags, body.page-guides .entry-tags a,
body.page-guides .entry-tags .text-tags, body.page-edgeproptv .entry-tags a,
body.page-edgeproptv .entry-tags .text-tags, body.page-pullout .entry-tags a,
body.page-pullout .entry-tags .text-tags, body.page-events .entry-tags a,
body.page-events .entry-tags .text-tags, body.page-event .entry-tags a,
body.page-event .entry-tags .text-tags, body.page-tags .entry-tags a,
body.page-tags .entry-tags .text-tags, body.page-node-29 .entry-tags a,
body.page-node-29 .entry-tags .text-tags, body.page-node-34 .entry-tags a,
body.page-node-34 .entry-tags .text-tags, body.page-content .entry-tags a,
body.page-content .entry-tags .text-tags {
  font-weight: bold;
  font-size: 10px;
  line-height: 12px;
  letter-spacing: 1px;
  color: #fff;
  background-color: #999;
  display: inline-block;
  padding: 2px 5px;
}

body.page-news .entry-tags .text-tags,
body.page-news .entry-tags a:hover, body.node-type-tep-blogs .entry-tags .text-tags,
body.node-type-tep-blogs .entry-tags a:hover, body.page-guides .entry-tags .text-tags,
body.page-guides .entry-tags a:hover, body.page-edgeproptv .entry-tags .text-tags,
body.page-edgeproptv .entry-tags a:hover, body.page-pullout .entry-tags .text-tags,
body.page-pullout .entry-tags a:hover, body.page-events .entry-tags .text-tags,
body.page-events .entry-tags a:hover, body.page-event .entry-tags .text-tags,
body.page-event .entry-tags a:hover, body.page-tags .entry-tags .text-tags,
body.page-tags .entry-tags a:hover, body.page-node-29 .entry-tags .text-tags,
body.page-node-29 .entry-tags a:hover, body.page-node-34 .entry-tags .text-tags,
body.page-node-34 .entry-tags a:hover, body.page-content .entry-tags .text-tags,
body.page-content .entry-tags a:hover {
  background-color: #1a1a1a;
}

body.page-news .use-sidebar-fixed, body.node-type-tep-blogs .use-sidebar-fixed, body.page-guides .use-sidebar-fixed, body.page-edgeproptv .use-sidebar-fixed, body.page-pullout .use-sidebar-fixed, body.page-events .use-sidebar-fixed, body.page-event .use-sidebar-fixed, body.page-tags .use-sidebar-fixed, body.page-node-29 .use-sidebar-fixed, body.page-node-34 .use-sidebar-fixed, body.page-content .use-sidebar-fixed {
  width: 60%;
}

body.page-news .menu li a, body.node-type-tep-blogs .menu li a, body.page-guides .menu li a, body.page-edgeproptv .menu li a, body.page-pullout .menu li a, body.page-events .menu li a, body.page-event .menu li a, body.page-tags .menu li a, body.page-node-29 .menu li a, body.page-node-34 .menu li a, body.page-content .menu li a {
  font-size: 14px;
  line-height: 18px;
  font-weight: normal;
  padding-bottom: 14px;
  color: #999;
}

body.page-news .menu li:last-of-type a, body.node-type-tep-blogs .menu li:last-of-type a, body.page-guides .menu li:last-of-type a, body.page-edgeproptv .menu li:last-of-type a, body.page-pullout .menu li:last-of-type a, body.page-events .menu li:last-of-type a, body.page-event .menu li:last-of-type a, body.page-tags .menu li:last-of-type a, body.page-node-29 .menu li:last-of-type a, body.page-node-34 .menu li:last-of-type a, body.page-content .menu li:last-of-type a {
  padding-bottom: 0;
}

body.page-news .widget_nav_menu ul li:last-of-type a, body.node-type-tep-blogs .widget_nav_menu ul li:last-of-type a, body.page-guides .widget_nav_menu ul li:last-of-type a, body.page-edgeproptv .widget_nav_menu ul li:last-of-type a, body.page-pullout .widget_nav_menu ul li:last-of-type a, body.page-events .widget_nav_menu ul li:last-of-type a, body.page-event .widget_nav_menu ul li:last-of-type a, body.page-tags .widget_nav_menu ul li:last-of-type a, body.page-node-29 .widget_nav_menu ul li:last-of-type a, body.page-node-34 .widget_nav_menu ul li:last-of-type a, body.page-content .widget_nav_menu ul li:last-of-type a {
  padding-bottom: 10px;
}

body.page-news #back_top, body.node-type-tep-blogs #back_top, body.page-guides #back_top, body.page-edgeproptv #back_top, body.page-pullout #back_top, body.page-events #back_top, body.page-event #back_top, body.page-tags #back_top, body.page-node-29 #back_top, body.page-node-34 #back_top, body.page-content #back_top {
  display: none;
  background-color: #488BF8;
  color: white;
  border: 1px solid #488BF8;
}

body.page-news body.page-content div#scroll-header .wrap, body.node-type-tep-blogs body.page-content div#scroll-header .wrap, body.page-guides body.page-content div#scroll-header .wrap, body.page-edgeproptv body.page-content div#scroll-header .wrap, body.page-pullout body.page-content div#scroll-header .wrap, body.page-events body.page-content div#scroll-header .wrap, body.page-event body.page-content div#scroll-header .wrap, body.page-tags body.page-content div#scroll-header .wrap, body.page-node-29 body.page-content div#scroll-header .wrap, body.page-node-34 body.page-content div#scroll-header .wrap, body.page-content body.page-content div#scroll-header .wrap {
  width: 100%;
  margin: 0;
  padding: 0 1.5rem;
}

body.page-news blockquote.news-quote, body.node-type-tep-blogs blockquote.news-quote, body.page-guides blockquote.news-quote, body.page-edgeproptv blockquote.news-quote, body.page-pullout blockquote.news-quote, body.page-events blockquote.news-quote, body.page-event blockquote.news-quote, body.page-tags blockquote.news-quote, body.page-node-29 blockquote.news-quote, body.page-node-34 blockquote.news-quote, body.page-content blockquote.news-quote {
  position: relative;
  margin: 30px 50px;
  background-color: #f2f2f2;
  padding: 32px 50px;
}

body.page-news body.page-content .main-content, body.node-type-tep-blogs body.page-content .main-content, body.page-guides body.page-content .main-content, body.page-edgeproptv body.page-content .main-content, body.page-pullout body.page-content .main-content, body.page-events body.page-content .main-content, body.page-event body.page-content .main-content, body.page-tags body.page-content .main-content, body.page-node-29 body.page-content .main-content, body.page-node-34 body.page-content .main-content, body.page-content body.page-content .main-content {
  padding-left: 100px;
}

body.page-news body.page-content header.header-layout-style-1 .wrap, body.node-type-tep-blogs body.page-content header.header-layout-style-1 .wrap, body.page-guides body.page-content header.header-layout-style-1 .wrap, body.page-edgeproptv body.page-content header.header-layout-style-1 .wrap, body.page-pullout body.page-content header.header-layout-style-1 .wrap, body.page-events body.page-content header.header-layout-style-1 .wrap, body.page-event body.page-content header.header-layout-style-1 .wrap, body.page-tags body.page-content header.header-layout-style-1 .wrap, body.page-node-29 body.page-content header.header-layout-style-1 .wrap, body.page-node-34 body.page-content header.header-layout-style-1 .wrap, body.page-content body.page-content header.header-layout-style-1 .wrap {
  width: 100%;
}

body.page-news body.page-content header.header-layout-style-1, body.node-type-tep-blogs body.page-content header.header-layout-style-1, body.page-guides body.page-content header.header-layout-style-1, body.page-edgeproptv body.page-content header.header-layout-style-1, body.page-pullout body.page-content header.header-layout-style-1, body.page-events body.page-content header.header-layout-style-1, body.page-event body.page-content header.header-layout-style-1, body.page-tags body.page-content header.header-layout-style-1, body.page-node-29 body.page-content header.header-layout-style-1, body.page-node-34 body.page-content header.header-layout-style-1, body.page-content body.page-content header.header-layout-style-1 {
  margin: 0 1.5rem;
}

body.page-news body.page-content header .header-menu, body.node-type-tep-blogs body.page-content header .header-menu, body.page-guides body.page-content header .header-menu, body.page-edgeproptv body.page-content header .header-menu, body.page-pullout body.page-content header .header-menu, body.page-events body.page-content header .header-menu, body.page-event body.page-content header .header-menu, body.page-tags body.page-content header .header-menu, body.page-node-29 body.page-content header .header-menu, body.page-node-34 body.page-content header .header-menu, body.page-content body.page-content header .header-menu {
  border-bottom: 1px solid transparent;
}

body.page-news .landing-pullout a.dark-blue-color.top-post-cat, body.node-type-tep-blogs .landing-pullout a.dark-blue-color.top-post-cat, body.page-guides .landing-pullout a.dark-blue-color.top-post-cat, body.page-edgeproptv .landing-pullout a.dark-blue-color.top-post-cat, body.page-pullout .landing-pullout a.dark-blue-color.top-post-cat, body.page-events .landing-pullout a.dark-blue-color.top-post-cat, body.page-event .landing-pullout a.dark-blue-color.top-post-cat, body.page-tags .landing-pullout a.dark-blue-color.top-post-cat, body.page-node-29 .landing-pullout a.dark-blue-color.top-post-cat, body.page-node-34 .landing-pullout a.dark-blue-color.top-post-cat, body.page-content .landing-pullout a.dark-blue-color.top-post-cat {
  margin-bottom: 0;
  text-transform: none;
  font-size: 12px;
  letter-spacing: 0.08em;
  display: block;
  width: fit-content;
}

body.page-news .landing-pullout a:hover, body.page-news .landing-pullout a:focus, body.node-type-tep-blogs .landing-pullout a:hover, body.node-type-tep-blogs .landing-pullout a:focus, body.page-guides .landing-pullout a:hover, body.page-guides .landing-pullout a:focus, body.page-edgeproptv .landing-pullout a:hover, body.page-edgeproptv .landing-pullout a:focus, body.page-pullout .landing-pullout a:hover, body.page-pullout .landing-pullout a:focus, body.page-events .landing-pullout a:hover, body.page-events .landing-pullout a:focus, body.page-event .landing-pullout a:hover, body.page-event .landing-pullout a:focus, body.page-tags .landing-pullout a:hover, body.page-tags .landing-pullout a:focus, body.page-node-29 .landing-pullout a:hover, body.page-node-29 .landing-pullout a:focus, body.page-node-34 .landing-pullout a:hover, body.page-node-34 .landing-pullout a:focus, body.page-content .landing-pullout a:hover, body.page-content .landing-pullout a:focus {
  text-decoration: none;
}

body.page-news .flexslider.video, body.node-type-tep-blogs .flexslider.video, body.page-guides .flexslider.video, body.page-edgeproptv .flexslider.video, body.page-pullout .flexslider.video, body.page-events .flexslider.video, body.page-event .flexslider.video, body.page-tags .flexslider.video, body.page-node-29 .flexslider.video, body.page-node-34 .flexslider.video, body.page-content .flexslider.video {
  margin: 0;
  border: 0;
}

body.page-news #content-section6 .content-column2_1 article.post-entry .box-thumb,
body.page-news #content-section2 article.post-entry .box-thumb, body.node-type-tep-blogs #content-section6 .content-column2_1 article.post-entry .box-thumb,
body.node-type-tep-blogs #content-section2 article.post-entry .box-thumb, body.page-guides #content-section6 .content-column2_1 article.post-entry .box-thumb,
body.page-guides #content-section2 article.post-entry .box-thumb, body.page-edgeproptv #content-section6 .content-column2_1 article.post-entry .box-thumb,
body.page-edgeproptv #content-section2 article.post-entry .box-thumb, body.page-pullout #content-section6 .content-column2_1 article.post-entry .box-thumb,
body.page-pullout #content-section2 article.post-entry .box-thumb, body.page-events #content-section6 .content-column2_1 article.post-entry .box-thumb,
body.page-events #content-section2 article.post-entry .box-thumb, body.page-event #content-section6 .content-column2_1 article.post-entry .box-thumb,
body.page-event #content-section2 article.post-entry .box-thumb, body.page-tags #content-section6 .content-column2_1 article.post-entry .box-thumb,
body.page-tags #content-section2 article.post-entry .box-thumb, body.page-node-29 #content-section6 .content-column2_1 article.post-entry .box-thumb,
body.page-node-29 #content-section2 article.post-entry .box-thumb, body.page-node-34 #content-section6 .content-column2_1 article.post-entry .box-thumb,
body.page-node-34 #content-section2 article.post-entry .box-thumb, body.page-content #content-section6 .content-column2_1 article.post-entry .box-thumb,
body.page-content #content-section2 article.post-entry .box-thumb {
  height: 80px;
  overflow: hidden;
}

body.page-news #content-section6 .content-column2_1 article.post-entry img,
body.page-news #content-section2 article.post-entry .box-thumb img, body.node-type-tep-blogs #content-section6 .content-column2_1 article.post-entry img,
body.node-type-tep-blogs #content-section2 article.post-entry .box-thumb img, body.page-guides #content-section6 .content-column2_1 article.post-entry img,
body.page-guides #content-section2 article.post-entry .box-thumb img, body.page-edgeproptv #content-section6 .content-column2_1 article.post-entry img,
body.page-edgeproptv #content-section2 article.post-entry .box-thumb img, body.page-pullout #content-section6 .content-column2_1 article.post-entry img,
body.page-pullout #content-section2 article.post-entry .box-thumb img, body.page-events #content-section6 .content-column2_1 article.post-entry img,
body.page-events #content-section2 article.post-entry .box-thumb img, body.page-event #content-section6 .content-column2_1 article.post-entry img,
body.page-event #content-section2 article.post-entry .box-thumb img, body.page-tags #content-section6 .content-column2_1 article.post-entry img,
body.page-tags #content-section2 article.post-entry .box-thumb img, body.page-node-29 #content-section6 .content-column2_1 article.post-entry img,
body.page-node-29 #content-section2 article.post-entry .box-thumb img, body.page-node-34 #content-section6 .content-column2_1 article.post-entry img,
body.page-node-34 #content-section2 article.post-entry .box-thumb img, body.page-content #content-section6 .content-column2_1 article.post-entry img,
body.page-content #content-section2 article.post-entry .box-thumb img {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  object-fit: cover;
  height: 100%;
  width: 100%;
}

body.page-news .video-iframe.img-responsive, body.node-type-tep-blogs .video-iframe.img-responsive, body.page-guides .video-iframe.img-responsive, body.page-edgeproptv .video-iframe.img-responsive, body.page-pullout .video-iframe.img-responsive, body.page-events .video-iframe.img-responsive, body.page-event .video-iframe.img-responsive, body.page-tags .video-iframe.img-responsive, body.page-node-29 .video-iframe.img-responsive, body.page-node-34 .video-iframe.img-responsive, body.page-content .video-iframe.img-responsive {
  width: 100%;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.75);
  position: fixed;
  display: flex;
  justify-content: center;
  z-index: 2002;
}

body.page-news .video-iframe .close-btn, body.node-type-tep-blogs .video-iframe .close-btn, body.page-guides .video-iframe .close-btn, body.page-edgeproptv .video-iframe .close-btn, body.page-pullout .video-iframe .close-btn, body.page-events .video-iframe .close-btn, body.page-event .video-iframe .close-btn, body.page-tags .video-iframe .close-btn, body.page-node-29 .video-iframe .close-btn, body.page-node-34 .video-iframe .close-btn, body.page-content .video-iframe .close-btn {
  position: absolute;
  top: auto;
  right: 0;
  font-size: 28px;
  padding: 0.8rem 1.6rem;
  color: white;
  cursor: pointer;
}

@media (min-width: 991px) {
  body.page-news .video-iframe .close-btn, body.node-type-tep-blogs .video-iframe .close-btn, body.page-guides .video-iframe .close-btn, body.page-edgeproptv .video-iframe .close-btn, body.page-pullout .video-iframe .close-btn, body.page-events .video-iframe .close-btn, body.page-event .video-iframe .close-btn, body.page-tags .video-iframe .close-btn, body.page-node-29 .video-iframe .close-btn, body.page-node-34 .video-iframe .close-btn, body.page-content .video-iframe .close-btn {
    top: 60px;
  }
}

body.page-news .video-iframe iframe, body.node-type-tep-blogs .video-iframe iframe, body.page-guides .video-iframe iframe, body.page-edgeproptv .video-iframe iframe, body.page-pullout .video-iframe iframe, body.page-events .video-iframe iframe, body.page-event .video-iframe iframe, body.page-tags .video-iframe iframe, body.page-node-29 .video-iframe iframe, body.page-node-34 .video-iframe iframe, body.page-content .video-iframe iframe {
  align-self: center;
}

body.page-news .search-img.entry-thumb img, body.node-type-tep-blogs .search-img.entry-thumb img, body.page-guides .search-img.entry-thumb img, body.page-edgeproptv .search-img.entry-thumb img, body.page-pullout .search-img.entry-thumb img, body.page-events .search-img.entry-thumb img, body.page-event .search-img.entry-thumb img, body.page-tags .search-img.entry-thumb img, body.page-node-29 .search-img.entry-thumb img, body.page-node-34 .search-img.entry-thumb img, body.page-content .search-img.entry-thumb img {
  width: 100%;
}

body.page-news .outter, body.node-type-tep-blogs .outter, body.page-guides .outter, body.page-edgeproptv .outter, body.page-pullout .outter, body.page-events .outter, body.page-event .outter, body.page-tags .outter, body.page-node-29 .outter, body.page-node-34 .outter, body.page-content .outter {
  width: 50%;
  display: inline;
}

body.page-news .event-list, body.node-type-tep-blogs .event-list, body.page-guides .event-list, body.page-edgeproptv .event-list, body.page-pullout .event-list, body.page-events .event-list, body.page-event .event-list, body.page-tags .event-list, body.page-node-29 .event-list, body.page-node-34 .event-list, body.page-content .event-list {
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-start;
}

body.page-news .event-date, body.node-type-tep-blogs .event-date, body.page-guides .event-date, body.page-edgeproptv .event-date, body.page-pullout .event-date, body.page-events .event-date, body.page-event .event-date, body.page-tags .event-date, body.page-node-29 .event-date, body.page-node-34 .event-date, body.page-content .event-date {
  padding: 1.5rem 0;
  flex: 1 0 20%;
  min-width: 110px;
  text-align: center;
}

body.page-news .event-date .calendar span.month, body.node-type-tep-blogs .event-date .calendar span.month, body.page-guides .event-date .calendar span.month, body.page-edgeproptv .event-date .calendar span.month, body.page-pullout .event-date .calendar span.month, body.page-events .event-date .calendar span.month, body.page-event .event-date .calendar span.month, body.page-tags .event-date .calendar span.month, body.page-node-29 .event-date .calendar span.month, body.page-node-34 .event-date .calendar span.month, body.page-content .event-date .calendar span.month {
  font-size: 16px;
  padding: 1rem .5rem 0 .5rem;
  text-transform: uppercase;
}

body.page-news .event-date .calendar span.date, body.node-type-tep-blogs .event-date .calendar span.date, body.page-guides .event-date .calendar span.date, body.page-edgeproptv .event-date .calendar span.date, body.page-pullout .event-date .calendar span.date, body.page-events .event-date .calendar span.date, body.page-event .event-date .calendar span.date, body.page-tags .event-date .calendar span.date, body.page-node-29 .event-date .calendar span.date, body.page-node-34 .event-date .calendar span.date, body.page-content .event-date .calendar span.date {
  font-size: 48px;
  padding: 0 .5rem .5rem;
  vertical-align: baseline;
}

body.page-news .event-date .calendar span.day, body.node-type-tep-blogs .event-date .calendar span.day, body.page-guides .event-date .calendar span.day, body.page-edgeproptv .event-date .calendar span.day, body.page-pullout .event-date .calendar span.day, body.page-events .event-date .calendar span.day, body.page-event .event-date .calendar span.day, body.page-tags .event-date .calendar span.day, body.page-node-29 .event-date .calendar span.day, body.page-node-34 .event-date .calendar span.day, body.page-content .event-date .calendar span.day {
  font-size: 12px;
  padding: 1rem .5rem;
  text-transform: uppercase;
  background-color: #252525;
}

body.page-news .event-date > .calendar, body.node-type-tep-blogs .event-date > .calendar, body.page-guides .event-date > .calendar, body.page-edgeproptv .event-date > .calendar, body.page-pullout .event-date > .calendar, body.page-events .event-date > .calendar, body.page-event .event-date > .calendar, body.page-tags .event-date > .calendar, body.page-node-29 .event-date > .calendar, body.page-node-34 .event-date > .calendar, body.page-content .event-date > .calendar {
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  width: 100%;
  color: white;
  background: linear-gradient(150deg, #5eb9f5 0%, #d202cb 100%);
}

body.page-news .event-list .meta, body.node-type-tep-blogs .event-list .meta, body.page-guides .event-list .meta, body.page-edgeproptv .event-list .meta, body.page-pullout .event-list .meta, body.page-events .event-list .meta, body.page-event .event-list .meta, body.page-tags .event-list .meta, body.page-node-29 .event-list .meta, body.page-node-34 .event-list .meta, body.page-content .event-list .meta {
  padding: 1rem 1.5rem 1.5rem 4rem;
}

body.page-news .event-list .meta a, body.node-type-tep-blogs .event-list .meta a, body.page-guides .event-list .meta a, body.page-edgeproptv .event-list .meta a, body.page-pullout .event-list .meta a, body.page-events .event-list .meta a, body.page-event .event-list .meta a, body.page-tags .event-list .meta a, body.page-node-29 .event-list .meta a, body.page-node-34 .event-list .meta a, body.page-content .event-list .meta a {
  display: block;
  font-weight: 550;
  font-size: 2.8rem;
}

body.page-news .event-list .meta span, body.page-news .event-list .meta p, body.node-type-tep-blogs .event-list .meta span, body.node-type-tep-blogs .event-list .meta p, body.page-guides .event-list .meta span, body.page-guides .event-list .meta p, body.page-edgeproptv .event-list .meta span, body.page-edgeproptv .event-list .meta p, body.page-pullout .event-list .meta span, body.page-pullout .event-list .meta p, body.page-events .event-list .meta span, body.page-events .event-list .meta p, body.page-event .event-list .meta span, body.page-event .event-list .meta p, body.page-tags .event-list .meta span, body.page-tags .event-list .meta p, body.page-node-29 .event-list .meta span, body.page-node-29 .event-list .meta p, body.page-node-34 .event-list .meta span, body.page-node-34 .event-list .meta p, body.page-content .event-list .meta span, body.page-content .event-list .meta p {
  color: #666;
}

body.page-news .related-post div:nth-last-child(2), body.node-type-tep-blogs .related-post div:nth-last-child(2), body.page-guides .related-post div:nth-last-child(2), body.page-edgeproptv .related-post div:nth-last-child(2), body.page-pullout .related-post div:nth-last-child(2), body.page-events .related-post div:nth-last-child(2), body.page-event .related-post div:nth-last-child(2), body.page-tags .related-post div:nth-last-child(2), body.page-node-29 .related-post div:nth-last-child(2), body.page-node-34 .related-post div:nth-last-child(2), body.page-content .related-post div:nth-last-child(2) {
  margin-right: 0;
}

body.page-news p.mailchimp-input, body.node-type-tep-blogs p.mailchimp-input, body.page-guides p.mailchimp-input, body.page-edgeproptv p.mailchimp-input, body.page-pullout p.mailchimp-input, body.page-events p.mailchimp-input, body.page-event p.mailchimp-input, body.page-tags p.mailchimp-input, body.page-node-29 p.mailchimp-input, body.page-node-34 p.mailchimp-input, body.page-content p.mailchimp-input {
  padding: 0;
  margin: 0;
}

body.page-news .mce-error-response, body.node-type-tep-blogs .mce-error-response, body.page-guides .mce-error-response, body.page-edgeproptv .mce-error-response, body.page-pullout .mce-error-response, body.page-events .mce-error-response, body.page-event .mce-error-response, body.page-tags .mce-error-response, body.page-node-29 .mce-error-response, body.page-node-34 .mce-error-response, body.page-content .mce-error-response {
  color: red;
}

body.page-news .mce-success-response, body.node-type-tep-blogs .mce-success-response, body.page-guides .mce-success-response, body.page-edgeproptv .mce-success-response, body.page-pullout .mce-success-response, body.page-events .mce-success-response, body.page-event .mce-success-response, body.page-tags .mce-success-response, body.page-node-29 .mce-success-response, body.page-node-34 .mce-success-response, body.page-content .mce-success-response {
  color: blue;
}

body.page-news .block-mrec, body.node-type-tep-blogs .block-mrec, body.page-guides .block-mrec, body.page-edgeproptv .block-mrec, body.page-pullout .block-mrec, body.page-events .block-mrec, body.page-event .block-mrec, body.page-tags .block-mrec, body.page-node-29 .block-mrec, body.page-node-34 .block-mrec, body.page-content .block-mrec {
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
}

body.page-news .block-mrec > div, body.node-type-tep-blogs .block-mrec > div, body.page-guides .block-mrec > div, body.page-edgeproptv .block-mrec > div, body.page-pullout .block-mrec > div, body.page-events .block-mrec > div, body.page-event .block-mrec > div, body.page-tags .block-mrec > div, body.page-node-29 .block-mrec > div, body.page-node-34 .block-mrec > div, body.page-content .block-mrec > div {
  min-width: 30rem;
}

body.page-news .header-main-menu ul, body.node-type-tep-blogs .header-main-menu ul, body.page-guides .header-main-menu ul, body.page-edgeproptv .header-main-menu ul, body.page-pullout .header-main-menu ul, body.page-events .header-main-menu ul, body.page-event .header-main-menu ul, body.page-tags .header-main-menu ul, body.page-node-29 .header-main-menu ul, body.page-node-34 .header-main-menu ul, body.page-content .header-main-menu ul {
  margin-bottom: 0;
}

body.page-news .entry-content p, body.node-type-tep-blogs .entry-content p, body.page-guides .entry-content p, body.page-edgeproptv .entry-content p, body.page-pullout .entry-content p, body.page-events .entry-content p, body.page-event .entry-content p, body.page-tags .entry-content p, body.page-node-29 .entry-content p, body.page-node-34 .entry-content p, body.page-content .entry-content p {
  margin: 0;
}

body.page-news article.featured.post-entry:before, body.node-type-tep-blogs article.featured.post-entry:before, body.page-guides article.featured.post-entry:before, body.page-edgeproptv article.featured.post-entry:before, body.page-pullout article.featured.post-entry:before, body.page-events article.featured.post-entry:before, body.page-event article.featured.post-entry:before, body.page-tags article.featured.post-entry:before, body.page-node-29 article.featured.post-entry:before, body.page-node-34 article.featured.post-entry:before, body.page-content article.featured.post-entry:before {
  content: 'Featured';
  display: block;
  padding: .5rem 1rem;
  background-color: #c94d51;
  color: #fff;
  font-size: 1.2rem;
  letter-spacing: .08rem;
  position: absolute;
  top: 1rem;
  left: -.1rem;
  z-index: 1;
}

body.page-news .entry-meta .last-updated, body.node-type-tep-blogs .entry-meta .last-updated, body.page-guides .entry-meta .last-updated, body.page-edgeproptv .entry-meta .last-updated, body.page-pullout .entry-meta .last-updated, body.page-events .entry-meta .last-updated, body.page-event .entry-meta .last-updated, body.page-tags .entry-meta .last-updated, body.page-node-29 .entry-meta .last-updated, body.page-node-34 .entry-meta .last-updated, body.page-content .entry-meta .last-updated {
  font-size: 11px;
  text-align: right;
  font-weight: 300;
  letter-spacing: 0.02em;
  display: none;
}

@media screen and (min-width: 1025px) {
  body.page-news body, body.node-type-tep-blogs body, body.page-guides body, body.page-edgeproptv body, body.page-pullout body, body.page-events body, body.page-event body, body.page-tags body, body.page-node-29 body, body.page-node-34 body, body.page-content body {
    padding-top: 0;
  }
}

@media screen and (max-width: 1024px) {
  body.page-news body, body.node-type-tep-blogs body, body.page-guides body, body.page-edgeproptv body, body.page-pullout body, body.page-events body, body.page-event body, body.page-tags body, body.page-node-29 body, body.page-node-34 body, body.page-content body {
    padding-top: 0;
  }
}

@media screen and (max-width: 1280px) {
  body.page-news .wrap-colum, body.node-type-tep-blogs .wrap-colum, body.page-guides .wrap-colum, body.page-edgeproptv .wrap-colum, body.page-pullout .wrap-colum, body.page-events .wrap-colum, body.page-event .wrap-colum, body.page-tags .wrap-colum, body.page-node-29 .wrap-colum, body.page-node-34 .wrap-colum, body.page-content .wrap-colum {
    width: 1140px;
  }
}

@media screen and (max-width: 1180px) {
  body.page-news, body.node-type-tep-blogs, body.page-guides, body.page-edgeproptv, body.page-pullout, body.page-events, body.page-event, body.page-tags, body.page-node-29, body.page-node-34, body.page-content {
    /*CONTAINER ELEMENT*/
    /* blog comment */
  }
  body.page-news .wrap, body.node-type-tep-blogs .wrap, body.page-guides .wrap, body.page-edgeproptv .wrap, body.page-pullout .wrap, body.page-events .wrap, body.page-event .wrap, body.page-tags .wrap, body.page-node-29 .wrap, body.page-node-34 .wrap, body.page-content .wrap {
    width: 960px;
  }
  body.page-news .wrap-colum, body.node-type-tep-blogs .wrap-colum, body.page-guides .wrap-colum, body.page-edgeproptv .wrap-colum, body.page-pullout .wrap-colum, body.page-events .wrap-colum, body.page-event .wrap-colum, body.page-tags .wrap-colum, body.page-node-29 .wrap-colum, body.page-node-34 .wrap-colum, body.page-content .wrap-colum {
    width: 1000px;
  }
  body.page-news .sidebar, body.node-type-tep-blogs .sidebar, body.page-guides .sidebar, body.page-edgeproptv .sidebar, body.page-pullout .sidebar, body.page-events .sidebar, body.page-event .sidebar, body.page-tags .sidebar, body.page-node-29 .sidebar, body.page-node-34 .sidebar, body.page-content .sidebar {
    padding-left: 0;
  }
  body.page-news .content-column2_1:nth-of-type(2n+1),
  body.page-news .post-layout-2 article:nth-of-type(2n+1),
  body.page-news .post-layout-4 article:nth-of-type(2n+2),
  body.page-news .post-layout-5 article:first-of-type .box-thumb,
  body.page-news .iblock-2 article:nth-of-type(2n+1), body.node-type-tep-blogs .content-column2_1:nth-of-type(2n+1),
  body.node-type-tep-blogs .post-layout-2 article:nth-of-type(2n+1),
  body.node-type-tep-blogs .post-layout-4 article:nth-of-type(2n+2),
  body.node-type-tep-blogs .post-layout-5 article:first-of-type .box-thumb,
  body.node-type-tep-blogs .iblock-2 article:nth-of-type(2n+1), body.page-guides .content-column2_1:nth-of-type(2n+1),
  body.page-guides .post-layout-2 article:nth-of-type(2n+1),
  body.page-guides .post-layout-4 article:nth-of-type(2n+2),
  body.page-guides .post-layout-5 article:first-of-type .box-thumb,
  body.page-guides .iblock-2 article:nth-of-type(2n+1), body.page-edgeproptv .content-column2_1:nth-of-type(2n+1),
  body.page-edgeproptv .post-layout-2 article:nth-of-type(2n+1),
  body.page-edgeproptv .post-layout-4 article:nth-of-type(2n+2),
  body.page-edgeproptv .post-layout-5 article:first-of-type .box-thumb,
  body.page-edgeproptv .iblock-2 article:nth-of-type(2n+1), body.page-pullout .content-column2_1:nth-of-type(2n+1),
  body.page-pullout .post-layout-2 article:nth-of-type(2n+1),
  body.page-pullout .post-layout-4 article:nth-of-type(2n+2),
  body.page-pullout .post-layout-5 article:first-of-type .box-thumb,
  body.page-pullout .iblock-2 article:nth-of-type(2n+1), body.page-events .content-column2_1:nth-of-type(2n+1),
  body.page-events .post-layout-2 article:nth-of-type(2n+1),
  body.page-events .post-layout-4 article:nth-of-type(2n+2),
  body.page-events .post-layout-5 article:first-of-type .box-thumb,
  body.page-events .iblock-2 article:nth-of-type(2n+1), body.page-event .content-column2_1:nth-of-type(2n+1),
  body.page-event .post-layout-2 article:nth-of-type(2n+1),
  body.page-event .post-layout-4 article:nth-of-type(2n+2),
  body.page-event .post-layout-5 article:first-of-type .box-thumb,
  body.page-event .iblock-2 article:nth-of-type(2n+1), body.page-tags .content-column2_1:nth-of-type(2n+1),
  body.page-tags .post-layout-2 article:nth-of-type(2n+1),
  body.page-tags .post-layout-4 article:nth-of-type(2n+2),
  body.page-tags .post-layout-5 article:first-of-type .box-thumb,
  body.page-tags .iblock-2 article:nth-of-type(2n+1), body.page-node-29 .content-column2_1:nth-of-type(2n+1),
  body.page-node-29 .post-layout-2 article:nth-of-type(2n+1),
  body.page-node-29 .post-layout-4 article:nth-of-type(2n+2),
  body.page-node-29 .post-layout-5 article:first-of-type .box-thumb,
  body.page-node-29 .iblock-2 article:nth-of-type(2n+1), body.page-node-34 .content-column2_1:nth-of-type(2n+1),
  body.page-node-34 .post-layout-2 article:nth-of-type(2n+1),
  body.page-node-34 .post-layout-4 article:nth-of-type(2n+2),
  body.page-node-34 .post-layout-5 article:first-of-type .box-thumb,
  body.page-node-34 .iblock-2 article:nth-of-type(2n+1), body.page-content .content-column2_1:nth-of-type(2n+1),
  body.page-content .post-layout-2 article:nth-of-type(2n+1),
  body.page-content .post-layout-4 article:nth-of-type(2n+2),
  body.page-content .post-layout-5 article:first-of-type .box-thumb,
  body.page-content .iblock-2 article:nth-of-type(2n+1) {
    padding-right: 15px;
  }
  body.page-news .content-column2_1:nth-of-type(2n),
  body.page-news .post-layout-2 article:nth-of-type(2n+2),
  body.page-news .post-layout-4 article:nth-of-type(2n+1),
  body.page-news .post-layout-5 article:first-of-type .box-details,
  body.page-news .iblock-2 article:nth-of-type(2n+2), body.node-type-tep-blogs .content-column2_1:nth-of-type(2n),
  body.node-type-tep-blogs .post-layout-2 article:nth-of-type(2n+2),
  body.node-type-tep-blogs .post-layout-4 article:nth-of-type(2n+1),
  body.node-type-tep-blogs .post-layout-5 article:first-of-type .box-details,
  body.node-type-tep-blogs .iblock-2 article:nth-of-type(2n+2), body.page-guides .content-column2_1:nth-of-type(2n),
  body.page-guides .post-layout-2 article:nth-of-type(2n+2),
  body.page-guides .post-layout-4 article:nth-of-type(2n+1),
  body.page-guides .post-layout-5 article:first-of-type .box-details,
  body.page-guides .iblock-2 article:nth-of-type(2n+2), body.page-edgeproptv .content-column2_1:nth-of-type(2n),
  body.page-edgeproptv .post-layout-2 article:nth-of-type(2n+2),
  body.page-edgeproptv .post-layout-4 article:nth-of-type(2n+1),
  body.page-edgeproptv .post-layout-5 article:first-of-type .box-details,
  body.page-edgeproptv .iblock-2 article:nth-of-type(2n+2), body.page-pullout .content-column2_1:nth-of-type(2n),
  body.page-pullout .post-layout-2 article:nth-of-type(2n+2),
  body.page-pullout .post-layout-4 article:nth-of-type(2n+1),
  body.page-pullout .post-layout-5 article:first-of-type .box-details,
  body.page-pullout .iblock-2 article:nth-of-type(2n+2), body.page-events .content-column2_1:nth-of-type(2n),
  body.page-events .post-layout-2 article:nth-of-type(2n+2),
  body.page-events .post-layout-4 article:nth-of-type(2n+1),
  body.page-events .post-layout-5 article:first-of-type .box-details,
  body.page-events .iblock-2 article:nth-of-type(2n+2), body.page-event .content-column2_1:nth-of-type(2n),
  body.page-event .post-layout-2 article:nth-of-type(2n+2),
  body.page-event .post-layout-4 article:nth-of-type(2n+1),
  body.page-event .post-layout-5 article:first-of-type .box-details,
  body.page-event .iblock-2 article:nth-of-type(2n+2), body.page-tags .content-column2_1:nth-of-type(2n),
  body.page-tags .post-layout-2 article:nth-of-type(2n+2),
  body.page-tags .post-layout-4 article:nth-of-type(2n+1),
  body.page-tags .post-layout-5 article:first-of-type .box-details,
  body.page-tags .iblock-2 article:nth-of-type(2n+2), body.page-node-29 .content-column2_1:nth-of-type(2n),
  body.page-node-29 .post-layout-2 article:nth-of-type(2n+2),
  body.page-node-29 .post-layout-4 article:nth-of-type(2n+1),
  body.page-node-29 .post-layout-5 article:first-of-type .box-details,
  body.page-node-29 .iblock-2 article:nth-of-type(2n+2), body.page-node-34 .content-column2_1:nth-of-type(2n),
  body.page-node-34 .post-layout-2 article:nth-of-type(2n+2),
  body.page-node-34 .post-layout-4 article:nth-of-type(2n+1),
  body.page-node-34 .post-layout-5 article:first-of-type .box-details,
  body.page-node-34 .iblock-2 article:nth-of-type(2n+2), body.page-content .content-column2_1:nth-of-type(2n),
  body.page-content .post-layout-2 article:nth-of-type(2n+2),
  body.page-content .post-layout-4 article:nth-of-type(2n+1),
  body.page-content .post-layout-5 article:first-of-type .box-details,
  body.page-content .iblock-2 article:nth-of-type(2n+2) {
    padding-left: 15px;
  }
  body.page-news .col-in-col article:nth-of-type(2n+1), body.node-type-tep-blogs .col-in-col article:nth-of-type(2n+1), body.page-guides .col-in-col article:nth-of-type(2n+1), body.page-edgeproptv .col-in-col article:nth-of-type(2n+1), body.page-pullout .col-in-col article:nth-of-type(2n+1), body.page-events .col-in-col article:nth-of-type(2n+1), body.page-event .col-in-col article:nth-of-type(2n+1), body.page-tags .col-in-col article:nth-of-type(2n+1), body.page-node-29 .col-in-col article:nth-of-type(2n+1), body.page-node-34 .col-in-col article:nth-of-type(2n+1), body.page-content .col-in-col article:nth-of-type(2n+1) {
    padding-right: 11px;
  }
  body.page-news .col-in-col article:nth-of-type(2n), body.node-type-tep-blogs .col-in-col article:nth-of-type(2n), body.page-guides .col-in-col article:nth-of-type(2n), body.page-edgeproptv .col-in-col article:nth-of-type(2n), body.page-pullout .col-in-col article:nth-of-type(2n), body.page-events .col-in-col article:nth-of-type(2n), body.page-event .col-in-col article:nth-of-type(2n), body.page-tags .col-in-col article:nth-of-type(2n), body.page-node-29 .col-in-col article:nth-of-type(2n), body.page-node-34 .col-in-col article:nth-of-type(2n), body.page-content .col-in-col article:nth-of-type(2n) {
    padding-left: 11px;
  }
  body.page-news .depth-2, body.node-type-tep-blogs .depth-2, body.page-guides .depth-2, body.page-edgeproptv .depth-2, body.page-pullout .depth-2, body.page-events .depth-2, body.page-event .depth-2, body.page-tags .depth-2, body.page-node-29 .depth-2, body.page-node-34 .depth-2, body.page-content .depth-2 {
    margin-left: 48px;
  }
  body.page-news .depth-3, body.node-type-tep-blogs .depth-3, body.page-guides .depth-3, body.page-edgeproptv .depth-3, body.page-pullout .depth-3, body.page-events .depth-3, body.page-event .depth-3, body.page-tags .depth-3, body.page-node-29 .depth-3, body.page-node-34 .depth-3, body.page-content .depth-3 {
    margin-left: 96px;
  }
  body.page-news .depth-4, body.node-type-tep-blogs .depth-4, body.page-guides .depth-4, body.page-edgeproptv .depth-4, body.page-pullout .depth-4, body.page-events .depth-4, body.page-event .depth-4, body.page-tags .depth-4, body.page-node-29 .depth-4, body.page-node-34 .depth-4, body.page-content .depth-4 {
    margin-left: 144px;
  }
  body.page-news .depth-5, body.node-type-tep-blogs .depth-5, body.page-guides .depth-5, body.page-edgeproptv .depth-5, body.page-pullout .depth-5, body.page-events .depth-5, body.page-event .depth-5, body.page-tags .depth-5, body.page-node-29 .depth-5, body.page-node-34 .depth-5, body.page-content .depth-5 {
    margin-left: 192px;
  }
}

@media screen and (max-width: 1025px) {
  body.page-news .wpsg-image-cover .top-post-title, body.node-type-tep-blogs .wpsg-image-cover .top-post-title, body.page-guides .wpsg-image-cover .top-post-title, body.page-edgeproptv .wpsg-image-cover .top-post-title, body.page-pullout .wpsg-image-cover .top-post-title, body.page-events .wpsg-image-cover .top-post-title, body.page-event .wpsg-image-cover .top-post-title, body.page-tags .wpsg-image-cover .top-post-title, body.page-node-29 .wpsg-image-cover .top-post-title, body.page-node-34 .wpsg-image-cover .top-post-title, body.page-content .wpsg-image-cover .top-post-title {
    font-size: 16px;
    line-height: 18px;
  }
  body.page-news .top-post-title, body.node-type-tep-blogs .top-post-title, body.page-guides .top-post-title, body.page-edgeproptv .top-post-title, body.page-pullout .top-post-title, body.page-events .top-post-title, body.page-event .top-post-title, body.page-tags .top-post-title, body.page-node-29 .top-post-title, body.page-node-34 .top-post-title, body.page-content .top-post-title {
    font-size: 14px;
    line-height: 16px;
  }
}

@media screen and (max-width: 1000px) {
  body.page-news #scroll-header .header-scroll-logo, body.node-type-tep-blogs #scroll-header .header-scroll-logo, body.page-guides #scroll-header .header-scroll-logo, body.page-edgeproptv #scroll-header .header-scroll-logo, body.page-pullout #scroll-header .header-scroll-logo, body.page-events #scroll-header .header-scroll-logo, body.page-event #scroll-header .header-scroll-logo, body.page-tags #scroll-header .header-scroll-logo, body.page-node-29 #scroll-header .header-scroll-logo, body.page-node-34 #scroll-header .header-scroll-logo, body.page-content #scroll-header .header-scroll-logo {
    width: 15%;
  }
  body.page-news #scroll-header .scroll-header-menu, body.node-type-tep-blogs #scroll-header .scroll-header-menu, body.page-guides #scroll-header .scroll-header-menu, body.page-edgeproptv #scroll-header .scroll-header-menu, body.page-pullout #scroll-header .scroll-header-menu, body.page-events #scroll-header .scroll-header-menu, body.page-event #scroll-header .scroll-header-menu, body.page-tags #scroll-header .scroll-header-menu, body.page-node-29 #scroll-header .scroll-header-menu, body.page-node-34 #scroll-header .scroll-header-menu, body.page-content #scroll-header .scroll-header-menu {
    width: 85%;
  }
  body.page-news #scroll-header .header-search-mazada i, body.node-type-tep-blogs #scroll-header .header-search-mazada i, body.page-guides #scroll-header .header-search-mazada i, body.page-edgeproptv #scroll-header .header-search-mazada i, body.page-pullout #scroll-header .header-search-mazada i, body.page-events #scroll-header .header-search-mazada i, body.page-event #scroll-header .header-search-mazada i, body.page-tags #scroll-header .header-search-mazada i, body.page-node-29 #scroll-header .header-search-mazada i, body.page-node-34 #scroll-header .header-search-mazada i, body.page-content #scroll-header .header-search-mazada i {
    padding: 19px 0 19px 12px;
  }
  body.page-news #scroll-header .header-menu-wrap > div > ul > li > a, body.node-type-tep-blogs #scroll-header .header-menu-wrap > div > ul > li > a, body.page-guides #scroll-header .header-menu-wrap > div > ul > li > a, body.page-edgeproptv #scroll-header .header-menu-wrap > div > ul > li > a, body.page-pullout #scroll-header .header-menu-wrap > div > ul > li > a, body.page-events #scroll-header .header-menu-wrap > div > ul > li > a, body.page-event #scroll-header .header-menu-wrap > div > ul > li > a, body.page-tags #scroll-header .header-menu-wrap > div > ul > li > a, body.page-node-29 #scroll-header .header-menu-wrap > div > ul > li > a, body.page-node-34 #scroll-header .header-menu-wrap > div > ul > li > a, body.page-content #scroll-header .header-menu-wrap > div > ul > li > a {
    padding: 21px 10px 21px 0;
    font-size: 13px;
  }
  body.page-news .wrap, body.node-type-tep-blogs .wrap, body.page-guides .wrap, body.page-edgeproptv .wrap, body.page-pullout .wrap, body.page-events .wrap, body.page-event .wrap, body.page-tags .wrap, body.page-node-29 .wrap, body.page-node-34 .wrap, body.page-content .wrap {
    width: 700px;
  }
  body.page-news .wrap-colum, body.node-type-tep-blogs .wrap-colum, body.page-guides .wrap-colum, body.page-edgeproptv .wrap-colum, body.page-pullout .wrap-colum, body.page-events .wrap-colum, body.page-event .wrap-colum, body.page-tags .wrap-colum, body.page-node-29 .wrap-colum, body.page-node-34 .wrap-colum, body.page-content .wrap-colum {
    width: 740px;
  }
  body.page-news .top-post-cat, body.node-type-tep-blogs .top-post-cat, body.page-guides .top-post-cat, body.page-edgeproptv .top-post-cat, body.page-pullout .top-post-cat, body.page-events .top-post-cat, body.page-event .top-post-cat, body.page-tags .top-post-cat, body.page-node-29 .top-post-cat, body.page-node-34 .top-post-cat, body.page-content .top-post-cat {
    position: relative;
    font-family: 'Roboto', Arial !important;
    font-size: 12px;
    font-weight: bold;
    line-height: 16px;
    color: #fff;
    padding: 5px 20px;
    text-transform: uppercase;
    background-color: #000;
    -webkit-border-radius: 21px;
    -moz-border-radius: 21px;
    border-radius: 21px;
    margin-bottom: 14px;
  }
  body.page-news .header-menu ul > li > a, body.node-type-tep-blogs .header-menu ul > li > a, body.page-guides .header-menu ul > li > a, body.page-edgeproptv .header-menu ul > li > a, body.page-pullout .header-menu ul > li > a, body.page-events .header-menu ul > li > a, body.page-event .header-menu ul > li > a, body.page-tags .header-menu ul > li > a, body.page-node-29 .header-menu ul > li > a, body.page-node-34 .header-menu ul > li > a, body.page-content .header-menu ul > li > a {
    padding: 14px 18px 14px 0;
  }
}

@media screen and (max-width: 800px) {
  body.page-news, body.node-type-tep-blogs, body.page-guides, body.page-edgeproptv, body.page-pullout, body.page-events, body.page-event, body.page-tags, body.page-node-29, body.page-node-34, body.page-content {
    /* show toggle menu */
  }
  body.page-news .wrap, body.node-type-tep-blogs .wrap, body.page-guides .wrap, body.page-edgeproptv .wrap, body.page-pullout .wrap, body.page-events .wrap, body.page-event .wrap, body.page-tags .wrap, body.page-node-29 .wrap, body.page-node-34 .wrap, body.page-content .wrap {
    width: 640px;
  }
  body.page-news .wrap-colum, body.node-type-tep-blogs .wrap-colum, body.page-guides .wrap-colum, body.page-edgeproptv .wrap-colum, body.page-pullout .wrap-colum, body.page-events .wrap-colum, body.page-event .wrap-colum, body.page-tags .wrap-colum, body.page-node-29 .wrap-colum, body.page-node-34 .wrap-colum, body.page-content .wrap-colum {
    width: 680px;
  }
  body.page-news .col-3-1,
  body.page-news .col-4-1, body.node-type-tep-blogs .col-3-1,
  body.node-type-tep-blogs .col-4-1, body.page-guides .col-3-1,
  body.page-guides .col-4-1, body.page-edgeproptv .col-3-1,
  body.page-edgeproptv .col-4-1, body.page-pullout .col-3-1,
  body.page-pullout .col-4-1, body.page-events .col-3-1,
  body.page-events .col-4-1, body.page-event .col-3-1,
  body.page-event .col-4-1, body.page-tags .col-3-1,
  body.page-tags .col-4-1, body.page-node-29 .col-3-1,
  body.page-node-29 .col-4-1, body.page-node-34 .col-3-1,
  body.page-node-34 .col-4-1, body.page-content .col-3-1,
  body.page-content .col-4-1 {
    float: none;
    width: 100%;
  }
  body.page-news #top-bar,
  body.page-news #scroll-header,
  body.page-news header .header-menu,
  body.page-news header .header-banner, body.node-type-tep-blogs #top-bar,
  body.node-type-tep-blogs #scroll-header,
  body.node-type-tep-blogs header .header-menu,
  body.node-type-tep-blogs header .header-banner, body.page-guides #top-bar,
  body.page-guides #scroll-header,
  body.page-guides header .header-menu,
  body.page-guides header .header-banner, body.page-edgeproptv #top-bar,
  body.page-edgeproptv #scroll-header,
  body.page-edgeproptv header .header-menu,
  body.page-edgeproptv header .header-banner, body.page-pullout #top-bar,
  body.page-pullout #scroll-header,
  body.page-pullout header .header-menu,
  body.page-pullout header .header-banner, body.page-events #top-bar,
  body.page-events #scroll-header,
  body.page-events header .header-menu,
  body.page-events header .header-banner, body.page-event #top-bar,
  body.page-event #scroll-header,
  body.page-event header .header-menu,
  body.page-event header .header-banner, body.page-tags #top-bar,
  body.page-tags #scroll-header,
  body.page-tags header .header-menu,
  body.page-tags header .header-banner, body.page-node-29 #top-bar,
  body.page-node-29 #scroll-header,
  body.page-node-29 header .header-menu,
  body.page-node-29 header .header-banner, body.page-node-34 #top-bar,
  body.page-node-34 #scroll-header,
  body.page-node-34 header .header-menu,
  body.page-node-34 header .header-banner, body.page-content #top-bar,
  body.page-content #scroll-header,
  body.page-content header .header-menu,
  body.page-content header .header-banner {
    display: none;
  }
  body.page-news header .header-logo, body.node-type-tep-blogs header .header-logo, body.page-guides header .header-logo, body.page-edgeproptv header .header-logo, body.page-pullout header .header-logo, body.page-events header .header-logo, body.page-event header .header-logo, body.page-tags header .header-logo, body.page-node-29 header .header-logo, body.page-node-34 header .header-logo, body.page-content header .header-logo {
    width: 70%;
    padding: 10px 0 10px 0;
    vertical-align: middle;
  }
  body.page-news header .header-mobile-right, body.node-type-tep-blogs header .header-mobile-right, body.page-guides header .header-mobile-right, body.page-edgeproptv header .header-mobile-right, body.page-pullout header .header-mobile-right, body.page-events header .header-mobile-right, body.page-event header .header-mobile-right, body.page-tags header .header-mobile-right, body.page-node-29 header .header-mobile-right, body.page-node-34 header .header-mobile-right, body.page-content header .header-mobile-right {
    width: 30%;
    vertical-align: middle;
  }
  body.page-news header .header-mobile-right i, body.node-type-tep-blogs header .header-mobile-right i, body.page-guides header .header-mobile-right i, body.page-edgeproptv header .header-mobile-right i, body.page-pullout header .header-mobile-right i, body.page-events header .header-mobile-right i, body.page-event header .header-mobile-right i, body.page-tags header .header-mobile-right i, body.page-node-29 header .header-mobile-right i, body.page-node-34 header .header-mobile-right i, body.page-content header .header-mobile-right i {
    text-align: right;
    padding: 0;
  }
  body.page-news .toggle-menu-button,
  body.page-news .colum-iblock .mobile-header-search, body.node-type-tep-blogs .toggle-menu-button,
  body.node-type-tep-blogs .colum-iblock .mobile-header-search, body.page-guides .toggle-menu-button,
  body.page-guides .colum-iblock .mobile-header-search, body.page-edgeproptv .toggle-menu-button,
  body.page-edgeproptv .colum-iblock .mobile-header-search, body.page-pullout .toggle-menu-button,
  body.page-pullout .colum-iblock .mobile-header-search, body.page-events .toggle-menu-button,
  body.page-events .colum-iblock .mobile-header-search, body.page-event .toggle-menu-button,
  body.page-event .colum-iblock .mobile-header-search, body.page-tags .toggle-menu-button,
  body.page-tags .colum-iblock .mobile-header-search, body.page-node-29 .toggle-menu-button,
  body.page-node-29 .colum-iblock .mobile-header-search, body.page-node-34 .toggle-menu-button,
  body.page-node-34 .colum-iblock .mobile-header-search, body.page-content .toggle-menu-button,
  body.page-content .colum-iblock .mobile-header-search {
    display: block;
  }
  body.page-news header .wrap, body.node-type-tep-blogs header .wrap, body.page-guides header .wrap, body.page-edgeproptv header .wrap, body.page-pullout header .wrap, body.page-events header .wrap, body.page-event header .wrap, body.page-tags header .wrap, body.page-node-29 header .wrap, body.page-node-34 header .wrap, body.page-content header .wrap {
    border-bottom: 1px solid #1a1a1a;
    margin-bottom: 25px;
  }
  body.page-news #header .main-menu, body.node-type-tep-blogs #header .main-menu, body.page-guides #header .main-menu, body.page-edgeproptv #header .main-menu, body.page-pullout #header .main-menu, body.page-events #header .main-menu, body.page-event #header .main-menu, body.page-tags #header .main-menu, body.page-node-29 #header .main-menu, body.page-node-34 #header .main-menu, body.page-content #header .main-menu {
    display: none;
  }
  body.page-news .container-background, body.node-type-tep-blogs .container-background, body.page-guides .container-background, body.page-edgeproptv .container-background, body.page-pullout .container-background, body.page-events .container-background, body.page-event .container-background, body.page-tags .container-background, body.page-node-29 .container-background, body.page-node-34 .container-background, body.page-content .container-background {
    margin-top: 0;
  }
  body.page-news .top-post-title, body.node-type-tep-blogs .top-post-title, body.page-guides .top-post-title, body.page-edgeproptv .top-post-title, body.page-pullout .top-post-title, body.page-events .top-post-title, body.page-event .top-post-title, body.page-tags .top-post-title, body.page-node-29 .top-post-title, body.page-node-34 .top-post-title, body.page-content .top-post-title {
    font-size: 12px;
    line-height: 14px;
  }
  body.page-news .wpsg-image-cover .top-post-title, body.node-type-tep-blogs .wpsg-image-cover .top-post-title, body.page-guides .wpsg-image-cover .top-post-title, body.page-edgeproptv .wpsg-image-cover .top-post-title, body.page-pullout .wpsg-image-cover .top-post-title, body.page-events .wpsg-image-cover .top-post-title, body.page-event .wpsg-image-cover .top-post-title, body.page-tags .wpsg-image-cover .top-post-title, body.page-node-29 .wpsg-image-cover .top-post-title, body.page-node-34 .wpsg-image-cover .top-post-title, body.page-content .wpsg-image-cover .top-post-title {
    font-size: 18px;
    line-height: 24px;
  }
  body.page-news .top-post-cat, body.node-type-tep-blogs .top-post-cat, body.page-guides .top-post-cat, body.page-edgeproptv .top-post-cat, body.page-pullout .top-post-cat, body.page-events .top-post-cat, body.page-event .top-post-cat, body.page-tags .top-post-cat, body.page-node-29 .top-post-cat, body.page-node-34 .top-post-cat, body.page-content .top-post-cat {
    display: none;
  }
}

@media screen and (max-width: 720px) {
  body.page-news, body.node-type-tep-blogs, body.page-guides, body.page-edgeproptv, body.page-pullout, body.page-events, body.page-event, body.page-tags, body.page-node-29, body.page-node-34, body.page-content {
    /*CONTAINER ELEMENT*/
    /*COMMENT FORM*/
    /* blog */
  }
  body.page-news .wrap, body.node-type-tep-blogs .wrap, body.page-guides .wrap, body.page-edgeproptv .wrap, body.page-pullout .wrap, body.page-events .wrap, body.page-event .wrap, body.page-tags .wrap, body.page-node-29 .wrap, body.page-node-34 .wrap, body.page-content .wrap {
    width: 420px;
  }
  body.page-news .wrap-colum, body.node-type-tep-blogs .wrap-colum, body.page-guides .wrap-colum, body.page-edgeproptv .wrap-colum, body.page-pullout .wrap-colum, body.page-events .wrap-colum, body.page-event .wrap-colum, body.page-tags .wrap-colum, body.page-node-29 .wrap-colum, body.page-node-34 .wrap-colum, body.page-content .wrap-colum {
    width: 460px;
  }
  body.page-news .post-layout-2 article:nth-of-type(2n+1),
  body.page-news .post-layout-2 article:nth-of-type(2n+2),
  body.page-news .post-layout-4 article:nth-of-type(2n+2),
  body.page-news .post-layout-4 article:nth-of-type(2n+1),
  body.page-news .post-layout-4 article:first-of-type .box-thumb,
  body.page-news .post-layout-5 article,
  body.page-news .iblock-2 article,
  body.page-news .content-column2_1, body.node-type-tep-blogs .post-layout-2 article:nth-of-type(2n+1),
  body.node-type-tep-blogs .post-layout-2 article:nth-of-type(2n+2),
  body.node-type-tep-blogs .post-layout-4 article:nth-of-type(2n+2),
  body.node-type-tep-blogs .post-layout-4 article:nth-of-type(2n+1),
  body.node-type-tep-blogs .post-layout-4 article:first-of-type .box-thumb,
  body.node-type-tep-blogs .post-layout-5 article,
  body.node-type-tep-blogs .iblock-2 article,
  body.node-type-tep-blogs .content-column2_1, body.page-guides .post-layout-2 article:nth-of-type(2n+1),
  body.page-guides .post-layout-2 article:nth-of-type(2n+2),
  body.page-guides .post-layout-4 article:nth-of-type(2n+2),
  body.page-guides .post-layout-4 article:nth-of-type(2n+1),
  body.page-guides .post-layout-4 article:first-of-type .box-thumb,
  body.page-guides .post-layout-5 article,
  body.page-guides .iblock-2 article,
  body.page-guides .content-column2_1, body.page-edgeproptv .post-layout-2 article:nth-of-type(2n+1),
  body.page-edgeproptv .post-layout-2 article:nth-of-type(2n+2),
  body.page-edgeproptv .post-layout-4 article:nth-of-type(2n+2),
  body.page-edgeproptv .post-layout-4 article:nth-of-type(2n+1),
  body.page-edgeproptv .post-layout-4 article:first-of-type .box-thumb,
  body.page-edgeproptv .post-layout-5 article,
  body.page-edgeproptv .iblock-2 article,
  body.page-edgeproptv .content-column2_1, body.page-pullout .post-layout-2 article:nth-of-type(2n+1),
  body.page-pullout .post-layout-2 article:nth-of-type(2n+2),
  body.page-pullout .post-layout-4 article:nth-of-type(2n+2),
  body.page-pullout .post-layout-4 article:nth-of-type(2n+1),
  body.page-pullout .post-layout-4 article:first-of-type .box-thumb,
  body.page-pullout .post-layout-5 article,
  body.page-pullout .iblock-2 article,
  body.page-pullout .content-column2_1, body.page-events .post-layout-2 article:nth-of-type(2n+1),
  body.page-events .post-layout-2 article:nth-of-type(2n+2),
  body.page-events .post-layout-4 article:nth-of-type(2n+2),
  body.page-events .post-layout-4 article:nth-of-type(2n+1),
  body.page-events .post-layout-4 article:first-of-type .box-thumb,
  body.page-events .post-layout-5 article,
  body.page-events .iblock-2 article,
  body.page-events .content-column2_1, body.page-event .post-layout-2 article:nth-of-type(2n+1),
  body.page-event .post-layout-2 article:nth-of-type(2n+2),
  body.page-event .post-layout-4 article:nth-of-type(2n+2),
  body.page-event .post-layout-4 article:nth-of-type(2n+1),
  body.page-event .post-layout-4 article:first-of-type .box-thumb,
  body.page-event .post-layout-5 article,
  body.page-event .iblock-2 article,
  body.page-event .content-column2_1, body.page-tags .post-layout-2 article:nth-of-type(2n+1),
  body.page-tags .post-layout-2 article:nth-of-type(2n+2),
  body.page-tags .post-layout-4 article:nth-of-type(2n+2),
  body.page-tags .post-layout-4 article:nth-of-type(2n+1),
  body.page-tags .post-layout-4 article:first-of-type .box-thumb,
  body.page-tags .post-layout-5 article,
  body.page-tags .iblock-2 article,
  body.page-tags .content-column2_1, body.page-node-29 .post-layout-2 article:nth-of-type(2n+1),
  body.page-node-29 .post-layout-2 article:nth-of-type(2n+2),
  body.page-node-29 .post-layout-4 article:nth-of-type(2n+2),
  body.page-node-29 .post-layout-4 article:nth-of-type(2n+1),
  body.page-node-29 .post-layout-4 article:first-of-type .box-thumb,
  body.page-node-29 .post-layout-5 article,
  body.page-node-29 .iblock-2 article,
  body.page-node-29 .content-column2_1, body.page-node-34 .post-layout-2 article:nth-of-type(2n+1),
  body.page-node-34 .post-layout-2 article:nth-of-type(2n+2),
  body.page-node-34 .post-layout-4 article:nth-of-type(2n+2),
  body.page-node-34 .post-layout-4 article:nth-of-type(2n+1),
  body.page-node-34 .post-layout-4 article:first-of-type .box-thumb,
  body.page-node-34 .post-layout-5 article,
  body.page-node-34 .iblock-2 article,
  body.page-node-34 .content-column2_1, body.page-content .post-layout-2 article:nth-of-type(2n+1),
  body.page-content .post-layout-2 article:nth-of-type(2n+2),
  body.page-content .post-layout-4 article:nth-of-type(2n+2),
  body.page-content .post-layout-4 article:nth-of-type(2n+1),
  body.page-content .post-layout-4 article:first-of-type .box-thumb,
  body.page-content .post-layout-5 article,
  body.page-content .iblock-2 article,
  body.page-content .content-column2_1 {
    width: 100%;
    float: none;
  }
  body.page-news .content-column2_1:nth-of-type(2n+1),
  body.page-news .post-layout-2 article:nth-of-type(2n+1),
  body.page-news .post-layout-4 article:nth-of-type(2n+2),
  body.page-news .iblock-2 article:nth-of-type(2n+1), body.node-type-tep-blogs .content-column2_1:nth-of-type(2n+1),
  body.node-type-tep-blogs .post-layout-2 article:nth-of-type(2n+1),
  body.node-type-tep-blogs .post-layout-4 article:nth-of-type(2n+2),
  body.node-type-tep-blogs .iblock-2 article:nth-of-type(2n+1), body.page-guides .content-column2_1:nth-of-type(2n+1),
  body.page-guides .post-layout-2 article:nth-of-type(2n+1),
  body.page-guides .post-layout-4 article:nth-of-type(2n+2),
  body.page-guides .iblock-2 article:nth-of-type(2n+1), body.page-edgeproptv .content-column2_1:nth-of-type(2n+1),
  body.page-edgeproptv .post-layout-2 article:nth-of-type(2n+1),
  body.page-edgeproptv .post-layout-4 article:nth-of-type(2n+2),
  body.page-edgeproptv .iblock-2 article:nth-of-type(2n+1), body.page-pullout .content-column2_1:nth-of-type(2n+1),
  body.page-pullout .post-layout-2 article:nth-of-type(2n+1),
  body.page-pullout .post-layout-4 article:nth-of-type(2n+2),
  body.page-pullout .iblock-2 article:nth-of-type(2n+1), body.page-events .content-column2_1:nth-of-type(2n+1),
  body.page-events .post-layout-2 article:nth-of-type(2n+1),
  body.page-events .post-layout-4 article:nth-of-type(2n+2),
  body.page-events .iblock-2 article:nth-of-type(2n+1), body.page-event .content-column2_1:nth-of-type(2n+1),
  body.page-event .post-layout-2 article:nth-of-type(2n+1),
  body.page-event .post-layout-4 article:nth-of-type(2n+2),
  body.page-event .iblock-2 article:nth-of-type(2n+1), body.page-tags .content-column2_1:nth-of-type(2n+1),
  body.page-tags .post-layout-2 article:nth-of-type(2n+1),
  body.page-tags .post-layout-4 article:nth-of-type(2n+2),
  body.page-tags .iblock-2 article:nth-of-type(2n+1), body.page-node-29 .content-column2_1:nth-of-type(2n+1),
  body.page-node-29 .post-layout-2 article:nth-of-type(2n+1),
  body.page-node-29 .post-layout-4 article:nth-of-type(2n+2),
  body.page-node-29 .iblock-2 article:nth-of-type(2n+1), body.page-node-34 .content-column2_1:nth-of-type(2n+1),
  body.page-node-34 .post-layout-2 article:nth-of-type(2n+1),
  body.page-node-34 .post-layout-4 article:nth-of-type(2n+2),
  body.page-node-34 .iblock-2 article:nth-of-type(2n+1), body.page-content .content-column2_1:nth-of-type(2n+1),
  body.page-content .post-layout-2 article:nth-of-type(2n+1),
  body.page-content .post-layout-4 article:nth-of-type(2n+2),
  body.page-content .iblock-2 article:nth-of-type(2n+1) {
    padding-right: 0;
  }
  body.page-news .content-column2_1:nth-of-type(2n),
  body.page-news .post-layout-2 article:nth-of-type(2n+2),
  body.page-news .post-layout-4 article:nth-of-type(2n+1),
  body.page-news .post-layout-5 article,
  body.page-news .iblock-2 article:nth-of-type(2n+2), body.node-type-tep-blogs .content-column2_1:nth-of-type(2n),
  body.node-type-tep-blogs .post-layout-2 article:nth-of-type(2n+2),
  body.node-type-tep-blogs .post-layout-4 article:nth-of-type(2n+1),
  body.node-type-tep-blogs .post-layout-5 article,
  body.node-type-tep-blogs .iblock-2 article:nth-of-type(2n+2), body.page-guides .content-column2_1:nth-of-type(2n),
  body.page-guides .post-layout-2 article:nth-of-type(2n+2),
  body.page-guides .post-layout-4 article:nth-of-type(2n+1),
  body.page-guides .post-layout-5 article,
  body.page-guides .iblock-2 article:nth-of-type(2n+2), body.page-edgeproptv .content-column2_1:nth-of-type(2n),
  body.page-edgeproptv .post-layout-2 article:nth-of-type(2n+2),
  body.page-edgeproptv .post-layout-4 article:nth-of-type(2n+1),
  body.page-edgeproptv .post-layout-5 article,
  body.page-edgeproptv .iblock-2 article:nth-of-type(2n+2), body.page-pullout .content-column2_1:nth-of-type(2n),
  body.page-pullout .post-layout-2 article:nth-of-type(2n+2),
  body.page-pullout .post-layout-4 article:nth-of-type(2n+1),
  body.page-pullout .post-layout-5 article,
  body.page-pullout .iblock-2 article:nth-of-type(2n+2), body.page-events .content-column2_1:nth-of-type(2n),
  body.page-events .post-layout-2 article:nth-of-type(2n+2),
  body.page-events .post-layout-4 article:nth-of-type(2n+1),
  body.page-events .post-layout-5 article,
  body.page-events .iblock-2 article:nth-of-type(2n+2), body.page-event .content-column2_1:nth-of-type(2n),
  body.page-event .post-layout-2 article:nth-of-type(2n+2),
  body.page-event .post-layout-4 article:nth-of-type(2n+1),
  body.page-event .post-layout-5 article,
  body.page-event .iblock-2 article:nth-of-type(2n+2), body.page-tags .content-column2_1:nth-of-type(2n),
  body.page-tags .post-layout-2 article:nth-of-type(2n+2),
  body.page-tags .post-layout-4 article:nth-of-type(2n+1),
  body.page-tags .post-layout-5 article,
  body.page-tags .iblock-2 article:nth-of-type(2n+2), body.page-node-29 .content-column2_1:nth-of-type(2n),
  body.page-node-29 .post-layout-2 article:nth-of-type(2n+2),
  body.page-node-29 .post-layout-4 article:nth-of-type(2n+1),
  body.page-node-29 .post-layout-5 article,
  body.page-node-29 .iblock-2 article:nth-of-type(2n+2), body.page-node-34 .content-column2_1:nth-of-type(2n),
  body.page-node-34 .post-layout-2 article:nth-of-type(2n+2),
  body.page-node-34 .post-layout-4 article:nth-of-type(2n+1),
  body.page-node-34 .post-layout-5 article,
  body.page-node-34 .iblock-2 article:nth-of-type(2n+2), body.page-content .content-column2_1:nth-of-type(2n),
  body.page-content .post-layout-2 article:nth-of-type(2n+2),
  body.page-content .post-layout-4 article:nth-of-type(2n+1),
  body.page-content .post-layout-5 article,
  body.page-content .iblock-2 article:nth-of-type(2n+2) {
    padding-left: 0;
  }
  body.page-news .post-layout-4 article:first-of-type .box-details, body.node-type-tep-blogs .post-layout-4 article:first-of-type .box-details, body.page-guides .post-layout-4 article:first-of-type .box-details, body.page-edgeproptv .post-layout-4 article:first-of-type .box-details, body.page-pullout .post-layout-4 article:first-of-type .box-details, body.page-events .post-layout-4 article:first-of-type .box-details, body.page-event .post-layout-4 article:first-of-type .box-details, body.page-tags .post-layout-4 article:first-of-type .box-details, body.page-node-29 .post-layout-4 article:first-of-type .box-details, body.page-node-34 .post-layout-4 article:first-of-type .box-details, body.page-content .post-layout-4 article:first-of-type .box-details {
    margin-left: 0;
  }
  body.page-news .comment-form-author,
  body.page-news .comment-form-email,
  body.page-news .form-submit,
  body.page-news .related-article, body.node-type-tep-blogs .comment-form-author,
  body.node-type-tep-blogs .comment-form-email,
  body.node-type-tep-blogs .form-submit,
  body.node-type-tep-blogs .related-article, body.page-guides .comment-form-author,
  body.page-guides .comment-form-email,
  body.page-guides .form-submit,
  body.page-guides .related-article, body.page-edgeproptv .comment-form-author,
  body.page-edgeproptv .comment-form-email,
  body.page-edgeproptv .form-submit,
  body.page-edgeproptv .related-article, body.page-pullout .comment-form-author,
  body.page-pullout .comment-form-email,
  body.page-pullout .form-submit,
  body.page-pullout .related-article, body.page-events .comment-form-author,
  body.page-events .comment-form-email,
  body.page-events .form-submit,
  body.page-events .related-article, body.page-event .comment-form-author,
  body.page-event .comment-form-email,
  body.page-event .form-submit,
  body.page-event .related-article, body.page-tags .comment-form-author,
  body.page-tags .comment-form-email,
  body.page-tags .form-submit,
  body.page-tags .related-article, body.page-node-29 .comment-form-author,
  body.page-node-29 .comment-form-email,
  body.page-node-29 .form-submit,
  body.page-node-29 .related-article, body.page-node-34 .comment-form-author,
  body.page-node-34 .comment-form-email,
  body.page-node-34 .form-submit,
  body.page-node-34 .related-article, body.page-content .comment-form-author,
  body.page-content .comment-form-email,
  body.page-content .form-submit,
  body.page-content .related-article {
    float: none;
    width: 100%;
    margin-right: 0;
  }
  body.page-news #cancel-comment-reply-link, body.node-type-tep-blogs #cancel-comment-reply-link, body.page-guides #cancel-comment-reply-link, body.page-edgeproptv #cancel-comment-reply-link, body.page-pullout #cancel-comment-reply-link, body.page-events #cancel-comment-reply-link, body.page-event #cancel-comment-reply-link, body.page-tags #cancel-comment-reply-link, body.page-node-29 #cancel-comment-reply-link, body.page-node-34 #cancel-comment-reply-link, body.page-content #cancel-comment-reply-link {
    position: initial;
    margin-top: 8px;
  }
  body.page-news .depth-2, body.node-type-tep-blogs .depth-2, body.page-guides .depth-2, body.page-edgeproptv .depth-2, body.page-pullout .depth-2, body.page-events .depth-2, body.page-event .depth-2, body.page-tags .depth-2, body.page-node-29 .depth-2, body.page-node-34 .depth-2, body.page-content .depth-2 {
    margin-left: 10px;
  }
  body.page-news .depth-3, body.node-type-tep-blogs .depth-3, body.page-guides .depth-3, body.page-edgeproptv .depth-3, body.page-pullout .depth-3, body.page-events .depth-3, body.page-event .depth-3, body.page-tags .depth-3, body.page-node-29 .depth-3, body.page-node-34 .depth-3, body.page-content .depth-3 {
    margin-left: 20px;
  }
  body.page-news .depth-4, body.node-type-tep-blogs .depth-4, body.page-guides .depth-4, body.page-edgeproptv .depth-4, body.page-pullout .depth-4, body.page-events .depth-4, body.page-event .depth-4, body.page-tags .depth-4, body.page-node-29 .depth-4, body.page-node-34 .depth-4, body.page-content .depth-4 {
    margin-left: 30px;
  }
  body.page-news .depth-5, body.node-type-tep-blogs .depth-5, body.page-guides .depth-5, body.page-edgeproptv .depth-5, body.page-pullout .depth-5, body.page-events .depth-5, body.page-event .depth-5, body.page-tags .depth-5, body.page-node-29 .depth-5, body.page-node-34 .depth-5, body.page-content .depth-5 {
    margin-left: 40px;
  }
  body.page-news .list-categories, body.node-type-tep-blogs .list-categories, body.page-guides .list-categories, body.page-edgeproptv .list-categories, body.page-pullout .list-categories, body.page-events .list-categories, body.page-event .list-categories, body.page-tags .list-categories, body.page-node-29 .list-categories, body.page-node-34 .list-categories, body.page-content .list-categories {
    text-align: left;
  }
  body.page-news .list-categories a:first-of-type, body.node-type-tep-blogs .list-categories a:first-of-type, body.page-guides .list-categories a:first-of-type, body.page-edgeproptv .list-categories a:first-of-type, body.page-pullout .list-categories a:first-of-type, body.page-events .list-categories a:first-of-type, body.page-event .list-categories a:first-of-type, body.page-tags .list-categories a:first-of-type, body.page-node-29 .list-categories a:first-of-type, body.page-node-34 .list-categories a:first-of-type, body.page-content .list-categories a:first-of-type {
    width: 100%;
    margin-bottom: 8px;
  }
  body.page-news .list-categories a, body.node-type-tep-blogs .list-categories a, body.page-guides .list-categories a, body.page-edgeproptv .list-categories a, body.page-pullout .list-categories a, body.page-events .list-categories a, body.page-event .list-categories a, body.page-tags .list-categories a, body.page-node-29 .list-categories a, body.page-node-34 .list-categories a, body.page-content .list-categories a {
    margin-left: 0;
    margin-right: 18px;
  }
  body.page-news .top-post-title, body.node-type-tep-blogs .top-post-title, body.page-guides .top-post-title, body.page-edgeproptv .top-post-title, body.page-pullout .top-post-title, body.page-events .top-post-title, body.page-event .top-post-title, body.page-tags .top-post-title, body.page-node-29 .top-post-title, body.page-node-34 .top-post-title, body.page-content .top-post-title {
    font-size: 12px;
    line-height: 14px;
  }
  body.page-news .wpsg-image-cover .top-post-title, body.node-type-tep-blogs .wpsg-image-cover .top-post-title, body.page-guides .wpsg-image-cover .top-post-title, body.page-edgeproptv .wpsg-image-cover .top-post-title, body.page-pullout .wpsg-image-cover .top-post-title, body.page-events .wpsg-image-cover .top-post-title, body.page-event .wpsg-image-cover .top-post-title, body.page-tags .wpsg-image-cover .top-post-title, body.page-node-29 .wpsg-image-cover .top-post-title, body.page-node-34 .wpsg-image-cover .top-post-title, body.page-content .wpsg-image-cover .top-post-title {
    font-size: 16px;
    line-height: 18px;
  }
  body.page-news blockquote.news-quote, body.node-type-tep-blogs blockquote.news-quote, body.page-guides blockquote.news-quote, body.page-edgeproptv blockquote.news-quote, body.page-pullout blockquote.news-quote, body.page-events blockquote.news-quote, body.page-event blockquote.news-quote, body.page-tags blockquote.news-quote, body.page-node-29 blockquote.news-quote, body.page-node-34 blockquote.news-quote, body.page-content blockquote.news-quote {
    margin: 30px 30px;
    padding: 32px 35px;
  }
}

@media screen and (max-width: 768px) {
  body.page-news .wrap, body.node-type-tep-blogs .wrap, body.page-guides .wrap, body.page-edgeproptv .wrap, body.page-pullout .wrap, body.page-events .wrap, body.page-event .wrap, body.page-tags .wrap, body.page-node-29 .wrap, body.page-node-34 .wrap, body.page-content .wrap {
    width: 100%;
    padding: 0 20px;
  }
  body.page-news .wrap-colum, body.node-type-tep-blogs .wrap-colum, body.page-guides .wrap-colum, body.page-edgeproptv .wrap-colum, body.page-pullout .wrap-colum, body.page-events .wrap-colum, body.page-event .wrap-colum, body.page-tags .wrap-colum, body.page-node-29 .wrap-colum, body.page-node-34 .wrap-colum, body.page-content .wrap-colum {
    width: 100%;
    padding: 0 10px;
  }
  body.page-news .wrap-colum .col-3-1,
  body.page-news .wrap-colum .col-4-1, body.node-type-tep-blogs .wrap-colum .col-3-1,
  body.node-type-tep-blogs .wrap-colum .col-4-1, body.page-guides .wrap-colum .col-3-1,
  body.page-guides .wrap-colum .col-4-1, body.page-edgeproptv .wrap-colum .col-3-1,
  body.page-edgeproptv .wrap-colum .col-4-1, body.page-pullout .wrap-colum .col-3-1,
  body.page-pullout .wrap-colum .col-4-1, body.page-events .wrap-colum .col-3-1,
  body.page-events .wrap-colum .col-4-1, body.page-event .wrap-colum .col-3-1,
  body.page-event .wrap-colum .col-4-1, body.page-tags .wrap-colum .col-3-1,
  body.page-tags .wrap-colum .col-4-1, body.page-node-29 .wrap-colum .col-3-1,
  body.page-node-29 .wrap-colum .col-4-1, body.page-node-34 .wrap-colum .col-3-1,
  body.page-node-34 .wrap-colum .col-4-1, body.page-content .wrap-colum .col-3-1,
  body.page-content .wrap-colum .col-4-1 {
    padding: 0;
  }
  body.page-news .horizontal-layout .entry-thumb,
  body.page-news .horizontal-layout .entry-details, body.node-type-tep-blogs .horizontal-layout .entry-thumb,
  body.node-type-tep-blogs .horizontal-layout .entry-details, body.page-guides .horizontal-layout .entry-thumb,
  body.page-guides .horizontal-layout .entry-details, body.page-edgeproptv .horizontal-layout .entry-thumb,
  body.page-edgeproptv .horizontal-layout .entry-details, body.page-pullout .horizontal-layout .entry-thumb,
  body.page-pullout .horizontal-layout .entry-details, body.page-events .horizontal-layout .entry-thumb,
  body.page-events .horizontal-layout .entry-details, body.page-event .horizontal-layout .entry-thumb,
  body.page-event .horizontal-layout .entry-details, body.page-tags .horizontal-layout .entry-thumb,
  body.page-tags .horizontal-layout .entry-details, body.page-node-29 .horizontal-layout .entry-thumb,
  body.page-node-29 .horizontal-layout .entry-details, body.page-node-34 .horizontal-layout .entry-thumb,
  body.page-node-34 .horizontal-layout .entry-details, body.page-content .horizontal-layout .entry-thumb,
  body.page-content .horizontal-layout .entry-details {
    float: none;
    width: 100%;
  }
  body.page-news .top-post-cat, body.node-type-tep-blogs .top-post-cat, body.page-guides .top-post-cat, body.page-edgeproptv .top-post-cat, body.page-pullout .top-post-cat, body.page-events .top-post-cat, body.page-event .top-post-cat, body.page-tags .top-post-cat, body.page-node-29 .top-post-cat, body.page-node-34 .top-post-cat, body.page-content .top-post-cat {
    display: inline-block;
  }
  body.page-news .top-post-title, body.node-type-tep-blogs .top-post-title, body.page-guides .top-post-title, body.page-edgeproptv .top-post-title, body.page-pullout .top-post-title, body.page-events .top-post-title, body.page-event .top-post-title, body.page-tags .top-post-title, body.page-node-29 .top-post-title, body.page-node-34 .top-post-title, body.page-content .top-post-title {
    font-size: 18px;
    line-height: 24px;
  }
  body.page-news .post-latest .entry-thumb, body.node-type-tep-blogs .post-latest .entry-thumb, body.page-guides .post-latest .entry-thumb, body.page-edgeproptv .post-latest .entry-thumb, body.page-pullout .post-latest .entry-thumb, body.page-events .post-latest .entry-thumb, body.page-event .post-latest .entry-thumb, body.page-tags .post-latest .entry-thumb, body.page-node-29 .post-latest .entry-thumb, body.page-node-34 .post-latest .entry-thumb, body.page-content .post-latest .entry-thumb {
    padding-right: 0;
  }
  body.page-news .horizontal-layout .entry-details, body.node-type-tep-blogs .horizontal-layout .entry-details, body.page-guides .horizontal-layout .entry-details, body.page-edgeproptv .horizontal-layout .entry-details, body.page-pullout .horizontal-layout .entry-details, body.page-events .horizontal-layout .entry-details, body.page-event .horizontal-layout .entry-details, body.page-tags .horizontal-layout .entry-details, body.page-node-29 .horizontal-layout .entry-details, body.page-node-34 .horizontal-layout .entry-details, body.page-content .horizontal-layout .entry-details {
    padding-left: 0;
  }
  body.page-news .search-form.content .search-input, body.node-type-tep-blogs .search-form.content .search-input, body.page-guides .search-form.content .search-input, body.page-edgeproptv .search-form.content .search-input, body.page-pullout .search-form.content .search-input, body.page-events .search-form.content .search-input, body.page-event .search-form.content .search-input, body.page-tags .search-form.content .search-input, body.page-node-29 .search-form.content .search-input, body.page-node-34 .search-form.content .search-input, body.page-content .search-form.content .search-input {
    margin-bottom: 10px;
  }
  body.page-news .cat-meta-line .entry-categories, body.node-type-tep-blogs .cat-meta-line .entry-categories, body.page-guides .cat-meta-line .entry-categories, body.page-edgeproptv .cat-meta-line .entry-categories, body.page-pullout .cat-meta-line .entry-categories, body.page-events .cat-meta-line .entry-categories, body.page-event .cat-meta-line .entry-categories, body.page-tags .cat-meta-line .entry-categories, body.page-node-29 .cat-meta-line .entry-categories, body.page-node-34 .cat-meta-line .entry-categories, body.page-content .cat-meta-line .entry-categories {
    float: none;
    margin-bottom: 18px;
    padding: 0 10px;
  }
  body.page-news .cat-meta-line .entry-meta, body.node-type-tep-blogs .cat-meta-line .entry-meta, body.page-guides .cat-meta-line .entry-meta, body.page-edgeproptv .cat-meta-line .entry-meta, body.page-pullout .cat-meta-line .entry-meta, body.page-events .cat-meta-line .entry-meta, body.page-event .cat-meta-line .entry-meta, body.page-tags .cat-meta-line .entry-meta, body.page-node-29 .cat-meta-line .entry-meta, body.page-node-34 .cat-meta-line .entry-meta, body.page-content .cat-meta-line .entry-meta {
    float: none;
  }
}

@media screen and (max-width: 768px) and (max-width: 768px) {
  body.page-news .cat-meta-line .entry-meta, body.node-type-tep-blogs .cat-meta-line .entry-meta, body.page-guides .cat-meta-line .entry-meta, body.page-edgeproptv .cat-meta-line .entry-meta, body.page-pullout .cat-meta-line .entry-meta, body.page-events .cat-meta-line .entry-meta, body.page-event .cat-meta-line .entry-meta, body.page-tags .cat-meta-line .entry-meta, body.page-node-29 .cat-meta-line .entry-meta, body.page-node-34 .cat-meta-line .entry-meta, body.page-content .cat-meta-line .entry-meta {
    padding-left: 10px;
  }
}

@media screen and (max-width: 768px) {
  body.page-news .alignright,
  body.page-news img.alignright,
  body.page-news .alignleft,
  body.page-news img.alignleft, body.node-type-tep-blogs .alignright,
  body.node-type-tep-blogs img.alignright,
  body.node-type-tep-blogs .alignleft,
  body.node-type-tep-blogs img.alignleft, body.page-guides .alignright,
  body.page-guides img.alignright,
  body.page-guides .alignleft,
  body.page-guides img.alignleft, body.page-edgeproptv .alignright,
  body.page-edgeproptv img.alignright,
  body.page-edgeproptv .alignleft,
  body.page-edgeproptv img.alignleft, body.page-pullout .alignright,
  body.page-pullout img.alignright,
  body.page-pullout .alignleft,
  body.page-pullout img.alignleft, body.page-events .alignright,
  body.page-events img.alignright,
  body.page-events .alignleft,
  body.page-events img.alignleft, body.page-event .alignright,
  body.page-event img.alignright,
  body.page-event .alignleft,
  body.page-event img.alignleft, body.page-tags .alignright,
  body.page-tags img.alignright,
  body.page-tags .alignleft,
  body.page-tags img.alignleft, body.page-node-29 .alignright,
  body.page-node-29 img.alignright,
  body.page-node-29 .alignleft,
  body.page-node-29 img.alignleft, body.page-node-34 .alignright,
  body.page-node-34 img.alignright,
  body.page-node-34 .alignleft,
  body.page-node-34 img.alignleft, body.page-content .alignright,
  body.page-content img.alignright,
  body.page-content .alignleft,
  body.page-content img.alignleft {
    max-width: 100%;
  }
  body.page-news .author-bio-avatar, body.node-type-tep-blogs .author-bio-avatar, body.page-guides .author-bio-avatar, body.page-edgeproptv .author-bio-avatar, body.page-pullout .author-bio-avatar, body.page-events .author-bio-avatar, body.page-event .author-bio-avatar, body.page-tags .author-bio-avatar, body.page-node-29 .author-bio-avatar, body.page-node-34 .author-bio-avatar, body.page-content .author-bio-avatar {
    float: none;
    margin: auto;
    width: 100px;
    margin-bottom: 12px;
  }
  body.page-news .author-bio-details, body.node-type-tep-blogs .author-bio-details, body.page-guides .author-bio-details, body.page-edgeproptv .author-bio-details, body.page-pullout .author-bio-details, body.page-events .author-bio-details, body.page-event .author-bio-details, body.page-tags .author-bio-details, body.page-node-29 .author-bio-details, body.page-node-34 .author-bio-details, body.page-content .author-bio-details {
    margin-left: 0;
  }
  body.page-news .author-bio-title, body.node-type-tep-blogs .author-bio-title, body.page-guides .author-bio-title, body.page-edgeproptv .author-bio-title, body.page-pullout .author-bio-title, body.page-events .author-bio-title, body.page-event .author-bio-title, body.page-tags .author-bio-title, body.page-node-29 .author-bio-title, body.page-node-34 .author-bio-title, body.page-content .author-bio-title {
    text-align: center;
  }
  body.page-news blockquote.news-quote, body.node-type-tep-blogs blockquote.news-quote, body.page-guides blockquote.news-quote, body.page-edgeproptv blockquote.news-quote, body.page-pullout blockquote.news-quote, body.page-events blockquote.news-quote, body.page-event blockquote.news-quote, body.page-tags blockquote.news-quote, body.page-node-29 blockquote.news-quote, body.page-node-34 blockquote.news-quote, body.page-content blockquote.news-quote {
    margin: 30px 0px;
    padding: 32px 25px;
  }
}

@media screen and (max-width: 380px) {
  body.page-news .content input,
  body.page-news .content .mc4wp-form-fields input,
  body.page-news .content .mc4wp-form-fields input[type=submit],
  body.page-news .search-form.content button, body.node-type-tep-blogs .content input,
  body.node-type-tep-blogs .content .mc4wp-form-fields input,
  body.node-type-tep-blogs .content .mc4wp-form-fields input[type=submit],
  body.node-type-tep-blogs .search-form.content button, body.page-guides .content input,
  body.page-guides .content .mc4wp-form-fields input,
  body.page-guides .content .mc4wp-form-fields input[type=submit],
  body.page-guides .search-form.content button, body.page-edgeproptv .content input,
  body.page-edgeproptv .content .mc4wp-form-fields input,
  body.page-edgeproptv .content .mc4wp-form-fields input[type=submit],
  body.page-edgeproptv .search-form.content button, body.page-pullout .content input,
  body.page-pullout .content .mc4wp-form-fields input,
  body.page-pullout .content .mc4wp-form-fields input[type=submit],
  body.page-pullout .search-form.content button, body.page-events .content input,
  body.page-events .content .mc4wp-form-fields input,
  body.page-events .content .mc4wp-form-fields input[type=submit],
  body.page-events .search-form.content button, body.page-event .content input,
  body.page-event .content .mc4wp-form-fields input,
  body.page-event .content .mc4wp-form-fields input[type=submit],
  body.page-event .search-form.content button, body.page-tags .content input,
  body.page-tags .content .mc4wp-form-fields input,
  body.page-tags .content .mc4wp-form-fields input[type=submit],
  body.page-tags .search-form.content button, body.page-node-29 .content input,
  body.page-node-29 .content .mc4wp-form-fields input,
  body.page-node-29 .content .mc4wp-form-fields input[type=submit],
  body.page-node-29 .search-form.content button, body.page-node-34 .content input,
  body.page-node-34 .content .mc4wp-form-fields input,
  body.page-node-34 .content .mc4wp-form-fields input[type=submit],
  body.page-node-34 .search-form.content button, body.page-content .content input,
  body.page-content .content .mc4wp-form-fields input,
  body.page-content .content .mc4wp-form-fields input[type=submit],
  body.page-content .search-form.content button {
    width: 100%;
  }
  body.page-news .content .mc4wp-form-fields input[type=email], body.node-type-tep-blogs .content .mc4wp-form-fields input[type=email], body.page-guides .content .mc4wp-form-fields input[type=email], body.page-edgeproptv .content .mc4wp-form-fields input[type=email], body.page-pullout .content .mc4wp-form-fields input[type=email], body.page-events .content .mc4wp-form-fields input[type=email], body.page-event .content .mc4wp-form-fields input[type=email], body.page-tags .content .mc4wp-form-fields input[type=email], body.page-node-29 .content .mc4wp-form-fields input[type=email], body.page-node-34 .content .mc4wp-form-fields input[type=email], body.page-content .content .mc4wp-form-fields input[type=email] {
    margin-right: 0;
  }
  body.page-news h3#reply-title, body.node-type-tep-blogs h3#reply-title, body.page-guides h3#reply-title, body.page-edgeproptv h3#reply-title, body.page-pullout h3#reply-title, body.page-events h3#reply-title, body.page-event h3#reply-title, body.page-tags h3#reply-title, body.page-node-29 h3#reply-title, body.page-node-34 h3#reply-title, body.page-content h3#reply-title {
    position: relative;
    margin-bottom: 50px;
  }
  body.page-news #cancel-comment-reply-link, body.node-type-tep-blogs #cancel-comment-reply-link, body.page-guides #cancel-comment-reply-link, body.page-edgeproptv #cancel-comment-reply-link, body.page-pullout #cancel-comment-reply-link, body.page-events #cancel-comment-reply-link, body.page-event #cancel-comment-reply-link, body.page-tags #cancel-comment-reply-link, body.page-node-29 #cancel-comment-reply-link, body.page-node-34 #cancel-comment-reply-link, body.page-content #cancel-comment-reply-link {
    top: 100%;
    margin-top: 12px;
    left: 0;
    right: initial;
  }
}

@media (max-width: 320px) {
  body.page-news .wrap, body.node-type-tep-blogs .wrap, body.page-guides .wrap, body.page-edgeproptv .wrap, body.page-pullout .wrap, body.page-events .wrap, body.page-event .wrap, body.page-tags .wrap, body.page-node-29 .wrap, body.page-node-34 .wrap, body.page-content .wrap {
    padding: 0 3px;
  }
}

@font-face {
  body.page-news, body.node-type-tep-blogs, body.page-guides, body.page-edgeproptv, body.page-pullout, body.page-events, body.page-event, body.page-tags, body.page-node-29, body.page-node-34, body.page-content {
    font-family: 'MyriadPro-Regular';
    src: url("../fonts/MyriadPro-Regular.eot?#iefix") format("embedded-opentype"), url("../fonts/MyriadPro-Regular.otf") format("opentype"), url("../fonts/MyriadPro-Regular.woff") format("woff"), url("../fonts/MyriadPro-Regular.ttf") format("truetype"), url("../fonts/MyriadPro-Regular.svg#MyriadPro-Regular") format("svg");
    font-weight: normal;
    font-style: normal;
  }
}

@font-face {
  body.page-news, body.node-type-tep-blogs, body.page-guides, body.page-edgeproptv, body.page-pullout, body.page-events, body.page-event, body.page-tags, body.page-node-29, body.page-node-34, body.page-content {
    font-family: 'MyriadPro-Bold';
    src: url("../fonts/MyriadPro-Bold.eot?#iefix") format("embedded-opentype"), url("../fonts/MyriadPro-Bold.otf") format("opentype"), url("../fonts/MyriadPro-Bold.woff") format("woff"), url("../fonts/MyriadPro-Bold.ttf") format("truetype"), url("../fonts/MyriadPro-Bold.svg#MyriadPro-Bold") format("svg");
    font-weight: normal;
    font-style: normal;
  }
}

body.page-news .clearfix:after,
body.page-news .clearfix:before, body.node-type-tep-blogs .clearfix:after,
body.node-type-tep-blogs .clearfix:before, body.page-guides .clearfix:after,
body.page-guides .clearfix:before, body.page-edgeproptv .clearfix:after,
body.page-edgeproptv .clearfix:before, body.page-pullout .clearfix:after,
body.page-pullout .clearfix:before, body.page-events .clearfix:after,
body.page-events .clearfix:before, body.page-event .clearfix:after,
body.page-event .clearfix:before, body.page-tags .clearfix:after,
body.page-tags .clearfix:before, body.page-node-29 .clearfix:after,
body.page-node-29 .clearfix:before, body.page-node-34 .clearfix:after,
body.page-node-34 .clearfix:before, body.page-content .clearfix:after,
body.page-content .clearfix:before {
  display: table;
  content: "";
}

body.page-news .clearfix:after, body.node-type-tep-blogs .clearfix:after, body.page-guides .clearfix:after, body.page-edgeproptv .clearfix:after, body.page-pullout .clearfix:after, body.page-events .clearfix:after, body.page-event .clearfix:after, body.page-tags .clearfix:after, body.page-node-29 .clearfix:after, body.page-node-34 .clearfix:after, body.page-content .clearfix:after {
  clear: both;
}

body.page-news .clearfix, body.node-type-tep-blogs .clearfix, body.page-guides .clearfix, body.page-edgeproptv .clearfix, body.page-pullout .clearfix, body.page-events .clearfix, body.page-event .clearfix, body.page-tags .clearfix, body.page-node-29 .clearfix, body.page-node-34 .clearfix, body.page-content .clearfix {
  *zoom: 1;
}

body.page-news .apsc-each-profile, body.node-type-tep-blogs .apsc-each-profile, body.page-guides .apsc-each-profile, body.page-edgeproptv .apsc-each-profile, body.page-pullout .apsc-each-profile, body.page-events .apsc-each-profile, body.page-event .apsc-each-profile, body.page-tags .apsc-each-profile, body.page-node-29 .apsc-each-profile, body.page-node-34 .apsc-each-profile, body.page-content .apsc-each-profile {
  font-family: 'MyriadPro-Regular';
}

body.page-news .apsc-theme-1 .apsc-each-profile, body.node-type-tep-blogs .apsc-theme-1 .apsc-each-profile, body.page-guides .apsc-theme-1 .apsc-each-profile, body.page-edgeproptv .apsc-theme-1 .apsc-each-profile, body.page-pullout .apsc-theme-1 .apsc-each-profile, body.page-events .apsc-theme-1 .apsc-each-profile, body.page-event .apsc-theme-1 .apsc-each-profile, body.page-tags .apsc-theme-1 .apsc-each-profile, body.page-node-29 .apsc-theme-1 .apsc-each-profile, body.page-node-34 .apsc-theme-1 .apsc-each-profile, body.page-content .apsc-theme-1 .apsc-each-profile {
  float: left;
  min-height: 80px;
  text-align: center;
  width: 80px;
  margin: 5px;
}

body.page-news .apsc-each-profile a, body.node-type-tep-blogs .apsc-each-profile a, body.page-guides .apsc-each-profile a, body.page-edgeproptv .apsc-each-profile a, body.page-pullout .apsc-each-profile a, body.page-events .apsc-each-profile a, body.page-event .apsc-each-profile a, body.page-tags .apsc-each-profile a, body.page-node-29 .apsc-each-profile a, body.page-node-34 .apsc-each-profile a, body.page-content .apsc-each-profile a {
  font-weight: normal;
  color: white !important;
  text-decoration: none;
}

body.page-news .apsc-each-profile a:hover, body.node-type-tep-blogs .apsc-each-profile a:hover, body.page-guides .apsc-each-profile a:hover, body.page-edgeproptv .apsc-each-profile a:hover, body.page-pullout .apsc-each-profile a:hover, body.page-events .apsc-each-profile a:hover, body.page-event .apsc-each-profile a:hover, body.page-tags .apsc-each-profile a:hover, body.page-node-29 .apsc-each-profile a:hover, body.page-node-34 .apsc-each-profile a:hover, body.page-content .apsc-each-profile a:hover {
  color: white !important;
  text-decoration: none !important;
}

body.page-news .apsc-theme-1 .apsc-each-profile a, body.node-type-tep-blogs .apsc-theme-1 .apsc-each-profile a, body.page-guides .apsc-theme-1 .apsc-each-profile a, body.page-edgeproptv .apsc-theme-1 .apsc-each-profile a, body.page-pullout .apsc-theme-1 .apsc-each-profile a, body.page-events .apsc-theme-1 .apsc-each-profile a, body.page-event .apsc-theme-1 .apsc-each-profile a, body.page-tags .apsc-theme-1 .apsc-each-profile a, body.page-node-29 .apsc-theme-1 .apsc-each-profile a, body.page-node-34 .apsc-theme-1 .apsc-each-profile a, body.page-content .apsc-theme-1 .apsc-each-profile a {
  padding: 9px;
  display: block;
  color: #fff;
  position: relative;
  border-radius: 5px;
  -ms-filter: "progid:DXImageTransform.Microsoft.Shadow(Strength=2, Direction=135, Color=#b3b3b3)";
  /*IE 8*/
  -moz-box-shadow: 1px 1px 2px #b3b3b3;
  /*FF 3.5+*/
  -webkit-box-shadow: 1px 1px 2px #b3b3b3;
  /*Saf3-4, Chrome, iOS 4.0.2-4.2, Android 2.3+*/
  box-shadow: 1px 1px 2px #b3b3b3;
  /* FF3.5+, Opera 9+, Saf1+, Chrome, IE10 */
  filter: progid:DXImageTransform.Microsoft.Shadow(Strength=2, Direction=135, Color=#b3b3b3);
  /*IE 5.5-7*/
  transition: all 0.5s ease-in-out;
  -webkit-transition: all 0.5s ease-in-out;
  -moz-transition: all 0.5s ease-in-out;
  -o-transition: all 0.5s ease-in-out;
  -ms-transition: all 0.5s ease-in-out;
}

body.page-news .entry-content .apsc-each-profile a, body.node-type-tep-blogs .entry-content .apsc-each-profile a, body.page-guides .entry-content .apsc-each-profile a, body.page-edgeproptv .entry-content .apsc-each-profile a, body.page-pullout .entry-content .apsc-each-profile a, body.page-events .entry-content .apsc-each-profile a, body.page-event .entry-content .apsc-each-profile a, body.page-tags .entry-content .apsc-each-profile a, body.page-node-29 .entry-content .apsc-each-profile a, body.page-node-34 .entry-content .apsc-each-profile a, body.page-content .entry-content .apsc-each-profile a {
  border: none;
}

body.page-news .social-icon, body.node-type-tep-blogs .social-icon, body.page-guides .social-icon, body.page-edgeproptv .social-icon, body.page-pullout .social-icon, body.page-events .social-icon, body.page-event .social-icon, body.page-tags .social-icon, body.page-node-29 .social-icon, body.page-node-34 .social-icon, body.page-content .social-icon {
  display: block;
}

body.page-news .apsc-theme-1 .media-name, body.node-type-tep-blogs .apsc-theme-1 .media-name, body.page-guides .apsc-theme-1 .media-name, body.page-edgeproptv .apsc-theme-1 .media-name, body.page-pullout .apsc-theme-1 .media-name, body.page-events .apsc-theme-1 .media-name, body.page-event .apsc-theme-1 .media-name, body.page-tags .apsc-theme-1 .media-name, body.page-node-29 .apsc-theme-1 .media-name, body.page-node-34 .apsc-theme-1 .media-name, body.page-content .apsc-theme-1 .media-name {
  display: none;
}

body.page-news .apsc-theme-1 .apsc-count, body.node-type-tep-blogs .apsc-theme-1 .apsc-count, body.page-guides .apsc-theme-1 .apsc-count, body.page-edgeproptv .apsc-theme-1 .apsc-count, body.page-pullout .apsc-theme-1 .apsc-count, body.page-events .apsc-theme-1 .apsc-count, body.page-event .apsc-theme-1 .apsc-count, body.page-tags .apsc-theme-1 .apsc-count, body.page-node-29 .apsc-theme-1 .apsc-count, body.page-node-34 .apsc-theme-1 .apsc-count, body.page-content .apsc-theme-1 .apsc-count {
  font-size: 16px;
  display: block;
  line-height: 15px;
  padding: 2px 0 0;
}

body.page-news .entry-content .apsc-each-profile a,
body.page-news .entry-content .apsc-each-profile a:hover,
body.page-news .entry-content .apsc-each-profile a:focus, body.node-type-tep-blogs .entry-content .apsc-each-profile a,
body.node-type-tep-blogs .entry-content .apsc-each-profile a:hover,
body.node-type-tep-blogs .entry-content .apsc-each-profile a:focus, body.page-guides .entry-content .apsc-each-profile a,
body.page-guides .entry-content .apsc-each-profile a:hover,
body.page-guides .entry-content .apsc-each-profile a:focus, body.page-edgeproptv .entry-content .apsc-each-profile a,
body.page-edgeproptv .entry-content .apsc-each-profile a:hover,
body.page-edgeproptv .entry-content .apsc-each-profile a:focus, body.page-pullout .entry-content .apsc-each-profile a,
body.page-pullout .entry-content .apsc-each-profile a:hover,
body.page-pullout .entry-content .apsc-each-profile a:focus, body.page-events .entry-content .apsc-each-profile a,
body.page-events .entry-content .apsc-each-profile a:hover,
body.page-events .entry-content .apsc-each-profile a:focus, body.page-event .entry-content .apsc-each-profile a,
body.page-event .entry-content .apsc-each-profile a:hover,
body.page-event .entry-content .apsc-each-profile a:focus, body.page-tags .entry-content .apsc-each-profile a,
body.page-tags .entry-content .apsc-each-profile a:hover,
body.page-tags .entry-content .apsc-each-profile a:focus, body.page-node-29 .entry-content .apsc-each-profile a,
body.page-node-29 .entry-content .apsc-each-profile a:hover,
body.page-node-29 .entry-content .apsc-each-profile a:focus, body.page-node-34 .entry-content .apsc-each-profile a,
body.page-node-34 .entry-content .apsc-each-profile a:hover,
body.page-node-34 .entry-content .apsc-each-profile a:focus, body.page-content .entry-content .apsc-each-profile a,
body.page-content .entry-content .apsc-each-profile a:hover,
body.page-content .entry-content .apsc-each-profile a:focus {
  color: #fff;
  text-decoration: none;
}

body.page-news .apsc-theme-1 .apsc-each-profile a:before, body.node-type-tep-blogs .apsc-theme-1 .apsc-each-profile a:before, body.page-guides .apsc-theme-1 .apsc-each-profile a:before, body.page-edgeproptv .apsc-theme-1 .apsc-each-profile a:before, body.page-pullout .apsc-theme-1 .apsc-each-profile a:before, body.page-events .apsc-theme-1 .apsc-each-profile a:before, body.page-event .apsc-theme-1 .apsc-each-profile a:before, body.page-tags .apsc-theme-1 .apsc-each-profile a:before, body.page-node-29 .apsc-theme-1 .apsc-each-profile a:before, body.page-node-34 .apsc-theme-1 .apsc-each-profile a:before, body.page-content .apsc-theme-1 .apsc-each-profile a:before {
  background: radial-gradient(ellipse at center center, rgba(0, 0, 0, 0.35) 0%, rgba(0, 0, 0, 0) 80%) repeat scroll 0 0 rgba(0, 0, 0, 0);
  content: "";
  height: 10px;
  left: 5%;
  opacity: 0;
  pointer-events: none;
  position: absolute;
  top: 100%;
  transition-duration: 0.3s;
  -webkit-transition-duration: 0.3s;
  transition-property: transform, opacity;
  -webkit-transition-property: transform, opacity;
  width: 90%;
  z-index: -1;
}

body.page-news .apsc-theme-1 .apsc-each-profile a:hover:before, body.node-type-tep-blogs .apsc-theme-1 .apsc-each-profile a:hover:before, body.page-guides .apsc-theme-1 .apsc-each-profile a:hover:before, body.page-edgeproptv .apsc-theme-1 .apsc-each-profile a:hover:before, body.page-pullout .apsc-theme-1 .apsc-each-profile a:hover:before, body.page-events .apsc-theme-1 .apsc-each-profile a:hover:before, body.page-event .apsc-theme-1 .apsc-each-profile a:hover:before, body.page-tags .apsc-theme-1 .apsc-each-profile a:hover:before, body.page-node-29 .apsc-theme-1 .apsc-each-profile a:hover:before, body.page-node-34 .apsc-theme-1 .apsc-each-profile a:hover:before, body.page-content .apsc-theme-1 .apsc-each-profile a:hover:before {
  opacity: 1;
  transform: translateY(6px);
  -webkit-transform: translateY(6px);
  -ms-transform: translateY(6px);
}

body.page-news .apsc-theme-1 .apsc-each-profile a:hover, body.node-type-tep-blogs .apsc-theme-1 .apsc-each-profile a:hover, body.page-guides .apsc-theme-1 .apsc-each-profile a:hover, body.page-edgeproptv .apsc-theme-1 .apsc-each-profile a:hover, body.page-pullout .apsc-theme-1 .apsc-each-profile a:hover, body.page-events .apsc-theme-1 .apsc-each-profile a:hover, body.page-event .apsc-theme-1 .apsc-each-profile a:hover, body.page-tags .apsc-theme-1 .apsc-each-profile a:hover, body.page-node-29 .apsc-theme-1 .apsc-each-profile a:hover, body.page-node-34 .apsc-theme-1 .apsc-each-profile a:hover, body.page-content .apsc-theme-1 .apsc-each-profile a:hover {
  transform: translateY(-6px);
  -webkit-transform: translateY(-6px);
  -ms-transform: translateY(-6px);
}

body.page-news .apsc-theme-1 .social-icon .fa, body.node-type-tep-blogs .apsc-theme-1 .social-icon .fa, body.page-guides .apsc-theme-1 .social-icon .fa, body.page-edgeproptv .apsc-theme-1 .social-icon .fa, body.page-pullout .apsc-theme-1 .social-icon .fa, body.page-events .apsc-theme-1 .social-icon .fa, body.page-event .apsc-theme-1 .social-icon .fa, body.page-tags .apsc-theme-1 .social-icon .fa, body.page-node-29 .apsc-theme-1 .social-icon .fa, body.page-node-34 .apsc-theme-1 .social-icon .fa, body.page-content .apsc-theme-1 .social-icon .fa {
  font-size: 45px;
}

body.page-news .apsc-facebook-icon, body.node-type-tep-blogs .apsc-facebook-icon, body.page-guides .apsc-facebook-icon, body.page-edgeproptv .apsc-facebook-icon, body.page-pullout .apsc-facebook-icon, body.page-events .apsc-facebook-icon, body.page-event .apsc-facebook-icon, body.page-tags .apsc-facebook-icon, body.page-node-29 .apsc-facebook-icon, body.page-node-34 .apsc-facebook-icon, body.page-content .apsc-facebook-icon {
  background-color: #3b5998;
}

body.page-news .apsc-twitter-icon, body.node-type-tep-blogs .apsc-twitter-icon, body.page-guides .apsc-twitter-icon, body.page-edgeproptv .apsc-twitter-icon, body.page-pullout .apsc-twitter-icon, body.page-events .apsc-twitter-icon, body.page-event .apsc-twitter-icon, body.page-tags .apsc-twitter-icon, body.page-node-29 .apsc-twitter-icon, body.page-node-34 .apsc-twitter-icon, body.page-content .apsc-twitter-icon {
  background-color: #1dcaff;
}

body.page-news .apsc-youtube-icon, body.node-type-tep-blogs .apsc-youtube-icon, body.page-guides .apsc-youtube-icon, body.page-edgeproptv .apsc-youtube-icon, body.page-pullout .apsc-youtube-icon, body.page-events .apsc-youtube-icon, body.page-event .apsc-youtube-icon, body.page-tags .apsc-youtube-icon, body.page-node-29 .apsc-youtube-icon, body.page-node-34 .apsc-youtube-icon, body.page-content .apsc-youtube-icon {
  background-color: #e52d27;
}

body.page-news .apsc-soundcloud-icon, body.node-type-tep-blogs .apsc-soundcloud-icon, body.page-guides .apsc-soundcloud-icon, body.page-edgeproptv .apsc-soundcloud-icon, body.page-pullout .apsc-soundcloud-icon, body.page-events .apsc-soundcloud-icon, body.page-event .apsc-soundcloud-icon, body.page-tags .apsc-soundcloud-icon, body.page-node-29 .apsc-soundcloud-icon, body.page-node-34 .apsc-soundcloud-icon, body.page-content .apsc-soundcloud-icon {
  background-color: #ff8800;
}

body.page-news .apsc-dribble-icon, body.node-type-tep-blogs .apsc-dribble-icon, body.page-guides .apsc-dribble-icon, body.page-edgeproptv .apsc-dribble-icon, body.page-pullout .apsc-dribble-icon, body.page-events .apsc-dribble-icon, body.page-event .apsc-dribble-icon, body.page-tags .apsc-dribble-icon, body.page-node-29 .apsc-dribble-icon, body.page-node-34 .apsc-dribble-icon, body.page-content .apsc-dribble-icon {
  background-color: #ea4c89;
}

body.page-news .apsc-comment-icon, body.node-type-tep-blogs .apsc-comment-icon, body.page-guides .apsc-comment-icon, body.page-edgeproptv .apsc-comment-icon, body.page-pullout .apsc-comment-icon, body.page-events .apsc-comment-icon, body.page-event .apsc-comment-icon, body.page-tags .apsc-comment-icon, body.page-node-29 .apsc-comment-icon, body.page-node-34 .apsc-comment-icon, body.page-content .apsc-comment-icon {
  background-color: #464646;
}

body.page-news .apsc-edit-icon, body.node-type-tep-blogs .apsc-edit-icon, body.page-guides .apsc-edit-icon, body.page-edgeproptv .apsc-edit-icon, body.page-pullout .apsc-edit-icon, body.page-events .apsc-edit-icon, body.page-event .apsc-edit-icon, body.page-tags .apsc-edit-icon, body.page-node-29 .apsc-edit-icon, body.page-node-34 .apsc-edit-icon, body.page-content .apsc-edit-icon {
  background-color: #837d7d;
}

body.page-news .apsc-google-plus-icon, body.node-type-tep-blogs .apsc-google-plus-icon, body.page-guides .apsc-google-plus-icon, body.page-edgeproptv .apsc-google-plus-icon, body.page-pullout .apsc-google-plus-icon, body.page-events .apsc-google-plus-icon, body.page-event .apsc-google-plus-icon, body.page-tags .apsc-google-plus-icon, body.page-node-29 .apsc-google-plus-icon, body.page-node-34 .apsc-google-plus-icon, body.page-content .apsc-google-plus-icon {
  background-color: #dd4b39;
}

body.page-news .apsc-theme-1 .apsc-media-type, body.node-type-tep-blogs .apsc-theme-1 .apsc-media-type, body.page-guides .apsc-theme-1 .apsc-media-type, body.page-edgeproptv .apsc-theme-1 .apsc-media-type, body.page-pullout .apsc-theme-1 .apsc-media-type, body.page-events .apsc-theme-1 .apsc-media-type, body.page-event .apsc-theme-1 .apsc-media-type, body.page-tags .apsc-theme-1 .apsc-media-type, body.page-node-29 .apsc-theme-1 .apsc-media-type, body.page-node-34 .apsc-theme-1 .apsc-media-type, body.page-content .apsc-theme-1 .apsc-media-type {
  display: none;
}

body.page-news .apsc-theme-2 .apsc-each-profile, body.node-type-tep-blogs .apsc-theme-2 .apsc-each-profile, body.page-guides .apsc-theme-2 .apsc-each-profile, body.page-edgeproptv .apsc-theme-2 .apsc-each-profile, body.page-pullout .apsc-theme-2 .apsc-each-profile, body.page-events .apsc-theme-2 .apsc-each-profile, body.page-event .apsc-theme-2 .apsc-each-profile, body.page-tags .apsc-theme-2 .apsc-each-profile, body.page-node-29 .apsc-theme-2 .apsc-each-profile, body.page-node-34 .apsc-theme-2 .apsc-each-profile, body.page-content .apsc-theme-2 .apsc-each-profile {
  float: left;
  min-height: 83px;
  text-align: center;
  width: 80px;
  margin: 5px;
}

body.page-news .apsc-theme-2 .apsc-count, body.node-type-tep-blogs .apsc-theme-2 .apsc-count, body.page-guides .apsc-theme-2 .apsc-count, body.page-edgeproptv .apsc-theme-2 .apsc-count, body.page-pullout .apsc-theme-2 .apsc-count, body.page-events .apsc-theme-2 .apsc-count, body.page-event .apsc-theme-2 .apsc-count, body.page-tags .apsc-theme-2 .apsc-count, body.page-node-29 .apsc-theme-2 .apsc-count, body.page-node-34 .apsc-theme-2 .apsc-count, body.page-content .apsc-theme-2 .apsc-count {
  display: block;
  padding: 1px 0;
}

body.page-news .apsc-theme-2 .social-icon .fa, body.node-type-tep-blogs .apsc-theme-2 .social-icon .fa, body.page-guides .apsc-theme-2 .social-icon .fa, body.page-edgeproptv .apsc-theme-2 .social-icon .fa, body.page-pullout .apsc-theme-2 .social-icon .fa, body.page-events .apsc-theme-2 .social-icon .fa, body.page-event .apsc-theme-2 .social-icon .fa, body.page-tags .apsc-theme-2 .social-icon .fa, body.page-node-29 .apsc-theme-2 .social-icon .fa, body.page-node-34 .apsc-theme-2 .social-icon .fa, body.page-content .apsc-theme-2 .social-icon .fa {
  font-size: 36px;
}

body.page-news .apsc-theme-2 .media-name, body.node-type-tep-blogs .apsc-theme-2 .media-name, body.page-guides .apsc-theme-2 .media-name, body.page-edgeproptv .apsc-theme-2 .media-name, body.page-pullout .apsc-theme-2 .media-name, body.page-events .apsc-theme-2 .media-name, body.page-event .apsc-theme-2 .media-name, body.page-tags .apsc-theme-2 .media-name, body.page-node-29 .apsc-theme-2 .media-name, body.page-node-34 .apsc-theme-2 .media-name, body.page-content .apsc-theme-2 .media-name {
  display: none;
}

body.page-news .apsc-theme-2 .apsc-media-type, body.node-type-tep-blogs .apsc-theme-2 .apsc-media-type, body.page-guides .apsc-theme-2 .apsc-media-type, body.page-edgeproptv .apsc-theme-2 .apsc-media-type, body.page-pullout .apsc-theme-2 .apsc-media-type, body.page-events .apsc-theme-2 .apsc-media-type, body.page-event .apsc-theme-2 .apsc-media-type, body.page-tags .apsc-theme-2 .apsc-media-type, body.page-node-29 .apsc-theme-2 .apsc-media-type, body.page-node-34 .apsc-theme-2 .apsc-media-type, body.page-content .apsc-theme-2 .apsc-media-type {
  display: block;
}

body.page-news .apsc-theme-2 .apsc-each-profile a, body.node-type-tep-blogs .apsc-theme-2 .apsc-each-profile a, body.page-guides .apsc-theme-2 .apsc-each-profile a, body.page-edgeproptv .apsc-theme-2 .apsc-each-profile a, body.page-pullout .apsc-theme-2 .apsc-each-profile a, body.page-events .apsc-theme-2 .apsc-each-profile a, body.page-event .apsc-theme-2 .apsc-each-profile a, body.page-tags .apsc-theme-2 .apsc-each-profile a, body.page-node-29 .apsc-theme-2 .apsc-each-profile a, body.page-node-34 .apsc-theme-2 .apsc-each-profile a, body.page-content .apsc-theme-2 .apsc-each-profile a {
  display: block;
  color: #fff;
  position: relative;
  transform: translateZ(0px);
  -webkit-transform: translateZ(0px);
  -ms-transform: translateZ(0px);
  -ms-filter: "progid:DXImageTransform.Microsoft.Shadow(Strength=2, Direction=135, Color=#b3b3b3)";
  filter: progid:DXImageTransform.Microsoft.Shadow(Strength=2, Direction=135, Color=#b3b3b3);
  /*IE 5.5-7*/
  transition: transform 0.5s ease-in-out;
  /* -webkit-transition:transform 0.5s ease-in-out; */
  -moz-transition: transform 0.5s ease-in-out;
  -o-transition: transform 0.5s ease-in-out;
  -ms-transition: transform 0.5s ease-in-out;
}

body.page-news .apsc-theme-3 .apsc-each-profile, body.node-type-tep-blogs .apsc-theme-3 .apsc-each-profile, body.page-guides .apsc-theme-3 .apsc-each-profile, body.page-edgeproptv .apsc-theme-3 .apsc-each-profile, body.page-pullout .apsc-theme-3 .apsc-each-profile, body.page-events .apsc-theme-3 .apsc-each-profile, body.page-event .apsc-theme-3 .apsc-each-profile, body.page-tags .apsc-theme-3 .apsc-each-profile, body.page-node-29 .apsc-theme-3 .apsc-each-profile, body.page-node-34 .apsc-theme-3 .apsc-each-profile, body.page-content .apsc-theme-3 .apsc-each-profile {
  float: left;
  margin: 5px;
}

body.page-news .apsc-theme-3 .apsc-each-profile a, body.node-type-tep-blogs .apsc-theme-3 .apsc-each-profile a, body.page-guides .apsc-theme-3 .apsc-each-profile a, body.page-edgeproptv .apsc-theme-3 .apsc-each-profile a, body.page-pullout .apsc-theme-3 .apsc-each-profile a, body.page-events .apsc-theme-3 .apsc-each-profile a, body.page-event .apsc-theme-3 .apsc-each-profile a, body.page-tags .apsc-theme-3 .apsc-each-profile a, body.page-node-29 .apsc-theme-3 .apsc-each-profile a, body.page-node-34 .apsc-theme-3 .apsc-each-profile a, body.page-content .apsc-theme-3 .apsc-each-profile a {
  border-radius: 4px;
  color: #fff;
  display: block;
  font-weight: normal;
  padding: 1px;
  position: relative;
  text-shadow: none;
}

body.page-news .apsc-theme-3 .social-icon, body.node-type-tep-blogs .apsc-theme-3 .social-icon, body.page-guides .apsc-theme-3 .social-icon, body.page-edgeproptv .apsc-theme-3 .social-icon, body.page-pullout .apsc-theme-3 .social-icon, body.page-events .apsc-theme-3 .social-icon, body.page-event .apsc-theme-3 .social-icon, body.page-tags .apsc-theme-3 .social-icon, body.page-node-29 .apsc-theme-3 .social-icon, body.page-node-34 .apsc-theme-3 .social-icon, body.page-content .apsc-theme-3 .social-icon {
  display: table-cell;
  min-width: 97px;
  padding: 0 0 0 4px;
  vertical-align: middle;
  position: relative;
  transition: all 0.5s ease-in-out;
  -webkit-transition: all 0.5s ease-in-out;
  -moz-transition: all 0.5s ease-in-out;
  -o-transition: all 0.5s ease-in-out;
  -ms-transition: all 0.5s ease-in-out;
}

body.page-news .apsc-theme-3 .social-icon .fa, body.node-type-tep-blogs .apsc-theme-3 .social-icon .fa, body.page-guides .apsc-theme-3 .social-icon .fa, body.page-edgeproptv .apsc-theme-3 .social-icon .fa, body.page-pullout .apsc-theme-3 .social-icon .fa, body.page-events .apsc-theme-3 .social-icon .fa, body.page-event .apsc-theme-3 .social-icon .fa, body.page-tags .apsc-theme-3 .social-icon .fa, body.page-node-29 .apsc-theme-3 .social-icon .fa, body.page-node-34 .apsc-theme-3 .social-icon .fa, body.page-content .apsc-theme-3 .social-icon .fa {
  display: table-cell;
  font-size: 18px;
  vertical-align: middle;
}

body.page-news .apsc-theme-3 .apsc-count, body.node-type-tep-blogs .apsc-theme-3 .apsc-count, body.page-guides .apsc-theme-3 .apsc-count, body.page-edgeproptv .apsc-theme-3 .apsc-count, body.page-pullout .apsc-theme-3 .apsc-count, body.page-events .apsc-theme-3 .apsc-count, body.page-event .apsc-theme-3 .apsc-count, body.page-tags .apsc-theme-3 .apsc-count, body.page-node-29 .apsc-theme-3 .apsc-count, body.page-node-34 .apsc-theme-3 .apsc-count, body.page-content .apsc-theme-3 .apsc-count {
  background-color: #fff;
  border-bottom-right-radius: 4px;
  border-top-right-radius: 4px;
  color: #666666;
  display: table-cell;
  font-size: 16px;
  min-width: 52px;
  padding: 3px 10px;
  text-align: center;
  position: relative;
  opacity: 1;
  transition: all 0.5s ease-in-out;
  -webkit-transition: all 0.5s ease-in-out;
  -moz-transition: all 0.5s ease-in-out;
  -o-transition: all 0.5s ease-in-out;
  -ms-transition: all 0.5s ease-in-out;
}

body.page-news .apsc-theme-3 .apsc-count:before, body.node-type-tep-blogs .apsc-theme-3 .apsc-count:before, body.page-guides .apsc-theme-3 .apsc-count:before, body.page-edgeproptv .apsc-theme-3 .apsc-count:before, body.page-pullout .apsc-theme-3 .apsc-count:before, body.page-events .apsc-theme-3 .apsc-count:before, body.page-event .apsc-theme-3 .apsc-count:before, body.page-tags .apsc-theme-3 .apsc-count:before, body.page-node-29 .apsc-theme-3 .apsc-count:before, body.page-node-34 .apsc-theme-3 .apsc-count:before, body.page-content .apsc-theme-3 .apsc-count:before {
  border-bottom: 4px solid transparent;
  border-right: 4px solid #fff;
  border-top: 4px solid transparent;
  content: "";
  left: -4px;
  margin-top: -3px;
  position: absolute;
  top: 50%;
}

body.page-news .apsc-theme-3 .media-name, body.node-type-tep-blogs .apsc-theme-3 .media-name, body.page-guides .apsc-theme-3 .media-name, body.page-edgeproptv .apsc-theme-3 .media-name, body.page-pullout .apsc-theme-3 .media-name, body.page-events .apsc-theme-3 .media-name, body.page-event .apsc-theme-3 .media-name, body.page-tags .apsc-theme-3 .media-name, body.page-node-29 .apsc-theme-3 .media-name, body.page-node-34 .apsc-theme-3 .media-name, body.page-content .apsc-theme-3 .media-name {
  display: table-cell;
  font-size: 14px;
  padding: 0 13px 0 5px;
  text-transform: capitalize;
  vertical-align: middle;
  min-width: 77px;
}

body.page-news .apsc-theme-3 .apsc-each-profile a:hover .social-icon, body.node-type-tep-blogs .apsc-theme-3 .apsc-each-profile a:hover .social-icon, body.page-guides .apsc-theme-3 .apsc-each-profile a:hover .social-icon, body.page-edgeproptv .apsc-theme-3 .apsc-each-profile a:hover .social-icon, body.page-pullout .apsc-theme-3 .apsc-each-profile a:hover .social-icon, body.page-events .apsc-theme-3 .apsc-each-profile a:hover .social-icon, body.page-event .apsc-theme-3 .apsc-each-profile a:hover .social-icon, body.page-tags .apsc-theme-3 .apsc-each-profile a:hover .social-icon, body.page-node-29 .apsc-theme-3 .apsc-each-profile a:hover .social-icon, body.page-node-34 .apsc-theme-3 .apsc-each-profile a:hover .social-icon, body.page-content .apsc-theme-3 .apsc-each-profile a:hover .social-icon {
  background-color: #fff;
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
}

body.page-news .apsc-theme-3 .apsc-each-profile a:hover .apsc-count, body.node-type-tep-blogs .apsc-theme-3 .apsc-each-profile a:hover .apsc-count, body.page-guides .apsc-theme-3 .apsc-each-profile a:hover .apsc-count, body.page-edgeproptv .apsc-theme-3 .apsc-each-profile a:hover .apsc-count, body.page-pullout .apsc-theme-3 .apsc-each-profile a:hover .apsc-count, body.page-events .apsc-theme-3 .apsc-each-profile a:hover .apsc-count, body.page-event .apsc-theme-3 .apsc-each-profile a:hover .apsc-count, body.page-tags .apsc-theme-3 .apsc-each-profile a:hover .apsc-count, body.page-node-29 .apsc-theme-3 .apsc-each-profile a:hover .apsc-count, body.page-node-34 .apsc-theme-3 .apsc-each-profile a:hover .apsc-count, body.page-content .apsc-theme-3 .apsc-each-profile a:hover .apsc-count {
  color: #fff;
}

body.page-news .apsc-theme-3 .apsc-each-profile a:hover .social-icon:after, body.node-type-tep-blogs .apsc-theme-3 .apsc-each-profile a:hover .social-icon:after, body.page-guides .apsc-theme-3 .apsc-each-profile a:hover .social-icon:after, body.page-edgeproptv .apsc-theme-3 .apsc-each-profile a:hover .social-icon:after, body.page-pullout .apsc-theme-3 .apsc-each-profile a:hover .social-icon:after, body.page-events .apsc-theme-3 .apsc-each-profile a:hover .social-icon:after, body.page-event .apsc-theme-3 .apsc-each-profile a:hover .social-icon:after, body.page-tags .apsc-theme-3 .apsc-each-profile a:hover .social-icon:after, body.page-node-29 .apsc-theme-3 .apsc-each-profile a:hover .social-icon:after, body.page-node-34 .apsc-theme-3 .apsc-each-profile a:hover .social-icon:after, body.page-content .apsc-theme-3 .apsc-each-profile a:hover .social-icon:after {
  border-bottom: 4px solid transparent;
  border-left: 4px solid #fff;
  border-top: 4px solid transparent;
  content: "";
  margin-top: -3px;
  position: absolute;
  right: -4px;
  top: 50%;
  z-index: 2;
}

body.page-news .apsc-theme-3 .apsc-media-type, body.node-type-tep-blogs .apsc-theme-3 .apsc-media-type, body.page-guides .apsc-theme-3 .apsc-media-type, body.page-edgeproptv .apsc-theme-3 .apsc-media-type, body.page-pullout .apsc-theme-3 .apsc-media-type, body.page-events .apsc-theme-3 .apsc-media-type, body.page-event .apsc-theme-3 .apsc-media-type, body.page-tags .apsc-theme-3 .apsc-media-type, body.page-node-29 .apsc-theme-3 .apsc-media-type, body.page-node-34 .apsc-theme-3 .apsc-media-type, body.page-content .apsc-theme-3 .apsc-media-type {
  display: none;
}

body.page-news .apsc-theme-3 .apsc-each-profile a.apsc-youtube-icon:hover .social-icon, body.node-type-tep-blogs .apsc-theme-3 .apsc-each-profile a.apsc-youtube-icon:hover .social-icon, body.page-guides .apsc-theme-3 .apsc-each-profile a.apsc-youtube-icon:hover .social-icon, body.page-edgeproptv .apsc-theme-3 .apsc-each-profile a.apsc-youtube-icon:hover .social-icon, body.page-pullout .apsc-theme-3 .apsc-each-profile a.apsc-youtube-icon:hover .social-icon, body.page-events .apsc-theme-3 .apsc-each-profile a.apsc-youtube-icon:hover .social-icon, body.page-event .apsc-theme-3 .apsc-each-profile a.apsc-youtube-icon:hover .social-icon, body.page-tags .apsc-theme-3 .apsc-each-profile a.apsc-youtube-icon:hover .social-icon, body.page-node-29 .apsc-theme-3 .apsc-each-profile a.apsc-youtube-icon:hover .social-icon, body.page-node-34 .apsc-theme-3 .apsc-each-profile a.apsc-youtube-icon:hover .social-icon, body.page-content .apsc-theme-3 .apsc-each-profile a.apsc-youtube-icon:hover .social-icon {
  color: #e52d27;
}

body.page-news .apsc-theme-3 .apsc-each-profile a.apsc-youtube-icon:hover .apsc-count, body.node-type-tep-blogs .apsc-theme-3 .apsc-each-profile a.apsc-youtube-icon:hover .apsc-count, body.page-guides .apsc-theme-3 .apsc-each-profile a.apsc-youtube-icon:hover .apsc-count, body.page-edgeproptv .apsc-theme-3 .apsc-each-profile a.apsc-youtube-icon:hover .apsc-count, body.page-pullout .apsc-theme-3 .apsc-each-profile a.apsc-youtube-icon:hover .apsc-count, body.page-events .apsc-theme-3 .apsc-each-profile a.apsc-youtube-icon:hover .apsc-count, body.page-event .apsc-theme-3 .apsc-each-profile a.apsc-youtube-icon:hover .apsc-count, body.page-tags .apsc-theme-3 .apsc-each-profile a.apsc-youtube-icon:hover .apsc-count, body.page-node-29 .apsc-theme-3 .apsc-each-profile a.apsc-youtube-icon:hover .apsc-count, body.page-node-34 .apsc-theme-3 .apsc-each-profile a.apsc-youtube-icon:hover .apsc-count, body.page-content .apsc-theme-3 .apsc-each-profile a.apsc-youtube-icon:hover .apsc-count {
  background-color: #e52d27;
}

body.page-news .apsc-theme-3 .apsc-each-profile a.apsc-soundcloud-icon:hover .social-icon, body.node-type-tep-blogs .apsc-theme-3 .apsc-each-profile a.apsc-soundcloud-icon:hover .social-icon, body.page-guides .apsc-theme-3 .apsc-each-profile a.apsc-soundcloud-icon:hover .social-icon, body.page-edgeproptv .apsc-theme-3 .apsc-each-profile a.apsc-soundcloud-icon:hover .social-icon, body.page-pullout .apsc-theme-3 .apsc-each-profile a.apsc-soundcloud-icon:hover .social-icon, body.page-events .apsc-theme-3 .apsc-each-profile a.apsc-soundcloud-icon:hover .social-icon, body.page-event .apsc-theme-3 .apsc-each-profile a.apsc-soundcloud-icon:hover .social-icon, body.page-tags .apsc-theme-3 .apsc-each-profile a.apsc-soundcloud-icon:hover .social-icon, body.page-node-29 .apsc-theme-3 .apsc-each-profile a.apsc-soundcloud-icon:hover .social-icon, body.page-node-34 .apsc-theme-3 .apsc-each-profile a.apsc-soundcloud-icon:hover .social-icon, body.page-content .apsc-theme-3 .apsc-each-profile a.apsc-soundcloud-icon:hover .social-icon {
  color: #ff8800;
}

body.page-news .apsc-theme-3 .apsc-each-profile a.apsc-soundcloud-icon:hover .apsc-count, body.node-type-tep-blogs .apsc-theme-3 .apsc-each-profile a.apsc-soundcloud-icon:hover .apsc-count, body.page-guides .apsc-theme-3 .apsc-each-profile a.apsc-soundcloud-icon:hover .apsc-count, body.page-edgeproptv .apsc-theme-3 .apsc-each-profile a.apsc-soundcloud-icon:hover .apsc-count, body.page-pullout .apsc-theme-3 .apsc-each-profile a.apsc-soundcloud-icon:hover .apsc-count, body.page-events .apsc-theme-3 .apsc-each-profile a.apsc-soundcloud-icon:hover .apsc-count, body.page-event .apsc-theme-3 .apsc-each-profile a.apsc-soundcloud-icon:hover .apsc-count, body.page-tags .apsc-theme-3 .apsc-each-profile a.apsc-soundcloud-icon:hover .apsc-count, body.page-node-29 .apsc-theme-3 .apsc-each-profile a.apsc-soundcloud-icon:hover .apsc-count, body.page-node-34 .apsc-theme-3 .apsc-each-profile a.apsc-soundcloud-icon:hover .apsc-count, body.page-content .apsc-theme-3 .apsc-each-profile a.apsc-soundcloud-icon:hover .apsc-count {
  background-color: #ff8800;
}

body.page-news .apsc-theme-3 .apsc-each-profile a.apsc-dribble-icon:hover .social-icon, body.node-type-tep-blogs .apsc-theme-3 .apsc-each-profile a.apsc-dribble-icon:hover .social-icon, body.page-guides .apsc-theme-3 .apsc-each-profile a.apsc-dribble-icon:hover .social-icon, body.page-edgeproptv .apsc-theme-3 .apsc-each-profile a.apsc-dribble-icon:hover .social-icon, body.page-pullout .apsc-theme-3 .apsc-each-profile a.apsc-dribble-icon:hover .social-icon, body.page-events .apsc-theme-3 .apsc-each-profile a.apsc-dribble-icon:hover .social-icon, body.page-event .apsc-theme-3 .apsc-each-profile a.apsc-dribble-icon:hover .social-icon, body.page-tags .apsc-theme-3 .apsc-each-profile a.apsc-dribble-icon:hover .social-icon, body.page-node-29 .apsc-theme-3 .apsc-each-profile a.apsc-dribble-icon:hover .social-icon, body.page-node-34 .apsc-theme-3 .apsc-each-profile a.apsc-dribble-icon:hover .social-icon, body.page-content .apsc-theme-3 .apsc-each-profile a.apsc-dribble-icon:hover .social-icon {
  color: #ea4c89;
}

body.page-news .apsc-theme-3 .apsc-each-profile a.apsc-dribble-icon:hover .apsc-count, body.node-type-tep-blogs .apsc-theme-3 .apsc-each-profile a.apsc-dribble-icon:hover .apsc-count, body.page-guides .apsc-theme-3 .apsc-each-profile a.apsc-dribble-icon:hover .apsc-count, body.page-edgeproptv .apsc-theme-3 .apsc-each-profile a.apsc-dribble-icon:hover .apsc-count, body.page-pullout .apsc-theme-3 .apsc-each-profile a.apsc-dribble-icon:hover .apsc-count, body.page-events .apsc-theme-3 .apsc-each-profile a.apsc-dribble-icon:hover .apsc-count, body.page-event .apsc-theme-3 .apsc-each-profile a.apsc-dribble-icon:hover .apsc-count, body.page-tags .apsc-theme-3 .apsc-each-profile a.apsc-dribble-icon:hover .apsc-count, body.page-node-29 .apsc-theme-3 .apsc-each-profile a.apsc-dribble-icon:hover .apsc-count, body.page-node-34 .apsc-theme-3 .apsc-each-profile a.apsc-dribble-icon:hover .apsc-count, body.page-content .apsc-theme-3 .apsc-each-profile a.apsc-dribble-icon:hover .apsc-count {
  background-color: #ea4c89;
}

body.page-news .apsc-theme-3 .apsc-each-profile a.apsc-comment-icon:hover .social-icon, body.node-type-tep-blogs .apsc-theme-3 .apsc-each-profile a.apsc-comment-icon:hover .social-icon, body.page-guides .apsc-theme-3 .apsc-each-profile a.apsc-comment-icon:hover .social-icon, body.page-edgeproptv .apsc-theme-3 .apsc-each-profile a.apsc-comment-icon:hover .social-icon, body.page-pullout .apsc-theme-3 .apsc-each-profile a.apsc-comment-icon:hover .social-icon, body.page-events .apsc-theme-3 .apsc-each-profile a.apsc-comment-icon:hover .social-icon, body.page-event .apsc-theme-3 .apsc-each-profile a.apsc-comment-icon:hover .social-icon, body.page-tags .apsc-theme-3 .apsc-each-profile a.apsc-comment-icon:hover .social-icon, body.page-node-29 .apsc-theme-3 .apsc-each-profile a.apsc-comment-icon:hover .social-icon, body.page-node-34 .apsc-theme-3 .apsc-each-profile a.apsc-comment-icon:hover .social-icon, body.page-content .apsc-theme-3 .apsc-each-profile a.apsc-comment-icon:hover .social-icon {
  color: #464646;
}

body.page-news .apsc-theme-3 .apsc-each-profile a.apsc-comment-icon:hover .apsc-count, body.node-type-tep-blogs .apsc-theme-3 .apsc-each-profile a.apsc-comment-icon:hover .apsc-count, body.page-guides .apsc-theme-3 .apsc-each-profile a.apsc-comment-icon:hover .apsc-count, body.page-edgeproptv .apsc-theme-3 .apsc-each-profile a.apsc-comment-icon:hover .apsc-count, body.page-pullout .apsc-theme-3 .apsc-each-profile a.apsc-comment-icon:hover .apsc-count, body.page-events .apsc-theme-3 .apsc-each-profile a.apsc-comment-icon:hover .apsc-count, body.page-event .apsc-theme-3 .apsc-each-profile a.apsc-comment-icon:hover .apsc-count, body.page-tags .apsc-theme-3 .apsc-each-profile a.apsc-comment-icon:hover .apsc-count, body.page-node-29 .apsc-theme-3 .apsc-each-profile a.apsc-comment-icon:hover .apsc-count, body.page-node-34 .apsc-theme-3 .apsc-each-profile a.apsc-comment-icon:hover .apsc-count, body.page-content .apsc-theme-3 .apsc-each-profile a.apsc-comment-icon:hover .apsc-count {
  background-color: #464646;
}

body.page-news .apsc-theme-3 .apsc-each-profile a.apsc-twitter-icon:hover .social-icon, body.node-type-tep-blogs .apsc-theme-3 .apsc-each-profile a.apsc-twitter-icon:hover .social-icon, body.page-guides .apsc-theme-3 .apsc-each-profile a.apsc-twitter-icon:hover .social-icon, body.page-edgeproptv .apsc-theme-3 .apsc-each-profile a.apsc-twitter-icon:hover .social-icon, body.page-pullout .apsc-theme-3 .apsc-each-profile a.apsc-twitter-icon:hover .social-icon, body.page-events .apsc-theme-3 .apsc-each-profile a.apsc-twitter-icon:hover .social-icon, body.page-event .apsc-theme-3 .apsc-each-profile a.apsc-twitter-icon:hover .social-icon, body.page-tags .apsc-theme-3 .apsc-each-profile a.apsc-twitter-icon:hover .social-icon, body.page-node-29 .apsc-theme-3 .apsc-each-profile a.apsc-twitter-icon:hover .social-icon, body.page-node-34 .apsc-theme-3 .apsc-each-profile a.apsc-twitter-icon:hover .social-icon, body.page-content .apsc-theme-3 .apsc-each-profile a.apsc-twitter-icon:hover .social-icon {
  color: #1dcaff;
}

body.page-news .apsc-theme-3 .apsc-each-profile a.apsc-twitter-icon:hover .apsc-count, body.node-type-tep-blogs .apsc-theme-3 .apsc-each-profile a.apsc-twitter-icon:hover .apsc-count, body.page-guides .apsc-theme-3 .apsc-each-profile a.apsc-twitter-icon:hover .apsc-count, body.page-edgeproptv .apsc-theme-3 .apsc-each-profile a.apsc-twitter-icon:hover .apsc-count, body.page-pullout .apsc-theme-3 .apsc-each-profile a.apsc-twitter-icon:hover .apsc-count, body.page-events .apsc-theme-3 .apsc-each-profile a.apsc-twitter-icon:hover .apsc-count, body.page-event .apsc-theme-3 .apsc-each-profile a.apsc-twitter-icon:hover .apsc-count, body.page-tags .apsc-theme-3 .apsc-each-profile a.apsc-twitter-icon:hover .apsc-count, body.page-node-29 .apsc-theme-3 .apsc-each-profile a.apsc-twitter-icon:hover .apsc-count, body.page-node-34 .apsc-theme-3 .apsc-each-profile a.apsc-twitter-icon:hover .apsc-count, body.page-content .apsc-theme-3 .apsc-each-profile a.apsc-twitter-icon:hover .apsc-count {
  background-color: #1dcaff;
}

body.page-news .apsc-theme-3 .apsc-each-profile a.apsc-instagram-icon:hover .social-icon, body.node-type-tep-blogs .apsc-theme-3 .apsc-each-profile a.apsc-instagram-icon:hover .social-icon, body.page-guides .apsc-theme-3 .apsc-each-profile a.apsc-instagram-icon:hover .social-icon, body.page-edgeproptv .apsc-theme-3 .apsc-each-profile a.apsc-instagram-icon:hover .social-icon, body.page-pullout .apsc-theme-3 .apsc-each-profile a.apsc-instagram-icon:hover .social-icon, body.page-events .apsc-theme-3 .apsc-each-profile a.apsc-instagram-icon:hover .social-icon, body.page-event .apsc-theme-3 .apsc-each-profile a.apsc-instagram-icon:hover .social-icon, body.page-tags .apsc-theme-3 .apsc-each-profile a.apsc-instagram-icon:hover .social-icon, body.page-node-29 .apsc-theme-3 .apsc-each-profile a.apsc-instagram-icon:hover .social-icon, body.page-node-34 .apsc-theme-3 .apsc-each-profile a.apsc-instagram-icon:hover .social-icon, body.page-content .apsc-theme-3 .apsc-each-profile a.apsc-instagram-icon:hover .social-icon {
  color: #3f729b;
}

body.page-news .apsc-theme-3 .apsc-each-profile a.apsc-instagram-icon:hover .apsc-count, body.node-type-tep-blogs .apsc-theme-3 .apsc-each-profile a.apsc-instagram-icon:hover .apsc-count, body.page-guides .apsc-theme-3 .apsc-each-profile a.apsc-instagram-icon:hover .apsc-count, body.page-edgeproptv .apsc-theme-3 .apsc-each-profile a.apsc-instagram-icon:hover .apsc-count, body.page-pullout .apsc-theme-3 .apsc-each-profile a.apsc-instagram-icon:hover .apsc-count, body.page-events .apsc-theme-3 .apsc-each-profile a.apsc-instagram-icon:hover .apsc-count, body.page-event .apsc-theme-3 .apsc-each-profile a.apsc-instagram-icon:hover .apsc-count, body.page-tags .apsc-theme-3 .apsc-each-profile a.apsc-instagram-icon:hover .apsc-count, body.page-node-29 .apsc-theme-3 .apsc-each-profile a.apsc-instagram-icon:hover .apsc-count, body.page-node-34 .apsc-theme-3 .apsc-each-profile a.apsc-instagram-icon:hover .apsc-count, body.page-content .apsc-theme-3 .apsc-each-profile a.apsc-instagram-icon:hover .apsc-count {
  background-color: #3f729b;
}

body.page-news .apsc-theme-3 .apsc-each-profile a.apsc-facebook-icon:hover .social-icon, body.node-type-tep-blogs .apsc-theme-3 .apsc-each-profile a.apsc-facebook-icon:hover .social-icon, body.page-guides .apsc-theme-3 .apsc-each-profile a.apsc-facebook-icon:hover .social-icon, body.page-edgeproptv .apsc-theme-3 .apsc-each-profile a.apsc-facebook-icon:hover .social-icon, body.page-pullout .apsc-theme-3 .apsc-each-profile a.apsc-facebook-icon:hover .social-icon, body.page-events .apsc-theme-3 .apsc-each-profile a.apsc-facebook-icon:hover .social-icon, body.page-event .apsc-theme-3 .apsc-each-profile a.apsc-facebook-icon:hover .social-icon, body.page-tags .apsc-theme-3 .apsc-each-profile a.apsc-facebook-icon:hover .social-icon, body.page-node-29 .apsc-theme-3 .apsc-each-profile a.apsc-facebook-icon:hover .social-icon, body.page-node-34 .apsc-theme-3 .apsc-each-profile a.apsc-facebook-icon:hover .social-icon, body.page-content .apsc-theme-3 .apsc-each-profile a.apsc-facebook-icon:hover .social-icon {
  color: #3b5998;
}

body.page-news .apsc-theme-3 .apsc-each-profile a.apsc-facebook-icon:hover .apsc-count, body.node-type-tep-blogs .apsc-theme-3 .apsc-each-profile a.apsc-facebook-icon:hover .apsc-count, body.page-guides .apsc-theme-3 .apsc-each-profile a.apsc-facebook-icon:hover .apsc-count, body.page-edgeproptv .apsc-theme-3 .apsc-each-profile a.apsc-facebook-icon:hover .apsc-count, body.page-pullout .apsc-theme-3 .apsc-each-profile a.apsc-facebook-icon:hover .apsc-count, body.page-events .apsc-theme-3 .apsc-each-profile a.apsc-facebook-icon:hover .apsc-count, body.page-event .apsc-theme-3 .apsc-each-profile a.apsc-facebook-icon:hover .apsc-count, body.page-tags .apsc-theme-3 .apsc-each-profile a.apsc-facebook-icon:hover .apsc-count, body.page-node-29 .apsc-theme-3 .apsc-each-profile a.apsc-facebook-icon:hover .apsc-count, body.page-node-34 .apsc-theme-3 .apsc-each-profile a.apsc-facebook-icon:hover .apsc-count, body.page-content .apsc-theme-3 .apsc-each-profile a.apsc-facebook-icon:hover .apsc-count {
  background-color: #3b5998;
}

body.page-news .apsc-theme-3 .apsc-each-profile a.apsc-google-plus-icon:hover .social-icon, body.node-type-tep-blogs .apsc-theme-3 .apsc-each-profile a.apsc-google-plus-icon:hover .social-icon, body.page-guides .apsc-theme-3 .apsc-each-profile a.apsc-google-plus-icon:hover .social-icon, body.page-edgeproptv .apsc-theme-3 .apsc-each-profile a.apsc-google-plus-icon:hover .social-icon, body.page-pullout .apsc-theme-3 .apsc-each-profile a.apsc-google-plus-icon:hover .social-icon, body.page-events .apsc-theme-3 .apsc-each-profile a.apsc-google-plus-icon:hover .social-icon, body.page-event .apsc-theme-3 .apsc-each-profile a.apsc-google-plus-icon:hover .social-icon, body.page-tags .apsc-theme-3 .apsc-each-profile a.apsc-google-plus-icon:hover .social-icon, body.page-node-29 .apsc-theme-3 .apsc-each-profile a.apsc-google-plus-icon:hover .social-icon, body.page-node-34 .apsc-theme-3 .apsc-each-profile a.apsc-google-plus-icon:hover .social-icon, body.page-content .apsc-theme-3 .apsc-each-profile a.apsc-google-plus-icon:hover .social-icon {
  color: #dd4b39;
}

body.page-news .apsc-theme-3 .apsc-each-profile a.apsc-google-plus-icon:hover .apsc-count, body.node-type-tep-blogs .apsc-theme-3 .apsc-each-profile a.apsc-google-plus-icon:hover .apsc-count, body.page-guides .apsc-theme-3 .apsc-each-profile a.apsc-google-plus-icon:hover .apsc-count, body.page-edgeproptv .apsc-theme-3 .apsc-each-profile a.apsc-google-plus-icon:hover .apsc-count, body.page-pullout .apsc-theme-3 .apsc-each-profile a.apsc-google-plus-icon:hover .apsc-count, body.page-events .apsc-theme-3 .apsc-each-profile a.apsc-google-plus-icon:hover .apsc-count, body.page-event .apsc-theme-3 .apsc-each-profile a.apsc-google-plus-icon:hover .apsc-count, body.page-tags .apsc-theme-3 .apsc-each-profile a.apsc-google-plus-icon:hover .apsc-count, body.page-node-29 .apsc-theme-3 .apsc-each-profile a.apsc-google-plus-icon:hover .apsc-count, body.page-node-34 .apsc-theme-3 .apsc-each-profile a.apsc-google-plus-icon:hover .apsc-count, body.page-content .apsc-theme-3 .apsc-each-profile a.apsc-google-plus-icon:hover .apsc-count {
  background-color: #dd4b39;
}

body.page-news .apsc-theme-3 .apsc-each-profile a.apsc-edit-icon:hover .social-icon, body.node-type-tep-blogs .apsc-theme-3 .apsc-each-profile a.apsc-edit-icon:hover .social-icon, body.page-guides .apsc-theme-3 .apsc-each-profile a.apsc-edit-icon:hover .social-icon, body.page-edgeproptv .apsc-theme-3 .apsc-each-profile a.apsc-edit-icon:hover .social-icon, body.page-pullout .apsc-theme-3 .apsc-each-profile a.apsc-edit-icon:hover .social-icon, body.page-events .apsc-theme-3 .apsc-each-profile a.apsc-edit-icon:hover .social-icon, body.page-event .apsc-theme-3 .apsc-each-profile a.apsc-edit-icon:hover .social-icon, body.page-tags .apsc-theme-3 .apsc-each-profile a.apsc-edit-icon:hover .social-icon, body.page-node-29 .apsc-theme-3 .apsc-each-profile a.apsc-edit-icon:hover .social-icon, body.page-node-34 .apsc-theme-3 .apsc-each-profile a.apsc-edit-icon:hover .social-icon, body.page-content .apsc-theme-3 .apsc-each-profile a.apsc-edit-icon:hover .social-icon {
  color: #837d7d;
}

body.page-news .apsc-theme-3 .apsc-each-profile a.apsc-edit-icon:hover .apsc-count, body.node-type-tep-blogs .apsc-theme-3 .apsc-each-profile a.apsc-edit-icon:hover .apsc-count, body.page-guides .apsc-theme-3 .apsc-each-profile a.apsc-edit-icon:hover .apsc-count, body.page-edgeproptv .apsc-theme-3 .apsc-each-profile a.apsc-edit-icon:hover .apsc-count, body.page-pullout .apsc-theme-3 .apsc-each-profile a.apsc-edit-icon:hover .apsc-count, body.page-events .apsc-theme-3 .apsc-each-profile a.apsc-edit-icon:hover .apsc-count, body.page-event .apsc-theme-3 .apsc-each-profile a.apsc-edit-icon:hover .apsc-count, body.page-tags .apsc-theme-3 .apsc-each-profile a.apsc-edit-icon:hover .apsc-count, body.page-node-29 .apsc-theme-3 .apsc-each-profile a.apsc-edit-icon:hover .apsc-count, body.page-node-34 .apsc-theme-3 .apsc-each-profile a.apsc-edit-icon:hover .apsc-count, body.page-content .apsc-theme-3 .apsc-each-profile a.apsc-edit-icon:hover .apsc-count {
  background-color: #837d7d;
}

body.page-news .apsc-theme-4 .apsc-each-profile, body.node-type-tep-blogs .apsc-theme-4 .apsc-each-profile, body.page-guides .apsc-theme-4 .apsc-each-profile, body.page-edgeproptv .apsc-theme-4 .apsc-each-profile, body.page-pullout .apsc-theme-4 .apsc-each-profile, body.page-events .apsc-theme-4 .apsc-each-profile, body.page-event .apsc-theme-4 .apsc-each-profile, body.page-tags .apsc-theme-4 .apsc-each-profile, body.page-node-29 .apsc-theme-4 .apsc-each-profile, body.page-node-34 .apsc-theme-4 .apsc-each-profile, body.page-content .apsc-theme-4 .apsc-each-profile {
  float: left;
  margin: 5px;
}

body.page-news .apsc-theme-4 .apsc-each-profile a, body.node-type-tep-blogs .apsc-theme-4 .apsc-each-profile a, body.page-guides .apsc-theme-4 .apsc-each-profile a, body.page-edgeproptv .apsc-theme-4 .apsc-each-profile a, body.page-pullout .apsc-theme-4 .apsc-each-profile a, body.page-events .apsc-theme-4 .apsc-each-profile a, body.page-event .apsc-theme-4 .apsc-each-profile a, body.page-tags .apsc-theme-4 .apsc-each-profile a, body.page-node-29 .apsc-theme-4 .apsc-each-profile a, body.page-node-34 .apsc-theme-4 .apsc-each-profile a, body.page-content .apsc-theme-4 .apsc-each-profile a {
  padding: 2px 9px 2px 6px;
  display: block;
  color: #fff;
  position: relative;
  border-radius: 4px;
}

body.page-news .apsc-theme-4 .apsc-each-profile a .fa, body.node-type-tep-blogs .apsc-theme-4 .apsc-each-profile a .fa, body.page-guides .apsc-theme-4 .apsc-each-profile a .fa, body.page-edgeproptv .apsc-theme-4 .apsc-each-profile a .fa, body.page-pullout .apsc-theme-4 .apsc-each-profile a .fa, body.page-events .apsc-theme-4 .apsc-each-profile a .fa, body.page-event .apsc-theme-4 .apsc-each-profile a .fa, body.page-tags .apsc-theme-4 .apsc-each-profile a .fa, body.page-node-29 .apsc-theme-4 .apsc-each-profile a .fa, body.page-node-34 .apsc-theme-4 .apsc-each-profile a .fa, body.page-content .apsc-theme-4 .apsc-each-profile a .fa {
  transform: translateZ(0px);
  -webkit-transform: translateZ(0px);
  -ms-transform: translateZ(0px);
  transition: all 0.5s ease-in-out;
  -webkit-transition: all 0.5s ease-in-out;
  -moz-transition: all 0.5s ease-in-out;
  -o-transition: all 0.5s ease-in-out;
  -ms-transition: all 0.5s ease-in-out;
}

body.page-news .apsc-theme-4 .apsc-each-profile a a:hover .fa, body.node-type-tep-blogs .apsc-theme-4 .apsc-each-profile a a:hover .fa, body.page-guides .apsc-theme-4 .apsc-each-profile a a:hover .fa, body.page-edgeproptv .apsc-theme-4 .apsc-each-profile a a:hover .fa, body.page-pullout .apsc-theme-4 .apsc-each-profile a a:hover .fa, body.page-events .apsc-theme-4 .apsc-each-profile a a:hover .fa, body.page-event .apsc-theme-4 .apsc-each-profile a a:hover .fa, body.page-tags .apsc-theme-4 .apsc-each-profile a a:hover .fa, body.page-node-29 .apsc-theme-4 .apsc-each-profile a a:hover .fa, body.page-node-34 .apsc-theme-4 .apsc-each-profile a a:hover .fa, body.page-content .apsc-theme-4 .apsc-each-profile a a:hover .fa {
  transform: scale(1.3) translateZ(0px);
  -webkit-transform: scale(1.3) translateZ(0px);
  -ms-transform: scale(1.3) translateZ(0px);
}

body.page-news .apsc-theme-4 .social-icon, body.node-type-tep-blogs .apsc-theme-4 .social-icon, body.page-guides .apsc-theme-4 .social-icon, body.page-edgeproptv .apsc-theme-4 .social-icon, body.page-pullout .apsc-theme-4 .social-icon, body.page-events .apsc-theme-4 .social-icon, body.page-event .apsc-theme-4 .social-icon, body.page-tags .apsc-theme-4 .social-icon, body.page-node-29 .apsc-theme-4 .social-icon, body.page-node-34 .apsc-theme-4 .social-icon, body.page-content .apsc-theme-4 .social-icon {
  display: inline-block;
  position: relative;
  transition: all 0.5s ease-in-out;
  -webkit-transition: all 0.5s ease-in-out;
  -moz-transition: all 0.5s ease-in-out;
  -o-transition: all 0.5s ease-in-out;
  -ms-transition: all 0.5s ease-in-out;
}

body.page-news .apsc-theme-4 .social-icon .fa, body.node-type-tep-blogs .apsc-theme-4 .social-icon .fa, body.page-guides .apsc-theme-4 .social-icon .fa, body.page-edgeproptv .apsc-theme-4 .social-icon .fa, body.page-pullout .apsc-theme-4 .social-icon .fa, body.page-events .apsc-theme-4 .social-icon .fa, body.page-event .apsc-theme-4 .social-icon .fa, body.page-tags .apsc-theme-4 .social-icon .fa, body.page-node-29 .apsc-theme-4 .social-icon .fa, body.page-node-34 .apsc-theme-4 .social-icon .fa, body.page-content .apsc-theme-4 .social-icon .fa {
  display: inline-block;
  font-size: 18px;
  vertical-align: middle;
}

body.page-news .apsc-theme-4 .media-name, body.node-type-tep-blogs .apsc-theme-4 .media-name, body.page-guides .apsc-theme-4 .media-name, body.page-edgeproptv .apsc-theme-4 .media-name, body.page-pullout .apsc-theme-4 .media-name, body.page-events .apsc-theme-4 .media-name, body.page-event .apsc-theme-4 .media-name, body.page-tags .apsc-theme-4 .media-name, body.page-node-29 .apsc-theme-4 .media-name, body.page-node-34 .apsc-theme-4 .media-name, body.page-content .apsc-theme-4 .media-name {
  display: inline-block;
  font-size: 14px;
  padding: 0 6px 0 5px;
  text-transform: capitalize;
}

body.page-news .apsc-theme-4 .apsc-count, body.node-type-tep-blogs .apsc-theme-4 .apsc-count, body.page-guides .apsc-theme-4 .apsc-count, body.page-edgeproptv .apsc-theme-4 .apsc-count, body.page-pullout .apsc-theme-4 .apsc-count, body.page-events .apsc-theme-4 .apsc-count, body.page-event .apsc-theme-4 .apsc-count, body.page-tags .apsc-theme-4 .apsc-count, body.page-node-29 .apsc-theme-4 .apsc-count, body.page-node-34 .apsc-theme-4 .apsc-count, body.page-content .apsc-theme-4 .apsc-count {
  border-bottom-right-radius: 4px;
  border-top-right-radius: 4px;
  color: #fff;
  display: inline-block;
  font-size: 14px;
  text-align: center;
  position: relative;
  font-family: 'MyriadPro-Bold';
  transition: all 0.5s ease-in-out;
  -webkit-transition: all 0.5s ease-in-out;
  -moz-transition: all 0.5s ease-in-out;
  -o-transition: all 0.5s ease-in-out;
  -ms-transition: all 0.5s ease-in-out;
}

body.page-news .apsc-theme-4 .apsc-media-type, body.node-type-tep-blogs .apsc-theme-4 .apsc-media-type, body.page-guides .apsc-theme-4 .apsc-media-type, body.page-edgeproptv .apsc-theme-4 .apsc-media-type, body.page-pullout .apsc-theme-4 .apsc-media-type, body.page-events .apsc-theme-4 .apsc-media-type, body.page-event .apsc-theme-4 .apsc-media-type, body.page-tags .apsc-theme-4 .apsc-media-type, body.page-node-29 .apsc-theme-4 .apsc-media-type, body.page-node-34 .apsc-theme-4 .apsc-media-type, body.page-content .apsc-theme-4 .apsc-media-type {
  display: none;
}

body.page-news .entry-content .apsc-each-profile a, body.node-type-tep-blogs .entry-content .apsc-each-profile a, body.page-guides .entry-content .apsc-each-profile a, body.page-edgeproptv .entry-content .apsc-each-profile a, body.page-pullout .entry-content .apsc-each-profile a, body.page-events .entry-content .apsc-each-profile a, body.page-event .entry-content .apsc-each-profile a, body.page-tags .entry-content .apsc-each-profile a, body.page-node-29 .entry-content .apsc-each-profile a, body.page-node-34 .entry-content .apsc-each-profile a, body.page-content .entry-content .apsc-each-profile a {
  border: none;
}

body.page-news .social-icon, body.node-type-tep-blogs .social-icon, body.page-guides .social-icon, body.page-edgeproptv .social-icon, body.page-pullout .social-icon, body.page-events .social-icon, body.page-event .social-icon, body.page-tags .social-icon, body.page-node-29 .social-icon, body.page-node-34 .social-icon, body.page-content .social-icon {
  display: block;
}

body.page-news .apsc-theme-5 .apsc-each-profile, body.node-type-tep-blogs .apsc-theme-5 .apsc-each-profile, body.page-guides .apsc-theme-5 .apsc-each-profile, body.page-edgeproptv .apsc-theme-5 .apsc-each-profile, body.page-pullout .apsc-theme-5 .apsc-each-profile, body.page-events .apsc-theme-5 .apsc-each-profile, body.page-event .apsc-theme-5 .apsc-each-profile, body.page-tags .apsc-theme-5 .apsc-each-profile, body.page-node-29 .apsc-theme-5 .apsc-each-profile, body.page-node-34 .apsc-theme-5 .apsc-each-profile, body.page-content .apsc-theme-5 .apsc-each-profile {
  min-height: 60px;
  text-align: center;
  width: 60px;
  margin: 5px;
}

body.page-news .apsc-theme-5 .apsc-each-profile a, body.node-type-tep-blogs .apsc-theme-5 .apsc-each-profile a, body.page-guides .apsc-theme-5 .apsc-each-profile a, body.page-edgeproptv .apsc-theme-5 .apsc-each-profile a, body.page-pullout .apsc-theme-5 .apsc-each-profile a, body.page-events .apsc-theme-5 .apsc-each-profile a, body.page-event .apsc-theme-5 .apsc-each-profile a, body.page-tags .apsc-theme-5 .apsc-each-profile a, body.page-node-29 .apsc-theme-5 .apsc-each-profile a, body.page-node-34 .apsc-theme-5 .apsc-each-profile a, body.page-content .apsc-theme-5 .apsc-each-profile a {
  padding: 6px;
  display: block;
  color: #fff;
  position: relative;
  border-radius: 5px;
  transform: translateZ(0px);
  -webkit-transform: translateZ(0px);
  -ms-transform: translateZ(0px);
  -ms-filter: "progid:DXImageTransform.Microsoft.Shadow(Strength=2, Direction=135, Color=#b3b3b3)";
  /*IE 8*/
  -moz-box-shadow: 1px 1px 2px #b3b3b3;
  /*FF 3.5+*/
  -webkit-box-shadow: 1px 1px 2px #b3b3b3;
  /*Saf3-4, Chrome, iOS 4.0.2-4.2, Android 2.3+*/
  box-shadow: 1px 1px 2px #b3b3b3;
  /* FF3.5+, Opera 9+, Saf1+, Chrome, IE10 */
  filter: progid:DXImageTransform.Microsoft.Shadow(Strength=2, Direction=135, Color=#b3b3b3);
  /*IE 5.5-7*/
  transition: all 0.5s ease-in-out;
  -webkit-transition: all 0.5s ease-in-out;
  -moz-transition: all 0.5s ease-in-out;
  -o-transition: all 0.5s ease-in-out;
  -ms-transition: all 0.5s ease-in-out;
}

body.page-news .apsc-theme-5 .apsc-count, body.node-type-tep-blogs .apsc-theme-5 .apsc-count, body.page-guides .apsc-theme-5 .apsc-count, body.page-edgeproptv .apsc-theme-5 .apsc-count, body.page-pullout .apsc-theme-5 .apsc-count, body.page-events .apsc-theme-5 .apsc-count, body.page-event .apsc-theme-5 .apsc-count, body.page-tags .apsc-theme-5 .apsc-count, body.page-node-29 .apsc-theme-5 .apsc-count, body.page-node-34 .apsc-theme-5 .apsc-count, body.page-content .apsc-theme-5 .apsc-count {
  font-size: 12px;
  display: block;
  line-height: 12px;
  padding: 3px 0 0;
}

body.page-news .apsc-theme-5 .social-icon, body.node-type-tep-blogs .apsc-theme-5 .social-icon, body.page-guides .apsc-theme-5 .social-icon, body.page-edgeproptv .apsc-theme-5 .social-icon, body.page-pullout .apsc-theme-5 .social-icon, body.page-events .apsc-theme-5 .social-icon, body.page-event .apsc-theme-5 .social-icon, body.page-tags .apsc-theme-5 .social-icon, body.page-node-29 .apsc-theme-5 .social-icon, body.page-node-34 .apsc-theme-5 .social-icon, body.page-content .apsc-theme-5 .social-icon {
  height: 34px;
}

body.page-news .apsc-theme-5 .social-icon .fa, body.node-type-tep-blogs .apsc-theme-5 .social-icon .fa, body.page-guides .apsc-theme-5 .social-icon .fa, body.page-edgeproptv .apsc-theme-5 .social-icon .fa, body.page-pullout .apsc-theme-5 .social-icon .fa, body.page-events .apsc-theme-5 .social-icon .fa, body.page-event .apsc-theme-5 .social-icon .fa, body.page-tags .apsc-theme-5 .social-icon .fa, body.page-node-29 .apsc-theme-5 .social-icon .fa, body.page-node-34 .apsc-theme-5 .social-icon .fa, body.page-content .apsc-theme-5 .social-icon .fa {
  font-size: 34px;
}

body.page-news .apsc-theme-5 .media-name, body.node-type-tep-blogs .apsc-theme-5 .media-name, body.page-guides .apsc-theme-5 .media-name, body.page-edgeproptv .apsc-theme-5 .media-name, body.page-pullout .apsc-theme-5 .media-name, body.page-events .apsc-theme-5 .media-name, body.page-event .apsc-theme-5 .media-name, body.page-tags .apsc-theme-5 .media-name, body.page-node-29 .apsc-theme-5 .media-name, body.page-node-34 .apsc-theme-5 .media-name, body.page-content .apsc-theme-5 .media-name {
  display: none;
}

body.page-news .apsc-theme-5 .apsc-media-type, body.node-type-tep-blogs .apsc-theme-5 .apsc-media-type, body.page-guides .apsc-theme-5 .apsc-media-type, body.page-edgeproptv .apsc-theme-5 .apsc-media-type, body.page-pullout .apsc-theme-5 .apsc-media-type, body.page-events .apsc-theme-5 .apsc-media-type, body.page-event .apsc-theme-5 .apsc-media-type, body.page-tags .apsc-theme-5 .apsc-media-type, body.page-node-29 .apsc-theme-5 .apsc-media-type, body.page-node-34 .apsc-theme-5 .apsc-media-type, body.page-content .apsc-theme-5 .apsc-media-type {
  display: none;
}

body.page-news .apsc-theme-5 .apsc-each-profile a:hover, body.node-type-tep-blogs .apsc-theme-5 .apsc-each-profile a:hover, body.page-guides .apsc-theme-5 .apsc-each-profile a:hover, body.page-edgeproptv .apsc-theme-5 .apsc-each-profile a:hover, body.page-pullout .apsc-theme-5 .apsc-each-profile a:hover, body.page-events .apsc-theme-5 .apsc-each-profile a:hover, body.page-event .apsc-theme-5 .apsc-each-profile a:hover, body.page-tags .apsc-theme-5 .apsc-each-profile a:hover, body.page-node-29 .apsc-theme-5 .apsc-each-profile a:hover, body.page-node-34 .apsc-theme-5 .apsc-each-profile a:hover, body.page-content .apsc-theme-5 .apsc-each-profile a:hover {
  box-shadow: 0 10px 10px -10px rgba(0, 0, 0, 0.5);
  transform: scale(0.8);
  -webkit-transform: scale(0.8);
  -ms-transform: scale(0.8);
}

@media screen and (max-width: 479px) {
  body.page-news .apsc-theme-4 .apsc-each-profile a,
  body.page-news .apsc-theme-3 .apsc-each-profile a, body.node-type-tep-blogs .apsc-theme-4 .apsc-each-profile a,
  body.node-type-tep-blogs .apsc-theme-3 .apsc-each-profile a, body.page-guides .apsc-theme-4 .apsc-each-profile a,
  body.page-guides .apsc-theme-3 .apsc-each-profile a, body.page-edgeproptv .apsc-theme-4 .apsc-each-profile a,
  body.page-edgeproptv .apsc-theme-3 .apsc-each-profile a, body.page-pullout .apsc-theme-4 .apsc-each-profile a,
  body.page-pullout .apsc-theme-3 .apsc-each-profile a, body.page-events .apsc-theme-4 .apsc-each-profile a,
  body.page-events .apsc-theme-3 .apsc-each-profile a, body.page-event .apsc-theme-4 .apsc-each-profile a,
  body.page-event .apsc-theme-3 .apsc-each-profile a, body.page-tags .apsc-theme-4 .apsc-each-profile a,
  body.page-tags .apsc-theme-3 .apsc-each-profile a, body.page-node-29 .apsc-theme-4 .apsc-each-profile a,
  body.page-node-29 .apsc-theme-3 .apsc-each-profile a, body.page-node-34 .apsc-theme-4 .apsc-each-profile a,
  body.page-node-34 .apsc-theme-3 .apsc-each-profile a, body.page-content .apsc-theme-4 .apsc-each-profile a,
  body.page-content .apsc-theme-3 .apsc-each-profile a {
    display: inline-block;
  }
  body.page-news .apsc-theme-4 .apsc-each-profile,
  body.page-news .apsc-theme-3 .apsc-each-profile, body.node-type-tep-blogs .apsc-theme-4 .apsc-each-profile,
  body.node-type-tep-blogs .apsc-theme-3 .apsc-each-profile, body.page-guides .apsc-theme-4 .apsc-each-profile,
  body.page-guides .apsc-theme-3 .apsc-each-profile, body.page-edgeproptv .apsc-theme-4 .apsc-each-profile,
  body.page-edgeproptv .apsc-theme-3 .apsc-each-profile, body.page-pullout .apsc-theme-4 .apsc-each-profile,
  body.page-pullout .apsc-theme-3 .apsc-each-profile, body.page-events .apsc-theme-4 .apsc-each-profile,
  body.page-events .apsc-theme-3 .apsc-each-profile, body.page-event .apsc-theme-4 .apsc-each-profile,
  body.page-event .apsc-theme-3 .apsc-each-profile, body.page-tags .apsc-theme-4 .apsc-each-profile,
  body.page-tags .apsc-theme-3 .apsc-each-profile, body.page-node-29 .apsc-theme-4 .apsc-each-profile,
  body.page-node-29 .apsc-theme-3 .apsc-each-profile, body.page-node-34 .apsc-theme-4 .apsc-each-profile,
  body.page-node-34 .apsc-theme-3 .apsc-each-profile, body.page-content .apsc-theme-4 .apsc-each-profile,
  body.page-content .apsc-theme-3 .apsc-each-profile {
    float: none;
  }
}

@media screen and (max-width: 1280px) {
  body.page-news body.page-content header.header-layout-style-1 .wrap, body.node-type-tep-blogs body.page-content header.header-layout-style-1 .wrap, body.page-guides body.page-content header.header-layout-style-1 .wrap, body.page-edgeproptv body.page-content header.header-layout-style-1 .wrap, body.page-pullout body.page-content header.header-layout-style-1 .wrap, body.page-events body.page-content header.header-layout-style-1 .wrap, body.page-event body.page-content header.header-layout-style-1 .wrap, body.page-tags body.page-content header.header-layout-style-1 .wrap, body.page-node-29 body.page-content header.header-layout-style-1 .wrap, body.page-node-34 body.page-content header.header-layout-style-1 .wrap, body.page-content body.page-content header.header-layout-style-1 .wrap {
    width: 1100px;
  }
  body.page-news body.page-content .main-content, body.node-type-tep-blogs body.page-content .main-content, body.page-guides body.page-content .main-content, body.page-edgeproptv body.page-content .main-content, body.page-pullout body.page-content .main-content, body.page-events body.page-content .main-content, body.page-event body.page-content .main-content, body.page-tags body.page-content .main-content, body.page-node-29 body.page-content .main-content, body.page-node-34 body.page-content .main-content, body.page-content body.page-content .main-content {
    padding-left: 100px;
  }
}

@media screen and (max-width: 1180px) {
  body.page-news .wrap.news-wope-theme,
  body.page-news body.page-content header.header-layout-style-1 .wrap, body.node-type-tep-blogs .wrap.news-wope-theme,
  body.node-type-tep-blogs body.page-content header.header-layout-style-1 .wrap, body.page-guides .wrap.news-wope-theme,
  body.page-guides body.page-content header.header-layout-style-1 .wrap, body.page-edgeproptv .wrap.news-wope-theme,
  body.page-edgeproptv body.page-content header.header-layout-style-1 .wrap, body.page-pullout .wrap.news-wope-theme,
  body.page-pullout body.page-content header.header-layout-style-1 .wrap, body.page-events .wrap.news-wope-theme,
  body.page-events body.page-content header.header-layout-style-1 .wrap, body.page-event .wrap.news-wope-theme,
  body.page-event body.page-content header.header-layout-style-1 .wrap, body.page-tags .wrap.news-wope-theme,
  body.page-tags body.page-content header.header-layout-style-1 .wrap, body.page-node-29 .wrap.news-wope-theme,
  body.page-node-29 body.page-content header.header-layout-style-1 .wrap, body.page-node-34 .wrap.news-wope-theme,
  body.page-node-34 body.page-content header.header-layout-style-1 .wrap, body.page-content .wrap.news-wope-theme,
  body.page-content body.page-content header.header-layout-style-1 .wrap {
    width: 960px;
  }
  body.page-news body.page-content .main-content, body.node-type-tep-blogs body.page-content .main-content, body.page-guides body.page-content .main-content, body.page-edgeproptv body.page-content .main-content, body.page-pullout body.page-content .main-content, body.page-events body.page-content .main-content, body.page-event body.page-content .main-content, body.page-tags body.page-content .main-content, body.page-node-29 body.page-content .main-content, body.page-node-34 body.page-content .main-content, body.page-content body.page-content .main-content {
    padding-left: 50px;
  }
}

@media screen and (max-width: 1000px) {
  body.page-news .wrap.news-wope-theme,
  body.page-news body.page-content header.header-layout-style-1 .wrap, body.node-type-tep-blogs .wrap.news-wope-theme,
  body.node-type-tep-blogs body.page-content header.header-layout-style-1 .wrap, body.page-guides .wrap.news-wope-theme,
  body.page-guides body.page-content header.header-layout-style-1 .wrap, body.page-edgeproptv .wrap.news-wope-theme,
  body.page-edgeproptv body.page-content header.header-layout-style-1 .wrap, body.page-pullout .wrap.news-wope-theme,
  body.page-pullout body.page-content header.header-layout-style-1 .wrap, body.page-events .wrap.news-wope-theme,
  body.page-events body.page-content header.header-layout-style-1 .wrap, body.page-event .wrap.news-wope-theme,
  body.page-event body.page-content header.header-layout-style-1 .wrap, body.page-tags .wrap.news-wope-theme,
  body.page-tags body.page-content header.header-layout-style-1 .wrap, body.page-node-29 .wrap.news-wope-theme,
  body.page-node-29 body.page-content header.header-layout-style-1 .wrap, body.page-node-34 .wrap.news-wope-theme,
  body.page-node-34 body.page-content header.header-layout-style-1 .wrap, body.page-content .wrap.news-wope-theme,
  body.page-content body.page-content header.header-layout-style-1 .wrap {
    width: 700px;
  }
  body.page-news body.page-content .main-content, body.node-type-tep-blogs body.page-content .main-content, body.page-guides body.page-content .main-content, body.page-edgeproptv body.page-content .main-content, body.page-pullout body.page-content .main-content, body.page-events body.page-content .main-content, body.page-event body.page-content .main-content, body.page-tags body.page-content .main-content, body.page-node-29 body.page-content .main-content, body.page-node-34 body.page-content .main-content, body.page-content body.page-content .main-content {
    padding-left: 0px;
  }
}

@media screen and (max-width: 800px) {
  body.page-news .wrap.news-wope-theme,
  body.page-news body.page-content header.header-layout-style-1 .wrap, body.node-type-tep-blogs .wrap.news-wope-theme,
  body.node-type-tep-blogs body.page-content header.header-layout-style-1 .wrap, body.page-guides .wrap.news-wope-theme,
  body.page-guides body.page-content header.header-layout-style-1 .wrap, body.page-edgeproptv .wrap.news-wope-theme,
  body.page-edgeproptv body.page-content header.header-layout-style-1 .wrap, body.page-pullout .wrap.news-wope-theme,
  body.page-pullout body.page-content header.header-layout-style-1 .wrap, body.page-events .wrap.news-wope-theme,
  body.page-events body.page-content header.header-layout-style-1 .wrap, body.page-event .wrap.news-wope-theme,
  body.page-event body.page-content header.header-layout-style-1 .wrap, body.page-tags .wrap.news-wope-theme,
  body.page-tags body.page-content header.header-layout-style-1 .wrap, body.page-node-29 .wrap.news-wope-theme,
  body.page-node-29 body.page-content header.header-layout-style-1 .wrap, body.page-node-34 .wrap.news-wope-theme,
  body.page-node-34 body.page-content header.header-layout-style-1 .wrap, body.page-content .wrap.news-wope-theme,
  body.page-content body.page-content header.header-layout-style-1 .wrap {
    width: 640px;
  }
  body.page-news body.page-content .main-content, body.node-type-tep-blogs body.page-content .main-content, body.page-guides body.page-content .main-content, body.page-edgeproptv body.page-content .main-content, body.page-pullout body.page-content .main-content, body.page-events body.page-content .main-content, body.page-event body.page-content .main-content, body.page-tags body.page-content .main-content, body.page-node-29 body.page-content .main-content, body.page-node-34 body.page-content .main-content, body.page-content body.page-content .main-content {
    padding-left: 0px;
  }
}

@media screen and (max-width: 720px) {
  body.page-news .wrap.news-wope-theme,
  body.page-news body.page-content header.header-layout-style-1 .wrap, body.node-type-tep-blogs .wrap.news-wope-theme,
  body.node-type-tep-blogs body.page-content header.header-layout-style-1 .wrap, body.page-guides .wrap.news-wope-theme,
  body.page-guides body.page-content header.header-layout-style-1 .wrap, body.page-edgeproptv .wrap.news-wope-theme,
  body.page-edgeproptv body.page-content header.header-layout-style-1 .wrap, body.page-pullout .wrap.news-wope-theme,
  body.page-pullout body.page-content header.header-layout-style-1 .wrap, body.page-events .wrap.news-wope-theme,
  body.page-events body.page-content header.header-layout-style-1 .wrap, body.page-event .wrap.news-wope-theme,
  body.page-event body.page-content header.header-layout-style-1 .wrap, body.page-tags .wrap.news-wope-theme,
  body.page-tags body.page-content header.header-layout-style-1 .wrap, body.page-node-29 .wrap.news-wope-theme,
  body.page-node-29 body.page-content header.header-layout-style-1 .wrap, body.page-node-34 .wrap.news-wope-theme,
  body.page-node-34 body.page-content header.header-layout-style-1 .wrap, body.page-content .wrap.news-wope-theme,
  body.page-content body.page-content header.header-layout-style-1 .wrap {
    width: 420px;
  }
  body.page-news body.page-content .main-content, body.node-type-tep-blogs body.page-content .main-content, body.page-guides body.page-content .main-content, body.page-edgeproptv body.page-content .main-content, body.page-pullout body.page-content .main-content, body.page-events body.page-content .main-content, body.page-event body.page-content .main-content, body.page-tags body.page-content .main-content, body.page-node-29 body.page-content .main-content, body.page-node-34 body.page-content .main-content, body.page-content body.page-content .main-content {
    padding-left: 0px;
  }
}

@media screen and (max-width: 480px) {
  body.page-news .wrap.news-wope-theme,
  body.page-news body.page-content header.header-layout-style-1 .wrap, body.node-type-tep-blogs .wrap.news-wope-theme,
  body.node-type-tep-blogs body.page-content header.header-layout-style-1 .wrap, body.page-guides .wrap.news-wope-theme,
  body.page-guides body.page-content header.header-layout-style-1 .wrap, body.page-edgeproptv .wrap.news-wope-theme,
  body.page-edgeproptv body.page-content header.header-layout-style-1 .wrap, body.page-pullout .wrap.news-wope-theme,
  body.page-pullout body.page-content header.header-layout-style-1 .wrap, body.page-events .wrap.news-wope-theme,
  body.page-events body.page-content header.header-layout-style-1 .wrap, body.page-event .wrap.news-wope-theme,
  body.page-event body.page-content header.header-layout-style-1 .wrap, body.page-tags .wrap.news-wope-theme,
  body.page-tags body.page-content header.header-layout-style-1 .wrap, body.page-node-29 .wrap.news-wope-theme,
  body.page-node-29 body.page-content header.header-layout-style-1 .wrap, body.page-node-34 .wrap.news-wope-theme,
  body.page-node-34 body.page-content header.header-layout-style-1 .wrap, body.page-content .wrap.news-wope-theme,
  body.page-content body.page-content header.header-layout-style-1 .wrap {
    width: 100%;
  }
  body.page-news body.page-content .main-content, body.node-type-tep-blogs body.page-content .main-content, body.page-guides body.page-content .main-content, body.page-edgeproptv body.page-content .main-content, body.page-pullout body.page-content .main-content, body.page-events body.page-content .main-content, body.page-event body.page-content .main-content, body.page-tags body.page-content .main-content, body.page-node-29 body.page-content .main-content, body.page-node-34 body.page-content .main-content, body.page-content body.page-content .main-content {
    padding-left: 0px;
  }
}

body.page-news h1.news-title, body.node-type-tep-blogs h1.news-title, body.page-guides h1.news-title, body.page-edgeproptv h1.news-title, body.page-pullout h1.news-title, body.page-events h1.news-title, body.page-event h1.news-title, body.page-tags h1.news-title, body.page-node-29 h1.news-title, body.page-node-34 h1.news-title, body.page-content h1.news-title {
  margin-bottom: .3em;
  font-size: 1.4rem;
  font-weight: 500;
  line-height: 1.3;
}

body.page-news h1.news-title a, body.node-type-tep-blogs h1.news-title a, body.page-guides h1.news-title a, body.page-edgeproptv h1.news-title a, body.page-pullout h1.news-title a, body.page-events h1.news-title a, body.page-event h1.news-title a, body.page-tags h1.news-title a, body.page-node-29 h1.news-title a, body.page-node-34 h1.news-title a, body.page-content h1.news-title a {
  color: #23527c;
}

body.page-news .footer-grid .footer-box > h5, body.node-type-tep-blogs .footer-grid .footer-box > h5, body.page-guides .footer-grid .footer-box > h5, body.page-edgeproptv .footer-grid .footer-box > h5, body.page-pullout .footer-grid .footer-box > h5, body.page-events .footer-grid .footer-box > h5, body.page-event .footer-grid .footer-box > h5, body.page-tags .footer-grid .footer-box > h5, body.page-node-29 .footer-grid .footer-box > h5, body.page-node-34 .footer-grid .footer-box > h5, body.page-content .footer-grid .footer-box > h5 {
  margin-top: 0;
  margin-bottom: 0;
  padding: 1rem 0;
}

body.page-news .explorer-tep h5, body.node-type-tep-blogs .explorer-tep h5, body.page-guides .explorer-tep h5, body.page-edgeproptv .explorer-tep h5, body.page-pullout .explorer-tep h5, body.page-events .explorer-tep h5, body.page-event .explorer-tep h5, body.page-tags .explorer-tep h5, body.page-node-29 .explorer-tep h5, body.page-node-34 .explorer-tep h5, body.page-content .explorer-tep h5 {
  font-size: 1.3rem;
  font-weight: 500;
  color: #fff;
}

body.page-news .footer-top a, body.node-type-tep-blogs .footer-top a, body.page-guides .footer-top a, body.page-edgeproptv .footer-top a, body.page-pullout .footer-top a, body.page-events .footer-top a, body.page-event .footer-top a, body.page-tags .footer-top a, body.page-node-29 .footer-top a, body.page-node-34 .footer-top a, body.page-content .footer-top a {
  color: silver;
}

body.page-news .footer-top a:hover, body.node-type-tep-blogs .footer-top a:hover, body.page-guides .footer-top a:hover, body.page-edgeproptv .footer-top a:hover, body.page-pullout .footer-top a:hover, body.page-events .footer-top a:hover, body.page-event .footer-top a:hover, body.page-tags .footer-top a:hover, body.page-node-29 .footer-top a:hover, body.page-node-34 .footer-top a:hover, body.page-content .footer-top a:hover {
  text-decoration: underline;
}

body.page-news .footer-intro a, body.node-type-tep-blogs .footer-intro a, body.page-guides .footer-intro a, body.page-edgeproptv .footer-intro a, body.page-pullout .footer-intro a, body.page-events .footer-intro a, body.page-event .footer-intro a, body.page-tags .footer-intro a, body.page-node-29 .footer-intro a, body.page-node-34 .footer-intro a, body.page-content .footer-intro a {
  color: #3b839c;
}

body.page-news .footer-intro a:hover, body.node-type-tep-blogs .footer-intro a:hover, body.page-guides .footer-intro a:hover, body.page-edgeproptv .footer-intro a:hover, body.page-pullout .footer-intro a:hover, body.page-events .footer-intro a:hover, body.page-event .footer-intro a:hover, body.page-tags .footer-intro a:hover, body.page-node-29 .footer-intro a:hover, body.page-node-34 .footer-intro a:hover, body.page-content .footer-intro a:hover {
  text-decoration: underline;
}

body.page-news .social-media li a, body.node-type-tep-blogs .social-media li a, body.page-guides .social-media li a, body.page-edgeproptv .social-media li a, body.page-pullout .social-media li a, body.page-events .social-media li a, body.page-event .social-media li a, body.page-tags .social-media li a, body.page-node-29 .social-media li a, body.page-node-34 .social-media li a, body.page-content .social-media li a {
  color: #fff;
}

body.page-news #scroll-header-content, body.node-type-tep-blogs #scroll-header-content, body.page-guides #scroll-header-content, body.page-edgeproptv #scroll-header-content, body.page-pullout #scroll-header-content, body.page-events #scroll-header-content, body.page-event #scroll-header-content, body.page-tags #scroll-header-content, body.page-node-29 #scroll-header-content, body.page-node-34 #scroll-header-content, body.page-content #scroll-header-content {
  background: #fff;
  border-bottom: 1px solid #eee;
}

@media (max-width: 1000px) {
  body.page-news .main-content,
  body.page-news .sidebar, body.node-type-tep-blogs .main-content,
  body.node-type-tep-blogs .sidebar, body.page-guides .main-content,
  body.page-guides .sidebar, body.page-edgeproptv .main-content,
  body.page-edgeproptv .sidebar, body.page-pullout .main-content,
  body.page-pullout .sidebar, body.page-events .main-content,
  body.page-events .sidebar, body.page-event .main-content,
  body.page-event .sidebar, body.page-tags .main-content,
  body.page-tags .sidebar, body.page-node-29 .main-content,
  body.page-node-29 .sidebar, body.page-node-34 .main-content,
  body.page-node-34 .sidebar, body.page-content .main-content,
  body.page-content .sidebar {
    float: none;
    width: 100%;
    padding-left: 0;
  }
  body.page-news .main-content, body.node-type-tep-blogs .main-content, body.page-guides .main-content, body.page-edgeproptv .main-content, body.page-pullout .main-content, body.page-events .main-content, body.page-event .main-content, body.page-tags .main-content, body.page-node-29 .main-content, body.page-node-34 .main-content, body.page-content .main-content {
    padding-right: 0;
  }
}

@media (max-width: 800px) {
  body.page-news .top-nav, body.node-type-tep-blogs .top-nav, body.page-guides .top-nav, body.page-edgeproptv .top-nav, body.page-pullout .top-nav, body.page-events .top-nav, body.page-event .top-nav, body.page-tags .top-nav, body.page-node-29 .top-nav, body.page-node-34 .top-nav, body.page-content .top-nav {
    margin-bottom: 81px;
  }
}

body.page-user .subs-news {
  display: none;
}

body.page-content .page-projectreview,
body.page-content #page-donedeal, body.node-type-tep-blogs .page-projectreview,
body.node-type-tep-blogs #page-donedeal {
  font-family: "Poppins", sans-serif !important;
}

body.page-content .page-projectreview label, body.page-content .page-projectreview span, body.page-content .page-projectreview div, body.page-content .page-projectreview button, body.page-content .page-projectreview h1, body.page-content .page-projectreview h2, body.page-content .page-projectreview h3, body.page-content .page-projectreview h4, body.page-content .page-projectreview h5, body.page-content .page-projectreview h6,
body.page-content .page-projectreview #form-agent,
body.page-content #page-donedeal label,
body.page-content #page-donedeal span,
body.page-content #page-donedeal div,
body.page-content #page-donedeal button,
body.page-content #page-donedeal h1,
body.page-content #page-donedeal h2,
body.page-content #page-donedeal h3,
body.page-content #page-donedeal h4,
body.page-content #page-donedeal h5,
body.page-content #page-donedeal h6,
body.page-content #page-donedeal #form-agent, body.node-type-tep-blogs .page-projectreview label, body.node-type-tep-blogs .page-projectreview span, body.node-type-tep-blogs .page-projectreview div, body.node-type-tep-blogs .page-projectreview button, body.node-type-tep-blogs .page-projectreview h1, body.node-type-tep-blogs .page-projectreview h2, body.node-type-tep-blogs .page-projectreview h3, body.node-type-tep-blogs .page-projectreview h4, body.node-type-tep-blogs .page-projectreview h5, body.node-type-tep-blogs .page-projectreview h6,
body.node-type-tep-blogs .page-projectreview #form-agent,
body.node-type-tep-blogs #page-donedeal label,
body.node-type-tep-blogs #page-donedeal span,
body.node-type-tep-blogs #page-donedeal div,
body.node-type-tep-blogs #page-donedeal button,
body.node-type-tep-blogs #page-donedeal h1,
body.node-type-tep-blogs #page-donedeal h2,
body.node-type-tep-blogs #page-donedeal h3,
body.node-type-tep-blogs #page-donedeal h4,
body.node-type-tep-blogs #page-donedeal h5,
body.node-type-tep-blogs #page-donedeal h6,
body.node-type-tep-blogs #page-donedeal #form-agent {
  font-family: "Poppins", sans-serif !important;
}

body.page-content .page-projectreview h1, body.page-content .page-projectreview h2,
body.page-content #page-donedeal h1,
body.page-content #page-donedeal h2, body.node-type-tep-blogs .page-projectreview h1, body.node-type-tep-blogs .page-projectreview h2,
body.node-type-tep-blogs #page-donedeal h1,
body.node-type-tep-blogs #page-donedeal h2 {
  font-weight: bold !important;
}

body.page-content .page-projectreview h3, body.page-content .page-projectreview h4, body.page-content .page-projectreview h5, body.page-content .page-projectreview h6,
body.page-content #page-donedeal h3,
body.page-content #page-donedeal h4,
body.page-content #page-donedeal h5,
body.page-content #page-donedeal h6, body.node-type-tep-blogs .page-projectreview h3, body.node-type-tep-blogs .page-projectreview h4, body.node-type-tep-blogs .page-projectreview h5, body.node-type-tep-blogs .page-projectreview h6,
body.node-type-tep-blogs #page-donedeal h3,
body.node-type-tep-blogs #page-donedeal h4,
body.node-type-tep-blogs #page-donedeal h5,
body.node-type-tep-blogs #page-donedeal h6 {
  font-weight: 600;
}

body.page-content .page-projectreview .news-hero-text h4,
body.page-content #page-donedeal .news-hero-text h4, body.node-type-tep-blogs .page-projectreview .news-hero-text h4,
body.node-type-tep-blogs #page-donedeal .news-hero-text h4 {
  font-weight: 600 !important;
}

body.page-content .slider-position, body.node-type-tep-blogs .slider-position {
  margin-top: 20px;
}

body.page-content .slider-position .news-top-stories, body.node-type-tep-blogs .slider-position .news-top-stories {
  padding-top: 2rem;
  padding-bottom: 2rem;
  border-bottom: 0.1rem solid #dedede;
}

body.page-content .slider-position .news-top-stories .news-row, body.node-type-tep-blogs .slider-position .news-top-stories .news-row {
  display: flex;
}

body.page-content .slider-position .news-top-stories .news-thumb, body.node-type-tep-blogs .slider-position .news-top-stories .news-thumb {
  float: none;
  width: 7rem;
  flex: 0 0 auto;
  align-self: center;
}

body.page-content .slider-position .news-top-stories .news-content, body.node-type-tep-blogs .slider-position .news-top-stories .news-content {
  width: auto;
  margin-left: 0;
  padding-left: 1.5rem;
  flex: 1 1 auto;
}

body.page-content .slider-position .news-top-stories .news-title, body.node-type-tep-blogs .slider-position .news-top-stories .news-title {
  margin-bottom: 0.3em;
  font-size: 1.3rem;
  font-weight: 500;
  line-height: 1.3;
  letter-spacing: .3px;
}

body.page-content .slider-position .news-top-stories .news-meta, body.node-type-tep-blogs .slider-position .news-top-stories .news-meta {
  font-size: 1.2rem;
  font-weight: 300;
  color: #000000;
  white-space: nowrap;
}

body.page-content .slider-position .news-top-stories .news-meta span + span, body.node-type-tep-blogs .slider-position .news-top-stories .news-meta span + span {
  margin-left: 1rem;
  padding-left: 1rem;
  border-left: 0.1rem solid #c9c9c9;
}

body.page-content .slider-position .news-top-stories.news-slider, body.node-type-tep-blogs .slider-position .news-top-stories.news-slider {
  padding: 0;
  border: none;
  box-shadow: none;
  font-family: 'Roboto', Arial;
  background-color: white;
}

body.page-content .slider-position .news-top-stories.news-slider .slider-title, body.node-type-tep-blogs .slider-position .news-top-stories.news-slider .slider-title {
  position: absolute;
  top: -10px;
  left: 25px;
  background-color: white;
  padding: 0 10px;
  text-transform: uppercase;
  font-weight: 600;
  font-size: 11px;
}

body.page-content .slider-position .news-top-stories.news-slider .container-fluid, body.node-type-tep-blogs .slider-position .news-top-stories.news-slider .container-fluid {
  padding: 0;
  border-top: 1px solid #d0d0d0;
  border-bottom: 1px solid #d0d0d0;
  box-shadow: 0 4px 11px -5px #bfbfbf;
}

body.page-content .slider-position .news-top-stories.news-slider .owl-stage, body.node-type-tep-blogs .slider-position .news-top-stories.news-slider .owl-stage {
  display: flex;
  flex-direction: row;
  align-items: stretch;
  margin-left: -35px;
  background: #f3f3f3;
}

@media (max-width: 1200px) {
  body.page-content .slider-position .news-top-stories.news-slider .owl-stage, body.node-type-tep-blogs .slider-position .news-top-stories.news-slider .owl-stage {
    margin-left: -65px;
  }
}

@media (max-width: 992px) {
  body.page-content .slider-position .news-top-stories.news-slider .owl-stage, body.node-type-tep-blogs .slider-position .news-top-stories.news-slider .owl-stage {
    margin-left: -35px;
  }
}

@media (max-width: 768px) {
  body.page-content .slider-position .news-top-stories.news-slider .owl-stage, body.node-type-tep-blogs .slider-position .news-top-stories.news-slider .owl-stage {
    margin-left: -35px;
  }
}

@media (max-width: 476px) {
  body.page-content .slider-position .news-top-stories.news-slider .owl-stage, body.node-type-tep-blogs .slider-position .news-top-stories.news-slider .owl-stage {
    margin-left: -20px;
  }
}

body.page-content .slider-position .news-top-stories.news-slider .owl-next,
body.page-content .slider-position .news-top-stories.news-slider .owl-prev, body.node-type-tep-blogs .slider-position .news-top-stories.news-slider .owl-next,
body.node-type-tep-blogs .slider-position .news-top-stories.news-slider .owl-prev {
  border-radius: 0;
  height: 100%;
  width: 30px;
  background-image: linear-gradient(to right, rgba(255, 255, 255, 0.3), #fff);
  font-size: 1.2rem;
  text-align: center;
  display: flex;
  justify-content: center;
  transition: all .2s ease-in-out;
  margin: 0 auto;
}

body.page-content .slider-position .news-top-stories.news-slider .owl-next:before,
body.page-content .slider-position .news-top-stories.news-slider .owl-prev:before, body.node-type-tep-blogs .slider-position .news-top-stories.news-slider .owl-next:before,
body.node-type-tep-blogs .slider-position .news-top-stories.news-slider .owl-prev:before {
  font-family: FontAwesome;
}

body.page-content .slider-position .news-top-stories.news-slider .owl-next span,
body.page-content .slider-position .news-top-stories.news-slider .owl-prev span, body.node-type-tep-blogs .slider-position .news-top-stories.news-slider .owl-next span,
body.node-type-tep-blogs .slider-position .news-top-stories.news-slider .owl-prev span {
  display: none;
}

body.page-content .slider-position .news-top-stories.news-slider .owl-prev, body.node-type-tep-blogs .slider-position .news-top-stories.news-slider .owl-prev {
  background-image: linear-gradient(to right, #fff, rgba(255, 255, 255, 0.3));
}

body.page-content .slider-position .news-top-stories.news-slider .owl-prev:before, body.node-type-tep-blogs .slider-position .news-top-stories.news-slider .owl-prev:before {
  content: "\f053";
  position: relative;
  top: 35px;
}

body.page-content .slider-position .news-top-stories.news-slider .owl-next:before, body.node-type-tep-blogs .slider-position .news-top-stories.news-slider .owl-next:before {
  content: "\f054";
  position: relative;
  top: 35px;
}

body.page-content .slider-position .news-top-stories.news-slider .owl-item, body.node-type-tep-blogs .slider-position .news-top-stories.news-slider .owl-item {
  position: relative;
  margin: 10px 0;
}

body.page-content .slider-position .news-top-stories.news-slider .owl-item:after, body.node-type-tep-blogs .slider-position .news-top-stories.news-slider .owl-item:after {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(to right, rgba(255, 255, 255, 0.1) 0%, white 25%);
  margin-left: -6px;
  transition: all .2s ease-in-out;
}

body.page-content .slider-position .news-top-stories.news-slider .owl-item.active:after, body.node-type-tep-blogs .slider-position .news-top-stories.news-slider .owl-item.active:after {
  content: none;
  transition: all .2s ease-in-out;
}

body.page-content .slider-position .news-top-stories.news-slider .owl-item.active:first-child:before, body.node-type-tep-blogs .slider-position .news-top-stories.news-slider .owl-item.active:first-child:before {
  content: none;
}

body.page-content .slider-position .news-top-stories.news-slider .owl-item.active:before, body.node-type-tep-blogs .slider-position .news-top-stories.news-slider .owl-item.active:before {
  content: "";
  display: block;
  height: 100%;
  width: 50%;
  position: absolute;
  top: 0;
  z-index: 1;
}

body.page-content .slider-position .news-top-stories.news-slider .owl-item.active ~ .active:before, body.node-type-tep-blogs .slider-position .news-top-stories.news-slider .owl-item.active ~ .active:before {
  content: none;
}

body.page-content .slider-position .news-top-stories.news-slider .owl-item:last-child a.news-row, body.node-type-tep-blogs .slider-position .news-top-stories.news-slider .owl-item:last-child a.news-row {
  border-right: none;
}

body.page-content .slider-position .news-top-stories.news-slider .i-carousel, body.node-type-tep-blogs .slider-position .news-top-stories.news-slider .i-carousel {
  padding: 0;
}

body.page-content .slider-position .news-top-stories.news-slider .i-carousel .owl-nav i, body.node-type-tep-blogs .slider-position .news-top-stories.news-slider .i-carousel .owl-nav i {
  align-self: center;
}

body.page-content .slider-position .news-top-stories.news-slider .i-carousel .owl-nav i:before, body.node-type-tep-blogs .slider-position .news-top-stories.news-slider .i-carousel .owl-nav i:before {
  font-weight: bold;
  text-shadow: -1px 0 #252525, 0 1px #252525, 1px 0 #252525, 0 -1px #252525;
}

body.page-content .slider-position .news-top-stories.news-slider a.news-row, body.node-type-tep-blogs .slider-position .news-top-stories.news-slider a.news-row {
  height: 100%;
  border-right: 1px solid #d0d0d0;
  padding: 0 15px;
}

body.page-content .slider-position .news-top-stories.news-slider a.news-row:hover, body.node-type-tep-blogs .slider-position .news-top-stories.news-slider a.news-row:hover {
  text-decoration: none;
}

body.page-content .slider-position .news-top-stories.news-slider a.news-row .news-content, body.node-type-tep-blogs .slider-position .news-top-stories.news-slider a.news-row .news-content {
  flex: 1 1 auto;
  padding-left: 10px;
}

body.page-content .slider-position .news-top-stories.news-slider a.news-row .news-content .news-meta, body.node-type-tep-blogs .slider-position .news-top-stories.news-slider a.news-row .news-content .news-meta {
  display: flex;
}

body.page-content .slider-position .news-top-stories.news-slider a.news-row .news-content .news-meta span.date, body.node-type-tep-blogs .slider-position .news-top-stories.news-slider a.news-row .news-content .news-meta span.date {
  white-space: normal;
}

body.page-content .slider-position .news-top-stories.news-slider a.news-row .news-content h1.news-title, body.node-type-tep-blogs .slider-position .news-top-stories.news-slider a.news-row .news-content h1.news-title {
  font-size: 13px;
  -o-text-overflow: ellipsis;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

@media (min-width: 320px) and (max-width: 1366px) {
  body.page-content .slider-position .news-top-stories.news-slider a.news-row .news-content h1.news-title, body.node-type-tep-blogs .slider-position .news-top-stories.news-slider a.news-row .news-content h1.news-title {
    font-size: 12px;
    position: relative;
    max-height: 62px;
  }
}

@media (max-width: 490px) {
  body.page-content .slider-position .news-top-stories.news-slider a.news-row .news-content h1.news-title, body.node-type-tep-blogs .slider-position .news-top-stories.news-slider a.news-row .news-content h1.news-title {
    font-size: 12px;
  }
}

body.page-content .slider-position .news-top-stories.news-slider a.news-row .news-thumb, body.node-type-tep-blogs .slider-position .news-top-stories.news-slider a.news-row .news-thumb {
  width: 8.5rem;
  height: 6.5rem;
  align-self: flex-start;
}

body.page-content .slider-position .news-top-stories.news-slider a.news-row .news-thumb > img, body.node-type-tep-blogs .slider-position .news-top-stories.news-slider a.news-row .news-thumb > img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

body.page-content .slider-position .news-top-stories.pos-fixed, body.node-type-tep-blogs .slider-position .news-top-stories.pos-fixed {
  position: fixed !important;
  width: 100%;
}

body.page-content .slider-position .news-top-stories.pos-fixed.newscroll, body.node-type-tep-blogs .slider-position .news-top-stories.pos-fixed.newscroll {
  z-index: 999;
  top: 100px;
}

@media (max-width: 1366px) {
  body.page-content .slider-position .news-top-stories.pos-fixed.newscroll, body.node-type-tep-blogs .slider-position .news-top-stories.pos-fixed.newscroll {
    top: 100px;
  }
}

@media (max-width: 800px) {
  body.page-content .slider-position .news-top-stories.pos-fixed.newscroll, body.node-type-tep-blogs .slider-position .news-top-stories.pos-fixed.newscroll {
    top: 51px;
  }
}

body.page-content .leaderboard-1, body.node-type-tep-blogs .leaderboard-1 {
  background: transparent;
}

@media screen and (max-width: 1180px) {
  body.page-content .wrap, body.node-type-tep-blogs .wrap {
    width: 960px;
  }
}

@media (max-width: 490px) {
  body.page-content .news-body.content .block-mrec figure, body.node-type-tep-blogs .news-body.content .block-mrec figure {
    left: 0px !important;
  }
}

@media (min-width: 425px) and (max-width: 490px) {
  body.page-content .news-body.content figure, body.node-type-tep-blogs .news-body.content figure {
    left: calc((100% - 425px) / 2);
  }
}

@media (max-width: 425px) {
  body.page-content .news-body.content figure, body.node-type-tep-blogs .news-body.content figure {
    width: 101.1vw;
    left: calc(-50vw + 50%);
    margin-bottom: 10px;
  }
}

body.page-content .news-body.content figure.first, body.node-type-tep-blogs .news-body.content figure.first {
  border-bottom: 25px solid #fff;
}

@media (max-width: 768px) {
  body.page-content .news-body.content figcaption, body.node-type-tep-blogs .news-body.content figcaption {
    width: 100%;
    text-align: left;
    padding: 0 20px;
  }
}

body.page-content .news-body.content p, body.node-type-tep-blogs .news-body.content p {
  color: #000;
  font-family: "Poppins", sans-serif !important;
  font-weight: 400;
  font-size: 1.2em;
  line-height: 1.5;
  padding: 0px 0px 16px 0px;
  margin: 0;
}

body.page-content .news-body.content p:last-child, body.node-type-tep-blogs .news-body.content p:last-child {
  padding-bottom: 0px;
}

@media (max-width: 991px) {
  body.page-content .news-body.content p, body.node-type-tep-blogs .news-body.content p {
    font-size: 16px;
    line-height: 26px;
    padding: 0 20px 20px;
    margin-left: 0 !important;
  }
}

body.page-content .news-body.content p a, body.node-type-tep-blogs .news-body.content p a {
  display: initial;
}

body.page-content .related-post, body.node-type-tep-blogs .related-post {
  margin-bottom: 15px;
}

body.page-content .related-post .post-section-title h5, body.node-type-tep-blogs .related-post .post-section-title h5 {
  margin-bottom: 0;
}

body.page-content .related-post ol, body.node-type-tep-blogs .related-post ol {
  list-style: none;
  counter-reset: li;
}

body.page-content .related-post ol li, body.node-type-tep-blogs .related-post ol li {
  counter-increment: li;
  margin-left: 32px;
}

body.page-content .related-post ol li .related-article, body.node-type-tep-blogs .related-post ol li .related-article {
  margin-bottom: 0;
  border-bottom: 0;
}

body.page-content .related-post ol li .line-breaker, body.node-type-tep-blogs .related-post ol li .line-breaker {
  border-bottom: 1px solid #d9d9d9;
  position: relative;
  left: -31px;
  width: 105%;
}

@media (max-width: 425px) {
  body.page-content .related-post ol li .line-breaker, body.node-type-tep-blogs .related-post ol li .line-breaker {
    width: 108%;
  }
}

@media (max-width: 375px) {
  body.page-content .related-post ol li .line-breaker, body.node-type-tep-blogs .related-post ol li .line-breaker {
    width: 110%;
  }
}

body.page-content .related-post ol li::before, body.node-type-tep-blogs .related-post ol li::before {
  content: counter(li);
  text-align: center;
  font-weight: 600;
  font-size: 30px;
  line-height: 0;
  position: relative;
  top: 33px;
  color: #212529;
  display: inline-block;
  width: 1em;
  margin-left: -1.3em;
}

body.page-content h2.entry-title, body.node-type-tep-blogs h2.entry-title {
  font-family: "Poppins", sans-serif !important;
}

@media (max-width: 767px) {
  body.page-content .top2-entry-post-share,
  body.page-content .bottom-entry-post-share, body.node-type-tep-blogs .top2-entry-post-share,
  body.node-type-tep-blogs .bottom-entry-post-share {
    display: none;
  }
}

@media (min-width: 768px) {
  body.page-content .share-news-content,
  body.page-content .share-item, body.node-type-tep-blogs .share-news-content,
  body.node-type-tep-blogs .share-item {
    display: none;
  }
}

body.page-content .slider-position.stick-top, body.node-type-tep-blogs .slider-position.stick-top {
  z-index: 1000;
}

body.page-content .entry-meta, body.node-type-tep-blogs .entry-meta {
  background: #fff;
}

body.page-content .entry-meta .em-author, body.node-type-tep-blogs .entry-meta .em-author {
  display: block;
  text-transform: initial;
  color: #212529;
  line-height: 18px;
  letter-spacing: -.3px;
  font-weight: 600;
  font-style: normal;
}

body.page-content .entry-meta .em-date, body.node-type-tep-blogs .entry-meta .em-date {
  display: inline-block;
  margin-top: 5px;
  color: #4a4a4a;
  border-bottom: 10px solid #fff;
}

@media (max-width: 767px) {
  body.page-content .entry-meta .em-date, body.node-type-tep-blogs .entry-meta .em-date {
    margin-top: 2px;
    border-bottom: 15px solid #fff;
  }
}

body.page-content .news-top-stories.news-slider, body.node-type-tep-blogs .news-top-stories.news-slider {
  z-index: 99999;
}

@media (min-width: 1023px) {
  body.page-content .wrap.news-wope-theme, body.node-type-tep-blogs .wrap.news-wope-theme {
    margin-top: 95px;
  }
}

@media (max-width: 768px) {
  body.page-content .wrap.news-wope-theme, body.node-type-tep-blogs .wrap.news-wope-theme {
    margin-top: 0;
  }
}

body.page-content .news-content-wrapper, body.node-type-tep-blogs .news-content-wrapper {
  background: #fff;
}

body.page-content .content table, body.node-type-tep-blogs .content table {
  background: #fff;
  pointer-events: auto;
}

body.page-edgeproptv .video-title, body.page-news .video-title, body.page-pullout .video-title, body.page-events .video-title, body.page-event .video-title, body.page-tags .video-title, body.page-node-34 .video-title, body.page-node-29 .video-title {
  font-size: 1em;
}

body.page-edgeproptv .wrap .main-content, body.page-news .wrap .main-content, body.page-pullout .wrap .main-content, body.page-events .wrap .main-content, body.page-event .wrap .main-content, body.page-tags .wrap .main-content, body.page-node-34 .wrap .main-content, body.page-node-29 .wrap .main-content {
  padding: 10px 0 0;
}

@media (min-width: 1000px) {
  body.page-edgeproptv .wrap .sidebar, body.page-news .wrap .sidebar, body.page-pullout .wrap .sidebar, body.page-events .wrap .sidebar, body.page-event .wrap .sidebar, body.page-tags .wrap .sidebar, body.page-node-34 .wrap .sidebar, body.page-node-29 .wrap .sidebar {
    padding-left: 50px;
  }
}

@media (min-width: 1025px) {
  body.page-edgeproptv .widget-tem-search, body.page-news .widget-tem-search, body.page-pullout .widget-tem-search, body.page-events .widget-tem-search, body.page-event .widget-tem-search, body.page-tags .widget-tem-search, body.page-node-34 .widget-tem-search, body.page-node-29 .widget-tem-search {
    margin-left: 22px;
  }
}

body.page-news .show-more-lifestyle {
  color: #f45454 !important;
}

body.page-news .flexslider.video .entry-thumb {
  cursor: pointer;
}

body.page-newspages .news-top-stories.news-slider {
  display: none;
}

body.page-newspages .video-date p {
  font-size: 16px;
  line-height: 28px;
}

@media (max-width: 490px) {
  body footer .footer-top .footer-grid {
    margin-right: 0;
    margin-left: 0;
  }
}

body.page-content-1902981 #block-block-10 {
  border-bottom: 40px solid #fff;
}

body.page-content-1903083 figure.first {
  border-bottom: 10px solid #fff !important;
}

body.page-content-1903083 .prlxlabel.end,
body.page-content-1903083 .skin-ads-parallax-wrapper {
  display: none !important;
}

@media (max-width: 1024px) {
  body[class*='page-content-done-deal-'] .container-full-new.header-mobile-view .navbar-brand-new .logo-mobile,
  body.page-content-1715218 .container-full-new.header-mobile-view .navbar-brand-new .logo-mobile {
    position: relative;
    left: calc(13% + -4%);
  }
}

body.page-content .news-body.content ul li, body.page-content .news-body.content ol li {
  font-size: 1.1em;
  margin-bottom: 10px;
  color: #222;
}

body.page-content .news-body.content ul li ul li, body.page-content .news-body.content ol li ul li {
  font-size: 1em;
  line-height: 1.7;
}

body.page-content .news-body.content ol li {
  font-size: 1.2em;
  line-height: 1.6;
}

body.page-content .news-body.content ul.content-bullet-list li {
  font-size: 1.35em;
}

body.page-content .news-body.content ul.content-bullet-list li:before {
  top: 23px;
}

body.page-content figcaption {
  color: #76767a;
  font-weight: 300;
  font-size: 12px;
}

body.page-content .related-post h6 {
  font-family: "Rubik", Helvetica, Arial, sans-serif !important;
}

.newsImg-enlarged-image {
  padding-top: 20px;
}

.enlrgImg {
  cursor: zoom-in;
}

@media (max-width: 768px) {
  body.page-news-author h1.page-heading {
    font-size: 18px;
    line-height: 24px;
    padding-bottom: 20px;
  }
}

.header-layout-style-1 .fmh-notice-wrapper, #scroll-header-content .fmh-notice-wrapper {
  height: 29px;
}

.header-layout-style-1 .announcement-top-banner, #scroll-header-content .announcement-top-banner {
  color: #fff;
  padding: 20px;
  z-index: 999;
  background: linear-gradient(270deg, #5a3fc8, #488bf8, #005d98, #5a3fc8);
  background-size: 800% 800%;
  -webkit-animation: AnimationName 9s ease infinite;
  -moz-animation: AnimationName 9s ease infinite;
  animation: AnimationName 9s ease infinite;
  font-size: 1.3rem;
  line-height: 1.42857143;
}

@media only screen and (max-width: 768px) {
  .header-layout-style-1 .announcement-top-banner, #scroll-header-content .announcement-top-banner {
    padding: 10px;
  }
}

@-webkit-keyframes AnimationName {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

@-moz-keyframes AnimationName {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

@keyframes AnimationName {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

.header-layout-style-1 .announcement-top-banner p, #scroll-header-content .announcement-top-banner p {
  margin: 0px;
  font-size: 20px;
  font-weight: 700;
}

.header-layout-style-1 .announcement-top-banner a, #scroll-header-content .announcement-top-banner a {
  color: #ffffff;
  font-weight: bold;
}

.header-layout-style-1 .announcement-top-banner.screen-condition, #scroll-header-content .announcement-top-banner.screen-condition {
  position: fixed;
  z-index: 999;
  left: 0;
}

@media (max-width: 768px) {
  .header-layout-style-1 .announcement-top-banner.screen-condition, #scroll-header-content .announcement-top-banner.screen-condition {
    width: 100%;
  }
}

.slider-position.announcement-top.stick-top {
  top: 6.1rem;
}

@media (max-width: 768px) {
  .slider-position.announcement-top.stick-top.top-zero {
    top: 70px;
  }
}

@media (max-width: 552px) {
  .slider-position.announcement-top.stick-top.top-zero {
    top: 102px;
  }
}

@media (max-width: 768px) {
  .edgeprop-header .header-banner {
    width: 100%;
    display: block;
  }
  #leaderboard_1_newspage {
    text-align: center !important;
  }
}

@media screen and (max-width: 768px) {
  .wrap.news-wope-theme, body.page-content header.header-layout-style-1 .wrap {
    padding: 0;
  }
}

body.page-area-outlook main section.content-area.p-t-25,
body.page-tips main section.content-area.p-t-25 {
  padding-top: 5.4rem;
}

.dropdown.mega-dropdown .menu-agent p {
  font-size: 13px;
}

.dropdown.mega-dropdown .menu-agent h4 {
  font-size: 15px;
}

.dropdown.mega-dropdown .menu-agent a.btn-agents {
  color: #fff;
}

body.page-content .flourish-embed.flourish-parliament figure.first {
  display: none !important;
}

body.page-content .news-end-quickaccess {
  font-family: 'Noto Serif SC', serif;
  line-height: 1.8;
  padding: 5px 0 40px;
  color: #333;
  font-style: italic;
  letter-spacing: .3px;
  font-size: 18px;
}

body.page-content .news-end-quickaccess a {
  display: initial;
  color: #488BF8 !important;
}

@media (max-width: 768px) {
  body.page-content .news-end-quickaccess {
    pointer-events: auto;
    background: #fff;
    font-size: 16px;
    line-height: 28px;
    padding: 0 17px 28px;
  }
}

@media (min-width: 768px) {
  body.page-content .entry-meta.meta-left {
    float: left;
    margin-top: 4px;
  }
}

@media (min-width: 768px) and (max-width: 1023px) {
  body.page-content .entry-meta.meta-left {
    width: 40%;
  }
}

@media (min-width: 1024px) {
  body.page-content .entry-meta.meta-left {
    width: 60%;
  }
}

@media (min-width: 768px) {
  body.page-content .top2-entry-post-share.meta-right {
    float: right;
    text-align: right;
  }
}

@media (min-width: 768px) and (max-width: 1023px) {
  body.page-content .top2-entry-post-share.meta-right {
    width: 60%;
  }
}

@media (min-width: 768px) and (max-width: 1259px) {
  body.page-content .top2-entry-post-share.meta-right {
    margin-top: 5px;
  }
}

@media (min-width: 1024px) {
  body.page-content .top2-entry-post-share.meta-right {
    width: 40%;
  }
}

.caphack {
  position: relative;
  top: -13px;
  padding-bottom: 15px;
}

#page-donedeal .donedeal-top a,
#page-donedeal .donedeal-content a {
  color: #488bf8;
  text-decoration: underline;
}

@media (min-width: 1200px) {
  #page-donedeal .donedeal-top,
  #page-donedeal .donedeal-content {
    width: 970px;
  }
}

#page-donedeal .donedeal-top {
  background: transparent;
  display: block;
  margin: 52px auto 5px;
}

#page-donedeal .donedeal-top .module-top {
  padding: 25px 0 10px;
}

@media (min-width: 1025px) {
  #page-donedeal .donedeal-top .module-top {
    padding: 50px 0 10px;
  }
}

#page-donedeal .donedeal-top .module-top .pre-heading {
  color: #ef4123;
  text-transform: uppercase;
  font-weight: 700;
  font-size: 28px;
  line-height: 1;
  border-bottom: 1px solid;
  padding-bottom: 10px;
  display: inline-block;
}

@media (max-width: 767px) {
  #page-donedeal .donedeal-top .module-top .pre-heading {
    font-size: 22px;
  }
}

#page-donedeal .donedeal-top .module-top h1 {
  color: #165297;
  line-height: .8;
  font-weight: 700;
}

@media (max-width: 767px) {
  #page-donedeal .donedeal-top .module-top h1 {
    font-size: 22px;
    line-height: 1;
  }
}

#page-donedeal .donedeal-top .module-top h1 span.top-psf {
  font-size: 20px;
  font-weight: 600;
  color: #488BF8;
}

@media (max-width: 767px) {
  #page-donedeal .donedeal-top .module-top h1 span.top-psf {
    font-size: 16px;
  }
}

#page-donedeal .donedeal-top .module-top h2 {
  font-size: 27px;
  font-weight: 600;
  border-bottom: 1px solid #ccc;
  padding-bottom: 10px;
  margin-top: 10px;
}

@media (max-width: 767px) {
  #page-donedeal .donedeal-top .module-top h2 {
    font-size: 16px;
  }
}

#page-donedeal .donedeal-top .module-top .save-share {
  position: absolute;
  right: 30px;
  top: 28px;
}

#page-donedeal .donedeal-top .module-top .save-share .share-btn {
  background-color: #fff;
  border-color: #d3d3d3;
  color: #414141;
  font-size: 14px;
  line-height: 14px;
  padding: 6px 10px;
  height: 32px;
}

@media (max-width: 768px) {
  #page-donedeal .donedeal-top .module-top .save-share {
    display: block;
    position: initial;
    margin-top: 20px;
  }
}

#page-donedeal .donedeal-top .module-top .meta-agent {
  width: 100%;
  padding: 10px;
}

@media (max-width: 768px) {
  #page-donedeal .donedeal-top .module-top .meta-agent {
    padding: 5px 0 10px 0;
  }
}

@media (min-width: 768px) {
  #page-donedeal .donedeal-top .module-top .meta-agent {
    width: 400px;
  }
}

#page-donedeal .donedeal-top .module-top .meta-agent img.meta-img {
  width: 60px;
  border-radius: 50%;
  float: left;
  margin-right: 10px;
}

#page-donedeal .donedeal-top .module-top .meta-agent .pro-agent {
  width: 32px;
}

#page-donedeal .donedeal-top .module-top .meta-agent .meta-highlight {
  font-weight: 700;
}

#page-donedeal .donedeal-top .module-top .img-module-top img {
  width: 100%;
  max-width: 600px;
}

@media (max-width: 768px) {
  #page-donedeal .donedeal-top .module-top .img-module-top img {
    max-width: 100%;
  }
}

#page-donedeal .donedeal-top .module-top .border-module {
  border-top: 0;
  border-bottom: 1px solid #ccc;
  overflow: hidden;
  display: block;
  height: 8px;
  width: 97%;
  margin: 0 auto 5px;
}

@media (max-width: 768px) {
  #page-donedeal .donedeal-top .module-top .border-module {
    display: none;
  }
}

#page-donedeal .donedeal-content {
  display: block;
  background: #fff;
}

#page-donedeal .donedeal-content .sidebar {
  float: left;
  padding: 0 15px;
}

@media (min-width: 769px) {
  #page-donedeal .donedeal-content .sidebar {
    width: 28%;
    margin-right: 25px;
  }
}

@media (min-width: 1024px) and (max-width: 1024px) {
  #page-donedeal .donedeal-content .sidebar {
    margin-right: 15px;
  }
}

#page-donedeal .donedeal-content .sidebar .side-col .side-label {
  color: #a0acc1;
  font-size: 12px;
  padding-bottom: 3px;
}

#page-donedeal .donedeal-content .sidebar .side-col .side-data {
  color: #191c26;
  line-height: 24px;
  display: block;
  border-bottom: 1px solid #ddd;
  padding-bottom: 2px;
  margin-bottom: 15px;
  font-size: 14px;
}

#page-donedeal .donedeal-content .sidebar.noteworthy {
  background: #aaa;
  color: #fff;
}

@media (max-width: 768px) {
  #page-donedeal .donedeal-content .sidebar.noteworthy {
    padding-top: 5px;
  }
}

#page-donedeal .donedeal-content .sidebar.noteworthy h3 {
  margin-top: 10px;
  padding-bottom: 5px;
  border-bottom: 1px solid #fff;
}

#page-donedeal .donedeal-content .sidebar.noteworthy ul {
  padding: 0 0 0px 15px;
  list-style: inherit;
}

#page-donedeal .donedeal-content .sidebar.noteworthy ul li {
  padding-bottom: 15px;
  line-height: 1.5;
}

#page-donedeal .donedeal-content .sidebar.noteworthy ul li p {
  line-height: normal;
  margin: 0;
  padding: 0;
}

#page-donedeal .donedeal-content .sidemain h3 {
  margin: 0 0 15px;
  line-height: 1;
}

#page-donedeal .donedeal-content .sidemain img {
  max-width: 100%;
}

#page-donedeal .donedeal-content .sidemain figcaption {
  color: #969696;
  font-style: italic;
  line-height: 1.4;
  margin-top: .6em;
  margin-bottom: 20px;
}

#page-donedeal .donedeal-content .sidemain figcaption:before {
  font-family: fontawesome;
  content: '\f05a';
  margin-right: 4px;
  font-style: normal;
  font-size: 1.3rem;
}

#page-donedeal .donedeal-content .sidemain .content-footer {
  border-top: 1px solid #036;
  font-size: 12px;
  color: #036;
  padding-top: 15px;
}

#page-donedeal .donedeal-content .module-median-chart {
  margin-bottom: 30px;
  margin-top: 30px;
}

#page-donedeal .donedeal-content .module-median-chart .desc {
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  margin-bottom: 16px;
}

@media (max-width: 767px) {
  #page-donedeal .donedeal-content .module-median-chart .desc {
    font-size: 12px;
  }
}

#page-donedeal .donedeal-content .module-median-chart .median-chart {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
}

#page-donedeal .donedeal-content .module-median-chart .median-chart .gradient-bar {
  height: 100%;
  width: 100%;
  overflow: hidden;
  vertical-align: middle;
}

#page-donedeal .donedeal-content .module-median-chart .median-chart .gradient-bar .svg-white {
  stop-color: #f7f7f7;
}

#page-donedeal .donedeal-content .module-median-chart .median-chart .gradient-bar .svg-ghost-light {
  stop-color: #e9e9ec;
}

#page-donedeal .donedeal-content .module-median-chart .median-chart .gradient-bar .svg-turqoise-dark,
#page-donedeal .donedeal-content .module-median-chart .median-chart .gradient-bar .svg-cerulean {
  stop-color: #4c87f8;
}

#page-donedeal .donedeal-content .module-median-chart .median-chart__edge {
  flex: 2;
  text-align: center;
}

#page-donedeal .donedeal-content .module-median-chart .median-chart__edge-bar-low, #page-donedeal .donedeal-content .module-median-chart .median-chart__edge-bar-high {
  height: 30px;
}

#page-donedeal .donedeal-content .module-median-chart .median-chart__low {
  flex: 4;
}

#page-donedeal .donedeal-content .module-median-chart .median-chart__low .median-chart__low-bar {
  background-color: #d7d7d7;
  height: 30px;
}

#page-donedeal .donedeal-content .module-median-chart .median-chart__middle {
  flex: 8;
  text-align: center;
}

#page-donedeal .donedeal-content .module-median-chart .median-chart__middle .median-chart__middle-bar {
  background: #488BF8;
  height: 30px;
  position: relative;
  width: 100%;
}

#page-donedeal .donedeal-content .module-median-chart .median-chart__middle .median-chart__middle-bar:after {
  border-left: 1px dashed #fff;
  content: "";
  height: 20px;
  left: 50%;
  margin-top: 5px;
  position: absolute;
  transform: translate(-50%);
  z-index: 1;
}

#page-donedeal .donedeal-content .module-median-chart .median-chart__high {
  flex: 4;
  text-align: right;
}

#page-donedeal .donedeal-content .module-median-chart .median-chart__high .median-chart__high-bar {
  background-color: #51bf6a;
  height: 30px;
}

#page-donedeal .donedeal-content .module-median-chart .median-chart__label {
  color: #212529;
  font-size: small;
  font-weight: 700;
  margin-top: 5px;
}

#page-donedeal .donedeal-content .module-median-chart .median-chart__label .percentage,
#page-donedeal .donedeal-content .module-median-chart .median-chart__label .psf {
  font-weight: 400;
  color: #525252;
  font-size: 14px;
  line-height: 18px;
}

@media (max-width: 767px) {
  #page-donedeal .donedeal-content .module-median-chart .median-chart__label .percentage,
  #page-donedeal .donedeal-content .module-median-chart .median-chart__label .psf {
    font-size: 11px;
  }
}

#page-donedeal .donedeal-content .module-median-chart .median-chart__label .price {
  color: #414141;
  font-size: 26px;
  font-weight: 800;
}

@media (max-width: 767px) {
  #page-donedeal .donedeal-content .module-median-chart .median-chart__label .price {
    font-size: 13px;
  }
}

#page-donedeal .donedeal-content .module-main p {
  font-size: 14px;
  line-height: 30px;
}

#page-donedeal .donedeal-listings .section-head {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  padding: 0;
}

#page-donedeal .donedeal-listings .section-head h2 {
  font-size: 28px;
  color: #414141;
  font-weight: 600;
  margin-bottom: 0;
}

@media (max-width: 991px) {
  #page-donedeal .donedeal-listings .section-head h2 {
    font-size: 20px;
    max-width: 60%;
  }
}

#page-donedeal .donedeal-listings .section-head a {
  font-weight: 600;
  color: #488BF8;
}

#page-donedeal .donedeal-listings .sale-listings {
  margin-top: 35px;
}

#page-donedeal .donedeal-listings .sale-listings .row {
  display: flex;
  flex-wrap: wrap;
}

@media (max-width: 767px) {
  #page-donedeal .donedeal-listings .sale-listings .listing-row {
    flex-wrap: nowrap;
    overflow-x: auto !important;
    overflow-y: hidden !important;
    -webkit-overflow-scrolling: touch !important;
  }
}

#page-donedeal .donedeal-listings .sale-listings .listing-card {
  margin-top: 5px;
  margin-bottom: 15px;
  border-radius: 5px;
  box-shadow: 0 0 6px rgba(0, 0, 0, 0.16);
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border: 1px solid rgba(0, 0, 0, 0.125);
}

#page-donedeal .donedeal-listings .sale-listings .listing-card .card-header {
  border-radius: 5px 5px 0 0;
  display: flex;
  background-color: #fff;
  color: #000;
  padding: 5px 13px;
  margin-bottom: 0;
  padding: 5px 13px;
  margin-bottom: 0;
  border-bottom: 0;
}

#page-donedeal .donedeal-listings .sale-listings .listing-card .card-header .avatar-item {
  min-width: 34px;
  margin-right: 10px;
}

#page-donedeal .donedeal-listings .sale-listings .listing-card .card-header .avatar-item .agent-avatar {
  position: absolute;
  width: 34px;
  height: 34px;
  z-index: 2;
  border-radius: 50%;
  object-fit: cover;
  object-position: top;
  border: 1px solid #1f56ab;
  background-color: #1f56ab;
}

#page-donedeal .donedeal-listings .sale-listings .listing-card .card-header .avatar-item a {
  display: initial;
}

#page-donedeal .donedeal-listings .sale-listings .listing-card .card-header .details-item .agent-name {
  margin: 0;
  font-size: 12px;
  font-weight: 600;
  display: -webkit-box;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
}

#page-donedeal .donedeal-listings .sale-listings .listing-card .card-header .details-item .agent-name a {
  color: #000;
  background-color: transparent;
}

#page-donedeal .donedeal-listings .sale-listings .listing-card .card-header .details-item .agent-name a:hover {
  text-decoration: underline;
}

#page-donedeal .donedeal-listings .sale-listings .listing-card .card-header .details-item .agent-listing-desc {
  margin: 0;
  font-size: 11px;
  display: -webkit-box;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
}

#page-donedeal .donedeal-listings .sale-listings .listing-card .card-header .pro-agent {
  width: 32px;
  margin-left: 2px;
  padding-top: 1px;
  vertical-align: top;
}

#page-donedeal .donedeal-listings .sale-listings .listing-card .card-header:first-child {
  border-radius: 5px 5px 0 0;
}

#page-donedeal .donedeal-listings .sale-listings .listing-card.featured .card-header {
  background-color: #488BF8;
  color: #fff;
}

#page-donedeal .donedeal-listings .sale-listings .listing-card.featured .card-header .avatar-item {
  min-width: 60px;
  margin-right: 10px;
}

#page-donedeal .donedeal-listings .sale-listings .listing-card.featured .card-header .avatar-item .agent-avatar {
  width: 60px;
  height: 60px;
  border: 2px solid #1f56ab;
}

#page-donedeal .donedeal-listings .sale-listings .listing-card.featured .card-header .details-item .agent-name a {
  color: #fff;
}

#page-donedeal .donedeal-listings .sale-listings .listing-card .card-img {
  width: 100%;
  height: 150px;
  object-fit: cover;
}

#page-donedeal .donedeal-listings .sale-listings .listing-card .card-body {
  flex: 1 1 auto;
  min-height: 1px;
  position: relative;
  padding: 12px;
}

#page-donedeal .donedeal-listings .sale-listings .listing-card .card-body .card-price {
  color: #1f56ab;
  font-size: 18px;
  line-height: 27px;
  font-weight: 600 !important;
  margin-bottom: 4px;
  max-width: 500px;
}

#page-donedeal .donedeal-listings .sale-listings .listing-card .card-body .card-name {
  font-size: 16px;
  color: #414141;
  font-weight: 500 !important;
  line-height: 18px;
  margin-bottom: 6px;
  max-width: 500px;
  display: -webkit-box;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
}

#page-donedeal .donedeal-listings .sale-listings .listing-card .card-body .card-address {
  line-height: 18px;
  margin-bottom: 6px;
  max-width: 500px;
  display: -webkit-box;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  color: #a0acc1;
  font-size: 12px;
}

#page-donedeal .donedeal-listings .sale-listings .listing-card .card-body .card-specs .spec-item {
  display: inline-block;
  margin-right: 20px;
  color: #525252;
  font-size: 13px;
  line-height: 21px;
  vertical-align: baseline;
}

#page-donedeal .donedeal-listings .sale-listings .listing-card .card-body .card-specs .spec-item .spec-icon {
  margin-right: 5px;
  width: 15px;
  height: auto;
}

#page-donedeal .donedeal-listings .sale-listings .listing-card:hover {
  cursor: pointer;
  box-shadow: 0 0 6px rgba(31, 86, 171, 0.7);
}

@media (max-width: 991px) {
  #page-donedeal .donedeal-listings .sale-listings .listing-card {
    width: 250px;
  }
}

@media (min-width: 1200px) {
  #page-donedeal .donedeal-listings,
  #page-donedeal .donedeal-past-transaction {
    width: 1130px;
  }
}

#page-donedeal .donedeal-past-transaction {
  margin-top: 20px;
}

@media (min-width: 768px) {
  #page-donedeal .donedeal-past-transaction {
    padding: 0 30px 30px;
    border: 1px solid #ddd;
  }
}

#page-donedeal .donedeal-past-transaction table {
  border: 1px solid #ddd;
}

#page-donedeal .donedeal-past-transaction table th {
  color: #a0acc1;
  font-weight: 200;
}

#page-donedeal .donedeal-past-transaction table td a {
  color: #488BF8;
}

#page-donedeal .donedeal-past-transaction table td .btn-primary {
  background-color: #488BF8;
  border-color: #488BF8;
}

#page-donedeal .donedeal-past-transaction table td .btn-primary.text-light {
  color: #f8f9fa;
}

#page-donedeal .donedeal-past-transaction .trans-redirect-link a {
  color: #488BF8;
}

#page-donedeal .donedeal-past-transaction .btn {
  font-size: 12px;
}

#page-donedeal .right-float-box {
  position: absolute;
  right: 10px;
  top: 10px;
}

@media (min-width: 1024px) {
  #page-donedeal .right-float-box {
    top: 20px;
  }
}

@media (max-width: 768px) {
  #page-donedeal .right-float-box {
    position: relative;
    top: 10px;
    right: 0;
  }
}

#page-donedeal .btn {
  transition: none;
  display: inline-block;
  font-weight: 400;
  color: #212529;
  text-align: center;
  vertical-align: middle;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  background-color: transparent;
  border: 1px solid transparent;
  padding: .375rem .75rem;
  font-size: 17px;
  line-height: 1.5;
  border-radius: .25rem;
  transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out;
}

#page-donedeal .btn:hover {
  color: #212529;
  text-decoration: none;
}

#page-donedeal .enquire-button,
#page-donedeal .whatsapp-button {
  background-color: transparent;
  color: #1f56ab;
  padding: 2px 20px;
  width: 200px;
  height: 48px;
  font-size: 16px;
  font-weight: 500;
}

@media (max-width: 1023px) {
  #page-donedeal .enquire-button,
  #page-donedeal .whatsapp-button {
    background: #fff;
    width: 48%;
    padding: 0;
    font-size: 13px;
    border: 1px solid #ddd;
  }
}

#page-donedeal .whatsapp-button {
  color: #128c7e;
}

@media (max-width: 1023px) {
  #page-donedeal .enquire-button {
    margin-right: 10px;
    margin-left: 2px;
    padding: 14px;
  }
}

@media (max-width: 375px) {
  #page-donedeal .enquire-button {
    margin-left: 0px;
  }
}

@media (max-width: 320px) {
  #page-donedeal .enquire-button {
    margin-right: 8px;
  }
}

#page-donedeal .meta-btn {
  overflow: auto;
  padding: 5px 0 10px 0;
}

#page-donedeal .meta-btn .enquire-button,
#page-donedeal .meta-btn .whatsapp-button {
  border: 1px solid #ddd;
  background: #fff;
  width: 48%;
}

#page-donedeal .meta-btn .enquire-button {
  margin-right: 9px;
  margin-left: 0;
  padding: 10px;
}

@media (min-width: 414px) {
  #page-donedeal .meta-btn .enquire-button {
    margin-right: 11px;
  }
}

@media (max-width: 1023px) {
  #page-donedeal .meta-btn .enquire-button {
    padding: 13px;
  }
}

@media (max-width: 320px) {
  #page-donedeal .meta-btn .enquire-button {
    margin-right: 8px;
  }
}

#page-donedeal .agent-stickybar {
  position: fixed;
  z-index: 10;
  left: 0;
  right: 0;
  top: -120px;
  color: #fff;
  display: -moz-box;
  display: flex;
  -moz-box-pack: center;
  justify-content: center;
  -moz-box-align: center;
  align-items: center;
  -moz-box-orient: horizontal;
  -moz-box-direction: normal;
  flex-direction: row;
  flex-wrap: nowrap;
  transition: all .3s ease-in-out .2s;
}

@media (min-width: 992px) {
  #page-donedeal .agent-stickybar {
    display: block;
  }
}

#page-donedeal .agent-stickybar.show {
  top: 81px;
}

#page-donedeal .agent-stickybar .agent-inner {
  background-color: rgba(0, 0, 0, 0.8);
  -webkit-backdrop-filter: saturate(180%) blur(20px);
  backdrop-filter: saturate(180%) blur(20px);
  color: #fff;
  height: 111px;
  line-height: 1.5;
  width: 100%;
}

#page-donedeal .agent-stickybar .agent-inner .agent-innerer {
  max-width: 1024px;
  width: 100%;
}

#page-donedeal .agent-stickybar .agent-inner .ag-avatar {
  margin: 20px 0;
  padding: 0;
}

@media (min-width: 992px) {
  #page-donedeal .agent-stickybar .agent-inner .ag-avatar {
    margin: 0 auto 20px;
  }
}

#page-donedeal .agent-stickybar .agent-inner .ag-avatar img {
  border-radius: 50%;
  object-fit: cover;
  object-position: top;
  border: 1px solid #efefef;
}

@media (min-width: 1024px) {
  #page-donedeal .agent-stickybar .agent-inner .ag-avatar img {
    position: relative;
    top: -18px;
  }
}

#page-donedeal .agent-stickybar .agent-inner .ag-avatar-sm {
  height: 69px;
  width: 69px;
}

@media (min-width: 1024px) {
  #page-donedeal .agent-stickybar .agent-inner .ag-avatar-sm {
    position: relative;
    top: 15px;
  }
}

#page-donedeal .agent-stickybar .agent-inner .ag-avatar-sm img {
  border: 2px solid #fff;
  top: 10px;
  position: relative;
}

#page-donedeal .agent-stickybar .agent-inner .ag-base-info h5 {
  color: #fff;
  margin-bottom: 0;
  font-weight: 400 !important;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  font-size: 14px;
  line-height: 1.18;
}

@media (min-width: 768px) {
  #page-donedeal .agent-stickybar .agent-inner .ag-base-info h5 {
    margin-top: 18px;
  }
}

#page-donedeal .agent-stickybar .agent-inner .ag-base-info h5 .pro-agent {
  width: 32px;
  vertical-align: top;
}

#page-donedeal .agent-stickybar .agent-inner .ag-base-info h5 a {
  color: inherit;
}

#page-donedeal .agent-stickybar .agent-inner .ag-base-info .text-secondary {
  margin-top: 10px;
}

#page-donedeal .agent-stickybar .agent-inner .ag-base-info .text-secondary span {
  display: block;
  padding: 0 0 3px;
  font-size: 12px;
  color: #a5adb5;
}

@media (min-width: 992px) {
  #page-donedeal .agent-stickybar .agent-inner .ag-base-info {
    position: relative;
    top: 10px;
  }
}

#page-donedeal .agent-stickybar .agent-inner .ag-sticky-btn {
  position: relative;
  top: 31px;
}

#page-donedeal .agent-stickybar .agent-inner .ag-sticky-btn .enquire-button {
  color: #fff;
  border: 1px solid #ddd;
  padding: 11px;
}

#page-donedeal .agent-stickybar .agent-inner .ag-sticky-btn .whatsapp-button {
  background: #fff;
}

@media (min-width: 768px) and (max-width: 768px) {
  #page-donedeal .agent-stickybar {
    display: none !important;
  }
}

#page-donedeal .agent-stickyfooter {
  box-shadow: 0 -2px 4px 0 rgba(0, 0, 0, 0.25);
  position: fixed;
  bottom: -80px;
  z-index: 999;
  width: 100%;
  background: #fff;
  padding: 0 10px 10px;
  transition: all .3s ease-in-out .2s;
}

#page-donedeal .agent-stickyfooter.show {
  bottom: 0;
}

#page-donedeal .form-agent-section {
  padding: 50px 0;
  margin: 30px 0;
}

@media (max-width: 475px) {
  #page-donedeal .form-agent-section textarea#message {
    min-height: 77px;
  }
}

#page-donedeal .inquiry-button {
  width: 100%;
  margin: 0 auto;
  display: inherit;
  cursor: pointer;
  font-size: 1.9rem;
  line-height: 1.3;
  text-transform: uppercase;
  border: 1px solid #cc0000;
  background: #cc0000;
  color: #fff;
  text-align: center;
  padding: 12px 80px;
  margin-top: 10px;
  display: inline-block;
}

#page-donedeal .inquiry-button:hover {
  background: #b30a0a;
  border: 1px solid #b30a0a;
}

#page-donedeal #formsection {
  height: 50px;
}

@media (max-width: 767px) {
  #page-donedeal #formsection {
    height: 0;
  }
}

body.page-content.page-content-1906288 .main-content table .featured-properties {
  display: none;
}

body.page-content.page-content-1906288 .main-content #adInContent iframe:first-child {
  display: none;
}

body.page-live,
body.page-live2019,
body.page-live2020,
body.page-destination {
  font-family: "Chivo", sans-serif;
  /*  @media (min-width: 768px) and (max-width: 991px) {
    .navbar-collapse.collapse {
      display: none !important;
      &.in {
        display: block !important;
      }
    }
    .navbar-header .collapse, .navbar-toggle {
      display: block !important;
    }
    .navbar-header {
      float: none;
    }
  } */
}

body.page-live a,
body.page-live2019 a,
body.page-live2020 a,
body.page-destination a {
  color: #000;
  -webkit-transition: all .3s;
  -moz-transition: all .3s;
  transition: all 0.3s;
}

body.page-live a:hover,
body.page-live2019 a:hover,
body.page-live2020 a:hover,
body.page-destination a:hover {
  color: #ff2481;
  text-decoration: none;
}

body.page-live span,
body.page-live2019 span,
body.page-live2020 span,
body.page-destination span {
  font-family: "Chivo", sans-serif;
}

body.page-live hr,
body.page-live2019 hr,
body.page-live2020 hr,
body.page-destination hr {
  max-width: 100px;
  margin: 25px auto 0;
  border-width: 1px;
  border-color: rgba(34, 34, 34, 0.1);
}

body.page-live hr.light,
body.page-live2019 hr.light,
body.page-live2020 hr.light,
body.page-destination hr.light {
  border-color: #fff;
}

body.page-live h1, body.page-live h2, body.page-live h3, body.page-live h4, body.page-live h5, body.page-live h6,
body.page-live2019 h1,
body.page-live2019 h2,
body.page-live2019 h3,
body.page-live2019 h4,
body.page-live2019 h5,
body.page-live2019 h6,
body.page-live2020 h1,
body.page-live2020 h2,
body.page-live2020 h3,
body.page-live2020 h4,
body.page-live2020 h5,
body.page-live2020 h6,
body.page-destination h1,
body.page-destination h2,
body.page-destination h3,
body.page-destination h4,
body.page-destination h5,
body.page-destination h6 {
  font-family: "Chivo", sans-serif;
  font-weight: 900;
  letter-spacing: 1px;
}

body.page-live p,
body.page-live2019 p,
body.page-live2020 p,
body.page-destination p {
  font-family: "Chivo", sans-serif;
  font-size: 18px;
  line-height: 1.5;
  margin-bottom: 20px;
}

body.page-live section,
body.page-live2019 section,
body.page-live2020 section,
body.page-destination section {
  padding: 100px 0;
}

body.page-live section h2,
body.page-live2019 section h2,
body.page-live2020 section h2,
body.page-destination section h2 {
  font-size: 50px;
}

body.page-live span.header-logotype,
body.page-live2019 span.header-logotype,
body.page-live2020 span.header-logotype,
body.page-destination span.header-logotype {
  font-weight: 700 !important;
  text-transform: uppercase !important;
  color: #ff006c !important;
  position: relative;
  top: 18px;
}

body.page-live .mag-title-wrapper,
body.page-live2019 .mag-title-wrapper,
body.page-live2020 .mag-title-wrapper,
body.page-destination .mag-title-wrapper {
  background: #000;
  text-align: center;
  padding: 23px 0 19px;
}

body.page-live .mag-title-wrapper .mag-title,
body.page-live2019 .mag-title-wrapper .mag-title,
body.page-live2020 .mag-title-wrapper .mag-title,
body.page-destination .mag-title-wrapper .mag-title {
  font-size: 48px;
  line-height: 44px;
  font-weight: 200;
  font-family: "Chivo", sans-serif;
  margin-top: 0;
  margin-bottom: 0;
  text-align: center;
  text-transform: uppercase;
  color: #fff;
}

body.page-live .mag-title-wrapper .mag-subtitle,
body.page-live2019 .mag-title-wrapper .mag-subtitle,
body.page-live2020 .mag-title-wrapper .mag-subtitle,
body.page-destination .mag-title-wrapper .mag-subtitle {
  font-family: "Inknut Antiqua", serif;
  color: #fff;
  font-size: 29px;
}

@media (min-width: 768px) {
  body.page-live .mag-title-wrapper,
  body.page-live2019 .mag-title-wrapper,
  body.page-live2020 .mag-title-wrapper,
  body.page-destination .mag-title-wrapper {
    background: rgba(0, 0, 0, 0.66);
    position: relative;
    margin-top: -129px;
  }
}

body.page-live .img-full,
body.page-live2019 .img-full,
body.page-live2020 .img-full,
body.page-destination .img-full {
  width: 100%;
}

@media (min-width: 768px) {
  body.page-live .img-full.magcover,
  body.page-live2019 .img-full.magcover,
  body.page-live2020 .img-full.magcover,
  body.page-destination .img-full.magcover {
    max-width: 300px;
    height: auto;
  }
}

@media (max-width: 767px) {
  body.page-live .img-full.magcover,
  body.page-live2019 .img-full.magcover,
  body.page-live2020 .img-full.magcover,
  body.page-destination .img-full.magcover {
    display: none;
  }
}

body.page-live .mag-box,
body.page-live2019 .mag-box,
body.page-live2020 .mag-box,
body.page-destination .mag-box {
  display: block;
  margin: 15px 0;
  overflow: hidden;
}

body.page-live .mag-box .box,
body.page-live2019 .mag-box .box,
body.page-live2020 .mag-box .box,
body.page-destination .mag-box .box {
  padding-top: 25px;
  height: 90px;
  background: #000;
  text-transform: uppercase;
  font-weight: 700;
  font-family: "Chivo", sans-serif;
  font-size: 13px;
  color: #fff;
  display: inline-block;
  width: 47%;
  float: left;
}

body.page-live .mag-box .box:hover,
body.page-live2019 .mag-box .box:hover,
body.page-live2020 .mag-box .box:hover,
body.page-destination .mag-box .box:hover {
  background: #ff2481;
}

@media (min-width: 768px) {
  body.page-live .mag-box .box,
  body.page-live2019 .mag-box .box,
  body.page-live2020 .mag-box .box,
  body.page-destination .mag-box .box {
    width: 70%;
    font-size: 14px;
    letter-spacing: 0.8px;
  }
}

body.page-live .mag-box .box-1,
body.page-live2019 .mag-box .box-1,
body.page-live2020 .mag-box .box-1,
body.page-destination .mag-box .box-1 {
  margin-right: 12px;
}

body.page-live .mag-box .box-2,
body.page-live2019 .mag-box .box-2,
body.page-live2020 .mag-box .box-2,
body.page-destination .mag-box .box-2 {
  background: #ff006c;
}

body.page-live .mag-box .box a,
body.page-live2019 .mag-box .box a,
body.page-live2020 .mag-box .box a,
body.page-destination .mag-box .box a {
  color: #fff;
}

body.page-live .mag-box .box a:hover,
body.page-live2019 .mag-box .box a:hover,
body.page-live2020 .mag-box .box a:hover,
body.page-destination .mag-box .box a:hover {
  text-decoration: none;
}

@media (min-width: 768px) {
  body.page-live .mag-box,
  body.page-live2019 .mag-box,
  body.page-live2020 .mag-box,
  body.page-destination .mag-box {
    margin-top: 122px;
  }
}

body.page-live #mainNavLive,
body.page-live2019 #mainNavLive,
body.page-live2020 #mainNavLive,
body.page-destination #mainNavLive {
  background-color: #333;
}

body.page-live #mainNavLive.stick,
body.page-live2019 #mainNavLive.stick,
body.page-live2020 #mainNavLive.stick,
body.page-destination #mainNavLive.stick {
  margin-top: 0 !important;
  width: 100%;
  position: fixed;
  top: 0;
  z-index: 10000;
}

body.page-live .m-b-20,
body.page-live2019 .m-b-20,
body.page-live2020 .m-b-20,
body.page-destination .m-b-20 {
  margin-bottom: 20px;
}

body.page-live .logotype,
body.page-live2019 .logotype,
body.page-live2020 .logotype,
body.page-destination .logotype {
  text-align: center;
  color: #f4b124;
  margin-left: auto;
  margin-right: auto;
  left: 0;
  right: 0;
}

body.page-live .logotype img,
body.page-live2019 .logotype img,
body.page-live2020 .logotype img,
body.page-destination .logotype img {
  width: 250px;
  opacity: 0.9;
}

body.page-live .logotype.footer img,
body.page-live2019 .logotype.footer img,
body.page-live2020 .logotype.footer img,
body.page-destination .logotype.footer img {
  width: 110px;
}

body.page-live .logotype.toplogo img,
body.page-live2019 .logotype.toplogo img,
body.page-live2020 .logotype.toplogo img,
body.page-destination .logotype.toplogo img {
  width: 90px;
}

body.page-live .logotype.home,
body.page-live2019 .logotype.home,
body.page-live2020 .logotype.home,
body.page-destination .logotype.home {
  text-align: left;
  position: absolute;
  z-index: 1;
  margin-left: auto;
  margin-right: auto;
  left: 0;
  right: 0;
  padding: 8px;
}

body.page-live .border-side,
body.page-live2019 .border-side,
body.page-live2020 .border-side,
body.page-destination .border-side {
  border-right: 1px solid #ccc;
  border-left: 1px solid #ccc;
}

body.page-live .no-float,
body.page-live2019 .no-float,
body.page-live2020 .no-float,
body.page-destination .no-float {
  float: none;
}

body.page-live footer,
body.page-live2019 footer,
body.page-live2020 footer,
body.page-destination footer {
  color: #fff;
  padding: 20px 0;
  text-align: center;
  color: rgba(255, 255, 255, 0.3);
  background-color: #222;
}

body.page-live footer p,
body.page-live2019 footer p,
body.page-live2020 footer p,
body.page-destination footer p {
  font-size: 12px;
  margin: 0;
}

body.page-live footer ul,
body.page-live2019 footer ul,
body.page-live2020 footer ul,
body.page-destination footer ul {
  margin-bottom: 0;
}

body.page-live footer ul li a,
body.page-live2019 footer ul li a,
body.page-live2020 footer ul li a,
body.page-destination footer ul li a {
  font-size: 12px;
  color: rgba(255, 255, 255, 0.3);
}

body.page-live footer ul li a.active, body.page-live footer ul li a:active, body.page-live footer ul li a:focus, body.page-live footer ul li a:hover,
body.page-live2019 footer ul li a.active,
body.page-live2019 footer ul li a:active,
body.page-live2019 footer ul li a:focus,
body.page-live2019 footer ul li a:hover,
body.page-live2020 footer ul li a.active,
body.page-live2020 footer ul li a:active,
body.page-live2020 footer ul li a:focus,
body.page-live2020 footer ul li a:hover,
body.page-destination footer ul li a.active,
body.page-destination footer ul li a:active,
body.page-destination footer ul li a:focus,
body.page-destination footer ul li a:hover {
  text-decoration: none;
}

body.page-live #mainNavLive,
body.page-live2019 #mainNavLive,
body.page-live2020 #mainNavLive,
body.page-destination #mainNavLive {
  border-color: rgba(34, 34, 34, 0.05);
  background-color: #000;
  -webkit-transition: all .3s;
  -moz-transition: all .3s;
  transition: all .3s;
  font-weight: 200;
  letter-spacing: 1px;
  border-radius: 0;
}

body.page-live #mainNavLive .navbar,
body.page-live2019 #mainNavLive .navbar,
body.page-live2020 #mainNavLive .navbar,
body.page-destination #mainNavLive .navbar {
  border-radius: 0 !important;
}

body.page-live #mainNavLive .navbar-brand,
body.page-live2019 #mainNavLive .navbar-brand,
body.page-live2020 #mainNavLive .navbar-brand,
body.page-destination #mainNavLive .navbar-brand {
  color: #ff006c;
  font-weight: 200;
  letter-spacing: 1px;
}

body.page-live #mainNavLive .navbar-brand:focus, body.page-live #mainNavLive .navbar-brand:hover,
body.page-live2019 #mainNavLive .navbar-brand:focus,
body.page-live2019 #mainNavLive .navbar-brand:hover,
body.page-live2020 #mainNavLive .navbar-brand:focus,
body.page-live2020 #mainNavLive .navbar-brand:hover,
body.page-destination #mainNavLive .navbar-brand:focus,
body.page-destination #mainNavLive .navbar-brand:hover {
  color: #b96700;
}

body.page-live #mainNavLive .navbar-toggler,
body.page-live2019 #mainNavLive .navbar-toggler,
body.page-live2020 #mainNavLive .navbar-toggler,
body.page-destination #mainNavLive .navbar-toggler {
  font-size: 12px;
  padding: 8px 10px;
  color: #fff;
  position: absolute;
  right: 10px;
  top: 10px;
}

body.page-live #mainNavLive .navbar-nav > li > a,
body.page-live2019 #mainNavLive .navbar-nav > li > a,
body.page-live2020 #mainNavLive .navbar-nav > li > a,
body.page-destination #mainNavLive .navbar-nav > li > a {
  font-size: 13px;
  letter-spacing: 2px;
  text-transform: uppercase;
  letter-spacing: 1px;
  color: #fff;
}

body.page-live #mainNavLive .navbar-nav > li > a.active,
body.page-live2019 #mainNavLive .navbar-nav > li > a.active,
body.page-live2020 #mainNavLive .navbar-nav > li > a.active,
body.page-destination #mainNavLive .navbar-nav > li > a.active {
  color: #ff006c !important;
  background-color: transparent;
}

body.page-live #mainNavLive .navbar-nav > li > a.active:hover,
body.page-live2019 #mainNavLive .navbar-nav > li > a.active:hover,
body.page-live2020 #mainNavLive .navbar-nav > li > a.active:hover,
body.page-destination #mainNavLive .navbar-nav > li > a.active:hover {
  background-color: transparent;
}

body.page-live #mainNavLive .navbar-nav > li > a:focus,
body.page-live2019 #mainNavLive .navbar-nav > li > a:focus,
body.page-live2020 #mainNavLive .navbar-nav > li > a:focus,
body.page-destination #mainNavLive .navbar-nav > li > a:focus {
  color: #fff;
}

body.page-live #mainNavLive .navbar-nav > li > a:focus:hover,
body.page-live2019 #mainNavLive .navbar-nav > li > a:focus:hover,
body.page-live2020 #mainNavLive .navbar-nav > li > a:focus:hover,
body.page-destination #mainNavLive .navbar-nav > li > a:focus:hover {
  color: #ff006c;
}

body.page-live #mainNavLive .navbar-nav > li > a:hover,
body.page-live2019 #mainNavLive .navbar-nav > li > a:hover,
body.page-live2020 #mainNavLive .navbar-nav > li > a:hover,
body.page-destination #mainNavLive .navbar-nav > li > a:hover {
  color: #ff006c;
}

body.page-live #mainNavLive .navbar-nav .nav-item .wrap-year-select,
body.page-live2019 #mainNavLive .navbar-nav .nav-item .wrap-year-select,
body.page-live2020 #mainNavLive .navbar-nav .nav-item .wrap-year-select,
body.page-destination #mainNavLive .navbar-nav .nav-item .wrap-year-select {
  position: relative;
  padding-top: 10px;
  padding-left: 10px;
}

@media (max-width: 425px) {
  body.page-live #mainNavLive .navbar-nav .nav-item .wrap-year-select,
  body.page-live2019 #mainNavLive .navbar-nav .nav-item .wrap-year-select,
  body.page-live2020 #mainNavLive .navbar-nav .nav-item .wrap-year-select,
  body.page-destination #mainNavLive .navbar-nav .nav-item .wrap-year-select {
    padding: 0;
  }
}

body.page-live #mainNavLive .navbar-nav .nav-item .wrap-year-select .dropdown .dropdown-toggle,
body.page-live2019 #mainNavLive .navbar-nav .nav-item .wrap-year-select .dropdown .dropdown-toggle,
body.page-live2020 #mainNavLive .navbar-nav .nav-item .wrap-year-select .dropdown .dropdown-toggle,
body.page-destination #mainNavLive .navbar-nav .nav-item .wrap-year-select .dropdown .dropdown-toggle {
  background-color: transparent;
  border: 1px solid #ffffff;
  border-radius: 0;
  font-size: 13px;
  color: #ffffff;
  letter-spacing: 1px;
}

body.page-live #mainNavLive .navbar-nav .nav-item .wrap-year-select .dropdown .dropdown-menu,
body.page-live2019 #mainNavLive .navbar-nav .nav-item .wrap-year-select .dropdown .dropdown-menu,
body.page-live2020 #mainNavLive .navbar-nav .nav-item .wrap-year-select .dropdown .dropdown-menu,
body.page-destination #mainNavLive .navbar-nav .nav-item .wrap-year-select .dropdown .dropdown-menu {
  background-color: #000;
}

body.page-live #mainNavLive .navbar-nav .nav-item .wrap-year-select .dropdown .dropdown-menu .dropdown-item,
body.page-live2019 #mainNavLive .navbar-nav .nav-item .wrap-year-select .dropdown .dropdown-menu .dropdown-item,
body.page-live2020 #mainNavLive .navbar-nav .nav-item .wrap-year-select .dropdown .dropdown-menu .dropdown-item,
body.page-destination #mainNavLive .navbar-nav .nav-item .wrap-year-select .dropdown .dropdown-menu .dropdown-item {
  border-radius: 0;
  font-size: 13px;
  color: #ffffff;
}

body.page-live #mainNavLive .navbar-nav .nav-item .wrap-year-select .dropdown .dropdown-menu .dropdown-item:hover,
body.page-live2019 #mainNavLive .navbar-nav .nav-item .wrap-year-select .dropdown .dropdown-menu .dropdown-item:hover,
body.page-live2020 #mainNavLive .navbar-nav .nav-item .wrap-year-select .dropdown .dropdown-menu .dropdown-item:hover,
body.page-destination #mainNavLive .navbar-nav .nav-item .wrap-year-select .dropdown .dropdown-menu .dropdown-item:hover {
  color: #ff006c;
  background-color: #000;
}

@media (max-width: 425px) {
  body.page-live #mainNavLive .navbar-nav .nav-item .wrap-year-select .dropdown .dropdown-menu .dropdown-item,
  body.page-live2019 #mainNavLive .navbar-nav .nav-item .wrap-year-select .dropdown .dropdown-menu .dropdown-item,
  body.page-live2020 #mainNavLive .navbar-nav .nav-item .wrap-year-select .dropdown .dropdown-menu .dropdown-item,
  body.page-destination #mainNavLive .navbar-nav .nav-item .wrap-year-select .dropdown .dropdown-menu .dropdown-item {
    text-align: left;
    padding-left: 0;
  }
}

body.page-live section.general,
body.page-live2019 section.general,
body.page-live2020 section.general,
body.page-destination section.general {
  border-top: 10px solid #ff006c;
  position: relative;
  padding: 50px 0 50px;
  overflow: hidden;
}

body.page-live section.general h2,
body.page-live2019 section.general h2,
body.page-live2020 section.general h2,
body.page-destination section.general h2 {
  font-size: 50px;
  margin: 0 0 30px;
  text-transform: uppercase;
  font-family: "Chivo", sans-serif;
}

body.page-live section.general h2.m-b-5,
body.page-live2019 section.general h2.m-b-5,
body.page-live2020 section.general h2.m-b-5,
body.page-destination section.general h2.m-b-5 {
  margin-bottom: 5px;
}

body.page-live section.general h3,
body.page-live2019 section.general h3,
body.page-live2020 section.general h3,
body.page-destination section.general h3 {
  font-size: 21px;
  font-family: "Chivo", sans-serif;
  margin-bottom: 30px;
  text-transform: uppercase;
}

body.page-live section.general h4,
body.page-live2019 section.general h4,
body.page-live2020 section.general h4,
body.page-destination section.general h4 {
  font-family: "Chivo", sans-serif;
  font-size: 2.2rem;
  line-height: 1;
  margin-bottom: 15px;
  color: #ff006c;
  text-transform: lowercase;
  font-style: italic;
}

body.page-live section.general .overlay-header,
body.page-live2019 section.general .overlay-header,
body.page-live2020 section.general .overlay-header,
body.page-destination section.general .overlay-header {
  width: 100%;
  background-color: rgba(0, 0, 0, 0.6);
  position: absolute;
  font-size: 68px;
  font-weight: 200;
  font-family: "Chivo", sans-serif;
  font-style: italic;
  color: #fff;
  text-transform: capitalize;
  text-align: center;
  padding: 71px;
}

body.page-live section.general header,
body.page-live2019 section.general header,
body.page-live2020 section.general header,
body.page-destination section.general header {
  height: 244px;
  margin-bottom: 30px;
  background-position: center;
}

body.page-live section.general .highlight-cards .single-card,
body.page-live2019 section.general .highlight-cards .single-card,
body.page-live2020 section.general .highlight-cards .single-card,
body.page-destination section.general .highlight-cards .single-card {
  margin-bottom: 40px;
}

body.page-live section.general .highlight-cards .single-card img,
body.page-live2019 section.general .highlight-cards .single-card img,
body.page-live2020 section.general .highlight-cards .single-card img,
body.page-destination section.general .highlight-cards .single-card img {
  max-width: 100%;
}

body.page-live section.general .highlight-cards .single-card .highlight-title,
body.page-live2019 section.general .highlight-cards .single-card .highlight-title,
body.page-live2020 section.general .highlight-cards .single-card .highlight-title,
body.page-destination section.general .highlight-cards .single-card .highlight-title {
  letter-spacing: 1px;
  font-size: 15px;
  font-family: "Chivo", sans-serif;
  text-transform: uppercase;
  font-weight: 800;
  padding: 17px 0;
}

body.page-live section.general .highlight-cards .single-card .highlight-desc,
body.page-live2019 section.general .highlight-cards .single-card .highlight-desc,
body.page-live2020 section.general .highlight-cards .single-card .highlight-desc,
body.page-destination section.general .highlight-cards .single-card .highlight-desc {
  font-size: 14px;
  color: #666;
}

body.page-live section.general .highlight-cards .single-card a,
body.page-live2019 section.general .highlight-cards .single-card a,
body.page-live2020 section.general .highlight-cards .single-card a,
body.page-destination section.general .highlight-cards .single-card a {
  text-decoration: none;
}

body.page-live section.general.no-border,
body.page-live2019 section.general.no-border,
body.page-live2020 section.general.no-border,
body.page-destination section.general.no-border {
  border-top: 0;
}

body.page-live section.general.p-t-25,
body.page-live2019 section.general.p-t-25,
body.page-live2020 section.general.p-t-25,
body.page-destination section.general.p-t-25 {
  padding-top: 25px;
}

body.page-live section.home,
body.page-live2019 section.home,
body.page-live2020 section.home,
body.page-destination section.home {
  position: relative;
  padding: 0;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
}

body.page-live section.home .home-content,
body.page-live2019 section.home .home-content,
body.page-live2020 section.home .home-content,
body.page-destination section.home .home-content {
  text-align: center;
  position: relative;
  z-index: 1;
}

body.page-live section.home .overlay,
body.page-live2019 section.home .overlay,
body.page-live2020 section.home .overlay,
body.page-destination section.home .overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
}

body.page-live section#editorsnote p,
body.page-live2019 section#editorsnote p,
body.page-live2020 section#editorsnote p,
body.page-destination section#editorsnote p {
  text-align: left;
  font-size: 16px;
  color: #666;
}

@media (max-width: 768px) {
  body.page-live .logotype img,
  body.page-live2019 .logotype img,
  body.page-live2020 .logotype img,
  body.page-destination .logotype img {
    width: 220px;
  }
}

@media (max-width: 767px) {
  body.page-live .border-side,
  body.page-live2019 .border-side,
  body.page-live2020 .border-side,
  body.page-destination .border-side {
    border-right: 0;
    border-left: 0;
  }
}

@media (min-width: 992px) {
  body.page-live #mainNavLive .navbar-brand,
  body.page-live2019 #mainNavLive .navbar-brand,
  body.page-live2020 #mainNavLive .navbar-brand,
  body.page-destination #mainNavLive .navbar-brand {
    color: fade(white, 70%);
  }
  body.page-live #mainNavLive .navbar-brand:focus, body.page-live #mainNavLive .navbar-brand:hover,
  body.page-live2019 #mainNavLive .navbar-brand:focus,
  body.page-live2019 #mainNavLive .navbar-brand:hover,
  body.page-live2020 #mainNavLive .navbar-brand:focus,
  body.page-live2020 #mainNavLive .navbar-brand:hover,
  body.page-destination #mainNavLive .navbar-brand:focus,
  body.page-destination #mainNavLive .navbar-brand:hover {
    color: #fff;
  }
  body.page-live #mainNavLive .navbar-nav > li > a,
  body.page-live2019 #mainNavLive .navbar-nav > li > a,
  body.page-live2020 #mainNavLive .navbar-nav > li > a,
  body.page-destination #mainNavLive .navbar-nav > li > a {
    color: rgba(255, 255, 255, 0.7);
  }
  body.page-live #mainNavLive .navbar-nav > li > a:focus,
  body.page-live2019 #mainNavLive .navbar-nav > li > a:focus,
  body.page-live2020 #mainNavLive .navbar-nav > li > a:focus,
  body.page-destination #mainNavLive .navbar-nav > li > a:focus {
    color: rgba(255, 255, 255, 0.7);
  }
  body.page-live #mainNavLive .navbar-nav > li > a:focus:hover,
  body.page-live2019 #mainNavLive .navbar-nav > li > a:focus:hover,
  body.page-live2020 #mainNavLive .navbar-nav > li > a:focus:hover,
  body.page-destination #mainNavLive .navbar-nav > li > a:focus:hover {
    color: #fff;
  }
  body.page-live #mainNavLive .navbar-nav > li > a:hover,
  body.page-live2019 #mainNavLive .navbar-nav > li > a:hover,
  body.page-live2020 #mainNavLive .navbar-nav > li > a:hover,
  body.page-destination #mainNavLive .navbar-nav > li > a:hover {
    color: #fff;
  }
  body.page-live #mainNavLive.navbar-shrink,
  body.page-live2019 #mainNavLive.navbar-shrink,
  body.page-live2020 #mainNavLive.navbar-shrink,
  body.page-destination #mainNavLive.navbar-shrink {
    border-color: rgba(34, 34, 34, 0.1);
    background-color: #000;
    border-radius: 0;
  }
  body.page-live #mainNavLive.navbar-shrink .navbar-brand,
  body.page-live2019 #mainNavLive.navbar-shrink .navbar-brand,
  body.page-live2020 #mainNavLive.navbar-shrink .navbar-brand,
  body.page-destination #mainNavLive.navbar-shrink .navbar-brand {
    color: #f9f9f9;
  }
  body.page-live #mainNavLive.navbar-shrink .navbar-brand:focus, body.page-live #mainNavLive.navbar-shrink .navbar-brand:hover,
  body.page-live2019 #mainNavLive.navbar-shrink .navbar-brand:focus,
  body.page-live2019 #mainNavLive.navbar-shrink .navbar-brand:hover,
  body.page-live2020 #mainNavLive.navbar-shrink .navbar-brand:focus,
  body.page-live2020 #mainNavLive.navbar-shrink .navbar-brand:hover,
  body.page-destination #mainNavLive.navbar-shrink .navbar-brand:focus,
  body.page-destination #mainNavLive.navbar-shrink .navbar-brand:hover {
    color: #ff006c;
  }
  body.page-live #mainNavLive.navbar-shrink .navbar-nav > li > a,
  body.page-live2019 #mainNavLive.navbar-shrink .navbar-nav > li > a,
  body.page-live2020 #mainNavLive.navbar-shrink .navbar-nav > li > a,
  body.page-destination #mainNavLive.navbar-shrink .navbar-nav > li > a {
    color: #f9f9f9;
  }
  body.page-live #mainNavLive.navbar-shrink .navbar-nav > li > a:focus,
  body.page-live2019 #mainNavLive.navbar-shrink .navbar-nav > li > a:focus,
  body.page-live2020 #mainNavLive.navbar-shrink .navbar-nav > li > a:focus,
  body.page-destination #mainNavLive.navbar-shrink .navbar-nav > li > a:focus {
    color: #f9f9f9;
  }
  body.page-live #mainNavLive.navbar-shrink .navbar-nav > li > a:focus:hover,
  body.page-live2019 #mainNavLive.navbar-shrink .navbar-nav > li > a:focus:hover,
  body.page-live2020 #mainNavLive.navbar-shrink .navbar-nav > li > a:focus:hover,
  body.page-destination #mainNavLive.navbar-shrink .navbar-nav > li > a:focus:hover {
    color: #ff006c;
  }
  body.page-live #mainNavLive.navbar-shrink .navbar-nav > li > a:hover,
  body.page-live2019 #mainNavLive.navbar-shrink .navbar-nav > li > a:hover,
  body.page-live2020 #mainNavLive.navbar-shrink .navbar-nav > li > a:hover,
  body.page-destination #mainNavLive.navbar-shrink .navbar-nav > li > a:hover {
    color: #ff006c;
  }
}

@media (max-width: 425px) {
  body.page-live .mag-title-wrapper .mag-title,
  body.page-live2019 .mag-title-wrapper .mag-title,
  body.page-live2020 .mag-title-wrapper .mag-title,
  body.page-destination .mag-title-wrapper .mag-title {
    font-size: 33px;
  }
  body.page-live .mag-title-wrapper .mag-subtitle,
  body.page-live2019 .mag-title-wrapper .mag-subtitle,
  body.page-live2020 .mag-title-wrapper .mag-subtitle,
  body.page-destination .mag-title-wrapper .mag-subtitle {
    font-size: 15px;
  }
  body.page-live .mag-box .box-1,
  body.page-live2019 .mag-box .box-1,
  body.page-live2020 .mag-box .box-1,
  body.page-destination .mag-box .box-1 {
    margin-right: 11px;
  }
  body.page-live .mag-box .box,
  body.page-live2019 .mag-box .box,
  body.page-live2020 .mag-box .box,
  body.page-destination .mag-box .box {
    width: 48%;
  }
  body.page-live .logotype img,
  body.page-live2019 .logotype img,
  body.page-live2020 .logotype img,
  body.page-destination .logotype img {
    width: 130px;
  }
  body.page-live section.general,
  body.page-live2019 section.general,
  body.page-live2020 section.general,
  body.page-destination section.general {
    padding: 10px 0 50px;
  }
  body.page-live section.general h2,
  body.page-live2019 section.general h2,
  body.page-live2020 section.general h2,
  body.page-destination section.general h2 {
    font-size: 28px;
  }
  body.page-live section.general .overlay-header,
  body.page-live2019 section.general .overlay-header,
  body.page-live2020 section.general .overlay-header,
  body.page-destination section.general .overlay-header {
    font-size: 33px;
    padding: 6px;
  }
  body.page-live section.general header,
  body.page-live2019 section.general header,
  body.page-live2020 section.general header,
  body.page-destination section.general header {
    height: 61px;
  }
  body.page-live section.general header.features-header,
  body.page-live2019 section.general header.features-header,
  body.page-live2020 section.general header.features-header,
  body.page-destination section.general header.features-header {
    height: 60px;
  }
  body.page-live section.home,
  body.page-live2019 section.home,
  body.page-live2020 section.home,
  body.page-destination section.home {
    padding: 0;
  }
}

@media (min-width: 426px) {
  body.page-live .visible-xs,
  body.page-live2019 .visible-xs,
  body.page-live2020 .visible-xs,
  body.page-destination .visible-xs {
    display: none !important;
  }
}

@media (max-width: 425px) {
  body.page-live .visible-xs,
  body.page-live2019 .visible-xs,
  body.page-live2020 .visible-xs,
  body.page-destination .visible-xs {
    display: block;
  }
}

body.page-live .bg-primary,
body.page-live2019 .bg-primary,
body.page-live2020 .bg-primary,
body.page-destination .bg-primary {
  background: #ff006c;
  background: -webkit-linear-gradient(#ff006c, #d37500);
  background: linear-gradient(#ff006c, #d37500);
}

body.page-live .text-primary,
body.page-live2019 .text-primary,
body.page-live2020 .text-primary,
body.page-destination .text-primary {
  color: #ff006c;
}

body.page-live .no-gutter > [class*=col-],
body.page-live2019 .no-gutter > [class*=col-],
body.page-live2020 .no-gutter > [class*=col-],
body.page-destination .no-gutter > [class*=col-] {
  padding-right: 0;
  padding-left: 0;
}

body.page-live .btn-outline,
body.page-live2019 .btn-outline,
body.page-live2020 .btn-outline,
body.page-destination .btn-outline {
  color: #fff;
  border: 1px solid;
  border-color: #fff;
}

body.page-live .btn-outline.active, body.page-live .btn-outline:active, body.page-live .btn-outline:focus, body.page-live .btn-outline:hover,
body.page-live2019 .btn-outline.active,
body.page-live2019 .btn-outline:active,
body.page-live2019 .btn-outline:focus,
body.page-live2019 .btn-outline:hover,
body.page-live2020 .btn-outline.active,
body.page-live2020 .btn-outline:active,
body.page-live2020 .btn-outline:focus,
body.page-live2020 .btn-outline:hover,
body.page-destination .btn-outline.active,
body.page-destination .btn-outline:active,
body.page-destination .btn-outline:focus,
body.page-destination .btn-outline:hover {
  color: #fff;
  border-color: #ff006c;
  background-color: #ff006c;
}

body.page-live .btn,
body.page-live2019 .btn,
body.page-live2020 .btn,
body.page-destination .btn {
  border-radius: 300px;
  letter-spacing: 2px;
  text-transform: uppercase;
}

body.page-live .btn-xl,
body.page-live2019 .btn-xl,
body.page-live2020 .btn-xl,
body.page-destination .btn-xl {
  font-size: 11px;
  padding: 15px 45px;
}

body.page-live .nav-pills .nav-link.active,
body.page-live2019 .nav-pills .nav-link.active,
body.page-live2020 .nav-pills .nav-link.active,
body.page-destination .nav-pills .nav-link.active {
  border: 1px solid #ff006c;
  color: #ff006c;
  background-color: transparent;
}

body.page-live ul.list-unstyled li,
body.page-live2019 ul.list-unstyled li,
body.page-live2020 ul.list-unstyled li,
body.page-destination ul.list-unstyled li {
  margin-bottom: 20px;
  font-size: 13px;
  color: #666;
}

body.page-live .ml-auto, body.page-live .mx-auto,
body.page-live2019 .ml-auto,
body.page-live2019 .mx-auto,
body.page-live2020 .ml-auto,
body.page-live2020 .mx-auto,
body.page-destination .ml-auto,
body.page-destination .mx-auto {
  margin-left: auto !important;
}

body.page-live .mr-auto, body.page-live .mx-auto,
body.page-live2019 .mr-auto,
body.page-live2019 .mx-auto,
body.page-live2020 .mr-auto,
body.page-live2020 .mx-auto,
body.page-destination .mr-auto,
body.page-destination .mx-auto {
  margin-right: auto !important;
}

body.page-live .fotorama__caption,
body.page-live2019 .fotorama__caption,
body.page-live2020 .fotorama__caption,
body.page-destination .fotorama__caption {
  font-size: 2rem;
  width: 50%;
  margin: 0 auto;
  text-align: center;
}

@media (min-width: 768px) {
  body.page-live .fotorama__caption,
  body.page-live2019 .fotorama__caption,
  body.page-live2020 .fotorama__caption,
  body.page-destination .fotorama__caption {
    bottom: 50px;
  }
}

@media (max-width: 767px) {
  body.page-live .fotorama__caption,
  body.page-live2019 .fotorama__caption,
  body.page-live2020 .fotorama__caption,
  body.page-destination .fotorama__caption {
    font-size: 2rem;
    bottom: 0px;
    width: 100%;
  }
}

body.page-live .fotorama__caption a,
body.page-live2019 .fotorama__caption a,
body.page-live2020 .fotorama__caption a,
body.page-destination .fotorama__caption a {
  text-decoration: none;
  border-bottom: 0;
}

body.page-live .fotorama__caption span,
body.page-live2019 .fotorama__caption span,
body.page-live2020 .fotorama__caption span,
body.page-destination .fotorama__caption span {
  display: block;
}

body.page-live .fotorama__caption span.slider-title,
body.page-live2019 .fotorama__caption span.slider-title,
body.page-live2020 .fotorama__caption span.slider-title,
body.page-destination .fotorama__caption span.slider-title {
  font-weight: bold;
  line-height: 1;
  margin-bottom: 6px;
}

@media (max-width: 767px) {
  body.page-live .fotorama__caption span.slider-title,
  body.page-live2019 .fotorama__caption span.slider-title,
  body.page-live2020 .fotorama__caption span.slider-title,
  body.page-destination .fotorama__caption span.slider-title {
    font-size: 1.7rem;
  }
}

body.page-live .fotorama__caption span.slider-subtitle,
body.page-live2019 .fotorama__caption span.slider-subtitle,
body.page-live2020 .fotorama__caption span.slider-subtitle,
body.page-destination .fotorama__caption span.slider-subtitle {
  font-size: 1.6rem;
  line-height: 1.5;
}

@media (max-width: 767px) {
  body.page-live .fotorama__caption span.slider-subtitle,
  body.page-live2019 .fotorama__caption span.slider-subtitle,
  body.page-live2020 .fotorama__caption span.slider-subtitle,
  body.page-destination .fotorama__caption span.slider-subtitle {
    font-size: 1.2rem;
  }
}

body.page-live .fotorama__caption span.slider-subtitle span.glyphicon,
body.page-live2019 .fotorama__caption span.slider-subtitle span.glyphicon,
body.page-live2020 .fotorama__caption span.slider-subtitle span.glyphicon,
body.page-destination .fotorama__caption span.slider-subtitle span.glyphicon {
  display: inline;
  font-size: 1.1rem;
}

@media (min-width: 769px) {
  body.page-live .fotorama__caption .fotorama__caption__wrap,
  body.page-live2019 .fotorama__caption .fotorama__caption__wrap,
  body.page-live2020 .fotorama__caption .fotorama__caption__wrap,
  body.page-destination .fotorama__caption .fotorama__caption__wrap {
    padding: 15px !important;
    width: 600px;
  }
}

body.page-live .fotorama__arr,
body.page-live2019 .fotorama__arr,
body.page-live2020 .fotorama__arr,
body.page-destination .fotorama__arr {
  background: 0 0 !important;
}

body.page-live .fotorama__arr:before,
body.page-live2019 .fotorama__arr:before,
body.page-live2020 .fotorama__arr:before,
body.page-destination .fotorama__arr:before {
  font-family: Ionicons;
  content: "";
  margin-right: 6px;
  font-size: xx-large;
  font-size: 58px;
  content: "";
  margin-right: 0;
  color: #ff2481;
  position: relative;
  top: -30px;
}

body.page-live .fotorama__arr:before:hover,
body.page-live2019 .fotorama__arr:before:hover,
body.page-live2020 .fotorama__arr:before:hover,
body.page-destination .fotorama__arr:before:hover {
  color: #ff006c;
}

body.page-live .fotorama__arr.fotorama__arr--next:before,
body.page-live2019 .fotorama__arr.fotorama__arr--next:before,
body.page-live2020 .fotorama__arr.fotorama__arr--next:before,
body.page-destination .fotorama__arr.fotorama__arr--next:before {
  content: "";
}

body.page-live .fotorama__arr.fotorama__arr--prev:before,
body.page-live2019 .fotorama__arr.fotorama__arr--prev:before,
body.page-live2020 .fotorama__arr.fotorama__arr--prev:before,
body.page-destination .fotorama__arr.fotorama__arr--prev:before {
  left: 8px;
  content: "";
}

body.page-live .fotorama .fotorama__html,
body.page-live2019 .fotorama .fotorama__html,
body.page-live2020 .fotorama .fotorama__html,
body.page-destination .fotorama .fotorama__html {
  background-color: rgba #000, 0.6;
}

body.page-live .fotorama .fotorama__html span,
body.page-live2019 .fotorama .fotorama__html span,
body.page-live2020 .fotorama .fotorama__html span,
body.page-destination .fotorama .fotorama__html span {
  display: block;
}

body.page-live .fotorama .fotorama__html a, body.page-live .fotorama .fotorama__html div,
body.page-live2019 .fotorama .fotorama__html a,
body.page-live2019 .fotorama .fotorama__html div,
body.page-live2020 .fotorama .fotorama__html a,
body.page-live2020 .fotorama .fotorama__html div,
body.page-destination .fotorama .fotorama__html a,
body.page-destination .fotorama .fotorama__html div {
  display: block;
  height: 100%;
  background: url(_.gif);
}

body.page-live .fotorama .caption-element,
body.page-live2019 .fotorama .caption-element,
body.page-live2020 .fotorama .caption-element,
body.page-destination .fotorama .caption-element {
  top: 0;
  position: absolute;
  z-index: 999;
  right: 0;
  background: rgba(255, 255, 255, 0.9);
  width: 50%;
  height: 100vh;
}

body.page-live .fotorama .caption-element span,
body.page-live2019 .fotorama .caption-element span,
body.page-live2020 .fotorama .caption-element span,
body.page-destination .fotorama .caption-element span {
  display: block;
}

body.page-live .fotorama .caption-element span.slider-title,
body.page-live2019 .fotorama .caption-element span.slider-title,
body.page-live2020 .fotorama .caption-element span.slider-title,
body.page-destination .fotorama .caption-element span.slider-title {
  font-weight: bold;
  line-height: 1;
  margin-bottom: 6px;
}

body.page-live .fotorama .caption-element span.slider-subtitle,
body.page-live2019 .fotorama .caption-element span.slider-subtitle,
body.page-live2020 .fotorama .caption-element span.slider-subtitle,
body.page-destination .fotorama .caption-element span.slider-subtitle {
  font-size: 2rem;
  line-height: 1.5;
}

@media (max-width: 767px) {
  body.page-live .fotorama .caption-element span.slider-subtitle,
  body.page-live2019 .fotorama .caption-element span.slider-subtitle,
  body.page-live2020 .fotorama .caption-element span.slider-subtitle,
  body.page-destination .fotorama .caption-element span.slider-subtitle {
    font-size: 1.2rem;
  }
}

body.page-live span,
body.page-live2019 span,
body.page-live2020 span,
body.page-destination span {
  display: block;
}

body.page-live span.stories-title,
body.page-live2019 span.stories-title,
body.page-live2020 span.stories-title,
body.page-destination span.stories-title {
  font-size: 1.4rem;
  font-weight: bold;
}

body.page-live span.stories-subtitle,
body.page-live2019 span.stories-subtitle,
body.page-live2020 span.stories-subtitle,
body.page-destination span.stories-subtitle {
  font-size: 1.2rem;
}

body.page-live ul.stories-list li span.story-text,
body.page-live2019 ul.stories-list li span.story-text,
body.page-live2020 ul.stories-list li span.story-text,
body.page-destination ul.stories-list li span.story-text {
  display: block;
  font-weight: 600;
  font-size: 1.4rem;
  line-height: 1.5;
  margin: 0 auto;
}

@media (min-width: 769px) {
  body.page-live ul.stories-list li span.story-text,
  body.page-live2019 ul.stories-list li span.story-text,
  body.page-live2020 ul.stories-list li span.story-text,
  body.page-destination ul.stories-list li span.story-text {
    width: 70%;
  }
}

@media (min-width: 769px) {
  body.page-live ul.stories-list li,
  body.page-live2019 ul.stories-list li,
  body.page-live2020 ul.stories-list li,
  body.page-destination ul.stories-list li {
    min-height: 270px;
  }
}

@media (min-width: 768px) and (max-width: 768px) {
  body.page-live ul.stories-list li,
  body.page-live2019 ul.stories-list li,
  body.page-live2020 ul.stories-list li,
  body.page-destination ul.stories-list li {
    min-height: 262px;
  }
}

body.page-live select#select-years,
body.page-live2019 select#select-years,
body.page-live2020 select#select-years,
body.page-destination select#select-years {
  font-size: 1.3rem;
  position: relative;
  background: #000;
  color: #fff;
}

@media (min-width: 768px) {
  body.page-live select#select-years,
  body.page-live2019 select#select-years,
  body.page-live2020 select#select-years,
  body.page-destination select#select-years {
    top: 13px;
  }
}

body.page-live .top-mag-area,
body.page-live2019 .top-mag-area,
body.page-live2020 .top-mag-area,
body.page-destination .top-mag-area {
  /* safari fix */
}

body.page-live .top-mag-area .row:before, body.page-live .top-mag-area .row:after,
body.page-live2019 .top-mag-area .row:before,
body.page-live2019 .top-mag-area .row:after,
body.page-live2020 .top-mag-area .row:before,
body.page-live2020 .top-mag-area .row:after,
body.page-destination .top-mag-area .row:before,
body.page-destination .top-mag-area .row:after {
  display: none !important;
}

body.page-live .stories-three-columns .row,
body.page-live2019 .stories-three-columns .row,
body.page-live2020 .stories-three-columns .row,
body.page-destination .stories-three-columns .row {
  /* safari fix */
}

body.page-live .stories-three-columns .row:before, body.page-live .stories-three-columns .row:after,
body.page-live2019 .stories-three-columns .row:before,
body.page-live2019 .stories-three-columns .row:after,
body.page-live2020 .stories-three-columns .row:before,
body.page-live2020 .stories-three-columns .row:after,
body.page-destination .stories-three-columns .row:before,
body.page-destination .stories-three-columns .row:after {
  display: none !important;
}

@media (max-width: 554px) {
  body.page-live .stories-three-columns .row,
  body.page-live2019 .stories-three-columns .row,
  body.page-live2020 .stories-three-columns .row,
  body.page-destination .stories-three-columns .row {
    display: inline-block;
    width: 100%;
  }
}

body.page-live .stories-three-columns .row .col .box,
body.page-live2019 .stories-three-columns .row .col .box,
body.page-live2020 .stories-three-columns .row .col .box,
body.page-destination .stories-three-columns .row .col .box {
  margin-bottom: 30px;
}

body.page-live .stories-three-columns .row .col .box img,
body.page-live2019 .stories-three-columns .row .col .box img,
body.page-live2020 .stories-three-columns .row .col .box img,
body.page-destination .stories-three-columns .row .col .box img {
  width: 220px;
  height: auto;
}

@media (max-width: 991px) {
  body.page-live .stories-three-columns .row .col .box img,
  body.page-live2019 .stories-three-columns .row .col .box img,
  body.page-live2020 .stories-three-columns .row .col .box img,
  body.page-destination .stories-three-columns .row .col .box img {
    width: 150px;
  }
}

@media (max-width: 554px) {
  body.page-live .stories-three-columns .row .col .box img,
  body.page-live2019 .stories-three-columns .row .col .box img,
  body.page-live2020 .stories-three-columns .row .col .box img,
  body.page-destination .stories-three-columns .row .col .box img {
    width: 100%;
  }
}

body.page-live .stories-three-columns .row .col .box .story-text,
body.page-live2019 .stories-three-columns .row .col .box .story-text,
body.page-live2020 .stories-three-columns .row .col .box .story-text,
body.page-destination .stories-three-columns .row .col .box .story-text {
  font-weight: 600;
  font-size: 1.4rem;
  margin: 0 auto;
  width: 250px;
  padding-top: 17px;
  text-align: center;
}

@media (max-width: 991px) {
  body.page-live .stories-three-columns .row .col .box .story-text,
  body.page-live2019 .stories-three-columns .row .col .box .story-text,
  body.page-live2020 .stories-three-columns .row .col .box .story-text,
  body.page-destination .stories-three-columns .row .col .box .story-text {
    width: 150px;
  }
}

@media (max-width: 425px) {
  body.page-live .stories-three-columns .row .col .box .story-text,
  body.page-live2019 .stories-three-columns .row .col .box .story-text,
  body.page-live2020 .stories-three-columns .row .col .box .story-text,
  body.page-destination .stories-three-columns .row .col .box .story-text {
    width: 250px;
  }
}

body.page-live a:hover,
body.page-live2020 a:hover {
  color: #24ff25;
}

body.page-live span.header-logotype,
body.page-live2020 span.header-logotype {
  color: #00ff01 !important;
}

body.page-live .mag-box .box:hover,
body.page-live2020 .mag-box .box:hover {
  background: #24ff25;
}

body.page-live .mag-box .box-2,
body.page-live2020 .mag-box .box-2 {
  background: #00ff01;
}

body.page-live #mainNavLive .navbar-brand,
body.page-live2020 #mainNavLive .navbar-brand {
  color: #00ff01;
}

body.page-live #mainNavLive .navbar-nav > li > a.active, body.page-live #mainNavLive .navbar-nav > li > a:hover,
body.page-live2020 #mainNavLive .navbar-nav > li > a.active,
body.page-live2020 #mainNavLive .navbar-nav > li > a:hover {
  color: #00ff01 !important;
}

body.page-live #mainNavLive .navbar-nav > li > a:focus:hover,
body.page-live2020 #mainNavLive .navbar-nav > li > a:focus:hover {
  color: #00ff01;
}

body.page-live #mainNavLive .navbar-nav .nav-item .wrap-year-select .dropdown .dropdown-menu .dropdown-item:hover,
body.page-live2020 #mainNavLive .navbar-nav .nav-item .wrap-year-select .dropdown .dropdown-menu .dropdown-item:hover {
  color: #00ff01;
}

@media (min-width: 992px) {
  body.page-live #mainNavLive.navbar-shrink .navbar-brand:focus, body.page-live #mainNavLive.navbar-shrink .navbar-brand:hover,
  body.page-live2020 #mainNavLive.navbar-shrink .navbar-brand:focus,
  body.page-live2020 #mainNavLive.navbar-shrink .navbar-brand:hover {
    color: #00ff01;
  }
  body.page-live #mainNavLive.navbar-shrink .navbar-nav > li > a:focus:hover,
  body.page-live2020 #mainNavLive.navbar-shrink .navbar-nav > li > a:focus:hover {
    color: #00ff01;
  }
  body.page-live #mainNavLive.navbar-shrink .navbar-nav > li > a:hover,
  body.page-live2020 #mainNavLive.navbar-shrink .navbar-nav > li > a:hover {
    color: #00ff01;
  }
}

body.page-live section.general,
body.page-live2020 section.general {
  border-top: 10px solid #00ff01;
}

body.page-live section h4,
body.page-live2020 section h4 {
  color: #00ff01;
}

body.page-live .bg-primary,
body.page-live2020 .bg-primary {
  background: #00ff01;
  background: -webkit-linear-gradient(#00ff01, #d37500);
  background: linear-gradient(#00ff01, #d37500);
}

body.page-live .text-primary,
body.page-live2020 .text-primary {
  color: #00ff01;
}

body.page-live .btn-outline.active, body.page-live .btn-outline:active, body.page-live .btn-outline:focus, body.page-live .btn-outline:hover,
body.page-live2020 .btn-outline.active,
body.page-live2020 .btn-outline:active,
body.page-live2020 .btn-outline:focus,
body.page-live2020 .btn-outline:hover {
  border-color: #00ff01;
  background-color: #00ff01;
}

body.page-live .nav-pills .nav-link.active,
body.page-live2020 .nav-pills .nav-link.active {
  border: 1px solid #00ff01;
  color: #00ff01;
}

body.page-live .fotorama__arr:before,
body.page-live2020 .fotorama__arr:before {
  color: #24ff25;
}

body.page-live .fotorama__arr:before:hover,
body.page-live2020 .fotorama__arr:before:hover {
  color: #00ff01;
}

body.page-live2019 a:hover {
  color: #ff2481;
}

body.page-live2019 span.header-logotype {
  color: #ff006c !important;
}

body.page-live2019 .mag-box .box:hover {
  background: #ff2481;
}

body.page-live2019 .mag-box .box-2 {
  background: #ff006c;
}

body.page-live2019 #mainNavLive .navbar-brand {
  color: #ff006c;
}

body.page-live2019 #mainNavLive .navbar-nav > li > a.active, body.page-live2019 #mainNavLive .navbar-nav > li > a:hover {
  color: #ff006c !important;
}

body.page-live2019 #mainNavLive .navbar-nav > li > a:focus:hover {
  color: #ff006c;
}

body.page-live2019 #mainNavLive .navbar-nav .nav-item .wrap-year-select .dropdown .dropdown-menu .dropdown-item:hover {
  color: #ff006c;
}

@media (min-width: 992px) {
  body.page-live2019 #mainNavLive.navbar-shrink .navbar-brand:focus, body.page-live2019 #mainNavLive.navbar-shrink .navbar-brand:hover {
    color: #ff006c;
  }
  body.page-live2019 #mainNavLive.navbar-shrink .navbar-nav > li > a:focus:hover {
    color: #ff006c;
  }
  body.page-live2019 #mainNavLive.navbar-shrink .navbar-nav > li > a:hover {
    color: #ff006c;
  }
}

body.page-live2019 section.general {
  border-top: 10px solid #ff006c;
}

body.page-live2019 section h4 {
  color: #ff006c;
}

body.page-live2019 .bg-primary {
  background: #ff006c;
  background: -webkit-linear-gradient(#ff006c, #d37500);
  background: linear-gradient(#ff006c, #d37500);
}

body.page-live2019 .text-primary {
  color: #ff006c;
}

body.page-live2019 .btn-outline.active, body.page-live2019 .btn-outline:active, body.page-live2019 .btn-outline:focus, body.page-live2019 .btn-outline:hover {
  border-color: #ff006c;
  background-color: #ff006c;
}

body.page-live2019 .nav-pills .nav-link.active {
  border: 1px solid #ff006c;
  color: #ff006c;
}

body.page-live2019 .fotorama__arr:before {
  color: #ff2481;
}

body.page-live2019 .fotorama__arr:before:hover {
  color: #ff006c;
}

body.page-destination {
  font-family: 'Eczar', serif;
  font-weight: 400;
}

@media (min-width: 1024px) {
  body.page-destination .logotype img {
    width: 300px;
  }
}

@media (min-width: 768px) {
  body.page-destination .fotorama__stage__frame.fotorama__loaded.fotorama__loaded--img.fotorama__active img {
    top: -36px !important;
  }
}

body.page-destination h2, body.page-destination h3 {
  font-weight: 700;
}

body.page-destination a:hover {
  color: #4dd49a;
}

body.page-destination span.header-logotype {
  color: #31cc89 !important;
}

body.page-destination .mag-box .box:hover {
  background: #4dd49a;
}

body.page-destination .mag-box .box-2 {
  background: #31cc89;
}

body.page-destination #mainNavLive .navbar-brand {
  color: #31cc89;
}

body.page-destination #mainNavLive .navbar-nav > li > a.active, body.page-destination #mainNavLive .navbar-nav > li > a:hover {
  color: #31cc89 !important;
}

body.page-destination #mainNavLive .navbar-nav > li > a:focus:hover {
  color: #31cc89;
}

body.page-destination #mainNavLive .navbar-nav .nav-item .wrap-year-select .dropdown .dropdown-menu .dropdown-item:hover {
  color: #31cc89;
}

@media (min-width: 992px) {
  body.page-destination #mainNavLive.navbar-shrink .navbar-brand:focus, body.page-destination #mainNavLive.navbar-shrink .navbar-brand:hover {
    color: #31cc89;
  }
  body.page-destination #mainNavLive.navbar-shrink .navbar-nav > li > a:focus:hover {
    color: #31cc89;
  }
  body.page-destination #mainNavLive.navbar-shrink .navbar-nav > li > a:hover {
    color: #31cc89;
  }
}

body.page-destination section.general {
  border-top: 10px solid #31cc89;
}

body.page-destination section.general .stories-three-columns .row .col .box img {
  width: 300px;
  height: auto;
}

@media (max-width: 1024px) {
  body.page-destination section.general .stories-three-columns .row .col .box img {
    width: 100%;
  }
}

@media (max-width: 991px) {
  body.page-destination section.general .stories-three-columns .row .col .box img {
    width: 150px;
  }
}

@media (max-width: 554px) {
  body.page-destination section.general .stories-three-columns .row .col .box img {
    width: 100%;
  }
}

body.page-destination section.general .stories-three-columns .row .col .box .story-text {
  font-weight: 700;
}

body.page-destination section h4 {
  color: #31cc89;
}

body.page-destination .bg-primary {
  background: #31cc89;
  background: -webkit-linear-gradient(#31cc89, #d37500);
  background: linear-gradient(#31cc89, #d37500);
}

body.page-destination .text-primary {
  color: #31cc89;
}

body.page-destination .btn-outline.active, body.page-destination .btn-outline:active, body.page-destination .btn-outline:focus, body.page-destination .btn-outline:hover {
  border-color: #31cc89;
  background-color: #31cc89;
}

body.page-destination .nav-pills .nav-link.active {
  border: 1px solid #31cc89;
  color: #31cc89;
}

body.page-destination .fotorama__arr:before {
  color: #4dd49a;
}

body.page-destination .fotorama__arr:before:hover {
  color: #31cc89;
}

body.page-destination .navbar .toplogo img {
  width: auto;
  height: 18px;
}

body.page-destination .preload-cover-chi {
  display: none;
}

body.page-destination footer {
  margin-top: 200px;
}

@media (max-width: 500px) {
  body.page-destination footer {
    margin-top: 30px;
  }
}

#cny2019 a {
  color: #363a94;
  text-decoration: underline;
}

#cny2019 a:hover {
  text-decoration: none;
}

#cny2019 section {
  padding: 25px 0 50px;
}

#cny2019 section.general {
  position: relative;
  padding: 50px 0 50px;
  overflow: hidden;
}

#cny2019 section.top {
  padding: 0;
}

#cny2019 section.top .top-bg {
  background: url(https://media.edgeprop.my/campaign/cny2019_d2.jpg) no-repeat center;
  background-size: cover;
  height: 685px;
}

@media (max-width: 768px) {
  #cny2019 section.top .top-bg {
    background: url(https://media.edgeprop.my/campaign/cny2019_t.jpg) no-repeat center;
    height: 390px;
  }
}

@media (max-width: 425px) {
  #cny2019 section.top .top-bg {
    background: url(https://media.edgeprop.my/campaign/cny2019_m.jpg) no-repeat center;
    height: 238px;
  }
}

#cny2019 section.how-to-win .title {
  font-weight: 800;
  font-size: 1.7rem;
  margin: 4rem 0;
}

#cny2019 section.how-to-win .title-1 {
  color: #005c98;
}

#cny2019 section.how-to-win .title-2 {
  color: #f9cc13;
}

#cny2019 section ul, #cny2019 section ol {
  text-align: left;
}

@media (min-width: 992px) {
  #cny2019 section ul, #cny2019 section ol {
    margin: 0;
    padding: 0;
  }
}

#cny2019 section ul li, #cny2019 section ol li {
  margin-bottom: 10px;
}

#cny2019 section ul li span.step-title, #cny2019 section ol li span.step-title {
  text-transform: uppercase;
  display: block;
  font-weight: 600;
  font-size: 2rem;
}

@media (min-width: 992px) {
  #cny2019 section ul.cny-ul, #cny2019 section ol.cny-ul {
    min-height: 265px;
  }
}

#cny2019 section ul li {
  margin-bottom: 25px;
}

#cny2019 h2, #cny2019 h3 {
  text-transform: uppercase;
}

#cny2019 h2 {
  margin-bottom: 1rem;
  font-size: 4rem;
  font-weight: 800;
}

#cny2019 h2 span.sub-h2 {
  text-transform: none;
  font-size: 2rem;
  font-weight: 400;
  display: block;
  margin: 1.5rem 0 2.5rem;
}

@media (min-width: 992px) {
  #cny2019 h2 {
    width: 70%;
    margin: 0 auto;
  }
}

#cny2019 h3 {
  font-size: 1.7rem;
  margin-bottom: 25px;
}

#cny2019 footer.subscribe {
  margin: 35px 0;
}

#cny2019 footer.subscribe .cny-submit h2 {
  font-size: 2rem;
}

@media (min-width: 992px) {
  #cny2019 footer.subscribe .cny-submit h2 {
    width: 55%;
    margin: 40px auto 20px;
  }
}

#cny2019 footer.subscribe .cny-submit .area-email-alternative {
  margin: 20px 0 40px;
  font-size: 1.5rem;
}

@media (min-width: 992px) {
  #cny2019 footer.subscribe .sub-title {
    width: 60%;
    margin: 0 auto;
  }
}

#cny2019 footer.subscribe .subscribe-input {
  margin: 20px 0;
}

#cny2019 footer.subscribe .subscribe-input .btn-subscribe-input button {
  width: 100%;
}

#cny2019 footer.subscribe .subscribe-input input {
  padding: 6px;
}

#cny2019 footer.subscribe .area-email-wrapper {
  display: block;
  overflow: auto;
}

#cny2019 .btn-cny-2019 {
  background: #ff5122;
  color: #fff;
  padding: 20px 25px;
  font-weight: 800;
}

#cny2019 .btn-cny-2019:hover {
  background: #fff;
  color: #ff5122;
  border: 1px solid #ff5122;
}

#cny2019 .btn-cny-2019 a {
  text-decoration: none;
  color: #fff;
}

#cny2019 .btn-cny-2019 a:hover {
  color: #ff5122;
}

#cny2019 .btn-cny-sm, #cny2019 .btn-cny-sm-gr {
  background: #ff5122;
  color: #fff;
}

#cny2019 .btn-cny-sm:hover, #cny2019 .btn-cny-sm-gr:hover {
  background: #fff;
  color: #ff5122;
  border: 1px solid #ff5122;
}

#cny2019 .btn-cny-sm-gr {
  background: #464a3c;
}

#cny2019 .btn-cny-sm-gr:hover {
  background: #fff;
  color: #464a3c;
  border: 1px solid #464a3c;
}

#cny2019 .btn-border-radius-no {
  border-radius: 0;
}

#cny2019 input {
  width: 100%;
}

#dkn-page {
  font-family: inherit;
}

#dkn-page .dkn-top {
  text-align: center;
  height: 48px;
  padding: 7px 0;
  display: block;
  overflow: hidden;
  background: #fff;
}

#dkn-page img {
  width: 100%;
}

#dkn-page a {
  color: #000;
  -webkit-transition: all .3s;
  -moz-transition: all .3s;
  transition: all 0.3s;
}

#dkn-page a:hover {
  color: #ffff83;
  text-decoration: none;
}

#dkn-page hr {
  max-width: 100px;
  margin: 25px auto 0;
  border-width: 1px;
  border-color: rgba(34, 34, 34, 0.1);
}

#dkn-page hr.light {
  border-color: #fff;
}

#dkn-page h1, #dkn-page h2, #dkn-page h3, #dkn-page h4, #dkn-page h5, #dkn-page h6 {
  color: #000;
  font-family: inherit;
  font-weight: 700;
}

#dkn-page h3 {
  font-size: 4rem;
  line-height: 1;
  margin-bottom: 30px;
  text-transform: initial;
}

@media (max-width: 768px) {
  #dkn-page h3 {
    font-size: 2.6rem;
  }
}

#dkn-page p {
  font-size: 1.6rem;
  line-height: 1.5;
  margin-bottom: 20px;
}

@media (max-width: 768px) {
  #dkn-page p {
    font-size: 1.3rem;
    line-height: 1.4;
  }
}

#dkn-page nav#lang {
  max-width: 985px;
  width: 90%;
  margin: 0 auto;
  font-size: 1.5rem;
  border-bottom: 1px solid #000;
}

#dkn-page nav#lang .nav-tabs {
  width: 100px;
  border-bottom: 0;
}

#dkn-page nav#lang .nav-link a:hover {
  color: inherit;
}

#dkn-page nav#lang .nav-link.active {
  background: transparent;
  border-color: #000 #000 #ffff5f;
  font-weight: 700;
}

#dkn-page nav#lang .nav-link:hover {
  border-color: #000;
  border-bottom: 1px solid #ffff5f;
  font-weight: 700;
  color: #000;
}

#dkn-page section {
  padding: 50px 0;
}

#dkn-page section li {
  font-size: 1.6rem;
  margin-bottom: 9px;
}

@media (max-width: 768px) {
  #dkn-page section li {
    font-size: 1.3rem;
    margin-bottom: 7px;
  }
}

#dkn-page section.general {
  border-top: 10px solid #ffff5f;
  position: relative;
  padding: 50px 0 50px;
  overflow: hidden;
}

#dkn-page section.general .overlay-header {
  width: 100%;
  background-color: rgba(0, 0, 0, 0.6);
  position: absolute;
  font-size: 68px;
  font-weight: 200;
  font-family: inherit;
  font-style: italic;
  color: #fff;
  text-transform: capitalize;
  text-align: center;
  padding: 71px;
}

#dkn-page section.general header {
  height: 244px;
  margin-bottom: 30px;
  background-position: center;
}

#dkn-page section.general.no-border {
  border-top: 0;
}

#dkn-page section.general.p-t-25 {
  padding-top: 25px;
}

#dkn-page section.home {
  padding: 0;
  background: url("https://media.edgeprop.my/page/dkn/header-dkn-sepia.jpg") no-repeat;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  display: block;
  overflow: hidden;
}

#dkn-page section.home .overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
}

#dkn-page section.home .home-text {
  background: #ffff5f;
  font-weight: 700;
  font-size: 5rem;
  line-height: 1;
  margin: 20px 0 0 15px;
  width: 100%;
  max-width: 380px;
  height: auto;
  padding: 57px 20px;
  color: #000;
}

@media (max-width: 768px) {
  #dkn-page section.home .home-text {
    font-size: 3rem;
  }
}

@media (max-width: 490px) {
  #dkn-page section.home .home-text {
    width: 100%;
    max-width: 100%;
    padding: 20px;
    margin: 20px 0;
  }
}

#dkn-page section.home .priority {
  background: rgba(255, 255, 255, 0.85);
  font-weight: 700;
  font-size: 5rem;
  line-height: 1;
  margin: 20px 0 0 15px;
  height: auto;
  padding: 15px 20px 20px;
  color: #000;
  text-align: center;
}

@media (min-width: 992px) {
  #dkn-page section.home .priority {
    float: right;
    width: 70%;
    position: relative;
    top: 45px;
  }
}

@media (min-width: 768px) and (max-width: 768px) {
  #dkn-page section.home .priority {
    position: relative;
    top: 15px;
  }
}

@media (max-width: 490px) {
  #dkn-page section.home .priority {
    width: 100%;
    padding: 20px;
    margin: 20px 0;
    font-size: 4rem;
  }
}

#dkn-page section.home .priority img.eplogo {
  width: 100%;
  max-width: 154px;
  height: auto;
  vertical-align: -webkit-baseline-middle;
}

@media (min-width: 992px) {
  #dkn-page section.home .priority img.eplogo {
    position: relative;
    top: 16px;
  }
}

#dkn-page section.home .priority img.kpktlogo {
  width: 100%;
  max-width: 108px;
  height: auto;
}

#dkn-page section.home .priority span.dkn-priority-text {
  color: #000 !important;
  font-size: 1.4rem;
  font-weight: 500;
  margin: 10px 0 0;
  line-height: 1.2;
  padding: 10px;
}

@media (min-width: 992px) {
  #dkn-page section.home .priority span.dkn-priority-text:hover {
    background: #ffff5f;
    color: #000;
  }
}

@media (max-width: 768px) {
  #dkn-page section.home .priority span.dkn-priority-text {
    font-size: 1.1rem;
    top: 12px;
  }
}

#dkn-page section.home .news-area {
  margin: 40px 0 20px;
  display: block;
  overflow: hidden;
}

#dkn-page section.home .news-area h1 {
  color: #fff;
  border-bottom: 1px solid #fff;
  padding: 0 0 10px 0;
  margin: 20px 0;
  font-size: 2rem;
}

@media (max-width: 490px) {
  #dkn-page section.home .news-area {
    margin: 10px 0 20px;
  }
  #dkn-page section.home .news-area .col-6 {
    float: left;
  }
}

#dkn-page section.home .news-area img {
  width: 100%;
  border-bottom: 1px solid #ccc;
}

#dkn-page section.home .news-area a:hover {
  color: inherit;
}

#dkn-page section.home .news-area .news-title {
  display: block;
  font-size: 1.2rem;
  background: #fff;
  min-height: 120px;
  padding: 15px;
  background: #fff;
}

#dkn-page section.home .news-area .news-title .meta {
  display: block;
  font-weight: 600;
}

#dkn-page section.home .news-area .news-title:hover {
  background: #ffff5f;
}

@media (min-width: 991px) {
  #dkn-page section.home .news-area .news-title {
    min-height: 138px;
  }
}

@media (max-width: 1024px) {
  #dkn-page section.home .news-area .news-title {
    font-size: 1.1rem;
    padding: 10px;
    margin-bottom: 15px;
  }
}

@media (max-width: 320px) {
  #dkn-page section.home .news-area .news-title {
    min-height: 140px;
  }
}

#dkn-page section.home .news-see-more {
  color: #fff;
  margin: 0 0 20px;
  font-size: 2rem;
}

#dkn-page section.home .news-see-more a, #dkn-page section.home .news-see-more a:active {
  text-decoration: none;
  color: #fff;
}

#dkn-page section.home .news-see-more a:hover, #dkn-page section.home .news-see-more a:active:hover {
  text-decoration: underline;
}

#dkn-page section.home .news-see-more a i, #dkn-page section.home .news-see-more a:active i {
  font-size: 1.4rem;
}

#dkn-page section.clusters .cluster-icon {
  margin-bottom: 25px;
  cursor: pointer;
}

#dkn-page section.clusters .cluster-icon:hover img {
  opacity: 0.5;
}

#dkn-page section.clusters .cluster-icon img {
  margin-bottom: 10px;
  max-width: 170px;
}

#dkn-page section.clusters .cluster-title {
  font-size: 1.5rem;
  font-weight: 500;
  line-height: 1.2;
}

@media (max-width: 490px) {
  #dkn-page section.clusters .cluster-title {
    font-size: 1rem;
    line-height: 1.1;
  }
}

@media (min-width: 375px) and (max-width: 375px) {
  #dkn-page section.clusters .col-6 {
    flex: none;
    max-width: 49%;
    float: none;
  }
}

@media (max-width: 320px) {
  #dkn-page section.clusters .col-6 {
    flex: none;
    max-width: 100%;
  }
}

#dkn-page section.what-is-dkn {
  background: #ffff5f;
}

#dkn-page section.what-is-dkn ul {
  padding: 0 0 0 16px;
}

#dkn-page section.problem {
  background: #bfffde;
}

#dkn-page section.wheretohelp {
  background: #bfffde;
}

#dkn-page section.wheretohelp .col-md-6.statename p {
  font-weight: 800;
  margin-bottom: 0px;
}

@media (min-width: 768px) and (max-width: 768px) {
  #dkn-page section.wheretohelp .col-md-6 {
    float: left;
  }
}

#dkn-page section.wheretohelp ul {
  margin: 0 0 20px 0;
  padding: 0 0 0 18px;
}

#dkn-page section.wheretohelp ul li {
  margin-bottom: 0px;
  font-size: 1.4rem;
}

#dkn-page .m-b-20 {
  margin-bottom: 20px;
}

#dkn-page .no-float {
  float: none;
}

#dkn-page footer .footer-top {
  text-align: center;
  color: #222;
  background: #ffff5f;
  padding: 15px 0;
}

#dkn-page footer .footer-top span {
  display: block;
}

#dkn-page footer .footer-top span:first-child {
  font-size: 4rem;
  font-weight: 700;
}

@media (max-width: 490px) {
  #dkn-page footer .footer-top span:first-child {
    font-size: 2rem;
  }
}

#dkn-page footer .footer-top span:nth-child(2) {
  font-size: 3.2rem;
  line-height: 1.1;
  margin-top: 15px;
}

@media (max-width: 490px) {
  #dkn-page footer .footer-top span:nth-child(2) {
    font-size: 1.5rem;
  }
}

#dkn-page footer a:hover {
  text-decoration: underline;
  color: inherit;
}

#dkn-page .volnow {
  padding: 1rem;
  font-size: 1.5rem;
  font-weight: 700;
  text-transform: uppercase;
  background: #ffff5f;
  border: 0;
}

@media (min-width: 991px) {
  #dkn-page .volnow {
    padding: 1rem 4rem;
  }
}

#dkn-page .volnow:hover {
  background: #f3f300;
}

#dkn-page #stickyNav {
  background-color: #333;
  border-color: rgba(34, 34, 34, 0.05);
  background-color: #000;
  -webkit-transition: all .3s;
  -moz-transition: all .3s;
  transition: all .3s;
  font-weight: 200;
  letter-spacing: 1px;
  border-radius: 0;
  margin-bottom: 0;
}

#dkn-page #stickyNav.stick {
  margin-top: 0 !important;
  width: 100%;
  position: fixed;
  top: 0;
  z-index: 10000;
}

#dkn-page #stickyNav .navbar {
  border-radius: 0 !important;
}

#dkn-page #stickyNav .navbar-brand {
  color: #ffff5f;
  font-weight: 200;
  letter-spacing: 1px;
}

#dkn-page #stickyNav .navbar-brand:focus, #dkn-page #stickyNav .navbar-brand:hover {
  color: #b96700;
}

#dkn-page #stickyNav .navbar-toggler {
  font-size: 12px;
  padding: 8px 10px;
  color: #fff;
  position: absolute;
  right: 10px;
  top: 10px;
}

#dkn-page #stickyNav .navbar-nav > li > a {
  font-weight: 500;
  text-transform: uppercase;
  letter-spacing: 0.5px;
  color: #fff;
}

@media (min-width: 1024px) {
  #dkn-page #stickyNav .navbar-nav > li > a {
    font-size: 1.4rem;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  #dkn-page #stickyNav .navbar-nav > li > a {
    font-size: 1.1rem;
    line-height: 1;
  }
}

#dkn-page #stickyNav .navbar-nav > li > a.active {
  color: #ffff5f !important;
  background-color: transparent;
}

#dkn-page #stickyNav .navbar-nav > li > a.active:hover {
  background-color: transparent;
}

#dkn-page #stickyNav .navbar-nav > li > a:focus {
  color: #fff;
}

#dkn-page #stickyNav .navbar-nav > li > a:focus:hover {
  color: #ffff5f;
}

#dkn-page #stickyNav .navbar-nav > li > a:hover {
  color: #ffff5f;
}

@media (min-width: 768px) and (max-width: 768px) {
  #dkn-page #stickyNav .navbar-nav li:nth-child(4) {
    width: 70px;
  }
}

#dkn-page .nav-pills .nav-link.active {
  border: 1px solid #ffff5f;
  color: #ffff5f;
  background-color: transparent;
}

#dkn-page .cluster-popup {
  border: 5px solid #000;
  box-shadow: 1px 7px 30px 2px #bcbcbc;
  z-index: 999;
  display: block;
  background: #ffff5f;
  position: absolute;
  text-align: left;
  width: 92%;
  height: auto;
  padding: 25px;
}

@media (min-width: 991px) {
  #dkn-page .cluster-popup {
    width: 984px;
  }
}

#dkn-page .cluster-popup h1 {
  font-size: 3.2rem;
}

@media (max-width: 768px) {
  #dkn-page .cluster-popup h1 {
    font-size: 1.8rem;
    text-align: center;
  }
}

#dkn-page .cluster-popup li {
  margin-bottom: 14px;
  line-height: 1.1;
  font-size: 1.7rem;
}

@media (max-width: 768px) {
  #dkn-page .cluster-popup li {
    font-size: 1.4rem;
  }
}

#dkn-page .cluster-popup .cluster-close {
  position: absolute;
  right: -5px;
  top: -46px;
  background: #000;
  color: yellow;
  padding: 8px 30px;
  font-weight: 700;
  font-size: 2rem;
}

#dkn-page .cluster-popup button.close span {
  font-size: 5rem;
  font-weight: 100;
  line-height: .6;
}

#dkn-page .cluster-popup img {
  width: 80%;
}

@media (max-width: 768px) {
  #dkn-page .cluster-popup img {
    width: 50%;
    max-width: 100px;
    text-align: center;
    margin: 20px auto;
    display: block;
  }
}

@media (max-width: 768px) {
  #dkn-page .cluster-popup .col-md-7 {
    margin: 0 auto;
  }
}

@media (min-width: 992px) {
  #dkn-page .navbar-expand-lg .navbar-nav .nav-link {
    padding-right: 2rem;
  }
}

#dkn-page ul.list-unstyled li {
  margin-bottom: 20px;
  font-size: 13px;
  color: #666;
}

#dkn-page .ml-auto, #dkn-page .mx-auto {
  margin-left: auto !important;
}

#dkn-page .mr-auto, #dkn-page .mx-auto {
  margin-right: auto !important;
}

#dkn-page .fotorama__wrap {
  margin: 0 auto;
}

#dkn-page .fotorama__caption {
  font-size: 2rem;
  width: 100%;
  margin: 0 auto;
  text-align: center;
}

@media (min-width: 768px) {
  #dkn-page .fotorama__caption {
    bottom: 0;
  }
}

@media (max-width: 767px) {
  #dkn-page .fotorama__caption {
    font-size: 2rem;
    bottom: 0px;
    width: 100%;
  }
}

#dkn-page .fotorama__caption a {
  text-decoration: none;
  border-bottom: 0;
}

#dkn-page .fotorama__caption span {
  display: block;
}

#dkn-page .fotorama__caption span.slider-title {
  font-weight: bold;
  line-height: 1;
  margin-bottom: 6px;
}

@media (max-width: 767px) {
  #dkn-page .fotorama__caption span.slider-title {
    font-size: 1.7rem;
  }
}

#dkn-page .fotorama__caption span.slider-subtitle {
  font-size: 1.6rem;
  line-height: 1.5;
}

@media (max-width: 767px) {
  #dkn-page .fotorama__caption span.slider-subtitle {
    font-size: 1.2rem;
  }
}

#dkn-page .fotorama__caption span.slider-subtitle span.glyphicon {
  display: inline;
  font-size: 1.1rem;
}

@media (min-width: 769px) {
  #dkn-page .fotorama__caption .fotorama__caption__wrap {
    padding: 5px !important;
    width: 100%;
  }
}

#dkn-page .fotorama__arr {
  background: 0 0 !important;
}

#dkn-page .fotorama__arr:before {
  font-family: Ionicons;
  content: "";
  margin-right: 6px;
  font-size: xx-large;
  font-size: 58px;
  content: "";
  margin-right: 0;
  color: #ffff83;
  position: relative;
  top: -30px;
}

#dkn-page .fotorama__arr:before:hover {
  color: #ffff5f;
}

#dkn-page .fotorama__arr.fotorama__arr--next:before {
  content: "";
}

#dkn-page .fotorama__arr.fotorama__arr--prev:before {
  left: 8px;
  content: "";
}

#dkn-page .fotorama .fotorama__html {
  background-color: rgba #000, 0.6;
}

#dkn-page .fotorama .fotorama__html span {
  display: block;
}

#dkn-page .fotorama .caption-element {
  top: 0;
  position: absolute;
  z-index: 999;
  right: 0;
  background: rgba(255, 255, 255, 0.9);
  width: 50%;
  height: 100vh;
}

#dkn-page .fotorama .caption-element span {
  display: block;
}

#dkn-page .fotorama .caption-element span.slider-title {
  font-weight: bold;
  line-height: 1;
  margin-bottom: 6px;
}

#dkn-page .fotorama .caption-element span.slider-subtitle {
  font-size: 2rem;
  line-height: 1.5;
}

@media (max-width: 767px) {
  #dkn-page .fotorama .caption-element span.slider-subtitle {
    font-size: 1.2rem;
  }
}

#dkn-page span {
  display: block;
}

#dkn-page span.caption-title {
  font-size: 1.2rem;
  font-weight: 100;
}

@media (max-width: 768px) {
  #dkn-page .logotype img {
    width: 220px;
  }
}

@media (max-width: 767px) {
  #dkn-page .border-side {
    border-right: 0;
    border-left: 0;
  }
}

@media (min-width: 992px) {
  #dkn-page #stickyNav .navbar-brand {
    color: fade(white, 70%);
  }
  #dkn-page #stickyNav .navbar-brand:focus, #dkn-page #stickyNav .navbar-brand:hover {
    color: #fff;
  }
  #dkn-page #stickyNav .navbar-nav > li > a {
    color: rgba(255, 255, 255, 0.7);
  }
  #dkn-page #stickyNav .navbar-nav > li > a:focus {
    color: rgba(255, 255, 255, 0.7);
  }
  #dkn-page #stickyNav .navbar-nav > li > a:focus:hover {
    color: #fff;
  }
  #dkn-page #stickyNav .navbar-nav > li > a:hover {
    color: #fff;
  }
  #dkn-page #stickyNav.navbar-shrink {
    border-color: rgba(34, 34, 34, 0.1);
    background-color: #000;
    border-radius: 0;
  }
  #dkn-page #stickyNav.navbar-shrink .navbar-brand {
    color: #f9f9f9;
  }
  #dkn-page #stickyNav.navbar-shrink .navbar-brand:focus, #dkn-page #stickyNav.navbar-shrink .navbar-brand:hover {
    color: #ffff5f;
  }
  #dkn-page #stickyNav.navbar-shrink .navbar-nav > li > a {
    color: #f9f9f9;
  }
  #dkn-page #stickyNav.navbar-shrink .navbar-nav > li > a:focus {
    color: #f9f9f9;
  }
  #dkn-page #stickyNav.navbar-shrink .navbar-nav > li > a:focus:hover {
    color: #ffff5f;
  }
  #dkn-page #stickyNav.navbar-shrink .navbar-nav > li > a:hover {
    color: #ffff5f;
  }
}

@media (max-width: 425px) {
  #dkn-page .logotype img {
    width: 130px;
  }
  #dkn-page section.general {
    padding: 10px 0 50px;
  }
  #dkn-page section.general h2 {
    font-size: 28px;
  }
  #dkn-page section.general .overlay-header {
    font-size: 33px;
    padding: 6px;
  }
  #dkn-page section.general header {
    height: 61px;
  }
  #dkn-page section.general header.features-header {
    height: 60px;
  }
  #dkn-page section.issue {
    padding: 0;
  }
  #dkn-page .visible-xs {
    display: block;
  }
}

@media (min-width: 426px) {
  #dkn-page .visible-xs {
    display: none !important;
  }
}

@media (max-width: 991px) {
  #dkn-page .navbar-nav {
    padding-left: 17px;
  }
}

@media (min-width: 768px) and (max-width: 768px) {
  #dkn-page .navbar-nav {
    padding-left: 0px;
  }
}

#dkn-page .col-6 {
  float: left;
}

@media (max-width: 768px) {
  #dkn-page .cluster-popup .col-md-4 {
    max-width: 100%;
  }
}

@media (min-width: 768px) and (max-width: 768px) {
  #dkn-page #cluster_popup5, #dkn-page #cluster_popup6, #dkn-page #cluster_popup7, #dkn-page #cluster_popup8,
  #dkn-page #cluster_popup5_bm, #dkn-page #cluster_popup6_bm, #dkn-page #cluster_popup7_bm, #dkn-page #cluster_popup8_bm {
    top: 279px;
  }
}

@media (max-width: 490px) {
  #dkn-page #cluster_popup3, #dkn-page #cluster_popup4,
  #dkn-page #cluster_popup3_bm, #dkn-page #cluster_popup4_bm {
    top: 263px;
  }
  #dkn-page #cluster_popup5, #dkn-page #cluster_popup6,
  #dkn-page #cluster_popup5_bm, #dkn-page #cluster_popup6_bm {
    top: 477px;
  }
  #dkn-page #cluster_popup7, #dkn-page #cluster_popup8,
  #dkn-page #cluster_popup7_bm, #dkn-page #cluster_popup8_bm {
    top: 710px;
  }
}

@media (max-width: 320px) {
  #dkn-page #cluster_popup2, #dkn-page #cluster_popup2_bm {
    top: 287px;
  }
  #dkn-page #cluster_popup3, #dkn-page #cluster_popup3_bm {
    top: 508px;
  }
  #dkn-page #cluster_popup4, #dkn-page #cluster_popup4_bm {
    top: 723px;
  }
  #dkn-page #cluster_popup5, #dkn-page #cluster_popup5_bm {
    top: 939px;
  }
  #dkn-page #cluster_popup6, #dkn-page #cluster_popup6_bm {
    top: 1164px;
  }
  #dkn-page #cluster_popup7, #dkn-page #cluster_popup7_bm {
    top: 1379px;
  }
  #dkn-page #cluster_popup8, #dkn-page #cluster_popup8_bm {
    top: 1597px;
  }
}

#ehcontest a {
  color: #363a94;
  text-decoration: underline;
}

#ehcontest a:hover {
  text-decoration: none;
}

#ehcontest section {
  padding: 25px 0 50px;
}

#ehcontest section.general {
  position: relative;
  padding: 50px 0 50px;
  overflow: hidden;
}

#ehcontest section.top {
  padding: 0;
}

#ehcontest section.top .top-bg {
  background: url(https://media.edgeprop.my/page/ehcontest/ehc_d.jpg) no-repeat center;
  background-size: cover;
  height: 685px;
}

@media (max-width: 768px) {
  #ehcontest section.top .top-bg {
    background: url(https://media.edgeprop.my/page/ehcontest/ehc_t.jpg) no-repeat center;
    height: 390px;
  }
}

@media (max-width: 425px) {
  #ehcontest section.top .top-bg {
    background: url(https://media.edgeprop.my/page/ehcontest/ehc_m.jpg) no-repeat center;
    height: 238px;
  }
}

@media (max-width: 375px) {
  #ehcontest section.top .top-bg {
    background: url(https://media.edgeprop.my/page/ehcontest/ehc_m1.jpg) no-repeat center;
    margin-top: -23px;
  }
}

@media (max-width: 320px) {
  #ehcontest section.top .top-bg {
    background: url(https://media.edgeprop.my/page/ehcontest/ehc_m3.jpg) no-repeat center;
    margin-top: -37px;
  }
}

#ehcontest section.how-to-win .title {
  font-weight: 800;
  font-size: 1.7rem;
  margin: 4rem 0;
}

#ehcontest section.how-to-win .title-1 {
  color: #005c98;
}

#ehcontest section.how-to-win .title-2 {
  color: #f9cc13;
}

#ehcontest section ul, #ehcontest section ol {
  text-align: left;
}

@media (min-width: 992px) {
  #ehcontest section ul, #ehcontest section ol {
    margin: 0;
    padding: 0;
  }
}

#ehcontest section ul li, #ehcontest section ol li {
  margin-bottom: 10px;
}

#ehcontest section ul li span.step-title, #ehcontest section ol li span.step-title {
  text-transform: uppercase;
  display: block;
  font-weight: 600;
  font-size: 2rem;
  color: #005c98;
}

#ehcontest section ul.ehc-ul, #ehcontest section ol.ehc-ul {
  text-align: center;
}

@media (min-width: 992px) {
  #ehcontest section ul.ehc-ul, #ehcontest section ol.ehc-ul {
    min-height: 265px;
  }
}

#ehcontest section ul li {
  margin-bottom: 25px;
}

#ehcontest h2, #ehcontest h3 {
  text-transform: uppercase;
}

#ehcontest h2 {
  margin-bottom: 1rem;
  font-size: 4rem;
  font-weight: 800;
}

#ehcontest h2 span.sub-h2 {
  text-transform: none;
  font-size: 2rem;
  font-weight: 400;
  display: block;
  margin: 1.5rem 0 2.5rem;
}

@media (min-width: 992px) {
  #ehcontest h2 {
    width: 70%;
    margin: 0 auto;
  }
}

#ehcontest h3 {
  font-size: 1.7rem;
  margin-bottom: 25px;
}

#ehcontest .btn-ehc {
  background: #ff5122;
  color: #fff;
  padding: 20px 25px;
  font-weight: 800;
}

@media (min-width: 1024px) {
  #ehcontest .btn-ehc {
    margin-top: -35px;
  }
}

#ehcontest .btn-ehc:hover {
  background: #fff;
  color: #ff5122;
  border: 1px solid #ff5122;
}

#ehcontest .btn-ehc a {
  text-decoration: none;
  color: #fff;
}

#ehcontest .btn-ehc a:hover {
  color: #ff5122;
}

#ehcontest .btn-border-radius-no {
  border-radius: 0;
}

#ehcontest input {
  width: 100%;
}

#startcontest a {
  color: #363a94;
  text-decoration: underline;
}

#startcontest a:hover {
  text-decoration: none;
}

#startcontest section {
  padding: 25px 0 50px;
}

#startcontest section.general {
  position: relative;
  padding: 50px 0 50px;
  overflow: hidden;
}

#startcontest section.top {
  padding: 0;
}

#startcontest section.top .top-bg {
  background: url(https://media.edgeprop.my/page/ehcontest/ehc_d.jpg) no-repeat center;
  background-size: cover;
  height: 685px;
}

@media (max-width: 768px) {
  #startcontest section.top .top-bg {
    background: url(https://media.edgeprop.my/page/ehcontest/ehc_t.jpg) no-repeat center;
    height: 390px;
  }
}

@media (max-width: 425px) {
  #startcontest section.top .top-bg {
    background: url(https://media.edgeprop.my/page/ehcontest/ehc_m.jpg) no-repeat center;
    height: 238px;
  }
}

@media (max-width: 375px) {
  #startcontest section.top .top-bg {
    background: url(https://media.edgeprop.my/page/ehcontest/ehc_m1.jpg) no-repeat center;
    margin-top: -23px;
  }
}

@media (max-width: 320px) {
  #startcontest section.top .top-bg {
    background: url(https://media.edgeprop.my/page/ehcontest/ehc_m3.jpg) no-repeat center;
    margin-top: -37px;
  }
}

#startcontest section.how-to-win .title {
  font-weight: 800;
  font-size: 1.7rem;
  margin: 4rem 0;
}

#startcontest section.how-to-win .title-1 {
  color: #005c98;
}

#startcontest section.how-to-win .title-2 {
  color: #f9cc13;
}

#startcontest section ul, #startcontest section ol {
  text-align: left;
}

@media (min-width: 992px) {
  #startcontest section ul, #startcontest section ol {
    margin: 0;
    padding: 0;
  }
}

#startcontest section ul li, #startcontest section ol li {
  margin-bottom: 10px;
}

#startcontest section ul li span.step-title, #startcontest section ol li span.step-title {
  text-transform: uppercase;
  display: block;
  font-weight: 600;
  font-size: 2rem;
  color: #005c98;
}

#startcontest section ul.ehc-ul, #startcontest section ol.ehc-ul {
  text-align: center;
}

@media (min-width: 992px) {
  #startcontest section ul.ehc-ul, #startcontest section ol.ehc-ul {
    min-height: 265px;
  }
}

#startcontest section ul li {
  margin-bottom: 25px;
}

#startcontest h2, #startcontest h3 {
  text-transform: uppercase;
}

#startcontest h2 {
  margin-bottom: 1rem;
  font-size: 4rem;
  font-weight: 800;
}

#startcontest h2 span.sub-h2 {
  text-transform: none;
  font-size: 2rem;
  font-weight: 400;
  display: block;
  margin: 1.5rem 0 2.5rem;
}

@media (min-width: 992px) {
  #startcontest h2 {
    width: 70%;
    margin: 0 auto;
  }
}

#startcontest h3 {
  font-size: 1.7rem;
  margin-bottom: 25px;
}

#startcontest .btn-ehc {
  background: #ff5122;
  color: #fff;
  padding: 20px 25px;
  font-weight: 800;
}

@media (min-width: 1024px) {
  #startcontest .btn-ehc {
    margin-top: -35px;
  }
}

#startcontest .btn-ehc:hover {
  background: #fff;
  color: #ff5122;
  border: 1px solid #ff5122;
}

#startcontest .btn-ehc a {
  text-decoration: none;
  color: #fff;
}

#startcontest .btn-ehc a:hover {
  color: #ff5122;
}

#startcontest .btn-border-radius-no {
  border-radius: 0;
}

#startcontest input {
  width: 100%;
}

#awards2019 {
  font-family: "poppins", serif;
  color: #fff;
}

#awards2019 .col-md-4 {
  text-align: center;
}

#awards2019 select#select-years {
  background: transparent;
  border-radius: 0;
  color: #fff;
  font-size: 1.2rem;
  height: 29px;
  position: relative;
  top: 10px;
}

@media (max-width: 767px) {
  #awards2019 select#select-years {
    top: 0;
  }
}

#awards2019 select#select-years option {
  background: transparent;
  color: #333;
}

@media (min-width: 768px) and (max-width: 991px) {
  #awards2019 select#select-years {
    top: 6px;
  }
}

#awards2019 img {
  width: 100%;
}

#awards2019 a {
  -webkit-transition: all .3s;
  -moz-transition: all .3s;
  transition: all 0.3s;
}

#awards2019 a:hover {
  color: #fdc436;
  text-decoration: none;
}

#awards2019 hr {
  max-width: 100px;
  margin: 25px auto 0;
  border-width: 1px;
  border-color: rgba(34, 34, 34, 0.1);
}

#awards2019 hr.light {
  border-color: #fff;
}

#awards2019 h1, #awards2019 h2, #awards2019 h3, #awards2019 h4, #awards2019 h5, #awards2019 h6 {
  font-family: "poppins", serif;
  text-transform: uppercase;
  font-weight: 700;
}

#awards2019 h1, #awards2019 h3 {
  text-align: center;
  padding: 0 0 20px 0;
  margin: 0 0 20px 0;
}

#awards2019 h3 {
  font-weight: 200;
}

#awards2019 .sub-header {
  text-align: center;
  font-size: 2rem;
  padding: 20px 0 30px;
  width: 60%;
  margin: 0 auto;
}

#awards2019 p {
  font-size: 1.6rem;
  line-height: 1.5;
  margin-bottom: 20px;
}

@media (max-width: 768px) {
  #awards2019 p {
    font-size: 1.3rem;
    line-height: 1.4;
  }
}

#awards2019 nav#winner-tab {
  margin: 0 auto 20px;
  font-size: 1.5rem;
  border-bottom: 1px solid #000;
}

#awards2019 nav#winner-tab .nav-tabs {
  border-bottom: 0;
}

#awards2019 nav#winner-tab .nav-fill a.nav-link {
  line-height: 1.3;
  background: transparent;
  border-color: transparent;
  color: #fff;
  border-bottom: 1px solid #03001c;
}

#awards2019 nav#winner-tab .nav-fill a.nav-link.active, #awards2019 nav#winner-tab .nav-fill a.nav-link:hover {
  border-bottom: 3px solid #ffe4a0;
  color: #ffe4a0;
}

@media (min-width: 768px) {
  #awards2019 nav#winner-tab .nav-fill a.nav-link {
    width: 20%;
  }
}

@media (max-width: 576px) {
  #awards2019 nav#winner-tab .nav-fill a.nav-link {
    font-size: 1.2rem;
  }
}

@media (max-width: 490px) {
  #awards2019 nav#winner-tab .nav-fill a.nav-link {
    font-size: 1.2rem;
    padding: .5rem .1rem;
  }
}

@media (min-width: 991px) {
  #awards2019 nav#winner-tab {
    width: 80%;
    margin-bottom: 40px;
  }
}

#awards2019 section {
  background: #000;
  padding: 50px 0;
}

#awards2019 section li {
  font-size: 1.6rem;
  margin-bottom: 9px;
}

@media (max-width: 768px) {
  #awards2019 section li {
    font-size: 1.3rem;
    margin-bottom: 7px;
  }
}

#awards2019 section.general {
  border-top: 10px solid #fdb913;
  position: relative;
  padding: 50px 0 50px;
  overflow: hidden;
}

#awards2019 section.general header {
  height: 244px;
  margin-bottom: 30px;
  background-position: center;
}

#awards2019 section.general.no-border {
  border-top: 0;
}

#awards2019 section.general.p-t-25 {
  padding-top: 25px;
}

#awards2019 section.home {
  padding: 0;
  height: 428px;
  background: url("https://media.edgeprop.my/page/bmpa/2019/bmpa-hero.jpg") no-repeat;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  background-position: center -89px;
  display: block;
  overflow: hidden;
}

@media (min-width: 1600px) {
  #awards2019 section.home {
    height: 547px;
    background-position: center -57px;
  }
}

@media (min-width: 1564px) and (max-width: 1564px) {
  #awards2019 section.home {
    height: 504px;
  }
}

@media (min-width: 2000px) {
  #awards2019 section.home {
    height: 626px;
    background-position: center -139px;
  }
}

@media (max-width: 1024px) {
  #awards2019 section.home {
    height: 357px;
    background-position: center -57px;
  }
}

@media (max-width: 768px) {
  #awards2019 section.home {
    height: 387px;
    background-position: center 0;
  }
}

@media (max-width: 576px) {
  #awards2019 section.home {
    height: 171px;
    background-position: center -44px;
  }
}

@media (max-width: 490px) {
  #awards2019 section.home {
    height: 164px;
    background-position: center -30px;
  }
}

@media (max-width: 425px) {
  #awards2019 section.home {
    height: 151px;
    background-position: center -24px;
  }
}

@media (max-width: 414px) {
  #awards2019 section.home {
    height: 159px;
    background-position: center -20px;
  }
}

@media (max-width: 375px) {
  #awards2019 section.home {
    height: 148px;
    background-position: center -16px;
  }
}

@media (max-width: 320px) {
  #awards2019 section.home {
    height: 121px;
    background-position: center -19px;
  }
}

#awards2019 section.home .kpkt-endorsement {
  width: 142px;
  position: relative;
  right: 11px;
  float: right;
  top: 34px;
}

@media (max-width: 767px) {
  #awards2019 section.home .kpkt-endorsement {
    width: 64px;
    top: 0;
    right: 32px;
  }
}

@media (max-width: 320px) {
  #awards2019 section.home .kpkt-endorsement {
    width: 49px;
    right: 21px;
  }
}

#awards2019 section.featured .featured-icon {
  margin-bottom: 25px;
  cursor: pointer;
}

#awards2019 section.featured .featured-icon:hover img {
  opacity: 0.5;
}

#awards2019 section.featured .featured-icon img {
  margin-bottom: 10px;
  max-width: 170px;
}

#awards2019 section.featured .featured-title {
  font-size: 1.5rem;
  font-weight: 500;
  line-height: 1.2;
}

@media (max-width: 490px) {
  #awards2019 section.featured .featured-title {
    font-size: 1rem;
    line-height: 1.1;
  }
}

@media (min-width: 375px) and (max-width: 375px) {
  #awards2019 section.featured .col-6 {
    flex: none;
    max-width: 49%;
    float: none;
  }
}

@media (max-width: 320px) {
  #awards2019 section.featured .col-6 {
    flex: none;
    max-width: 100%;
  }
}

#awards2019 section.winners ul {
  padding: 0 0 0 16px;
}

#awards2019 section.winners h4 {
  margin: 20px 0;
  padding-bottom: 15px;
  font-family: poppins;
  font-weight: 500;
  font-size: 1.5rem;
  letter-spacing: 1px;
  color: #fff;
}

@media (min-width: 1280px) {
  #awards2019 section.winners h4 {
    font-size: 2rem;
  }
}

#awards2019 section.winners h3 {
  letter-spacing: .8px;
  font-size: 1.6rem;
  font-family: 'poppins';
  font-weight: 600;
  border-top: 1px dotted #999;
  border-bottom: 1px dotted #999;
  padding: 20px;
  color: #fdb913;
}

@media (min-width: 1280px) {
  #awards2019 section.winners h3 {
    letter-spacing: 1px;
    font-size: 2rem;
  }
}

#awards2019 section.judges .judge-text {
  display: block;
  padding: 10px 15px;
  text-align: center;
  margin-bottom: 30px;
}

#awards2019 section.judges .judge-text .judge-title {
  color: #ffe4a0;
  font-size: 1.6rem;
}

@media (max-width: 490px) {
  #awards2019 section.judges .judge-text .judge-title {
    font-weight: 500;
    font-size: 1.3rem;
    line-height: 1.2;
  }
}

#awards2019 section.judges .judge-text .judge-role {
  font-size: 1.2rem;
  line-height: 1.4;
}

@media (max-width: 490px) {
  #awards2019 section.judges .judge-text .judge-role {
    font-size: 1.1rem;
    line-height: 1.3;
  }
}

#awards2019 section.judges img {
  width: 100%;
  max-width: 300px;
  height: auto;
}

#awards2019 section.judges .judge-footnote {
  text-align: center;
  font-size: 1.4rem;
  margin: 20px auto;
  width: 95%;
}

@media (min-width: 768px) {
  #awards2019 section.judges .judge-footnote {
    width: 70%;
  }
}

#awards2019 section.news .news-card .single-card {
  background: #fff;
  margin: 10px;
  padding: 0px;
}

#awards2019 section.news .news-card .single-card img {
  width: 100%;
  height: 151px;
  object-fit: cover;
  margin-bottom: 0;
}

@media (max-width: 768px) {
  #awards2019 section.news .news-card .single-card img {
    height: 100px;
  }
}

#awards2019 section.news .news-card .single-card .news-text {
  padding: 10px 10px 1px;
  min-height: 104px;
}

#awards2019 section.news .news-card .single-card .news-text .news-title {
  font-size: 1.3rem;
  line-height: 1.3;
  color: #414141;
  font-weight: 500;
  margin-bottom: 12px;
  overflow: hidden;
  position: relative;
  max-height: 52px;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
}

#awards2019 section.news .news-card .single-card .news-text .news-title a {
  color: inherit;
}

@media (max-width: 576px) {
  #awards2019 section.news .news-card .single-card .news-text .news-title {
    font-size: 1.1rem;
  }
}

#awards2019 section.news .news-card .single-card .news-text p.news-label {
  font-size: 10px;
  text-transform: uppercase;
  line-height: 16px;
  color: #488BF8;
  font-weight: bold;
  margin-bottom: 5px;
}

#awards2019 section.news .news-card .single-card .news-text p.news-date {
  font-size: 10px;
  line-height: 12px;
  color: #393939;
}

#awards2019 section.news .news-card h1 {
  padding: 9px 0;
  font-size: 0.9rem !important;
}

#awards2019 section.news .news-card .more-property-news {
  font-size: 1.5rem;
  margin-top: 10px;
  color: #fff;
  text-align: center;
  /*padding-bottom: 10px;*/
}

#awards2019 section.about h2 {
  font-weight: 500;
  border: 1px solid #fdb913;
  min-height: 146px;
  text-align: center;
  padding: 15px;
  font-size: 1.4rem;
}

#awards2019 section.about .article span.more-detail {
  font-family: 'Poppins';
  font-size: 1.2rem;
  font-weight: 400;
  text-transform: none;
  display: block;
  margin-top: 15px;
}

#awards2019 section.winners h1, #awards2019 section.featured h1 {
  color: #fdb913;
}

@media (min-width: 1280px) {
  #awards2019 section.winners h1, #awards2019 section.featured h1 {
    font-size: 4rem;
    letter-spacing: 2px;
  }
}

#awards2019 section.winners img, #awards2019 section.featured img {
  max-width: 300px;
  margin-bottom: 30px;
}

#awards2019 section.winners img.stpd, #awards2019 section.featured img.stpd {
  max-width: 301px;
}

#awards2019 section.winners, #awards2019 section.news, #awards2019 section.videos {
  background: #03001c;
}

@media (max-width: 576px) {
  #awards2019 section.news .col-6, #awards2019 section.videos .col-6, #awards2019 section.judges .col-6 {
    -webkit-box-flex: unset;
    flex: none;
    float: left;
    width: 48%;
    margin: 0 auto;
    max-width: 200px;
    position: unset;
  }
}

#awards2019 section img.g-img {
  cursor: pointer;
  margin-bottom: 30px;
  width: 100%;
  max-width: 280px;
  height: 170px;
  object-fit: cover;
}

@media (max-width: 1440px) {
  #awards2019 section img.g-img {
    height: 160px;
  }
}

@media (max-width: 1366px) {
  #awards2019 section img.g-img {
    height: 150px;
  }
}

@media (max-width: 1280px) {
  #awards2019 section img.g-img {
    height: 140px;
  }
}

@media (max-width: 1024px) {
  #awards2019 section img.g-img {
    height: 120px;
  }
}

@media (max-width: 768px) {
  #awards2019 section img.g-img {
    height: 86px;
  }
}

@media (max-width: 576px) {
  #awards2019 section img.g-img {
    height: 100px;
    width: 100%;
  }
}

@media (max-width: 375px) {
  #awards2019 section img.g-img {
    height: 86px;
  }
}

@media (max-width: 320px) {
  #awards2019 section img.g-img {
    height: 72px;
  }
}

@media (min-width: 991px) and (max-width: 1024px) {
  #awards2019 section .badge-tablet img {
    max-width: 242px;
  }
  #awards2019 section .badge-tablet img.stpd {
    max-width: 242px;
  }
}

@media (min-width: 768px) and (max-width: 768px) {
  #awards2019 section .badge-tablet img {
    max-width: 188px;
  }
  #awards2019 section .badge-tablet img.stpd {
    max-width: 188px;
  }
}

#awards2019 .m-b-20 {
  margin-bottom: 20px;
}

#awards2019 .no-float {
  float: none;
}

#awards2019 footer a:hover {
  text-decoration: underline;
  color: inherit;
}

#awards2019 footer .footer-logo {
  padding: 0 0 20px;
}

#awards2019 footer .footer-logo .logo-col-sm {
  padding: 10px 10px 10px 0;
}

@media (min-width: 768px) {
  #awards2019 footer .footer-logo .logo-col-sm {
    float: left;
    width: 14.2%;
  }
}

@media (max-width: 767px) {
  #awards2019 footer .footer-logo .logo-col-sm {
    float: left;
    width: 25%;
  }
}

#awards2019 footer .footer-logo img {
  width: 100%;
  position: relative;
}

#awards2019 footer .footer-logo img.present {
  max-width: 250px;
}

@media (min-width: 768px) {
  #awards2019 footer .footer-logo img.present {
    top: -8px;
  }
}

#awards2019 footer .footer-logo img.partner {
  max-width: 130px;
}

#awards2019 footer .footer-logo img.partner-2 {
  top: 12px;
}

@media (min-width: 768px) and (max-width: 768px) {
  #awards2019 footer .footer-logo img.partner-2 {
    top: 12px;
  }
}

#awards2019 footer .footer-logo img.support {
  max-width: 60px;
}

#awards2019 footer .footer-logo img.support-5 {
  top: -17px;
}

#awards2019 footer .footer-logo img.support-6 {
  top: 7px;
}

#awards2019 footer .footer-logo img.support-7 {
  top: -11px;
}

@media (max-width: 767px) {
  #awards2019 footer .footer-logo img {
    text-align: center;
    margin-bottom: 30px;
  }
}

#awards2019 footer .footer-logo .footer-label {
  display: block;
  text-transform: uppercase;
  font-size: 1.1rem;
  font-weight: 500;
  letter-spacing: .8px;
  text-align: left;
  color: #111;
  padding: 15px 10px 0 0;
  margin-bottom: 36px;
}

@media (min-width: 768px) {
  #awards2019 footer .footer-logo .footer-label {
    border-right: 2px solid #111;
  }
  #awards2019 footer .footer-logo .footer-label.lb-present {
    width: 110px;
  }
  #awards2019 footer .footer-logo .footer-label.lb-partner {
    width: 82px;
  }
  #awards2019 footer .footer-logo .footer-label.lb-sp-sponsor {
    width: 167px;
  }
  #awards2019 footer .footer-logo .footer-label.lb-support {
    width: 111px;
  }
}

@media (max-width: 767px) {
  #awards2019 footer .footer-logo .footer-label {
    font-weight: 600;
    font-size: 1.2rem;
    margin-bottom: 0;
    text-align: center;
  }
}

@media (max-width: 990px) {
  #awards2019 footer .footer-logo .footer-label.sm-margin-top {
    margin-top: 30px;
  }
}

#awards2019 #stickyNav {
  background-color: #333;
  border-color: rgba(34, 34, 34, 0.05);
  background-color: #000;
  -webkit-transition: all .3s;
  -moz-transition: all .3s;
  transition: all .3s;
  font-weight: 200;
  letter-spacing: 1px;
  border-radius: 0;
  margin-bottom: 0;
}

#awards2019 #stickyNav.stick {
  margin-top: 0 !important;
  width: 100%;
  position: fixed;
  top: 0;
  z-index: 998;
}

#awards2019 #stickyNav .navbar {
  border-radius: 0 !important;
}

#awards2019 #stickyNav .navbar-brand {
  color: #fdb913;
  font-weight: 200;
  letter-spacing: 1px;
}

#awards2019 #stickyNav .navbar-brand:focus, #awards2019 #stickyNav .navbar-brand:hover {
  color: #b96700;
}

#awards2019 #stickyNav .navbar-toggler {
  font-size: 12px;
  padding: 8px 10px;
  color: #fff;
  position: absolute;
  right: 10px;
  top: 10px;
}

#awards2019 #stickyNav .navbar-nav > li > a {
  font-weight: 500;
  text-transform: uppercase;
  margin-right: 20px;
  color: #fff;
}

@media (min-width: 1024px) {
  #awards2019 #stickyNav .navbar-nav > li > a {
    font-size: 1.4rem;
    margin-right: 5px;
  }
}

@media (max-width: 767px) {
  #awards2019 #stickyNav .navbar-nav > li > a {
    padding: 6px 0;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  #awards2019 #stickyNav .navbar-nav > li > a {
    font-size: 1.1rem;
    line-height: 1;
    margin-right: 0;
  }
}

#awards2019 #stickyNav .navbar-nav > li > a.active {
  color: #fdb913 !important;
  background-color: transparent;
}

#awards2019 #stickyNav .navbar-nav > li > a.active:hover {
  background-color: transparent;
}

#awards2019 #stickyNav .navbar-nav > li > a:focus {
  color: #fff;
}

#awards2019 #stickyNav .navbar-nav > li > a:focus:hover {
  color: #fdb913;
}

#awards2019 #stickyNav .navbar-nav > li > a:hover {
  color: #fdb913;
}

@media (min-width: 768px) and (max-width: 768px) {
  #awards2019 #stickyNav .navbar-nav li:nth-child(4) {
    width: 70px;
  }
}

#awards2019 .nav-pills .nav-link.active {
  border: 1px solid #fdb913;
  color: #fdb913;
  background-color: transparent;
}

@media (min-width: 992px) {
  #awards2019 .navbar-expand-lg .navbar-nav .nav-link {
    padding-right: 2rem;
  }
}

#awards2019 ul.list-unstyled li {
  margin-bottom: 20px;
  font-size: 13px;
  color: #666;
}

#awards2019 .ml-auto, #awards2019 .mx-auto {
  margin-left: auto !important;
}

#awards2019 .mr-auto, #awards2019 .mx-auto {
  margin-right: auto !important;
}

#awards2019 .fotorama__wrap {
  margin: 0 auto;
}

#awards2019 .fotorama__caption {
  font-size: 2rem;
  width: 100%;
  margin: 0 auto;
  text-align: center;
}

@media (min-width: 768px) {
  #awards2019 .fotorama__caption {
    bottom: 0;
  }
}

@media (max-width: 767px) {
  #awards2019 .fotorama__caption {
    font-size: 2rem;
    bottom: 0px;
    width: 100%;
  }
}

#awards2019 .fotorama__caption a {
  text-decoration: none;
  border-bottom: 0;
}

#awards2019 .fotorama__caption span {
  display: block;
}

#awards2019 .fotorama__caption span.slider-title {
  font-weight: bold;
  line-height: 1;
  margin-bottom: 6px;
}

@media (max-width: 767px) {
  #awards2019 .fotorama__caption span.slider-title {
    font-size: 1.7rem;
  }
}

#awards2019 .fotorama__caption span.slider-subtitle {
  font-size: 1.6rem;
  line-height: 1.5;
}

@media (max-width: 767px) {
  #awards2019 .fotorama__caption span.slider-subtitle {
    font-size: 1.2rem;
  }
}

#awards2019 .fotorama__caption span.slider-subtitle span.glyphicon {
  display: inline;
  font-size: 1.1rem;
}

@media (min-width: 769px) {
  #awards2019 .fotorama__caption .fotorama__caption__wrap {
    padding: 5px !important;
    width: 100%;
  }
}

#awards2019 .fotorama__arr {
  background: 0 0 !important;
}

#awards2019 .fotorama__arr:before {
  font-family: Ionicons;
  content: "";
  margin-right: 6px;
  font-size: xx-large;
  font-size: 58px;
  content: "";
  margin-right: 0;
  color: #fdc436;
  position: relative;
  top: -30px;
}

#awards2019 .fotorama__arr:before:hover {
  color: #fdb913;
}

#awards2019 .fotorama__arr.fotorama__arr--next:before {
  content: "";
}

#awards2019 .fotorama__arr.fotorama__arr--prev:before {
  left: 8px;
  content: "";
}

#awards2019 .fotorama .fotorama__html {
  background-color: rgba #000, 0.6;
}

#awards2019 .fotorama .fotorama__html span {
  display: block;
}

#awards2019 .fotorama .caption-element {
  top: 0;
  position: absolute;
  z-index: 999;
  right: 0;
  background: rgba(255, 255, 255, 0.9);
  width: 50%;
  height: 100vh;
}

#awards2019 .fotorama .caption-element span {
  display: block;
}

#awards2019 .fotorama .caption-element span.slider-title {
  font-weight: bold;
  line-height: 1;
  margin-bottom: 6px;
}

#awards2019 .fotorama .caption-element span.slider-subtitle {
  font-size: 2rem;
  line-height: 1.5;
}

@media (max-width: 767px) {
  #awards2019 .fotorama .caption-element span.slider-subtitle {
    font-size: 1.2rem;
  }
}

#awards2019 span {
  display: block;
}

#awards2019 span.caption-title {
  font-size: 1.2rem;
  font-weight: 100;
}

@media (max-width: 767px) {
  #awards2019 .logotype {
    margin: 0 auto;
  }
}

@media (max-width: 768px) {
  #awards2019 .logotype img {
    width: 220px;
  }
}

@media (max-width: 576px) {
  #awards2019 .logotype img {
    width: 195px;
  }
}

@media (max-width: 425px) {
  #awards2019 .logotype img {
    width: 130px;
  }
}

@media (max-width: 767px) {
  #awards2019 .border-side {
    border-right: 0;
    border-left: 0;
  }
}

@media (min-width: 992px) {
  #awards2019 #stickyNav .navbar-brand {
    color: fade(white, 70%);
  }
  #awards2019 #stickyNav .navbar-brand:focus, #awards2019 #stickyNav .navbar-brand:hover {
    color: #fff;
  }
  #awards2019 #stickyNav .navbar-nav > li > a {
    color: rgba(255, 255, 255, 0.7);
  }
  #awards2019 #stickyNav .navbar-nav > li > a:focus {
    color: rgba(255, 255, 255, 0.7);
  }
  #awards2019 #stickyNav .navbar-nav > li > a:focus:hover {
    color: #fff;
  }
  #awards2019 #stickyNav .navbar-nav > li > a:hover {
    color: #fff;
  }
  #awards2019 #stickyNav.navbar-shrink {
    border-color: rgba(34, 34, 34, 0.1);
    z-index: 1000;
    background-color: #000;
    border-radius: 0;
  }
  #awards2019 #stickyNav.navbar-shrink .navbar-brand {
    color: #f9f9f9;
  }
  #awards2019 #stickyNav.navbar-shrink .navbar-brand:focus, #awards2019 #stickyNav.navbar-shrink .navbar-brand:hover {
    color: #fdb913;
  }
  #awards2019 #stickyNav.navbar-shrink .navbar-nav > li > a {
    color: #f9f9f9;
  }
  #awards2019 #stickyNav.navbar-shrink .navbar-nav > li > a:focus {
    color: #f9f9f9;
  }
  #awards2019 #stickyNav.navbar-shrink .navbar-nav > li > a:focus:hover {
    color: #fdb913;
  }
  #awards2019 #stickyNav.navbar-shrink .navbar-nav > li > a:hover {
    color: #fdb913;
  }
}

@media (max-width: 425px) {
  #awards2019 section.general {
    padding: 10px 0 50px;
  }
  #awards2019 section.general h2 {
    font-size: 28px;
  }
  #awards2019 section.general .overlay-header {
    font-size: 33px;
    padding: 6px;
  }
  #awards2019 section.general header {
    height: 61px;
  }
  #awards2019 section.general header.features-header {
    height: 60px;
  }
  #awards2019 section.issue {
    padding: 0;
  }
  #awards2019 .visible-xs {
    display: block;
  }
}

@media (min-width: 426px) {
  #awards2019 .visible-xs {
    display: none !important;
  }
}

@media (max-width: 490px) {
  #awards2019 .navbar-collapse {
    background: #000;
    z-index: 999999;
  }
}

@media (max-width: 991px) {
  #awards2019 .navbar-nav {
    padding-left: 17px;
  }
}

@media (min-width: 768px) and (max-width: 768px) {
  #awards2019 .navbar-nav {
    padding-left: 0px;
  }
}

#awards2019 .col-6 {
  float: left;
}

#awards2019 .floating-bar {
  position: fixed;
  top: -200%;
  left: 1%;
  color: #000;
  width: 100px;
  background: #fff;
  transition: all .5s .2s ease-in-out;
  font-weight: 600;
  z-index: 999;
}

@media (min-width: 769px) {
  #awards2019 .floating-bar {
    border-right: 1px solid #000;
    border-left: 1px solid #000;
  }
}

@media (max-width: 768px) {
  #awards2019 .floating-bar {
    width: 100%;
    left: 0;
    border-bottom: 1px solid #000;
  }
}

#awards2019 .floating-bar .floating-menu {
  line-height: 1.4;
  text-align: center;
  padding: 12px;
  border-bottom: 1px dotted #000;
  font-size: 1.2rem;
}

#awards2019 .floating-bar .floating-menu:hover, #awards2019 .floating-bar .floating-menu.active {
  background: #fdb913;
  color: #fff;
}

@media (max-width: 768px) {
  #awards2019 .floating-bar .floating-menu {
    padding: 5px;
    font-size: .9rem;
    width: 16.6%;
    float: left;
    border-right: 1px dotted #000;
    border-bottom: none;
  }
}

@media (max-width: 320px) {
  #awards2019 .floating-bar .floating-menu {
    font-size: .7rem;
  }
}

#awards2019 .floating-bar.in {
  transition: all .5s .2s ease-in-out;
}

@media (min-width: 991px) {
  #awards2019 .floating-bar.in {
    top: 15%;
  }
}

@media (max-width: 768px) {
  #awards2019 .floating-bar.in {
    top: 70px;
  }
}

@media (max-width: 576px) {
  #awards2019 .floating-bar.in {
    top: 64px;
  }
}

@media (max-width: 425px) {
  #awards2019 .floating-bar.in {
    top: 50px;
  }
}

#awards2019 .floating-bar a {
  color: #333;
  text-decoration: none !important;
}

.calculator-container #close-chart {
  margin-top: 2rem;
  margin-right: 1.5rem;
}

@media (min-width: 768px) {
  .page-content-1723634 .page-projectreview section.page-hero .news-hero-bg,
  .page-content-1723633 .page-projectreview section.page-hero .news-hero-bg,
  .page-content-1727601 .page-projectreview section.page-hero .news-hero-bg,
  .page-content-1727597 .page-projectreview section.page-hero .news-hero-bg {
    background-position: bottom right;
  }
}

@media (min-width: 768px) {
  .page-content-1901179 .page-projectreview section.page-hero .news-hero-bg {
    height: 725px;
  }
}

@media (max-width: 767px) {
  .page-content-1901179 .page-projectreview section.page-hero .news-hero-bg {
    background-image: url("https://media.edgeprop.my/page/project-review/m_homevest.jpg") !important;
  }
}

@media (min-width: 768px) {
  .page-content-1723635 .page-projectreview section.page-hero .news-hero-bg {
    background-position: center;
  }
}

@media (max-width: 767px) {
  .page-content-1723635 .page-projectreview section.page-hero .news-hero-bg {
    background-position-y: -58px;
  }
}

@media (max-width: 767px) {
  .page-content-1849545 .page-projectreview section.page-hero .news-hero-bg {
    background-image: url("https://media.edgeprop.my/page/project-review/2021-MAY31-ecocity.jpg") !important;
  }
}

@media (min-width: 768px) {
  .page-content-1727597 .page-projectreview section.page-hero .news-hero-bg {
    background-position: center;
  }
}

@media (max-width: 767px) {
  .page-content-1727597 .page-projectreview section.page-hero .news-hero-bg {
    background-position: 45%;
  }
}

@media (max-width: 767px) {
  .page-content-1727597 .page-projectreview section.page-hero .news-hero-main-component .news-hero-text {
    position: relative;
    bottom: -33px;
  }
}

.page-content-1900642 .news-hero-text h1 {
  opacity: 0;
}

.page-content-1902525 .page-projectreview footer,
.page-content-1902525 .page-projectreview #mainNav.navbar,
.page-content-1902525 .page-projectreview .news-arrow-down .circle-color,
.page-content-1902525 .page-projectreview section.page-inquiry .inquiry-button,
.page-content-1902525 .page-projectreview .fixed-bar .fixed-buttons .btn.btn-enquire, .page-content-1901490 .page-projectreview footer,
.page-content-1901490 .page-projectreview #mainNav.navbar,
.page-content-1901490 .page-projectreview .news-arrow-down .circle-color,
.page-content-1901490 .page-projectreview section.page-inquiry .inquiry-button,
.page-content-1901490 .page-projectreview .fixed-bar .fixed-buttons .btn.btn-enquire, .page-content-1901489 .page-projectreview footer,
.page-content-1901489 .page-projectreview #mainNav.navbar,
.page-content-1901489 .page-projectreview .news-arrow-down .circle-color,
.page-content-1901489 .page-projectreview section.page-inquiry .inquiry-button,
.page-content-1901489 .page-projectreview .fixed-bar .fixed-buttons .btn.btn-enquire, .page-content-1902125 .page-projectreview footer,
.page-content-1902125 .page-projectreview #mainNav.navbar,
.page-content-1902125 .page-projectreview .news-arrow-down .circle-color,
.page-content-1902125 .page-projectreview section.page-inquiry .inquiry-button,
.page-content-1902125 .page-projectreview .fixed-bar .fixed-buttons .btn.btn-enquire, .page-content-1901487 .page-projectreview footer,
.page-content-1901487 .page-projectreview #mainNav.navbar,
.page-content-1901487 .page-projectreview .news-arrow-down .circle-color,
.page-content-1901487 .page-projectreview section.page-inquiry .inquiry-button,
.page-content-1901487 .page-projectreview .fixed-bar .fixed-buttons .btn.btn-enquire, .page-content-1901486 .page-projectreview footer,
.page-content-1901486 .page-projectreview #mainNav.navbar,
.page-content-1901486 .page-projectreview .news-arrow-down .circle-color,
.page-content-1901486 .page-projectreview section.page-inquiry .inquiry-button,
.page-content-1901486 .page-projectreview .fixed-bar .fixed-buttons .btn.btn-enquire, .page-content-1901938 .page-projectreview footer,
.page-content-1901938 .page-projectreview #mainNav.navbar,
.page-content-1901938 .page-projectreview .news-arrow-down .circle-color,
.page-content-1901938 .page-projectreview section.page-inquiry .inquiry-button,
.page-content-1901938 .page-projectreview .fixed-bar .fixed-buttons .btn.btn-enquire, .page-content-1901945 .page-projectreview footer,
.page-content-1901945 .page-projectreview #mainNav.navbar,
.page-content-1901945 .page-projectreview .news-arrow-down .circle-color,
.page-content-1901945 .page-projectreview section.page-inquiry .inquiry-button,
.page-content-1901945 .page-projectreview .fixed-bar .fixed-buttons .btn.btn-enquire, .page-content-1830267 .page-projectreview footer,
.page-content-1830267 .page-projectreview #mainNav.navbar,
.page-content-1830267 .page-projectreview .news-arrow-down .circle-color,
.page-content-1830267 .page-projectreview section.page-inquiry .inquiry-button,
.page-content-1830267 .page-projectreview .fixed-bar .fixed-buttons .btn.btn-enquire {
  background-color: #292F7E;
}

.page-content-1902525 .page-projectreview section.page-inquiry .inquiry-button,
.page-content-1902525 .page-projectreview .fixed-bar .fixed-buttons .btn.btn-enquire, .page-content-1901490 .page-projectreview section.page-inquiry .inquiry-button,
.page-content-1901490 .page-projectreview .fixed-bar .fixed-buttons .btn.btn-enquire, .page-content-1901489 .page-projectreview section.page-inquiry .inquiry-button,
.page-content-1901489 .page-projectreview .fixed-bar .fixed-buttons .btn.btn-enquire, .page-content-1902125 .page-projectreview section.page-inquiry .inquiry-button,
.page-content-1902125 .page-projectreview .fixed-bar .fixed-buttons .btn.btn-enquire, .page-content-1901487 .page-projectreview section.page-inquiry .inquiry-button,
.page-content-1901487 .page-projectreview .fixed-bar .fixed-buttons .btn.btn-enquire, .page-content-1901486 .page-projectreview section.page-inquiry .inquiry-button,
.page-content-1901486 .page-projectreview .fixed-bar .fixed-buttons .btn.btn-enquire, .page-content-1901938 .page-projectreview section.page-inquiry .inquiry-button,
.page-content-1901938 .page-projectreview .fixed-bar .fixed-buttons .btn.btn-enquire, .page-content-1901945 .page-projectreview section.page-inquiry .inquiry-button,
.page-content-1901945 .page-projectreview .fixed-bar .fixed-buttons .btn.btn-enquire, .page-content-1830267 .page-projectreview section.page-inquiry .inquiry-button,
.page-content-1830267 .page-projectreview .fixed-bar .fixed-buttons .btn.btn-enquire {
  border-color: #292F7E;
}

.page-content-1902525 .page-projectreview section.page-inquiry .inquiry-button:hover,
.page-content-1902525 .page-projectreview .fixed-bar .fixed-buttons .btn.btn-enquire:hover, .page-content-1901490 .page-projectreview section.page-inquiry .inquiry-button:hover,
.page-content-1901490 .page-projectreview .fixed-bar .fixed-buttons .btn.btn-enquire:hover, .page-content-1901489 .page-projectreview section.page-inquiry .inquiry-button:hover,
.page-content-1901489 .page-projectreview .fixed-bar .fixed-buttons .btn.btn-enquire:hover, .page-content-1902125 .page-projectreview section.page-inquiry .inquiry-button:hover,
.page-content-1902125 .page-projectreview .fixed-bar .fixed-buttons .btn.btn-enquire:hover, .page-content-1901487 .page-projectreview section.page-inquiry .inquiry-button:hover,
.page-content-1901487 .page-projectreview .fixed-bar .fixed-buttons .btn.btn-enquire:hover, .page-content-1901486 .page-projectreview section.page-inquiry .inquiry-button:hover,
.page-content-1901486 .page-projectreview .fixed-bar .fixed-buttons .btn.btn-enquire:hover, .page-content-1901938 .page-projectreview section.page-inquiry .inquiry-button:hover,
.page-content-1901938 .page-projectreview .fixed-bar .fixed-buttons .btn.btn-enquire:hover, .page-content-1901945 .page-projectreview section.page-inquiry .inquiry-button:hover,
.page-content-1901945 .page-projectreview .fixed-bar .fixed-buttons .btn.btn-enquire:hover, .page-content-1830267 .page-projectreview section.page-inquiry .inquiry-button:hover,
.page-content-1830267 .page-projectreview .fixed-bar .fixed-buttons .btn.btn-enquire:hover {
  background-color: #292F7E;
  opacity: 0.9;
  border-color: #292f7e;
}

.page-content-1902525 .page-projectreview footer, .page-content-1901490 .page-projectreview footer, .page-content-1901489 .page-projectreview footer, .page-content-1902125 .page-projectreview footer, .page-content-1901487 .page-projectreview footer, .page-content-1901486 .page-projectreview footer, .page-content-1901938 .page-projectreview footer, .page-content-1901945 .page-projectreview footer, .page-content-1830267 .page-projectreview footer {
  color: #fff;
}

.page-content-1902525 .page-projectreview footer a, .page-content-1902525 .page-projectreview footer p, .page-content-1901490 .page-projectreview footer a, .page-content-1901490 .page-projectreview footer p, .page-content-1901489 .page-projectreview footer a, .page-content-1901489 .page-projectreview footer p, .page-content-1902125 .page-projectreview footer a, .page-content-1902125 .page-projectreview footer p, .page-content-1901487 .page-projectreview footer a, .page-content-1901487 .page-projectreview footer p, .page-content-1901486 .page-projectreview footer a, .page-content-1901486 .page-projectreview footer p, .page-content-1901938 .page-projectreview footer a, .page-content-1901938 .page-projectreview footer p, .page-content-1901945 .page-projectreview footer a, .page-content-1901945 .page-projectreview footer p, .page-content-1830267 .page-projectreview footer a, .page-content-1830267 .page-projectreview footer p {
  color: #ffffff;
}

.page-content-1902525 .news-arrow-down, .page-content-1902591 .news-arrow-down {
  display: none;
}

.page-content-1903018 .whatsapp-float-container,
.page-content-1903018 section#prform,
.page-content-1903088 .whatsapp-float-container,
.page-content-1903088 section#prform {
  display: none;
}

.page-content-1903018 .lendlease-ads,
.page-content-1903088 .lendlease-ads {
  display: none;
  width: 100%;
  text-align: center;
  margin: 0 auto;
}

.page-content-1903018 .lendlease-ads img,
.page-content-1903088 .lendlease-ads img {
  max-width: 780px;
  width: 100%;
}

@media (min-width: 768px) {
  .page-content-1903018 .lendlease-ads.desktop,
  .page-content-1903088 .lendlease-ads.desktop {
    display: block;
  }
}

@media (max-width: 767px) {
  .page-content-1903018 .lendlease-ads.mobile,
  .page-content-1903088 .lendlease-ads.mobile {
    display: block;
  }
}

.page-content-1903018 .lendlease-survey,
.page-content-1903088 .lendlease-survey {
  width: 100%;
  height: 1000px;
  border: 0;
  overflow: hidden;
}

.page-content-1903018 .page-projectreview footer,
.page-content-1903018 .page-projectreview #mainNav.navbar,
.page-content-1903018 .page-projectreview .news-arrow-down .circle-color,
.page-content-1903018 .page-projectreview section.page-inquiry .inquiry-button,
.page-content-1903018 .page-projectreview .fixed-bar .fixed-buttons .btn.btn-enquire,
.page-content-1903018 .page-projectreview .fixed-bar,
.page-content-1903088 .page-projectreview footer,
.page-content-1903088 .page-projectreview #mainNav.navbar,
.page-content-1903088 .page-projectreview .news-arrow-down .circle-color,
.page-content-1903088 .page-projectreview section.page-inquiry .inquiry-button,
.page-content-1903088 .page-projectreview .fixed-bar .fixed-buttons .btn.btn-enquire,
.page-content-1903088 .page-projectreview .fixed-bar {
  background-color: #1965ae;
}

.page-content-1903018 .page-projectreview section.page-inquiry .inquiry-button,
.page-content-1903018 .page-projectreview .fixed-bar .fixed-buttons .btn.btn-enquire,
.page-content-1903088 .page-projectreview section.page-inquiry .inquiry-button,
.page-content-1903088 .page-projectreview .fixed-bar .fixed-buttons .btn.btn-enquire {
  background-color: #C92063;
  border-color: #C92063;
}

.page-content-1903018 .page-projectreview section.page-inquiry .inquiry-button:hover,
.page-content-1903018 .page-projectreview .fixed-bar .fixed-buttons .btn.btn-enquire:hover,
.page-content-1903088 .page-projectreview section.page-inquiry .inquiry-button:hover,
.page-content-1903088 .page-projectreview .fixed-bar .fixed-buttons .btn.btn-enquire:hover {
  background-color: #C92063;
  opacity: 0.9;
  border-color: #C92063;
}

.page-content-1903018 .page-projectreview footer,
.page-content-1903088 .page-projectreview footer {
  color: #fff;
}

.page-content-1903018 .page-projectreview footer a, .page-content-1903018 .page-projectreview footer p,
.page-content-1903088 .page-projectreview footer a,
.page-content-1903088 .page-projectreview footer p {
  color: #ffffff;
}

@media (max-width: 767px) {
  .page-content-1903018 .fixed-bar .bar-logo,
  .page-content-1903088 .fixed-bar .bar-logo {
    display: none !important;
  }
}

.page-content-1903088 i.fa.fa-quote-left.fa-2x.el-show {
  display: none;
}

.page-content-1903088 section#prform {
  display: block;
}

.page-content-1903172 i.fa.fa-quote-left.fa-2x.el-show {
  display: none;
}

.page-projectreview {
  /*  @media (min-width: 768px) and (max-width: 991px) {
    .navbar-collapse.collapse {
      display: none !important;
      &.in {
        display: block !important;
      }
    }
    .navbar-header .collapse, .navbar-toggle {
      display: block !important;
    }
    .navbar-header {
      float: none;
    }
  } */
}

.page-projectreview #brand-slide {
  z-index: 999;
  position: fixed;
  background: #dcdfe6;
  color: #333;
  width: 260px;
  text-align: center;
  bottom: 80px;
  right: -260px;
  height: 264px;
  transition: right 0.4s ease-in-out;
  -o-transition: right 0.4s ease-in-out;
  -ms-transition: right 0.4s ease-in-out;
  -moz-transition: right 0.4s ease-in-out;
  -webkit-transition: right 0.4s ease-in-out;
}

.page-projectreview #brand-slide .box {
  padding: 20px;
  font-size: 13px;
}

.page-projectreview #brand-slide:hover, .page-projectreview #brand-slide.in {
  right: 0px;
}

.page-projectreview #brand-slide.in, .page-projectreview #brand-slide.out {
  transition: right 0.4s ease-in-out;
  -o-transition: right 0.4s ease-in-out;
  -ms-transition: right 0.4s ease-in-out;
  -moz-transition: right 0.4s ease-in-out;
  -webkit-transition: right 0.4s ease-in-out;
  right: 0px;
}

.page-projectreview #brand-slide.out {
  right: -260px;
}

.page-projectreview #brand-slide #brand-toggle {
  position: absolute;
  right: 260px;
  padding: 10px 20px;
  background: #d1a12a;
  color: #fff;
  font-weight: 700;
  cursor: pointer;
}

.page-projectreview #brand-slide a.btn-slide {
  display: block;
  margin-top: 15px;
  background: #007F38;
  color: #fff;
  padding: 4px 10px;
  font-weight: 700;
}

@media (max-width: 768px) {
  .page-projectreview .brand-footer {
    display: block;
    margin-bottom: 10px;
  }
}

@media (min-width: 769px) {
  .page-projectreview .brand-footer {
    margin-right: 15px;
  }
}

.page-projectreview figcaption {
  margin-top: -25px;
  font-size: 1.2rem;
  font-family: "Poppins", sans-serif;
  font-style: italic;
  line-height: 1.4;
  letter-spacing: 0.03em;
  color: #969696;
}

.page-projectreview figcaption:before {
  font-family: 'FontAwesome';
  content: '\f05a';
  margin-right: 4px;
  font-style: normal;
  font-size: 1.3rem;
}

.page-projectreview .banner-show-d {
  display: none;
}

@media (min-width: 768px) {
  .page-projectreview .banner-show-d {
    display: block;
  }
}

.page-projectreview .banner-show-m {
  display: none;
}

@media (max-width: 767px) {
  .page-projectreview .banner-show-m {
    display: block;
  }
}

.page-projectreview a {
  color: #000;
  -webkit-transition: all .3s;
  -moz-transition: all .3s;
  transition: all 0.3s;
}

.page-projectreview a:hover {
  color: #f00000;
  text-decoration: none;
}

.page-projectreview .fa.fa-bars {
  font-style: normal;
  font-family: FontAwesome;
  font-size: 16px;
  line-height: 0;
}

.page-projectreview .container-news-skin {
  max-width: 980px;
  margin: 0 auto;
}

.page-projectreview .container-news-skin img {
  max-width: 100%;
  height: auto !important;
  width: 100% !important;
}

.page-projectreview .container-news-skin .news-side .side-quote {
  color: #666;
  font-size: 32px;
  line-height: 1.3;
  font-style: italic;
  font-weight: 700;
  letter-spacing: -0.1rem;
}

@media (min-width: 768px) and (max-width: 768px) {
  .page-projectreview .container-news-skin .news-side .side-quote {
    font-size: 22px;
  }
}

@media (min-width: 768px) {
  .page-projectreview .container-news-skin .news-side .side-quote {
    border-right: 1px solid #c2c2c2;
    padding-right: 20px;
  }
}

@media (max-width: 767px) {
  .page-projectreview .container-news-skin .news-side .side-quote {
    font-size: 19px;
  }
}

.page-projectreview .container-news-skin .news-side .side-meta {
  margin-top: 20px;
  color: #999;
  font-style: italic;
  font-size: 18px;
}

@media (max-width: 490px) {
  .page-projectreview .container-news-skin .news-side .side-meta {
    font-size: 15px;
    margin: 10px auto 30px;
  }
}

@media (max-width: 767px) {
  .page-projectreview .container-news-skin .heading-mobile {
    padding: 0 20px;
  }
}

.page-projectreview hr {
  max-width: 100px;
  margin: 25px auto 0;
  border-width: 1px;
  border-color: rgba(34, 34, 34, 0.1);
}

.page-projectreview hr.light {
  border-color: #fff;
}

.page-projectreview h1, .page-projectreview h2, .page-projectreview h3, .page-projectreview h4, .page-projectreview h5, .page-projectreview h6 {
  font-weight: 900;
}

.page-projectreview p {
  font-size: 16px;
  line-height: 1.5;
  margin-bottom: 20px;
  color: #666;
  font-style: italic;
}

.page-projectreview .news-content p {
  font-style: initial;
}

.page-projectreview section {
  padding: 40px 0;
}

.page-projectreview section h2 {
  margin-top: 15px;
  font-size: 28px;
  font-style: italic;
  color: #666;
  font-weight: 600;
  line-height: 1.3;
  margin-bottom: 20px;
}

.page-projectreview section.page-hero {
  padding-top: 0;
  padding-bottom: 0;
}

.page-projectreview section.page-hero .news-hero-main-component .news-hero-button ul.hero-buttons li a.active {
  background: #007455;
  padding: 1px 10px;
  border-radius: 3px;
}

.page-projectreview section.page-hero .news-hero-bg {
  background-image: url(https://media.edgeprop.my/s3fs-public/65632_1585711416_5EdgPro5_setiaphotogallery3_0.jpg);
  width: 100%;
  height: 570px;
  background-repeat: no-repeat;
  background-position: center 0;
  background-size: cover;
}

.page-projectreview section.page-hero .news-hero-bg .brand-partner {
  padding: 10px 0;
  background: rgba(0, 0, 0, 0.19);
}

@media (max-width: 1024px) and (min-width: 1024px) {
  .page-projectreview section.page-hero .news-hero-bg .brand-partner .col-lg-9.brand-partner-side {
    flex: 0 0 66.666667%;
    max-width: 66.666667%;
  }
}

@media (max-width: 425px) {
  .page-projectreview section.page-hero .news-hero-bg .brand-partner .brand-partner-side {
    display: none;
  }
}

.page-projectreview section.page-hero .news-hero-bg .brand-partner .brand-partner-placement {
  text-align: right;
  font-size: 13px;
}

@media (max-width: 768px) and (min-width: 768px) {
  .page-projectreview section.page-hero .news-hero-bg .brand-partner .brand-partner-placement {
    flex: 0 0 96%;
    max-width: 96%;
  }
}

.page-projectreview section.page-hero .news-hero-bg .brand-partner .brand-partner-placement .hero-field-text {
  line-height: 1;
  padding: 20px 0;
}

@media (max-width: 768px) {
  .page-projectreview section.page-hero .news-hero-bg .brand-partner .brand-partner-placement .hero-field-text {
    flex: 0 0 65%;
    max-width: 65%;
    padding: 20px 0;
  }
}

@media (max-width: 767px) {
  .page-projectreview section.page-hero .news-hero-bg .brand-partner .brand-partner-placement .hero-field-text {
    padding: 10px 0;
  }
}

@media (max-width: 425px) {
  .page-projectreview section.page-hero .news-hero-bg .brand-partner .brand-partner-placement .hero-field-text {
    flex: 0 0 68%;
    max-width: 68%;
  }
}

@media (max-width: 425px) {
  .page-projectreview section.page-hero .news-hero-bg .brand-partner .brand-partner-placement .hero-field-text {
    flex: 0 0 60%;
    max-width: 60%;
  }
}

@media (max-width: 768px) {
  .page-projectreview section.page-hero .news-hero-bg .brand-partner .brand-partner-placement .hero-field-text .field-text-container {
    width: 67%;
    float: right;
    position: relative;
    top: 0;
  }
}

@media (max-width: 568px) {
  .page-projectreview section.page-hero .news-hero-bg .brand-partner .brand-partner-placement .hero-field-text .field-text-container {
    width: 74%;
  }
}

@media (max-width: 424px) {
  .page-projectreview section.page-hero .news-hero-bg .brand-partner .brand-partner-placement .hero-field-text .field-text-container {
    width: 80%;
  }
}

@media (max-width: 320px) {
  .page-projectreview section.page-hero .news-hero-bg .brand-partner .brand-partner-placement .hero-field-text .field-text-container {
    top: 4px;
  }
}

.page-projectreview section.page-hero .news-hero-bg .brand-partner .brand-partner-placement .hero-field-text a,
.page-projectreview section.page-hero .news-hero-bg .brand-partner .brand-partner-placement .hero-field-text a:hover {
  color: inherit;
}

.page-projectreview section.page-hero .news-hero-bg .brand-partner .brand-partner-placement .hero-field-logo img {
  max-width: 150px;
}

@media (max-width: 767px) {
  .page-projectreview section.page-hero .news-hero-bg .brand-partner .brand-partner-placement .hero-field-logo img {
    max-width: 114px;
  }
}

@media (max-width: 375px) {
  .page-projectreview section.page-hero .news-hero-bg .brand-partner .brand-partner-placement .hero-field-logo img {
    max-width: 103px;
  }
}

@media (max-width: 768px) and (min-width: 768px) {
  .page-projectreview section.page-hero .news-hero-bg .brand-partner .brand-partner-placement .hero-field-logo {
    position: relative;
    top: 5px;
  }
}

@media (max-width: 768px) {
  .page-projectreview section.page-hero .news-hero-bg .brand-partner .brand-partner-placement .hero-field-logo {
    flex: 0 0 26%;
    max-width: 26%;
  }
}

@media (max-width: 320px) {
  .page-projectreview section.page-hero .news-hero-bg .brand-partner .brand-partner-placement .hero-field-logo {
    position: relative;
    top: 5px;
  }
}

@media (max-width: 1024px) and (min-width: 1024px) {
  .page-projectreview section.page-hero .news-hero-bg .brand-partner .col-lg-3 {
    max-width: 24%;
  }
}

.page-projectreview section.page-hero .news-hero-main-component {
  position: relative;
}

@media (min-width: 768px) {
  .page-projectreview section.page-hero .news-hero-main-component {
    top: 130px;
  }
}

@media (max-width: 767px) {
  .page-projectreview section.page-hero .news-hero-main-component {
    padding-top: 50px;
  }
}

.page-projectreview section.page-hero .news-hero-main-component .news-hero-text {
  color: #fff;
  text-align: center;
  margin-bottom: 90px;
  text-shadow: 4px 2px 8px #444;
}

.page-projectreview section.page-hero .news-hero-main-component .news-hero-text h4 {
  font-weight: 100;
  font-size: 22px;
}

@media (min-width: 1024px) {
  .page-projectreview section.page-hero .news-hero-main-component .news-hero-text h4 {
    font-size: 30px;
  }
}

@media (max-width: 490px) {
  .page-projectreview section.page-hero .news-hero-main-component .news-hero-text h4 {
    width: 90%;
    margin: 3px auto;
  }
}

.page-projectreview section.page-hero .news-hero-main-component .news-hero-text h1 {
  font-size: 35px;
  width: 90%;
  margin: 0 auto;
}

@media (min-width: 1024px) {
  .page-projectreview section.page-hero .news-hero-main-component .news-hero-text h1 {
    font-size: 56px;
  }
}

@media (max-width: 490px) {
  .page-projectreview section.page-hero .news-hero-main-component .news-hero-text h1 {
    margin: 3px auto;
  }
}

.page-projectreview section.page-hero .news-hero-main-component .news-hero-button {
  background: rgba(0, 0, 0, 0.5);
  padding: 11px 0;
}

.page-projectreview section.page-hero .news-hero-main-component .news-hero-button ul.hero-buttons {
  text-align: center;
  margin: 0;
  padding: 0;
}

.page-projectreview section.page-hero .news-hero-main-component .news-hero-button ul.hero-buttons li {
  display: inline-block;
  color: #fff;
  text-align: center;
  text-transform: uppercase;
  margin-right: 20px;
  font-size: 18px;
}

.page-projectreview section.page-hero .news-hero-main-component .news-hero-button ul.hero-buttons li a {
  color: #fff;
}

.page-projectreview section.page-content .news-content img {
  margin-bottom: 30px;
}

.page-projectreview section.page-content .news-content a {
  color: #488BF8 !important;
}

.page-projectreview section.page-related h3, .page-projectreview section.page-categories h3, .page-projectreview section.page-accolades h3 {
  margin-top: 15px;
  font-size: 23px;
  font-style: italic;
  color: #666;
  font-weight: 600;
  line-height: 1.3;
}

.page-projectreview section.page-related img.similar-articles, .page-projectreview section.page-accolades img.similar-articles {
  height: 190px !important;
  object-fit: cover;
}

.page-projectreview section.page-related h3, .page-projectreview section.page-accolades h3 {
  font-size: 19px;
}

.page-projectreview section.page-related p, .page-projectreview section.page-accolades p {
  display: block;
  display: -webkit-box;
  /* max-width: 400px; */
  height: 76px;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

.page-projectreview section.page-accolades .accolades-card {
  background-color: #fff;
  border: 1px solid rgba(0, 0, 0, 0.125);
  text-align: center;
  padding: 20px;
}

@media (min-width: 769px) {
  .page-projectreview section.page-accolades .accolades-card {
    min-height: 452px;
  }
}

@media (min-width: 768px) and (max-width: 768px) {
  .page-projectreview section.page-accolades .accolades-card {
    min-height: 557px;
  }
}

.page-projectreview section.page-accolades .accolades-card .accolades-logo {
  max-width: 196px;
  height: 100%;
  margin: 10px 0 30px;
}

.page-projectreview section.page-accolades .accolades-card .text-top,
.page-projectreview section.page-accolades .accolades-card .text-center,
.page-projectreview section.page-accolades .accolades-card .text-bottom {
  margin-bottom: 20px;
}

.page-projectreview section.page-accolades .accolades-card .text-top {
  font-style: italic;
  color: #222;
  font-size: 16px;
  font-weight: 700;
}

.page-projectreview section.page-accolades .accolades-card .text-center {
  color: #cc0000;
  font-size: 14px;
  font-weight: 600;
}

.page-projectreview section.page-accolades .accolades-card .text-bottom {
  color: #666;
  font-size: 14px;
  font-weight: 200;
  margin-bottom: 30px;
}

.page-projectreview section.page-brand .brand-button {
  cursor: pointer;
  font-size: 1.9rem;
  line-height: 1.3;
  text-transform: uppercase;
  border: 1px solid #000;
  text-align: center;
  background: #fff;
  padding: 12px 80px;
  margin-top: 10px;
  display: inline-block;
}

.page-projectreview section.page-brand .brand-button:hover {
  border-color: #cc0000;
  color: #cc0000;
}

.page-projectreview section.page-categories h2, .page-projectreview section.page-inquiry h2 {
  margin-top: 15px;
  font-size: 28px;
  font-style: italic;
  color: #666;
  font-weight: 700;
  line-height: 1.3;
  margin-bottom: 10px;
}

.page-projectreview section.page-categories h3, .page-projectreview section.page-inquiry h3 {
  font-weight: 500;
  color: #666;
  margin-bottom: 30px;
}

.page-projectreview section.page-categories {
  background: #0a2141;
  color: #fff;
}

.page-projectreview section.page-categories h2 {
  color: #fff;
}

.page-projectreview section.page-categories h3 {
  color: #fff;
}

.page-projectreview section.page-categories .categories-text {
  font-size: 22px;
  margin-bottom: 30px;
}

.page-projectreview section.page-inquiry {
  background: #efefef;
}

.page-projectreview section.page-inquiry h2, .page-projectreview section.page-inquiry h3 {
  text-align: center;
}

@media (min-width: 768px) {
  .page-projectreview section.page-inquiry #moreinfo {
    max-width: 500px;
    margin: 0 auto;
  }
}

.page-projectreview section.page-inquiry #moreinfo .form-group {
  margin-bottom: 20px;
}

.page-projectreview section.page-inquiry #moreinfo .form-group .form-control {
  font-size: 14px;
}

.page-projectreview section.page-inquiry #moreinfo .form-check {
  padding-left: 0;
}

.page-projectreview section.page-inquiry #moreinfo .form-check label {
  margin-left: 5px;
  font-size: 13px;
}

@media (max-width: 991px) {
  .page-projectreview section.page-inquiry #moreinfo .form-check label {
    font-size: 11px;
  }
}

.page-projectreview section.page-inquiry #moreinfo .label-pdpa {
  position: relative;
  right: -6px;
  top: -3px;
  height: 20px;
}

@media (max-width: 320px) {
  .page-projectreview section.page-inquiry #moreinfo .label-pdpa {
    margin-right: 10px;
  }
}

@media (max-width: 425px) {
  .page-projectreview section.page-inquiry #moreinfo .label-pdpa {
    margin-right: 4px;
  }
}

@media (min-width: 991px) {
  .page-projectreview section.page-inquiry #moreinfo .label-pdpa {
    position: relative;
    right: -1px;
    top: -1px;
  }
}

.page-projectreview section.page-inquiry .inquiry-button {
  width: 100%;
  margin: 0 auto;
  display: inherit;
  cursor: pointer;
  font-size: 1.9rem;
  line-height: 1.3;
  text-transform: uppercase;
  border: 1px solid #cc0000;
  background: #cc0000;
  color: #fff;
  text-align: center;
  padding: 12px 80px;
  margin-top: 10px;
  display: inline-block;
}

.page-projectreview section.page-inquiry .inquiry-button:hover {
  background: #990000;
}

.page-projectreview section.page-share {
  margin: 0 auto;
  text-align: center;
}

.page-projectreview section.page-share h2 {
  text-align: center;
  text-transform: uppercase;
  color: #666;
  font-size: 28px;
  font-weight: 600;
}

.page-projectreview section.page-share ul.share-icons {
  margin: 0;
  padding: 0;
}

.page-projectreview section.page-share ul.share-icons li {
  cursor: pointer;
  display: inline-block;
  list-style-type: none;
  border-radius: 50%;
  width: 44px;
  height: 44px;
  background: #efefef;
  padding: 8px;
  font-size: 2rem;
  color: #888;
}

.page-projectreview section.page-share ul.share-icons li.in-between {
  margin: 0 26px;
}

.page-projectreview section.page-share ul.share-icons li:hover {
  background: #ddd;
}

.page-projectreview .news-arrow-down {
  text-align: center;
  font-size: 70px;
  color: #cc0000;
  margin-top: -55px;
}

.page-projectreview .news-arrow-down .circle-color {
  margin: 0 auto;
  background: #cc0000;
  line-height: 57px;
  width: 60px;
  height: 60px;
  box-shadow: 0 5px 2px -2px #bfbfbf;
  border-radius: 50%;
}

.page-projectreview span.header-logotype {
  font-weight: 700 !important;
  text-transform: uppercase !important;
  color: #cc0000 !important;
  position: relative;
  top: 18px;
}

.page-projectreview .m-b-20 {
  margin-bottom: 20px;
}

.page-projectreview .logotype {
  text-align: center;
  color: #f4b124;
  margin-left: auto;
  margin-right: auto;
  left: 0;
  right: 0;
}

@media (max-width: 767px) {
  .page-projectreview .logotype {
    margin-left: 0;
  }
}

.page-projectreview .logotype img {
  opacity: 0.9;
}

.page-projectreview .logotype.footer img {
  height: 55px;
}

.page-projectreview .logotype.toplogo img {
  height: 100%;
  max-height: 35px;
  width: auto;
}

.page-projectreview .logotype.home {
  text-align: left;
  position: absolute;
  z-index: 1;
  margin-left: auto;
  margin-right: auto;
  left: 0;
  right: 0;
  padding: 8px;
}

.page-projectreview .border-side {
  border-right: 1px solid #ccc;
  border-left: 1px solid #ccc;
}

.page-projectreview .no-float {
  float: none;
}

.page-projectreview footer {
  font-style: normal;
  color: #fff;
  padding: 20px 0;
  text-align: center;
  color: rgba(255, 255, 255, 0.3);
  background-color: #222;
  margin-bottom: 45px;
}

@media (min-width: 769px) {
  .page-projectreview footer {
    margin-bottom: 74px;
  }
}

.page-projectreview footer p {
  font-size: 12px;
  margin: 0;
}

.page-projectreview footer ul {
  margin-bottom: 0;
}

.page-projectreview footer ul li a {
  font-size: 12px;
  color: rgba(255, 255, 255, 0.3);
}

.page-projectreview footer ul li a.active, .page-projectreview footer ul li a:active, .page-projectreview footer ul li a:focus, .page-projectreview footer ul li a:hover {
  text-decoration: none;
}

.page-projectreview footer a {
  color: inherit;
}

.page-projectreview footer a:hover {
  color: inherit;
}

.page-projectreview #mainNav {
  -webkit-transition: all .3s;
  -moz-transition: all .3s;
  transition: all .3s;
  font-weight: 200;
  border-radius: 0;
}

.page-projectreview #mainNav.stick {
  margin-top: 0 !important;
  width: 100%;
  position: fixed;
  top: 0;
  z-index: 10000;
}

.page-projectreview #mainNav.navbar {
  border-radius: 0 !important;
  margin-bottom: 0 !important;
  margin-bottom: 0;
  min-height: 44px;
  border: 0;
  background: #000;
}

.page-projectreview #mainNav .navbar-brand {
  color: #cc0000;
  font-weight: 200;
  letter-spacing: 1px;
}

.page-projectreview #mainNav .navbar-brand:focus, .page-projectreview #mainNav .navbar-brand:hover {
  color: #b96700;
}

.page-projectreview #mainNav .navbar-toggler {
  font-size: 12px;
  padding: 8px 10px;
  color: #fff;
  position: absolute;
  right: 10px;
  top: 10px;
  display: none;
}

.page-projectreview #mainNav .navbar-nav > li > a {
  font-size: 12px;
  letter-spacing: 2px;
  text-transform: uppercase;
  letter-spacing: 0;
  padding: 5px;
  color: #fff;
}

.page-projectreview #mainNav .navbar-nav > li > a.active {
  color: #000 !important;
  background-color: transparent;
}

.page-projectreview #mainNav .navbar-nav > li > a.active:hover {
  background-color: transparent;
}

.page-projectreview #mainNav .navbar-nav > li > a:focus {
  color: #fff;
}

.page-projectreview #mainNav .navbar-nav > li > a:focus:hover {
  color: #000;
}

.page-projectreview #mainNav .navbar-nav > li > a:hover {
  color: #000;
}

.page-projectreview #mainNav .navbar-nav .nav-item .wrap-year-select {
  position: relative;
  padding: 5px;
}

@media (max-width: 425px) {
  .page-projectreview #mainNav .navbar-nav .nav-item .wrap-year-select {
    padding: 0;
  }
}

.page-projectreview #mainNav .navbar-nav .nav-item .wrap-year-select .dropdown .dropdown-toggle {
  background-color: transparent;
  border: 1px solid #ffffff;
  border-radius: 0;
  font-size: 13px;
  color: #ffffff;
  letter-spacing: 1px;
}

.page-projectreview #mainNav .navbar-nav .nav-item .wrap-year-select .dropdown .dropdown-menu {
  background-color: #000;
}

.page-projectreview #mainNav .navbar-nav .nav-item .wrap-year-select .dropdown .dropdown-menu .dropdown-item {
  border-radius: 0;
  font-size: 13px;
  color: #ffffff;
}

.page-projectreview #mainNav .navbar-nav .nav-item .wrap-year-select .dropdown .dropdown-menu .dropdown-item:hover {
  color: #cc0000;
  background-color: #000;
}

@media (max-width: 425px) {
  .page-projectreview #mainNav .navbar-nav .nav-item .wrap-year-select .dropdown .dropdown-menu .dropdown-item {
    text-align: left;
    padding-left: 0;
  }
}

@media (min-width: 992px) {
  .page-projectreview #mainNav .navbar-brand {
    color: fade(white, 70%);
  }
  .page-projectreview #mainNav .navbar-brand:focus, .page-projectreview #mainNav .navbar-brand:hover {
    color: #fff;
  }
  .page-projectreview #mainNav .navbar-nav > li > a {
    color: #fff;
  }
  .page-projectreview #mainNav .navbar-nav > li > a:focus {
    color: rgba(255, 255, 255, 0.7);
  }
  .page-projectreview #mainNav .navbar-nav > li > a:focus:hover {
    color: rgba(255, 255, 255, 0.7);
  }
  .page-projectreview #mainNav .navbar-nav > li > a:hover {
    color: #fff;
  }
  .page-projectreview #mainNav.navbar-shrink {
    border-color: rgba(34, 34, 34, 0.1);
    background-color: #000;
    border-radius: 0;
  }
  .page-projectreview #mainNav.navbar-shrink .navbar-brand {
    color: #f9f9f9;
  }
  .page-projectreview #mainNav.navbar-shrink .navbar-brand:focus, .page-projectreview #mainNav.navbar-shrink .navbar-brand:hover {
    color: #cc0000;
  }
  .page-projectreview #mainNav.navbar-shrink .navbar-nav > li > a {
    color: #f9f9f9;
  }
  .page-projectreview #mainNav.navbar-shrink .navbar-nav > li > a:focus {
    color: #f9f9f9;
  }
  .page-projectreview #mainNav.navbar-shrink .navbar-nav > li > a:focus:hover {
    color: #cc0000;
  }
  .page-projectreview #mainNav.navbar-shrink .navbar-nav > li > a:hover {
    color: #cc0000;
  }
}

.page-projectreview section.general {
  border-top: 10px solid #cc0000;
  position: relative;
  padding: 50px 0 50px;
  overflow: hidden;
}

.page-projectreview section.general h2 {
  font-size: 50px;
  margin: 0 0 30px;
  text-transform: uppercase;
}

.page-projectreview section.general h2.m-b-5 {
  margin-bottom: 5px;
}

.page-projectreview section.general h3 {
  font-size: 21px;
  margin-bottom: 30px;
  text-transform: uppercase;
}

.page-projectreview section.general h4 {
  font-size: 2.2rem;
  line-height: 1;
  margin-bottom: 15px;
  color: #cc0000;
  text-transform: lowercase;
  font-style: italic;
}

.page-projectreview section.general.no-border {
  border-top: 0;
}

.page-projectreview section.general.p-t-25 {
  padding-top: 25px;
}

.page-projectreview .top-text {
  max-width: 800px;
  margin: 35px auto 10px;
  font-style: italic;
  font-size: 22px;
  text-align: center;
  color: #555;
}

@media (max-width: 767px) {
  .page-projectreview .top-text {
    font-size: 20px;
  }
}

@media (max-width: 768px) {
  .page-projectreview .logotype img {
    width: 220px;
  }
}

@media (max-width: 767px) {
  .page-projectreview .border-side {
    border-right: 0;
    border-left: 0;
  }
}

@media (min-width: 426px) {
  .page-projectreview .visible-xs {
    display: none !important;
  }
}

@media (max-width: 425px) {
  .page-projectreview .visible-xs {
    display: block;
  }
}

.page-projectreview .bg-primary {
  background: #cc0000;
  background: -webkit-linear-gradient(#cc0000, #d37500);
  background: linear-gradient(#cc0000, #d37500);
}

.page-projectreview .text-primary {
  color: #cc0000;
}

.page-projectreview .no-gutter > [class*=col-] {
  padding-right: 0;
  padding-left: 0;
}

.page-projectreview .btn-outline {
  color: #fff;
  border: 1px solid;
  border-color: #fff;
}

.page-projectreview .btn-outline.active, .page-projectreview .btn-outline:active, .page-projectreview .btn-outline:focus, .page-projectreview .btn-outline:hover {
  color: #fff;
  border-color: #cc0000;
  background-color: #cc0000;
}

.page-projectreview .btn {
  border-radius: 300px;
  letter-spacing: 2px;
  text-transform: uppercase;
}

.page-projectreview .btn-xl {
  font-size: 11px;
  padding: 15px 45px;
}

.page-projectreview .nav-pills .nav-link.active {
  border: 1px solid #cc0000;
  color: #cc0000;
  background-color: transparent;
}

.page-projectreview ul.list-unstyled li {
  margin-bottom: 20px;
  font-size: 13px;
  color: #666;
}

.page-projectreview .ml-auto, .page-projectreview .mx-auto {
  margin-left: auto !important;
}

.page-projectreview .mr-auto, .page-projectreview .mx-auto {
  margin-right: auto !important;
}

.page-projectreview span {
  display: block;
}

.page-projectreview span.stories-title {
  font-size: 1.4rem;
  font-weight: bold;
}

.page-projectreview span.stories-subtitle {
  font-size: 1.2rem;
}

.page-projectreview ul.stories-list li span.story-text {
  display: block;
  font-weight: 600;
  font-size: 1.4rem;
  line-height: 1.5;
  margin: 0 auto;
}

@media (min-width: 769px) {
  .page-projectreview ul.stories-list li span.story-text {
    width: 70%;
  }
}

@media (min-width: 769px) {
  .page-projectreview ul.stories-list li {
    min-height: 270px;
  }
}

@media (min-width: 768px) and (max-width: 768px) {
  .page-projectreview ul.stories-list li {
    min-height: 262px;
  }
}

.page-projectreview select#select-years {
  font-size: 1.3rem;
  position: relative;
  background: #000;
  color: #fff;
}

@media (min-width: 768px) {
  .page-projectreview select#select-years {
    top: 13px;
  }
}

@media (max-width: 767px) {
  .page-projectreview .mobile-bg-odd {
    background: #333;
    position: relative;
    right: 10px;
    bottom: -6px;
    width: 102%;
    padding: 5px 0 5px 11px;
  }
}

.page-projectreview .post-form-overlay {
  position: fixed;
  bottom: -160%;
  height: 100vh;
  width: 100vw;
  background: rgba(0, 0, 0, 0.5);
  transition: all .5s .2s ease-in-out;
}

.page-projectreview .post-form-overlay .post-title {
  font-size: 2.4rem;
  margin-bottom: 25px;
}

@media (min-width: 768px) {
  .page-projectreview .post-form-overlay .post-title {
    width: 80%;
    margin: 0 auto 25px;
  }
}

.page-projectreview .post-form-overlay .sub-post-title {
  font-size: 1.8rem;
}

.page-projectreview .post-form-overlay .post-form-close {
  position: absolute;
  top: 0;
  background: rgba(0, 0, 0, 0.18);
  color: white;
  right: calc((100% - 500px) / 2);
  padding: 0 10px;
  font-size: 28px;
  z-index: 1;
  cursor: pointer;
}

@media (max-width: 991px) {
  .page-projectreview .post-form-overlay .post-form-close {
    right: calc((100% - 300px) / 2);
  }
}

.page-projectreview .post-form-overlay .post-form-close:hover {
  background: rgba(20, 20, 20, 0.18);
}

.page-projectreview .post-form-overlay-content {
  background: #fff;
  box-shadow: 0 4px 11px -5px #bfbfbf;
  border: 1px solid #ddd;
  text-align: center;
  margin: 13% auto 0;
  min-height: 220px;
  padding: 25px 20px 20px;
  width: 500px;
  right: calc((100% - 500px) / 2);
}

@media (max-width: 991px) {
  .page-projectreview .post-form-overlay-content {
    margin: 20% auto 0;
    width: 300px;
    right: calc((100% - 300px) / 2);
    padding: 20px;
  }
}

.page-projectreview .post-form-overlay.in {
  bottom: 0;
  transition: all .5s .2s ease-in-out;
}

.page-projectreview .fixed-bar {
  position: fixed;
  background: #fff;
  width: 100%;
  padding: 15px 60px;
  bottom: 0;
  left: 0;
  z-index: 4;
  box-shadow: 0 -5px 10px rgba(0, 0, 0, 0.24);
}

@media (max-width: 991px) {
  .page-projectreview .fixed-bar {
    padding: 10px;
  }
}

.page-projectreview .fixed-bar .align-items-center {
  align-items: center !important;
}

.page-projectreview .fixed-bar .bar-logo {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.page-projectreview .fixed-bar .bar-logo img {
  height: 100%;
  max-height: 50px;
  width: auto;
}

@media (max-width: 991px) {
  .page-projectreview .fixed-bar .bar-logo img {
    height: auto;
    width: 100%;
    max-width: 30px;
  }
}

.page-projectreview .fixed-bar .bar-logo .bar-text {
  margin-left: 10px;
  font-size: 16px;
  font-style: italic;
  font-weight: 700;
  color: #666;
}

@media (max-width: 991px) {
  .page-projectreview .fixed-bar .bar-logo .bar-text {
    font-size: 10px;
    line-height: 1.2;
  }
}

@media (max-width: 991px) {
  .page-projectreview .fixed-bar .bar-logo {
    width: 39%;
  }
}

@media (min-width: 1024px) and (max-width: 1024px) {
  .page-projectreview .fixed-bar .bar-logo {
    width: 82%;
  }
}

@media (min-width: 1025px) {
  .page-projectreview .fixed-bar .bar-logo {
    width: 52%;
  }
}

.page-projectreview .fixed-bar .fixed-buttons .btn {
  color: #fff;
  padding: 8px 20px;
  font-size: 18px;
  letter-spacing: 0;
  border-radius: 3px;
}

@media (max-width: 991px) {
  .page-projectreview .fixed-bar .fixed-buttons .btn {
    font-size: 14px;
    padding: 6px 15px;
  }
}

.page-projectreview .fixed-bar .fixed-buttons .btn.btn-enquire {
  background-color: #cc0000;
  border-color: #cc0000;
}

.page-projectreview .fixed-bar .fixed-buttons .btn.btn-enquire:hover {
  background: #990000;
}

.page-content-1886214 .page-projectreview #mainNav.navbar {
  background: #09428f;
}

.page-content-1886214 .page-projectreview section.page-hero .news-hero-bg {
  background-image: url(https://media.edgeprop.my/page/project-review/nippon/nippon-hero.jpg) !important;
}

.page-content-1886214 .page-projectreview section.page-hero .news-hero-bg .news-hero-main-component {
  top: 70px;
}

@media (max-width: 767px) {
  .page-content-1886214 .page-projectreview section.page-hero .news-hero-bg .news-hero-main-component {
    padding-top: 10px;
  }
}

.page-content-1886214 .page-projectreview section.page-hero .news-hero-bg .news-hero-main-component .news-hero-text {
  margin-bottom: 50px;
}

.page-content-1886214 .page-projectreview section.page-hero .news-hero-bg .news-hero-main-component .news-hero-text h1, .page-content-1886214 .page-projectreview section.page-hero .news-hero-bg .news-hero-main-component .news-hero-text h4 {
  text-shadow: 2px 6px 8px #000000;
}

@media (min-width: 768px) {
  .page-content-1886214 .page-projectreview section.page-hero .news-hero-bg {
    background-position: bottom right;
  }
}

.page-content-1886214 .page-projectreview section.page-hero .news-hero-bg .nbox-container {
  margin: 0 auto;
  text-align: center;
}

@media (min-width: 768px) {
  .page-content-1886214 .page-projectreview section.page-hero .news-hero-bg .nbox-container .nbox-row {
    display: inline-block;
  }
}

@media (max-width: 767px) {
  .page-content-1886214 .page-projectreview section.page-hero .news-hero-bg .nbox-container .nbox-row {
    display: block;
  }
}

@media (max-width: 767px) {
  .page-content-1886214 .page-projectreview section.page-hero .news-hero-bg .nbox-container .nbox-row.mtop {
    margin-top: 3px;
  }
}

@media (min-width: 1024px) {
  .page-content-1886214 .page-projectreview section.page-hero .news-hero-bg .nbox-container .nbox-row span {
    padding-right: 0;
  }
}

@media (max-width: 767px) {
  .page-content-1886214 .page-projectreview section.page-hero .news-hero-bg .nbox-container .nbox-row span img {
    width: 150px;
  }
}

@media (min-width: 1024px) {
  .page-content-1886214 .page-projectreview section.page-hero .news-hero-bg .nbox-container .nbox-row.second span:last-child {
    padding-right: 0;
  }
}

.page-content-1886214 .page-projectreview section.page-hero .news-hero-bg .nippon-logo {
  margin-bottom: 30px;
}

@media (min-width: 769px) {
  .page-content-1886214 .page-projectreview section.page-hero .news-hero-bg .nippon-logo {
    width: 332px;
  }
}

@media (max-width: 425px) {
  .page-content-1886214 .page-projectreview section.page-hero .news-hero-bg .nippon-logo {
    width: 200px;
  }
}

.page-content-1886214 .page-projectreview #mainNav.navbar, .page-content-1884779 .page-projectreview #mainNav.navbar, .page-content-1884770 .page-projectreview #mainNav.navbar {
  background: #09428f;
}

#budget {
  margin-top: 130px;
}

@media (min-width: 992px) {
  #budget {
    margin-top: 90px;
  }
}

@media (max-width: 768px) {
  #budget .budget-logo.v21 {
    max-width: 95%;
  }
}

#budget a {
  color: #363a94;
  text-decoration: underline;
}

#budget a:hover {
  text-decoration: none;
}

#budget section {
  padding: 25px 0 50px;
}

#budget section .heading-word,
#budget section .heading-secondary {
  display: block;
  font-size: 3.5rem;
  padding-top: 40px;
  font-weight: 800;
}

@media (max-width: 768px) {
  #budget section .heading-word,
  #budget section .heading-secondary {
    font-size: 2.2rem;
  }
}

#budget section .heading-secondary {
  padding-top: 10px;
  font-weight: 600;
  font-size: 3rem;
}

@media (max-width: 768px) {
  #budget section .heading-secondary {
    font-size: 1.9rem;
  }
}

@media (max-width: 768px) {
  #budget section {
    padding: 0px 0 50px;
  }
}

#budget section.webform-form {
  padding: 22px 0 15px;
}

#budget section.general {
  position: relative;
  padding: 50px 0 50px;
  overflow: hidden;
}

#budget section.top {
  padding: 0;
}

#budget section.heading-top {
  padding: 25px 0 0;
}

#budget section.heading-top .title {
  font-weight: 800;
  font-size: 1.7rem;
  margin: 4rem 0;
}

#budget section.heading-top .title-1 {
  color: #005c98;
}

#budget section.heading-top .title-2 {
  color: #f9cc13;
}

#budget section.terms-conditions li {
  font-size: 1.2rem;
}

#budget section ul, #budget section ol {
  text-align: left;
}

@media (min-width: 992px) {
  #budget section ul, #budget section ol {
    margin: 0;
    padding: 0;
  }
}

#budget section ul li, #budget section ol li {
  margin-bottom: 10px;
}

#budget section ul li span.step-title, #budget section ol li span.step-title {
  text-transform: uppercase;
  display: block;
  font-weight: 600;
  font-size: 2rem;
  color: #005c98;
}

#budget section ul.ehc-ul, #budget section ol.ehc-ul {
  text-align: center;
}

#budget section ul li {
  margin-bottom: 25px;
}

#budget .btn-ehc {
  text-decoration: none;
  background: #ff5122;
  color: #fff;
  padding: 20px 25px;
  font-weight: 800;
}

@media (max-width: 1024px) {
  #budget .btn-ehc {
    padding: 20px 8px;
  }
}

#budget .btn-ehc:hover {
  background: #fff;
  color: #ff5122;
  border: 1px solid #ff5122;
}

#budget .btn-ehc a {
  text-decoration: none;
  color: #fff;
}

#budget .btn-ehc a:hover {
  color: #ff5122;
}

#budget .btn-border-radius-no {
  border-radius: 0;
}

#budget input {
  width: 100%;
}

#budget li {
  font-size: 1.7rem;
}

#contest-page.township .error {
  color: red;
}

#contest-page.township #loader-class {
  text-align: center;
  width: 100%;
}

#contest-page.township #loader-img {
  max-width: 40px;
}

#contest-page.township #name-error, #contest-page.township #email-error, #contest-page.township #number-error, #contest-page.township #tem-cond-error, #contest-page.township #otp-div, #contest-page.township #enquiry-submit-otp, #contest-page.township #loader-class {
  display: none;
}

#contest-page.township #inquiry-button-otp {
  cursor: pointer;
}

#contest-page.township .inquiry-button-otp {
  width: 100%;
  margin: 0 auto;
  display: inherit;
  cursor: pointer;
  font-size: 1.9rem;
  line-height: 1.3;
  text-transform: uppercase;
  border: 1px solid #cc0000;
  background: #cc0000;
  color: #fff;
  text-align: center;
  padding: 12px 80px;
  margin-top: 10px;
  display: inline-block;
}

#contest-page.township .form-to-download {
  padding-top: 20px;
  background: #efefef;
}

#contest-page.township .form-to-download a {
  color: inherit;
  text-decoration: none;
}

#contest-page.township .form-to-download .row {
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px;
}

#contest-page.township .form-to-download .row:before {
  display: table;
  content: " ";
}

#contest-page.township .form-to-download [class*=col-] {
  padding-right: 0;
  padding-left: 0;
  position: relative;
  width: 100%;
  min-height: 1px;
}

#contest-page.township .form-to-download .col-1 {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 8.333333%;
  flex: 0 0 8.333333%;
  max-width: 8.333333%;
}

#contest-page.township .form-to-download .col-10 {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 83.333333%;
  flex: 0 0 83.333333%;
  max-width: 83.333333%;
}

#contest-page.township .form-to-download h2 {
  text-transform: none;
  color: #555;
  font-style: italic;
  font-size: 28px;
  text-align: center;
  margin-bottom: 30px;
}

#contest-page.township .form-to-download .inner-form {
  max-width: 980px;
  margin: 0 auto;
}

#contest-page.township .form-to-download #moreinfo {
  width: 100%;
  max-width: 500px;
  margin: 0 auto;
}

#contest-page.township .form-to-download #moreinfo .form-group {
  margin-bottom: 20px;
}

#contest-page.township .form-to-download .download-box {
  width: 100%;
  max-width: 500px;
  margin: 0 auto;
}

#contest-page.township .form-to-download label {
  font-size: 11px;
}

#contest-page.township .form-to-download .label-pdpa {
  position: relative;
  right: -6px;
  top: -3px;
  margin-right: 8px;
}

#contest-page.township .form-to-download .inquiry-button {
  width: 100%;
  margin: 0 auto;
  display: inherit;
  cursor: pointer;
  font-size: 1.9rem;
  line-height: 1.3;
  text-transform: uppercase;
  border: 1px solid #007F38;
  background: #007F38;
  color: #fff;
  text-align: center;
  padding: 12px 80px;
  margin-top: 30px;
  display: inline-block;
  cursor: pointer;
}

#contest-page.township .page-desc {
  padding: 20px;
  line-height: 2;
}

#contest-page.township .page-desc p {
  font-size: 15px;
}

#contest-page.township .hero {
  background: url(https://media.edgeprop.my/page/townshipguidebook/township-hero.jpg) no-repeat center;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  height: 685px;
}

@media (max-width: 500px) {
  #contest-page.township .hero {
    height: 230px;
  }
}

@media (max-width: 320px) {
  #contest-page.township .hero {
    height: 198px;
  }
}

@media (min-width: 1024px) and (max-width: 1024px) {
  #contest-page.township .hero {
    height: 610px;
  }
}

#contest-page.township footer {
  font-style: normal;
  color: #fff;
  padding: 20px 0;
  text-align: center;
  color: rgba(255, 255, 255, 0.3);
  background-color: #222;
}

#contest-page.township .contest-banner {
  display: block;
  padding: 30px 20px;
  text-align: center;
}

#contest-page.township .contest-banner img {
  display: block;
  width: 100%;
  max-width: 600px;
  text-align: center;
  margin: 0 auto 30px;
}

#contest-page .brand-footer {
  margin-right: 15px;
  font-size: 12px;
  font-style: italic;
}

#contest-page .brand-footer a {
  color: inherit;
  text-decoration: none;
}

#contest-page .brand-footer a:active {
  color: inherit;
}

#contest-page .fixed-bar {
  position: fixed;
  background: #fff;
  width: 100%;
  padding: 15px 60px;
  bottom: 0;
  left: 0;
  z-index: 4;
  box-shadow: 0 -5px 10px rgba(0, 0, 0, 0.24);
}

@media (max-width: 991px) {
  #contest-page .fixed-bar {
    padding: 10px;
  }
}

#contest-page .fixed-bar .align-items-center {
  align-items: center !important;
}

#contest-page .fixed-bar .bar-logo {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

#contest-page .fixed-bar .bar-logo img {
  height: 100%;
  max-height: 50px;
  width: auto;
}

@media (max-width: 991px) {
  #contest-page .fixed-bar .bar-logo img {
    height: auto;
    width: 100%;
    max-width: 30px;
  }
}

#contest-page .fixed-bar .bar-logo .bar-text {
  margin-left: 10px;
  font-size: 16px;
  font-style: italic;
  font-weight: 700;
  color: #666;
}

@media (max-width: 991px) {
  #contest-page .fixed-bar .bar-logo .bar-text {
    font-size: 10px;
    line-height: 1.2;
  }
}

@media (max-width: 991px) {
  #contest-page .fixed-bar .bar-logo {
    width: 39%;
  }
}

@media (min-width: 1024px) and (max-width: 1024px) {
  #contest-page .fixed-bar .bar-logo {
    width: 82%;
  }
}

@media (min-width: 1025px) {
  #contest-page .fixed-bar .bar-logo {
    width: 52%;
  }
}

#contest-page .fixed-bar .fixed-buttons .btn {
  color: #fff;
  padding: 8px 20px;
  font-size: 18px;
  letter-spacing: 0;
  border-radius: 3px;
  text-decoration: none;
}

@media (max-width: 991px) {
  #contest-page .fixed-bar .fixed-buttons .btn {
    font-size: 14px;
    padding: 6px 15px;
  }
}

#contest-page .fixed-bar .fixed-buttons .btn.btn-enquire {
  background-color: #007F38;
  border-color: #007F38;
}

#contest-page .fixed-bar .fixed-buttons .btn.btn-enquire:hover {
  background: #004c22;
}

#contest-page .fixed-bar .fixed-buttons .btn.btn-enquire a {
  text-decoration: none;
}

#contest-page a {
  color: #363a94;
  text-decoration: underline;
}

#contest-page a:hover {
  text-decoration: none;
}

#contest-page section {
  padding: 25px 0 50px;
}

#contest-page section .introword {
  font-weight: 800;
  font-size: 2.6rem;
}

@media (max-width: 768px) {
  #contest-page section .introword {
    font-size: 2rem;
  }
}

@media (max-width: 768px) {
  #contest-page section {
    padding: 0px 0 50px;
  }
}

#contest-page section.webform-form {
  padding: 22px 0 15px;
}

#contest-page section.general {
  position: relative;
  padding: 50px 0 50px;
  overflow: hidden;
}

#contest-page section.top {
  padding: 0;
}

body.page-airsupplycontest #contest-page section.top .top-bg {
  background: url("https://media.edgeprop.my/page/airsupply/1511/d.jpg") no-repeat center;
  background-size: cover;
  height: 685px;
}

@media (min-width: 1564px) {
  body.page-airsupplycontest #contest-page section.top .top-bg {
    height: 807px;
  }
}

@media (min-width: 1900px) {
  body.page-airsupplycontest #contest-page section.top .top-bg {
    height: 955px;
  }
}

@media (max-width: 768px) {
  body.page-airsupplycontest #contest-page section.top .top-bg {
    background: url("https://media.edgeprop.my/page/airsupply/1511/t.jpg") no-repeat center;
    height: 390px;
  }
}

@media (max-width: 425px) {
  body.page-airsupplycontest #contest-page section.top .top-bg {
    background: url("https://media.edgeprop.my/page/airsupply/1511/425.jpg") no-repeat center;
    height: 238px;
    margin-top: -11px;
  }
}

@media (max-width: 375px) {
  body.page-airsupplycontest #contest-page section.top .top-bg {
    background: url("https://media.edgeprop.my/page/airsupply/1511/375.jpg") no-repeat center;
    margin-top: -23px;
  }
}

@media (max-width: 360px) {
  body.page-airsupplycontest #contest-page section.top .top-bg {
    background: url("https://media.edgeprop.my/page/airsupply/1511/360.jpg") no-repeat center;
    margin-top: -26px;
  }
}

@media (max-width: 320px) {
  body.page-airsupplycontest #contest-page section.top .top-bg {
    background: url("https://media.edgeprop.my/page/airsupply/1511/320.jpg") no-repeat center;
    margin-top: -37px;
  }
}

body.page-ewf #contest-page section.top .top-bg,
body.page-node-42 #contest-page section.top .top-bg {
  background: url("https://media.edgeprop.my/page/ewfcontest/1349.jpg") no-repeat center;
  background-size: cover;
  height: 685px;
}

@media (min-width: 1564px) {
  body.page-ewf #contest-page section.top .top-bg,
  body.page-node-42 #contest-page section.top .top-bg {
    height: 807px;
  }
}

@media (min-width: 1900px) {
  body.page-ewf #contest-page section.top .top-bg,
  body.page-node-42 #contest-page section.top .top-bg {
    height: 955px;
  }
}

@media (max-width: 768px) {
  body.page-ewf #contest-page section.top .top-bg,
  body.page-node-42 #contest-page section.top .top-bg {
    background: url("https://media.edgeprop.my/page/ewfcontest/768.jpg") no-repeat center;
    height: 390px;
  }
}

@media (max-width: 425px) {
  body.page-ewf #contest-page section.top .top-bg,
  body.page-node-42 #contest-page section.top .top-bg {
    background: url("https://media.edgeprop.my/page/ewfcontest/425.jpg") no-repeat center;
    height: 238px;
    margin-top: -11px;
  }
}

@media (max-width: 375px) {
  body.page-ewf #contest-page section.top .top-bg,
  body.page-node-42 #contest-page section.top .top-bg {
    background: url("https://media.edgeprop.my/page/ewfcontest/375.jpg") no-repeat center;
    margin-top: -23px;
  }
}

@media (max-width: 360px) {
  body.page-ewf #contest-page section.top .top-bg,
  body.page-node-42 #contest-page section.top .top-bg {
    background: url("https://media.edgeprop.my/page/ewfcontest/360.jpg") no-repeat center;
    margin-top: -26px;
  }
}

@media (max-width: 320px) {
  body.page-ewf #contest-page section.top .top-bg,
  body.page-node-42 #contest-page section.top .top-bg {
    background: url("https://media.edgeprop.my/page/ewfcontest/320.jpg") no-repeat center;
    margin-top: -37px;
  }
}

body.page-pepscontest #contest-page section.top .top-bg,
body.page-node-43 #contest-page section.top .top-bg,
body.page-node-37 #contest-page section.top .top-bg {
  background: url("https://media.edgeprop.my/page/peps/1349.jpg") no-repeat center;
  background-size: cover;
  height: 685px;
}

@media (min-width: 1564px) {
  body.page-pepscontest #contest-page section.top .top-bg,
  body.page-node-43 #contest-page section.top .top-bg,
  body.page-node-37 #contest-page section.top .top-bg {
    height: 807px;
  }
}

@media (min-width: 1900px) {
  body.page-pepscontest #contest-page section.top .top-bg,
  body.page-node-43 #contest-page section.top .top-bg,
  body.page-node-37 #contest-page section.top .top-bg {
    height: 955px;
  }
}

@media (max-width: 768px) {
  body.page-pepscontest #contest-page section.top .top-bg,
  body.page-node-43 #contest-page section.top .top-bg,
  body.page-node-37 #contest-page section.top .top-bg {
    background: url("https://media.edgeprop.my/page/peps/768.jpg") no-repeat center;
    height: 390px;
  }
}

@media (max-width: 425px) {
  body.page-pepscontest #contest-page section.top .top-bg,
  body.page-node-43 #contest-page section.top .top-bg,
  body.page-node-37 #contest-page section.top .top-bg {
    background: url("https://media.edgeprop.my/page/peps/425.jpg") no-repeat center;
    height: 238px;
    margin-top: -11px;
  }
}

@media (max-width: 375px) {
  body.page-pepscontest #contest-page section.top .top-bg,
  body.page-node-43 #contest-page section.top .top-bg,
  body.page-node-37 #contest-page section.top .top-bg {
    background: url("https://media.edgeprop.my/page/peps/375.jpg") no-repeat center;
    margin-top: -23px;
  }
}

@media (max-width: 360px) {
  body.page-pepscontest #contest-page section.top .top-bg,
  body.page-node-43 #contest-page section.top .top-bg,
  body.page-node-37 #contest-page section.top .top-bg {
    background: url("https://media.edgeprop.my/page/peps/360.jpg") no-repeat center;
    margin-top: -26px;
  }
}

@media (max-width: 320px) {
  body.page-pepscontest #contest-page section.top .top-bg,
  body.page-node-43 #contest-page section.top .top-bg,
  body.page-node-37 #contest-page section.top .top-bg {
    background: url("https://media.edgeprop.my/page/peps/320.jpg") no-repeat center;
    margin-top: -37px;
  }
}

#contest-page section.how-to-win {
  padding: 25px 0 0;
}

#contest-page section.how-to-win .title {
  font-weight: 800;
  font-size: 1.7rem;
  margin: 4rem 0;
}

#contest-page section.how-to-win .title-1 {
  color: #005c98;
}

#contest-page section.how-to-win .title-2 {
  color: #f9cc13;
}

#contest-page section.terms-conditions li {
  font-size: 1.2rem;
}

#contest-page section ul, #contest-page section ol {
  text-align: left;
}

@media (min-width: 992px) {
  #contest-page section ul, #contest-page section ol {
    margin: 0;
    padding: 0;
  }
}

#contest-page section ul li, #contest-page section ol li {
  margin-bottom: 10px;
}

#contest-page section ul li span.step-title, #contest-page section ol li span.step-title {
  text-transform: uppercase;
  display: block;
  font-weight: 600;
  font-size: 2rem;
  color: #005c98;
}

#contest-page section ul.ehc-ul, #contest-page section ol.ehc-ul {
  text-align: center;
}

#contest-page section ul li {
  margin-bottom: 25px;
}

#contest-page h2, #contest-page h3 {
  text-transform: uppercase;
}

#contest-page h5 {
  font-size: 2.8rem;
  text-transform: capitalize;
  font-weight: 800;
  text-align: center;
}

@media (max-width: 768px) {
  #contest-page h5 {
    font-size: 2rem;
  }
}

#contest-page h2 {
  margin-bottom: 1rem;
  font-size: 4rem;
  font-weight: 800;
}

#contest-page h2 span.sub-h2 {
  text-transform: none;
  font-size: 2rem;
  font-weight: 400;
  display: block;
  margin: 1.5rem 0 2.5rem;
}

@media (min-width: 992px) {
  #contest-page h2 {
    width: 70%;
    margin: 0 auto;
  }
}

#contest-page h3 {
  font-size: 1.7rem;
  margin-bottom: 25px;
}

@media (max-width: 767px) {
  #contest-page h3 {
    padding-top: 18px;
  }
}

#contest-page h4 {
  font-weight: 800;
  margin-top: 40px;
}

#contest-page .btn-ehc {
  text-decoration: none;
  background: #ff5122;
  color: #fff;
  padding: 20px 25px;
  font-weight: 800;
}

@media (max-width: 1024px) {
  #contest-page .btn-ehc {
    padding: 20px 8px;
  }
}

#contest-page .btn-ehc:hover {
  background: #fff;
  color: #ff5122;
  border: 1px solid #ff5122;
}

#contest-page .btn-ehc a {
  text-decoration: none;
  color: #fff;
}

#contest-page .btn-ehc a:hover {
  color: #ff5122;
}

#contest-page .btn-border-radius-no {
  border-radius: 0;
}

#contest-page input {
  width: 100%;
}

#contest-page li {
  font-size: 1.7rem;
}

.guides-wrap {
  width: 100%;
  max-width: 1020px;
  margin: 0 auto;
  position: relative;
  top: 75px;
  margin-bottom: 75px;
  /* safari fix */
}

.guides-wrap .guides-main-header-wrap {
  margin: 50px 0;
  text-align: center;
}

.guides-wrap .guides-main-header-wrap h1 {
  color: #3c475b;
  font-size: 30px;
  line-height: 40px;
  font-weight: 600;
}

.guides-wrap .guides-main-header-wrap p {
  color: #3c475b;
  font-family: Georgia,serif;
  font-size: 18px;
  line-height: 26px;
  font-style: italic;
}

.guides-wrap .guides-main-card-wrap {
  display: flex;
  flex-wrap: wrap;
  -webkit-box-pack: center;
  justify-content: center;
  margin: -10px 0;
}

.guides-wrap .guides-main-card-wrap .card {
  position: relative;
  overflow: hidden;
  width: 100%;
  margin: 10px;
}

@media (min-width: 624px) {
  .guides-wrap .guides-main-card-wrap .card {
    width: calc(1/2 * 100% - 20px);
  }
}

@media (min-width: 1021px) {
  .guides-wrap .guides-main-card-wrap .card {
    width: calc(1/3 * 100% - 20px);
  }
}

.guides-wrap .guides-main-card-wrap .card img {
  display: block;
  width: 100%;
  height: auto;
  transition: filter .25s ease-in-out,-webkit-filter .25s ease-in-out;
}

.guides-wrap .guides-main-card-wrap .card a {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(30, 41, 61, 0.5);
  text-align: center;
  text-decoration: none;
}

.guides-wrap .guides-main-card-wrap .card .cat-text-wrap {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  overflow: hidden;
  transition: margin-top .25s ease-in-out;
}

.guides-wrap .guides-main-card-wrap .card .cat-text-wrap .cat-text {
  font-size: 20px;
  line-height: 24px;
  display: inline-block;
  border-bottom: 2px solid #fff;
  margin-bottom: 0;
  padding: 0 20px 10px;
  color: #fff;
  font-weight: 500 !important;
  text-transform: uppercase;
  transition: margin-bottom .25s ease-in-out;
}

.guides-wrap .guides-main-card-wrap .card:hover img {
  webkit-filter: blur(4px);
  /* Chrome, Safari, Opera */
  filter: blur(4px);
}

.guides-wrap .row:before, .guides-wrap .row:after {
  display: none !important;
}

@media (min-width: 1023px) {
  body.node-type-tep-blogs .wrap.news-wope-theme {
    margin-top: 124px;
  }
}

@media (max-width: 768px) {
  body.node-type-tep-blogs .wrap.news-wope-theme {
    margin-top: 0;
  }
}

body.node-type-tep-blogs .wrap.news-wope-theme .entry-meta {
  display: none;
}

@media (max-width: 490px) {
  body.node-type-tep-blogs .wrap.news-wope-theme .entry-meta {
    padding-left: 0;
  }
}

@media (max-width: 576px) {
  body.node-type-tep-blogs .wrap.news-wope-theme .main-content .news-article h1 {
    z-index: 77;
    position: relative;
    background: #fff;
    pointer-events: auto;
    padding: 10px;
    margin: 0;
    font-size: 2.5rem;
  }
}

body.node-type-tep-blogs .wrap.news-wope-theme #bigleaderboard .wide-ad {
  margin: 0;
}

body[class*="page-guides-"] .breadcrumb,
body[class*="page-guides-"] .page-header {
  display: none;
}

@media (max-width: 800px) {
  body[class*="page-guides-"] .top-nav {
    margin-bottom: 0;
  }
}

body[class*="page-guides-"] .main .content-area .container:nth-child(2) {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
  margin-top: 70px;
}

@media (min-width: 1200px) {
  body[class*="page-guides-"] .main .content-area .container:nth-child(2) {
    max-width: 1200px;
  }
}

@media (max-width: 991px) {
  body[class*="page-guides-"] .main .content-area .container:nth-child(2) {
    margin-top: 0;
  }
}

body[class*="page-guides-"] .main .content-area .container:nth-child(2) .row .content-column {
  width: 67.25%;
}

@media (max-width: 800px) {
  body[class*="page-guides-"] .main .content-area .container:nth-child(2) .row .content-column {
    width: 100%;
  }
}

@media (max-width: 768px) {
  body[class*="page-guides-"] .main .content-area .container:nth-child(2) .row .content-column .page-heading {
    display: inline-block;
  }
}

body[class*="page-guides-"] .main .content-area .container:nth-child(2) .row .content-column .tepblog {
  margin: 0;
  width: 100%;
  top: 0;
}

body[class*="page-guides-"] .main .content-area .container:nth-child(2) .row .content-column .tepblog .main-content {
  width: 100%;
  padding: 0;
}

body[class*="page-guides-"] .main .content-area .container:nth-child(2) .row .sidebar-second {
  padding-top: 130px;
}

@media (max-width: 768px) {
  body[class*="page-guides-"] .main .content-area .container:nth-child(2) .row .sidebar-second {
    padding-top: 0;
  }
}

body[class*="page-guides-"] .main ul.pagination {
  margin-top: 100px;
}

@media (max-width: 768px) {
  body[class*="page-guides-"] .main ul.pagination {
    margin-top: 0;
  }
}

body[class*="page-guides-"] .main ul.pagination .active span {
  padding: 0.8rem 1.6rem;
  border-radius: 0;
  background-color: rgba(59, 131, 156, 0.85);
  color: #ffffff;
}

body[class*="page-guides-"] .main ul.pagination li a {
  padding: 0.8rem 1.6rem;
}

body[class*="page-guides-"] .main ul.pagination li a:hover {
  color: #000;
  background-color: rgba(59, 131, 156, 0.1);
}

body.page-livefireside {
  font-size: 15px;
}

body.page-livefireside #contest-page section .introword {
  margin: 30px 0;
  display: block;
}

body.page-livefireside .content-wrapper {
  width: 100%;
  text-align: center;
}

@media (min-width: 769px) {
  body.page-livefireside .content-wrapper {
    max-width: 700px;
    margin: 30px auto;
  }
}

body.page-livefireside a.btn-fb-livefireside {
  text-decoration: none !important;
  padding: 20px;
  background: #ffe056;
  display: block;
  margin: 20px auto;
  border-radius: 5px;
  font-weight: 700;
  font-size: 20px;
  max-width: 300px;
  color: #222 !important;
}

body.page-livefireside a.btn-fb-livefireside:hover {
  background: #f3d242;
}

body.page-livefireside p {
  line-height: 1.5;
  font-size: 15px;
}

body.page-livefireside p.p-big {
  font-size: 16px;
  line-height: 1.8;
}

#bma-page {
  font-family: "poppins", serif;
  color: #fff;
}

#bma-page .bma-sub-h4 {
  font-size: 2rem;
  text-transform: unset;
  margin-bottom: 20px;
}

#bma-page .col-md-4 {
  text-align: center;
}

#bma-page .col-md-4 .badge-more,
#bma-page .col-md-6.text-center .badge-more,
#bma-page .col-md-12.text-center .badge-more {
  border-radius: 50%;
  background: #f92626;
  width: 45px;
  height: 45px;
  padding: 11px 0 0;
  position: absolute;
  bottom: 197px;
  right: 57px;
  font-size: 10px;
  line-height: 1.2;
  cursor: pointer;
  pointer-events: none;
}

@media (max-width: 2000px) {
  #bma-page .col-md-4 .badge-more {
    bottom: 202px;
    right: 113px;
  }
}

@media (max-width: 1680px) {
  #bma-page .col-md-4 .badge-more {
    right: 64px;
  }
}

@media (max-width: 1440px) {
  #bma-page .col-md-4 .badge-more {
    right: 49px;
  }
}

@media (max-width: 1366px) {
  #bma-page .col-md-4 .badge-more {
    right: 36px;
  }
}

@media (max-width: 1280px) {
  #bma-page .col-md-4 .badge-more {
    right: 25px;
  }
}

@media (min-width: 1024px) and (max-width: 1024px) {
  #bma-page .col-md-4 .badge-more {
    bottom: 167px;
    right: 32px;
  }
}

@media (min-width: 768px) and (max-width: 768px) {
  #bma-page .col-md-4 .badge-more {
    bottom: 137px;
    right: 21px;
  }
}

@media (max-width: 490px) {
  #bma-page .col-md-4 .badge-more {
    bottom: 201px;
    right: 35px;
  }
}

@media (max-width: 375px) {
  #bma-page .col-md-4 .badge-more {
    right: 15px;
  }
}

@media (max-width: 320px) {
  #bma-page .col-md-4 .badge-more {
    bottom: 180px;
    right: 8px;
  }
}

@media (max-width: 2000px) {
  #bma-page .col-md-6.text-center .badge-more {
    bottom: 202px;
    right: 142px;
  }
}

@media (max-width: 1680px) {
  #bma-page .col-md-6.text-center .badge-more {
    right: 88px;
  }
}

@media (max-width: 1440px) {
  #bma-page .col-md-6.text-center .badge-more {
    right: 69px;
  }
}

@media (max-width: 1366px) {
  #bma-page .col-md-6.text-center .badge-more {
    right: 57px;
  }
}

@media (max-width: 1280px) {
  #bma-page .col-md-6.text-center .badge-more {
    right: 42px;
  }
}

@media (min-width: 1024px) and (max-width: 1024px) {
  #bma-page .col-md-6.text-center .badge-more {
    bottom: 167px;
    right: 51px;
  }
}

@media (min-width: 768px) and (max-width: 768px) {
  #bma-page .col-md-6.text-center .badge-more {
    bottom: 137px;
    right: 72px;
  }
}

@media (max-width: 490px) {
  #bma-page .col-md-6.text-center .badge-more {
    right: 35px;
  }
}

@media (max-width: 375px) {
  #bma-page .col-md-6.text-center .badge-more {
    right: 15px;
  }
}

@media (max-width: 320px) {
  #bma-page .col-md-6.text-center .badge-more {
    bottom: 180px;
    right: 8px;
  }
}

@media (max-width: 2000px) {
  #bma-page .col-md-12.text-center .badge-more {
    bottom: 202px;
    right: 425px;
  }
}

@media (max-width: 1680px) {
  #bma-page .col-md-12.text-center .badge-more {
    right: 319px;
  }
}

@media (max-width: 1440px) {
  #bma-page .col-md-12.text-center .badge-more {
    right: 282px;
  }
}

@media (max-width: 1366px) {
  #bma-page .col-md-12.text-center .badge-more {
    right: 257px;
  }
}

@media (max-width: 1280px) {
  #bma-page .col-md-12.text-center .badge-more {
    right: 234px;
  }
}

@media (min-width: 1024px) and (max-width: 1024px) {
  #bma-page .col-md-12.text-center .badge-more {
    bottom: 167px;
    right: 214px;
  }
}

@media (min-width: 768px) and (max-width: 768px) {
  #bma-page .col-md-12.text-center .badge-more {
    bottom: 137px;
    right: 235px;
  }
}

@media (max-width: 490px) {
  #bma-page .col-md-12.text-center .badge-more {
    bottom: 201px;
    right: 35px;
  }
}

@media (max-width: 375px) {
  #bma-page .col-md-12.text-center .badge-more {
    right: 15px;
  }
}

@media (max-width: 320px) {
  #bma-page .col-md-12.text-center .badge-more {
    bottom: 180px;
    right: 8px;
  }
}

#bma-page footer {
  text-align: center;
  margin-bottom: 30px;
}

#bma-page footer .bma-footer.desktop {
  display: none;
  max-width: 1000px;
  margin: 0 auto;
}

@media (min-width: 768px) {
  #bma-page footer .bma-footer.desktop {
    display: block;
  }
}

#bma-page footer .bma-footer.mobile {
  max-width: 320px;
  display: none;
  margin: 0 auto;
}

@media (max-width: 767px) {
  #bma-page footer .bma-footer.mobile {
    display: block;
  }
}

#bma-page select#select-years {
  background: transparent;
  border-radius: 0;
  color: #fff;
  font-size: 1.2rem;
  height: 29px;
  position: relative;
  top: 10px;
}

@media (max-width: 767px) {
  #bma-page select#select-years {
    top: 0;
  }
}

#bma-page select#select-years option {
  background: transparent;
  color: #333;
}

@media (min-width: 768px) and (max-width: 991px) {
  #bma-page select#select-years {
    top: 6px;
  }
}

#bma-page img {
  width: 100%;
}

#bma-page a {
  -webkit-transition: all .3s;
  -moz-transition: all .3s;
  transition: all 0.3s;
}

#bma-page a:hover {
  color: #fdc436;
  text-decoration: none;
}

#bma-page hr {
  max-width: 100px;
  margin: 25px auto 0;
  border-width: 1px;
  border-color: rgba(34, 34, 34, 0.1);
}

#bma-page hr.light {
  border-color: #fff;
}

#bma-page h1, #bma-page h2, #bma-page h3, #bma-page h4, #bma-page h5, #bma-page h6 {
  font-family: "poppins", serif;
  text-transform: uppercase;
  font-weight: 700;
}

#bma-page h1, #bma-page h3 {
  text-align: center;
  padding: 0 0 20px 0;
  margin: 0 0 20px 0;
}

#bma-page h3 {
  font-weight: 200;
}

#bma-page .sub-header {
  text-align: center;
  font-size: 2rem;
  padding: 20px 0 30px;
  width: 60%;
  margin: 0 auto;
}

#bma-page p {
  font-size: 1.6rem;
  line-height: 1.5;
  margin-bottom: 20px;
}

@media (max-width: 768px) {
  #bma-page p {
    font-size: 1.3rem;
    line-height: 1.4;
  }
}

#bma-page nav#winner-tab {
  margin: 0 auto 20px;
  font-size: 1.5rem;
  border-bottom: 1px solid #000;
}

#bma-page nav#winner-tab .nav-tabs {
  border-bottom: 0;
}

#bma-page nav#winner-tab .nav-fill a.nav-link {
  line-height: 1.3;
  background: transparent;
  border-color: transparent;
  color: #fff;
  border-bottom: 1px solid #03001c;
}

#bma-page nav#winner-tab .nav-fill a.nav-link.active, #bma-page nav#winner-tab .nav-fill a.nav-link:hover {
  border-bottom: 3px solid #ffe4a0;
  color: #ffe4a0;
}

@media (min-width: 768px) {
  #bma-page nav#winner-tab .nav-fill a.nav-link {
    width: 20%;
  }
}

@media (max-width: 576px) {
  #bma-page nav#winner-tab .nav-fill a.nav-link {
    font-size: 1.2rem;
  }
}

@media (max-width: 490px) {
  #bma-page nav#winner-tab .nav-fill a.nav-link {
    font-size: 1.2rem;
    padding: .5rem .1rem;
  }
}

@media (min-width: 991px) {
  #bma-page nav#winner-tab {
    width: 80%;
    margin-bottom: 40px;
  }
}

#bma-page section {
  background: #000;
  padding: 50px 0;
}

#bma-page section li {
  font-size: 1.6rem;
  margin-bottom: 9px;
}

@media (max-width: 768px) {
  #bma-page section li {
    font-size: 1.3rem;
    margin-bottom: 7px;
  }
}

#bma-page section.general {
  border-top: 10px solid #fdb913;
  position: relative;
  padding: 50px 0 50px;
  overflow: hidden;
}

#bma-page section.general header {
  height: 244px;
  margin-bottom: 30px;
  background-position: center;
}

#bma-page section.general.no-border {
  border-top: 0;
}

#bma-page section.general.p-t-25 {
  padding-top: 25px;
}

#bma-page section.home {
  padding: 0;
  height: 428px;
  background: url("https://media.edgeprop.my/page/bmpa/2020/hero-rev2.jpg") no-repeat;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  background-position: center -89px;
  display: block;
  overflow: hidden;
}

@media (min-width: 1280px) {
  #bma-page section.home {
    height: 445px;
  }
}

@media (min-width: 1440px) {
  #bma-page section.home {
    height: 485px;
  }
}

@media (min-width: 1564px) and (max-width: 1564px) {
  #bma-page section.home {
    height: 540px;
  }
}

@media (min-width: 1600px) {
  #bma-page section.home {
    height: 610px;
    background-position: center -57px;
  }
}

@media (min-width: 2000px) {
  #bma-page section.home {
    height: 626px;
    background-position: center -139px;
  }
}

@media (max-width: 1024px) {
  #bma-page section.home {
    height: 357px;
    background-position: center -57px;
  }
}

@media (max-width: 768px) {
  #bma-page section.home {
    height: 387px;
    background-position: center 0;
  }
}

@media (max-width: 576px) {
  #bma-page section.home {
    height: 171px;
    background-position: center -44px;
  }
}

@media (max-width: 490px) {
  #bma-page section.home {
    height: 164px;
    background-position: center -30px;
  }
}

@media (max-width: 425px) {
  #bma-page section.home {
    height: 151px;
    background-position: center -24px;
  }
}

@media (max-width: 414px) {
  #bma-page section.home {
    height: 159px;
    background-position: center -20px;
  }
}

@media (max-width: 375px) {
  #bma-page section.home {
    height: 148px;
    background-position: center -16px;
  }
}

@media (max-width: 320px) {
  #bma-page section.home {
    height: 121px;
    background-position: center -19px;
  }
}

#bma-page section.home .kpkt-endorsement {
  width: 142px;
  position: relative;
  right: 11px;
  float: right;
  top: 34px;
}

@media (max-width: 767px) {
  #bma-page section.home .kpkt-endorsement {
    width: 64px;
    top: 0;
    right: 32px;
  }
}

@media (max-width: 320px) {
  #bma-page section.home .kpkt-endorsement {
    width: 49px;
    right: 21px;
  }
}

#bma-page section.featured .featured-icon {
  margin-bottom: 25px;
  cursor: pointer;
}

#bma-page section.featured .featured-icon:hover img {
  opacity: 0.5;
}

#bma-page section.featured .featured-icon img {
  margin-bottom: 10px;
  max-width: 170px;
}

#bma-page section.featured .featured-title {
  font-size: 1.5rem;
  font-weight: 500;
  line-height: 1.2;
}

@media (max-width: 490px) {
  #bma-page section.featured .featured-title {
    font-size: 1rem;
    line-height: 1.1;
  }
}

@media (min-width: 375px) and (max-width: 375px) {
  #bma-page section.featured .col-6 {
    flex: none;
    max-width: 49%;
    float: none;
  }
}

@media (max-width: 320px) {
  #bma-page section.featured .col-6 {
    flex: none;
    max-width: 100%;
  }
}

#bma-page section.winners ul {
  padding: 0 0 0 16px;
}

#bma-page section.winners h4 {
  margin: 20px 0;
  padding-bottom: 15px;
  font-family: poppins;
  font-weight: 500;
  font-size: 1.5rem;
  letter-spacing: 1px;
  color: #fff;
}

@media (min-width: 1280px) {
  #bma-page section.winners h4 {
    font-size: 2rem;
  }
}

#bma-page section.winners h3 {
  letter-spacing: .8px;
  font-size: 1.6rem;
  font-family: 'poppins';
  font-weight: 600;
  border-top: 1px dotted #999;
  padding: 20px;
  color: #fdb913;
  margin-top: 70px;
}

@media (min-width: 1280px) {
  #bma-page section.winners h3 {
    letter-spacing: 1px;
    font-size: 2rem;
  }
}

#bma-page section.judges .judge-text {
  display: block;
  padding: 10px 15px;
  text-align: center;
  margin-bottom: 30px;
}

#bma-page section.judges .judge-text .judge-title {
  color: #ffe4a0;
  font-size: 1.6rem;
}

@media (max-width: 490px) {
  #bma-page section.judges .judge-text .judge-title {
    font-weight: 500;
    font-size: 1.3rem;
    line-height: 1.2;
  }
}

#bma-page section.judges .judge-text .judge-role {
  font-size: 1.2rem;
  line-height: 1.4;
}

@media (max-width: 490px) {
  #bma-page section.judges .judge-text .judge-role {
    font-size: 1.1rem;
    line-height: 1.3;
  }
}

#bma-page section.judges img {
  width: 100%;
  max-width: 300px;
  height: auto;
}

#bma-page section.judges .judge-footnote {
  text-align: center;
  font-size: 1.4rem;
  margin: 20px auto;
  width: 95%;
}

@media (min-width: 768px) {
  #bma-page section.judges .judge-footnote {
    width: 70%;
  }
}

#bma-page section.news .news-card .single-card {
  background: #fff;
  margin: 10px;
  padding: 0px;
}

#bma-page section.news .news-card .single-card img {
  width: 100%;
  height: 151px;
  object-fit: cover;
  margin-bottom: 0;
}

@media (max-width: 768px) {
  #bma-page section.news .news-card .single-card img {
    height: 100px;
  }
}

#bma-page section.news .news-card .single-card .news-text {
  padding: 10px 10px 1px;
  min-height: 104px;
}

#bma-page section.news .news-card .single-card .news-text .news-title {
  font-size: 1.3rem;
  line-height: 1.3;
  color: #414141;
  font-weight: 500;
  margin-bottom: 12px;
  overflow: hidden;
  position: relative;
  max-height: 52px;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
}

#bma-page section.news .news-card .single-card .news-text .news-title a {
  color: inherit;
}

@media (max-width: 576px) {
  #bma-page section.news .news-card .single-card .news-text .news-title {
    font-size: 1.1rem;
  }
}

#bma-page section.news .news-card .single-card .news-text p.news-label {
  font-size: 10px;
  text-transform: uppercase;
  line-height: 16px;
  color: #488BF8;
  font-weight: bold;
  margin-bottom: 5px;
}

#bma-page section.news .news-card .single-card .news-text p.news-date {
  font-size: 10px;
  line-height: 12px;
  color: #393939;
}

#bma-page section.news .news-card h1 {
  padding: 9px 0;
  font-size: 0.9rem !important;
}

#bma-page section.news .news-card .more-property-news {
  font-size: 1.5rem;
  margin-top: 10px;
  color: #fff;
  text-align: center;
  /*padding-bottom: 10px;*/
}

#bma-page section.about h2 {
  font-weight: 500;
  border: 1px solid #fdb913;
  min-height: 146px;
  text-align: center;
  padding: 15px;
  font-size: 1.4rem;
}

#bma-page section.about .article span.more-detail {
  font-family: 'Poppins';
  font-size: 1.2rem;
  font-weight: 400;
  text-transform: none;
  display: block;
  margin-top: 15px;
}

#bma-page section.winners h1, #bma-page section.featured h1 {
  color: #fdb913;
}

@media (min-width: 1280px) {
  #bma-page section.winners h1, #bma-page section.featured h1 {
    font-size: 4rem;
    letter-spacing: 2px;
  }
}

#bma-page section.winners img, #bma-page section.featured img {
  max-width: 300px;
  margin-bottom: 30px;
}

#bma-page section.winners img.stpd, #bma-page section.featured img.stpd {
  max-width: 301px;
}

#bma-page section.winners, #bma-page section.news, #bma-page section.videos, #bma-page section.judges {
  background: #03001c;
}

#bma-page section.winners img.cursor-pointer, #bma-page section.winners .accordion-wrap .accordion-js-panel img.accordion__header, .accordion-wrap .accordion-js-panel #bma-page section.winners img.accordion__header {
  cursor: pointer;
}

@media (max-width: 576px) {
  #bma-page section.news .col-6, #bma-page section.videos .col-6, #bma-page section.judges .col-6 {
    -webkit-box-flex: unset;
    flex: none;
    float: left;
    width: 48%;
    margin: 0 auto;
    max-width: 200px;
    position: unset;
  }
}

#bma-page section img.g-img {
  cursor: pointer;
  margin-bottom: 30px;
  width: 100%;
  max-width: 280px;
  height: 170px;
  object-fit: cover;
}

@media (max-width: 1440px) {
  #bma-page section img.g-img {
    height: 160px;
  }
}

@media (max-width: 1366px) {
  #bma-page section img.g-img {
    height: 150px;
  }
}

@media (max-width: 1280px) {
  #bma-page section img.g-img {
    height: 140px;
  }
}

@media (max-width: 1024px) {
  #bma-page section img.g-img {
    height: 120px;
  }
}

@media (max-width: 768px) {
  #bma-page section img.g-img {
    height: 86px;
  }
}

@media (max-width: 576px) {
  #bma-page section img.g-img {
    height: 100px;
    width: 100%;
  }
}

@media (max-width: 375px) {
  #bma-page section img.g-img {
    height: 86px;
  }
}

@media (max-width: 320px) {
  #bma-page section img.g-img {
    height: 72px;
  }
}

@media (min-width: 991px) and (max-width: 1024px) {
  #bma-page section .badge-tablet img {
    max-width: 242px;
  }
  #bma-page section .badge-tablet img.stpd {
    max-width: 242px;
  }
}

@media (min-width: 768px) and (max-width: 768px) {
  #bma-page section .badge-tablet img {
    max-width: 188px;
  }
  #bma-page section .badge-tablet img.stpd {
    max-width: 188px;
  }
}

#bma-page .m-b-20 {
  margin-bottom: 20px;
}

#bma-page .no-float {
  float: none;
}

#bma-page footer a:hover {
  text-decoration: underline;
  color: inherit;
}

#bma-page footer .footer-logo {
  padding: 0 0 20px;
}

#bma-page footer .footer-logo .logo-col-sm {
  padding: 10px 10px 10px 0;
}

@media (min-width: 768px) {
  #bma-page footer .footer-logo .logo-col-sm {
    float: left;
    width: 14.2%;
  }
}

@media (max-width: 767px) {
  #bma-page footer .footer-logo .logo-col-sm {
    float: left;
    width: 25%;
  }
}

#bma-page footer .footer-logo img {
  width: 100%;
  position: relative;
}

#bma-page footer .footer-logo img.present {
  max-width: 250px;
}

@media (min-width: 768px) {
  #bma-page footer .footer-logo img.present {
    top: -8px;
  }
}

#bma-page footer .footer-logo img.partner {
  max-width: 130px;
}

#bma-page footer .footer-logo img.partner-2 {
  top: 12px;
}

@media (min-width: 768px) and (max-width: 768px) {
  #bma-page footer .footer-logo img.partner-2 {
    top: 12px;
  }
}

#bma-page footer .footer-logo img.support {
  max-width: 60px;
}

#bma-page footer .footer-logo img.support-5 {
  top: -17px;
}

#bma-page footer .footer-logo img.support-6 {
  top: 7px;
}

#bma-page footer .footer-logo img.support-7 {
  top: -11px;
}

@media (max-width: 767px) {
  #bma-page footer .footer-logo img {
    text-align: center;
    margin-bottom: 30px;
  }
}

#bma-page footer .footer-logo .footer-label {
  display: block;
  text-transform: uppercase;
  font-size: 1.1rem;
  font-weight: 500;
  letter-spacing: .8px;
  text-align: left;
  color: #111;
  padding: 15px 10px 0 0;
  margin-bottom: 36px;
}

@media (min-width: 768px) {
  #bma-page footer .footer-logo .footer-label {
    border-right: 2px solid #111;
  }
  #bma-page footer .footer-logo .footer-label.lb-present {
    width: 110px;
  }
  #bma-page footer .footer-logo .footer-label.lb-partner {
    width: 82px;
  }
  #bma-page footer .footer-logo .footer-label.lb-sp-sponsor {
    width: 167px;
  }
  #bma-page footer .footer-logo .footer-label.lb-support {
    width: 111px;
  }
}

@media (max-width: 767px) {
  #bma-page footer .footer-logo .footer-label {
    font-weight: 600;
    font-size: 1.2rem;
    margin-bottom: 0;
    text-align: center;
  }
}

@media (max-width: 990px) {
  #bma-page footer .footer-logo .footer-label.sm-margin-top {
    margin-top: 30px;
  }
}

#bma-page #stickyNav {
  background-color: #333;
  border-color: rgba(34, 34, 34, 0.05);
  background-color: #000;
  -webkit-transition: all .3s;
  -moz-transition: all .3s;
  transition: all .3s;
  font-weight: 200;
  letter-spacing: 1px;
  border-radius: 0;
  margin-bottom: 0;
}

#bma-page #stickyNav.stick {
  margin-top: 0 !important;
  width: 100%;
  position: fixed;
  top: 0;
  z-index: 998;
}

#bma-page #stickyNav .navbar {
  border-radius: 0 !important;
}

#bma-page #stickyNav .navbar-brand {
  color: #fdb913;
  font-weight: 200;
  letter-spacing: 1px;
}

#bma-page #stickyNav .navbar-brand:focus, #bma-page #stickyNav .navbar-brand:hover {
  color: #b96700;
}

#bma-page #stickyNav .navbar-toggler {
  font-size: 12px;
  padding: 8px 10px;
  color: #fff;
  position: absolute;
  right: 10px;
  top: 10px;
}

#bma-page #stickyNav .navbar-nav > li > a {
  font-weight: 500;
  text-transform: uppercase;
  margin-right: 20px;
  color: #fff;
}

@media (min-width: 1024px) {
  #bma-page #stickyNav .navbar-nav > li > a {
    font-size: 1.4rem;
    margin-right: 5px;
  }
}

@media (max-width: 767px) {
  #bma-page #stickyNav .navbar-nav > li > a {
    padding: 6px 0;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  #bma-page #stickyNav .navbar-nav > li > a {
    font-size: 1.1rem;
    line-height: 1;
    margin-right: 0;
  }
}

#bma-page #stickyNav .navbar-nav > li > a.active {
  color: #fdb913 !important;
  background-color: transparent;
}

#bma-page #stickyNav .navbar-nav > li > a.active:hover {
  background-color: transparent;
}

#bma-page #stickyNav .navbar-nav > li > a:focus {
  color: #fff;
}

#bma-page #stickyNav .navbar-nav > li > a:focus:hover {
  color: #fdb913;
}

#bma-page #stickyNav .navbar-nav > li > a:hover {
  color: #fdb913;
}

@media (min-width: 768px) and (max-width: 768px) {
  #bma-page #stickyNav .navbar-nav li:nth-child(4) {
    width: 70px;
  }
}

#bma-page .nav-pills .nav-link.active {
  border: 1px solid #fdb913;
  color: #fdb913;
  background-color: transparent;
}

@media (min-width: 992px) {
  #bma-page .navbar-expand-lg .navbar-nav .nav-link {
    padding-right: 2rem;
  }
}

#bma-page ul.list-unstyled li {
  margin-bottom: 20px;
  font-size: 13px;
  color: #666;
}

#bma-page .ml-auto, #bma-page .mx-auto {
  margin-left: auto !important;
}

#bma-page .mr-auto, #bma-page .mx-auto {
  margin-right: auto !important;
}

#bma-page .fotorama__wrap {
  margin: 0 auto;
}

#bma-page .fotorama__caption {
  font-size: 2rem;
  width: 100%;
  margin: 0 auto;
  text-align: center;
}

@media (min-width: 768px) {
  #bma-page .fotorama__caption {
    bottom: 0;
  }
}

@media (max-width: 767px) {
  #bma-page .fotorama__caption {
    font-size: 2rem;
    bottom: 0px;
    width: 100%;
  }
}

#bma-page .fotorama__caption a {
  text-decoration: none;
  border-bottom: 0;
}

#bma-page .fotorama__caption span {
  display: block;
}

#bma-page .fotorama__caption span.slider-title {
  font-weight: bold;
  line-height: 1;
  margin-bottom: 6px;
}

@media (max-width: 767px) {
  #bma-page .fotorama__caption span.slider-title {
    font-size: 1.7rem;
  }
}

#bma-page .fotorama__caption span.slider-subtitle {
  font-size: 1.6rem;
  line-height: 1.5;
}

@media (max-width: 767px) {
  #bma-page .fotorama__caption span.slider-subtitle {
    font-size: 1.2rem;
  }
}

#bma-page .fotorama__caption span.slider-subtitle span.glyphicon {
  display: inline;
  font-size: 1.1rem;
}

@media (min-width: 769px) {
  #bma-page .fotorama__caption .fotorama__caption__wrap {
    padding: 5px !important;
    width: 100%;
  }
}

#bma-page .fotorama__arr {
  background: 0 0 !important;
}

#bma-page .fotorama__arr:before {
  font-family: Ionicons;
  content: "";
  margin-right: 6px;
  font-size: xx-large;
  font-size: 58px;
  content: "";
  margin-right: 0;
  color: #fdc436;
  position: relative;
  top: -30px;
}

#bma-page .fotorama__arr:before:hover {
  color: #fdb913;
}

#bma-page .fotorama__arr.fotorama__arr--next:before {
  content: "";
}

#bma-page .fotorama__arr.fotorama__arr--prev:before {
  left: 8px;
  content: "";
}

#bma-page .fotorama .fotorama__html {
  background-color: rgba #000, 0.6;
}

#bma-page .fotorama .fotorama__html span {
  display: block;
}

#bma-page .fotorama .caption-element {
  top: 0;
  position: absolute;
  z-index: 999;
  right: 0;
  background: rgba(255, 255, 255, 0.9);
  width: 50%;
  height: 100vh;
}

#bma-page .fotorama .caption-element span {
  display: block;
}

#bma-page .fotorama .caption-element span.slider-title {
  font-weight: bold;
  line-height: 1;
  margin-bottom: 6px;
}

#bma-page .fotorama .caption-element span.slider-subtitle {
  font-size: 2rem;
  line-height: 1.5;
}

@media (max-width: 767px) {
  #bma-page .fotorama .caption-element span.slider-subtitle {
    font-size: 1.2rem;
  }
}

#bma-page span {
  display: block;
}

#bma-page span.caption-title {
  font-size: 1.2rem;
  font-weight: 100;
}

@media (max-width: 767px) {
  #bma-page .logotype {
    margin: 0 auto;
  }
}

@media (max-width: 768px) and (min-width: 768px) {
  #bma-page .logotype img {
    width: 132px;
  }
}

@media (max-width: 576px) {
  #bma-page .logotype img {
    width: 195px;
  }
}

@media (max-width: 425px) {
  #bma-page .logotype img {
    width: 130px;
  }
}

@media (max-width: 767px) {
  #bma-page .border-side {
    border-right: 0;
    border-left: 0;
  }
}

@media (min-width: 992px) {
  #bma-page #stickyNav .navbar-brand {
    color: fade(white, 70%);
  }
  #bma-page #stickyNav .navbar-brand:focus, #bma-page #stickyNav .navbar-brand:hover {
    color: #fff;
  }
  #bma-page #stickyNav .navbar-nav > li > a {
    color: rgba(255, 255, 255, 0.7);
  }
  #bma-page #stickyNav .navbar-nav > li > a:focus {
    color: rgba(255, 255, 255, 0.7);
  }
  #bma-page #stickyNav .navbar-nav > li > a:focus:hover {
    color: #fff;
  }
  #bma-page #stickyNav .navbar-nav > li > a:hover {
    color: #fff;
  }
  #bma-page #stickyNav.navbar-shrink {
    border-color: rgba(34, 34, 34, 0.1);
    z-index: 1000;
    background-color: #000;
    border-radius: 0;
  }
  #bma-page #stickyNav.navbar-shrink .navbar-brand {
    color: #f9f9f9;
  }
  #bma-page #stickyNav.navbar-shrink .navbar-brand:focus, #bma-page #stickyNav.navbar-shrink .navbar-brand:hover {
    color: #fdb913;
  }
  #bma-page #stickyNav.navbar-shrink .navbar-nav > li > a {
    color: #f9f9f9;
  }
  #bma-page #stickyNav.navbar-shrink .navbar-nav > li > a:focus {
    color: #f9f9f9;
  }
  #bma-page #stickyNav.navbar-shrink .navbar-nav > li > a:focus:hover {
    color: #fdb913;
  }
  #bma-page #stickyNav.navbar-shrink .navbar-nav > li > a:hover {
    color: #fdb913;
  }
}

@media (max-width: 425px) {
  #bma-page section.general {
    padding: 10px 0 50px;
  }
  #bma-page section.general h2 {
    font-size: 28px;
  }
  #bma-page section.general .overlay-header {
    font-size: 33px;
    padding: 6px;
  }
  #bma-page section.general header {
    height: 61px;
  }
  #bma-page section.general header.features-header {
    height: 60px;
  }
  #bma-page section.issue {
    padding: 0;
  }
  #bma-page .visible-xs {
    display: block;
  }
}

@media (min-width: 426px) {
  #bma-page .visible-xs {
    display: none !important;
  }
}

@media (max-width: 490px) {
  #bma-page .navbar-collapse {
    background: #000;
    z-index: 999999;
  }
}

@media (max-width: 991px) {
  #bma-page .navbar-nav {
    padding-left: 17px;
  }
}

@media (min-width: 768px) and (max-width: 768px) {
  #bma-page .navbar-nav {
    padding-left: 0px;
  }
}

#bma-page .col-6 {
  float: left;
}

#bma-page .floating-bar {
  position: fixed;
  top: -200%;
  left: 1%;
  color: #000;
  width: 100px;
  background: #fff;
  transition: all .5s .2s ease-in-out;
  font-weight: 600;
  z-index: 999;
}

@media (min-width: 769px) {
  #bma-page .floating-bar {
    border-right: 1px solid #000;
    border-left: 1px solid #000;
  }
}

@media (max-width: 768px) {
  #bma-page .floating-bar {
    width: 100%;
    left: 0;
    border-bottom: 1px solid #000;
  }
}

#bma-page .floating-bar .floating-menu {
  line-height: 1.4;
  text-align: center;
  padding: 8px 12px;
  border-bottom: 1px dotted #000;
  font-size: 1.2rem;
}

#bma-page .floating-bar .floating-menu:hover, #bma-page .floating-bar .floating-menu.active {
  background: #fdb913;
  color: #fff;
}

@media (max-width: 768px) {
  #bma-page .floating-bar .floating-menu {
    padding: 5px;
    font-size: 9px;
    width: 16.6%;
    float: left;
    border-right: 1px dotted #000;
    border-bottom: none;
  }
}

@media (max-width: 320px) {
  #bma-page .floating-bar .floating-menu {
    font-size: 7px;
  }
}

#bma-page .floating-bar.in {
  transition: all .5s .2s ease-in-out;
}

@media (min-width: 991px) {
  #bma-page .floating-bar.in {
    top: 72px;
  }
}

@media (max-width: 768px) {
  #bma-page .floating-bar.in {
    top: 51px;
  }
}

@media (max-width: 576px) {
  #bma-page .floating-bar.in {
    top: 64px;
  }
}

@media (max-width: 425px) {
  #bma-page .floating-bar.in {
    top: 50px;
  }
}

#bma-page .floating-bar a {
  color: #333;
  text-decoration: none !important;
}

#bma-page .badge-popup {
  cursor: pointer;
  position: fixed;
  z-index: 999;
  color: #333;
  border: 1px solid #000;
  box-shadow: 1px 7px 30px 2px #6f6d6d;
  display: block;
  background: rgba(255, 255, 255, 0.98);
  text-align: center;
  overflow-x: hidden;
  overflow-y: auto;
  top: 28%;
  height: 300px;
  padding: 25px;
  right: calc((100% - 700px) / 2);
  width: 700px;
}

@media (max-width: 767px) {
  #bma-page .badge-popup {
    right: calc((100% - 320px) / 2);
    width: 320px;
  }
}

#bma-page .badge-popup p {
  font-size: 13px;
}

#bma-page .badge-popup.active {
  -webkit-overflow-scrolling: touch;
  opacity: 1;
  -webkit-transition: opacity 0.15s linear, z-index 0.15;
  -o-transition: opacity 0.15s linear, z-index 0.15;
  transition: opacity 0.15s linear, z-index 0.15;
}

#bma-page .badge-popup .btn-close {
  cursor: pointer;
  position: fixed;
  color: #fff;
  background: #ff4b22;
  border-radius: 50%;
  width: 35px;
  height: 35px;
  z-index: 999999999;
  margin-top: -20px;
  right: calc((100% - 700px) / 2);
  top: 24%;
  opacity: 1;
  text-shadow: none;
  font-size: 4rem;
  font-weight: 100;
  line-height: .9;
}

@media (max-width: 767px) {
  #bma-page .badge-popup .btn-close {
    right: calc((100% - 320px) / 2);
  }
}

#bma-page .badge-popup .btn-close:focus {
  box-shadow: none !important;
}

#bma-page .badge-popup .btn-close:not(:disabled):not(.disabled):active {
  background: none;
  border: 0;
}

.vjs-big-play-button {
  display: none !important;
}

.lg-sub-html {
  font-size: 1.5rem !important;
}

@media (max-width: 768px) {
  .lg-sub-html {
    font-size: 1rem !important;
  }
}

@media (max-width: 576px) {
  .lg-sub-html {
    padding: 5px 50px 5px 5px !important;
    text-align: left !important;
    font-size: .9rem !important;
  }
}

@media (max-width: 576px) {
  .lg-outer .lg-toogle-thumb {
    right: 5px !important;
  }
}

#bma-page.2020 section.judges {
  background: #03001c;
}

#bma-page.two-one a.view-listing {
  font-size: 13px;
  position: relative;
  top: -22px;
  margin-bottom: 7px;
  color: #fff;
  display: block;
}

@media (max-width: 1024px) {
  #bma-page.two-one a.view-listing {
    font-size: 12px;
  }
}

#bma-page.two-one a.view-listing:hover {
  color: #fdb913;
}

#bma-page.two-one h1 {
  color: #f7b30f !important;
  font-size: 27px !important;
  font-weight: 600 !important;
  font-family: 'georgia';
  letter-spacing: 2px;
  margin: 0 0 30px 0;
  padding: 0;
}

@media (max-width: 768px) and (min-width: 768px) {
  #bma-page.two-one h1 {
    text-align: center;
  }
}

@media (max-width: 576px) {
  #bma-page.two-one h1 {
    font-size: 25px !important;
    margin: 0 0 20px 0;
  }
}

#bma-page.two-one section .badge-tablet {
  padding: 0;
}

@media (min-width: 1366px) {
  #bma-page.two-one section .col-xl-11 {
    width: 1124px;
  }
}

#bma-page.two-one section.home {
  background-image: url(https://media.edgeprop.my/page/bmpa/2021/assets/2021-hero-2.jpg);
}

#bma-page.two-one section.winners {
  background: #04133b;
}

#bma-page.two-one section.winners h3 {
  margin-top: 35px;
  font-weight: 500;
  font-size: 27px;
  font-family: georgia;
  padding: 7px 0;
  margin-bottom: 5px;
  border-top: 0;
  color: #fff;
  text-shadow: 1px 1px #0f0e0e;
}

@media (min-width: 1024px) {
  #bma-page.two-one section.winners h3 {
    margin-top: 80px;
  }
}

@media (max-width: 768px) and (min-width: 768px) {
  #bma-page.two-one section.winners h3 {
    text-align: center;
  }
}

@media (max-width: 576px) {
  #bma-page.two-one section.winners h3 {
    font-size: 16px;
  }
}

#bma-page.two-one section.winners h4 {
  padding-bottom: 0;
}

#bma-page.two-one section.featured {
  background: #40407d;
}

#bma-page.two-one section.featured img {
  max-width: 100%;
}

#bma-page.two-one section.gallery {
  background: #04133b;
}

#bma-page.two-one section.videos {
  background: #f7b30f;
}

#bma-page.two-one section.videos h1 {
  color: #04133b !important;
}

#bma-page.two-one section.judges {
  background: #40407d;
}

#bma-page.two-one section.judges .judge-box {
  margin-bottom: 30px;
}

#bma-page.two-one section.judges .judge-box-empty-container {
  background: #fff;
  overflow: auto;
}

@media (min-width: 1024px) and (max-width: 1024px) {
  #bma-page.two-one section.judges .judge-box-empty-container {
    min-height: 250px;
  }
}

@media (max-width: 767px) {
  #bma-page.two-one section.judges .judge-box-empty-container {
    min-height: 365px;
  }
}

@media (max-width: 576px) {
  #bma-page.two-one section.judges .judge-box-empty-container {
    min-height: 310px;
  }
}

#bma-page.two-one section.judges .judge-box .judge-text {
  color: #333;
  margin-bottom: 0;
}

#bma-page.two-one section.judges .judge-box .judge-text .judge-title {
  color: #333;
  font-size: 15px;
  margin: 10px 0;
  font-weight: 700;
  line-height: 1.1;
}

@media (max-width: 768px) {
  #bma-page.two-one section.judges .judge-box .judge-text .judge-title {
    margin: 10px 0 5px;
  }
}

@media (max-width: 480px) {
  #bma-page.two-one section.judges .judge-box .judge-text .judge-title {
    font-size: 14px;
  }
}

#bma-page.two-one section.judges .judge-box .judge-text .judge-role {
  font-weight: 400;
  font-size: 12px;
  color: #444;
}

@media (max-width: 1024px) {
  #bma-page.two-one section.judges .judge-box .judge-text .judge-role {
    font-size: 11px;
  }
}

@media (max-width: 767px) {
  #bma-page.two-one section.judges .judge-box .judge-text {
    padding: 5px;
  }
}

#bma-page.two-one section.judges .judge-box img {
  height: 170px;
  width: 100%;
  object-fit: cover;
}

@media (min-width: 1025px) {
  #bma-page.two-one section.judges .judge-box img {
    height: 200px;
  }
}

@media (min-width: 1024px) and (max-width: 1024px) {
  #bma-page.two-one section.judges .judge-box img {
    min-height: 250px;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  #bma-page.two-one section.judges .judge-box img {
    height: 202px;
  }
}

@media (max-width: 375px) {
  #bma-page.two-one section.judges .judge-box img {
    height: 130px;
  }
}

@media (max-width: 991px) and (min-width: 768px) {
  #bma-page.two-one section.judges .judge-box .col-md-4 {
    flex: 0 0 50%;
    max-width: 50%;
  }
}

@media (max-width: 768px) {
  #bma-page.two-one section.judges .judge-box.last-judge {
    position: relative;
    left: 25%;
  }
}

#bma-page.two-one section.news {
  background: #04133b;
}

#bma-page.two-one section.news p.news-date {
  margin-bottom: 10px;
}

#bma-page.two-one section.news .single-card {
  margin: 0 0 30px 0;
}

#bma-page.two-one section.about h2 {
  border: 1px solid #fff;
  background: #fdb913;
  color: #333;
}

@media (min-width: 768px) {
  #bma-page.two-one section.about #ip-container .inlinepopup {
    margin: 0 auto 15px;
    width: 97.4%;
  }
  #bma-page.two-one section.about #ip-container .inlinepopup_arrow {
    margin-left: -25px;
  }
}

#bma-page.two-one section.winners img, #bma-page.two-one section.featured img {
  max-width: 515px;
}

@media (max-width: 768px) and (min-width: 768px) {
  #bma-page.two-one section.winners img, #bma-page.two-one section.featured img {
    max-width: 322px;
  }
}

#bma-page.two-one section.news .col-6, #bma-page.two-one section.videos .col-6, #bma-page.two-one section.judges .col-6 {
  width: 50%;
}

@media (max-width: 768px) {
  #bma-page.two-one section.news .col-6, #bma-page.two-one section.videos .col-6, #bma-page.two-one section.judges .col-6 {
    max-width: 100%;
  }
}

@media (max-width: 768px) {
  #bma-page.two-one section.news .judge-box,
  #bma-page.two-one section.news .news-card .single-card, #bma-page.two-one section.videos .judge-box,
  #bma-page.two-one section.videos .news-card .single-card, #bma-page.two-one section.judges .judge-box,
  #bma-page.two-one section.judges .news-card .single-card {
    margin-bottom: 20px;
  }
}

@media (max-width: 767px) {
  #bma-page.two-one section img.g-img {
    margin-bottom: 20px;
  }
}

#bma-page.two-one section h3 {
  text-align: center;
}

@media (min-width: 1024px) {
  #bma-page.two-one section h3 {
    margin-top: 80px;
    margin-bottom: 5px;
  }
}

@media (max-width: 768px) {
  #bma-page.two-one [class*='col-'] {
    padding-right: 10px;
    padding-left: 10px;
  }
}

@media (max-width: 1024px) and (min-width: 1024px) {
  #bma-page.two-one .col-md-11 {
    width: 83%;
  }
  #bma-page.two-one .col-md-11 .text-center img {
    max-width: 380px;
  }
}

@media (max-width: 991px) and (min-width: 768px) {
  #bma-page.two-one .col-md-6 {
    float: left;
  }
}

#bma-page.two-one .col-md-6.text-center .badge-more,
#bma-page.two-one .col-md-12.text-center .badge-more {
  bottom: 28%;
}

@media (max-width: 1024px) and (min-width: 1024px) {
  #bma-page.two-one .col-md-6.text-center .badge-more,
  #bma-page.two-one .col-md-12.text-center .badge-more {
    bottom: 66px;
  }
}

@media (max-width: 1024px) {
  #bma-page.two-one .col-md-6.text-center .badge-more,
  #bma-page.two-one .col-md-12.text-center .badge-more {
    width: 40px;
    height: 40px;
    font-size: 8px;
  }
}

@media (max-width: 320px) {
  #bma-page.two-one .col-md-6.text-center .badge-more,
  #bma-page.two-one .col-md-12.text-center .badge-more {
    zoom: 0.9;
    bottom: 30%;
  }
}

#bma-page.two-one .col-md-6.text-center.has-listing .badge-more,
#bma-page.two-one .col-md-12.text-center.has-listing .badge-more {
  bottom: 33.2%;
}

#bma-page.two-one .col-md-6.text-center .badge-more {
  right: 4%;
}

#bma-page.two-one .col-md-12.text-center .badge-more {
  right: 27%;
}

@media (max-width: 768px) and (min-width: 768px) {
  #bma-page.two-one .col-md-12.text-center .badge-more {
    bottom: 61px;
  }
}

@media (max-width: 767px) {
  #bma-page.two-one .col-md-12.text-center .badge-more {
    right: 4%;
  }
}

@media (min-width: 768px) {
  .page-content-1723634 .page-projectreview section.page-hero .news-hero-bg,
  .page-content-1723633 .page-projectreview section.page-hero .news-hero-bg,
  .page-content-1727601 .page-projectreview section.page-hero .news-hero-bg,
  .page-content-1727597 .page-projectreview section.page-hero .news-hero-bg {
    background-position: bottom right;
  }
}

@media (min-width: 768px) {
  .page-content-1901179 .page-projectreview section.page-hero .news-hero-bg {
    height: 725px;
  }
}

@media (max-width: 767px) {
  .page-content-1901179 .page-projectreview section.page-hero .news-hero-bg {
    background-image: url("https://media.edgeprop.my/page/project-review/m_homevest.jpg") !important;
  }
}

@media (min-width: 768px) {
  .page-content-1723635 .page-projectreview section.page-hero .news-hero-bg {
    background-position: center;
  }
}

@media (max-width: 767px) {
  .page-content-1723635 .page-projectreview section.page-hero .news-hero-bg {
    background-position-y: -58px;
  }
}

@media (max-width: 767px) {
  .page-content-1849545 .page-projectreview section.page-hero .news-hero-bg {
    background-image: url("https://media.edgeprop.my/page/project-review/2021-MAY31-ecocity.jpg") !important;
  }
}

@media (min-width: 768px) {
  .page-content-1727597 .page-projectreview section.page-hero .news-hero-bg {
    background-position: center;
  }
}

@media (max-width: 767px) {
  .page-content-1727597 .page-projectreview section.page-hero .news-hero-bg {
    background-position: 45%;
  }
}

@media (max-width: 767px) {
  .page-content-1727597 .page-projectreview section.page-hero .news-hero-main-component .news-hero-text {
    position: relative;
    bottom: -33px;
  }
}

.page-content-1900642 .news-hero-text h1 {
  opacity: 0;
}

.page-content-1902525 .page-projectreview footer,
.page-content-1902525 .page-projectreview #mainNav.navbar,
.page-content-1902525 .page-projectreview .news-arrow-down .circle-color,
.page-content-1902525 .page-projectreview section.page-inquiry .inquiry-button,
.page-content-1902525 .page-projectreview .fixed-bar .fixed-buttons .btn.btn-enquire, .page-content-1901490 .page-projectreview footer,
.page-content-1901490 .page-projectreview #mainNav.navbar,
.page-content-1901490 .page-projectreview .news-arrow-down .circle-color,
.page-content-1901490 .page-projectreview section.page-inquiry .inquiry-button,
.page-content-1901490 .page-projectreview .fixed-bar .fixed-buttons .btn.btn-enquire, .page-content-1901489 .page-projectreview footer,
.page-content-1901489 .page-projectreview #mainNav.navbar,
.page-content-1901489 .page-projectreview .news-arrow-down .circle-color,
.page-content-1901489 .page-projectreview section.page-inquiry .inquiry-button,
.page-content-1901489 .page-projectreview .fixed-bar .fixed-buttons .btn.btn-enquire, .page-content-1902125 .page-projectreview footer,
.page-content-1902125 .page-projectreview #mainNav.navbar,
.page-content-1902125 .page-projectreview .news-arrow-down .circle-color,
.page-content-1902125 .page-projectreview section.page-inquiry .inquiry-button,
.page-content-1902125 .page-projectreview .fixed-bar .fixed-buttons .btn.btn-enquire, .page-content-1901487 .page-projectreview footer,
.page-content-1901487 .page-projectreview #mainNav.navbar,
.page-content-1901487 .page-projectreview .news-arrow-down .circle-color,
.page-content-1901487 .page-projectreview section.page-inquiry .inquiry-button,
.page-content-1901487 .page-projectreview .fixed-bar .fixed-buttons .btn.btn-enquire, .page-content-1901486 .page-projectreview footer,
.page-content-1901486 .page-projectreview #mainNav.navbar,
.page-content-1901486 .page-projectreview .news-arrow-down .circle-color,
.page-content-1901486 .page-projectreview section.page-inquiry .inquiry-button,
.page-content-1901486 .page-projectreview .fixed-bar .fixed-buttons .btn.btn-enquire, .page-content-1901938 .page-projectreview footer,
.page-content-1901938 .page-projectreview #mainNav.navbar,
.page-content-1901938 .page-projectreview .news-arrow-down .circle-color,
.page-content-1901938 .page-projectreview section.page-inquiry .inquiry-button,
.page-content-1901938 .page-projectreview .fixed-bar .fixed-buttons .btn.btn-enquire, .page-content-1901945 .page-projectreview footer,
.page-content-1901945 .page-projectreview #mainNav.navbar,
.page-content-1901945 .page-projectreview .news-arrow-down .circle-color,
.page-content-1901945 .page-projectreview section.page-inquiry .inquiry-button,
.page-content-1901945 .page-projectreview .fixed-bar .fixed-buttons .btn.btn-enquire, .page-content-1830267 .page-projectreview footer,
.page-content-1830267 .page-projectreview #mainNav.navbar,
.page-content-1830267 .page-projectreview .news-arrow-down .circle-color,
.page-content-1830267 .page-projectreview section.page-inquiry .inquiry-button,
.page-content-1830267 .page-projectreview .fixed-bar .fixed-buttons .btn.btn-enquire {
  background-color: #292F7E;
}

.page-content-1902525 .page-projectreview section.page-inquiry .inquiry-button,
.page-content-1902525 .page-projectreview .fixed-bar .fixed-buttons .btn.btn-enquire, .page-content-1901490 .page-projectreview section.page-inquiry .inquiry-button,
.page-content-1901490 .page-projectreview .fixed-bar .fixed-buttons .btn.btn-enquire, .page-content-1901489 .page-projectreview section.page-inquiry .inquiry-button,
.page-content-1901489 .page-projectreview .fixed-bar .fixed-buttons .btn.btn-enquire, .page-content-1902125 .page-projectreview section.page-inquiry .inquiry-button,
.page-content-1902125 .page-projectreview .fixed-bar .fixed-buttons .btn.btn-enquire, .page-content-1901487 .page-projectreview section.page-inquiry .inquiry-button,
.page-content-1901487 .page-projectreview .fixed-bar .fixed-buttons .btn.btn-enquire, .page-content-1901486 .page-projectreview section.page-inquiry .inquiry-button,
.page-content-1901486 .page-projectreview .fixed-bar .fixed-buttons .btn.btn-enquire, .page-content-1901938 .page-projectreview section.page-inquiry .inquiry-button,
.page-content-1901938 .page-projectreview .fixed-bar .fixed-buttons .btn.btn-enquire, .page-content-1901945 .page-projectreview section.page-inquiry .inquiry-button,
.page-content-1901945 .page-projectreview .fixed-bar .fixed-buttons .btn.btn-enquire, .page-content-1830267 .page-projectreview section.page-inquiry .inquiry-button,
.page-content-1830267 .page-projectreview .fixed-bar .fixed-buttons .btn.btn-enquire {
  border-color: #292F7E;
}

.page-content-1902525 .page-projectreview section.page-inquiry .inquiry-button:hover,
.page-content-1902525 .page-projectreview .fixed-bar .fixed-buttons .btn.btn-enquire:hover, .page-content-1901490 .page-projectreview section.page-inquiry .inquiry-button:hover,
.page-content-1901490 .page-projectreview .fixed-bar .fixed-buttons .btn.btn-enquire:hover, .page-content-1901489 .page-projectreview section.page-inquiry .inquiry-button:hover,
.page-content-1901489 .page-projectreview .fixed-bar .fixed-buttons .btn.btn-enquire:hover, .page-content-1902125 .page-projectreview section.page-inquiry .inquiry-button:hover,
.page-content-1902125 .page-projectreview .fixed-bar .fixed-buttons .btn.btn-enquire:hover, .page-content-1901487 .page-projectreview section.page-inquiry .inquiry-button:hover,
.page-content-1901487 .page-projectreview .fixed-bar .fixed-buttons .btn.btn-enquire:hover, .page-content-1901486 .page-projectreview section.page-inquiry .inquiry-button:hover,
.page-content-1901486 .page-projectreview .fixed-bar .fixed-buttons .btn.btn-enquire:hover, .page-content-1901938 .page-projectreview section.page-inquiry .inquiry-button:hover,
.page-content-1901938 .page-projectreview .fixed-bar .fixed-buttons .btn.btn-enquire:hover, .page-content-1901945 .page-projectreview section.page-inquiry .inquiry-button:hover,
.page-content-1901945 .page-projectreview .fixed-bar .fixed-buttons .btn.btn-enquire:hover, .page-content-1830267 .page-projectreview section.page-inquiry .inquiry-button:hover,
.page-content-1830267 .page-projectreview .fixed-bar .fixed-buttons .btn.btn-enquire:hover {
  background-color: #292F7E;
  opacity: 0.9;
  border-color: #292f7e;
}

.page-content-1902525 .page-projectreview footer, .page-content-1901490 .page-projectreview footer, .page-content-1901489 .page-projectreview footer, .page-content-1902125 .page-projectreview footer, .page-content-1901487 .page-projectreview footer, .page-content-1901486 .page-projectreview footer, .page-content-1901938 .page-projectreview footer, .page-content-1901945 .page-projectreview footer, .page-content-1830267 .page-projectreview footer {
  color: #fff;
}

.page-content-1902525 .page-projectreview footer a, .page-content-1902525 .page-projectreview footer p, .page-content-1901490 .page-projectreview footer a, .page-content-1901490 .page-projectreview footer p, .page-content-1901489 .page-projectreview footer a, .page-content-1901489 .page-projectreview footer p, .page-content-1902125 .page-projectreview footer a, .page-content-1902125 .page-projectreview footer p, .page-content-1901487 .page-projectreview footer a, .page-content-1901487 .page-projectreview footer p, .page-content-1901486 .page-projectreview footer a, .page-content-1901486 .page-projectreview footer p, .page-content-1901938 .page-projectreview footer a, .page-content-1901938 .page-projectreview footer p, .page-content-1901945 .page-projectreview footer a, .page-content-1901945 .page-projectreview footer p, .page-content-1830267 .page-projectreview footer a, .page-content-1830267 .page-projectreview footer p {
  color: #ffffff;
}

.page-content-1902525 .news-arrow-down, .page-content-1902591 .news-arrow-down {
  display: none;
}

.page-content-1903018 .whatsapp-float-container,
.page-content-1903018 section#prform,
.page-content-1903088 .whatsapp-float-container,
.page-content-1903088 section#prform {
  display: none;
}

.page-content-1903018 .lendlease-ads,
.page-content-1903088 .lendlease-ads {
  display: none;
  width: 100%;
  text-align: center;
  margin: 0 auto;
}

.page-content-1903018 .lendlease-ads img,
.page-content-1903088 .lendlease-ads img {
  max-width: 780px;
  width: 100%;
}

@media (min-width: 768px) {
  .page-content-1903018 .lendlease-ads.desktop,
  .page-content-1903088 .lendlease-ads.desktop {
    display: block;
  }
}

@media (max-width: 767px) {
  .page-content-1903018 .lendlease-ads.mobile,
  .page-content-1903088 .lendlease-ads.mobile {
    display: block;
  }
}

.page-content-1903018 .lendlease-survey,
.page-content-1903088 .lendlease-survey {
  width: 100%;
  height: 1000px;
  border: 0;
  overflow: hidden;
}

.page-content-1903018 .page-projectreview footer,
.page-content-1903018 .page-projectreview #mainNav.navbar,
.page-content-1903018 .page-projectreview .news-arrow-down .circle-color,
.page-content-1903018 .page-projectreview section.page-inquiry .inquiry-button,
.page-content-1903018 .page-projectreview .fixed-bar .fixed-buttons .btn.btn-enquire,
.page-content-1903018 .page-projectreview .fixed-bar,
.page-content-1903088 .page-projectreview footer,
.page-content-1903088 .page-projectreview #mainNav.navbar,
.page-content-1903088 .page-projectreview .news-arrow-down .circle-color,
.page-content-1903088 .page-projectreview section.page-inquiry .inquiry-button,
.page-content-1903088 .page-projectreview .fixed-bar .fixed-buttons .btn.btn-enquire,
.page-content-1903088 .page-projectreview .fixed-bar {
  background-color: #1965ae;
}

.page-content-1903018 .page-projectreview section.page-inquiry .inquiry-button,
.page-content-1903018 .page-projectreview .fixed-bar .fixed-buttons .btn.btn-enquire,
.page-content-1903088 .page-projectreview section.page-inquiry .inquiry-button,
.page-content-1903088 .page-projectreview .fixed-bar .fixed-buttons .btn.btn-enquire {
  background-color: #C92063;
  border-color: #C92063;
}

.page-content-1903018 .page-projectreview section.page-inquiry .inquiry-button:hover,
.page-content-1903018 .page-projectreview .fixed-bar .fixed-buttons .btn.btn-enquire:hover,
.page-content-1903088 .page-projectreview section.page-inquiry .inquiry-button:hover,
.page-content-1903088 .page-projectreview .fixed-bar .fixed-buttons .btn.btn-enquire:hover {
  background-color: #C92063;
  opacity: 0.9;
  border-color: #C92063;
}

.page-content-1903018 .page-projectreview footer,
.page-content-1903088 .page-projectreview footer {
  color: #fff;
}

.page-content-1903018 .page-projectreview footer a, .page-content-1903018 .page-projectreview footer p,
.page-content-1903088 .page-projectreview footer a,
.page-content-1903088 .page-projectreview footer p {
  color: #ffffff;
}

@media (max-width: 767px) {
  .page-content-1903018 .fixed-bar .bar-logo,
  .page-content-1903088 .fixed-bar .bar-logo {
    display: none !important;
  }
}

.page-content-1903088 i.fa.fa-quote-left.fa-2x.el-show {
  display: none;
}

.page-content-1903088 section#prform {
  display: block;
}

.page-content-1903172 i.fa.fa-quote-left.fa-2x.el-show {
  display: none;
}

.page-projectreview {
  /*  @media (min-width: 768px) and (max-width: 991px) {
    .navbar-collapse.collapse {
      display: none !important;
      &.in {
        display: block !important;
      }
    }
    .navbar-header .collapse, .navbar-toggle {
      display: block !important;
    }
    .navbar-header {
      float: none;
    }
  } */
}

.page-projectreview #brand-slide {
  z-index: 999;
  position: fixed;
  background: #dcdfe6;
  color: #333;
  width: 260px;
  text-align: center;
  bottom: 80px;
  right: -260px;
  height: 264px;
  transition: right 0.4s ease-in-out;
  -o-transition: right 0.4s ease-in-out;
  -ms-transition: right 0.4s ease-in-out;
  -moz-transition: right 0.4s ease-in-out;
  -webkit-transition: right 0.4s ease-in-out;
}

.page-projectreview #brand-slide .box {
  padding: 20px;
  font-size: 13px;
}

.page-projectreview #brand-slide:hover, .page-projectreview #brand-slide.in {
  right: 0px;
}

.page-projectreview #brand-slide.in, .page-projectreview #brand-slide.out {
  transition: right 0.4s ease-in-out;
  -o-transition: right 0.4s ease-in-out;
  -ms-transition: right 0.4s ease-in-out;
  -moz-transition: right 0.4s ease-in-out;
  -webkit-transition: right 0.4s ease-in-out;
  right: 0px;
}

.page-projectreview #brand-slide.out {
  right: -260px;
}

.page-projectreview #brand-slide #brand-toggle {
  position: absolute;
  right: 260px;
  padding: 10px 20px;
  background: #d1a12a;
  color: #fff;
  font-weight: 700;
  cursor: pointer;
}

.page-projectreview #brand-slide a.btn-slide {
  display: block;
  margin-top: 15px;
  background: #007F38;
  color: #fff;
  padding: 4px 10px;
  font-weight: 700;
}

@media (max-width: 768px) {
  .page-projectreview .brand-footer {
    display: block;
    margin-bottom: 10px;
  }
}

@media (min-width: 769px) {
  .page-projectreview .brand-footer {
    margin-right: 15px;
  }
}

.page-projectreview figcaption {
  margin-top: -25px;
  font-size: 1.2rem;
  font-family: "Poppins", sans-serif;
  font-style: italic;
  line-height: 1.4;
  letter-spacing: 0.03em;
  color: #969696;
}

.page-projectreview figcaption:before {
  font-family: 'FontAwesome';
  content: '\f05a';
  margin-right: 4px;
  font-style: normal;
  font-size: 1.3rem;
}

.page-projectreview .banner-show-d {
  display: none;
}

@media (min-width: 768px) {
  .page-projectreview .banner-show-d {
    display: block;
  }
}

.page-projectreview .banner-show-m {
  display: none;
}

@media (max-width: 767px) {
  .page-projectreview .banner-show-m {
    display: block;
  }
}

.page-projectreview a {
  color: #000;
  -webkit-transition: all .3s;
  -moz-transition: all .3s;
  transition: all 0.3s;
}

.page-projectreview a:hover {
  color: #f00000;
  text-decoration: none;
}

.page-projectreview .fa.fa-bars {
  font-style: normal;
  font-family: FontAwesome;
  font-size: 16px;
  line-height: 0;
}

.page-projectreview .container-news-skin {
  max-width: 980px;
  margin: 0 auto;
}

.page-projectreview .container-news-skin img {
  max-width: 100%;
  height: auto !important;
  width: 100% !important;
}

.page-projectreview .container-news-skin .news-side .side-quote {
  color: #666;
  font-size: 32px;
  line-height: 1.3;
  font-style: italic;
  font-weight: 700;
  letter-spacing: -0.1rem;
}

@media (min-width: 768px) and (max-width: 768px) {
  .page-projectreview .container-news-skin .news-side .side-quote {
    font-size: 22px;
  }
}

@media (min-width: 768px) {
  .page-projectreview .container-news-skin .news-side .side-quote {
    border-right: 1px solid #c2c2c2;
    padding-right: 20px;
  }
}

@media (max-width: 767px) {
  .page-projectreview .container-news-skin .news-side .side-quote {
    font-size: 19px;
  }
}

.page-projectreview .container-news-skin .news-side .side-meta {
  margin-top: 20px;
  color: #999;
  font-style: italic;
  font-size: 18px;
}

@media (max-width: 490px) {
  .page-projectreview .container-news-skin .news-side .side-meta {
    font-size: 15px;
    margin: 10px auto 30px;
  }
}

@media (max-width: 767px) {
  .page-projectreview .container-news-skin .heading-mobile {
    padding: 0 20px;
  }
}

.page-projectreview hr {
  max-width: 100px;
  margin: 25px auto 0;
  border-width: 1px;
  border-color: rgba(34, 34, 34, 0.1);
}

.page-projectreview hr.light {
  border-color: #fff;
}

.page-projectreview h1, .page-projectreview h2, .page-projectreview h3, .page-projectreview h4, .page-projectreview h5, .page-projectreview h6 {
  font-weight: 900;
}

.page-projectreview p {
  font-size: 16px;
  line-height: 1.5;
  margin-bottom: 20px;
  color: #666;
  font-style: italic;
}

.page-projectreview .news-content p {
  font-style: initial;
}

.page-projectreview section {
  padding: 40px 0;
}

.page-projectreview section h2 {
  margin-top: 15px;
  font-size: 28px;
  font-style: italic;
  color: #666;
  font-weight: 600;
  line-height: 1.3;
  margin-bottom: 20px;
}

.page-projectreview section.page-hero {
  padding-top: 0;
  padding-bottom: 0;
}

.page-projectreview section.page-hero .news-hero-main-component .news-hero-button ul.hero-buttons li a.active {
  background: #007455;
  padding: 1px 10px;
  border-radius: 3px;
}

.page-projectreview section.page-hero .news-hero-bg {
  background-image: url(https://media.edgeprop.my/s3fs-public/65632_1585711416_5EdgPro5_setiaphotogallery3_0.jpg);
  width: 100%;
  height: 570px;
  background-repeat: no-repeat;
  background-position: center 0;
  background-size: cover;
}

.page-projectreview section.page-hero .news-hero-bg .brand-partner {
  padding: 10px 0;
  background: rgba(0, 0, 0, 0.19);
}

@media (max-width: 1024px) and (min-width: 1024px) {
  .page-projectreview section.page-hero .news-hero-bg .brand-partner .col-lg-9.brand-partner-side {
    flex: 0 0 66.666667%;
    max-width: 66.666667%;
  }
}

@media (max-width: 425px) {
  .page-projectreview section.page-hero .news-hero-bg .brand-partner .brand-partner-side {
    display: none;
  }
}

.page-projectreview section.page-hero .news-hero-bg .brand-partner .brand-partner-placement {
  text-align: right;
  font-size: 13px;
}

@media (max-width: 768px) and (min-width: 768px) {
  .page-projectreview section.page-hero .news-hero-bg .brand-partner .brand-partner-placement {
    flex: 0 0 96%;
    max-width: 96%;
  }
}

.page-projectreview section.page-hero .news-hero-bg .brand-partner .brand-partner-placement .hero-field-text {
  line-height: 1;
  padding: 20px 0;
}

@media (max-width: 768px) {
  .page-projectreview section.page-hero .news-hero-bg .brand-partner .brand-partner-placement .hero-field-text {
    flex: 0 0 65%;
    max-width: 65%;
    padding: 20px 0;
  }
}

@media (max-width: 767px) {
  .page-projectreview section.page-hero .news-hero-bg .brand-partner .brand-partner-placement .hero-field-text {
    padding: 10px 0;
  }
}

@media (max-width: 425px) {
  .page-projectreview section.page-hero .news-hero-bg .brand-partner .brand-partner-placement .hero-field-text {
    flex: 0 0 68%;
    max-width: 68%;
  }
}

@media (max-width: 425px) {
  .page-projectreview section.page-hero .news-hero-bg .brand-partner .brand-partner-placement .hero-field-text {
    flex: 0 0 60%;
    max-width: 60%;
  }
}

@media (max-width: 768px) {
  .page-projectreview section.page-hero .news-hero-bg .brand-partner .brand-partner-placement .hero-field-text .field-text-container {
    width: 67%;
    float: right;
    position: relative;
    top: 0;
  }
}

@media (max-width: 568px) {
  .page-projectreview section.page-hero .news-hero-bg .brand-partner .brand-partner-placement .hero-field-text .field-text-container {
    width: 74%;
  }
}

@media (max-width: 424px) {
  .page-projectreview section.page-hero .news-hero-bg .brand-partner .brand-partner-placement .hero-field-text .field-text-container {
    width: 80%;
  }
}

@media (max-width: 320px) {
  .page-projectreview section.page-hero .news-hero-bg .brand-partner .brand-partner-placement .hero-field-text .field-text-container {
    top: 4px;
  }
}

.page-projectreview section.page-hero .news-hero-bg .brand-partner .brand-partner-placement .hero-field-text a,
.page-projectreview section.page-hero .news-hero-bg .brand-partner .brand-partner-placement .hero-field-text a:hover {
  color: inherit;
}

.page-projectreview section.page-hero .news-hero-bg .brand-partner .brand-partner-placement .hero-field-logo img {
  max-width: 150px;
}

@media (max-width: 767px) {
  .page-projectreview section.page-hero .news-hero-bg .brand-partner .brand-partner-placement .hero-field-logo img {
    max-width: 114px;
  }
}

@media (max-width: 375px) {
  .page-projectreview section.page-hero .news-hero-bg .brand-partner .brand-partner-placement .hero-field-logo img {
    max-width: 103px;
  }
}

@media (max-width: 768px) and (min-width: 768px) {
  .page-projectreview section.page-hero .news-hero-bg .brand-partner .brand-partner-placement .hero-field-logo {
    position: relative;
    top: 5px;
  }
}

@media (max-width: 768px) {
  .page-projectreview section.page-hero .news-hero-bg .brand-partner .brand-partner-placement .hero-field-logo {
    flex: 0 0 26%;
    max-width: 26%;
  }
}

@media (max-width: 320px) {
  .page-projectreview section.page-hero .news-hero-bg .brand-partner .brand-partner-placement .hero-field-logo {
    position: relative;
    top: 5px;
  }
}

@media (max-width: 1024px) and (min-width: 1024px) {
  .page-projectreview section.page-hero .news-hero-bg .brand-partner .col-lg-3 {
    max-width: 24%;
  }
}

.page-projectreview section.page-hero .news-hero-main-component {
  position: relative;
}

@media (min-width: 768px) {
  .page-projectreview section.page-hero .news-hero-main-component {
    top: 130px;
  }
}

@media (max-width: 767px) {
  .page-projectreview section.page-hero .news-hero-main-component {
    padding-top: 50px;
  }
}

.page-projectreview section.page-hero .news-hero-main-component .news-hero-text {
  color: #fff;
  text-align: center;
  margin-bottom: 90px;
  text-shadow: 4px 2px 8px #444;
}

.page-projectreview section.page-hero .news-hero-main-component .news-hero-text h4 {
  font-weight: 100;
  font-size: 22px;
}

@media (min-width: 1024px) {
  .page-projectreview section.page-hero .news-hero-main-component .news-hero-text h4 {
    font-size: 30px;
  }
}

@media (max-width: 490px) {
  .page-projectreview section.page-hero .news-hero-main-component .news-hero-text h4 {
    width: 90%;
    margin: 3px auto;
  }
}

.page-projectreview section.page-hero .news-hero-main-component .news-hero-text h1 {
  font-size: 35px;
  width: 90%;
  margin: 0 auto;
}

@media (min-width: 1024px) {
  .page-projectreview section.page-hero .news-hero-main-component .news-hero-text h1 {
    font-size: 56px;
  }
}

@media (max-width: 490px) {
  .page-projectreview section.page-hero .news-hero-main-component .news-hero-text h1 {
    margin: 3px auto;
  }
}

.page-projectreview section.page-hero .news-hero-main-component .news-hero-button {
  background: rgba(0, 0, 0, 0.5);
  padding: 11px 0;
}

.page-projectreview section.page-hero .news-hero-main-component .news-hero-button ul.hero-buttons {
  text-align: center;
  margin: 0;
  padding: 0;
}

.page-projectreview section.page-hero .news-hero-main-component .news-hero-button ul.hero-buttons li {
  display: inline-block;
  color: #fff;
  text-align: center;
  text-transform: uppercase;
  margin-right: 20px;
  font-size: 18px;
}

.page-projectreview section.page-hero .news-hero-main-component .news-hero-button ul.hero-buttons li a {
  color: #fff;
}

.page-projectreview section.page-content .news-content img {
  margin-bottom: 30px;
}

.page-projectreview section.page-content .news-content a {
  color: #488BF8 !important;
}

.page-projectreview section.page-related h3, .page-projectreview section.page-categories h3, .page-projectreview section.page-accolades h3 {
  margin-top: 15px;
  font-size: 23px;
  font-style: italic;
  color: #666;
  font-weight: 600;
  line-height: 1.3;
}

.page-projectreview section.page-related img.similar-articles, .page-projectreview section.page-accolades img.similar-articles {
  height: 190px !important;
  object-fit: cover;
}

.page-projectreview section.page-related h3, .page-projectreview section.page-accolades h3 {
  font-size: 19px;
}

.page-projectreview section.page-related p, .page-projectreview section.page-accolades p {
  display: block;
  display: -webkit-box;
  /* max-width: 400px; */
  height: 76px;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

.page-projectreview section.page-accolades .accolades-card {
  background-color: #fff;
  border: 1px solid rgba(0, 0, 0, 0.125);
  text-align: center;
  padding: 20px;
}

@media (min-width: 769px) {
  .page-projectreview section.page-accolades .accolades-card {
    min-height: 452px;
  }
}

@media (min-width: 768px) and (max-width: 768px) {
  .page-projectreview section.page-accolades .accolades-card {
    min-height: 557px;
  }
}

.page-projectreview section.page-accolades .accolades-card .accolades-logo {
  max-width: 196px;
  height: 100%;
  margin: 10px 0 30px;
}

.page-projectreview section.page-accolades .accolades-card .text-top,
.page-projectreview section.page-accolades .accolades-card .text-center,
.page-projectreview section.page-accolades .accolades-card .text-bottom {
  margin-bottom: 20px;
}

.page-projectreview section.page-accolades .accolades-card .text-top {
  font-style: italic;
  color: #222;
  font-size: 16px;
  font-weight: 700;
}

.page-projectreview section.page-accolades .accolades-card .text-center {
  color: #cc0000;
  font-size: 14px;
  font-weight: 600;
}

.page-projectreview section.page-accolades .accolades-card .text-bottom {
  color: #666;
  font-size: 14px;
  font-weight: 200;
  margin-bottom: 30px;
}

.page-projectreview section.page-brand .brand-button {
  cursor: pointer;
  font-size: 1.9rem;
  line-height: 1.3;
  text-transform: uppercase;
  border: 1px solid #000;
  text-align: center;
  background: #fff;
  padding: 12px 80px;
  margin-top: 10px;
  display: inline-block;
}

.page-projectreview section.page-brand .brand-button:hover {
  border-color: #cc0000;
  color: #cc0000;
}

.page-projectreview section.page-categories h2, .page-projectreview section.page-inquiry h2 {
  margin-top: 15px;
  font-size: 28px;
  font-style: italic;
  color: #666;
  font-weight: 700;
  line-height: 1.3;
  margin-bottom: 10px;
}

.page-projectreview section.page-categories h3, .page-projectreview section.page-inquiry h3 {
  font-weight: 500;
  color: #666;
  margin-bottom: 30px;
}

.page-projectreview section.page-categories {
  background: #0a2141;
  color: #fff;
}

.page-projectreview section.page-categories h2 {
  color: #fff;
}

.page-projectreview section.page-categories h3 {
  color: #fff;
}

.page-projectreview section.page-categories .categories-text {
  font-size: 22px;
  margin-bottom: 30px;
}

.page-projectreview section.page-inquiry {
  background: #efefef;
}

.page-projectreview section.page-inquiry h2, .page-projectreview section.page-inquiry h3 {
  text-align: center;
}

@media (min-width: 768px) {
  .page-projectreview section.page-inquiry #moreinfo {
    max-width: 500px;
    margin: 0 auto;
  }
}

.page-projectreview section.page-inquiry #moreinfo .form-group {
  margin-bottom: 20px;
}

.page-projectreview section.page-inquiry #moreinfo .form-group .form-control {
  font-size: 14px;
}

.page-projectreview section.page-inquiry #moreinfo .form-check {
  padding-left: 0;
}

.page-projectreview section.page-inquiry #moreinfo .form-check label {
  margin-left: 5px;
  font-size: 13px;
}

@media (max-width: 991px) {
  .page-projectreview section.page-inquiry #moreinfo .form-check label {
    font-size: 11px;
  }
}

.page-projectreview section.page-inquiry #moreinfo .label-pdpa {
  position: relative;
  right: -6px;
  top: -3px;
  height: 20px;
}

@media (max-width: 320px) {
  .page-projectreview section.page-inquiry #moreinfo .label-pdpa {
    margin-right: 10px;
  }
}

@media (max-width: 425px) {
  .page-projectreview section.page-inquiry #moreinfo .label-pdpa {
    margin-right: 4px;
  }
}

@media (min-width: 991px) {
  .page-projectreview section.page-inquiry #moreinfo .label-pdpa {
    position: relative;
    right: -1px;
    top: -1px;
  }
}

.page-projectreview section.page-inquiry .inquiry-button {
  width: 100%;
  margin: 0 auto;
  display: inherit;
  cursor: pointer;
  font-size: 1.9rem;
  line-height: 1.3;
  text-transform: uppercase;
  border: 1px solid #cc0000;
  background: #cc0000;
  color: #fff;
  text-align: center;
  padding: 12px 80px;
  margin-top: 10px;
  display: inline-block;
}

.page-projectreview section.page-inquiry .inquiry-button:hover {
  background: #990000;
}

.page-projectreview section.page-share {
  margin: 0 auto;
  text-align: center;
}

.page-projectreview section.page-share h2 {
  text-align: center;
  text-transform: uppercase;
  color: #666;
  font-size: 28px;
  font-weight: 600;
}

.page-projectreview section.page-share ul.share-icons {
  margin: 0;
  padding: 0;
}

.page-projectreview section.page-share ul.share-icons li {
  cursor: pointer;
  display: inline-block;
  list-style-type: none;
  border-radius: 50%;
  width: 44px;
  height: 44px;
  background: #efefef;
  padding: 8px;
  font-size: 2rem;
  color: #888;
}

.page-projectreview section.page-share ul.share-icons li.in-between {
  margin: 0 26px;
}

.page-projectreview section.page-share ul.share-icons li:hover {
  background: #ddd;
}

.page-projectreview .news-arrow-down {
  text-align: center;
  font-size: 70px;
  color: #cc0000;
  margin-top: -55px;
}

.page-projectreview .news-arrow-down .circle-color {
  margin: 0 auto;
  background: #cc0000;
  line-height: 57px;
  width: 60px;
  height: 60px;
  box-shadow: 0 5px 2px -2px #bfbfbf;
  border-radius: 50%;
}

.page-projectreview span.header-logotype {
  font-weight: 700 !important;
  text-transform: uppercase !important;
  color: #cc0000 !important;
  position: relative;
  top: 18px;
}

.page-projectreview .m-b-20 {
  margin-bottom: 20px;
}

.page-projectreview .logotype {
  text-align: center;
  color: #f4b124;
  margin-left: auto;
  margin-right: auto;
  left: 0;
  right: 0;
}

@media (max-width: 767px) {
  .page-projectreview .logotype {
    margin-left: 0;
  }
}

.page-projectreview .logotype img {
  opacity: 0.9;
}

.page-projectreview .logotype.footer img {
  height: 55px;
}

.page-projectreview .logotype.toplogo img {
  height: 100%;
  max-height: 35px;
  width: auto;
}

.page-projectreview .logotype.home {
  text-align: left;
  position: absolute;
  z-index: 1;
  margin-left: auto;
  margin-right: auto;
  left: 0;
  right: 0;
  padding: 8px;
}

.page-projectreview .border-side {
  border-right: 1px solid #ccc;
  border-left: 1px solid #ccc;
}

.page-projectreview .no-float {
  float: none;
}

.page-projectreview footer {
  font-style: normal;
  color: #fff;
  padding: 20px 0;
  text-align: center;
  color: rgba(255, 255, 255, 0.3);
  background-color: #222;
  margin-bottom: 45px;
}

@media (min-width: 769px) {
  .page-projectreview footer {
    margin-bottom: 74px;
  }
}

.page-projectreview footer p {
  font-size: 12px;
  margin: 0;
}

.page-projectreview footer ul {
  margin-bottom: 0;
}

.page-projectreview footer ul li a {
  font-size: 12px;
  color: rgba(255, 255, 255, 0.3);
}

.page-projectreview footer ul li a.active, .page-projectreview footer ul li a:active, .page-projectreview footer ul li a:focus, .page-projectreview footer ul li a:hover {
  text-decoration: none;
}

.page-projectreview footer a {
  color: inherit;
}

.page-projectreview footer a:hover {
  color: inherit;
}

.page-projectreview #mainNav {
  -webkit-transition: all .3s;
  -moz-transition: all .3s;
  transition: all .3s;
  font-weight: 200;
  border-radius: 0;
}

.page-projectreview #mainNav.stick {
  margin-top: 0 !important;
  width: 100%;
  position: fixed;
  top: 0;
  z-index: 10000;
}

.page-projectreview #mainNav.navbar {
  border-radius: 0 !important;
  margin-bottom: 0 !important;
  margin-bottom: 0;
  min-height: 44px;
  border: 0;
  background: #000;
}

.page-projectreview #mainNav .navbar-brand {
  color: #cc0000;
  font-weight: 200;
  letter-spacing: 1px;
}

.page-projectreview #mainNav .navbar-brand:focus, .page-projectreview #mainNav .navbar-brand:hover {
  color: #b96700;
}

.page-projectreview #mainNav .navbar-toggler {
  font-size: 12px;
  padding: 8px 10px;
  color: #fff;
  position: absolute;
  right: 10px;
  top: 10px;
  display: none;
}

.page-projectreview #mainNav .navbar-nav > li > a {
  font-size: 12px;
  letter-spacing: 2px;
  text-transform: uppercase;
  letter-spacing: 0;
  padding: 5px;
  color: #fff;
}

.page-projectreview #mainNav .navbar-nav > li > a.active {
  color: #000 !important;
  background-color: transparent;
}

.page-projectreview #mainNav .navbar-nav > li > a.active:hover {
  background-color: transparent;
}

.page-projectreview #mainNav .navbar-nav > li > a:focus {
  color: #fff;
}

.page-projectreview #mainNav .navbar-nav > li > a:focus:hover {
  color: #000;
}

.page-projectreview #mainNav .navbar-nav > li > a:hover {
  color: #000;
}

.page-projectreview #mainNav .navbar-nav .nav-item .wrap-year-select {
  position: relative;
  padding: 5px;
}

@media (max-width: 425px) {
  .page-projectreview #mainNav .navbar-nav .nav-item .wrap-year-select {
    padding: 0;
  }
}

.page-projectreview #mainNav .navbar-nav .nav-item .wrap-year-select .dropdown .dropdown-toggle {
  background-color: transparent;
  border: 1px solid #ffffff;
  border-radius: 0;
  font-size: 13px;
  color: #ffffff;
  letter-spacing: 1px;
}

.page-projectreview #mainNav .navbar-nav .nav-item .wrap-year-select .dropdown .dropdown-menu {
  background-color: #000;
}

.page-projectreview #mainNav .navbar-nav .nav-item .wrap-year-select .dropdown .dropdown-menu .dropdown-item {
  border-radius: 0;
  font-size: 13px;
  color: #ffffff;
}

.page-projectreview #mainNav .navbar-nav .nav-item .wrap-year-select .dropdown .dropdown-menu .dropdown-item:hover {
  color: #cc0000;
  background-color: #000;
}

@media (max-width: 425px) {
  .page-projectreview #mainNav .navbar-nav .nav-item .wrap-year-select .dropdown .dropdown-menu .dropdown-item {
    text-align: left;
    padding-left: 0;
  }
}

@media (min-width: 992px) {
  .page-projectreview #mainNav .navbar-brand {
    color: fade(white, 70%);
  }
  .page-projectreview #mainNav .navbar-brand:focus, .page-projectreview #mainNav .navbar-brand:hover {
    color: #fff;
  }
  .page-projectreview #mainNav .navbar-nav > li > a {
    color: #fff;
  }
  .page-projectreview #mainNav .navbar-nav > li > a:focus {
    color: rgba(255, 255, 255, 0.7);
  }
  .page-projectreview #mainNav .navbar-nav > li > a:focus:hover {
    color: rgba(255, 255, 255, 0.7);
  }
  .page-projectreview #mainNav .navbar-nav > li > a:hover {
    color: #fff;
  }
  .page-projectreview #mainNav.navbar-shrink {
    border-color: rgba(34, 34, 34, 0.1);
    background-color: #000;
    border-radius: 0;
  }
  .page-projectreview #mainNav.navbar-shrink .navbar-brand {
    color: #f9f9f9;
  }
  .page-projectreview #mainNav.navbar-shrink .navbar-brand:focus, .page-projectreview #mainNav.navbar-shrink .navbar-brand:hover {
    color: #cc0000;
  }
  .page-projectreview #mainNav.navbar-shrink .navbar-nav > li > a {
    color: #f9f9f9;
  }
  .page-projectreview #mainNav.navbar-shrink .navbar-nav > li > a:focus {
    color: #f9f9f9;
  }
  .page-projectreview #mainNav.navbar-shrink .navbar-nav > li > a:focus:hover {
    color: #cc0000;
  }
  .page-projectreview #mainNav.navbar-shrink .navbar-nav > li > a:hover {
    color: #cc0000;
  }
}

.page-projectreview section.general {
  border-top: 10px solid #cc0000;
  position: relative;
  padding: 50px 0 50px;
  overflow: hidden;
}

.page-projectreview section.general h2 {
  font-size: 50px;
  margin: 0 0 30px;
  text-transform: uppercase;
}

.page-projectreview section.general h2.m-b-5 {
  margin-bottom: 5px;
}

.page-projectreview section.general h3 {
  font-size: 21px;
  margin-bottom: 30px;
  text-transform: uppercase;
}

.page-projectreview section.general h4 {
  font-size: 2.2rem;
  line-height: 1;
  margin-bottom: 15px;
  color: #cc0000;
  text-transform: lowercase;
  font-style: italic;
}

.page-projectreview section.general.no-border {
  border-top: 0;
}

.page-projectreview section.general.p-t-25 {
  padding-top: 25px;
}

.page-projectreview .top-text {
  max-width: 800px;
  margin: 35px auto 10px;
  font-style: italic;
  font-size: 22px;
  text-align: center;
  color: #555;
}

@media (max-width: 767px) {
  .page-projectreview .top-text {
    font-size: 20px;
  }
}

@media (max-width: 768px) {
  .page-projectreview .logotype img {
    width: 220px;
  }
}

@media (max-width: 767px) {
  .page-projectreview .border-side {
    border-right: 0;
    border-left: 0;
  }
}

@media (min-width: 426px) {
  .page-projectreview .visible-xs {
    display: none !important;
  }
}

@media (max-width: 425px) {
  .page-projectreview .visible-xs {
    display: block;
  }
}

.page-projectreview .bg-primary {
  background: #cc0000;
  background: -webkit-linear-gradient(#cc0000, #d37500);
  background: linear-gradient(#cc0000, #d37500);
}

.page-projectreview .text-primary {
  color: #cc0000;
}

.page-projectreview .no-gutter > [class*=col-] {
  padding-right: 0;
  padding-left: 0;
}

.page-projectreview .btn-outline {
  color: #fff;
  border: 1px solid;
  border-color: #fff;
}

.page-projectreview .btn-outline.active, .page-projectreview .btn-outline:active, .page-projectreview .btn-outline:focus, .page-projectreview .btn-outline:hover {
  color: #fff;
  border-color: #cc0000;
  background-color: #cc0000;
}

.page-projectreview .btn {
  border-radius: 300px;
  letter-spacing: 2px;
  text-transform: uppercase;
}

.page-projectreview .btn-xl {
  font-size: 11px;
  padding: 15px 45px;
}

.page-projectreview .nav-pills .nav-link.active {
  border: 1px solid #cc0000;
  color: #cc0000;
  background-color: transparent;
}

.page-projectreview ul.list-unstyled li {
  margin-bottom: 20px;
  font-size: 13px;
  color: #666;
}

.page-projectreview .ml-auto, .page-projectreview .mx-auto {
  margin-left: auto !important;
}

.page-projectreview .mr-auto, .page-projectreview .mx-auto {
  margin-right: auto !important;
}

.page-projectreview span {
  display: block;
}

.page-projectreview span.stories-title {
  font-size: 1.4rem;
  font-weight: bold;
}

.page-projectreview span.stories-subtitle {
  font-size: 1.2rem;
}

.page-projectreview ul.stories-list li span.story-text {
  display: block;
  font-weight: 600;
  font-size: 1.4rem;
  line-height: 1.5;
  margin: 0 auto;
}

@media (min-width: 769px) {
  .page-projectreview ul.stories-list li span.story-text {
    width: 70%;
  }
}

@media (min-width: 769px) {
  .page-projectreview ul.stories-list li {
    min-height: 270px;
  }
}

@media (min-width: 768px) and (max-width: 768px) {
  .page-projectreview ul.stories-list li {
    min-height: 262px;
  }
}

.page-projectreview select#select-years {
  font-size: 1.3rem;
  position: relative;
  background: #000;
  color: #fff;
}

@media (min-width: 768px) {
  .page-projectreview select#select-years {
    top: 13px;
  }
}

@media (max-width: 767px) {
  .page-projectreview .mobile-bg-odd {
    background: #333;
    position: relative;
    right: 10px;
    bottom: -6px;
    width: 102%;
    padding: 5px 0 5px 11px;
  }
}

.page-projectreview .post-form-overlay {
  position: fixed;
  bottom: -160%;
  height: 100vh;
  width: 100vw;
  background: rgba(0, 0, 0, 0.5);
  transition: all .5s .2s ease-in-out;
}

.page-projectreview .post-form-overlay .post-title {
  font-size: 2.4rem;
  margin-bottom: 25px;
}

@media (min-width: 768px) {
  .page-projectreview .post-form-overlay .post-title {
    width: 80%;
    margin: 0 auto 25px;
  }
}

.page-projectreview .post-form-overlay .sub-post-title {
  font-size: 1.8rem;
}

.page-projectreview .post-form-overlay .post-form-close {
  position: absolute;
  top: 0;
  background: rgba(0, 0, 0, 0.18);
  color: white;
  right: calc((100% - 500px) / 2);
  padding: 0 10px;
  font-size: 28px;
  z-index: 1;
  cursor: pointer;
}

@media (max-width: 991px) {
  .page-projectreview .post-form-overlay .post-form-close {
    right: calc((100% - 300px) / 2);
  }
}

.page-projectreview .post-form-overlay .post-form-close:hover {
  background: rgba(20, 20, 20, 0.18);
}

.page-projectreview .post-form-overlay-content {
  background: #fff;
  box-shadow: 0 4px 11px -5px #bfbfbf;
  border: 1px solid #ddd;
  text-align: center;
  margin: 13% auto 0;
  min-height: 220px;
  padding: 25px 20px 20px;
  width: 500px;
  right: calc((100% - 500px) / 2);
}

@media (max-width: 991px) {
  .page-projectreview .post-form-overlay-content {
    margin: 20% auto 0;
    width: 300px;
    right: calc((100% - 300px) / 2);
    padding: 20px;
  }
}

.page-projectreview .post-form-overlay.in {
  bottom: 0;
  transition: all .5s .2s ease-in-out;
}

.page-projectreview .fixed-bar {
  position: fixed;
  background: #fff;
  width: 100%;
  padding: 15px 60px;
  bottom: 0;
  left: 0;
  z-index: 4;
  box-shadow: 0 -5px 10px rgba(0, 0, 0, 0.24);
}

@media (max-width: 991px) {
  .page-projectreview .fixed-bar {
    padding: 10px;
  }
}

.page-projectreview .fixed-bar .align-items-center {
  align-items: center !important;
}

.page-projectreview .fixed-bar .bar-logo {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.page-projectreview .fixed-bar .bar-logo img {
  height: 100%;
  max-height: 50px;
  width: auto;
}

@media (max-width: 991px) {
  .page-projectreview .fixed-bar .bar-logo img {
    height: auto;
    width: 100%;
    max-width: 30px;
  }
}

.page-projectreview .fixed-bar .bar-logo .bar-text {
  margin-left: 10px;
  font-size: 16px;
  font-style: italic;
  font-weight: 700;
  color: #666;
}

@media (max-width: 991px) {
  .page-projectreview .fixed-bar .bar-logo .bar-text {
    font-size: 10px;
    line-height: 1.2;
  }
}

@media (max-width: 991px) {
  .page-projectreview .fixed-bar .bar-logo {
    width: 39%;
  }
}

@media (min-width: 1024px) and (max-width: 1024px) {
  .page-projectreview .fixed-bar .bar-logo {
    width: 82%;
  }
}

@media (min-width: 1025px) {
  .page-projectreview .fixed-bar .bar-logo {
    width: 52%;
  }
}

.page-projectreview .fixed-bar .fixed-buttons .btn {
  color: #fff;
  padding: 8px 20px;
  font-size: 18px;
  letter-spacing: 0;
  border-radius: 3px;
}

@media (max-width: 991px) {
  .page-projectreview .fixed-bar .fixed-buttons .btn {
    font-size: 14px;
    padding: 6px 15px;
  }
}

.page-projectreview .fixed-bar .fixed-buttons .btn.btn-enquire {
  background-color: #cc0000;
  border-color: #cc0000;
}

.page-projectreview .fixed-bar .fixed-buttons .btn.btn-enquire:hover {
  background: #990000;
}

.page-content-1886214 .page-projectreview #mainNav.navbar {
  background: #09428f;
}

.page-content-1886214 .page-projectreview section.page-hero .news-hero-bg {
  background-image: url(https://media.edgeprop.my/page/project-review/nippon/nippon-hero.jpg) !important;
}

.page-content-1886214 .page-projectreview section.page-hero .news-hero-bg .news-hero-main-component {
  top: 70px;
}

@media (max-width: 767px) {
  .page-content-1886214 .page-projectreview section.page-hero .news-hero-bg .news-hero-main-component {
    padding-top: 10px;
  }
}

.page-content-1886214 .page-projectreview section.page-hero .news-hero-bg .news-hero-main-component .news-hero-text {
  margin-bottom: 50px;
}

.page-content-1886214 .page-projectreview section.page-hero .news-hero-bg .news-hero-main-component .news-hero-text h1, .page-content-1886214 .page-projectreview section.page-hero .news-hero-bg .news-hero-main-component .news-hero-text h4 {
  text-shadow: 2px 6px 8px #000000;
}

@media (min-width: 768px) {
  .page-content-1886214 .page-projectreview section.page-hero .news-hero-bg {
    background-position: bottom right;
  }
}

.page-content-1886214 .page-projectreview section.page-hero .news-hero-bg .nbox-container {
  margin: 0 auto;
  text-align: center;
}

@media (min-width: 768px) {
  .page-content-1886214 .page-projectreview section.page-hero .news-hero-bg .nbox-container .nbox-row {
    display: inline-block;
  }
}

@media (max-width: 767px) {
  .page-content-1886214 .page-projectreview section.page-hero .news-hero-bg .nbox-container .nbox-row {
    display: block;
  }
}

@media (max-width: 767px) {
  .page-content-1886214 .page-projectreview section.page-hero .news-hero-bg .nbox-container .nbox-row.mtop {
    margin-top: 3px;
  }
}

@media (min-width: 1024px) {
  .page-content-1886214 .page-projectreview section.page-hero .news-hero-bg .nbox-container .nbox-row span {
    padding-right: 0;
  }
}

@media (max-width: 767px) {
  .page-content-1886214 .page-projectreview section.page-hero .news-hero-bg .nbox-container .nbox-row span img {
    width: 150px;
  }
}

@media (min-width: 1024px) {
  .page-content-1886214 .page-projectreview section.page-hero .news-hero-bg .nbox-container .nbox-row.second span:last-child {
    padding-right: 0;
  }
}

.page-content-1886214 .page-projectreview section.page-hero .news-hero-bg .nippon-logo {
  margin-bottom: 30px;
}

@media (min-width: 769px) {
  .page-content-1886214 .page-projectreview section.page-hero .news-hero-bg .nippon-logo {
    width: 332px;
  }
}

@media (max-width: 425px) {
  .page-content-1886214 .page-projectreview section.page-hero .news-hero-bg .nippon-logo {
    width: 200px;
  }
}

.page-content-1886214 .page-projectreview #mainNav.navbar, .page-content-1884779 .page-projectreview #mainNav.navbar, .page-content-1884770 .page-projectreview #mainNav.navbar {
  background: #09428f;
}

#bma-entry footer {
  text-align: center;
  margin-bottom: 30px;
}

#bma-entry footer .bma-footer.desktop {
  display: none;
  max-width: 1300px;
  margin: 0 auto;
}

@media (min-width: 1024px) and (max-width: 1299px) {
  #bma-entry footer .bma-footer.desktop {
    width: 100%;
  }
}

@media (min-width: 769px) {
  #bma-entry footer .bma-footer.desktop {
    display: block;
  }
}

#bma-entry footer .bma-footer.mobile {
  max-width: 320px;
  display: none;
  margin: 0 auto;
}

@media (max-width: 768px) {
  #bma-entry footer .bma-footer.mobile {
    display: block;
  }
}

#bmaentry footer {
  text-align: center;
  margin-bottom: 30px;
}

#bmaentry footer .bma-footer.desktop {
  display: none;
  max-width: 1300px;
  margin: 0 auto;
}

@media (min-width: 1024px) and (max-width: 1299px) {
  #bmaentry footer .bma-footer.desktop {
    width: 100%;
  }
}

@media (min-width: 769px) {
  #bmaentry footer .bma-footer.desktop {
    display: block;
  }
}

#bmaentry footer .bma-footer.mobile {
  max-width: 320px;
  display: none;
  margin: 0 auto;
}

@media (max-width: 768px) {
  #bmaentry footer .bma-footer.mobile {
    display: block;
  }
}

#bmaentry {
  color: #fff;
  font-size: 11px;
  /* footer area */
}

#bmaentry .highlight {
  color: #ffd900;
}

#bmaentry .padnull {
  padding: 0;
}

@media (max-width: 768px) {
  #bmaentry .padnull {
    text-align: center;
  }
}

#bmaentry .m-t-25 {
  margin-top: 25px;
}

#bmaentry h4, #bmaentry h5 {
  color: #ffd900;
}

#bmaentry h4 span.relax {
  font-weight: 200;
  margin: 0;
  display: block;
  color: #fff;
}

#bmaentry p.p-sm {
  font-size: 10px;
}

@media (max-width: 768px) {
  #bmaentry p.p-sm {
    text-align: center;
  }
}

#bmaentry .bg-full {
  background-image: url("https://media.edgeprop.my/page/bmpa/2020/submission/awards2020-bg.jpg");
  background-repeat: no-repeat;
  background-position: left center;
  background-size: cover;
  max-width: 100%;
  padding: 0;
  width: 100%;
}

@media (max-width: 768px) {
  #bmaentry .bg-full {
    background-position: 47% 0%;
  }
}

#bmaentry .bg-full.v21 {
  background-image: url("https://media.edgeprop.my/page/bmpa/2021/entry/2021-bg.jpg");
}

#bmaentry .bg-full.v21 .big-btn {
  position: relative;
  left: 2px;
  top: -131px;
  max-width: 97%;
}

#bmaentry .bg-full.v21 .big-btn-sm {
  display: none;
}

@media (max-width: 768px) {
  #bmaentry .bg-full.v21 .big-btn-sm {
    display: block;
    position: initial;
    margin-top: 30px;
    width: 100%;
  }
}

#bmaentry .bg-full.v21 .sm-btn {
  width: 100%;
}

@media (min-width: 1024px) {
  #bmaentry .bg-full.v21 .past-foot {
    bottom: -586px;
  }
}

#bmaentry .bg-full.v21 .box {
  background: #6f71d9;
  padding: 5px 8px;
  color: #fff;
  margin-bottom: 10px;
}

#bmaentry .bg-full.v21 .box.box-specialised {
  background: #924ae5;
}

#bmaentry .bg-full.v21 .box.box-guidelines {
  background: #b53bb8;
}

#bmaentry .bg-full.v21 .box.box-responsible {
  background: #7da24a;
}

#bmaentry .bg-full.v21 .box.box-ilam {
  background: #9b764a;
}

#bmaentry .bg-full.v21 .box ul {
  margin-bottom: 0;
}

#bmaentry img.kpkt {
  width: 100%;
  max-width: 124px;
  margin: 13px 0 0 0;
}

#bmaentry img.bmpa-logo {
  position: relative;
  top: 20px;
  /* width: 100%; */
  max-width: 518px;
}

@media (max-width: 768px) {
  #bmaentry img.bmpa-logo {
    width: 100%;
  }
}

#bmaentry .a20-wrapper ul {
  padding: 0 0 0 15px;
}

@media (max-width: 768px) {
  #bmaentry .a20-wrapper h4, #bmaentry .a20-wrapper h5 {
    text-align: center;
    margin-top: 25px;
  }
}

#bmaentry button {
  border: 0;
  border-radius: 5px;
  background: #fff;
  color: #444;
  text-transform: uppercase;
  font-weight: 700;
  padding: 4px 10px;
  margin: 5px 0;
  width: 100%;
}

#bmaentry button:hover {
  background: #fff3ae;
}

@media (max-width: 768px) {
  #bmaentry button {
    width: 100%;
  }
}

#bmaentry button.primary-bma {
  background: #ffd900;
  color: #000;
  padding: 10px;
  font-size: 13px;
  margin-bottom: 22px;
}

#bmaentry button.primary-bma:hover {
  background: #f2cf07;
}

#bmaentry .bg-opacity {
  background-color: rgba(0, 0, 0, 0.5);
  background-position: left center;
  background-size: cover;
}

#bmaentry .a20-footer {
  color: #444;
  padding: 20px 0 50px;
}

#bmaentry .a20-footer .a20-title {
  display: block;
}

#bmaentry .a20-footer ul.logo-supported {
  padding: 0;
  margin-top: 5px;
}

#bmaentry .a20-footer ul.logo-supported li {
  display: inline-block;
  padding-right: 10px;
}

#bmaentry .a20-footer ul.logo-supported li img {
  width: 85%;
}

#bmaentry .a20-footer img {
  margin-top: 10px;
  position: relative;
}

#bmaentry .a20-footer img.logo-supporting-partner {
  width: 114px;
}

@media (min-width: 1024px) {
  #bmaentry .a20-footer .col-md-3 {
    /* width: 20%; */
  }
  #bmaentry .a20-footer .col-md-2 {
    /* width: 13%; */
  }
  #bmaentry .a20-footer .col-md-5 {
    /* width: 54%; */
  }
  #bmaentry .a20-footer .col-md-3,
  #bmaentry .a20-footer .col-md-2,
  #bmaentry .a20-footer .col-md-5 {
    text-align: left;
  }
  #bmaentry .a20-footer img.logo-present {
    top: 11px;
  }
  #bmaentry .a20-footer img.logo-partner {
    left: -8px;
    width: 115px;
    bottom: -5px;
  }
  #bmaentry .a20-footer img.logo-supporting-partner {
    top: 20px;
  }
}

@media (max-width: 768px) {
  #bmaentry .a20-footer .col-md-2,
  #bmaentry .a20-footer .col-md-3,
  #bmaentry .a20-footer .col-md-8,
  #bmaentry .a20-footer .a20-title,
  #bmaentry .a20-footer ul.logo-supported {
    text-align: center;
  }
  #bmaentry .a20-footer .a20-title {
    margin-top: 30px;
  }
}

#bmaentry .past-foot a {
  font-size: 10px;
  color: #fff;
  border-bottom: 1px solid #ffd900;
  text-decoration: none;
}

@media (max-width: 768px) {
  #bmaentry .past-foot a {
    font-size: 9px;
  }
}

@media (min-width: 1024px) {
  #bmaentry .past-foot {
    position: absolute;
    bottom: -384px;
  }
  #bmaentry .past-foot .d-footnote {
    position: relative;
    bottom: -79px;
  }
}

@media (max-width: 768px) {
  #bmaentry .past-foot {
    text-align: center;
  }
}

@media (min-width: 1440px) {
  #bmaentry .container.cont-top {
    width: 100%;
    max-width: 1250px;
  }
}

@media (min-width: 1366px) {
  #bmaentry .container.cont-top {
    width: 100%;
    max-width: 1240px;
  }
}

@media (min-width: 1024px) {
  #bmaentry .container.a20-container {
    max-width: 100%;
    width: 100%;
    margin: 0 auto;
    text-align: center;
  }
}

@media (max-width: 768px) {
  #bmaentry .audit {
    margin: 10px 0;
  }
}

@media (max-width: 768px) {
  #bmaentry .m-kpkt {
    margin-top: 40px;
  }
}

body.page-awards2020entry .intercom-app {
  display: none !important;
}

#vpex_default {
  background: url(https://media.edgeprop.my/page/vpex/2021/bg_cloud.png) no-repeat center center fixed;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
}

#vpex_default .back-to-top {
  height: auto;
  position: fixed;
  z-index: 100;
  bottom: 0.7%;
  right: 5%;
}

#vpex_default .back-to-top img {
  max-width: 55px;
}

#vpex_default .vpex-menu {
  cursor: pointer;
  width: 100%;
  max-width: 700px;
  padding: 20px 0;
  margin: 0 auto;
}

#vpex_default .vpex-menu img {
  position: fixed;
  max-width: 55px;
  z-index: 100;
  right: 5%;
}

@media (max-width: 1024px) {
  #vpex_default .vpex-menu img {
    right: 5%;
  }
}

#vpex_default .vpex-menu :hover {
  opacity: 80%;
}

#vpex_default .vpex-top, #vpex_default .vpex-main-content, #vpex_default .vpex-projects {
  width: 100%;
}

#vpex_default .vpex-top .v--billboard,
#vpex_default .vpex-top .v--map,
#vpex_default .vpex-top .v--project, #vpex_default .vpex-main-content .v--billboard,
#vpex_default .vpex-main-content .v--map,
#vpex_default .vpex-main-content .v--project, #vpex_default .vpex-projects .v--billboard,
#vpex_default .vpex-projects .v--map,
#vpex_default .vpex-projects .v--project {
  max-width: 1080px;
  margin: 0 auto;
  position: relative;
}

@media (max-width: 1024px) {
  #vpex_default .vpex-top .v--billboard,
  #vpex_default .vpex-top .v--map,
  #vpex_default .vpex-top .v--project, #vpex_default .vpex-main-content .v--billboard,
  #vpex_default .vpex-main-content .v--map,
  #vpex_default .vpex-main-content .v--project, #vpex_default .vpex-projects .v--billboard,
  #vpex_default .vpex-projects .v--map,
  #vpex_default .vpex-projects .v--project {
    max-width: 900px;
  }
}

@media (max-width: 991px) {
  #vpex_default .vpex-top .v--billboard,
  #vpex_default .vpex-top .v--map,
  #vpex_default .vpex-top .v--project, #vpex_default .vpex-main-content .v--billboard,
  #vpex_default .vpex-main-content .v--map,
  #vpex_default .vpex-main-content .v--project, #vpex_default .vpex-projects .v--billboard,
  #vpex_default .vpex-projects .v--map,
  #vpex_default .vpex-projects .v--project {
    max-width: 99%;
  }
}

#vpex_default .vpex-top .v--billboard img,
#vpex_default .vpex-top .v--map img,
#vpex_default .vpex-top .v--project img, #vpex_default .vpex-main-content .v--billboard img,
#vpex_default .vpex-main-content .v--map img,
#vpex_default .vpex-main-content .v--project img, #vpex_default .vpex-projects .v--billboard img,
#vpex_default .vpex-projects .v--map img,
#vpex_default .vpex-projects .v--project img {
  width: 100%;
}

#vpex_default .vpex-top {
  position: relative;
  overflow: hidden;
  padding-top: 50px;
}

@media (max-width: 767px) {
  #vpex_default .vpex-top {
    height: 800px;
  }
}

@media (max-width: 490px) {
  #vpex_default .vpex-top {
    height: 655px;
  }
}

@media (max-width: 425px) {
  #vpex_default .vpex-top {
    height: 580px;
  }
}

@media (max-width: 375px) {
  #vpex_default .vpex-top {
    height: 510px;
  }
}

@media (max-width: 320px) {
  #vpex_default .vpex-top {
    height: 445px;
  }
}

@media (max-width: 768px) and (min-width: 768px) {
  #vpex_default .vpex-top {
    height: 1000px;
  }
}

@media (min-width: 1024px) {
  #vpex_default .vpex-top {
    height: 1300px;
  }
}

@media (min-width: 1440px) {
  #vpex_default .vpex-top {
    height: 1540px;
  }
}

@media (min-width: 1564px) {
  #vpex_default .vpex-top {
    height: 1600px;
  }
}

@media (min-width: 1564px) {
  #vpex_default .vpex-top {
    height: 1600px;
  }
}

@media (min-width: 1900px) {
  #vpex_default .vpex-top {
    height: 1696px;
  }
}

@media (min-width: 2256px) {
  #vpex_default .vpex-top {
    height: 1836px;
  }
}

#vpex_default .vpex-top .v--city {
  position: absolute;
  bottom: 0;
  z-index: 11;
}

@media (min-width: 1900px) {
  #vpex_default .vpex-top .v--city {
    width: 100%;
  }
}

#vpex_default .vpex-top .v--city img {
  max-width: 100%;
}

@media (min-width: 1900px) {
  #vpex_default .vpex-top .v--city img {
    width: 100%;
  }
}

#vpex_default .vpex-top .v--billboard {
  top: -15px;
  z-index: 10;
}

#vpex_default .vpex-main-content {
  background: url(https://media.edgeprop.my/page/vpex/2021/bg_green.png) no-repeat;
  background-size: cover;
}

#vpex_default .vpex-main-content .v--map {
  height: auto;
  z-index: 12;
  top: -240px;
}

@media (max-width: 767px) {
  #vpex_default .vpex-main-content .v--map {
    top: -85px;
  }
}

#vpex_default .vpex-main-content .v--map .vpex-auditorium {
  position: absolute;
  background: transparent;
  animation: blinker 1s linear infinite;
  border: 3px solid #462b5a;
  width: 20.3%;
  height: 3.8%;
  margin-top: 21%;
  margin-left: 3%;
}

#vpex_default .vpex-main-content .v--map img#vpex-incentives {
  position: absolute;
  width: 50.3%;
  height: auto;
  margin-top: 14%;
  margin-left: 3%;
}

#vpex_default .vpex-main-content .v--map .btn-loancheck {
  position: absolute;
  background: transparent;
  width: 50%;
  height: 9%;
  margin-top: 52%;
  margin-left: 46%;
}

@media (min-width: 1024px) {
  #vpex_default .vpex-main-content .v--map .btn-loancheck {
    height: 284px;
  }
}

#vpex_default .vpex-main-content .v--map .btn-loancheck:hover {
  background: rgba(255, 255, 255, 0.5);
}

#vpex_default .vpex-main-content .v--map .dot-container {
  width: 1080px;
}

#vpex_default .vpex-main-content .v--map .dot-container .project-button {
  background: transparent;
  width: 21%;
  height: 1.4%;
  border-radius: 41px;
  position: absolute;
}

#vpex_default .vpex-main-content .v--map .dot-container .project-button.setia-skyseputeh {
  margin-top: 60.4%;
  margin-left: 26.9%;
}

#vpex_default .vpex-main-content .v--map .dot-container .project-button.melodia2 {
  margin-top: 91%;
  margin-left: 44.9%;
}

#vpex_default .vpex-main-content .v--map .dot-container .project-button.clover {
  margin-top: 18%;
  margin-left: 56.9%;
}

#vpex_default .vpex-main-content .v--map .dot-container .project-button.ipark {
  margin-top: 66.4%;
  margin-left: 13.9%;
}

#vpex_default .vpex-main-content .v--map .dot-container .project-button.dutavilla {
  margin-top: 83.4%;
  margin-left: 49.7%;
}

#vpex_default .vpex-main-content .v--map .dot-container .project-button.aura {
  margin-top: 97.3%;
  margin-left: 72.2%;
}

#vpex_default .vpex-main-content .v--map .dot-container .project-button.florarosa {
  margin-top: 137.1%;
  margin-left: 7.3%;
}

#vpex_default .vpex-main-content .v--map .dot-container .project-button.augusta {
  margin-top: 147.1%;
  margin-left: 21.3%;
}

#vpex_default .vpex-main-content .v--map .dot-container .project-button.clover, #vpex_default .vpex-main-content .v--map .dot-container .project-button.ipark,
#vpex_default .vpex-main-content .v--map .dot-container .project-button.dutavilla #vpex_default .vpex-main-content .v--map .dot-container .project-button.aura, #vpex_default .vpex-main-content .v--map .dot-container .project-button.florarosa, #vpex_default .vpex-main-content .v--map .dot-container .project-button.augusta {
  width: 24%;
  height: 1.8%;
}

#vpex_default .vpex-main-content .v--map .dot-container img {
  position: absolute;
  width: 7%;
  height: auto;
  animation-name: dot-floating;
  animation-duration: 1.5s;
  animation-iteration-count: infinite;
  animation-timing-function: ease-in-out;
  margin-left: 30px;
  margin-top: 5px;
}

@keyframes dot-floating {
  0% {
    transform: translate(0, 0px);
  }
  50% {
    transform: translate(0, 15px);
  }
  100% {
    transform: translate(0, 0px);
  }
}

#vpex_default .vpex-main-content .v--map .dot-container img.dot-skyseputeh {
  margin-top: 53%;
  margin-left: 35%;
}

#vpex_default .vpex-main-content .v--map .dot-container img.dot-melodia2 {
  animation-name: dot-floating-2;
  animation-duration: 1.8s;
  animation-iteration-count: infinite;
  animation-timing-function: ease-in-out;
  margin-left: 30px;
  margin-top: 5px;
  margin-top: 83%;
  margin-left: 52%;
}

@keyframes dot-floating-2 {
  0% {
    transform: translate(0, 0px);
  }
  50% {
    transform: translate(0, 20px);
  }
  100% {
    transform: translate(0, 0px);
  }
}

#vpex_default .vpex-main-content .v--map .dot-container img.dot-clover {
  margin-top: 10%;
  margin-left: 66%;
}

#vpex_default .vpex-main-content .v--map .dot-container img.dot-ipark {
  animation-name: dot-floating-2;
  animation-duration: 1.8s;
  animation-iteration-count: infinite;
  animation-timing-function: ease-in-out;
  margin-left: 30px;
  margin-top: 5px;
  margin-top: 58%;
  margin-left: 23%;
}

@keyframes dot-floating-2 {
  0% {
    transform: translate(0, 0px);
  }
  50% {
    transform: translate(0, 20px);
  }
  100% {
    transform: translate(0, 0px);
  }
}

#vpex_default .vpex-main-content .v--map .dot-container img.dot-putra-dutavilla {
  animation-name: dot-floating-3;
  animation-duration: 2.4s;
  animation-iteration-count: infinite;
  animation-timing-function: ease-in-out;
  margin-left: 30px;
  margin-top: 5px;
  margin-top: 75%;
  margin-left: 59%;
}

@keyframes dot-floating-3 {
  0% {
    transform: translate(0, 0px);
  }
  50% {
    transform: translate(0, 20px);
  }
  100% {
    transform: translate(0, 0px);
  }
}

#vpex_default .vpex-main-content .v--map .dot-container img.dot-putra-aura {
  margin-top: 90%;
  margin-left: 82%;
}

#vpex_default .vpex-main-content .v--map .dot-container img.dot-putra-florarosa {
  animation-name: dot-floating-2;
  animation-duration: 1.8s;
  animation-iteration-count: infinite;
  animation-timing-function: ease-in-out;
  margin-left: 30px;
  margin-top: 5px;
  margin-top: 129.5%;
  margin-left: 17%;
}

@keyframes dot-floating-2 {
  0% {
    transform: translate(0, 0px);
  }
  50% {
    transform: translate(0, 20px);
  }
  100% {
    transform: translate(0, 0px);
  }
}

#vpex_default .vpex-main-content .v--map .dot-container img.dot-putra-augusta {
  animation-name: dot-floating-3;
  animation-duration: 2.4s;
  animation-iteration-count: infinite;
  animation-timing-function: ease-in-out;
  margin-left: 30px;
  margin-top: 5px;
  margin-top: 140%;
  margin-left: 31%;
}

@keyframes dot-floating-3 {
  0% {
    transform: translate(0, 0px);
  }
  50% {
    transform: translate(0, 20px);
  }
  100% {
    transform: translate(0, 0px);
  }
}

#vpex_default .vpex-projects {
  padding: 20px 0;
  background: #b5ef4f;
}

@media (max-width: 767px) {
  #vpex_default .vpex-projects {
    padding: 10px;
  }
}

#vpex_default .vpex-projects .v--project {
  background: transparent;
  min-height: 900px;
  max-width: 750px;
  margin-bottom: 50px;
}

@media (max-width: 767px) {
  #vpex_default .vpex-projects .v--project {
    min-height: 750px;
  }
}

@media (max-width: 490px) {
  #vpex_default .vpex-projects .v--project {
    min-height: 515px;
  }
}

@media (max-width: 425px) {
  #vpex_default .vpex-projects .v--project {
    min-height: 420px;
  }
}

@media (max-width: 320px) {
  #vpex_default .vpex-projects .v--project {
    min-height: 310px;
  }
}

#vpex_default .vpex-projects .v--project .menu-container img {
  cursor: pointer;
  position: absolute;
  width: 80px;
  height: auto;
  animation-name: dot-floating;
  animation-duration: 1.5s;
  animation-iteration-count: infinite;
  animation-timing-function: ease-in-out;
  margin-left: 30px;
  margin-top: 5px;
}

@media (max-width: 767px) {
  #vpex_default .vpex-projects .v--project .menu-container img {
    width: 54px;
  }
}

@media (max-width: 375px) {
  #vpex_default .vpex-projects .v--project .menu-container img {
    width: 47px;
  }
}

@keyframes dot-floating {
  0% {
    transform: translate(0, 0px);
  }
  50% {
    transform: translate(0, 15px);
  }
  100% {
    transform: translate(0, 0px);
  }
}

#vpex_default .vpex-projects .v--project .menu-container img#menu-skyseputeh-gallery {
  top: 87px;
  left: 77px;
  animation-name: dot-floating-2;
  animation-duration: 1.8s;
  animation-iteration-count: infinite;
  animation-timing-function: ease-in-out;
  margin-left: 30px;
  margin-top: 5px;
}

@keyframes dot-floating-2 {
  0% {
    transform: translate(0, 0px);
  }
  50% {
    transform: translate(0, 20px);
  }
  100% {
    transform: translate(0, 0px);
  }
}

@media (max-width: 767px) {
  #vpex_default .vpex-projects .v--project .menu-container img#menu-skyseputeh-gallery {
    top: 64px;
    left: 60px;
  }
}

@media (max-width: 490px) {
  #vpex_default .vpex-projects .v--project .menu-container img#menu-skyseputeh-gallery {
    top: 33px;
    left: 33px;
  }
}

@media (max-width: 425px) {
  #vpex_default .vpex-projects .v--project .menu-container img#menu-skyseputeh-gallery {
    top: 20px;
    left: 16px;
  }
}

@media (max-width: 375px) {
  #vpex_default .vpex-projects .v--project .menu-container img#menu-skyseputeh-gallery {
    top: 16px;
    left: 12px;
  }
}

@media (max-width: 320px) {
  #vpex_default .vpex-projects .v--project .menu-container img#menu-skyseputeh-gallery {
    top: 1px;
    left: -1px;
  }
}

#vpex_default .vpex-projects .v--project .menu-container img#menu-skyseputeh-tour {
  top: 10%;
  left: 62%;
}

@media (max-width: 767px) {
  #vpex_default .vpex-projects .v--project .menu-container img#menu-skyseputeh-tour {
    top: 9%;
  }
}

@media (max-width: 490px) {
  #vpex_default .vpex-projects .v--project .menu-container img#menu-skyseputeh-tour {
    top: 9%;
    left: 60%;
  }
}

@media (max-width: 425px) {
  #vpex_default .vpex-projects .v--project .menu-container img#menu-skyseputeh-tour {
    top: 7%;
    left: 58%;
  }
}

@media (max-width: 375px) {
  #vpex_default .vpex-projects .v--project .menu-container img#menu-skyseputeh-tour {
    top: 5%;
    left: 57%;
  }
}

@media (max-width: 320px) {
  #vpex_default .vpex-projects .v--project .menu-container img#menu-skyseputeh-tour {
    top: 3%;
    left: 54%;
  }
}

#vpex_default .vpex-projects .v--project .menu-container img#menu-skyseputeh-projects {
  top: 10%;
  left: 83%;
  animation-name: dot-floating-3;
  animation-duration: 2.4s;
  animation-iteration-count: infinite;
  animation-timing-function: ease-in-out;
  margin-left: 30px;
  margin-top: 5px;
}

@keyframes dot-floating-3 {
  0% {
    transform: translate(0, 0px);
  }
  50% {
    transform: translate(0, 20px);
  }
  100% {
    transform: translate(0, 0px);
  }
}

@media (max-width: 767px) {
  #vpex_default .vpex-projects .v--project .menu-container img#menu-skyseputeh-projects {
    top: 9%;
    left: 82%;
  }
}

@media (max-width: 490px) {
  #vpex_default .vpex-projects .v--project .menu-container img#menu-skyseputeh-projects {
    top: 8%;
    left: 79%;
  }
}

@media (max-width: 425px) {
  #vpex_default .vpex-projects .v--project .menu-container img#menu-skyseputeh-projects {
    top: 7%;
    left: 75%;
  }
}

@media (max-width: 375px) {
  #vpex_default .vpex-projects .v--project .menu-container img#menu-skyseputeh-projects {
    top: 5%;
  }
}

@media (max-width: 320px) {
  #vpex_default .vpex-projects .v--project .menu-container img#menu-skyseputeh-projects {
    top: 4%;
    left: 72%;
  }
}

#vpex_default .vpex-projects .v--project .menu-container img#menu-skyseputeh-chat {
  top: 46%;
  left: 32%;
}

@media (max-width: 767px) {
  #vpex_default .vpex-projects .v--project .menu-container img#menu-skyseputeh-chat {
    top: 44%;
    left: 34%;
  }
}

@media (max-width: 490px) {
  #vpex_default .vpex-projects .v--project .menu-container img#menu-skyseputeh-chat {
    top: 50%;
    left: 31%;
  }
}

@media (max-width: 425px) {
  #vpex_default .vpex-projects .v--project .menu-container img#menu-skyseputeh-chat {
    top: 51%;
    left: 29%;
  }
}

@media (max-width: 375px) {
  #vpex_default .vpex-projects .v--project .menu-container img#menu-skyseputeh-chat {
    top: 43%;
    left: 28%;
  }
}

@media (max-width: 320px) {
  #vpex_default .vpex-projects .v--project .menu-container img#menu-skyseputeh-chat {
    top: 46%;
    left: 24%;
  }
}

#vpex_default .vpex-projects .v--project .menu-container img#menu-skyseputeh-about {
  top: 55%;
  left: 51%;
  animation-name: dot-floating-2;
  animation-duration: 1.8s;
  animation-iteration-count: infinite;
  animation-timing-function: ease-in-out;
  margin-left: 30px;
  margin-top: 5px;
}

@keyframes dot-floating-2 {
  0% {
    transform: translate(0, 0px);
  }
  50% {
    transform: translate(0, 20px);
  }
  100% {
    transform: translate(0, 0px);
  }
}

@media (max-width: 767px) {
  #vpex_default .vpex-projects .v--project .menu-container img#menu-skyseputeh-about {
    top: 53%;
  }
}

@media (max-width: 490px) {
  #vpex_default .vpex-projects .v--project .menu-container img#menu-skyseputeh-about {
    top: 58%;
    left: 48%;
  }
}

@media (max-width: 425px) {
  #vpex_default .vpex-projects .v--project .menu-container img#menu-skyseputeh-about {
    top: 59%;
    left: 46%;
  }
}

@media (max-width: 375px) {
  #vpex_default .vpex-projects .v--project .menu-container img#menu-skyseputeh-about {
    top: 53%;
    left: 45%;
  }
}

@media (max-width: 320px) {
  #vpex_default .vpex-projects .v--project .menu-container img#menu-skyseputeh-about {
    top: 55%;
    left: 42%;
  }
}

#vpex_default .vpex-projects .v--project .menu-container img#menu-skyseputeh-brochure {
  top: 58%;
  left: 80%;
  animation-name: dot-floating-3;
  animation-duration: 2.4s;
  animation-iteration-count: infinite;
  animation-timing-function: ease-in-out;
  margin-left: 30px;
  margin-top: 5px;
}

@keyframes dot-floating-3 {
  0% {
    transform: translate(0, 0px);
  }
  50% {
    transform: translate(0, 20px);
  }
  100% {
    transform: translate(0, 0px);
  }
}

@media (max-width: 767px) {
  #vpex_default .vpex-projects .v--project .menu-container img#menu-skyseputeh-brochure {
    top: 50%;
    left: 78%;
  }
}

@media (max-width: 490px) {
  #vpex_default .vpex-projects .v--project .menu-container img#menu-skyseputeh-brochure {
    top: 60%;
    left: 75%;
  }
}

@media (max-width: 425px) {
  #vpex_default .vpex-projects .v--project .menu-container img#menu-skyseputeh-brochure {
    top: 63%;
    left: 73%;
  }
}

@media (max-width: 375px) {
  #vpex_default .vpex-projects .v--project .menu-container img#menu-skyseputeh-brochure {
    top: 54%;
    left: 71%;
  }
}

@media (max-width: 320px) {
  #vpex_default .vpex-projects .v--project .menu-container img#menu-skyseputeh-brochure {
    top: 60%;
    left: 69%;
  }
}

#vpex_default .vpex-projects .v--project .menu-container img#menu-melodia2-gallery {
  top: 115px;
  left: 30px;
  animation-name: dot-floating-2;
  animation-duration: 1.8s;
  animation-iteration-count: infinite;
  animation-timing-function: ease-in-out;
  margin-left: 30px;
  margin-top: 5px;
}

@keyframes dot-floating-2 {
  0% {
    transform: translate(0, 0px);
  }
  50% {
    transform: translate(0, 20px);
  }
  100% {
    transform: translate(0, 0px);
  }
}

@media (max-width: 767px) {
  #vpex_default .vpex-projects .v--project .menu-container img#menu-melodia2-gallery {
    top: 89px;
  }
}

@media (max-width: 490px) {
  #vpex_default .vpex-projects .v--project .menu-container img#menu-melodia2-gallery {
    top: 55px;
    left: -1px;
  }
}

@media (max-width: 425px) {
  #vpex_default .vpex-projects .v--project .menu-container img#menu-melodia2-gallery {
    top: 39px;
    left: -12px;
  }
}

@media (max-width: 375px) {
  #vpex_default .vpex-projects .v--project .menu-container img#menu-melodia2-gallery {
    top: 29px;
  }
}

@media (max-width: 320px) {
  #vpex_default .vpex-projects .v--project .menu-container img#menu-melodia2-gallery {
    top: 19px;
    left: -20px;
  }
}

#vpex_default .vpex-projects .v--project .menu-container img#menu-melodia2-tour {
  top: 10%;
  left: 62%;
}

@media (max-width: 767px) {
  #vpex_default .vpex-projects .v--project .menu-container img#menu-melodia2-tour {
    top: 10%;
  }
}

@media (max-width: 490px) {
  #vpex_default .vpex-projects .v--project .menu-container img#menu-melodia2-tour {
    left: 61%;
  }
}

@media (max-width: 425px) {
  #vpex_default .vpex-projects .v--project .menu-container img#menu-melodia2-tour {
    top: 7%;
    left: 59%;
  }
}

@media (max-width: 320px) {
  #vpex_default .vpex-projects .v--project .menu-container img#menu-melodia2-tour {
    top: 0;
    left: 54%;
  }
}

#vpex_default .vpex-projects .v--project .menu-container img#menu-melodia2-projects {
  top: 7%;
  left: 83%;
  animation-name: dot-floating-3;
  animation-duration: 2.4s;
  animation-iteration-count: infinite;
  animation-timing-function: ease-in-out;
  margin-left: 30px;
  margin-top: 5px;
}

@keyframes dot-floating-3 {
  0% {
    transform: translate(0, 0px);
  }
  50% {
    transform: translate(0, 20px);
  }
  100% {
    transform: translate(0, 0px);
  }
}

@media (max-width: 767px) {
  #vpex_default .vpex-projects .v--project .menu-container img#menu-melodia2-projects {
    top: 6%;
    left: 82%;
  }
}

@media (max-width: 490px) {
  #vpex_default .vpex-projects .v--project .menu-container img#menu-melodia2-projects {
    left: 77%;
  }
}

@media (max-width: 425px) {
  #vpex_default .vpex-projects .v--project .menu-container img#menu-melodia2-projects {
    top: 4%;
    left: 76%;
  }
}

@media (max-width: 375px) {
  #vpex_default .vpex-projects .v--project .menu-container img#menu-melodia2-projects {
    top: 3%;
  }
}

@media (max-width: 320px) {
  #vpex_default .vpex-projects .v--project .menu-container img#menu-melodia2-projects {
    top: -1%;
    left: 72%;
  }
}

#vpex_default .vpex-projects .v--project .menu-container img#menu-melodia2-chat {
  top: 46%;
  left: 32%;
}

@media (max-width: 767px) {
  #vpex_default .vpex-projects .v--project .menu-container img#menu-melodia2-chat {
    top: 45%;
    left: 34%;
  }
}

@media (max-width: 490px) {
  #vpex_default .vpex-projects .v--project .menu-container img#menu-melodia2-chat {
    top: 49%;
    left: 29%;
  }
}

@media (max-width: 375px) {
  #vpex_default .vpex-projects .v--project .menu-container img#menu-melodia2-chat {
    top: 43%;
    left: 27%;
  }
}

@media (max-width: 320px) {
  #vpex_default .vpex-projects .v--project .menu-container img#menu-melodia2-chat {
    top: 47%;
    left: 23%;
  }
}

#vpex_default .vpex-projects .v--project .menu-container img#menu-melodia2-about {
  top: 55%;
  left: 51%;
  animation-name: dot-floating-2;
  animation-duration: 1.8s;
  animation-iteration-count: infinite;
  animation-timing-function: ease-in-out;
  margin-left: 30px;
  margin-top: 5px;
}

@keyframes dot-floating-2 {
  0% {
    transform: translate(0, 0px);
  }
  50% {
    transform: translate(0, 20px);
  }
  100% {
    transform: translate(0, 0px);
  }
}

@media (max-width: 767px) {
  #vpex_default .vpex-projects .v--project .menu-container img#menu-melodia2-about {
    top: 52%;
  }
}

@media (max-width: 490px) {
  #vpex_default .vpex-projects .v--project .menu-container img#menu-melodia2-about {
    top: 58%;
    left: 48%;
  }
}

@media (max-width: 425px) {
  #vpex_default .vpex-projects .v--project .menu-container img#menu-melodia2-about {
    top: 59%;
    left: 46%;
  }
}

@media (max-width: 375px) {
  #vpex_default .vpex-projects .v--project .menu-container img#menu-melodia2-about {
    top: 52%;
  }
}

@media (max-width: 320px) {
  #vpex_default .vpex-projects .v--project .menu-container img#menu-melodia2-about {
    top: 54%;
    left: 43%;
  }
}

#vpex_default .vpex-projects .v--project .menu-container img#menu-melodia2-brochure {
  top: 55%;
  left: 80%;
  animation-name: dot-floating-3;
  animation-duration: 2.4s;
  animation-iteration-count: infinite;
  animation-timing-function: ease-in-out;
  margin-left: 30px;
  margin-top: 5px;
}

@keyframes dot-floating-3 {
  0% {
    transform: translate(0, 0px);
  }
  50% {
    transform: translate(0, 20px);
  }
  100% {
    transform: translate(0, 0px);
  }
}

@media (max-width: 767px) {
  #vpex_default .vpex-projects .v--project .menu-container img#menu-melodia2-brochure {
    top: 50%;
  }
}

@media (max-width: 490px) {
  #vpex_default .vpex-projects .v--project .menu-container img#menu-melodia2-brochure {
    top: 57%;
    left: 77%;
  }
}

@media (max-width: 425px) {
  #vpex_default .vpex-projects .v--project .menu-container img#menu-melodia2-brochure {
    top: 59%;
    left: 74%;
  }
}

@media (max-width: 375px) {
  #vpex_default .vpex-projects .v--project .menu-container img#menu-melodia2-brochure {
    top: 51%;
    left: 73%;
  }
}

@media (max-width: 320px) {
  #vpex_default .vpex-projects .v--project .menu-container img#menu-melodia2-brochure {
    top: 59%;
    left: 70%;
  }
}

#vpex_default .vpex-projects .v--project .menu-container img#menu-clover-gallery {
  top: 87px;
  left: 53px;
  animation-name: dot-floating-2;
  animation-duration: 1.8s;
  animation-iteration-count: infinite;
  animation-timing-function: ease-in-out;
  margin-left: 30px;
  margin-top: 5px;
}

@keyframes dot-floating-2 {
  0% {
    transform: translate(0, 0px);
  }
  50% {
    transform: translate(0, 20px);
  }
  100% {
    transform: translate(0, 0px);
  }
}

@media (max-width: 767px) {
  #vpex_default .vpex-projects .v--project .menu-container img#menu-clover-gallery {
    top: 64px;
    left: 40px;
  }
}

@media (max-width: 490px) {
  #vpex_default .vpex-projects .v--project .menu-container img#menu-clover-gallery {
    top: 33px;
    left: 20px;
  }
}

@media (max-width: 425px) {
  #vpex_default .vpex-projects .v--project .menu-container img#menu-clover-gallery {
    top: 20px;
    left: 7px;
  }
}

@media (max-width: 375px) {
  #vpex_default .vpex-projects .v--project .menu-container img#menu-clover-gallery {
    top: 16px;
    left: 3px;
  }
}

@media (max-width: 320px) {
  #vpex_default .vpex-projects .v--project .menu-container img#menu-clover-gallery {
    top: 1px;
    left: -8px;
  }
}

#vpex_default .vpex-projects .v--project .menu-container img#menu-clover-tour {
  top: 10%;
  left: 68%;
}

@media (max-width: 767px) {
  #vpex_default .vpex-projects .v--project .menu-container img#menu-clover-tour {
    top: 9%;
    left: 66%;
  }
}

@media (max-width: 490px) {
  #vpex_default .vpex-projects .v--project .menu-container img#menu-clover-tour {
    top: 8%;
    left: 64%;
  }
}

@media (max-width: 425px) {
  #vpex_default .vpex-projects .v--project .menu-container img#menu-clover-tour {
    top: 7%;
    left: 61%;
  }
}

@media (max-width: 375px) {
  #vpex_default .vpex-projects .v--project .menu-container img#menu-clover-tour {
    top: 4%;
    left: 61%;
  }
}

@media (max-width: 320px) {
  #vpex_default .vpex-projects .v--project .menu-container img#menu-clover-tour {
    top: 2%;
    left: 58%;
  }
}

#vpex_default .vpex-projects .v--project .menu-container img#menu-clover-projects {
  top: 12%;
  left: 81%;
  animation-name: dot-floating-3;
  animation-duration: 2.4s;
  animation-iteration-count: infinite;
  animation-timing-function: ease-in-out;
  margin-left: 30px;
  margin-top: 5px;
}

@keyframes dot-floating-3 {
  0% {
    transform: translate(0, 0px);
  }
  50% {
    transform: translate(0, 20px);
  }
  100% {
    transform: translate(0, 0px);
  }
}

@media (max-width: 767px) {
  #vpex_default .vpex-projects .v--project .menu-container img#menu-clover-projects {
    top: 10%;
    left: 79%;
  }
}

@media (max-width: 490px) {
  #vpex_default .vpex-projects .v--project .menu-container img#menu-clover-projects {
    top: 12%;
    left: 77%;
  }
}

@media (max-width: 425px) {
  #vpex_default .vpex-projects .v--project .menu-container img#menu-clover-projects {
    top: 9%;
  }
}

@media (max-width: 375px) {
  #vpex_default .vpex-projects .v--project .menu-container img#menu-clover-projects {
    top: 6%;
    left: 75%;
  }
}

@media (max-width: 320px) {
  #vpex_default .vpex-projects .v--project .menu-container img#menu-clover-projects {
    top: 2%;
    left: 73%;
  }
}

#vpex_default .vpex-projects .v--project .menu-container img#menu-clover-chat {
  top: 42%;
  left: 37%;
}

@media (max-width: 767px) {
  #vpex_default .vpex-projects .v--project .menu-container img#menu-clover-chat {
    top: 40%;
  }
}

@media (max-width: 490px) {
  #vpex_default .vpex-projects .v--project .menu-container img#menu-clover-chat {
    top: 44%;
    left: 33%;
  }
}

@media (max-width: 425px) {
  #vpex_default .vpex-projects .v--project .menu-container img#menu-clover-chat {
    left: 31%;
  }
}

@media (max-width: 375px) {
  #vpex_default .vpex-projects .v--project .menu-container img#menu-clover-chat {
    top: 39%;
  }
}

@media (max-width: 320px) {
  #vpex_default .vpex-projects .v--project .menu-container img#menu-clover-chat {
    top: 44%;
    left: 27%;
  }
}

#vpex_default .vpex-projects .v--project .menu-container img#menu-clover-about {
  top: 49%;
  left: 56%;
  animation-name: dot-floating-2;
  animation-duration: 1.8s;
  animation-iteration-count: infinite;
  animation-timing-function: ease-in-out;
  margin-left: 30px;
  margin-top: 5px;
}

@keyframes dot-floating-2 {
  0% {
    transform: translate(0, 0px);
  }
  50% {
    transform: translate(0, 20px);
  }
  100% {
    transform: translate(0, 0px);
  }
}

@media (max-width: 767px) {
  #vpex_default .vpex-projects .v--project .menu-container img#menu-clover-about {
    top: 48%;
  }
}

@media (max-width: 490px) {
  #vpex_default .vpex-projects .v--project .menu-container img#menu-clover-about {
    top: 55%;
    left: 52%;
  }
}

@media (max-width: 425px) {
  #vpex_default .vpex-projects .v--project .menu-container img#menu-clover-about {
    top: 55%;
    left: 50%;
  }
}

@media (max-width: 375px) {
  #vpex_default .vpex-projects .v--project .menu-container img#menu-clover-about {
    top: 46%;
    left: 47%;
  }
}

@media (max-width: 320px) {
  #vpex_default .vpex-projects .v--project .menu-container img#menu-clover-about {
    top: 53%;
    left: 47%;
  }
}

#vpex_default .vpex-projects .v--project .menu-container img#menu-clover-brochure {
  top: 55%;
  left: 80%;
  animation-name: dot-floating-3;
  animation-duration: 2.4s;
  animation-iteration-count: infinite;
  animation-timing-function: ease-in-out;
  margin-left: 30px;
  margin-top: 5px;
}

@keyframes dot-floating-3 {
  0% {
    transform: translate(0, 0px);
  }
  50% {
    transform: translate(0, 20px);
  }
  100% {
    transform: translate(0, 0px);
  }
}

@media (max-width: 767px) {
  #vpex_default .vpex-projects .v--project .menu-container img#menu-clover-brochure {
    top: 51%;
  }
}

@media (max-width: 490px) {
  #vpex_default .vpex-projects .v--project .menu-container img#menu-clover-brochure {
    top: 58%;
  }
}

@media (max-width: 425px) {
  #vpex_default .vpex-projects .v--project .menu-container img#menu-clover-brochure {
    left: 78%;
  }
}

@media (max-width: 375px) {
  #vpex_default .vpex-projects .v--project .menu-container img#menu-clover-brochure {
    top: 51%;
  }
}

@media (max-width: 320px) {
  #vpex_default .vpex-projects .v--project .menu-container img#menu-clover-brochure {
    top: 60%;
    left: 73%;
  }
}

#vpex_default .vpex-projects .v--project .menu-container img#menu-ipark-gallery {
  top: 7%;
  left: 1%;
  animation-name: dot-floating-2;
  animation-duration: 1.8s;
  animation-iteration-count: infinite;
  animation-timing-function: ease-in-out;
  margin-left: 30px;
  margin-top: 5px;
}

@keyframes dot-floating-2 {
  0% {
    transform: translate(0, 0px);
  }
  50% {
    transform: translate(0, 20px);
  }
  100% {
    transform: translate(0, 0px);
  }
}

@media (max-width: 490px) {
  #vpex_default .vpex-projects .v--project .menu-container img#menu-ipark-gallery {
    top: 5%;
    left: -2%;
  }
}

@media (max-width: 425px) {
  #vpex_default .vpex-projects .v--project .menu-container img#menu-ipark-gallery {
    top: 4%;
    left: -4%;
  }
}

@media (max-width: 320px) {
  #vpex_default .vpex-projects .v--project .menu-container img#menu-ipark-gallery {
    left: -8%;
  }
}

#vpex_default .vpex-projects .v--project .menu-container img#menu-ipark-tour {
  top: 12%;
  left: 65%;
}

@media (max-width: 490px) {
  #vpex_default .vpex-projects .v--project .menu-container img#menu-ipark-tour {
    top: 10%;
    left: 62%;
  }
}

@media (max-width: 375px) {
  #vpex_default .vpex-projects .v--project .menu-container img#menu-ipark-tour {
    top: 7%;
    left: 59%;
  }
}

#vpex_default .vpex-projects .v--project .menu-container img#menu-ipark-projects {
  top: 12%;
  left: 81%;
  animation-name: dot-floating-3;
  animation-duration: 2.4s;
  animation-iteration-count: infinite;
  animation-timing-function: ease-in-out;
  margin-left: 30px;
  margin-top: 5px;
}

@keyframes dot-floating-3 {
  0% {
    transform: translate(0, 0px);
  }
  50% {
    transform: translate(0, 20px);
  }
  100% {
    transform: translate(0, 0px);
  }
}

@media (max-width: 767px) {
  #vpex_default .vpex-projects .v--project .menu-container img#menu-ipark-projects {
    top: 10%;
    left: 79%;
  }
}

@media (max-width: 490px) {
  #vpex_default .vpex-projects .v--project .menu-container img#menu-ipark-projects {
    top: 12%;
    left: 77%;
  }
}

@media (max-width: 425px) {
  #vpex_default .vpex-projects .v--project .menu-container img#menu-ipark-projects {
    top: 9%;
  }
}

@media (max-width: 375px) {
  #vpex_default .vpex-projects .v--project .menu-container img#menu-ipark-projects {
    top: 6%;
    left: 75%;
  }
}

@media (max-width: 320px) {
  #vpex_default .vpex-projects .v--project .menu-container img#menu-ipark-projects {
    top: 2%;
    left: 73%;
  }
}

#vpex_default .vpex-projects .v--project .menu-container img#menu-ipark-chat {
  top: 47%;
  left: 34%;
}

@media (max-width: 767px) {
  #vpex_default .vpex-projects .v--project .menu-container img#menu-ipark-chat {
    top: 44%;
  }
}

@media (max-width: 490px) {
  #vpex_default .vpex-projects .v--project .menu-container img#menu-ipark-chat {
    top: 49%;
    left: 32%;
  }
}

@media (max-width: 425px) {
  #vpex_default .vpex-projects .v--project .menu-container img#menu-ipark-chat {
    top: 50%;
    left: 28%;
  }
}

@media (max-width: 375px) {
  #vpex_default .vpex-projects .v--project .menu-container img#menu-ipark-chat {
    top: 42%;
  }
}

@media (max-width: 320px) {
  #vpex_default .vpex-projects .v--project .menu-container img#menu-ipark-chat {
    top: 46%;
    left: 24%;
  }
}

#vpex_default .vpex-projects .v--project .menu-container img#menu-ipark-about {
  top: 54%;
  left: 51%;
  animation-name: dot-floating-2;
  animation-duration: 1.8s;
  animation-iteration-count: infinite;
  animation-timing-function: ease-in-out;
  margin-left: 30px;
  margin-top: 5px;
}

@keyframes dot-floating-2 {
  0% {
    transform: translate(0, 0px);
  }
  50% {
    transform: translate(0, 20px);
  }
  100% {
    transform: translate(0, 0px);
  }
}

@media (max-width: 767px) {
  #vpex_default .vpex-projects .v--project .menu-container img#menu-ipark-about {
    top: 52%;
  }
}

@media (max-width: 490px) {
  #vpex_default .vpex-projects .v--project .menu-container img#menu-ipark-about {
    top: 59%;
    left: 48%;
  }
}

@media (max-width: 425px) {
  #vpex_default .vpex-projects .v--project .menu-container img#menu-ipark-about {
    top: 60%;
    left: 46%;
  }
}

@media (max-width: 375px) {
  #vpex_default .vpex-projects .v--project .menu-container img#menu-ipark-about {
    top: 52%;
    left: 45%;
  }
}

@media (max-width: 320px) {
  #vpex_default .vpex-projects .v--project .menu-container img#menu-ipark-about {
    top: 55%;
    left: 42%;
  }
}

#vpex_default .vpex-projects .v--project .menu-container img#menu-ipark-brochure {
  top: 52%;
  left: 78%;
  animation-name: dot-floating-3;
  animation-duration: 2.4s;
  animation-iteration-count: infinite;
  animation-timing-function: ease-in-out;
  margin-left: 30px;
  margin-top: 5px;
}

@keyframes dot-floating-3 {
  0% {
    transform: translate(0, 0px);
  }
  50% {
    transform: translate(0, 20px);
  }
  100% {
    transform: translate(0, 0px);
  }
}

@media (max-width: 767px) {
  #vpex_default .vpex-projects .v--project .menu-container img#menu-ipark-brochure {
    top: 48%;
  }
}

@media (max-width: 490px) {
  #vpex_default .vpex-projects .v--project .menu-container img#menu-ipark-brochure {
    top: 56%;
    left: 74%;
  }
}

@media (max-width: 425px) {
  #vpex_default .vpex-projects .v--project .menu-container img#menu-ipark-brochure {
    top: 58%;
    left: 71%;
  }
}

@media (max-width: 375px) {
  #vpex_default .vpex-projects .v--project .menu-container img#menu-ipark-brochure {
    top: 49%;
    left: 70%;
  }
}

@media (max-width: 320px) {
  #vpex_default .vpex-projects .v--project .menu-container img#menu-ipark-brochure {
    top: 57%;
    left: 66%;
  }
}

#vpex_default .vpex-projects .v--project .menu-container img#menu-putra-dutavilla-gallery {
  top: 4%;
  left: 6%;
  animation-name: dot-floating-2;
  animation-duration: 1.8s;
  animation-iteration-count: infinite;
  animation-timing-function: ease-in-out;
  margin-left: 30px;
  margin-top: 5px;
}

@keyframes dot-floating-2 {
  0% {
    transform: translate(0, 0px);
  }
  50% {
    transform: translate(0, 20px);
  }
  100% {
    transform: translate(0, 0px);
  }
}

@media (max-width: 490px) {
  #vpex_default .vpex-projects .v--project .menu-container img#menu-putra-dutavilla-gallery {
    top: 2%;
    left: 2%;
  }
}

@media (max-width: 320px) {
  #vpex_default .vpex-projects .v--project .menu-container img#menu-putra-dutavilla-gallery {
    top: -3%;
  }
}

#vpex_default .vpex-projects .v--project .menu-container img#menu-putra-dutavilla-tour {
  top: 4%;
  left: 50%;
}

@media (max-width: 490px) {
  #vpex_default .vpex-projects .v--project .menu-container img#menu-putra-dutavilla-tour {
    top: 2%;
    left: 47%;
  }
}

@media (max-width: 425px) {
  #vpex_default .vpex-projects .v--project .menu-container img#menu-putra-dutavilla-tour {
    top: -1%;
    left: 44%;
  }
}

@media (max-width: 320px) {
  #vpex_default .vpex-projects .v--project .menu-container img#menu-putra-dutavilla-tour {
    left: 40%;
  }
}

#vpex_default .vpex-projects .v--project .menu-container img#menu-putra-dutavilla-projects {
  top: 19%;
  left: 68%;
  animation-name: dot-floating-3;
  animation-duration: 2.4s;
  animation-iteration-count: infinite;
  animation-timing-function: ease-in-out;
  margin-left: 30px;
  margin-top: 5px;
}

@keyframes dot-floating-3 {
  0% {
    transform: translate(0, 0px);
  }
  50% {
    transform: translate(0, 20px);
  }
  100% {
    transform: translate(0, 0px);
  }
}

@media (max-width: 490px) {
  #vpex_default .vpex-projects .v--project .menu-container img#menu-putra-dutavilla-projects {
    top: 16%;
    left: 65%;
  }
}

@media (max-width: 425px) {
  #vpex_default .vpex-projects .v--project .menu-container img#menu-putra-dutavilla-projects {
    left: 64%;
  }
}

@media (max-width: 375px) {
  #vpex_default .vpex-projects .v--project .menu-container img#menu-putra-dutavilla-projects {
    left: 63%;
  }
}

@media (max-width: 320px) {
  #vpex_default .vpex-projects .v--project .menu-container img#menu-putra-dutavilla-projects {
    left: 61%;
  }
}

#vpex_default .vpex-projects .v--project .menu-container img#menu-putra-dutavilla-chat {
  top: 47%;
  left: 32%;
}

@media (max-width: 767px) {
  #vpex_default .vpex-projects .v--project .menu-container img#menu-putra-dutavilla-chat {
    top: 44%;
  }
}

@media (max-width: 490px) {
  #vpex_default .vpex-projects .v--project .menu-container img#menu-putra-dutavilla-chat {
    top: 50%;
    left: 30%;
  }
}

@media (max-width: 425px) {
  #vpex_default .vpex-projects .v--project .menu-container img#menu-putra-dutavilla-chat {
    top: 50%;
    left: 28%;
  }
}

@media (max-width: 375px) {
  #vpex_default .vpex-projects .v--project .menu-container img#menu-putra-dutavilla-chat {
    top: 42%;
  }
}

@media (max-width: 320px) {
  #vpex_default .vpex-projects .v--project .menu-container img#menu-putra-dutavilla-chat {
    top: 46%;
    left: 24%;
  }
}

#vpex_default .vpex-projects .v--project .menu-container img#menu-putra-dutavilla-about {
  top: 57%;
  left: 48%;
  animation-name: dot-floating-2;
  animation-duration: 1.8s;
  animation-iteration-count: infinite;
  animation-timing-function: ease-in-out;
  margin-left: 30px;
  margin-top: 5px;
}

@keyframes dot-floating-2 {
  0% {
    transform: translate(0, 0px);
  }
  50% {
    transform: translate(0, 20px);
  }
  100% {
    transform: translate(0, 0px);
  }
}

@media (max-width: 767px) {
  #vpex_default .vpex-projects .v--project .menu-container img#menu-putra-dutavilla-about {
    top: 52%;
  }
}

@media (max-width: 490px) {
  #vpex_default .vpex-projects .v--project .menu-container img#menu-putra-dutavilla-about {
    top: 61%;
    left: 44%;
  }
}

@media (max-width: 425px) {
  #vpex_default .vpex-projects .v--project .menu-container img#menu-putra-dutavilla-about {
    top: 61%;
    left: 43%;
  }
}

@media (max-width: 375px) {
  #vpex_default .vpex-projects .v--project .menu-container img#menu-putra-dutavilla-about {
    top: 53%;
    left: 42%;
  }
}

@media (max-width: 320px) {
  #vpex_default .vpex-projects .v--project .menu-container img#menu-putra-dutavilla-about {
    top: 57%;
    left: 39%;
  }
}

#vpex_default .vpex-projects .v--project .menu-container img#menu-putra-dutavilla-brochure {
  top: 52%;
  left: 78%;
  animation-name: dot-floating-3;
  animation-duration: 2.4s;
  animation-iteration-count: infinite;
  animation-timing-function: ease-in-out;
  margin-left: 30px;
  margin-top: 5px;
}

@keyframes dot-floating-3 {
  0% {
    transform: translate(0, 0px);
  }
  50% {
    transform: translate(0, 20px);
  }
  100% {
    transform: translate(0, 0px);
  }
}

@media (max-width: 767px) {
  #vpex_default .vpex-projects .v--project .menu-container img#menu-putra-dutavilla-brochure {
    top: 48%;
  }
}

@media (max-width: 490px) {
  #vpex_default .vpex-projects .v--project .menu-container img#menu-putra-dutavilla-brochure {
    top: 56%;
    left: 74%;
  }
}

@media (max-width: 425px) {
  #vpex_default .vpex-projects .v--project .menu-container img#menu-putra-dutavilla-brochure {
    top: 58%;
    left: 71%;
  }
}

@media (max-width: 375px) {
  #vpex_default .vpex-projects .v--project .menu-container img#menu-putra-dutavilla-brochure {
    top: 49%;
    left: 70%;
  }
}

@media (max-width: 320px) {
  #vpex_default .vpex-projects .v--project .menu-container img#menu-putra-dutavilla-brochure {
    top: 57%;
    left: 66%;
  }
}

#vpex_default .vpex-projects .v--project .menu-container img#menu-putra-aura-gallery {
  top: 4%;
  left: 6%;
  animation-name: dot-floating-2;
  animation-duration: 1.8s;
  animation-iteration-count: infinite;
  animation-timing-function: ease-in-out;
  margin-left: 30px;
  margin-top: 5px;
}

@keyframes dot-floating-2 {
  0% {
    transform: translate(0, 0px);
  }
  50% {
    transform: translate(0, 20px);
  }
  100% {
    transform: translate(0, 0px);
  }
}

@media (max-width: 490px) {
  #vpex_default .vpex-projects .v--project .menu-container img#menu-putra-aura-gallery {
    top: 2%;
    left: 2%;
  }
}

@media (max-width: 320px) {
  #vpex_default .vpex-projects .v--project .menu-container img#menu-putra-aura-gallery {
    top: -3%;
  }
}

#vpex_default .vpex-projects .v--project .menu-container img#menu-putra-aura-tour {
  top: 4%;
  left: 50%;
}

@media (max-width: 490px) {
  #vpex_default .vpex-projects .v--project .menu-container img#menu-putra-aura-tour {
    top: 2%;
    left: 47%;
  }
}

@media (max-width: 425px) {
  #vpex_default .vpex-projects .v--project .menu-container img#menu-putra-aura-tour {
    top: -1%;
    left: 44%;
  }
}

@media (max-width: 320px) {
  #vpex_default .vpex-projects .v--project .menu-container img#menu-putra-aura-tour {
    left: 40%;
  }
}

#vpex_default .vpex-projects .v--project .menu-container img#menu-putra-aura-projects {
  top: 14%;
  left: 70%;
  animation-name: dot-floating-3;
  animation-duration: 2.4s;
  animation-iteration-count: infinite;
  animation-timing-function: ease-in-out;
  margin-left: 30px;
  margin-top: 5px;
}

@keyframes dot-floating-3 {
  0% {
    transform: translate(0, 0px);
  }
  50% {
    transform: translate(0, 20px);
  }
  100% {
    transform: translate(0, 0px);
  }
}

@media (max-width: 490px) {
  #vpex_default .vpex-projects .v--project .menu-container img#menu-putra-aura-projects {
    left: 66%;
  }
}

@media (max-width: 425px) {
  #vpex_default .vpex-projects .v--project .menu-container img#menu-putra-aura-projects {
    left: 64%;
  }
}

@media (max-width: 375px) {
  #vpex_default .vpex-projects .v--project .menu-container img#menu-putra-aura-projects {
    left: 63%;
  }
}

@media (max-width: 320px) {
  #vpex_default .vpex-projects .v--project .menu-container img#menu-putra-aura-projects {
    left: 60%;
  }
}

#vpex_default .vpex-projects .v--project .menu-container img#menu-putra-aura-chat {
  top: 47%;
  left: 32%;
}

@media (max-width: 767px) {
  #vpex_default .vpex-projects .v--project .menu-container img#menu-putra-aura-chat {
    top: 44%;
  }
}

@media (max-width: 490px) {
  #vpex_default .vpex-projects .v--project .menu-container img#menu-putra-aura-chat {
    top: 50%;
    left: 30%;
  }
}

@media (max-width: 425px) {
  #vpex_default .vpex-projects .v--project .menu-container img#menu-putra-aura-chat {
    top: 50%;
    left: 28%;
  }
}

@media (max-width: 375px) {
  #vpex_default .vpex-projects .v--project .menu-container img#menu-putra-aura-chat {
    top: 42%;
  }
}

@media (max-width: 320px) {
  #vpex_default .vpex-projects .v--project .menu-container img#menu-putra-aura-chat {
    top: 46%;
    left: 24%;
  }
}

#vpex_default .vpex-projects .v--project .menu-container img#menu-putra-aura-about {
  top: 57%;
  left: 48%;
  animation-name: dot-floating-2;
  animation-duration: 1.8s;
  animation-iteration-count: infinite;
  animation-timing-function: ease-in-out;
  margin-left: 30px;
  margin-top: 5px;
}

@keyframes dot-floating-2 {
  0% {
    transform: translate(0, 0px);
  }
  50% {
    transform: translate(0, 20px);
  }
  100% {
    transform: translate(0, 0px);
  }
}

@media (max-width: 767px) {
  #vpex_default .vpex-projects .v--project .menu-container img#menu-putra-aura-about {
    top: 52%;
  }
}

@media (max-width: 490px) {
  #vpex_default .vpex-projects .v--project .menu-container img#menu-putra-aura-about {
    top: 61%;
    left: 44%;
  }
}

@media (max-width: 425px) {
  #vpex_default .vpex-projects .v--project .menu-container img#menu-putra-aura-about {
    top: 61%;
    left: 43%;
  }
}

@media (max-width: 375px) {
  #vpex_default .vpex-projects .v--project .menu-container img#menu-putra-aura-about {
    top: 53%;
    left: 42%;
  }
}

@media (max-width: 320px) {
  #vpex_default .vpex-projects .v--project .menu-container img#menu-putra-aura-about {
    top: 57%;
    left: 39%;
  }
}

#vpex_default .vpex-projects .v--project .menu-container img#menu-putra-aura-brochure {
  top: 52%;
  left: 78%;
  animation-name: dot-floating-3;
  animation-duration: 2.4s;
  animation-iteration-count: infinite;
  animation-timing-function: ease-in-out;
  margin-left: 30px;
  margin-top: 5px;
}

@keyframes dot-floating-3 {
  0% {
    transform: translate(0, 0px);
  }
  50% {
    transform: translate(0, 20px);
  }
  100% {
    transform: translate(0, 0px);
  }
}

@media (max-width: 767px) {
  #vpex_default .vpex-projects .v--project .menu-container img#menu-putra-aura-brochure {
    top: 48%;
  }
}

@media (max-width: 490px) {
  #vpex_default .vpex-projects .v--project .menu-container img#menu-putra-aura-brochure {
    top: 56%;
    left: 74%;
  }
}

@media (max-width: 425px) {
  #vpex_default .vpex-projects .v--project .menu-container img#menu-putra-aura-brochure {
    top: 58%;
    left: 71%;
  }
}

@media (max-width: 375px) {
  #vpex_default .vpex-projects .v--project .menu-container img#menu-putra-aura-brochure {
    top: 49%;
    left: 70%;
  }
}

@media (max-width: 320px) {
  #vpex_default .vpex-projects .v--project .menu-container img#menu-putra-aura-brochure {
    top: 57%;
    left: 66%;
  }
}

#vpex_default .vpex-projects .v--project .menu-container img#menu-putra-florarosa-gallery {
  top: 4%;
  left: 6%;
  animation-name: dot-floating-2;
  animation-duration: 1.8s;
  animation-iteration-count: infinite;
  animation-timing-function: ease-in-out;
  margin-left: 30px;
  margin-top: 5px;
}

@keyframes dot-floating-2 {
  0% {
    transform: translate(0, 0px);
  }
  50% {
    transform: translate(0, 20px);
  }
  100% {
    transform: translate(0, 0px);
  }
}

@media (max-width: 490px) {
  #vpex_default .vpex-projects .v--project .menu-container img#menu-putra-florarosa-gallery {
    top: 2%;
    left: 2%;
  }
}

@media (max-width: 320px) {
  #vpex_default .vpex-projects .v--project .menu-container img#menu-putra-florarosa-gallery {
    top: -3%;
  }
}

#vpex_default .vpex-projects .v--project .menu-container img#menu-putra-florarosa-tour {
  top: 4%;
  left: 50%;
}

@media (max-width: 490px) {
  #vpex_default .vpex-projects .v--project .menu-container img#menu-putra-florarosa-tour {
    top: 2%;
    left: 47%;
  }
}

@media (max-width: 425px) {
  #vpex_default .vpex-projects .v--project .menu-container img#menu-putra-florarosa-tour {
    top: -1%;
    left: 44%;
  }
}

@media (max-width: 320px) {
  #vpex_default .vpex-projects .v--project .menu-container img#menu-putra-florarosa-tour {
    left: 40%;
  }
}

#vpex_default .vpex-projects .v--project .menu-container img#menu-putra-florarosa-projects {
  top: 19%;
  left: 68%;
  animation-name: dot-floating-3;
  animation-duration: 2.4s;
  animation-iteration-count: infinite;
  animation-timing-function: ease-in-out;
  margin-left: 30px;
  margin-top: 5px;
}

@keyframes dot-floating-3 {
  0% {
    transform: translate(0, 0px);
  }
  50% {
    transform: translate(0, 20px);
  }
  100% {
    transform: translate(0, 0px);
  }
}

@media (max-width: 490px) {
  #vpex_default .vpex-projects .v--project .menu-container img#menu-putra-florarosa-projects {
    top: 16%;
    left: 65%;
  }
}

@media (max-width: 425px) {
  #vpex_default .vpex-projects .v--project .menu-container img#menu-putra-florarosa-projects {
    left: 64%;
  }
}

@media (max-width: 375px) {
  #vpex_default .vpex-projects .v--project .menu-container img#menu-putra-florarosa-projects {
    left: 63%;
  }
}

@media (max-width: 320px) {
  #vpex_default .vpex-projects .v--project .menu-container img#menu-putra-florarosa-projects {
    left: 61%;
  }
}

#vpex_default .vpex-projects .v--project .menu-container img#menu-putra-florarosa-chat {
  top: 47%;
  left: 32%;
}

@media (max-width: 767px) {
  #vpex_default .vpex-projects .v--project .menu-container img#menu-putra-florarosa-chat {
    top: 44%;
  }
}

@media (max-width: 490px) {
  #vpex_default .vpex-projects .v--project .menu-container img#menu-putra-florarosa-chat {
    top: 50%;
    left: 30%;
  }
}

@media (max-width: 425px) {
  #vpex_default .vpex-projects .v--project .menu-container img#menu-putra-florarosa-chat {
    top: 50%;
    left: 28%;
  }
}

@media (max-width: 375px) {
  #vpex_default .vpex-projects .v--project .menu-container img#menu-putra-florarosa-chat {
    top: 42%;
  }
}

@media (max-width: 320px) {
  #vpex_default .vpex-projects .v--project .menu-container img#menu-putra-florarosa-chat {
    top: 46%;
    left: 24%;
  }
}

#vpex_default .vpex-projects .v--project .menu-container img#menu-putra-florarosa-about {
  top: 57%;
  left: 48%;
  animation-name: dot-floating-2;
  animation-duration: 1.8s;
  animation-iteration-count: infinite;
  animation-timing-function: ease-in-out;
  margin-left: 30px;
  margin-top: 5px;
}

@keyframes dot-floating-2 {
  0% {
    transform: translate(0, 0px);
  }
  50% {
    transform: translate(0, 20px);
  }
  100% {
    transform: translate(0, 0px);
  }
}

@media (max-width: 767px) {
  #vpex_default .vpex-projects .v--project .menu-container img#menu-putra-florarosa-about {
    top: 52%;
  }
}

@media (max-width: 490px) {
  #vpex_default .vpex-projects .v--project .menu-container img#menu-putra-florarosa-about {
    top: 61%;
    left: 44%;
  }
}

@media (max-width: 425px) {
  #vpex_default .vpex-projects .v--project .menu-container img#menu-putra-florarosa-about {
    top: 61%;
    left: 43%;
  }
}

@media (max-width: 375px) {
  #vpex_default .vpex-projects .v--project .menu-container img#menu-putra-florarosa-about {
    top: 53%;
    left: 42%;
  }
}

@media (max-width: 320px) {
  #vpex_default .vpex-projects .v--project .menu-container img#menu-putra-florarosa-about {
    top: 57%;
    left: 39%;
  }
}

#vpex_default .vpex-projects .v--project .menu-container img#menu-putra-florarosa-brochure {
  top: 52%;
  left: 78%;
  animation-name: dot-floating-3;
  animation-duration: 2.4s;
  animation-iteration-count: infinite;
  animation-timing-function: ease-in-out;
  margin-left: 30px;
  margin-top: 5px;
}

@keyframes dot-floating-3 {
  0% {
    transform: translate(0, 0px);
  }
  50% {
    transform: translate(0, 20px);
  }
  100% {
    transform: translate(0, 0px);
  }
}

@media (max-width: 767px) {
  #vpex_default .vpex-projects .v--project .menu-container img#menu-putra-florarosa-brochure {
    top: 48%;
  }
}

@media (max-width: 490px) {
  #vpex_default .vpex-projects .v--project .menu-container img#menu-putra-florarosa-brochure {
    top: 56%;
    left: 74%;
  }
}

@media (max-width: 425px) {
  #vpex_default .vpex-projects .v--project .menu-container img#menu-putra-florarosa-brochure {
    top: 58%;
    left: 71%;
  }
}

@media (max-width: 375px) {
  #vpex_default .vpex-projects .v--project .menu-container img#menu-putra-florarosa-brochure {
    top: 49%;
    left: 70%;
  }
}

@media (max-width: 320px) {
  #vpex_default .vpex-projects .v--project .menu-container img#menu-putra-florarosa-brochure {
    top: 57%;
    left: 66%;
  }
}

#vpex_default .vpex-projects .v--project .menu-container img#menu-putra-augusta-gallery {
  top: 4%;
  left: 6%;
  animation-name: dot-floating-2;
  animation-duration: 1.8s;
  animation-iteration-count: infinite;
  animation-timing-function: ease-in-out;
  margin-left: 30px;
  margin-top: 5px;
}

@keyframes dot-floating-2 {
  0% {
    transform: translate(0, 0px);
  }
  50% {
    transform: translate(0, 20px);
  }
  100% {
    transform: translate(0, 0px);
  }
}

@media (max-width: 490px) {
  #vpex_default .vpex-projects .v--project .menu-container img#menu-putra-augusta-gallery {
    top: 2%;
    left: 2%;
  }
}

@media (max-width: 320px) {
  #vpex_default .vpex-projects .v--project .menu-container img#menu-putra-augusta-gallery {
    top: -3%;
  }
}

#vpex_default .vpex-projects .v--project .menu-container img#menu-putra-augusta-tour {
  top: 4%;
  left: 50%;
}

@media (max-width: 490px) {
  #vpex_default .vpex-projects .v--project .menu-container img#menu-putra-augusta-tour {
    top: 2%;
    left: 47%;
  }
}

@media (max-width: 425px) {
  #vpex_default .vpex-projects .v--project .menu-container img#menu-putra-augusta-tour {
    top: -1%;
    left: 44%;
  }
}

@media (max-width: 320px) {
  #vpex_default .vpex-projects .v--project .menu-container img#menu-putra-augusta-tour {
    left: 40%;
  }
}

#vpex_default .vpex-projects .v--project .menu-container img#menu-putra-augusta-projects {
  top: 19%;
  left: 68%;
  animation-name: dot-floating-3;
  animation-duration: 2.4s;
  animation-iteration-count: infinite;
  animation-timing-function: ease-in-out;
  margin-left: 30px;
  margin-top: 5px;
}

@keyframes dot-floating-3 {
  0% {
    transform: translate(0, 0px);
  }
  50% {
    transform: translate(0, 20px);
  }
  100% {
    transform: translate(0, 0px);
  }
}

@media (max-width: 490px) {
  #vpex_default .vpex-projects .v--project .menu-container img#menu-putra-augusta-projects {
    top: 16%;
    left: 65%;
  }
}

@media (max-width: 425px) {
  #vpex_default .vpex-projects .v--project .menu-container img#menu-putra-augusta-projects {
    left: 64%;
  }
}

@media (max-width: 375px) {
  #vpex_default .vpex-projects .v--project .menu-container img#menu-putra-augusta-projects {
    left: 63%;
  }
}

@media (max-width: 320px) {
  #vpex_default .vpex-projects .v--project .menu-container img#menu-putra-augusta-projects {
    left: 61%;
  }
}

#vpex_default .vpex-projects .v--project .menu-container img#menu-putra-augusta-chat {
  top: 47%;
  left: 32%;
}

@media (max-width: 767px) {
  #vpex_default .vpex-projects .v--project .menu-container img#menu-putra-augusta-chat {
    top: 44%;
  }
}

@media (max-width: 490px) {
  #vpex_default .vpex-projects .v--project .menu-container img#menu-putra-augusta-chat {
    top: 50%;
    left: 30%;
  }
}

@media (max-width: 425px) {
  #vpex_default .vpex-projects .v--project .menu-container img#menu-putra-augusta-chat {
    top: 50%;
    left: 28%;
  }
}

@media (max-width: 375px) {
  #vpex_default .vpex-projects .v--project .menu-container img#menu-putra-augusta-chat {
    top: 42%;
  }
}

@media (max-width: 320px) {
  #vpex_default .vpex-projects .v--project .menu-container img#menu-putra-augusta-chat {
    top: 46%;
    left: 24%;
  }
}

#vpex_default .vpex-projects .v--project .menu-container img#menu-putra-augusta-about {
  top: 57%;
  left: 48%;
  animation-name: dot-floating-2;
  animation-duration: 1.8s;
  animation-iteration-count: infinite;
  animation-timing-function: ease-in-out;
  margin-left: 30px;
  margin-top: 5px;
}

@keyframes dot-floating-2 {
  0% {
    transform: translate(0, 0px);
  }
  50% {
    transform: translate(0, 20px);
  }
  100% {
    transform: translate(0, 0px);
  }
}

@media (max-width: 767px) {
  #vpex_default .vpex-projects .v--project .menu-container img#menu-putra-augusta-about {
    top: 52%;
  }
}

@media (max-width: 490px) {
  #vpex_default .vpex-projects .v--project .menu-container img#menu-putra-augusta-about {
    top: 61%;
    left: 44%;
  }
}

@media (max-width: 425px) {
  #vpex_default .vpex-projects .v--project .menu-container img#menu-putra-augusta-about {
    top: 61%;
    left: 43%;
  }
}

@media (max-width: 375px) {
  #vpex_default .vpex-projects .v--project .menu-container img#menu-putra-augusta-about {
    top: 53%;
    left: 42%;
  }
}

@media (max-width: 320px) {
  #vpex_default .vpex-projects .v--project .menu-container img#menu-putra-augusta-about {
    top: 57%;
    left: 39%;
  }
}

#vpex_default .vpex-projects .v--project .menu-container img#menu-putra-augusta-brochure {
  top: 52%;
  left: 78%;
  animation-name: dot-floating-3;
  animation-duration: 2.4s;
  animation-iteration-count: infinite;
  animation-timing-function: ease-in-out;
  margin-left: 30px;
  margin-top: 5px;
}

@keyframes dot-floating-3 {
  0% {
    transform: translate(0, 0px);
  }
  50% {
    transform: translate(0, 20px);
  }
  100% {
    transform: translate(0, 0px);
  }
}

@media (max-width: 767px) {
  #vpex_default .vpex-projects .v--project .menu-container img#menu-putra-augusta-brochure {
    top: 48%;
  }
}

@media (max-width: 490px) {
  #vpex_default .vpex-projects .v--project .menu-container img#menu-putra-augusta-brochure {
    top: 56%;
    left: 74%;
  }
}

@media (max-width: 425px) {
  #vpex_default .vpex-projects .v--project .menu-container img#menu-putra-augusta-brochure {
    top: 58%;
    left: 71%;
  }
}

@media (max-width: 375px) {
  #vpex_default .vpex-projects .v--project .menu-container img#menu-putra-augusta-brochure {
    top: 49%;
    left: 70%;
  }
}

@media (max-width: 320px) {
  #vpex_default .vpex-projects .v--project .menu-container img#menu-putra-augusta-brochure {
    top: 57%;
    left: 66%;
  }
}

#vpex_default .vpex-projects .v--project .menu-container .project-banner {
  background: transparent;
  border: 2px solid #462b5a;
  animation: blinker 1s linear infinite;
  width: 9.6%;
  height: 22.2%;
  transform: skew(0deg, 330deg);
  position: absolute;
}

@media (max-width: 767px) {
  #vpex_default .vpex-projects .v--project .menu-container .project-banner {
    width: 9.1%;
    height: 21%;
  }
}

@media (max-width: 490px) {
  #vpex_default .vpex-projects .v--project .menu-container .project-banner {
    height: 25%;
  }
}

@media (max-width: 425px) {
  #vpex_default .vpex-projects .v--project .menu-container .project-banner {
    height: 26%;
  }
}

@media (max-width: 375px) {
  #vpex_default .vpex-projects .v--project .menu-container .project-banner {
    height: 22%;
  }
}

@media (max-width: 320px) {
  #vpex_default .vpex-projects .v--project .menu-container .project-banner {
    height: 26%;
  }
}

#vpex_default .vpex-projects .v--project .menu-container .project-banner.skyseputeh-banner.banner3 {
  animation: blinker 0.7s linear infinite;
  margin-top: 25.2%;
  margin-left: 89%;
}

#vpex_default .vpex-projects .v--project .menu-container .project-banner.melodia2-banner.banner3 {
  animation: blinker 0.7s linear infinite;
  margin-top: 23.2%;
  margin-left: 88.6%;
}

#vpex_default .vpex-projects .v--project .menu-container .project-banner.ipark-banner.banner3 {
  animation: blinker 0.7s linear infinite;
  margin-top: 25.2%;
  margin-left: 89%;
}

#vpex_default .vpex-projects .v--project .menu-container .project-banner.dutavilla-banner.banner1 {
  animation: blinker 1.7s linear infinite;
  margin-top: 5%;
  margin-left: 74%;
}

#vpex_default .vpex-projects .v--project .menu-container .project-banner.dutavilla-banner.banner2 {
  margin-top: 9%;
  margin-left: 84.6%;
}

#vpex_default .vpex-projects .v--project .menu-container .project-banner.dutavilla-banner.banner3 {
  animation: blinker 0.7s linear infinite;
  margin-top: 31%;
  margin-left: 89%;
}

#vpex_default .vpex-projects .v--project .menu-container .project-banner.aura-banner.banner1 {
  animation: blinker 0.7s linear infinite;
  margin-top: 4.5%;
  margin-left: 74.7%;
}

#vpex_default .vpex-projects .v--project .menu-container .project-banner.aura-banner.banner2 {
  animation: blinker 1.7s linear infinite;
  margin-top: 7.6%;
  margin-left: 86.1%;
}

#vpex_default .vpex-projects .v--project .menu-container .project-banner.aura-banner.banner3 {
  margin-top: 33%;
  margin-left: 88.6%;
}

#vpex_default .vpex-projects .v--project .menu-container .project-banner.florarosa-banner.banner1 {
  margin-top: 4.3%;
  margin-left: 73.4%;
}

#vpex_default .vpex-projects .v--project .menu-container .project-banner.florarosa-banner.banner2 {
  animation: blinker 1.7s linear infinite;
  margin-top: 8.4%;
  margin-left: 85%;
}

#vpex_default .vpex-projects .v--project .menu-container .project-banner.florarosa-banner.banner3 {
  animation: blinker 0.7s linear infinite;
  margin-top: 29.6%;
  margin-left: 88.6%;
}

#vpex_default .vpex-projects .v--project .menu-container .project-banner.augusta-banner.banner1 {
  animation: blinker 0.7s linear infinite;
  margin-top: 4.2%;
  margin-left: 70.8%;
}

#vpex_default .vpex-projects .v--project .menu-container .project-banner.augusta-banner.banner2 {
  animation: blinker 1.7s linear infinite;
  margin-top: 7.9%;
  margin-left: 81.2%;
}

#vpex_default .vpex-projects .v--project .menu-container .project-banner.augusta-banner.banner3 {
  margin-top: 24.9%;
  margin-left: 89.1%;
}

#vpex_default .vpex-popup.menu {
  max-width: 181px;
  width: 100%;
  height: auto;
  background: #462c5a;
  padding: 15px;
}

#vpex_default .vpex-popup.menu a {
  color: #fff;
  text-decoration: none;
}

#contest-page.strata-living-survey .fa-bars:before {
  color: #999;
}

#contest-page.strata-living-survey .get-the-report {
  background-color: #d21f61;
  border: 0;
  color: #fff;
  text-decoration: none;
  font-size: large;
  padding: 15px 35px;
  margin: 30px auto;
  max-width: 300px;
}

#contest-page.strata-living-survey .video-desktop {
  display: none;
}

@media (min-width: 768px) {
  #contest-page.strata-living-survey .video-desktop {
    display: block;
  }
}

#contest-page.strata-living-survey .video-fallback {
  display: none;
}

@media (max-width: 767px) {
  #contest-page.strata-living-survey .video-fallback {
    display: block;
  }
}

@media (max-width: 1199px) {
  #contest-page.strata-living-survey .container {
    max-width: 95%;
  }
}

@media (min-width: 1200px) {
  #contest-page.strata-living-survey .container {
    max-width: 1190px;
  }
}

#contest-page.strata-living-survey .container.chart-container {
  padding: 15px 20px;
}

#contest-page.strata-living-survey h1 {
  font-size: 45px;
  font-weight: 100;
}

#contest-page.strata-living-survey .pre-content img {
  height: 18px;
  margin: 15px 0 15px -2px;
}

#contest-page.strata-living-survey .logotype img {
  width: 100%;
}

@media (max-width: 1023px) {
  #contest-page.strata-living-survey .logotype img {
    max-width: 38%;
  }
}

@media (max-width: 991px) {
  #contest-page.strata-living-survey .logotype img {
    max-width: 250px;
    width: 100%;
  }
}

@media (max-width: 767px) {
  #contest-page.strata-living-survey .logotype img {
    max-width: 75%;
    width: 100%;
  }
}

@media (min-width: 1024px) {
  #contest-page.strata-living-survey .logotype img {
    max-width: 350px;
  }
}

#contest-page.strata-living-survey #stickyNav {
  background-color: #fff;
  margin-top: 0 !important;
  width: 100%;
  position: fixed;
  top: 0;
  z-index: 1000;
}

#contest-page.strata-living-survey #stickyNav .navbar-toggler {
  right: 16px;
  top: 16px;
}

#contest-page.strata-living-survey #stickyNav .navbar-nav > li.nav-item a {
  text-decoration: none;
  padding: 10px 15px;
  text-transform: uppercase;
  color: #2d2e2f;
  font-size: 12px;
  letter-spacing: 1px;
  line-height: 1.33;
}

#contest-page.strata-living-survey #stickyNav .navbar-nav > li.nav-item a:hover {
  color: #d21f61;
}

#contest-page.strata-living-survey #stickyNav .navbar-nav > li.nav-item a:active {
  color: #000 !important;
}

@media (max-width: 820px) {
  #contest-page.strata-living-survey #stickyNav .navbar-nav > li.nav-item a {
    padding: 10px 4px;
  }
}

#contest-page.strata-living-survey section .section-text {
  margin-bottom: 30px;
}

#contest-page.strata-living-survey section p {
  line-height: 1.7;
  font-size: 15px;
}

#contest-page.strata-living-survey section.section-general {
  margin-top: 40px;
  padding-top: 0;
}

#contest-page.strata-living-survey section.section-general.bg-alternate-1 {
  background: linear-gradient(to left, #FFE3EE, #ffffff);
}

#contest-page.strata-living-survey section.section-general.bg-alternate-2 {
  background: linear-gradient(to left, #FFE4C1, #ffffff);
}

#contest-page.strata-living-survey section.section-general.bg-alternate-3 {
  background: linear-gradient(to left, #F3F3F3, #ffffff);
}

#contest-page.strata-living-survey section.section-general .numbering-section {
  position: absolute;
}

#contest-page.strata-living-survey section.section-general .numbering-h1 {
  display: block;
}

#contest-page.strata-living-survey section.section-general .numbering-h1 .h1-text, #contest-page.strata-living-survey section.section-general .numbering-h1 .h1-number {
  display: inline-block;
}

#contest-page.strata-living-survey section.section-general .numbering-h1 .h1-text {
  position: relative;
  top: 48px;
  left: -42px;
  line-height: 1.05;
}

@media (max-width: 991px) {
  #contest-page.strata-living-survey section.section-general .numbering-h1 .h1-text {
    font-size: 25px;
    left: -4%;
    top: 19px;
  }
}

@media (max-width: 425px) {
  #contest-page.strata-living-survey section.section-general .numbering-h1 .h1-text {
    font-size: 19px;
    top: 15px;
  }
}

@media (max-width: 320px) {
  #contest-page.strata-living-survey section.section-general .numbering-h1 .h1-text {
    font-size: 16px;
  }
}

#contest-page.strata-living-survey section.section-general .numbering-h1 .h1-text.number-2 {
  top: 12px;
  left: -62px;
}

@media (max-width: 990px) {
  #contest-page.strata-living-survey section.section-general .numbering-h1 .h1-text.number-2 {
    top: 5px;
    left: -6%;
  }
}

@media (max-width: 425px) {
  #contest-page.strata-living-survey section.section-general .numbering-h1 .h1-text.number-2 {
    top: -1px;
    left: -10%;
  }
}

#contest-page.strata-living-survey section.section-general .numbering-h1 .h1-text.number-3 {
  top: 58px;
  left: -13px;
}

@media (max-width: 990px) {
  #contest-page.strata-living-survey section.section-general .numbering-h1 .h1-text.number-3 {
    top: 32px;
    left: -1%;
  }
}

@media (max-width: 425px) {
  #contest-page.strata-living-survey section.section-general .numbering-h1 .h1-text.number-3 {
    top: 18px;
    left: -2%;
  }
}

#contest-page.strata-living-survey section.section-general .numbering-h1 .h1-text.number-4 {
  top: 9px;
}

@media (max-width: 990px) {
  #contest-page.strata-living-survey section.section-general .numbering-h1 .h1-text.number-4 {
    top: -6px;
  }
}

@media (max-width: 425px) {
  #contest-page.strata-living-survey section.section-general .numbering-h1 .h1-text.number-4 {
    left: -7%;
  }
}

#contest-page.strata-living-survey section.section-general .numbering-h1 .h1-text.number-5 {
  top: 50px;
  left: -31px;
}

@media (max-width: 990px) {
  #contest-page.strata-living-survey section.section-general .numbering-h1 .h1-text.number-5 {
    top: 21px;
    left: -3%;
  }
}

@media (max-width: 425px) {
  #contest-page.strata-living-survey section.section-general .numbering-h1 .h1-text.number-5 {
    top: 13px;
  }
}

@media (max-width: 990px) {
  #contest-page.strata-living-survey section.section-general .numbering-h1 .h1-number img {
    height: 122px;
  }
}

@media (max-width: 425px) {
  #contest-page.strata-living-survey section.section-general .numbering-h1 .h1-number img {
    height: 100px;
  }
}

#contest-page.strata-living-survey section.section-general .cover-style,
#contest-page.strata-living-survey section.section-general .cover-mobile {
  padding: 0 0 1%;
  background-size: cover;
  background-position: 100% 0;
  height: 100%;
  width: 100%;
  margin: 0 auto;
}

#contest-page.strata-living-survey section.section-general .cover-style.cover-none,
#contest-page.strata-living-survey section.section-general .cover-mobile.cover-none {
  background-image: none;
}

#contest-page.strata-living-survey section.section-general .cover-style.cover-default,
#contest-page.strata-living-survey section.section-general .cover-mobile.cover-default {
  background-repeat: no-repeat;
  background-size: 316px auto;
}

@media (max-width: 767px) {
  #contest-page.strata-living-survey section.section-general .cover-style.cover-default,
  #contest-page.strata-living-survey section.section-general .cover-mobile.cover-default {
    background-image: none;
  }
}

@media (max-width: 1280px) {
  #contest-page.strata-living-survey section.section-general .cover-style.cover-default,
  #contest-page.strata-living-survey section.section-general .cover-mobile.cover-default {
    background-size: 25% auto;
  }
}

@media (min-width: 768px) {
  #contest-page.strata-living-survey section.section-general .cover-style.cover-default.point-2,
  #contest-page.strata-living-survey section.section-general .cover-mobile.cover-default.point-2 {
    background-image: url(https://media.edgeprop.my/page/lendlease/m-point-02.png);
  }
  #contest-page.strata-living-survey section.section-general .cover-style.cover-default.point-4,
  #contest-page.strata-living-survey section.section-general .cover-mobile.cover-default.point-4 {
    background-image: url(https://media.edgeprop.my/page/lendlease/m-point-04.png);
  }
  #contest-page.strata-living-survey section.section-general .cover-style.cover-default.point-6,
  #contest-page.strata-living-survey section.section-general .cover-mobile.cover-default.point-6 {
    background-image: url(https://media.edgeprop.my/page/lendlease/m-point-6.png);
  }
  #contest-page.strata-living-survey section.section-general .cover-style.cover-default.cover-left,
  #contest-page.strata-living-survey section.section-general .cover-mobile.cover-default.cover-left {
    background-position-x: 0;
  }
  #contest-page.strata-living-survey section.section-general .cover-style.cover-default.cover-left.point-1,
  #contest-page.strata-living-survey section.section-general .cover-mobile.cover-default.cover-left.point-1 {
    background-image: url(https://media.edgeprop.my/page/lendlease/m-point-01.png);
  }
  #contest-page.strata-living-survey section.section-general .cover-style.cover-default.cover-left.point-3,
  #contest-page.strata-living-survey section.section-general .cover-mobile.cover-default.cover-left.point-3 {
    background-image: url(https://media.edgeprop.my/page/lendlease/m-point-03.png);
  }
  #contest-page.strata-living-survey section.section-general .cover-style.cover-default.cover-left.point-5,
  #contest-page.strata-living-survey section.section-general .cover-mobile.cover-default.cover-left.point-5 {
    background-image: url(https://media.edgeprop.my/page/lendlease/m-point-05.png);
  }
}

#contest-page.strata-living-survey section.section-general .cover-style.cover-masthead,
#contest-page.strata-living-survey section.section-general .cover-mobile.cover-masthead {
  background: linear-gradient(to left, #8e2f8b, #dd144b);
}

@media (min-width: 769px) {
  #contest-page.strata-living-survey section.section-general .cover-style.cover-masthead,
  #contest-page.strata-living-survey section.section-general .cover-mobile.cover-masthead {
    padding: 17px 0 40px;
  }
}

#contest-page.strata-living-survey section.section-general .cover-style.cover-masthead h1,
#contest-page.strata-living-survey section.section-general .cover-mobile.cover-masthead h1 {
  color: #fff !important;
  text-shadow: 0px 3px 5px #121212;
}

@media (max-width: 1100px) and (min-width: 769px) {
  #contest-page.strata-living-survey section.section-general .cover-style.cover-masthead h1,
  #contest-page.strata-living-survey section.section-general .cover-mobile.cover-masthead h1 {
    font-size: 32px;
  }
}

@media (max-width: 768px) {
  #contest-page.strata-living-survey section.section-general .cover-style.cover-masthead h1,
  #contest-page.strata-living-survey section.section-general .cover-mobile.cover-masthead h1 {
    text-shadow: 0px 1px 3px #121212;
    font-size: 22px;
  }
}

@media (max-width: 360px) {
  #contest-page.strata-living-survey section.section-general .cover-style.cover-masthead h1,
  #contest-page.strata-living-survey section.section-general .cover-mobile.cover-masthead h1 {
    font-size: 19px;
  }
}

@media (max-width: 320px) {
  #contest-page.strata-living-survey section.section-general .cover-style.cover-masthead h1,
  #contest-page.strata-living-survey section.section-general .cover-mobile.cover-masthead h1 {
    font-size: 16px;
  }
}

#contest-page.strata-living-survey section.section-general .cover-mobile {
  display: none;
}

@media (max-width: 767px) {
  #contest-page.strata-living-survey section.section-general .cover-mobile {
    background-repeat: no-repeat;
    background-size: 60% auto;
    height: 40vh;
    display: block;
    background-position: center;
  }
  #contest-page.strata-living-survey section.section-general .cover-mobile.m-point-1 {
    background-image: url(https://media.edgeprop.my/page/lendlease/m-point-01.png);
  }
  #contest-page.strata-living-survey section.section-general .cover-mobile.m-point-3 {
    background-image: url(https://media.edgeprop.my/page/lendlease/m-point-03.png);
  }
  #contest-page.strata-living-survey section.section-general .cover-mobile.m-point-5 {
    background-image: url(https://media.edgeprop.my/page/lendlease/m-point-05.png);
  }
  #contest-page.strata-living-survey section.section-general .cover-mobile.m-cover-left.m-point-2 {
    background-image: url(https://media.edgeprop.my/page/lendlease/m-point-02.png);
  }
  #contest-page.strata-living-survey section.section-general .cover-mobile.m-cover-left.m-point-4 {
    background-image: url(https://media.edgeprop.my/page/lendlease/m-point-04.png);
  }
}

#contest-page.strata-living-survey section.section-general.section-masthead {
  margin-top: 5%;
}

@media (max-width: 1023px) {
  #contest-page.strata-living-survey section.section-general.section-masthead {
    margin-top: 64px;
  }
}

@media (max-width: 767px) {
  #contest-page.strata-living-survey section.section-general.section-masthead {
    margin-top: 53px;
  }
}

#contest-page.strata-living-survey section.section-general.section-masthead .section-text {
  margin-top: 58px;
}

@media (max-width: 820px) {
  #contest-page.strata-living-survey section.section-general.section-masthead .section-text {
    margin-top: 7%;
  }
}

@media (max-width: 767px) {
  #contest-page.strata-living-survey section.section-general.section-masthead .section-text {
    margin-top: 12%;
  }
}

#contest-page.strata-living-survey section.section-general.section-pre {
  margin-top: 0;
}

#contest-page.strata-living-survey section.section-general.section-last {
  padding-bottom: 10px;
}

#contest-page.strata-living-survey section.section-general.section-last img {
  display: block;
  margin: 20px auto;
  max-width: 789px;
  width: 100%;
}

@media (max-width: 767px) {
  #contest-page.strata-living-survey section.section-general.section-last img {
    width: 90%;
  }
}

#contest-page.strata-living-survey section.section-general.section-last p {
  font-size: x-large;
}

#contest-page.strata-living-survey section.section-general.section-last video {
  width: 100%;
}

@media (max-width: 767px) {
  #contest-page.strata-living-survey .section-chart {
    padding-top: 40px;
  }
}

@media (max-width: 990px) {
  #contest-page.strata-living-survey .section-chart h1 {
    font-size: 25px;
  }
}

@media (max-width: 425px) {
  #contest-page.strata-living-survey .section-chart h1 {
    font-size: 19px;
  }
}

#contest-page.strata-living-survey .section-lowest img {
  margin: 30px 0;
  display: block;
}

#contest-page.strata-living-survey h2 {
  text-transform: uppercase;
  width: 100%;
}

#contest-page.strata-living-survey h3 {
  color: #ec008c !important;
  font-family: apexSansBook,Helvetica,Arial,sans-serif;
  font-size: 30px;
  font-weight: 300;
  letter-spacing: -.806004px;
  line-height: 39px;
  text-transform: none;
}

#contest-page.strata-living-survey footer {
  background: #f3f3f3;
  padding: 40px 0;
}

#contest-page.strata-living-survey footer .wrapper {
  text-align: center;
}

#contest-page.strata-living-survey footer .wrapper span {
  display: block;
  overflow: auto;
  text-transform: uppercase;
  font-size: 15px;
}

#contest-page.strata-living-survey footer .wrapper img {
  display: inline-block;
  width: 100%;
  max-width: 170px;
  padding: 20px;
}

@media (max-width: 490px) {
  #contest-page.strata-living-survey footer .wrapper img {
    max-width: 130px;
  }
}

.page-content-1902591 .page-projectreview footer,
.page-content-1902591 .page-projectreview #mainNav.navbar,
.page-content-1902591 .page-projectreview .news-arrow-down .circle-color,
.page-content-1902591 .page-projectreview section.page-inquiry .inquiry-button,
.page-content-1902591 .page-projectreview .fixed-bar .fixed-buttons .btn.btn-enquire, .page-content-1900642 .page-projectreview footer,
.page-content-1900642 .page-projectreview #mainNav.navbar,
.page-content-1900642 .page-projectreview .news-arrow-down .circle-color,
.page-content-1900642 .page-projectreview section.page-inquiry .inquiry-button,
.page-content-1900642 .page-projectreview .fixed-bar .fixed-buttons .btn.btn-enquire, .page-content-1849187 .page-projectreview footer,
.page-content-1849187 .page-projectreview #mainNav.navbar,
.page-content-1849187 .page-projectreview .news-arrow-down .circle-color,
.page-content-1849187 .page-projectreview section.page-inquiry .inquiry-button,
.page-content-1849187 .page-projectreview .fixed-bar .fixed-buttons .btn.btn-enquire, .page-content-1845150 .page-projectreview footer,
.page-content-1845150 .page-projectreview #mainNav.navbar,
.page-content-1845150 .page-projectreview .news-arrow-down .circle-color,
.page-content-1845150 .page-projectreview section.page-inquiry .inquiry-button,
.page-content-1845150 .page-projectreview .fixed-bar .fixed-buttons .btn.btn-enquire, .page-content-1841803 .page-projectreview footer,
.page-content-1841803 .page-projectreview #mainNav.navbar,
.page-content-1841803 .page-projectreview .news-arrow-down .circle-color,
.page-content-1841803 .page-projectreview section.page-inquiry .inquiry-button,
.page-content-1841803 .page-projectreview .fixed-bar .fixed-buttons .btn.btn-enquire, .page-content-1902490 .page-projectreview footer,
.page-content-1902490 .page-projectreview #mainNav.navbar,
.page-content-1902490 .page-projectreview .news-arrow-down .circle-color,
.page-content-1902490 .page-projectreview section.page-inquiry .inquiry-button,
.page-content-1902490 .page-projectreview .fixed-bar .fixed-buttons .btn.btn-enquire, .page-content-1902610 .page-projectreview footer,
.page-content-1902610 .page-projectreview #mainNav.navbar,
.page-content-1902610 .page-projectreview .news-arrow-down .circle-color,
.page-content-1902610 .page-projectreview section.page-inquiry .inquiry-button,
.page-content-1902610 .page-projectreview .fixed-bar .fixed-buttons .btn.btn-enquire {
  background-color: #007F38;
}

.page-content-1902591 .page-projectreview section.page-inquiry .inquiry-button,
.page-content-1902591 .page-projectreview .fixed-bar .fixed-buttons .btn.btn-enquire, .page-content-1900642 .page-projectreview section.page-inquiry .inquiry-button,
.page-content-1900642 .page-projectreview .fixed-bar .fixed-buttons .btn.btn-enquire, .page-content-1849187 .page-projectreview section.page-inquiry .inquiry-button,
.page-content-1849187 .page-projectreview .fixed-bar .fixed-buttons .btn.btn-enquire, .page-content-1845150 .page-projectreview section.page-inquiry .inquiry-button,
.page-content-1845150 .page-projectreview .fixed-bar .fixed-buttons .btn.btn-enquire, .page-content-1841803 .page-projectreview section.page-inquiry .inquiry-button,
.page-content-1841803 .page-projectreview .fixed-bar .fixed-buttons .btn.btn-enquire, .page-content-1902490 .page-projectreview section.page-inquiry .inquiry-button,
.page-content-1902490 .page-projectreview .fixed-bar .fixed-buttons .btn.btn-enquire, .page-content-1902610 .page-projectreview section.page-inquiry .inquiry-button,
.page-content-1902610 .page-projectreview .fixed-bar .fixed-buttons .btn.btn-enquire {
  border-color: #007F38;
}

.page-content-1902591 .page-projectreview section.page-inquiry .inquiry-button:hover,
.page-content-1902591 .page-projectreview .fixed-bar .fixed-buttons .btn.btn-enquire:hover, .page-content-1900642 .page-projectreview section.page-inquiry .inquiry-button:hover,
.page-content-1900642 .page-projectreview .fixed-bar .fixed-buttons .btn.btn-enquire:hover, .page-content-1849187 .page-projectreview section.page-inquiry .inquiry-button:hover,
.page-content-1849187 .page-projectreview .fixed-bar .fixed-buttons .btn.btn-enquire:hover, .page-content-1845150 .page-projectreview section.page-inquiry .inquiry-button:hover,
.page-content-1845150 .page-projectreview .fixed-bar .fixed-buttons .btn.btn-enquire:hover, .page-content-1841803 .page-projectreview section.page-inquiry .inquiry-button:hover,
.page-content-1841803 .page-projectreview .fixed-bar .fixed-buttons .btn.btn-enquire:hover, .page-content-1902490 .page-projectreview section.page-inquiry .inquiry-button:hover,
.page-content-1902490 .page-projectreview .fixed-bar .fixed-buttons .btn.btn-enquire:hover, .page-content-1902610 .page-projectreview section.page-inquiry .inquiry-button:hover,
.page-content-1902610 .page-projectreview .fixed-bar .fixed-buttons .btn.btn-enquire:hover {
  background-color: #007F38;
  opacity: 0.9;
  border-color: #007F38;
}

.page-content-1902591 .page-projectreview footer, .page-content-1900642 .page-projectreview footer, .page-content-1849187 .page-projectreview footer, .page-content-1845150 .page-projectreview footer, .page-content-1841803 .page-projectreview footer, .page-content-1902490 .page-projectreview footer, .page-content-1902610 .page-projectreview footer {
  color: #fff;
}

.page-content-1902591 .page-projectreview footer a, .page-content-1902591 .page-projectreview footer p, .page-content-1900642 .page-projectreview footer a, .page-content-1900642 .page-projectreview footer p, .page-content-1849187 .page-projectreview footer a, .page-content-1849187 .page-projectreview footer p, .page-content-1845150 .page-projectreview footer a, .page-content-1845150 .page-projectreview footer p, .page-content-1841803 .page-projectreview footer a, .page-content-1841803 .page-projectreview footer p, .page-content-1902490 .page-projectreview footer a, .page-content-1902490 .page-projectreview footer p, .page-content-1902610 .page-projectreview footer a, .page-content-1902610 .page-projectreview footer p {
  color: #ffffff;
}

.page-content-1902591 .news-hero-text h1 {
  opacity: 0 !important;
}

.page-content-1902591 section.page-hero .news-hero-bg {
  background-size: contain;
}

@media (max-width: 1440px) {
  .page-content-1902591 section.page-hero .news-hero-bg {
    height: 788px;
  }
}

@media (max-width: 1366px) {
  .page-content-1902591 section.page-hero .news-hero-bg {
    height: 750px;
  }
}

@media (max-width: 1280px) {
  .page-content-1902591 section.page-hero .news-hero-bg {
    height: 700px;
  }
}

@media (max-width: 1024px) {
  .page-content-1902591 section.page-hero .news-hero-bg {
    height: 560px;
  }
}

@media (max-width: 991px) {
  .page-content-1902591 section.page-hero .news-hero-bg {
    height: 542px;
  }
}

@media (max-width: 768px) {
  .page-content-1902591 section.page-hero .news-hero-bg {
    height: 420px;
  }
}

@media (max-width: 490px) {
  .page-content-1902591 section.page-hero .news-hero-bg {
    height: 269px;
    background-size: contain;
  }
}

@media (max-width: 425px) {
  .page-content-1902591 section.page-hero .news-hero-bg {
    height: 227px;
  }
}

@media (max-width: 375px) {
  .page-content-1902591 section.page-hero .news-hero-bg {
    height: 205px;
  }
}

@media (max-width: 320px) {
  .page-content-1902591 section.page-hero .news-hero-bg {
    height: 175px;
  }
}

@media (min-width: 1564px) {
  .page-content-1902591 section.page-hero .news-hero-bg {
    height: 858px;
  }
}

.page-content-1904967 .news-hero-text {
  background-color: rgba(0, 0, 0, 0.23);
  padding: 10px 0;
}

.page-content-1908336 i.fa.fa-quote-left.fa-2x.el-show, .page-content-1908246 i.fa.fa-quote-left.fa-2x.el-show, .page-content-1905980 i.fa.fa-quote-left.fa-2x.el-show, .page-content-1906640 i.fa.fa-quote-left.fa-2x.el-show, .page-content-1906721 i.fa.fa-quote-left.fa-2x.el-show, .page-content-1907220 i.fa.fa-quote-left.fa-2x.el-show, .page-content-1907497 i.fa.fa-quote-left.fa-2x.el-show, .page-content-1907608 i.fa.fa-quote-left.fa-2x.el-show, .page-content-1907919 i.fa.fa-quote-left.fa-2x.el-show, .page-content-1909347 i.fa.fa-quote-left.fa-2x.el-show, .page-content-1909737 i.fa.fa-quote-left.fa-2x.el-show {
  display: none;
}

.page-content-1906030 p a, .page-content-1906640 p a {
  display: initial;
}

@media (min-width: 551px) {
  .page-content-1905941 .page-projectreview section.page-hero .news-hero-bg {
    background-image: url("https://media.edgeprop.my/branded_page/sime_darby/sd_desktop.jpg") !important;
  }
}

@media (max-width: 550px) {
  .page-content-1905941 .page-projectreview section.page-hero .news-hero-bg {
    background-image: url("https://media.edgeprop.my/branded_page/sime_darby/sd_mobile.jpg") !important;
  }
}

@media (max-width: 375px) {
  .page-content-1905941 .page-projectreview section.page-hero .news-hero-bg {
    height: 494px;
  }
}

@media (max-width: 320px) {
  .page-content-1905941 .page-projectreview section.page-hero .news-hero-bg {
    height: 454px;
  }
}

@media (min-width: 768px) {
  .page-content-1905941 .news-hero-main-component {
    top: 16px !important;
  }
}

@media (min-width: 1025px) {
  .page-content-1905941 .news-hero-main-component .news-hero-text h1 {
    font-size: 54px !important;
  }
}

@media (max-width: 1024px) {
  .page-content-1905941 .news-hero-main-component .news-hero-text h1 {
    font-size: 41px !important;
  }
}

@media (max-width: 470px) {
  .page-content-1905941 .news-hero-main-component .news-hero-text h1 {
    font-size: 36px !important;
  }
}

@media (max-width: 375px) {
  .page-content-1905941 .news-hero-main-component .news-hero-text h1 {
    font-size: 32px !important;
  }
}

@media (max-width: 360px) {
  .page-content-1905941 .news-hero-main-component .news-hero-text h1 {
    font-size: 30px !important;
  }
}

@media (max-width: 320px) {
  .page-content-1905941 .news-hero-main-component .news-hero-text h1 {
    font-size: 27px !important;
  }
}

.page-content-1905941 #prform .container-news-skin.padding-mobile {
  display: none;
}

.page-content-1905941 #prform .container-news-skin.padding-mobile.sime-footer {
  display: block;
}

@media (min-width: 1024px) {
  .page-content-1908228 .page-projectreview section.page-hero .news-hero-main-component {
    top: 250px;
  }
}

@media (min-width: 1280px) {
  .page-content-1908228 .page-projectreview section.page-hero .news-hero-main-component {
    top: 320px;
  }
}

@media (max-width: 1023px) {
  .page-content-1908228 .page-projectreview section.page-hero .news-hero-main-component {
    top: 180px;
  }
}

@media (max-width: 767px) {
  .page-content-1908228 .page-projectreview section.page-hero .news-hero-main-component {
    top: 120px;
  }
}

.page-content-1908228 .page-projectreview section.page-hero .news-hero-main-component .news-hero-text h1,
.page-content-1908228 .page-projectreview section.page-hero .news-hero-main-component .news-hero-text h4, .page-content-1908246 .page-projectreview section.page-hero .news-hero-main-component .news-hero-text h1,
.page-content-1908246 .page-projectreview section.page-hero .news-hero-main-component .news-hero-text h4, .page-content-1908411 .page-projectreview section.page-hero .news-hero-main-component .news-hero-text h1,
.page-content-1908411 .page-projectreview section.page-hero .news-hero-main-component .news-hero-text h4 {
  text-shadow: 4px 3px 8px #111;
}

.page-content-1908246 .page-projectreview section.page-hero .news-hero-bg .news-hero-main-component .news-hero-text h1,
.page-content-1908246 .page-projectreview section.page-hero .news-hero-bg .news-hero-main-component .news-hero-text h4 {
  text-shadow: 11px 2px 16px #111;
}

.page-content-1908228 .page-projectreview section.page-hero .news-hero-bg {
  background-position: center -40px;
  height: 680px;
}

@media (min-width: 768px) {
  .page-content-1908228 .page-projectreview section.page-hero .news-hero-main-component {
    top: 452px;
  }
}

@media (min-width: 1024px) {
  .page-content-1909742 .page-projectreview section.page-hero .news-hero-bg {
    background-position: center -240px;
  }
}

@media (min-width: 1280px) {
  .page-content-1909742 .page-projectreview section.page-hero .news-hero-bg {
    background-position: center -310px;
  }
}

@media (min-width: 1366px) {
  .page-content-1909742 .page-projectreview section.page-hero .news-hero-bg {
    background-position: center -370px;
  }
}

.page-content-1910812 .page-projectreview section.page-hero .news-hero-main-component {
  top: 0px;
}

.page-content-1910812 .page-projectreview section.page-hero .news-hero-main-component .news-hero-text img {
  width: 90%;
}

@media (min-width: 768px) {
  .page-content-1910812 .page-projectreview section.page-hero .news-hero-main-component .news-hero-text img {
    max-width: 400px;
  }
}

@media (min-width: 1024px) {
  .page-content-1910812 .page-projectreview section.page-hero .news-hero-main-component .news-hero-text img {
    max-width: 500px;
  }
}

.page-content-1910653 .page-projectreview section.page-hero .news-hero-main-component {
  top: 30px;
}

@media (max-width: 767px) {
  .page-content-1910653 .page-projectreview section.page-hero .news-hero-main-component {
    padding-top: 20px;
  }
}

.page-content-1910653 .page-projectreview section.page-hero .news-hero-main-component .news-hero-text img {
  width: 100%;
  max-width: 360px;
  margin-bottom: 15px;
}

@media (max-width: 767px) {
  .page-content-1910653 .page-projectreview section.page-hero .news-hero-main-component .news-hero-text img {
    width: 70%;
  }
}

@media (max-width: 1024px) {
  #sidebar-main .dropdown-new h6, #sidebar-main .dropdown-new .nav-header {
    font-family: "Poppins", sans-serif;
    padding: 15px 20px 10px 20px;
    background-color: #fafafa;
    margin: 0;
    font-size: 15px;
    font-weight: 500 !important;
    display: block;
    line-height: 1.4;
  }
  #sidebar-main .main-sm-menu,
  #sidebar-main a#sidebar-dropdown-new {
    border-bottom: 1px solid #d8d8d8;
    color: #414141 !important;
  }
  #sidebar-main a#sidebar-dropdown-new {
    background: #fff;
  }
  #sidebar-main a.dropdown-item-new {
    color: #488BF8;
  }
  #sidebar-main .main-sm-menu {
    padding-top: 20px;
    padding-bottom: 20px;
  }
  #sidebar-main .border-sm-menu {
    float: right;
    font-weight: 100;
    color: #ababab;
    font-size: 2rem;
    border-right: 1px solid #d6d6d6;
    width: 20px;
    height: 33px;
    position: relative;
    top: -8px;
    left: -40px;
  }
}

.header-vpex {
  background: #462b5a;
}

.header-vpex .mega-dropdown:hover .nav-link-new {
  color: #462b5a !important;
  background-color: #fff;
}

.header-vpex .nav-link-new {
  color: #fff !important;
}

.header-vpex .nav-link-new:hover:not(.active) {
  color: #462b5a !important;
  background-color: #fff;
}

.header-vpex .nav-link-new.active {
  color: #462b5a !important;
  background-color: #fff;
}

.header-vpex i.fa.fa-search,
.header-vpex I.fa.fa-times {
  color: #fff;
}

.header-vpex #sidebarCollapse-new {
  background: transparent;
}

.header-vpex .ham-btn {
  background-image: url(https://media.edgeprop.my/assets/icons/hamburger-btn-white.png);
}

body.page-node-10 section.content-area.p-t-25.p-b-50 {
  margin-top: 30px;
}

.sto-ads,
.sto-singlepage {
  height: 100vh;
  z-index: 3000;
  position: fixed;
  top: -160%;
  width: 100%;
  left: 0%;
  transition: all .5s .2s ease-in-out;
}

.sto-ads.in,
.sto-singlepage.in {
  left: 0;
  top: 0;
  transition: all .5s .2s ease-in-out;
}

.sto-ads-content,
.sto-singlepage-content {
  position: relative;
}

.sto-ads-content > div[id^='div-gpt-ad'],
.sto-singlepage-content > div[id^='div-gpt-ad'] {
  display: table;
  margin: 85px auto 0;
}

.sto-ads .slide-close,
.sto-singlepage .slide-close {
  position: absolute;
  top: 0;
  background: rgba(0, 0, 0, 0.18);
  color: white;
  right: calc((100% - 780px) / 2);
  padding: 0 10px;
  font-size: 28px;
  z-index: 1;
  cursor: pointer;
}

.sto-ads .slide-close:hover,
.sto-singlepage .slide-close:hover {
  background: rgba(20, 20, 20, 0.18);
}

@media (max-width: 768px) {
  .sto-ads,
  .sto-singlepage {
    height: 100vh;
  }
  .sto-ads-content > div[id^='div-gpt-ad'],
  .sto-singlepage-content > div[id^='div-gpt-ad'] {
    margin: 40px auto 0;
  }
  .sto-ads .slide-close,
  .sto-singlepage .slide-close {
    right: calc((100% - 414px) / 2);
  }
}

@media (max-width: 425px) {
  .sto-ads-content > div[id^='div-gpt-ad'],
  .sto-singlepage-content > div[id^='div-gpt-ad'] {
    margin: 15px auto 0;
  }
  .sto-ads .slide-close,
  .sto-singlepage .slide-close {
    right: calc((100% - 414px) / 2);
  }
}

@media (max-width: 375px) {
  .sto-ads .slide-close,
  .sto-singlepage .slide-close {
    right: calc((100% - 300px) / 2);
  }
}

.admin-menu.adminimal-menu .banderole {
  top: 2.9rem;
}

.banderole {
  height: 100vh;
  background: rgba(0, 0, 0, 0.65);
  z-index: 3000;
  position: fixed;
  top: 0;
  width: 100%;
  left: -100%;
  transition: all .5s .2s ease-in-out;
}

.banderole.in {
  left: 0;
  transition: all .5s .2s ease-in-out;
}

.banderole.in .slide-trigger {
  left: -120px;
  transition: all .5s ease-in-out;
}

.banderole-content {
  position: relative;
}

.banderole-content > div[id^='div-gpt-ad'] {
  display: table;
  margin: 35px auto;
}

.banderole .slide-trigger {
  position: absolute;
  top: 50%;
  left: 100%;
  height: 250px;
  width: 100px;
  box-shadow: 1px 1px 5px rgba(25, 25, 25, 0.5);
  transform: translate(0%, -50%);
  cursor: pointer;
  opacity: 0;
  transition: all .5s .55s ease-in-out;
}

.banderole .slide-trigger.on {
  opacity: 1;
}

.banderole .slide-close {
  position: absolute;
  top: 0;
  background: rgba(0, 0, 0, 0.18);
  color: white;
  right: calc((100% - 980px) / 2);
  padding: 0 10px;
  font-size: 28px;
  z-index: 1;
  cursor: pointer;
}

.banderole .slide-close:hover {
  background: rgba(20, 20, 20, 0.18);
}

.banderole .trigger-close {
  position: absolute;
  top: 0px;
  right: -30px;
  background: rgba(13, 13, 13, 0.72);
  padding: 6px 10px 3px;
}

.banderole .trigger-close .ion-close {
  color: #fff;
}

.banderole .trigger-close:hover {
  background: rgba(73, 72, 72, 0.72);
}

.banderole .trigger-in {
  left: -120px;
  transition: all .5s .2s ease-in-out;
}

.cleanmode .banderole,
.map-search-2018 .banderole,
.page-iframe-maybank .banderole {
  display: none !important;
}

@media (max-width: 1061px) {
  .banderole-content > div[id^='div-gpt-ad'] {
    margin: 40px auto 0;
  }
  .banderole .slide-close {
    right: calc((100% - 700px) / 2);
  }
}

@media (max-width: 767px) {
  .admin-menu.adminimal-menu .banderole {
    top: 0;
  }
}

@media (max-width: 425px) {
  .banderole-content > div[id^='div-gpt-ad'] {
    margin: 15px auto 0;
  }
  .banderole .slide-close {
    right: calc((100% - 414px) / 2);
  }
}

@media (max-width: 375px) {
  .banderole .slide-close {
    right: calc((100% - 300px) / 2);
  }
}

.sto-big-animation {
  height: 100vh;
  z-index: 3000;
  position: fixed;
  top: -160%;
  width: 100%;
  left: 0%;
  transition: all .5s .2s ease-in-out;
  background: url("https://media.edgeprop.my/dfp/sime-darby/d.png");
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
}

@media (max-width: 767px) {
  .sto-big-animation {
    background: url("https://media.edgeprop.my/dfp/sime-darby/m.png");
  }
}

.sto-big-animation.in {
  left: 0;
  top: 0;
  transition: all .5s .2s ease-in-out;
}

.sto-big-animation-content {
  position: relative;
}

.sto-big-animation-content > div[id^='div-gpt-ad'] {
  display: table;
  margin: 85px auto 0;
  box-shadow: 0px 6px 18px 0px #666;
}

.sto-big-animation .slide-close {
  position: absolute;
  top: 0;
  background: rgba(0, 0, 0, 0.18);
  color: white;
  right: calc((100% - 780px) / 2);
  padding: 0 10px;
  font-size: 28px;
  z-index: 1;
  cursor: pointer;
}

.sto-big-animation .slide-close:hover {
  background: rgba(20, 20, 20, 0.18);
}

@media (max-width: 768px) {
  .sto-big-animation {
    height: 100vh;
  }
  .sto-big-animation-content > div[id^='div-gpt-ad'] {
    margin: 40px auto 0;
  }
  .sto-big-animation .slide-close {
    right: calc((100% - 414px) / 2);
  }
}

@media (max-width: 425px) {
  .sto-big-animation-content > div[id^='div-gpt-ad'] {
    margin: 15px auto 0;
  }
  .sto-big-animation .slide-close {
    right: calc((100% - 414px) / 2);
  }
}

@media (max-width: 375px) {
  .sto-big-animation .slide-close {
    right: calc((100% - 300px) / 2);
  }
}

.sto-singlepage .slide-close {
  padding: 0;
}

#bigleaderboard {
  margin-left: auto;
  margin-right: auto;
  text-align: center;
  padding: 0;
}

#bigleaderboard div[id^='div-gpt-ad'] {
  margin: 10px 0;
}

@media (min-width: 1024px) {
  #bigleaderboard div[id^='div-gpt-ad'] {
    margin: 102px 0 10px;
  }
}

@media (max-width: 1023px) {
  #bigleaderboard div[id^='div-gpt-ad'] {
    margin: 0;
    border-top: 10px solid #fff;
    border-bottom: 10px solid #fff;
  }
}

@media (min-width: 768px) {
  body.page-content #bigleaderboard div[id^='div-gpt-ad'] {
    margin: 0 0 10px;
  }
}

.catfish-ads {
  height: 80px;
  z-index: 3001;
  position: fixed;
  bottom: -100%;
  width: 100%;
  left: 0%;
  transition: all .5s .2s ease-in-out;
}

@media (max-width: 1024px) {
  .catfish-ads {
    height: 51px;
  }
}

@media (max-width: 576px) {
  .catfish-ads {
    height: 52px;
  }
}

@media (max-width: 320px) {
  .catfish-ads {
    height: 40px;
  }
}

.catfish-ads.in {
  left: 0;
  bottom: 130px;
  transition: all .5s .2s ease-in-out;
}

@media (max-width: 1024px) {
  .catfish-ads.in {
    bottom: 112px;
  }
}

@media (max-width: 576px) {
  .catfish-ads.in {
    bottom: 93px;
  }
}

@media (max-width: 375px) {
  .catfish-ads.in {
    bottom: 78px;
  }
}

@media (max-width: 320px) {
  .catfish-ads.in {
    bottom: 90px;
  }
}

.catfish-ads-content {
  position: relative;
}

.catfish-ads-content > div[id^='div-gpt-ad'] {
  display: table;
  margin: 80px auto 0;
}

.catfish-ads .slide-close {
  right: calc((100% - 1000px) / 2);
}

@media (min-width: 1200px) {
  .catfish-ads .slide-close {
    right: calc((100% - 1200px) / 2);
  }
}

@media (max-width: 1199px) {
  .catfish-ads .slide-close {
    right: calc((100% - 768px) / 2);
  }
}

@media (max-width: 767px) {
  .catfish-ads .slide-close {
    right: calc((100% - 414px) / 2);
  }
}

@media (max-width: 413px) {
  .catfish-ads .slide-close {
    right: calc((100% - 320px) / 2);
  }
}

.billboard {
  position: relative;
  z-index: 999;
  background: #fff;
}

.billboard div[id^='div-gpt-ad'],
.billboard > div[id^='div-gpt-ad'] {
  display: table;
  margin: 0 auto;
  border-bottom: 30px solid #fff;
}

.catfish-ads .slide-close {
  text-transform: uppercase;
  border-top-left-radius: 3px;
  border-bottom-left-radius: 3px;
  color: #fff;
  font-size: 11px;
  background: #444;
  top: 0;
  position: absolute;
  padding: 3px 10px;
  z-index: 1;
  cursor: pointer;
}

@media (max-width: 576px) {
  .catfish-ads .slide-close {
    top: -21px;
  }
}

.catfish-ads .slide-close:hover {
  background: rgba(20, 20, 20, 0.18);
}

.catfish-ads .slide-close i.material-icons {
  font-size: 15px;
}

.pop-slide-close {
  text-transform: uppercase;
  border-top-left-radius: 3px;
  border-bottom-left-radius: 3px;
  color: #fff;
  font-size: 11px;
  background: #444;
  top: 0;
  position: absolute;
  padding: 3px 10px;
  z-index: 1;
  cursor: pointer;
}

@media (max-width: 576px) {
  .pop-slide-close {
    top: -21px;
  }
}

.pop-slide-close:hover {
  background: rgba(20, 20, 20, 0.18);
}

.pop-slide-close i.material-icons {
  font-size: 15px;
}

.skin-ads-wrapper {
  position: fixed;
  top: 140px;
  height: auto;
  width: 100%;
}

.skin-ads {
  cursor: pointer;
  height: 100vh;
  background-position: center 187px;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
}

@media (max-width: 768px) {
  .skin-ads[id^='div-gpt-ad'] {
    margin-top: 0;
  }
}

@media (max-width: 767px) {
  .skin-ads {
    padding-top: 15px;
    background: #eee;
  }
}

.skin-ads.pos-fixed[id^='div-gpt-ad'] {
  margin-top: 0;
}

body.page-content footer.footer {
  position: relative;
}

body .wrap.news-wope-theme.push-2000 {
  top: 375px;
  margin-bottom: 375px;
  transition: all .5s .2s ease-in-out;
}

body .wrap.news-wope-theme.push-1600 {
  top: 375px;
  margin-bottom: 375px;
  transition: all .5s .2s ease-in-out;
}

@media (max-width: 576px) {
  body.page-content .wrap, body.node-type-tep-blogs .wrap {
    padding: 0;
  }
  body.page-content .news-details, body.node-type-tep-blogs .news-details {
    padding: 0;
  }
  body.page-content section.leaderboard.leaderboard-1, body.node-type-tep-blogs section.leaderboard.leaderboard-1 {
    z-index: 99;
    background: #fff;
  }
  body.page-content .entry-heading.cat-meta-line, body.node-type-tep-blogs .entry-heading.cat-meta-line {
    position: relative;
    border-top: 15px solid #fff;
    z-index: 100;
    background: #fff;
  }
  body.page-content .main-content, body.node-type-tep-blogs .main-content {
    z-index: 98;
    padding-top: 0;
  }
  body.page-content .main-content .news-article, body.node-type-tep-blogs .main-content .news-article {
    margin-bottom: -15px;
  }
  body.page-content .main-content .news-article .skin-ads-parallax-wrapper, body.node-type-tep-blogs .main-content .news-article .skin-ads-parallax-wrapper {
    display: none;
    position: relative;
    z-index: 76;
  }
  body.page-content .main-content .news-article p, body.page-content .main-content .news-article figure, body.page-content .main-content .news-article .news-owl, body.node-type-tep-blogs .main-content .news-article p, body.node-type-tep-blogs .main-content .news-article figure, body.node-type-tep-blogs .main-content .news-article .news-owl {
    z-index: 77;
    position: relative;
    background: #fff;
  }
  body.page-content .main-content .news-article p.card-address.address-clamp-short-list, body.page-content .main-content .news-article figure.card-address.address-clamp-short-list, body.page-content .main-content .news-article .news-owl.card-address.address-clamp-short-list, body.node-type-tep-blogs .main-content .news-article p.card-address.address-clamp-short-list, body.node-type-tep-blogs .main-content .news-article figure.card-address.address-clamp-short-list, body.node-type-tep-blogs .main-content .news-article .news-owl.card-address.address-clamp-short-list {
    position: inherit;
    padding: 0;
  }
  body.page-content .main-content .news-article ul, body.page-content .main-content .news-article ol, body.page-content .main-content .news-article h3, body.node-type-tep-blogs .main-content .news-article ul, body.node-type-tep-blogs .main-content .news-article ol, body.node-type-tep-blogs .main-content .news-article h3 {
    background: #fff;
    margin-bottom: 0;
    border-bottom: 15px solid #fff;
    pointer-events: auto;
  }
  body.page-content .main-content .news-article figure, body.page-content .main-content .news-article .news-owl, body.node-type-tep-blogs .main-content .news-article figure, body.node-type-tep-blogs .main-content .news-article .news-owl {
    margin-bottom: 0;
    border-bottom: 25px solid #fff;
  }
  body.page-content .main-content .news-article h3, body.node-type-tep-blogs .main-content .news-article h3 {
    margin-top: 0;
    padding: 5px 10px;
  }
  body.page-content .main-content .bottom-entry-post-share,
  body.page-content .main-content .news-body.content,
  body.page-content .main-content .news-tags,
  body.page-content .main-content .related-post,
  body.page-content .main-content .sidebar-widget,
  body.page-content .main-content .m-b-30, body.node-type-tep-blogs .main-content .bottom-entry-post-share,
  body.node-type-tep-blogs .main-content .news-body.content,
  body.node-type-tep-blogs .main-content .news-tags,
  body.node-type-tep-blogs .main-content .related-post,
  body.node-type-tep-blogs .main-content .sidebar-widget,
  body.node-type-tep-blogs .main-content .m-b-30 {
    margin-bottom: 0;
    padding-bottom: 0;
    position: relative;
    z-index: 99;
  }
  body.page-content .main-content .bottom-entry-post-share,
  body.page-content .main-content .news-tags,
  body.page-content .main-content .related-post,
  body.page-content .main-content .sidebar-widget,
  body.page-content .main-content .m-b-30,
  body.page-content .main-content .entry-heading.cat-meta-line, body.node-type-tep-blogs .main-content .bottom-entry-post-share,
  body.node-type-tep-blogs .main-content .news-tags,
  body.node-type-tep-blogs .main-content .related-post,
  body.node-type-tep-blogs .main-content .sidebar-widget,
  body.node-type-tep-blogs .main-content .m-b-30,
  body.node-type-tep-blogs .main-content .entry-heading.cat-meta-line {
    background: #fff;
  }
  body.page-content .main-content .related-post,
  body.page-content .main-content .news-tags,
  body.page-content .main-content .sidebar-widget,
  body.page-content .main-content .m-b-30, body.node-type-tep-blogs .main-content .related-post,
  body.node-type-tep-blogs .main-content .news-tags,
  body.node-type-tep-blogs .main-content .sidebar-widget,
  body.node-type-tep-blogs .main-content .m-b-30 {
    margin-bottom: 0;
    padding: 5px 20px 15px;
  }
  body.page-content .sidebar, body.node-type-tep-blogs .sidebar {
    z-index: 99;
    position: relative;
    background: #fff;
  }
  body.page-content .entry-heading.cat-meta-line, body.node-type-tep-blogs .entry-heading.cat-meta-line {
    padding: 0 10px;
  }
  body.page-content .main-content p,
  body.page-content .main-content h2, body.node-type-tep-blogs .main-content p,
  body.node-type-tep-blogs .main-content h2 {
    padding: 0 20px 28px;
  }
  body.page-content .main-content h2, body.node-type-tep-blogs .main-content h2 {
    background: #fff;
    margin: 0;
  }
  body.page-content .main-content h2.entry-title, body.node-type-tep-blogs .main-content h2.entry-title {
    padding-left: 0;
    padding-right: 0;
  }
  body.page-content .main-content, body.node-type-tep-blogs .main-content {
    pointer-events: none;
  }
  body.page-content .main-content .billboard,
  body.page-content .main-content .entry-heading,
  body.page-content .main-content .entry-categories,
  body.page-content .main-content .entry-meta,
  body.page-content .main-content .entry-title,
  body.page-content .main-content figure,
  body.page-content .main-content #block-block-5,
  body.page-content .main-content .news-body.content p,
  body.page-content .main-content .news-body.content a,
  body.page-content .main-content #block-block-10,
  body.page-content .main-content #block-block-11,
  body.page-content .main-content .news-tags,
  body.page-content .main-content .related-post,
  body.page-content .main-content .sidebar-widget,
  body.page-content .main-content .m-b-30 ul.extra-links li.link,
  body.page-content .main-content .m-b-30 .fb-comments,
  body.page-content .main-content iframe, body.node-type-tep-blogs .main-content .billboard,
  body.node-type-tep-blogs .main-content .entry-heading,
  body.node-type-tep-blogs .main-content .entry-categories,
  body.node-type-tep-blogs .main-content .entry-meta,
  body.node-type-tep-blogs .main-content .entry-title,
  body.node-type-tep-blogs .main-content figure,
  body.node-type-tep-blogs .main-content #block-block-5,
  body.node-type-tep-blogs .main-content .news-body.content p,
  body.node-type-tep-blogs .main-content .news-body.content a,
  body.node-type-tep-blogs .main-content #block-block-10,
  body.node-type-tep-blogs .main-content #block-block-11,
  body.node-type-tep-blogs .main-content .news-tags,
  body.node-type-tep-blogs .main-content .related-post,
  body.node-type-tep-blogs .main-content .sidebar-widget,
  body.node-type-tep-blogs .main-content .m-b-30 ul.extra-links li.link,
  body.node-type-tep-blogs .main-content .m-b-30 .fb-comments,
  body.node-type-tep-blogs .main-content iframe {
    pointer-events: auto;
  }
  body.page-content figure, body.node-type-tep-blogs figure {
    max-width: 100% !important;
    margin: 0 auto;
    text-align: center;
  }
}

.skin-ads-parallax-wrapper {
  display: none;
}

@media (min-width: 768px) {
  .skin-ads-parallax-wrapper {
    display: none;
  }
}

@media (min-width: 426px) {
  .skin-ads-parallax-wrapper .skin-ads-parallax {
    left: calc((100% - 425px) / 2) !important;
  }
}

.prlxlabel {
  background: #000;
  text-transform: uppercase;
  font-size: 10px;
  color: #fff;
  font-weight: 200;
  letter-spacing: .5px;
  text-align: center;
  height: 20px;
  line-height: 21px;
  position: relative;
  top: 28px;
  margin-left: -10px;
  margin-right: -10px;
}

.prlxlabel.end {
  top: 0;
  margin-bottom: 20px;
}

#app-banner {
  z-index: 9999;
  background: #fff;
  border-bottom: 1px solid #e1e1e1;
}

@media (min-width: 768px) {
  #app-banner {
    display: none;
  }
}

@media (max-width: 576px) {
  #app-banner {
    display: none;
  }
  #app-banner img {
    width: 100%;
  }
}

.normalize-fix {
  margin-bottom: 0 !important;
}

.normalize-fix .fixed-top-new {
  position: initial;
}

@media (min-width: 426px) {
  .app-news-scroller {
    top: 158px !important;
  }
}

@media (min-width: 480px) and (max-width: 480px) {
  .app-news-scroller {
    top: 161px !important;
  }
}

@media (max-width: 425px) {
  .app-news-scroller {
    top: 149px !important;
  }
}

@media (max-width: 414px) {
  .app-news-scroller {
    top: 145px !important;
  }
}

@media (max-width: 375px) {
  .app-news-scroller {
    top: 137px !important;
  }
}

@media (max-width: 360px) {
  .app-news-scroller {
    top: 133px !important;
  }
}

@media (max-width: 320px) {
  .app-news-scroller {
    top: 125px !important;
  }
}

.app-slider-position {
  margin-top: 0 !important;
}

.app-close {
  position: fixed;
  top: 0;
  right: 0;
  color: #c9c9c9;
  line-height: 1;
  pointer-events: auto;
}

.app-maincontent {
  margin-bottom: 40px;
}

.ads-compact-overlay {
  height: 345px;
  z-index: 3000;
  position: fixed;
  bottom: -100%;
  width: 300px;
  left: 6%;
  transition: all .5s .2s ease-in-out;
}

@media (max-width: 1024px) {
  .ads-compact-overlay {
    height: 51px;
  }
}

@media (max-width: 576px) {
  .ads-compact-overlay {
    height: 52px;
  }
}

@media (max-width: 320px) {
  .ads-compact-overlay {
    height: 300px;
  }
}

@media (max-width: 768px) {
  .ads-compact-overlay {
    left: 0;
    margin: 0 auto;
    width: 100%;
  }
}

.ads-compact-overlay.in {
  bottom: 0;
  transition: all .5s .2s ease-in-out;
}

@media (max-width: 768px) {
  .ads-compact-overlay.in {
    bottom: 48%;
  }
}

@media (max-width: 576px) {
  .ads-compact-overlay.in {
    bottom: 60%;
  }
}

@media (max-width: 320px) {
  .ads-compact-overlay.in {
    bottom: 14%;
  }
}

.ads-compact-overlay-content {
  position: relative;
}

.ads-compact-overlay-content > div[id^='div-gpt-ad'] {
  display: table;
  margin: 0 auto 0;
  border-radius: 50%;
  box-shadow: 0 4px 11px -5px #000000;
}

.ads-compact-overlay .pop-slide-close {
  right: calc((100% - 300px) / 2);
}

.button-banner {
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  flex-flow: row nowrap;
  -webkit-box-pack: center;
  justify-content: center;
}

.button-banner div[id^='div-gpt-ad'] {
  margin-bottom: 40px;
}

.ads-in-article div[id^='div-gpt-ad'] {
  margin: 15px auto 0 !important;
  text-align: center;
}

#adInContent {
  padding-top: 40px;
  text-align: center;
}

.ads-mrec-floating {
  height: 345px;
  z-index: 3000;
  position: fixed;
  bottom: -100%;
  width: 300px;
  left: 6%;
  transition: all .5s .2s ease-in-out;
}

@media (max-width: 1024px) {
  .ads-mrec-floating {
    height: 51px;
  }
}

@media (max-width: 576px) {
  .ads-mrec-floating {
    height: 52px;
  }
}

@media (max-width: 320px) {
  .ads-mrec-floating {
    height: 300px;
  }
}

@media (max-width: 768px) {
  .ads-mrec-floating {
    left: 0;
    margin: 0 auto;
    width: 100%;
  }
}

.ads-mrec-floating.in {
  bottom: 0;
  transition: all .5s .2s ease-in-out;
}

@media (max-width: 768px) {
  .ads-mrec-floating.in {
    bottom: 48%;
  }
}

@media (max-width: 576px) {
  .ads-mrec-floating.in {
    bottom: 60%;
  }
}

@media (max-width: 320px) {
  .ads-mrec-floating.in {
    bottom: 14%;
  }
}

.ads-mrec-floating-content {
  position: relative;
}

.ads-mrec-floating-content > div[id^='div-gpt-ad'] {
  display: table;
  margin: 0 auto 0;
  box-shadow: 0 4px 11px -5px #000000;
}

.ads-mrec-floating .pop-slide-close {
  right: calc((100% - 300px) / 2);
}

.mrec-sideslide-toggle {
  opacity: 0;
}

body.page-content .featured-properties {
  pointer-events: auto;
  margin-top: 30px;
  background: #fff;
}

@media (max-width: 767px) {
  body.page-content .featured-properties {
    padding: 5px 0 15px;
  }
}

@media (max-width: 768px) {
  body.page-content .featured-properties {
    margin: 0 20px 40px;
    border-top: 1px solid #aaa;
    border-bottom: 1px solid #dceaec;
  }
  body.page-content .featured-properties .exclusive-title {
    margin-bottom: 5px;
  }
  body.page-content .featured-properties .exclusive-title h5 {
    display: block;
    color: #1a1a1a;
    font-weight: 700;
    text-transform: uppercase;
    font-size: 13px;
    border-bottom: 0;
    padding: 0;
    margin: 12px 0 0;
    letter-spacing: 2px;
    line-height: 20px;
    text-align: center;
  }
}

body.page-content .featured-properties .card-content-full {
  padding: 5px 0 8px 0;
}

body.page-content .featured-properties .card-content-full h4.detail-similar-prop-title {
  padding: 0;
}

body.page-content .featured-properties .card-content-full .card-address.address-clamp-short-list {
  font-family: poppins,sans-serif;
}

body.page-content .featured-properties .card-content-full p.card-address.address-clamp-short-list {
  line-height: 26px;
  font-size: 11px;
}

body.page-content .featured-properties .card-content-full a.agent-link {
  color: #1a1a1a;
}

body.page-content .featured-properties .card-content-full .prop-image.first {
  border-bottom: 0 solid #fff;
}

body.page-content .featured-properties .card-content-full .prop-image {
  max-height: 99px;
}

@media (max-width: 767px) {
  body.page-content .featured-properties .card-content-full .prop-image {
    max-width: 140px;
  }
}

@media (min-width: 768px) {
  body.page-content .featured-properties .card-content-full .prop-image {
    max-height: 134px;
  }
}

body.page-content .featured-properties .owl-nav {
  position: absolute;
  top: -42px;
  right: 7px;
}

body.page-content .featured-properties .owl-nav button.owl-prev, body.page-content .featured-properties .owl-nav button.owl-next {
  font-size: 14px;
  margin: 0 5px;
  margin-left: 5px;
  margin-right: 5px;
}

@media only screen and (max-width: 768px) {
  body.page-content .featured-properties .owl-nav {
    display: none;
  }
}

body.page-content .featured-properties-col {
  padding-right: 0;
  padding-left: 0;
  width: 100%;
}

body.page-content .featured-properties .detail-similar-prop-content .card-image {
  position: relative;
}

body.page-content .featured-properties .detail-similar-prop-content .card-image .item-list-type {
  position: absolute;
  padding: 5px;
  z-index: 9999;
}

body.page-content .featured-properties .detail-similar-prop-content .card-image .item-list-type .list-type {
  font-size: 10px;
  line-height: 15px;
  color: #fff;
  font-weight: 600;
  background-color: rgba(0, 0, 0, 0.5);
  padding: 0 5px;
  border-radius: 5px;
}

body.page-content .featured-properties .detail-similar-prop-content .card-image .item-exclusive {
  position: absolute;
  padding: 5px;
  bottom: 0;
  z-index: 999;
}

body.page-content .featured-properties .detail-similar-prop-content .card-image .item-exclusive .exclusive {
  font-size: 10px;
  line-height: 15px;
  color: #fff;
  font-weight: 600;
  background-color: rgba(0, 0, 0, 0.5);
  border: 2px solid #fff;
  padding: 0 5px;
  border-radius: 5px;
}

body.page-content .featured-properties .detail-similar-prop-title {
  color: #414141;
  font-weight: 700;
  font-size: 14px;
  margin-bottom: 5px;
  line-height: 1.2;
  overflow: hidden !important;
  white-space: nowrap;
  text-overflow: ellipsis !important;
}

@media (max-width: 425px) {
  body.page-content .featured-properties .detail-similar-prop-title {
    max-width: 140px;
  }
}

@media (min-width: 768px) {
  body.page-content .featured-properties .detail-similar-prop-title {
    line-height: 22px;
    margin-bottom: -2px;
    max-width: 190px;
  }
}

body.page-content .featured-properties .detail-similar-prop-location {
  line-height: 17px;
  max-height: 17px;
  font-size: 11px;
  margin: 0;
}

body.page-content .featured-properties .detail-similar-prop-location .address-clamp-short-list {
  margin: 0;
  overflow: hidden !important;
  max-width: 20ch;
  white-space: nowrap;
  text-overflow: ellipsis !important;
}

body.page-content .featured-properties .detail-similar-prop-agent {
  font-size: 12.9px;
  text-decoration: none;
  color: #414141;
  overflow: hidden !important;
  max-width: 20ch;
  white-space: nowrap;
  text-overflow: ellipsis !important;
}

@media only screen and (max-width: 768px) {
  body.page-content .featured-properties .detail-similar-prop-agent {
    display: none;
  }
}

body.page-content .featured-properties .detail-similar-prop-price {
  font-size: 18px;
  color: #488BF8;
  padding-right: 15px;
  margin: 13px 0px 0px 0px;
  font-weight: bold;
  text-decoration: none;
}

@media only screen and (max-width: 768px) {
  body.page-content .featured-properties .detail-similar-prop-price {
    font-size: 16px;
    margin: 2px 0px 0px 0px;
  }
}

body.page-content .featured-properties .detail-similar-prop-details {
  overflow: hidden !important;
  max-width: 25ch;
  white-space: nowrap;
  text-overflow: ellipsis !important;
  font-size: 12px;
}

@media (max-width: 767px) {
  body.page-content .featured-properties .detail-similar-prop-details {
    font-size: 10px;
  }
}

#block-block-5 div[id^='div-gpt-ad']:after,
#block-block-10 div[id^='div-gpt-ad']:after,
#block-block-11 div[id^='div-gpt-ad']:after,
#bigleaderboard div[id^='div-gpt-ad']:after,
#adInContent div[id^='div-gpt-ad']:after {
  content: 'Advertisement';
  text-transform: uppercase;
  letter-spacing: 0.5px;
  color: #888;
  font-size: 10px;
  font-weight: 400;
  line-height: 20px;
  display: block;
  text-align: center;
}

#ip-container {
  position: relative;
}

#ip-container .article {
  margin-bottom: 15px;
  cursor: pointer;
}

#ip-container .article span {
  display: block;
  padding-bottom: 5px;
}

#ip-container .ip-details {
  display: none;
}

#ip-container .inlinepopup {
  background: #fff none repeat scroll 0 0;
  border: 1px solid #c2c2c2;
  float: left;
  width: 100%;
  margin-bottom: 15px;
  position: relative;
  border-top: 5px solid #fdb913;
  box-shadow: 0 3px 7px 0 rgba(0, 0, 0, 0.5);
}

#ip-container .inlinepopup_content {
  margin: 20px;
  color: #585858;
}

#ip-container .inlinepopup_content h2 {
  color: #585858;
  margin: 0;
  padding: 0 0 10px;
}

#ip-container .inlinepopup_content ul, #ip-container .inlinepopup_content ol {
  margin: 0 0 20px;
  padding: 0 0 0 20px;
}

#ip-container .inlinepopup_content .img-middle img {
  margin: 5px auto;
}

#ip-container .inlinepopup_arrow {
  background: url("https://media.edgeprop.my/icon/bmpa/1-arrow-top.png?v3") no-repeat scroll center 0;
  height: 32px;
  position: absolute;
  top: -34px;
  width: 32px;
}

#ip-container .inlinepopupClose {
  background: url("https://media.edgeprop.my/icon/bmpa/1-close.png?v3") no-repeat scroll 0 0;
  cursor: pointer;
  height: 16px;
  position: absolute;
  right: 5px;
  text-indent: -9999px;
  top: 10px;
  width: 16px;
  z-index: 99;
  overflow: hidden;
  transition: all 0.5s ease 0s;
}

#ip-container .inlinepopupClose:hover {
  transform: rotate(180deg);
}

.share-news-content {
  position: fixed;
  bottom: -48px;
  transition: all .4s .2s ease-in-out;
  background: #001246;
  color: #fff;
  width: 100px;
  border-radius: 30px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.14), 0 3px 4px 0 rgba(0, 0, 0, 0.12), 0 1px 5px 0 rgba(0, 0, 0, 0.2);
  margin: 0 auto;
  height: 43px;
  line-height: 24px;
  text-align: center;
  padding: 10px 0;
}

.share-news-content img.ico {
  width: 20px;
  margin-right: 5px;
  transition: all .4s .2s ease-in-out;
}

.share-news-content.on-click {
  background: #fff;
  color: #fff;
}

.share-news-content.on-click img.ico {
  transition: all .4s .2s ease-in-out;
  position: relative;
  right: -22px;
}

.share-news-content.in {
  cursor: pointer;
  bottom: 27px;
  transition: all .4s .2s ease-in-out;
  z-index: 1033;
}

@media (max-width: 576px) {
  .share-news-content.in {
    left: 40%;
  }
}

@media (max-width: 425px) {
  .share-news-content.in {
    left: 39%;
  }
}

@media (max-width: 375px) {
  .share-news-content.in {
    left: 38%;
  }
}

@media (max-width: 320px) {
  .share-news-content.in {
    left: 33%;
  }
}

@media (max-width: 576px) {
  .share-news-content.in.catfish-extension {
    bottom: 72px;
    z-index: 9999;
  }
}

.share-news-content-box {
  position: fixed;
  top: 0;
  background: rgba(26, 26, 26, 0.7);
  -webkit-transition: opacity .4s linear;
  -o-transition: opacity .4s linear;
  /* transition: opacity .4s linear; */
  opacity: 0;
  visibility: hidden;
  width: 100%;
  height: 100vh;
  z-index: 1032;
}

.share-news-content-box.in {
  touch-action: none;
  visibility: inherit;
  opacity: 1;
  transition: all .5s .2s ease-in-out;
}

.share-news-content-box .share-item {
  width: 40px;
  margin: 0 auto;
  display: table;
  bottom: -100px;
  position: relative;
}

@media (max-width: 320px) {
  .share-news-content-box .share-item {
    bottom: -45px;
  }
}

.share-news-content-box .share-item a {
  background: #001246;
  color: #fff;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  line-height: 25px;
  text-align: center;
  padding: 8.5px 12px;
  margin: 0 0 10px;
}

.share-news-content-box .share-item a i.fa {
  font-size: 16px;
}

@media (max-width: 576px) {
  .show-safari-bar {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    overflow: scroll;
    -webkit-overflow-scrolling: touch;
    overflow-scrolling: touch;
  }
}

body.page-airsupplycontest .block-webform h2.block-title, body.page-ewf .block-webform h2.block-title, body.page-node-42 .block-webform h2.block-title, body.page-pepscontest .block-webform h2.block-title, body.page-node-37 .block-webform h2.block-title, body.page-node-43 .block-webform h2.block-title {
  display: none;
}

body.page-airsupplycontest .block-webform form,
body.page-airsupplycontest .block-webform .messages.status.webform-confirmation, body.page-ewf .block-webform form,
body.page-ewf .block-webform .messages.status.webform-confirmation, body.page-node-42 .block-webform form,
body.page-node-42 .block-webform .messages.status.webform-confirmation, body.page-pepscontest .block-webform form,
body.page-pepscontest .block-webform .messages.status.webform-confirmation, body.page-node-37 .block-webform form,
body.page-node-37 .block-webform .messages.status.webform-confirmation, body.page-node-43 .block-webform form,
body.page-node-43 .block-webform .messages.status.webform-confirmation {
  margin: 0 auto;
}

@media (min-width: 768px) {
  body.page-airsupplycontest .block-webform form,
  body.page-airsupplycontest .block-webform .messages.status.webform-confirmation, body.page-ewf .block-webform form,
  body.page-ewf .block-webform .messages.status.webform-confirmation, body.page-node-42 .block-webform form,
  body.page-node-42 .block-webform .messages.status.webform-confirmation, body.page-pepscontest .block-webform form,
  body.page-pepscontest .block-webform .messages.status.webform-confirmation, body.page-node-37 .block-webform form,
  body.page-node-37 .block-webform .messages.status.webform-confirmation, body.page-node-43 .block-webform form,
  body.page-node-43 .block-webform .messages.status.webform-confirmation {
    max-width: 452px;
  }
}

body.page-airsupplycontest .block-webform .btn.btn-default.form-submit.ajax-processed, body.page-ewf .block-webform .btn.btn-default.form-submit.ajax-processed, body.page-node-42 .block-webform .btn.btn-default.form-submit.ajax-processed, body.page-pepscontest .block-webform .btn.btn-default.form-submit.ajax-processed, body.page-node-37 .block-webform .btn.btn-default.form-submit.ajax-processed, body.page-node-43 .block-webform .btn.btn-default.form-submit.ajax-processed {
  margin-top: 5px;
  background: #fff;
  color: #ff5122;
  border: 1px solid #ff5122;
}

@media (min-width: 768px) {
  body.page-airsupplycontest .block-webform .btn.btn-default.form-submit.ajax-processed, body.page-ewf .block-webform .btn.btn-default.form-submit.ajax-processed, body.page-node-42 .block-webform .btn.btn-default.form-submit.ajax-processed, body.page-pepscontest .block-webform .btn.btn-default.form-submit.ajax-processed, body.page-node-37 .block-webform .btn.btn-default.form-submit.ajax-processed, body.page-node-43 .block-webform .btn.btn-default.form-submit.ajax-processed {
    width: 50%;
  }
}

body.page-airsupplycontest .block-webform .btn.btn-default.form-submit.ajax-processed:hover, body.page-ewf .block-webform .btn.btn-default.form-submit.ajax-processed:hover, body.page-node-42 .block-webform .btn.btn-default.form-submit.ajax-processed:hover, body.page-pepscontest .block-webform .btn.btn-default.form-submit.ajax-processed:hover, body.page-node-37 .block-webform .btn.btn-default.form-submit.ajax-processed:hover, body.page-node-43 .block-webform .btn.btn-default.form-submit.ajax-processed:hover {
  border-color: #ff5122;
  color: #fff;
  background: #ff5122;
}

body.page-airsupplycontest .block-webform .form-actions .form-submit, body.page-ewf .block-webform .form-actions .form-submit, body.page-node-42 .block-webform .form-actions .form-submit, body.page-pepscontest .block-webform .form-actions .form-submit, body.page-node-37 .block-webform .form-actions .form-submit, body.page-node-43 .block-webform .form-actions .form-submit {
  width: 100%;
}

body.page-airsupplycontest .block-webform .webform-submit.button-primary.btn.btn-primary.form-submit, body.page-ewf .block-webform .webform-submit.button-primary.btn.btn-primary.form-submit, body.page-node-42 .block-webform .webform-submit.button-primary.btn.btn-primary.form-submit, body.page-pepscontest .block-webform .webform-submit.button-primary.btn.btn-primary.form-submit, body.page-node-37 .block-webform .webform-submit.button-primary.btn.btn-primary.form-submit, body.page-node-43 .block-webform .webform-submit.button-primary.btn.btn-primary.form-submit {
  text-decoration: none;
  background: #ff5122;
  color: #fff;
  padding: 20px 25px;
  font-weight: 800;
  border-color: #ff5122;
}

body.page-airsupplycontest .block-webform .webform-submit.button-primary.btn.btn-primary.form-submit:hover, body.page-ewf .block-webform .webform-submit.button-primary.btn.btn-primary.form-submit:hover, body.page-node-42 .block-webform .webform-submit.button-primary.btn.btn-primary.form-submit:hover, body.page-pepscontest .block-webform .webform-submit.button-primary.btn.btn-primary.form-submit:hover, body.page-node-37 .block-webform .webform-submit.button-primary.btn.btn-primary.form-submit:hover, body.page-node-43 .block-webform .webform-submit.button-primary.btn.btn-primary.form-submit:hover {
  background: #fff;
  color: #ff5122;
  border: 1px solid #ff5122;
}

@media (max-width: 320px) {
  body.page-airsupplycontest .block-webform .webform-submit.button-primary.btn.btn-primary.form-submit, body.page-ewf .block-webform .webform-submit.button-primary.btn.btn-primary.form-submit, body.page-node-42 .block-webform .webform-submit.button-primary.btn.btn-primary.form-submit, body.page-pepscontest .block-webform .webform-submit.button-primary.btn.btn-primary.form-submit, body.page-node-37 .block-webform .webform-submit.button-primary.btn.btn-primary.form-submit, body.page-node-43 .block-webform .webform-submit.button-primary.btn.btn-primary.form-submit {
    font-size: 1.3rem;
    padding: 20px 4px;
  }
}

body.page-airsupplycontest .block-webform .messages.status.webform-confirmation, body.page-ewf .block-webform .messages.status.webform-confirmation, body.page-node-42 .block-webform .messages.status.webform-confirmation, body.page-pepscontest .block-webform .messages.status.webform-confirmation, body.page-node-37 .block-webform .messages.status.webform-confirmation, body.page-node-43 .block-webform .messages.status.webform-confirmation {
  background: #edffd9;
  padding: 50px 30px;
  font-size: 2rem;
  text-align: center;
}

@media (max-width: 767px) {
  body.page-airsupplycontest .block-webform .messages.status.webform-confirmation, body.page-ewf .block-webform .messages.status.webform-confirmation, body.page-node-42 .block-webform .messages.status.webform-confirmation, body.page-pepscontest .block-webform .messages.status.webform-confirmation, body.page-node-37 .block-webform .messages.status.webform-confirmation, body.page-node-43 .block-webform .messages.status.webform-confirmation {
    padding: 10px;
  }
}

@media (max-width: 767px) {
  body.page-airsupplycontest .block-webform form, body.page-ewf .block-webform form, body.page-node-42 .block-webform form, body.page-pepscontest .block-webform form, body.page-node-37 .block-webform form, body.page-node-43 .block-webform form {
    padding: 20px;
  }
}

.owl-carousel {
  pointer-events: auto;
}

.m-news-search {
  display: none;
}

@media (max-width: 1024px) {
  body.page-news .m-news-search,
  body.page-content .m-news-search,
  body.page-node-29 .m-news-search,
  body.page-edgeproptv .m-news-search,
  body.page-pullout .m-news-search {
    display: block;
  }
}

.m-news-search .header-search-mazada {
  position: relative;
}

.m-news-search .header-search-popup {
  width: 100%;
  visibility: hidden;
  opacity: 0;
  position: absolute;
  top: 68px;
  right: 0;
  background: #fff;
  border-top: 1px solid transparent;
  -webkit-background-clip: padding-box;
  background-clip: padding-box;
  z-index: 9999;
  padding: 22px 18px;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.2);
  -webkit-transition: all .2s ease-out;
  -moz-transition: all .2s ease-out;
  -o-transition: all .2s ease-out;
  transition: all .2s ease-out;
  transform: translate3d(0, 20px, 0);
  -webkit-transform: translate3d(0, 20px, 0);
  -moz-transform: translate3d(0, 20px, 0);
  -ms-transform: translate3d(0, 20px, 0);
  -o-transform: translate3d(0, 20px, 0);
}

.m-news-search .header-search-popup.header-search-popup-open {
  visibility: visible;
  opacity: 1;
  transform: translate3d(0, 0, 0);
  -webkit-transform: translate3d(0, 0, 0);
}

.m-news-search .header-search-popup input {
  font-size: 13px;
}

.m-news-search .fa-search,
.m-news-search .fa-times {
  font-size: 17px;
}

.m-news-search button.search-news .fa-search {
  font-size: 36px;
}

.scrolldown-to-view,
.scrolldown-arrow {
  position: fixed;
  display: block;
  background-color: #fffffff0;
  width: 100%;
  text-align: center;
  bottom: 0;
}

.scrolldown-to-view {
  font-size: 2rem;
  padding: 5px 0 23px;
  z-index: 999;
}

.scrolldown-arrow {
  background: url(idropdown.png) 50%/2% no-repeat;
  padding: 0px 0 28px;
}

.vjs-big-play-button {
  display: none !important;
}

.lg-sub-html {
  font-size: 13px !important;
  opacity: 0 !important;
  transition: all .1s .1s ease-in-out !important;
}

@media (min-width: 1024px) {
  .lg-sub-html {
    width: 85%;
    margin: 0 auto;
  }
}

.lg-sub-html:hover {
  transition: all .1s .1s ease-in-out !important;
  opacity: 1 !important;
}

@media (max-width: 768px) {
  .lg-sub-html {
    font-size: 1rem !important;
  }
}

@media (max-width: 576px) {
  .lg-sub-html {
    background-color: #000 !important;
    padding: 5px 50px 5px 5px !important;
    text-align: left !important;
    font-size: .9rem !important;
  }
}

.lg-img-wrap:after {
  display: block !important;
  position: fixed;
  width: 100%;
  height: 20px;
  line-height: 21px;
  font-style: italic;
  bottom: 0;
  /* z-index: 99999999; */
  background: rgba(0, 0, 0, 0.29);
  color: #fff;
  /* padding: 20px; */
  margin: 0 auto;
  text-align: center;
}

@media (min-width: 768px) {
  .lg-img-wrap:after {
    content: "hover here for caption";
  }
}

@media (max-width: 490px) {
  .lg-img-wrap:after {
    content: "click here for caption";
  }
}

@media (max-width: 576px) {
  .lg-outer .lg-toogle-thumb {
    right: 5px !important;
  }
}

.full-popup {
  cursor: pointer;
  position: fixed;
  z-index: 999;
  color: #333;
  border: 1px solid #000;
  box-shadow: 1px 7px 30px 2px #6f6d6d;
  display: block;
  background: rgba(255, 255, 255, 0.98);
  text-align: center;
  overflow-x: hidden;
  overflow-y: auto;
  top: 28%;
  height: 300px;
  padding: 25px;
  right: calc((100% - 700px) / 2);
  width: 700px;
}

@media (max-width: 767px) {
  .full-popup {
    right: calc((100% - 320px) / 2);
    width: 320px;
  }
}

.full-popup p {
  font-size: 14px;
  padding-top: 13px;
}

.full-popup.active {
  -webkit-overflow-scrolling: touch;
  opacity: 1;
  -webkit-transition: opacity 0.15s linear, z-index 0.15;
  -o-transition: opacity 0.15s linear, z-index 0.15;
  transition: opacity 0.15s linear, z-index 0.15;
}

.full-popup .btn-close {
  cursor: pointer;
  position: fixed;
  color: #fff;
  background: #462b5a;
  border-radius: 50%;
  width: 35px;
  height: 35px;
  z-index: 999999999;
  margin-top: -20px;
  right: calc((100% - 700px) / 2);
  top: 24%;
  opacity: 1;
  text-shadow: none;
  font-size: 4rem;
  font-weight: 100;
  line-height: .9;
}

@media (max-width: 767px) {
  .full-popup .btn-close {
    right: calc((100% - 320px) / 2);
  }
}

.full-popup .btn-close:focus {
  box-shadow: none !important;
}

.full-popup .btn-close:not(:disabled):not(.disabled):active {
  background: none;
  border: 0;
}

.full-popup.vpex-popup {
  max-width: 400px;
  position: fixed;
  top: 80px;
  right: 5%;
}

#brand-slide {
  z-index: 999;
  position: fixed;
  background: #dcdfe6;
  color: #333;
  width: 260px;
  text-align: center;
  bottom: 80px;
  right: -260px;
  height: 264px;
  transition: right 0.4s ease-in-out;
  -o-transition: right 0.4s ease-in-out;
  -ms-transition: right 0.4s ease-in-out;
  -moz-transition: right 0.4s ease-in-out;
  -webkit-transition: right 0.4s ease-in-out;
}

#brand-slide .box {
  padding: 20px;
  font-size: 13px;
}

#brand-slide:hover, #brand-slide.in {
  right: 0px;
}

#brand-slide.in, #brand-slide.out {
  transition: right 0.4s ease-in-out;
  -o-transition: right 0.4s ease-in-out;
  -ms-transition: right 0.4s ease-in-out;
  -moz-transition: right 0.4s ease-in-out;
  -webkit-transition: right 0.4s ease-in-out;
  right: 0px;
}

#brand-slide.out {
  right: -260px;
}

#brand-slide #brand-toggle {
  position: absolute;
  right: 260px;
  padding: 10px 20px;
  background: #d1a12a;
  color: #fff;
  font-weight: 700;
  font-size: 10px;
  cursor: pointer;
}

#brand-slide a.btn-slide {
  display: block;
  margin-top: 15px;
  background: #007F38;
  color: #fff;
  padding: 4px 10px;
  font-weight: 700;
}

/* Ignore This, just coloring */
.cursor-pointer, .accordion-wrap .accordion-js-panel .accordion__header {
  cursor: pointer;
}

.accordion-wrap {
  border-bottom: 1px solid #000;
}

.accordion-wrap .accordion-js-panel {
  overflow: hidden;
}

.accordion-wrap .accordion-js-panel .accordion__header {
  border-top: 1px solid #333;
  color: #fff;
  padding: 17px 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.accordion-wrap .accordion-js-panel .accordion__header h3 {
  margin: 0;
}

.accordion-wrap .accordion-js-panel .accordion__header .acc-title {
  color: #000;
  font-size: 19px;
  font-weight: 500;
}

@media (max-width: 820px) {
  .accordion-wrap .accordion-js-panel .accordion__header .acc-title {
    font-size: 16px;
  }
}

.accordion-wrap .accordion-js-panel .accordion__header .accordion__button {
  width: 31px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-image: linear-gradient(90deg, #ffac1b -2.58%, #e10b90 45.27%);
  padding: 16px 8px 15px;
  border-radius: 50%;
}

.accordion-wrap .accordion-js-panel .accordion__header .accordion__button:after {
  content: "+";
  font-size: 22px;
  transform-origin: center;
  transition: 200ms linear;
  font-weight: 200;
}

.accordion-wrap .accordion-js-panel .accordion__header .accordion__button.accordion-open:after {
  transform-origin: center;
  transition: 200ms linear;
}

.accordion-wrap .accordion-js-panel .accordion__header .accordion__button.accordion-close:after {
  transform: rotate(45deg);
}

.accordion-wrap .accordion-js-panel .accordion__header:last-child {
  border-bottom: 1px solid #333;
}

.accordion-wrap .accordion-js-panel .accordion__body {
  border-top: 1px solid #b8babe;
  padding: 20px 0;
  display: none;
}

.accordion-wrap .accordion-js-panel .accordion__body p {
  margin-bottom: 3px;
}

.accordion-wrap .accordion-js-panel .accordion__body p:last-child {
  margin: 0;
}

.highcharts-figure,
.highcharts-data-table table {
  min-width: 310px;
  max-width: 800px;
  margin: 1em auto;
}

.highcharts-data-table table {
  font-family: Verdana, sans-serif;
  border-collapse: collapse;
  border: 1px solid #ebebeb;
  margin: 10px auto;
  text-align: center;
  width: 100%;
  max-width: 500px;
}

.highcharts-data-table caption {
  padding: 1em 0;
  font-size: 1.2em;
  color: #555;
}

.highcharts-data-table th {
  font-weight: 600;
  padding: 0.5em;
}

.highcharts-data-table td,
.highcharts-data-table th,
.highcharts-data-table caption {
  padding: 0.5em;
}

.highcharts-data-table thead tr,
.highcharts-data-table tr:nth-child(even) {
  background: #f8f8f8;
}

.highcharts-data-table tr:hover {
  background: #f1f7ff;
}

@keyframes blinker {
  50% {
    opacity: 0;
  }
}

@keyframes blinker2 {
  30% {
    opacity: 0;
  }
}

@keyframes blinker3 {
  70% {
    opacity: 0;
  }
}

body #intercom-container,
body .intercom-lightweight-app {
  display: none !important;
}

body.page-airsupply .intercom-app,
body.page-airsupply .sto-ads,
body.page-airsupply .sto-big-animation,
body.page-airsupply .catfish-ads,
body.page-airsupply .banderole, body.page-awards2020 .intercom-app,
body.page-awards2020 .sto-ads,
body.page-awards2020 .sto-big-animation,
body.page-awards2020 .catfish-ads,
body.page-awards2020 .banderole, body.page-awards2021 .intercom-app,
body.page-awards2021 .sto-ads,
body.page-awards2021 .sto-big-animation,
body.page-awards2021 .catfish-ads,
body.page-awards2021 .banderole, body.page-airsupplycontest .intercom-app,
body.page-airsupplycontest .sto-ads,
body.page-airsupplycontest .sto-big-animation,
body.page-airsupplycontest .catfish-ads,
body.page-airsupplycontest .banderole, body.page-awards2020entry .intercom-app,
body.page-awards2020entry .sto-ads,
body.page-awards2020entry .sto-big-animation,
body.page-awards2020entry .catfish-ads,
body.page-awards2020entry .banderole, body.page-awards2021entry .intercom-app,
body.page-awards2021entry .sto-ads,
body.page-awards2021entry .sto-big-animation,
body.page-awards2021entry .catfish-ads,
body.page-awards2021entry .banderole, body.page-awards2021 .intercom-app,
body.page-awards2021 .sto-ads,
body.page-awards2021 .sto-big-animation,
body.page-awards2021 .catfish-ads,
body.page-awards2021 .banderole, body.page-vpex2021 .intercom-app,
body.page-vpex2021 .sto-ads,
body.page-vpex2021 .sto-big-animation,
body.page-vpex2021 .catfish-ads,
body.page-vpex2021 .banderole, body.page-ewf .intercom-app,
body.page-ewf .sto-ads,
body.page-ewf .sto-big-animation,
body.page-ewf .catfish-ads,
body.page-ewf .banderole, body.page-node-42 .intercom-app,
body.page-node-42 .sto-ads,
body.page-node-42 .sto-big-animation,
body.page-node-42 .catfish-ads,
body.page-node-42 .banderole, body.page-node-87 .intercom-app,
body.page-node-87 .sto-ads,
body.page-node-87 .sto-big-animation,
body.page-node-87 .catfish-ads,
body.page-node-87 .banderole, body.page--stratalivingsurvey .intercom-app,
body.page--stratalivingsurvey .sto-ads,
body.page--stratalivingsurvey .sto-big-animation,
body.page--stratalivingsurvey .catfish-ads,
body.page--stratalivingsurvey .banderole, body.page-node-43 .intercom-app,
body.page-node-43 .sto-ads,
body.page-node-43 .sto-big-animation,
body.page-node-43 .catfish-ads,
body.page-node-43 .banderole, body.page-node-37 .intercom-app,
body.page-node-37 .sto-ads,
body.page-node-37 .sto-big-animation,
body.page-node-37 .catfish-ads,
body.page-node-37 .banderole, body.page-cny2020 .intercom-app,
body.page-cny2020 .sto-ads,
body.page-cny2020 .sto-big-animation,
body.page-cny2020 .catfish-ads,
body.page-cny2020 .banderole, body.page-node-45 .intercom-app,
body.page-node-45 .sto-ads,
body.page-node-45 .sto-big-animation,
body.page-node-45 .catfish-ads,
body.page-node-45 .banderole, body.page-node-65 .intercom-app,
body.page-node-65 .sto-ads,
body.page-node-65 .sto-big-animation,
body.page-node-65 .catfish-ads,
body.page-node-65 .banderole, body.page-node-44 .intercom-app,
body.page-node-44 .sto-ads,
body.page-node-44 .sto-big-animation,
body.page-node-44 .catfish-ads,
body.page-node-44 .banderole, body.page-node-46 .intercom-app,
body.page-node-46 .sto-ads,
body.page-node-46 .sto-big-animation,
body.page-node-46 .catfish-ads,
body.page-node-46 .banderole, body.page--townshipguidebook .intercom-app,
body.page--townshipguidebook .sto-ads,
body.page--townshipguidebook .sto-big-animation,
body.page--townshipguidebook .catfish-ads,
body.page--townshipguidebook .banderole, body.page-content-ecoworld .intercom-app,
body.page-content-ecoworld .sto-ads,
body.page-content-ecoworld .sto-big-animation,
body.page-content-ecoworld .catfish-ads,
body.page-content-ecoworld .banderole, body.page-content-1902490 .intercom-app,
body.page-content-1902490 .sto-ads,
body.page-content-1902490 .sto-big-animation,
body.page-content-1902490 .catfish-ads,
body.page-content-1902490 .banderole {
  display: none !important;
}

@media (min-width: 577px) and (max-width: 767px) {
  #app-banner {
    display: none !important;
  }
}

body.page-news .news-page .main-content .flexslider .post-entry, body.page-node-29 .news-page .main-content .flexslider .post-entry {
  border-bottom: 0;
}

body.page-news .news-page .main-content .post-entry, body.page-node-29 .news-page .main-content .post-entry {
  padding-bottom: 20px;
  border-bottom: 1px solid #ddd;
  margin-bottom: 20px;
}

body.page-news .news-page .main-content .post-entry h4.title,
body.page-news .news-page .main-content .post-entry h5.title, body.page-node-29 .news-page .main-content .post-entry h4.title,
body.page-node-29 .news-page .main-content .post-entry h5.title {
  font-size: 3.3rem;
  line-height: 1.2;
  margin-bottom: 10px;
  font-weight: 700 !important;
}

@media (max-width: 767px) {
  body.page-news .news-page .main-content .post-entry h4.title,
  body.page-news .news-page .main-content .post-entry h5.title, body.page-node-29 .news-page .main-content .post-entry h4.title,
  body.page-node-29 .news-page .main-content .post-entry h5.title {
    font-size: 2.2rem;
    line-height: 1.3;
  }
}

body.page-news .news-page .main-content .post-entry h5.title, body.page-node-29 .news-page .main-content .post-entry h5.title {
  font-size: 2.4rem;
  line-height: 1.4;
}

@media (max-width: 767px) {
  body.page-news .news-page .main-content .post-entry h5.title, body.page-node-29 .news-page .main-content .post-entry h5.title {
    font-size: 1.6rem;
    line-height: 1.3;
  }
}

body.page-news .news-page .main-content .post-entry img, body.page-node-29 .news-page .main-content .post-entry img {
  width: 100%;
}

body.page-news .news-page .main-content .post-entry .entry-meta .author, body.page-node-29 .news-page .main-content .post-entry .entry-meta .author {
  font-weight: 600;
  color: #222;
  line-height: 1.6;
}

body.page-news .news-page .main-content .alignment, body.page-node-29 .news-page .main-content .alignment {
  margin-bottom: 30px;
}

body.page-news .news-page .main-content .box-details, body.page-node-29 .news-page .main-content .box-details {
  margin-left: 244px;
  min-height: 150px;
}

@media (max-width: 767px) {
  body.page-news .news-page .main-content .box-details, body.page-node-29 .news-page .main-content .box-details {
    margin-left: 130px;
    min-height: 50px;
  }
}

@media (max-width: 490px) {
  body.page-news .news-page .main-content .box-details, body.page-node-29 .news-page .main-content .box-details {
    margin-left: 115px;
    min-height: 69px;
  }
}

body.page-news .news-page .main-content #content-section2 article.post-entry .box-thumb, body.page-node-29 .news-page .main-content #content-section2 article.post-entry .box-thumb {
  height: 140px;
}

body.page-news .news-page .main-content #content-section2 article.post-entry .box-thumb img, body.page-node-29 .news-page .main-content #content-section2 article.post-entry .box-thumb img {
  position: initial;
  transform: initial;
}

body.page-news .news-page .main-content .box-thumb, body.page-node-29 .news-page .main-content .box-thumb {
  position: absolute;
  left: 0;
  top: 0;
}

@media (min-width: 1024px) {
  body.page-news .news-page .main-content .box-thumb .post-thumb, body.page-node-29 .news-page .main-content .box-thumb .post-thumb {
    width: 218px;
    height: 140px;
    overflow: hidden;
  }
}

@media (max-width: 767px) {
  body.page-news .news-page .main-content .box-thumb .post-thumb, body.page-node-29 .news-page .main-content .box-thumb .post-thumb {
    width: 120px;
    height: 77px;
    overflow: hidden;
  }
}

@media (max-width: 490px) {
  body.page-news .news-page .main-content .box-thumb .post-thumb, body.page-node-29 .news-page .main-content .box-thumb .post-thumb {
    width: 100px;
    height: 64px;
    overflow: hidden;
  }
}

body.page-news .news-page .main-content .box-thumb .post-thumb img, body.page-node-29 .news-page .main-content .box-thumb .post-thumb img {
  max-width: 100%;
}

body.page-news .news-page .main-content .entry-meta, body.page-node-29 .news-page .main-content .entry-meta {
  font-size: 1.4rem;
}

@media (max-width: 767px) {
  body.page-news .news-page .main-content .entry-meta, body.page-node-29 .news-page .main-content .entry-meta {
    font-size: 1.2rem;
  }
}

body.page-news .news-page .main-content .title-wrap, body.page-node-29 .news-page .main-content .title-wrap {
  border-top: 1px solid #d8d8d8;
  border-bottom: 1px solid #d8d8d8;
  margin-bottom: 20px;
}

body.page-news .news-page .main-content .title-wrap.categories, body.page-node-29 .news-page .main-content .title-wrap.categories {
  border-top: 0;
}

body.page-news .news-page .main-content .title-wrap.video, body.page-node-29 .news-page .main-content .title-wrap.video {
  border-top: 1px solid #fc9322;
  border-bottom: 1px solid #fc9322;
}

body.page-news .news-page .main-content .title-wrap.latest, body.page-node-29 .news-page .main-content .title-wrap.latest {
  border-top: 1px solid #2056ab;
  border-bottom: 1px solid #2056ab;
}

body.page-news .news-page .main-content .title-wrap.indepth, body.page-node-29 .news-page .main-content .title-wrap.indepth {
  border-top: 1px solid #46c4f3;
  border-bottom: 1px solid #46c4f3;
}

body.page-news .news-page .main-content .title-wrap.lifestyle, body.page-node-29 .news-page .main-content .title-wrap.lifestyle {
  border-top: 1px solid #f45454;
  border-bottom: 1px solid #f45454;
}

body.page-news .news-page .main-content .title-wrap .block-title, body.page-node-29 .news-page .main-content .title-wrap .block-title {
  display: inline-block;
  color: #2056ab;
  font-size: 14px;
  margin: 14px 0;
  line-height: 1.25em;
  text-transform: uppercase;
  letter-spacing: 2px;
  font-weight: 800;
}

@media (max-width: 490px) {
  body.page-news .news-page .main-content .title-wrap .block-title, body.page-node-29 .news-page .main-content .title-wrap .block-title {
    font-size: 18px;
  }
}

body.page-news .news-page .main-content .title-wrap .block-title.video, body.page-node-29 .news-page .main-content .title-wrap .block-title.video {
  color: #fc9322;
}

body.page-news .news-page .main-content .title-wrap .block-title.video a, body.page-node-29 .news-page .main-content .title-wrap .block-title.video a {
  color: #fc9322;
}

body.page-news .news-page .main-content .title-wrap .block-title.latest a, body.page-node-29 .news-page .main-content .title-wrap .block-title.latest a {
  color: #2056ab;
}

body.page-news .news-page .main-content .title-wrap .block-title.indepth a, body.page-node-29 .news-page .main-content .title-wrap .block-title.indepth a {
  color: #46c4f3;
}

body.page-news .news-page .main-content .title-wrap .block-title.lifestyle a, body.page-node-29 .news-page .main-content .title-wrap .block-title.lifestyle a {
  color: #f45454;
}

body.page-news .news-page .main-content .title-wrap .custom-navigation, body.page-node-29 .news-page .main-content .title-wrap .custom-navigation {
  top: 9px;
  position: relative;
}

body.page-news .news-page .main-content .content-section.video .title, body.page-node-29 .news-page .main-content .content-section.video .title {
  font-size: 1.6rem;
}

.partner-content.news-bottom {
  pointer-events: auto;
  font-size: 12px;
  margin: 25px 0;
}

.partner-content.news-bottom a {
  display: inherit !important;
}

.partner-content.news-bottom .read-more {
  display: inline-block;
  padding: 10px 22px;
  color: #fff;
  text-transform: uppercase;
  font-size: 14px;
}

@media (max-width: 490px) {
  .partner-content.news-bottom .read-more {
    padding: 8px 22px;
  }
}

.partner-content.news-bottom .read-more.ecoworld {
  background: #00b398;
}

.partner-content.news-bottom .read-more.maybank {
  background: #fcc530;
  color: #000;
}

.partner-content.news-bottom p {
  margin-bottom: 11px;
  display: block;
  display: -webkit-box;
  /* max-width: 400px; */
  height: 50px;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  -o-text-overflow: ellipsis;
  text-overflow: ellipsis;
}

@media (max-width: 490px) {
  .partner-content.news-bottom p {
    padding: 0 !important;
    display: none;
  }
}

.partner-content.news-bottom .partner-desc {
  display: block;
  font-size: 21px;
  font-weight: 700;
  margin-bottom: 11px;
}

@media (max-width: 564px) {
  .partner-content.news-bottom .partner-desc {
    font-size: 16px;
  }
}

.partner-content.news-bottom .partner-bottom {
  border-bottom: 1px solid #ccc;
  display: block;
  margin: 10px 0;
  width: 100%;
}

.partner-content.news-bottom .partner-title {
  text-transform: uppercase;
  letter-spacing: .5px;
  color: #888;
  font-size: 10px;
  font-weight: 400;
  line-height: 20px;
  border-bottom: 1px solid #ccc;
  display: block;
  margin: 20px 0 10px;
}

@media (max-width: 767px) {
  .partner-content.news-bottom .partner-title {
    font-size: 13px;
    font-weight: normal;
    padding-bottom: 8px;
    margin: 0 0 12px;
    letter-spacing: 2px;
    line-height: 20px;
  }
}

.partner-content.news-bottom .partner-title h3 {
  margin-top: 0;
  padding-top: 0;
}

.partner-content.news-bottom .row {
  display: flex;
}

.partner-content.news-bottom .row .column-1, .partner-content.news-bottom .row .column-2 {
  flex: 35%;
  padding: 10px;
}

.partner-content.news-bottom .row .column-2 {
  flex: 65%;
}

@media (max-width: 768px) {
  .partner-content.news-bottom {
    padding: 0 20px;
  }
  .partner-content.news-bottom .col-sm-5 {
    width: 40%;
  }
  .partner-content.news-bottom .col-sm-7 {
    width: 60%;
  }
}

.page-node-12 article,
.page-node-13 article {
  margin-top: 52px;
}
