body {
  //Binastra’s Trion @ KL enjoys strong take-up rate
  &.page-content-1602443 {
    .news-body {
      &.content {
        figure {
          img {
            &.qrcode {

            }
          }
        }
      }
    }
  }
  &.page-node-10 {
    section.content-area.p-t-25.p-b-50 {
      margin-top: 30px;
    }
  }
}