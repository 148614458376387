.billboard {
    position: relative;
    z-index: 999;
    background: #fff;
}
.billboard div[id^='div-gpt-ad'],
.billboard > div[id^='div-gpt-ad'] {
    display: table; 
    margin: 0 auto;
    border-bottom: 30px solid #fff;
}