

$body_font: "Poppins", sans-serif;
$theme_color: #363a94;
$theme_color_light: #ccd9e0;
$color_white: #fff;
$color_grey: #666;
$color_grey_light: #888;
$color_grey_lightest: #f5f5f5;
$color_green: #1a8954;
$color_trans: transparent;
$background_color_4: rgba(3, 127, 184, .7);
$background_color_6: rgba(0, 65, 101, .5);
$background_color_7: rgba(0, 77, 118, .6);
$primary_homeadvisor_hero_bg: rgba(0, 0, 0, 0.43);