@charset "UTF-8";

// @import url('https://fonts.googleapis.com/css?family=Chivo:900');
// @import url('https://fonts.googleapis.com/css?family=Inknut+Antiqua');
$dkn-title: inherit;
$dkn-subtitle: inherit;
$dkn-color: #ffff5f;
$dkn-color2: #bfffde;
$dkn-shadow: 1px 7px 30px 2px rgb(188, 188, 188);
// $dkn-cover: 'https://media.edgeprop.my/cover/1440_1.jpg';
$dkn-cover: 'https://media.edgeprop.my/page/dkn/header-dkn-sepia.jpg';

#dkn-page {
  font-family: $dkn-title;
  .dkn-top {
    text-align: center;
    height: 48px;
    padding: 7px 0;
    display: block;
    overflow: hidden;
    background: #fff;
  }
  img {
    width: 100%;
  }
  a {
    color: #000;
    -webkit-transition: all .3s;
    -moz-transition: all .3s;
    transition: all 0.3s;
    &:hover {
      color: lighten( $dkn-color, 7%);
      text-decoration: none;
    }
  }
  hr {
    max-width: 100px;
    margin: 25px auto 0;
    border-width: 1px;
    border-color: rgba(34, 34, 34, 0.1);
    &.light {
      border-color: #fff;
    }
  }
  h1, h2, h3, h4, h5, h6 {
    color: #000;
    font-family: $dkn-title;
    font-weight: 700;
  }
  h3 {
    font-size: 4rem;
    line-height: 1;
    margin-bottom: 30px;
    text-transform: initial;
    @media (max-width: $break_tablet) { 
      font-size: 2.6rem;
    }
  }
  p {
    font-size: 1.6rem;
    line-height: 1.5;
    margin-bottom: 20px;
    @media (max-width: $break_tablet) {
      font-size: 1.3rem;
      line-height: 1.4;
    }
  }
  nav {
    &#lang {
      max-width: 985px;
      width: 90%;
      margin: 0 auto;
      font-size: 1.5rem;
      border-bottom: 1px solid #000;
      .nav-tabs {
        width: 100px;
        border-bottom: 0;
      }
      .nav-tabs, .nav-tabs:hover {
        // border-color: #000;
      }
      .nav-link {
        a {
          &:hover {
            color: inherit;
          }
        }
        &.active {
          background: transparent;
          border-color: #000 #000 $dkn-color;
          font-weight: 700;
        }
        &:hover {
          border-color: #000;
          border-bottom: 1px solid $dkn-color;
          font-weight: 700;
          color: #000;
        }
      }
    }
  }
  section {
    padding: 50px 0;
    li {
      font-size: 1.6rem;
      margin-bottom: 9px;
      @media (max-width: $break_tablet) {
        font-size: 1.3rem; 
        margin-bottom: 7px;
      }
    }
    &.general {
      border-top: 10px solid $dkn-color;
      position: relative;
      padding: 50px 0 50px;
      overflow: hidden;
      .overlay-header {
        width: 100%;
        background-color: rgba(0, 0, 0, 0.6);
        position: absolute;
        font-size: 68px;
        font-weight: 200;
        font-family: $dkn-title;
        font-style: italic;
        color: #fff;
        text-transform: capitalize;
        text-align: center;
        padding: 71px;
      }
      header {
        height: 244px;
        margin-bottom: 30px;
        background-position: center;
      }
      &.no-border {
        border-top: 0;
      }
      &.p-t-25 {
        padding-top: 25px;
      }
    }
    &.home {
      // position: relative;
      padding: 0;
      //   text-align: center;
      //   position: relative;
      //   z-index: 1;
      // }
      background: url($dkn-cover) no-repeat;
      -webkit-background-size: cover;
      -moz-background-size: cover;
      -o-background-size: cover;
      background-size: cover; 
      // .problem-content {
      // height: 90vh;
      display: block;
     overflow: hidden;
      .overlay {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.5);
      }
      .home-text {
        background: $dkn-color; 
        font-weight: 700;
        font-size: 5rem;
        line-height: 1;
        margin: 20px 0 0 15px;
        width: 100%;
        max-width: 380px;
        height: auto;
        padding: 57px 20px;
        color: #000;
        @media (max-width: $break_tablet) {
          font-size: 3rem;
        }
        @media (max-width: $break_m_x) { 
          width: 100%;
          max-width: 100%;
          padding: 20px;
          margin: 20px 0; 
        }
      }
      .priority {
        background: rgba(255, 255, 255, 0.85);
        font-weight: 700;
        font-size: 5rem;
        line-height: 1;
        margin: 20px 0 0 15px;
        // width: 100%;
        height: auto;
        padding: 15px 20px 20px;
        color: #000;
        text-align: center;
        @media (min-width: $break_post_d_sm) {
          float: right;
          width: 70%;
          position: relative;
          top: 45px;
        }
        @media (min-width: $break_tablet) and (max-width: $break_tablet) { 
          position: relative;
          top: 15px;
        }
        @media (max-width: $break_m_x) { 
          width: 100%;
          padding: 20px;
          margin: 20px 0;
          font-size: 4rem;
        }
        img {
          &.eplogo {
            width: 100%;
            max-width: 154px;
            height: auto;
            vertical-align: -webkit-baseline-middle;
            @media (min-width: $break_post_d_sm) {
              position: relative;
              top: 16px;
            }
          }
          &.kpktlogo {
            width: 100%;
            max-width: 108px;
            height: auto;
          }
        }
        span {
          &.dkn-priority-text {
            color: #000 !important;
            font-size: 1.4rem;
            font-weight: 500;
            margin: 10px 0 0;
            line-height: 1.2;
            padding: 10px;
            // position: relative;
            // top: 22px;
            @media (min-width: 992px) {
              &:hover {
                background: $dkn-color;
                color: #000;
              }
            }
            
            @media (max-width: $break_tablet) {
              font-size: 1.1rem;
              top: 12px;
            }

          }
        }
      }
      .news-area {
        h1 {
          color: #fff;
          border-bottom: 1px solid #fff;
          padding: 0 0 10px 0;
          margin: 20px 0;
          font-size: 2rem;
        }
        
        margin: 40px 0 20px;
        display: block;
        overflow: hidden;
        @media (max-width: $break_m_x) { 
          margin: 10px 0 20px;
          .col-6 {
            float: left;
          }
        }
        img {
          width: 100%;
          border-bottom: 1px solid #ccc;
        }
        a {
          &:hover {
            color: inherit;
          }
        }
        .news-title {
          display: block;
          font-size: 1.2rem;
          background: #fff;
          min-height: 120px;
          padding: 15px;
          background: #fff; 
          .meta {
            display: block;
            font-weight: 600;
          }
          &:hover {
            background: $dkn-color;
          }
          @media (min-width: 991px) {
            min-height: 138px;
          }
          @media (max-width: $break_d_md) {
            font-size: 1.1rem;
            padding: 10px; 
            margin-bottom: 15px;
          }
          @media (max-width: $break_m_sm) {
            min-height: 140px; 
          }
        }

      }
      .news-see-more {
        color: #fff;
        margin: 0 0 20px;
        font-size: 2rem;
        a, a:active {
          text-decoration: none;
          color: #fff;
          &:hover {
            text-decoration: underline;
          }
          i {
            font-size: 1.4rem;
          }
        }
      }
    }
    &.clusters {
      .cluster-icon {
        margin-bottom: 25px;
        cursor: pointer;
        @media (max-width: $break_m_x) {
          // margin-bottom: 45px;
        }
        &:hover {
          img {
            // box-shadow: $dkn-shadow;
            opacity: 0.5;
          }
        }
        img {
          margin-bottom: 10px;
          max-width: 170px;
        }
      }
      .cluster-title {
        font-size: 1.5rem;
        font-weight: 500;
        line-height: 1.2;
        @media (max-width: $break_m_x) {
          font-size: 1rem;
          line-height: 1.1;
        }
      }
      .col-6 {
        @media (min-width: $break_m_md) and (max-width: $break_m_md) {
          flex: none;
          max-width: 49%;
          float: none;
        }
        @media (max-width: $break_m_sm) {
          flex: none;
          max-width: 100%;
        }
      }
    }
    &.what-is-dkn {
      background: $dkn-color;
      ul {
        padding: 0 0 0 16px;
      }
    }
    &.problem {
      background: $dkn-color2;
    }
    &.help {
    }
    &.wheretohelp {
      background: $dkn-color2;
      .col-md-6 {
        &.statename {
          p {
            font-weight: 800;
            margin-bottom: 0px;
          }
        }
        @media (min-width: $break_tablet) and (max-width: $break_tablet) {
          float: left;
        }
      }
      ul{
        margin: 0 0 20px 0;
        padding: 0 0 0 18px;
        li {
          margin-bottom: 0px;
          font-size: 1.4rem;
        }
      }
      
    }
  }
  .m-b-20 {
    margin-bottom: 20px;
  }
  .no-float {
    float: none;
  }
  footer {
    .footer-top {
      text-align: center;
      color: #222;
      background: $dkn-color;
      padding: 15px 0;
      span {
        display: block;
        &:first-child {
          font-size: 4rem;
          @media (max-width: $break_m_x) {
            font-size: 2rem;
          }
          font-weight: 700;
        }
        &:nth-child(2) {
          font-size: 3.2rem;
          line-height: 1.1;
          margin-top: 15px;
          // font-weight: 900;
          @media (max-width: $break_m_x) {
            font-size: 1.5rem;
          }
        }
      }
    }
    a {
      &:hover {
        text-decoration: underline;
        color: inherit;
      }
    }
  }
  .volnow {
    padding: 1rem;
    font-size: 1.5rem;
    font-weight: 700;
    text-transform: uppercase;
    background: $dkn-color;
    border: 0;
    @media (min-width: 991px) {
      padding: 1rem 4rem;
    }
    &:hover {
      background: darken($dkn-color, 21%);
    }
  }
  #stickyNav {
    background-color: #333;
    border-color: rgba(34, 34, 34, 0.05);
    background-color: #000;
    -webkit-transition: all .3s;
    -moz-transition: all .3s;
    transition: all .3s;
    font-weight: 200;
    letter-spacing: 1px;
    border-radius: 0;
    margin-bottom: 0;
    &.stick {
      margin-top: 0 !important;
      width: 100%;
      position: fixed;
      top: 0;
      z-index: 10000;
    }
    .navbar {
      border-radius: 0 !important; 
    }
    .navbar-brand {
      color: $dkn-color;
      // font-family: Montserrat, sans-serif;
      font-weight: 200;
      letter-spacing: 1px;
      &:focus, &:hover {
        color: #b96700;
      }
    }
    .navbar-toggler {
      font-size: 12px;
      padding: 8px 10px;
      color: #fff;
      position: absolute;
      right: 10px;
      top: 10px;
    }
    .navbar-nav > li > a {
      font-weight: 500;
      text-transform: uppercase; 
      letter-spacing: 0.5px;
      @media (min-width: $break_d_md) {
        font-size: 1.4rem; 
      }
      @media (min-width: $break_tablet) and (max-width: $break_d_sm) {
        font-size: 1.1rem;
        line-height: 1;
      }
      &.active {
        color: $dkn-color !important;
        background-color: transparent;
        &:hover {
          background-color: transparent;
        }
      }
      color: #fff;
      &:focus {
        color: #fff;
        &:hover {
          color: $dkn-color;
        }
      }
      &:hover {
        color: $dkn-color;
      }
    }
    @media (min-width: $break_tablet) and (max-width: $break_tablet) {
      .navbar-nav {
        li {
          &:nth-child(4) {
            width: 70px;
          }
        }
      }
    }
  }
  .nav-pills .nav-link.active {
    border: 1px solid $dkn-color;
    color: $dkn-color;
    background-color: transparent;
  }
  .cluster-popup {
    border: 5px solid #000;
    box-shadow: $dkn-shadow;
    z-index: 999;
    // display: none;
    display: block;
    background: $dkn-color; 
    position: absolute;
    text-align: left;
    width: 92%;
    @media (min-width: $break_d_sm ) {
      width: 984px;
    }
    height: auto;
    padding: 25px;
    h1 {
      font-size: 3.2rem;
      @media (max-width: $break_tablet) {
        font-size: 1.8rem;
        text-align: center;
      }
    }
    li {
      margin-bottom: 14px;
      line-height: 1.1;
      font-size: 1.7rem;
      @media (max-width: $break_tablet) {
        font-size: 1.4rem;
      }
    }
    .cluster-close {
      position: absolute;
      right: -5px;
      top: -46px;
      background: #000;
      color: yellow;
      padding: 8px 30px;
      font-weight: 700;
      font-size: 2rem;
    }
    button.close {
      span {
        font-size: 5rem;
        font-weight: 100;
        line-height: .6;
      }
    }
    img {
      width: 80%;
      @media (max-width: $break_tablet) {
        width: 50%;
        max-width: 100px;
        text-align: center;
        margin: 20px auto;
        display: block;
      }
    }
    .col-md-7 {
      @media (max-width: $break_tablet) {
        margin: 0 auto;
      }
    }
  }
 
  @media (min-width: 992px) {
    .navbar-expand-lg .navbar-nav .nav-link {
      padding-right: 2rem;
    }
  }
  
  ul.list-unstyled li {
    margin-bottom: 20px;
    font-size: 13px;
    color: #666;
  }
  .ml-auto, .mx-auto {
    margin-left: auto !important;
  }
  .mr-auto, .mx-auto {
    margin-right: auto !important;
  }
  .fotorama {
    &__wrap {
      margin: 0 auto;
    }
    &__caption { 
      font-size: 2rem;
      // padding: 30px;
      width: 100%;
      margin: 0 auto;
      text-align: center;
      @media (min-width: $break_tablet) {
        bottom: 0;
      }
      @media (max-width: $break_pre_tablet) {
        font-size: 2rem;
        bottom: 0px;
        width: 100%;
      }
      
      a {
        text-decoration: none;
        border-bottom: 0;
      }
      span {
        display: block;
        &.slider-title {
          font-weight: bold;
          line-height: 1;
          margin-bottom: 6px;
          @media (max-width: $break_pre_tablet) {
            font-size: 1.7rem;
          }
        }
        &.slider-subtitle {
          font-size: 1.6rem;
          line-height: 1.5;
          @media (max-width: $break_pre_tablet) {
            font-size: 1.2rem; 
          }
          span.glyphicon {
            display: inline;
            font-size: 1.1rem;
          }
        }
      }
        .fotorama__caption__wrap {
          @media (min-width: $break_post_tablet) {
            padding: 5px !important;
            width: 100%;
          } 
        }
    }
    &__arr {
        background: 0 0 !important;
        &:before {
          font-family: Ionicons;
          content: "";
          margin-right: 6px;
          font-size: xx-large; 
          font-size: 58px;
          content: "";
          margin-right: 0;
          color: lighten( $dkn-color, 7%);
          position: relative;
          top: -30px;
          &:hover {
            color: $dkn-color;
          }
        }
        &.fotorama__arr--next:before {
          content: "";
        }
        &.fotorama__arr--prev:before {
          left: 8px;
          content: "";
        }
    }
    .fotorama__html {
      background-color: rgba #000, 0.6;
      span {
          display: block;
      }
      // a, div {
      //   display: block;
      //   height: 100%;
      //   // Transparent links are not clickable in IE,
      //   //    but non-existent background fixes this.
      //   //   (Put an empty 1×1 image here to avoid
      //   //    errors in console.)
      //   // background: url(https://media.edgeprop.my/ui/1px.png);
      //   background: url(_.gif);
      // }
    }
    .caption-element {
      top: 0;
      position: absolute;
      z-index: 999;
      right: 0;
      background: rgba(255, 255, 255, 0.9);
      width: 50%;
      height: 100vh;
      span {
        display: block;
        &.slider-title {
          font-weight: bold;
          line-height: 1;
          margin-bottom: 6px;
        }
        &.slider-subtitle {
          font-size: 2rem;
          line-height: 1.5;
          @media (max-width: $break_pre_tablet) {
            font-size: 1.2rem; 
          }
        }
      }
    }
  }
  span {
    display: block;
    &.caption-title {
      font-size: 1.2rem;
      font-weight: 100;
    }
  }

  @media (max-width: $break_tablet) {
    .logotype {
      img {
        width: 220px;  
      }
    }
  }
  @media (max-width: $break_pre_tablet) {
    .border-side {
      border-right: 0;
      border-left: 0; 
    }
  }
  @media (min-width: $break_post_d_sm) {
    #stickyNav {
      .navbar-brand {
        color: fade(white, 70%);
        &:focus, &:hover {
          color: #fff;
        }
      }
      .navbar-nav > li > a {
        color: rgba(255, 255, 255, 0.7);
        &:focus {
          color: rgba(255, 255, 255, 0.7);
          &:hover {
            color: #fff;
          }
        }
        &:hover {
          color: #fff;
        }
      }
      &.navbar-shrink {
        border-color: rgba(34, 34, 34, 0.1);
        background-color: #000;
        border-radius: 0;
        .navbar-brand {
          color: #f9f9f9;
          &:focus, &:hover {
            color: $dkn-color;
          }
        }
        .navbar-nav > li > a {
          color: #f9f9f9;
          &:focus {
            color: #f9f9f9;
            &:hover {
              color: $dkn-color;
            }
          }
          &:hover {
            color: $dkn-color;
          }
        }
      }
    }
  }
  @media (max-width: $break_m_lg) {
    .logotype {
      img {
         width: 130px; 
      }
    }
    section {
      &.general {
        padding: 10px 0 50px;
        h2 {
          font-size: 28px;
        } 
        .overlay-header {
          font-size: 33px;
          padding: 6px;
        }
        header {
          height: 61px;
          &.features-header {
             height: 60px; 
          }
        }
      }
      &.issue {
        padding: 0;
      }
    }
    .visible-xs {
      display: block;
    }
  }
  @media (min-width: 426px) {
    .visible-xs {
      display: none !important;
    }
  }
  // @media (min-width: 768px) and (max-width: 991px) {
  //   .navbar-collapse.collapse {
  //     display: none !important;
  //     &.in {
  //       display: block !important;
  //     }
  //   }
  //   .navbar-header .collapse, .navbar-toggle {
  //     display: block !important;
  //   }
  //   .navbar-header {
  //     float: none;
  //   }
  // }

  //bootstrap
  .navbar-nav {
    @media (max-width: $break_d_sm) {
      padding-left: 17px;
    }
    @media (min-width: $break_tablet) and (max-width: $break_tablet) {
      padding-left: 0px;
    }
  }
  .col-6  {
    float: left;
  }

  @media (max-width: $break_tablet){
    .cluster-popup .col-md-4 {
      max-width: 100%;
    }
  }
  //cluster popup for sm mobile
  @media (min-width: $break_tablet) and (max-width: $break_tablet) {
    #cluster_popup5, #cluster_popup6, #cluster_popup7, #cluster_popup8,
    #cluster_popup5_bm, #cluster_popup6_bm, #cluster_popup7_bm, #cluster_popup8_bm {
      top: 279px;
    }
  }
  @media (max-width: $break_m_x) {
    #cluster_popup3, #cluster_popup4,
    #cluster_popup3_bm, #cluster_popup4_bm {
      top: 263px;
    }
    #cluster_popup5, #cluster_popup6,
    #cluster_popup5_bm, #cluster_popup6_bm {
      top: 477px;
    }
    #cluster_popup7, #cluster_popup8,
    #cluster_popup7_bm, #cluster_popup8_bm {
      top: 710px;
    }
  }
  @media (max-width: $break_m_sm) {
    #cluster_popup2, #cluster_popup2_bm {
      top: 287px;
    }
    #cluster_popup3, #cluster_popup3_bm {
      top: 508px;
    }
    #cluster_popup4, #cluster_popup4_bm {
      top: 723px;
    }
    #cluster_popup5, #cluster_popup5_bm {
      top: 939px;
    }
    #cluster_popup6, #cluster_popup6_bm {
      top: 1164px;
    }
    #cluster_popup7, #cluster_popup7_bm {
      top: 1379px;
    }
    #cluster_popup8, #cluster_popup8_bm {
      top: 1597px;
    }
  }
  
}