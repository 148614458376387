#app-banner {
    @media(min-width: $break_tablet) {
        display: none;
    }
    z-index: 9999;
    background: #fff;
    border-bottom: 1px solid #e1e1e1;
    @media (max-width: $break_m_xx ) {
        display: none;
        img {
            width: 100%;
        }

    }
}
.normalize-fix {
    margin-bottom: 0 !important;
    .fixed-top-new {
        position: initial;
    }
}
.app-news-scroller {
    @media (min-width: 426px) {
        top: 158px !important;
    }
    @media (min-width: 480px) and (max-width: 480px) {
        top: 161px !important;
    }
    @media (max-width: $break_m_lg) {
        top: 149px !important;
    }
    @media (max-width: $break_m_i7) {
        top: 145px !important;
    }
    @media (max-width: $break_m_md) {
        top: 137px !important;
    }
    @media (max-width: $break_m_sam) {
        top: 133px !important;
    }
    @media (max-width: $break_m_sm) {
        top: 125px !important;
    }
}
.app-slider-position {
    margin-top: 0 !important;
}
.app-close {
    // display: none;
    position: fixed;
    top: 0;
    right: 0;
    color: #c9c9c9;
    line-height: 1;
    pointer-events: auto;
}
.app-maincontent {
    @media (max-width: $break_m_sam) {

    }
    @media (max-width: $break_m_i7) {

    }
    margin-bottom: 40px;
}