body.page-live,  
body.page-live2020 { 
  a {
    &:hover {
      color: lighten( $live2020-color, 7%);
    }
  }
  span.header-logotype { 
    color: $live2020-color !important;
  }
  .mag-box {
    .box { 
      &:hover {
        background: lighten( $live2020-color, 7%);
      }
    }
    .box-2 {
      background: $live2020-color;
    }
  }
  #mainNavLive {
    .navbar-brand {
      color: $live2020-color;
    }
    .navbar-nav > li > a {
      &.active,
      &:hover {
        color: $live2020-color !important;
      }
      &:focus {
        &:hover {
          color: $live2020-color;
        }
      }
    }
    @include yearDropDownItemHover($live2020-color);

    @media (min-width: $break_post_d_sm) {
      &.navbar-shrink {
        .navbar-brand {
          &:focus, &:hover {
            color: $live2020-color;
          }
        }
        .navbar-nav > li > a { 
          &:focus { 
            &:hover {
              color: $live2020-color;
            }
          }
          &:hover {
            color: $live2020-color;
          }
        }
      }
    }
  }
  section {
    &.general {
      border-top: 10px solid $live2020-color;
    }
    h4 {
      color: $live2020-color;
    }
  }
  .bg-primary {
    background: $live2020-color;
    background: -webkit-linear-gradient($live2020-color, #d37500);
    background: linear-gradient($live2020-color, #d37500);
  }
  .text-primary {
      color: $live2020-color;
  }
  .btn-outline { 
    &.active, &:active, &:focus, &:hover { 
      border-color: $live2020-color;
      background-color: $live2020-color;
    }
  }
  .nav-pills .nav-link.active {
    border: 1px solid $live2020-color;
    color: $live2020-color; 
  }
  .fotorama {
    &__arr {
      &:before {
        color: lighten( $live2020-color, 7%);
        &:hover {
          color: $live2020-color;
        }
      }
    }
  }
  

}