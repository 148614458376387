/* ---------------------------------------- */
/*  Custom:Drupal
/* ---------------------------------------- */
// Layout ----------
// .no-sidebars
// .one-sidebar
//   .sidebar-first
//   .sidebar-second
// .two-sidebars
//   .sidebar-first
//   .sidebar-second

.content {
  @media (min-width: 992px) {
    &-area {
      .content-column {
        width: calc(100% - #{$layout-width});
      }
      .sidebar {
        width: $layout-width;
      }
    }
  }
}

.one-sidebar {
  &.sidebar-first {
    .content {
      @media (min-width: 992px) {
        &-area {
          .content-column {
            left: $layout-width;
          }
          .sidebar-first {
            right: calc(100% - #{$layout-width});
          }
        }
      }
    }
  }
}

.two-sidebars {
  .content {
    @media (min-width: 992px) {
      &-area {
        .content-column {
          width: calc(100% - #{($layout-width / 1.5) * 2});
          left: ($layout-width / 1.5);
        }
        .sidebar {
          width: ($layout-width / 1.5);
          &-first {
            right: calc(100% - #{($layout-width / 1.5) * 2});
          }
        }
      }
    }
  }
}

.no-sidebars {
  .content {
    @media (min-width: 992px) {
      &-area {
        .content-column {
          width: 100%;
        }
      }
    }
  }
}

// Region ----------
// .container in different region set as full width
.header-top,
.header-nav,
.footer-top,
.footer-bottom {
  > .container {
    width: 100%;
  }
}

// Module ----------
.module-dev {
  font-size: 1.2rem;
  .alert-block {
    margin-bottom: 0;
    border-radius: 0;
    .placeholder {
      font-weight: 500;
      color: #650e0e;
    }
    ul {
      padding-left: 0;
      margin-bottom: 0;
      list-style: none;
      > li {
        margin-bottom: 0.5em;
        padding-left: 1em;
        position: relative;
        &:before {
          content: '\f3d1';
          display: inline-block;
          font-family: $font-ionic;
          font-weight: normal;
          line-height: 1;
          position: absolute;
          top: 0.25em;
          left: 0;
        }
      }
    }
  }
}

// Admin Menu ------
.admin-menu {
  // Adminimal Menu
  &.adminimal-menu {
    .header {
      top: 2.9rem;
    }
  }
  //- Responsive ---------------------------
  @media (max-width: 1024px) {
    .user-bar {
      .dropdown-menu {
        margin-top: 0;
      }
    }
  }
}
@media (max-width: 767px) {
  #admin-menu,
  body.admin-menu.adminimal-menu:before {
    display: none;
  }
  .admin-menu.adminimal-menu .header {
    top: 0;
  }
}