//------------------------------
// Profile
//------------------------------
.profile {
  // Interests
  &-interests {
    .interests {
      &-heading {
        margin-top: 0;
        margin-bottom: 1.273em;
        font-size: 1.286em;
        font-weight: 600;
        text-align: center;
      }
      &-board {
        position: relative;
      }
      &-card,
      &-card-block {
        background-color: mix($theme-color, $white, 5%);
        color: $theme-color;
        text-align: center;
        position: relative;
        cursor: pointer;
        border-radius: 0.857em;
        transition: box-shadow 150ms ease-in-out;
      }
      &-card-block {
        padding: 0.857em;
        .material-icons {
          margin-top: -0.08em;
          margin-bottom: -0.08em;
          margin-right: 0.2em;
          color: $grey-color5;
        }
        // Selected
        &.selected {
          .material-icons {
            color: $theme-color-alt;
          }
        }
      }
      &-content {
        padding-top: 1.429em;
        padding-bottom: 0.714em;
      }
      &-triangle {
        width: 0;
        height: 0;
        border-top: 1.929em solid $grey-color5;
        border-right: 1.929em solid $grey-color5;
        border-left: 1.929em solid transparent;
        border-bottom: 1.929em solid transparent;
        border-top-right-radius: 0.857em;
        position: absolute;
        top: 0;
        right: 0;
        .material-icons {
          margin-top: -1.786em;
          color: $white;
        }
        &.border {
          border-top: 2.071em solid $white;
          border-right: 2.071em solid $white;
          border-left: 2.071em solid transparent;
          border-bottom: 2.071em solid transparent;
        }
      }
      &-icon {
        font-size: 1.714em;
        transition: color 150ms ease-in-out;
      }
      &-label {
        margin-top: 0.429em;
        font-size: 1em;
        color: $base-color;
      }
      &-timeframe {
        margin-top: 0.714em;
        font-size: 0.857em;
        color: $grey-text;
        visibility: hidden;
      }
      // Hover
      &-card:hover,
      &-card-block:hover {
        color: $theme-color-alt;
        box-shadow: 0 0 0 0.2rem rgba($theme-color, 0.2);
      }
      // Modal
      &-modal {
        padding: 2.857em;
        background-color: mix($theme-color, $white, 5%);
        color: $theme-color;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        border-radius: 0.857em;
        box-shadow: 0 0 0 0.2rem rgba($black, 0.07), 0 0.1rem 0.8rem 0 rgba($black, 0.1);
        transition: all 300ms ease-in-out;
        &-header {
          margin-bottom: 2em;
          color: $theme-color;
          font-weight: 600;
          h2 {
            margin-top: 0.357em;
            margin-bottom: 1em;
            font-size: 1.714em;
          }
          p {
            font-size: 1.143em;
          }
          .interests-icon {
            margin-top: 0.857em;
            color: $theme-color-alt;
          }
        }
        &-content {
          .i-form {
            .input,
            .select {
              border-width: 0.2rem;
            }
          }
        }
        &-close {
          padding: 0.857em;
          cursor: pointer;
          position: absolute;
          top: 0;
          right: 0;
        }
        // Off
        &.off {
          visibility: hidden;
          opacity: 0;
          transform: scale(0);
        }
      }
    }
    // Selected Card
    .selected {
      .interests {
        &-triangle {
          &:not(.border) {
            border-right-color: $theme-color-alt;
            border-top-color: $theme-color-alt;
          }
        }
        &-icon {
          color: $theme-color-alt;
        }
        &-timeframe {
          visibility: visible;
        }
      }
    }
  }
  // Details
  &-details {
    padding-top: 2em;
    border-top: 0.1rem solid $grey-color6;
    .block-title {
      font-size: 1.571em;
      text-align: center;
    }
    .i-form {
      h4 {
        margin-bottom: 0.4em;
        font-size: 1.143em;
        font-weight: 600;
      }
      .input,
      .select {
        border-width: 0.2rem;
      }
      .checkbox,
      .radio {
        margin-top: 1.2rem;
        margin-bottom: 1.2rem;
        label {
          &:before {
            border-width: 0.2rem;
          }
        }
      }
    }
  }
  //- Responsive ---------------------------
  @media (min-width: 768px) {
    &-interests {
      .interests {
        &-heading {
          font-size: 1.571em;
        }
        &-content {
          padding-top: 2.857em;
          padding-bottom: 1.429em;
        }
        &-icon {
          font-size: 2.5em;
        }
        &-label {
          font-size: 1.286em;
        }
      }
    }
  }
}
//- Pages --------------------------------
.page-profile {
  .page-header {
    padding-bottom: 1.2em;
    text-align: center;
    position: relative;
    &:after {
      content: '';
      width: 30%;
      height: 0.1rem;
      margin-left: -15%;
      background-color: $grey-color6;
      position: absolute;
      left: 50%;
      bottom: 0.5rem;
    }
  }
}

/***** ABANDON *****/ 
.password-edit {
  display: none;
  &.active {
    display: block;
  }
}
.password {
  display: block;
  &.active {
    display: none;
  }
}
.email-edit {
  display: none;
  &.active {
    display: block;
  }
}
.email {
  display: block;
  &.active {
    display: none;
  }
}
.edit {
  &-col {
    background-color: #eee;
    padding: 2rem 5rem 5rem 5rem;
  }
  &-header {
    position: relative;
  }
  &-join {
    position: absolute;
    bottom: 0;
    right: 0;
    margin-bottom: 10px;
  }
}
// User Profile
.user-profile {
  hr {
    border-top: 1px solid #d6d6d6;
  }
  input[type="file"] {
    display: none;
  }
  .i-form .input {
    input {
      &.narrow {
        min-height: 3.5rem;
      }
    }
    &.narrow {
      min-height: 3.7rem;
    }
  }
  .i-form .select {
    select {
      &.narrow {
        min-height: 3.5rem;
      }
    }
    &.narrow {
      min-height: 3.7rem;
    }
    &.narrow:after {
      top: 1.51667rem;
    }
  }
  .i-segment {
    &.seg-grey {
      background-color: #eee;
    }
  }
}
.profile {
  &.i-menu {
    border-radius: 0;
    border-right: 0;
    i {
      margin-left: 1.5rem;
      margin-right: 1.5rem;
    }
    a {
      color: #333;
      &.active {
        background-color: #eee;
      }
      &.item:after {
        content: '';
      }
    }
  }
  &-upload {
    display: inline-block;
    padding: 0;
    cursor: pointer;
    color: #c0c0c0;
    background-color: #eee;
    text-align: center;
    width: 100%;
    height: 100%;
    position: relative;
    text-align: center;
    padding-top: 20%;
  }
}
.display {
  height: 12rem;
  width: 12rem;
  border: 1px dashed #ccc;
  overflow: hidden;
  position: relative;
  display: block;
  img {
    margin: auto;
    position: absolute;
    top: -50%;
    bottom: -50%;
    left: -50%;
    right: -50%;
    z-index: 1;
    pointer-events: none;
  }
  .landscape {
    max-height: 100%;
  }
  .portrait {
    max-width: 100%;
  }
}