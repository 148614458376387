.widget {
    &-tem-search {
        margin-bottom: 32px;
        border: none;
        width: 300px;
        height: 373px;
        &.news-banner-ad {
            height: 543px;
            @media (max-width: 1023px) {
                margin-top: -10px;
            }
        }
        @media (min-width: $break_post_d) {
            margin-left: 8px;
        }
        @media (max-width: $break_d_md) {
            margin-left: 0;
        }
        @media (max-width: 820px) {
            margin-left: 25%;
        }
        @media (max-width: 767px) {
            margin-left: 11.4%;
        }
        @media (max-width: 480px) {
            margin-left: 18.4%;
        }
        @media (max-width:425px) {
            margin-left: 15%;
        }
        @media (max-width: 414px) {
            margin-left: 14%;
        }
        @media (max-width: 375px) {
            margin-left: 10%;
        }
        @media (max-width: 360px) {
            margin-left: 8%;
        }
        @media (max-width: $break_m_sm) {
            margin-left: 2.4%;
        }
        // new
        // @media (min-width: $break_post_d) {
        //     margin-left: 8px;
        // }
        // @media (max-width: $break_d_md) {
        //     margin-left: 0;
        // }
        // @media (max-width: 800px) {
        //     margin-left: 26.5%;
        // }
        // @media (max-width: 767px) {
        //     margin-left: 14.4%;
        // }
        // @media (max-width: 425px) {
        //     margin-left: 15%;
        // }
        // @media (max-width: 414px) {
        //     margin-left: 13.7%;
        // }
        // @media (max-width: 375px) {
        //     margin-left: 10%;
        // }
        // @media (max-width: 360px) {
        //     margin-left: 8%;
        // }
        // @media (max-width: 320px) {
        //     margin-left: 2.8%;
        // }
    }
}