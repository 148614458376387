#homeadvisor {
    .page {
        .home {
            .section-intro {
                .hero-image {
                    margin-left: -15px;
                    margin-right: -15px;
                    margin-bottom: 30px;
                    .hero-image-inner {
                        position: relative;
                        width: 100%;
                        height: 400px;
                        background-color: #e2eff5;
                        // background-image: url(https://media.gettyimages.com/photos/stressful-citylife-picture-id626454234);
                        background-size: contain;
                        background-position: center;
                        background-repeat: no-repeat;
                        @media screen and (min-width: 992px) { 
                            background-size: cover;
                            background-position: bottom center; 
                            height: 100%;
                        }
                        @media screen and (min-width:480px) { 
                            background-size: contain;
                            background-position: bottom center; 
                        } 
                    }
                    @media screen and (min-width: 992px) {
                        width: 100%;
                        height: 520px;
                        // width: 100%;
                        // max-width: 380px;
                        // max-height: 446px;
                    }
                    @media screen and (min-width: $break_tablet) {
                        margin-bottom: 0;
                        // float: right;
                    }
                    img {
                        width: 418px;
                        height: auto; 
                        @media screen and (min-width: $break_tablet) {
                            width: 418px;
                            float: right;
                        }
                        @media screen and (max-width: $break_d_sm) {
                            width: 325px;
                        }
                        @media screen and (max-width: $break_m_lg) {
                            width: 100%;
                            height: auto;
                        }
                    }
                }
            }
        }
        article {
            &.social {
                .equity,
                &.equity {
                    &.hero-image {
                        &:before {
                            position: absolute;
                            top: 0;
                            right: 0;
                            bottom: 0;
                            left: 0;
                            content: '';
                            display: block;
                            background-color: rgba(0, 65, 101, .2);
                        }
                        .row {
                            &:first-child {
                                // background-image: url(../img/article/social-whats-an-equity-xs.jpg);
                                background-size: cover;
                                background-position: top left;
                                background-repeat: no-repeat;
                                &:before {
                                    position: absolute;
                                    top: 0;
                                    right: 0;
                                    bottom: 0;
                                    left: 0;
                                    content: '';
                                    display: block;
                                    background-color: rgba(0, 65, 101, .2);
                                    @media screen and (min-width: $break_tablet) {
                                        display: none; 
                                    }
                                }
                                @media screen and (min-width: $break_tablet) {
                                    background-image: none;
                                }
                            }
                        }
                        @media screen and (min-width: $break_tablet) {
                            // background-image: url(../img/article/social-whats-an-equity-large.jpg);
                            background-size: cover;
                            background-position: top center;
                            background-repeat: no-repeat;
                        }
                    }
                }
            }
        }
    }
 }
