//------------------------------
// Advertisment
//------------------------------
.leaderboard {
  position: relative;
  &-wrapper {
    margin-left: auto;
    margin-right: auto;
    img {
      max-width: 100%;
      height: auto;
      margin-left: auto;
      margin-right: auto;
    }
  } // Section
  &-1 {
    // padding-top: 1.5rem;
    // padding-bottom: 1.5rem;
    background-color: #f8f9f9;
    .leaderboard-wrapper>.block>div[id]>div[id] {
      // padding-top: 1.5rem;
      // padding-bottom: 1.5rem;
    }
  }
}
div[id^='div-gpt-ad'] {
  margin: auto;
}

.block-adbutton > div[id^='div-gpt-ad']:first-of-type {
  margin-bottom: 2.5rem;
}

.admin-menu.adminimal-menu .banderole {
  top: 2.9rem;
}
.banderole {
  height: 100vh;
  background: rgba(0,0,0,.65);
  z-index: 3000;
  position: fixed;
  top: 0;
  width: 100%;
  left: -100%;
  transition: all .5s .2s ease-in-out;
  &.in {
    left: 0;
    transition: all .5s .2s ease-in-out;
    .slide-trigger{
      left: -120px;
      transition: all .5s ease-in-out;
    }
  }
  &-content {
    position: relative;
    > div[id^='div-gpt-ad'] {
      display: table;
      margin: 35px auto;
    }
  }
  .slide-trigger {
    position: absolute;
    top: 50%;
    left: 100%;
    height: 250px;
    width: 100px;
    box-shadow: 1px 1px 5px rgba(25, 25, 25, .5);
    transform: translate(0%, -50%);
    cursor: pointer;
    opacity: 0;
    transition: all .5s .55s ease-in-out;
    &.on{
      opacity: 1;
    }
  }
  .slide-close {
    position: absolute;
    top: 0;
    color: white;
    right: calc((100% - 980px) / 2);
    padding: 0 10px;
    font-size: 28px;
    z-index: 1;
    cursor: pointer;
  }
}
//banderole responsive
@media (max-width: 1061px) {
  .banderole {
    height: 569px;
    &-content > div[id^='div-gpt-ad'] {
      margin: 150px auto;
    }
    .slide-close {
      right: calc((100% - 580px) / 2);
    }
  }
}
@media (max-width: 767px) {
  .admin-menu.adminimal-menu .banderole {
    top: 0;
  }
}
@media (max-width: 619px) {
  .banderole {
    height: 320px;
    &-content > div[id^='div-gpt-ad'] {
      margin: 35px auto;
    }
    .slide-close {
      right: calc((100% - 300px) / 2);
    }
  }
}

//------------------------------
// Text Ads
//------------------------------
.textAds {
  position: absolute;
  bottom: 0;
  width: 100%;
  text-align: center;
  color: white;
  background-color: rgba(0, 92, 152, .5);
  height: 50px;
  line-height: 50px;
  font-size: 1.4rem;
  letter-spacing: 0.05em;
  > * {
    padding-right: 1rem;
  }
  i {
    border: 2px solid;
    border-radius: 50%;
    padding: .5rem;
    width: 3.2rem;
    height: 3.2rem;
    margin-right: 1rem;
  }
  img {
    max-width: 4rem;
  }
  button {
    background-color: $link-color-19;    
    color: $white;
    padding: 0;
    > a {
      color: rgba(255, 255, 255, .8);
      display: block;
      padding: .6rem 1.2rem;
      &:hover {
        color: rgba(255, 255, 255, 1);
        text-decoration: none;
      }
      &:active, 
      &:focus {
        text-decoration: none;
      }
    }
  }
}

//----------------------
// for temp
//----------------------
.agt-registration {
  background-color: #f4eb49;
  position: absolute;
  top: 10rem;
  right: -180px;
  max-width: 180px;  
  font-size: 1rem;
  letter-spacing: 0.03em;
  transition: all .3s ease-in-out;
  &.in {  
    right: 0px;
    transition: all .3s ease-in-out;
  }
  a {
    color: black;
    display: block;
    padding: .6rem 1rem;
    &:hover, &:active, &:focus {
      text-decoration: none;
    }
  }
}
#hero-banner.hero-banner-container{
  padding: 145px 0 50px;
}
