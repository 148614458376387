//------------------------------
// Hero
//------------------------------
.hero {
  &-tron {
    margin-bottom: 0;
    padding-top: 8rem;
    padding-bottom: 6rem;
    background-color: transparent;
    color: $white;
    position: relative;
    .container {
      position: relative;
    }
    h1 {
      margin-top: 8rem;
      margin-bottom: 0;
      line-height: 1;
    }
  }
  &-apps,
  &-social {
    margin-top: 0.5rem;
  }
  &-bg {
    background-position: center bottom;
    background-repeat: no-repeat;
    background-size: contain;
    background-color: #f5f6f8;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: -2;
    &.fixed {
      background-attachment: fixed;
    }
    &.cover {
      background-size: cover;
      // &.merdeka18 {
      //   @media only screen and (max-width: 768px) and (min-width: 500px) {
      //     background-image: url(https://media.edgeprop.my/cover/merdeka2018v2-tab-min.jpg)!important;
      //   }
      // }
      &.event-tab{
        @media only screen and (min-width: 768px) and (max-width: 1023px) {
          background-image: url(https://media.edgeprop.my/cover/cny19/cny19-t3.jpg) !important;
        }
        @media (min-width: 320px) and (max-width: 480px) {
          background-image: url(https://media.edgeprop.my/cover/cny19/cny19-m3.jpg) !important;
        }
      }
    }
  }
  &-gradient {
    width: 100%;
    height: 100%;
    // background-image: url('../img/hero/hero-gradient.png');
    background-size: cover;
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
  }
  //- Responsive ---------------------------
  @media (max-width: 767px) {
    &-tron {
      padding-top: 4rem;
      padding-bottom: 4rem;
    }
    &-apps {
      margin-top: 0;
      text-align: center;
      position: absolute;
      left: 0;
      right: 0;
      bottom: 0;
    }
    &-social {
      display: none;
      margin-top: 0.5rem;
      text-align: center;
    }
  }
  @media (min-width: 768px) and (max-width: 1199px) {
    &-apps,
    &-social {
      margin-top: 0;
      position: absolute;
      bottom: 0;
    }
    &-apps {
      left: 0.6rem;
    }
    &-social {
      right: 0.6rem;
    }
  }
  @media (min-width: 1200px) {
    &-apps,
    &-social {
      position: absolute;
      bottom: 1rem;
    }
    &-apps {
      left: 1rem;
    }
    &-social {
      right: 1rem;
    }
  }
}