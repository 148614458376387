#bmaentry {
    //footer
  footer {
    text-align: center;
    margin-bottom: 30px;
    .bma-footer {
      &.desktop {
        display: none;
        max-width: 1300px;
        margin: 0 auto;
        @media (min-width: 1024px) and (max-width: 1299px) {
          width: 100%;
        }
        @media (min-width: 769px) {
          display: block;
        }
      }
      &.mobile {
        max-width: 320px;
        display: none;
        margin: 0 auto;
        @media (max-width: $break_tablet) {
          display: block;
        }
      }
    }
  }
}
#bmaentry {
  color: #fff;
  font-size: 11px;
  .highlight {
    color: #ffd900;
  }
  .padnull {
    padding: 0;
    @media (max-width: 768px) {
      text-align: center;
    }
  }
  .m-t-25 {
    margin-top: 25px;
  }
  h4, h5 {
    color: #ffd900;
  }
  h4 span.relax {
    font-weight: 200;
    margin: 0;
    display: block;
    color: #fff;
  }
  p.p-sm {
    font-size: 10px;
    @media (max-width: 768px) {
      text-align: center;
    }
  }
  .bg-full {
    background-image: url('https://media.edgeprop.my/page/bmpa/2020/submission/awards2020-bg.jpg');
    background-repeat: no-repeat;
    background-position: left center;
    background-size: cover; 
    max-width: 100%;
    padding: 0;
    width: 100%;
    @media (max-width: 768px) {
      background-position: 47% 0%;
    }
    &.v21 {
      background-image: url('https://media.edgeprop.my/page/bmpa/2021/entry/2021-bg.jpg');
      .big-btn {
        position: relative;
        // left: 15px;
        // top: -29px;
        left: 2px;
        top: -131px;
        max-width: 97%;
        &-sm {
          display: none;
          @media (max-width: 768px) {
            display: block;
            position: initial;
            margin-top: 30px;
            width: 100%;
          }
        }
      }
      .sm-btn {
        width: 100%;
      }
      .past-foot {
        @media (min-width: 1024px) {
          bottom: -586px;
        }
      }
      .box {
        background: #6f71d9;
        padding: 5px 8px;
        color: #fff;
        margin-bottom: 10px;
        &.box-specialised {
          background: #924ae5;
        }
        &.box-guidelines {
          background: #b53bb8;
        }
        &.box-responsible {
          background: #7da24a;
        }
        &.box-ilam {
          background: #9b764a;
        }
        ul {
          margin-bottom: 0;
        }
      }
    }
  }
  img {
    &.kpkt {
      width: 100%;
      max-width: 124px;
      margin: 13px 0 0 0;
    }
    &.bmpa-logo {
      position: relative;
      top: 20px;
      /* width: 100%; */
      max-width: 518px;
      @media (max-width: 768px) {
        width: 100%;
      }
    }
  }
  .a20-wrapper {
    ul {
      padding: 0 0 0 15px;
    }
    h4, h5 {
      @media (max-width: 768px) { 
        text-align: center;
        margin-top: 25px; 
      }
    }
  }
  button {
    border: 0;
    border-radius: 5px;
    background: #fff;
    color: #444;
    text-transform: uppercase;
    font-weight: 700;
    padding: 4px 10px;
    margin: 5px 0;
    width: 100%;
    &:hover {
      background: #fff3ae;
    }
    @media (max-width: 768px) {
      width: 100%;
    }
    &.primary-bma {
      background: #ffd900;
      color: #000;
      padding: 10px;
      font-size: 13px;
      margin-bottom: 22px;
      &:hover {
        background: #f2cf07;
      }
    }
  }
  .bg-opacity {
    background-color: rgba(0, 0, 0, .5);
    background-position: left center;
    background-size: cover;
  }
  /* footer area */
  .a20-footer {
    color: #444;
    padding: 20px 0 50px;
    .a20-title {
      display: block;
    }
    ul.logo-supported {
      padding: 0;
      margin-top: 5px;
      li {
        display: inline-block;
        padding-right: 10px;
        img {
          width: 85%;
        }
      }
    }
    img {
      margin-top: 10px;
      position: relative;
      &.logo-supporting-partner {
        width: 114px; 
      }
    }
    @media(min-width: 1024px) {
      .col-md-3 {
        /* width: 20%; */
      }
      .col-md-2 {
        /* width: 13%; */
      }
      .col-md-5 {
        /* width: 54%; */
      }
      .col-md-3,
      .col-md-2,
      .col-md-5 {
        text-align: left;
      }
      img {
        &.logo-present {
          top: 11px;
        }
        &.logo-partner {
          left: -8px;
          width: 115px;
          bottom: -5px;
        }
        &.logo-supporting-partner {
          top: 20px;
        }
        
      }
    }
    @media (max-width: 768px) {
      .col-md-2,
      .col-md-3,
      .col-md-8,
      .a20-title,
      ul.logo-supported {
        text-align: center;
      }
      .a20-title {
        margin-top: 30px;
      }
    } 
  } 
  .past-foot {
    a {
      font-size: 10px;
      color: #fff;
      border-bottom: 1px solid #ffd900;
      text-decoration: none;
      @media (max-width: 768px) {
        font-size: 9px;
      }
    }
    @media(min-width: 1024px) {
      position: absolute;
      bottom: -384px;
      .d-footnote {
        position: relative;
        bottom: -79px;  
      }
    }
    @media (max-width: 768px) {
      text-align: center; 
    }
  }
  .container {
    &.cont-top {
      @media(min-width: 1440px) { 
        width: 100%;
        max-width: 1250px; 
      }
      @media(min-width: 1366px) { 
        width: 100%;
        max-width: 1240px; 
      }
    }
    &.a20-container {
      @media(min-width: 1024px) {
        max-width: 100%;
        width: 100%;
        margin: 0 auto;
        text-align: center;
      }

    }
  }
  .audit {
    @media (max-width: 768px) {
      margin: 10px 0;
    }
  }
  .m-kpkt {
    @media (max-width: 768px) {
      margin-top: 40px;
    }
  }
}

body.page-awards2020entry .intercom-app {
  display: none !important;
}
 
 