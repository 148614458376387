//------------------------------
// User
//------------------------------
/* User */
.user {
  // Header: Login Bar
  &-bar {
    float: left;
    margin-top: 1.5rem;
    margin-bottom: 1.5rem;
    transition: transform 300ms ease-in-out;
    > li {
      position: relative;
      + li { margin-left: 0; }
      > a {
        padding-top: 1rem;
        padding-bottom: 1rem;
        color: $header-nav-link-color;
        color: rgba($header-nav-link-color, 0.75);
        transition: all 200ms ease-in-out;
        &:hover,
        &:focus {
          background-color: transparent;
          color: $header-nav-link-color;
        }
        // Ghost Button
        &.ghost {
          border: 0.1rem solid;
          font-size: 1.3rem;
          line-height: 2rem;
          text-transform: uppercase;
          border-radius: 0;
          color: rgba($white, 0.8);
          .ion-person { display: none; }
        }
        // Icon
        &.icon {
          font-size: 2.2rem;
          line-height: 0;
          .ion-icon { line-height: inherit; }
        }
      }
    }
    .open {
      > a,
      > a:hover,
      > a:focus {
        background-color: transparent;
        border-color: $header-nav-link-hover;
        color: $header-nav-link-hover;
      }
    }
  }
  // Page: Sign up / Sign in screen
  &-wrap {
    margin-left: auto;
    margin-right: auto;
    &.user-signup {
      max-width: 48rem;
    }
    &.user-signin {
      max-width: 36rem;
    }
  }
  //- Reponsive ------------------
  @media (max-width: 1024px) {
    &-bar {
      float: none;
      margin-top: 0;
      margin-bottom: 0;
      margin-right: -1.5rem;
      transition: none;
      > li {
        > a {
          padding-top: 1rem;
          padding-bottom: 1rem;
          &.ghost {
            padding-left: 1rem;
            padding-right: 1rem;
            border: 0;
            line-height: 1;
            .ion-icon {
              display: inline-block;
              vertical-align: middle;
              font-size: 2rem;
              color: $base-color;
            }
            // Hide person icon
            .ion-person {
              + span {
                display: none;
              }
            }
          }
          &.icon {
            width: 5rem;
            height: 5rem;
            padding: 0;
            line-height: 5rem;
            text-align: center;
          }
        }
      }
      .dropdown-menu {
        margin-top: 0;
        top: 5rem;
        border-radius: 0;
        > li {
          > a {
            padding-top: 1rem;
            padding-bottom: 1rem;
          }
          &:first-child,
          &:last-child {
            > a {
              border-radius: 0;
            }
          }
        }
      }
    }
  }
  @media (min-width: 1025px) {
    &-bar {
      .dropdown-menu {
        left: auto;
        right: 0;
      }
    }
  }
}

//------------------------------
// User for transparent header
//------------------------------
// .page-agent-search,
// .page-agent,
// .area-outlook-landing,
// .project-outlook-landing,
// .page-node-15,
// .page-transaction,
// .page-edgehome2k18 {
//   .user-bar > li > a, #modal_trigger{
//     color: rgba($white, 0.75);
//     &:hover,
//     &:focus {
//       background-color: transparent;
//       color: $white;
//     }
//   }
// }
/* User Property (My Property) */
.mp {
  &-module {
    padding-top: 5rem;
    padding-bottom: 5rem;
    &-full {
      padding-top: 0;
      padding-bottom: 0;
    }
    .module {
      &-title {
        font-size: 2.4rem;
        &:after { display: none; }
      }
      &-subtitle {
        margin-top: 0;
        font-size: 1.5rem;
      }
      &-title {
        + .module-subtitle {
          margin-top: -0.5em;
        }
      }
    }
  }
  &-breadcrumb {
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    .breadcrumb {
      padding-top: 1.35rem;
      padding-bottom: 1.35rem;
    }
  }
  &-quicklink {
    .mp-bar {
      width: 100%;
      background-color: $bg-color;
      box-shadow: 0 0 0 0.1rem rgba($base-color, 0.1);
      position: relative;
      z-index: 1;
      .i-tabs {
        margin-bottom: 0;
        border: 0;
        background-color: transparent;
        > li {
          + li {
            &:before {
              content: '';
              display: block;
              width: 0.1rem;
              background-color: $grey-color5;
              position: absolute;
              top: 0.7rem;
              bottom: 0.7rem;
            }
          }
          > a {
            margin: 0;
            padding: 1rem;
            border: 0;
            background-color: transparent;
          }
          &.active {
            > a,
            > a:hover,
            > a:focus {
              border: 0;
            }
          }
        }
      }
    }
    .mp-spacer { display: none; }
    // Sticky Bar
    &.mp-sticky {
      .mp-bar {
        position: fixed;
        z-index: 3;
        box-shadow: 0 0.2rem 0.6rem 0 rgba($base-color, 0.1), 0 0.1rem 0 0 rgba($base-color, 0.08);
        transform: translate3d(0,0,0); // Fixed jumping @ chrome
      }
    }
  }
  &-slider {
    background-color: $base-color;
  }
  &-summary {
    padding-top: 2rem;
    padding-bottom: 2rem;
    .property {
      &-summary {
        min-height: 18rem;
        position: relative;
      }
      &-details {
        padding-right: 34rem;
        .title {
          margin-top: 0;
          font-weight: 300;
          span {
            display: block;
            margin-top: 0.5em;
            color: $grey-text;
            font-size: 1.6rem;
          }
        }
        .icon {
          font-size: 1.6rem;
          .fa {
            color: $dark-text;
          }
        }
        .claim-home {
          color: $dark-text;
          a {
            color: inherit;
            text-decoration: underline;
            &:hover {
              text-decoration: none;
            }
          }
        }
      }
      &-value-card {
        width: 32rem;
        padding-bottom: 0.5rem;
        background-color: #f6f7f9;
        position: absolute;
        top: 0;
        right: 0;
        border-radius: $base-radius;
        header {
          padding: 2rem 2rem 1rem;
          position: relative;
          h2 {
            margin: 0.3rem 0 0.5rem;
            color: $theme-color;
            font-size: 2.2rem;
            font-weight: 400;
          }
          .value-estimated,
          .value-label,
          .value-price {
            font-size: 1.2rem;
            color: $dark-text;
          }
          .value-estimated {
            margin: 0;
            text-transform: uppercase;
            letter-spacing: 0.1em;
          }
          .value-price {
            display: block;
            float: right;
            margin-top: 2px;
            letter-spacing: 0.05em;
          }
        }
        footer {
          display: flex;
          margin: 0.5rem 0.5rem 0;
          padding: 1.5rem;
          background-color: $white;
          border-radius: 0 0 0.3rem 0.3rem;
          justify-content: space-between;
          align-items: center;
          .find-agents-info {
            width: 16rem;
            font-size: 1.2rem;
            line-height: 1.6;
            color: $dark-text;
          }
          .find-agents-btn {
            padding: 0.7rem 1rem;
          }
        }
        .estimated-feedback {
          display: none;
          font-size: 1.2rem;
          position: absolute;
          bottom: -2.8rem;
          span + span { margin-left: 0.5rem; }
        }
      }
    }
  }
  // &-history {}
  &-about {
    .i-list {
      &-data {
        text-align: right;
      }
    }
  }
  &-location {
    .map-gmap {
      height: 58rem;
      border: 0.1rem solid $grey-color5;
      border-radius: 0.4rem;
    }
  }
  // &-similar {}
  // &-related {}
  &-insights {
    .valuation {
      min-height: 7rem;
      .value {
        &-container {
          display: flex;
        }
        &-label {
          color: $grey-text;
          font-size: smaller;
        }
        &-display {
          min-width: 9rem;
          font-size: 3.2rem;
          font-weight: 300;
          line-height: 1;
        }
        &-measurement {
          margin-left: 0.571em;
          font-size: 0.929em;
          span {
            display: block;
            + span {
              color: $grey-text;
              font-size: 0.714em;
              text-transform: uppercase;
              letter-spacing: 0.08em;
            }
          }
        }
      }
    }
    // Components
    .i-card {
      > .content {
        > .title {
          font-weight: 600;
        }
      }
      > .extra {
        padding-top: 1.5rem;
        border-top: 0;
        background-color: #f0f3f6;
        border-radius: 0 0 $base-radius $base-radius;
      }
    }
    .i-items {
      margin: 0 -1rem -0.5rem;
      padding: 1rem 2.5rem;
      background-color: $white;
      > .item {
        > .image {
          &.circle {
            width: 5rem;
            height: 5rem;
            line-height: 4.6rem;
            font-size: 1.4em;
            font-weight: 300;
            text-align: center;
            letter-spacing: -0.05em;
            color: $color-danger;
            border: 0.2rem solid $color-danger;
            border-radius: 50%;
          }
        }
      }
    }
  }
  // &-trend {}
  &-agents {
    .agent {
      // &s-list {}
      &-profile {
        height: 9rem;
        padding: 1em 0;
        border-bottom: 0.1rem solid $border-color;
        text-align: center;
        &:first-child {
          border-top: 0.1rem solid $border-color;
        }
      }
      &-picture,
      &-details,
      &-button {
        display: inline-block;
        vertical-align: middle;
      }
      &-picture {
        border: 0.1rem solid #eee;
        border-radius: 50%;
        overflow: hidden;
      }
      &-details {
        width: 65%;
        padding-left: 1.5rem;
        padding-right: 1.5rem;
        text-align: left;
        .agent-name,
        .agency-name,
        .recent-sales {
          display: inline-block;
          width: 30%;
          padding-right: 2em;
          vertical-align: middle;
        }
        .agent-name,
        .agency-name {
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
        }
        .agent-name {
          font-weight: 600;
        }
        .agency-name {
          width: 40%;
        }
        .recent-sales {
          padding-right: 0;
        }
      }
    }
  }
  // &-amenities {}
  // Components
  &-tabs {
    margin-bottom: 2rem;
    border: 0;
    background-color: transparent;
    text-align: center;
    font-size: 0;
    > li {
      display: inline-block;
      float: none;
      margin-bottom: 0;
      font-size: $base-font-size;
      > a {
        min-width: 12rem;
        margin: 0;
        padding-top: 1em;
        padding-bottom: 1em;
        border: 0;
        background-color: transparent;
        border-radius: 0;
        position: relative;
        transition: all 300ms ease-in-out;
        &:after {
          content: '';
          display: block;
          width: 100%;
          height: 0;
          background-color: transparent;
          position: absolute;
          left: 0;
          bottom: 0;
          transition: inherit;
        }
      }
      &.active {
        > a,
        > a:hover,
        > a:focus {
          background-color: transparent;
          border: 0;
        }
        > a:after {
          height: 0.3rem;
          background-color: $theme-color-alt;
        }
      }
    }
  }
  &-items {
    > .item {
      margin: 0;
      padding: 1rem;
      border-bottom: 0.1rem solid $grey-color5;
      transition: background 250ms ease-in-out, box-shadow 250ms ease-in-out, border-color 250ms ease-in-out;
      &:first-child {
        border-top: 0.1rem solid $grey-color5;
      }
      &:focus { text-decoration: none; }
      &:hover {
        border-color: #fff;
        background-color: #fff;
        text-decoration: none;
        position: relative;
        box-shadow: 0 0.2rem 1.2rem -0.1rem rgba($base-color, 0.2), 0 0 0 0.1rem rgba($base-color, 0.1);
      }
      > .content {
        .title { display: block; }
        .meta { margin-top: 0.5em; margin-bottom: 0; color: $grey-text; }
      }
      > .arrow {
        min-width: 3rem;
        align-self: center;
        text-align: right;
        i { font-size: 1.8rem; }
      }
    }
    .property {
      &-details {
        padding-right: 15%;
        position: relative;
      }
      &-pane {
        text-align: right;
        position: absolute;
        top: 50%;
        right: 0;
        transform: translateY(-50%);
      }
      &-price {
        font-weight: 600;
        + span {
          margin-top: 0.3em;
        }
      }
      &-sold-date,
      &-authority-type,
      &-available-date {
        display: block;
        color: $grey-text;
        font-size: 95%;
      }
    }
  }
  &-table {
    > thead {
      > tr {
        > th {
          border-bottom: 0;
        }
      }
    }
    > tbody {
      > tr {
        > td {
          padding: 1.5rem 0.5rem;
        }
      }
    }
  }
  //- Responsive ---------------------------
  @media (max-width: 767px) {
    &-module {
      padding-top: 2rem;
      padding-bottom: 2rem;
      .module {
        &-title {
          font-size: 2rem;
          font-weight: 600;
        }
        &-subtitle {
          font-size: 1.3rem;
        }
      }
    }
    &-summary {
      .property {
        &-summary {
          min-height: none;
        }
        &-details {
          padding-right: 0;
        }
        &-value-card {
          width: auto;
          margin-left: -1.5rem;
          margin-right: -1.5rem;
          position: relative;
          top: auto;
          right: auto;
        }
      }
    }
    &-about {
      .i-list {
        &-label {
          float: left;
        }
      }
    }
    &-quicklink {
      .mp-bar {
        .i-tabs {
          > li {
            + li {
              &:before {
                display: none;
              }
            }
          }
        }
      }
    }
    // Components
    &-tabs {
      > li {
        > a {
          min-width: auto;
        }
      }
    }
  }
}