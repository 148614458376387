$general-font: 'Poppins', sans-serif !important;
.footer-22 { 
  background-color: #ebecf0; color: #333; padding: 45px 0px 15px 0px; display: block;
  div, h3, p {
    font-family: $general-font;
  }
  .footer-layer2 {
    width: 100%;
    padding-right: 15px;
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto;
    @media (min-width: 575.98px) { 
      max-width: 640px;
    }
    @media (min-width: 767.98px) { 
      max-width: 920px;
    }
    @media (min-width: 991.98px) { 
      max-width: 1024px;
    }
    @media (min-width: 1199.98px) { 
      max-width: 1170px; 
    }
    .footerRow { 
      display: -webkit-box;
      display: -webkit-flex;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-flex-wrap: wrap;
      -webkit-flex-wrap: wrap;
      -ms-flex-wrap: wrap;
      flex-wrap: wrap;
      margin-right: -15px;
      margin-left: -15px;
      height: 100%;
      color: #333333; 
      .footerCol {
        -webkit-flex: 0 0 50%;
        -ms-flex: 0 0 50%;
        flex: 0 0 50%;
        max-width: 50%;
        width: 100%;
        padding-right: 15px;
        padding-left: 15px;
        .imageWrapper {
          width: 130px;
          height: 30px;
          margin-bottom: 25px;
        }
        .textBlock {
          max-width: 620px;
          a {
            font-weight: 600;
          }
          p {
            font-size: 10px;
            color: #3b4d5d;
            margin-top: 0;
            margin-bottom: 1rem;
            line-height: 15px;
          }
        }
        h3 {
          font-size: 14px;
          font-weight: 600 !important;
          color: #140c40;
          margin-bottom: 25px;
        }
        ul {
          padding-left: 0;
          li {
            list-style-type: none;
            margin-bottom: 10px;
            position: relative;
            line-height: 2;
            @media (min-width: 770px) {
              margin-bottom: 0px;
            } 
            &:before {
                content: '';
                position: absolute;
                height: 1px;
                width: 0px;
                top: 13px;
                background: #2f80ed;
                margin-right: 20px;
                -webkit-transition: all ease-in-out 0.35s;
                transition: all ease-in-out 0.35s;
            }
            a {
              color: #3b4d5d;
              font-size: 12px;
              transition: all 0.35s ease;
              &:hover {
                color: #2f80ed;
                -webkit-text-decoration: none;
                text-decoration: none;
              }
            } 
            &:hover {
              a {
                -webkit-transition: all ease-in-out 0.35s;
                transition: all ease-in-out 0.35s;
                margin-left: 15px;
              }
              &:before {
                width: 10px;
              }
            }
          }
        }
        .subscribe-txt {
          line-height: 1.5;
          margin-bottom: 15px;
          color: #3b4d5d;
          font-size: 12px;
          letter-spacing: .03em;
          @media (min-width: 768px) {
            width: 90%;
          }
          @media (min-width: 900px) {
            width: 80%;
          }
          @media (min-width: 1100px) {
            width: 70%;
          }
        }
        .subscribe-btn {
          margin-top: 5px;
          margin-bottom: 15px;
          a {
            background: #2056AB;
            color: white;
            padding: 6px 35px;
            border-radius: 3px;
            cursor: pointer;
            letter-spacing: .03em;
            line-height: 32px;
            font-size: 16px;
          }
        }
        @media (max-width: 767px) {
          flex: 0 0 100%;
          max-width: 100%;
        }
      }
      .fillCol {
        -webkit-flex: 0 0 100%;
        -ms-flex: 0 0 100%;
        flex: 0 0 100%;
        max-width: 100%;
        width: 100%;
        padding-right: 15px;
        padding-left: 15px;
        .bottomWrap {
          display: -webkit-box;
          display: -webkit-flex;
          display: -ms-flexbox;
          display: flex;
          -webkit-box-pack: justify;
          -webkit-justify-content: space-between;
          justify-content: space-between;
          -webkit-align-items: center;
          -webkit-box-align: center;
          -ms-flex-align: center;
          align-items: center;
          width: 100%;
          border-top: 1px solid #b8bdcc;
          padding-top: 15px;
          @media (max-width: 767px) {
            display: block;
          }
        }
      }
      .socialWrap {
        display: -webkit-box;
        display: -webkit-flex;
        display: -ms-flexbox;
        display: flex;
        -webkit-align-items: center;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        h3 {
          margin-right: 25px;
          margin-bottom: 0;
          margin-top: 2px;
          font-weight: 500 !important;
          color: #3b4d5d;
        }
        ul {
          display: -webkit-box;
          display: -webkit-flex;
          display: -ms-flexbox;
          display: flex;
          margin-bottom: 0;
          li {
            margin-right: 20px;
            margin-bottom: 0;
            &:hover {
              &:before {
                width: 0;
              }
              a {
                margin-left: 0px;
              }
            }
          }
        }
      }
      .webWrap {
        display: -webkit-box;
        display: -webkit-flex;
        display: -ms-flexbox;
        display: flex;
        -webkit-flex-direction: column;
        -ms-flex-direction: column;
        flex-direction: column;
        a {
          display: -webkit-box;
          display: -webkit-flex;
          display: -ms-flexbox;
          display: flex;
          margin-bottom: 10px;
          -webkit-align-items: center;
          -webkit-box-align: center;
          -ms-flex-align: center;
          align-items: center;
          -webkit-transition: all ease-in-out 0.3s;
          transition: all ease-in-out 0.3s;
          img {
            vertical-align: middle;
          }
          p {
            color: #3b4d5d;
            font-size: 12px;
            margin-bottom: 0;
            margin-left: 10px;
            margin-top: 0;
          }
        }
      }
      .cpWrap {
        p {
          font-size: 10px;
          color: #3b4d5d;
          margin-bottom: 0px;
        }
        @media (max-width: 767px) {
          margin-top: 15px;
        }
      }
    }
  }
} 