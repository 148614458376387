
body.page-content { 
    .featured-properties {
        pointer-events: auto;
        margin-top: 30px;
        background: #fff;
        @media (max-width: 767px) {
            padding: 5px 0 15px;
        }
        @media (max-width : 768px) {
            margin: 0 20px 40px;
            border-top: 1px solid #aaa;
            border-bottom: 1px solid #dceaec;
            .exclusive-title {
                margin-bottom: 5px;
                h5 { 
                    display: block;
                    color: #1a1a1a;
                    font-weight: 700; 
                    text-transform: uppercase;   
                    font-size: 13px;
                    border-bottom: 0;
                    padding: 0;
                    margin: 12px 0 0;
                    letter-spacing: 2px;
                    line-height: 20px;
                    text-align: center;
                }
    
            }
        }
        .card-content-full {
            padding: 5px 0 8px 0;  
            h4.detail-similar-prop-title {
                padding: 0;
            }
            .card-address {
                &.address-clamp-short-list {
                    font-family: poppins,sans-serif;
                }
            }
            p.card-address.address-clamp-short-list {
                line-height: 26px;
                // color: #969696;
                font-size: 11px;
            }
            a.agent-link {
                color: #1a1a1a;
            }

            .prop-image.first {
                border-bottom: 0 solid #fff;
            }
            .prop-image {
                max-height: 99px;
                @media (max-width: 767px) {
                    max-width: 140px;
                }
                @media (min-width: 768px) { 
                    max-height: 134px;
                }
            }
        }
        .owl-nav {
            position: absolute;
            top: -42px;
            right: 7px;
            button {
                &.owl-prev, &.owl-next {
                    font-size: 14px;
                    margin: 0 5px;
                    margin-left: 5px;
                    margin-right: 5px;
                }
            }
            @media only screen and (max-width : 768px) {
                display: none;
            }
        }
        &-col {
            padding-right: 0;
            padding-left: 0;
            width: 100%;
        }  
        // .item-exclusive {
        //     .exclusive {
        //       bottom: -36px;
        //       position: relative;
        //       @media (max-width: 576px) {
        //         bottom: 0;
        //       }
        //     }
        // }

        .detail-similar-prop-content { 
            .card-image{
                position: relative;
                .item-list-type {
                    position: absolute;
                    padding: 5px;
                    z-index: 9999; 
                    .list-type { 
                        font-size: 10px;
                        line-height: 15px;
                        color: #fff;
                        font-weight: 600;
                        background-color: rgba(0, 0, 0, 0.5);
                        // border: 2px solid #fff;
                        padding: 0 5px;
                        border-radius: 5px;
                    }
                } 
                .item-exclusive {
                    position: absolute;
                    padding: 5px;
                    bottom: 0; 
                    z-index: 999;
                    // @media (min-width: 768px) {
                    //     bottom: 40px;
                    // }
                    .exclusive{ 
                        font-size: 10px;
                        line-height: 15px;
                        color: #fff;
                        font-weight: 600;
                        background-color: rgba(0, 0, 0, 0.5);
                        border: 2px solid #fff;
                        padding: 0 5px;
                        border-radius: 5px;
                    }
                }
            } 
        }
        .detail-similar-prop-title {
            color: #414141;
            font-weight: 700;
            font-size: 14px;
            margin-bottom: 5px;
            line-height: 1.2;
            overflow: hidden !important;
            white-space: nowrap;
            text-overflow: ellipsis !important;
            @media (max-width: 425px) { 
                max-width: 140px;
            }
            @media (min-width: 768px) { 
                // line-height: 12px;
                line-height: 22px;
                margin-bottom: -2px;
                max-width: 190px;
            }
        }
        .detail-similar-prop-location {
            line-height: 17px;
            max-height: 17px;
            // color: #969696;
            font-size: 11px;
            margin: 0;
            .address-clamp-short-list{
                margin: 0;
                overflow: hidden !important;
                max-width: 20ch;
                white-space: nowrap;
                text-overflow: ellipsis !important;
            }
        }
        .detail-similar-prop-agent{
            font-size: 12.9px;
            text-decoration: none;
            color: #414141;
            overflow: hidden !important;
            max-width: 20ch;
            white-space: nowrap;
            text-overflow: ellipsis !important;
            @media only screen and (max-width : 768px) {
                display: none;
            }
        }
        .detail-similar-prop-price{
            font-size: 18px;
            color: $link-color-19;
            padding-right: 15px;
            margin: 13px 0px 0px 0px;
            font-weight: bold;
            text-decoration: none;
            @media only screen and (max-width : 768px) {
                font-size: 16px;
                margin: 2px 0px 0px 0px;
            }
        }
        .detail-similar-prop-details{
            overflow: hidden !important;
            max-width: 25ch;
            white-space: nowrap;
            text-overflow: ellipsis !important;
            font-size: 12px;
            @media (max-width: 767px) {
                font-size: 10px;
            }
        }
    }
}