.newlaunch-news {
  .news-item {
    margin-bottom: 20px;
  }
  .news-body {
    font-size: 1.2rem;
  }  
  .news-meta {
    margin-bottom: 1rem;
    color: #969696; 
    font-size: 1.2rem;
    font-weight: 300;
    white-space: nowrap;
    span+span {
      margin-left: 1rem;
      padding-left: 1rem;
      border-left: .1rem solid #c9c9c9;
    }
  }
  h1 {
    &.news-title {
      margin-bottom: .3em;
      font-size: 1.4rem;
      font-weight: 500;
      line-height: 1.3;
      a {
        color: #23527c;
      }
    }
  }
}