//------------------------------
// Event
//------------------------------
body.page-event{
  font-family: 'Roboto', Arial;
  font-size: 16px;
  line-height: 28px;
}
.event{
  // wope theme event styling
  &-wope-theme{
    .event{
      &-details, &-location {
        background-color: #edf1f5;
        border: 1px solid #d2d6da;
        padding: 1.5rem 2.5rem;
        h3 {
          margin:0;
        }        
      }
      &-prev-next {
        display: flex;
        flex-flow: row wrap;
        justify-content: center;
        font-size: 14px;
        font-weight: 500;
        .e-prev{
          text-align: left;
          flex: 1 1 50%;
        }
        .e-next{
          text-align: right;
          flex: 1 1 50%;
        }
      } 
    } 
    .details-info, .venue-info {
      display: flex;
      flex-flow: row nowrap;
      .column {
        flex: 1 1 50%;
        padding: 1rem 1rem 1rem 0;
        .info {
          margin: 1rem 1rem 1rem 0;
          span {
            width: 100%;
            &.title {
              font-weight: 600;
              margin-bottom: 5px;
            }
          }
          iframe {
            border: 1px solid #d2d6da;
          }
        }
        &:last-child {
          padding: 1rem 0;
        }
      }     
    }    
  }
}

//------------------------------
// Pullout
//------------------------------

.pullout{
  display: flex;
  flex-flow: row wrap;
  margin-left: -3rem;
  margin-right: -3rem;  
  @media screen and (max-width:719px){
    margin-left: -1.5rem;
    margin-right: -1.5rem;
  }
  @media screen and (max-width:347px){
    margin-left: 0;
    margin-right: 0;
  }
   .card{
     flex: 1 1 calc(100% / 3);
     padding: 1rem 3rem;
     text-align: center;
     min-width: 10rem;     
     @media screen and (max-width:719px){
      flex: 1 1 calc(100% / 2);
      padding: 1rem 1.5rem;
     }
     @media screen and (max-width:347px){
      flex: 1 1 calc(100% / 1);
      padding: 1rem 0;
     }
     > .content{
      margin-bottom: 0;
      .image{
        position: relative; 
        img{
          border: 1px solid #eee;
          filter: contrast(115%);        
        }
        > a:before{
          content: "";
          background: linear-gradient(to right,
            rgba(0,0,0,.25),
            rgba(255,255,255,.08) 5%,
            rgba(255,255,255,.35) 12%,
            rgba(255,255,255,.2) 18%,
            rgba(30, 30, 30, 0.15) 100%);
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          z-index: 1;
        }
      }
      .publication {
        padding: 10px;
        font-family: 'roboto', arial;
        font-size: 16px;
      }
    }    
  }
}