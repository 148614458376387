
.highcharts-figure,
.highcharts-data-table table {
  min-width: 310px;
  max-width: 800px;
  margin: 1em auto;
}

// #container {
//   height: 400px;
// }

.highcharts-data-table table {
  font-family: Verdana, sans-serif;
  border-collapse: collapse;
  border: 1px solid #ebebeb;
  margin: 10px auto;
  text-align: center;
  width: 100%;
  max-width: 500px;
}

.highcharts-data-table caption {
  padding: 1em 0;
  font-size: 1.2em;
  color: #555;
}

.highcharts-data-table th {
  font-weight: 600;
  padding: 0.5em;
}

.highcharts-data-table td,
.highcharts-data-table th,
.highcharts-data-table caption {
  padding: 0.5em;
}

.highcharts-data-table thead tr,
.highcharts-data-table tr:nth-child(even) {
  background: #f8f8f8;
}

.highcharts-data-table tr:hover {
  background: #f1f7ff;
}

// .highcharts-figure {
//     font-family: sans-serif;
//     min-width: 378px; 
//     max-width: 1140px;
//     margin: 1em auto;
//     h3 {
//       margin-bottom: .25rem;
//     }
//     h6 {
//       font-weight: 400;
//       margin: .125rem 0;
//     }
//     #container {
//       min-height: 80vh;
//     }
//     #sector-legend {
//       text-align: center;
//       & > div {
//         display: inline-block;
//         margin: 1rem 2rem;
//       }
//       i {
//         margin-right: 6px;
//       }
//       .series-0 i {
//         color: #4ad594;
//       }
//       .series-1 i {
//         color: #0a1c29;
//       }
//       .series-2 i {
//         color: #707589;
//       }
//     }
//   }