

//------------------------------
// Utilities
//------------------------------

.center-mini {
  width: 100%;
  margin: 0 auto;
  text-align: center;
  &.m-t-20 {
    margin-top: 20px;
  }
  &.m-t-30 {
    margin-top: 30px;
  }
  &.m-b-30 {
    margin-bottom: 30px;
  }
  &.m-b-20 {
    margin-bottom: 20px;
  }
}
.m-t {
  &-0 {
    margin-top: 0;
  }
}
.m-l {
  &-0 {
    margin-left: 0 !important;
  }
}
.m-b-10 {
  margin-bottom: 10px;
}
.m-lr-0 {
  margin-right: 0 !important;
  margin-left: 0 !important;
}
.p-r-0 {
  @media (min-width: $break_post_d_sm) {
    padding-right: 0;
  }
}
.p-l-0 {
  @media (min-width: $break_post_d_sm) {
    padding-left: 0;
  }
}
.p-0 {
  padding: 0;
}

.padder-top {
  height:29px; 
  &-buyvsrent {
    height: 68px;
  }
}
.p-m-20 {
  @media (max-width: 768px) {
    padding-right: 20px;
    padding-left: 20px;
  }
}

.cursor {
  &-disabled {
    cursor: not-allowed !important;
  }
  &-pointer {
    cursor: pointer;
  }
}
img {
  &.cursor-pointer {
    cursor: pointer;
  }
}

.max-w250 {
  width: 100%;
  max-width: 250px;
}
.max-w1000 {
  width: 100%;
  max-width: 1000px;
  margin: 0 auto;
}

.border {
  &-right-no {
    border-right: 0 !important;
  }
  @media (max-width: $break_tablet) {
    &-right-0-sm {
      border-right: 0 !important;
    }
  }
}
.float {
  &-none {
    float: none;
  }
}
.sides-auto {
  float: none;
  margin-left: auto !important;
  margin-right: auto !important;
}

.bg-dark {
  background: $bg-dark;
  color: #fff;
}
.overflow {
  &-hidden {
    overflow: hidden;
  }
  &-auto {
    overflow: auto;
    clear: both;
  }
}

  .hidden {
    &-tablet-only {
      @media (max-width: $break_tablet) and (min-width: $break_tablet) {
        display: none;
      }
    }
  }
  .tab-none {
    @media (max-width: $break_tablet) {
      display: none !important;
    }
  }

  .sm-text-center {
    @media (max-width: $break_pre_tablet ) {
      text-align: center;
    }
  }
  .hash-offset {
    display: block;
    position: relative;
    top: -70px;
    visibility: hidden;
    @media (max-width: $break_tablet) {
      top: -100px;
    }
  }
  .pos-top {
    top: 0 !important;
    margin: auto !important;
  }
  .pos-top-content {
    top: 0 !important;
    margin: auto !important;
  } 
  .pos-fixed {
    position: fixed !important;
    width: 100%;
  }

  .m {
    &-only {
      @media (max-width: 577px ) {
        display: none;
      }
      @media (max-width: $break_m_xx ) {
        display: block;
      }
    }
  }

  .el {
    &-show {
      display: block;
    }
    &-hide {
      display: none;
    }
  }

  .justify-content-center {
    justify-content: center !important;
  }

  a {
    &.menu-title-link {
      h4 {
        &:hover {
          color: inherit;
          text-decoration: underline;
        }
      }
    }
  }

.m-hide {
  display: block;
  @media (max-width: $break_pre_tablet) {
    display: none !important;
  }
}

.m-show {
  display: none;
  @media (max-width: $break_pre_tablet) {
    display: block !important;
  }
}

.sm-hide {
  display: block !important;
  @media (max-width: 1023px) {
    display: none !important;
  }
}

.sm-show {
  display: none !important;
  @media (max-width: 1023px) {
    display: block !important;
  }
}
.mobile-micro-masthead {
  img {
    width: 100%;
    height: auto; 
  }
}
.desktop-micro-masthead {
  margin: 0 auto;
  text-align: center;
  img {
    width: 100%;
    height: auto;
    max-width: 1440px;
  }

}

.m-long-menu {
  white-space: inherit;
  line-height: 1.3;
}
@media (min-width: $break_tablet) {
  .t-hide {
    display: none;
  }

}

.text {
  &-white {
    color: #fff;
  }
}

.padding-mobile {
  @media (max-width: 768px) {
    padding: 0 20px;
  }
}

.bg-alt {
  background: #efefef !important;
}
.no-gutters { 
  margin-right: 0;
  margin-left: 0;
  // display: flex;
  // flex-wrap: wrap;
}

// .mb-hide {
//   display: block;
//   @media (max-width: $break_pre_tablet) {
//     display: none;
//   }
// }

// .mb-show {
//   display: none;
//   @media (max-width: $break_pre_tablet) {
//     display: block;
//   }
// }

.display-initial {
  display: initial !important;
}
.flex {
  display: -webkit-box;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}

.global-loader {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0; left: 0;
  background: #fff url(https://upload.wikimedia.org/wikipedia/commons/b/b1/Loading_icon.gif) no-repeat center center;
}

.d-text-break {
  display: block;
}
.rainbow {
  -webkit-text-fill-color: transparent;
  -webkit-background-clip: text;
  background-image: linear-gradient(90deg,#ffac1b -2.58%,#e10b90 45.27%);
  background-position: 50%;
  background-repeat: no-repeat;
  background-size: cover;
  color: #fff;
} 

.font-weight {
  &-700 {
    font-weight: 700;
  }
}


