// @import url('https://fonts.googleapis.com/css2?family=Noto+Serif+SC:wght@300;400;500;600;700&display=swap');
// $content-font: 'Noto Serif SC', serif;
.newsImg-enlarged-image {
  padding-top: 20px;
}
.enlrgImg {
  cursor: zoom-in;
}
body {
  &.page-news-author {
    h1.page-heading {
      @media (max-width: 768px) {
        font-size: 18px;
        line-height: 24px;
        padding-bottom: 20px;
      }
    }
  }
}
.header-layout-style-1, #scroll-header-content {
    .fmh-notice-wrapper {
        height: 29px;
    }
    .announcement-top-banner {
        color: #fff;
        padding: 20px;
        z-index: 999;
        @media only screen and (max-width : 768px) {
          padding: 10px;
        }
        // background: linear-gradient(270deg, #5a3fc8, #4e8fd3, #438bca, #5a3fc8);
        background: linear-gradient(270deg, #5a3fc8, #488bf8, #005d98, #5a3fc8);
        background-size: 800% 800%;
        
        -webkit-animation: AnimationName 9s ease infinite;
        -moz-animation: AnimationName 9s ease infinite;
        animation: AnimationName 9s ease infinite;
        
        @-webkit-keyframes AnimationName {
            0%{background-position:0% 50%}
            50%{background-position:100% 50%}
            100%{background-position:0% 50%}
        }
        @-moz-keyframes AnimationName {
            0%{background-position:0% 50%}
            50%{background-position:100% 50%}
            100%{background-position:0% 50%}
        }
        @keyframes AnimationName { 
            0%{background-position:0% 50%}
            50%{background-position:100% 50%}
            100%{background-position:0% 50%}
        }
        font-size: 1.3rem;;
        line-height: 1.42857143;
        p {
            margin:0px;
            font-size: 20px;
            font-weight: 700; 
        }
        a {
            color:#ffffff;
            font-weight:bold;  
        }
        &.screen-condition {
            position: fixed;
            z-index: 999;
            left: 0;
            @media(max-width: $break_tablet) {
                width: 100%;
            }
        }
    }
}
.slider-position.announcement-top {
    &.stick-top {
        top: 6.1rem;
        &.top-zero {
            @media(max-width: $break_tablet) {
                top: 70px;
            }
            @media(max-width: 552px) {
                top: 102px;
            }
        }
    }
}
@media (max-width: 768px) {
    .edgeprop-header .header-banner {
        width: 100%;
        display: block;
    }
    #leaderboard_1_newspage {
        text-align: center !important;
    }
}


.wrap.news-wope-theme, body.page-content header.header-layout-style-1 .wrap {
    @media screen and (max-width: $break_tablet) {
        padding: 0;
    }
}
main {
    section {
        &.content-area {
            &.p-t-25 {
                body.page-area-outlook &,
                body.page-tips & {
                    padding-top: 5.4rem;
                }
            }
        }
    }

}
.dropdown {
    &.mega-dropdown {
        .menu-agent {
            p {
                font-size: 13px;
            }
            h4 {
                font-size: 15px;
            }
            a {
                &.btn-agents {
                    color: #fff;
                }
            }
        }
    }
}

body.page-content { 
  .flourish-embed.flourish-parliament {
      figure.first {
          display: none !important;
      }
  }
  .main-content {
    // .news-body.content {
    //   .featured-properties {
    //     .featured-properties-col {
    //       .detail-similar-prop-content {
    //         // .item-list-type {
    //         //   position: absolute;
    //         //   padding: 5px 4px;
    //         // }
    //         // .item-exclusive {
    //         //   position: absolute;
    //         //   padding: 5px;
    //         //   bottom: 0;
    //         // }
    //         // figure.prop-image.first {
    //         //   max-height: 99px;
    //         //   img {
    //         //     margin: initial;
    //         //     display: initial;
    //         //   }
    //         // }
    //         @media (max-width: $break_m_xx) {
    //           figure { 
    //             width: initial;
    //             left: initial;
    //             img {
    //               max-height: 99px !important;
    //             }
    //           }
    //           .item-exclusive {
    //             .exclusive {
    //               bottom: -36px;
    //               position: relative;
    //             }
    //           }
    //           .card-image {
    //             .item-exclusive {
    //               .exclusive {
    //                 bottom: 0;
    //                 position: relative;
    //               }
    //             }

    //           }
    //         }
    //       }
    //     }
    //   }
    // }
  }
  .news-end-quickaccess {
    font-family: 'Noto Serif SC', serif;
    line-height: 1.8;
    padding: 5px 0 40px;
    color: #333;
    font-style: italic;
    letter-spacing: .3px;
    font-size: 18px;
    a {
      // font-weight: 600;
      // white-space: wrap;
      display: initial;
      color: #488BF8 !important;
    }
    @media (max-width: 768px) {
      pointer-events: auto;
      background: #fff;
      font-size: 16px;
      line-height: 28px;
      padding: 0 17px 28px;
    }
  }
  .entry-meta {
    &.meta-left {
      @media (min-width: 768px) { 
        float: left;
        margin-top: 4px;
      }
      @media (min-width: 768px) and (max-width: 1023px) {
        width: 40%; 
      }
      @media (min-width: 1024px) {
        width: 60%; 
      }
    }
  }
  .top2-entry-post-share {
    &.meta-right {
      @media (min-width: 768px) { 
        float: right;
        text-align: right;
      }
      @media (min-width: 768px) and (max-width: 1023px) {
        width: 60%;  
      }
      @media (min-width: 768px) and (max-width: 1259px) {
        margin-top: 5px;
      }
      @media (min-width: 1024px) {
        width: 40%; 
      }
    }
  }
}

.caphack {
  position: relative; 
  top: -13px;
  padding-bottom: 15px;
}
