
#budget {
    margin-top: 130px;
    @media (min-width: 992px) {
        margin-top: 90px;
	}
	.budget-logo {
		&.v21 {
			@media (max-width: $break_tablet) {
				max-width: 95%;
			}
		}
	}
	a {
		color: $theme-color;
		text-decoration: underline;
		&:hover {
			text-decoration: none;
		}
	}
	section {
        .heading-word,
        .heading-secondary {
            display: block;
            font-size: 3.5rem;
            padding-top: 40px;
			font-weight: 800; 
			@media (max-width: $break_tablet) {
				font-size: 2.2rem;
			}
        }
        .heading-secondary { 
            padding-top: 10px;
            font-weight: 600;
			font-size: 3rem;
			@media (max-width: $break_tablet) {
				font-size: 1.9rem;
			}
		}
		padding: 25px 0 50px;
		@media (max-width: $break_tablet) {
			padding: 0px 0 50px;
		}
		&.webform-form {
			padding: 22px 0 15px;
		}
		&.general {
			// border-top: 10px solid #ff006c;
		    position: relative;
		    padding: 50px 0 50px;
		    overflow: hidden;
		} 
		&.top {
			padding: 0;
			// .top-bg {
			// 	body.page-airsupplycontest & {
			// 		background: url($as-1349) no-repeat center;
			// 		background-size: cover; 
			// 		// height: 100vh;
			// 		height: 685px;
			// 		@media (min-width: $break_d_lg1) { 
			// 			height: 807px;
			// 		}
			// 		@media (min-width: $break_d_xxx) { 
			// 			height: 955px;
			// 		}
			// 		@media (max-width: $break_tablet) {
			// 			background: url($as-768) no-repeat center;
			// 			height: 390px;
			// 		}
			// 		@media (max-width: $break_m_lg) {
			// 			background: url($as-425) no-repeat center;
			// 			height: 238px;
			// 			margin-top: -11px;
			// 		}
			// 		@media (max-width: $break_m_md) {
			// 			background: url($as-375) no-repeat center;
			// 			//background-size: 100% 100%; 
			// 			//height: 238px;
			// 			margin-top: -23px;
			// 		}
			// 		@media (max-width: $break_m_sam) {
			// 			background: url($as-360) no-repeat center;
			// 			//background-size: 100% 100%; 
			// 			//height: 238px;
			// 			margin-top: -26px;
			// 		}
			// 		@media (max-width: $break_m_sm) {
			// 			background: url($as-320) no-repeat center;
			// 			//background-size: 100% 100%; 
			// 			//height: 238px;
			// 			margin-top: -37px;
			// 		}

			// 	} 
			// 	body.page-ewf &,
			// 	body.page-node-42 & {
			// 		background: url($ewf-1349) no-repeat center;
			// 		background-size: cover; 
			// 		// height: 100vh;
			// 		height: 685px;
			// 		@media (min-width: $break_d_lg1) { 
			// 			height: 807px;
			// 		}
			// 		@media (min-width: $break_d_xxx) { 
			// 			height: 955px;
			// 		}
			// 		@media (max-width: $break_tablet) {
			// 			background: url($ewf-768) no-repeat center;
			// 			height: 390px;
			// 		}
			// 		@media (max-width: $break_m_lg) {
			// 			background: url($ewf-425) no-repeat center;
			// 			height: 238px;
			// 			margin-top: -11px;
			// 		}
			// 		@media (max-width: $break_m_md) {
			// 			background: url($ewf-375) no-repeat center;
			// 			//background-size: 100% 100%; 
			// 			//height: 238px;
			// 			margin-top: -23px;
			// 		}
			// 		@media (max-width: $break_m_sam) {
			// 			background: url($ewf-360) no-repeat center;
			// 			//background-size: 100% 100%; 
			// 			//height: 238px;
			// 			margin-top: -26px;
			// 		}
			// 		@media (max-width: $break_m_sm) {
			// 			background: url($ewf-320) no-repeat center;
			// 			//background-size: 100% 100%; 
			// 			//height: 238px;
			// 			margin-top: -37px;
			// 		}
			// 	}
			// 	body.page-pepscontest &,
			// 	body.page-node-43 &,
			// 	body.page-node-37 & {
			// 		background: url($peps-1349) no-repeat center;
			// 		background-size: cover; 
			// 		// height: 100vh;
			// 		height: 685px;
			// 		@media (min-width: $break_d_lg1) { 
			// 			height: 807px;
			// 		}
			// 		@media (min-width: $break_d_xxx) { 
			// 			height: 955px;
			// 		}
			// 		@media (max-width: $break_tablet) {
			// 			background: url($peps-768) no-repeat center;
			// 			height: 390px;
			// 		}
			// 		@media (max-width: $break_m_lg) {
			// 			background: url($peps-425) no-repeat center;
			// 			height: 238px;
			// 			margin-top: -11px;
			// 		}
			// 		@media (max-width: $break_m_md) {
			// 			background: url($peps-375) no-repeat center;
			// 			//background-size: 100% 100%; 
			// 			//height: 238px;
			// 			margin-top: -23px;
			// 		}
			// 		@media (max-width: $break_m_sam) {
			// 			background: url($peps-360) no-repeat center;
			// 			//background-size: 100% 100%; 
			// 			//height: 238px;
			// 			margin-top: -26px;
			// 		}
			// 		@media (max-width: $break_m_sm) {
			// 			background: url($peps-320) no-repeat center;
			// 			//background-size: 100% 100%; 
			// 			//height: 238px;
			// 			margin-top: -37px;
			// 		}
			// 	}
			// }
		}
		&.heading-top {
			padding: 25px 0 0;
			.title {
				font-weight: 800;
				font-size: 1.7rem;
				margin: 4rem 0;
				&-1 {
					color: $primary-color1;
				}
				&-2 {
					color: #f9cc13;
				}
			}
		}
		&.terms-conditions {
			// padding-bottom: 50px;
			li {
				font-size: 1.2rem;
			}
		}
		ul, ol {
			text-align: left;
			@media (min-width: $break_post_d_sm) {
				margin: 0;
				padding: 0;
			}
			li {
				margin-bottom: 10px;
				span {
					&.step-title {
						text-transform: uppercase;
						display: block;
					    font-weight: 600;
					    font-size: 2rem;
					    color: $primary-color1;
					}
				}
			}
			&.ehc-ul {
				text-align: center;
				@media (min-width: $break_post_d_sm) {
					// min-height: 265px;
				}
			}
		}
		ul {
			li {
				margin-bottom: 25px;
			}
		}
	} 
	.btn {
		&-ehc {
			text-decoration: none;
			background: #ff5122;
			color: #fff;
			padding: 20px 25px;
			font-weight: 800;
			@media (max-width: $break_d_md) {
				// margin-top: -35px; 
				padding: 20px 8px;
			}
			&:hover {
				background: #fff;
				color: #ff5122;
				border: 1px solid #ff5122;
			}
			a {
				text-decoration: none;
				color: #fff;
				&:hover {
					color: #ff5122;
				}
			}
		}
		// &-cny-sm, &-cny-sm-gr {
		// 	background: #ff5122;
		// 	color: #fff;
		// 	&:hover {
		// 		background: #fff;
		// 		color: #ff5122;
		// 		border: 1px solid #ff5122;
		// 	}
		// }
		// &-cny-sm-gr {
		// 	background: $bg_dark;
		// 	&:hover {
		// 		background: #fff;
		// 		color: $bg_dark;
		// 		border: 1px solid $bg_dark;
		// 	}
		// }
		&-border-radius-no {
			border-radius: 0;
		}
	}
	// .a-btn-ehc {

	// }
	input {
		width: 100%;
	}
	li {
		font-size: 1.7rem;
	}

} 