.ads-mrec-floating {
  // height: 550px;
  // display: none;
  height: 345px;
  @media (max-width: $break_d_md) {
    height: 51px;
  }
  @media (max-width: $break_m_xx) {
    height: 52px;
  }
  @media (max-width: $break_m_sm) {
    height: 300px;
  }
  z-index: 3000;
  position: fixed;
  bottom: -100%;
  width: 300px;
  left: 6%;
  transition: all .5s .2s ease-in-out;
  @media (max-width: $break_tablet) { 
    left: 0;
    margin: 0 auto;
    width: 100%;
  }
  &.in {
    // left: 0;
    bottom: 0;
    transition: all .5s .2s ease-in-out;
    @media (max-width: $break_tablet) {  
      bottom: 48%;
    }
    @media (max-width: $break_m_xx ) {  
      bottom: 60%;
    }
    @media (min-width: $break_m_md) { 
    }
    @media (max-width: $break_m_sm ) {  
      bottom: 14%;
    }
  }
  &-content {
    position: relative;
    > div[id^='div-gpt-ad'] {
      display: table;
      margin: 0 auto 0;
      // border-radius: 50%;
      box-shadow: 0 4px 11px -5px #000000;
    }
  }
  .pop-slide-close {
    right: calc((100% - 300px) / 2);
    // @media (min-width: 1200px) {
    //   right: calc((100% - 1200px) / 2);
    // }
    // @media (max-width: 1199px) {
    //   right: calc((100% - 768px) / 2);
    // }
    // @media (max-width: 767px) {
    //   right: calc((100% - 414px) / 2);
    // }
    // @media (max-width: 413px) {
    //   right: calc((100% - 320px) / 2);
    // }
  }
}