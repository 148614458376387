
.m-news-search {
    display: none;
    @media(max-width: 1024px) {
        body.page-news &,
        body.page-content &,
        body.page-node-29 &,
        body.page-edgeproptv &,
        body.page-pullout & {
            display: block;
        }
    }
    .header-search-mazada {
        position: relative;
        // @media(max-width: $break_tablet ) {
        //     right: -235px;
        // }
        // @media(max-width: $break_m_xx ) {
        //     right: -144px;
        // }
        // @media(max-width: $break_m_x ) {
        //     right: -104px;
        // }
        // @media(max-width: $break_m_lg ) {
        //     right: -68px;
        // }
        // @media(max-width: $break_m_md ) {
        //     right: -47px;
        // }
        // @media(max-width: $break_m_sam ) {
        //     right: -39px;
        // }
        // @media(max-width: $break_m_sm ) {
        //     right: -22px;
        // }
        // top: 3px;
    }
    .header-search-popup  {
        width: 100%;
        visibility: hidden;
        opacity: 0;
        position: absolute;
        // top: 100%;
        top: 68px;
        right: 0;
        background: #fff;
        border-top: 1px solid transparent;
        -webkit-background-clip: padding-box;
        background-clip: padding-box;
        z-index: 9999;
        padding: 22px 18px;
        box-shadow: 0 2px 6px rgba(0, 0, 0, .2);
        -webkit-transition: all .2s ease-out;
        -moz-transition: all .2s ease-out;
        -o-transition: all .2s ease-out;
        transition: all .2s ease-out;
        transform: translate3d(0, 20px, 0);
        -webkit-transform: translate3d(0, 20px, 0);
        -moz-transform: translate3d(0, 20px, 0);
        -ms-transform: translate3d(0, 20px, 0);
        -o-transform: translate3d(0, 20px, 0);
        &.header-search-popup-open {
            visibility: visible;
            opacity: 1;
            transform: translate3d(0, 0, 0);
            -webkit-transform: translate3d(0, 0, 0);
        }
        input {
            font-size: 13px;
        }
    }
    .fa-search,
    .fa-times {
        font-size: 17px;
    } 
    button.search-news {
        .fa-search {
            font-size: 36px;
        }
    }

}