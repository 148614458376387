//------------------------------
// Segment
//------------------------------
.i-segment {
  background-color: #fff;
  border: none;
  border-radius: 0.4rem;
  box-shadow: 0 0 0 0.1rem rgba($base-color, 0.15);
  > .head {
    margin: 0;
    padding: 1rem 1.5rem;
    border-bottom: 0.1rem solid rgba(0,0,0,0.1);
    background-color: #f8f9fb;
    font-size: 1.8rem;
    font-weight: 500;
    border-radius: 0.4rem 0.4rem 0 0;
    > .close {
      display: block;
      width: 4.5rem;
      height: 4.5rem;
      margin-top: -1rem;
      margin-right: -1.5rem;
      background-color: transparent;
      font-size: 3rem;
      line-height: 4.5rem;
      text-align: center;
      text-shadow: none;
      opacity: 0.4;
      border-radius: 0 0.4rem 0 0;
      &:hover {
        opacity: 0.8;
        background-color: transparent;
      }
    }
    + .extra {
      margin-top: -0.1rem;
      border-top: 0;
    }
  }
  > .extra {
    background-color: #f8f9fb;
    border-top: 0.1rem solid rgba($base-color, 0.1);
    > .nav {
      padding: 1rem;
      &-tabs {
        padding-bottom: 0;
      }
    }
    + .foot {
      border-top: 0;
    }
  }
  > .body {
    padding: 1.5rem;
    > header {
      margin-bottom: 4.8rem;
    }
    &.reset {
      padding: 0;
    }
    + .extra {
      margin-bottom: -0.1rem;
      > .nav {
        padding: 1rem;
        &-tabs {
          padding-top: 0;
          > li {
            margin-top: -0.1rem;
            margin-bottom: 0;
            > a {
              border-radius: 0 0 0.4rem 0.4rem;
              &:hover {
                border-color: #ddd #eee #eee;
              }
            }
            &.active {
              > a {
                border-top-color: transparent;
                border-bottom-color: #ddd;
              }
            }
          }
        }
      }
      &:last-child {
        > .nav-tabs {
          margin-bottom: 0;
          border-bottom: 0;
        }
      }
    }
  }
  > .foot {
    padding: 1rem 1.5rem;
    background-color: #f8f9fb;
    border-top: 0.1rem solid rgba($base-color, 0.1);
    font-size: 90%;
    color: rgba($base-color, 0.45);
    border-radius: 0 0 0.4rem 0.4rem;
  }
  > .thumb {
    position: relative;
    > img {
      width: 100%;
      height: auto;
      transition: opacity 450ms ease-in-out;
    }
    &:first-child {
      img {
        border-radius: 0.4rem 0.4rem 0 0;
      }
    }
    // Actions Panel
    .actions {
      position: absolute;
      z-index: 1;
      opacity: 0;
      visibility: hidden;
      transition: all 300ms ease-in-out;
    }
    &.middle {
      .actions {
        top: 50%;
        left: 50%;
        transform: translate3d(-50%, -50%, 0);
      }
    }
    &.center {
      .actions {
        left: 50%;
        transform: translate3d(-50%, 0, 0);
      }
    }
    &.top {
      .actions {
        top: 1rem;
      }
    }
    &.bottom {
      .actions {
        bottom: 1rem;
      }
    }
    &.left {
      .actions {
        left: 1rem;
      }
    }
    &.right {
      .actions {
        right: 1rem;
      }
    }
    // Hover
    &:hover {
      > img {
        opacity: 0.25;
      }
      .actions {
        opacity: 1;
        visibility: visible;
      }
    }
  }
  // When both meet together
  + .i-segment {
    margin-top: 1.5rem;
  }
  // Style
  &.clean {
    > .head {
      margin-bottom: -0.5rem;
      padding-bottom: 0.5rem;
      background-color: transparent;
      border-bottom: 0;
    }
    > .extra {
      background-color: transparent;
    }
    > .foot {
      padding-top: 0.5rem;
      background-color: transparent;
      border-top: 0;
    }
    // Addon Style
    .more-link {
      background-color: transparent;
    }
  }
  &.phat {
    > .head {
      padding: 1.25rem 2rem;
    }
    > .body {
      padding: 2rem;
    }
    > .foot {
      padding: 1.25rem 2rem;
    }
  }
  &.bar {
    > .head,
    > .body,
    > .foot {
      padding: 0.5rem;
    }
    > .head {
      font-size: 1.4rem;
    }
    > .foot {
      font-size: 1.2rem;
    }
  }
  &.shadow {
    box-shadow: 0 0.2rem 1rem -0.3rem rgba(0,0,0,0.3), 0 0 0 0.1rem #e1e1ea;
    &.shadow-large {
      box-shadow: 0 0.5rem 2rem -0.5rem rgba(0, 0, 0, 0.3), 0 0 0 0.1rem #e1e1ea;
    }
  }
  // Theme Color
  &.theme {
    // Nav Tabs
    .nav-tabs {
      border-bottom: 0;
      > li {
        > a {
          border-color: transparent;
          color: $white;
          &:hover,
          &:focus {
            background-color: rgba($white, 0.3);
          }
        }
        // Active
        &.active {
          > a {
            background-color: $white;
            color: $base-color;
          }
        }
      }
    }
    // Colors
    &.default {
      box-shadow: 0 0 0 0.1rem $theme-color;
      > .head,
      > .head + .extra {
        background-color: $theme-color;
        color: $theme-text;
      }
    }
    &.primary {
      box-shadow: 0 0 0 0.1rem $color-primary;
      > .head,
      > .head + .extra {
        background-color: $color-primary;
        color: $white;
      }
    }
    &.success {
      box-shadow: 0 0 0 0.1rem $color-success;
      > .head,
      > .head + .extra {
        background-color: $color-success;
        color: $white;
      }
    }
    &.info {
      box-shadow: 0 0 0 0.1rem $color-info;
      > .head,
      > .head + .extra {
        background-color: $color-info;
        color: $white;
      }
    }
    &.warning {
      box-shadow: 0 0 0 0.1rem $color-warning;
      > .head,
      > .head + .extra {
        background-color: $color-warning;
        color: $white;
      }
    }
    &.danger {
      box-shadow: 0 0 0 0.1rem $color-danger;
      > .head,
      > .head + .extra {
        background-color: $color-danger;
        color: $white;
      }
    }
  }
  // Others combination
  > .jumbotron {
    position: relative;
    margin-bottom: 0;
    border-radius: 0;
    h1,h2,h3 {
      margin-top: 0;
      margin-bottom: 0;
    }
    h1 {
      font-size: 6.4rem;
    }
    h2 {
      font-size: 5.2rem;
    }
    h3 {
      font-size: 4.0rem;
    }
    .jumbotron-bg {
      background-position: center;
      background-repeat: no-repeat;
      background-size: cover;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      z-index: 0;
      border-radius: 0.3rem 0.3rem 0 0;
    }
  }
}

@media (max-width: 767px) {
  .i-segment {
    > .jumbotron {
      h1 {
        font-size: 6.4 * 0.8rem;
      }
      h2 {
        font-size: 5.2 * 0.8rem;
      }
      h3 {
        font-size: 4.0 * 0.8rem;
      }
    }
  }
}

@media (min-width: 768px) {
  .i-segment {
    > .jumbotron {
      + .body {
        padding-left: 6rem;
        padding-right: 6rem;
        padding-top: 1.5rem;
        padding-bottom: 3rem;
      }
    }
  }
}