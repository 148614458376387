#bigleaderboard { 
	margin-left: auto;
	margin-right: auto;
	text-align: center; 
	padding: 0;
	div[id^='div-gpt-ad'] {
		margin: 10px 0;
		@media(min-width: 1024px) {
			// margin: 75px 0 10px;
			//center logo header
			margin: 102px 0 10px;
		}
		@media(max-width: $break_pre_d ) {
			margin: 0;
			border-top: 10px solid #fff;
			border-bottom: 10px solid #fff;
		}
		// &:after {
		// 	content: 'Advertisement';
		// 	text-transform: uppercase;
		//     letter-spacing: .5px;
		// 	color: #888;
		// 	font-size: 10px;
		//     font-weight: 400;
		// 	line-height: 20px;
		// 	display: block;
		// }
		// body.page-news & {
		// 	@media(min-width: $break_d_md) {
		// 		margin: 122px 0 10px;
		// 	}
		// }
		body.page-content & {
			@media (min-width: $break_tablet) {
				margin: 0 0 10px;
			} 

		}
	}
}