#form-agent {
    @media (min-width: 768px) {
      max-width: 500px;
      margin: 0 auto;
    }
    @media (max-width: 767px) {
        width: 92%;
        margin: 0 auto;
    }
    .form-group {
      margin-bottom: 20px;
      .form-control {
        font-size: 14px;
      }
      label {
          text-transform: uppercase;
          font-size: 1rem;
      }
    }
    .form-check {
      padding-left: 0;
      label {
        // margin-left: 5px;
        font-size: 13px;
        // display: inline;
        @media (max-width: 991px) {
          font-size: 11px;
        }
      }
    }
    .label-pdpa {
      position: relative;
      right: -6px;
      top: -3px;
      @media (max-width: 320px) {
        margin-right: 10px; 
      }
      @media (max-width: 425px) {
        margin-right: 4px; 
      }
      @media (min-width: 991px) {
        position: relative;
        right: -1px;
        top: -1px;
      }
    }
  }

  .general-form-check {
    display: inline-block;
    width: 17px;
    float: left;
  }
  .general-form-label {
    font-size: 11px;
    display: inline-block;
    width: 94%;
    float: right;
    text-transform: inherit !important;
    position: relative;
    top: 2px;
  }
  #tem-cond-error {
    position: relative;
    left: 10px;
    top: 3px;
  }

  .agent-form-overlay {
    position: fixed;
    // top: 30%;
    bottom: -160%;
    height: 100vh;
    width: 100vw;
    background: rgba(0, 0, 0, 0.5);
    z-index: 9999;
    left: 0;
    // width: 500px;
    // right: calc((100% - 500px) / 2);
    transition: all .5s .2s ease-in-out;
    .agent-title {
      font-size: 2.4rem;
      margin-bottom: 25px;
      @media (min-width: 768px) {
        width: 80%;
        margin: 0 auto 25px;
      }
    }
    .sub-agent-title {
      font-size: 1.8rem;
    }
    .agent-form-close {
      position: absolute;
      top: 0;
      background: $bg-close-btn;
      color: white;
      right: calc((100% - 500px) / 2);
      @media (max-width: 991px) {
        right: calc((100% - 300px) / 2);
      }
      // @media (max-width: 767px) {
      //   right: calc((100% - 300px) / 2);
      // }
      padding: 0 10px;
      font-size: 28px;
      z-index: 1;
      cursor: pointer;
      &:hover {
        background: lighten($bg-close-btn, 8%);
      }
    }
    
    &-content {
      background: #fff;
      box-shadow: 0 4px 11px -5px #bfbfbf;
      border: 1px solid #ddd;
      text-align: center;
      margin: 50px auto 0;
      min-height: 220px;
      padding: 25px 20px 20px;
      width: 600px;
      right: calc((100% - 500px) / 2);
      // top: 33%;
      // position: fixed;
      @media (max-width: 991px) {  
        margin: 20% auto 0;
        width: 300px;
        right: calc((100% - 300px) / 2);
        padding: 20px;
      } 
    }
    &.in {
      // left: 0;
      bottom: 0;
      // bottom: 20%;
      transition: all .5s .2s ease-in-out;
    }
  }