//------------------------------
// Image
//------------------------------
.image {
  position: relative;
  display: inline-block;
  vertical-align: middle;
  max-width: 100%;
  background-color: transparent;
  img {
    display: block;
    max-width: 100%;
    height: auto;
  }
  // Size
  &.tiny {
    width: 6.5rem;
  }
  &.small {
    width: 9rem;
  }
  &.medium {
    width: 12rem;
  }
  &.big {
    width: 16.5rem;
  }
  &.large {
    width: 18rem;
  }
  &.xlarge {
    width: 20rem;
  }
  &.xxlarge {
    width: 24rem;
  }
  // Style
  &.rounded {
    img {
      border-radius: 0.4rem;
    }
  }
  &.circle {
    img {
      border-radius: 100%;
    }
  }
  // Frame
  &.frame {
    border-width: 0.3rem;
    border-style: solid;
    border-color: $white; 
  }
  // Carousel
  &.carousel {
    min-width: 12rem;
    // Nav (clone from UI)
    .owl-nav {
      i { display: block; }
      .owl-prev,
      .owl-next {
        width: 3rem;
        height: 3.8rem;
        line-height: 3.8rem;
        color: $white;
        text-shadow: 0 0.1rem 0 rgba(0,0,0,0.6);
        font-size: 2.8rem;
        text-align: center;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
      }
      .owl-prev {
        left: 0;
      }
      .owl-next {
        right: 0;
      }
      .disabled {
        opacity: 0.1;
      }
    }
  }
  // Indicator
  .indicator {
    line-height: 1;
    position: absolute;
    z-index: 2;
    &.top {
      top: 0;
    }
    &.bottom {
      bottom: 0;
    }
    &.left {
      left: 0;
    }
    &.right {
      right: 0;
    }
    &.center {
      left: 50%;
      right: auto;
      transform: translate(-50%, 0);
    }
    &.middle {
      top: 50%;
      bottom: auto;
      transform: translate(0, -50%);
      &.center {
        left: 50%;
        transform: translate(-50%, -50%);
      }
    }
    // Colors
    &.white {
      color: $white;
    }
  }
  // Wrapper
  > .wrapper {
    position: relative;
  }
}