.jumbotron {
  &.newlaunch-search-container {
    margin-bottom: 20px;
    padding: 30px 0;
    margin-top: 72px;
    .search-tiny {
      .search-fakebar {
        max-width: 100%;
      }
      .search-wrapper {
        max-width: 100%;
        font-family: inherit;
        padding:0;
      }
      .search-form {
        .onebar {
          .input-group {
            .button {
              border-left: .1rem solid #dedede;
              border-right: 0;
              font-size: 1.2rem;
            }
          }
        }

      }
    }
    .dropdown-theme {
      max-height: 300px;
      overflow: auto;
      margin-top: 0;
      padding: 0;
    }
  }  
}

 