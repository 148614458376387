// body {
// 	&.page-news {
// 		.header-banner {
// 			#leaderboard_1_newspage {
// 				display: block!important;
// 			}
// 		}
// 	}
// }
// .sidebar-mailchimp-label {
// 	font-weight: 200 !important; 
// 	width: 100% ;
// }


.page-node-29,
.page-news {
	.post-entry {
		.entry-content {
			display: none;
			@media (min-width: 769px) {
			display: block;
			font-size: 14px;
			line-height: 21px;
			margin: 12px 0;
			color: #3b4d5d;
			overflow: hidden;
			text-overflow: ellipsis;
			display: -webkit-box;
			-webkit-line-clamp: 2;
			-webkit-box-orient: vertical;

			}
		}
		.entry-meta {
			.date-published {
				color: #757575;
				font-size: 12px;
				margin-top: auto;
			}
		}
	}
}
// .page-news {
// 	.widget-entry {

// 	}
// }