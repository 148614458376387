body.page-proppoints {

    table.blueTable {
        background-color: #ffffff;
        width: 100%;
        text-align: left;
        border-collapse: collapse;
    }

    table.blueTable td,
    table.blueTable th {
        border: 1px solid #AAAAAA;
        padding: 3px 2px;
    }

    table.blueTable tbody td {
        font-size: 13px;
    }

    table.blueTable tr:nth-child(even) {
        background: #E6F1F4;
    }

    table.blueTable thead {
        background: #1C6EA4;
        background: -moz-linear-gradient(top, #5592bb 0%, #327cad 66%, #1C6EA4 100%);
        background: -webkit-linear-gradient(top, #5592bb 0%, #327cad 66%, #1C6EA4 100%);
        background: linear-gradient(to bottom, #5592bb 0%, #327cad 66%, #1C6EA4 100%);
        border-bottom: 2px solid #444444;
    }

    table.blueTable thead th {
        font-size: 15px;
        font-weight: bold;
        color: #FFFFFF;
        border-left: 2px solid #D0E4F5;
    }

    table.blueTable thead th:first-child {
        border-left: none;
    }

    table.blueTable tfoot {
        font-size: 14px;
        font-weight: bold;
        color: #FFFFFF;
        background: #D0E4F5;
        background: -moz-linear-gradient(top, #dcebf7 0%, #d4e6f6 66%, #D0E4F5 100%);
        background: -webkit-linear-gradient(top, #dcebf7 0%, #d4e6f6 66%, #D0E4F5 100%);
        background: linear-gradient(to bottom, #dcebf7 0%, #d4e6f6 66%, #D0E4F5 100%);
        border-top: 2px solid #444444;
    }

    table.blueTable tfoot td {
        font-size: 14px;
    }

    table.blueTable tfoot .links {
        text-align: right;
    }

    table.blueTable tfoot .links a {
        display: inline-block;
        background: #1C6EA4;
        color: #FFFFFF;
        padding: 2px 8px;
        border-radius: 5px;
    }

    table tr:last-child td:first-child {
        border-bottom-left-radius: 10px;
    }

    table tr:last-child td:last-child {
        border-bottom-right-radius: 10px;
    }

    table.blueTable tr td {
        padding: 10px;
        &.td-1 {
            width: 55%;
            @media screen and (max-width: 425px) {
                width: 40%;
            }
        }
        &.td-2 {
            width: 22.5%;
            text-align: center;
            @media screen and (max-width: 425px) {
                width: 30%;
            }
        }
    }

    ul li {
        list-style-type: none !important;
        position: relative;
        margin-bottom: 15px;
    }

    ul {    
        li {
            &:before {
                content: '\f0a9';
                font-family: FontAwesome;
                position: absolute;
                top: 2px;
                left: -18px;
                color: #005c98;
                font-size: inherit;
                line-height: 1;

            } 
            &.admin-menu-toolbar-category.expandable,
            &.admin-menu-action.admin-menu-users {
                &:before {
                    content: none;
                }
            }
        }
    }

    #title-faq-1:hover, #arrow-1:hover,
    #title-faq-2:hover, #arrow-2:hover,
    #title-faq-3:hover, #arrow-3:hover,
    #title-faq-4:hover, #arrow-4:hover,
    #title-faq-5:hover, #arrow-5:hover,
    #title-faq-6:hover, #arrow-6:hover,
    #title-faq-7:hover, #arrow-7:hover,
    #title-faq-8:hover, #arrow-8:hover,
    #title-faq-9:hover, #arrow-9:hover,
    #title-faq-10:hover, #arrow-10:hover  
    {
        cursor: pointer;
    }
}

