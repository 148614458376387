#brand-slide {
  z-index: 999;
  position: fixed;
  background: #dcdfe6;
  color: #333;
  width: 260px; 
  text-align: center;
  bottom: 80px;
  right: -260px;
  height: 264px;
  transition: right 0.4s ease-in-out;
  -o-transition: right 0.4s ease-in-out;
  -ms-transition: right 0.4s ease-in-out;
  -moz-transition: right 0.4s ease-in-out;
  -webkit-transition: right 0.4s ease-in-out;
  .box {
    padding: 20px;
    font-size: 13px;
  }
  &:hover,
  &.in  {
    right: 0px;
  }
  &.in,
  &.out  {
    transition: right 0.4s ease-in-out;
    -o-transition: right 0.4s ease-in-out;
    -ms-transition: right 0.4s ease-in-out;
    -moz-transition: right 0.4s ease-in-out;
    -webkit-transition: right 0.4s ease-in-out;
    right: 0px;
  }
  &.out {
    right: -260px;
  }
  #brand-toggle {
    position: absolute;
    right: 260px;
    padding: 10px 20px;
    background: #d1a12a; 
    color: #fff;
    font-weight: 700;
    font-size: 10px;
    cursor: pointer;
  }
  a.btn-slide {
    display: block;
    margin-top: 15px;
    background: #007F38;
    color: #fff;
    padding: 4px 10px;
    font-weight: 700;
  }
}