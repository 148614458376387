#ip-container {
    position: relative;
    .article {
        // border: 1px solid #c2c2c2;
        // color: #585858;
        // width: 260px;
        // height: 270px;
        // float: left;
        // background: #fff;
        // margin-right: 15px;
        // padding: 15px;
        margin-bottom: 15px;
        cursor: pointer;
        span {
            display: block;
            padding-bottom: 5px;
        }
    }
    .ip-details {
    display: none;
    }
    .inlinepopup {
        background: #fff none repeat scroll 0 0;
        border: 1px solid #c2c2c2;
        float: left;
        width: 100%;
        margin-bottom: 15px;
        position: relative;
        border-top: 5px solid $award19-color;
        box-shadow: 0 3px 7px 0 rgba(0, 0, 0, 0.5);
        &_content {
            margin: 20px;
            color: #585858;
            h2 {
                color: #585858;
                margin: 0;
                padding: 0 0 10px;
            }
            ul, ol {
                margin: 0 0 20px;
                padding: 0 0 0 20px;
            }
            .img-middle img {
                margin: 5px auto;
            }
        }
        &_arrow {
            background: url("https://media.edgeprop.my/icon/bmpa/1-arrow-top.png?v3") no-repeat scroll center 0;
            height: 32px;
            position: absolute;
            top: -34px;
            width: 32px;
        }
        &Close {
            background: url("https://media.edgeprop.my/icon/bmpa/1-close.png?v3") no-repeat scroll 0 0;
            cursor: pointer;
            height: 16px;
            position: absolute;
            right: 5px;
            text-indent: -9999px;
            top: 10px;
            width: 16px;
            z-index: 99;
            overflow: hidden;
            transition: all 0.5s ease 0s;
            &:hover {
                transform: rotate(180deg);
            }
        }
    }
}