.catfish-ads {
  // height: 550px;
  // display: none;
  height: 80px;
  @media (max-width: $break_d_md) {
    height: 51px;
  }
  @media (max-width: $break_m_xx) {
    height: 52px;
  }
  @media (max-width: $break_m_sm) {
    height: 40px;
  }
  z-index: 3001;
  position: fixed;
  bottom: -100%;
  width: 100%;
  left: 0%;
  transition: all .5s .2s ease-in-out;
  &.in {
    left: 0;
    bottom: 130px;
    transition: all .5s .2s ease-in-out;
    @media (max-width: $break_d_md ) { 
      bottom: 112px; 
    }
    @media (max-width: $break_m_xx ) { 
      bottom: 93px; 
    }
    @media (max-width: $break_m_md ) { 
      bottom: 78px; 
    }
    @media (max-width: $break_m_sm ) { 
      bottom: 90px; 
    }
  }
  &-content {
    position: relative;
    > div[id^='div-gpt-ad'] {
      display: table;
      margin: 80px auto 0;
      // box-shadow: 0px 6px 18px 0px #666;
    }
  }
  .slide-close {
    right: calc((100% - 1000px) / 2);
    @media (min-width: 1200px) {
      right: calc((100% - 1200px) / 2);
    }
    @media (max-width: 1199px) {
      right: calc((100% - 768px) / 2);
    }
    @media (max-width: 767px) {
      right: calc((100% - 414px) / 2);
    }
    @media (max-width: 413px) {
      right: calc((100% - 320px) / 2);
    }
  }
}