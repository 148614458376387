//Mixins
@mixin vendor-prefix($property, $value) {
  @each $prefix in "-webkit-", "-moz-", "-ms-", "-o-" {
    #{unquote($prefix)}#{$property} : $value
  }
}
  
//Color
$acc_gray: #9EA0A5;
 
  
.cursor-pointer {
  cursor: pointer;
}
  
 .accordion-wrap {
  border-bottom: 1px solid #000;
  .accordion-js-panel { 
    overflow: hidden; 
    .accordion__header {
      border-top: 1px solid #333;
      color: #fff;
      padding: 17px 0; 
      display: flex;
      align-items: center;
      justify-content: space-between;
      @extend .cursor-pointer;
      &:hover {
        // color: #fff;
        // background: darken($acc_primary, 5%)
      }
      h3 {
        margin: 0;
      }
      .acc-title {
        color: #000;
        font-size: 19px;
        font-weight: 500;
        @media (max-width: 820px) {
          font-size: 16px;
        }
      }
      .accordion__button {
        width: 31px;
        height: 30px;
        display: flex;
        align-items: center;
        justify-content: center;
        background-image: linear-gradient(90deg,#ffac1b -2.58%,#e10b90 45.27%);
        // background: #333;
        padding: 16px 8px 15px;
        border-radius: 50%;
        &:after {
          content: "+";
          font-size: 22px;
          transform-origin: center;
          transition: 200ms linear;
          font-weight: 200;
        }
        &.accordion-open {
          &:after {
            transform-origin: center;
            transition: 200ms linear;
          }
        }
        &.accordion-close {
          &:after {
            transform: rotate(45deg);
          }
        }
      }
      &:last-child {
        border-bottom: 1px solid #333;
      }
    }
    .accordion__body {
      border-top: 1px solid lighten($acc_gray, 10%);
      padding: 20px 0;
      display: none;
      p {
        margin-bottom: 3px;
      }
      p:last-child {
        margin: 0;
      }
    }
  }
 }