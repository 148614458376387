.sto-ads,
.sto-singlepage {
  // height: 550px;
  // display: none;
  height: 100vh;
  // background: rgba(0,0,0,.65);
  z-index: 3000;
  position: fixed;
  top: -160%;
  width: 100%;
  left: 0%;
  transition: all .5s .2s ease-in-out;
  &.in {
    left: 0;
    top: 0;
    transition: all .5s .2s ease-in-out;
  }
  &-content {
    position: relative;
    > div[id^='div-gpt-ad'] {
      display: table;
      margin: 85px auto 0;
      // box-shadow: 0px 6px 18px 0px #666;
    }
  }
  .slide-close {
    position: absolute;
    top: 0;
    background: $bg-close-btn;
    color: white;
    right: calc((100% - 780px) / 2);
    padding: 0 10px;
    font-size: 28px;
    z-index: 1;
    cursor: pointer;
    &:hover {
      background: lighten($bg-close-btn, 8%);
    }
  }
}

@media (max-width: 768px) {
  .sto-ads,
  .sto-singlepage {
    height: 100vh;
    &-content > div[id^='div-gpt-ad'] {
      margin: 40px auto 0;
    }
    .slide-close {
      // right: calc((100% - 700px) / 2);
      right: calc((100% - 414px) / 2);
    }
  }
}

@media (max-width: 425px) {
  .sto-ads,
  .sto-singlepage {
    &-content > div[id^='div-gpt-ad'] {
      margin: 15px auto 0;
    }
    .slide-close {
      right: calc((100% - 414px) / 2);
    }
  }
}

@media (max-width: 375px) {
  .sto-ads,
  .sto-singlepage {
    .slide-close {
      right: calc((100% - 300px) / 2);
    }
  }
}