.section-nl, .section-vpex {
  .content-area {
    background-color: #e9e9ea;
  }
  .block-content {
    padding: 0 5rem;
  }
}

.nl-module {
  &.i-card {
    border-radius: 0;
    .content {
      padding: 1.5rem 2rem;
    }
  }
  .meta {
    height: 4.5rem;
    overflow: hidden;
  }
  .project-name {
    color: #2e3a52;
    font-size: 1.4rem;
    font-weight: 500;
  }
  .developer-name {
    margin-bottom: 10px;
    color: #777;
    font-size: 1.2rem;
    font-weight: 500;
  }
  .label-default {
    background: #f5f5f5;
    font-weight: 400;
    font-size: 11px;
    color: #888;
    padding: 5px;
    margin-bottom: 0.4rem;
    display: inline-block;
  }
  .image {
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
  }
  a {
    &:hover {
      text-decoration: none;
    }
  }
  .price-desc {
    position: absolute;
    top: 0.1rem;
    left: 0.1rem;
    z-index: 88;
    background: rgba(0, 0, 0, 0.65);
    padding: 10px;
    color: #fff;
    font-weight: 500;
    font-size: 1.4rem;
    line-height: 1em;
    span {
      display: block;
      text-transform: uppercase;
      font-size: 1rem;
      letter-spacing: 0.05em;
    }
  }
}

.nl-listing, .nl-module {
  .material-icons {
    font-size: initial;
    position: relative;
    top: -2px;
    padding-right: 7px;
  }

}

.nl-vpex-thumb {
  display: block;
  position: relative;
}

button {
  &.btn-vpex-list {
    width: 100%;
  }
}
.image {
  &.thumb {
    display:block;
  }
}
.thumb-img {
  border: solid;
  border-width: 0 1px 1px 0;
  border-color: white;
  &:first-child {
    border-left:1px solid white;
  }
}

.glass-holder{
  &.stage {
    background: rgba(0, 0, 0, 0);
    border: 1px solid white;
    height: 13.5rem;
    cursor: -webkit-zoom-in;
    &:hover {
      background: rgba(0, 0, 0, 0.12);
      -webkit-transition: opacity .15s ease-out;
      -o-transition: opacity .15s ease-out;
      -moz-transition: opacity .15s ease-out;
      transition: opacity .15s ease-out;
    }
  }
  &.thumb {
    background: #000;
    opacity: .1;
    cursor: -webkit-zoom-in;
    height: 4.2rem;
    &:hover {
      opacity: .3;
      -webkit-transition: opacity .15s ease-out;
      -o-transition: opacity .15s ease-out;
      -moz-transition: opacity .15s ease-out;
      transition: opacity .15s ease-out;
    }
  }
    
}

.view_more {
  width: 25%;
  margin: auto;
  .button {
    background: transparent;
    color: #2e3a52;
    border: 2px solid #2e3a52;
    font-weight: 600;
    letter-spacing: 0.05em;
    &:hover {
      color: white;
      background: #2e3a52;
      border: 2px solid #2e3a52;
    }
  }
}


.vpex {
  &-overlay {
    background: rgba(0, 0, 0, 0.65);
    z-index: 89;
    position: absolute;
    height: 100%;
    width: 100%;
    top: 0;
    border: solid;
    border-color: white;
    border-width: 0 1px 1px 1px;
  }
  &-list-col {
    .social-sharer {
      color: #fff;
      height: 100%;
      .button {
        &.block {
          height: 100%;
          font-size: 1.5rem;
        }
      }
    }
  }
} 

.mrec{
  min-width: 316px;
}

.foto-slider {
  .container-bg-dark {
    background: #000;
  }
}

.newlaunch-content-area {
  span {
    &.view-all-btn {
      font-size: small; 
      font-weight: 600;
      text-transform: uppercase; 
      float: right;
      position: relative;
      top: 11px;
      a {
        &:hover {
          text-decoration: underline;
        }
      }
      i {
        margin-left: 5px;
      }
    }
  }
  a {
    color: #2e3a52;
  }
}

.newlaunch, .newlaunch-vpex {
  .social-sharer {
    .social-media {
      min-width: 100%;
      text-align: center;
      bottom: 60px;
    }
  }
}

.newlaunch, .newlaunch-vpex {
  .vpex-list-col {
    .social-sharer {
      .button {
        &.block {
          font-size: 1.3rem;
        }
      }
      li {
        display: inline-block;
        margin: 0 10px;
      }
    }
  }
}
 
 
