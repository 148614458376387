.full-popup {
	cursor: pointer;
	position: fixed;
	z-index: 999;
	color: #333;
	border: 1px solid #000;
	box-shadow: 1px 7px 30px 2px #6f6d6d; 
	display: block;
	background: rgba(255, 255, 255, 0.98);
	text-align: center;
	// overflow: hidden;
	overflow-x: hidden;
	overflow-y: auto;
	top: 28%; 
	height: 300px;
	padding: 25px; 
	// width: 92%;
	right: calc((100% - 700px) / 2);
	width: 700px;
	// @media (min-width: $break_tablet ) {
	// }
	@media (max-width: $break_pre_tablet) {
		right: calc((100% - 320px) / 2);
		width: 320px;
	}
	p {
		font-size: 14px;
		padding-top: 13px;
	}
	&.active {
		-webkit-overflow-scrolling: touch; 
		opacity: 1;
		-webkit-transition: opacity 0.15s linear, z-index 0.15;
		-o-transition: opacity 0.15s linear, z-index 0.15;
		transition: opacity 0.15s linear, z-index 0.15;
		// z-index: -1;
	}
	.btn-close {
		cursor: pointer;
		position: fixed;
		color: #fff;
		background: #462b5a;
		border-radius: 50%;
		width: 35px;
		height: 35px;
		z-index: 999999999;
		margin-top: -20px;
		right: calc((100% - 700px) / 2);
		top: 24%;
		@media (max-width: $break_pre_tablet) {
			right: calc((100% - 320px) / 2); 
		}
		opacity: 1;
		text-shadow: none;
		font-size: 4rem;
		font-weight: 100;
		line-height: .9;
		&:focus {
			box-shadow: none !important;
		}
		&:not(:disabled):not(.disabled):active {
			background: none; 
			border: 0; 
		
		}
	} 
	&.vpex-popup {
		max-width: 400px;
		position: fixed;
		top: 80px;
		right: 5%; 
	}
}