body.page-node-58,
.page-donedeal {
	// background: #eee;
}
#page-donedeal {
	.donedeal-top,
	.donedeal-content {
		a {
			color: #488bf8;
			text-decoration: underline;
		}
		@media (min-width: 1200px) {
			// width: 1200px;
			width: 970px;
		}
	}
	.donedeal-top {
		background: transparent;
		display: block;
		margin: 52px auto 5px;
		.module-top {
			// border-bottom: 1px solid #ccc;
			padding: 25px 0 10px;
			@media (min-width: 1025px) {
				padding: 50px 0 10px;
			}
			.pre-heading {
				color: #ef4123;
				text-transform: uppercase;
				font-weight: 700;
				font-size: 28px;
				line-height: 1;
				border-bottom: 1px solid;
				padding-bottom: 10px;
				display: inline-block;
				@media (max-width: 767px) {
					font-size: 22px; 
				}
			}
			h1 {
				color: #165297;
				line-height: .8;
				font-weight: 700;
				@media (max-width: 767px) {
					font-size: 22px;
					line-height: 1;
				}
				span {
					&.top-psf {
						font-size: 20px;
						font-weight: 600;
						color: $link-color-19;
						@media (max-width: 767px) {
							font-size: 16px; 
						}
					}
				}
			}
			h2 {
				font-size: 27px;
				font-weight: 600;
				border-bottom: 1px solid #ccc;
				padding-bottom: 10px;
				margin-top: 10px;
				@media (max-width: 767px) {
					font-size: 16px;
				}
			}
			.save-share {
				position: absolute;
				right: 30px;
				top: 28px;
				.share-btn {
					background-color: #fff;
					border-color: #d3d3d3;
					color: #414141;
					font-size: 14px;
					line-height: 14px;
					padding: 6px 10px;
					height: 32px;
				}
				@media (max-width: 768px) {
					display: block;
					position: initial; 
					margin-top: 20px;
				}
			}
			.meta-agent {
				width: 100%;
				// background: #eee;
				padding: 10px;
				@media (max-width: 768px) {
					// padding-bottom: 20px;
					// padding-top: 5px;
					padding: 5px 0 10px 0;
				}
				@media (min-width: 768px) {
					width: 400px;
				}
				img.meta-img {
					width: 60px;
					border-radius: 50%;
					float: left;
					margin-right: 10px;
				} 
				.pro-agent {
					width: 32px;
				}
				.meta-highlight {
					font-weight: 700;
				}
			}
			.img-module-top {
				img {
					width: 100%;
					max-width: 600px;
					@media (max-width: 768px) {
						max-width: 100%;
					}
				}
			}
			.border-module {
				border-top: 0;
				border-bottom: 1px solid #ccc;
				// padding: 10px 0;
				overflow: hidden;
				display: block;
				height: 8px;
				width: 97%;
				margin: 0 auto 5px;
				@media (max-width: 768px) {
					display: none;
				}
			}
		}
	}
	.donedeal-content {
		display: block; 
		background: #fff;
		.sidebar {
			float: left;
			padding: 0 15px;
			@media (min-width: 769px) {
				width: 28%;
				margin-right: 25px;
			}
			@media (min-width: 1024px) and (max-width: 1024px) {
				margin-right: 15px;
			}
			.side-col { 
				.side-label {
					color: #a0acc1;
					font-size: 12px;
					padding-bottom: 3px;
				}
				.side-data {
					color: #191c26; 
					line-height: 24px;
					display: block;
					border-bottom: 1px solid #ddd;
					padding-bottom: 2px;
					margin-bottom: 15px;
					font-size: 14px;
				}
			}
			h3 {
				// margin-left: 0;
			}
			&.noteworthy {
				background: #aaa;
				color: #fff;
				@media (max-width: 768px) {
					padding-top: 5px;
				}
				h3 {
					margin-top: 10px;
					padding-bottom: 5px;
					border-bottom: 1px solid #fff;
				}
				ul {
					padding: 0 0 0px 15px;
					list-style: inherit;
					li {
						padding-bottom: 15px;
						line-height: 1.5;
						p {
							line-height: normal;
							margin: 0;
							padding: 0;
						}
					}
				}
			}
		}
		.sidemain {
			h3 {
				margin: 0 0 15px;
				line-height: 1;
			}
			img {
				max-width: 100%;
			}
			figcaption {
				color: #969696;
				font-style: italic;
				line-height: 1.4;
				margin-top: .6em;
				margin-bottom: 20px;
				&:before {
					font-family: fontawesome;
					content: '\f05a';
					margin-right: 4px;
					font-style: normal;
					font-size: 1.3rem;
				}
			}
			.content-footer {
				border-top: 1px solid #036;
				font-size: 12px;
				color: #036;
				padding-top: 15px;
			}
		}
	
		.module-median-chart {
			margin-bottom: 30px;
			margin-top: 30px;
			.desc {
				font-size: 16px;
				line-height: 24px;
				text-align: center;
				margin-bottom: 16px;
				@media (max-width: 767px) {
					font-size: 12px;
				}
			}
			.median-chart {
				display: flex;
				flex-wrap: wrap;
				width: 100%;
				.gradient-bar {
					height: 100%;
					width: 100%;
					overflow: hidden;
					vertical-align: middle;
					.svg-white {
						stop-color: #f7f7f7;
					}
					.svg-ghost-light {
						stop-color: #e9e9ec;
					}
					.svg-turqoise-dark,
					.svg-cerulean {
						stop-color: #4c87f8;
					}
				}
				&__edge {
					flex: 2;
					text-align: center;
					&-bar-low,
					&-bar-high {
						height: 30px;
					}
				} 
				&__low {
					flex: 4;
					.median-chart__low-bar {
						background-color: #d7d7d7;
						height: 30px;
					}
				}
				&__middle {
					flex: 8;
					text-align: center;
					.median-chart__middle-bar {
						background: $link-color-19;
						height: 30px;
						position: relative;
						width: 100%;
						&:after {
							border-left: 1px dashed #fff;
							content: "";
							height: 20px;
							left: 50%;
							margin-top: 5px;
							position: absolute;
							transform: translate(-50%);
							z-index: 1;
						}
					}
				}
				&__high {
					flex: 4;
					text-align: right;
					.median-chart__high-bar {
						background-color: #51bf6a;
						height: 30px;
					}
				}
				&__label {
					color: #212529;
					font-size: small;
					font-weight: 700;
					margin-top: 5px;
					.percentage,
					.psf  {
						font-weight: 400;
						color: #525252;
						font-size: 14px;
						line-height: 18px;
						@media (max-width: 767px) {
							font-size: 11px;
						}
					}
					.price {
						color: #414141;
						font-size: 26px;
						font-weight: 800;
						@media (max-width: 767px) {
							font-size: 13px;
						}
					} 
				} 
			}
		}
		.module-main {
			p {
				font-size: 14px;
				line-height: 30px;
			}
		}
	}
	.donedeal-listings {
		//general
		.section-head {
			display: -webkit-box;
			display: -ms-flexbox;
			display: flex;
			-webkit-box-align: center;
			-ms-flex-align: center;
			align-items: center;
			-webkit-box-pack: justify;
			-ms-flex-pack: justify;
			justify-content: space-between;
			// padding: 0 0 25px;
			padding: 0;
			h2 {
				font-size: 28px;
				color: #414141;
				font-weight: 600;
				margin-bottom: 0;
				@media (max-width: 991px) {
					font-size: 20px;
					max-width: 60%;
				}
			}
			a {
				font-weight: 600;
				color: $link-color-19;
			}
		}
		.sale-listings {
			.row {
				display: flex;
				flex-wrap: wrap;
			}
			margin-top: 35px;
			// @media (max-width: 767px) {
			// 	width: 1200px;
			// 	overflow-x: auto!important;
			// 	overflow-y: hidden!important;
			// 	-webkit-overflow-scrolling: touch!important;
			// }
			.listing-head {
			}
			.listing-row {
				// @media (max-width: 991px) {
				// 	flex-wrap: nowrap;
				// 	margin-right: -15px!important;
				// 	overflow-x: auto!important;
				// 	overflow-y: hidden!important;
				// 	-webkit-overflow-scrolling: touch!important;
	
				// }
				@media (max-width: 767px) {
					// width: 1200px;
					flex-wrap: nowrap;
					overflow-x: auto!important;
					overflow-y: hidden!important;
					-webkit-overflow-scrolling: touch!important;
				}
			}
			.listing-card {
				margin-top: 5px;
				margin-bottom: 15px;
				border-radius: 5px;
				box-shadow: 0 0 6px rgba(0,0,0,.16);
				position: relative;
				display: flex;
				flex-direction: column;
				min-width: 0;
				word-wrap: break-word;
				background-color: #fff;
				background-clip: border-box;
				border: 1px solid rgba(0,0,0,.125);
				.card-header {
					border-radius: 5px 5px 0 0;
					display: flex;
					background-color: #fff;
					color: #000;
					padding: 5px 13px;
					margin-bottom: 0;  
					padding: 5px 13px;
					margin-bottom: 0;
					border-bottom: 0;
					.avatar-item {
						min-width: 34px;
						margin-right: 10px;
						.agent-avatar {
							position: absolute;
							width: 34px;
							height: 34px;
							z-index: 2;
							border-radius: 50%;
							object-fit: cover;
							object-position: top;
							border: 1px solid #1f56ab;
							background-color: #1f56ab;
						}	
						a {
							display: initial;
						}
					}
					.details-item {
						.agent-name {
							margin: 0;
							font-size: 12px;
							font-weight: 600;
							display: -webkit-box;
							overflow: hidden;
							text-overflow: ellipsis;
							-webkit-line-clamp: 1;
							-webkit-box-orient: vertical;
							a {
								color: #000;
								background-color: transparent;
								&:hover {
									text-decoration: underline;
								}
							}
	
						}
						.agent-listing-desc {
							margin: 0;
							font-size: 11px;
							display: -webkit-box;
							overflow: hidden;
							text-overflow: ellipsis;
							-webkit-line-clamp: 1;
							-webkit-box-orient: vertical;
						}
					}
					.pro-agent {
						width: 32px;
						margin-left: 2px;
						padding-top: 1px;
						vertical-align: top;
					}
					&:first-child {
						border-radius: 5px 5px 0 0;
					}
				}
				&.featured {
					.card-header {
						background-color: $link-color-19;
						color: #fff;
						.avatar-item {
							min-width: 60px;
							margin-right: 10px;
							.agent-avatar { 
								width: 60px;
								height: 60px; 
								border: 2px solid #1f56ab;  
							}
						}
						.details-item {
							.agent-name {
								a {
									color: #fff;
	
								}
							}
						}
					} 
				}
				.card-img {
					width: 100%;
					height: 150px;
					object-fit: cover;
				}
				.card-body {
					flex: 1 1 auto;
					min-height: 1px;
					position: relative;
					padding: 12px;
					.card-price {
						color: #1f56ab;
						font-size: 18px;
						line-height: 27px;
						font-weight: 600 !important;
						margin-bottom: 4px;
						max-width: 500px;
					}
					.card-name {
						font-size: 16px;
						color: #414141;
						font-weight: 500 !important;
						line-height: 18px;
						margin-bottom: 6px;
						max-width: 500px;
						display: -webkit-box;
						overflow: hidden;
						text-overflow: ellipsis;
						-webkit-line-clamp: 1;
						-webkit-box-orient: vertical;
					}
					.card-address {
						line-height: 18px;
						margin-bottom: 6px;
						max-width: 500px;
						display: -webkit-box;
						overflow: hidden;
						text-overflow: ellipsis;
						-webkit-line-clamp: 1;
						-webkit-box-orient: vertical;
						color: #a0acc1;
						font-size: 12px;
					}
					.card-specs {
						.spec-item {
							display: inline-block;
							margin-right: 20px;
							color: #525252;
							font-size: 13px;
							line-height: 21px;
							vertical-align: baseline;
							.spec-icon {
								margin-right: 5px;
								width: 15px;
								height: auto;
							}
						}
					}
				}
				&:hover {
					cursor: pointer;
					box-shadow: 0 0 6px rgba(31,86,171,.7);
				}
				@media (max-width: 991px) {
					width: 250px;
				}
			}
		}
	}
	.donedeal-listings,
	.donedeal-past-transaction {
		@media (min-width: 1200px) {
			// width: 1200px;
			width: 1130px;
		}
	}
	.donedeal-past-transaction {
		margin-top: 20px;
		@media (min-width: 768px) {
			padding: 0 30px 30px;
			border: 1px solid #ddd; 
		}
		table {
			border: 1px solid #ddd;
			th {
				color: #a0acc1;
				font-weight: 200;
			}
			td {
				a {
					color: $link-color-19;
				}
				.btn-primary {
					background-color: $link-color-19;
					border-color: $link-color-19;
					&.text-light {
						color: #f8f9fa;
					}
				}
			}
		}
		.trans-redirect-link {
			a {
				color: $link-color-19;
			}
		}
		.btn {
			font-size: 12px;
		}
	}
	.right-float-box {
		position: absolute;
		right: 10px;
		top: 10px;
		// @media (min-width: 1200px) {
			@media (min-width: 1024px) {
			top: 20px;
		}
		@media (max-width: 768px) {
			position: relative;
			top: 10px;
			right: 0;
		}
	}
	.btn {
		transition: none;
		display: inline-block;
		font-weight: 400;
		color: #212529;
		text-align: center;
		vertical-align: middle;
		cursor: pointer;
		-webkit-user-select: none;
		-moz-user-select: none;
		-ms-user-select: none;
		user-select: none;
		background-color: transparent;
		border: 1px solid transparent;
		padding: .375rem .75rem;
		font-size: 17px;
		line-height: 1.5;
		border-radius: .25rem;
		transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out;
		&:hover {
			color: #212529;
			text-decoration: none;
		}
	}
	.enquire-button,
	.whatsapp-button {
		background-color: transparent;
		color: #1f56ab;
		padding: 2px 20px;
		width: 200px;
		height: 48px;
		font-size: 16px;
		font-weight: 500;
		@media (max-width: 1023px) {
			background: #fff;
			width: 48%;
			padding: 0;
			font-size: 13px;
			border: 1px solid #ddd;
		}
	}
	.whatsapp-button {
		color: #128c7e;  
	}
	.enquire-button {
		@media (max-width: 1023px) {
			margin-right: 10px;
			margin-left: 2px;
			padding: 14px;
		}
		@media (max-width: 375px) {
			margin-left: 0px;
		}
		@media (max-width: 320px) {
			margin-right: 8px;
		}
	}
	.meta-btn {
		overflow: auto;
		padding: 5px 0 10px 0;
		.enquire-button,
		.whatsapp-button {
			
			border: 1px solid #ddd;
			background: #fff;
			width: 48%;
		}
		.enquire-button {
			margin-right: 9px;
			margin-left: 0;
			padding: 10px;
			@media (min-width: 414px) {
				margin-right: 11px; 
			}
			@media (max-width: 1023px) {
				padding: 13px;
			}
			@media (max-width: 320px) {
				margin-right: 8px;
			}
		}

	}
	.agent-stickybar {
		position: fixed;
		z-index: 10;
		left: 0;
		right: 0;
		top: -120px;
		color: #fff;
		display: -moz-box;
		display: flex;
		-moz-box-pack: center;
		justify-content: center;
		-moz-box-align: center;
		align-items: center;
		-moz-box-orient: horizontal;
		-moz-box-direction: normal;
		flex-direction: row;
		flex-wrap: nowrap;
		transition: all .3s ease-in-out .2s;
		@media (min-width: 992px) {
			display: block;
		}
		&.show {
			// display: block;
			// top: 52px;
			//center logo header
			top: 81px;
		}
		.agent-inner {
			.agent-innerer {
				max-width: 1024px;
				width: 100%;
			}
			background-color: rgba(0,0,0,.8);
			-webkit-backdrop-filter: saturate(180%) blur(20px);
			backdrop-filter: saturate(180%) blur(20px);
			color: #fff;
			height: 111px;
			line-height: 1.5;
			width: 100%;
			.ag-avatar {
				// width: 125px;
				// height: 125px;
				margin: 20px 0;
				padding: 0;
				@media (min-width: 992px) {
					margin: 0 auto 20px;
				}
				img {
					// width: 180px;
					// height: 180px;
					border-radius: 50%;
					object-fit: cover;
					object-position: top;
					border: 1px solid #efefef;
					// @media (min-width: 1200px) {
					@media (min-width: 1024px) {
						position: relative;
						top: -18px;
					}
				}
			}
			.ag-avatar-sm {
				height: 69px;
				width: 69px;
				// @media (min-width: 1200px) {
				@media (min-width: 1024px) {
					position: relative;
					top: 15px;
				}
				img {
					// height: 69px;
					// width: 69px;
					border: 2px solid #fff;
					top: 10px;
					position: relative;
				}
			}
			
			.ag-base-info {
				h5 {
					color: #fff; 
					margin-bottom: 0;
					font-weight: 400 !important;
					overflow: hidden;
					white-space: nowrap;
					text-overflow: ellipsis;
					font-size: 14px;
					line-height: 1.18;
					// color: #333f48;
					@media (min-width: 768px) {
						margin-top: 18px;
					}
					.pro-agent {
						width: 32px;
						vertical-align: top;
					}
					a {
						color: inherit;
					}

				}
				.text-secondary {
					margin-top: 10px; 
					span {
						display: block;
						padding: 0 0 3px;
						font-size: 12px;
						color: #a5adb5;
					}
					.agency-name { 
					// 
					}
				} 
				@media (min-width: 992px) {
					position: relative;
					top: 10px;
				}
			} 
			.ag-sticky-btn {
				position: relative;
				top: 31px;
				.enquire-button {
					color: #fff; 
					border: 1px solid #ddd;
					padding: 11px;
				}
				.whatsapp-button {
					background: #fff;
				}

			}	
		}
		@media (min-width: 768px) and (max-width: 768px) {
			display: none !important;
		}
	}
	.agent-stickyfooter {
		box-shadow: 0 -2px 4px 0 rgba(0,0,0,.25);
		position: fixed;
		bottom: -80px;
		z-index: 999;
		width: 100%;
		background: #fff;
		// margin-left: -15px;
		// margin-right: -15px;
		padding: 0 10px 10px;
		transition: all .3s ease-in-out .2s;
		&.show {
			bottom: 0;
		}
		// @media (min-width: 768px) and (max-width: 768px) {
		// 	display: none !important;
		// }
	}
	.form-agent-section {
		padding: 50px 0;
		margin: 30px 0;
		textarea {
			&#message {
				@media (max-width: 475px) {
					min-height: 77px;
				}
			}
		}
	}
	.inquiry-button { 
		width: 100%;
		margin: 0 auto;
		display: inherit;
		cursor: pointer;
		font-size: 1.9rem;
		line-height: 1.3;
		text-transform: uppercase;
		border: 1px solid #cc0000;
		background: #cc0000;
		color: #fff;
		text-align: center;
		padding: 12px 80px;
		margin-top: 10px;
		display: inline-block;
		&:hover {
			background: #b30a0a;
			border: 1px solid #b30a0a;

		}
	}
	#formsection {
		height: 50px;
		@media (max-width: 767px) {
			height: 0;
		}
	}
}