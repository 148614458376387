body.page-proppoints {
  @media only screen and (max-width:600px) {
    #lp-pom-image-128-holder {
      background-image:url(https://www.edgeprop.my/sites/all/themes/markone/logo.png);
      position:absolute;
      left:-1000000;
      visibility:none;
    }
    #lp-pom-image-144-holder {
      background-image:url(http://s3-ap-southeast-1.amazonaws.com/files.theedgeproperty.com.my/public/images/proppoints/card1.jpg);
      position:absolute;
      left:-1000000;
      visibility:none;
    }
    #lp-pom-image-152-holder {
      background-image:url(http://s3-ap-southeast-1.amazonaws.com/files.theedgeproperty.com.my/public/images/proppoints/card2.jpg);
      position:absolute;
      left:-1000000;
      visibility:none;
    }
    #lp-pom-image-157-holder {
      background-image:url(http://s3-ap-southeast-1.amazonaws.com/files.theedgeproperty.com.my/public/images/proppoints/card3.jpg);
      position:absolute;
      left:-1000000;
      visibility:none;
    }
    #lp-pom-image-162-holder {
      background-image:url(http://s3-ap-southeast-1.amazonaws.com/files.theedgeproperty.com.my/public/images/proppoints/card4.jpg);
      position:absolute;
      left:-1000000;
      visibility:none;
    }
    #lp-pom-image-174-holder {
      background-image:url(http://s3-ap-southeast-1.amazonaws.com/files.theedgeproperty.com.my/public/images/proppoints/table_tabs.png);
      position:absolute;
      left:-1000000;
      visibility:none;
    }
    #lp-pom-text-11 {
      left:35px;
      top:198px;
      display:block;
      background:rgba(255,255,255,0);
      -pie-background:rgba(255,255,255,0);
      border-style:none;
      behavior:url(http://d9hhrg4mnvzow.cloudfront.net/PIE.htc);
      border-radius:0px;
      z-index:1;
      width:259px;
      height:78px;
      transform:scale(0.9);
      transform-origin:0 0;
      -webkit-transform:scale(0.9);
      -webkit-transform-origin:0 0;
      position:absolute;
    }
    #lp-pom-text-12 {
      left:35px;
      top:148px;
      display:block;
      background:rgba(255,255,255,0);
      -pie-background:rgba(255,255,255,0);
      border-style:none;
      behavior:url(http://d9hhrg4mnvzow.cloudfront.net/PIE.htc);
      border-radius:0px;
      z-index:2;
      width:359px;
      height:43px;
      transform:scale(0.75);
      transform-origin:0 0;
      -webkit-transform:scale(0.75);
      -webkit-transform-origin:0 0;
      position:absolute;
    }
    #lp-pom-root {
      display:block;
      background:rgba(230,231,232,1);
      -pie-background:rgba(230,231,232,1);
      border-style:none;
      margin:auto;
      padding-top:0px;
      behavior:url(http://d9hhrg4mnvzow.cloudfront.net/PIE.htc);
      border-radius:0px;
      min-width:320px;
      height:auto;
    }
    #lp-pom-block-8 {
      display:block;
      background:rgba(128,186,218,1);
      background-image:url(http://s3-ap-southeast-1.amazonaws.com/files.theedgeproperty.com.my/public/images/proppoints/proppoints_main_mobile.jpg);
      background-repeat:no-repeat;
      background-position:center bottom;
      -pie-background:rgba(128,186,218,1) url(http://s3-ap-southeast-1.amazonaws.com/files.theedgeproperty.com.my/public/images/proppoints/proppoints_main_mobile.jpg) no-repeat center bottom;
      border-style:none;
      margin-left:auto;
      margin-right:auto;
      margin-bottom:0px;
      behavior:url(http://d9hhrg4mnvzow.cloudfront.net/PIE.htc);
      border-radius:0px;
      width:100%;
      height:568px;
      position:relative;
    }
    #lp-pom-image-128 {
      display:block;
      background:rgba(255,255,255,0);
      -pie-background:rgba(255,255,255,0);
      left:35px;
      top:51px;
      z-index:10;
      position:absolute;
    }
    #lp-pom-block-21 {
      display:block;
      background:rgba(255,255,255,1);
      -pie-background:rgba(255,255,255,1);
      border-style:none;
      margin-left:auto;
      margin-right:auto;
      margin-bottom:0px;
      behavior:url(http://d9hhrg4mnvzow.cloudfront.net/PIE.htc);
      border-radius:0px;
      width:100%;
      height:1086px;
      position:relative;
    }
    #lp-pom-box-25 {
      display:block;
      background:rgba(255,255,255,1);
      -pie-background:rgba(255,255,255,1);
      border-style:solid;
      border-width:1px;
      border-color:#c1cfd4;
      behavior:url(http://d9hhrg4mnvzow.cloudfront.net/PIE.htc);
      border-radius:0px;
      left: 20px;
      top:670px;
      z-index:3;
      width:278px;
      height:458px;
      position:absolute;
    }
    #lp-pom-text-26 {
      display:block;
      background:rgba(255,255,255,0);
      -pie-background:rgba(255,255,255,0);
      border-style:none;
      behavior:url(http://d9hhrg4mnvzow.cloudfront.net/PIE.htc);
      border-radius:0px;
      top:274px;
      z-index:4;
      width:226px;
      height:44px;
      position:absolute;
    }
    #lp-pom-text-27 {
      display:block;
      background:rgba(255,255,255,0);
      -pie-background:rgba(255,255,255,0);
      border-style:none;
      behavior:url(http://d9hhrg4mnvzow.cloudfront.net/PIE.htc);
      border-radius:0px;
      top:234px;
      z-index:5;
      width:226px;
      height:24px;
      position:absolute;
    }

    #lp-pom-box-26,
    #lp-pom-box-28 {
      left: 6px;
    }
    #lp-pom-text-133 {
      display:block;
      background:rgba(255,81,34,0);
      -pie-background:rgba(255,81,34,0);
      border-style:none;
      behavior:url(http://d9hhrg4mnvzow.cloudfront.net/PIE.htc);
      border-radius:0px;
      left:23px;
      top:412px;
      z-index:6;
      width:226px;
      height:22px;
      position:absolute;
    }
    #lp-pom-image-144 {
      display:block;
      background:rgba(255,255,255,0);
      -pie-background:rgba(255,255,255,0);
      left:0px;
      top:0px;
      z-index:7;
      position:absolute;
    }
    #lp-pom-text-62 {
      display:block;
      background:rgba(255,255,255,0);
      -pie-background:rgba(255,255,255,0);
      border-style:none;
      behavior:url(http://d9hhrg4mnvzow.cloudfront.net/PIE.htc);
      border-radius:0px;
      left:0px;
      top:599px;
      z-index:8;
      width:320px;
      height:29px;
      position:absolute;
    }
    #lp-pom-box-148 {
      display:block;
      background:rgba(255,255,255,1);
      -pie-background:rgba(255,255,255,1);
      border-style:solid;
      border-width:1px;
      border-color:#c1cfd4;
      behavior:url(http://d9hhrg4mnvzow.cloudfront.net/PIE.htc);
      border-radius:0px;
      left:20px;
      top:1162px;
      z-index:27;
      width:278px;
      height:458px;
      position:absolute;
    }
    #lp-pom-text-149 {
      display:block;
      background:rgba(255,255,255,0);
      -pie-background:rgba(255,255,255,0);
      border-style:none;
      behavior:url(http://d9hhrg4mnvzow.cloudfront.net/PIE.htc);
      border-radius:0px;
      left:23px;
      top:273px;
      z-index:28;
      width:226px;
      height:66px;
      position:absolute;
    }
    #lp-pom-text-150 {
      display:block;
      background:rgba(255,255,255,0);
      -pie-background:rgba(255,255,255,0);
      border-style:none;
      behavior:url(http://d9hhrg4mnvzow.cloudfront.net/PIE.htc);
      border-radius:0px;
      left:23px;
      top:234px;
      z-index:28;
      width:226px;
      height:24px;
      position:absolute;
    }
    #lp-pom-text-151 {
      display:block;
      background:rgba(255,81,34,0);
      -pie-background:rgba(255,81,34,0);
      border-style:none;
      behavior:url(http://d9hhrg4mnvzow.cloudfront.net/PIE.htc);
      border-radius:0px;
      left:23px;
      top:416px;
      z-index:29;
      width:226px;
      height:22px;
      position:absolute;
    }
    #lp-pom-image-152 {
      display:block;
      background:rgba(255,255,255,0);
      -pie-background:rgba(255,255,255,0);
      left:0px;
      top:0px;
      z-index:30;
      position:absolute;
    }
    #lp-pom-box-153 {
      display:block;
      background:rgba(255,255,255,1);
      -pie-background:rgba(255,255,255,1);
      border-style:solid;
      border-width:1px;
      border-color:#c1cfd4;
      behavior:url(http://d9hhrg4mnvzow.cloudfront.net/PIE.htc);
      border-radius:0px;
      left:20px;
      top:1651px;
      z-index:21;
      width:278px;
      height:458px;
      position:absolute;
    }
    #lp-pom-text-154 {
      display:block;
      background:rgba(255,255,255,0);
      -pie-background:rgba(255,255,255,0);
      border-style:none;
      behavior:url(http://d9hhrg4mnvzow.cloudfront.net/PIE.htc);
      border-radius:0px;
      left:23px;
      top:274px;
      z-index:22;
      width:226px;
      height:66px;
      position:absolute;
    }
    #lp-pom-text-155 {
      display:block;
      background:rgba(255,255,255,0);
      -pie-background:rgba(255,255,255,0);
      border-style:none;
      behavior:url(http://d9hhrg4mnvzow.cloudfront.net/PIE.htc);
      border-radius:0px;
      left:23px;
      top:234px;
      z-index:23;
      width:226px;
      height:24px;
      position:absolute;
    }
    #lp-pom-text-156 {
      display:block;
      background:rgba(255,81,34,0);
      -pie-background:rgba(255,81,34,0);
      border-style:none;
      behavior:url(http://d9hhrg4mnvzow.cloudfront.net/PIE.htc);
      border-radius:0px;
      left:23px;
      top:418px;
      z-index:24;
      width:226px;
      height:22px;
      position:absolute;
    }
    #lp-pom-image-157 {
      display:block;
      background:rgba(255,255,255,0);
      -pie-background:rgba(255,255,255,0);
      left:0px;
      top:0px;
      z-index:25;
      position:absolute;
    }
    #lp-pom-box-158 {
      display:block;
      background:rgba(255,255,255,1);
      -pie-background:rgba(255,255,255,1);
      border-style:solid;
      border-width:1px;
      border-color:#c1cfd4;
      behavior:url(http://d9hhrg4mnvzow.cloudfront.net/PIE.htc);
      border-radius:0px;
      left:20px;
      top:2138px;
      z-index:16;
      width:278px;
      height:458px;
      position:absolute;
    }
    #lp-pom-text-159 {
      display:block;
      background:rgba(255,255,255,0);
      -pie-background:rgba(255,255,255,0);
      border-style:none;
      behavior:url(http://d9hhrg4mnvzow.cloudfront.net/PIE.htc);
      border-radius:0px;
      left:23px;
      top:273px;
      z-index:17;
      width:226px;
      height:132px;
      position:absolute;
    }
    #lp-pom-text-160 {
      display:block;
      background:rgba(255,255,255,0);
      -pie-background:rgba(255,255,255,0);
      border-style:none;
      behavior:url(http://d9hhrg4mnvzow.cloudfront.net/PIE.htc);
      border-radius:0px;
      left:23px;
      top:234px;
      z-index:18;
      width:226px;
      height:24px;
      position:absolute;
    }
    #lp-pom-text-161 {
      display:block;
      background:rgba(255,81,34,0);
      -pie-background:rgba(255,81,34,0);
      border-style:none;
      behavior:url(http://d9hhrg4mnvzow.cloudfront.net/PIE.htc);
      border-radius:0px;
      left:23px;
      top:417px;
      z-index:19;
      width:226px;
      height:22px;
      position:absolute;
    }
    #lp-pom-image-162 {
      display:block;
      background:rgba(255,255,255,0);
      -pie-background:rgba(255,255,255,0);
      left:0px;
      top:0px;
      z-index:20;
      position:absolute;
    }
    #lp-pom-block-22 {
      display:block;
      background:rgba(230,231,232,1);
      -pie-background:rgba(230,231,232,1);
      border-style:solid none none none;
      border-width:1px;
      border-color:#c1cfd4;
      margin-left:auto;
      margin-right:auto;
      margin-bottom:0px;
      behavior:url(http://d9hhrg4mnvzow.cloudfront.net/PIE.htc);
      border-radius:0px;
      width:320px;
      height:545px;
      position:relative;
    }
    #lp-code-138 {
      display:block;
      left:4px;
      top:1766px;
      z-index:11;
      width:312px;
      height:915px;
      position:absolute;
    }
    #lp-code-139 {
      display:none;
      left:107px;
      top:2738px;
      z-index:12;
      width:209px;
      height:47px;
      position:absolute;
    }
    #lp-pom-text-163 {
      display:block;
      background:rgba(255,255,255,0);
      -pie-background:rgba(255,255,255,0);
      border-style:none;
      behavior:url(http://d9hhrg4mnvzow.cloudfront.net/PIE.htc);
      border-radius:0px;
      left:0px;
      top:1690px;
      z-index:13;
      width:320px;
      height:29px;
      position:absolute;
    }
    #lp-pom-image-174 {
      display:block;
      background:rgba(255,255,255,0);
      -pie-background:rgba(255,255,255,0);
      left:116px;
      top:1743px;
      z-index:31;
      position:absolute;
    }
    #lp-pom-block-23 {
      display:block;
      background:rgba(255,255,255,1);
      -pie-background:rgba(255,255,255,1);
      border-style:none;
      margin-left:auto;
      margin-right:auto;
      margin-bottom:2px;
      behavior:url(http://d9hhrg4mnvzow.cloudfront.net/PIE.htc);
      border-radius:0px;
      width:100%;
      height:2852px;
      position:relative;
    }
    #lp-pom-text-165 {
      display:block;
      background:rgba(255,255,255,0);
      -pie-background:rgba(255,255,255,0);
      border-style:none;
      behavior:url(http://d9hhrg4mnvzow.cloudfront.net/PIE.htc);
      border-radius:0px;
      left:0px;
      top:2220px;
      z-index:14;
      width:320px;
      height:29px;
      position:absolute;
    }
    #lp-code-168 {
      display:block;
      left:8px;
      top:2009px;
      z-index:15;
      width:304px;
      height:auto;
      position:absolute;
    }
    #lp-pom-block-24 {
      display:block;
      background:rgba(15,49,76,1);
      -pie-background:rgba(15,49,76,1);
      border-style:none;
      margin-left:auto;
      margin-right:auto;
      margin-bottom:1px;
      behavior:url(http://d9hhrg4mnvzow.cloudfront.net/PIE.htc);
      border-radius:0px;
      width:100%;
      height:95px;
      position:relative;
      z-index: 9;
    }
    #lp-pom-text-76 {
      display:block;
      background:rgba(255,255,255,0);
      -pie-background:rgba(255,255,255,0);
      border-style:none;
      behavior:url(http://d9hhrg4mnvzow.cloudfront.net/PIE.htc);
      border-radius:0px;
      left:0px;
      top:5387px;
      z-index:9;
      width:325px;
      height:22px;
      position:absolute;
    }
    body { color:#4a646f; }
    a {
      color:#0000ff;
      text-decoration:none;
    }
    #lp-pom-root .lp-positioned-content {
      top:0px;
      width:320px;
      margin-left:-160px;
    }
    #lp-pom-block-8 .lp-pom-block-content {
      margin-left:auto;
      margin-right:auto;
      width:320px;
      height:568px;
    }
    #lp-pom-block-21 .lp-pom-block-content {
      margin-left:auto;
      margin-right:auto;
      width:320px;
      height:auto;
    }
    #lp-pom-block-22 .lp-pom-block-content {
      margin-left:auto;
      margin-right:auto;
      width:320px;
      height:1044px;
    }
    #lp-pom-block-23 .lp-pom-block-content {
      margin-left:auto;
      margin-right:auto;
      width:320px;
      height:auto;
    }
    #lp-pom-block-24 .lp-pom-block-content {
      margin-left:auto;
      margin-right:auto;
      width:320px;
      height:95px;
    }
    #lp-pom-image-128 .lp-pom-image-container {
      border-style:none;
      behavior:url(http://d9hhrg4mnvzow.cloudfront.net/PIE.htc);
      border-radius:0px;
      width:141px;
      height:51px;
    }
    #lp-pom-image-144 .lp-pom-image-container {
      border-style:none;
      behavior:url(http://d9hhrg4mnvzow.cloudfront.net/PIE.htc);
      border-radius:0px;
      width:278px;
      height:208px;
    }
    #lp-pom-image-144 .lp-pom-image-container img {
      width:278px;
      height:208px;
    }
    #lp-pom-image-152 .lp-pom-image-container {
      border-style:none;
      behavior:url(http://d9hhrg4mnvzow.cloudfront.net/PIE.htc);
      border-radius:0px;
      width:278px;
      height:208px;
    }
    #lp-pom-image-152 .lp-pom-image-container img {
      width:278px;
      height:208px;
    }
    #lp-pom-image-157 .lp-pom-image-container {
      border-style:none;
      behavior:url(http://d9hhrg4mnvzow.cloudfront.net/PIE.htc);
      border-radius:0px;
      width:278px;
      height:208px;
    }
    #lp-pom-image-157 .lp-pom-image-container img {
      width:278px;
      height:208px;
    }
    #lp-pom-image-162 .lp-pom-image-container {
      border-style:none;
      behavior:url(http://d9hhrg4mnvzow.cloudfront.net/PIE.htc);
      border-radius:0px;
      width:278px;
      height:208px;
    }
    #lp-pom-image-162 .lp-pom-image-container img {
      width:278px;
      height:208px;
    }
    #lp-pom-image-174 .lp-pom-image-container {
      border-style:none;
      behavior:url(http://d9hhrg4mnvzow.cloudfront.net/PIE.htc);
      border-radius:0px;
      width:201px;
      height:23px;
    }
    #lp-pom-image-174 .lp-pom-image-container img {
      width:201px;
      height:23px;
    }
    #lp-pom-root-color-overlay {
      position:absolute;
      background:none;
      top:0;
      width:100%;
      height:auto;
      min-height:100%;
    }
    #lp-pom-block-8-color-overlay {
      position:absolute;
      background:none;
      height:568px;
      width:100%;
    }
    #lp-pom-block-21-color-overlay {
      position:absolute;
      background:none;
      height:auto;
      width:100%;
    }
    #lp-pom-block-22-color-overlay {
      position:absolute;
      background:none;
      height:1044px;
      width:320px;
    }
    #lp-pom-block-23-color-overlay {
      position:absolute;
      background:none;
      height:auto;
      width:100%;
    }
    #lp-pom-block-24-color-overlay {
      position:absolute;
      background:none;
      height:95px;
      width:100%; 
    }
    #lp-pom-box-25-color-overlay {
      position:absolute;
      background:none;
      behavior:url(http://d9hhrg4mnvzow.cloudfront.net/PIE.htc);
      height:458px;
      width:278px;
    }
    #lp-pom-box-148-color-overlay {
      position:absolute;
      background:none;
      behavior:url(http://d9hhrg4mnvzow.cloudfront.net/PIE.htc);
      height:458px;
      width:278px;
    }
    #lp-pom-box-153-color-overlay {
      position:absolute;
      background:none;
      behavior:url(http://d9hhrg4mnvzow.cloudfront.net/PIE.htc);
      height:458px;
      width:278px;
    }
    #lp-pom-box-158-color-overlay {
      position:absolute;
      background:none;
      behavior:url(http://d9hhrg4mnvzow.cloudfront.net/PIE.htc);
      height:458px;
      width:278px;
    }
  }
}

