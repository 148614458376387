$width-news-content: 970px;
$width-main-content: 67.25%;
$width-sidebar:      32.75%; 
@import url('https://fonts.googleapis.com/css2?family=Rubik:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,500&display=swap');
@import url('https://fonts.googleapis.com/css2?family=IBM+Plex+Serif:wght@200;300;400;500;700&display=swap');
// $blog-font: 'Noto Serif', serif;
// $blog-font: 'Lab Grotesque', Helvetica, Arial, sans-serif;
// $blog-font: 'Rubik', Helvetica, Arial, sans-serif;
$widget-side-trending: 'Rubik', Helvetica, Arial, sans-serif !important;
$blog-font: 'Poppins', sans-serif !important;
$poppins-font: 'Poppins', sans-serif !important;
$content-font: 'Poppins', sans-serif !important;
// $content-font: "TiemposTextWeb";

$body-color: #212529;
$blue_22: #0e6bc5;
body { 
    .subs-news {
        display: none;
    }
    &.page-news,
    //guides
    &.node-type-tep-blogs,
    &.page-guides,
    &.page-edgeproptv,
    &.page-pullout,
    &.page-events,
    &.page-event,
    &.page-tags,
    &.page-node-29,
    &.page-node-34,
    //news
    &.page-content {
        color: $body-color;
        padding-top: 0; 
        .news-end-quickaccess {
            ul.content-bullet {
                // list-style: inherit;
                li {
                    font-size: inherit;
                    margin-bottom: 15px;
                    // font-family: $content-font;
                }
            }
        } 
        ul.content-bullet-list {
            font-family: "Rubik", Helvetica, Arial, sans-serif !important;
            line-height: 1.4;
            margin: 0 14px;
            padding-left: 30px !important;
            padding-right: 15px;
            // padding-left: 21px;
            @media (max-width: 767px) {
                margin: 0;
            }
            @media (min-width: 1024px) {
                padding-left: 8px !important;
            }
            li {
                font-weight: 500;
                list-style-type: none;
                // border-bottom: 1px solid #e8e9ea;
                margin: 0;
                padding-bottom: 15px;
                padding-left: 0;
                padding-top: 15px; 
                position: relative;
                font-size: 18px; 
                color: #000;
                @media (max-width: 767px) {
                    padding: 15px 10px 15px 5px;
                }

                &:before {
                    background: #0e6bc5;
                    content: "";
                    height: 7px;
                    left: -21px;
                    position: absolute;
                    top: 31px;
                    width: 7px;
                    @media (max-width: 767px) {
                        left: -13px;
                    }
                }
                // &:first-child {
                //     &:before {
                //         top: 8px;
                //     }
                // }
            }

        }
        .container-fluid.in-content-container {
            padding: 0;
            .ads-in-content {
                text-align: center;
            }
        }
        // @import url('https://fonts.googleapis.com/css?family=Montserrat:400,700|Roboto:400,700');

        // html,body,div,span,applet,object,iframe,h1,nph2,h3,h4,h5,h6,p,
        // blockquote,pre,a,abbr,acronym,address,big,cite,code,del,dfn,em,img,
        // ins,kbd,q,s,samp,small,strike,strong,sub,sup,tt,var,b,u,i,center,dl,dt,
        // dd,ol,ul,li,fieldset,form,label,legend,table,caption,tbody,tfoot,thead,
        // tr,th,td,article,aside,canvas,details,embed,figure,figcaption, header,
        // hgroup,menu,nav,output,ruby,section,summary,time,mark,audio,video {
        // // margin: 0;
        // // padding: 0;
        // // border: 0;
        // // font-size: 100%;
        // // font: inherit;
        // // vertical-align: baseline;
        // -webkit-font-smoothing: antialiased;
        // -moz-osx-font-smoothing: grayscale;
        // }
        // #scroll-header {
        //     display: block !important;
        // }
        .sidebar-component-new.overlay-new {
            font-family: $poppins-font;
            div, h6, a {
                font-family: $poppins-font;
            } 
        }

        h1,h2,h3,h5,h6 {
            font-weight: 500 !important;
            font-family: $blog-font;
        } 

        /* HTML5 display-role reset for older browsers */

        article,aside,details,figcaption,figure, header,
        hgroup,menu,nav,section {
            display: block;
        }

        /* ignore ios input style */

        input,
        textarea {
            -webkit-border-radius: 0;
            border-radius: 0;
        }

        /* Common CSS */

        ol,
        ul {
            list-style: none;
            padding: 0;
        }

        blockquote,
        q {
            quotes: none;
        }

        blockquote:before,blockquote:after,q:before,q:after {
        content: '';
        content: none;
        }

        table {
            border-collapse: collapse;
            border-spacing: 0;
        }
        b,
        strong {
            font-weight: bold;
        }

        em {
            font-style: italic;
        }

        a {
            text-decoration: none;
        }

        *:focus {
            outline: none;
        }

        /* 2. Common Styles */

        .cleared {
            clear: both;
        }

        .center {
            text-align: center;
        }

        .hidden {
            display: none;
        }

        /* typography */

        .content h1,.content h2,.content h3,
        .content h4,.content h5,.content h6 {
            color: #1a1a1a;
        }

        .content {
            color: #666666;
        }

        /* typography */

        h1 {
            font-size: 36px;
        }

        h2 {
            font-size: 28px;
        }

        h3 {
            font-size: 24px;
        }

        h4 {
            font-size: 22px;
        }

        h5 {
            font-size: 20px;
        }

        h6 {
            font-size: 18px;
        } 
        blockquote {
            font-size: 18px;
            line-height: 24px;
            font-weight: bold;
            span {
                font-size: 150px;
                line-height: 48px;
                font-weight: 400;
                font-style: normal;
            }
        }
        .slider-position {
            &.stick-top {
                position: sticky;
                position: -webkit-sticky;
                // position: fixed;
                top: 5.2rem;
                z-index: 2002;
                &.top-zero {
                    top:-1px;
                    padding-top: 15px;
                    background: white;
                }
                .container-fluid {
                    box-shadow: 0px 5px 10px 0px rgba(0, 0, 0, 0.1);
                }
                #scroll-header {
                    position: fixed;
                    top: 0px;
                    z-index: 2002;
                    width: 100%;
                }

            }   
        }
        body {
            // font-family: 'Roboto', Arial;
        }

        span {
        display: inline-block; 
        }
        div, span, label {
            font-family: $blog-font;
        }

        .navbar-new,
        .dropdown.mega-dropdown,
        .dropdown-menu {
            h4, div {
                font-family: $poppins-font;
            } 
        }

        a {
            cursor: pointer;
            text-decoration: none;
            color: #1a1a1a;
            display: inline-block;
            transition: all 200ms ease-out;
            /*safari and chrome */
            -webkit-transition: all 200ms ease-out;
            /*safari and chrome */
        }

        img {
            max-width: 100%;
            height: auto;
        }

        .content img {
            max-width: 100% !important;
        }

        iframe,embed,video,audio {
            max-width: 100%;
        }

        *:focus {
            outline: none;
        }

        *,*:before,*:after {
            -webkit-box-sizing: border-box;
            -moz-box-sizing: border-box;
            box-sizing: border-box;
        }
        .news-content-wrapper {
            overflow-x: hidden;
        }
        .wrap {
            width: 1200px;
            margin: auto;
            position: relative;
            // temporary fix for leaderboard
            top: 116px;
            margin-bottom: 120px;
            z-index: 2;

            @media(max-width: 991px) {
                top: 0;
                margin-bottom: 0;
            }

            &.news-wope-theme {
                width: $width-news-content;
                background: #fff;
                top: 0;
                // background : pink;
            }

            &-top-0 {
                top: 0;
            }
        }

        .wrap-colum {
            width: 1240px;
            margin: auto;
            position: relative;
        }

        .clearfix:before,.clearfix:after {
            content: " ";
            /* 1 */
            display: table;
            /* 2 */
        }

        /*
        * For IE 6/7 only
        * Include this rule to trigger hasLayout and contain floats.
        */

        .clearfix {
            *zoom: 1;
            &:after {
                clear: both;
            }
        }

        figure {
            font-size: 0;
            margin-bottom: 35px;
            &.first {
                margin-bottom: 0;
            }
        }

        figcaption {
            font-size: 14px;
            color: $body-color;
            font-weight: 600;
        }

        .col-2-1 {
            float: left;
            width: 50%;
        }

        .col-4-1 {
            float: left;
            width: 25%;
        }

        .col-3-1 {
            float: left;
            width: 33.33%;
        }

        .col-3-2 {
            float: left;
            width: 66.66%;
        }

        .colum-iblock,
        .iblock-2 {
            letter-spacing: -5px;
        }

        .colum-iblock>div,.colum-iblock>article,.iblock-2>* {
                letter-spacing: 0;
                display: inline-block;
                float: none;
                vertical-align: middle;
        }

        .iblock-2 {
            article {
                width: 50%;
                &:nth-of-type(2n+1) {
                    padding-right: 19px;
                }
                &:nth-of-type(2n+2) {
                    padding-left: 19px;
                }
            }
        }

        .small-column {
            width: 27.5%;
            box-sizing: border-box;
            -moz-box-sizing: border-box;
            /* Firefox */
            -webkit-box-sizing: border-box;
            position: relative;
            z-index: 2;
            display: table-cell;
            vertical-align: top;
        }

        .big-column {
            width: 72.5%;
            box-sizing: border-box;
            -moz-box-sizing: border-box;
            /* Firefox */
            -webkit-box-sizing: border-box;
            position: relative;
            z-index: 3;
            display: table-cell;
            vertical-align: top;
        }

        .left {
            float: left;
        }

        .right {
            float: right !important;
        }

        .column1,.column1_1 {
            float: left;
            width: 100%;
        }

        .column1 {
            &_2 {
                float: left;
                width: 48%;
                margin-right: 4%;
            }
            &_3 {
                float: left;
                width: 31%;
                margin-right: 4%;
                &.column-last {
                    width: 30%;
                }
            }
            &_4 {
                float: left;
                width: 22%;
                margin-right: 4%;
            }
        }

        .column2 {
            &_3 {
                float: left;
                width: 66%;
                margin-right: 4%;
                &.column-last {
                    width: 65%;
                }
            }
            &_4 {
                float: left;
                width: 48%;
                margin-right: 4%;
            }
        }
  
        .column3_4 {
            float: left;
            width: 74%;
            margin-right: 4%;
        }

        // .footer-column {
        // box-sizing: border-box;
        // -moz-box-sizing: border-box;
        // /* Firefox */
        // -webkit-box-sizing: border-box;
        // width: 32%;
        // margin-right: 3%;
        // float: left;
        // padding-top: 53px;
        // padding-bottom: 50px;
        // }

        // .footer-column.column-last {
        // width: 30%;
        // }

        .column-last {
            margin-right: 0%;
        }
        .no_margin {
            margin-bottom: 0px;
        }

        /***** 3. Parent Structure *****/

        #back_top {
            display: none;
            width: 50px;
            height: 50px;
            position: fixed;
            bottom: 25px;
            right: 0px;
            margin-right: 26px;
            z-index: 100;
            transition: all 200ms ease-out;
            /*safari and chrome */
            -webkit-transition: all 200ms ease-out;
            /*safari and chrome */
            cursor: pointer;
            text-align: center;
            i {
                line-height: 50px;
                font-size: 30px;
                transition: all 200ms ease-out;
                /*safari and chrome */
                -webkit-transition: all 200ms ease-out;
                /*safari and chrome */
            }
        }

        /*TOGGLE MENU*/

        .toggle-menu-button {
            height: 73px;
            display: none;
            cursor: pointer;
            text-align: right;
            float: right;
            letter-spacing: 0;
            i {
                font-size: 23px;
                line-height: 75px;
                color: #999;
            }
        }

        .toggle-menu-top {
            text-align: left;
            padding-bottom: 10px;
        }

        .toggle-menu-close {
            width: 30px;
            height: 30px;
            text-align: center;
            border-radius: 20px;
            display: inline-block;
            cursor: pointer;
            transition: all 200ms ease-out;
            /*safari and chrome */
            -webkit-transition: all 200ms ease-out;
            /*safari and chrome */
            margin-bottom: 10px;
            i {
                font-size: 24;
                line-height: 30px;
                color: rgba(255, 255, 255, 0.5);
            }
        }

        #main-menu-toggle {
            background-color: #2e2e2e;
            position: absolute;
            top: 0px;
            left: -300px;
            width: 300px;
            min-height: 100%;
            padding: 40px 30px 40px;
            z-index: 9999;
            -webkit-transition: all 0.3s ease;
            -moz-transition: all 0.3s ease;
            transition: all 0.3s ease;
            box-sizing: border-box;
            -moz-box-sizing: border-box;
            /* Firefox */
            a {
                font-size: 12px;
                line-height: 24px;
                font-style: normal;
                font-weight: 500;
                // text-transform: uppercase;
                color: #ffffff;
                letter-spacing: 1px;
                padding: 10px 0px 10px 0px;
                display: block;
                transition: all 200ms ease-out;
                /*safari and chrome */
                -webkit-transition: all 200ms ease-out;
                /*safari and chrome */
            }
            &.toggle-menu-open {
                left: 0px;
            }
            ul ul.sub-menu li {
                padding-left: 30px;
            }
            ul ul.sub-menu ul.sub-menu li {
                padding-left: 60px;
            }
        
        }

        /***** 4. Header *****/

        #page #header {
            left: 0px;
        }

        #top-bar {
            -webkit-box-shadow: 0px 5px 10px 0px rgba(0, 0, 0, 0.1);
            -moz-box-shadow: 0px 5px 10px 0px rgba(0, 0, 0, 0.1);
            box-shadow: 0px 5px 10px 0px rgba(0, 0, 0, 0.1);
            .wrap {
                z-index: 996;
            }
        }
 
        .topbar-menu,
        .menu-top-bar-container {
            float: left;
        }

        .topbar-menu {
            width: 60%;
            ul li {
                position: relative;
                .sub-menu {
                    position: absolute;
                    width: 250px;
                    padding: 20px;
                    left: -26px;
                    background-color: #000;
                    display: none;
                }
            }
        }

        .topbar-social-network {
            float: right;
            width: 40%;
            ul {
                float: right;
                li:hover>.sub-menu {
                    display: block;
                }
            }
        }

        .topbar-menu ul li,
        .social-network-list li,
        .topbar-datetime {
            float: left;
        }

        .topbar-menu ul li ul li:hover>.sub-menu {
            top: -20px;
            left: 100%;
        }

        .topbar-menu ul li .sub-menu li {
            float: none;
            display: block;
        }

        .topbar-menu ul li .sub-menu li a {
        color: #fff;
        }

        .topbar-menu ul li a,
        .topbar-datetime {
            display: block;
            text-transform: uppercase;
            color: #1a1a1a;
            font-size: 12px;
            font-weight: normal;
            line-height: 14px;
            padding: 10px 22px 10px 0;
        }

        .social-network-list li a {
            display: block;
            color: #1a1a1a;
            font-size: 14px;
            font-weight: normal;
            line-height: 18px;
            padding: 8px 0 8px 22px;
        }

        .topbar-menu ul li a:hover,
        .social-network-list li a:hover {
            color: #808080;
            text-decoration: none;
        }

        // .footer-sidebar-fixed .social-network-list li a {
        // padding: 8px 22px 8px 0;
        // color: #999;
        // }

        // .footer-sidebar-fixed .social-network-list li a:hover {
        // color: #fff;
        // }

        // .footer-sidebar-fixed .social-network-list {
        // margin-bottom: 22px;
        // }

        .site-logo {
            h1 {
                font-size: 30px;
                line-height: 36px;
                font-weight: 900;
                color: #1a1a1a;
                text-transform: uppercase;
            }
            p {
                font-size: 12px;
                line-height: 18px;
                letter-spacing: 1px;
                color: #999;
                text-transform: uppercase;
            }
        }
        .site-banner {
            position: relative;
        }

        .widget_media_image.content {
            line-height: 0;
        }

        

        /* WordPress CSS */

        .alignleft,
        img.alignleft {
            float: left;
            padding: 5px 20px 10px 0px;
        }

        .alignright,
        img.alignright {
            float: right;
            padding: 5px 0px 10px 20px;
        }

        .aligncenter {
            text-align: center;
        }

        img.aligncenter {
            clear: both;
            display: block;
            margin-left: auto;
            margin-right: auto;
        } 

        .wp-caption p.wp-caption-text { 
            font-style: italic;
            font-size: 14px;
            line-height: 28px;
        }

        .alignnone, img.alignnone, .wp-caption, .wp-caption img, .wp-smiley, .gallery dl, 
        .gallery dt, .gallery dd, .gallery dl a, .gallery dl img, .size-full, .size-large, .size-medium,
        .size-thumbnail, .bypostauthor, .screen-reader-text:focus {
            position: initial;
        } 

        .gallery-caption {
            font-size: 16px;
            line-height: 24px;
            padding-top: 10px;
        } 

        .sticky {
            padding: 24px;
            background-color: #e6e6e6;
        }

        .post-layout .sticky .entry-thumb {
            margin-bottom: 0;
        }

        .box-thumb {
            float: left;
            position: relative;
        }

        .e-right {
            float: right;
        }

        pre {
            padding: 12px;
        }

        /* 5. Body */

        /* 5.1 Page */

        #background {
            position: relative;
            z-index: 1;
            line-height: 20px;
            font-size: 14px;
            overflow: hidden;
            width: 100%;
        }

        #page {
            background-color: #fff;
            margin: auto;
            position: relative;
            &.layout-boxed {
                padding: 0px 0px 0px 0px;
                margin-top: 30px;
                margin-bottom: 30px;
                width: 1280px;
                box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
            }
        }

        .text-404 {
            text-align: center;
            font-size: 150px;
            line-height: 204px;
            font-weight: 400;
            text-transform: uppercase;
        }

        .page-heading {
            font-size: 36px;
            line-height: 48px;
            font-weight: bold;
            color: #1a1a1a;
            padding: 24px 0 46px 0;
            margin: 0;
        }

        .error404 {
            .page-heading {
                text-align: center;
            }
            .main-content {
                padding: 100px 0;
            }  
        }

        

        /*5.2 Post*/

        .content {
            // margin-bottom: 32px;
            ul, ol {
                // font-family: "TiemposTextWeb";
                list-style-type: disc;
                padding-left: 30px;
                margin-bottom: 22px;
                li {
                    margin-bottom: 14px;
                    &:last-of-type {
                        margin-bottom: 0;
                    }
                }
                a {
                    color: #488BF8;
                }
            }
            .menu {
                list-style-type: none;
                padding-left: 0;
                margin-bottom: 0;
            } 
            blockquote {
                p {
                    position: relative;
                    font-size: 18px;
                    font-weight: bold;
                    line-height: 28px;
                    color: #1a1a1a;
                    padding-bottom: 0;
                }  
                &:before {
                    content: '\201C';
                    position: absolute;
                    top: 68px;
                    left: 30px;
                    font-size: 150.1px;
                    line-height: 28px;
                    font-weight: normal;
                    color: #ccc;
                }            
            }
            h1, h2, h3, h4, h5, h6 {
                padding: 18px 0px 28px 0px;
            }
            .entry-title {
                padding: 0;
            }
            ol {
                list-style-type: decimal;
                padding: 2px 0px 6px 30px;
                @media (max-width: 991px) {
                    padding: 2px 0px 6px 38px;
                }
            }
            ul {
                @media (max-width: 991px) {
                    padding: 2px 0px 6px 38px;
                }
            }
        }

        .wp-caption-text {
            font-size: 14px;
            line-height: 28px;
            font-style: italic;
        }
        blockquote {
            position: relative;
            margin: 0px 50px 30px;
            background-color: #f2f2f2;
            padding: 32px 50px;
        }
        .entry-title a:hover {
            color: #808080;
            text-decoration: none;
        }
        // .single-post .entry-footer .entry-socials {
        // margin-bottom: 46px;
        // border-bottom: 1px solid #e5e5e5;
        // padding-bottom: 72px;
        // font-size: 12px;
        // line-height: 12px;
        // display: block;
        // }

        /*Social network share*/

        .top1-entry-post-share {
            margin-top: 10px;
            margin-bottom: 20px;
        }

        .top2-entry-post-share {
            margin-bottom: 13px;
            @media (min-width: 768px) {
                margin-bottom: 0;
            }
        }

        .bottom-entry-post-share {
            display: none;
            padding: 12px 28px;
            padding-bottom: 0;
            border: 1px solid #1a1a1a;
            margin-bottom: 50px;
            span {
                font-size: 18px;
                font-weight: bold;
                letter-spacing: 1px;
                line-height: 24px;
                color: #1a1a1a;
                margin-right: 20px;
            }
        }

        .wope-social-share {
            display: inline-block;
            a {
                font-size: 14px;
                line-height: 18px;
                padding: 8.5px 12px;
                margin-bottom: 12px;
                color: #fff;
                margin-right: 8px;
                text-align: center;
                // min-height: 36px;
                /* Safari 3-4, iOS 1-3.2, Android 1.6- */
                -webkit-border-radius: 3px;
                /* Firefox 1-3.6 */
                -moz-border-radius: 3px;
                /* Opera 10.5, IE 9, Safari 5, Chrome, Firefox 4, iOS 4, Android 2.1+ */
                border-radius: 3px;
                &:hover {
                    text-decoration: none;
                }
                &:last-child {
                    margin-right: 0;
                }
                i {
                    font-size: 15px;
                    line-height: 18px;
                } 
            }
            &.theme_2022 {
                // display: block;
                .round-i-media {
                    border: 1px solid #666;
                    border-radius: 50%;
                    height: 33px;
                    width: 33px;
                    padding: 8px;
                    line-height: 1.5;
                    span {
                        height: 16px;
                        width: 16px;
                        &.fb {
                            background: url("https://media.edgeprop.my/icon/social-media/fb.png") center; 
                        } 
                        &.twitter {
                            background: url("https://media.edgeprop.my/icon/social-media/twitter.png") center; 
                        }
                        &.whatsapp {
                            background: url("https://media.edgeprop.my/icon/social-media/wa.png") center; 
                        }
                        &.pinterest {
                            background: url("https://media.edgeprop.my/icon/social-media/pin.png") center; 
                        }
                        &.linkedin {
                            background: url("https://media.edgeprop.my/icon/social-media/in.png") center; 
                        }
                        &.email {
                            background: url("https://media.edgeprop.my/icon/social-media/mail.png") center; 
                        }
                        &.print {
                            background: url("https://media.edgeprop.my/icon/social-media/print.png") center; 
                        }
                    }
                    &:hover {
                        border-color: #096ffa;
                    }
                }
            }
        }

        .fb-ss,
        .apsc-facebook-icon {
            background-color: #3b5998;
            &:hover {
                background-color: #6a7999;
            }
        }

        .i-ss {
            width: 37px;
        }

        .fb-ss i {
            padding-right: 12px;
            border-right: 1px solid #4d69a2;
            margin-right: 11px;
        }

        .twitter-ss,
        .apsc-twitter-icon {
            background-color: #1da1f2;
            &:hover {
                background-color: #66bcf2;
            }
        }

        .twitter-ss i {
            padding-right: 12px;
            border-right: 1px solid #35abf4;
            margin-right: 11px;
        }

        .ws-ss {
            background-color: #25d366;
            &:hover {
                background-color: #57e28b;
            }
        }

        .gp-ss,
        .apsc-google-plus-icon {
            background-color: #dd4b39;
            &:hover {
                background-color: #de887c;
            }
        }


        .pinterest-ss {
            background-color: #bd081c;
            &:hover {
                background-color: #bd404f;
            }
        }

        .linkedin-ss {
        background-color: #0077b5;
        }



        .tumblr-ss {
        background-color: #35465c;
        }

        .tumblr-ss:hover {
        background-color: #51565c;
        }

        .reddit-ss {
        background-color: #ff4500;
        }

        .reddit-ss:hover {
        background-color: #ff7c4c;
        }

        .email-ss,
        .print-ss {
        background-color: #969696;
        }

        .email-ss:hover,
        .print-ss:hover {
        background-color: #c9c9c9;
        }

        .apsc-instagram-icon {
        background-color: #e4405f;
        }

        .apsc-instagram-icon:hover {
        background-color: #e38495;
        }

        .apsc-soundcloud-icon {
        background-color: #ff8800;
        }

        .apsc-soundcloud-icon:hover {
        background-color: #ffac4c;
        }

        .apsc-youtube-icon {
        background-color: #cd201f;
        }

        .apsc-youtube-icon:hover {
        background-color: #cc5c5c;
        }

        .apsc-dribbble-icon {
        background-color: #ea4c89;
        }

        .apsc-dribbble-icon:hover {
        background-color: #eb91b4;
        }

        /**POST SINGLE**/

        .single-post .main-content {
        margin-bottom: 64px;
        }

        .main-content {
            float: left;
            width: $width-main-content;
            padding: 10px;
            @media (min-width: 992px) {
                padding: 0 0 10px;
            }

        }

        .no-sidebar {
            .main-content {
                float: none;
                width: 100%;
            } 
            .sidebar {
                display: none;
                float: none;
            }      
        }

        .sidebar {
            float: right;
            width: $width-sidebar;
            padding: 10px 0px 10px 0;
            @media (min-width: 992px) {
                padding-top: 0;
            }
        }

        .entry-categories {
            a {
                font-weight: bold;
                color: $blue_22;
                line-height: 14px;
                text-transform: uppercase;
                // background-color: ;
                // letter-spacing: 1px;
                // padding: 5px 15px;
                display: inline-block;
                font-size: 12px; 
                margin-right: 8px;
                &:hover {
                    text-decoration: none;
                }

            }
        }

        .header_post_style2 .entry-categories a:hover,
        .header_post_style3 .entry-categories a:hover {
            background-color: #fff;
            color: #1a1a1a;
            text-decoration: none;
        }

        .entry-meta,
        .entry-meta a {
            // font-weight: normal;
            font-size: 13px !important;
            text-transform: initial;
            color: $body-color;
            font-family: $blog-font; 
            // line-height: 18px;
            // font-family: $blog-font;
            // font-size: .875rem;
            // letter-spacing: -.3px;
            // font-weight: 900;
            // font-style: normal;
        }

        .entry-meta {
            a {
                font-weight: bold;
                &:hover {
                    color: #808080;
                    text-decoration: none;
                }
            }  
        }

        h2.entry-title,
        h1.entry-title {
            font-weight: 600 !important;
            // letter-spacing: .3px;
            font-size: 44px;
            line-height: 1.21;
            color: #1a1a1a;
            padding: 28px 0 15px;
            border-bottom: 1px solid #ccc;
            margin: 0;
            // line-height: 1.21;
            @media (min-width: 1260px) {
                margin-bottom: 11px;
            }
            @media (max-width: 768px) {
                font-size: 2.4rem;
                line-height: 1.2;
                margin: 0 0 15px;
                padding:0 10px 15px;
                letter-spacing: -.3px;
            }
        }

        .entry-thumb,
        .post-header {
            margin-bottom: 28px;
            overflow: hidden;
            position: relative;
        }

        .post-header-media {
            margin-bottom: 8px;
        }

        .cat-meta-line {
            .entry-categories {
                // Please enable this to put back the meta dom position
                // float: left;
            }   
            .entry-meta {
                // Please enable this to put back the meta dom position
                // float: right;
            }    
        }

        .header_post_style2 .post-header {
            background-repeat: no-repeat;
            background-size: cover;
            padding: 16% 0;
            text-align: center;
            margin-top: -25px;
        }

        .container-background {
            margin-top: -25px;
            background-color: #222;
            margin-bottom: 28px;
        }

        .header_post_style2  {
            .entry-heading {
                padding: 0 16%;
            }  
            .entry-title  {
                color: #fff;
                padding: 24px 0;
                font-size: 42px;
            }    
        }

        .header_post_style3 {
            .post-header {
                background-repeat: no-repeat;
                background-size: cover;
                background-position: center;
                padding-top: 34%;
                padding-bottom: 3.5%;
            } 
            .entry-heading {
                padding: 0 4.5%;
            }
            .entry-title {
                color: #fff;
                font-size: 42px;
                padding: 28px 0;
            }
        }

        .header_post_style4 .entry-heading {
            text-align: center;
        }

        .background-media {
            padding-bottom: 48px;
            padding-top: 48px;
        }

        #pingbacks {
            margin-bottom: 22px;
        }

        .pingbacklist {
            .comment-author {
                padding: 18px 0;
                img {
                    margin-right: 12px;
                }
            }       
        }

        /*5.3 Category Page*/
        .category .page-heading {
            padding: 18px 0 22px 0;
        }

        .horizontal-layout {
            padding-bottom: 0
        }

        .list-cat-child {
            padding: 12px 0;
            a {
                font-size: 14px;
                line-height: 16px;
                color: #666;
                padding: 4px 8px;
                border: 1px solid #e5e5e5;
                margin-right: 10px;
                margin-bottom: 8px;
                &:hover {
                    background-color: #2884ec;
                    border: 1px solid #2884ec;
                    color: #fff;
                }
            }
        }

        .horizontal-layout .entry-thumb,
        .horizontal-layout .entry-details {
            float: left;
            width: 50%;
        }

        .horizontal-layout {
            .entry-thumb  {
                padding-right: 20px;
            }
            .entry-details {
                padding-left: 20px;
            }
            .entry-meta {
                padding: 6px 0;
            }
        
        }

        

        

        .read-more-button {
            font-size: 14px;
            line-height: 16px;
            font-weight: bold;
            letter-spacing: 1px;
            color: #fff;
            background-color: #1a1a1a;
            padding: 5px 15px;
            border: 2px solid #1a1a1a;
            margin-top: 22px;
            &:hover {
                background-color: #fff;
                color: #1a1a1a;
                text-decoration: none;
            }
        }

        /*5.4 Page Builder*/

        /*COLUM*/

        .content-column1_1 {
            float: left;
            width: 100%;
            box-sizing: border-box;
            -moz-box-sizing: border-box;
            /* Firefox */
        }

        .content-column2_1 {
            float: left;
            width: 50%;
            box-sizing: border-box;
            -moz-box-sizing: border-box;
            /* Firefox */
            &:nth-of-type(2n+1) {
                padding-right: 19px;
            }
            &:nth-of-type(2n) {
                padding-left: 19px;
            }
        }
        
        .post-layout {
            padding-bottom: 12px;
            article {
                position: relative;
                margin-bottom: 25px;
            }
            h4.entry-title {
                position: relative;
                font-size: 18px;
                line-height: 24px;
                margin-bottom: 8px;
                margin-top: 0;
            }
            h5.entry-title {
                font-size: 14px;
                line-height: 18px;
                margin-bottom: 12px;
                margin-top: 0;
            }
            .entry-meta {
                font-size: 12px;
                line-height: 14px;
            }
        
            .entry-content {
                font-size: 14px;
                line-height: 18px;
                color: #666;
                margin-top: 12px;
            }
            .entry-thumb {
                margin-bottom: 18px;
            }
            .box-details {
                margin-left: 138px;
            }        
        }

        .no-thumb {
            .entry-thumb {
                margin-bottom: 0;
            }
            .entry-details {
                padding-left: 0;
            }
        }
        
        /*CONTAINER ELEMENT*/
        .link-post-details {
            position: absolute;
            width: 100%;
            height: 100%;
            left: 0;
            top: 0;
        }

        .post-entry-overlay {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background: #000;
            /* For browsers that do not support gradients */
            -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=30)";
            /* This works in IE 8 & 9 too */
            /* ... but also 5, 6, 7 */
            filter: alpha(opacity=30);
            /* Older than Firefox 0.9 */
            -moz-opacity: 0.3;
            /* Safari 1.x (pre WebKit!) */
            -khtml-opacity: 0.3;
            /* Modern!
                /* Firefox 0.9+, Safari 2?, Chrome any?
                /* Opera 9+, IE 9+ */
            opacity: 0.3;
        }

        .header_post_style4 .post-entry-overlay {
            display: none;
        }

        .top-post-layout .post-entry-overlay {
            background: #000;
            /* For browsers that do not support gradients */
        }

        .top-posts {
            &-page-container {
                margin-bottom: 42px;
            }      
        }

        .top-post {
            &-entry {
                position: relative;
            }
            &-thumb a {
                display: block;
                line-height: 0
            }
            &-details {
                position: absolute;
                left: 0;
                bottom: 0;
                padding: 9%;
                overflow: hidden;
            }
            &-title {
                font-size: 18px;
                line-height: 24px;
                font-weight: bold;
                position: relative;
                a {
                    color: #fff;
                    &:hover {
                        color: rgba(255, 255, 255, 0.7);
                        text-decoration: none;
                    }
                }
            }
            &-cat {
                position: relative;
                font-size: 14px;
                font-weight: bold;
                line-height: 16px;
                color: #fff;
                padding: 7px 20px;
                text-transform: uppercase;
                background-color: #000;
                /* Safari 3-4, iOS 1-3.2, Android 1.6- */
                -webkit-border-radius: 21px;
                /* Firefox 1-3.6 */
                -moz-border-radius: 21px;
                /* Opera 10.5, IE 9, Safari 5, Chrome, Firefox 4, iOS 4, Android 2.1+ */
                border-radius: 21px;
                margin-bottom: 21px;
                &.black-color {
                    background-color: #000;
                }
                &.blue-color {
                    background-color: #46c4f3;
                }
                &.green-color {
                    background-color: #8bc527;
                }
            
                &.pink-color {
                    background-color: #f570d4;
                }
            
                &.purple-color {
                    background-color: #772edb;
                }
            
                &.orange-color {
                    background-color: #fc9322;
                }
            
                &.light-red-color {
                    background-color: #f45454;
                }
            
                &.dark-blue-color {
                    background-color: #2664d5;
                }
            
                &.dark-yellow-color {
                    background-color: #f7c201;
                }
            
                &.dark-pink-color {
                    background-color: #dc127a;
                }
            
                &.dark-green-color {
                    background-color: #50b36c;
                }
                &:hover {
                    background-color: #fff;
                    color: #1a1a1a;
                }
            }
        }

        .wpsg-image-cover .top-post-title {
            font-size: 30px;
            line-height: 36px;
        }

        /*Page builder widgets*/
        .list-categories {
            border-bottom: 2px solid #1a1a1a;
            padding-bottom: 12px;
            margin-bottom: 22px;
            text-align: right;
            a {
                font-size: 14px;
                line-height: 18px;
                padding-bottom: 6px;
                margin-left: 24px;
                text-transform: capitalize;
                color: #666;
                &:hover {
                    color: #1a1a1a;
                }
                &:first-of-type {
                    float: left;
                    font-weight: 600;
                    margin-left: 0;
                    font-size: 20px;
                    letter-spacing: 1px;
                    text-transform: uppercase;
                    color: #fff;
                    text-decoration: none;
                }
            }
        }
        
        .custom-navigation {
            float: right;
            span {
                cursor: pointer;
                font-size: 14px;
                padding: 3px 9px;
                background-color: #e6e6e6;
                color: #666;
                margin-left: 10px;
                &:hover {
                    background-color: #1a1a1a;
                    color: #fff;
                }
            }
        }

        .post-layout-2 {
            .post-layout {
                letter-spacing: -5px;
            }
            article {
                letter-spacing: 0;
                display: inline-block;
                width: 50%;
                vertical-align: top;
                &:nth-of-type(2n+1) {
                    padding-right: 19px;
                }
                &:nth-of-type(2n+2) {
                    padding-left: 19px;
                }
            }
        }

        .col-in-col {
            letter-spacing: -5px;
            article {
                letter-spacing: 0;
                display: inline-block;
                vertical-align: top;
                width: 50%;
                &:nth-of-type(2n+1) {
                    padding-right: 13px;
                }
                &:nth-of-type(2n) {
                    padding-left: 13px;
                }
            }
        }

        .post-layout-3 .col-in-col .entry-title {
        margin-bottom: 0;
        }

        .post-layout-4 {
            .post-layout {
                letter-spacing: -5px;
            }
            article {
                letter-spacing: 0;
                display: inline-block;
                width: 50%;
                vertical-align: top;
                &:nth-of-type(2n+1) {
                    padding-left: 19px;
                }
                &:nth-of-type(2n+2) {
                    padding-right: 19px;
                }
                &:first-of-type {
                    width: 100%;
                    padding: 0 !important;
                    .box-thumb {
                        .post-thumb {
                            width: 244px;
                        }                      
                    }
                    .box-details {
                        margin-left: 276px;
                        padding-top: 16px;
                    }
                }
            }   
        }

        

        .post-layout-6  {
            article {
                &:first-of-type {
                    .entry-details {
                        position: absolute;
                        left: 0;
                        bottom: 0;
                        padding: 34px 32px;
                        z-index: 2;
                    }
                }
            }
            article {
                .entry-meta {
                    a {
                        &:hover  {
                            /* Theoretically for IE 8 & 9 (more valid) */
                            /* ...but not required as filter works too */
                            /* should come BEFORE filter */
                            -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
                            /* This works in IE 8 & 9 too */
                            /* ... but also 5, 6, 7 */
                            filter: alpha(opacity=100);
                            /* Older than Firefox 0.9 */
                            -moz-opacity: 1;
                            /* Safari 1.x (pre WebKit!) */
                            -khtml-opacity: 1;
                            /* Modern!
                                /* Firefox 0.9+, Safari 2?, Chrome any?
                                /* Opera 9+, IE 9+ */
                            opacity: 1;
                        }
                    }
                }
            }           
        }

        .post-latest {
            .entry-thumb {
                position: relative;
            }
            .cat-meta {
                position: absolute;
                bottom: 26px;
                left: 28px;
            }
        }

        .cat-meta {
            a {
                font-size: 12px;
                line-height: 14px;
                text-transform: uppercase;
                font-weight: bold;
                padding: 5px 10.5px;
                background-color: #e6e6e6;
                /* Safari 3-4, iOS 1-3.2, Android 1.6- */
                -webkit-border-radius: 25px;
                /* Firefox 1-3.6 */
                -moz-border-radius: 25px;
                /* Opera 10.5, IE 9, Safari 5, Chrome, Firefox 4, iOS 4, Android 2.1+ */
                border-radius: 25px;
                &:hover {
                    background-color: #fff;
                    text-decoration: none;
                }
            }         
        }

        

        .paginate {
        padding-bottom: 18px;
        margin-bottom: 40px;
        }

        .page-links a {
        display: inline-block;
        }

        .page-links>span {
        display: inline-block;
        }

        .page-numbers,
        .page-links>span,
        .page-links a {
            font-size: 14px;
            line-height: 16px;
            font-weight: bold;
            padding: 10px 15px;
            margin-right: 6px;
            margin-bottom: 6px;
        }

        .page-numbers:hover,
        .page-links a:hover {
            background-color: #e6e6e6;
        }

        .current,
        .page-links>span {
            background-color: $link-color-19;
            color: #fff;
        }

        /*Color*/

        .element-box .blue-color {
            border-bottom-color: #46c4f3;
        }

        .blue-color a:first-of-type {
            color: #46c4f3;
        }

        .element-box .black-color {
            border-bottom-color: #000;
        }

        .black-color a:first-of-type {
            color: #000;
        }

        .element-box .green-color {
            border-bottom-color: #8bc527;
        }

        .green-color a:first-of-type {
            color: #8bc527;
        }

        .element-box .pink-color {
            border-bottom-color: #f570d4;
        }

        .pink-color a:first-of-type {
            color: #f570d4;
        }
        .show-more-latest {
        color: #f570d4 !important;
        }
        .show-more-indepth {
        color: #46c4f3 !important;
        }
        .show-more-epick {
        color: #f7c201 !important;
        }
        .alignment {
            text-decoration: none !important;
            font-weight: 600;
            border: 1px solid;
            padding: 5px;
            margin-bottom: 5px;
        }
        .one-line {
        display: flex;
        }
        .newside {
            width: 50%;
            text-align: left;
        }
        .element-box .purple-color {
            border-bottom-color: #772edb;
        }

        .purple-color a:first-of-type {
        color: #772edb;
        }

        .element-box .orange-color {
        border-bottom-color: #fc9322;
        }

        .orange-color a:first-of-type {
        color: #fc9322;
        }

        .element-box .light-red-color {
        border-bottom-color: #f45454;
        }

        .light-red-color a:first-of-type {
        color: #f45454;
        }

        .element-box .dark-blue-color {
        border-bottom-color: #2664d5;
        }

        .dark-blue-color a:first-of-type {
        color: #2664d5;
        }

        .element-box .dark-yellow-color {
        border-bottom-color: #f7c201;
        }

        .dark-yellow-color a:first-of-type {
        color: #f7c201;
        }

        .element-box .dark-pink-color {
        border-bottom-color: #dc127a;
        }

        .dark-pink-color a:first-of-type {
        color: #dc127a;
        }

        .element-box .dark-green-color {
        border-bottom-color: #50b36c;
        }

        .dark-green-color a:first-of-type {
        color: #50b36c;
        }

        /* EMBED CODE */

        /* youtube */

        .embed_youtube {
            position: relative;
            /* keeps the aspect ratio */
            padding-bottom: 56.25%;
            height: 0;
            overflow: hidden;
        }

        .embed_youtube iframe,
        .embed_youtube object,
        .embed_youtube embed {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
        }

        /* vimeo */

        .embed_vimeo {
            position: relative;
            /* keeps the aspect ratio */
            padding-bottom: 56.25%;
            /* fine tunes the video positioning */
            height: 0;
            overflow: hidden;
        }

        .embed_vimeo iframe,
        .embed_vimeo object,
        .embed_vimeotainer embed {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
        }

        /*5.5 Widget*/

        /*** Default widget ***/

        .content select {
            padding: 12px 0px 10px 12px;
            max-width: 100%;
            margin-bottom: 10px;
        }

        .widget_text .content select {
        margin-bottom: 32px;
        }

        .sidebar {
            .content {
                select {
                    width: 100%;
                }
            }
        }

        .sidebar-widget {
            ul {
                list-style-type: none;
                padding: 0;
                li {
                    margin-bottom: 0;
                    a {
                        font-size: 14px;
                        line-height: 18px;
                        font-weight: normal;
                        padding-bottom: 10px;
                        color: #999;
                    }
                    .children {
                        padding-left: 20px;
                        margin-bottom: 0;
                    } 
                }
            }       
        }

        .widget_nav_menu .sub-menu {
            padding-left: 20px;
            margin-bottom: 0;
        }

        .widget_text {
            .textwidget {
                margin-bottom: -26px;
            }
            .custom-html-widget {
                margin-bottom: 0;
            }
            .widget-media-y {
                width: 100%;
                height: 227px;
            }
        
        }

        .widget_recent_entries {
            ul {
                li {
                    padding-bottom: 12px;
                    margin-bottom: 14px;
                    border-bottom: 1px solid #d9d9d9;
                    &:last-of-type {
                        border-bottom: 0;
                        margin-bottom: 0;
                    }
                    span {
                        font-size: 12px;
                        line-height: 14px;
                        color: #4d4d4d;
                    }
                }
            }
        }

        .widget_tag_cloud {
            a {
                font-weight: bold;
                color: #fff;
                line-height: 14px;
                text-transform: uppercase;
                background-color: $link-color-19;
                display: inline-block;
                padding: 5px 15px;
                letter-spacing: 1px;
                font-size: 12px !important;
                border-radius: 15px;
                margin-right: 8px;
                margin-bottom: 12px;
                &:hover {
                    background-color: #000;
                }
            }  
        }

        /* table */
        .widget_calendar {
            padding-bottom: 10px;
        }

        #wp-calendar caption {
            color: white;
            border: 3px solid #2e2e2e;
            border-bottom: 0px;
            background-color: #2e2e2e;
            font-weight: bold;
            font-weight: 700;
            text-transform: uppercase;
            padding: 10px 0px 5px 0px;
        }

        .content {
            thead td, thead th, tfoot td {
                padding: 5px;
            }
            tbody td {
                padding: 5px;
            }
            #wp-calendar {
                margin-bottom: 20px;
                width: 100%;
                td, th {
                    text-align: center;
                }
                table {
                    color: #333333;
                    border: 3px solid #ccc;
                }
                thead td, thead th, tfoot td {
                    color: #808080;
                    border: 1px solid #ccc;
                    font-weight: bold;
                    font-weight: 700;
                    text-transform: uppercase;
                }
                tbody td {
                    color: #999999;
                    border: 1px solid #ccc;
                }
            }
            table {
                border-collapse: collapse;
            }
            table, th, td {
                border: 1px solid #000;
                padding: 5px;
            }
        }

        .sidebar-widget label {
        display: block;
        }

        .sidebar-widget .screen-reader-text {
        display: none;
        }

        .comments-are-closed {
        font-style: italic;
        }

        /*** ExpMag widget ***/

        .sidebar-widget {
        position: relative;
        }

        .search-form.content { 
                width: 100%; 
                max-width: 905px; 
                @media (min-width: 1564px) {
                    max-width: 1100px;
                }
                @media (min-width: 1440px) {
                    max-width: 989px;
                }
                @media (min-width: 991px) {
                    padding: 30px 0 50px;
                }
                // @media (max-width: 768px) {
                //     width: 100%;
                // }
                margin: 0 auto;
            .search-input {
                padding: 8px 18px;
                max-width: 100%;
                border: 0; 
                border-bottom: 1px solid; 
                width: 100%;
                // @media (max-width: 768px) {
                //     width: 82%;
                // }
            }
            button {
                font-size: 36px;
                line-height: 1;
                border: 0;
                color: #000;
                padding: 0;
                background-color: #fff;
                cursor: pointer;
                width: 10% !important; 
                float: right;
                position: relative;
                top: -57px;
                right: -19px;
                @media (max-width: 768px) {
                    right: 0;
                }
            }
        }

        /*AccessPress Social Counter*/

        .content .apsc-theme-1 .apsc-each-profile,
        .content .apsc-theme-2 .apsc-each-profile {
        width: 30%;
        margin: 0 5% 5% 0;
        }

        .content .apsc-theme-1 .apsc-each-profile:nth-of-type(3n+3),
        .content .apsc-theme-2 .apsc-each-profile:nth-of-type(3n+3) {
        margin-right: 0
        }

        .content .apsc-each-profile:nth-last-of-type(1),
        .content .apsc-each-profile:nth-last-of-type(2),
        .content .apsc-each-profile:nth-last-of-type(3) {
        margin-bottom: 10px;
        }

        .content .apsc-theme-2 .apsc-inner-block {
        padding: 15px 8px 8px 8px;
        }

        .apsc-theme-1 .apsc-each-profile a {
            border-radius: 0;
            transform: none;
            -webkit-transform: none;
            -ms-transform: none;
            -ms-filter: none;
            -moz-box-shadow: none;
            -webkit-box-shadow: none;
            box-shadow: none;
            filter: initial;
        }

        .apsc-theme-1 .apsc-each-profile a:hover {
            transform: none;
            -webkit-transform: none;
            -ms-transform: none;
        }

        .apsc-theme-1 .apsc-each-profile a:before {
        display: none;
        }

        .apsc-theme-2 .apsc-each-profile a {
            border-radius: 0;
            transform: none;
            -webkit-transform: none;
            -ms-transform: none;
            -ms-filter: none;
            -moz-box-shadow: none;
            -webkit-box-shadow: none;
            box-shadow: none;
            filter: initial;
            display: block;
            position: relative;
        }

        .apsc-theme-2 .apsc-each-profile a:hover {
            transform: none;
            -webkit-transform: none;
            -ms-transform: none;
        }

        .apsc-theme-2 .apsc-inner-block {
            padding: 0;
            border-radius: 0;
            -webkit-box-shadow: none;
            -moz-box-shadow: none;
            box-shadow: none;
        }

        .apsc-theme-1 .social-icon .fa,
        .apsc-theme-2 .social-icon .fa {
            font-size: 30px;
            line-height: 36px;
            margin-bottom: 6px;
        }

        .widget_apsc_widget .social-icon {
            display: block;
            width: initial;
            margin-right: 0;
            margin-bottom: 0;
            height: initial;
            text-align: center;
            line-height: initial;
        }

        .apsc-theme-1 .apsc-count,
        .apsc-theme-2 .apsc-count {
        font-size: 18px;
        line-height: 24px;
        font-weight: bold;
        margin-bottom: 4px;
        }

        .apsc-theme-2 .apsc-media-type { 
        font-size: 12px;
        line-height: 14px;
        opacity: 0.5;
        }

        .widget-post {
        margin-bottom: 25px;
        }

        .widget-post:last-of-type {
        padding-bottom: 10px;
        }

        .box-thumb .post-thumb {
        width: 113px;
        }

        .entry-thumb>a,
        .post-thumb>a {
        display: block;
        line-height: 0
        }

        .widget-post .box-details {
        margin-left: 138px;
        }

        .post-editor-picks .cwp {
        position: absolute;
        top: 0;
        left: 0;
        font-size: 18px;
        line-height: 24px;
        color: #fff;
        font-weight: 600;
        background-color: $link-color-19;
        padding: 4px 12px;
        text-align: center;
        }

        .post-trending {
        margin-bottom: 14px;
        border-bottom: 1px solid #d9d9d9;
        padding-bottom: 12px;
        }

        .post-trending:last-of-type {
        border-bottom: 0;
        margin-bottom: 0;
        padding-bottom: 10px;
        }

        .post-trending .cwp {
        width: 40px;
        font-weight: 600;
        font-size: 30px;
        line-height: 36px;
        color: $body-color;
        }

        .post-trending .box-details {
        margin-left: 40px;
        }

        .widget-post-title {
        font-size: 16px;
        font-weight: 500;
        line-height: 18px;
        margin-bottom: 10px;
        line-height: 1.5;
        font-family: $widget-side-trending;
        }

        .widget-post-title a:hover {
        color: #808080;
        text-decoration: none;
        }

        .widget-post-meta {
        font-size: 12px;
        line-height: 14px;
        font-weight: normal;
        color: #4d4d4d;
        }

        .widget-post-meta span {
        color: #4d4d4d;
        }

        /*Wope categories*/

        .categries-list-widget ul li a {
        padding-bottom: 12px;
        float: left;
        }

        .categries-list-widget ul li span {
        float: right;
        }

        /* MailChimp for WP */

        .widget_mc4wp_form_widget {
            background-color: #e6e6e6;
            padding: 40px 28px 0; 
            .sidebar-widget-title {
                position: relative;
                background-color: transparent;
                height: auto;
                margin-top: 62px;
                color: #1a1a1a;
                font-size: 18px;
                text-transform: uppercase;
                line-height: 24px;
                text-align: center;
                display: block;
                padding: 16px 0 16px;
                margin-bottom: 0;
                border: 0;
                color: #111; 
                &:before {
                    font-family: FontAwesome;
                    content: "\f2b7";
                    font-size: 60px;
                    line-height: 72px;
                    font-weight: normal;
                    letter-spacing: 1px;
                    background-color: transparent;
                    height: auto;
                    bottom: 100%;
                    left: 0;
                    top: initial;
                    display: inline-block;
                    text-align: center;
                    position: absolute;
                    width: 100%;
                }
            }
        }

        .content {
            .mc4wp-form-fields {
                input {
                    line-height: 18px;
                    width: 62%;
                    border: 2px solid #1a1a1a;
                    padding: 9px 14px;
                    background-color: transparent;
                    vertical-align: top;
                }
                input[type=email] {
                    margin-right: -6px;
                }
                input[type=submit] {
                    font-size: 14px;
                    line-height: 16px;
                    margin-right: 0;
                    font-weight: normal;
                    letter-spacing: 1px;
                    width: 38%;
                    color: #fff;
                    padding: 10px 6px;
                    border: 2px solid #1a1a1a;
                }
                p {
                    text-align: center;
                }
            }
        }

        .widget_mc4wp_form_widget,
        #wope_posts_widget-5  { 
            max-width: 300px; 
            margin: 0 auto 32px;
        }

        /* 5.6 Shortcodes */

        /* Tabs */

        .tab {
            margin-bottom: 20px;
            &-title {
                i {
                    margin-right: 5px;
                }
            }
            &-top {
                letter-spacing: -3px;
            }
            &-title {
                letter-spacing: 0px;
                display: inline-block;
                cursor: pointer;
                transition: all 200ms ease-out;
                /*safari and chrome */
                -webkit-transition: all 200ms ease-out;
                /*safari and chrome */
                padding: 10px 30px;
                margin-right: 10px;
                border-radius: 50px;
            }
            &-id {
                display: none;
            }
            &-bottom {
                padding: 30px 30px;
                margin-top: 15px;
            }
            &-content {
                display: none;
                p {
                    &:last-child {
                        padding-bottom: 0px;
                    }       
                }
            }
        
        }


        .tab-content-current {
        display: block;
        }

        .tab.tab-post .tab-bottom {
        margin-top: -1px;
        padding: 0px;
        }

        /* Accordion */

        .accordion {
        line-height: 0px;
        margin-bottom: 20px;
        }

        .accor-title {
        padding: 10px 45px 10px 30px;
        cursor: pointer;
        position: relative;
        transition: all 200ms ease-out;
        /*safari and chrome */
        -webkit-transition: all 200ms ease-out;
        /*safari and chrome */
        margin-bottom: 10px;
        border-radius: 50px;
        }

        .accor-title i {
        margin-right: 5px;
        font-size: 16px;
        }

        .accor-title-icon {
        position: absolute;
        width: 50px;
        height: 38px;
        right: 0px;
        top: 0px;
        padding-top: 12px;
        text-align: center;
        }

        .accor-title-icon i {
        font-size: 24px;
        line-height: 24px;
        margin-right: 0px;
        }

        .ui-state-active i {
        vertical-align: 4px;
        }

        .ui-state-focus {
        outline: 0px;
        }

        .accor-content {
        padding: 10px 30px 30px 30px;
        margin-top: 20px;
        }

        .accor-content p:last-child {
        padding-bottom: 0px;
        }

        /* Gallery */

        .gallery-image-lightbox {
        position: relative;
        line-height: 0px;
        font-size: 0px;
        }

        .gallery-image-lightbox a {
        line-height: 0px;
        font-size: 0px;
        }

        .gallery-image-bg {
        position: absolute;
        left: 0px;
        top: 0px;
        width: 100%;
        height: 100%;
        content: '';
        opacity: 0;
        filter: alpha(opacity=0);
        /* For IE8 and earlier */
        transition: all 300ms ease-out;
        /*safari and chrome */
        -webkit-transition: all 300ms ease-out;
        /*safari and chrome */
        }

        .gallery-image-icon {
        display: inline-block;
        position: absolute;
        left: 50%;
        top: 50%;
        margin-left: -30px;
        margin-top: -30px;
        width: 60px;
        height: 60px;
        content: '';
        text-align: center;
        border-radius: 5px;
        opacity: 0;
        filter: alpha(opacity=0);
        /* For IE8 and earlier */
        transition: all 300ms ease-out;
        /*safari and chrome */
        -webkit-transition: all 300ms ease-out;
        /*safari and chrome */
        transform: scale(0);
        -ms-transform: scale(0);
        /* IE 9 */
        -webkit-transform: scale(0);
        /* Safari and Chrome */
        }

        .gallery-image-icon i {
        font-size: 30px;
        line-height: 60px;
        }

        .gallery-image-lightbox:hover .gallery-image-bg {
        opacity: 0.8;
        filter: alpha(opacity=80);
        /* For IE8 and earlier */
        }

        .gallery-image-lightbox:hover .gallery-image-icon {
        opacity: 1;
        filter: alpha(opacity=100);
        /* For IE8 and earlier */
        transform: scale(1);
        -ms-transform: scale(1);
        /* IE 9 */
        -webkit-transform: scale(1);
        /* Safari and Chrome */
        }

        .gallery-image-lightbox .gallery-image-icon:hover {
        opacity: 1;
        filter: alpha(opacity=100);
        /* For IE8 and earlier */
        }

        .image-left img {
        float: left;
        }

        .image-right img {
        float: right;
        }

        .image-center {
        text-align: center;
        }

        div.pp_default .pp_description {
        color: #808080;
        padding: 0px 0px 0px 12px;
        }

        .gallery-grid {
        line-height: 0px;
        font-size: 0px;
        }

        .gallery-thumb-main {
        line-height: 0px;
        font-size: 0px;
        overflow: hidden;
        }

        .gallery-thumb-bottom .gallery-thumb-column {
        position: relative;
        line-height: 0px;
        font-size: 0px;
        cursor: pointer;
        }

        .gallery-thumb-bottom .gallery-thumb-border {
        position: absolute;
        width: 100%;
        height: 100%;
        content: '';
        box-sizing: border-box;
        -moz-box-sizing: border-box;
        /* Firefox */
        transition: all 200ms ease-out;
        /*safari and chrome */
        -webkit-transition: all 200ms ease-out;
        /*safari and chrome */
        -webkit-box-sizing: border-box;
        }

        .gallery-thumb-main .gallery-thumb-each {
        height: 0px;
        overflow: hidden;
        }

        .gallery-thumb-main .gallery-current {
        height: auto;
        }

        /* Info Boxes */

        .info-box {
        position: relative;
        padding: 30px 60px 30px 40px;
        margin-bottom: 20px;
        transition: all 200ms ease-out;
        /*safari and chrome */
        -webkit-transition: all 200ms ease-out;
        /*safari and chrome */
        }

        .info-box p:last-child {
        padding-bottom: 0px;
        }

        .info-box-remove {
        position: absolute;
        right: 0px;
        top: 0px;
        margin-right: 8px;
        margin-top: 8px;
        text-align: center;
        opacity: 0.9;
        text-align: center;
        opacity: 0.5;
        filter: alpha(opacity=50);
        /* For IE8 and earlier */
        transition: all 200ms ease-out;
        /*safari and chrome */
        -webkit-transition: all 200ms ease-out;
        /*safari and chrome */
        cursor: pointer;
        box-sizing: border-box;
        -moz-box-sizing: border-box;
        /* Firefox */
        }

        .info-box-remove i {
        font-size: 18px;
        line-height: 18px;
        vertical-align: 4px;
        }

        .info-box:hover .info-box-remove {
        opacity: 1;
        filter: alpha(opacity=100);
        /* For IE8 and earlier */
        }

        /* Dropcap */

        .dropcap {
        float: left;
        text-transform: uppercase;
        width: 40px;
        height: 40px;
        padding: 0px;
        text-align: center;
        margin: 8px 10px 0px 0px;
        border-radius: 50%;
        }

        /* Custom list */

        .content {
            ul {
                &.custom-list {
                    list-style-type: none;
                    margin: 0px;
                    padding: 0px 0px 20px 0px;
                    i {
                        margin-right: 5px; 
                        padding: 5px 0px;
                        font-size: 14px;
                        line-height: 14px;
                        width: 24px;
                        border-radius: 16px;
                        margin-right: 5px;
                        text-align: center;
                        vertical-align: 1px;
                    }
                }
            }  
        }

        /* Social Icon */
        .social-icon {
            display: inline-block;
            width: 50px;
            height: 50px;
            line-height: 50px;
            transition: all 200ms ease-out;
            /*safari and chrome */
            -webkit-transition: all 200ms ease-out;
            /*safari and chrome */
            border-radius: 50%;
            text-align: center;
            margin-right: 10px;
            margin-bottom: 13px;
            i {
                transition: all 200ms ease-out;
                /*safari and chrome */
                -webkit-transition: all 200ms ease-out;
                /*safari and chrome */
                font-size: 20px;
                line-height: 50px;
            }
        }

        // .content .footer-widget .social-icon {
        // width: 40px;
        // height: 40px;
        // line-height: 40px;
        // }

        // .content .footer-widget .social-icon i {
        // line-height: 40px;
        // }

        /* Embed Code */

        /* youtube */

        .youtube-container {
        position: relative;
        /* keeps the aspect ratio */
        padding-bottom: 56.25%;
        /* fine tunes the video positioning */
        overflow: hidden;
        }

        .youtube-container iframe,
        .youtube-container object,
        .youtube-container embed {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        }

        /* vimeo */

        .post-fullwidth-top .vimeo-container {
        padding-bottom: 55%;
        /* fine tunes the video positioning */
        }

        .vimeo-container {
        position: relative;
        /* keeps the aspect ratio */
        padding-bottom: 53%;
        /* fine tunes the video positioning */
        overflow: hidden;
        }

        .vimeo-container iframe,
        .vimeo-container object,
        .vimeo-container embed {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        }

        /* Button */

        .content .small-button,
        .content a.small-button {
        padding: 0px 20px;
        margin-bottom: 10px;
        margin-right: 10px;
        display: inline-block;
        cursor: pointer;
        transition: all 300ms ease-out;
        /*safari and chrome */
        -webkit-transition: all 300ms ease-out;
        /*safari and chrome */
        }

        .small-button i {
        padding-right: 5px;
        text-decoration: none;
        }

        .content input[type=submit],
        .content button,
        .content .normal-button,
        .content a.normal-button,
        .woocommerce #respond input#submit.alt,
        .woocommerce a.button.alt,
        .woocommerce button.button.alt,
        .woocommerce input.button.alt {
        padding: 6px 36px 4px;
        margin-bottom: 10px;
        margin-right: 10px;
        display: inline-block;
        cursor: pointer;
        transition: all 300ms ease-out;
        /*safari and chrome */
        -webkit-transition: all 300ms ease-out;
        /*safari and chrome */
        }

        .normal-button i {
        padding-right: 8px;
        text-decoration: none;
        }

        a.big-button {
        padding: 7px 40px;
        margin-bottom: 10px;
        margin-right: 10px;
        display: inline-block;
        cursor: pointer;
        transition: all 200ms ease-out;
        /*safari and chrome */
        -webkit-transition: all 200ms ease-out;
        /*safari and chrome */
        }

        a.big-button i {
        padding-right: 10px;
        text-decoration: none;
        }

        .sidebar-button {
        padding: 2px 8px;
        border-radius: 5px;
        display: inline-block;
        cursor: pointer;
        transition: all 200ms ease-out;
        /*safari and chrome */
        -webkit-transition: all 200ms ease-out;
        /*safari and chrome */
        }

        .no-border {
        border-bottom: 0px;
        }

        /* author bio */

        .author-bio-single {
            padding-top: 60px;
            border-bottom: 1px solid #E8E8E8;
        }

        .author-bio {
            margin-bottom: 50px;
            a {
                transition: all 200ms ease-out;
                /*safari and chrome */
                -webkit-transition: all 200ms ease-out;
                /*safari and chrome */

            }
            &-avatar {
                float: left;
                width: 100px;
                img {
                    height: 100%;
                }
            }
            &-details {
                margin-left: 130px;
            }
            &-title a {
                font-size: 14px;
                line-height: 18px;
                letter-spacing: 1px;
                color: #fff;
                font-weight: bold;
                padding: 3px 12px;
                position: relative;
                background-color: #1a1a1a;
                margin-bottom: 22px;
                text-transform: uppercase;
                /* Safari 3-4, iOS 1-3.2, Android 1.6- */
                -webkit-border-radius: 3px;
                /* Firefox 1-3.6 */
                -moz-border-radius: 3px;
                /* Opera 10.5, IE 9, Safari 5, Chrome, Firefox 4, iOS 4, Android 2.1+ */
                border-radius: 3px;
            }
        }

        .author-bio-description {
            font-weight: normal;
            font-size: 16px;
            line-height: 28px;
            color: #666;
            margin-bottom: 15px;
        }

        a.author-bio-url {
            padding: 4px 10px 6px 10px;
            border-radius: 3px;
        }

        /* post relative */

        .related-article {
            font-family: $blog-font;
            // padding-bottom: 12px;
            // img {
            //     margin-bottom: 10px;
            // }
            margin-top: 0;
            margin-bottom: 14px;
            border-bottom: 1px solid #d9d9d9;
            padding-bottom: 12px;
            h6 {  
                margin: 5px 0;
                font-size: 16px;
                font-weight: bold; 
                line-height: 18px;
                color: #1a1a1a;
                &:hover {
                    color: #666;
                }
            }  
        }

        .last-element {
            margin-right: 0 !important;
        }

        .related-post {
            margin-bottom: 32px;
        }

        h3#reply-title,
        .post-section-title h5,
        .sidebar-widget-title {
            display: block;
            color: #1a1a1a;
            font-weight: 600; 
            text-transform: uppercase;  
            font-size: 13px;
            border-bottom: 1px solid #dceaec;
            padding-bottom: 8px;
            margin: 0 0 12px; 
            letter-spacing: 2px;
            line-height: 20px; 
        }

        h3#reply-title {
            margin: 22px 0;
        }

        // .related-article img {
        // margin-bottom: 10px;
        // }

        // .related-article h6 {
        // font-family: 'Roboto', Arial !important;
        // font-size: 14px;
        // line-height: 18px;
        // color: #666;
        // }

        // .related-article h6:hover {
        // color: #1a1a1a;
        // }
        
        //COVID-19 Starts
        .covid-19-widget{
            pointer-events: all!important;
            @media only screen and (max-width : 768px) {
                padding: 5px 10px 15px;
            }
            .post-section-title{
                h5{
                    margin-bottom: 0;
                }
                // font-weight: normal;
                // font-size: 10px;
            }
            .covid-19-count-box{
                padding: 20px;
                @media only screen and (max-width : 768px) {
                    padding: 20px 0 ;
                }
                .total{
                    font-size: 16px;
                    font-weight: 600;
                    margin-bottom: 0px;
                    margin-bottom: 0px;
                    background: none;
                    padding: 0px;
                    @media only screen and (max-width : 768px) {
                        font-size: 10px;
                    }
                }
                .total-count{
                    font-size: 42px;
                    margin-bottom: 10px;
                    margin-bottom: 0px;
                    background: none;
                    padding: 0px;
                    @media only screen and (max-width : 768px) {
                        font-size: 36px;
                    }
                }
                .change{
                    font-size: 14px;
                    margin-bottom: 0px;
                    margin-bottom: 0px!important;
                    background: none;
                    padding: 0px;
                    @media only screen and (max-width : 768px) {
                        font-size: 10px;
                    }
                }
                .change-count{
                    font-size: 32px;
                    margin-bottom: 5px;
                    margin-bottom: 0px;
                    background: none;
                    padding: 0px;
                    @media only screen and (max-width : 768px) {
                        font-size: 24px;
                    }
                }
                .ft-red{
                    color:#b80100;
                }
                .ft-blue{
                    color:#1380a1;
                }
            
            }
            .find-out{
                margin-top: 10px;
                font-weight: 600;
                font-style: italic;
                padding-bottom: 0;
            }
            .covid-19-title-header{
                background: #1380a1;
                padding: 0 20px;
                h5{
                    color:#fff;
                }
            }
            .covid-19-body{
                background: #f2f2f2;
                padding: 0 20px 20px 20px;
                .enter-postal{
                    color:#6e6e73;
                    font-size: 16px;
                    margin: 0 0 10px 0;
                }
                .covid-result{
                    .success{
                        color: #106078;
                        font-size: 18px;
                        margin: 10px 0 0 0;
                        .RED{
                            color: #900;
                            font-weight: 600;
                        }
                        .YELLOW{
                            color: #FFAA1d;
                            font-weight: 600;
                        }
                        .GREEN{
                            color: #008000;
                            font-weight: 600;
                        }
                    }
                    .fail{
                        color: #900;
                        font-size: 18px;
                        margin: 10px 0 0 0;
                    }
                    .source{
                        color: #252525;
                        font-size: 14px;
                        font-weight: 500;
                        margin: 2px 0 0 0;
                    }
                }
                .covid-note{
                    color: #252525;
                    font-size: 16px;
                    margin: 10px 0 0 0;
                    .mb10{
                        margin-bottom: 10px;
                    }
                    .covid-explorer-link {
                        // color: #fffce0;
                        color: #FF5A5F;
                        font-weight: 600;
                    }
                }
                .covid-source{
                    p
                    {
                        border-top: 1px solid #aeaeb5;
                        font-size: 14px;
                        padding-top: 10px;
                        color: #252525;
                        font-size: 14px;
                        font-weight: 500;
                        margin: 0 0 0 0;
                    }
                }
            }
        }
        //COVID-19 Ends
 
        /* post search */

        .post-search-entry {
            padding-bottom: 40px;
        }

        .entry-section .post-search-entry:last-child {
            padding-bottom: 60px;
        }

        /* comment section */

        #comment {
            &-section {
                margin-bottom: 46px;
            }
        }

        #comment-container a {
        transition: all 200ms ease-out;
        /*safari and chrome */
        -webkit-transition: all 200ms ease-out;
        /*safari and chrome */
        }

        #comment-container ul {
            padding: 0px;
            list-style-type: none;
        }

        

        h3#reply-title {
            position: static;
        }

        #cancel-comment-reply-link {
            position: absolute;
            top: 22px;
            right: 0;
            padding: 3px 8px;
            background-color: #1a1a1a;
            color: #fff;
            border-radius: 3px;
        }

        .comment {
            &-respond {
                position: relative;
            }
            &-entry {
                position: relative;
            }
            &-info {
                padding-left: 102px;
            }
            &-top {
                margin-bottom: 10px;
            }
            &-author {
                font-weight: bold;
                display: inline-block;
                color: #1a1a1a;
                font-size: 18px;
                line-height: 28px;
                margin-right: 20px;
            }
            &-date {
                font-weight: normal;
                font-size: 12px;
                color: #666;
                line-height: 14px;
                display: inline-block;
                a {
                    color: #999;
                }
            }
            &-content  {
                &.content p {
                    line-height: 24px;
                    &:last-child {
                        padding-bottom: 0px;
                    }
                }
            }
            &-reply {
                float: right;
                a {
                    font-weight: bold;
                    font-size: 12px;
                    line-height: 15px;
                    letter-spacing: 1px;
                    padding: 3px 8px;
                    background-color: #1a1a1a;
                    color: #fff;
                    /* Safari 3-4, iOS 1-3.2, Android 1.6- */
                    -webkit-border-radius: 3px;
                    /* Firefox 1-3.6 */
                    -moz-border-radius: 3px;
                    /* Opera 10.5, IE 9, Safari 5, Chrome, Firefox 4, iOS 4, Android 2.1+ */
                    border-radius: 3px;
                    display: inline-block;
                }
            } 
            &-navigation {
                margin-bottom: 18px;
            }
        }

        .bypostauthor .comment-author a {
            padding-bottom: 1px;
            display: inline-block;
        }

        .comment-avatar {
            position: absolute;
            width: 72px;
            height: 72px;
            img {
                max-width: 100%;
                max-height: 100%;
            }
        }

        .depth {
            &-2 {
                margin-left: 72px;
            }
            &-3 {
                margin-left: 144px;
            }
            &-4 {
                margin-left: 216px;
            }
            &-5 {
                margin-left: 288px;
            }
        }

        /* comment form */

        .content textarea,
        .contact-form textarea,
        .content input {
        color: #808080;
        border: 1px solid #999;
        padding: 10px 18px;
        margin-bottom: 10px;
        font-size: 16px;
        line-height: 28px;
        }

        .content input[type=submit] {
            cursor: pointer;
            border: 0;
            font-size: 14px;
            padding: 11px 18px;
            line-height: 28px;
            background-color: #1a1a1a;
            transition: all 200ms ease-out;
            /*safari and chrome */
            -webkit-transition: all 200ms ease-out;
            /*safari and chrome */
            color: #fff;
        }

        .comment-form {
            &-comment {
                padding-bottom: 12px;
            }
            &-left {
                padding-bottom: 8px;
            }
        }

        #comment-form label {
            font-size: 14px;
            line-height: 16px;
            padding-bottom: 15px;
            color: #1a1a1a;
            display: inline-block;
        }

        

        .comment-form-left .comment-form-author,
        .comment-form-left .comment-form-email,
        .comment-form-left .comment-form-url {
            float: left;
            width: 30.6666%;
            margin-right: 4%;
            box-sizing: border-box;
        }

        .comment-form-left .comment-form-url {
            margin-right: 0%;
        }

        .content .comment-form-author input[type=text],
        .content .comment-form-email input[type=text],
        .content .comment-form-url input[type=text],
        .content .form-submit input[type=submit] {
            box-sizing: border-box;
            -moz-box-sizing: border-box;
            /* Firefox */
            width: 100%;
        }

        .comment-form-author,
        .comment-form-email,
        .form-submit {
            float: left;
            width: 29%;
            margin-right: 6.5%;
        }

        .form-submit {
            margin-right: 0;
            margin-top: 32px;
        }

        .content .comment-form-comment textarea {
            box-sizing: border-box;
            -moz-box-sizing: border-box;
            /* Firefox */
            width: 100%;
            outline: none;
            margin-bottom: 0px;
        }

        .form-allowed-tags {
        display: none;
        }

        .entry-tags {
        margin-bottom: 50px;
        }

        .entry-tags a,
        .entry-tags .text-tags { 
            font-weight: bold;
            font-size: 10px;
            line-height: 12px;
            letter-spacing: 1px;
            color: #fff;
            background-color: #999;
            display: inline-block;
            padding: 2px 5px;
        }

        .entry-tags .text-tags,
        .entry-tags a:hover {
            background-color: #1a1a1a;
        }

        /* 6. Footer */

        // .footer-sidebar {
        // background-color: #1a1a1a;
        // padding-top: 90px;
        // padding-bottom: 54px;
        // }

        // .no-footer-sidebar {
        // padding: 0;
        // }

        // .footer-sidebar .categries-list-widget ul {
        // list-style-type: none;
        // padding-left: 0;
        // margin-bottom: 0;
        // }

        // .footer-sidebar .content p {
        // font-size: 14px;
        // line-height: 24px;
        // color: #999;
        // }

        // .footer-widget ul li {
        // margin-bottom: 0;
        // }

        // .footer-sidebar a:hover,
        // .footer-bottom a:hover {
        // color: #fff;
        // }

        // .footer-sidebar-fixed,
        // .use-sidebar-fixed {
        // float: left;
        // }

        // .footer-sidebar-fixed {
        // width: 40%;
        // }

        .use-sidebar-fixed {
            width: 60%;
        }

        // .footer-sidebar-fixed,
        // .footer-sidebar-col .col-3-1,
        // .footer-sidebar-col .col-4-1 {
        // padding-left: 20px;
        // padding-right: 20px;
        // min-height: 1px;
        // }

        // .footer-widget,
        // .footer-widget a {
        // font-family: 'Roboto', Arial;
        // font-size: 14px;
        // font-weight: normal;
        // color: #999;
        // }

        // .footer-sidebar .widget_tag_cloud a {
        // color: #fff;
        // font-weight: bold;
        // }

        // .footer-sidebar .widget_tag_cloud a:hover {
        // background-color: #fff;
        // color: #000;
        // }

        // .footer-widget .widget-post {
        // margin-bottom: 20px;
        // }

        // .footer-widget .box-thumb .post-thumb {
        // width: 86px;
        // }

        // .footer-widget .box-details {
        // margin-left: 110px;
        // }

        // .footer-widget .post-trending .box-details {
        // margin-left: 40px;
        // }

        // .footer-widget .widget-post-title a {
        // font-size: 14px;
        // line-height: 16px;
        // font-weight: bold;
        // color: #fff;
        // }

        // .footer-widget .widget-post-title a:hover {
        // color: #999;
        // }

        // /*Wope categories*/

        // .footer-sidebar.categries-list-widget ul li,
        // .footer-sidebar .categries-list-widget ul li a {
        // color: #fff;
        // }

        // .categries-list-widget ul li a:hover {
        // color: #999;
        // }

        // .footer-widget {
        // margin-bottom: 32px;
        // }

        // .footer-widget.widget_media_image {
        // margin-bottom: 20px;
        // }

        // .footer-widget .widget-post-meta span {
        // color: #b2b2b2;
        // }

        // .footer-sidebar .widget_recent_entries ul {
        // list-style-type: none;
        // padding-left: 0;
        // margin-bottom: 0
        // }

        // .footer-sidebar .content>ul {
        // list-style-type: none;
        // padding-left: 0;
        // }

        .menu {
            li {
                a {
                    font-size: 14px;
                    line-height: 18px;
                    font-weight: normal;
                    padding-bottom: 14px;
                    color: #999;
                }
                &:last-of-type {
                    a {
                        padding-bottom: 0;
                    }
                }
            }
        }

        .widget_nav_menu ul li:last-of-type a {
            padding-bottom: 10px;
        }

        // .content .footer-widget-title {
        // font-family: 'Roboto', Arial;
        // font-size: 18px;
        // line-height: 24px;
        // font-weight: bold;
        // letter-spacing: 1px;
        // color: #fff;
        // text-transform: uppercase;
        // }

        // .footer-widget-title {
        // margin-bottom: 28px;
        // }

        /* .footer-social a{
            display: inline-block;
            padding-right: 20px;
            font-size: 14px;
            line-height: 18px;
            color: #999;
        }
        .footer-social a:hover{
            color: #fff;
        } */

        /* .footer-bottom{
            background-color: #000;
        } */

        // .footer-bottom h2 {
        // float: left;
        // width: 45%;
        // font-family: 'Roboto', Arial;
        // font-size: 12px;
        // line-height: 24px;
        // font-weight: normal;
        // color: #999;
        // text-transform: uppercase;
        // padding: 14px 0 14px 16px;
        // }

        // .menu-footer-bottom {
        // float: right;
        // width: 55%;
        // }

        // .menu-footer-bottom .sub-menu {
        // display: none;
        // }

        /* .footer-bottom ul{
            float: right;
        }
        .footer-bottom ul li{
            display: inline-block;
        }
        .footer-bottom ul li a{
            font-family: 'Roboto', Arial;
            font-size: 12px;
            line-height: 24px;
            font-weight: normal;
            color: #999;
            text-transform: uppercase;
            padding: 14px 0 14px 16px;
        } */

        /* back to button */

        #back_top {
            display: none;
            background-color: $link-color-19;
            color: white;
            border: 1px solid $link-color-19;
        }

        /* Customize */
        body.page-content div#scroll-header .wrap { 
            width: 100%;
            margin: 0;
            padding: 0 1.5rem;
        }
        blockquote.news-quote {
            position: relative;
            margin: 30px 50px;
            background-color: #f2f2f2;
            padding: 32px 50px;
        }

        body.page-content .main-content {
            padding-left: 100px;
        }

        body.page-content header.header-layout-style-1 .wrap {
            width: 100%;
        }
        body.page-content header.header-layout-style-1{
            margin: 0 1.5rem;
        }

        body.page-content header .header-menu {
            border-bottom: 1px solid transparent;
        }

        .landing-pullout a.dark-blue-color.top-post-cat {
            margin-bottom: 0;
            text-transform: none;
            font-size: 12px;
            letter-spacing: 0.08em;
            display: block;
            width: fit-content;
        }

        .landing-pullout {
            a {
                &:hover,
                &:focus {
                    text-decoration: none;
                }
            }
        }

        .flexslider.video {
            margin: 0;
            border: 0;
        }

        #content-section6 .content-column2_1 article.post-entry .box-thumb,
        #content-section2 article.post-entry .box-thumb {
            height: 80px;
            overflow: hidden;
        }

        #content-section6 .content-column2_1 article.post-entry img,
        #content-section2 article.post-entry .box-thumb img {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            object-fit: cover;
            height: 100%;
            width: 100%;
        }
        .video-iframe {
            &.img-responsive {
                width: 100%;
                height: 100vh;
                background-color: rgba(0, 0, 0, 0.75);
                position: fixed;
                display: flex;
                justify-content: center;
                z-index: 2002;
            }
            .close-btn {
                position: absolute;
                top: auto;
                @media (min-width: $break_d_sm) {
                    top: 60px;
                }
                right: 0;
                font-size: 28px;
                padding: 0.8rem 1.6rem;
                color: white;
                cursor: pointer;
            }
            iframe {
                align-self: center;
            }
        }

        .search-img.entry-thumb img {
            width: 100%;
        }

        .outter {
            width: 50%;
            display: inline;
        }

        .event-list {
            display: flex;
            flex-flow: row nowrap;
            justify-content: flex-start;
        }

        /*event listing  */

        .event-date {
            padding: 1.5rem 0;
            flex: 1 0 20%;
            min-width: 110px;
            text-align: center;
            .calendar {
                span {
                    &.month {
                        font-size: 16px;
                        padding: 1rem .5rem 0 .5rem;
                        text-transform: uppercase;
                    }
                    &.date {
                        font-size: 48px;
                        padding: 0 .5rem .5rem;
                        vertical-align: baseline;
                    }
                    &.day {
                        font-size: 12px;
                        padding: 1rem .5rem;
                        text-transform: uppercase;
                        background-color: #252525;
                    }
                }               
            }
        }

        .event-date>.calendar {
            display: flex;
            flex-flow: column nowrap;
            justify-content: center;
            width: 100%;
            color: white;
            background: linear-gradient(150deg, #5eb9f5 0%, #d202cb 100%);
        }

        .event-list {
            .meta {
                padding: 1rem 1.5rem 1.5rem 4rem;
                a {
                    display: block;
                    font-weight: 550;
                    font-size: 2.8rem;
                }
                span, p {
                    color: #666;
                }
            }
        }

        .related-post div:nth-last-child(2) {
            margin-right: 0;
        }

        p.mailchimp-input {
            padding: 0;
            margin: 0;
        }

        .mce-error-response {
            color: red;
        }

        .mce-success-response {
            color: blue;
        }

        .block-mrec {
            display: flex;
            flex-flow: row nowrap;
            justify-content: center;
        }

        .block-mrec>div {
            min-width: 30rem;
        }

        .header-main-menu ul {
        margin-bottom: 0;
        }

        .entry-content p {
        margin: 0;
        }

        article.featured.post-entry:before {
            content: 'Featured';
            display: block;
            padding: .5rem 1rem;
            background-color: #c94d51;
            color: #fff;
            font-size: 1.2rem;
            letter-spacing: .08rem;
            position: absolute;
            top: 1rem;
            left: -.1rem;
            z-index: 1;
        }
        .entry-meta .last-updated {
            font-size: 11px;
            text-align: right;
            font-weight: 300;
            letter-spacing: 0.02em;
            display: none;
        }

        /* Responsive */

        @media screen and (min-width: 1025px) {
        body {
            padding-top: 0;
        }
        }

        @media screen and (max-width: 1024px) {
        body {
            padding-top: 0;
        }
        }

        @media screen and (max-width: 1280px) {
        .wrap {
            // width: 1100px;
        }
        .wrap-colum {
            width: 1140px;
        }
        }

        @media screen and (max-width: 1180px) {
        .wrap {
            width: 960px;
        }
        .wrap-colum {
            width: 1000px;
        }
        .sidebar {
            padding-left: 0;
        }
        /*CONTAINER ELEMENT*/
        .content-column2_1:nth-of-type(2n+1),
        .post-layout-2 article:nth-of-type(2n+1),
        .post-layout-4 article:nth-of-type(2n+2),
        .post-layout-5 article:first-of-type .box-thumb,
        .iblock-2 article:nth-of-type(2n+1) {
            padding-right: 15px;
        }
        .content-column2_1:nth-of-type(2n),
        .post-layout-2 article:nth-of-type(2n+2),
        .post-layout-4 article:nth-of-type(2n+1),
        .post-layout-5 article:first-of-type .box-details,
        .iblock-2 article:nth-of-type(2n+2) {
            padding-left: 15px;
        }
        .col-in-col article:nth-of-type(2n+1) {
            padding-right: 11px;
        }
        .col-in-col article:nth-of-type(2n) {
            padding-left: 11px;
        }
        /* blog comment */
        .depth-2 {
            margin-left: 48px;
        }
        .depth-3 {
            margin-left: 96px;
        }
        .depth-4 {
            margin-left: 144px;
        }
        .depth-5 {
            margin-left: 192px;
        }
        }

        @media screen and (max-width: 1025px) {
        .wpsg-image-cover .top-post-title {
            font-size: 16px;
            line-height: 18px;
        }
        .top-post-title {
            font-size: 14px;
            line-height: 16px;
        }
        }

        @media screen and (max-width: 1000px) {
        #scroll-header .header-scroll-logo {
            width: 15%;
        }
        #scroll-header .scroll-header-menu {
            width: 85%;
        }

        #scroll-header .header-search-mazada i{
            padding: 19px 0 19px 12px;
        }
        #scroll-header .header-menu-wrap>div>ul>li>a{
            padding: 21px 10px 21px 0;
            font-size: 13px;
        }
        
        
        .wrap {
            width: 700px;
        }
        .wrap-colum {
            width: 740px;
        }
        .top-post-cat {
            position: relative;
            font-family: 'Roboto', Arial !important;
            font-size: 12px;
            font-weight: bold;
            line-height: 16px;
            color: #fff;
            padding: 5px 20px;
            text-transform: uppercase;
            background-color: #000;
            -webkit-border-radius: 21px;
            -moz-border-radius: 21px;
            border-radius: 21px;
            margin-bottom: 14px;
        }
        .header-menu ul>li>a {
            padding: 14px 18px 14px 0;
        }
        }

        @media screen and (max-width: 800px) {
        .wrap {
            width: 640px;
        }
        .wrap-colum {
            width: 680px;
        }
        .col-3-1,
        .col-4-1 {
            float: none;
            width: 100%;
        }
        #top-bar,
        #scroll-header,
        header .header-menu,
        header .header-banner {
            display: none;
        }
        /* show toggle menu */
        header {
            .header-logo {
                width: 70%;
                padding: 10px 0 10px 0;
                vertical-align: middle;
            }
            .header-mobile-right {
                width: 30%;
                vertical-align: middle;
                i {
                    text-align: right;
                    padding: 0;
                }
            }
        }  
        
        .toggle-menu-button,
        .colum-iblock .mobile-header-search {
            display: block;
        }
        header .wrap {
            border-bottom: 1px solid #1a1a1a;
            margin-bottom: 25px;
        }
        #header .main-menu {
            display: none;
        }
        .container-background {
            margin-top: 0;
        }
        .top-post-title {
            font-size: 12px;
            line-height: 14px;
        }
        .wpsg-image-cover .top-post-title {
            font-size: 18px;
            line-height: 24px;
        }
        .top-post-cat {
            display: none;
        }
        // .footer-bottom h2 {
        //     width: 100%;
        //     float: none;
        //     padding-left: 0;
        //     text-align: center;
        // }
        // .menu-footer-bottom {
        //     width: 100%;
        //     float: none;
        //     text-align: center;
        // }
        // .footer-bottom {
        //     padding-bottom: 12px;
        // }
        }

        @media screen and (max-width: 720px) {
            .wrap {
                width: 420px;
            }
            .wrap-colum {
                width: 460px;
            }
            /*CONTAINER ELEMENT*/
            .post-layout-2 article:nth-of-type(2n+1),
            .post-layout-2 article:nth-of-type(2n+2),
            .post-layout-4 article:nth-of-type(2n+2),
            .post-layout-4 article:nth-of-type(2n+1),
            .post-layout-4 article:first-of-type .box-thumb,
            .post-layout-5 article,
            .iblock-2 article,
            .content-column2_1 {
                width: 100%;
                float: none;
            }
            .content-column2_1:nth-of-type(2n+1),
            .post-layout-2 article:nth-of-type(2n+1),
            .post-layout-4 article:nth-of-type(2n+2),
            .iblock-2 article:nth-of-type(2n+1) {
                padding-right: 0;
            }
            .content-column2_1:nth-of-type(2n),
            .post-layout-2 article:nth-of-type(2n+2),
            .post-layout-4 article:nth-of-type(2n+1),
            .post-layout-5 article,
            .iblock-2 article:nth-of-type(2n+2) {
                padding-left: 0;
            }
            .post-layout-4 article:first-of-type .box-details {
                margin-left: 0
            }
            /*COMMENT FORM*/
            .comment-form-author,
            .comment-form-email,
            .form-submit,
            .related-article {
                float: none;
                width: 100%;
                margin-right: 0;
            }
            #cancel-comment-reply-link {
                position: initial;
                margin-top: 8px;
            }
            /* blog */
            .depth-2 {
                margin-left: 10px;
            }
            .depth-3 {
                margin-left: 20px;
            }
            .depth-4 {
                margin-left: 30px;
            }
            .depth-5 {
                margin-left: 40px;
            }
            .list-categories {
                text-align: left;
            }
            .list-categories a:first-of-type {
                width: 100%;
                margin-bottom: 8px;
            }
            .list-categories a {
                margin-left: 0;
                margin-right: 18px;
            }
            .top-post-title {
                font-size: 12px;
                line-height: 14px;
            }
            .wpsg-image-cover .top-post-title {
                font-size: 16px;
                line-height: 18px;
            }
            blockquote.news-quote {
                margin: 30px 30px;
                padding: 32px 35px;
            }
        }

        @media screen and (max-width: 768px) {
        .wrap {
            width: 100%;
            padding: 0 20px;
        }
        .wrap-colum {
            width: 100%;
            padding: 0 10px;
        }
        .wrap-colum .col-3-1,
        .wrap-colum .col-4-1 {
            padding: 0;
        }
        .horizontal-layout .entry-thumb,
        .horizontal-layout .entry-details {
            float: none;
            width: 100%;
        }
        .top-post-cat {
            display: inline-block;
        }
        .top-post-title {
            font-size: 18px;
            line-height: 24px;
        }
        .post-latest .entry-thumb {
            padding-right: 0;
        }
        .horizontal-layout .entry-details {
            padding-left: 0;
        }
        .search-form.content .search-input {
            margin-bottom: 10px;
        }
        .cat-meta-line .entry-categories {
            float: none;
            margin-bottom: 18px;
            padding: 0 10px;
        }
        .cat-meta-line .entry-meta {
            float: none;
            @media (max-width: 768px) {
                padding-left: 10px;
            }
        }
        .alignright,
        img.alignright,
        .alignleft,
        img.alignleft {
            max-width: 100%;
        }
        .author-bio-avatar {
            float: none;
            margin: auto;
            width: 100px;
            margin-bottom: 12px;
        }
        .author-bio-details {
            margin-left: 0;
        }
        .author-bio-title {
            text-align: center;
        }
        blockquote.news-quote {
            margin: 30px 0px;
            padding: 32px 25px;
        }
        }

        @media screen and (max-width: 380px) {
        .content input,
        .content .mc4wp-form-fields input,
        .content .mc4wp-form-fields input[type=submit],
        .search-form.content button {
            width: 100%;
        }
        .content .mc4wp-form-fields input[type=email] {
            margin-right: 0;
        }
        h3#reply-title {
            position: relative;
            margin-bottom: 50px;
        }
        #cancel-comment-reply-link {
            top: 100%;
            margin-top: 12px;
            left: 0;
            right: initial;
        }
        }
        @media (max-width: $break_m_sm) {
            .wrap {
                padding: 0 3px;
            }
        }

        /* Social Css */

        /*accesspress social counter front end css*/

        @font-face {
        font-family: 'MyriadPro-Regular';
        src: url('../fonts/MyriadPro-Regular.eot?#iefix') format('embedded-opentype'), url('../fonts/MyriadPro-Regular.otf') format('opentype'), url('../fonts/MyriadPro-Regular.woff') format('woff'), url('../fonts/MyriadPro-Regular.ttf') format('truetype'), url('../fonts/MyriadPro-Regular.svg#MyriadPro-Regular') format('svg');
        font-weight: normal;
        font-style: normal;
        }

        @font-face {
        font-family: 'MyriadPro-Bold';
        src: url('../fonts/MyriadPro-Bold.eot?#iefix') format('embedded-opentype'), url('../fonts/MyriadPro-Bold.otf') format('opentype'), url('../fonts/MyriadPro-Bold.woff') format('woff'), url('../fonts/MyriadPro-Bold.ttf') format('truetype'), url('../fonts/MyriadPro-Bold.svg#MyriadPro-Bold') format('svg');
        font-weight: normal;
        font-style: normal;
        }

        .clearfix:after,
        .clearfix:before {
        display: table;
        content: ""
        }

        .clearfix:after {
        clear: both
        }

        ;
        .clearfix {
        *zoom: 1
        }

        .apsc-each-profile {
        font-family: 'MyriadPro-Regular';
        }

        .apsc-theme-1 .apsc-each-profile {
        float: left;
        min-height: 80px;
        text-align: center;
        width: 80px;
        margin: 5px;
        }

        .apsc-each-profile a {
        font-weight: normal;
        color: white !important;
        text-decoration: none;
        }

        .apsc-each-profile a:hover {
        color: white !important;
        text-decoration: none !important;
        }

        .apsc-theme-1 .apsc-each-profile a {
        padding: 9px;
        display: block;
        color: #fff;
        position: relative;
        border-radius: 5px;
        -ms-filter: "progid:DXImageTransform.Microsoft.Shadow(Strength=2, Direction=135, Color=#b3b3b3)";
        /*IE 8*/
        -moz-box-shadow: 1px 1px 2px #b3b3b3;
        /*FF 3.5+*/
        -webkit-box-shadow: 1px 1px 2px #b3b3b3;
        /*Saf3-4, Chrome, iOS 4.0.2-4.2, Android 2.3+*/
        box-shadow: 1px 1px 2px #b3b3b3;
        /* FF3.5+, Opera 9+, Saf1+, Chrome, IE10 */
        filter: progid:DXImageTransform.Microsoft.Shadow(Strength=2, Direction=135, Color=#b3b3b3);
        /*IE 5.5-7*/
        transition: all 0.5s ease-in-out;
        -webkit-transition: all 0.5s ease-in-out;
        -moz-transition: all 0.5s ease-in-out;
        -o-transition: all 0.5s ease-in-out;
        -ms-transition: all 0.5s ease-in-out;
        }

        .entry-content .apsc-each-profile a {
        border: none;
        }

        // .site-footer .widget .apsc-each-profile a {
        // color: #fff;
        // text-decoration: none;
        // }

        .social-icon {
        display: block;
        }

        .apsc-theme-1 .media-name {
        display: none;
        }

        .apsc-theme-1 .apsc-count {
        font-size: 16px;
        display: block;
        line-height: 15px;
        padding: 2px 0 0;
        }

        .entry-content .apsc-each-profile a,
        .entry-content .apsc-each-profile a:hover,
        .entry-content .apsc-each-profile a:focus {
        color: #fff;
        text-decoration: none;
        }

        .apsc-theme-1 .apsc-each-profile a:before {
        background: radial-gradient(ellipse at center center, rgba(0, 0, 0, 0.35) 0%, rgba(0, 0, 0, 0) 80%) repeat scroll 0 0 rgba(0, 0, 0, 0);
        content: "";
        height: 10px;
        left: 5%;
        opacity: 0;
        pointer-events: none;
        position: absolute;
        top: 100%;
        transition-duration: 0.3s;
        -webkit-transition-duration: 0.3s;
        transition-property: transform, opacity;
        -webkit-transition-property: transform, opacity;
        width: 90%;
        z-index: -1;
        }

        .apsc-theme-1 .apsc-each-profile a:hover:before {
        opacity: 1;
        transform: translateY(6px);
        -webkit-transform: translateY(6px);
        -ms-transform: translateY(6px);
        }

        .apsc-theme-1 .apsc-each-profile a:hover {
        transform: translateY(-6px);
        -webkit-transform: translateY(-6px);
        -ms-transform: translateY(-6px);
        }

        .apsc-theme-1 .social-icon .fa {
        font-size: 45px;
        }

        .apsc-facebook-icon {
        background-color: #3b5998;
        }

        .apsc-twitter-icon {
        background-color: #1dcaff;
        }

        .apsc-youtube-icon {
        background-color: #e52d27;
        }

        .apsc-soundcloud-icon {
        background-color: #ff8800;
        }

        .apsc-dribble-icon {
        background-color: #ea4c89;
        }

        .apsc-comment-icon {
        background-color: #464646;
        }

        .apsc-edit-icon {
        background-color: #837d7d;
        }

        .apsc-google-plus-icon {
        background-color: #dd4b39;
        }

        .apsc-theme-1 .apsc-media-type {
        display: none;
        }

        /********************************************Theme 2 css*******************************************************/

        .apsc-theme-2 .apsc-each-profile {
        float: left;
        min-height: 83px;
        text-align: center;
        width: 80px;
        margin: 5px;
        }

        .apsc-theme-2 .apsc-count {
        display: block;
        padding: 1px 0;
        }

        .apsc-theme-2 .social-icon .fa {
        font-size: 36px;
        }

        .apsc-theme-2 .media-name {
        display: none;
        }

        .apsc-theme-2 .apsc-media-type {
        display: block;
        }

        .apsc-theme-2 .apsc-each-profile a {
        display: block;
        color: #fff;
        position: relative;
        transform: translateZ(0px);
        -webkit-transform: translateZ(0px);
        -ms-transform: translateZ(0px);
        -ms-filter: "progid:DXImageTransform.Microsoft.Shadow(Strength=2, Direction=135, Color=#b3b3b3)";
        filter: progid:DXImageTransform.Microsoft.Shadow(Strength=2, Direction=135, Color=#b3b3b3);
        /*IE 5.5-7*/
        transition: transform 0.5s ease-in-out;
        /* -webkit-transition:transform 0.5s ease-in-out; */
        -moz-transition: transform 0.5s ease-in-out;
        -o-transition: transform 0.5s ease-in-out;
        -ms-transition: transform 0.5s ease-in-out;
        }

        /********************************************Theme 3 css*******************************************************/

        .apsc-theme-3 {
            .apsc-each-profile {
                float: left;
                margin: 5px;
                a {
                    border-radius: 4px;
                    color: #fff;
                    display: block;
                    font-weight: normal;
                    padding: 1px;
                    position: relative;
                    text-shadow: none;
                }
            }
            .social-icon {
                display: table-cell;
                min-width: 97px;
                padding: 0 0 0 4px;
                vertical-align: middle;
                position: relative;
                transition: all 0.5s ease-in-out;
                -webkit-transition: all 0.5s ease-in-out;
                -moz-transition: all 0.5s ease-in-out;
                -o-transition: all 0.5s ease-in-out;
                -ms-transition: all 0.5s ease-in-out;
                .fa {
                    display: table-cell;
                    font-size: 18px;
                    vertical-align: middle;
                }
            }
            .apsc-count {
                background-color: #fff;
                border-bottom-right-radius: 4px;
                border-top-right-radius: 4px;
                color: #666666;
                display: table-cell;
                font-size: 16px;
                min-width: 52px;
                padding: 3px 10px;
                text-align: center;
                position: relative;
                opacity: 1;
                transition: all 0.5s ease-in-out;
                -webkit-transition: all 0.5s ease-in-out;
                -moz-transition: all 0.5s ease-in-out;
                -o-transition: all 0.5s ease-in-out;
                -ms-transition: all 0.5s ease-in-out;
                &:before {
                    border-bottom: 4px solid transparent;
                    border-right: 4px solid #fff;
                    border-top: 4px solid transparent;
                    content: "";
                    left: -4px;
                    margin-top: -3px;
                    position: absolute;
                    top: 50%;
                }
            }
            .media-name {
                display: table-cell;
                font-size: 14px;
                padding: 0 13px 0 5px;
                text-transform: capitalize;
                vertical-align: middle;
                min-width: 77px;
            }
        }

        /*.apsc-theme-3 .apsc-each-profile a:hover .apsc-count{
            height: 32px;
            min-width: 0;
            opacity: 0;
            padding: 0;
            width: 0;
        }*/

        .apsc-theme-3 .apsc-each-profile a:hover .social-icon {
        background-color: #fff;
        border-top-left-radius: 4px;
        border-bottom-left-radius: 4px;
        }

        .apsc-theme-3 .apsc-each-profile a:hover .apsc-count {
        color: #fff;
        }

        .apsc-theme-3 .apsc-each-profile a:hover .social-icon:after {
        border-bottom: 4px solid transparent;
        border-left: 4px solid #fff;
        border-top: 4px solid transparent;
        content: "";
        margin-top: -3px;
        position: absolute;
        right: -4px;
        top: 50%;
        z-index: 2;
        }

        .apsc-theme-3 .apsc-media-type {
        display: none;
        }

        .apsc-theme-3 .apsc-each-profile a.apsc-youtube-icon:hover .social-icon {
        color: #e52d27;
        }

        .apsc-theme-3 .apsc-each-profile a.apsc-youtube-icon:hover .apsc-count {
        background-color: #e52d27;
        }

        .apsc-theme-3 .apsc-each-profile a.apsc-soundcloud-icon:hover .social-icon {
        color: #ff8800;
        }

        .apsc-theme-3 .apsc-each-profile a.apsc-soundcloud-icon:hover .apsc-count {
        background-color: #ff8800;
        }

        .apsc-theme-3 .apsc-each-profile a.apsc-dribble-icon:hover .social-icon {
        color: #ea4c89;
        }

        .apsc-theme-3 .apsc-each-profile a.apsc-dribble-icon:hover .apsc-count {
        background-color: #ea4c89;
        }

        .apsc-theme-3 .apsc-each-profile a.apsc-comment-icon:hover .social-icon {
        color: #464646;
        }

        .apsc-theme-3 .apsc-each-profile a.apsc-comment-icon:hover .apsc-count {
        background-color: #464646;
        }

        .apsc-theme-3 .apsc-each-profile a.apsc-twitter-icon:hover .social-icon {
        color: #1dcaff;
        }

        .apsc-theme-3 .apsc-each-profile a.apsc-twitter-icon:hover .apsc-count {
        background-color: #1dcaff;
        }

        .apsc-theme-3 .apsc-each-profile a.apsc-instagram-icon:hover .social-icon {
        color: #3f729b;
        }

        .apsc-theme-3 .apsc-each-profile a.apsc-instagram-icon:hover .apsc-count {
        background-color: #3f729b;
        }

        .apsc-theme-3 .apsc-each-profile a.apsc-facebook-icon:hover .social-icon {
        color: #3b5998;
        }

        .apsc-theme-3 .apsc-each-profile a.apsc-facebook-icon:hover .apsc-count {
        background-color: #3b5998;
        }

        .apsc-theme-3 .apsc-each-profile a.apsc-google-plus-icon:hover .social-icon {
        color: #dd4b39;
        }

        .apsc-theme-3 .apsc-each-profile a.apsc-google-plus-icon:hover .apsc-count {
        background-color: #dd4b39;
        }

        .apsc-theme-3 .apsc-each-profile a.apsc-edit-icon:hover .social-icon {
        color: #837d7d;
        }

        .apsc-theme-3 .apsc-each-profile a.apsc-edit-icon:hover .apsc-count {
        background-color: #837d7d;
        }

        /********************************************Theme 4 css*******************************************************/

        .apsc-theme-4 {
            .apsc-each-profile {
                float: left;
                margin: 5px;
                a {
                    padding: 2px 9px 2px 6px;
                    display: block;
                    color: #fff;
                    position: relative;
                    border-radius: 4px;
                    .fa {
                        transform: translateZ(0px);
                        -webkit-transform: translateZ(0px);
                        -ms-transform: translateZ(0px);
                        transition: all 0.5s ease-in-out;
                        -webkit-transition: all 0.5s ease-in-out;
                        -moz-transition: all 0.5s ease-in-out;
                        -o-transition: all 0.5s ease-in-out;
                        -ms-transition: all 0.5s ease-in-out;
                    }
                    a {
                        &:hover {
                            .fa {
                                transform: scale(1.3) translateZ(0px);
                                -webkit-transform: scale(1.3) translateZ(0px);
                                -ms-transform: scale(1.3) translateZ(0px);
                            }
                        }
                    }
                }
            }
            .social-icon {
                display: inline-block;
                position: relative;
                transition: all 0.5s ease-in-out;
                -webkit-transition: all 0.5s ease-in-out;
                -moz-transition: all 0.5s ease-in-out;
                -o-transition: all 0.5s ease-in-out;
                -ms-transition: all 0.5s ease-in-out;
                .fa {
                    display: inline-block;
                    font-size: 18px;
                    vertical-align: middle;
                }
            }
            .media-name {
                display: inline-block;
                font-size: 14px;
                padding: 0 6px 0 5px;
                text-transform: capitalize;
            }
            .apsc-count {
                border-bottom-right-radius: 4px;
                border-top-right-radius: 4px;
                color: #fff;
                display: inline-block;
                font-size: 14px;
                text-align: center;
                position: relative;
                font-family: 'MyriadPro-Bold';
                transition: all 0.5s ease-in-out;
                -webkit-transition: all 0.5s ease-in-out;
                -moz-transition: all 0.5s ease-in-out;
                -o-transition: all 0.5s ease-in-out;
                -ms-transition: all 0.5s ease-in-out;
            }
            .apsc-media-type {
                display: none;
            }
        }

        /********************************************Theme 5 css*******************************************************/
        .entry-content .apsc-each-profile a {
            border: none;
        }

        .social-icon {
            display: block;
        }

        .apsc-theme-5 {
            .apsc-each-profile {
                min-height: 60px;
                text-align: center;
                width: 60px;
                margin: 5px;
                a {
                    padding: 6px;
                    display: block;
                    color: #fff;
                    position: relative;
                    border-radius: 5px;
                    transform: translateZ(0px);
                    -webkit-transform: translateZ(0px);
                    -ms-transform: translateZ(0px);
                    -ms-filter: "progid:DXImageTransform.Microsoft.Shadow(Strength=2, Direction=135, Color=#b3b3b3)";
                    /*IE 8*/
                    -moz-box-shadow: 1px 1px 2px #b3b3b3;
                    /*FF 3.5+*/
                    -webkit-box-shadow: 1px 1px 2px #b3b3b3;
                    /*Saf3-4, Chrome, iOS 4.0.2-4.2, Android 2.3+*/
                    box-shadow: 1px 1px 2px #b3b3b3;
                    /* FF3.5+, Opera 9+, Saf1+, Chrome, IE10 */
                    filter: progid:DXImageTransform.Microsoft.Shadow(Strength=2, Direction=135, Color=#b3b3b3);
                    /*IE 5.5-7*/
                    transition: all 0.5s ease-in-out;
                    -webkit-transition: all 0.5s ease-in-out;
                    -moz-transition: all 0.5s ease-in-out;
                    -o-transition: all 0.5s ease-in-out;
                    -ms-transition: all 0.5s ease-in-out;

                }
            }
            .apsc-count {
                font-size: 12px;
                display: block;
                line-height: 12px;
                padding: 3px 0 0;
            }
            .social-icon {
                height: 34px;
                .fa {
                    font-size: 34px;
                }           
            }
            .media-name {
                display: none;
            }
            .apsc-media-type {
                display: none;
            }
            .apsc-each-profile a:hover {
                box-shadow: 0 10px 10px -10px rgba(0, 0, 0, 0.5);
                transform: scale(0.8);
                -webkit-transform: scale(0.8);
                -ms-transform: scale(0.8);
            }
        }
        /************************************************responsive*************************************************************/

        @media screen and (max-width:479px) {
            .apsc-theme-4 .apsc-each-profile a,
            .apsc-theme-3 .apsc-each-profile a {
                display: inline-block;
            }
            .apsc-theme-4 .apsc-each-profile,
            .apsc-theme-3 .apsc-each-profile {
                float: none;
            }
        }

        @media screen and (max-width: 1280px) {
            body.page-content header.header-layout-style-1 .wrap {
                width: 1100px;
            }
            body.page-content .main-content {
                padding-left: 100px;
            }
        }

        @media screen and (max-width: 1180px) {
            .wrap.news-wope-theme,
            body.page-content header.header-layout-style-1 .wrap {
                width: 960px;
            }
            body.page-content .main-content {
                padding-left: 50px;
            }
        }

        @media screen and (max-width: 1000px) {
            .wrap.news-wope-theme,
            body.page-content header.header-layout-style-1 .wrap {
                width: 700px;
            }
            body.page-content .main-content {
                padding-left: 0px;
            }
        }

        @media screen and (max-width: 800px) {
            .wrap.news-wope-theme,
            body.page-content header.header-layout-style-1 .wrap {
                width: 640px;
            }
            body.page-content .main-content {
                padding-left: 0px;
            }
        }

        @media screen and (max-width: 720px) {
            .wrap.news-wope-theme,
            body.page-content header.header-layout-style-1 .wrap {
                width: 420px;
            }
            body.page-content .main-content {
                padding-left: 0px;
            }
        }

        @media screen and (max-width: 480px) {
            .wrap.news-wope-theme,
            body.page-content header.header-layout-style-1 .wrap {
                width: 100%;
            }
            body.page-content .main-content {
                padding-left: 0px;
            }
        }
        h1 {
            &.news-title {
                margin-bottom: .3em;
                font-size: 1.4rem;
                font-weight: 500;
                line-height: 1.3;
                a {
                color: #23527c;
                }
            }
        }
        // .news-top-stories {
        //     .news-thumb {
        //         float: left;
        //     }
        //     .news-thumb {
        //         float: left;
        //     }

        // }
        .footer-grid .footer-box>h5 {
            margin-top: 0;
            margin-bottom: 0;
            padding: 1rem 0;
        }
        .explorer-tep h5 {
            font-size: 1.3rem;
            font-weight: 500;
            color: #fff;
        }
        .footer-top a {
            color: silver;
            &:hover {
                text-decoration: underline;
            }
        }
        .footer-intro a {
            color: #3b839c;
            &:hover {
                text-decoration: underline;
            }
        }
        .social-media li a {
            color: #fff;
        }
        #scroll-header-content {
            background: #fff;
            border-bottom: 1px solid #eee;
        }
        @media (max-width: 1000px) {
            .main-content,
            .sidebar {
                float: none;
                width: 100%;
                padding-left: 0;
            }
            .main-content {
                padding-right: 0;
            }
        }
        // .navbar-brand-new {
        //     img {
        //         max-width: 119px;
        //         height: auto;
        //     }
        // }
        .top-nav {
            @media (max-width: 800px) {
                //center logo header
                // margin-bottom: 52px;
                margin-bottom: 81px;
            }
        }
        // .news-banner-ad {
        //     height: 505px;
        // }
    }
    &.page-user {
        .subs-news {
            display: none;
        }
    }
    &.page-content,
    &.node-type-tep-blogs
     {
         .page-projectreview, 
         #page-donedeal {
             font-family: $poppins-font;
             label, span, div, button, h1, h2, h3, h4, h5, h6,
             #form-agent {
                font-family: $poppins-font;
             }
             h1, h2 {
                 font-weight: bold !important;
             }
             h3, h4, h5, h6 {
                 font-weight: 600;
             }
             .news-hero-text {
                 h4 {
                     font-weight: 600 !important;
                 }
             }
         }
        // overflow-y: scroll;
        // -webkit-overflow-scrolling: touch;
        .slider-position {
            margin-top: 20px;
            .news-top-stories {
                padding-top: 2rem;
                padding-bottom: 2rem;
                border-bottom: 0.1rem solid $grey-color5;
                // box-shadow: 0 0.2rem 0.5rem -0.2rem $grey-color5;
                // position: relative;
                // z-index: 1;
                .news {
                    &-row {
                        display: flex;
                    }
                    &-thumb {
                        float: none;
                        width: 7rem;
                        flex: 0 0 auto;
                        align-self: center;
                    }
                    &-content {
                        width: auto;
                        margin-left: 0;
                        padding-left: 1.5rem;
                        flex: 1 1 auto;
                    }
                    &-title {
                        margin-bottom: 0.3em;
                        font-size: $base-font-size - 0.1rem;
                        font-weight: 500;
                        line-height: 1.3;
                        letter-spacing: .3px;
                    }
                    &-meta {
                        font-size: $base-font-size - 0.2rem;
                        font-weight: 300;
                        color: $black;
                        white-space: nowrap;
                        span {
                            +span {
                                margin-left: 1rem;
                                padding-left: 1rem;
                                border-left: 0.1rem solid $grey-color4;
                            }
                        }
                    }
                }
                
                // Wope Theme news top stories slider
                &.news-slider {
                    padding: 0;
                    border: none;
                    box-shadow: none;
                    font-family: 'Roboto', Arial;
                    background-color: white;
                    // box-shadow: 0 0.2rem 0.5rem -0.2rem $grey-color5;
                    @media(max-width: $break_tablet_sn8)and(min-width: $break_tablet) {
                        // position: relative;
                        // top: -29px;
                    }
                    .slider-title {
                        position: absolute;
                        top: -10px;
                        left: 25px;
                        background-color: white;
                        padding: 0 10px;
                        text-transform: uppercase;
                        font-weight: 600;
                        font-size: 11px;
                    }
                    .container-fluid {
                        padding: 0;
                        border-top: 1px solid #d0d0d0;
                        border-bottom: 1px solid #d0d0d0;
                        box-shadow: 0 4px 11px -5px #bfbfbf;
                    }
                    .owl-stage {
                        display: flex;
                        flex-direction: row;
                        align-items: stretch;
                        margin-left: -35px;
                        background: #f3f3f3;
                        @media (max-width: 1200px) {
                            margin-left: -65px;
                        }
                        @media (max-width: 992px) {
                            margin-left: -35px;
                        }
                        @media (max-width: 768px) {
                            margin-left: -35px;
                        }
                        @media (max-width: 476px) {
                            margin-left: -20px;
                        }
                    }
                    .owl-next, 
                    .owl-prev {
                        border-radius: 0;
                        height: 100%;
                        width: 30px;
                        background-image: linear-gradient(to right, rgba(255, 255, 255, 0.3), #fff);
                        font-size: 1.2rem;
                        text-align: center;
                        display: flex;
                        justify-content: center;
                        transition: all .2s ease-in-out;
                        margin: 0 auto;
                        &:before {
                            font-family: FontAwesome;
                        }
                        span {
                            display: none;
                        }
                    }
                    .owl-prev {
                        background-image: linear-gradient(to right, #fff, rgba(255, 255, 255, 0.3)) ;
                        &:before {
                            content: "\f053";
                            position: relative;
                            top: 35px;
                            // opacity: .5;
                            // display: none;
                        }
                    }
                    .owl-next {
                        &:before {
                            content: "\f054";
                            position: relative;
                            top: 35px;
                            // opacity: .5;
                            // display: none;
                        }
                    }
                    .owl-item {
                        position: relative;
                        margin: 10px 0;
                        &:after {
                            content: "";
                            display: block;
                            position: absolute;
                            top: 0;
                            width: 100%;
                            height: 100%;
                            background: linear-gradient(to right, rgba(255, 255, 255, .1) 0%, rgba(255, 255, 255, 1) 25%);
                            margin-left: -6px;
                            transition: all .2s ease-in-out;
                        }
                        &.active:after {
                            content: none;
                            transition: all .2s ease-in-out;
                        }
                        &.active:first-child:before {
                            content: none;
                        }
                        &.active:before {
                            content: "";
                            display: block;
                            height: 100%;
                            width: 50%;
                            position: absolute;
                            top: 0;
                            z-index: 1;
                            
                        }
                        &.active~.active:before {
                            content: none;
                        }
                        &:last-child a.news-row {
                            border-right: none;
                        }
                    }
                    .i-carousel {
                        padding: 0;
                        .owl-nav i {
                            align-self: center;
                            &:before {
                                font-weight: bold;
                                text-shadow: -1px 0 #252525, 0 1px #252525, 1px 0 #252525, 0 -1px #252525;
                            }
                        }
                    }
                    a.news-row {
                        height: 100%;
                        border-right: 1px solid #d0d0d0;
                        padding: 0 15px;
                        &:hover {
                            text-decoration: none;
                        }
                        .news-content {
                            flex: 1 1 auto;
                            padding-left: 10px;
                            .news-meta {
                                display: flex;
                                span.date {
                                    white-space: normal;
                                }
                            }
                            h1.news-title {
                                font-size: 13px;
                                -o-text-overflow: ellipsis;
                                text-overflow: ellipsis;
                                display: -webkit-box;
                                -webkit-line-clamp: 4;
                                -webkit-box-orient: vertical;
                                overflow: hidden;
                                @media (min-width: $break_m_sm) and (max-width: $break_d_common_md) {
                                    font-size: 12px;
                                    position: relative;
                                    max-height: 62px;
                                }
                                @media (max-width: $break_m_x) {
                                    font-size: 12px;
                                }
                            }
                        }
                        .news-thumb {
                            width: 8.5rem;
                            height: 6.5rem;
                            align-self: flex-start;
                            >img {
                                width: 100%;
                                height: 100%;
                                object-fit: cover;
                            }
                        }
                    }
                }
                
                &.pos-fixed {
                    position: fixed !important;
                    width: 100%;
                    &.newscroll {
                        z-index: 999;
                        // top: 53px;
                        top: 100px;
                        // @media (min-width: $break_post_d) {
                        //     z-index: 999;
                        // }
                        @media (max-width: $break_d_common_md) {
                            // top: 53px;
                            top: 100px;
                        }
                        @media (max-width: $break_tablet_sn8) {
                            top: 51px;
                        }
                        @media (max-width: $break_m_xx ) {
                            // top: 0;
                        }
                    }
                }
            }
        }  
        .leaderboard-1 {
            background: transparent;
            // #bigleaderboard div[id^='div-gpt-ad'] {
            //     @media (min-width: $break_tablet) {
            //         margin: 0 0 10px;
            //     }           
            // }
        }
        @media screen and (max-width: 1180px) {
            .wrap {
                width: 960px;
            }
        }
        .news-body {
            &.content {
                #block-block-10 {
                    // border-bottom: 40px solid #fff;
                }
                .block-mrec {
                    figure {
                        @media (max-width: $break_m_x) {
                            left: 0px!important;
                        }
                    }

                } 
                figure {
                    @media (min-width: 425px) and (max-width: 490px) { 
                        left: calc((100% - 425px) / 2);
                    }
                    @media (max-width: 425px) {
                        width: 101.1vw;
                        left: calc(-50vw + 50%);
                        
                        margin-bottom: 10px;
                    }
                    &.first {
                        border-bottom: 25px solid #fff;
                    }
                }
                figcaption {
                    @media (max-width: 768px) {
                        width: 100%;
                        text-align: left;
                        padding: 0 20px;
                    }
                }
                p {
                    // font-family: $content-font;
                    // font-weight: normal;
                    // font-size: 20px;
                    // line-height: 1.75; 
                    // padding: 0px 0px 35px 0px;
                    // margin: 0;
                    color: #000;
                    font-family: $poppins-font;
                    font-weight: 400;
                    font-size: 1.2em;
                    line-height: 1.5; 
                    padding: 0px 0px 16px 0px;
                    margin: 0;
                    &:last-child {
                        padding-bottom: 0px;
                    }
                    @media (max-width: 991px) {
                        // font-size: 17.5px;
                        // line-height: 1.8;   
                        // padding: 0 20px 40px;
                        // margin-left: 0 !important;
                        font-size: 16px;
                        line-height: 26px;   
                        padding: 0 20px 20px;
                        margin-left: 0 !important;
                        // letter-spacing: -.1px;
                    }
                    a {
                        display: initial;
                    }
                }
            }
        }
        .related-post {
            .post-section-title {
                h5 {
                    margin-bottom: 0;
                }
            }
            margin-bottom: 15px;
            ol {
                // list-style: decimal;
                list-style: none;
                counter-reset: li;
                li {
                    counter-increment: li;
                    margin-left: 32px;
                    .related-article {
                        margin-bottom: 0;
                        border-bottom: 0;
                    }
                    .line-breaker {
                        border-bottom: 1px solid #d9d9d9;
                        position: relative;
                        left: -31px;
                        width: 105%;
                        @media (max-width: $break_m_lg) {
                            width: 108%;
                        }
                        @media (max-width: $break_m_md) {
                            width: 110%;
                        }
                    }
                }
                li::before {
                    content: counter(li); 
                    text-align: center;
                    font-weight: 600;
                    font-size: 30px;
                    line-height: 0;
                    position: relative;
                    top: 33px;
                    // color: $link-color-19;
                    color: $body-color;
                    display: inline-block; 
                    width: 1em;
                    margin-left: -1.3em;
                }
            }
        }
        h2 {
            &.entry-title {
                font-family: $blog-font;
            }
        }
        // share button
        .top2-entry-post-share,
        .bottom-entry-post-share {
            @media ( max-width: $break_pre_tablet) {
                display: none;
            }
        }
        .share-news-content,
        .share-item {
            @media (min-width: $break_tablet) {
                display: none;
            }
        }
        .slider-position.stick-top {
            z-index: 1000;
        }
        .entry-meta {
            // Please enable this to put back the meta dom position
            // display: none; 
            // Please enable this to put back the meta dom position
            background: #fff;
            @media (max-width: 767px) {
                // padding-left: 10px;
            }
            // padding: 10px;
            .em-author {
                display: block;
                // text-transform: uppercase;
                // letter-spacing: 1px;
                // color: #1e4f96;
                text-transform: initial;
                color: $body-color;
                line-height: 18px;
                letter-spacing: -.3px;
                font-weight: 600;
                font-style: normal;
            }
            .em-date {
                // display: block;
                display: inline-block;
                // letter-spacing: 1px;
                // font-weight: bold;
                // font-size: 1.2rem;
                margin-top: 5px;
                color: #4a4a4a;
                border-bottom: 10px solid #fff; 
                @media (max-width: 767px) {
                    margin-top: 2px;
                    border-bottom: 15px solid #fff; 
                }
            }
        }
        .news-top-stories.news-slider {
            z-index: 99999;
        }
        // skin ads related
        .wrap.news-wope-theme {
            @media (min-width: 1023px) {
                // margin-top: 186px;
                //center logo header
                // margin-top: 75px;
                margin-top: 95px;
            }
            @media (max-width: $break_tablet) {
                margin-top: 0;
            }
            // @media (max-width: $break_m_xx) {
            //     margin-top: 97px;
            // } 
        }
        .news-content-wrapper {
            background: #fff;
        }
        .content {
            table {
                background: #fff;
                pointer-events: auto;
            }
        }
    }
    &.page-edgeproptv,
    &.page-news,
    &.page-pullout,
    &.page-events,
    &.page-event,
    &.page-tags,
    &.page-node-34,
    &.page-node-29 {
        .video-title {
            font-size: 1em;
        }
        .wrap {
            .main-content {
                padding: 10px 0 0;
            }
            .sidebar {
                @media (min-width: 1000px) {
                    padding-left: 50px;
                }
            }
        }
        .widget-tem-search {
            @media (min-width: $break_post_d) {
                margin-left: 22px;
            }
        }
    }

    &.page-news {
        .show-more-lifestyle {
            color: #f45454!important;
        }
        .flexslider {
            &.video {
                .entry-thumb {
                    cursor: pointer;
                }
            }   
        }
    }
    &.page-newspages {
        .news-top-stories.news-slider {
            display: none;
        }
        .video-date p{
            font-size: 16px;
            line-height: 28px;
        }
    }
    @media (max-width: $break_m_x) {
        footer {
            .footer-top {
                .footer-grid {
                    margin-right: 0;
                    margin-left: 0;
                }
            }
        }
    }

    // custom specific content
    &.page-content {
        &-1902981 {
            #block-block-10 {
                border-bottom: 40px solid #fff;
            }
        }
        &-1903083 {
            figure.first {
                border-bottom: 10px solid #fff !important;
            }
            .prlxlabel.end,
            .skin-ads-parallax-wrapper {
                display: none !important; 
            }
        }
    }
    // end custom specific content
}
body[class*='page-content-done-deal-'],
body.page-content-1715218 {
    .container-full-new.header-mobile-view .navbar-brand-new .logo-mobile {
        @media (max-width: 1024px) {
            position: relative; 
            left: calc(13% + -4%);
        } 
        @media (max-width: 912px) { 
            // left: 84%;
        } 
        @media (max-width: 820px) {  
        }  
        @media (max-width: 767px) {
            // left: 4%; 
        } 
        @media (max-width: 425px) { 
            // left: 21px;
        }
        @media (max-width: 320px) {
            // width: 80%;
        }
    }
}

body.page-content {
    .news-body.content {
        ul, ol {
            li {
                font-size: 1.1em;
                margin-bottom: 10px;
                color: #222;
                ul li {
                    font-size: 1em;
                    line-height: 1.7;
                }
            @media (max-width: 576px) {
                // font-size: 17.5px;
            }
            }
        }
        ol li {
            font-size: 1.2em;
            line-height: 1.6;
        }
        ul.content-bullet-list {
            li {
                font-size: 1.35em;
                &:before {
                    top: 23px;
                }
            }
        }
    }
    figcaption {
        color: #76767a;
        font-weight: 300;
        font-size: 12px;
    }
}
body.page-content {
    .related-post h6 {
        font-family: $widget-side-trending;
    }
}