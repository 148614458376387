@charset "UTF-8";

@import url('https://fonts.googleapis.com/css?family=Chivo:400,700,900');
@import url('https://fonts.googleapis.com/css?family=Inknut+Antiqua');
$live-title: 'Chivo', sans-serif;
$live-subtitle: 'Inknut Antiqua', serif;
$live-color: #ff006c;
$live2019-color: #ff006c;
$live2020-color: #00ff01;
$destination-color: #31cc89;

body.page-live, 
body.page-live2019, 
body.page-live2020,
body.page-destination
// , body.page-node-56 
{ 
  font-family: $live-title;
  a {
    color: #000;
    -webkit-transition: all .3s;
    -moz-transition: all .3s;
    transition: all 0.3s;
    &:hover {
      color: lighten( $live-color, 7%);
      text-decoration: none;
    }
  }
  span {
    font-family: $live-title;
  }
  hr {
    max-width: 100px;
    margin: 25px auto 0;
    border-width: 1px;
    border-color: rgba(34, 34, 34, 0.1);
    &.light {
      border-color: #fff;
    }
  }
  h1, h2, h3, h4, h5, h6 {
    font-family: $live-title;
    font-weight: 900;
    letter-spacing: 1px;
  }
  p {
    font-family: $live-title;
    font-size: 18px;
    line-height: 1.5;
    margin-bottom: 20px;
  }
  section {
    padding: 100px 0;
    h2 {
      font-size: 50px;
    }
  }
  span.header-logotype {
    font-weight: 700 !important;
    text-transform: uppercase !important;
    color: $live-color !important;
    position: relative;
    top: 18px;
  }
  .mag-title-wrapper {
    background: #000;
    text-align: center;
    padding: 23px 0 19px;
    .mag-title {
      font-size: 48px;
      line-height: 44px;
      font-weight: 200;
      font-family: $live-title;
      margin-top: 0;
      margin-bottom: 0;
      text-align: center;
      text-transform: uppercase;
      color: #fff;
    }
    .mag-subtitle {
      font-family: $live-subtitle;
      color: #fff;
      font-size: 29px;
    }
    @media (min-width: $break_tablet) {
      background: rgba(0, 0, 0, 0.66);
      position: relative;
      margin-top: -129px;
    }
  }
  .img-full {
    width: 100%;
    &.magcover {
      @media (min-width: $break_tablet) {
        max-width: 300px;
        height: auto;
      }
      @media (max-width: $break_pre_tablet) {
        display: none;
      }
    }
  }
  // img.img-full.magcover {
  //   @media (max-width: $break_m_lg) {
  //     display: none;
  //   }
  // }
  .mag-box {
    display: block;
    margin: 15px 0;
    overflow: hidden;
    .box {
      padding-top: 25px;
      height: 90px;
      background: #000;
      text-transform: uppercase;
      font-weight: 700;
      font-family: $live-title;
      font-size: 13px;
      color: #fff;
      display: inline-block;
      width: 47%;
      float: left;
      &:hover {
        background: lighten( $live-color, 7%);
      }
      @media (min-width: $break_tablet) {
        width: 70%;
        font-size: 14px;
        letter-spacing: 0.8px;
      }
    }
    .box-1 {
      margin-right: 12px;
    }
    .box-2 {
      background: $live-color;
    }
    .box a {
      color: #fff;
      &:hover {
        text-decoration: none;
      }
    }
    @media (min-width: $break_tablet) {
      margin-top: 122px;
    }
  }
  
  #mainNavLive {
    background-color: #333;
    &.stick {
      margin-top: 0 !important;
      width: 100%;
      position: fixed;
      top: 0;
      z-index: 10000;
    }
  }
  .m-b-20 {
    margin-bottom: 20px;
  }
  .logotype {
    text-align: center;
    color: #f4b124;
    margin-left: auto;
    margin-right: auto;
    left: 0;
    right: 0;
    img {
      width: 250px;
      opacity: 0.9;
    }
    &.footer img {
      width: 110px;
    }
    &.toplogo img {
      width: 90px;
    }
    &.home {
      text-align: left;
      position: absolute;
      z-index: 1;
      margin-left: auto;
      margin-right: auto;
      left: 0;
      right: 0;
      padding: 8px;
    }
  }
  .border-side {
    border-right: 1px solid #ccc;
    border-left: 1px solid #ccc;
  }
  .no-float {
    float: none;
  }
  footer {
    color: #fff;
    padding: 20px 0;
    text-align: center;
    color: rgba(255, 255, 255, 0.3);
    background-color: #222;
    p {
      font-size: 12px;
      margin: 0;
    }
    ul {
      margin-bottom: 0;
      li a {
        font-size: 12px;
        color: rgba(255, 255, 255, 0.3);
        &.active, &:active, &:focus, &:hover {
          text-decoration: none;
        }
      }
    }
  }
  #mainNavLive {
    border-color: rgba(34, 34, 34, 0.05);
    background-color: #000;
    -webkit-transition: all .3s;
    -moz-transition: all .3s;
    transition: all .3s; 
    font-weight: 200;
    letter-spacing: 1px;
    border-radius: 0;
    .navbar {
      border-radius: 0 !important;
    }
    .navbar-brand {
      color: $live-color; 
      font-weight: 200;
      letter-spacing: 1px;
      &:focus, &:hover {
        color: #b96700;
      }
    }
    .navbar-toggler {
      font-size: 12px;
      padding: 8px 10px;
      color: #fff;
      position: absolute;
      right: 10px;
      top: 10px;
    }
    .navbar-nav > li > a {
      font-size: 13px; 
      letter-spacing: 2px;
      text-transform: uppercase;
      letter-spacing: 1px;
      &.active {
        color: $live-color !important;
        background-color: transparent;
        &:hover {
          background-color: transparent;
        }
      }
      color: #fff;
      &:focus {
        color: #fff;
        &:hover {
          color: $live-color;
        }
      }
      &:hover {
        color: $live-color;
      }
    }
    .navbar-nav {
      .nav-item {
        .wrap-year-select {
          position: relative;
          padding-top: 10px;
          padding-left: 10px;
          @media (max-width: 425px) {
            padding: 0;
          }
          .dropdown {
            .dropdown-toggle {
              background-color: transparent;
              border: 1px solid #ffffff;
              border-radius: 0;
              font-size: 13px;
              color: #ffffff;
              letter-spacing: 1px;
            }
            .dropdown-menu {
              background-color: #000;
              .dropdown-item {
                border-radius: 0;
                font-size: 13px;
                color: #ffffff;
                &:hover {
                  color: $live-color;
                  background-color: #000;
                }
                @media (max-width: 425px) {
                  text-align: left;
                  padding-left: 0;
                }
              }
            }
          }
        }
      }
    }
  }
  section {
    &.general {
      border-top: 10px solid $live-color;
      position: relative;
      padding: 50px 0 50px;
      overflow: hidden;
      h2 {
        font-size: 50px;
        margin: 0 0 30px;
        text-transform: uppercase;
        font-family: $live-title;
        &.m-b-5 {
          margin-bottom: 5px;
        }
      }
      h3 {
        font-size: 21px;
        font-family: $live-title;
        margin-bottom: 30px;
        text-transform: uppercase;
      }
      h4 {
        font-family: $live-title;
        font-size: 2.2rem;
        line-height: 1;
        margin-bottom: 15px;
        color: $live-color;
        text-transform: lowercase;
        font-style: italic;
      }
      .overlay-header {
        width: 100%;
        background-color: rgba(0, 0, 0, 0.6);
        position: absolute;
        font-size: 68px;
        font-weight: 200;
        font-family: $live-title;
        font-style: italic;
        color: #fff;
        text-transform: capitalize;
        text-align: center;
        padding: 71px;
      }
      header {
        height: 244px;
        margin-bottom: 30px;
        background-position: center;
      }
      .highlight-cards .single-card {
        margin-bottom: 40px;
        img {
          max-width: 100%;
        }
        .highlight-title {
          letter-spacing: 1px;
          font-size: 15px;
          font-family: $live-title;
          text-transform: uppercase;
          font-weight: 800;
          padding: 17px 0;
        }
        .highlight-desc {
          font-size: 14px;
          color: #666;
        }
        a {
          text-decoration: none;
        }
      }
      &.no-border {
        border-top: 0;
      }
      &.p-t-25 {
        padding-top: 25px;
      }
    }
    &.home {
      position: relative;
      padding: 0;
      -webkit-background-size: cover;
      -moz-background-size: cover;
      -o-background-size: cover;
      background-size: cover;
      .home-content {
        text-align: center;
        position: relative;
        z-index: 1;
      }
      .overlay {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.5);
      }
    }
    &#editorsnote p {
      text-align: left;
      font-size: 16px;
      color: #666;
    }
  }


  @media (max-width: $break_tablet) {
    .logotype {
        img {
          width: 220px;  
        }
    }
  }
  @media (max-width: $break_pre_tablet) {
    .border-side {
        border-right: 0;
        border-left: 0; 
    }
  }
  @media (min-width: $break_post_d_sm) {
    #mainNavLive {
      .navbar-brand {
        color: fade(white, 70%);
        &:focus, &:hover {
          color: #fff;
        }
      }
      .navbar-nav > li > a {
        color: rgba(255, 255, 255, 0.7);
        &:focus {
          color: rgba(255, 255, 255, 0.7);
          &:hover {
            color: #fff;
          }
        }
        &:hover {
          color: #fff;
        }
      }
      &.navbar-shrink {
        border-color: rgba(34, 34, 34, 0.1);
        background-color: #000;
        border-radius: 0;
        .navbar-brand {
          color: #f9f9f9;
          &:focus, &:hover {
            color: $live-color;
          }
        }
        .navbar-nav > li > a {
          color: #f9f9f9;
          &:focus {
            color: #f9f9f9;
            &:hover {
              color: $live-color;
            }
          }
          &:hover {
            color: $live-color;
          }
        }
      }
    }
  }  
  @media (max-width: $break_m_lg) {
    .mag-title-wrapper {
        .mag-title {
            font-size: 33px;
        }
        .mag-subtitle {
            font-size: 15px;
        }
    }
    .mag-box {
        .box-1 {
          margin-right: 11px; 
        }
        .box {
          width: 48%; 
        }
    }
    .logotype {
        img {
          width: 130px; 
        }
    }
    section {
        &.general {
            padding: 10px 0 50px;
            h2 {
              font-size: 28px;
            } 
            .overlay-header {
                font-size: 33px;
                padding: 6px;
            }
            header {
                height: 61px;
                &.features-header {
                  height: 60px; 
                }
            }
        }
        &.home {
            padding: 0;
        }
    }
  }
  @media (min-width: 426px) {
    .visible-xs {
      display: none !important;
    }
  }
  
  @media (max-width: $break_m_lg) {
    .visible-xs {
      display: block;
    }
  }


  .bg-primary {
      background: $live-color;
      background: -webkit-linear-gradient($live-color, #d37500);
      background: linear-gradient($live-color, #d37500);
  }
  .text-primary {
      color: $live-color;
  }
  .no-gutter > [class*=col-] {
      padding-right: 0;
      padding-left: 0;
  }
  .btn-outline {
      color: #fff;
      border: 1px solid;
      border-color: #fff;
      &.active, &:active, &:focus, &:hover {
          color: #fff;
          border-color: $live-color;
          background-color: $live-color;
      }
  }
  .btn {
      border-radius: 300px; 
      letter-spacing: 2px;
      text-transform: uppercase;
  }
  .btn-xl {
      font-size: 11px;
      padding: 15px 45px;
  }
  .nav-pills .nav-link.active {
      border: 1px solid $live-color;
      color: $live-color;
      background-color: transparent;
  }
  ul.list-unstyled li {
      margin-bottom: 20px;
      font-size: 13px;
      color: #666;
  }
  .ml-auto, .mx-auto {
      margin-left: auto !important;
  }
  .mr-auto, .mx-auto {
      margin-right: auto !important;
  }
  .fotorama {
      &__caption { 
          font-size: 2rem;
          // padding: 30px;
          width: 50%;
          margin: 0 auto;
          text-align: center;
          @media (min-width: $break_tablet) {
            bottom: 50px;
          }
          @media (max-width: $break_pre_tablet) {
            font-size: 2rem;
            bottom: 0px;
            width: 100%;
          }
          
          a {
              text-decoration: none;
              border-bottom: 0;
          }
          span {
            display: block;
            &.slider-title {
              font-weight: bold;
              line-height: 1;
              margin-bottom: 6px;
              @media (max-width: $break_pre_tablet) {
                font-size: 1.7rem;
              }
            }
            &.slider-subtitle {
              font-size: 1.6rem;
              line-height: 1.5;
              @media (max-width: $break_pre_tablet) {
                font-size: 1.2rem; 
              }
              span.glyphicon {
                display: inline;
                font-size: 1.1rem;
              }
            }
          }
          .fotorama__caption__wrap {
            @media (min-width: $break_post_tablet) {
              padding: 15px !important;
              width: 600px;
            } 
          }
      }
      &__arr {
          background: 0 0 !important;
          &:before {
              font-family: Ionicons;
              content: "";
              margin-right: 6px;
              font-size: xx-large; 
              font-size: 58px;
              content: "";
              margin-right: 0;
              color: lighten( $live-color, 7%);
              position: relative;
              top: -30px;
              &:hover {
                color: $live-color;
              }
          }
          &.fotorama__arr--next:before {
              content: "";
          }
          &.fotorama__arr--prev:before {
              left: 8px;
              content: "";
          }
      }
      .fotorama__html {
          background-color: rgba #000, 0.6;
          span {
              display: block;
          }
          a, div {
            display: block;
            height: 100%;
            // Transparent links are not clickable in IE,
            //    but non-existent background fixes this.
            //   (Put an empty 1×1 image here to avoid
            //    errors in console.)
            // background: url(https://media.edgeprop.my/ui/1px.png);
            background: url(_.gif);
          }
      }
      .caption-element {
        top: 0;
        position: absolute;
        z-index: 999;
        right: 0;
        background: rgba(255, 255, 255, 0.9);
        width: 50%;
        height: 100vh;
        span {
          display: block;
          &.slider-title {
            font-weight: bold;
            line-height: 1;
            margin-bottom: 6px;
          }
          &.slider-subtitle {
            font-size: 2rem;
            line-height: 1.5;
            @media (max-width: $break_pre_tablet) {
              font-size: 1.2rem; 
            }
          }
        }
      }
  }
  span {
    display: block;
    &.stories-title {
      font-size: 1.4rem;
      font-weight: bold;
    }
    &.stories-subtitle {
      font-size: 1.2rem;
    }
  } 
  ul {
    &.stories-list {
      li { 
        span {
          &.story-text {
            display: block; 
            font-weight: 600;
            font-size: 1.4rem;
            line-height: 1.5;
            margin: 0 auto;
            @media (min-width: $break_post_tablet) {
              width: 70%;
            }
          }
        }
        @media(min-width: $break_post_tablet) {
          min-height: 270px;
        }
        @media(min-width: $break_tablet) and (max-width: $break_tablet) {
          min-height: 262px;
        }
      }
    }
  }


 /*  @media (min-width: 768px) and (max-width: 991px) {
    .navbar-collapse.collapse {
      display: none !important;
      &.in {
        display: block !important;
      }
    }
    .navbar-header .collapse, .navbar-toggle {
      display: block !important;
    }
    .navbar-header {
      float: none;
    }
  } */
  select#select-years {
    font-size: 1.3rem;
    position: relative;
    background: #000;
    color: #fff;
    @media (min-width: $break_tablet) {
      top: 13px;
    }
  }

  .top-mag-area {
    .row:before, .row:after {display: none !important;} /* safari fix */
  }

  .stories-three-columns {
    .row {
      &:before, &:after {display: none !important;} /* safari fix */
      @media(max-width: 554px){
        display: inline-block;
        width: 100%;
      }
      .col {
        .box {
          margin-bottom: 30px;
          img {
            width: 220px;
            height: auto;
            @media (max-width: 991px) {
              width: 150px;
            }
            @media (max-width: 554px) {
              width: 100%;
            }
          }
    
          .story-text {
            font-weight: 600;
            font-size: 1.4rem;
            margin: 0 auto;
            width: 250px;
            padding-top: 17px;
            text-align: center;
            @media (max-width: 991px) {
              width: 150px;
            }
            @media (max-width: 425px) {
              width: 250px;
            }
          }
        }
      }
    }
  }
}

// global function to adjust year dropdown item color
@mixin yearDropDownItemHover($themeColor) {
  .navbar-nav {
    .nav-item {
      .wrap-year-select {
        .dropdown {
          .dropdown-menu {
            .dropdown-item {
              &:hover {
                color: $themeColor;
              }
            }
          }
        }
      }
    }
  }
}