body { 
  // for all page-guides-*
  &[class*="page-guides-"] {
    .breadcrumb,
    .page-header {
      display: none;
    }
    .top-nav {
      @media (max-width: 800px) {
          margin-bottom: 0;
      }
    }
    // container fluid
    .main {
      // margin-bottom: 150px;
      .content-area {
        .container:nth-child(2) {
          width: 100%;
          padding-right: 15px;
          padding-left: 15px;
          margin-right: auto;
          margin-left: auto;
          margin-top: 70px;

          @media (min-width: 1200px) {
            max-width: 1200px;
          }
          @media (max-width: 991px) {
            margin-top: 0;
          }
          .row {
            // left col
            .content-column {
              width: 67.25%;

              @media (max-width: 800px) {
                width: 100%;
              }
              .page-heading {
                // display: none;
                // padding-bottom: 15px;
                
                @media (max-width: 768px) {
                  display: inline-block;
                }
              }
              .tepblog {
                margin: 0;
                width: 100%;
                top: 0;

                .main-content {
                  width: 100%;
                  padding: 0;
                }
              }
            }
            // right col
            .sidebar-second {
              padding-top: 130px;
              @media(max-width: 768px) {
                padding-top: 0;
              }
            }
          }
        }
      }

      ul.pagination {
        margin-top: 100px;

        @media (max-width: 768px) {
          margin-top: 0;
        }
        .active {
          span {
            padding: 0.8rem 1.6rem;
            border-radius: 0;
            background-color: rgba(59, 131, 156, 0.85);
            color: #ffffff;
          }
        }

        li {
          a {
            padding: 0.8rem 1.6rem;
            &:hover {
                color: #000;
                background-color: rgba(59, 131, 156, 0.1);
            }
          }
        }
      }
    }

  }
}