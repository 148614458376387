//------------------------------
// Bootstrap - Fix, Addon & etc
//------------------------------

/* Breadcrumb */
.breadcrumb {
  padding: 0.5rem 0;
  margin-bottom: 0;
  background-color: transparent;
  font-size: x-small;
  border-radius: 0;
  > li {
    &:first-child {
      > a {
        font-size: 0;
        &:before {
          content: '\f144';
          display: block;
          float: left;
          margin-top: -0.1rem;
          color: lighten($base-color, 18%);
          font-size: 1.8rem;
          font-family: $font-ionic;
          line-height: 1;
          position: relative;
          font-size: 1.4rem;
        }
      }
    }
    + li {
      &:before {
        content: '\f3d3\00a0';
        font-family: $font-ionic;
        line-height: 1;
      }
    }
    > a {
      color: $base-color;
    }
  }
}

/* Button */
.btn {
  &-default {
    &.active,
    &.active:hover,
    &.active:focus {
      background-color: $theme-color;
      border-color: $theme-color;
      color: $white;
      box-shadow: none;
    }
    &.active:active {
      background-color: darken($theme-color, 10%);
    }
  }
}
.btn,
[role='button'] {
  &.active.focus,
  &.active:focus,
  &.focus,
  &:active.focus,
  &:active:focus,
  &:focus {
    outline: none;
  }
}

/* Container */
.container {
  @media (min-width: 1200px) {
    width: $base-container;
  }
}

/* Form Input */
.form {
  &-control {
    &:not(:focus) {
      box-shadow: none;
    }
    &:focus {
      border-color: $theme-color;
      box-shadow: 0 0 0.6rem -0.1rem rgba($theme-color, 0.36)
    }
  }
}

/* Input Group */
.input-group {
  &-addon {
    &.clean {
      background-color: transparent;
      border-color: transparent;
      + .form-control {
        border-radius: 0.4rem;
      }
    }
  }
}

/* List */
.list-inline {
  &.dots {
    > li {
      position: relative;
      + li {
        &:before {
          content: '';
          display: block;
          width: 0.2rem;
          height: 0.2rem;
          border-radius: 2rem;
          background-color: lighten($base-color, 50%);
          position: absolute;
          left: -0.2rem;
          top: 50%;
          border-radius: 1rem;
          transform: translateY(-50%);
        }
      }
    }
  }
}

/* Label */
.label {
  &.rounded {
    padding: 0.286em 1.429em;
    border-radius: 2em;
  }
  &.block {
    display: block;
    padding-top: 0.476em;
    padding-bottom: 0.476em;
  }
}

/* Dropdown */
.dropdown {
  // Toggle
  &-toggle {
    > .ion-icon {
      font-size: 1.1em;
    }
  }
  // Default
  &-menu {
    &:not(.social-media) {
      .ion-icon {
        display: inline-block;
        width: 2rem;
        margin-left: -0.5rem;
        margin-right: 0.3rem;
        font-size: 1.8rem;
        text-align: center;
        position: relative;
        top: 0.1rem;
      }
      .ion-caret {
        float: right;
        font-size: 1.2rem;
      }
    }
  }
  // Themes
  &-theme {
    margin-top: 0.7rem;
    border: 0.1rem solid rgba($theme-color, 0.1);
    border-radius: $base-radius;
    box-shadow: 0 0.2rem 0.8rem -0.2rem rgba($base-color, 0.3);
    &:before,
    &:after {
      content: '';
      display: block;
      width: 0;
      height: 0;
      border-width: 0.5rem;
      border-style: solid;
      border-color: transparent transparent $white;
      position: absolute;
      bottom: 100%;
      left: 1.5rem;
    }
    &:after {
      border-width: 0.6rem;
      border-color: transparent transparent rgba($theme-color, 0.2);
      left: 1.4rem;
      z-index: -1;
    }
    .ion-icon {
      margin-left: 0;
      top: 0.2rem;
    }
    > li {
      > a {
        padding: 0.7rem 1.5rem;
        font-size: 0.929em;
        &:hover {
          background-color: $theme-color-alt;
          color: $white;
        }
      }
    }
    > .active {
      > a {
        // background-color: $theme-color-alt;
        background-color: transparent;
        color: $theme-color-alt;
        &:hover,
        &:focus {
          background-color: $theme-color-alt;
          color: $white;
        }
      }
    }
    > .logout {
      margin-bottom: -0.5rem;
      > a {
        color: $grey-color2;
        background-color: $grey-color7;
        border-top: 0.1rem solid rgba($base-color, 0.15);
        border-radius: 0 0 $base-radius $base-radius;
      }
    }
  }
  // Position
  &-right {
    left: auto;
    right: 0;
    &:before {
      left: auto;
      right: 1.5rem;
    }
    &:after {
      left: auto;
      right: 1.4rem;
    }
  }
}

/* Page Header */
.page-header {
  margin-top: 0.3em;
  padding-bottom: 0;
  border-bottom: 0;
  font-size: 2.8rem;
  font-weight: 600;
  color: $theme-color;
  //- Responsive ---------------------------
  @media (max-width: 767px) {
    font-size: 2.2rem;
  }
}