body {
  background: #fff;
  color: $base-color;
  font-size: 1.3rem;
  font-family: $base-font;
  overflow-x: hidden;
  &.no-header {
    padding-top: 0;
  }
  @media (min-width: 1024px) and (max-width: 1024px) {
    @if $header-bar {
      padding-top: 4rem + $header-bar-height;
    } @else {
      padding-top: 4rem;
    }
  }
  // @media (min-width: 1025px) {
  //   @if $header-bar {
  //     padding-top: 7.2rem + $header-bar-height;
  //   } @else {
  //     padding-top: 7.2rem;
  //   }
  // }
  &.page-news {
    p {
      font-family: 'Noto Serif SC', serif;
      color: #4a4a4a;
      font-size: 15px;
      line-height: 24px;
    }
  }
}

h1,h2,h3,h5,h6,
.h1,.h2,.h3,.h4,.h5,.h6 {
  font-family: $font-sans;
  line-height: 1.4;
}
h4 {
  // font-family: rubik;
}

a {
  color: $theme-color;
  &:hover {
    color: darken($theme-color, 10%);
  }
  &:focus {
    color: $theme-color;
  }
}

p {
  margin-bottom: ($base-font-size * 0.75);
}

// Typography
.font {
  &-sans {
    font-family: $font-sans;
  }
  &-serif {
    font-family: $font-serif;
  }
  &-opensans {
    font-family: $font-sans-alt;
  }
  &-w200 {
    font-weight: 200;
  }
  &-w300 {
    font-weight: 300;
  }
  &-w400 {
    font-weight: 400;
  }
  &-w500 {
    font-weight: 500;
  }
  &-w600 {
    font-weight: 600;
  }
  &-w700 {
    font-weight: 700;
  }
}
.text {
  &-xxsmall {
    font-size: xx-small;
  }
  &-xsmall {
    font-size: x-small;
  }
  &-small {
    font-size: small;
  }
  &-smaller {
    font-size: smaller;
  }
  &-medium {
    font-size: medium;
  }
  &-larger {
    font-size: larger;
  }
  &-large {
    font-size: large;
  }
  &-xlarge {
    font-size: x-large;
  }
  &-xxlarge {
    font-size: xx-large;
  }
  // Color
  &-theme {
    color: $theme-color;
  }
  &-theme-alt {
    color: $theme-color-alt;
  }
  &-grey {
    color: $grey-text;
  }
  &-lightgrey {
    color: lighten($grey-text, 15%);
  }
  // Weight
  &-bold {
    font-weight: bold;
  }
  // Strike
  &-strike {
    text-decoration: line-through;
  }
  // Single Line / Ellipse
  &-ellipsis {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}
.letter {
  &-s { letter-spacing: 0.08rem; }
  &-s-1 { letter-spacing: 0.1rem; }
  &-s-2 { letter-spacing: 0.2rem; }
}

// Theme
.bg {
  &-theme {
    background-color: $theme-color;
  }
  &-theme-alt {
    background-color: $theme-color-alt;
  }
  &-white {
    background-color: $white;
  }
  &-black {
    background-color: $black;
  }
  &-base {
    background-color: $base-color;
  }
}
.color {
  &-theme {
    color: $theme-color;
  }
  &-theme-alt {
    color: $theme-color-alt;
  }
  &-white {
    color: $white;
  }
  &-black {
    color: $black;
  }
  &-base {
    color: $base-color;
  }
  &-grey {
    color: $grey-text;
  }
  &-dark {
    color: $dark-text;
  }
  &-even {
    color: $even-color;
  }
  &-odd {
    color: $odd-color;
  }
}

// Icon
.ion {
  &-icon {
    line-height: 1;
  }
  &-smaller {
    font-size: 1.2rem;
  }
  &-small {
    font-size: 1.4rem;
  }
  &-medium {
    font-size: 2rem;
  }
  &-large {
    font-size: 3rem;
  }
  &-xlarge {
    font-size: 6rem;
  }
  &-xxlarge {
    font-size: 12rem;
  }
}
.material-icons {
  font-size: 1.714em;
  vertical-align: middle;
  + span {
    vertical-align: middle;
  }
}

// Img
.img {
  &-center {
    img {
      display: block;
      margin-left: auto;
      margin-right: auto;
    }
  }
}

// General
.link {
  &-tabs {
    > li {
      > a {
        text-decoration: underline;
      }
      &.active {
        > a {
          color: $dark-text;
          font-weight: bold;
          text-decoration: none;
          cursor: default;
        }
      }
    }
  }
  &-inline {
    > li {
      > a {
        text-decoration: none;
      }
      &.active {
        text-decoration: underline;
        cursor: default;
      }
    }
  }
}

.more {
  &-link {
    padding: 0.5rem 1.5rem;
    &.default {
      background-color: rgba(0,0,0,0.03);
      border-top: 0.1rem solid rgba(0,0,0,0.08);
    }
    &.clean {
      background-color: transparent;
      border-top-color: rgba(0,0,0,0.1);
    }
    &.theme-alt {
      a {
        color: $theme-color-alt;
      }
    }
    &.absolute {
      position: absolute;
      right: 0;
      top: -4.5rem;
    }
    &.reset {
      padding: 0;
    }
    .ion-icon {
      vertical-align: middle;
    }
  }
  @media (max-width: 767px) {
    &-link {
      &.absolute {
        position: relative;
        right: auto;
        top: -2rem;
      }
    }
  }
}

.tags {
  margin-top: 1.429em;
  body.page-content & {
    margin-top: 0;
  }
  margin-bottom: 1.429em;
  .tag {
    display: inline-block;
    margin-right: 0.571em;
    margin-bottom: 0.429em;
    font-family: $font-sans;
    letter-spacing: 0.043em;
    > a {
      display: block;
      padding: 0.429em 0.857em 0.429em 0.643em;
      background-color: $grey-color6;
      color: inherit;
      border-radius: $base-radius;
      transition: all 200ms ease-in-out;
      &:focus,
      &:hover {
        text-decoration: none;
      }
      &:hover {
        border-color: transparent;
        background-color: mix($theme-color, $grey-color6, 15%);
      }
    }
  }
}

[data-toggle='nav'] {
  &:focus {
    text-decoration: none;
  }
  &:not(.button):not(.btn):hover,
  &:not(.button):not(.btn):focus:hover {
    text-decoration: underline;
  }
}

[data-toggle='expandable'] {
  @include clearfix;
  height: 20rem;
  padding-bottom: 3rem;
  overflow: hidden;
  position: relative;
  &:after {
    content: '';
    display: block;
    width: 100%;
    height: 5rem;
    background: none;
    background: linear-gradient(to bottom, rgba(#fff, 0) 0%, rgba(#fff, 1) 45%, rgba(#fff, 1) 100%);
    position: absolute;
    bottom: 0;
    left: 0;
    .cleanmode & {
      display: none;
    }
  }
  .readmore {
    color: $theme-color-alt;
    font-size: 0.93em;
    font-weight: 600;
    position: absolute;
    bottom: 0;
    left: 0;
    z-index: 1;
    user-select: none;
    .material-icons {
      font-size: 1.6em;
      position: relative;
      top: -0.06em;
    }
    &:hover {
      color: $theme-color;
    }
    &:focus,
    &:hover {
      text-decoration: none;
    }
  }
  &.active {
    height: auto;
    overflow: visible;
    &:after { display: none; }
  }
}

.collapsible {
  &-trigger {
    display: inline-block;
    > i {
      margin-left: 0.357em;
      margin-right: 0.357em;
    }
  }
  &-wrapper {
    padding-top: 1em;
    padding-bottom: 1em;
  }
}

.disclaimer {
  margin-top: 2rem;
  margin-bottom: 2rem;
  color: $grey-text;
  font-size: 1.1rem;
  &.area {
    padding-top: 2rem;
    padding-bottom: 2rem;
    border-top: 0.1rem solid $grey-color5;
    border-bottom: 0.1rem solid $grey-color5;
  }
  // Not Area
  &:not(.area) {
    > .title {
      float: left;
      margin: 0 0.5rem 0 0;
      font-size: inherit;
      font-weight: 600;
      &:after {
        content: ':';
      }
    }
  }
}

.definition {
  &-button {
    &:focus,
    &:hover {
      text-decoration: none;
    }
    &:active {
      color: $black;
    }
  }
}

.country {
  &-selector {
    img { max-width: 100%; height: auto; }
  }
  &-flag {
    margin-bottom: 1.8rem;
    @include clearfix;
    > a {
      display: block;
      float: left;
      width: 4.5rem;
      + a {
        margin-left: 0.5rem;
      }
    }
  }
  //- Responsive ---------------------------
  @media (min-width: 768px) {
    &-flag {
      width: 25%;
      margin-bottom: 0;
      position: absolute;
      top: -5.5rem;
      right: 0;
    }
  }
}

// Widget
.social-media {
  margin: 0;
  padding: 0;
  list-style: none;
  li {
    display: inline-block;
    vertical-align: middle;
    + li {
      margin-left: 0.3rem;
    }
    a {
      display: block;
      width: 3.8rem;
      height: 3.8rem;
      background-color: $theme-color;
      color: $white;
      font-size: 2rem;
      line-height: 3.8rem;
      text-align: center;
      border-radius: $base-radius;
      box-shadow: 0 0.1rem 0 0 rgba($white, 0.1) inset;
      transition: all 300ms ease-in-out;
      svg:not(:root) {
        margin: 0.9rem;
      }
      &:hover {
        color: inherit;
      }
    }
    @each $item in $social-media-lists {
      &.#{nth($item, 1)} {
        a {
          background-color: #{nth($item, 2)};
        }
      }
    }
  }
  // Circle
  &.circle {
    li {
      a {
        border-radius: 100%;
      }
    }
  }
  // Transparent
  &.transparent {
    li {
      + li { margin-left: 0; }
      a {
        background-color: transparent;
        box-shadow: none;
      }
    }
  }
  // Hovered Theme
  &.hovered {
    li {
      a {
        background-color: $grey-color4;
        &:hover {
          background-color: $grey-color2;
          color: $white;
        }
      }
      @each $item in $social-media-hovered {
        &.#{nth($item, 1)} {
          a {
            &:hover {
              background-color: #{nth($item, 2)};
            }
          }
        }
      }
    }
  }
  //- Responsive ---------------------------
  @media (max-width: 479px) {
    li {
      + li {
        margin-left: 0;
      }
    }
  }
}
.social-sharer {
  position: relative;
  .social-button {
    width: 5rem;
    height: 5rem;
    line-height: 5rem;
    text-align: center;
    background-color: $theme-color;
    color: $white;
    border-radius: 50%;
    box-shadow: 0 0.3rem 1.2rem -0.2rem rgba($base-color, 0.5), 0 0.2rem 0.3rem -0.1rem rgba($base-color, 0.5);
    &:hover {
      background-color: $theme-color-alt;
    }
  }
  .social-media {
    display: block;
    float: none;
    min-width: auto;
    background-color: transparent;
    border: 0;
    left: 50%;
    bottom: 100%;
    top: auto;
    z-index: 1;
    opacity: 0;
    visibility: hidden;
    box-shadow: none;
    transform: translateX(-50%);
    transition: all 300ms cubic-bezier(0.65, -0.13, 0.34, 1.15);
    > li {
      display: block;
      margin-bottom: 1rem;
      + li {
        margin-left: 0;
      }
      > a {
        display: block;
        padding: 0;
        &:focus,
        &:hover {
          color: $white;
        }
      }
    }
  }
  // Active
  &.open {
    .social-media {
      visibility: visible;
      opacity: 1;
    }
  }
}
.highcharts {
  &-contextmenu {
    hr {
      margin-top: 0.5rem;
      margin-bottom: 0.5rem;
    }
  }
}

// Embed
.embed {
  // Embed Frame for Youtube iFrame, GMap iFrame and others
  // (height / width) * 100
  &-frame {
    position: relative;
    height: 0;
    overflow: hidden;
    iframe {
      border: 0;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
    &-full {
      padding-bottom: (520 / 1140) * 100%;
    }
  }
}

// Notify [Temp]
.notify {
  &-top {
    .header {
      top: 2.8rem; // total height of notify-top-wrapper
    }
    &-wrapper {
      display: none;
      width: 100%;
      background-color: $secondary-color5;
      color: $base-color;
      font-family: $font-sans-alt;
      font-weight: 600;
      text-align: center;
      line-height: 2.8rem;
      position: fixed;
      top: 0;
      left: 0;
      z-index: 100;
      transform: translate3d(0,0,0);
    }
    // Only appear when body have .notify-top
    .notify-top-wrapper {
      display: block;
    }
  }
  @media (max-width: 1024px) {
    &-top {
      padding-top: 7.8rem; // 5rem + 2.8rem
      .header-nav {
        top: 7.8rem;
        height: calc(100% - 7.8rem);
      }
      &-wrapper {
        font-size: 1.3rem;
      }
    }
  }
  @media (min-width: 1025px) {
    &-top {
      padding-top: 10rem; // 7.2rem + 2.8rem
      .front-wrapper .hero {
        top: 10rem;
        height: calc(100vh - 10rem);
        &.sticky { margin-top: 2.8rem; }
      }
    }
  }
}

// Layout
.content {
  &-top {
    background-color: $content-top-bgcolor;
    position: relative;
  }
  &-area {
    background-color: $content-area-bgcolor;
    position: relative;
  }
  &-bottom {
    border-top: 0.1rem solid $content-bottom-bcolor;
    background-color: $content-bottom-bgcolor;
    position: relative;
  }
  //- Responsive ---------------------------
  @media (max-width: 991px) {
    &-area {
      .sidebar {
        margin-top: 3rem;
      }
    }
  }
}

[v-cloak] {
  display: none !important;
}


.m-b-05 {
  margin-bottom: 5px !important;
}