body.page-redirectcredit {
    background: $footer-bg-bottom;
}
.redirect {
    &-credit {
        margin: 8% auto;
        width: 100%;
        min-width: 300px;
        max-width: 600px;
        text-align: center;
        /* background: #fafafa; */
        font-size: 1.5rem;
        /* padding: 30px; */

    }
}