#homeadvisor {
  .address-wrapper {
    float: left;
    width: 400px; 
    @media screen and (max-width: $break_tablet) {
      width: 100%;
    }
  }
  #results, 
  #nonlanded-results,
  #area-results {
    display: none;
    position: absolute;
    background-color: #f1f1f1;
    min-width: 400px;
    max-width: 400px;
    box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
    z-index: 1;
    @media screen and (min-width: 1024px) {
      top: 42px;
    }
    @media screen and (max-width: $break_tablet) {
      max-width: 100%;
      width: 100%;
      min-width: 100%;
    }
    li {
      color: black;
      padding: 12px 16px;
      text-decoration: none;
      display: block;
      &.list-search {
        list-style-type:none;
        padding:3px;
        margin:5px;
        font-size: 14px;
        &:hover {
          background: $theme-color;
          cursor: pointer;
          color: #fff;
        }
      }
    }
  }

  // #address-landed, 
  // #address-nonlanded{
  //   width: 360px;
  // }

}