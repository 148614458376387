//------------------------------
// Row
//------------------------------
.most_popular_wrapper {
  .image {
    opacity: 1;
    display: block;
    width: 100%;
    height: auto;
    transition: .5s ease;
    backface-visibility: hidden;
  }
  .middle {
    transition: .5s ease;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    text-align: center;
    width: 100%;
    height: 100%;
  }
}

 
.home_st_cnt.most_popular_wrapper {
  .cell {
    .text {
      color: white;
      font-size: 25px;
      padding: 5px;
      border-radius: 5px;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      transition: all .2s ease-in-out;
    }
    &:hover {
      .text {
        background-color: rgba(0, 0, 0, 0.4);
        transition: all .2s ease-in-out;
      }
    }
  }    
}
 
.showcase_wrapper {
  width: 890px;
  margin: 20px auto;
  position: relative;
  margin-left: 19%;
  .blk_header {
    padding-left: 0;
  }
}
.showcase {
  margin-top: 15px;
  .showcell {
    width: 100%;
    height:500px;
    img {
      width: 100%;
    }
  }
}

//showcase ad
.showcase-ad-container {
  // background: #f6f6f6;
  margin: 0 auto;
  position: relative;
  left: -4px;
  top: -4px;
  @media (min-width: 991px) {
    margin-left: 19%;
    width: 890px;
    height: 500px;
    iframe {
      body {
        margin: 0!important;
      }
    }
  }
  @media (max-width: 768px) {
    margin-left: -1%;
    width: 632px;
    height: 355px;
    margin-bottom: 20px;
  }
  @media (max-width: 425px) {
    margin-left: 12.2%;
    width: 300px;
    height: 169px;
  }
  @media (max-width: 375px) {
    margin-left: 22px;
  }
  @media (max-width: 320px) {
    margin-left: -5px;
  }
      
 }
