//------------------------------
// Area outlook & project outlook
//------------------------------

.area-outlook-detail,
.page-project-outlook,
.page-property-detail {
  .glance-box {
    display: none;
    .glance-box-inner {
      padding: 15px;
      background: #222;
      color: #fff;
      span {
        display: block;
        margin-bottom: 20px;

        &:first-child {
          font-size: 5.5rem;
          &:after {
            content: "%";
            color: #eee;
            font-size: 3rem;
            vertical-align: middle;
          }
        }
        &:nth-child(2) {
          font-size: 1.5rem;
          text-transform: capitalize;
          color: #fff;
        }
        &:nth-child(3) {
          font-size: 1.2rem;
          color: #ccc;
        }
        &:nth-child(4) {
          font-size: 1.1rem;
          margin-bottom: 0;
          color: #ccc;
        }
      }

    }
  }
  .outlook-middle-box {
    margin-bottom: 25px;
    .middle-box {
      overflow: hidden;
      background: $theme-chart;
      color: #fff;
      text-align: center;
      span {
        display: block;
        text-align: center;
        margin-bottom: 20px;
        &:first-child {
          font-size: 3rem;
          padding: 25px 15px 15px;
          color: #aaa;

        }
      }
      img {
        &.icon-outlook {
          width: 100%;
          max-width: 60px;
          margin: 30px 0 20px;
          &.listing {
            max-width: 80px;
          }
        }
      }
      @media screen and (max-width: 767px) {
        margin-bottom: 20px;
      }
    }
  }
  .outlook-footnote {
    background: #3e4247;
    width: 100%;
    .outlook-footnote-inner {
      font-size: 1.1rem;
      padding: 20px 0;
      margin: 0 auto;
      color: #fff;
      max-width: 990px;
      width: 100%;
    }
  }
  .boxmax {
    width: 100vw;
    position: relative;
    margin-left: -50vw;
    left: 50%;
  }
  .transaction-datatab {
    margin-top: 20px; 
  }
  .amenities-group-wrap {
    &.narrow-search {
      font-size: 1.3rem;
      margin-top: 20px;
      .group-heading { 
        font-weight: 500;
        margin-bottom: 10px;
      }
    }
  }
  .overview {
    line-height: 2;
    h3 {
      font-size: 1.6rem;
      font-weight: 600;
      strong {
        font-weight: 600;
      }
    }
  }
  h3 {
    margin-top: 0;
    margin-bottom: 1.8rem;
    color: $theme-color;
    font-size: 1.6rem;
    font-weight: 600;
  }
  .project-trend {
    .highchart {
      margin-bottom: 25px;
    }
  }
  span {
    &.disclaimer {
      margin: 20px 0 30px;
      display: block;
    }
  }
}
.page-property-detail,
.area-outlook-detail {
  .transaction-datatab {
    margin-top: 40px;
    @media screen and (min-width: 768px) {
      .transaction-table {
        .transaction-head {
          .transaction-row {
            .transaction-title {
              &.transaction-name {
                width: 21%;
              }
              &.transaction-type {
                width: 30%;
              }
              &.transaction-psf {
                width: 17%;
              }
              &.transaction-price {
                width: 17%;
              }
              &.transaction-filed {
                width: 15%;
              }
            }
          }
        }
        .transaction-body {
          .transaction-region {
            &.transaction-region-1  {
              .transaction-section-1 {
                width: 60%;
                .transaction-type {
                  width: 57%;
                }
              }
              .transaction-section-2 {
                width: 39%;
                .transaction-item {
                  &.transaction-psf {
                    width: 52%;
                  }
                  &.transaction-price {
                    &.area-trx {
                      width: 48%;
                    }
                  }
                }
              }
            }
            &.transaction-region-2 {
              .transaction-section-3 {
                text-align: left;
              }
            }
          }
        }
      }
    }
  }
  .overview {
    h3 {
      margin-top: 30px;
    }
  }
  .outlook-middle-box {
    margin-top: 25px;
  }
}
.area-outlook-detail,
.page-project-outlook,
.page-transaction {
  h1 {
    &.page-header {
      font-size: 2rem;
      margin-bottom: 0;
    }
  }
  .definition {
    text-align: center;
    .definition-button {
      font-size: 1.1rem;
      font-weight: 500;
    }
  }
  .i-table > thead > tr > th {
    background: $theme-chart;
    color: #fff;
    font-weight: 500;
  }
  // .watablecontainer {
  //   tr {
  //     &.sort {
  //       background: $theme-chart;
  //       color: #fff;
  //     }
  //   }
  // }
}

.page-project-outlook {
  span {
    &.disclaimer {
      margin-top: 0;
    }
  }
}

.page-project-outlook,
.page-area-outlook {
  h2 {
    &.page-header {
      display: none;
    }
  }
}
  