$general-font: 'Poppins', sans-serif !important;
.footer-new {
  background-color: #f5f5f5;
  color: #333;
  padding: 50px 0px;
  position: relative;
  z-index: 2;

  ul {
    list-style: none;
    padding: 0px;
    padding-inline-start: 0px;
    font-size: 12px;
    line-height: 22px;
    li{
      line-height: 18px;
    }
  }
  h3 {
      font-size: 14px !important;
      font-weight: 600 !important;
      margin-top: 0px !important;
  }
}
@media (max-width: 767.98px){
  .footer-new {
    padding-bottom: 25%;
  }  
}
@media (max-width: 575.98px){
  .footer-new {
    padding: 40px 40px;
    text-align: center;
  }  
}

.footer-new .intro-new {
    font-size: 11px;
    color: #333;
    p {
      margin-bottom: 2.05rem;
    }
}
.pre-footer-new {
  background-color: #fff;
  padding: 50px 0px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.25);
  position: relative;
  z-index: 2;
  h3 {
    font-size: 14px !important;
    font-weight: 600 !important;
    margin-bottom: 6px !important;
    margin-top: 21px !important;
  }
  ul {
    list-style: none;
    padding: 0px;
    padding-inline-start: 0px;
    font-size: 12px;
    line-height: 22px;
  }
  &-app-info{
    background: rgb(219,219,219);
    background: -moz-linear-gradient(0deg, rgba(219,219,219,1) 0%, rgba(255,255,255,0) 100%);
    background: -webkit-linear-gradient(0deg, rgba(219,219,219,1) 0%, rgba(255,255,255,0) 100%);
    background: linear-gradient(0deg, rgba(219,219,219,1) 0%, rgba(255,255,255,0) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#dbdbdb",endColorstr="#ffffff",GradientType=1);
    @media (min-width: 1280px){
      .container{
        max-width: 1000px;
      }
    }
    margin-bottom: 50px;
    .phone-mock{
      // max-width: 100%;
      width: 500px;
      max-width: none;
      height: auto;

      @media (max-width: 991.98px) { 
        display: none;
      }
    }
    .google-rating{
      display: block;
      margin-left: auto;
      margin-right: auto;
      height: 100px;
    }
    .app-info-left{
      padding: 60px 60px 0 0;

      @media (max-width: 991.98px) {
        padding: 0;
      }
    }
    .most-loved{
      font-family: 'Montserrat', sans-serif;
      font-size: 23px;
      color: #000;
      font-weight: bold;
      margin-top: 80px;
      margin-bottom: 90px;
      line-height: 1.4;
      letter-spacing: 0.8px;
      text-align: center;

      @media (max-width: 991.98px) {
        margin: 20px 0 20px 0;
        font-size: 21px;
      }
    }
    h5{
      font-size: 19px;
      text-align: center;
    }
    .app-desc{
      font-size: 14px;
      text-align: center;

      @media (max-width: 991.98px) {
        font-size: 11px;
      }
    }
    .app-info-inner {
      @media (max-width: 991.98px) {
        margin-bottom: 40px;
      }
      .app-info-icons{
        margin: 12px 0;
        text-align: center;
        a{
          width: 185px;
          display: inline-block;
          margin-right: 10px;
        }
        .mobile-logo{
          height: 48px;

          @media (max-width: 991.98px) {
            margin-bottom: 20px;
          }
        }
      }
    }
  }
}
@media (max-width: 575.98px){
  .pre-footer-new {
    padding: 50px 0px;
    text-align: center;
  }  
}
h3.head-new{
  margin-top: 0px !important;  
}
.pre-footer-new a.showMore-new {
  font-size: 14px;
  font-weight: 600;
  color: #1093fb !important;
  cursor: pointer;
  &:hover{
    text-decoration:none;
  }
}
.log-margin-new {
  margin-bottom: 2.5rem !important;
  padding-left: 16px;
}
.logo-height {
  height: 30px !important;
}
.logo-flag-height {
  height: 45px !important; 
}
.d-inline-block-new {
    display: inline-block !important;
}
.align-top-new {
  vertical-align: top !important;  
}
.image-left-pad {
  padding-right: 16px
}
.copyright-new {
    font-size: 11px;
    color: #333;
    padding-left: 16px;
}
.news-letter-component #mc_embed_signup {
    background: none;
    clear: left;
    font: 14px Helvetica,Arial,sans-serif;
    width: 100%;
}
input#mce-EMAIL.email {
    font-family: "Open Sans","Helvetica Neue",Arial,Helvetica,Verdana,sans-serif;
    font-size: 15px;
    border: 1px solid #ABB0B2;
    border-right: 0px;
    color: #343434;
    background-color: #fff;
    box-sizing: border-box;
    height: 40px;
    padding: 0px 0.4em;
    display: inline-block;
    margin: 0;
    width: 72%;
    vertical-align: top;
    border-top-left-radius: 3px;
    border-bottom-left-radius: 3px;
    @media (max-width: 768px){
      width:100%;
    }
}
#mc_embed_signup {
  .button {
      font-size: 13px;
      border: none;
      border-radius: 3px;
      letter-spacing: .03em;
      color: #fff;
      background-color: #2056AB;
      box-sizing: border-box;
      height: 40px;
      line-height: 32px;
      padding: 0 18px;
      display: inline-block;
      margin: 0;
      transition: all 0.23s ease-in-out 0s;
      @media (max-width: 768px){
        width:100%;
      }
    }
}
#mc_embed_signup label {
    display: block;
    font-size: 14px;
    color: #333333;
    padding-bottom: 10px;
    font-weight: bold;
    /* border-bottom: 1px solid #ddd; */
    width: 87%;
    margin-bottom: 0px;
    font-weight: 600;
    @media (max-width: 768px){
        width:100%;
    }
}
.styleMailChi-new {
    position: absolute;
    left: -5000px;
}
#mc_embed_signup .clear-new {
    display: inline-block;
    position: absolute;
    margin-left: -2px;
    @media (max-width: 768px){
      display:block;
      position: static;
      margin-left: 0px;
    }
}
#mc_embed_signup form {
    padding: 10px 0 10px 0;
}
.container-footer {
  div, h3, p {
    font-family: $general-font;
  }
  width:100% !important;
}
@media (min-width: 576px){
  .container {
    max-width: 540px;
  }
}
@media (min-width: 768px){
  .container {
    max-width: 720px;
  }
}
@media (min-width: 992px){
  .container {
    max-width: 960px;
  }
}
@media (min-width: 1200px){
  .container {
    max-width: 1140px;
  }
}
@media (min-width: 1200px){
  .container {
    max-width: 1280px;
  }
}