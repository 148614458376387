@charset "UTF-8";
 
 #homeadvisor {
    // margin-top: 52px;
    //center logo header
    margin-top: 81px;
    body {
        background-color: $color_white;
        background-position: top center;
        background-repeat: no-repeat;
        overflow-y: scroll;
        font-size: 1rem;
        line-height: 1.5rem;
        font-family: 'Montserrat', sans-serif;
    }

    h1,
    h2,
    h3,
    h4 {
        font-weight: 500
    }

    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
        margin: 0;
        padding: 0;
        -webkit-animation: fader .5s ease-in;
        -o-animation: fader .5s ease-in;
        animation: fader .5s ease-in
    }

    h2,
    h3,
    h4 {
        margin-bottom: calc(1.3380909902vw + 10.72px)
    }

    h1 {
        font-size: calc(1.6057091882vw + 28.86px);
        margin-bottom: calc(1.6057091882vw + 6.86px);
        @media screen and (max-width: $break_pre_tablet) {
            font-size: 2.4rem;
        }
    }


    h2 {
        font-size: calc(1.6057091882vw + 28.86px)
    }


    h3 {
        font-size: calc(1.0704727921vw + 18.57px)
    }

    h4 {
        font-size: calc(1.0704727921vw + 12.57px);
        font-weight: 500
    }


    h5 {
        font-size: calc(.6244424621vw + 11px);
        margin-bottom: calc(.356824264vw + 4.86px);
        font-weight: 400
    }

    h6 {
        font-size: calc(.267618198vw + 12.14px);
        font-weight: 100
    }

    .btn,
    a.non,
    a.non:active,
    a.non:focus,
    a.non:hover {
        text-decoration: none
    }
    .container-fluid.padd-both-sm,
    .container.padd-both-sm,
    .row.padd-both-sm,
    [class^=col-].padd-both-sm {
        padding-top: calc(1.3380909902vw + 10.72px);
        padding-bottom: calc(1.3380909902vw + 10.72px);
    }
     
    .container-fluid.padd-both-md,
    .container.padd-both-md,
    .row.padd-both-md,
    [class^=col-].padd-both-md {
        padding-top: calc(2.2301516503vw + 17.86px);
        padding-bottom: calc(2.2301516503vw + 17.86px);
    }

    .container-fluid.padd-both-lg,
    .container.padd-both-lg,
    .row.padd-both-lg,
    [class^=col-].padd-both-lg {
        padding-top: calc(3.5682426405vw + 28.58px);
        padding-bottom: calc(3.5682426405vw + 28.58px);
    }

    .container-fluid.padd-top-sm,
    .container.padd-top-sm,
    .row.padd-top-sm,
    [class^=col-].padd-top-sm {
        padding-top: calc(1.3380909902vw + 10.72px);
    }

    .container-fluid.padd-top-md,
    .container.padd-top-md,
    .row.padd-top-md,
    [class^=col-].padd-top-md {
        padding-top: calc(2.2301516503vw + 17.86px);
    }

    .container-fluid.padd-top-lg,
    .container.padd-top-lg,
    .row.padd-top-lg,
    [class^=col-].padd-top-lg {
        padding-top: calc(3.5682426405vw + 28.58px);
    }

    .container-fluid.padd-bottom-sm,
    .container.padd-bottom-sm,
    .row.padd-bottom-sm,
    [class^=col-].padd-bottom-sm  {
        padding-bottom: calc(1.3380909902vw + 10.72px);
    }

    .container-fluid.padd-bottom-md,
    .container.padd-bottom-md,
    .row.padd-bottom-md,
    [class^=col-].padd-bottom-md {
        padding-bottom: calc(2.2301516503vw + 17.86px);
    }

    .container-fluid.padd-bottom-lg,
    .container.padd-bottom-lg,
    .row.padd-bottom-lg,
    [class^=col-].padd-bottom-lg  {
        padding-bottom: calc(3.5682426405vw + 28.58px);
    }
  
    a {
        color: $primary-color1;
        cursor: pointer;
        &:active, &:focus, &:hover {
            color: darken( $primary-color1, 6% );
            -moz-transition-property: background-color, border-color;
            -o-transition-property: background-color, border-color;
            -webkit-transition-property: background-color, border-color;
            transition-property: background-color, border-color;
            -moz-transition-timing-function: ease-in-out;
            -o-transition-timing-function: ease-in-out;
            -webkit-transition-timing-function: ease-in-out;
            transition-timing-function: ease-in-out;
            -moz-transition-duration: .2s;
            -o-transition-duration: .2s;
            -webkit-transition-duration: .2s;
            transition-duration: .2s;
        }
        &:focus {
           outline: 0; 
           &.key-focus {
                outline-offset: -1px;
                outline: #bce9fe solid 1px!important;
                border-color: #bce9fe;
                box-shadow: 0 0 10px #bce9fe;
           }
        }
        &.link {
            color: $primary-color1;
            text-decoration: underline;
            &.white {
                color: $color_white;
            }
            &.blue {
                color: $primary-color1;
            }
        } 
        // &.arrow {
        //     position: relative;
        //     padding-right: 5px;
        //     &:after {
        //         font-family: anz-icons!important;
        //         font-style: normal!important;
        //         font-weight: 400!important;
        //         font-variant: normal!important;
        //         text-transform: none!important;
        //         speak: none;
        //         line-height: 1;
        //         content: "\47";
        //         position: absolute;
        //         font-size: calc(.178412132vw + 19.43px);
        //         -webkit-transform: translateZ(0);
        //         -moz-transform: translateZ(0);
        //         -ms-transform: translateZ(0);
        //         -o-transform: translateZ(0);
        //         transform: translateZ(0);
        //         -moz-transition-duration: .1s;
        //         -o-transition-duration: .1s;
        //         -webkit-transition-duration: .1s;
        //         transition-duration: .1s;
        //         -moz-transition-property: transform;
        //         -o-transition-property: transform;
        //         -webkit-transition-property: transform;
        //         transition-property: transform;
        //         -moz-transition-timing-function: ease-out;
        //         -o-transition-timing-function: ease-out;
        //         -webkit-transition-timing-function: ease-out;
        //         transition-timing-function: ease-out;
        //     }
        //     &:active,
        //     &:focus,
        //     &:hover {
        //         &:after {
        //             -webkit-transform: translateX(2px);
        //             -moz-transform: translateX(2px);
        //             -ms-transform: translateX(2px);
        //             -o-transform: translateX(2px);
        //             transform: translateX(2px);
        //         }
        //     }
        // }
    }
     
    nav .branding .title,
    nav .nav-list a {
        text-transform: uppercase
    }
     

    .page .home .cont-four ul,
    .page .home .cont-four ul li,
    .page .home .section-intro ul,
    .page .home .section-intro ul li,
    .page .home .cont-seven ul,
    .page .home .cont-seven ul li,
    nav ol.nav-list,
    nav ol.nav-list li,
    nav ul.nav-list,
    nav ul.nav-list li,
    nav.sub ol.navbar-nav,
    nav.sub ol.navbar-nav li,
    nav.sub ul.navbar-nav,
    nav.sub ul.navbar-nav li,
    ol.nostyle,
    ol.nostyle li,
    ul.nostyle,
    ul.nostyle li {
        list-style-type: none;
        margin: 0;
        padding: 0
    }

    article,
    section {
        border-top: 6px solid $color_white;
        z-index: 10
    }

    article {
        color: $color_white;
        overflow: hidden
    }

    section {
        background-color: $color_white
    }
     
    .page {
        height: 100%;
        .home {
            h4 {
                color: $primary-color1;
            }
            .photo-one {
                height: 350px;
                background-color: transparent;
            }
            .section-intro {
                color: #666;
                background-color: $color_white;
                .report-point {
                    margin: -1px 10px 0 0;
                    font-size: 1.1rem;
                    color: $primary-color1;
                }
                ul {
                    margin-bottom: 20px;
                    li {
                        display: -webkit-box;
                        display: -webkit-flex;
                        display: -moz-flex;
                        display: -ms-flexbox;
                        display: flex;
                        -webkit-box-align: center;
                        -ms-flex-align: center;
                        -webkit-align-items: center;
                        -moz-align-items: center;
                        align-items: center;
                        padding: 10px 0;
                        font-weight: 400;
                        .icon {
                            color: $primary-color1;
                            font-size: 180%;
                            margin: 0 15px;
                        }
                    }
                }
            }
            .section-general {
                color: #666;
                background-color: #f5f5f5;
                z-index: 1;
                .sectbox {
                    -moz-transition: opacity .22s ease-in-out;
                    -o-transition: opacity .22s ease-in-out;
                    -webkit-transition: opacity .22s ease-in-out;
                    transition: opacity .22s ease-in-out;
                    .sect-block {
                        position: relative;
                        background-color: $color_white;
                        height: calc(.8920606601vw + 387.15px);
                        padding-top: calc(2.6761819804vw + 11.44px);
                        padding-bottom: calc(2.6761819804vw + 11.44px);
                        padding-left: calc(1.7841213202vw + 4.29px);
                        padding-right: calc(1.7841213202vw + 4.29px);
                        -webkit-border-radius: 10px;
                        -moz-border-radius: 10px;
                        border-radius: 10px;
                        -webkit-box-shadow: 1px 1px 12px 4px rgba(0, 0, 0, .1);
                        -moz-box-shadow: 1px 1px 12px 4px rgba(0, 0, 0, .1);
                        box-shadow: 1px 1px 12px 4px rgba(0, 0, 0, .1);
                        -moz-transition: all .22s ease-in-out;
                        -o-transition: all .22s ease-in-out;
                        -webkit-transition: all .22s ease-in-out;
                        transition: all .22s ease-in-out;
                        -webkit-opacity: 1;
                        -moz-opacity: 1;
                        opacity: 1;
                        filter: alpha(opacity=100);
                        margin-bottom: 30px;
                        .icon {
                            color: $primary-color1;
                            font-size: calc(.7136485281vw + 21.72px);
                            margin-bottom: calc(.4460303301vw + 13.57px);
                        }
                        h4 {
                            font-size: calc(.4460303301vw + 14.57px);
                            span {
                                display: block; 
                            }
                        }
                        .btn {
                            position: absolute;
                            bottom: calc(2.6761819804vw + 11.44px);
                            left: 50%;
                            margin-left: -90px;
                            min-width: 180px;
                        }
                        &.hover {
                            -webkit-transform: scale(1.03);
                            -moz-transform: scale(1.03);
                            -ms-transform: scale(1.03);
                            -o-transform: scale(1.03);
                            transform: scale(1.03);
                            &.opacity {
                                -webkit-opacity: 1;
                                -moz-opacity: 1;
                                opacity: 1;
                                filter: alpha(opacity=100);
                            }
                        }
                        &.opacity {
                            -webkit-opacity: .5;
                            -moz-opacity: .5;
                            opacity: .5;
                            filter: alpha(opacity=50);
                        }
                    }
                }
                &.bg-white {
                    background: #fff;
                }
            }
        }
 
        article {
            &.home {
                color: $color_white;
                border-top: 0;
                margin-bottom: 30px;
                [class^=col-] {
                    height: 195px;
                    padding: 0;
                    >.row {
                        margin: 0;
                    }
                    >a {
                        &:not(.btn) {
                            position: relative;
                            display: block;
                            width: 100%;
                            height: 100%;
                            padding: 0;
                            color: $color_white;
                            text-decoration: none;
                            overflow: hidden;
                            z-index: 1;
                            &:hover {
                                .menu-image {
                                    -webkit-transform: scale(1.04);
                                    -moz-transform: scale(1.04);
                                    -ms-transform: scale(1.04);
                                    -o-transform: scale(1.04);
                                    transform: scale(1.04);
                                }
                            }
                        }
                    }
                    .menu-bg {
                        position: absolute;
                        top: 0;
                        left: 0;
                        width: 100%;
                        height: 100%;
                        background-color: $primary_homeadvisor_hero_bg;
                        z-index: 2;
                        -webkit-opacity: 0;
                        -moz-opacity: 0;
                        opacity: 0;
                        filter: alpha(opacity=0);
                        -moz-transition: all .3s ease-in-out;
                        -o-transition: all .3s ease-in-out;
                        -webkit-transition: all .3s ease-in-out;
                        transition: all .3s ease-in-out;
                    }
                    .menu-image {
                        position: relative;
                        width: 100%;
                        height: 100%;
                        -webkit-transform: scale(1);
                        -moz-transform: scale(1);
                        -ms-transform: scale(1);
                        -o-transform: scale(1);
                        transform: scale(1);
                        -moz-transition: all .3s ease-in-out;
                        -o-transition: all .3s ease-in-out;
                        -webkit-transition: all .3s ease-in-out;
                        transition: all .3s ease-in-out;
                        overflow: hidden;
                        z-index: 1;
                    }
                    .menu-text {
                        position: absolute;
                        left: 15px;
                        bottom: 0;
                        width: 80%;
                        color: $color_white;
                        padding-bottom: 30px;
                        -moz-transition: all .3s ease-in-out;
                        -o-transition: all .3s ease-in-out;
                        -webkit-transition: all .3s ease-in-out;
                        transition: all .3s ease-in-out;
                        z-index: 2;
                        @media screen and (max-width: $break_m_lg) {
                            // top: 20px;
                        }
                        h1 {
                            color: $color_white;
                            line-height: 1.1;
                        }
                        h5 {
                            display: none;
                            margin-bottom: calc(1.3380909902vw + 5.72px);
                        }
                        h4 {
                            color: $color_white; 
                            margin-bottom: calc(.4460303301vw + 25px);
                            text-shadow: 0 0 8px rgba(0, 0, 0, .3);
                            line-height: 1.1;
                            @media screen and (max-width: $break_m_lg) {
                                margin-bottom: calc(.4460303301vw + 40px);  
                            }
                            @media screen and (max-width: 991px) {
                                margin-bottom: calc(.4460303301vw + 52px);  
                            }
                        }
                        h6 {
                            position: absolute;
                            -moz-transition: all .3s ease-in-out;
                            -o-transition: all .3s ease-in-out;
                            -webkit-transition: all .3s ease-in-out;
                            transition: all .3s ease-in-out;
                            @media screen and (max-width: $break_m_lg) { 
                                bottom: 20px;
                            }
                        }
                        h6.icon-angle-right {
                            &:before {
                                position: absolute;
                                top: 2px;
                                right: -20px;
                                bottom: auto;
                                left: auto;
                            }
                        }
                    }
                }
                .first {
                    height: 220px;
                    .menu-text {
                        left: 15px;
                    }
                }
                &.complete {
                    .menu-bg {
                        -webkit-opacity: .5;
                        -moz-opacity: .5;
                        opacity: .5;
                        filter: alpha(opacity=50);
                    }
                }
                .box-1 {
                    background-color: #a5a9ac;
                    .menu-image {
                        background-image: url("https://media.edgeprop.my/page/home-advisor/ha-main-1.jpg");
                        background-repeat: no-repeat;
                        background-size: cover;
                        background-position: center;
                    }
                    h1 {
                        span {
                            &.main-1-sub {
                                display: block;
                                font-size: 3rem;
                                margin-top: 15px;
                                @media screen and (max-width: $break_pre_tablet) {
                                    font-size: 1.4rem;

                                }
                            }
                        }
                    }
                }
                .box-2 {
                    background-color: #bdb1b1;
                    .menu-image {
                        background-image: url("https://media.edgeprop.my/page/home-advisor/ha-main-2.jpg");
                        background-repeat: no-repeat;
                        background-size: cover;
                        background-position: center;
                    }
                }
                // .ser {
                //     background-color: #cccad5;
                //     .menu-image {
                //         background-image: url(https://media.gettyimages.com/photos/couple-eating-sushi-together-in-new-home-picture-id175139695);
                //         background-repeat: no-repeat;
                //         background-size: cover;
                //         background-position: center;
                //     }
                // }
                .box-5 {
                    background-color: #c1e672;
                    .menu-image {
                        background-image: url("https://media.edgeprop.my/page/home-advisor/ha-main-5.jpg");
                        background-repeat: no-repeat;
                        background-size: cover;
                        background-position: center;
                    }
                }
                .box-3 {
                    background-color: #ddd;
                    .menu-image {
                        background-image: url("https://media.edgeprop.my/page/home-advisor/ha-main-3.jpg");
                        background-repeat: no-repeat;
                        background-size: cover;
                        background-position: center;
                    }
                }
                .box-6 {
                    background-color: $primary-color1;
                    .menu-image {
                        background-image: url("https://media.edgeprop.my/page/home-advisor/ha-main-6.jpg");
                        background-repeat: no-repeat;
                        background-size: cover;
                        background-position: center;
                    }
                }
                .box-4 {
                    background-color: #cbd4d1;
                    h5 {
                        display: block!important;
                    }
                    .menu-image {
                        background-image: url("https://media.edgeprop.my/page/home-advisor/ha-main-4.jpg");
                        background-size: cover;
                        background-position: center;
                        background-repeat: no-repeat;
                    }
                    &.first {
                        height: 320px;
                    }
                }
            }
            &.social {
                background-color: $primary-color1;
                color: $color_white;
                border-top: 0;
                margin-top: 60px;
                .container {
                    position: relative;
                    z-index: 3;
                    h5 {
                        position: relative;
                        display: inline-block;
                        font-size: calc(.5352363961vw + 16.29px);
                        margin: 0;
                        font-weight: 100;
                    }
                    h6 {
                        position: relative;
                        display: inline-block;
                    }
                    h5.icon-angle-right {
                        &:before {
                            position: absolute;
                            top: 2px;
                            right: -20px;
                            bottom: auto;
                            left: auto;
                        }
                    }
                    h6.icon-angle-right {
                        &:before {
                            position: absolute;
                            top: 2px;
                            right: -20px;
                            bottom: auto;
                            left: auto;
                        }
                    }
                    .copy {
                        margin-top: calc(7.136485281vw + 37.16px);
                        margin-bottom: calc(1.7841213202vw + 54.29px);
                        -webkit-animation: fader .8s forwards;
                        -o-animation: fader .8s forwards;
                        animation: fader .8s forwards;
                        opacity: 0;
                        -moz-transition: opacity .3s ease-in-out;
                        -o-transition: opacity .3s ease-in-out;
                        -webkit-transition: opacity .3s ease-in-out;
                        transition: opacity .3s ease-in-out;
                        &:nth-of-type(1) {
                            -webkit-animation-delay: .8s;
                            animation-delay: .8s;
                        }
                        &:nth-of-type(2) {
                            -webkit-animation-delay: 1.6s;
                            animation-delay: 1.6s;
                        }
                        &:nth-of-type(3) {
                            -webkit-animation-delay: 2.4s;
                            animation-delay: 2.4s;
                        }
                        &:nth-of-type(4) {
                            -webkit-animation-delay: 3.2s;
                            animation-delay: 3.2s;
                        }
                        &:nth-of-type(5) {
                            -webkit-animation-delay: 4s;
                            animation-delay: 4s;
                        }
                        &:nth-of-type(6) {
                            -webkit-animation-delay: 4.8s;
                            animation-delay: 4.8s;
                        }
                        &:nth-of-type(7) {
                            -webkit-animation-delay: 5.6s;
                            animation-delay: 5.6s;
                        }
                        &:nth-of-type(8) {
                            -webkit-animation-delay: 6.4s;
                            animation-delay: 6.4s;
                        }
                        &:nth-of-type(9) {
                            -webkit-animation-delay: 7.2s;
                            animation-delay: 7.2s;
                        }
                        &:nth-of-type(10) {
                            -webkit-animation-delay: 8s;
                            animation-delay: 8s;
                        }
                    }
                    .copy.delay-1 {
                        -webkit-animation-delay: .8s;
                        animation-delay: .8s;
                    }
                    .copy.delay-2 {
                        -webkit-animation-delay: 1.6s;
                        animation-delay: 1.6s;
                    }
                    .copy.delay-3 {
                        -webkit-animation-delay: 2.4s;
                        animation-delay: 2.4s;
                    }
                    .copy.delay-4 {
                        -webkit-animation-delay: 3.2s;
                        animation-delay: 3.2s;
                    }
                    .copy.delay-5 {
                        -webkit-animation-delay: 4s;
                        animation-delay: 4s;
                    }
                    .copy.delay-6 {
                        -webkit-animation-delay: 4.8s;
                        animation-delay: 4.8s;
                    }
                    .copy.delay-7 {
                        -webkit-animation-delay: 5.6s;
                        animation-delay: 5.6s;
                    }
                    .copy.delay-8 {
                        -webkit-animation-delay: 6.4s;
                        animation-delay: 6.4s;
                    }
                    .copy.delay-9 {
                        -webkit-animation-delay: 7.2s;
                        animation-delay: 7.2s;
                    }
                    .copy.delay-10 {
                        -webkit-animation-delay: 8s;
                        animation-delay: 8s;
                    }
                }
                .tile {
                    height: calc(4.4603033006vw + 185.73px);
                    padding: 0;
                    >a {
                        overflow: hidden;
                        position: relative;
                        display: block;
                        width: 100%;
                        height: 100%;
                        padding: 0;
                        color: $color_white;
                        text-decoration: none;
                        &:hover {
                            text-decoration: none;
                            border: 0;
                            .menu-image {
                                -webkit-transform: scale(1.04);
                                -moz-transform: scale(1.04);
                                -ms-transform: scale(1.04);
                                -o-transform: scale(1.04);
                                transform: scale(1.04);
                            }
                        }
                    }
                    .menu-bg {
                        width: 100%;
                        -moz-transition: all .3s ease-in-out;
                        -o-transition: all .3s ease-in-out;
                        -webkit-transition: all .3s ease-in-out;
                        position: absolute;
                        top: 0;
                        right: auto;
                        bottom: auto;
                        left: 0;
                        height: 100%;
                        // background-color: $primary-color1;
                        z-index: 2;
                        -webkit-opacity: .3;
                        -moz-opacity: .3;
                        opacity: .3;
                        filter: alpha(opacity=30);
                        transition: all .3s ease-in-out;
                    }
                    .menu-image {
                        width: 100%;
                        -moz-transition: all .3s ease-in-out;
                        -o-transition: all .3s ease-in-out;
                        -webkit-transition: all .3s ease-in-out;
                        position: relative;
                        height: 100%;
                        -webkit-transform: scale(1);
                        -moz-transform: scale(1);
                        -ms-transform: scale(1);
                        -o-transform: scale(1);
                        transform: scale(1);
                        transition: all .3s ease-in-out;
                        overflow: hidden;
                        z-index: 1;
                    }
                    .menu-text {
                        -moz-transition: all .3s ease-in-out;
                        -o-transition: all .3s ease-in-out;
                        -webkit-transition: all .3s ease-in-out;
                        position: absolute;
                        top: auto;
                        right: auto;
                        bottom: 0;
                        left: 15px;
                        width: 60%;
                        color: $color_white;
                        padding-bottom: 30px;
                        transition: all .3s ease-in-out;
                        z-index: 2;
                        h6 {
                            -moz-transition: all .3s ease-in-out;
                            -o-transition: all .3s ease-in-out;
                            -webkit-transition: all .3s ease-in-out;
                            position: absolute;
                            margin: 0;
                            font-weight: 100;
                            transition: all .3s ease-in-out;
                        }
                        h1 {
                            color: $color_white;
                        }
                        h4 {
                            color: $color_white;
                            font-weight: 400;
                        }
                    }
                }
            }
        }
    }
     
    .container_overlay {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        background-color: rgba(3, 127, 184, .5);
        z-index: 2;
        &.article {
            background-color: rgba(0, 65, 101, .2);
        }
    }
     

    .ie8, .ie9 {
        .anmtn {
            -webkit-opacity: 1!important;
            -moz-opacity: 1!important;
            opacity: 1!important;
            filter: alpha(opacity=100)!important;
        }  
        nav {
            &.sub {
                a {
                    &:before {
                        display: none!important;
                    }
                }
            }
        }
    }
     

    .ie10 .page .home ol strong,
    .ie10 .page .home ul strong,
    .ie10 .paragraph strong,
    .ie10 p strong,
    .page .home .ie10 ol strong,
    .page .home .ie10 ul strong {
        letter-spacing: 1.2px;
        letter-spacing: .06em
    }

    .no-js .anmtn {
        -webkit-opacity: 1!important;
        -moz-opacity: 1!important;
        opacity: 1!important;
        filter: alpha(opacity=100)!important
    }
    .twolinks {
        span {
            display: block; 
            font-size: 1.4rem;
            &:first-child {
                margin-bottom: 10px;
            }
            a {
                color: #fff;
                &:hover {
                    color: inherit;
                }
            }
        }
    }
    @media screen and (min-width: 1200px) {
        .container-homeadvisor {
            width: 1170px;
        }
    }

 }