body {
    #intercom-container, 
    .intercom-lightweight-app {
        display: none !important;
    }
    &.page-airsupply,
    &.page-awards2020,
    &.page-awards2021,
    &.page-airsupplycontest,
    &.page-awards2020entry,
    &.page-awards2021entry,
    &.page-awards2021,
    &.page-vpex2021,
    &.page-ewf,
    &.page-node-42,
    // Strata-living survey
    &.page-node-87,
    &.page--stratalivingsurvey,
    //peps prod
    &.page-node-43,
    //peps staging
    &.page-node-37,
    // cny prod
    &.page-cny2020, &.page-node-45,
    //lendlease staging
    &.page-node-65,
    // live!2019 & 2020
    &.page-node-44, &.page-node-46,
    //ecoworld related
    &.page--townshipguidebook,
    &.page-content-ecoworld,
    &.page-content-1902490 {
        .intercom-app,
        .sto-ads,
        .sto-big-animation,
        .catfish-ads,
        .banderole {
            display: none !important;
        }
    }
    // &.page-vpex2021 {
    //     .intercom-app, 
    //     .sto-big-animation,
    //     .catfish-ads,
    //     .banderole {
    //         display: none !important;
    //     }

    // }
}
#app-banner {
    @media (min-width: 577px) and (max-width: 767px) {
        display: none !important;
    }
}