/* ---------------------------------------- */
/*  Custom:General
/* ---------------------------------------- */

/* Block */
.block {
  &-newsletter-signup {
    margin: 3em 0;
    .block-content {
      padding: 2em;
      background: linear-gradient(to right, $primary-color1, $primary-color2);
      color: $white;
      border: 0.1rem solid transparent;
      transition: all 200ms ease-in-out;
    }
    .i-row {
      display: block;
      + .i-row {
        display: none;
      }
    }
    .nls-title {
      margin-top: 1.6rem;
      margin-bottom: 0.4rem;
      line-height: 2.5rem;
      position: relative;
      small {
        margin-bottom: 0.35em;
        color: $secondary-color3;
        position: absolute;
        left: 0;
        bottom: 100%;
      }
    }
    &.active {
      .block-content {
        background: $grey-color6;
        color: $base-color;
        border-color: $grey-color5;
      }
      .i-row {
        display: none;
        + .i-row {
          display: block;
        }
      }
    }
  }
  &-mortgage-calculator {
    padding: 2rem;
    border: 0.1rem solid $border-color;
    background-color: $white;
    .i-form {
      hr { margin: 1.5rem 0; }
      .input {
        border-color: $border-color;
        input {
          font-weight: 600;
          color: $theme-color;
        }
        label {
          &:before,
          &:after {
            font-weight: 600;
            color: $theme-color;
          }
        }
        &.deposit {
          > label {
            &:before {
              content: "RM\a" attr(data-compute);
              float: right;
            }
            &:after {
              content: attr(data-prefix) "\a(" attr(data-num) attr(data-suffix)"):";
              float: none;
            }
          }
        }
        &:last-child {
          margin-bottom: 0;
        }
      }
    }
    .i-segment {
      padding: 2rem;
      background-color: $theme-color;
      color: $white;
      border-radius: 0;
      box-shadow: none;
      > .body {
        padding-top: 6rem;
        padding-bottom: 6rem;
        border: 0.2rem solid $theme-color-alt;
        border-radius: 0.2rem;
        h2 {
          line-height: 1;
          span {
            display: inline-block;
            vertical-align: middle;
            color: inherit;
            + span {
              margin-left: 0.8rem;
            }
          }
        }
      }
    }
    .flex-center {
      display: flex;
      align-items: center;
    }
  }
  &-train-map {
    $spos: 45%;
    width: 100%;
    height: 100%;
    margin-top: 0 !important;
    padding: 4rem;
    background-color: $white;
    opacity: 0;
    visibility: hidden;
    overflow-x: auto;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 100;
    transition: all 200ms ease-in-out;
    .block {
      &-title {
        font-size: 2.8rem;
        text-align: center;
      }
    }
    .tm-line {
      &-row {
        display: flex;
        max-height: 290rem;
        flex-direction: columns;
        flex-flow: column wrap;
        align-content: left;
        align-items: left;
        justify-content: flex-start;
      }
      &-col {
        width: 33.333%;
        margin-bottom: 3rem;
      }
    }
    .tm-station {
      padding-top: 0.4rem;
      padding-bottom: 0.4rem;
      clear: both;
      &-prefix {
        float: left;
        width: $spos;
        padding-right: 1rem;
        position: relative;
      }
      &-label {
        min-height: auto;
        margin: 0 0 0 $spos;
        padding-top: 0.2rem;
        padding-bottom: 0.2rem;
        line-height: 1;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        label {
          line-height: 1.6;
          &:before {
            box-shadow: 0 0.2rem 0.5rem -0.2rem rgba($base-color, 0.12), 0 -0.1rem 0.2rem 0 rgba($base-color, 0.08) inset;
          }
        }
        input {
          &:checked {
            + label {
              &:before {
                background-color: transparent;
                border-color: $input-border-color;
              }
              &:after {
                color: $theme-color;
              }
            }
          }
          &[type="checkbox"] {
            &:checked {
              + label {
                &:after {
                  top: 0.1rem;
                }
              }
            }
          }
        }
      }
    }
    .tm-actions {
      width: 100%;
      padding: 1rem;
      background-color: rgba($white, 0.9);
      text-align: center;
      position: fixed;
      left: 0;
      bottom: 0;
      box-shadow: 0 -0.6rem 1rem -0.2rem rgba($base-color, 0.05), 0 -0.1rem 0 0 rgba($base-color, 0.1);
    }
    .station {
      &-linkage {
        display: block;
        border-left: 0.2rem solid $theme-color;
        border-right: 0.2rem solid $theme-color;
        position: absolute;
        top: -1.5rem;
        bottom: -1rem;
        right: 3.2rem;
        z-index: -1;
      }
      &-link {
        display: block;
        float: right;
        font-size: 0;
        line-height: 1;
        text-align: right;
        white-space: nowrap;
      }
      &-code {
        display: inline-block;
        min-width: 5rem;
        padding: 0.5rem;
        border-top: 0.1rem solid $white;
        border-bottom: 0.1rem solid $white;
        background-color: $theme-color;
        color: $white;
        font-size: small;
        line-height: 1.2rem;
        text-align: center;
        text-transform: uppercase;
        &:first-child {
          border-top-left-radius: 5rem;
          border-bottom-left-radius: 5rem;
        }
        &:last-child {
          border-top-right-radius: 5rem;
          border-bottom-right-radius: 5rem;
        }
      }
    }
    // Header
    .tm-header {
      clear: both;
      h4 {
        max-width: 80%;
        margin-top: 0;
        margin-left: 10%;
        margin-bottom: 0.3em;
        padding: 0.5em 1em;
        background-color: $theme-color;
        color: $white;
        font-size: 1.5rem;
        font-weight: 600;
        text-align: center;
        border-radius: 5rem;
        > small {
          color: rgba($white, 0.5);
        }
      }
      .tm-station-label {
        label { font-weight: 700; }
      }
    }
    // Tweak
    .tm-lines > .tm-station {
      &:last-child {
        .station-linkage {
          bottom: 0;
        }
      }
    }
    &.active {
      opacity: 1;
      visibility: visible;
    }
    // Color Theme
    $lines: (
      'sb': '#1e4d2b',
      'kj': '#e0115f',
      'ag': '#fe8e10',
      'sp': '#721422',
      'mr': '#7dba00',
      'rkf': '#ff5722',
      'sbk': '#008000',
      'ke': '#800080',
      'kt': '#139593',
      'ka': '#dc241f',
      'kd': '#dc241f',
      'kb': '#1964b7',
      'kc': '#1964b7',
    );
    @each $i, $line in $lines {
      .tm-line-#{$i} {
        // .tm-station-label.checkbox input:checked + label:after { color: #{$line}; }
        // .tm-station-label.checkbox input:checked + label:before,
        .tm-station-label.radio input:checked + label:before,
        .station-linkage { border-color: #{$line}; }
        .tm-header h4,
        .tm-station-label.radio input:checked + label:after { background-color: #{$line}; }
      }
    }
    @each $i, $line in $lines {
      .tm-lines .color-#{$i} { background-color: #{$line}; }
    }
  }
  &-train-map-sg {    
    .block {
      &-title {
        font-size: 2.8rem;
        text-align: center;
      }
      &-content {
        display: flex;
        flex-flow: row nowrap;
        height: calc(100vh - 72px);
        //tm-line theme color
        $lines: (
          'sb': '#1e4d2b',
          'kj': '#e0115f',
          'ag': '#fe8e10',
          'sp': '#721422',
          'mr': '#7dba00',
          'rkf': '#ff5722',
          'sbk': '#008000',
          'ke': '#800080',
          'kt': '#139593',
          'ka': '#dc241f',
          'kd': '#dc241f',
          'kb': '#1964b7',
          'kc': '#1964b7',
        );
        @each $i, $line in $lines {
          .trainmap-form .selection .checkbox.line input:checked + label.tm-line-#{$i},
          .tm-line-content .tm-line-row .tm-line-col .tm-lines .tm-header h5.tm-line-#{$i} {
            background-color: #{$line};
          }
          .tm-header i.color-#{$i}, 
          .tm-line-content .tm-line-row .tm-line-col .tm-lines .tm-station.color-#{$i}:before,
          .station-code.color-#{$i} {
            color: #{$line};
          }
          .tm-line-content .tm-line-row .tm-line-col .tm-lines .tm-station.color-#{$i} {
            border-top-color: #{$line};
          }          
        }
      .trainmap-form {
        background-color: #f4f7fa;
        flex: 0 0 30%;
        padding: 3rem 1.5rem;
        min-width: 250px;
        max-width: 250px;
        .dropdown {
          margin-bottom: 10px;
          .button {
            width: 100%;
            text-align: left;
            padding: 5px 10px;
            i{
              position:absolute;
              right: 10px;
              bottom: 8px;
            }
            &-trainmap{
              background-color: white;
              border: 1px solid #979797;
              &:active{
                box-shadow: none;
              }
            }
          }
          ul.dropdown-menu {
            border-radius: 0;
            box-shadow: 5px 5px 12px 2px rgba(0,0,0,.5);
            margin: 0 10px;
            right: 0;
            left: 0;
            & > li{
              padding: 5px 10px;
              &:hover {
                background-color: #f5f5f5;
              }
            }
          }
        }
        hr {
          margin-left: -15px;
          margin-right: -15px;
        }
        .selection{
          .checkbox {
            display: inline-block;
            label {
              background-color:#eee;
              padding: 2px 12px;
              border-radius: 20px;
              &:before{
                display: none;
              }
            }
            input:checked + label {
              background-color: rgb(69, 90, 100);
              color: white;
              &:before, &:after {
                display:none;
              }
            }        
          }
        }
      }
      .tm-line-content {
          flex: 1 1 70%;
          display: flex;
          flex-flow: column nowrap;
          box-shadow: inset 20px 0px 20px -20px grey;
          overflow: hidden;
          .title {
            padding: 30px 0 10px 40px;
            font-size: 16px;
          }
          .tm-line-row {
            overflow-y: scroll;          
            overflow-x: hidden;
            width: calc(100% + 20px);
            height: 100%;
            .tm-line-empty{
              height: 100%;
              display: none;              
              flex-flow: column nowrap;
              justify-content: center;
              img.img-responsive {
                max-width: 42%;
                margin: 0 auto;
              }
              &.active{
                display: flex;
              }
            }
            .tm-line-col {
              padding-left: 4rem;
              display: none;
              &:last-child {
                box-shadow: inset 0px -20px 20px -20px grey;
              }
              .tm-lines {
                display: flex;
                flex-flow: row wrap;
                justify-content: flex-start;
                .tm-header {
                  flex-basis: 100%;
                  padding-bottom: 5px;
                  i {
                    font-size: 18px;
                    padding-right: 8px;
                    vertical-align: text-bottom;
                  }
                  h5 {
                    background-color: rgb(69, 90, 100);
                    width: fit-content;
                    color: white;
                    padding: 2px 25px;
                    font-weight: 400;
                    border-radius: 20px;
                    display: inline-block;
                  }
                  .tm-station-label {
                    display: inline-block;
                    input[type="checkbox"] {
                      display: none;
                    }
                    &.checkbox{
                      position: relative;
                      label{
                        padding-left: 35px;
                      }
                      label:before {
                        content:"";
                        border: 1px solid #bfbebe;
                        background-color: white;
                        width: 18px;
                        height: 18px;
                        display: block;
                        position: absolute;
                        left: 0;
                        margin: 1px 8px;
                        border-radius:  5px;
                      }
                      input:checked + label:after {
                        content:"";
                        background-color: rgb(255, 81, 34);
                        padding: 2px;
                        border: 2px solid white;
                        position: absolute;
                        left: 0;
                        top: 0;
                        border-radius:5px;
                        width: 16px;
                        height: 16px;
                        margin: 2px 9px;
                      }
                    }                  
                  }
                }
                .tm-station {
                  border-top: 2px solid orange;
                  position: relative;
                  flex: 1 1 15%;
                  min-width: 150px;
                  max-width: 150px;
                  &:before {
                    font-family: FontAwesome;
                    content:"\f111";
                    color: orange;
                    position: absolute;
                    top: -10px;
                    text-shadow: -1px 0 #fff, 0 1px #fff, 1px 0 #fff, 0 -1px #fff;
                  }                 
                  &:last-child {
                    border-top: 2px solid transparent;
                  }
                  .station-code{
                    padding-right: 4px;
                    font-weight: 600;
                  }
                  .tm-station-label {
                    padding: 5px 0;
                    input[type="checkbox"] {
                      display: none;
                    }
                    &.checkbox{
                      position: relative;
                      label{
                        padding-left: 35px;
                        font-size: 0.86em;
                        .avg-price{
                          font-weight: 600;
                        }
                      }
                      label:before {
                        content:"";
                        border: 1px solid #bfbebe;
                        background-color: white;
                        width: 18px;
                        height: 18px;
                        display: block;
                        position: absolute;
                        left: 0;
                        top: 10px;
                        margin: 1px 8px;
                        border-radius:  5px;
                      }
                      input:checked + label:after {
                        content:"";
                        background-color: rgb(255, 81, 34);
                        padding: 2px;
                        border: 2px solid white;
                        position: absolute;
                        left: 0;
                        top: 10px;
                        border-radius:5px;
                        width: 16px;
                        height: 16px;
                        margin: 2px 9px;
                      }
                    }
                    &.checkbox:hover{
                      background-color: #f5f5f5;
                      border-radius: 5px;
                    }
                  }
                }                         
              }
              &.active{
                display: block;
                animation: slide-left .2s ease-in-out;
              }        
            }            
            /* Safari 4.0 - 8.0 */
            @-webkit-keyframes slide-left {
              0% { opacity: 0; -webkit-transform: translateX(50%); }   
              100% { opacity: 1; -webkit-transform: translateX(0); }
            }
            /* Standard syntax */
            @keyframes slide-left {
              0% { opacity: 0; -webkit-transform: translateX(50%); }   
              100% { opacity: 1; -webkit-transform: translateX(0); }
            }
          }
          .tm-actions{
            width: 100%;
            .actions {
              margin: auto;
              width: fit-content;
              a{
                display: none;
              }
            }
            i {
              font-size: 2rem;
              padding-right: 8px;
              vertical-align: text-bottom;
              cursor: pointer;
            }
            .button {
              cursor: not-allowed;
              background-color: rgba(181, 181, 181, 0.8);
              color: white;
              width: 200px;
              border-radius: 100px;
              font-size: 14px;
              font-weight: 300;
              padding: 8px 30px;
              &.active{
                cursor: pointer;
                background-color: rgb(255, 81, 34);
              }
            }
          } 
      }
      .tm-actions {
        padding: 4px;
        margin: auto;
        .actions {
            > * {
            display: inline-block;
          }
        }
      }
      // &.active {
      //   opacity: 1;
      //   visibility: visible;
      // }    
      }
    }
  }
  //- Train-map-sg Responsive ---------------------------
  @media (max-width: 1024px) {
    &-train-map-sg {    
      .block-content {
        height: calc(100vh - 50px);
      }
    }
  }
  @media (max-width: 676px)  {
    &-train-map-sg {    
      .block-content {
        flex-flow: column nowrap;
        height: auto;
        .trainmap-form {
          max-width: 100%;
          min-width: 100%;
          padding: 1rem .8rem 1.5rem .8rem;
          hr {
            display: none;
          }
          .trainmap-filter {
            display: flex;
            flex-flow: row wrap;
            justify-content: space-between;
            .dropdown {
              flex: 0 1 49%;
              &:last-child {
                flex: 1 1 100%;
              }
            }
          }
        }
        .tm-line-content{
          box-shadow: none;
          overflow: visible;
          .title {
            padding: .8rem;
          }
          .tm-line-row {
            .tm-line-col {
              padding: 0 .8rem;
            }
          }
          .tm-actions {
            position: sticky;
            bottom: 0;
            background-color: #f8f8f8;
            border-top: 1px solid #cacaca;
            .actions {
              position: relative;
              width: 100%;
              padding: 0 20px;
              > div:first-of-type{
                width: 100%;
                text-align: center;
                padding-bottom: 5px;
              }
              button {
                margin: 0;
                width: 100%;
              }
              a {
                display: block;
                position: absolute;
                top: -25px;
                right: 20px;
                border-radius: 50%;
                background: #46B8DA;
                color: white;
                text-align: center;
                width: 35px;
                height: 35px;
                line-height: 32px;
                i {
                  padding: 0;
                }
              }
            }
          }
        }
      }
    }
  }
  &-school-map-sg {    
    .block {
      &-title {
        font-size: 2.8rem;
        text-align: center;
      }
      &-content {
        display: flex;
        flex-flow: row nowrap;
        height: calc(100vh - 72px);
        //tm-line theme color
        $lines: (
          'sb': '#1e4d2b',
          'kj': '#e0115f',
          'ag': '#fe8e10',
          'sp': '#721422',
          'mr': '#7dba00',
          'rkf': '#ff5722',
          'sbk': '#008000',
          'ke': '#800080',
          'kt': '#139593',
          'ka': '#dc241f',
          'kd': '#dc241f',
          'kb': '#1964b7',
          'kc': '#1964b7',
        );
        @each $i, $line in $lines {
          .trainmap-form .selection .checkbox input:checked + label.tm-line-#{$i},
          .tm-line-content .tm-line-row .tm-line-col .tm-lines .tm-header h5.tm-line-#{$i} {
            background-color: #{$line};
          }
          .tm-header i.color-#{$i}, 
          .tm-line-content .tm-line-row .tm-line-col .tm-lines .tm-station.color-#{$i}:before,
          .station-code.color-#{$i} {
            color: #{$line};
          }
          .tm-line-content .tm-line-row .tm-line-col .tm-lines .tm-station.color-#{$i} {
            border-top-color: #{$line};
          }          
        }
      .school-form {
        background-color: #f4f7fa;
        flex: 0 0 30%;
        padding: 3rem 1.5rem;
        min-width: 250px;
        max-width: 250px;
        .dropdown {
          margin-bottom: 10px;
          .button {
            width: 100%;
            text-align: left;
            padding: 5px 10px;
            i{
              position:absolute;
              right: 10px;
              bottom: 8px;
            }
            &-schoolmap{
              background-color: white;
              border: 1px solid #979797;
              &:active{
                box-shadow: none;
              }
            }
          }
          ul.dropdown-menu {
            border-radius: 0;
            box-shadow: 5px 5px 12px 2px rgba(0,0,0,.5);
            margin: 0 10px;
            right: 0;
            left: 0;
            & > li > a{
              padding: 5px 10px;
            }
          }
        }
        hr {
          margin-left: -15px;
          margin-right: -15px;
        }
        .selection{
          display: flex;
          flex-flow: row wrap;
          justify-content: flex-start;
          .checkbox.school {
            flex: 1 1 50%;
            label:before {
              content: "";
              border: 1px solid #bfbebe;
              background-color: white;
              width: 18px;
              height: 18px;
              display: block;
              border-radius: 5px;
            }
            input:checked + label:before {
              background-color: white;
              border: 1px solid #bfbebe;
            }
            input:checked + label:after {
              content: "";
              background-color: #ff5122;
              width: 12px;
              height: 12px;
              border-radius: 3px;
              position:absolute;
              left: 3px;
              top: 3px;
            }
            label {
              padding-left: 2.2rem;
              span {
                font-size: 1.2rem;
                color: #262626;
              }
            }
          }
        }
      }
      .school-content {
          flex: 1 1 70%;
          display: flex;
          flex-flow: column nowrap;
          box-shadow: inset 20px 0px 20px -20px grey;
          overflow: hidden;
          .title {
            padding: 30px 0 10px 40px;
            font-size: 16px;
          }
          .school-row {
            overflow-y: scroll;          
            overflow-x: hidden;
            width: calc(100% + 20px);
            display: flex;
            flex-flow: column nowrap;
            height: 100%;            
            .school-result {
              padding-left: 4rem;
              display: none;
              &.active {
                display: block;
              }
              .showing {
                .desc {
                  margin-right: 1.5rem;
                  display: inline-block;
                }
              }
              .clear-filter{
                text-decoration: underline;
                font-weight: 500;
                display: inline-block;
                cursor: pointer;
              }
            }
            .school-empty {
              height: 100%;
              display: none;              
              flex-flow: column nowrap;
              justify-content: center;
              img.img-responsive {
                max-width: 42%;
                margin: 0 auto;
              }
              &.active{
                display: flex;
              }
            }
            .sm-school-col {
              padding-left: 4rem;
              display: none;
              box-shadow: inset 0px -20px 20px -20px grey;           
              .sm-state {
                display: flex;
                flex-flow: row wrap;
                justify-content: flex-start;
                .sm-header {
                  flex-basis: 100%;
                  padding-bottom: 5px;
                  i {
                    font-size: 18px;
                    padding-right: 8px;
                    vertical-align: text-bottom;
                  }
                  h5 {
                    background-color: rgb(69, 90, 100);
                    width: fit-content;
                    color: white;
                    padding: 2px 25px;
                    font-weight: 400;
                    border-radius: 20px;
                    display: inline-block;
                  }
                  .sm-state-label {
                    display: inline-block;
                    input[type="checkbox"] {
                      display: none;
                    }
                    label {
                      padding-left: 0px;
                    }
                    &.checkbox {
                      label:before {
                        content: "";
                        width: 18px;
                        height: 18px;
                        display: inline-block;
                        border: 1px solid #bfbebe;
                        border-radius: 5px;
                        vertical-align: top;
                        margin-right: 5px;
                      }
                      input:checked + label:after {
                        content: "";
                        width: 12px;
                        height: 12px;
                        display: block;
                        background-color: #ff5122;
                        border-radius: 3px;                       
                        position:absolute;
                        top: 3px;
                        left: 3px;
                      }
                    }                  
                  }
                }
                .sm-school {
                  flex: 1 1 15%;
                  min-width: 250px;
                  max-width: 250px;
                  display: none;
                  &:last-child {
                    border-color: transparent;
                  }
                  .sm-school-label {
                    padding: 5px 0;
                    font-size: 12px;
                    input[type="checkbox"] {
                      display: none;
                    }
                    label {
                      padding-left: 0px;
                    }
                    &.checkbox {
                      label:before {
                        content: "";
                        width: 18px;
                        height: 18px;
                        display: inline-block;
                        border: 1px solid #bfbebe;
                        border-radius: 5px;
                        vertical-align: top;
                      }
                      input:checked + label:after {
                        content: "";
                        width: 12px;
                        height: 12px;
                        display: block;
                        background-color: #ff5122;
                        border-radius: 3px;                       
                        position:absolute;
                        top: 8px;
                        left: 3px;
                      }
                    }
                    &.checkbox:hover{
                      background-color: #f5f5f5;
                      border-radius: 5px;
                    }
                    .sm-school-name {
                      font-weight: 600;
                      max-width: 220px;
                      display: inline-block;
                      .school-type {
                        font-weight: 400;
                      }
                    }
                  }
                  &.active {
                    display: block;
                  }
                }                 
              }
              &.active{
                display: block;
                animation: slide-left .2s ease-in-out;
                min-height: 100%;
              }        
            }            
            /* Safari 4.0 - 8.0 */
            @-webkit-keyframes slide-left {
              0% { opacity: 0; -webkit-transform: translateX(50%); }   
              100% { opacity: 1; -webkit-transform: translateX(0); }
            }
            /* Standard syntax */
            @keyframes slide-left {
              0% { opacity: 0; -webkit-transform: translateX(50%); }   
              100% { opacity: 1; -webkit-transform: translateX(0); }
            }
          }
          .sm-actions{
            width: 100%;
            padding: 4px;
            margin: auto;
            .actions {
              margin: auto;
              width: fit-content;
              a{
                display: none;
              }
              > * {
                display: inline-block;
              }
            }
            i {
              font-size: 2rem;
              padding-right: 8px;
              vertical-align: text-bottom;
              cursor: pointer;
            }
            .button {
              cursor: not-allowed;
              background-color: rgba(181, 181, 181, 0.8);
              color: white;
              width: 200px;
              border-radius: 100px;
              font-size: 14px;
              font-weight: 300;
              padding: 8px 30px;
              &.active{
                cursor: pointer;
                background-color: rgb(255, 81, 34);
              }
            }
          } 
        }
      }
    }
  }
  //- Responsive ---------------------------
  @media (max-width: 1024px) {
    &-school-map-sg {    
      .block-content {
        height: calc(100vh - 50px);
      }
    }
  }
  @media (max-width: 676px)  {
    &-school-map-sg {    
      .block-content {
        flex-flow: column nowrap;
        height: auto;
        .school-form {
          max-width: 100%;
          min-width: 100%;
          padding: 1rem .8rem 1.5rem .8rem;
          hr {
            display: none;
          }
          .schoolmap-filter {
            display: flex;
            flex-flow: row wrap;
            justify-content: space-between;
            .dropdown {
              flex: 0 1 49%;
              &:last-child {
                flex: 1 1 100%;
              }
            }
          }
        }
        .school-content{
          box-shadow: none;
          overflow: visible;
          .title {
            padding: .8rem;
          }          
          .school-row {
            .school-result {
              padding-left: 8px;
              .clear-filter {
                display: block;
              }
            }
            .sm-school-col {
              padding: 0 .8rem;
            }
          }
          .sm-actions {
            position: sticky;
            bottom: 0;
            background-color: #f8f8f8;
            border-top: 1px solid #cacaca;
            .actions {
              position: relative;
              width: 100%;
              padding: 0 20px;
              > div:first-of-type{
                width: 100%;
                text-align: center;
                padding-bottom: 5px;
              }
              button {
                margin: 0;
                width: 100%;
              }
              a {
                display: block;
                position: absolute;
                top: -25px;
                right: 20px;
                border-radius: 50%;
                background: #46B8DA;
                color: white;
                text-align: center;
                width: 35px;
                height: 35px;
                line-height: 32px;
                cursor: pointer;
                i {
                  padding: 0;
                }
              }
            }
          }
        }
      }
    }
  } 
  &-school-explore {
    margin-top: 0 !important;
    .block {
      &-title {
        font-size: 2.8rem;
        text-align: center;
      }
    }
    .tt-menu {
      width: 100%;
      max-height: 42rem;
      margin-top: 0.1rem;
      overflow: auto;
      box-shadow: 0 0 0 0.1rem $input-border-color, 0 0.4rem 0.5rem -0.15rem rgba($base-color, 0.1);
      &:after {
        border-bottom-color: $input-border-color;
      }
    }
    .se-form {
      .input {
        label { padding-left: 1.5rem; padding-right: 1.5rem; }
        input { padding-left: 1.5rem; padding-right: 1.5rem; }
      }
    }
    .se-bar,
    .se-content {
      max-width: 72rem;
      margin-left: auto;
      margin-right: auto;
      padding-left: 1.5rem;
      padding-right: 1.5rem;
    }
    .se-bar {
      .input-group {
        &-btn {
          .button {
            font-size: $base-font-size;
            border-radius: 0 $input-radius $input-radius 0;
          }
        }
        .input {
          border-right: 0;
          border-radius: $input-radius 0 0 $input-radius;
        }
      }
    }
    .se-content {
      .input-group {
        &-btn {
          .button {
            padding-top: $base-font-size;
            padding-bottom: $base-font-size;
            font-size: $base-font-size - 0.1rem;
            border-radius: 0 $input-radius $input-radius 0;
          }
        }
        .input {
          min-height: 4.8rem;
          border-right: 0;
          border-radius: $input-radius 0 0 $input-radius;
          label { line-height: 4.6rem; }
          input { height: 4.6rem; }
        }
      }
    }
    .se-actions {
      width: 100%;
      padding: 1rem;
      background-color: rgba($white, 0.9);
      text-align: center;
      position: fixed;
      left: 0;
      bottom: 0;
      z-index: 5;
      box-shadow: 0 -0.6rem 1rem -0.2rem rgba($base-color, 0.05), 0 -0.1rem 0 0 rgba($base-color, 0.1);
    }
    // Front Search Integrated
    &.search-integrated {
      $spos: 45%;
      width: 100%;
      height: 100%;
      padding: 4rem 4rem 6.5rem;
      background-color: $white;
      opacity: 0;
      visibility: hidden;
      overflow-x: auto;
      position: fixed;
      top: 0;
      left: 0;
      z-index: 100;
      transition: all 200ms ease-in-out;
      &.active {
        opacity: 1;
        visibility: visible;
      }
    }
  }
  //- Responsive ---------------------------
  @media (max-width: 767px) {
    &-newsletter-signup {
      .block-content {
        padding: 2.2em 1.5em;
      }
      .nls-title {
        font-size: 1.7em;
      }
    }
    &-mortgage-calculator {
      padding: 1.5rem;
    }
    &-train-map {
      .tm-line {
        &-row {
          display: block;
          max-height: none;
        }
        &-col {
          width: 100%;
        }
      }
    }
  }
  @media (min-width: 768px) and (max-width: 1199px) {
    &-train-map {
      padding: 3rem 5rem;
      .block {
        &-titlte {
          font-size: 1.8rem;
        }
      }
      .tm-line {
        &-row {
          max-height: 430rem;
        }
        &-col {
          width: 50%;
        }
      }
    }
  }
}

/* Get More */
.get-more {
  .block-title {
    text-align: center;
  }
  .icon-box {
    display: block;
    min-height: 12rem;
    color: mix($base-color, $white, 39%); 
    text-decoration: none;
    text-align: center;
    figure {
      padding-top: 1em;
      font-size: 1.2em;
    }
    h5 {
      margin-top: 1.2em;
      margin-bottom: 0;
      color: inherit;
      font-size: 1em;
      font-weight: 500;
      text-transform: uppercase;
      line-height: 1.1;
      letter-spacing: 0.08em;
    }
    // Hover
    &:hover {
      color: $base-color;
    }
  }
  //- Responsive ---------------------------
  @media (min-width: 768px) {
    .block-title { font-size: 3rem; }
  }
}

/* Explorer TEP */
.explorer-tep {
  .block-title {
    color: inherit;
  }
  h5 {
    font-size: 1.3rem;
    font-weight: 500;
    color: $white;
  }
  //- Responsive ---------------------------
  @media (min-width: 768px) {
    .block-title { font-size: 3rem; }
  }
}

// trianmap view when login
body.admin-menu.adminimal-menu {
  .block-train-map-sg {
    .block-content{
      height: calc(100vh - 101px);
    }
    @media (max-width: 1024px){
      .block-content{
        height: calc(100vh - 79px);
      }
    }
    @media (max-width: 767px){
      .block-content{
        height: calc(100vh - 50px);
      }
    }
  }
}