$igap: 20; // Total items

// Margin reset all
.m-reset {
  margin: 0 !important;
}

// Margin reset left / right
.m-lr {
  margin-left: 0 !important;
  margin-right: 0 !important;
}

// Margin Top
.m-t-0 { margin-top: 0; }
@for $i from 1 to $igap + 1 {
  .m-t-#{(100 / $igap) * $i} {
    margin-top: #{((100 / $igap) * $i) / 10}rem;
  }
}

// Margin Bottom
.m-b-0 { margin-bottom: 0; }
@for $i from 1 to $igap + 1 {
  .m-b-#{(100 / $igap) * $i} {
    margin-bottom: #{((100 / $igap) * $i) / 10}rem;
  }
}

// Margin Left
.m-l-0 { margin-left: 0; }
@for $i from 1 to $igap + 1 {
  .m-l-#{(100 / $igap) * $i} {
    margin-left: #{((100 / $igap) * $i) / 10}rem;
  }
}

// Margin Right
.m-r-0 { margin-right: 0; }
@for $i from 1 to $igap + 1 {
  .m-r-#{(100 / $igap) * $i} {
    margin-right: #{((100 / $igap) * $i) / 10}rem;
  }
}

// Padding reset all
.p-reset {
  padding: 0 !important;
}

// Padding reset left + right
.p-lr {
  padding-left: 0 !important;
  padding-right: 0 !important;
}

// Padding Top
.p-t-0 { padding-top: 0; }
@for $i from 1 to $igap + 1 {
  .p-t-#{(100 / $igap) * $i} {
    padding-top: #{((100 / $igap) * $i) / 10}rem;
  }
}

// Padding Bottom
.p-b-0 { padding-bottom: 0; }
@for $i from 1 to $igap + 1 {
  .p-b-#{(100 / $igap) * $i} {
    padding-bottom: #{((100 / $igap) * $i) / 10}rem;
  }
}

// Padding Left
.p-l-0 { padding-left: 0; }
@for $i from 1 to $igap + 1 {
  .p-l-#{(100 / $igap) * $i} {
    padding-left: #{((100 / $igap) * $i) / 10}rem;
  }
}

// Padding Right
.p-r-0 { padding-right: 0; }
@for $i from 1 to $igap + 1 {
  .p-r-#{(100 / $igap) * $i} {
    padding-right: #{((100 / $igap) * $i) / 10}rem;
  }
}

@media (max-width: 1199px) {
  // Margin Top
  .m-t-md-0 { margin-top: 0 !important; }
  @for $i from 1 to $igap + 1 {
    .m-t-md-#{(100 / $igap) * $i} {
      margin-top: #{((100 / $igap) * $i) / 10}rem !important;
    }
  }

  // Margin Bottom
  .m-b-md-0 { margin-bottom: 0 !important; }
  @for $i from 1 to $igap + 1 {
    .m-b-md-#{(100 / $igap) * $i} {
      margin-bottom: #{((100 / $igap) * $i) / 10}rem !important;
    }
  }

  // Padding Top
  .p-t-md-0 { padding-top: 0 !important; }
  @for $i from 1 to $igap + 1 {
    .p-t-md-#{(100 / $igap) * $i} {
      padding-top: #{((100 / $igap) * $i) / 10}rem !important;
    }
  }

  // Padding Bottom
  .p-b-md-0 { padding-bottom: 0 !important; }
  @for $i from 1 to $igap + 1 {
    .p-b-md-#{(100 / $igap) * $i} {
      padding-bottom: #{((100 / $igap) * $i) / 10}rem !important;
    }
  }
}

@media (max-width: 991px) {
  // Margin Top
  .m-t-sm-0 { margin-top: 0 !important; }
  @for $i from 1 to $igap + 1 {
    .m-t-sm-#{(100 / $igap) * $i} {
      margin-top: #{((100 / $igap) * $i) / 10}rem !important;
    }
  }

  // Margin Bottom
  .m-b-sm-0 { margin-bottom: 0 !important; }
  @for $i from 1 to $igap + 1 {
    .m-b-sm-#{(100 / $igap) * $i} {
      margin-bottom: #{((100 / $igap) * $i) / 10}rem !important;
    }
  }

  // Padding Top
  .p-t-sm-0 { padding-top: 0 !important; }
  @for $i from 1 to $igap + 1 {
    .p-t-sm-#{(100 / $igap) * $i} {
      padding-top: #{((100 / $igap) * $i) / 10}rem !important;
    }
  }

  // Padding Bottom
  .p-b-sm-0 { padding-bottom: 0 !important; }
  @for $i from 1 to $igap + 1 {
    .p-b-sm-#{(100 / $igap) * $i} {
      padding-bottom: #{((100 / $igap) * $i) / 10}rem !important;
    }
  }
}

@media (max-width: 767px) {
  // Margin Top
  .m-t-xs-0 { margin-top: 0 !important; }
  @for $i from 1 to $igap + 1 {
    .m-t-xs-#{(100 / $igap) * $i} {
      margin-top: #{((100 / $igap) * $i) / 10}rem !important;
    }
  }

  // Margin Bottom
  .m-b-xs-0 { margin-bottom: 0 !important; }
  @for $i from 1 to $igap + 1 {
    .m-b-xs-#{(100 / $igap) * $i} {
      margin-bottom: #{((100 / $igap) * $i) / 10}rem !important;
    }
  }

  // Padding Top
  .p-t-xs-0 { padding-top: 0 !important; }
  @for $i from 1 to $igap + 1 {
    .p-t-xs-#{(100 / $igap) * $i} {
      padding-top: #{((100 / $igap) * $i) / 10}rem !important;
    }
  }

  // Padding Bottom
  .p-b-xs-0 { padding-bottom: 0 !important; }
  @for $i from 1 to $igap + 1 {
    .p-b-xs-#{(100 / $igap) * $i} {
      padding-bottom: #{((100 / $igap) * $i) / 10}rem !important;
    }
  }
}

@media (max-width: 479px) {
  // Margin Top
  .m-t-xxs-0 { margin-top: 0 !important; }
  @for $i from 1 to $igap + 1 {
    .m-t-xxs-#{(100 / $igap) * $i} {
      margin-top: #{((100 / $igap) * $i) / 10}rem !important;
    }
  }

  // Margin Bottom
  .m-b-xxs-0 { margin-bottom: 0 !important; }
  @for $i from 1 to $igap + 1 {
    .m-b-xxs-#{(100 / $igap) * $i} {
      margin-bottom: #{((100 / $igap) * $i) / 10}rem !important;
    }
  }

  // Padding Top
  .p-t-xxs-0 { padding-top: 0 !important; }
  @for $i from 1 to $igap + 1 {
    .p-t-xxs-#{(100 / $igap) * $i} {
      padding-top: #{((100 / $igap) * $i) / 10}rem !important;
    }
  }

  // Padding Bottom
  .p-b-xxs-0 { padding-bottom: 0 !important; }
  @for $i from 1 to $igap + 1 {
    .p-b-xxs-#{(100 / $igap) * $i} {
      padding-bottom: #{((100 / $igap) * $i) / 10}rem !important;
    }
  }
}