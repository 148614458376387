body {
    &.node-type-tep-blogs {
        // guides detail page style
        .wrap.news-wope-theme {
            @media (min-width: $break_pre_d) {
                margin-top: 124px;
            }
            @media (max-width: $break_tablet) {
                margin-top: 0;
            }
            .entry-meta {
                display: none;
                @media (max-width: $break_m_x) { 
                    padding-left: 0; 
                }
            }
            
            .main-content .news-article h1 {
                @media (max-width: $break_m_xx) {
                    z-index: 77;
                    position: relative;
                    background: #fff;
                    pointer-events: auto;
                    padding: 10px;
                    margin: 0;
                    font-size: 2.5rem;
                }

            }

            #bigleaderboard {
                .wide-ad {
                    margin: 0;
                }
            }
        }
        
    }
}