.animate {
  &.waving {
    animation: waveHand 4.5s infinite;
    &.left {
      transform-origin: center left;
    }
    &.right {
      transform-origin: center right;
    }
  }
  &.marquee-grey {
    background: linear-gradient(270deg, $grey-color6 8%, $grey-color5 16%, $grey-color6 24%);
    background-size: 200% 100%;
    background-attachment: fixed;
    animation: marquee 2s linear infinite;
    border-radius: $base-radius;
  }
}

@keyframes waveHand {
  0% { transform: rotate(0deg); }
  7.5% { transform: rotate(3deg); }
  15% { transform: rotate(0deg); }
  22.5% { transform: rotate(3deg); }
  30% { transform: rotate(0deg); }
  to { transform: rotate(0deg); }
}

@keyframes marquee {
  0% { background-position: 0 0; }
  100% { background-position: -200% 0; }
}