//------------------------------
// New Launches
//------------------------------
// Page
.new-launch {
  margin: 0;
  padding: 0;
  // overflow-y: hidden; // Disabled for listing
  position: relative;
  top: 0;
  left: 0;
  visibility: visible;
  opacity: 1;
  .header {
    // transform: translate3d(0, -7.2rem, 0);
    // &-nav {
    //   background-color: transparent;
    //   background-image: linear-gradient(0deg, rgba(0,0,0,0) 0%, rgba(0,0,0,0.15) 50%, rgba(0,0,0,0.35) 100%);
    //   transition: background-color 300ms ease-in-out;
    //   .nav-link {
    //     > li {
    //       > a {
    //         color: $white;
    //         color: rgba($white, 0.75);
    //         //- text-shadow: 0 0.1rem 0.1rem rgba($base-color, 0.3);
    //       }
    //       &.active {
    //         > a {
    //           color: $white;
    //           background-color: transparent;
    //           font-weight: bold;
    //         }
    //       }
    //       &.open {
    //         > a {
    //           color: $header-nav-dm-color;
    //           background-color: rgba($header-nav-dm-bg, 0.7);
    //         }
    //       }
    //     }
    //   }
    //   .dropdown {
    //     &:hover {
    //       > a {
    //         color: $header-nav-dm-color;
    //         background-color: rgba($header-nav-link-active, 0.7);
    //       }
    //     }
    //   }
    //   .dropdown-menu {
    //     background-color: rgba($header-nav-dm-bg, 0.7);
    //     .dropdown {
    //       &:hover {
    //         > a {
    //           background-color: $header-nav-dm-hoverbg;
    //         }
    //       }
    //     }
    //   }
    // }
    // // Sticky
    // &.sticky {
    //   .header-nav {
    //     background-color: rgba($theme-color, 0.75);
    //   }
    // }
  }
  // Loaded
  &.loaded {
    visibility: visible;
    opacity: 1;
  }
  //- Responsive ---------------------------
  @media (max-width: 767px) {
    // .header {
    //   transform: none;
    //   &-top { background-color: transparent; }
    //   &-brand {
    //     .logo { display: none; }
    //     .mobile-toggle { background-color: transparent; }
    //   }
    //   &-nav {
    //     background-image: linear-gradient(0deg, rgba(0,0,0,0) 0%, rgba(0,0,0,0.15) 50%, rgba(0,0,0,0.35) 100%);
    //   }
    // }
  }
}
// Slides
.nl-slides {
  position: relative;
  .slides {
    &-container {
      margin: 0;
      padding: 0;
      list-style: none;
    }
    &-item {
      > a {
        display: block;
        width: 100%;
        height: 100%;
        position: relative;
        &:before {
          content: '';
          display: block;
          background-image: linear-gradient(205deg, rgba(0,0,0,0) 45%, rgba(0,0,0,0.4) 80%, rgba(0,0,0,0.8) 100%);
          position: absolute;
          left: 0;
          right: 0;
          top: 0;
          bottom: 0;
        }
      }
    }
    &-caption {
      max-width: 40rem;
      background-color: transparent;
      color: $white;
      text-shadow: 0 0.1rem 0.1rem rgba($base-color, 0.3), 0 0.1rem 0.1rem rgba($base-color, 0.7);
      position: absolute;
      left: 5rem;
      bottom: 5rem;
      z-index: 6;
      h3 { margin-top: 0; margin-bottom: 0.2em; line-height: 1.2; }
      p { margin-bottom: 0; }
    }
    &-navigation {
      a {
        display: block;
        font-size: 5rem;
        line-height: 1;
        color: $white;
        opacity: 0.5;
        position: absolute;
        top: 50%;
        z-index: 2;
        transform: translateY(-50%);
        &.prev { left: 0; }
        &.next { right: 0; }
        &:hover {
          opacity: 1;
        }
      }
    }
    &-pagination {
      position: absolute;
      bottom: 1rem;
      left: 50%;
      z-index: 3;
      transform: translateX(-50%);
      a {
        display: block;
        float: left;
        width: 5rem;
        height: 0.5rem;
        margin: 1rem 0.5rem;
        background-color: $white;
        background-color: rgba($white, 0.25);
        color: $base-color;
        font-size: 0;
        text-align: center;
        text-decoration: none;
        border-radius: 1rem;
        &:hover {
          background-color: $grey-color5;
          background-color: rgba($grey-color5, 0.75);
          color: $white;
        }
        &.current {
          background-color: $theme-color-alt;
          background-color: rgba($theme-color-alt, 0.85);
          color: $white;
        }
      }
    }
  }
  //- Responsive ---------------------------
  @media (max-width: 767px) {
    .slides {
      &-caption {
        width: 100%;
        padding: 2rem;
        text-align: center;
        left: 0;
        bottom: 2.5rem;
      }
      &-navigation {
        a {
          font-size: 3rem;
          opacity: 1;
          &.prev { left: -1rem; }
          &.next { right: -1rem; }
        }
      }
      &-pagination {
        text-align: center;
        left: 0;
        right: 0;
        bottom: 0;
        transform: none;
        a {
          display: inline-block;
          float: none;
          width: 2rem;
        }
      }
    }
  }
}
// Featured
.nl-featured {
  padding-top: 5rem;
  padding-bottom: 5rem;
  .nl-title {
    margin-top: 0;
    margin-bottom: 1em;
    font-size: 3rem;
    font-weight: 600;
    text-align: center;
    &:after {
      content: '';
      display: block;
      width: 8rem;
      height: 0.3rem;
      margin-top: 0.333em;
      margin-left: auto;
      margin-right: auto;
      background-color: $theme-color; 
    }
  }
  //- Responsive ---------------------------
  @media (max-width: 767px) {
    .nl-title {
      font-size: 2.4rem;
    }
  }
}
// Cards
.nl-card {
  > .buttons {
    border-top: 0;
    .button {
      padding: 0;
      line-height: 4.8rem;
    }
    .material-icons {
      font-size: 1.571em;
    }
    .social-sharer {
      padding: 0;
      .social-button {
        width: auto;
        height: auto;
        line-height: 4.8rem;
        background-color: transparent;
        color: inherit;
        border-radius: 0;
        box-shadow: none;
      }
    }
  }
  //- Responsive ---------------------------
  @media (min-width: 768px) and (max-width: 991px) {
    &s {
      > .nl-col {
        &:nth-child(2n+3) {
          clear: both;
        }
      }
    }
  }
  @media (min-width: 992px) {
    &s {
      > .nl-col {
        &:nth-child(3n+4) {
          clear: both;
        }
      }
    }
  }
}