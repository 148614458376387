
body {
  &.page-livefireside {
    #contest-page section .introword {
      margin: 30px 0;
      display: block; 
    }
    .content-wrapper {
      width: 100%;
      text-align: center;
      @media(min-width: $break_post_tablet) {
        max-width: 700px;
        margin: 30px auto;
      }
    }
    a.btn-fb-livefireside {
      text-decoration: none !important;
      padding: 20px; 
      background: #ffe056;
      display: block;
      margin: 20px auto;
      border-radius: 5px;
      font-weight: 700;
      font-size: 20px;
      max-width: 300px;
      color: #222 !important;
      &:hover {
        background: #f3d242;
      }
    }
    font-size: 15px;
    p {
      line-height: 1.5;
      font-size: 15px;
      &.p-big {
        font-size: 16px;
        line-height: 1.8;
      }
    }
  }
}