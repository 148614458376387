#vpex_default {  
    background: url(https://media.edgeprop.my/page/vpex/2021/bg_cloud.png) no-repeat center center fixed; 
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;

    .back-to-top { 
        height: auto;
        position: fixed;
        // max-width: 55px;
        z-index: 100;
        bottom: 0.7%;
        right: 5%;
        img {
            max-width: 55px;
        }
    }
    .vpex {
        &-menu { 
            cursor: pointer;
            width: 100%;
            max-width: 700px;
            padding: 20px 0;
            margin: 0 auto; 
            img {
                position: fixed; 
                max-width: 55px;
                z-index: 100;
                right: 5%;
                @media (max-width: 1024px) {
                    right: 5%;
                }
            }
            :hover {
                opacity: 80%;
            }
        }
        &-top,
        &-main-content,
        &-projects {
            width: 100%;
            .v--billboard,
            .v--map,
            .v--project {
                max-width: 1080px;
                margin: 0 auto;
                @media (max-width: 1024px) {
                    max-width: 900px;
                }
                @media (max-width: 991px) {
                    max-width: 99%;
                }
                position: relative; 
                img {
                    width: 100%;
                }
            }
        }
        &-top {
            position: relative; 
            @media (max-width: 767px) {
                height: 800px;
            } 
            @media (max-width: 490px) {
                height: 655px;
            }
            @media (max-width: 425px) {
                height: 580px;
            } 
            @media (max-width: 375px) {
                height: 510px;
            } 
            @media (max-width: 320px) {
                height: 445px;
            } 
            @media (max-width: 768px) and (min-width: 768px) {
                height: 1000px;
            } 
            @media (min-width: 1024px) {
                height: 1300px;
            } 
            @media (min-width: 1440px) {
                height: 1540px;
            } 
            @media (min-width: 1564px) {
                height: 1600px;
            } 
            @media (min-width: 1564px) {
                height: 1600px;
            } 
            @media (min-width: 1900px) {
                height: 1696px;
            } 
            @media (min-width: 2256px) {
                height: 1836px;
            } 
            overflow: hidden;
            padding-top: 50px;
            .v--city {
                // position: relative;
                position: absolute;
                bottom: 0;
                z-index: 11;
                @media (min-width: 1900px) {
                    width: 100%;
                }
                img {
                    max-width: 100%;
                    @media (min-width: 1900px) {
                        width: 100%;
                    }
                }
            }
            .v--billboard {  
                top: -15px; 
                z-index: 10; 
            }
        }
        &-main-content {
            // background: #a4e05b; 
            // background: url(https://media.edgeprop.my/page/vpex/2021/bg_green.png) no-repeat center center fixed; 
            // -webkit-background-size: cover;
            // -moz-background-size: cover;
            // -o-background-size: cover;
            // background-size: cover;
            background: url(https://media.edgeprop.my/page/vpex/2021/bg_green.png) no-repeat;  
            background-size: cover;
            .v--map { 
                // height: 1200px; 
                height: auto; 
                z-index: 12;
                top: -240px;
                @media (max-width: 767px) {
                    top: -85px;
                } 
                .vpex-auditorium {
                    position: absolute;
                    background: transparent;
                    animation: blinker 1s linear infinite;
                    border: 3px solid #462b5a;
                    width: 20.3%;
                    height: 3.8%;
                    margin-top: 21%;
                    margin-left: 3%; 
                }
                img#vpex-incentives {
                    position: absolute; 
                    width: 50.3%;
                    height: auto;
                    margin-top: 14%;
                    margin-left: 3%;  
                }
                .btn-loancheck {
                    position: absolute;
                    background: transparent;
                    width: 50%;
                    @media (min-width: 1024px) {
                        height: 284px;
                    }
                    height: 9%;
                    margin-top: 52%;
                    margin-left: 46%; 
                    &:hover {
                        background: rgba(255, 255, 255, 0.5);
                    }
                }
                .dot-container { 
                    .project-button {
                        background: transparent; 
                        width: 21%;
                        height: 1.4%;
                        border-radius: 41px; 
                        position: absolute; 
                        &.setia-skyseputeh { 
                            margin-top: 60.4%;
                            margin-left: 26.9%;
                        } 
                        &.melodia2 { 
                            margin-top: 91%;
                            margin-left: 44.9%;
                        } 
                        &.clover { 
                            margin-top: 18%;
                            margin-left: 56.9%; 
                        }  
                        &.ipark { 
                            margin-top: 66.4%;
                            margin-left: 13.9%; 
                        } 
                        &.dutavilla { 
                            margin-top: 83.4%;
                            margin-left: 49.7%;
                        }
                        &.aura { 
                            margin-top: 97.3%;
                            margin-left: 72.2%;
                        } 
                        &.florarosa { 
                            margin-top: 137.1%;
                            margin-left: 7.3%;
                        }  
                        &.augusta { 
                            margin-top: 147.1%;
                            margin-left: 21.3%;
                        }  
                        &.clover,
                        &.ipark,
                        &.dutavilla
                        &.aura,
                        &.florarosa,
                        &.augusta {
                            width: 24%;
                            height: 1.8%;
                        }

                    }
                    width: 1080px;
                    img {
                        position: absolute;
                        // width: 75px !important;
                        width: 7%;
                        height: auto; 
                        @include floating-animation; 
                        &.dot {
                            &-skyseputeh {
                                margin-top: 53%;
                                margin-left: 35%;  
                                // @media (max-width: 767px) { 
                                //     margin-top: 51%;
                                //     margin-left: 34%; 
                                // }   
                                // @media (max-width: 425px) { 
                                //     margin-top: 49%; 
                                // }  
                                // @media (max-width: 375px) { 
                                //     margin-top: 47%; 
                                //     margin-left: 33%;
                                // }  
                                // @media (max-width: 320px) { 
                                //     margin-top: 45%;
                                // } 
                            }
                            &-melodia2 {
                                @include floating-animation-2;
                                margin-top: 83%;
                                margin-left: 52%;  
                            } 
                            &-clover {
                                // @include floating-animation-2;
                                margin-top: 10%;
                                margin-left: 66%; 
                            } 
                            &-ipark {
                                @include floating-animation-2;
                                margin-top: 58%;
                                margin-left: 23%; 
                            }
                            &-putra-dutavilla {
                                @include floating-animation-3;
                                margin-top: 75%;
                                margin-left: 59%; 
                            }
                            &-putra-aura {
                                // @include floating-animation-3;
                                margin-top: 90%;
                                margin-left: 82%; 
                            }
                            &-putra-florarosa {
                                @include floating-animation-2;
                                margin-top: 129.5%;
                                margin-left: 17%; 
                            }
                            &-putra-augusta {
                                @include floating-animation-3;
                                margin-top: 140%;
                                margin-left: 31%; 
                            }
                        }  
                    }  
                }
                
            }
        }
        &-projects {
            padding: 20px 0;
            // @media (max-width: 768px) {
            //     padding:20px 0;
            // } 
            @media (max-width: 767px) {
                padding:10px;
            } 
            background: #b5ef4f;  
            .v--project { 
                background: transparent;
                min-height: 900px;
                @media (max-width: 767px) {
                    min-height: 750px;
                }
                @media (max-width: 490px) {
                    min-height: 515px;
                }
                @media (max-width: 425px) {
                    min-height: 420px;
                }
                @media (max-width: 320px) {
                    min-height: 310px;
                }
                max-width: 750px;
                margin-bottom: 50px;  
                // #project-img {
                //     @media (max-width: 767px) {
                //         padding: 0 10px;
                //     }
                // }
                .menu-container {
                    // position: absolute;
                    img {
                        cursor: pointer;
                        position: absolute;
                        width: 80px;
                        height: auto;
                        @media (max-width: 767px) {
                            width: 54px;
                        }
                        @media (max-width: 375px) {
                            width: 47px;
                        }
                        @include floating-animation;
                        &#menu-skyseputeh {
                            &-gallery {
                                top: 87px;
                                left: 77px; 
                                @include floating-animation-2;
                                @media (max-width: 767px) {
                                    top: 64px;
                                    left: 60px;
                                }
                                @media (max-width: 490px) {
                                    top: 33px;
                                    left: 33px;
                                } 
                                @media (max-width: 425px) { 
                                    top: 20px;
                                    left: 16px;
                                } 
                                @media (max-width: 375px) { 
                                    top: 16px;
                                    left: 12px;
                                }  
                                @media (max-width: 320px) { 
                                    top: 1px;
                                    left: -1px;
                                } 
                            } 
                            &-tour {
                                top: 10%;
                                left: 62%; 
                                @media (max-width: 767px) {
                                    top: 9%; 
                                }
                                @media (max-width: 490px) {  
                                    top: 9%;
                                    left: 60%;
                                } 
                                @media (max-width: 425px) { 
                                    top: 7%;
                                    left: 58%; 
                                } 
                                @media (max-width: 375px) { 
                                    top: 5%;
                                    left: 57%;
                                }  
                                @media (max-width: 320px) { 
                                    top: 3%;
                                    left: 54%;
                                } 
                            }
                            &-projects {
                                top: 10%;
                                left: 83%; 
                                @include floating-animation-3;
                                @media (max-width: 767px) {
                                    top: 9%;
                                    left: 82%;
                                }
                                @media (max-width: 490px) { 
                                    top: 8%;
                                    left: 79%;
                                } 
                                @media (max-width: 425px) {  
                                    top: 7%;
                                    left: 75%;
                                } 
                                @media (max-width: 375px) { 
                                    top: 5%; 
                                }  
                                @media (max-width: 320px) { 
                                    top: 4%;
                                    left: 72%;
                                } 
                            }
                            &-chat {
                                top: 46%;
                                left: 32%; 
                                // @include floating-animation-2;
                                @media (max-width: 767px) {
                                    top: 44%;
                                    left: 34%;
                                } 
                                @media (max-width: 490px) {  
                                    top: 50%;
                                    left: 31%;
                                } 
                                @media (max-width: 425px) { 
                                    top: 51%;
                                    left: 29%;
                                } 
                                @media (max-width: 375px) { 
                                    top: 43%;
                                    left: 28%;
                                }  
                                @media (max-width: 320px) { 
                                    top: 46%;
                                    left: 24%;
                                } 
                            }
                            &-about {
                                top: 55%;
                                left: 51%; 
                                @include floating-animation-2;
                                @media (max-width: 767px) {
                                    top: 53%;
                                } 
                                @media (max-width: 490px) { 
                                    top: 58%;
                                    left: 48%; 
                                } 
                                @media (max-width: 425px) { 
                                    top: 59%;
                                    left: 46%; 
                                } 
                                @media (max-width: 375px) { 
                                    top: 53%;
                                    left: 45%;
                                }  
                                @media (max-width: 320px) {
                                    top: 55%;
                                    left: 42%;
                                } 
                            }  
                            &-brochure {
                                top: 58%;
                                left: 80%; 
                                @include floating-animation-3;
                                @media (max-width: 767px) {
                                    top: 50%;
                                    left: 78%; 
                                } 
                                @media (max-width: 490px) { 
                                    top: 60%;
                                    left: 75%;
                                } 
                                @media (max-width: 425px) { 
                                    top: 63%;
                                    left: 73%; 
                                } 
                                @media (max-width: 375px) { 
                                    top: 54%;
                                    left: 71%;
                                }  
                                @media (max-width: 320px) { 
                                    top: 60%;
                                    left: 69%;
                                } 
                            } 
                        } 
                        &#menu-melodia2 {
                            &-gallery {
                                top: 115px;
                                left: 30px; 
                                @include floating-animation-2;
                                @media (max-width: 767px) {
                                    top: 89px;
                                } 
                                @media (max-width: 490px) {
                                    top: 55px;
                                    left: -1px;  
                                } 
                                @media (max-width: 425px) {  
                                    top: 39px;
                                    left: -12px;
                                } 
                                @media (max-width: 375px) { 
                                    top: 29px;
                                }  
                                @media (max-width: 320px) { 
                                    top: 19px;
                                    left: -20px;
                                } 
                            } 
                            &-tour {
                                top: 10%;
                                left: 62%; 
                                @media (max-width: 767px) {
                                    top: 10%;
                                } 
                                @media (max-width: 490px) {  
                                    left: 61%;
                                } 
                                @media (max-width: 425px) { 
                                    top: 7%;
                                    left: 59% 
                                } 
                                @media (max-width: 375px) { 
                                }  
                                @media (max-width: 320px) { 
                                    top: 0;
                                    left: 54%;
                                } 
                            }
                            &-projects {
                                top: 7%;
                                left: 83%; 
                                @include floating-animation-3; 
                                @media (max-width: 767px) {
                                    top: 6%;
                                    left: 82%;
                                }
                                @media (max-width: 490px) {  
                                    left: 77%;
                                } 
                                @media (max-width: 425px) {  
                                    top: 4%;
                                    left: 76%;
                                } 
                                @media (max-width: 375px) { 
                                    top: 3%;
                                }  
                                @media (max-width: 320px) { 
                                    top: -1%;
                                    left: 72%;
                                } 
                            }
                            &-chat {
                                top: 46%;
                                left: 32%; 
                                // @include floating-animation-2;
                                @media (max-width: 767px) {
                                    top: 45%;
                                    left: 34%;
                                } 
                                @media (max-width: 490px) { 
                                    top: 49%;
                                    left: 29%; 
                                } 
                                @media (max-width: 425px) {  
                                } 
                                @media (max-width: 375px) { 
                                    top: 43%;
                                    left: 27%;
                                }  
                                @media (max-width: 320px) { 
                                    top: 47%;
                                    left: 23%;
                                } 
                            }
                            &-about {
                                top: 55%;
                                left: 51%; 
                                @include floating-animation-2;
                                @media (max-width: 767px) { 
                                    top: 52%;
                                } 
                                @media (max-width: 490px) { 
                                    top: 58%;
                                    left: 48%; 
                                } 
                                @media (max-width: 425px) {  
                                    top: 59%;
                                    left: 46%;
                                } 
                                @media (max-width: 375px) { 
                                    top: 52%;
                                }  
                                @media (max-width: 320px) { 
                                    top: 54%;
                                    left: 43%;
                                } 
                            }  
                            &-brochure {
                                top: 55%;
                                left: 80%; 
                                @include floating-animation-3;
                                @media (max-width: 767px) { 
                                    top: 50%;
                                } 
                                @media (max-width: 490px) { 
                                    top: 57%; 
                                    left: 77%;
                                } 
                                @media (max-width: 425px) {  
                                    top: 59%; 
                                    left: 74%;
                                } 
                                @media (max-width: 375px) { 
                                    top: 51%;
                                    left: 73%;
                                }  
                                @media (max-width: 320px) { 
                                    top: 59%;
                                    left: 70%
                                } 
                            } 
                        }
                        &#menu-clover {
                            &-gallery {
                                top: 87px;
                                left: 53px;
                                @include floating-animation-2;
                                @media (max-width: 767px) {
                                    top: 64px;
                                    left: 40px;
                                }
                                @media (max-width: 490px) {
                                    top: 33px;
                                    left: 20px;
                                } 
                                @media (max-width: 425px) { 
                                    top: 20px;
                                    left: 7px;
                                } 
                                @media (max-width: 375px) { 
                                    top: 16px;
                                    left: 3px;
                                }  
                                @media (max-width: 320px) { 
                                    top: 1px;
                                    left: -8px;
                                } 
                            } 
                            &-tour {
                                top: 10%;
                                left: 68%; 
                                @media (max-width: 767px) { 
                                    top: 9%;
                                    left: 66%;
                                } 
                                @media (max-width: 490px) { 
                                    top: 8%;
                                    left: 64%; 
                                } 
                                @media (max-width: 425px) { 
                                    top: 7%;
                                    left: 61%; 
                                } 
                                @media (max-width: 375px) {
                                    top: 4%;
                                    left: 61%;
                                }  
                                @media (max-width: 320px) {
                                    top: 2%;
                                    left: 58%;
                                }  
                            }
                            &-projects {
                                top: 12%;
                                left: 81%;
                                @include floating-animation-3;
                                @media (max-width: 767px) { 
                                    top: 10%;
                                    left: 79%;
                                } 
                                @media (max-width: 490px) {  
                                    top: 12%;
                                    left: 77%;
                                } 
                                @media (max-width: 425px) {  
                                    top: 9%;
                                } 
                                @media (max-width: 375px) { 
                                    top: 6%;
                                    left: 75%;
                                }  
                                @media (max-width: 320px) { 
                                    top: 2%;
                                    left: 73%;
                                }  
                            }
                            &-chat {
                                top: 42%;
                                left: 37%; 
                                // @include floating-animation-2;
                                @media (max-width: 767px) { 
                                    top: 40%;
                                } 
                                @media (max-width: 490px) {  
                                    top: 44%;
                                    left: 33%;
                                } 
                                @media (max-width: 425px) {
                                    left: 31%;  
                                } 
                                @media (max-width: 375px) { 
                                    top: 39%;
                                }  
                                @media (max-width: 320px) { 
                                    top: 44%;
                                    left: 27%;
                                } 
                            }
                            &-about {
                                top: 49%;
                                left: 56%; 
                                @include floating-animation-2;
                                @media (max-width: 767px) { 
                                    top: 48%;
                                } 
                                @media (max-width: 490px) {  
                                    top: 55%;
                                    left: 52%; 
                                } 
                                @media (max-width: 425px) { 
                                    top: 55%;
                                    left: 50%; 
                                } 
                                @media (max-width: 375px) { 
                                    top: 46%;
                                    left: 47%;
                                }  
                                @media (max-width: 320px) { 
                                    top: 53%;
                                    left: 47%;
                                } 
                            }  
                            &-brochure {
                                top: 55%;
                                left: 80%; 
                                @include floating-animation-3; 
                                @media (max-width: 767px) { 
                                    top: 51%;
                                } 
                                @media (max-width: 490px) {  
                                    top: 58%;
                                } 
                                @media (max-width: 425px) {
                                    left: 78%;  
                                } 
                                @media (max-width: 375px) { 
                                    top: 51%;
                                }  
                                @media (max-width: 320px) { 
                                    top: 60%;
                                    left: 73%;
                                } 
                            } 
                        }
                        &#menu-ipark {
                            &-gallery {
                                top: 7%;
                                left: 1%;
                                @include floating-animation-2;
                                @media (max-width: 767px) {  
                                }
                                @media (max-width: 490px) { 
                                    top: 5%;
                                    left: -2%;
                                } 
                                @media (max-width: 425px) {  
                                    top: 4%;
                                    left: -4%;
                                } 
                                @media (max-width: 375px) {  
                                }  
                                @media (max-width: 320px) {  
                                    left: -8%;
                                } 
                            } 
                            &-tour {
                                top: 12%;
                                left: 65%;
                                @media (max-width: 767px) {  
                                } 
                                @media (max-width: 490px) {  
                                    top: 10%;
                                    left: 62%;
                                } 
                                @media (max-width: 425px) {  
                                } 
                                @media (max-width: 375px) { 
                                    top: 7%;
                                    left: 59%;
                                }  
                                @media (max-width: 320px) { 
                                }  
                            }
                            &-projects {
                                top: 12%;
                                left: 81%;
                                @include floating-animation-3;
                                @media (max-width: 767px) { 
                                    top: 10%;
                                    left: 79%;
                                } 
                                @media (max-width: 490px) {  
                                    top: 12%;
                                    left: 77%;
                                } 
                                @media (max-width: 425px) {  
                                    top: 9%;
                                } 
                                @media (max-width: 375px) { 
                                    top: 6%;
                                    left: 75%;
                                }  
                                @media (max-width: 320px) { 
                                    top: 2%;
                                    left: 73%;
                                }  
                            }
                            &-chat {
                                top: 47%;
                                left: 34%;
                                // @include floating-animation-2;
                                @media (max-width: 767px) {  
                                    top: 44%;
                                } 
                                @media (max-width: 490px) { 
                                    top: 49%;
                                    left: 32%;  
                                } 
                                @media (max-width: 425px) { 
                                    top: 50%;
                                    left: 28%;
                                } 
                                @media (max-width: 375px) {  
                                    top: 42%;
                                }  
                                @media (max-width: 320px) {  
                                    top: 46%;
                                    left: 24%;
                                } 
                            }
                            &-about {
                                top: 54%;
                                left: 51%; 
                                @include floating-animation-2;
                                @media (max-width: 767px) {  
                                    top: 52%;
                                } 
                                @media (max-width: 490px) {  
                                    top: 59%;
                                    left: 48%; 
                                } 
                                @media (max-width: 425px) {  
                                    top: 60%;
                                    left: 46%;
                                } 
                                @media (max-width: 375px) { 
                                    top: 52%;
                                    left: 45%; 
                                }  
                                @media (max-width: 320px) {  
                                    top: 55%;
                                    left: 42%;
                                } 
                            }  
                            &-brochure {
                                top: 52%;
                                left: 78%;
                                @include floating-animation-3; 
                                @media (max-width: 767px) {  
                                    top: 48%;
                                } 
                                @media (max-width: 490px) { 
                                    top: 56%;
                                    left: 74%;  
                                } 
                                @media (max-width: 425px) { 
                                    top: 58%;
                                    left: 71%;
                                } 
                                @media (max-width: 375px) {  
                                    top: 49%;
                                    left: 70%;
                                }  
                                @media (max-width: 320px) { 
                                    top: 57%;
                                    left: 66%; 
                                } 
                            } 
                        }

                        // putrajaya
                        &#menu-putra-dutavilla {
                            &-gallery {
                                top: 4%;
                                left: 6%;
                                @include floating-animation-2;
                                @media (max-width: 767px) {  
                                }
                                @media (max-width: 490px) {  
                                    top: 2%;
                                    left: 2%;
                                } 
                                @media (max-width: 425px) {   
                                } 
                                @media (max-width: 375px) {  
                                }  
                                @media (max-width: 320px) {  
                                    top: -3%;
                                } 
                            } 
                            &-tour {
                                // top: 7%;
                                // left: 56%;
                                top: 4%;
                                left: 50%;
                                @media (max-width: 767px) {  
                                } 
                                @media (max-width: 490px) {  
                                    top: 2%;
                                    left: 47%; 
                                } 
                                @media (max-width: 425px) {
                                    top: -1%; 
                                    left: 44%;  
                                } 
                                @media (max-width: 375px) {  
                                }  
                                @media (max-width: 320px) { 
                                    left: 40%;
                                }  
                            }
                            &-projects {
                                top: 19%;
                                left: 68%;
                                @include floating-animation-3;
                                @media (max-width: 767px) {  
                                } 
                                @media (max-width: 490px) {   
                                    top: 16%;
                                    left: 65%;
                                } 
                                @media (max-width: 425px) {  
                                    left: 64%;
                                } 
                                @media (max-width: 375px) {  
                                    left: 63%;
                                }  
                                @media (max-width: 320px) {
                                    left: 61%;  
                                }  
                            } 
                            &-chat {
                                top: 47%;
                                left: 32%;
                                // @include floating-animation-2;
                                @media (max-width: 767px) {  
                                    top: 44%;
                                } 
                                @media (max-width: 490px) { 
                                    top: 50%;
                                    left: 30%;
                                } 
                                @media (max-width: 425px) { 
                                    top: 50%;
                                    left: 28%;
                                } 
                                @media (max-width: 375px) {  
                                    top: 42%;
                                }  
                                @media (max-width: 320px) {  
                                    top: 46%;
                                    left: 24%;
                                } 
                            }
                            &-about {
                                top: 57%;
                                left: 48%; 
                                @include floating-animation-2;
                                @media (max-width: 767px) {  
                                    top: 52%;
                                } 
                                @media (max-width: 490px) {  
                                    top: 61%;
                                    left: 44%;
                                } 
                                @media (max-width: 425px) {  
                                    top: 61%;
                                    left: 43%;
                                } 
                                @media (max-width: 375px) { 
                                    top: 53%;
                                    left: 42%;
                                }  
                                @media (max-width: 320px) {  
                                    top: 57%;
                                    left: 39%;
                                } 
                            }  
                            &-brochure {
                                top: 52%;
                                left: 78%;
                                @include floating-animation-3; 
                                @media (max-width: 767px) {  
                                    top: 48%;
                                } 
                                @media (max-width: 490px) { 
                                    top: 56%;
                                    left: 74%;  
                                } 
                                @media (max-width: 425px) { 
                                    top: 58%;
                                    left: 71%;
                                } 
                                @media (max-width: 375px) {  
                                    top: 49%;
                                    left: 70%;
                                }  
                                @media (max-width: 320px) { 
                                    top: 57%;
                                    left: 66%; 
                                } 
                            } 
                        }
                        &#menu-putra-aura {
                            &-gallery {
                                top: 4%;
                                left: 6%;
                                @include floating-animation-2;
                                @media (max-width: 767px) {  
                                }
                                @media (max-width: 490px) {  
                                    top: 2%;
                                    left: 2%;
                                } 
                                @media (max-width: 425px) {   
                                } 
                                @media (max-width: 375px) {  
                                }  
                                @media (max-width: 320px) {  
                                    top: -3%;
                                } 
                            } 
                            &-tour {
                                top: 4%;
                                left: 50%;
                                @media (max-width: 767px) {  
                                } 
                                @media (max-width: 490px) {  
                                    top: 2%;
                                    left: 47%; 
                                } 
                                @media (max-width: 425px) {
                                    top: -1%; 
                                    left: 44%;  
                                } 
                                @media (max-width: 375px) {  
                                }  
                                @media (max-width: 320px) { 
                                    left: 40%;
                                } 
                            }
                            &-projects {
                                top: 14%;
                                left: 70%;
                                @include floating-animation-3;
                                @media (max-width: 767px) {  
                                } 
                                @media (max-width: 490px) { 
                                    left: 66%;    
                                } 
                                @media (max-width: 425px) { 
                                    left: 64%;   
                                } 
                                @media (max-width: 375px) { 
                                    left: 63%;   
                                }  
                                @media (max-width: 320px) { 
                                    left: 60%;   
                                }  
                            }
                            &-chat {
                                top: 47%;
                                left: 32%;
                                // @include floating-animation-2;
                                @media (max-width: 767px) {  
                                    top: 44%;
                                } 
                                @media (max-width: 490px) { 
                                    top: 50%;
                                    left: 30%;
                                } 
                                @media (max-width: 425px) { 
                                    top: 50%;
                                    left: 28%;
                                } 
                                @media (max-width: 375px) {  
                                    top: 42%;
                                }  
                                @media (max-width: 320px) {  
                                    top: 46%;
                                    left: 24%;
                                } 
                            }
                            &-about {
                                top: 57%;
                                left: 48%; 
                                @include floating-animation-2;
                                @media (max-width: 767px) {  
                                    top: 52%;
                                } 
                                @media (max-width: 490px) {  
                                    top: 61%;
                                    left: 44%;
                                } 
                                @media (max-width: 425px) {  
                                    top: 61%;
                                    left: 43%;
                                } 
                                @media (max-width: 375px) { 
                                    top: 53%;
                                    left: 42%;
                                }  
                                @media (max-width: 320px) {  
                                    top: 57%;
                                    left: 39%;
                                } 
                            }  
                            &-brochure {
                                top: 52%;
                                left: 78%;
                                @include floating-animation-3; 
                                @media (max-width: 767px) {  
                                    top: 48%;
                                } 
                                @media (max-width: 490px) { 
                                    top: 56%;
                                    left: 74%;  
                                } 
                                @media (max-width: 425px) { 
                                    top: 58%;
                                    left: 71%;
                                } 
                                @media (max-width: 375px) {  
                                    top: 49%;
                                    left: 70%;
                                }  
                                @media (max-width: 320px) { 
                                    top: 57%;
                                    left: 66%; 
                                } 
                            } 
                        }
                        &#menu-putra-florarosa {
                            &-gallery {
                                top: 4%;
                                left: 6%;
                                @include floating-animation-2;
                                @media (max-width: 767px) {  
                                }
                                @media (max-width: 490px) {  
                                    top: 2%;
                                    left: 2%;
                                } 
                                @media (max-width: 425px) {   
                                } 
                                @media (max-width: 375px) {  
                                }  
                                @media (max-width: 320px) {  
                                    top: -3%;
                                } 
                            } 
                            &-tour {
                                top: 4%;
                                left: 50%;
                                @media (max-width: 767px) {  
                                } 
                                @media (max-width: 490px) {  
                                    top: 2%;
                                    left: 47%; 
                                } 
                                @media (max-width: 425px) {
                                    top: -1%; 
                                    left: 44%;  
                                } 
                                @media (max-width: 375px) {  
                                }  
                                @media (max-width: 320px) { 
                                    left: 40%;
                                }  
                            }
                            &-projects {
                                top: 19%;
                                left: 68%;
                                @include floating-animation-3;
                                @media (max-width: 767px) {  
                                } 
                                @media (max-width: 490px) {   
                                    top: 16%;
                                    left: 65%;
                                } 
                                @media (max-width: 425px) {  
                                    left: 64%;
                                } 
                                @media (max-width: 375px) {  
                                    left: 63%;
                                }  
                                @media (max-width: 320px) {
                                    left: 61%;  
                                }  
                            }
                            &-chat {
                                top: 47%;
                                left: 32%;
                                // @include floating-animation-2;
                                @media (max-width: 767px) {  
                                    top: 44%;
                                } 
                                @media (max-width: 490px) { 
                                    top: 50%;
                                    left: 30%;
                                } 
                                @media (max-width: 425px) { 
                                    top: 50%;
                                    left: 28%;
                                } 
                                @media (max-width: 375px) {  
                                    top: 42%;
                                }  
                                @media (max-width: 320px) {  
                                    top: 46%;
                                    left: 24%;
                                } 
                            }
                            &-about {
                                top: 57%;
                                left: 48%; 
                                @include floating-animation-2;
                                @media (max-width: 767px) {  
                                    top: 52%;
                                } 
                                @media (max-width: 490px) {  
                                    top: 61%;
                                    left: 44%;
                                } 
                                @media (max-width: 425px) {  
                                    top: 61%;
                                    left: 43%;
                                } 
                                @media (max-width: 375px) { 
                                    top: 53%;
                                    left: 42%;
                                }  
                                @media (max-width: 320px) {  
                                    top: 57%;
                                    left: 39%;
                                } 
                            }  
                            &-brochure {
                                top: 52%;
                                left: 78%;
                                @include floating-animation-3; 
                                @media (max-width: 767px) {  
                                    top: 48%;
                                } 
                                @media (max-width: 490px) { 
                                    top: 56%;
                                    left: 74%;  
                                } 
                                @media (max-width: 425px) { 
                                    top: 58%;
                                    left: 71%;
                                } 
                                @media (max-width: 375px) {  
                                    top: 49%;
                                    left: 70%;
                                }  
                                @media (max-width: 320px) { 
                                    top: 57%;
                                    left: 66%; 
                                } 
                            } 
                        }
                        &#menu-putra-augusta {
                            &-gallery {
                                top: 4%;
                                left: 6%;
                                @include floating-animation-2;
                                @media (max-width: 767px) {  
                                }
                                @media (max-width: 490px) {  
                                    top: 2%;
                                    left: 2%;
                                } 
                                @media (max-width: 425px) {   
                                } 
                                @media (max-width: 375px) {  
                                }  
                                @media (max-width: 320px) {  
                                    top: -3%;
                                } 
                            } 
                            &-tour {
                                top: 4%;
                                left: 50%;
                                @media (max-width: 767px) {  
                                } 
                                @media (max-width: 490px) {  
                                    top: 2%;
                                    left: 47%; 
                                } 
                                @media (max-width: 425px) {
                                    top: -1%; 
                                    left: 44%;  
                                } 
                                @media (max-width: 375px) {  
                                }  
                                @media (max-width: 320px) { 
                                    left: 40%;
                                } 
                            }
                            &-projects {
                                top: 19%;
                                left: 68%;
                                @include floating-animation-3;
                                @media (max-width: 767px) {  
                                } 
                                @media (max-width: 490px) {   
                                    top: 16%;
                                    left: 65%;
                                } 
                                @media (max-width: 425px) {  
                                    left: 64%;
                                } 
                                @media (max-width: 375px) {  
                                    left: 63%;
                                }  
                                @media (max-width: 320px) {
                                    left: 61%;  
                                }  
                            }
                            &-chat {
                                top: 47%;
                                left: 32%;
                                // @include floating-animation-2;
                                @media (max-width: 767px) {  
                                    top: 44%;
                                } 
                                @media (max-width: 490px) { 
                                    top: 50%;
                                    left: 30%;
                                } 
                                @media (max-width: 425px) { 
                                    top: 50%;
                                    left: 28%;
                                } 
                                @media (max-width: 375px) {  
                                    top: 42%;
                                }  
                                @media (max-width: 320px) {  
                                    top: 46%;
                                    left: 24%;
                                } 
                            }
                            &-about {
                                top: 57%;
                                left: 48%; 
                                @include floating-animation-2;
                                @media (max-width: 767px) {  
                                    top: 52%;
                                } 
                                @media (max-width: 490px) {  
                                    top: 61%;
                                    left: 44%;
                                } 
                                @media (max-width: 425px) {  
                                    top: 61%;
                                    left: 43%;
                                } 
                                @media (max-width: 375px) { 
                                    top: 53%;
                                    left: 42%;
                                }  
                                @media (max-width: 320px) {  
                                    top: 57%;
                                    left: 39%;
                                } 
                            }  
                            &-brochure {
                                top: 52%;
                                left: 78%;
                                @include floating-animation-3; 
                                @media (max-width: 767px) {  
                                    top: 48%;
                                } 
                                @media (max-width: 490px) { 
                                    top: 56%;
                                    left: 74%;  
                                } 
                                @media (max-width: 425px) { 
                                    top: 58%;
                                    left: 71%;
                                } 
                                @media (max-width: 375px) {  
                                    top: 49%;
                                    left: 70%;
                                }  
                                @media (max-width: 320px) { 
                                    top: 57%;
                                    left: 66%; 
                                } 
                            } 
                        }
                    }
                    
                    .project-banner {
                        background: transparent;
                        border: 2px solid #462b5a;
                        animation: blinker 1s linear infinite;
                        // width: 69px;
                        // height: 201px;
                        width: 9.6%;
                        height: 22.2%;
                        transform: skew(0deg,330deg);
                        position: absolute;
                        @media (max-width: 767px) { 
                            width: 9.1%;
                            height: 21%;
                        }
                        @media (max-width: 490px) { 
                            height: 25%;
                        }
                        @media (max-width: 425px) { 
                            height: 26%;
                        }
                        @media (max-width: 375px) { 
                            height: 22%;
                        }
                        @media (max-width: 320px) { 
                            height: 26%;
                        }
                        &.skyseputeh-banner { 
                            &.banner3 { 
                                animation: blinker 0.7s linear infinite;
                                margin-top: 25.2%;
                                margin-left: 89%; 
                            }
                        } 
                        &.melodia2-banner { 
                            &.banner3 { 
                                animation: blinker 0.7s linear infinite;
                                margin-top: 23.2%;
                                margin-left: 88.6%;
                                // height: 23%;
                            }
                        } 
                        &.ipark-banner { 
                            &.banner3 { 
                                animation: blinker 0.7s linear infinite;
                                margin-top: 25.2%;
                                margin-left: 89%; 
                            }
                        } 

                        &.dutavilla-banner {
                            // border-color: #da515a; 
                            &.banner1 {
                                animation: blinker 1.7s linear infinite;
                                margin-top: 5%;
                                margin-left: 74%; 
                            }
                            &.banner2 {
                                margin-top: 9%;
                                margin-left: 84.6%;
                            }
                            &.banner3 { 
                                animation: blinker 0.7s linear infinite;
                                margin-top: 31%;
                                margin-left: 89%; 
                            }

                        } 
                        &.aura-banner {
                            // border-color:#fbb040; 
                            &.banner1 {
                                animation: blinker 0.7s linear infinite;
                                margin-top: 4.5%;
                                margin-left: 74.7%;
                            }
                            &.banner2 {
                                animation: blinker 1.7s linear infinite;
                                margin-top: 7.6%;
                                margin-left: 86.1%;
                            }
                            &.banner3 { 
                                // height: 195px;
                                margin-top: 33%;
                                margin-left: 88.6%; 
                            }

                        } 
                        &.florarosa-banner {
                            // border-color:#fbb040; 
                            &.banner1 {
                                margin-top: 4.3%;
                                margin-left: 73.4%;
                            }
                            &.banner2 {
                                animation: blinker 1.7s linear infinite;
                                margin-top: 8.4%;
                                margin-left: 85%;
                            }
                            &.banner3 { 
                                animation: blinker 0.7s linear infinite;
                                // height: 195px;
                                margin-top: 29.6%;
                                margin-left: 88.6%;
                            }

                        } 
                        &.augusta-banner {
                            // border-color:#fbb040;
                            // height: 195px; 
                            &.banner1 {
                                animation: blinker 0.7s linear infinite;
                                margin-top: 4.2%;
                                margin-left: 70.8%;
                            }
                            &.banner2 {
                                animation: blinker 1.7s linear infinite;
                                // height: 201px; 
                                margin-top: 7.9%;
                                margin-left: 81.2%;
                            }
                            &.banner3 { 
                                // height: 201px;
                                margin-top: 24.9%;
                                margin-left: 89.1%;
                            }

                        } 
                    }

                } 
            }
        } 
    }
    .vpex-popup.menu {
        max-width: 181px;
        width: 100%;
        height: auto;
        background: #462c5a;
        padding: 15px;
        a {
            color: #fff;
            text-decoration: none;
        }
    }
}
