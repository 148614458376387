.partner-content {
    &.news-bottom { 
        pointer-events: auto;
        font-size: 12px;
        margin: 25px 0;
        a {
            display: inherit !important;
        }
        .read-more { 
            display: inline-block; 
            padding: 10px 22px;
            color: #fff;
            text-transform: uppercase;
            font-size: 14px;
            @media (max-width: 490px) {
                padding: 8px 22px;
            }
            &.ecoworld {
                background: #00b398;  
            }
            &.maybank {
                background: #fcc530;
                color: #000;
            }
        }
        p {
            margin-bottom: 11px;
            display: block;
            display: -webkit-box;
            /* max-width: 400px; */
            height: 50px;
            -webkit-line-clamp: 3;
            -webkit-box-orient: vertical;
            overflow: hidden;
            -o-text-overflow: ellipsis;
            text-overflow: ellipsis;
            @media (max-width: 490px) {
                padding: 0 !important;
                display: none;
            }
        }
        .partner-desc {
            display: block; 
            font-size: 21px;
            font-weight: 700;
            margin-bottom: 11px;
            @media (max-width: 564px) {
                font-size: 16px;
            }
        }
        .partner-bottom {
            border-bottom: 1px solid #ccc;
            display: block;
            margin: 10px 0; 
            width: 100%;
        }
        .partner-title {
            text-transform: uppercase;
            letter-spacing: .5px;
            color: #888;
            font-size: 10px;
            font-weight: 400;
            line-height: 20px;
            border-bottom: 1px solid #ccc;
            display: block;
            margin: 20px 0 10px; 
            @media (max-width: 767px) {
                font-size: 13px;
                font-weight: normal;
                padding-bottom: 8px;
                margin: 0 0 12px;
                letter-spacing: 2px;
                line-height: 20px;
            }
            h3 {
                margin-top: 0;
                padding-top: 0;
            }
            // &:before {
            //     content:'sponsored content';
            //     text-transform: capitalize;
            //     color: #ccc;
            // }

        } 
        .row {
            display: flex;
            .column-1, .column-2 {
                flex: 35%;
                padding: 10px;
                // height: 300px; 
            }
            .column-2 {
                flex: 65%;
            }
        } 
        @media (max-width: 768px) {
            padding: 0 20px;
            .col-sm-5 {
                width: 40%;
            }
            .col-sm-7 {
                width: 60%;
            }
        }
    }
}