#homeadvisor {
    .btn {
        position: relative;
        min-width: 200px;
        overflow: hidden;
        color: #fff;
        -webkit-border-radius: 4px;
        -moz-border-radius: 4px;
        border-radius: 4px;
        padding: 15px 0;
        text-indent: 0;
        font-size: inherit;
        line-height: 1;
        font-style: normal;
        text-align: center;
        font-weight: 500;
        background-color: $primary-color1;
        text-transform: uppercase; 
        &:hover  {
            color: #fff !important;
            background-color: darken( $primary-color1, 6% );
            text-decoration: none!important;
            -webkit-box-shadow: none;
            -moz-box-shadow: none;
            box-shadow: none;
        }
        &.white {
            color: $primary-color1;
            background-color: #fff;
            &:hover {
                background-color: $color_trans;
                border:1px solid #fff;
                color: #fff;
            }
        }
         

        &.small {
            min-width: inherit;
            min-width: auto;
            min-width: unset;
            height: 40px;
            padding: 8px 10px 0;
            &.outline {
                border-width: 1px;   
            }
        }
        &.block {
            display: block;
        }
    }
}

