
//Dependencies
.vjs-big-play-button {
  display: none!important;
}
.lg-sub-html {
  font-size: 13px !important;
  @media (min-width: 1024px) {
    width: 85%;
    margin: 0 auto;
  }
  opacity: 0 !important;
  transition: all .1s .1s ease-in-out !important;
  // display: none !important;
  // &.show {
  //   display: block !important;
  // }
  &:hover {
    transition: all .1s .1s ease-in-out !important;
    opacity: 1 !important;
  }
  @media (max-width: $break_tablet) {
      font-size: 1rem !important;
  }
  @media (max-width: $break_m_xx) {
      // display: none;
      background-color: #000 !important;
      padding: 5px 50px 5px 5px !important;
      text-align: left !important;
      font-size: .9rem !important;
  }
}
.lg-img-wrap {
  &:after {
    display: block !important;
    position: fixed;
    width: 100%;
    height: 20px;
    line-height: 21px;
    font-style: italic;
    bottom: 0;
    /* z-index: 99999999; */
    background: rgba(0, 0, 0, 0.29);
    color: #fff;
    /* padding: 20px; */
    margin: 0 auto;
    text-align: center;
    @media (min-width: $break_tablet) {
      content: "hover here for caption";
    }
    @media (max-width: 490px) {
      content:"click here for caption";
    }
  }
}
.lg-outer .lg-toogle-thumb {
  @media (max-width: $break_m_xx) {
      right: 5px !important;
  }

}