// .header-news-wrapper {}
.header-news {
    font-family: 'Poppins', sans-serif;
    background: #fff;
    width: 100%;
    height: 52px;
    // max-width: 1440px;
    // margin: 28px auto 23px; 
    // padding: 0 10px;
    // position: relative;
    // top: 23px;
    z-index: 2;
    border-bottom: 1px solid #eee;
    .header-widget {
        &.content {
            margin-bottom: 0;
        }
        p {
            img {
                margin-bottom: -9px;
            }
        } 
    }
    ul.nav-news-menu {
        padding: 0;
        margin: 0;
        li { 
            // font-weight: 600;
            text-transform: uppercase; 
            padding: 2px 10px;
            font-weight: 400;
            line-height: 1.5; 
            font-size: 1.3rem;
            color: rgba(0, 0, 0, 0.7);
            // padding: 15px 20px;
            display: inline-block;
            &:hover {
                background-color: $link-color-19;
                text-decoration: none;
                color: #FFFFFF !important;
                cursor: pointer;
            }
        }

    }

    /* 4.3 Header Main */
    header {
        position: relative;
        z-index: 995;
        .header-menu {
            position: relative;
            width: 100%;
            margin: 0 0 2rem 0;
            ul#mega-menu-main-menu {
                li {
                    &.active {
                        background-color: $link-color-19;
                        color: #FFFFFF !important;
                    }
                }
            }
        }
    }

    .header-logo {
        padding: 10px 0;
        width: 22%;
        @media (min-width: $break_d_md) and (max-width: 1335px) {
            width: 11%;
        }
    }

    .site-logo {
        line-height: 0;
        .logo-image {
            display: inline-block;
            line-height: 0;
            img {
                position: relative;
                display: inline-block;
                height: 31.5px;
                width: auto; 
            }    
        }
    }

    .colum-iblock .mobile-header-search {
        float: right;
        display: none;
    }

    .mobile-header-search .header-search-mazada i {
        line-height: 75px;
        padding: 0 0 0 14px;
    }

    // .header-layout-style-4 .header-menu-wrap {
    // display: inline-block;
    // }

    .header-menu .header-main-menu,
    .header-menu .mega-menu-wrap {
        float: left;
    }

    .header-search-mazada {
        float: right;
        position: relative;
        right: 23px;
        body.page-events & {
            display: none;
        }
    }
    .header-menu {
        ul {
            ul {
                width: 250px;
                padding: 20px;
                left: -26px;
                li {
                    float: none;
                    a {
                        font-weight: normal;
                        display: block;
                        font-size: 1.5rem;
                        line-height: 18px;
                        margin-bottom: 8px;
                        color: rgba(0, 0, 0, 0.7);
                        text-align: left;
                        padding: 6px;
                        text-transform: capitalize;
                        text-decoration: none;
                    }
                }
                ul {
                    border-top: 0;
                    top: -20px;
                    left: 100%;
                }
            }
            li {
                float: left;
                position: relative;
                a { 
                    font-size: 1.5rem; 
                    color: rgba(0, 0, 0, 0.7); 
                    text-transform: uppercase; 
                    padding: 15px;
                    font-weight: 400;
                    line-height: 1.5; 
                    font-size: 1.3rem;
                    background-color: inherit;
                    &:hover:not(.active), &.active { 
                        background-color: $link-color-19;
                        color: #FFFFFF !important;
                    }
                    &.active {
                        background-color: #2056ab;
                    }
                    @media (min-width: $break_d_md ) and (max-width: 1128px) {
                        padding: 15px; 
                    } 
                }
                &.mega-menu-megamenu {
                    position: initial;
                }
                &.active {
                    background-color: $link-color-19;
                    color: #FFFFFF !important;
                }
            }
        }
    }

    .header-menu ul ul>li:last-of-type>a {
        margin-bottom: 0;
    }

    .header-menu ul li.current-menu-item a,
    .header-menu ul>li:hover>a,
    .mega-menu-wrap ul li.mega-current-menu-item>a {
        color: #808080;
        text-decoration: none;
    }

    .header-search-mazada i {
        display: block;
        width: 37px;
        font-size: 16px;
        line-height: 18px;
        padding: 14px 0 14px 22px;
        cursor: pointer;
    }

    .header-search-mazada .fa-times,
    .header-search-open .fa-search {
        display: none;
    }

    .header-search-open .fa-times {
        display: block;
    }

    .header-search-popup,
    .header-menu ul ul {
        visibility: hidden;
        opacity: 0;
        position: absolute;
        top: 100%;
        right: 50px;
        background: #fff;
        border-top: 1px solid transparent;
        -webkit-background-clip: padding-box;
        /* for Safari */
        background-clip: padding-box;
        /* for IE9+, Firefox 4+, Opera, Chrome */
        z-index: 9999;
        padding: 22px 18px;
        box-shadow: 0 2px 6px rgba(0, 0, 0, .2);
        -webkit-transition: all .2s ease-out;
        -moz-transition: all .2s ease-out;
        -o-transition: all .2s ease-out;
        transition: all .2s ease-out;
        transform: translate3d(0, 20px, 0);
        -webkit-transform: translate3d(0, 20px, 0);
        -moz-transform: translate3d(0, 20px, 0);
        -ms-transform: translate3d(0, 20px, 0);
        -o-transform: translate3d(0, 20px, 0);
    }
    .header-search-popup {
        top: 0%;
        padding: 7px 18px;
        box-shadow: none;
    }

    // .header-layout-style-2 .header-menu ul ul ul {
    //     right: 100%;
    //     left: initial;
    // }

    .header-menu ul li.mega-menu-megamenu .mega-sub-menu {
        width: 100%;
        padding: 0;
        left: 0;
    }

    .header-search-popup.header-search-popup-open,
    .header-menu ul li:hover>ul {
        visibility: visible;
        opacity: 1;
        transform: translate3d(0, 0, 0);
        -webkit-transform: translate3d(0, 0, 0);
        -moz-transform: translate3d(0, 0, 0);
        -ms-transform: translate3d(0, 0, 0);
        -o-transform: translate3d(0, 0, 0);
    }

    .mega-menu-wrap .mega-menu-flyout .mega-sub-menu li:hover>a,
    .header-main-menu .sub-menu li:hover>a {
        color: #1a1a1a;
    }

    .header-search-popup {
        .content {
            margin-bottom: 0;
        }
        .search-input,
        .search-form.content button  {
            font-size: 14px;
            line-height: 18px;
            margin-bottom: 0;
        }
    
    }

    /* 4.5 Mega Menu */

    .megamenu-posts-last,
    .megamenu-categories {
    padding: 24px 12px;
    }

    .megamenu-categories {
    min-height: 255px;
    }

    .mega-block-title {
    display: none;
    }

    .megamenu-categories-container {
    position: relative;
    }

    .megamenu-article-box {
        position: absolute;
        right: 0;
        top: 0;
        width: 80%;
        opacity: 0;
        z-index: 1;
        background-color: #fff;
        -webkit-transition: all .3s ease;
        -moz-transition: all .3s ease;
        -o-transition: all .3s ease;
        transition: all .3s ease;
        transform: translate3d(0, 12px, 0);
        -webkit-transform: translate3d(0, 12px, 0);
        -moz-transform: translate3d(0, 12px, 0);
        -ms-transform: translate3d(0, 12px, 0);
        -o-transform: translate3d(0, 12px, 0);
        &-box-open {
            opacity: 1;
            z-index: 2;
            transform: translate3d(0, 0, 0);
            -webkit-transform: translate3d(0, 0, 0);
            -moz-transform: translate3d(0, 0, 0);
            -ms-transform: translate3d(0, 0, 0);
            -o-transform: translate3d(0, 0, 0);
        }
    }

    .megamenu-post-entry {
        float: left;
        padding: 0 12px;
    }

    .megamenu-cat-box {
        padding: 0 12px;
    }

    .mega-menu-wrap .megamenu-cat-box a { 
        font-size: 14px;
        line-height: 18px;
        margin-bottom: 8px;
        color: #808080;
        display: block;
        text-align: right;
        padding: 6px;
        text-decoration: none;
        -webkit-transition: all .3s ease;
        -moz-transition: all .3s ease;
        -o-transition: all .3s ease;
        transition: all .3s ease;
    }

    .mega-menu-wrap ul li .megamenu-cat-box-hover a {
        background-color: #f2f2f2;
        color: #1a1a1a;
    }

    .widget-post-4 {
    width: 25%;
    }

    .megamenu-cat-box,
    .widget-post-5 {
        width: 20%;
    }

    .widget-post-6 {
    width: 16.66%;
    }

    .megamenu-post-entry {
        h5 {
            font-size: 14px;
            line-height: 18px;
        }
        .entry-thumb {
            margin-bottom: 18px;
            position: relative;
        }
        .cat-meta {
            position: absolute;
            bottom: 28px;
            left: 28px;
        }
    }
    #scroll-header {
        width: 100%;
        /*height: 60px; original, comment for fmh notice*/
        // height: 88px;
        position: fixed;
        z-index: 2001;
        top: 0;
        -webkit-box-shadow: 0px 5px 10px 0px rgba(0, 0, 0, 0.1);
        -moz-box-shadow: 0px 5px 10px 0px rgba(0, 0, 0, 0.1);
        box-shadow: 0px 5px 10px 0px rgba(0, 0, 0, 0.1);
        -webkit-background-clip: padding-box;
        /* for Safari */
        background-clip: padding-box;
        /* for IE9+, Firefox 4+, Opera, Chrome */
        background-color: #fff;
        display: none;
        .header-scroll-logo {
            width: 20%;
            vertical-align: middle;
        } 
        // .scroll-header-menu {
        //     width: 80%;
        //     vertical-align: middle;
        // }
        .site-logo img {
            max-height: 60px;
        }
        .header-menu {
            ul {
                ul {
                    left: initial;
                    right: 0;
                    border-top: 0;
                    ul {
                        right: 100%;
                    }
                }
            } 
        }
        .header-search-mazada i {
            padding: 19px 0 19px 22px;
        }
        .header-main-menu,
        .mega-menu-wrap {
            float: right;
        }
        .header-main-menu,
        .mega-menu-flyout>.mega-sub-menu {
            right: 0;
        }
    }


    .admin-bar #scroll-header {
        top: 32px;
    } 
    @media (max-width: 800px) {
        display: none;
    }
}
// body {
//     &.page-news {
//         &.active {
//             ul#mega-menu-main-menu {
//                 li {
//                     &:first-child {
//                         background-color: $link-color-19;
//                         color: #FFFFFF !important; 
//                     }
//                 }
//             }
//         }
//     }
// }