//------------------------------
// Items
//------------------------------
.i-items {
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;
  &:first-child {
    margin-top: 0;
  }
  &:last-child {
    margin-bottom: 0;
  }
  // Item
  > .item {
    display: flex;
    width: 100%;
    min-height: 0;
    margin: 1rem 0;
    padding: 0;
    &:first-child {
      margin-top: 0;
    }
    &:last-child {
      margin-bottom: 0;
    }
    > .image {
      display: block;
      float: none;
      position: relative;
      flex: 0 0 auto;
      align-self: top;
      > img {
        width: 100%;
      }
      + .content {
        display: block;
        width: auto;
        min-width: 0;
        margin-left: 0;
        padding-left: 1.5rem;
        align-self: top;
      }
    }
    > .content {
      display: block;
      flex: 1 1 auto;
      line-height: 1.16;
      > .title {
        display: inline-block;
        margin: 0;
      }
      > .meta {
        margin: 0.3rem 0 0.5rem;
        font-size: small;
        color: lighten($base-color, 50%);
      }
      > .description {
        margin-top: 0.5rem;
      }
      > .extra {
        font-size: small;
      }
      // Sizing
      &.full {
        width: 100%;
      }
      // Alignment
      &.middle {
        align-self: center;
      }
      &.bottom {
        align-self: flex-end;
      }
    }
  }
  // Style
  &.divided {
    > .item {
      margin: 0;
      padding: 1rem 0;
      border-top: 0.1rem solid rgba($base-color, 0.1);
      &:first-child {
        margin-top: 0;
        padding-top: 0;
        border-top: 0;
      }
      &:last-child {
        margin-bottom: 0;
        padding-bottom: 0;
      }
    }
    &.boxes {
      margin-top: 0;
      margin-bottom: 0;
      > .item {
        padding: 1.5rem;
      }
    }
  }
}

@media only screen and (max-width: 767px) {
  .i-items {
    // Item
    > .item {
      // flex-direction: column;
      > .image {
        + .content {
          // padding: 1rem 0 0;
        }
      }
    }
  }
}