body.page-proppoints {
  &.blueTable {
    background-color: #ffffff;
    width: 100%;
    text-align: left;
    border-collapse: collapse;
      td { 
        // font-family: 'Montserrat';
        &.td-1 {
          @media screen and (min-width: 800px) {
            padding-left: 51px;
            width: 55%;
          }
          padding-left: 10px; 
          padding-top: 10px; 
          padding-bottom: 10px;
        }
        &.td-2 {
          padding: 10px 40px;
          text-align: center;
        }
        
      }
  }
  table.blueTable td, table.blueTable th {
    border: none !important;
    padding: 3px 2px;
  }
  table.blueTable tbody td {
    font-size: 13px;
  }
  table.blueTable tr:nth-child(even) {
    background: #E6F1F4;
  }
  table.blueTable thead {
    background: #1C6EA4;
    background: -moz-linear-gradient(top, #5592bb 0%, #327cad 66%, #1C6EA4 100%);
    background: -webkit-linear-gradient(top, #5592bb 0%, #327cad 66%, #1C6EA4 100%);
    background: linear-gradient(to bottom, #5592bb 0%, #327cad 66%, #1C6EA4 100%);
    border-bottom: 2px solid #444444;
  }
  table.blueTable thead th {
    font-size: 15px;
    font-weight: bold;
    color: #FFFFFF;
    border-left: 2px solid #D0E4F5;
  }
  table.blueTable thead th:first-child {
    border-left: none;
  }
  table.blueTable tfoot {
    font-size: 14px;
    font-weight: bold;
    color: #FFFFFF;
    background: #D0E4F5;
    background: -moz-linear-gradient(top, #dcebf7 0%, #d4e6f6 66%, #D0E4F5 100%);
    background: -webkit-linear-gradient(top, #dcebf7 0%, #d4e6f6 66%, #D0E4F5 100%);
    background: linear-gradient(to bottom, #dcebf7 0%, #d4e6f6 66%, #D0E4F5 100%);
    border-top: 2px solid #444444;
  }
  table.blueTable tfoot td {
    font-size: 14px;
  }
  table.blueTable tfoot .links {
    text-align: right;
  }
  table.blueTable tfoot .links a{
    display: inline-block;
    background: #1C6EA4;
    color: #FFFFFF;
    padding: 2px 8px;
    border-radius: 5px;
  }
  table tr:last-child td:first-child {
    border-bottom-left-radius: 10px;
  }
  table tr:last-child td:last-child {
    border-bottom-right-radius: 10px;
  }
}
 

