@mixin ads_label {
    content: 'Advertisement';
    text-transform: uppercase;
    letter-spacing: 0.5px;
    color: #888;
    font-size: 10px;
    font-weight: 400;
    line-height: 20px;
    display: block;
    text-align: center;
}

#block-block-5,
#block-block-10,
#block-block-11,
#bigleaderboard,
#adInContent {
    div[id^='div-gpt-ad'] {
        &:after {
            @include ads_label;
        }
    }
}
@media (max-width: 991px) {
    #block-block-5,
    #block-block-10,
    #block-block-11 {
        // margin-bottom: 20px;
    }
}