$share-color: #001246;
$sh-fb:        #3b5998;


.share-news-content {
    // display: none;
    position: fixed;
    // bottom: .142857142857143rem;
    bottom: -48px;
    // will-change: transform;
    // transition: transform .5s,-webkit-transform .5s;
    // transform: translate3d(0,50px,0);
    transition: all .4s .2s ease-in-out;
    background: $share-color;
    color: #fff;
    width: 100px;
    border-radius: 30px;
    box-shadow: 0 2px 4px 0 rgba(0,0,0,.14), 0 3px 4px 0 rgba(0,0,0,.12), 0 1px 5px 0 rgba(0,0,0,.2);
    margin: 0 auto;
    height: 43px;
    line-height: 24px;
    text-align: center;
    padding: 10px 0;
    img {
        &.ico {
            width: 20px;
            margin-right: 5px;
            transition: all .4s .2s ease-in-out;
        }
    }
    &.on-click {
        background: #fff;
        color: #fff;
        img {
            &.ico {
                transition: all .4s .2s ease-in-out;
                position: relative;
                right: -22px;
            }
        }
    }
    &.in {
        cursor: pointer;
        // display: block;
        bottom: 27px;
        transition: all .4s .2s ease-in-out;
        // transform: translate3d(0,-24px,0);
        z-index: 1033;
        @media (max-width: $break_m_xx) {
            left: 40%;
        }
        @media (max-width: $break_m_lg) {
            left: 39%;
        }
        @media (max-width: $break_m_md) {
            left: 38%;
        }
        @media (max-width: $break_m_sm ) {
            left: 33%;
        }
        @media (max-width: $break_m_xx) {
            &.catfish-extension {
                bottom: 72px;
                z-index: 9999;
            }
        }
    }
    // &.default {
    //     bottom: .142857142857143rem;
    //     will-change: transform;
    //     transition: transform .5s,-webkit-transform .5s;
    //     transform: translate3d(0,-25px,0);

    // }
    &-box {
        position: fixed;
        top: 0;
        background: rgba(26,26,26,.7);
        -webkit-transition: opacity .4s linear;
        -o-transition: opacity .4s linear;
        /* transition: opacity .4s linear; */
        opacity: 0;
        visibility: hidden;
        width: 100%;
        height: 100vh;
        // left: -100%;
        z-index: 1032;
        &.in {
            // left: 0;
            touch-action: none;
            visibility: inherit;
            opacity: 1;
            transition: all .5s .2s ease-in-out; 
        }
        .share-item {
            // width: 183px;
            width: 40px;
            margin: 0 auto;
            display: table;
            // top: 35%;
            bottom: -100px;
            @media (max-width: $break_m_sm ) {
                bottom: -45px;
            }
            position: relative;
            a {
                background: $share-color;
                color: #fff;
                border-radius: 50%;
                width: 40px;
                height: 40px;
                line-height: 25px;
                text-align: center;
                padding: 8.5px 12px;
                margin: 0 0 10px;
                i {
                    &.fa {
                        font-size: 16px;
                    }
                }
            }
        }
    }
}

.show-safari-bar {
    @media (max-width: $break_m_xx) {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        overflow: scroll;
        -webkit-overflow-scrolling: touch;
        overflow-scrolling: touch;
    }
}