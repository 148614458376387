#homeadvisor {
	.property-listing {
		.listing-box {
			margin-bottom: 25px;
			span {
				display: block; 
				font-size: 12px;
				color: #666;	
				&.img-listing {
					img { 
						width: 100%;
					}
					
				}
				&.prop-name {
					font-weight: 600;
					font-size: 13px; 
					margin-bottom: 10px;
				}
				&.location { 
					color: #888;
				}
				&.type {
					color: #888;
				}
				&.price {
					font-weight: 600;
					font-size: 13px;
					margin: 10px 0;
					span {
						&.permonth {
							font-size: 11px;
							display: inline-block;
							font-weight: 200;
						}
					}

				}
				&.dimension {

				}
			}
			&-info {
				padding: 15px;
				background: $color_grey_lightest;

			}	
		}
		.col-xs-6 {
			@media screen and (max-width: $break_m_lg) {
				width: 100%;
			}
		}
		.col-md-3 {
			@media screen and (min-width: 992px) and (max-width: 1170px) {
				width: 33.333%;
			}
		}
		.col-sm-4 {
			@media screen and (min-width: 769px) and (max-width: 815px) {
				width: 50%;
			}
		}
	}
}