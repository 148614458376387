.catfish-ads {
  .slide-close {
    text-transform: uppercase;
    border-top-left-radius: 3px;
    border-bottom-left-radius: 3px;
    color: #fff;
    font-size: 11px;   
    background: #444;
    top: 0;
    @media (max-width: $break_m_xx) {
      top: -21px;
    }
    position: absolute;
    padding: 3px 10px;
    z-index: 1;
    cursor: pointer;
    &:hover {
      background: lighten($bg-close-btn, 8%);
    }
    i {
      &.material-icons {
        font-size: 15px;
      }
    }
  }  
}
.pop-slide-close {
  text-transform: uppercase;
  border-top-left-radius: 3px;
  border-bottom-left-radius: 3px;
  color: #fff;
  font-size: 11px;   
  background: #444;
  top: 0;
  @media (max-width: $break_m_xx) {
    top: -21px;
  }
  position: absolute;
  padding: 3px 10px;
  z-index: 1;
  cursor: pointer;
  &:hover {
    background: lighten($bg-close-btn, 8%);
  }
  i {
    &.material-icons {
      font-size: 15px;
    }
  }
} 