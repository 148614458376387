//------------------------------
// Button
//------------------------------
.button {
  display: inline-block;
  padding: 1rem 1.8rem;
  background-color: transparent;
  border: 0.1rem solid transparent;
  text-align: center;
  cursor: pointer;
  position: relative;
  border-radius: $button-radius;
  transition: background-color 200ms ease-in-out, color 200ms ease-in-out, border-color 200ms ease-in-out;
  user-select: none;
  &:hover,
  &:active,
  &:focus {
    text-decoration: none;
    outline: none;
  }
  &:active {
    box-shadow: 0 0.3rem 1rem -0.3rem rgba(0,0,0,0.3) inset;
  }
  &[disabled] {
    cursor: not-allowed;
  }
  &.theme {
    background-color: $button-theme-bgcolor;
    color: $button-theme-color;
    border-color: darken($button-theme-bgcolor, 5%);
    &:hover {
      background-color: darken($button-theme-bgcolor, 8%);
      border-color: darken($button-theme-bgcolor, 12%);
    }
    &.ghost {
      border-color: $button-theme-bgcolor;
      color: $button-theme-bgcolor;
    }
  }
  &.theme-alt {
    background-color: $primary-color1;
    color: $button-theme-alt-color;
    border-color: darken($button-theme-alt-bgcolor, 5%);
    &:hover {
      background-color: darken($button-theme-alt-bgcolor, 8%);
      border-color: darken($button-theme-alt-bgcolor, 12%);
    }
    &.ghost {
      border-color: $button-theme-alt-bgcolor;
      color: $button-theme-alt-bgcolor;
    }
  }
  &.default {
    background-color: $button-default-bgcolor;
    color: $button-default-color;
    border-color: darken($button-default-bgcolor, 16%);
    &:hover {
      background-color: darken($button-default-bgcolor, 5%);
      border-color: darken($button-default-bgcolor, 20%);
    }
    &.ghost {
      border-color: $button-default-bgcolor;
      color: $button-default-bgcolor;
      &:hover {
        background-color: $button-default-bgcolor;
        color: $button-dark-bgcolor;
      }
      &.dark {
        border-color: $button-dark-bgcolor;
        color: $button-dark-bgcolor;
        &:hover {
          background-color: $button-dark-bgcolor;
          color: $button-dark-color;
        }
      }
    }
  }
  &.primary {
    background-color: $button-primary-bgcolor;
    color: $button-primary-color;
    border-color: darken($button-primary-bgcolor, 6%);
    &:hover {
      background-color: darken($button-primary-bgcolor, 8%);
      border-color: darken($button-primary-bgcolor, 15%);
    }
    &.ghost {
      color: $button-primary-bgcolor;
    }
  }
  &.success {
    background-color: $button-success-bgcolor;
    color: $button-success-color;
    border-color: darken($button-success-bgcolor, 5%);
    &:hover {
      background-color: darken($button-success-bgcolor, 8%);
      border-color: darken($button-success-bgcolor, 12%);
    }
    &.ghost {
      color: $button-success-bgcolor;
    }
  }
  &.info {
    background-color: $button-info-bgcolor;
    color: $button-info-color;
    border-color: darken($button-info-bgcolor, 10%);
    &:hover {
      background-color: darken($button-info-bgcolor, 16%);
      border-color: darken($button-info-bgcolor, 24%);
    }
    &.ghost {
      color: $button-info-bgcolor;
    }
  }
  &.warning {
    background-color: $button-warning-bgcolor;
    color: $button-warning-color;
    border-color: darken($button-warning-bgcolor, 8%);
    &:hover {
      background-color: darken($button-warning-bgcolor, 8%);
      border-color: darken($button-warning-bgcolor, 12%);
    }
    &.ghost {
      color: $button-warning-bgcolor;
    }
  }
  &.danger {
    background-color: $button-danger-bgcolor;
    color: $button-danger-color;
    border-color: darken($button-danger-bgcolor, 10%);
    &:hover {
      background-color: darken($button-danger-bgcolor, 15%);
      border-color: darken($button-danger-bgcolor, 25%);
    }
    &.ghost {
      color: $button-danger-bgcolor;
    }
  }
  &.ghost {
    background-color: transparent;
    border-width: 0.2rem;
    &:hover {
      border-color: transparent;
      color: $white;
    }
  }
  &.block {
    display: block;
    width: 100%;
  }
  &.nowrap {
    white-space: nowrap;
  }
  &.huge {
    padding: 2rem 3rem;
    font-size: larger;
  }
  &.large {
    padding: 1.5rem 2.5rem;
    font-size: large;
  }
  &.small {
    padding: 0.7rem 1.4rem;
    font-size: small;
  }
  &.tiny {
    padding: 0.4rem 1rem;
    font-size: smaller;
  }
  &.action {
    //- 4.2rem as default button height
    //- 1rem as default padding-top and padding-bottom
    padding-top: (($input-height - 4.2rem) / 2 ) + 1.1rem;
    padding-bottom: (($input-height - 4.2rem) / 2 ) + 1.1rem;
    border: 0;
    background: linear-gradient(to bottom, $secondary-color4 0%, $secondary-color5 100%);
    color: $white;
  }
  &.round {
    border-radius: 5rem;
  }
  &.icon {
    padding-left: 5.8rem;
    text-align: left;
    > .fa,
    > .ion-icon {
      width: 4rem;
      height: 100%;
      border-right-width: 0.1rem;
      border-right-style: solid;
      border-right-color: inherit;
      position: absolute;
      top: 0;
      left: 0;
      &:before {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%,-50%);
      }
    }
    &.right {
      padding-left: 1.8rem;
      padding-right: 5.8rem;
      text-align: right;
      > .fa,
      > .ion-icon {
        border-right: 0;
        border-left-width: 0.1rem;
        border-left-style: solid;
        border-left-color: inherit;
        left: auto;
        right: 0;
      }
    }
    &.block {
      &.center {
        padding-left: 5rem;
        padding-right: 5rem;
        text-align: center;
      }
      &.text {
        &-left {
          text-align: left;
        }
        &-right {
          text-align: right;
        }
      }
    }
  }
  ~ .button {
    &.block {
      margin-top: 0.8rem;
    }
    &:not(.block) {
      margin-left: 0.3rem;
    }
  }
}

// Custom button
.button {
  &.facebook {
    background-color: #3b5998;
    border-color: darken(#3b5998, 10%);
    color: $white;
  }
  &.twitter {
    background-color: #1da1f2;
    border-color: darken(#1da1f2, 10%);
    color: $white;
  }
  &.instagram {
    background-color: #405de6;
    border-color: darken(#405de6, 10%);
    color: $white;
  }
  &.linkedin {
    background-color: #0077b5;
    border-color: darken(#0077b5, 10%);
    color: $white;
  }
  &.google {
    background-color: #4285f4;
    border-color: darken(#4285f4, 10%);
    color: $white;
  }
}

// Styling button
.button {
  &-wall {
    .button {
      margin: 0.3rem;
    }
  }
  &-list {
    .button {
      display: block;
      border: 0;
      border-bottom: 0.1rem solid $border-color;
      text-align: left;
      border-radius: 0;
      transition: none;
      ~ .button { margin-left: 0; }
      &:last-child { border-bottom: 0; }
      &:hover {
        border-color: transparent;
      }
      &:active {
        box-shadow: none;
      }
    }
  }
}

//------------------------------
// Ratings
//------------------------------
.ratings {
  margin-bottom: 0.3rem;
  overflow: hidden;
  vertical-align: middle;
  .fill {
    display: block;
    float: left;
    height: 100%;
    background-position: 0 -2.4rem;
    background-size: 2.4rem;
  }
  &.red .fill { background-position: 0 -4.8rem; }
  &.green .fill { background-position: 0 -7.2rem; }
  &.blue .fill { background-position: 0 -9.6rem; }
  &.dblue .fill { background-position: 0 -12rem; }
  &.purple .fill { background-position: 0 -14.4rem; }
  &.pink .fill { background-position: 0 -16.8rem; }
  &.gold .fill { background-position: 0 -19.2rem; }
  &.black .fill { background-position: 0 100%; }
  // All
  &.star,
  &.love,
  &.poo {
    display: inline-block;
    width: 12rem;
    height: 2.4rem;
    background-repeat: repeat-x;
    background-size: 2.4rem;
  }
  // Star
  &.star,
  &.star .fill {
    background-image: url('../img/rating/star.png');
  }
  // Love
  &.love,
  &.love .fill {
    background-image: url('../img/rating/love.png');
  }
  // Poo
  &.poo,
  &.poo .fill {
    background-image: url('../img/rating/dabian.png');
  }
  &.label {
    padding: 0 0.5rem;
    color: $base-color;
    font-size: $base-font-size;
    font-weight: normal;
  }
}

//------------------------------
// Form
//------------------------------
.i-form {
  // Label
  label {
    @if $debug == 'true' {
      outline: 0.1rem dashed rgba($theme-color,0.5);
    }
    font-size: small;
    font-weight: 400;
    color: $input-color;
    line-height: 1.5;
    .required {
      color: $color-danger;
      font-weight: 700;
      font-size: 0.9em;
    }
    sup {
      top: -0.3em;
    }
  }
  // Input / File / Select
  .input,
  .file,
  .select {
    width: 100%;
    min-height: $input-height;
    margin: 0 0 1.5rem;
    background-color: $input-bg-color;
    border: ($input-border * 0.1rem) $input-border-style $input-border-color;
    border-radius: $input-radius;
    position: relative;
    &.single {
      margin-bottom: 0;
    }
  }
  .input {
    label {
      width: 100%;
      height: 100%;
      margin: 0;
      padding: 0 2rem;
      line-height: $input-height - (($input-border * 0.1rem) * 2);
      opacity: 1;
      position: absolute;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      pointer-events: none;
      transform: translate3d(0,0,0);
    }
    input,
    textarea {
      height: 100%;
      min-height: $input-height - (($input-border * 0.1rem) * 2);
      border: 0;
      background: none;
      color: $input-color;
      outline: none;
      appearance: none;
    }
    input {
      width: 100%;
      padding: 1rem 2rem 0 2rem;
    }
    textarea {
      width: calc(100% - 3rem);
      margin: 1.4rem 2rem;
      vertical-align: middle;
      resize: vertical;
    }
    &.animate {
      label {
        transition: all 300ms ease;
      }
    }
    &.faded {
      input {
        padding-top: 0;
      }
    }
    &.focused {
      &:not(.faded) label {
        opacity: 0.4;
        transform: translate3d(0,-1.4rem,0);
      }
      &.faded {
        label {
          opacity: 0;
        }
      }
    }
    &.error {
      border-color: $color-danger;
      label {
        color: $color-danger;
      }
    }
    &[data-toggle="nav"] {
      cursor: pointer;
      > label {
        > .fa,
        > .ion-icon {
          position: absolute;
          top: 50%;
          right: 1rem;
          color: $input-theme;
          transform: translateY(-50%);
        }
      }
    }
    + .dropdown {
      width: 32rem;
      padding: 1rem;
      background-color: $white;
      position: absolute;
      top: 100%;
      right: 0.5rem;
      z-index: 99;
      opacity: 0;
      visibility: hidden;
      box-shadow: 0 0 0 0.1rem rgba(0,0,0,0.04), 0 0.3rem 1rem -0.2rem rgba(0,0,0,0.1);
      &:before,
      &:after {
        content: '';
        display: block;
        width: 0;
        border-width: 0.7rem;
        border-style: solid;
        position: absolute;
        bottom: 100%;
        right: 1rem;
      }
      &:before {
        border-color: transparent transparent $white;
      }
      &:after {
        border-width: 0.8rem;
        border-color: transparent transparent $grey-color4;
        right: 0.9rem;
        z-index: -1;
      }
      &.active {
        opacity: 1;
        visibility: visible;
      }
    }
  }
  .file {
    label {
      // display: none;
      width: 100%;
      height: 100%;
      margin: 0;
      padding: 0 2rem;
      font-size: small;
      font-weight: 400;
      line-height: $input-height - (($input-border * 0.1rem) * 2);
      background-color: $input-bg-color;
      color: $input-color;
      opacity: 1;
      position: absolute;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      pointer-events: none;
      transform: translate3d(0,0,0);
      transition: opacity 300ms ease-in-out;
      &:after {
        content: '\f255';
        display: block;
        width: 5rem;
        height: 3rem;
        background-color: darken($input-bg-color, 12%);
        color: $white;
        font-size: 3rem;
        font-family: $font-ionic;
        line-height: 3rem;
        text-align: center;
        position: absolute;
        top: 50%;
        right: 1.5rem;
        border-radius: $input-radius;
        transform: translateY(-50%);
      }
    }
    input[type='file'] {
      width: 100%;
      height: 100%;
      min-height: $input-height - (($input-border * 0.1rem) * 2);
      padding: (($input-height - (($input-border * 0.1rem) * 2) - 2.5rem) / 2) 2rem;
      border: 0;
      background: none;
      color: $input-color;
      font-size: small;
      cursor: pointer;
      outline: none;
      appearance: none;
      &::-webkit-file-upload-button {
        padding: 0.5rem 1.5rem;
        border: 0;
        background-color: darken($input-bg-color, 10%);
        color: $input-color;
        cursor: pointer;
        border-radius: $input-radius;
        -webkit-appearance: none;
      }
    }
    &.focused {
      label {
        opacity: 0;
      }
    }
  }
  .select {
    // Three Dot
    // &:after {
    //   content: '';
    //   display: block;
    //   width: 0.4rem;
    //   height: 0.4rem;
    //   background-color: $input-theme;
    //   position: absolute;
    //   top: 50%;
    //   right: 1.5rem;
    //   z-index: 1;
    //   pointer-events: none;
    //   border-radius: 50%;
    //   box-shadow: 0 0.7rem 0 0 $input-theme, 0 -0.7rem 0 0 $input-theme;
    //   transform: translateY(-50%);
    //   transition: all 200ms ease-in-out;
    // }
    // Arrow Down
    &:after {
      content: '';
      display: block;
      width: 0;
      height: 0;
      border-width: 0.6rem;
      border-style: solid;
      border-color: $input-theme transparent transparent;
      position: absolute;
      top: ($input-height / 2) - (0.6rem / 1.8);
      right: 1.5rem;
      z-index: 1;
      pointer-events: none;
    }
    label {
      display: none;
    }
    select {
      width: 100%;
      height: 100%;
      min-height: $input-height - (($input-border * 0.1rem) * 2);
      padding: 1rem 2rem;
      border: 0;
      background: none;
      color: $input-color;
      font-size: small;
      line-height: 100%;
      outline: none;
      appearance: none;
      // Fix IE Arrow
      &::-ms-expand {
        display: none;
      }
    }
    &.focused {
      // Three Dot
      // &:after {
      //   border-radius: 0;
      //   box-shadow: 0 0.4rem 0 0 $input-theme, 0 -0.4rem 0 0 $input-theme;
      // }
      &:after {
        border-color: mix($input-theme, $white, 45%) transparent transparent;
      }
    }
  }
  // Checkbox / Radio
  .checkbox,
  .radio {
    label {
      padding-left: 3rem;
      font-size: small;
      position: relative;
      user-select: none;
      &:before {
        content: '';
        display: block;
        width: 2rem;
        height: 2rem;
        background-color: $input-bg-color;
        border: ($input-border * 0.1rem) $input-border-style $input-border-color;
        position: absolute;
        top: 0;
        left: 0;
        transition: background 200ms ease;
      }
    }
    input {
      display: none;
      visibility: hidden;
      &:checked + label {
        &:after {
          display: block;
          width: 2rem;
          height: 2rem;
          line-height: 2rem;
          text-align: center;
          position: absolute;
          top: 0;
          left: 0;
        }
      }
    }
  }
  .checkbox {
    label {
      &:before {
        border-radius: $input-radius;
      }
      &:hover {
        &:before {
          background-color: darken($input-bg-color, 3%);
        }
      }
    }
    input {
      &:checked + label {
        &:before {
          background-color: $input-theme;
          border-color: transparent;
        }
        &:after {
          content: '\f122';
          color: $input-bg-color;
          font-family: $font-ionic;
          font-weight: normal;
          font-style: normal;
        }
      }
      &:disabled + label {
        cursor: not-allowed;
        &:before {
          background-color: $input-disabled;
          border-color: $input-border-color;
        }
      }
    }
    // Toggle
    &.toggle {
      label {
        padding-left: 5.5rem;
        &:before {
          width: 4rem;
          border-radius: 5rem;
        }
        &:after {
          content: '';
          display: block;
          width: 2rem;
          height: 2rem;
          background-color: darken($input-disabled, 35%);
          position: absolute;
          top: 0;
          left: 0;
          z-index: 1;
          border-radius: 100%;
          transition: margin 200ms ease-in-out;
        }
      }
      input {
        &:checked + label {
          &:before {
            background-color: $input-bg-color;
            border-color: $input-border-color;
          }
          &:after {
            content: '';
            width: 2rem;
            height: 2rem;
            margin-left: 2rem;
            background-color: $input-theme;
          }
        }
        &:disabled + label {
          &:after {
            background-color: darken($input-disabled, 15%);
          }
        }
        &:disabled:checked + label {
          &:after {
            background-color: mix($input-disabled, $input-theme, 35%);
          }
        }
      }
    }
  }
  .radio {
    label {
      &:before {
        border-radius: 100%;
      }
      &:hover {
        &:before {
          background-color: darken($input-bg-color, 3%);
        }
      }
    }
    input {
      &:checked + label {
        &:after {
          content: '';
          width: 1rem;
          height: 1rem;
          margin: 0.5rem;
          background-color: $input-theme;
          border-radius: 100%;
        }
      }
    }
  }
  // Checkboxes / Radios Group
  .checkboxes,
  .radios {
    width: 100%;
    margin-bottom: 1.5rem;
    &.single,
    .radio:last-child {
      margin-bottom: 0;
    }
  }
  .checkboxes {
    &.inline {
      .checkbox {
        display: inline-block;
        + .checkbox {
          margin-left: 1.5rem;
        }
      }
    }
  }
  .radios {
    &.inline {
      .radio {
        display: inline-block;
        + .radio {
          margin-left: 1.5rem;
        }
      }
    }
  }
  // Range
  .input.range {
    background-color: transparent;
    border: 0;
    label {
      display: inline-block;
      width: auto;
      height: auto;
      line-height: 1;
    }
    label,
    input[type='range'] {
      padding-left: 0;
      padding-right: 0;
    }
    input[type='range'] {
      min-height: $input-height;
      padding-top: 0;
      &::-webkit-slider-thumb {
        width: 1.5rem;
        height: 1.5rem;
        margin-top: -0.5rem;
        background-color: $input-theme;
        border-color: transparent;
        cursor: pointer;
        border-radius: 100%;
        box-shadow: 0 0 0 0.3rem $white;
        -webkit-appearance: none;
      }
      &::-moz-range-thumb {
        width: 1.5rem;
        height: 1.5rem;
        margin-top: -0.5rem;
        background-color: $input-theme;
        border-color: transparent;
        cursor: pointer;
        border-radius: 100%;
        box-shadow: 0 0 0 0.3rem $white;
        -moz-appearance: none;
      }
      &::-ms-thumb {
        width: 1.15rem;
        height: 1.15rem;
        margin-top: 0;
        background-color: $input-theme;
        border-color: transparent;
        cursor: pointer;
        border-radius: 100%;
        box-shadow: 0 0 0 0.3rem $white;
        -ms-appearance: none;
      }
      &::-webkit-slider-runnable-track {
        height: 0.5rem;
        background-color: darken($input-bg-color, 5%);
        border-radius: 2rem;
      }
      &::-moz-range-track {
        height: 0.5rem;
        background-color: darken($input-bg-color, 5%);
        border-radius: 2rem;
      }
      &::-ms-track {
        height: 0.25rem;
        background-color: darken($input-bg-color, 5%);
        border-radius: 2rem;
      }
      // Fix firefox outline
      &::-moz-focusring {
        outline: 1px solid #fff;
        outline-offset: -1;
      }
      // IE 10+ Bonus
      &::-ms-fill-upper {
        // background-color: $input-theme;
        border-radius: 2rem;
      }
      &::-ms-fill-lower {
        // background-color: $input-theme;
        border-radius: 2rem;
      }
    }
    // Indicator
    &.indicator {
      > label {
        display: block;
        position: relative;
        overflow: visible;
        &:after {
          content: attr(data-prefix) '\A' attr(data-num) '\A' attr(data-suffix) ;
          float: right;
        }
      }
    }
  }
  // Helper
  .helper {
    &.inline {
      display: inline-block;
      vertical-align: middle;
    }
  }
  // Description
  .description {
    font-style: italic;
    color: $dark-text;
  }
  //---------------
  // Group
  //---------------
  .group {
    .input {
      input {
        padding: 0 1.5rem;
      }
      textarea {
        margin-left: 1.5rem;
        margin-right: 1.5rem;
      }
    }
    .file {
      input {
        padding-left: 1.5rem;
        padding-right: 1.5rem;
      }
    }
    .select {
      &:after {
        right: 1rem;
      }
      select {
        padding: 0 1.5rem;
      }
    }
  }
  //---------------
  // Bootstrap
  //---------------
  .form-control {
    &:focus {
      box-shadow: none;
    }
  }
  &.form-horizontal {
    .form-group {
      .input, .select, .file {
        margin-bottom: 0;
      }
    }
    .control-label {
      padding-top: decimal-floor(($input-height - ($input-border * 2 / 10)) / 3, 1);
      line-height: 1.25;
    }
    // Label position
    &.label-left {
      .control-label {
        text-align: left;
      }
    }
  }
  //---------------
  // Actions
  //---------------
  .actions {
    .button:not(.action) {
      padding-top: (($input-height - 4.2rem) / 2 ) + 1rem;
      padding-bottom: (($input-height - 4.2rem) / 2 ) + 1rem;
    }
  }
}

//------------------------------
// Menu
//------------------------------
.i-menu {
  display: flex;
  max-width: 100%;
  margin: 0;
  background-color: #fff;
  border: 0.1rem solid #e7e7e8;
  overflow-x: auto;
  border-radius: $base-radius;
  .item {
    padding: 1.3rem 1.5rem;
    line-height: 1;
    text-decoration: none;
    position: relative;
    vertical-align: middle;
    -webkit-tap-highlight-color: transparent;
    -webkit-box-flex: 0;
    flex: 0 0 auto;
    user-select: none;
    &:before {
      content: '';
      width: 0.1rem;
      height: 100%;
      background-color: #e7e7e7;
      position: absolute;
      top: 0;
      right: 0;
    }
    &:hover {
      background-color: rgba($theme-color,0.05);
    }
    &.active {
      background-color: rgba($theme-color,0.15);
    }
    > .ion-icon,
    > .fa {
      width: 2rem;
      font-size: 1.6rem;
      text-align: center;
    }
  }
  &:not(.vertical) {
    .item {
      display: flex;
      align-items: center;
      &.active {
        &:after {
          content: '';
          position: absolute;
          bottom: 0;
          left: 0;
          right: 0;
          height: 0.3rem;
          background-color: $theme-color;
        }
      }
    }
  }
  &.vertical {
    display: block;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    flex-direction: column;
    .item {
      display: block;
      border-top: 0;
      &:before {
        width: 100%;
        height: 0.1rem;
        left: 0;
      }
      &:after {
        content: '\f3d3';
        display: block;
        float: right;
        margin-left: 0.5rem;
        font-family: $font-ionic;
        font-weight: 400;
        text-transform: none;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        zoom: 1;
      }
      &:first-child {
        &:before {
          display: none;
        }
      }
    }
  }
  // Style
  &.phat {
    .item {
      padding: 1.8rem 2rem;
    }
  }
}

//------------------------------
// List
//------------------------------
.i-list {
  padding-left: 0;
  list-style: none;
  > li {
    @include clearfix;
    margin-top: -0.1rem;
    padding: 1rem;
    border: 0.1rem solid $border-color;
    position: relative;
  }
  &-label {
    color: $grey-color3;
  }
  &-data {
    color: $theme-color;
    font-weight: 500;
    > span {
      font-weight: normal;
    }
  }
  &-icon {
    float: left;
    margin-left: 0.4rem;
    color: $theme-color-alt;
    line-height: 1;
    .material-icons,
    .fa,
    .ion-icon {
      display: inline-block;
      width: 1em;
      margin-top: -0.06em;
      font-size: 1.5em;
      text-align: center;
    }
    + .i-list-data {
      margin-left: 3.8rem;
    }
  }
  // Double
  &.double {
    margin-left: -0.3rem;
    margin-right: -0.3rem;
    @include clearfix;
    > li {
      float: left;
      width: calc(50% - 0.6rem);
      margin: 0 0.3rem 0.6rem;
    }
  }
  // Style
  &.clean {
    > li {
      padding-left: 0;
      padding-right: 0;
      border-left: 0;
      border-right: 0;
    }
    .i-list {
      &-label {
        &:before {
          display: none;
        }
      }
    }
  }
  // Style
  &.landscape {
    > li {
      display: inline-block;
      text-align: center;
      width: calc(25% - 3px);
      vertical-align: top;
      margin-bottom: 0.4rem; 
    }
    .i-list {
      &-label {
        float: none;        
        padding: 0; 
        width: 100%;       
        &:before {
          display: none;
        }
        + .i-list-data {
          margin-left: 0;
          padding: 0;
        }
      }
    }   
    @media (max-width: 767px) {
      > li{
        width: calc(50% - 2px);
      }
    }
    @media (max-width: 479px) {
      > li{
        width: 100%;
      }
    } 
  }
  
  //- Responsive ---------------------------
  @media (min-width: 767px) {
    &-label {
      float: left;
      width: 20rem;
      padding-right: 1rem;
      &:before {
        content: '';
        border-right: 0.1rem solid $border-color;
        position: absolute;
        top: 0;
        bottom: 0;
        left: 20.9rem;
      }
      + .i-list-data {
        margin-left: 20rem;
        padding-left: 1rem;
      }
    }
  }
}

//------------------------------
// Carousel
//------------------------------
.i-carousel {
  padding-left: 2rem;
  padding-right: 2rem;
  .item {
    position: relative;
    > .caption {
      width: 100%;
      padding: 1.5rem;
      background-color: rgba($base-color, 0.65);
      color: $white;
      position: absolute;
      bottom: 0;
      left: 0;
    }
  }
  // Card
  .i-card {
    border: 0.1rem solid #ddd;
    box-shadow: none;
    > .image {
      transform: translate3d(0,0,0);
    }
    &:hover {
      box-shadow: none;
    }
  }
  // Nav
  .owl-nav {
    i { display: block; }
    .owl-prev,
    .owl-next {
      width: 2rem;
      height: 3.8rem;
      line-height: 3.8rem;
      font-size: 2.8rem;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
    }
    .owl-prev {
      left: 0;
    }
    .owl-next {
      right: 0;
      text-align: right;
    }
    .disabled {
      opacity: 0;
      visibility: hidden;
    }
  }
  // Mobile Carousel (Fake)
  &.nise {
    padding-left: 0;
    padding-right: 0;
    overflow-x: auto;
    overflow-y: hidden;
    white-space: nowrap;
    &::-webkit-scrollbar {
      display: none;
    }
    > .i-card {
      display: inline-block;
      max-width: 46.5%;
      margin-bottom: 0.1rem;
      margin-left: 0.2rem;
      touch-action: manipulation;
      &:first-child {
        margin-left: 0;
      }
    }
  }
}

//------------------------------
// Pagination
//------------------------------
.i-pagination {
  display: inline-flex;
  max-width: 100%;
  margin: 2rem 0;
  padding: 0;
  background-color: #fff;
  list-style: none;
  overflow-x: auto;
  box-shadow: 0 0 0 0.1rem $border-color;
  border-radius: $base-radius;
  li {
    position: relative;
    &:after {
      // content: '';
      display: block;
      width: 0.1rem;
      height: 100%;
      background-color: $border-color;
      position: absolute;
      top: 0;
      right: 0;
      z-index: 1;
    }
    &:last-child {
      &:after {
        display: none;
      }
    }
    a {
      display: block;
      padding: 0.8rem 1.6rem;
      color: $theme-color-alt;
      &:focus,
      &:hover {
        text-decoration: none;
      }
      &:hover {
        background-color: rgba($theme-color-alt, 0.1);
      }
    }
    // Active
    &.active {
      a {
        background-color: rgba($theme-color-alt, 0.85);
        color: $white;
      }
    }
    // Disabled
    &.disabled {
      a {
        color: $grey-color5;
        cursor: not-allowed;
        background-color: $white;
      }
    }
  }
  // Style
  &.lite {
    background-color: transparent;
    box-shadow: none;
    border-radius: 0;
    li {
      margin-left: 0.5rem;
      margin-right: 0.5rem;
      a {
        padding: 0.5rem;
        color: $grey-text;
        text-transform: uppercase;
        &:hover {
          background-color: transparent;
          color: $theme-color-alt;
        }
      }
      // Position
      &:first-child {
        margin-left: 0;
        a {
          padding-left: 0;
        }
      }
      &:last-child {
        margin-right: 0;
        a {
          padding-right: 0;
        }
      }
      // Active
      &.active {
        a {
          background-color: transparent;
          color: $theme-color-alt;
        }
      }
      // Disabled
      &.disabled {
        a {
          background-color: transparent;
          color: $grey-color4;
        }
      }
    }
  }
}

//------------------------------
// Scrollbar
//------------------------------
.i-scrollbar {
  height: 32rem;
}

//------------------------------
// Table
//------------------------------
.i-table {
  margin-bottom: 0;
  border: 0.1rem solid $border-color;
  th,
  td {
    padding: 1rem;
  }
  > thead {
    > tr {
      > th {
        background-color: $grey-color8;
        color: $theme-color;
        border-bottom: 0;
        font-weight: 600;
      }
    }
  }
  > tbody {
    > tr {
      > td {
        border-top-color: $border-color;
      }
      &:nth-child(odd) {
        > td { background-color: $white; }
      }
      &:nth-child(even) {
        > td { background-color: #fffffa; }
      }
    }
  }
}

//------------------------------
// Tabs
//------------------------------
.i-tabs {
  margin-bottom: 1rem;
  background-color: $bg-color;
  border: 0.1rem solid $border-color;
  border-radius: 0.4rem;
  > li {
    > a {
      margin-right: 0;
      padding-top: 1.2em;
      padding-bottom: 1.2em;
      border-radius: 0;
      &:focus,
      &:hover {
        background-color: transparent;
        border-color: transparent;
        color: $theme-color-alt;
      }
    }
    // Active
    &.active {
      > a,
      > a:focus,
      > a:hover {
        border-top: 0;
        border-left-color: $border-color;
        border-right-color: $border-color;
        color: $theme-color-alt;
      }
      &:first-child {
        > a {
          border-left-color: transparent;
        }
      }
      &:last-child {
        > a {
          border-right-color: transparent;
        }
      }
    }
    // Dropdown
    &.dropdown {
      .dropdown-menu {
        border-radius: $base-radius;
      }
      &.open {
        > a {
          border-color: transparent;
          background-color: $grey-color6;
        }
      }
    }
  }
  // Justified
  &.nav-justified {
    border-bottom: 0.1rem solid $border-color;
    border-radius: 0.4rem;
    > li {
      vertical-align: bottom;
      > a {
        margin-bottom: 0;
        border-bottom: 0;
        border-radius: 0;
        white-space: nowrap;
      }
      // Active
      &.active {
        > a,
        > a:focus,
        > a:hover {
          border-top: 0;
          border-left-color: $border-color;
          border-right-color: $border-color;
          color: $theme-color-alt;
        }
        &:first-child {
          > a {
            border-left-color: transparent;
            border-radius: 0.4rem 0 0 0.4rem;
          }
        }
        &:last-child {
          > a {
            border-right-color: transparent;
            border-radius: 0 0.4rem 0.4rem 0;
          }
        }
      }
    }
  }
  // Closest
  &.nestle,
  &[class*='m-b-0'] {
    margin-bottom: -0.1rem;
    border-bottom: 0;
    border-radius: 0.4rem 0.4rem 0 0;
    li {
      &.active {
        &:first-child {
          > a {
            border-radius: 0.4rem 0 0 0;
          }
        }
        &:last-child {
          > a {
            border-radius: 0 0.4rem 0 0;
          }
        }
      }
    }
  }
  //- Pane
  ~ .tab-content {
    .tab-pane {
      padding: 1.5rem;
      border: 0.1rem solid $border-color;
    }
  }
  //- Responsive ---------------------------
  @media (max-width: 767px) {
    &.nav-justified {
      > li {
        float: left;
        &.active {
          > a,
          > a:focus,
          > a:hover {
            border-bottom-color: transparent;
          }
        }
      }
    }
  }
}

//------------------------------
// Price
//------------------------------
.i-price {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  width: 100%;
  margin: 0 auto 2em;
  .pricing {
    &-item {
      position: relative;
      display: flex;
      flex-direction: column;
      align-items: stretch;
      text-align: center;
      flex: 0 1 24rem;
    }
    &-action {
      color: inherit;
      border: none;
      background: none;
      &:focus {
        outline: none;
      }
    }
  }
  // Theme
  &.theme {
    .pricing {
      &-item {
        margin: 1em -0.5em;
      }
      &-deco {
        padding: 2em 0 6em;
        background-color: $theme-color;
        color: $white;
        border-radius: 0.6em 0.6em 0 0;
        position: relative;
      }
      &-list {
        .list-item {
          padding-top: 0.6em;
          padding-bottom: 0.6em;
        }
      }
    }
  }
}