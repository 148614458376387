legend {
	border-bottom: 0;
}

select, input[type="text"] {
	border: 1px solid #bcbcbc;
}

div,
span,
button,
p,
label,
select, 
input[type="text"],
input {
	font-family: $body_font;
}
label {
	font-weight: 400;
}