.page-forum {
  ul {
    &.action-links {
      margin: 0 0 20px 0;
      padding: 0;
      li {
        display: inherit;
      }
    }
  }
}
#forum {
  table {
    width: 100%;
    td {
      &.title {
        padding-bottom: 20px;
        div {
          a {
            color: $theme-color;
          }
        }
        div {
          span {
            &.submitted {
              font-size: 1.0rem;
            }
          }
        }
      }
    }
    th {
      &.active {
        float: right;
        img {
          float: right;
          margin-left: 5px;
        }
      }
    }
  }
}