.navbar-new {
    @media (max-width: 768px) {
        // display: none;
        position: fixed; 
        transition: all .3s .2s ease-in-out;
        // bottom: -48px; 
        &.in, &.out {
            cursor: pointer; 
            transition: all .3s .2s ease-in-out;
        }
        &.in { 
            top: 0; 
        }
        &.out { 
            top: -71px; 
        }
    }  
}