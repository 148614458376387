/* 
fotorama css
*/
.foto-slider{
  background: transparent;
  font-family: inherit;
}
#nl-slider {
  .fotorama__nav-wrap {
    margin-top: -40px;
    .fotorama__nav__shaft {
      background: rgba(0, 0, 0, 0.75);
      border: 4px solid transparent;
      border-top: 0;
      border-bottom: 0;
      box-shadow: 0 4px 10px 2px rgba(0, 0, 0, 0.3);
    }
  }
  .fotorama__stage__frame img {
    height: 100% !important;
    width: 100% !important;
    top: 0 !important;
    object-fit: cover !important;
    }    
  .fotorama__thumb-border {
    border-color: #3b839c;
  }
  .fotorama__stage__shaft {
    .fotorama__loaded .fotorama__img {
      opacity: .25;
      transition: opacity 0.3s;
    }
    .fotorama__active {
      &.fotorama__loaded {
        .fotorama__img {
          opacity: 1;
        }
      }
    }
  }
  .fotorama__active {
    .fotorama__caption {
      opacity: 1;
      height: 0;
    }
    .fotorama__caption__wrap {
      opacity: 1;
    }
  }
  &.fotorama--fullscreen {
    .fotorama__caption {
      opacity: 1;
    }
  }
  .fotorama__arr {
    font-family: FontAwesome;
    font-size: 4rem;
    color: white;
    &.fotorama__arr--next {
      right:2%;
      text-align: center;
      background: none;
      &:before {
        content: '\f054';
      }
    }
    &.fotorama__arr--prev {
      left:2%;
      text-align: center;
      background: none;
      &:before {
        content: '\f053';
      }
    }
  }
  .fotorama__stage__frame {
    &.fotorama__loaded {
      &.fotorama__loaded--img {
        background: black;
      }
    }
  }
  .fotorama__html {
    div, a {
      display: block;
      height: 100%;
    }
  }
  .fotorama__caption {
    top: 0;
  }
  .fotorama__caption__wrap {
    background: rgba(0, 0, 0, 0.65);
    color: white;
    opacity: 0;
    font-size: 2.4rem;
    line-height: 1.2em;
    font-weight: 600;
    /*letter-spacing: 0.02em;*/
    padding: 1.2rem;
    text-transform: uppercase;
    font-family: 'Poppins';
    span {
      display: block;
      font-size: 1.2rem;
      font-weight: 700;
      letter-spacing: 0.05em;
      line-height: 1em;
    }
  }
}
.nl-slider {
  &.activeCaption {
    padding: 1.2rem 1rem 2rem;
    font-size: 0;
    color: white;
    text-align: center;
    .areaCaption {
      &:before {
        content: '•';
        padding: 8px;
      }
    }
  }
  .projectCaption {
    font-weight: 600;
    font-size: 3rem;
    margin-bottom: 1.2rem;
  }
  .areaCaption, .devCaption {
    font-size: 1.4rem;
    font-weight: 400;
    display:inline-block;
    margin-top: 1.8rem;
  }
  &.deco {
    width: 10rem;
    height: 2.5px;
    border: none;
    background-color: white;
    position: absolute;
    left: calc(50% - 5rem);
    margin: 0;
  }
}