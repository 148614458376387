.scrolldown-to-view,
.scrolldown-arrow  {
    position: fixed;
    display: block;
    background-color: #fffffff0;
    width: 100%;
    text-align: center;
    bottom: 0;
}
.scrolldown-to-view {
    font-size: 2rem;
    padding: 5px 0 23px;
    z-index: 999;
}
.scrolldown-arrow {
    background: url(idropdown.png) 50%/2% no-repeat;
    padding: 0px 0 28px;
}