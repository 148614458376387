//------------------------------
// Listing
//------------------------------
.listing {
  //--------------- Listing --------------
  &-filter {
    .i-segment {
      background-color: $even-color;
      box-shadow: none;
    }
  }
  &-items {
    .list-inline {
      margin-bottom: 0.385em;
      padding-bottom: 0.16em;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      &:last-child {
        margin-bottom: 0;
      }
      >li {
        display: inline;
      }
    }
    .listing-row,
    .listing-col {
      height: 100%;
      position: relative;
    }
    .listing-title {
      margin-top: 0;
      margin-bottom: 0.5rem;
      font-size: inherit;
      font-weight: 600;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
    }
    .listing-description {
      font-size: small;
    }
    .listing-footer {
      margin-top: 1.7146em;
      font-size: smaller;
      color: $grey-text;
    }
    .listing-price {
      margin-top: 0;
      margin-bottom: 0;
      font-weight: 600;
      color: $theme-color;
      text-align: right;
    }
    .listing-panel {
      position: absolute;
      bottom: 0;
      right: 0;
      z-index: 1;
    }
    .listing-btn {
      text-align: right;
      font-size: 1.7rem;
      white-space: nowrap;
      >span {
        display: inline-block;
        width: 1.7146em;
        height: 1.7146em;
        line-height: 1.7146em;
        text-align: center;
        color: $grey-color1;
        vertical-align: middle;
        .tooltip {
          white-space: nowrap;
        }
        .agent-phone-number {
          display: none;
          font-size: 0.64em;
        } // Active
        &.active {
          width: auto;
          margin-left: -5rem;
          [data-toggle],
          .agent-phone-number {
            display: block;
            float: left;
          }
        }
      }
    }
    .listing-share {
      position: relative;
      >a {
        color: $grey-color1;
      }
      .dropdown-menu {
        min-width: auto;
        margin-top: 0;
        padding: 0.5rem;
        font-size: 0;
        line-height: 0;
        >li {
          display: inline-block;
          font-size: 1.5rem;
          .ion-icon {
            margin-right: 0;
          }
        }
        &:before {
          right: 0.8rem;
        }
        &:after {
          right: 0.7rem;
        }
      }
    } // Override core i-items
    .i-items {
      >.item {
        >.image {
          background-color: $bg-color;
        }
        >.content {
          align-self: stretch;
          line-height: 1;
        }
      }
    }
  }
  &-enquire {
    &-form {
      padding-left: 2rem;
      padding-right: 2rem;
      padding-bottom: 2rem;
      >header {
        margin-bottom: 3rem;
        h2 {
          margin-top: 0;
          margin-bottom: 0.5rem;
          font-size: 2.2rem;
          color: $theme-color;
          letter-spacing: 0.08rem;
        }
        .list-inline {
          color: $grey-color2;
          .material-icons {
            font-size: 1.286em;
            position: relative;
            top: -0.111em;
          }
          >li {
            padding-left: 0;
            padding-right: 0;
            +li:before {
              content: '|';
              margin-left: 0.6rem;
              margin-right: 0.9rem;
            }
          }
        }
      }
      .form-group>label {
        text-align: left;
      }
    }
  } //- Responsive ---------------------------
  @media (max-width: 767px) {
    &-items {
      margin-left: -0.8rem;
      margin-right: -0.8rem;
      overflow: hidden;
      .listing-col {
        position: relative;
        &:last-child {
          width: 100%;
          height: auto;
          position: absolute;
          bottom: 0;
          left: 0;
        }
      }
      .listing-description {
        font-size: smaller;
        .list-inline {
          margin-bottom: 0.2em;
        }
        .listing-rooms {
          margin-top: 2.06em;
          margin-bottom: 0;
          >li {
            padding-right: 0;
            +li:before {
              display: none !important;
            }
          }
        }
      }
      .listing-price {
        line-height: 1;
      } // Override core i-items
      .i-items {
        >.item {
          >.image {
            width: 14rem;
            +.content {
              padding-left: 0.5rem;
            }
          }
        }
      }
    }
  }
  @media (min-width: 992px) {
    &-items {
      .listing-row {
        display: flex;
      }
      .listing-col {
        height: auto;
      }
    }
  } //--------------- Detail ---------------
  &-slideshow {
    .ls-carousel {
      .owl {
        &-caption {
          padding: 1rem 1.3rem;
          background-color: rgba($base-color, 0.7);
          color: $white;
          position: absolute;
          right: 2rem;
          bottom: 2rem;
          opacity: 0;
          transition: opacity 800ms ease-out;
        }
        &-item {
          height: 45rem;
          .item {
            height: 100%;
            background-color: $grey-color6;
            background-repeat: no-repeat;
            background-position: center center;
            background-size: cover;
          }
          &.active {
            .owl-caption {
              opacity: 1;
            }
          }
        }
        &-nav {
          color: $white;
          i {
            display: block;
            text-align: center;
          }
          .owl-prev,
          .owl-next {
            width: 4.2rem;
            height: 4.2rem;
            padding-left: 1rem;
            padding-right: 1rem;
            line-height: 4.2rem;
            font-size: 1.8rem;
            background-color: rgba($theme-color, 0.8);
            color: $theme-color-alt;
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
          }
          .owl-prev {
            left: 0;
          }
          .owl-next {
            right: 0;
            text-align: right;
          }
          .disabled {
            opacity: 0;
            visibility: hidden;
          }
        }
      }
    }
    .ls-pagination {
      margin-top: 0.5rem;
      .owl-item {
        height: 6.4rem;
        cursor: pointer;
        transition: opacity 300ms ease-in-out;
        .item {
          height: 100%;
          background-color: $base-color;
          background-repeat: no-repeat;
          background-position: center center;
          background-size: contain;
        }
        &.current {
          border: 0.3rem solid $theme-color-alt;
        }
      }
    }
  }
  &-detail {
    .listing {
      &-pane {
        padding: 2rem;
        border: 0.1rem solid $border-color;
        background-color: $white;
      }
      &-header {
        margin-top: 0;
        margin-bottom: 0.8em;
      }
      &-info {
        margin: 0 -2.1rem;
        &:first-child {
          margin-top: -2.1rem;
        }
        a {
          color: $theme-color-alt;
          text-decoration: none;
          &:hover {
            color: $theme-color;
          }
        }
        .li-row {
          display: table;
          width: 100%;
          border: 0.1rem solid $border-color;
          &-1 {
            text-align: center;
            >.li-col {
              border-left: 0.1rem solid $border-color;
              &:first-child {
                border-left: 0;
              }
            }
          }
          &-2 {
            margin-top: -0.1rem;
          }
        }
        .li-col {
          display: table-cell;
          padding: 1.5rem 2rem;
          vertical-align: middle;
        }
        .listing-price {
          text-align: left;
          h2 {
            margin-top: 0;
            margin-bottom: 0.3em;
            color: $theme-color-alt;
            font-weight: 600;
            line-height: 1;
          }
          small {
            display: block;
            color: $grey-text;
            font-size: small;
          }
        }
        .listing-beds,
        .listing-bath,
        .listing-size {
          span {
            display: block;
            line-height: 1;
            +span {
              margin-top: 0.5em;
            }
          }
        }
        .listing-share {
          width: 9rem;
          padding: 0;
          position: relative;
          >a {
            display: block;
            width: 100%;
            height: 100%;
            padding-top: 3.5rem;
            background-color: transparent;
            line-height: 1;
            position: absolute;
            top: 0;
            left: 0;
            box-shadow: none;
            border-radius: 0;
            &:hover {
              background-color: $bg-color;
              color: $theme-color-alt;
            }
          }
          .social-media {
            >li {
              >a {
                color: $white;
              }
            }
          }
        }
        .listing-address {
          margin-top: 0;
          margin-bottom: 0;
          font-size: 1.124em;
          font-style: italic;
          letter-spacing: 0.03em;
          .material-icons {
            font-size: 1.36em;
          }
        }
        .listing-addbookmark {
          text-align: right;
          a {
            font-weight: 700;
          }
        }
      }
      &-meta {
        >h4 {
          margin-top: 2rem;
          font-size: 2.2rem;
        }
        .listing-label {
          float: left;
          width: 18rem;
          position: relative;
          color: $grey-color3;
          &:before {
            content: '';
            border-right: 0.1rem solid $border-color;
            position: absolute;
            top: -1rem;
            bottom: -1rem;
            right: 0;
          }
          .material-icons {
            margin-right: 0.278em;
            color: inherit;
            font-size: 1.286em;
            position: relative;
            top: -0.111em;
          }
        }
        .listing-data {
          margin-left: 18rem;
          padding-left: 1rem;
        }
        .listing-list {
          margin-bottom: 0;
          padding-left: 0;
          list-style: none;
          >li {
            @include clearfix;
            margin-top: -0.1rem;
            padding: 1rem;
            border: 0.1rem solid $border-color;
          }
        }
      }
      &-facilities {
        margin-top: -0.1rem;
        padding: 1.5rem;
        border: 0.1rem solid $border-color;
        .listing-list {
          margin-bottom: 0;
          padding-left: 0;
          list-style: none;
          line-height: 2rem;
          @include clearfix;
          .material-icons {
            color: $theme-color-alt;
            font-size: 2rem;
            position: absolute;
            top: 0.3rem;
            left: 0;
          }
          >li {
            float: left;
            width: 33.3333%;
            padding: 0.3rem 1rem 0.3rem 3rem;
            position: relative;
          }
        }
      }
      &-description {
        margin-top: -0.1rem;
        padding: 1.5rem;
        border: 0.1rem solid $border-color;
        .listing-expandable {
          // height: 26rem;
          font-size: 1.3rem;
          &.active {
            height: auto !important;
          }
          .cleanmode & {
            height: auto;
            [data-toggle='expandable']:after {
              display: none;
            }
          }
        }
      }
    }
  }
  &-datatable {
    padding: 2.5rem 2rem;
    border: 0.1rem solid $border-color;
    background-color: $white;
    .listing-block {
      +.listing-block {
        margin-top: 4rem;
      }
    }
    .listing-display {
      margin-top: -1.5rem;
      .form-group {
        margin-left: -0.5rem;
        margin-right: -0.5rem;
        >[class*="col"] {
          padding-left: 0.5em;
          padding-right: 0.5em;
          margin-bottom: 0;
        }
      }
      .control-label {
        width: 9rem;
        color: $theme-color;
        font-weight: 600;
        line-height: 1.4286;
        text-align: left;
        +div {
          float: none;
          width: auto;
          margin-left: 9rem;
        }
      }
      .radios.inline {
        margin-bottom: 0;
        >.radio {
          padding-top: 1.25rem;
          padding-bottom: 1.25rem;
          label {
            color: $theme-color;
            font-weight: 700;
            text-transform: uppercase;
            .area-outlook-detail &,
            .page-property-detail &,
            .page-project-outlook & {
              font-weight: 400;
              text-transform: capitalize;
            }
            &:before {
              border-color: $theme-color;
            }
          }
          +.radio {
            margin-left: 1rem;
          }
        }
      }
    }
  }
  &-agent-contact {
    padding: 2rem;
    border: 0.1rem solid $border-color;
    background-color: $white;
    .block {
      &-title {
        margin-top: -0.2rem;
        margin-bottom: 1.6rem;
        line-height: 1;
      }
    }
    .listing {
      &-info {
        margin: -2rem -2rem 1.5rem;
        padding: 2rem;
        border-bottom: 0.1rem solid #f1f1f1;
        h3 {
          margin-top: 0;
          margin-bottom: 0.5rem;
          font-size: 2.4rem;
          font-weight: 600;
          line-height: 1;
          color: $theme-color;
        }
        p {
          margin-bottom: 0;
          font-size: small;
          font-weight: 600;
          text-transform: uppercase;
          color: $theme-color-alt;
        }
      }
    }
    .agent {
      &-info {
        margin-top: 1.6rem;
        margin-bottom: 1.6rem;
        padding-top: 1.4rem;
        padding-bottom: 1.4rem;
        border-top: 0.1rem solid $border-color;
        border-bottom: 0.1rem solid $border-color;
      }
      &-name {
        margin-top: 0;
        margin-bottom: 0.8rem;
        font-size: 1.5rem;
        font-weight: 600;
        line-height: 1;
      }
      &-id,
      &-company {
        font-size: 1.3rem;
      }
      &-phone-number {
        margin-top: 0.8rem;
        font-size: 1.2rem;
      }
      &-tools {
        display: none;
      }
    }
    .avatar {
      &-text {
        width: 7.5rem;
        height: 7.5rem;
        line-height: 7.5rem;
        font-size: 2.5rem;
        font-weight: 600;
        text-align: center;
        letter-spacing: 0.2rem;
        color: $white;
        background-color: $theme-color;
        border-radius: 0.5rem;
      }
      &-image {
        position: absolute;
        top: 0;
        left: 0;
        width: 7.5rem;
        height: 7.5rem;
        z-index: 1;
        img {
          max-width: 100%;
          height: auto;
        }
      }
    }
    .i-form {
      .input {
        min-height: 4.2rem;
        margin-bottom: 1rem;
        >label {
          font-size: inherit;
          line-height: 4.2rem;
          color: $base-color;
        }
        >input {
          min-height: 4.2rem;
          color: $base-color;
        }
        >textarea {
          padding: 0;
          color: $base-color;
        }
      }
    } // Form Block
    &-form {
      padding: 3rem;
      background-color: $theme-color;
      color: $white;
      .block-title {
        color: inherit;
      }
      .i-form {
        .input,
        .checkbox label:before {
          background-color: transparent;
        }
        label,
        input,
        textarea,
        .help-block {
          color: $white;
        }
      }
    } // Addition Function
    .float-agent-contact {
      display: none;
      width: 100%;
      border-top: 0.1rem solid darken($border-color, 5%);
      background-color: #363a94;//$white;
      color: $white;//$theme-color-alt;
      text-align: center;
      overflow: hidden;
      position: fixed;
      left: 0;
      bottom: 0;
      z-index: 98;
      .button {
        float: left;
        width: (100% / 2);
        margin: 0.7rem 0;
        padding: 0.8rem 0;
        border: 0;
        color: inherit;
        font-size: 1.4rem;
        font-weight: 600;
        letter-spacing: 0.02em;
        line-height: 1;
        border-radius: 0;
        &:active {
          box-shadow: none;
        }
        +.button {
          margin-left: 0;
          border-left: 0.1rem solid darken($border-color, 5%);
        }
        .fa {
          margin-right: 0.1rem;
        }
        &.call {
          .fa {
            color: $white; //$primary-color1;
          }
        }
        &.sms {
          .fa {
            color: $secondary-color4;
          }
        }
        &.whatsapp {
          .fa {
            color: $secondary-color7;
          }
        }
      }
    }
  }
  &-social-sharing {
    padding-top: 0.2rem;
    padding-bottom: 0.2rem;
    @include clearfix;
  }
  &-similiar-property {
    .listing {
      &-price {
        margin-top: 0;
        margin-bottom: 0.35em;
        color: $theme-color-alt;
        font-size: 1.6em;
        line-height: 1;
        a {
          color: inherit;
          text-decoration: none;
          &:hover {
            color: darken($theme-color-alt, 15%);
          }
        }
      }
      &-location,
      &-date {
        margin-top: 0;
        margin-bottom: 0;
        padding-left: 2rem;
        position: relative;
        .material-icons {
          margin-top: -0.4em;
          font-size: inherit;
          position: absolute;
          left: 0;
          top: 50%;
        }
      }
      &-location {
        margin-bottom: 0.2em;
        font-size: 1em;
      }
      &-date {
        font-size: 0.9em;
        color: $grey-text;
      }
      &-buttons {
        display: table;
        width: 100%;
        border-top: 0.1rem solid darken($border-color, 8%);
      }
      &-item {
        display: table-cell;
        width: 25%;
        padding: 0.5rem;
        text-align: center;
        vertical-align: middle;
        &:last-child {
          padding: 0;
        }
        +.listing-item {
          border-left: 0.1rem solid darken($border-color, 8%);
        }
        .button {
          padding-left: 0.5rem;
          padding-right: 0.5rem;
          border: 0;
          border-radius: 0;
          &:active {
            background-color: $border-color;
            box-shadow: none;
          }
        }
      }
    } // Modal Block (Thank You page with similar listing)
    .agent-modal {
      .modal-header {
        .close {
          margin-top: 0;
          margin-right: 0.3em;
          font-size: 2.5rem;
        }
      }
      .modal-body {
        background-color: $bg-color;
        border: 0.1rem solid $border-color;
        header {
          margin-bottom: 1.5rem;
          text-align: center;
          h4 {
            margin-top: 0;
            margin-bottom: 0.5em;
            font-weight: 600;
            line-height: 1.2;
          }
          p {
            font-size: 1.6rem;
          }
        }
      }
      .i-form {
        .checkbox {
          min-height: auto;
          padding-top: 0;
        }
        .button.theme-alt {
          &[disabled],
          &[disabled]:hover {
            background-color: lighten($theme-color-alt, 15%);
            border-color: lighten($theme-color-alt, 10%);
          }
        }
      }
    }
  } //- Responsive ---------------------------
  @media (max-width: 991px) {
    //--------------- Detail ---------------
    &-agent-contact {
      margin-top: 2rem;
    }
  }
  @media (max-width: 767px) {
    //--------------- Detail ---------------
    &-slideshow {
      .ls-carousel {
        .owl {
          &-caption {
            left: 0;
            right: 0;
            bottom: 0;
            background-color: $base-color;
            opacity: 1;
          }
        }
      }
      .ls-pagination {
        margin-top: 0.5rem;
      }
    }
    &-detail {
      .listing {
        &-pane {
          padding: 0;
        }
        &-header {
          margin-bottom: 0;
          padding: 1.5rem;
          line-height: 1.2;
        }
        &-info {
          margin: 0;
          .li-row {
            border-left: 0;
            border-right: 0;
            &-1 {
              >.li-col {
                width: 25%;
                padding: 1.5rem;
                &:first-child {
                  display: table-caption;
                  width: 100%;
                  border-top: 0.1rem solid $border-color;
                }
                &:nth-child(2) {
                  border-left: 0;
                }
              }
            }
            &-2 {
              display: block;
              >.li-col {
                display: block;
                padding: 1.5rem;
              }
            }
          }
          .listing-share {
            >a {
              padding-top: 2.4rem;
            }
          }
          .listing-address {
            font-size: 0.92em;
          }
          .listing-addbookmark {
            padding: 0 !important;
            border-top: 0.1rem solid $border-color;
            background-color: $bg-color;
            text-align: center;
            a {
              display: block;
              padding: 1rem 1.5rem;
            }
          }
        }
        &-meta {
          h4 {
            padding-left: 1.5rem;
            padding-right: 1.5rem;
          }
          .listing-label {
            width: 12.5rem;
            .material-icons {
              margin-right: 0;
            }
          }
          .listing-data {
            margin-left: 12.5rem;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
          }
        }
        &-facilities {
          .listing-list {
            >li {
              width: 50%;
              padding-left: 2.5rem;
            }
          }
        }
        &-facilities,
        &-description {
          padding-left: 1rem;
          padding-right: 1rem;
          border-left: 0;
          border-right: 0;
        }
      }
    }
    &-datatable {
      padding-left: 1.5rem;
      padding-right: 1.5rem;
      .listing-display {
        .control-label,
        .control-label+div {
          float: none;
          margin-left: 0;
          padding-top: 0;
        }
        .control-label {
          bottom: -0.5rem;
          color: $grey-text;
        }
      }
    }
    &-agent-contact {
      padding-left: 1.5rem;
      padding-right: 1.5rem;
      .agent {
        &-tools {
          display: block;
          .nav {
            >li {
              >a {
                padding-top: 1.1rem;
                padding-bottom: 1.2rem;
                color: $white;
              }
              >a,
              >a:focus,
              >a:hover {
                background-color: transparent;
              }
            }
            >li.open {
              >a,
              >a:focus,
              >a:hover {
                background-color: rgba($base-color, 0.25);
              }
            }
          }
          .dropdown-menu {
            min-width: 24rem;
            margin: 0;
            padding: 0.5rem;
            border: 0;
            border-radius: 0;
          }
        }
      }
      .float-agent-contact {
        display: block;
      }
    }
  }
  @media (min-width: 992px) {
    //--------------- Detail ---------------
    &-column {
      &:not(.reset) {
        width: calc(100% - #{$layout-width});
      }
    }
    &-sidebar {
      width: $layout-width;
      .sidebar-affix {
        width: $layout-width - 3rem;
        top: 0;
        &.affix {
          position: fixed !important;
          top: 6.5rem;
        }
      }
    }
  }
  @media (min-width: 1025px) {
    //--------------- Detail ---------------
    &-sidebar {
      .sidebar-affix {
        &.affix {
          top: 8.7rem;
        }
      }
    }
  }
}

//--- Pages ---
.page-property-detail {
  .content-top {
    background-color: $theme-color;
  }
  .content-area {
    background-color: $content-top-bgcolor;
    .i-tabs {
      border-color: darken($border-color, 10%);
      &~.tab-content .tab-pane {
        background-color: $white;
      }
      &.nav-justified {
        >li {
          >a,
          &.active>a {
            border-top: 0;
            border-bottom: 0;
          }
          &.active {
            >a,
            >a:focus,
            >a:hover {
              border-left-color: darken($border-color, 10%);
              border-right-color: darken($border-color, 10%);
            }
            &:first-child>a {
              border-left-color: transparent;
            }
            &:last-child>a {
              border-right-color: transparent;
            }
          }
        }
      }
    }
  }
  .listing-slideshow .ls-carousel,
  .listing-detail .listing-pane,
  .listing-agent-contact,
  .listing-datatable,
  .block-mortgage-calculator,
  .content-area .i-tabs~.tab-content .tab-pane {
    border-color: darken($border-color, 10%);
    box-shadow: 0 0.1rem 0.2rem 0 rgba($base-color, 0.1);
  }
  .listing-slideshow {
    .ls-carousel {
      border-width: 0.1rem;
      border-style: solid;
      background-color: $white;
      .owl-item>.item {
        background-color: transparent;
      }
    }
    .ls-pagination {
      margin-top: 1rem;
    }
  }
  .listing-detail {
    .listing-info {
      margin-left: -2rem;
      margin-right: -2rem;
      .li-row {
        border-left: 0;
        border-right: 0;
      }
    }
  } //- Responsive ---------------------------
  @media (max-width: 767px) {
    padding-bottom: 4.6rem; // Float Agent Contact
    .listing-detail {
      .listing-info {
        margin: 0;
      }
      .listing-meta {
        .listing-list>li {
          border-left: 0;
          border-right: 0;
        }
      }
    }
  }
}

//--------------- Override -------------
// 20170711 -- Listing style (Remove this all to rollback)
.listing {
  @media (max-width: 479px) {
    &-items {
      margin-left: 0;
      margin-right: 0;
      .listing {
        &-title {
          font-size: 1.2em;
          white-space: normal;
          text-overflow: unset;
          overflow: visible;
        }
        &-price {
          color: $theme-color-alt;
        }
      }
      .i-items {
        >.item {
          display: block;
          padding-top: 1.5rem;
          padding-bottom: 1.5rem;
          >.image {
            width: auto;
            +.content {
              padding-top: 0.6rem;
              padding-left: 0;
            }
          }
          &:first-child {
            padding-top: 0;
          }
          &:last-child {
            padding-bottom: 0;
          }
        }
      }
    }
  }
}

// 20171130 -- Page Listing detail (mobile revamp)
.page-property-detail {
  @media (max-width: 767px) {
    // Slideshow
    .listing-slideshow {
      .ls-carousel {
        border: 0;
      }
      +.listing-detail {
        margin-top: 1rem;
      }
    } // Hiding
    .content-top,
    .listing-list,
    .listing-expandable,
    .listing-map>.block-content,
    .listing-agent-contact>.block-title,
    .block-mortgage-calculator>.block-content,
    .listing-datatable .listing-block>.block-content,
    .listing-datatable .listing-display,
    .listing-datatable .listing-table {
      display: none;
    } // Title Consistency (Accordion style)
    .listing-detail {
      .listing-pane {
        border: 0;
      }
    }
    .listing-meta>h4,
    .listing-facilities>h5,
    .listing-description>h5,
    .listing-map>h3,
    .block-mortgage-calculator>.block-title,
    .listing-datatable .block-title {
      padding: 1rem;
      font-size: 1.5rem;
      font-weight: 500;
      color: $theme-color;
    }
    .listing-meta>h4,
    .listing-facilities>h5,
    .listing-description>h5 {
      margin: -1.5rem -1rem;
    }
    .listing-meta>h4 {
      margin: 0;
    } //- Agent Contact
    .listing-agent-contact {
      margin: 0;
      padding: 0;
      border: 0;
      box-shadow: none;
      @media (max-width: 480px) {
        .agent-info {
          margin: 0;
          padding: 0;
          border: 0;
          background: $agent-contact-bgcolor;
          background-position-x: -5rem;
          background-size: 150%;
          position: fixed;
          top: 0;
          left: 10rem;
          right: 0;
          z-index: 100;
          .image {
            width: 3.2rem;
            height: 3.2rem;
            margin: auto 0.6rem;
            border: 0.2rem solid $white;
            overflow: hidden;
            border-radius: 50%;
            .avatar-text,
            .avatar-image {
              width: 100%;
              height: 100%;
            }
            .avatar-text {
              line-height: 3.6rem;
              font-size: 2rem;
              letter-spacing: 0;
            }
          }
          .content {
            padding-left: 0;
            color: white;
            .agent-id,
            .agent-company,
            .agent-phone-number {
              display: none;
            }
            .agent-name {
              margin-bottom: 0;
              padding-top: 1rem;
              padding-bottom: 1rem;
              color: $white;
              font-size: $base-font-size - 0.1;
              font-weight: 300;
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;
              &:before {
                content: 'Contact ';
              }
              a {
                color: inherit;
              }
            }
          }
        }
      }
      #contact-form {
        @media (max-width: 767px) {
          padding: 20px 10px;
          background-color: #f4f7fa;
        }
      }
    } //- Amenities Map
    .listing-map {
      background-color: $white;
      >.block-title {
        margin-bottom: 0;
      }
    } // Mortgage Calculator
    .block-mortgage-calculator {
      border-left: 0;
      border-right: 0;
      border-color: $border-color;
      >.block-title {
        margin: -1.5rem;
      }
    } // Listing Datatable
    .listing-datatable {
      margin-top: -0.1rem !important;
      padding: 0;
      border-color: $border-color;
      border-left: 0;
      border-right: 0;
      .listing-block {
        // border-top: 1px solid $border-color;
        >.block-title {
          margin-bottom: 0;
        }
        >.block-content {
          padding-left: 1rem;
          padding-right: 1rem;
        }
        >.listing-block,
        +.listing-block {
          margin-top: 0;
        }
        >.listing-block {
          border-top: 0;
        }
      }
      .listing-display {
        margin-top: -1rem;
        margin-left: 1rem;
        margin-right: 1rem;
        .form-group {
          margin-bottom: 0;
        }
      }
      >.block-content {
        >.block-title {
          margin-bottom: 0;
        }
      }
    } // Similar Property
    .listing-similiar-property {
      padding-left: 1.5rem;
      padding-right: 1.5rem;
      .i-card {
        >.content {
          padding: 1rem;
        }
      }
      .listing-price {
        font-size: 1.5rem;
        >a {
          display: block;
          text-overflow: ellipsis;
          white-space: nowrap;
          overflow: hidden;
        }
      }
      .listing-location,
      .listing-date {
        padding-left: 0;
        line-height: 1.08333;
        font-size: 1.2rem;
        >.text-ellipsis {
          overflow: visible;
          text-overflow: unset;
          white-space: normal;
        }
      }
      .listing-date {
        margin-top: 0.6em;
        font-size: 1.1rem;
      }
    } // Layout Manipulate
    .content-area {
      .container:nth-child(2) {
        .row {
          >.listing-column {
            padding-left: 0;
            padding-right: 0;
          }
        }
        .row-ld-1 {
          >.listing-sidebar {
            min-height: 0;
          }
        }
      }
    } // Active Boxes
    .listing-meta.active>.listing-list,
    .listing-facilities.active>.listing-list,
    .listing-description.active>.listing-expandable,
    .listing-map.active>.block-content,
    .block-mortgage-calculator.active>.block-content,
    .listing-datatable .active .block-content,
    .listing-datatable .active .listing-display,
    .listing-datatable .active .listing-table {
      display: block;
    }
    .listing-facilities.active>.listing-list,
    .listing-description.active>.listing-expandable,
    .block-mortgage-calculator.active>.block-content {
      margin-top: 1.5rem;
    }
    .listing-description.active>.listing-expandable {
      height: auto;
      padding-bottom: 0.5rem;
      &:after {
        display: none;
      }
      >.readmore {
        display: none;
      }
    } // Zing Block
    .zing-block {
      padding-left: 1.5rem;
      padding-right: 1.5rem;
      margin-bottom: 2.5rem;
    }
  }
}

.page-search-property {
  @media (max-width: 479px) {
    .listing-items {
      overflow: visible;
    }
  }
}

//------------- Fix Safari -------------
.ipad {
  .listing {
    &-items {
      .listing {
        &-row {
          display: table;
          width: 100%;
        }
        &-col {
          height: 100%;
        }
      }
    }
  }
}

// fix clean mode iframe
@media (min-width: 768px) and (max-width: 992px) {

}

.height-initial {
  height: inherit !important;
}
