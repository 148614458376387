
$project-color: #007F38; 
$bg-alt: #efefef;
$link-color: #488BF8;
$dpc-color: #007455;

#contest-page {
	&.township {
		.error {
			color: red;
		  }
		
		  #loader-class {
			text-align: center;
			width: 100%;
		  }
		
		  #loader-img {
			max-width: 40px;
		  }
		
		  #name-error, #email-error, #number-error, #tem-cond-error, #otp-div, #enquiry-submit-otp, #loader-class {
			display: none;
		  }
		
		  #inquiry-button-otp {
			cursor: pointer;
		  }
		
		  .inquiry-button-otp {
			width: 100%;
			margin: 0 auto;
			display: inherit;
			cursor: pointer;
			font-size: 1.9rem;
			line-height: 1.3;
			text-transform: uppercase;
			border: 1px solid #cc0000;
			background: #cc0000;
			color: #fff;
			text-align: center;
			padding: 12px 80px;
			margin-top: 10px;
			display: inline-block;
		}
		.form-to-download {
			padding-top: 20px; 
			a {
				color: inherit;
				text-decoration: none;
			}
			.row {
				display: flex;
				-ms-flex-wrap: wrap;
				flex-wrap: wrap;
				margin-right: -15px;
				margin-left: -15px;
				&:before {
					display: table;
					content: " ";
				}
			}
			[class*=col-] {
				padding-right: 0;
				padding-left: 0;
				position: relative;
				width: 100%;
				min-height: 1px;
			}
			.col-1 {
				-webkit-box-flex: 0;
				-ms-flex: 0 0 8.333333%;
				flex: 0 0 8.333333%;
				max-width: 8.333333%;
			}
			.col-10 {
				-webkit-box-flex: 0;
				-ms-flex: 0 0 83.333333%;
				flex: 0 0 83.333333%;
				max-width: 83.333333%;
			}
			h2 {
				text-transform: none;
				color: #555;
				font-style: italic;
				font-size: 28px;
				text-align: center;
				margin-bottom: 30px;
			}
			background: #efefef;
			.inner-form {
				max-width: 980px;
				margin: 0 auto;
			}
			#moreinfo {
				width: 100%;
				max-width: 500px;
				margin: 0 auto;
				.form-group {
					margin-bottom: 20px;
				}
			}
			.download-box {
				width: 100%;
				max-width: 500px;
				margin: 0 auto;
			}
			label {
				font-size: 11px;
				// text-transform: uppercase;
			}
			.label-pdpa {
				position: relative;
				right: -6px;
				top: -3px;
				margin-right: 8px;
			}
            .inquiry-button {
				width: 100%;
				margin: 0 auto;
				display: inherit;
				cursor: pointer;
				font-size: 1.9rem;
				line-height: 1.3;
				text-transform: uppercase;
				border: 1px solid #007F38;
				background: #007F38;
				color: #fff;
				text-align: center;
				padding: 12px 80px;
				margin-top: 30px;
				display: inline-block;
				cursor: pointer;
			} 
		}
		.page-desc {
			padding: 20px;
			line-height: 2;
			p {
				font-size: 15px;
			}
		}
		.hero { 
			background: url(https://media.edgeprop.my/page/townshipguidebook/township-hero.jpg) no-repeat center;
			-webkit-background-size: cover;
			-moz-background-size: cover;
			-o-background-size: cover;
			background-size: cover;
			height: 685px; 
			@media (max-width: 500px) {
				height: 230px;
			}
			@media (max-width: 320px) {
				height: 198px;
			}
			@media (min-width: 1024px) and (max-width: 1024px) {
				height: 610px;
			}
		}
		footer {
			font-style: normal;
			color: #fff;
			padding: 20px 0;
			text-align: center;
			color: rgba(255, 255, 255, 0.3);
			background-color: #222;
			// margin-bottom: 45px;
			// @media (min-width: 769px) {
			// 	margin-bottom: 74px;
			// }
		}
		.contest-banner {
			display: block;
			padding: 30px 20px;
			text-align: center;
			img {
				display: block;
				width: 100%;
				max-width: 600px;
				text-align: center;
				margin: 0 auto 30px;
			}
		}
	}
	// &.lendlease-page {
	// 	.container {
	// 		@media (min-width: 1200px) {
	// 			max-width: 1190px;
	// 		}
	// 	}
	// 	h1 {
	// 		font-size: 50px;
	// 		font-weight: 100;
	// 	}
	// 	.pre-content {
	// 		img {
	// 			height: 18px;
	// 			margin: 15px 0 15px -2px; 
	// 		}
	// 	}
	// 	.logotype {
	// 		img {
	// 			width: 100%;
	// 			max-width: 125px;
	// 			@media (min-width: 1024px) {
	// 				max-width: 600px;
	// 			}
	// 		}
	// 	}
	// 	#stickyNav {
	// 		background-color: #fff;
	// 		margin-top: 0 !important;
	// 		width: 100%;
	// 		position: fixed;
	// 		top: 0;
	// 		z-index: 1000;
	// 		.navbar-nav > li > a {
	// 			text-decoration: none;
	// 			color: #000;
	// 			color: #000;
	// 			border: 2px solid #000;
	// 			padding: 10px 15px;
	// 			&:active {
	// 				color: #000 !important;
	// 			}
	// 		} 
	// 	}
	// 	.form-to-download {
	// 		padding-top: 20px; 
	// 		a {
	// 			color: inherit;
	// 			text-decoration: none;
	// 		}
	// 		.row {
	// 			display: flex;
	// 			-ms-flex-wrap: wrap;
	// 			flex-wrap: wrap;
	// 			margin-right: -15px;
	// 			margin-left: -15px;
	// 			&:before {
	// 				display: table;
	// 				content: " ";
	// 			}
	// 		}
	// 		[class*=col-] {
	// 			padding-right: 0;
	// 			padding-left: 0;
	// 			position: relative;
	// 			width: 100%;
	// 			min-height: 1px;
	// 		}
	// 		.col-1 {
	// 			-webkit-box-flex: 0;
	// 			-ms-flex: 0 0 8.333333%;
	// 			flex: 0 0 8.333333%;
	// 			max-width: 8.333333%;
	// 		}
	// 		.col-10 {
	// 			-webkit-box-flex: 0;
	// 			-ms-flex: 0 0 83.333333%;
	// 			flex: 0 0 83.333333%;
	// 			max-width: 83.333333%;
	// 		}
	// 		h2 {
	// 			text-transform: none;
	// 			color: #555;
	// 			font-style: italic;
	// 			font-size: 28px;
	// 			text-align: center;
	// 			margin-bottom: 30px;
	// 		}
	// 		background: #efefef;
	// 		.inner-form {
	// 			max-width: 980px;
	// 			margin: 0 auto;
	// 		}
	// 		#moreinfo {
	// 			width: 100%;
	// 			max-width: 500px;
	// 			margin: 0 auto;
	// 			.form-group {
	// 				margin-bottom: 20px;
	// 			}
	// 		}
	// 		.download-box {
	// 			width: 100%;
	// 			max-width: 500px;
	// 			margin: 0 auto;
	// 		}
	// 		label {
	// 			font-size: 11px;
	// 			// text-transform: uppercase;
	// 		}
	// 		.label-pdpa {
	// 			position: relative;
	// 			right: -6px;
	// 			top: -3px;
	// 			margin-right: 8px;
	// 		}
    //         .inquiry-button {
	// 			width: 100%;
	// 			margin: 0 auto;
	// 			display: inherit;
	// 			cursor: pointer;
	// 			font-size: 1.9rem;
	// 			line-height: 1.3;
	// 			text-transform: uppercase;
	// 			border: 1px solid #007F38;
	// 			background: #007F38;
	// 			color: #fff;
	// 			text-align: center;
	// 			padding: 12px 80px;
	// 			margin-top: 30px;
	// 			display: inline-block;
	// 			cursor: pointer;
	// 		} 
	// 	}
	// 	.page-desc {
	// 		padding: 20px;
	// 		line-height: 2;
	// 		p {
	// 			font-size: 15px;
	// 		}
	// 	}
	// 	.hero { 
	// 		background: url(https://media.edgeprop.my/page/townshipguidebook/township-hero.jpg) no-repeat center;
	// 		-webkit-background-size: cover;
	// 		-moz-background-size: cover;
	// 		-o-background-size: cover;
	// 		background-size: cover;
	// 		height: 685px; 
	// 		@media (max-width: 500px) {
	// 			height: 230px;
	// 		}
	// 		@media (max-width: 320px) {
	// 			height: 198px;
	// 		}
	// 		@media (min-width: 1024px) and (max-width: 1024px) {
	// 			height: 610px;
	// 		}
	// 	}
	// 	footer {
	// 		font-style: normal;
	// 		color: #fff;
	// 		padding: 20px 0;
	// 		text-align: center;
	// 		color: rgba(255, 255, 255, 0.3);
	// 		background-color: #222;
	// 		// margin-bottom: 45px;
	// 		// @media (min-width: 769px) {
	// 		// 	margin-bottom: 74px;
	// 		// }
	// 	}
	// 	.contest-banner {
	// 		display: block;
	// 		padding: 30px 20px;
	// 		text-align: center;
	// 		img {
	// 			display: block;
	// 			width: 100%;
	// 			max-width: 600px;
	// 			text-align: center;
	// 			margin: 0 auto 30px;
	// 		}
	// 	}
	// 	section {
	// 		p {
	// 			line-height: calc(24px + (800vw - 2560px)/1080);
	// 			font-size: 17px;
	// 		}
	// 		&.section { 
	// 			&-general {
	// 				.numbering-section {
	// 					position: absolute;
	// 				}
	// 				.numbering-h1 {
	// 					display: block;
	// 					.h1-text, .h1-number {
	// 						// float: right;
	// 						display: inline-block;
	// 					} 
	// 					.h1-text {
	// 						position: relative;
	// 						top: 48px;
	// 						left: -97px;
	// 						line-height: 1.05;
	// 						&.number-2 {
	// 							top: 12px;
	// 						}
	// 					}
	// 				}
	// 				margin-top: 82px;
	// 				padding-top: 0;
	// 				.general-cover {
	// 					padding: 60px 0;
	// 					background-image: url(https://media.edgeprop.my/page/lendlease/ll_header.png);
	// 					background-size: cover;
	// 					background-position: 100% 0;
	// 					height: 100%;
	// 					width: 100%;
	// 					&.cover-2, 
	// 					&.cover-3 {
	// 						background-image: url(https://media.edgeprop.my/page/lendlease/respondents.png);
	// 						// background-position: 50px 150px;
	// 						background-size: contain;
	// 						background-repeat: no-repeat;
	// 					}
	// 					&.cover-3 {
	// 						background-image: url(https://media.edgeprop.my/page/lendlease/point1.png);
	// 						background-position-x: 0;
	// 					}
	// 					&.cover-4 {
	// 						background-image: url(https://media.edgeprop.my/page/lendlease/respondents.png);
	// 						background-position-x: inherit;
	// 					}
	// 				}
	// 				&.section-2 {
	// 					margin-top: 0;
	// 				}
	// 			} 
	// 			// &-second {
	// 			// 	img {
	// 			// 		width: 100%;
	// 			// 	}
	// 			// 	.roc {
	// 			// 		@media (min-width: 992px) {
	// 			// 			column-count: 2;
	// 			// 		}
	// 			// 	}
	// 			// }
	// 			&-third {
	// 				.third-cover {
	// 					// background-image: url(https://www.teamlewis.com/wp-content/themes/Lewis/source-2021/dist/images/trends-consumers.jpg);
	// 					background-size: cover;
	// 					background-position: 100% 0;
	// 					height: 100%;
	// 					width: 100%;
	// 				}
	// 			}
	// 			&-fourth {
	// 				iframe {
	// 					width: 100%;
	// 					height: calc(100vh - 20vh);
	// 					@media (max-width: 991px) {
	// 						height: calc(100vh - 80vh);
	// 					}
	// 				}
	// 			}
	// 			&-fifth {
	// 				strong {
	// 					-webkit-text-fill-color: transparent;
	// 					-webkit-background-clip: text;
	// 					background-image: linear-gradient(90deg,#ffac1b -2.58%,#e10b90 45.27%);
	// 					background-position: 50%;
	// 					background-repeat: no-repeat;
	// 					background-size: cover;
	// 					color: #fff;
	// 					font-weight: 300;
	// 				}
	// 			}
	// 			&-sixth {
	// 				background-color: transparent!important;
	// 				display: flex;
	// 				align-items: center;
	// 				justify-content: center;
	// 				max-width: 1268px;
	// 				margin: 0 auto;
	// 				.column.plx {
	// 					pointer-events: none;
	// 					.only-mobile {
	// 						display: none !important
	// 					}
	// 				}
	// 			}
	// 		}
	// 	}
	// 	h2 {
	// 		text-transform: uppercase;
	// 		width: 100%;
	// 	}
	// 	h3 {
	// 		color: #ec008c!important;
	// 		font-family: apexSansBook,Helvetica,Arial,sans-serif;
	// 		font-size: 30px;
	// 		font-weight: 300;
	// 		letter-spacing: -.806004px;
	// 		line-height: 39px;
	// 		text-transform: none;
	// 	}
	// }
	.brand-footer {
		margin-right: 15px;
		font-size: 12px;
		font-style: italic;
		a {
			color: inherit;
			text-decoration: none;
			&:active {
				color: inherit;
			}
		}
	}
	.fixed-bar {
		position: fixed;
		background: #fff;
		width: 100%;
		padding: 15px 60px;
		bottom: 0;
		left: 0;
		z-index: 4;
		box-shadow: 0 -5px 10px rgba(0,0,0,.24);
		@media (max-width: 991px) { 
		  padding: 10px;
		}
		.align-items-center {
		  align-items: center!important;
		}
		.bar-logo {
		  display: -webkit-box;
		  display: -ms-flexbox;
		  display: flex;
		  -webkit-box-align: center;
		  -ms-flex-align: center;
		  align-items: center;
		  img {
			height: 100%;
			max-height: 50px;
			width: auto;
			@media (max-width: 991px) {
			  height: auto;
			  width: 100%;
			  max-width: 30px;
			}
		  }
		  .bar-text {
			margin-left: 10px;
			font-size: 16px;
			font-style: italic;
			font-weight: 700;
			color: #666;
			@media (max-width: 991px) {
			  font-size: 10px;
			  line-height: 1.2;
			}
		  }
		  @media (max-width: 991px) {
			width: 39%;
		  }
		  @media (min-width: 1024px) and (max-width: 1024px) {
			width: 82%;
		  }
		  @media (min-width: 1025px) {
			width: 52%;
		  }
		}
		.fixed-buttons {
		  .btn {
			color: #fff;
			padding: 8px 20px;
			font-size: 18px;
			letter-spacing: 0;
			border-radius:3px;
			text-decoration: none;
			@media (max-width: 991px) {
			  font-size: 14px;
			  padding: 6px 15px;
	
			}
			&.btn-enquire {
			  background-color: $project-color;
			  border-color: $project-color;
			  &:hover {
				background: darken($project-color, 10%);
			  }
			  a {
				text-decoration: none;
			  }
			}
		  }
	
		}
	  } 
	a {
		color: $theme-color;
		text-decoration: underline;
		&:hover {
			text-decoration: none;
		}
	}
	section {
		.introword {
			font-weight: 800;
			font-size: 2.6rem;
			@media (max-width: $break_tablet) {
				font-size: 2rem;
			}
		}
		padding: 25px 0 50px;
		@media (max-width: $break_tablet) {
			padding: 0px 0 50px;
		}
		&.webform-form {
			padding: 22px 0 15px;
		}
		&.general {
			// border-top: 10px solid #ff006c;
		    position: relative;
		    padding: 50px 0 50px;
		    overflow: hidden;
		} 
		&.top {
			padding: 0;
			.top-bg {
				body.page-airsupplycontest & {
					background: url($as-1349) no-repeat center;
					background-size: cover; 
					// height: 100vh;
					height: 685px;
					@media (min-width: $break_d_lg1) { 
						height: 807px;
					}
					@media (min-width: $break_d_xxx) { 
						height: 955px;
					}
					@media (max-width: $break_tablet) {
						background: url($as-768) no-repeat center;
						height: 390px;
					}
					@media (max-width: $break_m_lg) {
						background: url($as-425) no-repeat center;
						height: 238px;
						margin-top: -11px;
					}
					@media (max-width: $break_m_md) {
						background: url($as-375) no-repeat center;
						//background-size: 100% 100%; 
						//height: 238px;
						margin-top: -23px;
					}
					@media (max-width: $break_m_sam) {
						background: url($as-360) no-repeat center;
						//background-size: 100% 100%; 
						//height: 238px;
						margin-top: -26px;
					}
					@media (max-width: $break_m_sm) {
						background: url($as-320) no-repeat center;
						//background-size: 100% 100%; 
						//height: 238px;
						margin-top: -37px;
					}

				} 
				body.page-ewf &,
				body.page-node-42 & {
					background: url($ewf-1349) no-repeat center;
					background-size: cover; 
					// height: 100vh;
					height: 685px;
					@media (min-width: $break_d_lg1) { 
						height: 807px;
					}
					@media (min-width: $break_d_xxx) { 
						height: 955px;
					}
					@media (max-width: $break_tablet) {
						background: url($ewf-768) no-repeat center;
						height: 390px;
					}
					@media (max-width: $break_m_lg) {
						background: url($ewf-425) no-repeat center;
						height: 238px;
						margin-top: -11px;
					}
					@media (max-width: $break_m_md) {
						background: url($ewf-375) no-repeat center;
						//background-size: 100% 100%; 
						//height: 238px;
						margin-top: -23px;
					}
					@media (max-width: $break_m_sam) {
						background: url($ewf-360) no-repeat center;
						//background-size: 100% 100%; 
						//height: 238px;
						margin-top: -26px;
					}
					@media (max-width: $break_m_sm) {
						background: url($ewf-320) no-repeat center;
						//background-size: 100% 100%; 
						//height: 238px;
						margin-top: -37px;
					}
				}
				body.page-pepscontest &,
				body.page-node-43 &,
				body.page-node-37 & {
					background: url($peps-1349) no-repeat center;
					background-size: cover; 
					// height: 100vh;
					height: 685px;
					@media (min-width: $break_d_lg1) { 
						height: 807px;
					}
					@media (min-width: $break_d_xxx) { 
						height: 955px;
					}
					@media (max-width: $break_tablet) {
						background: url($peps-768) no-repeat center;
						height: 390px;
					}
					@media (max-width: $break_m_lg) {
						background: url($peps-425) no-repeat center;
						height: 238px;
						margin-top: -11px;
					}
					@media (max-width: $break_m_md) {
						background: url($peps-375) no-repeat center;
						//background-size: 100% 100%; 
						//height: 238px;
						margin-top: -23px;
					}
					@media (max-width: $break_m_sam) {
						background: url($peps-360) no-repeat center;
						//background-size: 100% 100%; 
						//height: 238px;
						margin-top: -26px;
					}
					@media (max-width: $break_m_sm) {
						background: url($peps-320) no-repeat center;
						//background-size: 100% 100%; 
						//height: 238px;
						margin-top: -37px;
					}
				}
			}
		}
		&.how-to-win {
			padding: 25px 0 0;
			.title {
				font-weight: 800;
				font-size: 1.7rem;
				margin: 4rem 0;
				&-1 {
					color: $primary-color1;
				}
				&-2 {
					color: #f9cc13;
				}
			}
		}
		&.terms-conditions {
			// padding-bottom: 50px;
			li {
				font-size: 1.2rem;
			}
		}
		ul, ol {
			text-align: left;
			@media (min-width: $break_post_d_sm) {
				margin: 0;
				padding: 0;
			}
			li {
				margin-bottom: 10px;
				span {
					&.step-title {
						text-transform: uppercase;
						display: block;
					    font-weight: 600;
					    font-size: 2rem;
					    color: $primary-color1;
					}
				}
			}
			&.ehc-ul {
				text-align: center;
				@media (min-width: $break_post_d_sm) {
					// min-height: 265px;
				}
			}
		}
		ul {
			li {
				margin-bottom: 25px;
			}
		}
	}
	h2, h3 {
		text-transform: uppercase;
	}
	h5 {
		font-size: 2.8rem;
		text-transform: capitalize;
		font-weight: 800;
		text-align: center;
		// margin: 50px 0 0;
		@media (max-width: $break_tablet) {
			font-size: 2rem;
		}
	}
	h2 {
		margin-bottom: 1rem;
		font-size: 4rem;
		font-weight: 800;
		span {
			&.sub-h2 {
				text-transform: none;
				font-size: 2rem; 
				font-weight: 400;
				display: block;
				margin: 1.5rem 0 2.5rem;
			}
		}
		@media (min-width: $break_post_d_sm) {
		    width: 70%;
		    margin: 0 auto;
		}
	}
	h3 {
		font-size: 1.7rem;
		margin-bottom: 25px;
		@media (max-width: $break_pre_tablet ) {
			padding-top: 18px;
		}
	}
	h4 {
		font-weight: 800;
		margin-top: 40px;
	}
	// footer {
	// 	&.subscribe {
	// 		margin: 35px 0;
	// 		.cny-submit {
	// 			h2 {
	// 				font-size: 2rem;
	// 				@media (min-width: $break_post_d_sm) {
	// 					width: 55%;
	// 					margin: 40px auto 20px;
	// 				}
	// 			}
	// 			.area-email-alternative {
	// 				margin: 20px 0 40px;
	// 			    font-size: 1.5rem;
	// 			}
	// 		}
	// 		.sub-title {
	// 			@media (min-width: $break_post_d_sm) {
	// 				width: 60%;
	// 				margin: 0 auto;
	// 			}
	// 		}
	// 		.subscribe-input  {
	// 			margin: 20px 0;
	// 			.btn-subscribe-input {
	// 				button {
	// 					width: 100%;
	// 				}
	// 			}
	// 			input {
	// 				padding: 6px;
	// 			}
	// 		}
	// 		.area-email-wrapper {
	// 			display: block;
	// 			overflow: auto;
	// 		}
	// 	}
	// }
	.btn {
		&-ehc {
			text-decoration: none;
			background: #ff5122;
			color: #fff;
			padding: 20px 25px;
			font-weight: 800;
			@media (max-width: $break_d_md) {
				// margin-top: -35px; 
				padding: 20px 8px;
			}
			&:hover {
				background: #fff;
				color: #ff5122;
				border: 1px solid #ff5122;
			}
			a {
				text-decoration: none;
				color: #fff;
				&:hover {
					color: #ff5122;
				}
			}
		}
		// &-cny-sm, &-cny-sm-gr {
		// 	background: #ff5122;
		// 	color: #fff;
		// 	&:hover {
		// 		background: #fff;
		// 		color: #ff5122;
		// 		border: 1px solid #ff5122;
		// 	}
		// }
		// &-cny-sm-gr {
		// 	background: $bg_dark;
		// 	&:hover {
		// 		background: #fff;
		// 		color: $bg_dark;
		// 		border: 1px solid $bg_dark;
		// 	}
		// }
		&-border-radius-no {
			border-radius: 0;
		}
	}
	// .a-btn-ehc {

	// }
	input {
		width: 100%;
	}
	li {
		font-size: 1.7rem;
	}
} 