.admin-menu.adminimal-menu .banderole {
  top: 2.9rem;
}
.banderole {
  // height: 550px;
  height: 100vh;
  background: rgba(0,0,0,.65);
  z-index: 3000;
  position: fixed;
  top: 0;
  width: 100%;
  left: -100%;
  transition: all .5s .2s ease-in-out;
  &.in {
    left: 0;
    transition: all .5s .2s ease-in-out;
    .slide-trigger {
      left: -120px;
      transition: all .5s ease-in-out;
    }
  }
  &-content {
    position: relative;
    > div[id^='div-gpt-ad'] {
      display: table;
      margin: 35px auto;
    }
  }
  .slide-trigger {
    position: absolute;
    top: 50%;
    left: 100%;
    height: 250px;
    width: 100px;
    box-shadow: 1px 1px 5px rgba(25, 25, 25, .5);
    transform: translate(0%, -50%);
    cursor: pointer;
    opacity: 0;
    transition: all .5s .55s ease-in-out;
    &.on {
      opacity: 1;
    }
  }
  .slide-close {
    position: absolute;
    top: 0;
    background: $bg-close-btn;
    color: white;
    right: calc((100% - 980px) / 2);
    padding: 0 10px;
    font-size: 28px;
    z-index: 1;
    cursor: pointer;
    &:hover {
      background: lighten($bg-close-btn, 8%);
    }
  }
  .trigger-close {
    position: absolute;
    top: 0px;
    right: -30px;
    background: rgba(13, 13, 13, 0.72);
    padding: 6px 10px 3px;
    .ion-close {
      color: #fff;
    }
    &:hover {
      background: rgba(73, 72, 72, 0.72);
    }
  }
  .trigger-in {
    left: -120px;
    transition: all .5s .2s ease-in-out;
  }
  .cleanmode &,
  .map-search-2018 &,
  .page-iframe-maybank & {
    display: none !important;
  }
}
//banderole responsive
@media (max-width: 1061px) {
  .banderole {
    // height: 569px;
    &-content > div[id^='div-gpt-ad'] {
      margin: 40px auto 0;
    }
    .slide-close {
      right: calc((100% - 700px) / 2);
    }
  }
}
@media (max-width: 767px) {
  .admin-menu.adminimal-menu .banderole {
    top: 0;
  }
}
// @media (max-width: 619px) {
//   .banderole {
//     height: 320px;
//     &-content > div[id^='div-gpt-ad'] {
//       margin: 35px auto;
//     }
//     .slide-close {
//       right: calc((100% - 300px) / 2);
//     }
//   }
// }
@media (max-width: 425px) {
  .banderole {
    &-content > div[id^='div-gpt-ad'] {
      margin: 15px auto 0;
    }
    .slide-close {
      right: calc((100% - 414px) / 2);
    }
  }
}

@media (max-width: 375px) {
  .banderole {
    .slide-close {
      right: calc((100% - 300px) / 2);
    }
  }
}