//------------------------------
// Project
//------------------------------

///// Outlook /////
.project {
  &-nearby {
    .i-segment {
      border: 1px solid $border-color;
      box-shadow: none;
    }
    .more-link {
      font-size: 1em;
      font-weight: 600;
      text-transform: uppercase;
      a {
        text-decoration: none;
      }
    }
  }
  &-trend {
    .highchart {
      &-chart {
        height: 30rem;
      }
    }
  }
  // &-trend {}
  //- Responsive ---------------------------
  @media (max-width: 479px) {
    &-nearby {
      margin-left: -1.6rem;
      margin-right: -1.6rem;
      margin-bottom: -1.6rem;
      background-color: rgba($base-color, 0.03);
      .i-list {
        &.double {
          > li {
            float: none;
            width: auto;
          }
        }
      }
      .i-segment {
        border-radius: 0;
        + .i-segment {
          margin-top: 0.5rem;
        }
      }
    }
    &-trend {
      > h3.color-theme {
        font-size: 1.8rem;
        font-weight: 600;
      }
      .highchart {
        margin-left: -3rem;
        margin-right: -3rem;
        background-color: #fff;
        border-top: 0.1rem solid $border-color;
        border-bottom: 0.1rem solid $border-color;
        &-chart {
          height: 25rem;
        }
      }
    }
  }
}