//------------------------------
// Zing
//------------------------------
$zing-theme: #f3aa11;

.zing {
  &-card {
    background-color: white;
    // box-shadow: 0 0.1rem 0.3rem 0 rgba(0,0,0,0.09);
    border: 1px solid #ddd;
    .image {
      display: block;
      margin-bottom: 0.1rem;
      img {
        width: 100%;
      }
    }
    .thumbnails {
      display: flex;
      margin-left: -0.1rem;
      padding-right: 0.3rem;
      .thumb {
        flex: 0 0 33.33334%;
        margin-left: 0.1rem;
        position: relative;
        &:first-child {
          border-left: 0;
        }
        &:last-child {
          border-right: 0;
        }
        &:before {
          content: '';
          display: block;
          width: 100%;
          padding-bottom: 66.6666%;
        }
        img {
          display: block;
          width: 100%;
          height: auto;
          margin-left: auto;
          margin-right: auto;
          position: absolute;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
        }
        .count {
          &:before {
            content: '+';
            display: inline;
            margin-right: 0.12em;
          }
          width: 100%;
          height: 100%;
          padding-top: 20%;
          padding-bottom: 20%;
          background-color: rgba(0,0,0,0.2);
          color: white;
          font-size: 2rem;
          font-weight: 400;
          line-height: 1;
          text-align: center;
          position: absolute;
          top: 0;
          left: 0;
          z-index: 1;
        }
      }
    }
    .content {
      padding: 1rem 1.2rem;
      .title {
        display: -webkit-box;
        margin-top: 0;
        margin-bottom: 0.5rem;
        font-size: 0.9em;
        font-weight: 600;
        text-overflow: ellipsis;
        overflow: hidden;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 2;
        a { display: block; }
      }
      .company { 
        font-weight: 400;
        line-height: 1.55;
        font-size: 11px;
        color: #999;
      }
    }
  }
  // Layout
  &-cards {
    display: flex;
    flex-wrap: wrap;
    .zing-card {
      width: 100%;
      margin-bottom: 2rem;
      &:last-child {
        margin-bottom: 0;
      }
    }
  }
  &-carousel {
    padding-left: 0;
    padding-right: 0;
    .zing-card {
      margin-bottom: 0.5rem;
      .content {
        height: 7.7rem;
      }
    }
    .owl-nav {
      .owl-prev,
      .owl-next {
        width: 3rem;
        height: 3rem;
        background-color: #fff;
        line-height: 3rem;
        text-align: center;
        overflow: hidden;
        position: absolute;
        top: -3.7rem;
        right: 0;
        transform: translateY(0);
        box-shadow: 0 0.1rem 0.3rem -0.1rem rgba(0,0,0,0.3);
        border-radius: $base-radius;
        i {
          line-height: 3.3rem;
          font-size: 24px;
        }
        &.disabled {
          visibility: visible;
          opacity: 0.3;
          cursor: default;
        }
        &:active:not(.disabled) {
          background-color: $grey-color7;
        }
      }
      .owl-prev {
        left: auto;
        right: 3.4rem;
      }
    }
  }
  // Block
  &-block {
    .block-title {
      margin-bottom: 0.7rem;
      font-size: 1.6rem;
    }
  }
  //- Responsive ---------------------------
  @media (min-width: 480px) {
    &-cards {
      margin-left: -0.8rem;
      margin-right: -0.8rem;
      .zing-card {
        width: calc(33.33334% - 1.6rem);
        margin-left: 0.8rem;
        margin-right: 0.8rem;
        margin-bottom: 0;
      }
    }
    &-block {
      .block-title {
        // font-size: 2rem;
      }
    }
  }
  // View more Block
  &-view-more {
    position: relative;
    .overlay {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background: #fff;
      display: flex;
      cursor: pointer;
      .center {
        flex: 1 1 100%;
        align-self: center;
        img {    
          width: 150px;
          display: block;
          margin: 0 auto 20px;
        }
      }
    }
  }
  // utility
  &-as-3-2 {
    position: relative;
    &:before {
      display: block;
      content: "";
      width: 100%;
      padding-bottom: 66.66667%;
    }
    > * {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
    }
  }
}

// Zing integrated @ Listing items
.listing-items {
  .i-items.divided {
    > .zing-item {
      flex-wrap: wrap;
      padding-top: 2.5rem;
      padding-bottom: 2.5rem;
      .zing-title {
        flex: 0 0 100%;
        margin: -1rem 0 0.7rem;
        font-size: 1.5rem;
        line-height: 2;
      }
      .zing-carousel {
        .zing-card {
          margin-left: 0.2rem;
          margin-right: 0.2rem;
        }
      }
      @media (max-width: 479px) {
        width: auto;
        margin-left: -1.5rem;
        margin-right: -1.5rem;
        padding-left: 1.5rem;
        padding-right: 1.5rem;
        background-color: $border-color;
      }
    }
  }
}