//------------------------------
// News
//------------------------------
$body-color: #212529;

//- Page -----------------------
.page {
  &-newspages {
    .content-area {
      background-color: $news-bg;
    }
  }
  //- Responsive ---------------------------
  @media (min-width: 1200px) {
    &-newspages {
      .content-area {
        .container {
          width: auto;
          max-width: 121rem;
        }
      }
      .content-column {
        padding-right: 3rem;
      }
    }
  }
}

//- Layout ---------------------
.news {
  // Global style
  &-container {
    padding: 2rem;
    border: 0.1rem solid $border-color;
  }
  &-item {
    position: relative;
  }
  &-thumb {
    img {
      display: block;
      max-width: 100%;
      height: auto;
      margin-left: auto;
      margin-right: auto;
    }
  }
  &-title {
    margin-top: 0;
    font-size: 2.4rem;
  }
  &-meta {
    span {
      display: inline-block;
      + span {
        margin-left: 1.2rem;
      }
    }
  }
  // Global detail
  &-details {
    margin-bottom: 4rem;
    // font-family: $font-serif;
    @media (min-width: $break_tablet) and (max-width: $break_tablet) {
      padding: 10px;
    }
    @media (min-width: $break_post_d_sm) {
      max-width: 610px;
      // margin: 0 auto 4rem;
      margin: 0 0 4rem;
    }
    @media (max-width: $break_m_x) {
      padding: 0 5px;
    }
    img {
      max-width: 100%;
      height: auto !important;
    }
    figure {
      // width: min-content; // Not suitable for dynamic width
      margin-top: 0;
      margin-left: auto;
      margin-right: auto;
      margin-bottom: 1.34em;
      position: relative;
      figcaption {
        margin-top: 0.6em;
        // margin-bottom: 3rem;
        font-size: 1.2rem;
        // font-family: $font-sans;
        // font-style: italic;
        // letter-spacing: 0.03em;
        line-height: 1.4;
        
        color: body-color;
        &:before {
          font-family: 'FontAwesome';
          content: '\f05a';
          margin-right: 4px;
          font-style: normal;
          font-size: 1.3rem;
        }
      }
      &.pull-left {
        margin-right: 2rem;
      }
      &.pull-right {
        margin-left: 2rem;
      }
      &.first {
        @if $news-content-spacing > 0 {
          margin-top: -$news-content-spacing;
          margin-left: -$news-content-spacing;
          margin-right: -$news-content-spacing;
          figcaption {
            padding-left: $news-content-spacing;
            padding-right: $news-content-spacing;
          }
        }
        img {
          display: block;
          width: 100%;
          margin-left: auto;
          margin-right: auto;
        }
        // &.edge {
        //   float: left;
        // }
      }
      &.embed-frame {
        padding-bottom: (320 / 600) * 100%;
      }
    }
    .news {
      &-body {
        p {
          a {
            color: $link-color-19;
            &:hover{
              color: $link-color-19;
            }
          }
        }
      }
      &-action {
        margin-top: 1.429em;
        margin-bottom: 2.143em;
        &:last-child {
          margin-top: 2.143em;
        }
        .social-media {
          > li {
            margin-bottom: 0.2143em;
            &.active:not(.close-toggle) {
              display: none;
            }
          }
        }
        // Close Toggle
        .close-toggle {
          .ion-icon {
            display: inline-block;
            font-size: 2.8rem;
            line-height: 1.45;
          }
        }
      }
      &-share {
        display: none;
        margin-left: 0;
        margin-bottom: 0;
        padding-left: 0.5em;
        list-style: none;
        > li {
          padding-left: 0;
          padding-right: 0;
          > a {
            display: block;
            min-width: 3.8rem;
            height: 3.8rem;
            padding-left: 1rem;
            padding-right: 1rem;
            background-color: $grey-color4;
            color: $white;
            line-height: 3.8rem;
            text-align: center;
            border-radius: 3rem;
            &:hover,
            &:focus {
              text-decoration: none;
            }
            > span {
              margin-left: 0.5rem;
              font-family: $font-sans;
            }
          }
        }
      }
      &-date {
        color: $grey-color3;
      }
      &-title {
        color: $base-color;
        font-size: 4rem;
        font-weight: 600;
        line-height: 1.28;
        text-align: center;
      }
      &-author {
        margin-top: 0.5em;
        margin-bottom: 0.5em;
        font-weight: 400;
        a, span {
          font-weight: 600;
        }
      }
      &-cover {
        width: auto;
        margin-top: 2rem;
        margin-bottom: 2rem;
        padding: 0;
        figcaption {
          .material-icons.info {
            margin-top: -0.2rem;
          }
        }
      }
      &-content {
        padding: $news-content-spacing;
        background-color: $white;
        color: rgba($black, 0.8);
        font-size: 2rem;
        line-height: 1.6;
        a {
          color: $theme-color-alt;
          text-decoration: none;
          border-bottom: 0.1rem solid white;
          &:hover {
            color: darken($theme-color-alt, 10%);
          }
          &:visited {
            color: darken($theme-color-alt, 20%);
            border-bottom-color: $grey-color2;
          }
        }
        p {
          margin-bottom: 1.34em;
          &:first-of-type {
            &:first-letter {
              float: left;
              margin-right: 0.16em;
              margin-bottom: -0.2em;
              font-size: 500%;
              font-weight: bold;
              line-height: 1;
              text-transform: uppercase;
            }
          }
        }
      }
      &-aside {
        display: none;
        float: left;
        width: $news-aside-width;
        margin-right: $news-content-spacing;
        font-size: $base-font-size;
        font-family: $font-sans;
        clear: both;
        a {
          border-bottom: 0;
          color: $base-color;
        }
        figure {
          margin-bottom: 1em;
        }
        &-head {
          margin-bottom: 0.6em;
          font-weight: 600;
          text-transform: uppercase;
        }
        &-body {
          display: block;
          padding-top: 1.3em;
          border-top: 0.1rem solid $grey-text;
        }
        &-author {
          display: block;
        }
        .news {
          &-date {
            font-size: 1.2rem;
            line-height: 1.2;
            letter-spacing: 0.01em;
            color: $black;
          }
          &-title {
            margin-top: 0;
            font-size: 1.6rem;
            font-weight: 600;
            line-height: 1.28;
            color: $theme-color;
            text-align: left;
          }
          &-author {
            color: $base-color;
          }
        }
      }
      &-tags {
        text-transform: uppercase;
        @media only screen and (max-width : 768px) {
        margin-top: 0px;
        }
        .tag {
          > a {
            background-color: $grey-color5;
            &:hover {
              background-color: $grey-color4;
            }
          }
        }
      }
    }
  }
  &-row {
    @include clearfix;
    .news-thumb {
      display: block;
      float: left;
      width: 12rem;
    }
    .news-content {
      margin-left: 14rem;
      p:last-child {
        margin-bottom: 0;
      }
    }
    // Mini Row
    &.mini {
      .news-thumb {
        float: left;
        width: 6rem;
      }
      .news-content {
        margin-left: 8rem;
      }
    }
  }
  &-list {
    position: relative;
    .news {
      &-item {
        padding-top: 2.5rem;
        padding-bottom: 2.5rem;
        border-top: 0.1rem solid darken($border-color, 2.75%);
        &.first {
          padding-top: 0;
          border-top: 0;
        }
      }
      &-title {
        margin-bottom: 0.5rem;
        font-weight: 600;
      }
      &-meta {
        margin-bottom: 1rem;
        color: $grey-color2;
        font-size: 1.2rem;
        font-weight: 400;
        text-transform: uppercase;
        .author {
          a { color: inherit; }
        }
        .category {
          color: $theme-color-alt;
        }
      }
    }
    .events {
      &-content {
        .news-meta {
          color: darken($color-danger, 5%);
          font-size: 1.4rem;
          font-weight: 700;
          letter-spacing: 0.05rem;
        }
      }
    }
  }
  // Infinite Scrolling
  &-infinite-scroll {
    .news {
      &-loading {
        margin-top: 2em;
        color: $grey-text;
        font-size: $base-font-size * 1.5;
        letter-spacing: 0.02em;
        text-align: center;
      }
      &-row {
        max-width: 32rem;
        margin: 1em auto;
        .news {
          &-thumb {
            width: 8rem;
            height: 8rem;
          }
          &-content {
            margin-top: 1rem;
            margin-left: 9.5rem;
          }
          &-title {
            height: 1em;
          }
          &-meta {
            text-align: left;
            line-height: 1;
            span {
              width: 3em;
              height: 0.8em;
            }
          }
          &-thumb,
          &-title,
          &-meta > span {
            background-color: $grey-color5;
            border-radius: $base-radius;
          }
        }
      }
    }
  }
  //- Responsive ---------------------------
  @media (max-width: 1199px) {
    &-details {
      .news {
        &-aside {
          &-author {
            display: block;
            float: none;
            width: auto;
            margin-top: -0.3em;
            margin-bottom: 2em;
            text-align: center;
            .news {
              &-avatar {
                display: none;
              }
              &-author {
                margin-top: 0;
                margin-bottom: 0.2em;
              }
              &-date {
                br { display: none; }
              }
            }
          }
        }
      }
    }
  }
  @media (max-width: 479px) {
    &-container {
      padding-left: 0;
      padding-right: 0;
      border-width: 0;
    }
    &-details {
      figure {
        &.pull-left,
        &.pull-right {
          float: none !important;
          width: auto;
          max-width: none !important;
          margin-left: auto !important;
          margin-right: auto !important;
        }
      }
      .news {
        &-action {
          margin-bottom: 0.429em;
        }
        &-title {
          font-size: 2.5rem;
          letter-spacing: -0.01em;
        }
        &-content {
          margin-left: -1.5rem;
          margin-right: -1.5rem;
          padding: 1.5rem;
          font-size: 1.6rem;
        }
      }
    }
    &-list {
      .news {
        &-thumb {
          float: none;
          width: auto;
          margin-bottom: 1.5rem;
        }
        &-content {
          margin-left: auto;
        }
      }
    }
  }
  @media (min-width: 1200px) {
    &-details {
      .news {
        &-title {
          font-size: 4.5rem;
        }
        &-content {
          // margin-left: $news-aside-gap;
        }
        &-aside {
          display: block;
          margin-top: - $news-content-spacing;
          margin-left: - $news-aside-gap - $news-content-spacing;
        }
      }
    }
  }
}

//- Components -----------------
.news {
  &-filter {
    .control-label {
      color: $grey-color1;
    }
  }
  &-sortby {
    padding-bottom: 2rem;
    border-bottom: 0.1rem solid $border-color;
    .link-tabs {
      margin-bottom: 0;
      > li {
        > a {
          color: $grey-color3;
          text-decoration: none;
          text-transform: uppercase;
        }
        &.active {
          > a {
            border-bottom: 0.2rem solid $theme-color-alt;
            color: $theme-color-alt;
            font-weight: 600;
          }
        }
      }
    }
  }
  &-related {
    .i-row {
      > .i-col {
        &:nth-child(2n+3) {
          clear: both;
        }
      }
    }
    .news {
      &-row {
        display: flex;
      }
      &-thumb {
        float: none;
        width: 9rem;
        flex: 0 0 auto;
      }
      &-content {
        width: auto;
        margin-left: 0;
        padding-left: 1.5rem;
        flex: 1 1 auto;
      }
      &-title {
        font-size: $base-font-size + 0.4rem;
        font-weight: 600;
      }
      &-meta {
        font-size: $base-font-size - 0.1rem;
        font-weight: 400;
        line-height: 1.2;
        color: $grey-color2;
        white-space: nowrap;
        span {
          + span {
            margin-left: 1rem;
            padding-left: 1rem;
            border-left: 0.1rem solid $grey-color4;
          }
        }
      }
    }
  }
  &-mostviewed {
    .news-list {
      .news-item {
        border-top: 0.1rem dashed $grey-color5;
        &:first-child {
          border-top: 0;
        }
        &:last-child {
          border-bottom: 0
        }
        &:hover {
          .news-count {
            color: $grey-color1;
          }
        }
      }
      .news-count {
        float: left;
        width: 5rem;
        color: $grey-color4;
        font-size: 3.6rem;
        line-height: 1;
        text-align: center;
      }
      .news-content {
        margin-left: 6rem;
      }
    }
  }
}

.news-owl {
  margin-bottom: 30px;
  .item {
    >.img {
      background-position: center center;
      background-size: contain;
      background-repeat: no-repeat;
      background-color: $grey-color6;
      height: 350px;
      @media (max-width: 479px) {
        height: 250px;
      }
    }
    .caption {
      margin-top: 0.6em; 
      // font-family: $font-sans; 
      line-height: 1.4; 
      font-size: 14px;
      color: #212529;
      font-weight: 600;
      padding: 0 20px;
      &:before{
        font-family: 'FontAwesome';
        content: '\f05a';
        margin-right: 4px;
        font-style: normal;
        font-size: 1.3rem;
      }
    }
  }
  .owl-nav {
    position: absolute;
    top: 45%;
    width: 100%;
    transform: translateY(-50%);
    margin: 0;
    padding: 0 15px;
    .owl-prev,
    .owl-next {
      background: none;
      margin: 0;
      position: relative;
      &:before {
        font-family: FontAwesome;
        color: #869791;
        font-size: 30px;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%,-50%);
        padding: 10px;
      }
      &:hover {
        background: none;
        &:before {
          color: #000;
        }
      }
    }
    .owl-prev {
      float: left;
      &:before {
        content: "\f053";
      }
    }
    .owl-next {
      float: right;
      &:before {
        content: "\f054";
      }
    }
  }
  .owl-dots {
    display: none;
  }
}
