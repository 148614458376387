#bma-entry {
    //footer
  footer {
    text-align: center;
    margin-bottom: 30px;
    .bma-footer {
      &.desktop {
        display: none;
        max-width: 1300px;
        margin: 0 auto;
        @media (min-width: 1024px) and (max-width: 1299px) {
          width: 100%;
        }
        @media (min-width: 769px) {
          display: block;
        }
      }
      &.mobile {
        max-width: 320px;
        display: none;
        margin: 0 auto;
        @media (max-width: $break_tablet) {
          display: block;
        }
      }
    }

  }
}