//------------------------------
// Transaction table - Currently used for project-outlook, property detail & outlook detail
//------------------------------
.area-outlook-detail,
.page-property-detail,
.page-project-outlook {
  .transaction-table,
  .listing-datatable,
  .listing-table,
  .watable,
  .i-table {
    font-size: 1.2rem; 
      .radios {
        &.inline {
          .radio {
            label {
              font-weight: 400;
              text-transform: capitalize;
            }
          }
        }
      }
      th {
        font-weight: 600;
      }
    }
  
  .listing-datatable {
    .text-small {
      font-size: 1.2rem;
    } 
    .listing-past-trx {
      h3 {
        &.block-title {
          font-size: 1.3rem;
          font-weight: 500;
        }
      }
    }
    .map-list-card .cp-card .card-info > div:first-of-type {
      font-size: 13px !important;
    }
  }

  .transaction-table {
    &.project-trx {
      .transaction-group {
        .transaction-row {
          &:nth-child(even) {
            background: #fafafa;
          }
        }
      }
    }
    .transaction-date {
      font-weight: 400;
      &.head-weight {
        font-weight: 600;
      }
    }
  }
}