.sidebar-sticky {

}


// .content-section {
//     min-height: 2000px;
//   }
//   .sidebar-section {
//     position: absolute;
//     height: 100%;
//     width: 100%;
//   }
  
  
//   .sidebar-item {
//       position: absolute;
//       top: 0;
//       left: 0;
//       width: 100%;
//   }
//   .has-4-items {
//     .sidebar-item {
//         height: 25%;
//         &:nth-child(2) { 
//             top: 25%; 
//         }
//         &:nth-child(3) { 
//             top: 50%; 
//         }
//         &:nth-child(4) { 
//             top: 75%; 
//         }
//     }
//   }

//   .has-10-items .sidebar-item {
//       height: 10%;
//     &:nth-child(2) { top: 10%; }
//     &:nth-child(3) { top: 20%; }
//     &:nth-child(4) { top: 30%; }
//     &:nth-child(5) { top: 40%; }
//     &:nth-child(6) { top: 50%; }
//     &:nth-child(7) { top: 60%; }
//     &:nth-child(8) { top: 70%; }
//     &:nth-child(9) { top: 80%; }
//     &:nth-child(10) { top: 90%; }
//   }
  
//   .make-me-sticky {
//       position: -webkit-sticky;
//       position: sticky;
//       top: 0;
//     padding: 0 15px;
//   }

//   above to enable
  
  
  
  /* Ignore This, just coloring */
//   body {
//     background: #fff;
//   }
  
//   article {
//     background: #f1f1f1;
//     border-radius: 12px;
//     padding: 25px 0;
//   }
  
  
//   .title-section, .content-section, .sidebar-section {
//     background: #fff;
//     // border-radius: 12px;
//     // border: solid 10px #f1f1f1; 
//   }
  
//   .title-section {
//     text-align: center;
//     padding: 50px 15px;
//     margin-bottom: 30px;
//   }
  
//   .content-section h2 {
//     text-align: center;
//     margin: 0;
//     padding-top: 200px;
//   }
  
//   .sidebar-item {
//     text-align: center;
    
//     h3 {
//       background: gold;
//       max-width: 100%;
//       margin: 0 auto;
//       padding: 50px 0 100px; 
//       border-bottom: solid 1px #fff;
      
//       &.alt { background: pink; }
//     }
    
//     h4 {
//       padding: 25px 0;
//       background: orange;
//       border-bottom: solid 1px #fff;
//       font-size: 12px;
//     }
    
//     h5 {
//       padding: 25px 0;
//       background: lightgreen;
//       border-bottom: solid 1px #fff;
//       font-size: 12px;
//     }
//   }
  
  
//   body {
//     padding-bottom: 1000px;
//   }