//------------------------------
// Block
//------------------------------
.i-block {
  position: relative;
  + .i-block {
    //- Ignore block contain .m-t-* classes
    &:not([class*="m-t"]) {
      margin-top: 2.5rem;
    }
  }
  // Title
  .block-title {
    margin-top: 0;
    margin-bottom: 1.8rem;
    color: $theme-color;
    font-size: 2rem;
    font-weight: 600;
    // font-size: 1.8rem;
    // letter-spacing: .6px;
    // font-weight: 700;
    // text-transform: uppercase;
  }
  // Content
  .block-content {
    position: relative;
  }
  // Pattern
  &.frame {
    padding: 1.5rem;
    border: 0.1rem solid $border-color;
  }
}
.area-outlook-detail,
.page-project-outlook {
  h3 {
    &.block-title {
      @extend .block-title;
    }
  }
}
body.front {
  .i-block {
    .block-title {
      margin-bottom: 0;
      font-size: 2.2rem;
    }
  }
}