.page-content {
  //ecoworld
  &-1902591, &-1900642, &-1849187, &-1845150, &-1841803, &-1902490, &-1902610 {
    .page-projectreview {
      footer,
      #mainNav.navbar,
      .news-arrow-down .circle-color,
      section.page-inquiry .inquiry-button,
      .fixed-bar .fixed-buttons .btn.btn-enquire {
        background-color: #007F38;
      }
      section.page-inquiry .inquiry-button, 
      .fixed-bar .fixed-buttons .btn.btn-enquire {
        border-color: #007F38;
        &:hover {
          background-color: #007F38;
          opacity: 0.9;
          border-color: #007F38;
        }
      }
      footer {
        color: #fff;
        a, p {
          color: #ffffff;
        }
      }
    }

  }
  //Ecoworld main
  &-1902591 { 
    .news-hero-text {
      h1 {
        // display: none;
        opacity: 0 !important;
      }
    }
    section.page-hero {
      .news-hero-bg {
        // height: 777px;
        background-size: contain;
        // @media (max-width: 1440px) {
        //   height: 788px;
        // }
        @media (max-width: 1440px) {
          height: 788px;
        }
        @media (max-width: 1366px) {
          height: 750px;
        }
        @media (max-width: 1280px) {
          height: 700px;
        }
        @media (max-width: 1024px) {
          height: 560px;
        }
        @media (max-width: 991px) {
          height: 542px;
        }
        @media (max-width: 768px) {
          height: 420px;
        }
        @media (max-width: 490px) {
          height: 269px;
          background-size: contain;
        }
        @media (max-width: 425px) {
          height: 227px;
        }
        @media (max-width: 375px) {
          height: 205px;
        }
        @media (max-width: 320px) {
          height: 175px;
        }
        @media (min-width: 1564px) {
          height: 858px;
        }
      }

    }
  }

  // panasonic
  &-1904967 {
    .news-hero-text {
      background-color: rgba(0,0,0,0.23);
      padding: 10px 0;
    }
  }
  // hide quote - James common issue
  //Mori Residences 2, JBL
  //Sime Darby Property-Livspace
  //Paramount property
  //alora-residences
  &-1908336, &-1908246, &-1905980,
  &-1906640, &-1906721, &-1907220, 
  &-1907497, &-1907608,
  &-1907919 , &-1909347, &-1909737 {
    i.fa.fa-quote-left.fa-2x.el-show {
      display: none;
    }
  }
  //Sime Darby Property-Livspace
  //Paramount property
  &-1906030,
  &-1906640 {
    p a {
      display: initial;
    } 
  }

  // Setia 
  &-1905941 {
    .page-projectreview section.page-hero .news-hero-bg { 
      @media (min-width: 551px) {
        background-image: url('https://media.edgeprop.my/branded_page/sime_darby/sd_desktop.jpg')!important;
      }
      @media (max-width: 550px) {
        background-image: url('https://media.edgeprop.my/branded_page/sime_darby/sd_mobile.jpg')!important;
      }
      @media (max-width: 375px) {
        height: 494px;
      }
      @media (max-width: 320px) {
        height: 454px;
      }
    }
    .news-hero-main-component {
      @media (min-width: 768px) {
        top: 16px !important;
      }
      @media (max-width: 767px) {
        // padding-top: 12px !important;
      } 
      .news-hero-text {
        h1 {
          @media (min-width: 1025px) {
            font-size: 54px !important; 
          }
          @media (max-width: 1024px) {
            font-size: 41px !important; 
          }
          @media (max-width: 470px) {
            font-size: 36px !important; 
          }
          @media (max-width: 375px) {
            font-size: 32px !important; 
          }
          @media (max-width: 360px) {
            font-size: 30px !important; 
          }  
          @media (max-width: 320px) {
            font-size: 27px !important; 
          }  
        }
      }
    }
    #prform {
      .container-news-skin.padding-mobile {
        display: none;
        &.sime-footer {
          display: block;
        }
      }
    }
  }

  //red dot
  &-1908228 {
    .page-projectreview section.page-hero .news-hero-main-component {
      @media (min-width: 1024px) {
        top: 250px;
      }
      @media (min-width: 1280px) {
        top: 320px;
      }
      @media (max-width: 1023px) {
        top: 180px;
      }
      @media (max-width: 767px) {
        top: 120px;
      }
      // .news-hero-text {
      //   h1 {
      //     text-shadow: 4px 1px 8px #3b3b3b;
      //   }
      // }
    }
  }
  //text-shadow 
  &-1908228,
  &-1908246,
  &-1908411 {
    .page-projectreview section.page-hero .news-hero-main-component {
      .news-hero-text {
        h1, 
        h4 {
          text-shadow: 4px 3px 8px #111;
        }
      }
    }
  }
  &-1908246 {
    .page-projectreview section.page-hero {
      .news-hero-bg {
        // background-position: center -40px;
        // height: 680px;
        .news-hero-main-component {
          .news-hero-text {
            h1,
            h4 {
              text-shadow: 11px 2px 16px #111;
            }
          }
        }
      }
    } 
  }
  &-1908228 {
    .page-projectreview section.page-hero {
      .news-hero-bg {
        background-position: center -40px;
        height: 680px;
      }
      .news-hero-main-component {
        @media (min-width: 768px) {
          top: 452px;
        }
      }
    }  
  }
  &-1909742 {
    .page-projectreview section.page-hero {
      .news-hero-bg {
        @media (min-width: 1024px) {
          background-position: center -240px;
        }
        @media (min-width: 1280px) {
          background-position: center -310px;
        }
        @media (min-width: 1366px) {
          background-position: center -370px;
        }
      } 
    }  
  }

  // talisa
  &-1910812 {
    .page-projectreview section.page-hero .news-hero-main-component {
      top: 0px;
      .news-hero-text {
        img {
          width: 90%;
          @media (min-width: 768px) {
            max-width: 400px;
          }
          @media (min-width: 1024px) {
            max-width: 500px;
          }
        }
      }
    }
  }
  // uoa
  &-1910653 {
    .page-projectreview {
        section.page-hero {
            .news-hero-main-component {
                @media (max-width: 767px) {
                    padding-top: 20px;
                }
                top: 30px;
                .news-hero-text img {
                    width: 100%;
                    max-width: 360px;
                    margin-bottom: 15px;
                    @media (max-width: 767px) {
                        width: 70%;
                    }
                }
            }
        }
    }
}
//uoa ended

}