#startcontest {
	a {
		color: $theme-color;
		text-decoration: underline;
		&:hover {
			text-decoration: none;
		}
	}
	section {
		padding: 25px 0 50px;
		&.general {
			// border-top: 10px solid #ff006c;
		    position: relative;
		    padding: 50px 0 50px;
		    overflow: hidden;
		} 
		&.top {
			padding: 0;
			.top-bg {
				background: url(https://media.edgeprop.my/page/ehcontest/ehc_d.jpg) no-repeat center;
			    background-size: cover; 
			    // height: 100vh;
			    height: 685px;
			    @media (max-width: $break_tablet) {
			    	background: url(https://media.edgeprop.my/page/ehcontest/ehc_t.jpg) no-repeat center;
			    	height: 390px;
			    }
			    @media (max-width: $break_m_lg) {
			    	background: url(https://media.edgeprop.my/page/ehcontest/ehc_m.jpg) no-repeat center;
			    	height: 238px;
			    }
			    @media (max-width: $break_m_md) {
			    	background: url(https://media.edgeprop.my/page/ehcontest/ehc_m1.jpg) no-repeat center;
			    	//background-size: 100% 100%; 
			    	//height: 238px;
			    	margin-top: -23px;
			    }
			    @media (max-width: $break_m_sm) {
			    	background: url(https://media.edgeprop.my/page/ehcontest/ehc_m3.jpg) no-repeat center;
			    	//background-size: 100% 100%; 
			    	//height: 238px;
			    	margin-top: -37px;
			    }
			}
		}
		&.how-to-win {
			.title {
				font-weight: 800;
				font-size: 1.7rem;
				margin: 4rem 0;
				&-1 {
					color: $primary-color1;
				}
				&-2 {
					color: #f9cc13;
				}
			}
		}
		&.terms-conditions {
			// padding-bottom: 50px;
		}
		ul, ol {
			text-align: left;
			@media (min-width: $break_post_d_sm) {
				margin: 0;
				padding: 0;
			}
			li {
				margin-bottom: 10px;
				span {
					&.step-title {
						text-transform: uppercase;
						display: block;
					    font-weight: 600;
					    font-size: 2rem;
					    color: $primary-color1;
					}
				}
			}
			&.ehc-ul {
				text-align: center;
				@media (min-width: $break_post_d_sm) {
					min-height: 265px;
				}
			}
		}
		ul {
			li {
				margin-bottom: 25px;
			}
		}
	}
	h2, h3 {
		text-transform: uppercase;
	}
	h2 {
		margin-bottom: 1rem;
		font-size: 4rem;
		font-weight: 800;
		span {
			&.sub-h2 {
				text-transform: none;
				font-size: 2rem; 
				font-weight: 400;
				display: block;
				margin: 1.5rem 0 2.5rem;
			}
		}
		@media (min-width: $break_post_d_sm) {
		    width: 70%;
		    margin: 0 auto;
		}
	}
	h3 {
		font-size: 1.7rem;
		margin-bottom: 25px;
	}
	// footer {
	// 	&.subscribe {
	// 		margin: 35px 0;
	// 		.cny-submit {
	// 			h2 {
	// 				font-size: 2rem;
	// 				@media (min-width: $break_post_d_sm) {
	// 					width: 55%;
	// 					margin: 40px auto 20px;
	// 				}
	// 			}
	// 			.area-email-alternative {
	// 				margin: 20px 0 40px;
	// 			    font-size: 1.5rem;
	// 			}
	// 		}
	// 		.sub-title {
	// 			@media (min-width: $break_post_d_sm) {
	// 				width: 60%;
	// 				margin: 0 auto;
	// 			}
	// 		}
	// 		.subscribe-input  {
	// 			margin: 20px 0;
	// 			.btn-subscribe-input {
	// 				button {
	// 					width: 100%;
	// 				}
	// 			}
	// 			input {
	// 				padding: 6px;
	// 			}
	// 		}
	// 		.area-email-wrapper {
	// 			display: block;
	// 			overflow: auto;
	// 		}
	// 	}
	// }
	.btn {
		&-ehc {
			@media (min-width: $break_d_md) {
				margin-top: -35px; 
			}
			background: #ff5122;
			color: #fff;
			padding: 20px 25px;
			font-weight: 800;
			&:hover {
				background: #fff;
				color: #ff5122;
				border: 1px solid #ff5122;
			}
			a {
				text-decoration: none;
				color: #fff;
				&:hover {
					color: #ff5122;
				}
			}
		}
		// &-cny-sm, &-cny-sm-gr {
		// 	background: #ff5122;
		// 	color: #fff;
		// 	&:hover {
		// 		background: #fff;
		// 		color: #ff5122;
		// 		border: 1px solid #ff5122;
		// 	}
		// }
		// &-cny-sm-gr {
		// 	background: $bg_dark;
		// 	&:hover {
		// 		background: #fff;
		// 		color: $bg_dark;
		// 		border: 1px solid $bg_dark;
		// 	}
		// }
		&-border-radius-no {
			border-radius: 0;
		}
	}
	// .a-btn-ehc {

	// }
	input {
		width: 100%;
	}

} 