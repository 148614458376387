@media (max-width: $break_d_md ) {
  #sidebar-main {
    .dropdown-new {
      h6, .nav-header {
        font-family: "Poppins", sans-serif;
        padding: 15px 20px 10px 20px;
        background-color: $header-dropdown-bg;
        margin: 0;
        font-size: 15px;
        font-weight: 500 !important;
        display: block;
        line-height: 1.4;
      }
    }
    .main-sm-menu,
    a#sidebar-dropdown-new  {
      border-bottom: 1px solid #d8d8d8;
      color: #414141 !important;
    }
    a {
      &#sidebar-dropdown-new {
        background: #fff;
      }
    }
    a.dropdown-item-new {
      color: #488BF8;
    }
    .main-sm-menu {
      padding-top: 20px;
      padding-bottom: 20px;
    }
    .border-sm-menu {
      float: right;
      font-weight: 100;
      color: #ababab;
      font-size: 2rem;
      border-right: 1px solid #d6d6d6;
      width: 20px;
      height: 33px;
      position: relative;
      top: -8px;
      left: -40px;
    }
  }
}