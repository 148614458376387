$brand-toggle: #08192c;

// .mrec-sideslide-wrapper {
//   z-index: 9999;
//   position: fixed;
//   background: transparent;
//   color: #333;
//   // width: 300px; 
//   width: 95px;
//   text-align: center;
//   bottom: 0;
//   right: 0;
//   height: 300px;
// }

// .mrec-toggle-local {
//   opacity: 0;
//   pointer-events: auto;
//   position: fixed;
//   // bottom: 10%;
//   z-index: 9999999999;
// }
.mrec-sideslide-toggle {
  opacity: 0;
}
