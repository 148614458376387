//------------------------------
// Transaction
//------------------------------

///// Transaction Input /////
.transaction-input {
  h2 {
    margin-top: 0;
    margin-bottom: 0.6em;
    font-size: 2.5rem;
    line-height: 1.2;
  }
  .i-form {
    .input {
      border-color: $border-color;
      input {
        color: $theme-color;
        font-weight: 600;
        @include placeholder {
          color: $theme-color;
        }
      }
      .input-group {
        &-addon {
          border-width: 0;
          background-color: transparent;
          color: $theme-color;
        }
      }
    }
    .twitter-typeahead .tt-menu {
      width: 100%;
    }
  }
  // Landing pages
  &.invert {
    h2,
    .i-form input,
    .i-form .input-group-addon {
      color: $white;
    }
    .i-form {
      input {
        @include placeholder {
          color: $white;
        }
      }
      .input {
        border-color: $white;
        background-color: transparent;
      }
    }
  }
  //- Responsive ---------------------------
  @media (min-width: 1600px) {
    h2 {
      font-size: 3.6rem;
    }
  }
}

///// Transaction Filter /////
.transaction-filter {
  .i-form {
    .select {
      border-color: $border-color;
      select {
        padding-left: 1rem;
        padding-right: 1rem;
        color: $theme-color;
        font-weight: 600;
      }
      &:after {
        content: '\f123';
        display: inline-block;
        width: auto;
        height: auto;
        border: 0;
        font-family: $font-ionic;
        font-size: 1rem;
        line-height: 1;
        top: 1.7rem;
        right: 1rem;
      }
    }
    .input-group {
      > .group {
        @include clearfix;
        > .select {
          float: left;
          width: 50%;
          &:first-child {
            border-right: 0;
            border-radius: 0.4rem 0 0 0.4rem;
          }
          &:last-child {
            border-left: 0;
            border-radius: 0 0.4rem 0.4rem 0;
          }
        }
      }
    }
  }
}

///// Transaction Scoreboard /////
.transaction-scoreboard {
  color: $white;
  .search-link {
    .item {
      a { color: $theme-color-alt; }
    }
  }
}

///// Transaction Chart /////
.transaction-chart {
  background: $theme-chart;
  color: #fff;
  .transaction {
    &-title {
      font-size: 2rem;
      font-weight: 600;
    }
    &-clabel {
      font-size: 1.5rem;
    }
    &-ctitle {
      font-size: 2rem;
      font-weight: 600;
    }
  }
  //- Responsive ---------------------------
  @media (max-width: 767px) {
    .transaction {
      &-clabel {
        font-size: 1.3rem;
      }
      &-ctitle {
        font-size: 1.6rem;
        font-weight: 700;
      }
    }
    .i-items {
      > .item {
        display: block;
        > .image {
          margin-left: auto;
          margin-right: auto;
          + .content {
            padding-left: 0;
            text-align: center;
          }
        }
      }
    }
  }
}

///// Transaction Table /////
$table-thead-color: $theme-color;
$table-highlight-color: $theme-color;
.transaction-table {
  .transaction {
    &-name {
      color: $table-highlight-color;
      font-weight: 600;
    }
    &-date {
      color: $table-highlight-color;
      font-weight: 600;
    }
    &-head {
      display: none;
    }
    &-body {
      .transaction {
        &-name {
          > small {
            display: block;
            font-weight: 400;
            color: $grey-color3;
          }
        }        
      }
    }
  }
  //- Responsive ---------------------------
  @media (max-width: 767px) {
    .transaction {
      &-name {
        font-size: 2rem;
        line-height: 1.2;
      }
      &-date {
        font-size: 2rem;
        line-height: 1.2;
      }
      &-address {
        color: #999;
      }
      &-type {
        color: #999;
      }
      &-psf {
        &:before {
          content: 'RM';
          margin-right: 0.5rem;
        }
        &:after {
          content: 'psf';
          margin-left: 0.5rem;
        }
      }
      &-sqft {
        &:after {
          content: 'sqft';
          margin-left: 0.5rem;
        }
      }
      &-price {
        &:before {
          content: 'RM';
          margin-right: 0.5rem;
        }
      }
      &-body {
        .transaction {
          &-row {
            margin: 1.5rem 0;
            padding: 1.5rem;
            background-color: $bg-color;
            border: 0.1rem solid $border-color;
            border-radius: 0.4rem;
          }
          &-region {
            &-2 {
              margin-top: 1rem;
            }
          }
          &-section {
            &-2 {
              margin-top: 1rem;
              &:before,
              &:after {
                content: '';
                display: table;
              }
              &:after {
                clear: both;
              }
              .transaction {
                &-item {
                  float: left;
                  width: 33.3%;
                  font-size: 1.6rem;
                  font-weight: 600;
                  color: #565765;
                  .page-area-outlook & {
                    width: 50%;
                  }
                }
                &-price {
                  // font-size: 2.4rem;
                  // text-align: right;
                  font-size: 1.6rem;
                  text-align: right;
                }
              }
            }
            &-4 {
              .transaction {
                &-item {
                  font-size: 2rem;
                  font-weight: 600;
                }
              }
            }
          }
        }
      }
    }
  }
  @media (min-width: 768px) {
    width: 100%;
    .transaction {
      &-title,
      &-item {
        float: left;
        padding: 0.8rem;
      }
      &-title {
        color: #fff;
        font-weight: 500;
        letter-spacing: 0.02rem;
        > a {
          color: #fff;
        }
      }
      &-region,
      &-section {
        float: left;
      }
      &-head {
        display: block;
        border: 0.1rem solid $border-color;
        background: $theme-chart; 
        .transaction {
          &-row {
            &:before, &:after {
              content: '';
              display: table;
            }
            &:after {
              clear: both;
            }
          }
          // Width for Columns
          // For transaction/state
          // 100 = (Name[40] + Type[15] + Psf[15] + Price[15] + Filed[15])
          // For transaction/state/result
          // 100 = (Date[10] + Address[30] + Type2[15] + Lotsize[15] + PSF[15] + Price[15]) 
          $columns: (
            'name': '21%',
            'type': '34%',
            'date': '10%',
            'address': '30%',
            'type2': '15%',
            'lotsize': '15%',
            'psf': '15%',
            'price': '15%',
            'filed': '15%'
          );
          @each $name, $width in $columns {
            &-#{$name} {
              width: #{$width};
            }
          }
          &-title {
            &:not(.transaction-name):not(.transaction-address) {
              text-align: left;
            }
            @if $debug == 'true' {
              background-color: rgba(100,100,255,0.12);
              box-shadow: 0 0 0 1px rgba(0,0,255,0.1) inset;
            }
          }
        }
      }
      &-body {
        .transaction {
          &-row,
          &-region {
            &:before, &:after {
              content: '';
              display: table;
            }
            &:after {
              clear: both;
            }
          }
          &-row {
            margin-top: 0;
            padding-top: 0;
            padding-bottom: 0;
            border: 1px solid $border-color;
            &:nth-child(even) {
              background-color: #fffffa;
            }
          }
          &-region {
            $regions: ( 1: '85%', 2: '15%' );
            @each $i, $width in $regions {
              &-#{$i} {
                width: #{$width};
              }
            }
          }
          &-section {
            $sections: ( 1: '64.7%', 2: '35.3%' );
            @each $i, $width in $sections {
              &-#{$i} {
                width: #{$width};
              }
            }
            &-1 {
              .transaction {
                $items: (
                  // For transaction/state
                  // 100 = (Name + Type)
                  'name': '38%',
                  'type': '62%',
                  // For transaction/state/result
                  // 100 = (Date + Address + Type2)
                  'date': '18.2%',
                  'address': '54.6%',
                  'type2' : '27.2%'
                );
                @each $name, $width in $items {
                  &-#{$name} {
                    width: #{$width};
                  }
                }
                &-date,
                &-type,
                &-type2 {
                  text-align: left;
                }                
              }
              .transaction-item {
                @if $debug == 'true' {
                  background-color: rgba(255,100,100,0.12);
                  box-shadow: 0 0 0 1px rgba(255,0,0,0.1) inset;
                }
              }
            }
            &-2 {
              .transaction-item {
                width: 33%;
                text-align: left;
                @if $debug == 'true' {
                  background-color: rgba(255,200,0,0.12);
                  box-shadow: 0 0 0 1px rgba(200,180,0,0.1) inset;
                }
                &:nth-child(2) {
                  width: 35%;
                }
                &:nth-child(3) {
                  width: 32%;
                }
              }
            }
            &-3 {
              float: none;
              padding: 0.8rem;
              text-align: center;
              .transaction-item {
                @if $debug == 'true' {
                  background-color: rgba(100,100,200,0.12);
                  box-shadow: 0 0 0 1px rgba(0,0,255,0.1) inset;
                }
              }
            }
            &-4 {
              float: none;
              text-align: center;
              .transaction-item {
                float: none;
                @if $debug == 'true' {
                  background-color: rgba(100,200,100,0.12);
                  box-shadow: 0 0 0 1px rgba(0,255,0,0.1) inset;
                }
              }
            }
          }
        }
      }
    }
    // Additional Styles
    &.project-trx {
      .transaction {
        &-head {
          .transaction {
            $columns: (
              'date': '15%',
              'address': '34.6%',
              'sqft': '16.5%',
              'psf': '18%',
              'price': '15%',
            );
            @each $name, $width in $columns {
              &-#{$name} {
                width: #{$width};
              }
            }
          }
        }
        &-body {
          .transaction {
            &-region {
              $regions: ( 1: '100%' );
              @each $i, $width in $regions {
                &-#{$i} {
                  width: #{$width};
                }
              }
            }
            &-section {
              $sections: ( 1: '50%', 2: '50%' );
              @each $i, $width in $sections {
                &-#{$i} {
                  width: #{$width};
                }
              }
              &-1 {
                .transaction {
                  $items: (
                    'date': '30%',
                    'address': '70%'
                  );
                  @each $name, $width in $items {
                    &-#{$name} {
                      width: #{$width};
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
  @media (min-width: 768px) and (max-width: 991px) {
    .transaction {
      &-body {
        .transaction {
          &-section {
            &-1 {
              .transaction-item {
                text-align: left;
              }
              .transaction-name,
              .transaction-type {
                padding: .8rem;
              }
              // Inner
              .transaction-date,
              .transaction-address,
              .transaction-type2 {
                padding: .8rem;
              }              
            }
          }
        }
      }
    }
  }
}

///// Transaction SVG /////
.transaction-svg {
  transform: rotate(-90deg);
  .default,
  .circle { fill: none; stroke-width: 1.6rem; }
  .default { stroke: #f5f5f5; }
  .circle {
    stroke: #f4b124;
    stroke-dasharray: 440;
    stroke-dashoffset: 440;
    stroke-linecap: round;
    &.quarter { animation: quarter 1s cubic-bezier(0.4, 0, 0.2, 1) forwards; }
    &.half { animation: half 1s cubic-bezier(0.4, 0, 0.2, 1) forwards; }
    &.three-quarter { animation: three-quarter 1s cubic-bezier(0.4, 0, 0.2, 1) forwards; }
  }
}
@keyframes quarter {
  to { stroke-dashoffset: 330; }
}
@keyframes half {
  to { stroke-dashoffset: 220; }
}
@keyframes three-quarter {
  to { stroke-dashoffset: 110; }
}