body {
    &.page-airsupplycontest,
    &.page-ewf,
    &.page-node-42,
    &.page-pepscontest,
    // peps staging
    &.page-node-37,
    //peps prod
    &.page-node-43 {
        .block-webform {
            h2.block-title {
                display: none;
            }
            form,
            .messages.status.webform-confirmation
             {
                margin: 0 auto;
                @media (min-width: $break_tablet ) {
                    max-width: 452px;
                }
            }
            .btn.btn-default.form-submit.ajax-processed {
                @media (min-width: $break_tablet) {
                    width: 50%;
                }
                margin-top: 5px;
                background: #fff;
                color: #ff5122;
                border: 1px solid #ff5122;
                &:hover {
                    border-color: #ff5122;
                    color: #fff;
                    background: #ff5122;
                }
            }
            .form-actions {
                .form-submit {
                    width: 100%;
                }
            }
            .webform-submit.button-primary.btn.btn-primary.form-submit {
                text-decoration: none;
                background: #ff5122;
                color: #fff;
                padding: 20px 25px;
                font-weight: 800;
                border-color: #ff5122;
                &:hover {
                    background: #fff;
                    color: #ff5122;
                    border: 1px solid #ff5122;
                }
                @media (max-width: $break_m_sm) {
                    font-size: 1.3rem;
                    padding: 20px 4px;
                }
            }
            .messages.status.webform-confirmation {
                background: #edffd9;
                padding: 50px 30px;
                font-size: 2rem;
                text-align: center;
                @media (max-width: $break_pre_tablet) {
                    padding: 10px;
                }
            }
            form {
                @media (max-width: $break_pre_tablet ) {
                    padding: 20px;
                }
            }

        }
    }
}