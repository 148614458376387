/*New Nav Bar*/
.nav-new {
  margin: 0;
}
.fixed-top-new {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
}
.z-index-class-new {
  z-index: 1030;
}
.navbar-new {
  //center logo header
  padding: 40px 10px;
  @media (max-width: 1144px) {
    padding: 0px 10px;
  }
  @media (max-width: 320px) {
    padding: 0px;
  }
  background-color: #fff;
  border-bottom: 0.5px solid #E6E6E6;
  height: 52px;
  border-radius: 0;
  display: flex;
  align-items: center;
  @media (max-width:  991px) {
    height: 71px;
  }
}
@media (min-width: 1025px) {
  .mobile-header-items-new{
    display: none;
  }
}
.navbar-new >.container-full-new {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.container-full-new {
  width: 100%;
  height: 100%;
  margin-right: auto;
  margin-left: auto;
  .nav-header {
    font-family: "Poppins", sans-serif;
    font-size: 15px;
    font-weight: 600;
    margin-top: 10px;
    margin-bottom: 10px;
    line-height: 1.2;
  }
  .menu-agent p {
    font-family: "Poppins", sans-serif;
  }
}
.navbar-expand-lg-new > .container-full-new {
  flex-wrap: nowrap;
}
@media (min-width: 1200px) {
  .container-full-new {
      max-width: 1350px;
      padding: 0px 0px;
  }
}
@media (min-width: 1025px) {
  .container-full-new {
      // max-width: 100%;
      padding: 0px 0px;
  }
}
.navbar-brand-new {
  //center logo header
  // display: inline-block;
  // padding-top: 0.3125rem;
  // padding-bottom: 0.3125rem !important;
  // margin-right: 1rem;
  // font-size: 1.25rem;
  // line-height: inherit;
  white-space: nowrap;
  flex: 0 0 15%;
  text-align: center;
  @media (max-width: 1144px) {
    flex: 0 0 10%;
  }
  img {
    height: 30px !important;
    max-width: 119px !important;
    @media (min-width: 1120px) {
      height: 40px !important;
      max-width: 100% !important;
    }
  }
}

.navbar-light-new .navbar-brand-new {
  color: rgba(0, 0, 0, 0.9);
}
.navbar-collapse-new {
  flex-grow: 1;
  align-items: center;  
  
  @media (min-width: 1025px) {
    display: flex !important;
    flex-basis: auto;
    flex: 0 0 45%;
    &.nav-left {
      justify-content: flex-start;
      flex: 0 0 38%;
    }
    &.nav-right {
      justify-content: flex-end;
      flex: 0 0 50%;
    }
    .dropdown-menu {
      top: 84%;
    }
  }
  // @media (min-width: 1145px) {
  //   flex: 0 0 40%;
  // }
}
// @media (min-width: 1025px){
//   .navbar-expand-lg-new .navbar-collapse-new {
//       display: flex !important;
//       flex-basis: auto;
//       flex: 0 0 40%;
//   }
// }
.ml-auto-new, .mx-auto-new {
    margin-left: auto !important;
}
@media (min-width: 1025px) {
  .navbar-expand-lg-new .navbar-nav-new {
      flex-direction: row;
  }
}
.navbar-nav-new {
  display: flex;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}
.mega-dropdown{
  position: static;
  &:hover{
    .dropdown-menu{
      padding: 20px 0;
      margin: 0;
      border: 0;
      min-width: 100%;
      display: block;
      border-radius: 0;
      box-shadow: 0px 10px 10px rgba(0, 0, 0, 0.06);
    }
    .nav-link-new {
      // color: #FFFFFF!important;
      // background-color: #488BF8;
    }
  }
  .menu-container{
    width: 100% !important;
    padding: 0 35px;
    &>.row{
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -ms-flex-wrap: wrap;
          flex-wrap: wrap;
    }
    .row:before, .row:after {display: none !important;} /* safari fix */
  }
  .menu-sub-section{
    -webkit-box-flex: 0;
    -ms-flex: 0 0 20%;
    flex: 0 0 20%;
    max-width: 20%;
    border-right: 1px solid rgba(0, 0, 0, 0.1);
    // h4{
    //   font-size: 15px;
    //   font-weight: 600;
    // }
    // .nav-header {
    //   font-family: "Poppins", sans-serif;
    //   font-size: 15px;
    //   font-weight: 600;
    //   margin-top: 10px;
    //   margin-bottom: 10px;
    //   line-height: 1.2;
    // }
    .dropdown-item{
      font-size: 13px;
      color: #414141;
      padding: 7px 5px;
      display: block;
      white-space: unset !important;
      transition: all 0.5s ease;
      &:hover{
        padding-left: 7px;
        text-decoration: none;
        background-color: #e1e1e1;
        border-radius: 5px;
      }
    }
    &--no-title{
      padding-top: 28px;
    }
    &:last-child{
      border-right: 0;
    }
  }
}
.nav-link-new {
  // font-size: 12.75px!important;
  @media (min-width: 1368px) {
    // font-size: 0.9rem;
    font-size: 14.5px;
  }
  font-weight: 400;
  // text-transform: uppercase;
  /*color : rgba(60, 71, 85, 1);*/
  color: #000 !important;
  transition: all 0.1s ease;
  border-bottom: 2px solid transparent;
  &.active, 
  &:hover:not(.active) {
    // background-color: #2056AB;
    // color: #FFFFFF!important;
    // text-decoration: none!important;
    //center logo header
    color: #2056ab !important;
    border-bottom: 2px solid #2056ab;
    border-radius: 0;
  }
  // &:hover:not(.active){
  //   background-color: $link-color-19;
  //   text-decoration: none;
  //   color: #FFFFFF!important;
  // }
  @media (min-width: 1025px){ 
      //center logo header
      // padding: 9px 15px !important;
      // margin: 8px 2px;
      // border-radius: 5px;
      padding: 8px 0px !important;
      margin: 8px 17px;
      display: block;
  }
  &.webui-news-search {
    padding: 8px 20px !important;
  }
}
.nav-link-new.active {
  text-decoration: none;
}
.nav-link-new:focus {
  text-decoration: none;
}
.separator-class-new {
  width: 3px;
  -ms-flex: 0 0 1px;
  flex: 0 0 1px;
  background-color: #c1c1c3;
  margin: 8px 0;
  pointer-events: none;  
}
.btn-menu-new {
  background-color: #fff;
  width: 35px !important;
  height: 49px !important;
  font-size: 160% !important;
}
.btn-menu-decoration-new {
  font-size: 30px;
  color: #808080ba;
}
#sidebarCollapse-new.btn:active {
  box-shadow: none;
}
.fa-navicon:before, .fa-reorder:before, .fa-bars:before{
  font-size: 115%;
}
div.loggedin-items-new {
    // padding-top: 7px;
}
.padding-4 {
  padding: 4px;
}
.shortlistbar-button-new {
    background-color: transparent;
    border: 0;
    padding: 4px 8px;
}
.fav-size-head-new {
  padding-top: 0;
  font-size: 22px;
  vertical-align: middle;
  line-height: 22px;
  color: #ff5a60;
}
.savedsearch-link-new {
  font-family: 'Poppins', sans-serif;
  font-size: 14.5px;
  vertical-align: middle;
  // text-transform: uppercase;
  color: rgba(0, 0, 0, 0.7) !important;
  background-color: transparent;
  text-decoration: none;
  padding: 0 10px; 
}
.savedsearch-link-new:hover{
  text-decoration: none;
}
.iconColor {
    color: #ccc;
    cursor: pointer;
    padding-top: 0;
    font-size: 26px;
    vertical-align: middle;
    line-height: 26px;
}
.drpdown-position-new {
    position: absolute;
}
.dropdown-menu-bar-new{
  position:absolute;
  float: none;
  top: -178%;
  z-index: 1000;
  display: none;
  min-width: 16rem;
  margin-right: -38px;
  /*padding: 0.5rem 0;*/
  /*margin: 0.125rem 0 0;*/
  /*font-size: 1rem;*/
  color: #212529;
  text-align: left;
  list-style: none;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 0.25rem;
}
.dropdown-menu-right-new {
  margin-top: 15px;
  right: 0;
  left: auto;
}
.dropdown-divider-new {
  height: 0;
  margin: 0.5rem 0;
  overflow: hidden;
  border-top: 1px solid #e9ecef;
}
.dropdown-item-bar-new {
  display: block;
  width: 100%;
  padding: 1.10rem 2.5rem;
  clear: both;
  font-weight: 400;
  font-size: 15px;
  color: #212529;
  text-align: inherit;
  white-space: nowrap;
  background-color: transparent;
  border: 0;
}
.dropdown-item-bar-new:hover {
  color: #16181b;
  text-decoration: none;
  background-color: #f8f9fa;
}
.drop-user-active{
  display: block;
}
.btn-logout-new {
  font-size: 14px;
  text-align: center;
  font-weight: 600;
  padding-left: 0;
  padding-right: 0;
  line-height: 25px;
}
/*Side Bar*/
.no-gutters-new {
    margin-right: 0;
    margin-left: 0;
}

#sidebar-new {
  width: 293px;
  position: fixed;
  top: 0;
  right: -720px;
  height: 100vh;
  z-index: 9999;
  background: #FFFFFF;
  color: #fff;
  transition: all 0.3s;
  box-shadow: 3px 3px 3px rgba(0, 0, 0, 0.2);
}
.overlay-new {
  top: 0;
  position: fixed;
  /* full screen */
  width: 100vw;
  height: 100vh;
  /* transparent black */
  background: rgba(0, 0, 0, 0.7);
  /* middle layer, i.e. appears below the sidebar */
  z-index: 9998;
  opacity: 1;
  /* animate the transition */
  transition: all 0.5s ease-in-out;
  &-transparent{
    top: 0;
  position: fixed;
  /* full screen */
  width: 100vw;
  height: 100vh;
  /* transparent black */
  background: transparent;
  /* middle layer, i.e. appears below the sidebar */
  z-index: 9998;
  opacity: 1;
  /* animate the transition */
  transition: all 0.5s ease-in-out;
  }
}
#sidebar-new.active {
  right: 0;
}
.sidebar-component-new{
  color:#414141;
}
.sidebar-main-new{
  padding: 0 0 20px 0;
}
.sidebar-component-new .sidebar-head-new{
  border-bottom: 1px solid #ccc;
}
.sidebar-close-new{
  color:#414141;
  text-align: right;
  padding: 10px 10px 10px 10px;
  &-icon{
    font-size: 25px;
    color: $link-color-19;
    vertical-align: middle;
    &:hover{
      color: #495057;
      text-decoration: none;
      background-color: #f8f9fa;
      cursor: pointer; 
    }
  }
}
.sidebar-close-new:hover{
  color: #495057;
  text-decoration: none;
  background-color: #f8f9fa;
  cursor: pointer; 
}
.sidebar-getstarted-new{
  color:#414141;
  text-align: right;
  padding: 5px 10px 0px 0px;
  .right-div-new{
    margin-right: 5px;
    .power-btn{
      vertical-align: middle;
      font-size: 22px;
      line-height: 14px;
    }
  }
  .login{
    text-transform: uppercase;
    font-size: 14px;
    color: $link-color-19;
    font-weight: 600;
    vertical-align: middle;
    line-height: 25px;
  }
  .signup{
    text-transform: uppercase;
    font-size: 14px;
    color: $link-color-19;
    font-weight: 600;
    vertical-align: middle;
    line-height: 25px;
  }
  .btn-link-login-new{
    font-weight: 600;
    padding-left: 0;
    padding-right: 0;
    font-size: 14px;
    line-height: 25px;
  }
  .btn-link-signup-new{
    font-weight: 600;
    padding-left: 0;
    padding-right: 0;
    font-size: 14px;
    line-height: 25px;
  }
  .btn-link-new {
    color: #488BF8;
    font-weight: 600;
    background-color: #fff;
  }
}
.menu-group-new{
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
}
.menu-group-item-new{
  position: relative;
  display: block;
  font-size:14px;
  line-height: 13px;
  color:#414141;
  padding: 1.75rem 2rem;
  margin-bottom: -1px;
}
.menu-group-item-new.active{
    z-index: 2;
    color: #fff;
    background-color: $link-color-19;
    border-color: $link-color-19;
  }
.menu-group-item-new:hover{
  color: #495057;
  text-decoration: none;
  background-color: #f8f9fa;
}
div.dropdown-menu-new{
  position: static!important;
  transform: none!important;
  width: 100%;
  font-size:14px;
  line-height: 13px;
  // background-color: #f1f1f1;
  color:#414141;
  padding: 0px 0px;
  margin-bottom: -1px;
  box-shadow: none;
  border: none;
}
.dropdown-item-new{
  padding: 1.30rem 1.25rem!important;
}
.menu-group-item-new {
  text-transform: uppercase;
  font-weight: 600;
  color: #414141;
}
a#sidebar-dropdown-new.dropdown-toggle-new:after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.35em solid;
  border-right: 0.35em solid transparent;
  border-bottom: 0;
  border-left: 0.35em solid transparent;
  float: right;
  position: relative;
  top: 8px;
  left: 14px;
}
#sidebar-dropdown-new{
  font-size:14px;
  // line-height: 13px;
  font-weight: 600;
  color: #414141;
  text-transform: uppercase;
  padding: 17px 1.25rem!important;
  display: block !important;
  text-decoration: none;
  /*margin-bottom: -1px;  */
}
#sidebar-dropdown-new.active{
  z-index: 2;
  color: #fff;
  background-color: $link-color-19;
  border-color: $link-color-19;
  display: block !important;
  text-decoration: none;
}
#sidebar-dropdown-new:hover{
  color: #495057!important;
  text-decoration: none;
  background-color: #f8f9fa;
  display: block !important;
  text-decoration: none;
}
.nav-item-new{
  padding: 7px 0px;
  padding-right: 0px;
}
a.dropdown-item-new {
  font-size: 13px;
  background-color : #fafafa;
  display: block !important;
  padding-left: 20px!important;
}
a.dropdown-item-new:hover {
  text-decoration: none;
  color: #16181b;
}
/*.dropdown-menu.show {
  border-radius: 0px;
  border : 0px;
  padding: 0px 0px;
  a:active {
    color: #212529;
  }
}*/
a#sidebar-dropdown-new {
  margin-bottom: -2px;
  padding-left: 20px !important;
  padding-right: 20px !important;
}
.sidebar-component-new .dropdown-new.nav-item-new {
  padding: 1px 0px;
  padding-right: 0px;
}
.dropdown-new {
  position: relative;
}
.show-menu {
  display: block;
}
.hide-menu-new {
  display: none;
}
.scroll-vertical-new {
  overflow-x: hidden;
  overflow-y: auto;
  max-height: 100%;
}
.sidebar-close-icon-new,
.sidebar-close-button {
  font-size: 25px;
  color: #488BF8;
  vertical-align: middle;
}
a#list-settings-list {
  color: rgba(0, 0, 0, 0.7);
}

.ham-btn {
  width: 20px;
  height: 24px;
  vertical-align: middle;
  content: "";
  background: no-repeat center center;
  background-size: 100% 100%;
  background-image: url(https://media.edgeprop.my/assets/icons/hamburger-btn.svg);
  margin-top: -2px;
  display: block;
}

//new added code
@media (min-width: 1025px) {
  #sidebarCollapse-new {
    position: relative;
    top: 11px;
  }
  .flex-fill.bd-highlight {
    position: relative;
    top: 7px;
  }
  
  .savedsearch-link-new {
    position: relative;
    top: 11px;
  }
  .dropdown-new.nav-item-new {
    position: relative;
    top: 23px;
    left: -40px;
  }
}
// .d-flex.bd-highlight.left-line.padding-4 {
//   padding: 14px 0px 14px 9px;
// }
.avatar-img {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  object-fit: cover;
  object-position: center;
}

body { 
  .subs-news {
      display: none;
  }
  &.page-news,
  //guides
  &.node-type-tep-blogs,
  &.page-guides,
  &.page-edgeproptv,
  &.page-pullout,
  &.page-events,
  &.page-event,
  &.page-tags,
  &.page-node-29,
  &.page-node-34,
  //news
  &.page-content {
    .container-full-new.header-mobile-view {
      // height: 71px;
      @media (max-width: 768px) {
          padding: 0 10px;
      }
      .navbar-brand-new {
          .logo-mobile {
              @media (max-width: 1024px) {
                  position: relative; 
                  left: 100%;
              } 
              @media (max-width: 912px) { 
                  left: 84%;
              } 
              @media (max-width: 820px) { 
                  left: 65%;
              }  
              @media (max-width: 767px) {
                  left: 13%;
              } 
              @media (max-width: 425px) { 
                  // left: 21px;
              }
              @media (max-width: 320px) {
                  // width: 80%;
              }
          }
          // @media (max-width: 767px) {
          //     // display: none;
          //     position: relative;
          //     left: 42%; 
          // } 
          // @media (max-width: 480px) { 
          //     left: 38%; 
          // } 
          // @media (max-width: 425px) { 
          //     left: 35%; 
          // } 
      } 
      //center logo header
      // .m-news-search {
      //     .header-search-mazada {
      //         @media (max-width: 1024px) { 
      //             font-size: 20px; 
      //             right: -150%;
      //         }
      //         @media (max-width: 425px) {
      //             right: -36px;
      //         }
      //         @media (max-width: 768px) {
      //             right: -145px;
      //         }
      //         @media (max-width: 375px) {
      //             right: -25px;
      //         }
      //         @media (max-width: 320px) { 
      //             // display: none;
      //             right: -17px;
      //         }
      //     }
      // }
      .m-news-search .header-search-mazada {
          @media (max-width: 1024px) { 
              font-size: 20px; 
              right: -218px;
          }
          @media (max-width: 946px) {  
              right: -200px;
          }
          @media (max-width: 900px) {  
              right: -189px;
          }
          @media (max-width: 860px) {  
              right: -169px;
          }
          @media (max-width: 820px) {  
              right: -160px;
          }
          @media (max-width: 768px) {  
              right: -137px;
          }
          @media (max-width: 740px) { 
              right: -129px;
          }
          @media (max-width: 720px) { 
              right: -120px;
          }
          @media (max-width: 700px) { 
              right: -110px;
          }
          @media (max-width: 680px) { 
              right: -100px;
          }
          @media (max-width: 650px) { 
              right: -90px;
          }
          @media (max-width: 610px) { 
              right: -78px;
          }
          @media (max-width: 550px) { 
              right: -68px;
          }
          @media (max-width: 425px) {
              right: -36px;
          }
          @media (max-width: 510px) {
              right: -48px;
          }
          @media (max-width: 414px) {
              right: -27px;
              // right: -125%;
          }
          @media (max-width: 390px) {
              right: -22px;
              // right: -125%;
          }
          @media (max-width: 375px) {
              right: -17px;
          }
          @media (max-width: 360px) {
              // right: -24px;
          }
          @media (max-width: 320px) {
              right: -10px;
          }
      }
      .subs-news {
          display: none;
          @media (max-width: 1024px) {
              cursor: pointer;
              display: inline;
              border-radius: 5px;
              color: #fff;
              background: #000;
              font-size: 11px;
              font-weight: 600;
              height: 25px;
              line-height: 25px;
              margin-left: 0;
              padding: 4px 9px;
              text-align: center;
              user-select: none;
              width: 73px;
              position: relative;
              top: 2px;
          } 
      }
    }
  }
}