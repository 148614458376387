body {
	&.page-transaction {
		.i-form {
  	    	padding: 13px 0;
    		background-color: hsla(0,0%,100%,.8);
    		width: 100%;
    		max-height: 92px;
    		.group {
  				position: relative;
  				i {
  					&.fa-search {
    					float: left;
    					padding-top: 10px;
    					&:before {
						    font-size: 15px;
						    padding: 10px;
						    color: #333;
						}
    				}
				}
				select {
    				border: 0;
				    background: transparent;
				    //width: 150px;
				    //padding: 5px 35px 5px 5px;
				    //height: 54px;
				    -webkit-appearance: none;
				    -moz-appearance: none;
				    appearance: none;
				    //background: url(home/idropdown.png) 96% / 9% no-repeat;
				    font-size: 14px;
				    color: #333;
				    outline: none;
				    text-transform: none;
				}
  				.wrap-search {
				  	height: 66px;
				    display: inline-block;
				    vertical-align: top;
				    position: relative;
				    margin: 0 10px 0 10px;
				    background-color: #fff;
				    cursor: pointer;
				    padding: 10px 10px;
				    box-shadow: 0 4px 10px 0 rgba(0,0,0,.07);
				    .single {
						vertical-align: middle;
    					display: inline-block;
    					width: calc(100% - 60px);
				    	.dropdown {
  							width: 100%;
  						}
  						#edit-projects {
						    margin-top: 12px;
						    font-size: 14px;
						    color: #333;
						    border: none;
						    text-overflow: ellipsis;
						    cursor: pointer;
						    border-radius: 4px;
						    background-color: #fff;
						    width: 100%;
						    line-height: 1.25;
						    padding: 0;
						    margin: 0;
						}
  					}
  				}
  			}
		}
		::-webkit-input-placeholder {
    		color: #666 !important;
		}
		::-moz-placeholder {
		    color: #666 !important;
		}
		::-ms-placeholder {
    		color: #666 !important;
		}
		::placeholder {
    		color: #666 !important;
		}
		.wrap {
		  	display: inline-block;
		    vertical-align: top;
		    position: relative;
		    margin: 0 5px 0 0;
		    background-color: #fff;
		    cursor: pointer;
		    padding: 5px 5px;
		    box-shadow: 0 4px 10px 0 rgba(0,0,0,.07);
		    outline: none;
		}
		.transaction-input {
			//margin-top: -85px;
    		//width: 930px;
    		.ficon, 
			button, 
			select {
		    	text-transform: none;
			}
			#hero-banner-header1 {
			    margin: 5px 0;
			    color: #fff;
			    text-align: center;
			}
			#hero-banner-header2, 
			#hero-banner-header1 {
    			position: relative;
    			font-family: montserrat,sans-serif;
			}
			&.h1, 
			h1 {
    			font-weight: 200;
    			line-height: 1.2;
    			letter-spacing: .03em;
  			}
  			#hero-banner-header3 {
    			margin-bottom: 40px;
  			}
  			h2 {
			    font-size: 19px;
			    text-align: center;
			    font-weight: 500;
			    margin: 10px 0;
			    color: #fff;
			    font-weight: 100;
  			}
  		}
		.wrap-state {
  	    	width: 164px;
  	    	margin-left: 10px;
  	    	height: 66px;
  		}
  		.transaction-scoreboard {
  			.search-link {
  				.item {
  					a {
  						color: #fff;
  						font-weight: 400
  					}
  				}
  			}	
		}
		.main {
			.content-area {
      			//padding-top: 5rem;
      			//padding-bottom: 5rem;
      			//background-image: url("/home/trans.png");
      			//background-position: center center;
      			//background-repeat: no-repeat;
      			//background-size: cover;

    			//padding: 230px 0 21px;
			    //z-index: auto;
			    //background-color: rgba(2, 2, 2, 0.35);
			    //margin-top: -90px;
			    //height: auto;
			    //background-size: auto;
			    box-shadow: inset 0 -100px 100px -100px #000;
			    //position: relative;
			    .page-header { 
			    	display: none; 
			    }
			    .breadcrumb { 
			    	color: #252525;
			    	li {
			    		a,
			    		&:first-child > a:before {
			    			color: inherit;
			    		}
			    	}
			    	//.main .content-area .breadcrumb > li > a,
					//.main .content-area .breadcrumb > li:first-child > a:before { color: inherit; }
			    }
			}
    	}
    	h1, 
    	h2, 
    	h3, 
    	h4, 
    	h5, 
    	h6, 
    	.h1, 
    	.h2, 
    	.h3, 
    	.h4, 
    	.h5, 
    	.h6 {
    		font-family: poppins,sans-serif;
    		line-height: 1.4;
  		}
  		//h1, h2, h3, h4, h5, h6, .h1, .h2, .h3, .h4, .h5, .h6 {
    	//	font-family: poppins,sans-serif;
    	//	line-height: 1.4;
  		//}
  		@media screen and (max-width: $break_tablet) {
  			.transaction-input {
      			width: 100%;
    		}
    		.input-group-addon {
      			float: right;
      			display: none;
    		}
    	}
    	@media screen and (max-width: $break_m_sm) {
    		.i-form {
    			.group {
    				.wrap-search {
      					padding: 10px 5px;
      					.single {
      						#edit-projects {
      							font-size: 12px;
      						}
      					}
      				}
      			}
      		}
  		}
	}
}
