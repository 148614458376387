//
//
//most sto-singlepage classes is shared at sto.scss
//
//

.sto-singlepage {
  .slide-close {
    padding: 0;
  }
}