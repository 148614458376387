/* ---------------------------------------- */
/*  Custom:Front
/* ---------------------------------------- */

/* Layout */
.front {
  // General
  &-wrapper {
    .hero {
      &-tron {
        display: table;
        width: 100%;
        min-height: 52rem;
        padding: 0;
        > .container {
          display: table-cell;
          width: auto;
          padding-top: 20vh; //malaysia-day update, was 10vh before
          vertical-align: top; // [middle|top]
        }
      }
      &-bg {
        background-color: #6092c3;
      }
      &-gradient {
        background-image: linear-gradient(180deg, rgba($base-color, 0.3) 80%, rgba($base-color, 0.8) 100%);
      }
      &.sticky {
        position: absolute;
      }
    }
  }
  //- Responsive ---------------------------
  @media (max-width: 767px) {
    &-wrapper {
      .hero {
        &-tron {
          min-height: auto;
          > .container {
            padding-top: 0;
            padding-bottom: 0;
          }
        }
      }
    }
  }
  @media (min-width: 768px) and (max-width: 1199px) {
    &-wrapper {
      .main {
        .container {
          width: 64.6rem;
        }
      }
    }
  }
  @media (min-width: 1200px) {
    &-wrapper {
      .hero {
        position: fixed;
        @if $header-bar {
          top: 7.2rem + $header-bar-height;
        } @else {
          top: 7.2rem;
        }
        left: 0;
        width: calc(100% - 64.6rem);
        @if $header-bar {
          height: calc(100vh - #{7.2rem + $header-bar-height});
        } @else {
          height: calc(100vh - 7.2rem);
        }
        transform: translate(0,0);
        &-tron {
          height: 100%;
          min-height: auto;
        }
      }
      .main {
        max-width: 64.6rem;
        margin-left: auto;
        margin-right: 0;
        position: relative;
        .container {
          width: 100%;
          max-width: 80rem;
        }
      }
    }
    .footer {
      position: relative;
      z-index: 2;
    }
  }
  //- Premium Ads --------------------------
  &-wrapper {
    .premium {
      &-landscape {
        > .container {
          background-color: rgba($black, 0.85);
        }
        // Hero
        .hero {
          &-bg {
            top: 11rem;
            z-index: 1;
            background-position: center;
            background-repeat: no-repeat;
            background-size: contain;
            background-color: transparent;
            &-clone {
              display: block;
              background-repeat: repeat-x;
              background-size: contain;
              z-index: 0;
              filter: grayscale(90%) blur(0.2rem);
            }
          }
        }
        // Link
        [data-link] {
          user-select: none;
          cursor: pointer;
        }
      }
    }
  }
  @media (max-width: 767px) {
    &-wrapper {
      .premium {
        &-landscape {
          min-height: 32rem;
          > .container {
            display: block;
            padding-left: 0;
            padding-right: 0;
          }
          // Hero
          .hero {
            &-apps,
            &-social { display: none; }
            &-bg { top: 6rem; }
            &-gradient { display: none; }
          }
          // Search
          .search {
            &-fakebar {
              margin: 0.6rem auto;
            }
          }
        }
      }
    }
  }
  @media (min-width: 768px) {
    &-wrapper {
      .premium {
        &-landscape {
          > .container {
            display: block;
            padding: 0;

          }
          // Search
          .search {
            &-wrapper {
              max-width: none;
              padding: 1.7rem 1.5rem 1rem;
              border-radius: 0;
            }
            &-header {
              display: none;
            }
          }
          // Hero
          .hero {
            &-apps,
            &-social { display: none; }
          }
        }
      }
    }
  }
  @media (min-width: 768px) and (max-width: 1199px) {
    &-wrapper {
      .premium {
        &-landscape {
          // Search
          .search {
            &-wrapper {
              padding-top: 1.8rem;
            }
          }
          // Hero
          .hero {
            &-bg {
              top: 10.5rem;
            }
          }
        }
      }
    }
  }
}

/* Search Hero */
.search-hero {
  //- Layout
  .search-wrapper {
    padding: 3rem 5rem;
    background-color: rgba($hero-background-color, $hero-background-opacity);
    @if $hero-border-color {
      border: 0.1rem solid $hero-border-color;
    }
  }
  .search-header {
    h2 {
      font-size: 2.5rem;
    }
  }
  .search-footer {
    padding-top: 1rem;
    .search-link {
      > .item {
        + .item:before {
          content: '|';
          vertical-align: baseline;
        }
      }
    }
    .ion-icon {
      margin-left: 0.2em;
      margin-right: 0.2em;
      font-size: 2em;
      position: relative;
      top: -0.075em;
      vertical-align: middle;
    }
    a {
      color: #fff;
      text-decoration: none;
      &:hover {
        color: $theme-color-alt;
      }
    }
  }
  //- Components
  .i-form {
    .input,
    .select {
      min-height: auto;
      background-color: transparent;
      border: 0.1rem solid #fff;
      border-radius: 0.2rem;
      label {
        color: #fff;
        line-height: 4rem;
      }
    }
    .input input,
    .select select {
      min-height: 4rem;
      color: #fff;
    }
    .select {
      &:after {
        border-color: #fff transparent transparent;
      }
      select {
        color: #fff;
        line-height: normal;
      }
      option {
        color: $theme-color;
      }
    }
    .checkbox,
    .radio {
      label {
        color: #fff;
        font-weight: 600;
        text-transform: uppercase;
        &:before {
          background-color: transparent;
          border-width: 0.2rem;
        }
      }
    }
    .button.theme {
      background-color: $theme-color-alt;
      border-color: $theme-color-alt;
      border-radius: 0.2rem;
    }
  }
  .twitter-typeahead {
    .tt-menu {
      width: 100%;
      color: $base-color;
    }
    .empty-message {
      font-size: 1.4rem;
    }
  }
  //- Responsive ---------------------------
  @media (max-width: 479px) {
    .search-wrapper {
      padding-left: 1.8rem;
      padding-right: 1.8rem;
    }
    .search-header {
      h2 {
        margin-top: 0;
        font-size: 1.7rem;
      }
    }
  }
  @media (min-width: 1600px) {
    .search-header {
      h2 {
        font-size: 3.6rem;
      }
    }
  }
}

/* Search Tiny */
// Dependenced Search Hero
.search-tiny {
  .search-fakebar-wrapper {
    margin: 12vh auto 10vh;
    text-align: center;
    font-weight: 400;
    h4 {
      font-size: 1.5rem;
    }
    h5 {
      font-size: 0.8rem;
    }
  }
  .search-fakebar {
    max-width: 29rem;
    margin: 0 auto;
    padding: 1.4rem 1.5rem 1.4rem 3.5rem;
    background-color: $white;
    font-size: small;
    line-height: 2rem;
    text-decoration: none !important;
    color: #252525;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    position: relative;
    border-radius: $base-radius;
    .ion-icon {
      font-size: 2rem;
      margin-top: -1rem;
      position: absolute;
      top: 50%;
      left: 1.5rem;
    }
  }
  .search-wrapper {
    max-width: 64.6rem;
    margin-left: auto;
    margin-right: auto;
    padding: 1.5rem 2rem 1rem;
    // background-color: rgba($black, 0.7);
    background-color: transparent;
    font-family: $font-sans-alt;
    border-radius: 0.5rem;
  }
  .search-header {
    h3 {
      margin-bottom: 0.8em;
      font-size: 2rem;
      font-weight: 400;
      line-height: 1;
      letter-spacing: 0.02em;
      text-align: center;
    }
  }
  .search-form {
    text-align: left;
    // Onebar
    .onebar {
      .input-group {
        width: 100%;
        border: 0;
        background-color: $white;
        color: $base-color;
        border-radius: 0.3rem 0 0 0.3rem;
        .input {
          border: 0;
          label,
          input {
            padding-left: 1rem;
            padding-right: 1rem;
            color: $base-color;
            min-height: 4.2rem;
            line-height: 4.2rem;
          }
          .ion-icon {
            font-size: 1.4em;
            position: relative;
            bottom: -0.06em;
          }
        }
        .button {
          margin: 0;
          padding: 1.1rem 1rem;
          border: 0;
          border-right: 0.1rem solid $grey-color5;
          border-radius: 0;
          font-size: 1.4rem;
          .ion-icon {
            font-size: 1.5rem;
            line-height: 1.2;
            vertical-align: middle;
          }
          .ion-navicon {
            display: none;
          }
        }
      }
      .actions {
        .button {
          margin-top: 0;
          padding: 1.1rem 0.5rem;
          border: 0;
          border-radius: 0 0.3rem 0.3rem 0;
        }
      }
    }
    // Dropdown
    .herotiny-dropdown {
      width: 100%;
      margin: 0;
      padding: 0;
      border: 0;
      border-top: 0.1rem solid $input-border-color;
      border-radius: 0;
      box-shadow: 0 0.2rem 1rem -0.1rem rgba($base-color,0.05) inset, 0 0.5rem 1rem 0 rgba($base-color, 0.2);
      > li {
        padding: 1rem 1.2rem;
        cursor: pointer;
        &:hover {
          background-color: $theme-color-alt;
          color: $white;
        }
      }
    }
    // Map Link
    .maps-link {
      float: right;
      padding-top: 0.3rem;
      font-size: 1.3rem;
      .ion-icon {
        display: inline-block;
        margin-top: -0.2rem;
        font-size: 2rem;
        position: relative;
        top: 0.2rem;
      }
      a {
        &:focus,
        &:hover {
          text-decoration: none;
          color: $grey-color5;
        }
      }
      span { display: none; }
    }
  }
  .search-footer {
    margin: 0.75rem -2rem -2rem;
    padding-top: 0;
    padding-bottom: 0.5rem;
  }
  //- Responsive ---------------------------
  @media (max-width: 767px) {
    .search-wrapper {
      padding: 5rem 1.5rem 6.5rem;
      background-color: $white;
      position: fixed;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      z-index: 100;
      opacity: 0;
      visibility: hidden;
      transition: all 250ms ease-in-out;
      transform: translateY(12.5%);
      border-radius: 0.5rem;
      box-shadow: 0 0.2rem 0 0.2rem $base-color;
    }
    .search-dismiss {
      padding: 1rem 1.5rem;
      font-size: 2em;
      color: $base-color;
      line-height: 0;
      position: absolute;
      top: 0;
      left: 0;
      z-index: 1;
    }
    .search-header {
      display: none;
      color: $base-color;
    }
    .search-form {
      height: 100%;
      overflow-x: hidden;
      overflow-y: auto;
      .onebar {
        .radios {
          label {
            color: $base-color;
            &:before {
              border-width: 0.1rem;
              border-color: $base-color;
            }
            &:after {
              background-color: $base-color;
            }
          }
        }
        .input-group {
          background-color: transparent;
          border-radius: 0;
          .input {
            border: 0.1rem solid $base-color;
            label,
            input {
              min-height: 4.6rem;
              line-height: 4.6rem;
            }
          }
        }
        .actions {
          padding: 0.9rem 1.5rem;
          position: fixed;
          bottom: 0;
          left: 0;
          right: 0;
          z-index: 10;
          .button {
            border-radius: 0.3rem;
          }
        }
      }
      .minimal {
        .select {
          margin-bottom: 1rem;
          border-color: $base-color;
          color: $base-color;
          &:after {
            display: none;
            border-color: $base-color transparent transparent;
          }
          select {
            min-height: 4.6rem;
            color: inherit;
          }
          &.character {
            margin-top: 2.5rem;
            &:before {
              content: 'State';
              display: block;
              padding-bottom: 0.35em;
              font-weight: 600;
              line-height: 1.2;
              position: absolute;
              left: -0.2rem;
              bottom: 100%;
            }
            &.area:before{
              content: 'Area';
            }
            + .character {
              margin-top: 3.5rem;
            }
          }
          &.numeric {
            float: left;
            width: 48.5%;
            margin-top: 2.5rem;
            &:before {
              content: 'Price Min';
              display: block;
              padding-bottom: 0.35em;
              font-weight: 600;
              line-height: 1.2;
              position: absolute;
              left: -0.2rem;
              bottom: 100%;
            }
            + .numeric {
              margin-left: 3%;
              &:before {
                content: 'Price Max';
              }
            }
          }
        }
      }
    }
    &.active {
      .search-wrapper {
        opacity: 1;
        visibility: visible;
        transform: translateY(0);
      }
    }
  }
  @media (min-width: 768px) {
    .search-fakebar-wrapper, .search-fakebar {
      display: none;
    }
    .search-form {
      // Minimal
      .minimal {
        margin-left: -0.5rem;
        margin-right: -0.5rem;
        > .i-col {
          padding-left: 0.5rem;
          padding-right: 0.5rem;
        }
        .select {
          display: inline-block;
          width: auto;
          border: 0;
          padding-right: 1.2rem;
          margin-right: 1.2rem;
          &:after {
            margin-top: -0.2rem;
            top: 50%;
            right: 0;
          }
          select {
            min-height: auto;
            line-height: normal;
            padding: 0.5rem 0;
          }
        }
      }
    }
  }
  @media (min-width: 1200px) {
    .search-wrapper {
      max-width: none;
      padding: 2rem 2.5rem 1.3rem;
    }
    .search-header {
      h3 {
        margin-bottom: 1em;
        font-size: 2.6rem;
      }
    }
    .search-form {
      .onebar {
        .input-group {
          .input {
            label,
            input {
              min-height: 4.8rem;
              line-height: 4.8rem;
              font-size: 1.1em;
              width: 100%;
            }
          }
          .button {
            padding: 1.4rem 1.8rem;
            span {
              font-size: 1.2em;
              line-height: 1;
            }
          }
        }
        .actions {
          .button {
            padding-top: 1.5rem;
            padding-bottom: 1.5rem;
            font-size: 1.2em;
            line-height: 1.1;
          }
        }
      }
      .maps-link {
        span { display: inline; }
      }
    }
  }
}

/* Regular Homepage Block */
.block {
  &-front-navi {
    .block-title {
      margin-top: 0;
      margin-bottom: 0;
      padding-top: 2rem;
      padding-bottom: 2rem;
      line-height: 1;
      text-align: center;
    }
    .nav-anchor {
      background-color: $grey-color2;
      @include clearfix;
      > li {
        float: left;
        width: (100% / 3);
        border-left: 1px solid rgba($white, 0.5);
        &:first-child {
          border-left: 0;
        }
        > a {
          padding: 1rem;
          border: 0;
          background-color: $grey-color2;
          color: $white;
          font-weight: 400;
          line-height: 1.3;
          border-radius: 0;
          &:hover {
            background-color: mix($grey-color2, $theme-color, 55%);
          }
        }
        &.active {
          > a {
            background-color: $theme-color-alt;
            color: $white;
          }
        }
      }
    }
    // Sticky
    &.nav-sticky {
      .nav-anchor {
        width: 61.6rem;
        position: fixed;
        z-index: 9;
        transform: translate3d(0,0,0);
      }
    }
    // Icons
    .navi {
      &-icon {
        display: inline-block;
        width: 3rem;
        height: 3rem;
        background-repeat: no-repeat;
        background-position: center center;
        background-size: 100%;
        &.news { background-image: url('../img/navi/news-active.png'); }
        &.listings { background-image: url('../img/navi/listings-active.png'); }
        &.research { background-image: url('../img/navi/research-active.png'); }
      }
    }
    //- Responsive ---------------------------
    @media (max-width: 767px) {
      &.nav-sticky {
        .nav-anchor {
          width: 100%;
          left: 0;
        }
      }
      .navi {
        &-icon {
          float: left;
          width: 2rem;
          height: 2rem;
        }
        &-text {
          padding-left: 2.7rem;
          line-height: 2rem;
          text-align: left;
        }
      }
    }
  }
  // Editor's Pick
  &-editors-pick {
    $ep-gap: 0.8rem;
    .i-row {
      // margin-left: - $ep-gap;
      // margin-right: - $ep-gap;
      > .i-col {
        // padding-left: $ep-gap;
        // padding-right: $ep-gap;
        margin-bottom: $ep-gap * 2;
      }
      > .col-sm-6 {
        max-height: 25rem;
      }
    }
    .news {
      &-boxes {
        position: relative;
        padding-bottom: 5.8rem;
        border: 1px solid $grey-color5;
        box-shadow: 0 0.2rem 0.6rem -0.3rem rgba($base-color, 0.2);
        + .news-boxes { margin-top: 1.6rem; }
      }
      &-cover {
        display: block;
        figure {
          height: 100%;
        }
        img {
          width: 100%;
          height: auto;
        }
      }
      &-content {
        width: 100%;
        padding: 1.071em;
        background-color: $even-color;
        position: absolute;
        left: 0;
        bottom: 0;
      }
      &-category {
        display: none;
        margin-top: 0;
        margin-bottom: 0.8em;
        font-size: 0.714em;
        text-transform: uppercase;
        letter-spacing: 0.06em;
        &:after {
          content: '/';
          margin-left: 0.54em;
        }
      }
      &-title {
        margin-bottom: 0.833em;
        font-size: 1.143em;
        > a {
          color: inherit;
        }
      }
      &-date {
        margin-top: 0.727em;
        font-size: 0.714em;
        line-height: 1.364em;
        text-transform: uppercase;
      }
      // Specify
      &-boxes.no-image {
        padding-bottom: 0;
        .news {
          &-cover { display: none; }
          &-content { position: relative; }
          &-title { height: 4.8rem; overflow: hidden; }
        }
      }
      &-boxes.large {
        .news {
          &-title {
            height: 6.5rem;
            margin-bottom: 0;
          }
        }
      }
      &-boxes.highlight {
        border-color: $header-nav-bgcolor;
        box-shadow: 0 0.2rem 0.6rem -0.3rem rgba($header-nav-bgcolor, 0.2);
        .news {
          &-content {
            background-color: $header-nav-bgcolor;
          }
        }
      }
      &-boxes.featured {
        .news-cover {
          &:before {
            content: 'Featured';
            display: block;
            padding: 0.5rem 1rem;
            background-color: mix($color-danger, $base-color, 75%);
            color: $white;
            font-size: 1.2rem;
            letter-spacing: 0.08rem;
            position: absolute;
            top: 1rem;
            left: -0.1rem;
            z-index: 1;
          }
        }
      }
    }
    //- Responsive ---------------------------
    @media (max-width: 767px) {
      .i-row {
        > .col-sm-6 {
          max-height: none;
        }
      }
      .news {
        &-boxes {
          padding-bottom: 0;
          @include clearfix;
        }
        &-cover {
          width: 11em;
          height: 100%;
          position: absolute;
          top: 0;
          left: 0;
          z-index: 1;
          overflow: hidden;
          img {
            width: 140%;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate3d(-50%, -50%, 0) scale(1.3);
          }
        }
        &-content {
          width: auto;
          padding-left: 12em;
          position: relative;
          left: auto;
          bottom: auto;
        }
        // Specify
        &-boxes.no-image {
          .news {
            &-cover {
              display: block;
            }
          }
        }
      }
    }
    @media (max-width: 479px) {
      .news {
        &-cover {
          width: 7.5em;
        }
        &-content {
          padding-left: 8.5em;
        }
        &-title {
          font-size: 0.9em;
        }
        // Specify
        &-boxes.no-image {
          .news {
            &-title { height: auto; overflow: visible; }
          }
        }
      }
    }
    @media (min-width: 768px) {
      .news {
        //- Check $('.news-title').text().length < 28
        &-boxes.side-image {
          display: flex;
          padding-bottom: 0;
          .news {
            &-cover {
              width: 100%;
              height: 10.5rem;
              position: relative;
              overflow: hidden;
              img {
                width: 130%;
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate3d(-50%, -50%, 0);
              }
            }
            &-content {
              position: relative;
              left: auto;
              bottom: auto;
            }
          }
        }
        &-boxes.large {
          .news {
            &-cover {
              height: 11.5rem;
            }
          }
        }
      }
    }
  }
  // Newsletter
  &-newsletter {
    .nl-cover {
      display: none;
    }
    .nl-boxes {
      padding: 2rem;
      background-color: $theme-color;
      color: $white;
      text-align: center;
      h3 {
        margin-top: 0;
        margin-bottom: 0.8em;
        font-size: 1.8rem;
        font-weight: 500;
      }
      .i-form {
        .input {
          margin-bottom: 0.8rem;
          background-color: transparent;
          label {
            color: $white;
          }
        }
        .button {
          border: 0;
        }
      }
    }
    //- Responsive ---------------------------
    @media (min-width: 768px) {
      .nl-wrapper {
        display: flex;
      }
      .nl-cover {
        display: flex;
        &-wrap {
          min-width: 27rem;
          min-height: 100%;
          position: relative;
          overflow: hidden;
          img {
            min-width: 32rem;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate3d(-50%, -50%, 0);
          }
        }
      }
      .nl-boxes {
        padding: 2rem 3rem;
      }
    }
  }
  // Most Popular
  &-trending {
    .i-row {
      > .i-col {
        &:nth-child(2n+3) {
          clear: both;
        }
      }
    }
    .i-items {
      > .item {
        padding: 1rem;
        border: 0.2rem solid $even-color;
      }
    }
    .content {
      .title {
        color: $theme-color;
        font-size: 1.2rem;
        font-weight: 600;
        // text-transform: uppercase;
        line-height: 1.4;
        letter-spacing: 0.1rem;
      }
      .list-inline {
        margin-top: 0.727em;
        margin-bottom: 0;
        font-size: 0.786em;
        text-transform: uppercase;
      }
      .tag {
        color: $theme-color-alt;
      }
      &.icon {
        flex: none;
        margin-right: 1rem;
        padding-top: 1.8rem;
        padding-bottom: 1.8rem;
        color: $grey-text;
        line-height: 0;
      }
    }
    svg {
      fill: $theme-color-alt;
    }
  }
  // Showcase
  &-showcase {
    .showcase-title {
      margin: 0;
      padding: 0.7rem 1.5rem;
      background-color: rgba($base-color, 0.75);
      color: $white;
      font-size: 1.5rem;
      text-transform: uppercase;
      position: absolute;
      left: 10rem;
      top: 6rem;
      z-index: 2;
    }
    .i-carousel {
      .item {
        > .caption {
          padding-top: 1rem;
          padding-bottom: 1rem;
          background-color: rgba($theme-color, 0.8);
          :last-child {
            margin-bottom: 0;
          }
        }
      }
      .owl-nav {
        color: $white;
        i { text-align: center; }
        .owl-prev,
        .owl-next {
          width: 4.2rem;
          height: 4.2rem;
          padding-left: 1rem;
          padding-right: 1rem;
          line-height: 4.2rem;
          font-size: 1.8rem;
          background-color: rgba($theme-color, 0.8);
          color: $theme-color-alt;
        }
      }
    }
    //- Responsive ---------------------------
    @media (max-width: 767px) {
      .i-carousel {
        .item {
          > .caption {
            font-size: 95%;
            line-height: 1.1;
          }
        }
      }
    }
  }
  // News
  &-news {
    .block-title {
      padding: 1.2rem 1.5rem;
      background-color: $theme-color;
      color: $white;
      font-size: 1.3rem;
      text-transform: uppercase;
      letter-spacing: 0.3rem;
    }
    .more-link {
      &.absolute {
        padding-top: 0.7rem;
        padding-bottom: 0.7rem;
        color: $white;
        font-size: 2.5rem;
        line-height: 1;
        top: -6rem;
      }
    }
    .i-card {
      position: relative;
      > .content {
        border: 0.2rem solid #f7f6f6;
        > .title {
          margin-top: 0.5rem;
          margin-bottom: 0.8rem;
          font-size: 1.5rem;
          font-weight: 500;
          line-height: 1.4;
        }
        > .meta {
          font-size: 1.1rem;
          color: inherit;
          &.category {
            font-size: 1rem;
            text-transform: uppercase;
          }
        }
      }
    }
    //- Responsive ---------------------------
    @media (max-width: 991px) {
      .more-link {
        &.absolute {
          position: absolute;
          right: 0;
        }
      }
      // &.news-in-depth {
      //   .i-card {
      //     @include clearfix;
      //     .image {
      //       display: none;
      //     }
      //     &.odd {
      //       > .content {
      //         width: auto;
      //         position: relative;
      //         left: auto;
      //         bottom: auto;
      //       }
      //     }
      //   }
      // }
    }
    @media (min-width: 992px) {
      // &.news-in-depth {
      //   .i-card {
      //     background-color: $theme-color-alt;
      //     color: #fff;
      //     a {
      //       color: inherit;
      //     }
      //     > .content {
      //       border: 0;
      //       > .title {
      //         font-size: 1.5rem;
      //       }
      //     }
      //     &.odd {
      //       background-color: $theme-color;
      //       > .content {
      //         width: 100%;
      //         position: absolute;
      //         left: 0;
      //         bottom: 0;
      //         background-image: linear-gradient(transparent 5%, rgba($theme-color, 0.8) 60%, rgba($theme-color, 1) 100%);
      //         > .title {
      //           font-size: 1.2rem;
      //         }
      //       }
      //     }
      //   }
      // }
    }
  }
  // Listing
  &-listing {
    a.image {
      display: block;
      overflow: hidden;
      img {
        width: 100%;
        transition: all 250ms ease;
      }
      &:hover {
        img {
          transform: scale(1.06);
        }
      }
      .indicator {
        padding: 1rem 1.5rem;
        background-color: $theme-color-alt;
        color: $white;
      }
    }
    .i-card {
      > .content {
        > .title {
          margin-bottom: 0.7rem;
          font-size: 1.4rem;
          font-weight: 600;
        }
      }
    }
  }
  // Media
  &-edgetv {
    .i-card {
      > .content {
        > .category {
          margin-top: -0.2em;
          margin-bottom: 0.6em;
          font-weight: 600;
          color: $theme-color-alt;
        }
        > .title {
          margin-top: 0;
          margin-bottom: 0.5em;
          font-size: 1.4rem;
        }
      }
    }
  }
  // Event Calendar
  &-event-calendar {
    .event-item {
      @include clearfix;
      > .date {
        float: left;
        width: 7.5rem;
        background-color: $theme-color-alt;
        color: $white;
        text-align: center;
        > span {
          display: block;
          padding: 0.6rem 0.5rem;
          font-size: 1rem;
          text-transform: uppercase;
          &.day {
            padding: 0 0.5rem 0.5rem;
            font-size: 2.8rem;
            line-height: 1;
          }
          &.text {
            background-color: $base-color;
          }
        }
      }
      > .content {
        margin-left: 8.5rem;
        > .title {
          margin-top: 0;
          font-size: 1.3rem;
          font-weight: 600;
          line-height: 1.2;
        }
        > .time {
          font-size: 1.1rem;
          color: $grey-text;
        }
      }
    }
  }
  // Research
  &-research {
    .block-title {
      margin-top: -0.5rem;
    }
    .i-row {
      > .i-col {
        margin-bottom: 1.6rem;
      }
    }
    a.image {
      display: block;
      overflow: hidden;
      img {
        width: 100%;
        transition: all 250ms ease;
      }
      &:hover {
        img {
          transform: scale(1.06);
        }
      }
    }
  }
  // TheEdgeMarkets same classes with Most Popular
  &-tem {
    .embed-tem {
      padding-bottom: 56.81818182%;
    }
    //- Responsive ---------------------------
    @media (max-width: 479px) {
      .embed-tem { padding-bottom: 73.5rem; }
    }
  }
  // Ads
  &-mrec, &-adbutton {
    max-width: 30rem;
    margin-left: auto;
    margin-right: auto;
  }
}
