/* ---------------------------------------- */
/*  Custom:Misc
/* ---------------------------------------- */
// Malaysia Property Excellence Awards
.mpea {
  &-2017 {
    $mpea-2017-theme: $theme-color;
    $mpea-2017-title: $base-color;
    $mpea-2017-subtitle: $theme-color;
    .mpea {
      &-title {
        margin-top: 0;
        margin-bottom: 0;
        font-size: 2rem;
        font-weight: 600;
        color: $mpea-2017-title;
      }
      &-subtitle {
        margin-top: 0;
        margin-bottom: 1.8rem;
        font-size: 1.5rem;
        color: $mpea-2017-subtitle;
      }
      &-boxes {
        display: flex;
        margin-left: -0.5rem;
        margin-right: -0.5rem;
      }
      &-card {
        display: block;
        width: 33.3333%;
        margin-left: 0.5rem;
        margin-right: 0.5rem;
        border: 0.1rem solid mix($mpea-2017-theme, $white, 18%);
        background-color: $white;
        box-shadow: 0 0.2rem 1rem -0.5rem rgba($mpea-2017-theme, 0.5);
        transition: all 150ms linear;
        .content {
          padding: 1.5rem;
        }
        .title {
          margin-top: 0;
          margin-bottom: 0.5em;
          color: $mpea-2017-theme;
          font-size: 115%;
          font-weight: 500;
          line-height: 1.34;
        }
        .description {
          color: $dark-text;
          font-size: 95%;
          line-height: 1.3;
        }
        &:hover {
          border-color: rgba($mpea-2017-theme, 0.5);
          box-shadow: 0 0.2rem 1rem -0.5rem rgba($mpea-2017-theme, 0.5), 0 0 0 0.1rem rgba($mpea-2017-theme, 0.5);
        }
        &:focus,
        &:hover {
          text-decoration: none;
        }
      }
    }
    @media (max-width: 479px) {
      .mpea {
        &-boxes {
          display: block;
          margin-left: 0;
          margin-right: 0;
        }
        &-card {
          display: block;
          width: 100%;
          margin: 0 0 1.5rem;
          &:last-child {
            margin-bottom: 0;
          }
        }
      }
    }
  }
}