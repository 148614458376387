
body {
  &.homeadvisor-form {
    padding-top: 0;
    #homeadvisor {
      .frame {
        width: 100%;
        margin: 0 auto;
        @media only screen and (min-width: $break_tablet) {
          max-width: 90%;
        }
        @media only screen and (min-width: 1164px) {
          max-width: 1134px;
        }
      }
     
      .hidden {
        display: none;
      }
      main {
        @media only screen and (min-width: $break_tablet) {
          background-color: transparent;
        }
      }

      .background {
        position: fixed;
        display: block;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        z-index: -2;
        background-color: #5d91db;  
        background-image: url("https://s3-ap-southeast-1.amazonaws.com/files.theedgeproperty.com.my/public/images/homeadvisor/bg/homeadvisor-bg.jpg");
        background-size: cover;
        @media screen and (max-width: 775px) {
          background-image: none;
        }
      }

      fieldset {
        border: 0;
        margin: 0;
        padding: 0;
      }

      .required__star {
        position: absolute;
        content: "*";
        padding-left: 3px;
        color: red;
        @media only screen and (min-width: $break_tablet) {
          right: 38px;
        }
      }

      .form__wrapper {
        position: relative;
        z-index: 10;
        border-radius: 4px;
        padding: 7px;
        background-color: rgba(255, 255, 255, 0);
        .form__block {
          border: 0;
          position: relative;
          margin-bottom: 15px;
          background-color: white;
          .form__block__wrapper {
            display: inline-block;
            width: 100%;
            padding: 20px 10px 20px 10px;
            @media only screen and (min-width: $break_tablet) {
              padding: 38px;
            }
            h3 {
              margin-bottom: 10px;
              font-family: $body_font;
              font-weight: normal;
              font-weight: lighter;
              font-size: 2rem;
              color: $theme_color;
              &:first-letter {
                text-transform: uppercase;
              }
              .block__sub__title {
                display: block;
                margin-top: 10px;
                font-size: 14px;
                // font-size: .875rem;
                font-family: $body_font;
                font-weight: normal;
                text-decoration: inherit;
                color: inherit;
                @media only screen and (min-width: $break_tablet) {
                  display: inline-block;
                  margin-left: 5px;
                } 
              }
              @media only screen and (max-width: $break_tablet) {
                margin-bottom: 32px;
                text-align: center;
              }
            }
            .form__item {
              .inputs {
                @media only screen and (min-width: 960px) {
                  width: 54%;
                }
                .input__phone-type {
                  display: inline-block;
                  width: calc(50% - 5px);
                  float: left;
                  @media only screen and (min-width: 960px) {
                    width: 115px;
                  }
                  @media only screen and (min-width: $break_post_tablet) {
                    width: 95px;
                    float: none;
                    margin-right: 10px;
                    select {
                      width: inherit;
                    }
                  }
                  @media only screen and (max-width: $break_m_lg) {
                    width: 48%;
                    float: none;
                    margin-right: 10px;
                    select {
                      width: 79%;
                    }
                  }
                }
                .input__region-number {
                  display: inline-block;
                  width: calc(50% - 5px);
                  float: right;
                  @media only screen and (min-width: 960px) {
                    width: 90px;
                  }
                  @media only screen and (min-width: $break_tablet) {
                    width: 70px;
                    float: none;
                    margin-right: 10px;
                  }
                }
                .input__phone-number, 
                .input__mobile-number {
                  display: inline-block;
                  width: 100%;
                  margin-top: 10px;
                }
                .input__phone-number {
                  @media only screen and (min-width: 960px) {
                    width: calc(100% - 225px);
                    max-width: 175px;
                  }
                  @media only screen and (min-width: $break_post_tablet) {
                    width: calc(100% - 185px);
                    margin-top: 0;
                    max-width: 210px;
                  }
                  @media only screen and (max-width: $break_m_lg) {
                    width: calc(100% - 185px);
                    // margin-top: 15px; 
                    width: 100%;
                  }
                }
                .input__mobile-number {
                  @media only screen and (min-width: 960px) {
                    // width: calc(100% - 125px);
                    // max-width: 275px;
                  }
                  @media only screen and (min-width: $break_post_tablet) {
                    margin-top: 0;
                    // width: calc(100% - 105px);
                    max-width: 100%;
                  }
                  @media only screen and (max-width: $break_tablet) {
                    margin-top: 0;
                    // width: calc(100% - 105px);
                    width: 100%;
                  }
                }
                span {
                  &.hide {
                    display: none;
                  } 
                }
                input[type='radio'] + label {
                  &.btn__radio {
                    @media only screen and (min-width: 960px) {
                      margin-right: 40px;
                    }
                    @media only screen and (min-width: 480px) {
                      font-size: 17px;
                      position: relative;
                      margin: 0 40px 0 30px;
                      cursor: pointer;
                      display: inline-block;
                      padding: 0;
                      border: 0;
                      text-align: left;
                      background-color: #fff;
                      color: #494949;
                    }
                    &.btn__radio--half {
                      @media only screen and (min-width: 480px) {
                        width: auto;
                      }
                      &.btn__float {
                        @media only screen and (min-width: 480px) {
                          float: none;
                        }
                      }
                      &.btn__radio--blocked {
                        @media only screen and (min-width: 480px) {
                          display: block;
                          margin-bottom: 10px;
                          margin-right: 0;
                        }
                      }
                    }
                  }
                }
                input[type='radio']:checked + label {
                  &.btn__radio {
                    @media only screen and (min-width: 480px) {
                      border: 0;
                      background-color: #fff;
                      color: #494949;
                    }
                  }
                }
                &.inputs__error {
                  input[type='radio'] + label {
                    &.btn__radio {
                      @media only screen and (min-width: 480px) {
                        border: 0;
                      }
                    }
                  }
                  &.btn__radio--half {
                    &.btn__float {
                      @media only screen and (min-width: 480px) {
                        float: none;
                      }
                    }
                  }
                }
              }
            }
          }
          &.transparent {
            background-image: none;
            background-color: transparent;
          }
          .form__item {
            position: relative;
            font-size: 0;
            margin: 15px auto 10px auto;
            @media only screen and (min-width: $break_tablet) {
              margin: 15px auto;
            }
            float: left;
            width: 100%;
            &:after {
              content: "";
              display: table;
              clear: both;
            }
            .inputs {
              display: block;
              width: 100%;
              @media only screen and (min-width: $break_post_tablet) {
                display: inline-table;
                width: 50%;
              }
              @media only screen and (max-width: $break_tablet) {
                display: block;
                width: 100%;
              }
              &.inputs__radio {
                vertical-align: top;
              }

              &.inputs__error {
                select.error__style {
                  border: 1px solid red;
                }
                input {
                  &.error__style {
                    border: 1px solid red;
                  }
                  &[type='radio'] + label {
                    &.btn {
                      border: 1px solid red;
                    }
                    &.btn__radio--half.btn__float {
                      float: right;
                    }
                    &.btn__radio {
                      @media only screen and (min-width: $break_tablet) {
                        margin-right: 25px;
                      }
                    }
                  }
                }
                .error {
                  position: relative;
                  display: block;
                  font-size: 17px;
                  font-size: 1rem;
                  color: red;
                  margin-top: 5px;
                  margin-left: 23px;
                  a {
                    color: red;
                    @media only screen and (min-width: $break_tablet) {
                      text-decoration: none;
                      cursor: default;
                    }
                  }
                  @media only screen and (min-width: $break_tablet) {
                    max-width: 380px;
                  }
                }
              }
            }
            .label {
              position: relative;
              display: block;
              font-size: 17px;
              margin-bottom: 10px;
              font-family: $body_font;
              font-weight: normal; 
              color: #555;
              @media only screen and (min-width: $break_post_tablet ) {
                display: inline-block;
                width: 46%;
                text-align: right;
                padding-right: 50px;
                margin-bottom: 0;
                font-family: $body_font;
                font-weight: normal;
              }
              @media only screen and (max-width: $break_tablet) {
                width: 100%;
                text-align: center;
                display: block;
              }
              &:before {
                content: "";
                display: none;
              }
              &.required:after {
                // position: absolute;
                content: "*";
                padding-left: 3px;
                color: red;
                @media only screen and (min-width: $break_tablet) {
                  right: 38px;
                }
              }
              &:first-letter {
                text-transform: uppercase;
              }
              span.required:after {
                position: absolute;
                content: "*";
                padding-left: 3px;
                color: red;
                @media only screen and (min-width: $break_tablet) {
                  right: 38px;
                }
              }
              .item__sub__title {
                font-size: 12px;
                // font-size: .875rem;
                // line-height: 1rem;
                display: block;
                margin-top: 8px;
                margin-bottom: 20px;
                width: 95%;
                color: #747678;
                font-family: $body_font;
                font-weight: normal;
                @media screen and (min-width: $break_tablet) {
                  float: right;
                  margin-bottom: 0;
                }
                @media screen and (max-width: $break_m_lg) {
                  display: none !important;
                }
              }
            }
            fieldset .float__left {
              float: none;
              @media only screen and (min-width: $break_tablet) {
                float: left;
              }
            }
            select {
              @media only screen and (min-width: $break_post_tablet) {
                max-width: 400px;
              }
              @media only screen and (man-width: $break_tablet) {
                max-width: 100%;
              }
            }
            input {
              &[type='text'], 
              &[type='tel'], 
              &[type='number'],
              &[type='email'] {
                @media only screen and (min-width: $break_post_tablet) {
                  max-width: 400px;
                }
                @media only screen and (max-width: $break_tablet) {
                  max-width: 100%;
                }
              }
            }
            label {
              // cursor: pointer;
            }
          }
        }
        @media only screen and (min-width: $break_tablet) {
          padding: 17px;
          background-color: rgba(255, 255, 255, 0.7);
        }
      }

      .block__plus {
        display: block;
        position: relative;
        margin-right: 10px;
        .action__plus {
          cursor: pointer;
          span {
            position: absolute;
            width: 25px;
            height: 3px;
            background: #007dba;
            right: 0;
            bottom: 8px;
            &:last-child {
              -webkit-transform: rotate(90deg);
              -moz-transform: rotate(90deg);
              -ms-transform: rotate(90deg);
              transform: rotate(90deg);
            }
          }
        }
        @media only screen and (min-width: $break_tablet) {
          display: none;
        }
      }
      
      .form_actions {
        display: none;
        button {
          display: inline-block;
          width: 100px;
          padding: 10px;
          font-size: 17px; 
          text-align: center;
          border-radius: 4px;
          cursor: pointer;
          &.action__delete {
            margin-left: calc((60% - 100px)/2);
            color: white;
            background-color: #ca4d49;
            border: 1px solid #ca4d49;
            @media only screen and (min-width: $break_tablet) {
              border: 0;
              color: #494949;
              background-color: #e5e5e5;
              margin: 0;
            }
          }
          &.action__confirm {
            margin-right: calc((60% - 100px)/2);
            color: white;
            background-color: #007dba;
            border: 1px solid #007dba;
            @media only screen and (min-width: $break_tablet) {
              border: 0;
              color: #494949;
              background-color: #e5e5e5;
              margin: 0;
            }
          }
          &:last-child {
            float: right;
          }
          @media only screen and (min-width: $break_tablet) {
            width: calc(50% - 1px);
            padding: 12px;
            border-radius: 0;
            border: 0;
            color: #494949;
            background-color: #e5e5e5;
            font-weight: 600;
            &:hover {
              color: $theme_color;
              background-color: lighten( $theme_color, 50% );
            }
            &.single {
              width: 100%;
            }
          }
        }
        @media only screen and (min-width: $break_tablet) {
          display: block;
        }
      }

      .form__block .disclaimer {
        margin-top: 10px;
        // font-size: .875rem;
        // line-height: 1.25rem;
        color: #747678;
        p {
          margin-bottom: 10px;
          &:last-child {
            margin-bottom: 0;
          }
        }
        @media only screen and (min-width: $break_tablet) {
          margin-top: 0;
        }
      }
     
      .header_title {
        text-align: center;
        margin: 25px 0 20px 0;
        &_text {
          .header_title_text_wrapper {
            width: 80%;
            margin: 0 auto;
            font-family: $body_font;
            font-weight: normal;
            font-weight: lighter;
            .text_primary {
              font-size: 26px;
              text-transform: capitalize;
              color: #fff;
              line-height: 40px;
              font-weight: 600;
              margin: 0;
              span {
                display: block;
                font-size: 17px;
                font-weight: 400;
                line-height: 24px;
                text-transform: initial;
              }
            }
            @media only screen and (min-width: $break_tablet) {
              width: 600px;
              margin: 0 0 0 15px;
            }
          }
        }
        @media only screen and (min-width: $break_tablet) {
          background-color: transparent;
          text-align: left;
          margin: 40px 0;
        }
      }

      .errorpage_container {
        height: calc(100vh - 500px);
        text-align: center;
        display: table;
        width: 100%;
        min-height: 400px;
        position: relative;
        z-index: 10;
        .errorpage_wrapper {
          display: table-cell;
          vertical-align: middle;
          .errorpage_title {
            margin-bottom: 15px;
            h1 {
              @media only screen and (min-width: $break_tablet) {
              }
            }
            @media only screen and (min-width: $break_tablet) {
              margin-bottom: 30px;
            }
          }
          .errorpage_discription {
            width: 83%;
            margin: 0 auto;
            margin-bottom: 35px;
            @media only screen and (min-width: $break_tablet) {
              margin-bottom: 60px;
            }
            p {

            }
          }
          .errorpage_backlink {
            color: #007dba;
            text-decoration: inherit;
            display: inline-block;
            border: 0;
            background: inherit;
            cursor: pointer;
            &:focus {
              color: #007dba;
            }
            @media only screen and (min-width: $break_tablet) {
              margin-bottom: 45px;
            }
          }
        }
        @media only screen and (min-width: $break_tablet) {
          height: calc(100vh - 250px);
        }
        @media only screen and (min-width: 960px) {
          height: calc(100vh - 262px);
        }
      }

      .form__wrapper {
        padding: 25px 20px 10px 20px;
        .form__block {
          .form__success {
            text-align: center;
            h3 {
              text-align: center;
              color: $theme_color;
            }
            p, .normal_buyer, .fhb_buyer {
              text-align: center;
              margin: 20px auto 0 auto;
            }
            p {
              @media only screen and (min-width: 480px) {
                width: 80%;
              }
              @media only screen and (min-width: $break_tablet) {
                width: 80%;
              }
              @media only screen and (min-width: 1164px) {
                width: 50%;
              }
            }
            .normal_buyer, .fhb_buyer {
              @media only screen and (min-width: 480px) {
                width: 80%;
              }
              @media only screen and (min-width: $break_tablet) {
                width: 80%;
              }
              @media only screen and (min-width: 1164px) {
                width: 50%;
              }
            }
          }
          .form--confirmation {
            width: 50px;
            height: 50px;
            margin: 25px auto;
          }
          .form__error {
            h3 {
              text-align: center;
              color: $theme_color;
            }
            p {
              text-align: center;
              margin: 20px auto 10px auto;
              a {
                white-space: nowrap;
              }
              @media only screen and (min-width: 320px) {
                width: 78%;
              }
              @media only screen and (min-width: $break_tablet) {
                width: 80%;
              }
              @media only screen and (min-width: 1164px) {
                width: 37%;
              }
            }
            a {
              color: #007dba;
              text-decoration: none;
              &:hover {
                text-decoration: underline;
              }
            }
          }
          .form--error {
            width: 50px;
            height: 50px;
            margin: 25px auto;
          }
        }
      }

      .error__actions {
        button {
          width: 50%;
          border: 0;
          display: inline-block;
          background-color: #e4e4e4;
          padding: 15px;
          text-align: center;
          cursor: pointer;
          color: #313131;
          font-size: 18px;
        }
        &.return__action {
          text-align: center;
          padding: 25px 0 10px 0;
          .error_button {
            text-align: center;
            cursor: pointer;
            display: inline-block;
            border-style: solid;
            border-width: thin;
            text-decoration: none;
            border-color: #007dba;
            background-color: white;
            color: #007dba;
            border-radius: 4px;
            padding: 10px;
            width: 120px;
            max-width: 80%;
          }
        }
      }
     
      .address__ui {
        display: inline-block;
        width: 100%;
        position: relative;
        @media only screen and (min-width: $break_post_tablet) {
          display: inline-table;
          width: 50%;
        }
        @media only screen and (max-width: $break_tablet) {
          display: block;
          width: 100%;
        }
        @media only screen and (min-width: 960px) {
          width: 54%;
        }
      }
      .report-success {
        h3, span, p {
          text-align: center;
        }
        h3 {
          margin-bottom: 20px;
        }
        p {
          @media screen and (min-width: $break_d_sm) {
            width: 50%;
            margin: 0 auto;
          }
        }
        span.confirmed {
          background: #30ac30;
          width: 50px;
          height: 50px;
          border-radius: 50%;
          color: #fff;
          font-size: 21px;
          padding-top: 13px;
          margin: 0 auto 20px;
          display: block;
        } 
      }
     
      .general-block {
        font-size: 17px;
        color: #555;
        .small {
          margin-top: 10px;
          display: block;
          font-size: 12px;
          color: #747678;
        }
        label {
          cursor: pointer;
        }
      }

      .submit__action {
        text-align: right;
        .btn {
          @media screen and (max-width: $break_m_lg) {
            width: 100%;
          }
        }
      }

      label {
        font-size: 17px;
        // margin-left: 10px;
      }
      input {
        &[type='radio'] {
          // height: 0;
          // float: left;
          // position: absolute;
          // opacity: 0;
        }
        &[type='text'], &[type='tel'],&[type='number'], &[type='email'] {
          width: 100%;
          padding: 8px;
          font-size: 17px;
        }
      }
      select {
        width: 100%; 
        font-size: 17px;
        padding: 9px 10px;
        // -webkit-appearance: none;
        // -moz-appearance: none;
        // appearance: none;
        border: 1px solid #bcb8b8;
      }

      #hiddenLanded {
        font-size: 14px;
        color: black;
      }
    }
  }
  span {
    &.logo-home-advisor {
      img {
        max-width: 210px;
        margin: 0 0 10px 0;
      }
    }
  }
}

