.wrapper-home-carousel {
	max-width: 65%;
	margin: 20px auto;
	position: relative;
	.flickity-prev-next-button {
		position: absolute;
	    top: 50%;
	    width: 44px;
	    height: 44px;
	    border: none;
	    border-radius: 50%;
	    background: white;
	    background: hsla(0, 0%, 100%, 0.75);
	    cursor: pointer;
	    -webkit-transform: translateY(-50%);
	    transform: translateY(-50%);
	    &.previous {
	    	left: -450px;
		    display: none;
	    }
	    &.next {
	    	right: -150px;
	    }
	}
	.flickity-viewport {
	    overflow: initial;
	    max-height: 550px;
	}
	.flickity-slider {
	    position: absolute;
	    width: 100%;
	    height: 100%;
	}
	.carousel-cell {
	    position: relative;
	    display: inline-block;
	    background-color: #fff;
	    opacity: 0.3;
	    &.is-selected,
		&.is-selected + div{
		    opacity: 1;
		}
	    img {
	    	width: 100%;
		    height: auto;
		    max-height: 250px;
		    background-size: cover;
		    background-position: center;
		    background-repeat: no-repeat;
	    }
	    .pcell,
	    .view_more {
	    	box-shadow: 0 1px 37px 0 rgba(165,165,165,.29);
			margin: 18px;
			width: 300px;
			height: 250px;
			-webkit-transition: .2s;
			-o-transition: .2s;
			transition: .2s;
			display: table;
			flex-direction: row;
			position: relative;
	    }
	    .view_more {
			height: 515px;
			font-size: 14px;
			color: #fff;
			text-transform: uppercase;
			cursor: pointer;
			padding: 10px 0;
			text-align: center;
			line-height: 35;
			background-position: center;
			background-image: url('https://media.edgeprop.my/s3fs-public/2k18home/discover_more/more_news.jpg');
			background-repeat: no-repeat;
			&:hover:before {
				content: "";
				position: absolute;
				left: 0; right: 0;
				top: 0; bottom: 0;
				background: rgba(255,255,255,.2);
			}
		}
		&.more {
			a {
				text-decoration: none;
			}
		}
		.pcell {
			.news-content {
				width: 100%;
				padding: 1.071em;
				background-color: #f7f6f6;
				position: absolute;
				left: 0;
				bottom: 0;
			}
			.news-title {
				margin-bottom: 5px;
				font-size: 1.143em;
			}
			.news-date {
				font-size: .714em;
				line-height: 1.364em;
				text-transform: uppercase;
			}
			&.featured {
				.news-cover {
					&:before {
						content: 'Featured';
						display: block;
						padding: .5rem 1rem;
						background-color: #c94d51;
						color: #fff;
						font-size: 1.2rem;
						letter-spacing: .08rem;
						position: absolute;
						top: 1rem;
						left: -.1rem;
						z-index: 1;
					}
				}
			}
		}
	}
	.dfp_news_cell,
	.dfp_list_cell {
		position: absolute;
		top:48px;
	}
	.dfp_news_cell .pcell,
	.dfp_list_cell .pcell {
		box-shadow: 0 1px 37px 0 rgba(165,165,165,.29);
		margin: 18px;
		width: 300px;
		height: 250px;
		-webkit-transition: .2s;
		-o-transition: .2s;
		transition: .2s;
		display: table;
		flex-direction: row;
		position: relative;
	}
	.slider_tab {
		list-style: none;
		overflow: hidden;
		margin: 0;
		padding-left: 18px;
		.tabs {
			float: left;
			padding: 10px 0px;
			font-size: 1.8rem;
			font-weight: 700;
			cursor: pointer;
			color: #AAAAAA;
			margin-right: 45px;
			letter-spacing: 1px;
			&:hover {
				color: #7b7575;
			}
			&.active {
				color: #000;
			}
		}
	}
}


.property-main-container {
	.pcell {
		a {
			&.image {
				.indicator {
					padding: 1rem 1.5rem;
				    background-color: #3b839c;
				    color: #fff;
				}
			}
		}
		.i-card {
			.content {
				position: absolute;
				bottom: 0;
				left: 0;
				width:100%;
				background:#f7f6f6;
				.title {
				    margin-bottom: .7rem;
				    font-size: 1.4rem;
				    font-weight: 600;
				}
			}	
			&.flat {
				width: 300px;
				height: 250px;
				position: relative;
			}	
		}
	}
	.carousel-cell .view_more.prop {
	  background-image: url('https://media.edgeprop.my/s3fs-public/2k18home/discover_more/more_prop.jpg');
	}
	.dfp_list_cell .pcell.btnAds {
	  background: transparent;
	  box-shadow: none;
	}
}