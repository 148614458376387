.header-vpex {
    background: $vpex-color;
    .mega-dropdown:hover .nav-link-new {
        color: $vpex-color !important;
        background-color: #fff; 
    }
    .nav-link-new {
        color: #fff !important;
        &:hover {
            &:not(.active) {
                color: $vpex-color !important;
                background-color: #fff; 

            }
        }
        &.active { 
            color: $vpex-color !important;
            background-color: #fff; 
        }
    }
    i.fa.fa-search,
    I.fa.fa-times {
        color : #fff;
    }
    #sidebarCollapse-new {
        background: transparent;
    }
    .ham-btn {
        background-image: url(https://media.edgeprop.my/assets/icons/hamburger-btn-white.png);
    }

}