//main elements
@media (max-width: $break_m_xx) {
  body {
    &.page-content,
    &.node-type-tep-blogs {
      .wrap {
        padding: 0;
      }
      .news-details {
        padding: 0;
      }
      section {
        &.leaderboard.leaderboard-1 {
          z-index: 99;
          background: #fff;
        }
      }
      .entry-heading.cat-meta-line {
        // background: #fff;
        position: relative;
        border-top: 15px solid #fff;
        z-index: 100;
        background: #fff;
      }
      .main-content {
        z-index: 98;
        padding-top: 0;
        .news-article {
          // background: #fff;
          margin-bottom: -15px;
          .skin-ads-parallax-wrapper {
            display: none;
            position: relative;
            z-index: 76;
          }
          p, figure, .news-owl {
            z-index: 77;
            position: relative;
            background: #fff;
            &.card-address.address-clamp-short-list {
              position: inherit;
              padding: 0;
            }
          }
          ul, ol, h3 {
            background: #fff;
            margin-bottom: 0;
            border-bottom: 15px solid #fff;
            pointer-events: auto;
          }
          figure, .news-owl {
            margin-bottom: 0;
            border-bottom: 25px solid #fff;
          }
          h3 {
            margin-top: 0;
            padding: 5px 10px;
          }
        }
        .bottom-entry-post-share,
        .news-body.content,
        .news-tags, 
        .related-post,
        .sidebar-widget,
        .m-b-30 {
          margin-bottom: 0;
          padding-bottom: 0;
          position: relative;
          z-index: 99;
        }
        .bottom-entry-post-share, 
        .news-tags, 
        .related-post,
        .sidebar-widget,
        .m-b-30,
        .entry-heading.cat-meta-line {
          background: #fff;
        }
        .related-post,
        .news-tags, 
        .sidebar-widget,
        .m-b-30 {
          margin-bottom: 0; 
          padding: 5px 20px 15px;
        }
       
      }
      .sidebar {
        z-index: 99;
        position: relative;
        background: #fff;
      }
      .entry-heading.cat-meta-line {
        padding: 0 10px;
      }
      .main-content p,
      .main-content h2 {
        padding: 0 20px 28px;
      }
      .main-content h2 { 
        background: #fff;
        margin: 0;
        &.entry-title {
          padding-left: 0;
          padding-right: 0;
        }
      }
      .main-content {
        pointer-events: none;
        .billboard,
        .entry-heading,
        .entry-categories,
        .entry-meta,
        .entry-title,
        figure, 
        #block-block-5,
        .news-body.content p,
        .news-body.content a,
        #block-block-10,
        #block-block-11,
        .news-tags,  
        .related-post,
        .sidebar-widget,
        .m-b-30 ul.extra-links li.link,
        .m-b-30 .fb-comments,
        iframe {
          pointer-events: auto;
        }
      }
      figure {
        max-width: 100% !important;
        margin: 0 auto;
        text-align: center;
      }
    }
  }
}
.skin-ads-parallax-wrapper {
//   height: 620px;
  @media (min-width: $break_tablet) {
    display: none;
  }
  display: none;
// //   .skin-ads-parallax {
// //     // background-image: url($parallaxbg);
// //     // min-height: 1000px; 
// //     // background-attachment: fixed;
// //     // background-position: 0 138px;
// //     // background-repeat: no-repeat;
// //     // // background-size: cover;
// //     // background-size: 97%;
// //     //exact size
// //     // background-position: 21px 138px;
// //     // width: 384px;
// //     // height: 488px;
// //     div {
// //       position: fixed;
// //       top: 139px;
// //       left: 0;
// //     }
// //   }
.skin-ads-parallax {
  @media (min-width: 426px) {
    left: calc((100% - 425px) / 2) !important;
  }
}
}
// .prlx-label {
//   &:before {
//     content: 'Scroll to continue with content';
//     background: #000; 
//     text-transform: uppercase; 
//     font-size: 11px;
//   }
// }
.prlxlabel {
  background: #000; 
  text-transform: uppercase;
  font-size: 10px;
  color: #fff;
  font-weight: 200;
  letter-spacing: .5px;
  text-align: center;
  height: 20px;
  line-height: 21px;
  position: relative;
  top: 28px;
  margin-left: -10px;
  margin-right: -10px;
  &.end {
    top: 0;
    margin-bottom: 20px;
  }
}