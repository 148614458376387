@keyframes blinker {  
    50% { opacity: 0; }
}

@keyframes blinker2 {  
    30% { opacity: 0; }
}

@keyframes blinker3 {  
    70% { opacity: 0; }
}