//------------------------------
// Button
//------------------------------
 

.button {
  &.white {
    border-radius: 5px;
    line-height: 1.3em;
    text-align: center;
    text-decoration: none;
    background: transparent;
    background-clip: padding-box;
    cursor: pointer;
    transition: border-color,background,box-shadow,color,fill .1s ease;
    @include user-select;  
    font-weight: 400;
    font-style: normal;
    padding: 10px 20px;
    font-size: 1.3rem;
    border: 1px solid rgba(48,51,57,.15);
    background-color: #fff;
    color: $primary-color1;
    &:hover {
      border-color: rgba(48,51,57,.3);
      box-shadow: 0 1px 1px rgba(0,0,0,.1);
      background-color: linear-gradient(180deg,#fff,#f4f5f9);
      background-image: linear-gradient(180deg,#fff,#f4f5f9);
    }
  }
}