@charset "UTF-8";

@import url('https://fonts.googleapis.com/css?family=Noto+Serif:400,700');
// $award19-font: 'Noto Serif', serif;
$award19-font: 'poppins', serif;
$award19-color: #fdb913;
$award19-color2: #333;
$award19-shadow: 1px 7px 30px 2px rgb(188, 188, 188);
$award20-cover: 'https://media.edgeprop.my/page/bmpa/2020/hero-rev2.jpg';
$zindex-menubar: 998;
$border-color: #fff;
$section-bg: #000;
$section-alt-bg: #03001c;
$award-active: #ffe4a0;


#bma-page {
  font-family: $award19-font;
  color: #fff;
  .bma-sub-h4 {
    font-size: 2rem;
    text-transform: unset;
    margin-bottom: 20px;
  }
  .col-md-4 {
    text-align: center;
  }
  .col-md-4, 
  .col-md-6.text-center, 
  .col-md-12.text-center {
    .badge-more {
      border-radius: 50%;
      background: #f92626;
      width: 45px;
      height: 45px;
      padding: 11px 0 0;
      position: absolute;
      bottom: 197px;
      right: 57px;
      font-size: 10px;
      line-height: 1.2;
      cursor: pointer;
      pointer-events:none;
    }
  }
  .col-md-4 {
    .badge-more {
      @media (max-width: 2000px) {
        bottom: 202px;
        right: 113px;
      }
      @media (max-width: 1680px) { 
        right: 64px;
      }
      @media (max-width: 1440px) { 
        right: 49px;
      }
      @media (max-width: 1366px) { 
        right: 36px;
      } 
      @media (max-width: 1280px) { 
        right: 25px;
      } 
      @media (min-width: 1024px) and (max-width: 1024px) {
        bottom: 167px;
        right: 32px;
      }
      @media (min-width: $break_tablet) and (max-width: $break_tablet) {
        bottom: 137px;
        right: 21px;
      }
      @media (max-width: 490px) {
        bottom: 201px;
        right: 35px;
      } 
      @media (max-width: 375px) {
        right: 15px;
      } 
      @media (max-width: 320px) {
        bottom: 180px;
        right: 8px;
      }
    }
  }
  .col-md-6.text-center {
    .badge-more {
      @media (max-width: 2000px) {
        bottom: 202px;
        right: 142px;
      }
      @media (max-width: 1680px) { 
        right: 88px;
      }
      @media (max-width: 1440px) { 
        right: 69px;
      }
      @media (max-width: 1366px) { 
        right: 57px;
      } 
      @media (max-width: 1280px) { 
        right: 42px;
      } 
      @media (min-width: 1024px) and (max-width: 1024px) {
        bottom: 167px;
        right: 51px;
      }
      @media (min-width: $break_tablet) and (max-width: $break_tablet) {
        bottom: 137px;
        right: 72px;
      }
      @media (max-width: 490px) {
        right: 35px;
      } 
      @media (max-width: 375px) {
        right: 15px;
      } 
      @media (max-width: 320px) {
        bottom: 180px;
        right: 8px;
      }
    }
  }
  .col-md-12.text-center {
    .badge-more {
      @media (max-width: 2000px) {
        bottom: 202px;
        right: 425px;
      }
      @media (max-width: 1680px) { 
        right: 319px;
      }
      @media (max-width: 1440px) { 
        right: 282px;
      }
      @media (max-width: 1366px) { 
        right: 257px;
      } 
      @media (max-width: 1280px) { 
        right: 234px;
      } 
      @media (min-width: 1024px) and (max-width: 1024px) {
        bottom: 167px;
        right: 214px;
      }
      @media (min-width: $break_tablet) and (max-width: $break_tablet) {
        bottom: 137px;
        right: 235px;
      }
      @media (max-width: 490px) {
        bottom: 201px;
        right: 35px;
      } 
      @media (max-width: 375px) {
        right: 15px;
      } 
      @media (max-width: 320px) {
        bottom: 180px;
        right: 8px;
      }
    }
  }
 
  //footer
  footer {
    text-align: center;
    margin-bottom: 30px;
    .bma-footer {
      &.desktop {
        display: none;
        max-width: 1000px;
        margin: 0 auto;
        @media (min-width: $break_tablet) {
          display: block;
        }
      }
      &.mobile {
        max-width: 320px;
        display: none;
        margin: 0 auto;
        @media (max-width: $break_pre_tablet) {
          display: block;
        }
      }
    }

  }
  
  // .award-top {
  //   text-align: center;
  //   height: 48px;
  //   padding: 7px 0;
  //   display: block;
  //   overflow: hidden;
  //   background: #fff;
  // }
  select#select-years {
    background: transparent;
    border-radius: 0;
    color: #fff;
    font-size: 1.2rem;
    height: 29px;
    position: relative;
    top: 10px;
    @media (max-width: $break_pre_tablet) {
      top: 0;
    }
    option {
      background: transparent;
      color: #333;
    }
    @media (min-width: $break_tablet) and (max-width: $break_d_sm) {
      top: 6px;
    }
  }
  img {
    width: 100%;
  }
  a {
    -webkit-transition: all .3s;
    -moz-transition: all .3s;
    transition: all 0.3s;
    &:hover {
      color: lighten( $award19-color, 7%);
      text-decoration: none;
    }
  }
  hr {
    max-width: 100px;
    margin: 25px auto 0;
    border-width: 1px;
    border-color: rgba(34, 34, 34, 0.1);
    &.light {
      border-color: $border-color;
    }
  }
  h1, h2, h3, h4, h5, h6 {
    font-family: $award19-font;
    text-transform: uppercase;
    font-weight: 700;
  }
  h1, h3 {
    // border-bottom: 1px solid $border-color;
    text-align: center;
    padding: 0 0 20px 0;
    margin: 0 0 20px 0;
  }
  h3 {
    font-weight: 200;
  }
  .sub-header {
    // font-family: $award19-font;
    text-align: center;
    font-size: 2rem;
    padding: 20px 0 30px;
    width: 60%;
    margin: 0 auto;
  }
  p {
    font-size: 1.6rem;
    line-height: 1.5;
    margin-bottom: 20px;
    @media (max-width: $break_tablet) {
      font-size: 1.3rem;
      line-height: 1.4;
    }
  }
  nav {
    &#winner-tab {
      // max-width: 985px;
      // width: 90%;
      margin: 0 auto 20px;
      font-size: 1.5rem;
      border-bottom: 1px solid #000;
      .nav-tabs {
        // width: 100px;
        border-bottom: 0;
      }
      .nav-tabs, .nav-tabs:hover {
        // border-color: #000;
      }
      .nav-fill {
        a {
          &.nav-link {
            line-height: 1.3;
            background: transparent;
            border-color: transparent;
            color: #fff;
            border-bottom: 1px solid $section-alt-bg;
            &.active, &:hover {
              border-bottom: 3px solid $award-active;
              color: $award-active;
            }
            @media (min-width: $break_tablet) {
              width: 20%;
            }
            @media (max-width: $break_m_xx) {
              font-size: 1.2rem;
            }
            @media (max-width: $break_m_x) {
              font-size: 1.2rem;
              padding: .5rem .1rem;
            }
          }
        }
      }
      @media (min-width: $break_d_sm) {
        width: 80%;
        margin-bottom: 40px;
      }
    }
  }
  section {
    background: $section-bg;
    padding: 50px 0;
    img {
      // margin-bottom: 20px;
    }
    li {
      font-size: 1.6rem;
      margin-bottom: 9px;
      @media (max-width: $break_tablet) {
        font-size: 1.3rem; 
        margin-bottom: 7px;
      }
    }
    &.general {
      border-top: 10px solid $award19-color;
      position: relative;
      padding: 50px 0 50px;
      overflow: hidden;
      header {
        height: 244px;
        margin-bottom: 30px;
        background-position: center;
      }
      &.no-border {
        border-top: 0;
      }
      &.p-t-25 {
        padding-top: 25px;
      }
    }
    &.home {
      // position: relative;
      padding: 0;
      height: 428px;
      background: url($award20-cover) no-repeat;
      -webkit-background-size: cover;
      -moz-background-size: cover;
      -o-background-size: cover;
      background-size: cover; 
      background-position: center -89px;
      display: block;
      overflow: hidden;  
      @media (min-width: 1280px) {
        height: 445px;
      }
      @media (min-width: 1440px) {
        height: 485px;
      }
      @media (min-width: $break_d_lg1) and (max-width: $break_d_lg1) {
        height: 540px;
      }
      @media (min-width: $break_d_x) {
        height: 610px;
        background-position: center -57px;
      }
      @media (min-width: 2000px) {
        height: 626px;
        background-position: center -139px;
      }
      
      @media (max-width: $break_d_md) {
        height: 357px;
        background-position: center -57px;
      }
      @media (max-width: $break_tablet) {
        height: 387px;
        background-position: center 0;
      }
      @media (max-width: $break_m_xx) {
        height: 171px;
        background-position: center -44px;
      }
      @media (max-width: $break_m_x) {
        height: 164px;
        background-position: center -30px;
      }
      @media (max-width: $break_m_lg) {
        height: 151px; 
        background-position: center -24px;
      }
      @media (max-width: $break_m_i7) {
        height: 159px;
        background-position: center -20px;
      }
      @media (max-width: $break_m_md) {
        height: 148px;
        background-position: center -16px;
      }
      @media (max-width: $break_m_sm) {
        height: 121px;
        background-position: center -19px;
      }
      .kpkt-endorsement {
        width: 142px;
        position: relative;
        right: 11px;
        float: right;
        top: 34px;
        @media (max-width: $break_pre_tablet) {
          width: 64px;
          top: 0;
          right: 32px;
        }
        @media (max-width: $break_m_sm) {
          width: 49px;
          right: 21px;
        }
        img {

        }
      }
    }
    &.featured {
      .featured-icon {
        margin-bottom: 25px;
        cursor: pointer;
        @media (max-width: $break_m_x) {
          // margin-bottom: 45px;
        }
        &:hover {
          img {
            // box-shadow: $award19-shadow;
            opacity: 0.5;
          }
        }
        img {
          margin-bottom: 10px;
          max-width: 170px;
        }
      }
      .featured-title {
        font-size: 1.5rem;
        font-weight: 500;
        line-height: 1.2;
        @media (max-width: $break_m_x) {
          font-size: 1rem;
          line-height: 1.1;
        }
      }
      .col-6 {
        @media (min-width: $break_m_md) and (max-width: $break_m_md) {
          flex: none;
          max-width: 49%;
          float: none;
        }
        @media (max-width: $break_m_sm) {
          flex: none;
          max-width: 100%;
        }
      }
    }
    &.winners {
      // background: $award19-color;
      ul {
        padding: 0 0 0 16px;
      }
      h4 {
        margin: 20px 0;
        padding-bottom: 15px;
        font-family: poppins;
        font-weight: 500;
        font-size: 1.5rem;
        letter-spacing: 1px;
        color: #fff;
        @media (min-width: 1280px) {
          font-size: 2rem;
        }
      }
      h3 {
        letter-spacing: .8px;
        font-size: 1.6rem;
        font-family: 'poppins';
        font-weight: 600;
        border-top: 1px dotted #999;
        // border-bottom: 1px dotted #999;
        padding: 20px;
        color: $award19-color;
        margin-top: 70px;
        @media (min-width: 1280px) {
          letter-spacing: 1px;
          font-size: 2rem;
        }
      }
      
    }
    &.pictures {
    }
    &.videos {
    }
    &.judges {
      .judge-text {
        display: block;
        padding: 10px 15px;
        text-align: center;
        margin-bottom: 30px;
        .judge-title {
          color: $award-active;
          font-size: 1.6rem;
          @media (max-width: $break_m_x) { 
            font-weight: 500;
            font-size: 1.3rem;
            line-height: 1.2; 
          } 
        }
        .judge-role {
          font-size: 1.2rem;
          line-height: 1.4; 
          @media (max-width: $break_m_x) {
            font-size: 1.1rem;
            line-height: 1.3;
          }
        }
        // @media (max-width: $break_m_lg) {
        //   min-height: 115px;
        // }
        // @media (max-width: $break_m_sm) {
        //   min-height: 173px;
        // }
      }
      img {
        width: 100%;
        max-width: 300px;
        height: auto;
      }
      .judge-footnote {
        text-align: center;
        font-size: 1.4rem;
        margin: 20px auto;
        width: 95%;
        @media (min-width: $break_tablet) {
          width: 70%;
        }
      }
    }
    &.news {
      .news-card {
        .single-card {
          // border: 1px solid #ddd;
          background: #fff;
          margin: 10px;
          padding: 0px;
          img {
            width: 100%;
            height: 151px;
            object-fit: cover;
            margin-bottom: 0;
            @media (max-width: $break_tablet) {
              height: 100px;
            }

          }
          .news-text {
            padding: 10px 10px 1px;
            min-height: 104px;
            .news-title {
              font-size: 1.3rem;
              line-height: 1.3;
              color: #414141;
              font-weight: 500;
              margin-bottom: 12px;
              overflow: hidden;
              position: relative;
              max-height: 52px;
              text-overflow: ellipsis;
              display: -webkit-box;
              -webkit-line-clamp: 3;
              -webkit-box-orient: vertical;
              a {
                color: inherit; 
              }
              @media (max-width: $break_m_xx) {
                font-size: 1.1rem;
              }
            }
            p {
              &.news-label {
                font-size: 10px;
                text-transform: uppercase;
                line-height: 16px;
                color: $link-color-19;
                font-weight: bold;
                margin-bottom: 5px;
              }
              &.news-date {
                font-size: 10px;
                line-height: 12px;
                color: #393939;
              }
            }
          }
        }
        
        h1 {
          padding: 9px 0;
          font-size: 0.9rem !important;
        }
        .more-property-news {
          font-size: 1.5rem;
          margin-top: 10px;
          color: #fff; 
          text-align: center;
          /*padding-bottom: 10px;*/
        }
      }
    }
    &.about {
      h2 {
        font-weight: 500;
        border: 1px solid $award19-color;
        min-height: 146px;
        text-align: center;
        padding: 15px;
        font-size: 1.4rem;
      }
      .article {
        span {
          &.more-detail {
            font-family: 'Poppins';
            font-size: 1.2rem;
            font-weight: 400;
            text-transform: none;
            display: block;
            margin-top: 15px;
          }
        }
      }
    }
    //Badge img
    &.winners, 
    &.featured {
      h1 {
        color: $award19-color;
        @media (min-width: 1280px) {
          font-size: 4rem;
          letter-spacing: 2px;
        }
      }
      img {
        max-width: 300px;
        margin-bottom: 30px;
        &.stpd {
          max-width: 301px;
        }
      }
    }
    //section bg
    &.winners, &.news, &.videos, &.judges {
      background: $section-alt-bg;
    }
    &.winners {
      img {
        // cursor: pointer;
        &.cursor-pointer {
          cursor: pointer;
        }
      }
    }
    //ios breakpoint fix
    &.news, &.videos, &.judges {
      .col-6 {
        @media (max-width: $break_m_xx) {
          -webkit-box-flex: unset;
          flex: none;
          float: left;
          width: 48%;
          margin: 0 auto;
          max-width: 200px;
          position: unset;
          // padding: 0;
        }
      }
    }

    //lightgallery
    img.g-img {
      // max-width: 90%;
      cursor: pointer;
      margin-bottom: 30px; 
      width: 100%;
      max-width: 280px;
      height: 170px; 
      object-fit: cover;
      @media (max-width: $break_d_common_mdx) {
        height: 160px;
      }
      @media (max-width: $break_d_common_md) {
        height: 150px;
      }
      @media (max-width: $break_d_common_sm) {
        height: 140px;
      }
      @media (max-width: $break_d_md) {
        height: 120px;
      }
      @media (max-width: $break_tablet) {
        height: 86px;
      }
      @media (max-width: $break_m_xx) {
        height: 100px;
        width: 100%;
      }
      @media (max-width: $break_m_md) {
        height: 86px; 
      }
      @media (max-width: $break_m_sm) {
        height: 72px; 
      }
    }

    //general elements
    .badge-tablet {
      img {
        @media (min-width: $break_d_sm) and (max-width: $break_d_md) {
          max-width: 242px;
          &.stpd {
            max-width: 242px;
          }
        }
        @media (min-width: $break_tablet) and (max-width: $break_tablet) {
          max-width: 188px;
          &.stpd {
            max-width: 188px;
          }
        }
      }
    }
  }
  .m-b-20 {
    margin-bottom: 20px;
  }
  .no-float {
    float: none;
  }
  footer {
    a {
      &:hover {
        text-decoration: underline;
        color: inherit;
      }
    }
    .footer-logo {
      padding: 0 0 20px;
      .logo-col-sm {
        @media (min-width: $break_tablet ) {
          float: left;
          width: 14.2%;
        }
        padding: 10px 10px 10px 0;
        @media (max-width: $break_pre_tablet ) {
          float: left;
          width: 25%;
        }
      }
      img {
        width: 100%;
        position: relative;
        &.present {
          max-width: 250px;
          @media (min-width: $break_tablet) {
            top: -8px;
          }          
        }
        &.partner {
          max-width: 130px;
          &-2 {
            top: 12px; 
            // @media (max-width: 1440px) {
            //   top: -4px;
            // }
            @media (min-width: $break_tablet) and (max-width: $break_tablet) {
              top: 12px;
            }
          }
        }
        &.support {
          max-width: 60px;
          &-5 {
            top: -17px;
          }
          &-6 {
            top: 7px;
          }
          &-7 {
            top: -11px;
          }

        }
        @media (max-width: $break_pre_tablet) {
          text-align: center;
          margin-bottom: 30px;
        }
      }
      .footer-label {
        display: block;
        text-transform: uppercase;
        font-size: 1.1rem;
        font-weight: 500;
        letter-spacing: .8px;
        text-align: left;
        color: #111;
        padding: 15px 10px 0 0;
        margin-bottom: 36px;        
        @media (min-width: $break_tablet) {
          border-right: 2px solid #111;
          &.lb-present {
            width: 110px;
          }
          &.lb-partner {
            width: 82px;
          }
          &.lb-sp-sponsor {
            width: 167px;
          }
          &.lb-support {
            width: 111px;
          }
        }
        @media (max-width: $break_pre_tablet) {
          font-weight: 600;
          font-size: 1.2rem;
          margin-bottom: 0;
          // border-bottom: 1px dotted #666;
          text-align: center;
        }
        &.sm-margin-top {
          @media (max-width: 990px) {
            margin-top: 30px;
          }
        }
      }

    }
  }

  #stickyNav {
    background-color: #333;
    border-color: rgba(34, 34, 34, 0.05);
    background-color: #000;
    -webkit-transition: all .3s;
    -moz-transition: all .3s;
    transition: all .3s;
    font-weight: 200;
    letter-spacing: 1px;
    border-radius: 0;
    margin-bottom: 0;
    &.stick {
      margin-top: 0 !important;
      width: 100%;
      position: fixed;
      top: 0;
      z-index: $zindex-menubar;
    }
    .navbar {
      border-radius: 0 !important; 
    }
    .navbar-brand {
      color: $award19-color;
      // font-family: Montserrat, sans-serif;
      font-weight: 200;
      letter-spacing: 1px;
      &:focus, &:hover {
        color: #b96700;
      }
    }
    .navbar-toggler {
      font-size: 12px;
      padding: 8px 10px;
      color: #fff;
      position: absolute;
      right: 10px;
      top: 10px;
    }
    .navbar-nav > li > a {
      font-weight: 500;
      text-transform: uppercase; 
      margin-right: 20px;
      @media (min-width: $break_d_md) {
        font-size: 1.4rem; 
        margin-right: 5px;
      }
      @media (max-width: $break_pre_tablet) {
        padding: 6px 0;
      }
      @media (min-width: $break_tablet) and (max-width: $break_d_sm) {
        font-size: 1.1rem;
        line-height: 1;
        margin-right: 0;
      }
      &.active {
        color: $award19-color !important;
        background-color: transparent;
        &:hover {
          background-color: transparent;
        }
      }
      color: #fff;
      &:focus {
        color: #fff;
        &:hover {
          color: $award19-color;
        }
      }
      &:hover {
        color: $award19-color;
      }
    }
    @media (min-width: $break_tablet) and (max-width: $break_tablet) {
      .navbar-nav {
        li {
          &:nth-child(4) {
            width: 70px;
          }
        }
      }
    }
  }
  .nav-pills .nav-link.active {
    border: 1px solid $award19-color;
    color: $award19-color;
    background-color: transparent;
  }
 
  @media (min-width: 992px) {
    .navbar-expand-lg .navbar-nav .nav-link {
      padding-right: 2rem;
    }
  }
  ul.list-unstyled li {
    margin-bottom: 20px;
    font-size: 13px;
    color: #666;
  }
  .ml-auto, .mx-auto {
    margin-left: auto !important;
  }
  .mr-auto, .mx-auto {
    margin-right: auto !important;
  }
  .fotorama {
    &__wrap {
      margin: 0 auto;
    }
    &__caption { 
      font-size: 2rem;
      // padding: 30px;
      width: 100%;
      margin: 0 auto;
      text-align: center;
      @media (min-width: $break_tablet) {
        bottom: 0;
      }
      @media (max-width: $break_pre_tablet) {
        font-size: 2rem;
        bottom: 0px;
        width: 100%;
      }
      
      a {
        text-decoration: none;
        border-bottom: 0;
      }
      span {
        display: block;
        &.slider-title {
          font-weight: bold;
          line-height: 1;
          margin-bottom: 6px;
          @media (max-width: $break_pre_tablet) {
            font-size: 1.7rem;
          }
        }
        &.slider-subtitle {
          font-size: 1.6rem;
          line-height: 1.5;
          @media (max-width: $break_pre_tablet) {
            font-size: 1.2rem; 
          }
          span.glyphicon {
            display: inline;
            font-size: 1.1rem;
          }
        }
      }
        .fotorama__caption__wrap {
          @media (min-width: $break_post_tablet) {
            padding: 5px !important;
            width: 100%;
          } 
        }
    }
    &__arr {
        background: 0 0 !important;
        &:before {
          font-family: Ionicons;
          content: "";
          margin-right: 6px;
          font-size: xx-large; 
          font-size: 58px;
          content: "";
          margin-right: 0;
          color: lighten( $award19-color, 7%);
          position: relative;
          top: -30px;
          &:hover {
            color: $award19-color;
          }
        }
        &.fotorama__arr--next:before {
          content: "";
        }
        &.fotorama__arr--prev:before {
          left: 8px;
          content: "";
        }
    }
    .fotorama__html {
      background-color: rgba #000, 0.6;
      span {
          display: block;
      }
      // a, div {
      //   display: block;
      //   height: 100%;
      //   // Transparent links are not clickable in IE,
      //   //    but non-existent background fixes this.
      //   //   (Put an empty 1×1 image here to avoid
      //   //    errors in console.)
      //   // background: url(https://media.edgeprop.my/ui/1px.png);
      //   background: url(_.gif);
      // }
    }
    .caption-element {
      top: 0;
      position: absolute;
      z-index: 999;
      right: 0;
      background: rgba(255, 255, 255, 0.9);
      width: 50%;
      height: 100vh;
      span {
        display: block;
        &.slider-title {
          font-weight: bold;
          line-height: 1;
          margin-bottom: 6px;
        }
        &.slider-subtitle {
          font-size: 2rem;
          line-height: 1.5;
          @media (max-width: $break_pre_tablet) {
            font-size: 1.2rem; 
          }
        }
      }
    }
  }
  span {
    display: block;
    &.caption-title {
      font-size: 1.2rem;
      font-weight: 100;
    }
  }
  .logotype {
    @media (max-width: $break_pre_tablet) {
      margin: 0 auto;
    } 
    img {
      @media (max-width: $break_tablet) and (min-width: $break_tablet) {
        width: 132px; 
      } 
      @media (max-width: $break_m_xx) {
        width: 195px; 
      } 
      @media (max-width: $break_m_lg) {
        width: 130px; 
      }
    }
  }
  @media (max-width: $break_pre_tablet) {
    .border-side {
      border-right: 0;
      border-left: 0; 
    }
  }
  @media (min-width: $break_post_d_sm) {
    #stickyNav {
      .navbar-brand {
        color: fade(white, 70%);
        &:focus, &:hover {
          color: #fff;
        }
      }
      .navbar-nav > li > a {
        color: rgba(255, 255, 255, 0.7);
        &:focus {
          color: rgba(255, 255, 255, 0.7);
          &:hover {
            color: #fff;
          }
        }
        &:hover {
          color: #fff;
        }
      }
      &.navbar-shrink {
        border-color: rgba(34, 34, 34, 0.1);
        z-index: 1000;
        background-color: #000;
        border-radius: 0;
        .navbar-brand {
          color: #f9f9f9;
          &:focus, &:hover {
            color: $award19-color;
          }
        }
        .navbar-nav > li > a {
          color: #f9f9f9;
          &:focus {
            color: #f9f9f9;
            &:hover {
              color: $award19-color;
            }
          }
          &:hover {
            color: $award19-color;
          }
        }
      }
    }
  }
  @media (max-width: $break_m_lg) {
    section {
      &.general {
        padding: 10px 0 50px;
        h2 {
          font-size: 28px;
        } 
        .overlay-header {
          font-size: 33px;
          padding: 6px;
        }
        header {
          height: 61px;
          &.features-header {
             height: 60px; 
          }
        }
      }
      &.issue {
        padding: 0;
      }
    }
    .visible-xs {
      display: block;
    }
  }
  @media (min-width: 426px) {
    .visible-xs {
      display: none !important;
    }
  }
  // @media (min-width: 768px) and (max-width: 991px) {
  //   .navbar-collapse.collapse {
  //     display: none !important;
  //     &.in {
  //       display: block !important;
  //     }
  //   }
  //   .navbar-header .collapse, .navbar-toggle {
  //     display: block !important;
  //   }
  //   .navbar-header {
  //     float: none;
  //   }
  // }

  .navbar-collapse {
    @media (max-width: $break_m_x) {
      background: #000;
      z-index: 999999;
    }
  }

  //bootstrap
  .navbar-nav {
    @media (max-width: $break_d_sm) {
      padding-left: 17px;
    }
    @media (min-width: $break_tablet) and (max-width: $break_tablet) {
      padding-left: 0px;
    }
  }
  .col-6  {
    float: left;
  }

  .floating-bar {
    position: fixed;
    top: -200%;
    left: 1%;
    color: #000;
    width: 100px;
    background: #fff;
    transition: all .5s .2s ease-in-out;
    font-weight: 600;
    z-index: 999;
    @media (min-width: $break_post_tablet ) {
      border-right: 1px solid #000;
      border-left: 1px solid #000;
    }
    @media (max-width: $break_tablet) {
      width: 100%;
      left: 0;
      border-bottom: 1px solid #000;
    }
    .floating-menu {
      line-height: 1.4;
      text-align: center;
      padding: 8px 12px;
      border-bottom: 1px dotted #000;
      font-size: 1.2rem;
      &:hover, &.active {
        background: $award19-color;
        color: #fff;
      }
      @media (max-width: $break_tablet) {
        padding: 5px;
        font-size: 9px;
        width: 16.6%;
        float: left;
        border-right: 1px dotted #000;
        border-bottom: none;
      }
      @media (max-width: $break_m_sm) {
        font-size: 7px;
      }
    }
    &.in {
      transition: all .5s .2s ease-in-out;
      @media (min-width: $break_d_sm ) {
        top: 72px;
      }
      @media (max-width: $break_tablet) {
        top: 51px;
      }
      @media (max-width: $break_m_xx) {
        top: 64px;
      }
      @media (max-width: $break_m_lg) {
        top: 50px;
      }
    }
    a {
      color: #333;
      text-decoration: none !important;
    }
  }
  //popup elements
  .badge-popup {
    cursor: pointer;
    position: fixed;
    z-index: 999;
    color: #333;
    border: 1px solid #000;
    box-shadow: 1px 7px 30px 2px #6f6d6d; 
    display: block;
    background: rgba(255, 255, 255, 0.98);
    text-align: center;
    // overflow: hidden;
    overflow-x: hidden;
    overflow-y: auto;
    top: 28%; 
    height: 300px;
    padding: 25px; 
    // width: 92%;
    right: calc((100% - 700px) / 2);
    width: 700px;
    // @media (min-width: $break_tablet ) {
    // }
    @media (max-width: $break_pre_tablet) {
      right: calc((100% - 320px) / 2);
      width: 320px;
    }
    p {
      font-size: 13px;
    }
    &.active {
      -webkit-overflow-scrolling: touch; 
      opacity: 1;
      -webkit-transition: opacity 0.15s linear, z-index 0.15;
      -o-transition: opacity 0.15s linear, z-index 0.15;
      transition: opacity 0.15s linear, z-index 0.15;
      // z-index: -1;
    }
    .btn-close {
      cursor: pointer;
      position: fixed;
      color: #fff;
      background: #ff4b22;
      border-radius: 50%;
      width: 35px;
      height: 35px;
      z-index: 999999999;
      margin-top: -20px;
      right: calc((100% - 700px) / 2);
      top: 24%;
      @media (max-width: $break_pre_tablet) {
        right: calc((100% - 320px) / 2); 
      }
      opacity: 1;
      text-shadow: none;
      font-size: 4rem;
      font-weight: 100;
      line-height: .9;
      &:focus {
        box-shadow: none !important;
      }
      &:not(:disabled):not(.disabled):active {
        background: none; 
        border: 0; 
      
      }
    } 
  }
  // .modal-backdrop {
  //   opacity: 1;
  //   background-color: rgba(0,0,0,.6);
  //   backdrop-filter: blur(20px);
  //   position: fixed;
  //   top: 0;
  //   left: 0;
  //   z-index: 1040;
  //   width: 100vw;
  //   height: 100vh;
  // }
  // .caption-content-wrapper {
  //   min-height: calc(100% - 3.5rem); 
  //   max-width: 500px;
  //   margin: 1.75rem auto;
  //   position: relative;
  //   width: auto;
  //   pointer-events: none;
  // }
  // .caption-content-inner {
  //   position: relative;
  //   display: flex;
  //   flex-direction: column;
  //   width: 100%;
  //   pointer-events: auto;
  //   background-color: #fff;
  //   background-clip: padding-box;
  //   border: 1px solid rgba(0,0,0,.2);
  //   border-radius: .3rem;
  //   outline: 0;
  // }
}

//Dependencies
.vjs-big-play-button {
  display: none!important;
}
.lg-sub-html {
  font-size: 1.5rem !important;
  @media (max-width: $break_tablet) {
    font-size: 1rem !important;
  }
  @media (max-width: $break_m_xx) {
    // display: none;
    padding: 5px 50px 5px 5px !important;
    text-align: left !important;
    font-size: .9rem !important;
  }
}
.lg-outer .lg-toogle-thumb {
  @media (max-width: $break_m_xx) {
    right: 5px !important;
  }

}