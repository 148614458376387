.guides-wrap {
    width: 100%;
    max-width: 1020px;
    margin: 0 auto;
    position: relative;
    top: 75px;
    margin-bottom: 75px;
    .guides-main {
        &-header-wrap {
            margin: 50px 0;
            text-align: center;
            h1 {
                color: #3c475b;
                font-size: 30px;
                line-height: 40px;
                font-weight: 600;
            }
            p {
                color: #3c475b;
                font-family: Georgia,serif;
                font-size: 18px;
                line-height: 26px;
                font-style: italic;
            }
        }
        &-card-wrap {
            display: flex;
            flex-wrap: wrap;
            -webkit-box-pack: center;
            justify-content: center;
            margin: -10px 0;
            .card {
                position: relative;
                overflow: hidden;
                width: 100%;
                margin: 10px;
                @media (min-width: 624px) {
                    width: calc(1/2 * 100% - 20px);
                }
                @media (min-width: 1021px) {
                    width: calc(1/3 * 100% - 20px);
                }
                img {
                    display: block;
                    width: 100%;
                    height: auto;
                    transition: filter .25s ease-in-out,-webkit-filter .25s ease-in-out;
                }
                a {
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 100%;
                    height: 100%;
                    background: rgba(30,41,61,.5);
                    text-align: center;
                    text-decoration: none;
                }
                .cat-text-wrap {
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%,-50%);
                    width: 100%;
                    overflow: hidden;
                    transition: margin-top .25s ease-in-out;
                    .cat-text {
                        font-size: 20px;
                        line-height: 24px;
                        display: inline-block;
                        border-bottom: 2px solid #fff;
                        margin-bottom: 0;
                        padding: 0 20px 10px;
                        color: #fff;
                        font-weight: 500 !important;
                        text-transform: uppercase;
                        transition: margin-bottom .25s ease-in-out;
                    }
                }
                &:hover {
                    img { 
                        webkit-filter: blur(4px); /* Chrome, Safari, Opera */
                        filter: blur(4px); 
                    }
                }
    
            }
        }
    }
    .row:before, .row:after {display: none !important;} /* safari fix */
}