.epiq-container {
    color: #777;
    h2 {
      font-weight: 100;
      margin-bottom: 30px;
      max-width: 700px;
      line-height: initial;
      margin: 0 auto 30px;
    }
    h3 {
      max-width:590px;
      width: 100%;
      text-align: center;
      margin: 0 auto;
      line-height: 4.6rem;
      font-size: 3.1rem;
      @media screen and (max-width: $break_m_lg ) {
        line-height: 3rem;
        font-size: 2rem;
      }
    }
    h4 {
      font-size: 2.2rem;
      font-weight: 600;
      margin-bottom: 5px;
    }
    p {
      font-size: 1.7rem;
      line-height: 1.8;
    }
    ul {
      margin: 0;
      padding: 0 0 10px 20px;
    }
    li {
      list-style-type: none;
      padding: 7px 0;
      &:before {
        color: #bcc161;
        content: "\f00c";
        font-family: FontAwesome;
        display: inline-block;
        margin-left: -1.3em;
        width: 1.3em;
      }
    }
    h1, h3 {
      color:#2e3a52;
    }
    h2, h4 {
      color:$theme-color;
    }
    .jumbotron {
      color: #fff;
      padding:40px 25px 70px;
      background: url(https://files.theedgeproperty.com.my/public/images/epiq/bg-epiq.png) no-repeat fixed;
      -webkit-background-size: cover;
      -moz-background-size: cover;
      -o-background-size: cover;
      background-size: cover;
      border-bottom: 1px solid #cce2e7;
    }
}
#epiq-page {
  h1.epiq-tq {
      font-weight: 900;
      font-size: 6rem;
  }

   
  span.h1-epic-span {
     display: block;
     font-size: 2.3rem;
    padding: 10px 0 20px;
  }

  .img-responsive {
    max-width: 310px;
    width: 100%;
  }

   
  .container-fluid {
      padding: 40px 50px;
  }

  /*.item h4 {
      font-size: 19px;
      line-height: 1.375em;
      font-weight: 400;
      font-style: italic;
      margin: 70px 0;
  }
  .item span {
      font-style: normal;
  }
  */
  .slideanim { visibility: hidden;
  }
  .slide {
      animation-name: slide;
      -webkit-animation-name: slide;
      animation-duration: 1s;
      -webkit-animation-duration: 1s;
      visibility: visible;
  }

  @keyframes slide {
    0% {
      opacity: 0;
      transform: translateY(70%);
    } 
    100% {
      opacity: 1;
      transform: translateY(0%);
    }
  }
  @-webkit-keyframes slide {
    0% {
      opacity: 0;
      -webkit-transform: translateY(70%);
    } 
    100% {
      opacity: 1;
      -webkit-transform: translateY(0%);
    }
  }
  @media screen and (max-width: 768px) {
    .col-sm-4 {
      text-align: center;
      margin: 25px 0;
    }

  }

   

  /*utilities*/
  .m-t-20 {
    margin-top: 20px;
  }
  .m-t-30 {
    margin-top: 30px;
  }

  .m-b-20 {
    margin-bottom: 20px;
  }
   
  .epiq-bg {
    &-0 {
      background: #feffe6;
    }
    &-1 {
      background: #f7f7f7;
    }
    &-2 {
      background: #e8f1f9;
    }
    &-3 {
      background: #ffea35;
      padding: 7px 15px;
    }
    &-tq {
      background: url(../images/bg-epiq-tq.png) no-repeat;
      -webkit-background-size: cover;
      -moz-background-size: cover;
      -o-background-size: cover;
      background-size: cover;
    }
  }

  .epic-colr-1 {
    color: #2e3a52;
  }
   
  .bg-text {
    padding: 10px;
    background: rgba(46, 58, 82, 0.55);
    display: initial;
    color: #fff!important;
  }

  .sub-style {
    &-1 {
      display: block;
      font-weight: 700;
      color: #E91E63;
      font-size: larger;
    }
    &-2 {
      font-weight: 700;
      color: #333;
    }
    &-3 {
      background: #2e3a52; 
      padding:12px 0; 
      color: #fff;
    }
  }

  h3 {
    &.t-a-left {
      text-align: left;
    }
  }
}