$md-breakpoint: 1199px;
$sm-breakpoint: 820px;
$xs-breakpoint: 767px;
$xxs-breakpoint: 375px;
$md-zoom: 0.7;
$sm-zoom: 0.6;
$xs-zoom: 0.4;
$xxs-zoom: 0.3;

#contest-page {
	&.strata-living-survey {
		@media (max-width: 1023px) {
			// .collapse.navbar-collapse { 
			// 	display: none !important;
			// }
			// .navbar-expand-md .navbar-toggler {
			// 	display: block;
			// }
		}
		.fa-bars:before {
			color: #999;
		}
		.get-the-report {
			background-color: #d21f61;
			border: 0;
			color: #fff;
			text-decoration: none;
			font-size: large;
			padding: 15px 35px;
			margin: 30px auto;
			max-width: 300px;
		}
		.video-desktop {
			display: none;
			@media (min-width: 768px) {
				display: block;
			}
		}
		.video-fallback {
			display: none;
			@media (max-width: 767px) {
				display: block;
			}
		}
		.container {
			@media (max-width: $md-breakpoint) {
				max-width: 95%;
			}
			@media (min-width: 1200px) {
				max-width: 1190px;
			}
            &.chart-container {
                padding: 15px 20px;
            }
		}
		h1 {
			font-size: 45px;
			font-weight: 100;
		}
		.pre-content {
			img {
				height: 18px;
				margin: 15px 0 15px -2px; 
			}
		}
		.logotype {
			img {
				width: 100%;  
				@media (max-width: 1023px) {
					max-width: 38%;
				}
				@media (max-width: 991px) {
					max-width: 250px;
					width: 100%;
				}
				@media (max-width: 767px) {
					max-width: 75%;
					width: 100%;
				}
				@media (min-width: 1024px) {
					max-width: 350px;
				}
			}
		}
		#stickyNav {
			background-color: #fff;
			margin-top: 0 !important;
			width: 100%;
			position: fixed;
			top: 0;
			z-index: 1000;
			.navbar-toggler {
				right: 16px;
				top: 16px;
			}
			.navbar-nav > li {
				&.nav-item {
					a {
						text-decoration: none;  
						padding: 10px 15px;
						text-transform: uppercase; 
						color: #2d2e2f;
						font-size: 12px;
						// font-weight: 300;
						letter-spacing: 1px; 
						line-height: 1.33; 
						&:hover {
							color: #d21f61;
						}
						&:active {
							color: #000 !important;
						}
					}
					@media (max-width: 820px) {
						a {
							padding: 10px 4px;
						}
						// &.link-1 {
						// 	width: 126px;
						// }
						// &.link-3 {
						// 	width: 170px;
						// }
					}
				}
			} 
		}
		section {
			.section-text {
				margin-bottom: 30px;
			}
			p {
				// line-height: calc(24px + (800vw - 2560px)/1080);
				line-height: 1.7;
				font-size: 15px;
			}
			&.section { 
				&-general {
					&.bg-alternate {
						&-1 {
							background: linear-gradient(to left, #FFE3EE, #ffffff);
						}
						&-2 {
							background: linear-gradient(to left, #FFE4C1, #ffffff);
						}
						&-3 {
							background: linear-gradient(to left, #F3F3F3, #ffffff);
						}
					}
					.numbering-section {
						position: absolute;
					}
					.numbering-h1 {
						display: block;
						.h1-text, .h1-number {
							// float: right;
							display: inline-block;
						}  
						.h1-text { 
							position: relative;
							top: 48px;
							left: -42px;
							line-height: 1.05; 
							@media (max-width: 991px) {
								font-size: 25px; 
								left: -4%;
								top: 19px;
							}
							@media (max-width: 425px) {
								font-size: 19px;
								top: 15px;
							}
							@media (max-width: 320px) {
								font-size: 16px; 
							}
							&.number-2 {
								top: 12px;
                                left: -62px;
								@media (max-width: 990px) {
									top: 5px;
									left: -6%;
								}
								@media (max-width: 425px) {
									top: -1px;
									left: -10%;
								}
							}
                            &.number-3 {
								top: 58px;
								left: -13px; 
								@media (max-width: 990px) {
									top: 32px;
									left: -1%;
								}
								@media (max-width: 425px) {
									top: 18px;
									left: -2%;
								}
							}
                            &.number-4 {
								top: 9px; 
								@media (max-width: 990px) {
									top: -6px;
								}
								@media (max-width: 425px) {
									left: -7%;
								}
							}
                            &.number-5 {
								top: 50px;
								left: -31px; 
								@media (max-width: 990px) {
									top: 21px;
									left: -3%;
								}
								@media (max-width: 425px) {
									top: 13px;
								}
							} 
						} 
						.h1-number {
							img {
								@media (max-width: 990px) {
									height: 122px;
								}
								@media (max-width: 425px) {
									height: 100px;
								}
							} 
						}
					}
					margin-top: 40px;
					padding-top: 0;
					.cover-style,
					.cover-mobile  {
						// padding: 0 0 60px;
						padding: 0 0 1%;
						// background-image: url(https://media.edgeprop.my/page/lendlease/ll_header.png);
						background-size: cover;
						background-position: 100% 0;
						height: 100%;
						width: 100%;
						// max-width: 1440px;
						margin: 0 auto;
						// @media (max-width: $md-breakpoint) {
						// 	padding: 0 0 1%;
						// }
						&.cover-none {
							background-image: none;
						}
						&.cover-default {
							background-repeat: no-repeat;
                            // background-size: 400px auto;
							background-size: 316px auto;
							@media (max-width: $xs-breakpoint) {
								background-image: none;
							}
							@media (max-width: 1280px) {
								background-size: 25% auto;
							}

							// cover only show on desktop
							@media (min-width: 768px) {
								&.point-2 {
									background-image: url(https://media.edgeprop.my/page/lendlease/m-point-02.png);
								}
								&.point-4 {
									background-image: url(https://media.edgeprop.my/page/lendlease/m-point-04.png);
								}
								&.point-6 {
									background-image: url(https://media.edgeprop.my/page/lendlease/m-point-6.png);
								}
								&.cover-left {
									background-position-x: 0;
									&.point-1 {
										background-image: url(https://media.edgeprop.my/page/lendlease/m-point-01.png);
									}
									&.point-3 {
										background-image: url(https://media.edgeprop.my/page/lendlease/m-point-03.png);
									}
									&.point-5 {
										background-image: url(https://media.edgeprop.my/page/lendlease/m-point-05.png);
									} 
								}
							}
						} 
						&.cover-masthead {
							background: linear-gradient(to left, #8e2f8b, #dd144b);
							@media (min-width: 769px) {
								padding: 17px 0 40px;
							}
							h1 {
								color: #fff !important;
								text-shadow: 0px 3px 5px #121212;
								@media (max-width: 1100px) and (min-width: 769px ) {
									font-size: 32px;
								}
								@media (max-width: 768px) {
									text-shadow: 0px 1px 3px #121212; 
									font-size: 22px;
								}
								@media (max-width: 360px) {
									font-size: 19px;
								}
								@media (max-width: 320px) {
									font-size: 16px;
								}
								
							}
						}
					}
					.cover-mobile {
						display: none;
						@media (max-width: $xs-breakpoint) { 
							background-repeat: no-repeat; 
							background-size: 60% auto;
							height: 40vh;
							display: block;
							background-position: center;
							&.m-point-1 {
								background-image: url(https://media.edgeprop.my/page/lendlease/m-point-01.png);
							}
							&.m-point-3 {
								background-image: url(https://media.edgeprop.my/page/lendlease/m-point-03.png);
							}
							&.m-point-5 {
								background-image: url(https://media.edgeprop.my/page/lendlease/m-point-05.png);
							}
							&.m-cover-left {
								// background-position: 50% 0;
								&.m-point-2 {
									background-image: url(https://media.edgeprop.my/page/lendlease/m-point-02.png);
								}
								&.m-point-4 {
									background-image: url(https://media.edgeprop.my/page/lendlease/m-point-04.png);
								}
							}
						}

					}
					// &.section-1,
					// &.section-3,
					// &.section-5 {
					// 	@media (max-width: 767px) {
					// 		.numbering-h1 {
					// 			display: flex;
					// 			flex-wrap: wrap;
					// 			justify-content: end;
					// 		}
					// 	}
					// }
					&.section-masthead {
						margin-top: 5%;
						@media (max-width: 1023px) {
							margin-top: 64px;
						}
						@media (max-width: 767px) {
							margin-top: 53px;
						}
						.section-text {
							margin-top: 58px;
							@media (max-width: $sm-breakpoint) {
								margin-top: 7%;
							}
							@media (max-width: $xs-breakpoint) {
								margin-top: 12%;
							}
						}
						// .cover-style {
						// 	padding: 0 0 20px;
						// }
					}
					&.section-pre {
						margin-top: 0;
					}
					&.section-last {
						img {
							display: block;
							margin: 20px auto;
							max-width: 789px;
							width: 100%;
							@media (max-width: 767px) {
								width: 90%;
							}
						}
						p {
							font-size: x-large;
						}
						video {
							width: 100%;
						}
						padding-bottom: 10px;
					}
				} 
			}
		}
		.section-chart {
			@media (max-width: $xs-breakpoint) {
				padding-top: 40px;
			}
			h1 {
				@media (max-width: 990px) {
					font-size: 25px;  
				}
				@media (max-width: 425px) {
					font-size: 19px;
				}
			}
		}
		.section-lowest {
			img {
				margin: 30px 0;
				display: block;
			}
		}
		h2 {
			text-transform: uppercase;
			width: 100%;
		}
		h3 {
			color: #ec008c!important;
			font-family: apexSansBook,Helvetica,Arial,sans-serif;
			font-size: 30px;
			font-weight: 300;
			letter-spacing: -.806004px;
			line-height: 39px;
			text-transform: none;
		}
		footer {
			background: #f3f3f3;
			padding: 40px 0;
			.wrapper {
				text-align: center;
				span {
					display: block;
					overflow: auto;
					text-transform: uppercase;
					font-size: 15px;
				}
				img {
					display: inline-block;
					width: 100%;
					max-width: 170px;
					padding: 20px;
					@media (max-width: 490px) {
						max-width: 130px;
					}
				}
			}
		}
	}
} 