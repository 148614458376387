
body {
  &.page-content {
    &.page-content-sc-issues-reminder-mudajaya {
      // background: pink;
      .entry-meta {
        // border-top: 1px solid #fff;
      }
    }
    //https://www.edgeprop.my/content/1906288/recycle-resell-repair-or-relove-%E2%80%93-green-guide-moving-house
    &.page-content-1906288 {
      .main-content {
        table {
          .featured-properties {
            display: none;
          }
        }
        #adInContent {
          iframe:first-child {
            display: none;
          }
        }
      }
    }
  }
}