//------------------------------
// Row
//------------------------------
.i-row {
  margin-left: $irow;
  margin-right: $irow;
  > .i-col {
    padding-left: $icol;
    padding-right: $icol;
    // Page Layout
    //- for page layout structure
    &.sidebar {
      &.left {
        padding-right: $icol * 2;
      }
      &.right {
        padding-left: $icol * 2;
      }
    }
    // Specify elements
    //- normally used in .i-form, 
    > .input,
    > .select {
      margin-bottom: 0;
    }
  }
  // Style
  &.narrow {
    margin-left: $irow / 2;
    margin-right: $irow / 2;
    > .i-col {
      padding-left: $icol / 2;
      padding-right: $icol / 2;
    }
  }
  &.zero {
    margin-left: 0;
    margin-right: 0;
    > .i-col {
      padding-left: 0;
      padding-right: 0;
    }
  }
  //- Responsive ---------------------------
  @media (max-width: 479px) {
    &:not(.reset) {
      > .i-col {
        float: none;
        width: auto;
        margin-left: auto;
        margin-right: auto;
      }
    }
  }
}