.general-tq-overlay {
    position: fixed;
    // top: 30%;
    bottom: -160%;
    height: 100vh;
    width: 100vw;
    background: rgba(0, 0, 0, 0.5);
    // width: 500px;
    // right: calc((100% - 500px) / 2);
    transition: all .5s .2s ease-in-out;
    .post-title {
      font-size: 2.4rem;
      margin-bottom: 25px;
      @media (min-width: 768px) {
        width: 80%;
        margin: 0 auto 25px;
      }
    }
    .sub-post-title {
      font-size: 1.8rem;
    }
    .post-form-close {
      position: absolute;
      top: 0;
      background: $bg-close-btn;
      color: white;
      right: calc((100% - 500px) / 2);
      @media (max-width: 991px) {
        right: calc((100% - 300px) / 2);
      }
      // @media (max-width: 767px) {
      //   right: calc((100% - 300px) / 2);
      // }
      padding: 0 10px;
      font-size: 28px;
      z-index: 1;
      cursor: pointer;
      &:hover {
        background: lighten($bg-close-btn, 8%);
      }
    }
    
    &-content {
      background: #fff;
      box-shadow: 0 4px 11px -5px #bfbfbf;
      border: 1px solid #ddd;
      text-align: center;
      margin: 13% auto 0;
      min-height: 220px;
      padding: 25px 20px 20px;
      width: 500px;
      right: calc((100% - 500px) / 2);
      // top: 33%;
      // position: fixed;
      @media (max-width: 991px) {  
        margin: 20% auto 0;
        width: 300px;
        right: calc((100% - 300px) / 2);
        padding: 20px;
      } 
    }
    &.in {
      // left: 0;
      bottom: 0;
      // bottom: 20%;
      transition: all .5s .2s ease-in-out;
    }
  }