//------------------------------
// Card
//------------------------------
.i-card {
  background-color: #fff;
  border: 0.1rem solid $grey-color5;
  border-radius: 0.4rem;
  transition: box-shadow 300ms ease-in-out;
  // image
  > .image {
    display: block;
    padding: 0;
    background-color: rgba(0,0,0,0.05);
    position: relative;
    img {
      display: block;
      width: 100%;
      height: auto;
      border: 0;
      border-radius: inherit;
    }
    &:first-child {
      border-top-right-radius: inherit;
      border-top-left-radius: inherit;
    }
  }
  // content
  > .content {
    flex-grow: 1;
    padding: 1.5rem;
    > .title {
      margin-top: -0.42rem;
      margin-bottom: 0.42rem;
      line-height: 1.2;
    }
    > .meta {
      color: rgba(0,0,0,0.4);
      a {
        color: rgba(0,0,0,0.6);
        &:hover {
          color: rgba(0,0,0,0.8);
        }
      }
    }
    > .description {
      margin-top: 0.8rem;
      color: rgba(0,0,0,0.68);
      :last-child {
        margin-bottom: 0;
      }
    }
  }
  // buttons
  > .buttons {
    border-top: 0.1rem solid rgba(0,0,0,0.08);
    @include clearfix;
    .button {
      display: block;
      float: left;
      width: 100%;
      margin: 0;
      padding: 1.4rem 1rem;
      border: 0;
      text-align: center;
      border-radius: 0 0 0.4rem 0.4rem;
    }
    &.double {
      .button {
        width: 50%;
      }
    }
    &.triple {
      .button {
        width: 33.33334%;
      }
    }
    &.quadruple {
      .button {
        width: 25%;
      }
    }
    &.double,
    &.triple,
    &.quadruple {
      .button {
        border-radius: 0;
        &:first-child {
          border-radius: 0 0 0 0.4rem;
        }
        &:last-child {
          border-radius: 0 0 0.4rem 0;
        }
      }
    }
  }
  // extra
  > .extra {
    padding: 1rem 1.5rem;
    border-top: 0.1rem solid rgba(0,0,0,0.08);
  }
  //-------------------
  // Hover
  //-------------------
  &:hover {
    box-shadow: 0 0.3rem 1rem -0.1rem rgba(0, 0, 0, 0.15);
  }
  //-------------------
  // Clean
  //-------------------
  &.clean {
    background-color: transparent;
    border: 0;
    border-radius: 0;
    box-shadow: none;
    transition: none;
    > .content {
      padding-left: 0;
      padding-right: 0;
    }
    > .extra {
      padding-top: 0;
      padding-left: 0;
      padding-right: 0;
      border-top: none;
    }
  }
  &.flat {
    border: 0;
    border-radius: 0;
    box-shadow: none;
    transition: none;
  }
  &.price {
    text-align: center;
    > .image {
      padding: 1.5rem 0;
      background: linear-gradient(140deg, #0fb8ad 0%, #1fc8db 51%, #2cb5e8 75%);
    }
    > .content {
      ul {
        margin-bottom: 0;
        padding-left: 0;
        list-style: none;
        li {
          padding-top: 0.8rem;
          padding-bottom: 0.8rem;
          + li {
            border-top: 0.1rem solid $grey-color7;
          }
          &.text-strike {
            color: $grey-color4;
          }
        }
      }
    }
    .package {
      .name {
        font-size: 2rem;
        font-weight: 600;
        color: $white;
      }
      .price { font-size: 2.8rem; }
      .unit { font-size: 1.2rem; }
    }
  }
  //-------------------
  // Role
  //-------------------
  &[role='button'] {
    display: block;
    text-decoration: none;
  }
}