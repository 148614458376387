$break_tablet: 767px;
$break_d_sm: 991px;

@media screen and (max-width: 479px) {
  .nl-module {
    .price-desc {
      font-size: 2.6rem;
      span {
        font-size: 1.4rem;
      }
    }
  }
 
  .glass-holder  {
    &.stage {
      height: 22rem;
    }
    &.thumb {
      height: 7.5rem;
    } 
  }

  .i-row:not(.reset)>.i-col {    
    width: 100%;
  }
  .nl-slider {
    .areaCaption, .devCaption {
      display: block;
      margin-top: 2.8rem;
    } 
    .areaCaption {
      margin-top: 0;
    }
    &.activeCaption {
      .areaCaption {
        &:before {
          content: '';
          padding: 0;
        }
      }
    }
  }

 
  .newlaunch-news {
    .news-row {
      .news-thumb {
        width: 100%;
        display: block;
        float: none;
      }
      .news-content {
        margin-left: 0;
        display: block;
      }
    }
    h1 {
      &.news-title {
        margin-top: 10px;
      }
    } 
    .news-thumb {
      img {
        width: 100%;
      }
    } 
  }
}

@media screen and (min-width: 480px) and (max-width: 590px) {
   .glass-holder {
    &.stage {
      height: 18.5rem;
    }  
    &.thumb {
      height: 7.5rem;
    }
  }  
}

@media screen and (min-width: 591px) and (max-width: $break_tablet){
  .glass-holder {
    &.stage {
      height: 20.5rem;
    }  
    &.thumb {
      height: 7.5rem;
    }
  }

  .jumbotron {
    &.newlaunch-search-container {
      .search-tiny {
        .search-form {
          overflow-y: inherit ;
        }
      }
    }   
  }
}

@media screen and (min-width: 480px) and (max-width: $break_tablet) {
  .vpex-list-col {
    &:nth-child(2n+1) {
      clear:both;
    }
  }  
}

@media screen and (max-width: 767px) {
  .jumbotron {
    &.newlaunch-search-container {
      padding: 5px 0;
      margin-top: 50px;
      .search-tiny {
        .search-fakebar {
          margin: 4vh auto;
        }
        .search-form {
          padding: 50px 15px 15px ;
        }
      }
    }   
  }
 
  .newlaunch, .newlaunch-vpex  {
    .social-sharer {
      .social-media {
        bottom: 100px;
      }
    }  
  }
}

@media screen and (min-width: 768px) and (max-width: $break_d_sm) {
  .vpex-list-col {
    &:nth-child(3n+1) {
      clear:both;
    }
  }

  .section-nl, .section-vpex {
    .block-content {
      padding: 0;
    } 
  }
}

@media screen and (min-width: 992px) {
  .vpex-list-col {
    &:nth-child(4n+1) {
    }
  }
}

@media screen and (min-width: $break_d_sm) {
  .jumbotron {
    &.newlaunch-search-container {
      .container {
        padding-left: 0;
        padding-right: 0;
      }
    }   
  }
  .newlaunch-content-area {
    .container {
      padding-left: 0;
      padding-right: 0;
    }
  }
}
@media screen and (max-width: 1024px) {
  .jumbotron {
    &.newlaunch-search-container {
      margin-top: 50px;
    }
  }
}

@media screen and (max-width: 769px) {
  .newlaunch-content-area {
    span {
      &.view-all-btn {
        display: block;
        float: none;
        position: inherit;
      }
    }
  }
  .jumbotron {
    &.newlaunch-search-container {
      .search-tiny {
        .minimal {
          .select {
            &.character {
              margin-top: 0;
              margin-bottom: 2.5rem;
              &:before {
                content: none;
              }
              &.nl-last-select {
                margin-bottom: 5rem;
              }
            }
          }
        }
      }
    }
  }
  .nl-select-title {
    display: block;
    clear: both;
    font-size: 1.4rem;
    margin-bottom: 5px;
  }
}