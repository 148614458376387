$theme-color: #ef60a3;
#stickyNav {
    background-color: #333;
    border-color: rgba(34, 34, 34, 0.05);
    background-color: #000;
    -webkit-transition: all .3s;
    -moz-transition: all .3s;
    transition: all .3s;
    font-weight: 200;
    letter-spacing: 1px;
    border-radius: 0;
    margin-bottom: 0;
    &.stick {
      margin-top: 0 !important;
      width: 100%;
      position: fixed;
      top: 0;
      z-index: 999;
    }
    .navbar {
      border-radius: 0 !important; 
    }
    .navbar-brand {
      color: $theme-color;
      // font-family: Montserrat, sans-serif;
      font-weight: 200;
      letter-spacing: 1px;
      &:focus, &:hover {
        color: #b96700;
      }
    }
    .navbar-toggler {
      font-size: 12px;
      padding: 8px 10px;
      color: #fff;
      position: absolute;
      right: 10px;
      top: 10px;
    }
    .navbar-nav > li > a {
      font-weight: 500;
      text-transform: uppercase; 
      margin-right: 20px;
      @media (min-width: $break_d_md) {
        font-size: 1.4rem; 
        margin-right: 5px;
      }
      @media (max-width: $break_pre_tablet) {
        padding: 6px 0;
      }
      @media (min-width: $break_tablet) and (max-width: $break_d_sm) {
        font-size: 1.1rem;
        line-height: 1;
        margin-right: 0;
      }
      &.active {
        color: $theme-color !important;
        background-color: transparent;
        &:hover {
          background-color: transparent;
        }
      }
      color: #fff;
      &:focus {
        color: #fff;
        &:hover {
          color: $theme-color;
        }
      }
      &:hover {
        color: $theme-color;
      }
    }
    @media (min-width: $break_tablet) and (max-width: $break_tablet) {
      .navbar-nav {
        li {
          &:nth-child(4) {
            width: 70px;
          }
        }
      }
    }
  }
  .nav-pills .nav-link.active {
    border: 1px solid $theme-color;
    color: $theme-color;
    background-color: transparent;
  }
 
  @media (min-width: 992px) {
    .navbar-expand-lg .navbar-nav .nav-link {
      padding-right: 2rem;
    }
  }