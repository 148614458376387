body.page-destination
// , body.page-node-56 
{ 
  // margin-bottom: 123.44px;

  .logotype {
    img {
      @media (min-width: 1024px) {
        width: 300px;
      }
    }
  }
  .fotorama__stage__frame.fotorama__loaded.fotorama__loaded--img.fotorama__active {
    img {
      @media (min-width: 768px) {
        top: -36px !important;
      }
    }
    
  }
  
  @import url('https://fonts.googleapis.com/css2?family=Eczar:wght@400;700&display=swap');
  font-family: 'Eczar', serif;
  font-weight: 400;

  h2, h3 {
    font-weight: 700;
  }

  a {
    &:hover {
      color: lighten( $destination-color, 7%);
    }
  }
  span.header-logotype { 
    color: $destination-color !important;
  }
  .mag-box {
    .box { 
      &:hover {
        background: lighten( $destination-color, 7%);
      }
    }
    .box-2 {
      background: $destination-color;
    }
  }
  #mainNavLive {
    .navbar-brand {
      color: $destination-color;
    }
    .navbar-nav > li > a {
      &.active,
      &:hover {
        color: $destination-color !important;
      }
      &:focus {
        &:hover {
          color: $destination-color;
        }
      }
    }
    @include yearDropDownItemHover($destination-color);

    @media (min-width: $break_post_d_sm) {
      &.navbar-shrink {
        .navbar-brand {
          &:focus, &:hover {
            color: $destination-color;
          }
        }
        .navbar-nav > li > a { 
          &:focus { 
            &:hover {
              color: $destination-color;
            }
          }
          &:hover {
            color: $destination-color;
          }
        }
      }
    }
  }
  section {
    &.general {
      border-top: 10px solid $destination-color;

      .stories-three-columns {
        .row {
          .col {
            .box {
              img {
                width: 300px;
                height: auto;
                @media (max-width: 1024px) {
                  width: 100%;
                }
                @media (max-width: 991px) {
                  width: 150px;
                }
                @media (max-width: 554px) {
                  width: 100%;
                }
              }
              .story-text {
                font-weight: 700;
              }
            }
          }
        }
      }

    }
    h4 {
      color: $destination-color;
    }
    &.home {
      
    }
  }
  .bg-primary {
    background: $destination-color;
    background: -webkit-linear-gradient($destination-color, #d37500);
    background: linear-gradient($destination-color, #d37500);
  }
  .text-primary {
      color: $destination-color;
  }
  .btn-outline { 
    &.active, &:active, &:focus, &:hover { 
      border-color: $destination-color;
      background-color: $destination-color;
    }
  }
  .nav-pills .nav-link.active {
    border: 1px solid $destination-color;
    color: $destination-color; 
  }
  .fotorama {
    &__arr {
      &:before {
        color: lighten( $destination-color, 7%);
        &:hover {
          color: $destination-color;
        }
      }
    }
  }

  .navbar {
    .toplogo {
      img {
        width: auto;
        height: 18px;
      }
    }
  }

  .preload-cover-chi {
    display: none;
  }

  footer {
    margin-top: 200px;
    @media (max-width: 500px) {
      margin-top: 30px;
    }
  }


}