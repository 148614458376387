// .ads-in-content {
//     // display: none;
    
//     div[id^='div-gpt-ad'] {
//         margin: 15px auto 0 !important;
//         text-align: center;
//     }
// }
// // .ads-in-news.guide > div[id^='div-gpt-ad'] {
// //     margin: 15px auto 0;
// // }

#adInContent {
    padding-top: 40px;
    text-align: center;
}