#homeadvisor {
	.bglax {
	    background: 0 0
	}

	.m-break {
	    display: none
	}

	.btn,
	.d-break {
	    display: block
	}

	.no-border {
	    border: none!important
	}
	.margin-center {
		float: none;
	    overflow: hidden;
	    margin: 0 auto;
	}
	.m-t-35 {
		margin-top: 35px;	
	}
	.m-b-30 {
		margin-bottom: 30px;
		overflow: hidden;
	}
	.show-none {
		display: none;
	}
	.float-right {
		float: right;
	}
	.float-left {
		float: left;
	}

}