//------------------------------
// Comments
//------------------------------
.i-comments {
  .comment {
    padding-left: 6.5rem;
    position: relative;
    > .image {
      position: absolute;
      left: 0;
      top: 0;
    }
    > .author {
      margin-bottom: 0.6rem;
      .name {
        font-size: 1.6rem;
        color: $theme-color;
      }
      .meta {
        font-size: small;
        color: $grey-text;
      }
    }
    > .content {
      margin-top: 0.6rem;
      margin-bottom: 1rem;
      font-size: small;
    }
    > .extra {
      margin-top: 0.8rem;
      margin-bottom: 0.8rem;
      a {
        color: darken($theme-color, 10%);
        &:hover {
          text-decoration: none;
        }
      }
    }
  }
  .replied {
    margin-top: 3rem;
    .comment {
      padding-left: 5.5rem;
      + .comment {
        margin-top: 3rem;
      }
    }
  }
  //- Responsive ---------------------------
  @media (max-width: 479px) {
    .comment {
      padding-left: 4.6rem;
      > .image {
        width: 3.6rem;
      }
      > .author {
        .meta {
          font-size: smaller;
        }
      }
      > .content {
        font-size: smaller;
      }
    }
    .replied {
      .comment {
        padding-left: 4rem;
        > .image {
          width: 3rem;
        }
      }
    }
  }
}