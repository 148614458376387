//------------------------------
// Videos
//------------------------------
.video {
  // Layout
  &-grid {
    display: flex;
    flex-wrap: wrap;
    margin: 0 -0.8rem;
    padding: 0;
    list-style: none;
  }
  &-item {
    display: flex;
    float: left;
    width: 33.3333%;
    img {
      width: 100%;
      height: auto;
    }
  }
  &-wrap {
    width: 100%;
    margin: 0.8rem;
  }
  // Content
  &-thumb {
    position: relative;
    background-color: $black;
    > a {
      display: block;
      height: 16.8rem;
      overflow: hidden;
      position: relative;
      img {
        position: absolute;
        top: 50%;
        left: 0;
        transform: translate3d(0, -50%, 0);
      }
    }
  }
  // &-content {}
  // &-meta {}
  &-title {
    font-size: 1em;
  }
  &-video {
    line-height: 1.2;
  }
  //- Responsive ---------------------------
  @media (max-width: 767px) {
    &-item {
      width: 50%;
    }
  }
  @media (max-width: 479px) {
    &-grid {
      margin-left: -0.4rem;
      margin-right: -0.4rem;
    }
    &-wrap {
      margin: 0.4rem;
    }
    // Content
    &-thumb {
      > a {
        height: 12rem;
      }
    }
  }
}