//------------------------------
// Area
//------------------------------
.area {
  &-container {
    padding: 2rem;
    border: 0.1rem solid $border-color;    
  }
  &-panel {
    margin-bottom: 1rem;
    border: 0.1rem solid $border-color;
    background-color: $white;
    border-radius: 0;
    box-shadow: none;
    .area-heading {
      a {
        display: block;
        padding: 1.4rem 1.5rem;
        font-size: 1.3rem;
        &:hover {
          background-color: $theme-color-alt;
          text-decoration: none;
        }
        &:focus {
          text-decoration: none;
        }
      }
    }
    .active {
      background-color:$theme-color-alt;
      a {
        text-decoration: none;
      }
    }
    &-body {
      padding: 1.4rem 1.5rem;
      font-size: 1.2rem;
      ul {
        padding: 0;
        list-style: none;
        margin-bottom: 0;
        line-height: 2.6rem;  
        color:$theme-color-alt;
        a {             
          display:inline-block;
          color:$theme-color-alt;
        }
      }
    }
  }
  //- Responsive ---------------------------
  @media (max-width: 479px) {
    &-container {
      padding: 2rem 1rem;
    }
  }
}
//----------------------------------------
// Area Outlook Nearby Widget
//----------------------------------------
.nearby {
  margin-bottom: 3rem;
  border-radius: 0;
  border: 0.1rem solid $border-color;
  box-shadow: 0 0 0 0;
  .head {
    background-color: $theme-chart;
    color: #fff;
    font-size: 1.15rem;
    font-weight: 600;
    padding: 1.45rem 2rem;
    border-bottom: 0.1rem solid $border-color;
  }
  .body {
    padding: 0 2rem;
    background: $outlook-body;
    .title {
      font-weight: 600;
    }
  }
  .i-items.divided > .item {
    padding: 1.5rem 0;
    border-top: 0.1rem solid $border-color;
    &:first-child {
      border-top:none;
    }
  }
}
//----------------------------------------
// Area Outlook Star Rating
//----------------------------------------
.star-rating {
  .i-segment {
    border-radius:0;
    margin-bottom:0.8rem;
    border: 0.1rem solid $border-color;
    box-shadow: 0 0 0 0;
  }
}
//----------------------------------------
// Area Outlook Nearby-List
//----------------------------------------
.nearby-list {
  .i-segment {
    border-radius: 0;
    margin-bottom: 0.8rem;
    border: 0.1rem solid $border-color;
    box-shadow: 0 0 0 0;
  }
  .category {
    padding-top: 0.5rem;
    padding-bottom: 2rem;
  }
  i {
    padding-right: 1rem;
    color: $theme-color-alt;
  }
  .nearby-title {
    color: $theme-color-alt;
    padding: 2rem 0;
    position: absolute;
    bottom: 0;
    a {
      color: $theme-color-alt;
    }    
  }
  .wrap {
    position: relative;
    padding-bottom: 5rem;
  }
  .info {
    .material-icons {
      font-size: 1.4rem;
    }
  }
}
//----------------------------------------
// Area Outlook Transaction Filter
//----------------------------------------
.area-transaction-filter {
  .filter-col {
    padding: 0 0.4rem;
  }
}
//----------------------------------------
// Area Outlook Price Trend Data Table
//----------------------------------------
.area-price.listing-datatable {
  padding: 0;
  box-shadow: none;
  @media screen and (min-width: 768px) {
    border: none;
  }
}
.trx-link {
  a {
    color: $theme-color-alt;
    transition: color 200ms ease-in-out;  
  }
}
@media (max-width: 767px) {
  .trx-link {
    background-color: transparent;
    transition: background-color 200ms ease-in-out;
    a {
      padding: 0.7rem 1.4rem;
      border: 0.2rem solid $theme-color-alt;
      border-radius: 0.3rem;
      display: block;
      text-align: center;    
      &:before {
        content: 'View all ';
        font-size: 1.2rem;
        color: $theme-color-alt;
        transition: color 200ms ease-in-out;
      }
      &:after {
        content: ' transactions ';
        font-size: 1.2rem;
        color: $theme-color-alt;
        transition: color 200ms ease-in-out;
      }
    }
    &:hover {
      background-color: $theme-color-alt;
      border-radius: 0.3rem;
      transition: background-color 200ms ease-in-out;
      a, a:before, a:after {
        color: #fff;
        transition: color 200ms ease-in-out;
        text-decoration: none;              
      }
    }
  }
  .transaction-table .transaction-body .transaction-section-2 .transaction-item.area-trx {
    font-size: 1.6rem;
  }
}
//----------------------------------------
// Area Outlook Star Rating
//----------------------------------------
.rateStar {
  float: left;
  padding-right: 2rem;
}
.team {
  display: flex;
  align-items: baseline;
}
.star-rating {
  .title {
    font-weight: 700;
    color: #2e3a52;
  }
}