// -----------------------------
// Mixins
// -----------------------------
//---------------------------- feGaussianBlur
@mixin blur($value) {
  filter: blur($value);
  //- filter: progid:DXImageTransform.Microsoft.Blur(PixelRadius=$value,MakeShadow=false)
}
//---------------------------- Clearfix
@mixin clearfix() {
  &:before, &:after { content: ''; display: table; }
  &:after { clear: both; }
}
//---------------------------- Placeholder
@mixin placeholder {
  &::-webkit-input-placeholder {@content;}
  &:-moz-placeholder           {@content;}
  &::-moz-placeholder          {@content;}
  &:-ms-input-placeholder      {@content;}  
}
//---------------------------- Position
@mixin position($position: relative, $coordinates: null null null null) {
  @if type-of($position) == list {
    $coordinates: $position;
    $position: relative;
  }
  $coordinates: unpack($coordinates);
  $offsets: (
    top:    nth($coordinates, 1),
    right:  nth($coordinates, 2),
    bottom: nth($coordinates, 3),
    left:   nth($coordinates, 4)
  );
  position: $position;
  @each $offset, $value in $offsets {
    @if is-length($value) {
      #{$offset}: $value;
    }
  }
}
// -----------------------------
// Special Effect
// -----------------------------
//---------------------------- Filter:01
@mixin cover-gradient($opacity1, $opacity2) {
  &:after {
    content: 'gradient mask for image';
    font-size: 0;
    line-height: 0;
    text-indent: -99999rem;
    background: none;
    background:
      linear-gradient(135deg, rgba(255,101,100,0.4) 0%, rgba(255,101,100,0) 50%, rgba(255,101,100,0) 100%),
      linear-gradient(45deg, rgba(40,0,215,0) 0%, rgba(40,0,215,0) 50%, rgba(40,0,215,0.4) 100%),
      linear-gradient(transparent 5%, rgba(0,0,0,0.8) 100%);
    opacity: $opacity1;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    transition: opacity 300ms ease-in-out;
  }
  &:hover:after {
    opacity: $opacity2;
  }
}

@mixin user-select () {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
}

@mixin floating-animation {
  animation-name: dot-floating;
  animation-duration: 1.5s;
  animation-iteration-count: infinite;
  animation-timing-function: ease-in-out;
  margin-left: 30px;
  margin-top: 5px;
  @keyframes dot-floating {
      0% { transform: translate(0,  0px); }
      50%  { transform: translate(0, 15px); }
      100%   { transform: translate(0, -0px); }    
  }
}

@mixin floating-base {
  animation-iteration-count: infinite;
  animation-timing-function: ease-in-out;
  margin-left: 30px;
  margin-top: 5px;
}

@mixin floating-animation {
  animation-name: dot-floating;
  animation-duration: 1.5s;
  @include floating-base;
  @keyframes dot-floating {
      0% { transform: translate(0,  0px); }
      50%  { transform: translate(0, 15px); }
      100%   { transform: translate(0, -0px); }    
  }
}

@mixin floating-animation-2 {
  animation-name: dot-floating-2;
  animation-duration: 1.8s;
  @include floating-base;
  @keyframes dot-floating-2 {
      0% { transform: translate(0,  0px); }
      50%  { transform: translate(0, 20px); }
      100%   { transform: translate(0, -0px); }    
  }
}

@mixin floating-animation-3 {
  animation-name: dot-floating-3;
  animation-duration: 2.4s;
  @include floating-base;
  @keyframes dot-floating-3 {
      0% { transform: translate(0,  0px); }
      50%  { transform: translate(0, 20px); }
      100%   { transform: translate(0, -0px); }    
  }
}