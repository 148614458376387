.p-l-r-0 {
    padding-left: 0;
    padding-right: 0;
}

.centered {
    text-align: center;
    font-size: 0;
    div {
	    float: none;
	    display: inline-block;
	    text-align: left;
	    font-size: 12px;
	    vertical-align: top;
    }
}