//------------------------------
// Module
//------------------------------
.module {
  padding-top: 8rem;
  padding-bottom: 8rem;
  position: relative;
  &.odd {
    background-color: $module-odd;
  }
  &.even {
    background-color: $module-even;
  }
  // Layout
  &-full {
    padding-top: 0;
    padding-bottom: 0;
  }
  // Content
  &-title {
    margin-top: 0;
    margin-bottom: 1em;
    &:after {
      content: '';
      display: block;
      width: 3em;
      height: 0.1em;
      margin-top: 0.5em;
      background-color: $theme-color;
    }
    // Subtitle
    .module-subtitle {
      letter-spacing: 0.03em;
      font-size: 0.6em;
    }
  }
  &-subtitle {
    display: block;
    margin-top: 0.4em;
    margin-bottom: 1.5em;
    font-family: $font-sans-alt;
    color: $module-subtitle-color;
  }
  &-title,
  &-subtitle {
    &.center {
      text-align: center;
      &:after {
        margin-left: auto;
        margin-right: auto;
      }
    }
    &.right {
      text-align: right;
      &:after {
        margin-left: auto;
      }
    }
  }
  // Addon
  &-bg {
    background-color: $module-even;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: -1;
  }
  &-border {
    &-top {
      border-top: 0.1rem solid darken($module-even, 5%);
    }
    &-bottom {
      border-bottom: 0.1rem solid darken($module-even, 5%);
    }
  }
}