#homeadvisor {
	// @media (max-width:320px) {
	// 	.container-fluid.padd-both-sm {
	// 		padding-top: 15px;
	// 		padding-bottom: 15px;
	// 	}
	// 	.container.padd-both-sm {
	// 		padding-top: 15px;
	// 		padding-bottom: 15px;
	// 	}
	// 	.row.padd-both-sm {
	// 		padding-top: 15px;
	// 		padding-bottom: 15px;
	// 	}
	// 	[class^=col-].padd-both-sm {
	// 		padding-top: 15px;
	// 		padding-bottom: 15px;
	// 	}
	// 	.container-fluid.padd-both-md {
	// 		padding-top: 25px;
	// 		padding-bottom: 25px;
	// 	}
	// 	.container.padd-both-md {
	// 		padding-top: 25px;
	// 		padding-bottom: 25px;
	// 	}
	// 	.row.padd-both-md {
	// 		padding-top: 25px;
	// 		padding-bottom: 25px;
	// 	}
	// 	[class^=col-].padd-both-md {
	// 		padding-top: 25px;
	// 		padding-bottom: 25px;
	// 	}
	// 	.container-fluid.padd-both-lg {
	// 		padding-top: 40px;
	// 		padding-bottom: 40px;
	// 	}
	// 	.container.padd-both-lg {
	// 		padding-top: 40px;
	// 		padding-bottom: 40px;
	// 	}
	// 	.row.padd-both-lg {
	// 		padding-top: 40px;
	// 		padding-bottom: 40px;
	// 	}
	// 	[class^=col-].padd-both-lg {
	// 		padding-top: 40px;
	// 		padding-bottom: 40px;
	// 	}
	// 	.container-fluid.padd-top-sm {
	// 		padding-top: 15px;
	// 	}
	// 	.container.padd-top-sm {
	// 		padding-top: 15px;
	// 	}
	// 	.row.padd-top-sm {
	// 		padding-top: 15px;
	// 	}
	// 	[class^=col-].padd-top-sm {
	// 		padding-top: 15px;
	// 	}
	// 	.container-fluid.padd-top-md {
	// 		padding-top: 25px;
	// 	}
	// 	.container.padd-top-md {
	// 		padding-top: 25px;
	// 	}
	// 	.row.padd-top-md {
	// 		padding-top: 25px;
	// 	}
	// 	[class^=col-].padd-top-md {
	// 		padding-top: 25px;
	// 	}
	// 	.container-fluid.padd-top-lg {
	// 		padding-top: 40px;
	// 	}
	// 	.container.padd-top-lg {
	// 		padding-top: 40px;
	// 	}
	// 	.row.padd-top-lg {
	// 		padding-top: 40px;
	// 	}
	// 	[class^=col-].padd-top-lg {
	// 		padding-top: 40px;
	// 	}
	// 	.container-fluid.padd-bottom-sm {
	// 		padding-bottom: 15px;
	// 	}
	// 	.container.padd-bottom-sm {
	// 		padding-bottom: 15px;
	// 	}
	// 	.row.padd-bottom-sm {
	// 		padding-bottom: 15px;
	// 	}
	// 	[class^=col-].padd-bottom-sm {
	// 		padding-bottom: 15px;
	// 	}
	// 	.container-fluid.padd-bottom-md {
	// 		padding-bottom: 25px;
	// 	}
	// 	.container.padd-bottom-md {
	// 		padding-bottom: 25px;
	// 	}
	// 	.row.padd-bottom-md {
	// 		padding-bottom: 25px;
	// 	}
	// 	[class^=col-].padd-bottom-md {
	// 		padding-bottom: 25px;
	// 	}
	// 	.container-fluid.padd-bottom-lg {
	// 		padding-bottom: 40px;
	// 	}
	// 	.container.padd-bottom-lg {
	// 		padding-bottom: 40px;
	// 	}
	// 	.row.padd-bottom-lg {
	// 		padding-bottom: 40px;
	// 	}
	// 	[class^=col-].padd-bottom-lg {
	// 		padding-bottom: 40px;
	// 	}
	// 	h2 {
	// 		font-size: 34px;
	// 		margin-bottom: 15px;
	// 	}
	// 	h3 {
	// 		font-size: 22px;
	// 		margin-bottom: 15px;
	// 	}
	// 	h4 {
	// 		font-size: 16px;
	// 		margin-bottom: 15px;
	// 	}
	// 	h5 {
	// 		font-size: 13px;
	// 		margin-bottom: 6px;
	// 	}
	// 	h6 {
	// 		font-size: 13px;
	// 	}
	// 	a.arrow {
	// 		&:after {
	// 			font-size: 20px;
	// 		}
	// 	}
	// 	.cont-disclaimer {
	// 		li {
	// 			font-size: 12px;
	// 		}
	// 		ol {
	// 			font-size: 12px;
	// 		}
	// 		p {
	// 			font-size: 12px;
	// 		}
	// 		.title {
	// 			h5 {
	// 				font-size: 16px;
	// 			}
	// 		}
	// 	}
	// 	.page {
	// 		article.home {
	// 			[class^=col-] {
	// 				.menu-text {
	// 					h5 {
	// 						margin-bottom: 10px;
	// 					}
	// 					// h4 {
	// 					// 	margin-bottom: 5px;
	// 					// }
	// 				}
	// 			}
	// 		}
	// 		.home {
	// 			.section-general {
	// 				.sectbox {
	// 					sect-block {
	// 						height: 390px;
	// 						padding: 20px 10px;
	// 						.icon {
	// 							font-size: 24px;
	// 							margin-bottom: 15px;
	// 						}
	// 						h4 {
	// 							font-size: 16px;
	// 						}
	// 						.btn {
	// 							bottom: 20px;
	// 						}
	// 					}
	// 				}
	// 			}
	// 			.cont-nine {
	// 				.videos {
	// 					.item {
	// 						.photo {
	// 							height: 250px;
	// 						}
	// 					}
	// 				}
	// 			}
	// 			.cont-ten {
	// 				h3 {
	// 					span {
	// 						font-size: 18px;
	// 					}
	// 				}
	// 			}
	// 		}
	// 		article.social {
	// 			.container {
	// 				h5 {
	// 					font-size: 18px;
	// 				}
	// 				.copy {
	// 					margin-top: 60px;
	// 					margin-bottom: 60px;
	// 				}
	// 			}
	// 			.tile {
	// 				height: 200px;
	// 			}
	// 		}
	// 		.social {
	// 			h1 {
	// 				font-size: 60px;
	// 			}
	// 			h2 {
	// 				font-size: 28px;
	// 			}
	// 			h3 {
	// 				font-size: 22px;
	// 			}
	// 			h6 {
	// 				font-size: 16px;
	// 				margin-bottom: 10px;
	// 			}
	// 			.cont-five {
	// 				h5 {
	// 					font-size: 18px;
	// 				}
	// 				.row {
	// 					&:nth-child(1) {
	// 						.copy {
	// 							margin-top: 20px;
	// 							margin-bottom: 20px;
	// 						}
	// 					}
	// 					&:nth-child(2) {
	// 						.copy {
	// 							margin-top: 160px;
	// 							margin-bottom: 160px;
	// 						}
	// 					}
	// 					&:nth-child(3) {
	// 						padding-top: 10px;
	// 					}
	// 				}
	// 				.copy {
	// 					.icon {
	// 						width: 80px;
	// 						height: 80px;
	// 						font-size: 40px;
	// 						&:before {
	// 							line-height: 80px;
	// 						}
	// 					}
	// 				}
	// 			}
	// 			.cont-four {
	// 				h5 {
	// 					font-size: 18px;
	// 				}
	// 				.row {
	// 					&:nth-child(1) {
	// 						.copy {
	// 							margin-top: 20px;
	// 							margin-bottom: 20px;
	// 						}
	// 					}
	// 					&:nth-child(2) {
	// 						.copy {
	// 							margin-top: 160px;
	// 							margin-bottom: 160px;
	// 						}
	// 					}
	// 					&:nth-child(3) {
	// 						padding-top: 10px;
	// 					}
	// 				}
	// 				.copy {
	// 					.icon {
	// 						width: 80px;
	// 						height: 80px;
	// 						font-size: 40px;
	// 						&:before {
	// 							line-height: 80px;
	// 						}
	// 					}
	// 				}
	// 			}
	// 			.section-intro {
	// 				h5 {
	// 					font-size: 18px;
	// 				}
	// 				.row {
	// 					&:nth-child(1) {
	// 						.copy {
	// 							margin-top: 20px;
	// 							margin-bottom: 20px;
	// 						}
	// 					}
	// 					&:nth-child(2) {
	// 						.copy {
	// 							margin-top: 160px;
	// 							margin-bottom: 160px;
	// 						}
	// 					}
	// 					&:nth-child(3) {
	// 						padding-top: 10px;
	// 					}
	// 				}
	// 				.copy {
	// 					.icon {
	// 						width: 80px;
	// 						height: 80px;
	// 						font-size: 40px;
	// 						&:before {
	// 							line-height: 80px;
	// 						}
	// 					}
	// 				}
	// 			}
	// 			.cont-six {
	// 				h5 {
	// 					font-size: 18px;
	// 				}
	// 				.row {
	// 					&:nth-child(1) {
	// 						.copy {
	// 							margin-top: 20px;
	// 							margin-bottom: 20px;
	// 						}
	// 					}
	// 					&:nth-child(2) {
	// 						.copy {
	// 							margin-top: 160px;
	// 							margin-bottom: 160px;
	// 						}
	// 					}
	// 					&:nth-child(3) {
	// 						padding-top: 10px;
	// 					}
	// 				}
	// 				.copy {
	// 					.icon {
	// 						width: 80px;
	// 						height: 80px;
	// 						font-size: 40px;
	// 						&:before {
	// 							line-height: 80px;
	// 						}
	// 					}
	// 				}
	// 			}
	// 			.section-general {
	// 				h5 {
	// 					font-size: 18px;
	// 				}
	// 				.row {
	// 					&:nth-child(1) {
	// 						.copy {
	// 							margin-top: 20px;
	// 							margin-bottom: 20px;
	// 						}
	// 					}
	// 					&:nth-child(2) {
	// 						.copy {
	// 							margin-top: 160px;
	// 							margin-bottom: 160px;
	// 						}
	// 					}
	// 					&:nth-child(3) {
	// 						padding-top: 10px;
	// 					}
	// 				}
	// 				.copy {
	// 					.icon {
	// 						width: 80px;
	// 						height: 80px;
	// 						font-size: 40px;
	// 						&:before {
	// 							line-height: 80px;
	// 						}
	// 					}
	// 				}
	// 			}
	// 			.cont-three {
	// 				h6 {
	// 					min-height: 40px;
	// 				}
	// 				.icon {
	// 					width: 55px;
	// 					height: 55px;
	// 					font-size: 27.5px;
	// 					margin-bottom: 15px;
	// 					&:before {
	// 						line-height: 55px;
	// 					}
	// 				}
	// 			}
	// 		}
	// 	}
	// }
	@media (min-width:1441px) {
		.container-fluid.padd-both-sm {
			padding-top: 30px;
			padding-bottom: 30px;
		}
		.container.padd-both-sm {
			padding-top: 30px;
			padding-bottom: 30px;
		}
		.row.padd-both-sm {
			padding-top: 30px;
			padding-bottom: 30px;
		}
		[class^=col-].padd-both-sm {
			padding-top: 30px;
			padding-bottom: 30px;
		}
		.container-fluid.padd-both-md {
			padding-top: 50px;
			padding-bottom: 50px;
		}
		.container.padd-both-md {
			padding-top: 50px;
			padding-bottom: 50px;
		}
		.row.padd-both-md {
			padding-top: 50px;
			padding-bottom: 50px;
		}
		[class^=col-].padd-both-md {
			padding-top: 50px;
			padding-bottom: 50px;
		}
		.container-fluid.padd-both-lg {
			padding-top: 80px;
			padding-bottom: 80px;
		}
		.container.padd-both-lg {
			padding-top: 80px;
			padding-bottom: 80px;
		}
		.row.padd-both-lg {
			padding-top: 80px;
			padding-bottom: 80px;
		}
		[class^=col-].padd-both-lg {
			padding-top: 80px;
			padding-bottom: 80px;
		}
		.container-fluid.padd-top-sm {
			padding-top: 30px;
		}
		.container.padd-top-sm {
			padding-top: 30px;
		}
		.row.padd-top-sm {
			padding-top: 30px;
		}
		[class^=col-].padd-top-sm {
			padding-top: 30px;
		}
		.container-fluid.padd-top-md {
			padding-top: 50px;
		}
		.container.padd-top-md {
			padding-top: 50px;
		}
		.row.padd-top-md {
			padding-top: 50px;
		}
		[class^=col-].padd-top-md {
			padding-top: 50px;
		}
		.container-fluid.padd-top-lg {
			padding-top: 80px;
		}
		.container.padd-top-lg {
			padding-top: 80px;
		}
		.row.padd-top-lg {
			padding-top: 80px;
		}
		[class^=col-].padd-top-lg {
			padding-top: 80px;
		}
		.container-fluid.padd-bottom-sm {
			padding-bottom: 30px;
		}
		.container.padd-bottom-sm {
			padding-bottom: 30px;
		}
		.row.padd-bottom-sm {
			padding-bottom: 30px;
		}
		[class^=col-].padd-bottom-sm {
			padding-bottom: 30px;
		}
		.container-fluid.padd-bottom-md {
			padding-bottom: 50px;
		}
		.container.padd-bottom-md {
			padding-bottom: 50px;
		}
		.row.padd-bottom-md {
			padding-bottom: 50px;
		}
		[class^=col-].padd-bottom-md {
			padding-bottom: 50px;
		}
		.container-fluid.padd-bottom-lg {
			padding-bottom: 80px;
		}
		.container.padd-bottom-lg {
			padding-bottom: 80px;
		}
		.row.padd-bottom-lg {
			padding-bottom: 80px;
		}
		[class^=col-].padd-bottom-lg {
			padding-bottom: 80px;
		}
		h1 {
			font-size: 52px;
			margin-bottom: 30px;
		}
		h2 {
			font-size: 52px;
			margin-bottom: 30px;
		}
		h3 {
			font-size: 34px;
			margin-bottom: 30px;
		}
		h4 {
			font-size: 28px;
			margin-bottom: 30px;
		}
		h5 {
			font-size: 20px;
			margin-bottom: 10px;
		}
		h6 {
			font-size: 16px;
		}
		a.arrow {
			&:after {
				font-size: 22px;
			}
		}
		.cont-disclaimer {
			li {
				font-size: 14px;
			}
			ol {
				font-size: 14px;
			}
			p {
				font-size: 14px;
			}
			.title {
				h5 {
					font-size: 20px;
				}
			}
		}
		.page {
			article.home {
				[class^=col-] {
					.menu-text {
						h5 {
							margin-bottom: 25px;
						}
						h4 {
							// margin-bottom: 10px;
						}
					}
				}
			}
			.home {
				.section-general {
					.sectbox {
						sect-block {
							height: 400px;
							padding: 50px 30px;
							.icon {
								font-size: 32px;
								margin-bottom: 20px;
							}
							h4 {
								font-size: 21px;
							}
							.btn {
								bottom: 50px;
							}
						}
					}
				}
				.cont-nine {
					.videos {
						.item {
							.photo {
								height: 150px;
							}
						}
					}
				}
				.cont-ten {
					h3 {
						span {
							font-size: 24px;
						}
					}
				}
			}
			article.social {
				.container {
					h5 {
						font-size: 24px;
					}
					.copy {
						margin-top: 140px;
						margin-bottom: 80px;
					}
				}
				.tile {
					height: 250px;
				}
			}
			.social {
				h1 {
					font-size: 80px;
				}
				h2 {
					font-size: 44px;
				}
				h3 {
					font-size: 34px;
				}
				h6 {
					font-size: 18px;
					margin-bottom: 15px;
				}
				.cont-five {
					h5 {
						font-size: 24px;
					}
					.row {
						&:nth-child(1) {
							.copy {
								margin-top: 40px;
								margin-bottom: 40px;
							}
						}
						&:nth-child(2) {
							.copy {
								margin-top: 160px;
								margin-bottom: 160px;
							}
						}
						&:nth-child(3) {
							padding-top: 15px;
						}
					}
					.copy {
						.icon {
							width: 160px;
							height: 160px;
							font-size: 80px;
							&:before {
								line-height: 160px;
							}
						}
					}
				}
				.cont-four {
					h5 {
						font-size: 24px;
					}
					.row {
						&:nth-child(1) {
							.copy {
								margin-top: 40px;
								margin-bottom: 40px;
							}
						}
						&:nth-child(2) {
							.copy {
								margin-top: 160px;
								margin-bottom: 160px;
							}
						}
						&:nth-child(3) {
							padding-top: 15px;
						}
					}
					.copy {
						.icon {
							width: 160px;
							height: 160px;
							font-size: 80px;
							&:before {
								line-height: 160px;
							}
						}
					}
				}
				.section-intro {
					h5 {
						font-size: 24px;
					}
					.row {
						&:nth-child(1) {
							.copy {
								margin-top: 40px;
								margin-bottom: 40px;
							}
						}
						&:nth-child(2) {
							.copy {
								margin-top: 160px;
								margin-bottom: 160px;
							}
						}
						&:nth-child(3) {
							padding-top: 15px;
						}
					}
					.copy {
						.icon {
							width: 160px;
							height: 160px;
							font-size: 80px;
							&:before {
								line-height: 160px;
							}
						}
					}
				}
				.cont-six {
					h5 {
						font-size: 24px;
					}
					.row {
						&:nth-child(1) {
							.copy {
								margin-top: 40px;
								margin-bottom: 40px;
							}
						}
						&:nth-child(2) {
							.copy {
								margin-top: 160px;
								margin-bottom: 160px;
							}
						}
						&:nth-child(3) {
							padding-top: 15px;
						}
					}
					.copy {
						.icon {
							width: 160px;
							height: 160px;
							font-size: 80px;
							&:before {
								line-height: 160px;
							}
						}
					}
				}
				.section-general {
					h5 {
						font-size: 24px;
					}
					.row {
						&:nth-child(1) {
							.copy {
								margin-top: 40px;
								margin-bottom: 40px;
							}
						}
						&:nth-child(2) {
							.copy {
								margin-top: 160px;
								margin-bottom: 160px;
							}
						}
						&:nth-child(3) {
							padding-top: 15px;
						}
					}
					.copy {
						.icon {
							width: 160px;
							height: 160px;
							font-size: 80px;
							&:before {
								line-height: 160px;
							}
						}
					}
				}
				.cont-three {
					h6 {
						min-height: 50px;
					}
					.icon {
						width: 110px;
						height: 110px;
						font-size: 55px;
						margin-bottom: 20px;
						&:before {
							line-height: 110px;
						}
					}
				}
			}
		}
	}
	@media (min-width:768px) {
		.container-fluid.flex {
			display: -webkit-box;
			display: -webkit-flex;
			display: -moz-flex;
			display: -ms-flexbox;
			display: flex;
		}
		.container.flex {
			display: -webkit-box;
			display: -webkit-flex;
			display: -moz-flex;
			display: -ms-flexbox;
			display: flex;
		}
		.row.flex {
			display: -webkit-box;
			display: -webkit-flex;
			display: -moz-flex;
			display: -ms-flexbox;
			display: flex;
		}
		[class^=col-].flex {
			display: -webkit-box;
			display: -webkit-flex;
			display: -moz-flex;
			display: -ms-flexbox;
			display: flex;
		}
		.bgfix {
			background-attachment: fixed;
		}
		.page {
			article.home {
				[class^=col-] {
					height: 212px;
					>a {
						&:not(.btn) {
							&:hover {
								.menu-bg {
									-webkit-opacity: .7;
									-moz-opacity: .7;
									opacity: .7;
									filter: alpha(opacity=70);
								}
							}
						}
						&:not(.btn).learn-more {
							&:hover {
								.menu-text {
									bottom: 20px;
									h6 {
										bottom: 0;
									}
								}
							}
						}
					}
					.menu-bg {
						// background: -moz-linear-gradient(left, rgba(0, 77, 118, .8) 0, rgba(0, 77, 118, .18) 100%);
						// background: -webkit-linear-gradient(left, rgba(0, 77, 118, .8) 0, rgba(0, 77, 118, .18) 100%);
						// background: linear-gradient(to right, rgba(0, 77, 118, .8) 0, rgba(0, 77, 118, .18) 100%);
						// filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#cc004d76', endColorstr='#4d004d76', GradientType=1);
					}
					.menu-text {
						left: 30px;
						bottom: 0;
						width: 70%;
						h6 {
							bottom: -50px;
							@media screen and (max-width: 991px) {
								bottom: 10px;
							}
						}
					}
				}
				.first {
					height: 424px;
					.menu-text {
						left: 40px;
						bottom: 0;
					}
				}
				.ser {
					.menu-text {
						width: 100%;
						h4 {
							width: 70%;
						}
					}
				}
				.conv.first {
					height: 424px;
				}
				.fhb.first {
					height: 424px;
				}
			}
			article.home.complete {
				.menu-bg {
					-webkit-opacity: .7;
					-moz-opacity: .7;
					opacity: .7;
					filter: alpha(opacity=70);
				}
			}
			.home {
				.cont-three {
					.container {
						background-color: $theme_color;
					}
					.button-group {
						.btn {
							margin-right: 8px;
						}
					}
				}
				.cont-five {
					.container {
						text-align: right;
						background-color: $theme_color;
					}
				}
				.cont-eight {
					h5 {
						margin: 0;
					}
				}
				.cont-nine {
					.videos {
						.item {
							&:hover {
								-webkit-transform: scale(1.04);
								-moz-transform: scale(1.04);
								-ms-transform: scale(1.04);
								-o-transform: scale(1.04);
								transform: scale(1.04);
								-webkit-box-shadow: 3px 4px 30px 0 rgba(0, 0, 0, .04);
								-moz-box-shadow: 3px 4px 30px 0 rgba(0, 0, 0, .04);
								box-shadow: 3px 4px 30px 0 rgba(0, 0, 0, .04);
							}
						}
					}
				}
				.cont-ten {
					.container {
						background-color: $theme_color;
					}
					h3 {
						&:before {
							left: 0;
						}
					}
					p {
						width: 80%;
					}
					.button-group {
						.btn {
							margin-right: 8px;
						}
					}
				}
			}
			article.social {
				.tile {
					padding-left: 15px;
					padding-right: 15px;
					margin-bottom: 20px;
					>a.learn-more {
						&:hover {
							.menu-text {
								bottom: 20px;
								h6 {
									bottom: 0;
								}
							}
						}
					}
					.menu-bg {
						-webkit-opacity: .5;
						-moz-opacity: .5;
						opacity: .5;
						filter: alpha(opacity=50);
						background-color: $color_white;
					}
					.menu-text {
						position: absolute;
						top: auto;
						right: auto;
						bottom: 0;
						left: 20px;
						width: 80%;
						h4 {
							margin: 0;
						}
						h6 {
							bottom: -40px;
						}
					}
				}
			}
			.social {
				h2 {
					font-size: calc(2.1409455843vw + 37.15px);
					margin-bottom: calc(0vw + 10px);
				}
				.cont-five {
					.row {
						&:nth-child(1) {
							color: $color_white;
							background-color: $theme_color;
							.copy {
								margin-top: calc(5.3523639607vw + 102.87px);
								margin-bottom: calc(5.3523639607vw + 102.87px);
							}
						}
						&:nth-child(3) {
							background-color: $theme_color;
						}
					}
					.copy {
						.icon {
							position: relative;
							display: block;
							width: calc(3.5682426405vw + 28.58px);
							height: calc(3.5682426405vw + 28.58px);
							font-size: calc(1.7841213202vw + 14.29px);
							-webkit-border-radius: 100%;
							-moz-border-radius: 100%;
							border-radius: 100%;
							margin: auto;
							text-align: center;
							color: $color_white;
							border: 1px solid $color_white;
							-moz-transition: all .3s ease-in-out;
							-o-transition: all .3s ease-in-out;
							-webkit-transition: all .3s ease-in-out;
							transition: all .3s ease-in-out;
							margin-bottom: calc(0vw + 20px);
							&:before {
								line-height: calc(3.5682426405vw + 28.58px);
							}
						}
					}
				}
				.cont-four {
					.row {
						&:nth-child(1) {
							color: $color_white;
							background-color: $theme_color;
							.copy {
								margin-top: calc(5.3523639607vw + 102.87px);
								margin-bottom: calc(5.3523639607vw + 102.87px);
							}
						}
						&:nth-child(3) {
							background-color: $theme_color;
						}
					}
					.copy {
						.icon {
							position: relative;
							display: block;
							width: calc(3.5682426405vw + 28.58px);
							height: calc(3.5682426405vw + 28.58px);
							font-size: calc(1.7841213202vw + 14.29px);
							-webkit-border-radius: 100%;
							-moz-border-radius: 100%;
							border-radius: 100%;
							margin: auto;
							text-align: center;
							color: $color_white;
							border: 1px solid $color_white;
							-moz-transition: all .3s ease-in-out;
							-o-transition: all .3s ease-in-out;
							-webkit-transition: all .3s ease-in-out;
							transition: all .3s ease-in-out;
							margin-bottom: calc(0vw + 20px);
							&:before {
								line-height: calc(3.5682426405vw + 28.58px);
							}
						}
					}
				}
				.section-intro {
					.row {
						&:nth-child(1) {
							color: $color_white;
							background-color: $theme_color;
							.copy {
								margin-top: calc(5.3523639607vw + 102.87px);
								margin-bottom: calc(5.3523639607vw + 102.87px);
							}
						}
						&:nth-child(3) {
							background-color: $theme_color;
						}
					}
					.copy {
						.icon {
							position: relative;
							display: block;
							width: calc(3.5682426405vw + 28.58px);
							height: calc(3.5682426405vw + 28.58px);
							font-size: calc(1.7841213202vw + 14.29px);
							-webkit-border-radius: 100%;
							-moz-border-radius: 100%;
							border-radius: 100%;
							margin: auto;
							text-align: center;
							color: $color_white;
							border: 1px solid $color_white;
							-moz-transition: all .3s ease-in-out;
							-o-transition: all .3s ease-in-out;
							-webkit-transition: all .3s ease-in-out;
							transition: all .3s ease-in-out;
							margin-bottom: calc(0vw + 20px);
							&:before {
								line-height: calc(3.5682426405vw + 28.58px);
							}
						}
					}
				}
				.cont-six {
					.row {
						&:nth-child(1) {
							color: $color_white;
							background-color: $theme_color;
							.copy {
								margin-top: calc(5.3523639607vw + 102.87px);
								margin-bottom: calc(5.3523639607vw + 102.87px);
							}
						}
						&:nth-child(3) {
							background-color: $theme_color;
						}
					}
					.copy {
						.icon {
							position: relative;
							display: block;
							width: calc(3.5682426405vw + 28.58px);
							height: calc(3.5682426405vw + 28.58px);
							font-size: calc(1.7841213202vw + 14.29px);
							-webkit-border-radius: 100%;
							-moz-border-radius: 100%;
							border-radius: 100%;
							margin: auto;
							text-align: center;
							color: $color_white;
							border: 1px solid $color_white;
							-moz-transition: all .3s ease-in-out;
							-o-transition: all .3s ease-in-out;
							-webkit-transition: all .3s ease-in-out;
							transition: all .3s ease-in-out;
							margin-bottom: calc(0vw + 20px);
							&:before {
								line-height: calc(3.5682426405vw + 28.58px);
							}
						}
					}
				}
				.section-general {
					.row {
						&:nth-child(1) {
							color: $color_white;
							background-color: $theme_color;
							.copy {
								margin-top: calc(5.3523639607vw + 102.87px);
								margin-bottom: calc(5.3523639607vw + 102.87px);
							}
						}
						&:nth-child(3) {
							background-color: $theme_color;
						}
					}
					.copy {
						.icon {
							position: relative;
							display: block;
							width: calc(3.5682426405vw + 28.58px);
							height: calc(3.5682426405vw + 28.58px);
							font-size: calc(1.7841213202vw + 14.29px);
							-webkit-border-radius: 100%;
							-moz-border-radius: 100%;
							border-radius: 100%;
							margin: auto;
							text-align: center;
							color: $color_white;
							border: 1px solid $color_white;
							-moz-transition: all .3s ease-in-out;
							-o-transition: all .3s ease-in-out;
							-webkit-transition: all .3s ease-in-out;
							transition: all .3s ease-in-out;
							margin-bottom: calc(0vw + 20px);
							&:before {
								line-height: calc(3.5682426405vw + 28.58px);
							}
						}
					}
				}
				.cont-three {
					.col-xs-6 {
						&:nth-child(odd) {
							clear: none;
						}
					}
				}
			}
		}
	}
	@media (min-width:480px) {
		.btn {
			display: inline-block;
		}
	}
	@media (max-width:390px) {
		.btn.small {
			padding: 8px 8px 0;
		}
	}
	@media (max-width:479px) {
		.telephone {
			display: block;
			margin: 15px 0;
			text-align: center;
		}
		.page {
			.home {
				.section-general {
					.sectbox {
						.col-xs-6 {
							width: 100%;
							margin-bottom: 20px;
							sect-block {
								height: 310px;
							}
						}
					}
				}
				.cont-ten {
					h3 {
						span {
							br {
								display: none;
							}
						}
					}
				}
			}
		}
	}
	@media (max-width:767px) {
		.page {
			.home {
				.cont-eight {
					.text-left {
						text-align: center;
					}
					.text-right {
						text-align: center;
					}
				}
			}
		}
	}
	@media (min-width:768px) and (max-width:320px) {
		.page {
			.social {
				h2 {
					font-size: 44px;
					margin-bottom: 10px;
				}
				.cont-five {
					.row {
						&:nth-child(1) {
							.copy {
								margin-top: 120px;
								margin-bottom: 120px;
							}
						}
					}
					.copy {
						.icon {
							width: 40px;
							height: 40px;
							font-size: 20px;
							margin-bottom: 20px;
							&:before {
								line-height: 40px;
							}
						}
					}
				}
				.cont-four {
					.row {
						&:nth-child(1) {
							.copy {
								margin-top: 120px;
								margin-bottom: 120px;
							}
						}
					}
					.copy {
						.icon {
							width: 40px;
							height: 40px;
							font-size: 20px;
							margin-bottom: 20px;
							&:before {
								line-height: 40px;
							}
						}
					}
				}
				.section-intro {
					.row {
						&:nth-child(1) {
							.copy {
								margin-top: 120px;
								margin-bottom: 120px;
							}
						}
					}
					.copy {
						.icon {
							width: 40px;
							height: 40px;
							font-size: 20px;
							margin-bottom: 20px;
							&:before {
								line-height: 40px;
							}
						}
					}
				}
				.cont-six {
					.row {
						&:nth-child(1) {
							.copy {
								margin-top: 120px;
								margin-bottom: 120px;
							}
						}
					}
					.copy {
						.icon {
							width: 40px;
							height: 40px;
							font-size: 20px;
							margin-bottom: 20px;
							&:before {
								line-height: 40px;
							}
						}
					}
				}
				.section-general {
					.row {
						&:nth-child(1) {
							.copy {
								margin-top: 120px;
								margin-bottom: 120px;
							}
						}
					}
					.copy {
						.icon {
							width: 40px;
							height: 40px;
							font-size: 20px;
							margin-bottom: 20px;
							&:before {
								line-height: 40px;
							}
						}
					}
				}
			}
		}
	}
	@media (min-width:768px) and (min-width:1441px) {
		.page {
			.social {
				h2 {
					font-size: 68px;
					margin-bottom: 10px;
				}
				.cont-five {
					.row {
						&:nth-child(1) {
							.copy {
								margin-top: 180px;
								margin-bottom: 180px;
							}
						}
					}
					.copy {
						.icon {
							width: 80px;
							height: 80px;
							font-size: 40px;
							margin-bottom: 20px;
							&:before {
								line-height: 80px;
							}
						}
					}
				}
				.cont-four {
					.row {
						&:nth-child(1) {
							.copy {
								margin-top: 180px;
								margin-bottom: 180px;
							}
						}
					}
					.copy {
						.icon {
							width: 80px;
							height: 80px;
							font-size: 40px;
							margin-bottom: 20px;
							&:before {
								line-height: 80px;
							}
						}
					}
				}
				.section-intro {
					.row {
						&:nth-child(1) {
							.copy {
								margin-top: 180px;
								margin-bottom: 180px;
							}
						}
					}
					.copy {
						.icon {
							width: 80px;
							height: 80px;
							font-size: 40px;
							margin-bottom: 20px;
							&:before {
								line-height: 80px;
							}
						}
					}
				}
				.cont-six {
					.row {
						&:nth-child(1) {
							.copy {
								margin-top: 180px;
								margin-bottom: 180px;
							}
						}
					}
					.copy {
						.icon {
							width: 80px;
							height: 80px;
							font-size: 40px;
							margin-bottom: 20px;
							&:before {
								line-height: 80px;
							}
						}
					}
				}
				.section-general {
					.row {
						&:nth-child(1) {
							.copy {
								margin-top: 180px;
								margin-bottom: 180px;
							}
						}
					}
					.copy {
						.icon {
							width: 80px;
							height: 80px;
							font-size: 40px;
							margin-bottom: 20px;
							&:before {
								line-height: 80px;
							}
						}
					}
				}
			}
		}
	}
}
