.button-banner { 
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    flex-flow: row nowrap;
    -webkit-box-pack: center;
    justify-content: center;
    div[id^='div-gpt-ad'] {
          margin-bottom: 40px; 
          // @media(min-width: $break_d_md) {
          // 	margin: 122px 0 10px;
          // } 
      }
  }