$brand_color: #ff5122;
$brand_blue: #363a94;

body.page-proppoints { 
  padding-top: 0 !important;
  color:#4a646f;
  a {
    color:#0000ff;
    text-decoration:none;
  }
  #lp-pom-image-128-holder {
    background-image:url(https://www.edgeprop.my/sites/all/themes/markone/logo.png);
    position:absolute;
    left:-1000000;
    visibility:none;
  }
  #lp-pom-image-144-holder {
    background-image:url(http://s3-ap-southeast-1.amazonaws.com/files.theedgeproperty.com.my/public/images/proppoints/card1.jpg);
    position:absolute;
    left:-1000000;
    visibility:none;
  }
  #lp-pom-image-152-holder {
    background-image:url(http://s3-ap-southeast-1.amazonaws.com/files.theedgeproperty.com.my/public/images/proppoints/card2.jpg);
    position:absolute;
    left:-1000000;
    visibility:none;
  }
  #lp-pom-image-157-holder {
    background-image:url(http://s3-ap-southeast-1.amazonaws.com/files.theedgeproperty.com.my/public/images/proppoints/card3.jpg);
    position:absolute;
    left:-1000000;
    visibility:none;
  }
  #lp-pom-image-162-holder {
    background-image:url(http://s3-ap-southeast-1.amazonaws.com/files.theedgeproperty.com.my/public/images/proppoints/card4.jpg);
    position:absolute;
    left:-1000000;
    visibility:none;
  }
  #lp-pom-image-174-holder {
    background-image:url(http://s3-ap-southeast-1.amazonaws.com/files.theedgeproperty.com.my/public/images/proppoints/table_tabs.png);
    position:absolute;
    left:-1000000;
    visibility:none;
  }
  #lp-pom-root {
    display:block;
    background:rgba(230,231,232,1);
    -pie-background:rgba(230,231,232,1);
    border-style:none;
    margin:auto;
    padding-top:0px;
    behavior:url(http://d9hhrg4mnvzow.cloudfront.netPIE.htc);
    border-radius:0px;
    min-width:1280px;
    height:auto;
  }
  #lp-pom-block-8 {
    display:block;
    background:rgba(128,186,218,1);
    background-image:url(http://s3-ap-southeast-1.amazonaws.com/files.theedgeproperty.com.my/public/images/proppoints/proppoints-v2.jpg);
    background-repeat:no-repeat;
    background-position:center bottom;
    -pie-background:rgba(128,186,218,1) url(http://s3-ap-southeast-1.amazonaws.com/files.theedgeproperty.com.my/public/images/proppoints/proppoints-v2.jpg) no-repeat center bottom;
    border-style:none;
    margin-left:auto;
    margin-right:auto;
    margin-bottom:0px;
    behavior:url(http://d9hhrg4mnvzow.cloudfront.netPIE.htc);
    border-radius:0px;
    width:100%;
    height:602px;
    position:relative;
  }
  #lp-pom-text-11 {
    display:block;
    background:rgba(255,255,255,0);
    -pie-background:rgba(255,255,255,0);
    border-style:none;
    behavior:url(http://d9hhrg4mnvzow.cloudfront.netPIE.htc);
    border-radius:0px;
    right: 141px;
    top: 179px;
    z-index:1;
    width:325px;
    height:52px;
    position:absolute;
  }
  #lp-pom-text-12 {
    display:block;
    background:rgba(255,255,255,0);
    -pie-background:rgba(255,255,255,0);
    border-style:none;
    behavior:url(http://d9hhrg4mnvzow.cloudfront.netPIE.htc);
    border-radius:0px;
    right: 127px;
    top: 122px;
    z-index:2;
    width:341px;
    height:43px;
    position:absolute;
  }
  #lp-pom-image-128 {
    display:block;
    background:rgba(255,255,255,0);
    -pie-background:rgba(255,255,255,0);
    right: 526px;
    top: 46px;
    z-index:10;
    position:absolute;
  }
  #lp-pom-block-21 {
    display:block;
    background:rgba(255,255,255,1);
    -pie-background:rgba(255,255,255,1);
    border-style:none;
    margin-left:auto;
    margin-right:auto;
    margin-bottom:0px;
    behavior:url(http://d9hhrg4mnvzow.cloudfront.netPIE.htc);
    border-radius:0px;
    width:100%;
    height:358px;
    position:relative;
  }
  #lp-pom-box-25 {
    display:block;
    background:rgba(255,255,255,1);
    -pie-background:rgba(255,255,255,1);
    border-style:solid;
    border-width:1px;
    border-color:#c1cfd4;
    behavior:url(http://d9hhrg4mnvzow.cloudfront.netPIE.htc);
    border-radius:0px;
    left:39px;
    top:733px;
    z-index:3;
    width:278px;
    height:458px;
    position:absolute;
  }
  #lp-pom-text-26 {
    display:block;
    background:rgba(255,255,255,0);
    -pie-background:rgba(255,255,255,0);
    border-style:none;
    behavior:url(http://d9hhrg4mnvzow.cloudfront.netPIE.htc);
    border-radius:0px;
    left:23px;
    top:274px;
    z-index:4;
    width:226px;
    height:44px;
    position:absolute;
  }
  #lp-pom-text-27 {
    display:block;
    background:rgba(255,255,255,0);
    -pie-background:rgba(255,255,255,0);
    border-style:none;
    behavior:url(http://d9hhrg4mnvzow.cloudfront.netPIE.htc);
    border-radius:0px;
    left:23px;
    top:234px;
    z-index:5;
    width:226px;
    height:24px;
    position:absolute;
  }
  #lp-pom-text-133 {
    display:block;
    background:rgba(255,81,34,0);
    -pie-background:rgba(255,81,34,0);
    border-style:none;
    behavior:url(http://d9hhrg4mnvzow.cloudfront.netPIE.htc);
    border-radius:0px;
    left:23px;
    top:412px;
    z-index:6;
    width:226px;
    height:22px;
    position:absolute;
  }
  #lp-pom-image-144 {
    display:block;
    background:rgba(255,255,255,0);
    -pie-background:rgba(255,255,255,0);
    left:0px;
    top:0px;
    z-index:7;
    position:absolute;
  }
  #lp-pom-text-62 {
    display:block;
    background:rgba(255,255,255,0);
    -pie-background:rgba(255,255,255,0);
    border-style:none;
    behavior:url(http://d9hhrg4mnvzow.cloudfront.netPIE.htc);
    border-radius:0px;
    left:0px;
    top:671px;
    z-index:8;
    width:1280px;
    height:29px;
    position:absolute;
  }
  #lp-pom-box-148 {
    display:block;
    background:rgba(255,255,255,1);
    -pie-background:rgba(255,255,255,1);
    border-style:solid;
    border-width:1px;
    border-color:#c1cfd4;
    behavior:url(http://d9hhrg4mnvzow.cloudfront.netPIE.htc);
    border-radius:0px;
    left:345px;
    top:733px;
    z-index:27;
    width:278px;
    height:458px;
    position:absolute;
  }
  #lp-pom-text-149 {
    display:block;
    background:rgba(255,255,255,0);
    -pie-background:rgba(255,255,255,0);
    border-style:none;
    behavior:url(http://d9hhrg4mnvzow.cloudfront.netPIE.htc);
    border-radius:0px;
    left:23px;
    top:273px;
    z-index:28;
    width:226px;
    height:44px;
    position:absolute;
  }
  #lp-pom-text-150 {
    display:block;
    background:rgba(255,255,255,0);
    -pie-background:rgba(255,255,255,0);
    border-style:none;
    behavior:url(http://d9hhrg4mnvzow.cloudfront.netPIE.htc);
    border-radius:0px;
    left:23px;
    top:234px;
    z-index:28;
    width:226px;
    height:24px;
    position:absolute;
  }
  #lp-pom-text-151 {
    display:block;
    background:rgba(255,81,34,0);
    -pie-background:rgba(255,81,34,0);
    border-style:none;
    behavior:url(http://d9hhrg4mnvzow.cloudfront.netPIE.htc);
    border-radius:0px;
    left:23px;
    top:412px;
    z-index:29;
    width:226px;
    height:22px;
    position:absolute;
  }
  #lp-pom-image-152 {
    display:block;
    background:rgba(255,255,255,0);
    -pie-background:rgba(255,255,255,0);
    left:0px;
    top:0px;
    z-index:30;
    position:absolute;
  }
  #lp-pom-box-153 {
    display:block;
    background:rgba(255,255,255,1);
    -pie-background:rgba(255,255,255,1);
    border-style:solid;
    border-width:1px;
    border-color:#c1cfd4;
    behavior:url(http://d9hhrg4mnvzow.cloudfront.netPIE.htc);
    border-radius:0px;
    left:653px;
    top:733px;
    z-index:21;
    width:278px;
    height:458px;
    position:absolute;
  }
  #lp-pom-text-154 {
    display:block;
    background:rgba(255,255,255,0);
    -pie-background:rgba(255,255,255,0);
    border-style:none;
    behavior:url(http://d9hhrg4mnvzow.cloudfront.netPIE.htc);
    border-radius:0px;
    left:23px;
    top:274px;
    z-index:22;
    width:226px;
    height:44px;
    position:absolute;
  }
  #lp-pom-text-155 {
    display:block;
    background:rgba(255,255,255,0);
    -pie-background:rgba(255,255,255,0);
    border-style:none;
    behavior:url(http://d9hhrg4mnvzow.cloudfront.netPIE.htc);
    border-radius:0px;
    left:23px;
    top:234px;
    z-index:23;
    width:226px;
    height:24px;
    position:absolute;
  }
  #lp-pom-text-156 {
    display:block;
    background:rgba(255,81,34,0);
    -pie-background:rgba(255,81,34,0);
    border-style:none;
    behavior:url(http://d9hhrg4mnvzow.cloudfront.netPIE.htc);
    border-radius:0px;
    left:23px;
    top:415px;
    z-index:24;
    width:226px;
    height:22px;
    position:absolute;
  }
  #lp-pom-image-157 {
    display:block;
    background:rgba(255,255,255,0);
    -pie-background:rgba(255,255,255,0);
    left:0px;
    top:0px;
    z-index:25;
    position:absolute;
  }
  #lp-pom-box-158 {
    display:block;
    background:rgba(255,255,255,1);
    -pie-background:rgba(255,255,255,1);
    border-style:solid;
    border-width:1px;
    border-color:#c1cfd4;
    behavior:url(http://d9hhrg4mnvzow.cloudfront.netPIE.htc);
    border-radius:0px;
    left:962px;
    top:733px;
    z-index:16;
    width:278px;
    height:458px;
    position:absolute;
  }
  #lp-pom-text-159 {
    display:block;
    background:rgba(255,255,255,0);
    -pie-background:rgba(255,255,255,0);
    border-style:none;
    behavior:url(http://d9hhrg4mnvzow.cloudfront.netPIE.htc);
    border-radius:0px;
    left:23px;
    top:273px;
    z-index:17;
    width:226px;
    height:132px;
    position:absolute;
  }
  #lp-pom-text-160 {
    display:block;
    background:rgba(255,255,255,0);
    -pie-background:rgba(255,255,255,0);
    border-style:none;
    behavior:url(http://d9hhrg4mnvzow.cloudfront.netPIE.htc);
    border-radius:0px;
    left:23px;
    top:234px;
    z-index:18;
    width:226px;
    height:24px;
    position:absolute;
  }
  #lp-pom-text-161 {
    display:block;
    background:rgba(255,81,34,0);
    -pie-background:rgba(255,81,34,0);
    border-style:none;
    behavior:url(http://d9hhrg4mnvzow.cloudfront.netPIE.htc);
    border-radius:0px;
    left:23px;
    top:417px;
    z-index:19;
    width:226px;
    height:22px;
    position:absolute;
  }
  #lp-pom-image-162 {
    display:block;
    background:rgba(255,255,255,0);
    -pie-background:rgba(255,255,255,0);
    left:0px;
    top:0px;
    z-index:20;
    position:absolute;
  }
  #lp-pom-block-22 {
    display:block;
    background:rgba(230,231,232,1);
    -pie-background:rgba(230,231,232,1);
    border-style:solid none none none;
    border-width:1px;
    border-color:#c1cfd4;
    margin-left:auto;
    margin-right:auto;
    margin-bottom:0px;
    behavior:url(http://d9hhrg4mnvzow.cloudfront.netPIE.htc);
    border-radius:0px;
    width:1280px;
    height:385px;
    position:relative;
  }
  #lp-code-138 {
    display:block;
    left:143px;
    top:1133px;
    z-index:11;
    width:994px;
    height:497px;
    position:absolute;
  }
  #lp-code-139 {
    display:block;
    left:687px;
    top:1070px;
    z-index:12;
    width:473px;
    height:77px;
    position:absolute;
  }
  #lp-pom-text-163 {
    display:block;
    background:rgba(255,255,255,0);
    -pie-background:rgba(255,255,255,0);
    border-style:none;
    behavior:url(http://d9hhrg4mnvzow.cloudfront.netPIE.htc);
    border-radius:0px;
    left:0px;
    top:1000px;
    z-index:13;
    width:1280px;
    height:29px;
    position:absolute;
  }
  #lp-pom-image-174 {
    display:none;
    background:rgba(255,255,255,0);
    -pie-background:rgba(255,255,255,0);
    left:90px;
    top:1348px;
    z-index:31;
    position:absolute;
  }
  #lp-pom-block-23 {
    display:block;
    background:rgba(255,255,255,1);
    -pie-background:rgba(255,255,255,1);
    border-style:none;
    margin-left:auto;
    margin-right:auto;
    margin-bottom:2px;
    behavior:url(http://d9hhrg4mnvzow.cloudfront.netPIE.htc);
    border-radius:0px;
    width:100%;
    height:1673px;
    position:relative;
  }
  #lp-pom-text-165 {
    display:block;
    background:rgba(255,255,255,0);
    -pie-background:rgba(255,255,255,0);
    border-style:none;
    behavior:url(http://d9hhrg4mnvzow.cloudfront.netPIE.htc);
    border-radius:0px;
    left:0px;
    top:1380px;
    z-index:14;
    width:1280px;
    height:29px;
    position:absolute;
  }
  #lp-code-168 {
    display:block;
    left:143px;
    top:1378px;
    z-index:15;
    width:994px;
    height:auto;
    position:absolute;
  }
  #lp-pom-block-24 {
    display:block;
    background:rgba(15,49,76,1);
    -pie-background:rgba(15,49,76,1);
    border-style:none;
    margin-left:auto;
    margin-right:auto;
    margin-bottom:1px;
    behavior:url(http://d9hhrg4mnvzow.cloudfront.netPIE.htc);
    border-radius:0px;
    width:100%;
    height:95px;
    position:relative;
    z-index:9;
  }
  #lp-pom-text-76 {
    display:block;
    background:rgba(255,255,255,0);
    -pie-background:rgba(255,255,255,0);
    border-style:none;
    behavior:url(http://d9hhrg4mnvzow.cloudfront.netPIE.htc);
    border-radius:0px;
    left:0px;
    top:3328px;
    z-index:9;
    width:1280px;
    height:22px;
    position:absolute;
  }
  #lp-pom-root .lp-positioned-content {
    top:0px;
    width:1280px;
    margin-left:-640px;
  }
  #lp-pom-block-8 .lp-pom-block-content {
    margin-left:auto;
    margin-right:auto;
    width:1280px;
    height:602px;
  }
  #lp-pom-block-21 .lp-pom-block-content {
    margin-left:auto;
    margin-right:auto;
    width:1280px;
    height:auto;
  }
  #lp-pom-block-22 .lp-pom-block-content {
    margin-left:auto;
    margin-right:auto;
    width:1280px;
    height:693px;
  }
  #lp-pom-block-23 .lp-pom-block-content {
    margin-left:auto;
    margin-right:auto;
    width:1280px;
    height:auto;
  }
  #lp-pom-block-24 .lp-pom-block-content {
    margin-left:auto;
    margin-right:auto;
    width:1280px;
    height:95px;
  }
  #lp-pom-image-128 .lp-pom-image-container {
    border-style:none;
    behavior:url(http://d9hhrg4mnvzow.cloudfront.netPIE.htc);
    border-radius:0px;
    width:141px;
    height:51px;
  }
  #lp-pom-image-128 .lp-pom-image-container img {
    width: 110px;
    height: auto;
  }
  #lp-pom-image-144 .lp-pom-image-container {
    border-style:none;
    behavior:url(http://d9hhrg4mnvzow.cloudfront.netPIE.htc);
    border-radius:0px;
    width:278px;
    height:208px;
  }
  #lp-pom-image-144 .lp-pom-image-container img {
    width:278px;
    height:208px;
  }
  #lp-pom-image-152 .lp-pom-image-container {
    border-style:none;
    behavior:url(http://d9hhrg4mnvzow.cloudfront.netPIE.htc);
    border-radius:0px;
    width:278px;
    height:208px;
  }
  #lp-pom-image-152 .lp-pom-image-container img {
    width:278px;
    height:208px;
  }
  #lp-pom-image-157 .lp-pom-image-container {
    border-style:none;
    behavior:url(http://d9hhrg4mnvzow.cloudfront.netPIE.htc);
    border-radius:0px;
    width:278px;
    height:208px;
  }
  #lp-pom-image-157 .lp-pom-image-container img {
    width:278px;
    height:208px;
  }
  #lp-pom-image-162 .lp-pom-image-container {
    border-style:none;
    behavior:url(http://d9hhrg4mnvzow.cloudfront.netPIE.htc);
    border-radius:0px;
    width:278px;
    height:208px;
  }
  #lp-pom-image-162 .lp-pom-image-container img {
    width:278px;
    height:208px;
  }
  #lp-pom-image-174 .lp-pom-image-container {
    border-style:none;
    behavior:url(http://d9hhrg4mnvzow.cloudfront.netPIE.htc);
    border-radius:0px;
    width:203px;
    height:23px;
  }
  #lp-pom-image-174 .lp-pom-image-container img {
    width:203px;
    height:23px;
  }
  .lp-pom-image-container {
    overflow: hidden;
  }
  #lp-pom-root-color-overlay {
    position:absolute;
    background:none;
    top:0;
    width:100%;
    height:auto;
    min-height:100%;
  }
  #lp-pom-block-8-color-overlay {
    position:absolute;
    background:none;
    height:602px;
    width:100%;
  }
  #lp-pom-block-21-color-overlay {
    position:absolute;
    background:none;
    height:auto;
    width:100%;
  }
  #lp-pom-block-22-color-overlay {
    position:absolute;
    background:none;
    height:693px;
    width:1280px;
  }
  #lp-pom-block-23-color-overlay {
    position:absolute;
    background:none;
    height:auto;
    width:100%;
  }
  #lp-pom-block-24-color-overlay {
    position:absolute;
    background:none;
    height:95px;
    width:100%;
  }
  #lp-pom-box-25-color-overlay {
    position:absolute;
    background:none;
    behavior:url(http://d9hhrg4mnvzow.cloudfront.netPIE.htc);
    height:458px;
    width:278px;
  }
  #lp-pom-box-148-color-overlay {
    position:absolute;
    background:none;
    behavior:url(http://d9hhrg4mnvzow.cloudfront.netPIE.htc);
    height:458px;
    width:278px;
  }
  #lp-pom-box-153-color-overlay {
    position:absolute;
    background:none;
    behavior:url(http://d9hhrg4mnvzow.cloudfront.netPIE.htc);
    height:458px;
    width:278px;
  }
  #lp-pom-box-158-color-overlay {
    position:absolute;
    background:none;
    behavior:url(http://d9hhrg4mnvzow.cloudfront.netPIE.htc);
    height:458px;
    width:278px;
  }

  .brand-color {
    
  }
  .brand {
    &-color {
      // font-family: montserrat;
      color: $brand_color;
    }
    &-blue {
      color: $brand_blue;
    }
  }

  .lplh {
    &-22 {
      span {
        &.boxtext {
          font-size: 16px;
        }
      }
    }
    &-24 {
      a {
        span {
          &.pointsy {
            font-size:24px; 
            font-weight: 100; 
            color: rgb(15, 49, 76);
          }
        }
      }
    }
  }

  #title-faq {
    &-1, &-2, &-3, &-4, &-5, &-6, &-7, &-8, &-9, &-10 {
      color:black; 
      padding: 25px; 
      font-size: 20px;
    }
  }

  * {
      -webkit-box-sizing: unset;
      -moz-box-sizing: unset;
      box-sizing: unset;
  }

  .lc-boxes {
    .col-md-6 {
      padding: 0;
      width: 48%;
    }
    h3 {
      text-align: center; 
      font-weight: 400;
      font-size: 24px;
      margin-bottom: 30px;
      padding-top: 40px
    }
  }
   
  .lc-boxes-box {
    border: 1px solid #c1cfd4;
    @media screen and (min-width: 601px) {
      &.float-desktop-only {
        float: right;
      }
    }
    span {
      display: block;
      font-size: 13px; 
      line-height: 19px;
      padding: 15px 0 10px;
      &.maintext {
        font-size: 16px; 
        color: #ff5122;
        a {
          color: #ff5122;
          position: relative;
          bottom: -39px;
        }
      }
      &.title {
        font-size: 24px;
        font-weight: 400;
        color: #0f314c;
      }
    }
  }


  @media screen and (min-width: 601px) and (max-width: 991px) {
    .lc-boxes-box {
      height: 207px;
      .col-md-6 {
        &.tablet-only {
          position: relative;
          top: -204px;
          right: -303px;
        }
      }
    }

  }

  @media screen and (max-width: 600px) { 
    .lc-boxes {
      .col-md-6 {
        padding: 0;
        width: 100%;
      }
   }
   .lc-boxes-box {
      height: 444px;
      margin-bottom: 30px;
    .col-md-6 {
      img {
        width: 100%;
      }
    }
    span {
      padding: 15px 15px 5px;
    }
   }
  }

  .img-proppoints-faq9 {
    max-width: 450px;
    float: right;
    margin-right: 31px;
    margin-bottom: 31px;
    @media screen and (max-width: 425px) {
      max-width: 90%;
      float: none;
      margin-right: 0;
      margin: 0 auto;
      margin-left: 15px;
    }
  }

  .footprint-tabler {
    font-size: 1rem;
    font-style: italic;
    position: relative;
    left: 9px;
    top: 9px;
    @media screen and (max-width: 425px) {
      left: 4px;
      top: 7px;
    }
  }
  .m-faq-m {
    margin-top: 60px;
    @media screen and (max-width: 425px) {
      margin-top: 260px;

    }
  }

  ul.simple-bullet {
    li {
      list-style-type: circle !important;
      margin-bottom: 5px;
      font-size: 1.2rem;
      margin-left: 15px;
      &:before {
        content:"";
      }
      &:first-child {
        margin-top: 10px;
        @media screen and (max-width: 425px) {
          margin-top: 3px;
        }
      }
      @media screen and (max-width: 425px) {
        font-size: 1rem;
        margin-bottom: 3px;
      }
    }

  }   
}
