$indepth : #46c4f3;
$latest  : #2056ab;
$video   : #fc9322;
$lifestyle: #f45454;

body {
  &.page-news,
  &.page-node-29 {
    .news-page {
      .main-content {
        .flexslider {
          .post-entry {
            border-bottom: 0;
          }
        }
        .post-entry {
          padding-bottom: 20px;
          border-bottom: 1px solid #ddd;
          margin-bottom: 20px;
          h4.title,
          h5.title {
            font-size: 3.3rem; 
            line-height: 1.2;
            margin-bottom: 10px;
            font-weight: 700 !important;
            @media (max-width: $break_pre_tablet) {
              font-size: 2.2rem;
              line-height: 1.3;
            }
          }
          h5.title {
            font-size: 2.4rem; 
            line-height: 1.4;
            @media (max-width: $break_pre_tablet) {
              font-size: 1.6rem;
              line-height: 1.3;
            }
          }
          img {
            width: 100%; 
          }
          .entry-meta {
            .author {
              font-weight: 600;
              color: #222;
              line-height: 1.6;
            }
          }
        } 
        .alignment {
          margin-bottom: 30px;
        }
        .box-details {
          margin-left: 244px;
          min-height: 150px;
          @media (max-width: $break_pre_tablet) {
            margin-left: 130px;
            min-height: 50px;
          }
          @media (max-width: $break_m_x) {
            margin-left: 115px;
            min-height: 69px;
          }
        }
        #content-section2 article.post-entry .box-thumb {
          height: 140px;
          img {
            position: initial;
            transform: initial;
          }
        }
        
        .box-thumb {
          position: absolute;
          left: 0;
          top: 0;
          .post-thumb {
            @media (min-width: $break_d_md) {
              width: 218px;
              // width: 165px;
              height: 140px;
              overflow: hidden;
            }
            @media (max-width: $break_pre_tablet) {
              width: 120px;
              height: 77px;
              overflow: hidden;
            }
            @media (max-width: $break_m_x) {
              width: 100px;
              height: 64px;
              overflow: hidden;
            }
            img {
              max-width: 100%;
            }
          }
        }
        .entry-meta {
          font-size: 1.4rem;
          @media (max-width: $break_pre_tablet) {
            font-size: 1.2rem;
          }
        }
        .title-wrap {
          border-top: 1px solid #d8d8d8;
          border-bottom: 1px solid #d8d8d8;
          margin-bottom: 20px;
          &.categories {
            border-top: 0;
          }
          &.video {
            border-top: 1px solid $video;
            border-bottom: 1px solid $video;
          }
          &.latest {
            border-top: 1px solid $latest;
            border-bottom: 1px solid $latest;
          }
          &.indepth {
            border-top: 1px solid $indepth;
            border-bottom: 1px solid $indepth;
          }
          &.lifestyle {
            border-top: 1px solid $lifestyle;
            border-bottom: 1px solid $lifestyle;
          }
          .block-title {
            display: inline-block;
            color: $latest;
            font-size: 14px;
            margin: 14px 0;
            line-height: 1.25em;
            text-transform: uppercase;
            letter-spacing: 2px;
            font-weight: 800;
            @media (max-width: $break_m_x) {
              font-size: 18px;
            }
            &.video {
              color: $video;
              a {
                color: $video;
              }
            }
            &.latest {
              a {
                color: $latest;
              }
            } 
            &.indepth {
              a {
                color: $indepth;
              }
            } 
            &.lifestyle {
              a {
                color: $lifestyle;
              }
            } 
          }
          .custom-navigation {
            top: 9px;
            position: relative;
          }
        }
        .content-section {
          &.video {
            .title {
              font-size: 1.6rem;
            }
          }
        }
      }
    }
  }
}
