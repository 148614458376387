// $skin-ads-img: 'https://static1.srcdn.com/wordpress/wp-content/uploads/2018/11/Mission-Impossible-Fallout-poster.jpg';
$skin-ads-img: 'https://media.edgeprop.my/dfp/skin-ads/fmh-zunar-v4.jpg';
$default-bg: #eee;

.skin-ads-wrapper {
    position: fixed;
    top: 140px;
    height: auto;
    width: 100%;
    // @media (max-width: $break_pre_tablet) {
    //     display: none;
    // }
}
.skin-ads {
    cursor: pointer;
    // background: url($skin-ads-img) no-repeat fixed;
    // background-color: $default-bg;
    height: 100vh;
    // background-position: center 140px; old skin ads
    background-position: center 187px;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    @media (max-width: $break_tablet ) {
        &[id^='div-gpt-ad'] {
            margin-top: 0;
        }
    }
    @media (max-width: $break_pre_tablet ) {
        padding-top: 15px;
        background: $default-bg;
    }
    &.pos-fixed {
        // -webkit-transition: all .5s .2s ease-in-out;
        // -o-transition: all .5s .2s ease-in-out;
        // -moz-transition: all .5s .2s ease-in-out;
        // transition: all .5s .2s ease-in-out;
        // background-position: center 140px;
        &[id^='div-gpt-ad'] {
            margin-top: 0;
        }
    }
}
body {
    &.page-content {
        // .wrap.news-wope-theme {
        //     background: transparent;
        // }
        footer {
            &.footer {
                position: relative;
            }
        }
    }
    .wrap.news-wope-theme {
        &.push-2000 {
            top: 375px;
            margin-bottom: 375px;
            transition: all .5s .2s ease-in-out;
        }
        &.push-1600 {
            top: 375px;
            margin-bottom: 375px;
            transition: all .5s .2s ease-in-out;
        }
        // @media (max-width: $break_pre_tablet ) {
        //     top: 15px;
        // }
    }
}