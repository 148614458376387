body.page-proppoints {
	.p-points {
		font-family: 'Montserrat';
		 &.positive, &.negative {
		 	position: relative;
			margin: 0;
		    top: 30px;
		    display: inline;
		    background-color: #27AE60;
		    color: #ffffff;
		    padding-left: 85px;
		    padding-right: 85px;
		    padding-top: 15px;
		    padding-bottom: 15px;
		    border-top-left-radius: .5em;
		 }
		 &.negative {
		 	background: #f44336;
		 	border-top-left-radius: 0;
		 	border-top-right-radius: .5em;
		 	left: -3px;
		 }
	}

	.faq-wrapper {
		border-radius: 10px;
	    overflow: hidden;
	    border: 1px solid;
	    border-color: #cccccc;
	    .line {
	    	height: 60px;
	    	#title-here {
	    		img {
	    			width: 15px; 
	    			padding: 25px;
	    		}
	    	}
	    }
	    p {
	    	padding: 5px 25px 25px;
			font-size: 14px; 
			color: #000000; 
			font-weight: 400; 
			line-height: 20px; 
		}
		#1-faq {
			p {
				padding: 5px 25px; 
				font-size: 14px; 
				color: #000000; 
				font-weight: 400; 
				line-height: 20px;
			}
			ul {
				list-style-type:circle; 
				padding: 5px 25px 5px 40px; 
				font-size: 14px; 
				color: #000000;
			}
		}
	}
}
