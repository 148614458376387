//------------------------------
// Variables
//------------------------------

// Typography
// @import url('https://fonts.googleapis.com/css?family=Muli:200,300,400,400i,600,700,800|Open+Sans:300,400,600,700|Material+Icons');
@import url('https://fonts.googleapis.com/css?family=Poppins:300,400,500,600,700|Material+Icons');
$base-font:                 'Poppins', sans-serif;
$font-sans:                 $base-font;
$font-sans-alt:             'Arial', sans-serif;
$font-serif:                'Georgia', 'Times New Roman', Times, serif;
$font-awesome:              'fontAwesome';
$font-ionic:                'Ionicons';
$base-font-size:            1.4rem;

// Theme
$base-color:                #252525;
$white:                     #ffffff;
$black:                     #000000;

$primary-color1:            #005c98; // #005c98 | 0 92 152
$primary-color2:            #363a94; // #363a94 | 54 58 148

$secondary-color1:          #275075; // #275075 | 39 80 117
$secondary-color2:          #0f314c; // #0f314c | 17 49 76
$secondary-color3:          #46b8da; // #46b8da | 70 184 218
$secondary-color4:          #ff5122; // #ff5122 | 255 81 34
$secondary-color5:          #f4b124; // #f4b124 | #f4b124
$secondary-color6:          #e82093; // #e82093 | 232 32 147
$secondary-color7:          #27ae60; // #27ae60 | 232 32 147
$secondary-color8:          #3b839c; // #3b839c | 59 131 156

$theme-color-19:            #488af8;
$theme-color:               #2e3a52;
$theme-color-edgeprop:      #2e3a52;
$theme-color-alt:           $secondary-color8;
$theme-text:                $white;
$theme-chart:               $secondary-color1;
$outlook-body:              #fffffa;
$link-color-19:             #488BF8;

$color-primary:             #1ca8dd;
$color-success:             #1cd496;
$color-info:                #12dbe2;
$color-warning:             #f9c51f;
$color-danger:              #ff5a5f;

$grey-color1:               #7c7c7c;
$grey-color2:               #969696;
$grey-color3:               #b1b1b1;
$grey-color4:               #c9c9c9;
$grey-color5:               #dedede;
$grey-color6:               #f4f4f4;
$grey-color7:               #f7f6f6;
$grey-color8:               #fafafa;

$dark-text:                 #656565;
$grey-text:                 #a5a5a5;

// UI
$base-container:            99rem;
$base-radius:               0.3rem;
$odd-color:                 $white;
$even-color:                $grey-color7;
$bg-color:                  $grey-color8;
$bg-close-btn:              rgba(0, 0, 0, 0.18);
$bg-trans:                  rgba(0,0,0,.65);
$bg-alt:                   #efefef;
$border-color:              $grey-color7;
$button-theme-color:        $white;
$button-theme-bgcolor:      $theme-color;
$button-theme-alt-color:    $white;
$button-theme-alt-bgcolor:  $theme-color-alt;
$button-default-color:      $base-color;
$button-default-bgcolor:    $white;
$button-dark-color:         $white;
$button-dark-bgcolor:       $base-color;
$button-primary-color:      $white;
$button-primary-bgcolor:    $color-primary;
$button-success-color:      $white;
$button-success-bgcolor:    $color-success;
$button-info-color:         $white;
$button-info-bgcolor:       $color-info;
$button-warning-color:      $white;
$button-warning-bgcolor:    $color-warning;
$button-danger-color:       $white;
$button-danger-bgcolor:     $color-danger;
$button-radius:             $base-radius;
$input-theme:               $theme-color-alt;
$input-color:               #88898a;
$input-bg-color:            #ffffff;
$input-disabled:            #ececed;
$input-height:              4.5rem;
$input-border:              1;
$input-border-style:        solid;
$input-border-color:        #dededf;
$input-radius:              $base-radius;

// Header
$agent-contact-bgcolor:        linear-gradient(to right, $primary-color1 0%, $primary-color2 100%); // $theme-color;
$header-nav-bgcolor:        $theme-color-alt;
$header-nav-link-size:      $base-font-size - 0.1rem; // Font Size
$header-nav-link-weight:    300;                      // Font Weight
$header-nav-link-color:     $base-color;
$header-nav-link-hover:     $base-color; // $theme-color-alt;
$header-nav-link-active:    transparent;
$header-nav-link-activetxt: $white; // $theme-color-alt;
$header-nav-dm-bg:          $theme-color-alt;
$header-nav-dm-color:       $white;
$header-nav-dm-size:        $base-font-size - 0.1rem; // Font Size
$header-nav-dm-hoverbg:     hsl(195, 45%, 48%); // $header-nav-bgcolor;
$header-nav-dm-hovertxt:    $white; // $theme-color;
$header-nav-dm-active:      $color-warning;
$header-nav-dm-activebg:    darken($theme-color-alt, 10%);
$header-bar:                null;
$header-bar-height:         0.5rem;
$header-bar-bgcolor:        $secondary-color3;

// Hero
$hero-background-color:     $theme-color;
$hero-background-opacity:   0.7;
$hero-background-image:     'http://i.imgur.com/H9wcCLY.jpg';
$hero-border-color:         null;

// Content
$content-top-bgcolor:       lighten(mix($theme-color, $base-color, 5%), 80%);
$content-area-bgcolor:      $white;
$content-bottom-bcolor:     #e8e9eb;
$content-bottom-bgcolor:    $even-color;

// Layout
$layout-width:              33rem;

// Footer
$footer-bg-top:             #454545;
$footer-color-top:          #c0c0c0;
$footer-bg-bottom:          #f5f5f5;
$footer-color-bottom:       #454545;

// Module
$module-subtitle-color:     mix($base-color, $white, 45%);
$module-odd:                $odd-color;
$module-even:               $even-color;

// Misc
$irow:                      - 0.8rem;
$icol:                      - $irow;
$social-media-lists:        'facebook' '#3b5998',
                            'twitter' '#1da1f2',
                            'google-plus' '#dd4b39',
                            'linkedin' '#0077b5',
                            'instagram' '#405de6',
                            'youtube' '#cd201f',
                            'pinterest' '#bd081c',
                            'messenger' '#0084ff',
                            'weixin' '#7bb32e',
                            'whatsapp' '#25d366',
                            'github' '#6e5494',
                            'gitlab' '#e24329',
                            'bitbucket' '#205081',
                            'codepen' '#353535';
$social-media-hovered:      'facebook' '#3b5998',
                            'twitter' '#1da1f2',
                            'google-plus' '#dd4b39',
                            'linkedin' '#0077b5',
                            'pinterest' '#bd081c',
                            'whatsapp' '#25d366';
// News
$news-bg:                   #fff;
$news-content-spacing:      0;
$news-aside-width:          15rem;
$news-aside-gap:            $news-aside-width + 4.5rem;
$bg-dark:                   #464a3c;
$header-dropdown-bg:        $grey-color8;
// Dev
$dev-dark-color:            $base-color;
$vpex-color:              #462b5a;

// Debug
$debug:                     'false';