
$two-one-bg-alt: #40407d;
$two-one-bg: #04133b;
$two-one-bg-v: #f7b30f;
#bma-page {
    &.two-one { 
        a.view-listing {
            font-size: 13px;
            position: relative;
            top: -22px;
            margin-bottom: 7px; 
            color: #fff;
            display: block;
            @media (max-width: 1024px) {
                font-size: 12px;
            } 
            &:hover {
                color: #fdb913;
            }
        }
        h1 {
            color: #f7b30f !important;
            font-size: 27px !important;
            font-weight: 600 !important;
            font-family: 'georgia';
            letter-spacing: 2px;
            // text-align: left;
            margin: 0 0 30px 0;
            padding: 0;
            @media (max-width: 768px) and (min-width: 768px) {
                text-align: center;
            }
            @media (max-width: 576px) {
                font-size: 25px !important;
                margin: 0 0 20px 0;
            }
        }
        section {
            .badge-tablet {
                padding: 0;
            }
            //overwrite classes
            .col-xl-11 {
                @media (min-width: 1366px) {
                    width: 1124px;
                }
            }
            &.home {
                background-image: url(https://media.edgeprop.my/page/bmpa/2021/assets/2021-hero-2.jpg);
            }
            &.winners {
                background: $two-one-bg;
                h3 {
                    margin-top: 35px;
                    font-weight: 500;
                    font-size: 27px;
                    font-family: georgia;
                    padding: 7px 0;
                    margin-bottom: 5px;
                    border-top: 0;
                    color: #fff;
                    // text-align: left;
                    text-shadow: 1px 1px #0f0e0e;
                    @media (min-width: 1024px) {
                        margin-top: 80px; 
                    }
                    @media (max-width: 768px) and (min-width: 768px) {
                        text-align: center;
                    }
                    @media (max-width: 576px) {
                        font-size: 16px; 
                    }
                } 
                h4 {
                    padding-bottom: 0;
                }
            }
            &.featured {
                background: $two-one-bg-alt;
                img {
                    max-width: 100%;
                }
            }
            &.gallery {
                background: $two-one-bg;
            }
            &.videos {
                background: $two-one-bg-v;
                h1 {
                    color: $two-one-bg !important;
                }
            }
        &.judges {
                background: $two-one-bg-alt;
                .judge-box {
                    // padding: 0;
                    // max-width: 364px;
                    margin-bottom: 30px;
                    // @media (min-width: 1024px) {
                    // 	flex: 0 0 31.4%;
                    // 	max-width: 31.4%;
                    // }
                    &-empty-container {
                        background: #fff;
                        overflow: auto;
                        @media (min-width: 1024px) and (max-width: 1024px) {
                            min-height: 250px;
                        }
                        @media (max-width: 767px) {
                            min-height: 365px;
                        }
                        @media (max-width: 576px) {
                            min-height: 310px;
                        }
                    }  
                    // box-shadow: 0 2px 4px 0 rgb(0 0 0 / 14%), 0 3px 4px 0 rgb(0 0 0 / 12%), 0 1px 5px 0 rgb(0 0 0 / 20%);
                    .judge-text {
                        color: #333;
                        margin-bottom: 0;
                        .judge-title {
                            color: #333;
                            font-size: 15px; 
                            margin: 10px 0;
                            font-weight: 700;
                            line-height: 1.1;   
                            @media (max-width: 768px) {
                                margin: 10px 0 5px;
                            }
                            @media (max-width: 480px) {
                                font-size: 14px;
                            }
                        }
                        .judge-role {
                            font-weight: 400;
                            font-size: 12px;
                            color: #444; 
                            @media (max-width: 1024px) {
                                font-size: 11px;
                            }
                        }
                        @media (max-width: 767px) {
                            padding: 5px;
                        }
                    }
                    img {
                        // position: relative;
                        // left: -15px;
                        height: 170px;
                        width: 100%;
                        object-fit: cover; 
                        @media (min-width: 1025px) {
                            // left: -15px;
                            height: 200px; 
                        }
                        @media (min-width: 1024px) and (max-width: 1024px) {
                            min-height: 250px;
                        }
                        @media (min-width: 768px) and (max-width: 991px) {
                            height: 202px;
                        }
                        @media (max-width: 375px) {
                            // left: -15px;
                            height: 130px; 
                        }
                        // @media (min-width: 1440px) { 
                        // 	width: 188px;
                        // }
                        // @media (min-width: 1564px) { 
                        // 	width: 204px;
                        // }
                        // @media (min-width: 1680px) { 
                        // 	width: 220px;
                        // }
                        // @media (min-width: 1900px) { 
                        // 	width: 250px;
                        // }
                    }
                    .col-md-4 {
                        @media (max-width: 991px) and (min-width: 768px) {
                            flex: 0 0 50%;
                            max-width: 50%;
                        }

                    }
            &.last-judge {
            @media (max-width: 768px) {
                position: relative;
                left: 25%;
            }
            }
                }
            }
            &.news {
                background: $two-one-bg;
                p.news-date {
                    margin-bottom: 10px;
                }
                .single-card {
                    margin: 0 0 30px 0;
                }
            }
            &.about {
                h2 {
                    border: 1px solid #fff;
                    background: #fdb913;
                    color: #333;
                }
                #ip-container .inlinepopup {
                    // width: 25%;
                    @media (min-width: 768px) {
                        margin: 0 auto 15px;
                        width: 97.4%; 
                        &_arrow {
                            margin-left: -25px;
                        }
                    }  
                }
            }
            &.winners,
            &.featured {
                img {
                    max-width: 515px;
                    @media (max-width: 768px) and (min-width: 768px) {
                        max-width: 322px;
                    }
                }
            }
            &.news,
            &.videos, 
            &.judges {
                .col-6 {
                    width: 50%;
                    // max-width: 100%;
                    @media (max-width: 768px) {
                        max-width: 100%;
                    }
                }
                @media (max-width: 768px) {
                    .judge-box,
                    .news-card .single-card {
                        margin-bottom: 20px;
                    }
                        
                }
            }
            img {
                &.g-img {
                    @media (max-width: 767px) {
                        margin-bottom: 20px;
                    }
                }
            }
            h3 {
                text-align: center;
                @media (min-width: 1024px) {
                    margin-top: 80px;
                    margin-bottom: 5px;
                }
            }
        }
            //utilities
        @media (max-width: 768px) {
            [class*='col-'] {
                padding-right: 10px;
                padding-left: 10px;
            }
        }
        @media (max-width: 1024px) and (min-width: 1024px) {
            .col-md-11 {
                width: 83%;
                .text-center {
                    img {
                        max-width: 380px;
                    }
                }
            }
        }
        @media (max-width: 991px) and (min-width: 768px) {
            .col-md-6 {
                float: left;
                // &.p-0 {
                // 	// float: none; 
                // }
            }
        } 
        .col-md-6, 
        .col-md-12 {
            &.text-center {
                .badge-more {
                    bottom: 28%; 
                    @media (max-width: 1024px) and (min-width: 1024px) {
                        bottom: 66px;
                    } 
                    @media (max-width: 1024px) {
                        width: 40px;
                        height: 40px;
                        font-size: 8px;
                    }
                    @media (max-width: 320px) {
                        zoom: 0.9;
                        bottom: 30%;
                    }
                }
                &.has-listing {
                    .badge-more {
                        bottom: 33.2%;
                    }
                }
            }
        }
        .col-md-6 {
            &.text-center {
                .badge-more {
                    right: 4%; 
                } 
            }
        }
        .col-md-12 {
            &.text-center {
                .badge-more {
                    right: 27%; 
                    @media (max-width: 768px) and (min-width: 768px) {
                        bottom: 61px;
                    }
                    @media (max-width: 767px) {
                        right: 4%;
                    }
                    
                }
            }
        }
    }
}
