@charset "UTF-8";

// @import url('https://fonts.googleapis.com/css?family=Chivo:400,700,900');
// @import url('https://fonts.googleapis.com/css?family=Inknut+Antiqua');
// $project-title: 'Chivo', sans-serif;
// $project-subtitle: 'Inknut Antiqua', serif;
$project-color: #cc0000; 
$bg-alt: #efefef;
$link-color: #488BF8;
$dpc-color: #007455;

.page-content-1723634,
.page-content-1723633,
.page-content-1727601,
.page-content-1727597 {
  .page-projectreview section.page-hero .news-hero-bg {
    @media (min-width: 768px) {
      background-position: bottom right;
    }
  }
}
.page-content-1901179 {
  .page-projectreview section.page-hero .news-hero-bg { 
    @media (min-width: 768px) {
      height: 725px;
    }
    @media (max-width: 767px) {
      // background-position-y: -58px;
      background-image: url('https://media.edgeprop.my/page/project-review/m_homevest.jpg')!important;
    }
  }

}
.page-content-1723635 {
  .page-projectreview section.page-hero .news-hero-bg {
    @media (min-width: 768px) {
      background-position: center;
    }
    @media (max-width: 767px) {
      background-position-y: -58px;
    }
  }
}
.page-content-1849545 {
  .page-projectreview section.page-hero .news-hero-bg {
    // @media (min-width: 768px) {
    //   background-position: center;
    // }
    @media (max-width: 767px) {
      // background-position-y: -58px;
      background-image: url('https://media.edgeprop.my/page/project-review/2021-MAY31-ecocity.jpg')!important;
    }
  }
}
.page-content-1727597 {
  .page-projectreview section.page-hero {
    .news-hero-bg {
      @media (min-width: 768px) {
        background-position: center;
      }
      @media (max-width: 767px) {
        background-position: 45%;
      }
    } 
    .news-hero-main-component .news-hero-text {
      @media (max-width: 767px) {
        position: relative;
        bottom: -33px;
      }
    }
  } 

}
.page-content-1900642 {
  .news-hero-text {
    h1 {
      opacity: 0;
    }
  }
}

.page-content {
  //skyworld brand pages
  &-1902525, 
  &-1901490, 
  &-1901489,
  &-1902125,
  &-1901487,
  &-1901486,
  &-1901938,
  &-1901945,
  &-1830267 { 
    .page-projectreview {
      footer,
      #mainNav.navbar,
      .news-arrow-down .circle-color,
      section.page-inquiry .inquiry-button,
      .fixed-bar .fixed-buttons .btn.btn-enquire {
        background-color: #292F7E;
      }
      section.page-inquiry .inquiry-button, 
      .fixed-bar .fixed-buttons .btn.btn-enquire {
        border-color: #292F7E;
        &:hover {
          background-color: #292F7E;
          opacity: 0.9;
          border-color: #292f7e;
        }
      }
      footer {
        color: #fff;
        a, p {
          color: #ffffff;
        }
      }
    }
  }
  &-1902525,
  &-1902591 {
    .news-arrow-down {
      display: none;
    }
  }
}

// lendlease
.page-content-1903018,
.page-content-1903088 {
  .whatsapp-float-container, 
  section#prform {
    display: none;
  }
  .lendlease-ads {
    display: none;
    width: 100%;
    text-align: center;
    margin: 0 auto;
    img {
      max-width: 780px;
      width: 100%;
    }
    &.desktop {
      @media (min-width: 768px) {
        display: block;
      }
    }
    &.mobile {
      @media (max-width: 767px) {
        display: block;
      }
    }
  }
  .lendlease-survey {
    width: 100%;
    height: 1000px;
    border: 0;
    overflow: hidden;
  }
  .page-projectreview {
    footer,
    #mainNav.navbar,
    .news-arrow-down .circle-color,
    section.page-inquiry .inquiry-button,
    .fixed-bar .fixed-buttons .btn.btn-enquire,
    .fixed-bar {
      background-color: #1965ae;
    }
    section.page-inquiry .inquiry-button,
    .fixed-bar .fixed-buttons .btn.btn-enquire {
      background-color: #C92063;
      border-color: #C92063;
      &:hover {
        background-color: #C92063;
        opacity: 0.9;
        border-color: #C92063;
      }
    }
    footer {
      color: #fff;
      a, p {
        color: #ffffff;
      }
    }
  }
  .fixed-bar {
    .bar-logo {
      @media (max-width: 767px) {
        display: none !important;
      }
    }
  }
}

.page-content-1903088 {
  i.fa.fa-quote-left.fa-2x.el-show {
    display: none;
  }
  section#prform {
    display: block;
  }
}
// end lendlease

//hide quote
.page-content-1903172 {
  i.fa.fa-quote-left.fa-2x.el-show {
    display: none;
  }

}
 
.page-projectreview { 

  #brand-slide {
    z-index: 999;
    position: fixed;
    background: #dcdfe6;
    color: #333;
    width: 260px; 
    text-align: center;
    bottom: 80px;
    right: -260px;
    height: 264px;
    transition: right 0.4s ease-in-out;
    -o-transition: right 0.4s ease-in-out;
    -ms-transition: right 0.4s ease-in-out;
    -moz-transition: right 0.4s ease-in-out;
    -webkit-transition: right 0.4s ease-in-out;
    .box {
      padding: 20px;
      font-size: 13px;
    }
    &:hover,
    &.in  {
      right: 0px;
    }
    &.in,
    &.out  {
      transition: right 0.4s ease-in-out;
      -o-transition: right 0.4s ease-in-out;
      -ms-transition: right 0.4s ease-in-out;
      -moz-transition: right 0.4s ease-in-out;
      -webkit-transition: right 0.4s ease-in-out;
      right: 0px;
    }
    &.out {
      right: -260px;
    }
    #brand-toggle {
      position: absolute;
      right: 260px;
      padding: 10px 20px;
      background: #d1a12a; 
      color: #fff;
      font-weight: 700;
      cursor: pointer;
    }
    a.btn-slide {
      display: block;
      margin-top: 15px;
      background: #007F38;
      color: #fff;
      padding: 4px 10px;
      font-weight: 700;
    }
  }
  .brand-footer {
    @media (max-width: 768px) {
      display: block;
      margin-bottom: 10px;
    }
    @media (min-width: 769px) { 
      margin-right: 15px;
    }
  }
  
  figcaption{
    margin-top: -25px;
    // margin-bottom: 3rem;
    font-size: 1.2rem;
    font-family: $font-sans;
    font-style: italic;
    line-height: 1.4;
    letter-spacing: 0.03em;
    color: $grey-color2;
    &:before {
      font-family: 'FontAwesome';
      content: '\f05a';
      margin-right: 4px;
      font-style: normal;
      font-size: 1.3rem;
    }
  }
  // font-family: $project-title; 
  .banner-show {
    &-d {
      display: none;
      @media (min-width: 768px) {
        display: block;
      }
    }
    &-m {
      display: none;
      @media (max-width: 767px) {
        display: block;
      }

    }
  }
  a {
    color: #000;
    -webkit-transition: all .3s;
    -moz-transition: all .3s;
    transition: all 0.3s;
    &:hover {
      color: lighten( $project-color, 7%);
      text-decoration: none;
    }
  }
  .fa.fa-bars {
    font-style: normal;
    font-family: FontAwesome;
    font-size: 16px;
    line-height: 0;
  }
  .container-news-skin {
    max-width: 980px;
    margin: 0 auto;
    img {
      max-width: 100%;
      height: auto !important;
      width: 100% !important;
    //   @media (max-width: 767px) {
    //     width: 100% !important;
    //   }
    }
    .news-side { 
      
      .side-quote {
        color: #666;
        font-size: 32px;
        line-height: 1.3;
        font-style: italic;
        font-weight: 700;
        letter-spacing: -0.1rem;
        @media (min-width: 768px) and (max-width: 768px) {
          font-size: 22px;
        }
        @media (min-width: 768px) {
          border-right: 1px solid #c2c2c2;
          padding-right: 20px;
        }
        @media (max-width: 767px) {
          font-size: 19px;
        }
      }
      .side-meta {
        margin-top: 20px;
        color: #999;
        font-style: italic;
        font-size: 18px;
        @media (max-width: 490px) {
          font-size: 15px;
          margin: 10px auto 30px;
        }
      }
    }
    .heading-mobile {
      @media (max-width: 767px) {
        padding: 0 20px;
      }
    }
  }
  span {
    // font-family: $project-title;
  }
  hr {
    max-width: 100px;
    margin: 25px auto 0;
    border-width: 1px;
    border-color: rgba(34, 34, 34, 0.1);
    &.light {
      border-color: #fff;
    }
  }
  h1, h2, h3, h4, h5, h6 {
    // font-family: $project-title;
    font-weight: 900;
    // letter-spacing: 1px;
  }
  p {
    // font-family: $project-title;
    font-size: 16px;
    line-height: 1.5;
    margin-bottom: 20px;
    color: #666;
    font-style: italic;
  }
  .news-content {
    p {
      font-style: initial;
    }
  }
  section {
    padding: 40px 0;
    // h2 {
    //   font-size: 50px;
    // }
    h2 {
      margin-top: 15px;
      font-size: 28px;
      font-style: italic;
      color: #666;
      font-weight: 600;
      line-height: 1.3;
      margin-bottom: 20px;
    }
    &.page-hero {
      padding-top: 0;
      padding-bottom: 0;
      .news-hero-main-component {
        .news-hero-button {
          ul.hero-buttons li a.active {
            background: $dpc-color;
            padding: 1px 10px;
            border-radius: 3px;
            // font-weight: 600;
          }
        }
      }
      .news-hero-bg {
        // z-index: 1;
        // @media (min-width: 768px) {
        //   margin-top: -5px;
        // }
        .brand-partner {
          // max-width: 980px;
          // margin: 0 auto;
          // position: relative;
          // top: 24px;
          padding: 10px 0;
          background: rgba(0, 0, 0, 0.19);
          .col-lg-9.brand-partner-side {
            @media (max-width: 1024px) and (min-width: 1024px) {
              flex: 0 0 66.666667%;
              max-width: 66.666667%;
            }
          }
          .brand-partner-side {
            @media (max-width: 425px) {
              display: none;
            }
          }
          .brand-partner-placement {
            text-align: right;
            font-size: 13px; 
            @media (max-width: 768px) and (min-width: 768px) {
              flex: 0 0 96%;
              max-width: 96%;
            }
            .hero-field-text {
              line-height: 1;
              padding: 20px 0; 
              @media (max-width: 768px) {
                flex: 0 0 65%;
                max-width: 65%;
                padding: 20px 0;
              }
              @media (max-width: 767px) {
                padding: 10px 0;
              }
              @media (max-width: 425px) {
                flex: 0 0 68%;
                max-width: 68%;
              }
              @media (max-width: 425px) {
                flex: 0 0 60%;
                max-width: 60%;
              }
              .field-text-container {
                @media (max-width: 768px) {
                width: 67%;
                float: right;
                position: relative;
                top: 0;
                }
                @media (max-width: 568px) {
                  width: 74%; 
                }
                @media (max-width: 424px) {
                  width: 80%;
                }
                @media (max-width: 320px) {
                  top: 4px;
                }
              }
              a,
              a:hover {
                color: inherit;
              }
            }
            .hero-field-logo {
              img {
                max-width: 150px;
                @media (max-width: 767px) {
                  max-width: 114px;
                }
                @media (max-width: 375px) {
                  max-width: 103px;
                }
              }
              @media (max-width: 768px) and (min-width: 768px) {
                position: relative;
                top: 5px;
              }
              @media (max-width: 768px) {
                flex: 0 0 26%;
                max-width: 26%;
              }
              @media (max-width: 320px) {
                position: relative;
                top: 5px;
              }
            } 
          }
          .col-lg-3 {
            @media (max-width: 1024px) and (min-width: 1024px) {
              max-width: 24%;
            }
          }
        }
        background-image: url(https://media.edgeprop.my/s3fs-public/65632_1585711416_5EdgPro5_setiaphotogallery3_0.jpg);
        // position: absolute;
        // top: 0;
        // left: 0;
        width: 100%;
        // height: 100%;
        height: 570px;
        background-repeat: no-repeat;
        background-position: center 0;
        background-size: cover;
      }
      .news-hero-main-component {
        position: relative;
        @media (min-width: 768px) {
          top: 130px;
        }
        @media (max-width: 767px) {
          padding-top: 50px;
        }
        .news-hero-text {
          color: #fff;
          text-align: center;  
          margin-bottom: 90px;
          // text-shadow: 2px 1px #3b3b3b;
          text-shadow: 4px 2px 8px #444;
          // z-index: 2;
          h4 {
            font-weight: 100 ;
            font-size: 22px;
            @media (min-width: 1024px) {
              font-size: 30px;
            }
            @media (max-width: 490px) {
              width: 90%;
              margin: 3px auto;
            }
          }
          h1 {
            font-size: 35px;
            width: 90%;
            margin: 0 auto;
            @media (min-width: 1024px) {
              font-size: 56px;
            }
            @media (max-width: 490px) { 
              margin: 3px auto;
            }
          }
        }
        .news-hero-button {
          background: rgba(0, 0, 0, 0.5);
          padding: 11px 0;  
          ul.hero-buttons {
            text-align: center;
            margin:0;
            padding: 0;
            li {
              display: inline-block;
              color: #fff;
              text-align: center;
              text-transform: uppercase;
              margin-right: 20px;
              font-size: 18px;
              a {
                color: #fff;
              }
            }
          }
        }
      }
    }
    &.page-content {
      .news-content {
        img {
          margin-bottom: 30px;
        }
        a {
          color: $link-color !important;
        }
      }
    }
    &.page-related,
    &.page-categories,
    &.page-accolades { 
      h3 {
        margin-top: 15px;
        font-size: 23px;
        font-style: italic;
        color: #666;
        font-weight: 600;
        line-height: 1.3;
      }
    }
    &.page-related,
    &.page-accolades {
      img {
        &.similar-articles {
          height: 190px !important;
          object-fit: cover;
        }
      }
      h3 {
        font-size: 19px;
      }
      p {
        display: block;
        display: -webkit-box;
        /* max-width: 400px; */
        height: 76px;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
    &.page-accolades {
      .accolades-card {
        background-color: #fff;
        border: 1px solid rgba(0, 0, 0, .125);
        text-align: center;
        padding: 20px; 
        @media (min-width: 769px) {
          min-height: 452px;
        }
        @media (min-width: 768px) and (max-width: 768px) {
          min-height: 557px;
        }
        .accolades-logo {
          max-width: 196px;
          height: 100%;
          margin: 10px 0 30px;
        }
        .text-top, 
        .text-center,
        .text-bottom {
          margin-bottom: 20px;
        }
        .text-top {
          font-style: italic;
          color: #222;
          font-size: 16px;
          font-weight: 700;
        }
        .text-center {
          color: $project-color;
          font-size: 14px;
          font-weight: 600;
        }
        .text-bottom {
          color: #666;
          font-size: 14px;
          font-weight: 200;
          margin-bottom: 30px;
        }
      }

    }
    &.page-brand {
      // background: $bg-alt;
      .brand-button {
        cursor: pointer;
        font-size: 1.9rem;
        line-height: 1.3;
        text-transform: uppercase;
        border: 1px solid #000;
        text-align: center;
        background: #fff;
        padding: 12px 80px;
        margin-top: 10px;
        display: inline-block; 
        &:hover {
          border-color: $project-color;
          color: $project-color;
        }
      }
    }
    &.page-categories,
    &.page-inquiry {
      h2 {
        margin-top: 15px;
        font-size: 28px;
        font-style: italic;
        color: #666;
        font-weight: 700;
        line-height: 1.3;
        margin-bottom: 10px;
      }
      h3 {
        font-weight: 500;
        color: #666;
        margin-bottom: 30px;
      }
    }
    &.page-categories {
      background: #0a2141;
      color: #fff;
      h2 { 
        color: #fff; 
      }
      h3 { 
        color: #fff; 
      }
      .categories-text {
        font-size: 22px;
        margin-bottom: 30px;
      }
    }
    
    &.page-inquiry {
      background: $bg-alt;
      h2, h3 {
        text-align: center;
      }
      #moreinfo {
        @media (min-width: 768px) {
          max-width: 500px;
          margin: 0 auto;
        }
        .form-group {
          margin-bottom: 20px;
          .form-control {
            font-size: 14px;
          }
        }
        .form-check {
          padding-left: 0;
          label {
            margin-left: 5px;
            font-size: 13px;
            @media (max-width: 991px) {
              font-size: 11px;
            }
          }
        }
        .label-pdpa {
          position: relative;
          right: -6px;
          top: -3px;
          height: 20px;
          @media (max-width: 320px) {
            margin-right: 10px; 
          }
          @media (max-width: 425px) {
            margin-right: 4px; 
          }
          @media (min-width: 991px) {
            position: relative;
            right: -1px;
            top: -1px;
          }
        }
      }
      .inquiry-button {
        width: 100%;
        @media(min-width: 768px) {
          // width: 300px;
        }
        margin: 0 auto;
        display: inherit;
        cursor: pointer;
        font-size: 1.9rem;
        line-height: 1.3;
        text-transform: uppercase;
        border: 1px solid $project-color;
        background: $project-color;
        color: #fff;
        text-align: center; 
        padding: 12px 80px;
        margin-top: 10px;
        display: inline-block;
        &:hover {
          background: darken($project-color, 10%);
        }
      }
    }
    &.page-share { 
      margin: 0 auto;
      text-align: center;
      h2 {
        text-align: center;
        text-transform: uppercase;
        color: #666;
        font-size: 28px;
        font-weight: 600;
      }
      ul.share-icons {
        margin: 0;
        padding: 0;
        li {
          cursor: pointer;
          display: inline-block;
          list-style-type: none;
          border-radius: 50%;
          width: 44px;
          height: 44px;
          background: #efefef;
          padding: 8px;
          font-size: 2rem;
          color: #888; 
          &.in-between {
            margin: 0 26px;
          }
          &:hover {
            background: #ddd;
          }
        }
  
      }
    }
  }

  .news-arrow-down {
    text-align: center;
    font-size: 70px;
    color: $project-color;
    margin-top: -55px;
    .circle-color {
      margin: 0 auto;
      background: $project-color;
      line-height: 57px;
      width: 60px;
      height: 60px;
      box-shadow: 0 5px 2px -2px #bfbfbf;
      border-radius: 50%;
    }
  }
  span.header-logotype {
    font-weight: 700 !important;
    text-transform: uppercase !important;
    color: $project-color !important;
    position: relative;
    top: 18px;
  } 

  
  .m-b-20 {
    margin-bottom: 20px;
  }
  .logotype {
    text-align: center;
    color: #f4b124;
    margin-left: auto;
    margin-right: auto;
    left: 0;
    right: 0;
    @media (max-width: 767px) {
      margin-left: 0;
    }
    img {
      // width: 250px;
      opacity: 0.9;
    }
    &.footer img {
      // width: 110px;
      height: 55px;
    }
    &.toplogo img {
      // width: 90px;
      height: 100%;
      max-height: 35px;
      width: auto;
    }
    &.home {
      text-align: left;
      position: absolute;
      z-index: 1;
      margin-left: auto;
      margin-right: auto;
      left: 0;
      right: 0;
      padding: 8px;
    }
  }
  .border-side {
    border-right: 1px solid #ccc;
    border-left: 1px solid #ccc;
  }
  .no-float {
    float: none;
  }
  footer {
    font-style: normal;
    color: #fff;
    padding: 20px 0;
    text-align: center;
    color: rgba(255, 255, 255, 0.3);
    background-color: #222;
    margin-bottom: 45px;
    @media (min-width: 769px) {
      margin-bottom: 74px;
    }
    p {
      font-size: 12px;
      margin: 0;
    }
    ul {
      margin-bottom: 0;
      li a {
        font-size: 12px;
        color: rgba(255, 255, 255, 0.3);
        &.active, &:active, &:focus, &:hover {
          text-decoration: none;
        }
      }
    }
    a {
      color: inherit;
      &:hover {
        color: inherit;
      }
    }
  }
  #mainNav {
    // background-color: #333;
    // border-color: rgba(34, 34, 34, 0.05); 
    -webkit-transition: all .3s;
    -moz-transition: all .3s;
    transition: all .3s; 
    font-weight: 200;
    // letter-spacing: 1px;
    border-radius: 0;
    &.stick {
      margin-top: 0 !important;
      width: 100%;
      position: fixed;
      top: 0;
      z-index: 10000;
    }
    &.navbar {
      border-radius: 0 !important;
      margin-bottom: 0 !important;
      margin-bottom: 0;
      min-height: 44px;
      border: 0;
      background: #000;
    }
    .navbar-brand {
      color: $project-color; 
      font-weight: 200;
      letter-spacing: 1px;
      &:focus, &:hover {
        color: #b96700;
      }
    }
    // hide hamburger menu on mobile
    .navbar-toggler {
      font-size: 12px;
      padding: 8px 10px;
      color: #fff;
      position: absolute;
      right: 10px;
      top: 10px;
      display: none;
    }
    .navbar-nav > li > a {
      font-size: 12px; 
      letter-spacing: 2px;
      text-transform: uppercase;
      letter-spacing: 0;
      padding: 5px;
      &.active {
        color: #000 !important;
        background-color: transparent;
        &:hover {
          background-color: transparent;
        }
      }
      color: #fff;
      &:focus {
        color: #fff;
        &:hover {
          color: #000;
        }
      }
      &:hover {
        color: #000;
      }
    }
    .navbar-nav {
      .nav-item {
        .wrap-year-select {
          position: relative;
          padding: 5px;
          @media (max-width: 425px) {
            padding: 0;
          }
          .dropdown {
            .dropdown-toggle {
              background-color: transparent;
              border: 1px solid #ffffff;
              border-radius: 0;
              font-size: 13px;
              color: #ffffff;
              letter-spacing: 1px;
            }
            .dropdown-menu {
              background-color: #000;
              .dropdown-item {
                border-radius: 0;
                font-size: 13px;
                color: #ffffff;
                &:hover {
                  color: $project-color;
                  background-color: #000;
                }
                @media (max-width: 425px) {
                  text-align: left;
                  padding-left: 0;
                }
              }
            }
          }
        }
      }
    }
    @media (min-width: $break_post_d_sm) {
      .navbar-brand {
        color: fade(white, 70%);
        &:focus, &:hover {
          color: #fff;
        }
      }
      .navbar-nav > li > a {
        color: #fff;
        &:focus {
          color: rgba(255, 255, 255, 0.7);
          &:hover {
            color: rgba(255, 255, 255, 0.7);
          }
        }
        &:hover {
          color: #fff;
        }
      }
      &.navbar-shrink {
        border-color: rgba(34, 34, 34, 0.1);
        background-color: #000;
        border-radius: 0;
        .navbar-brand {
          color: #f9f9f9;
          &:focus, &:hover {
            color: $project-color;
          }
        }
        .navbar-nav > li > a {
          color: #f9f9f9;
          &:focus {
            color: #f9f9f9;
            &:hover {
              color: $project-color;
            }
          }
          &:hover {
            color: $project-color;
          }
        }
      }
    } 
  }
  section {
    &.general {
      border-top: 10px solid $project-color;
      position: relative;
      padding: 50px 0 50px;
      overflow: hidden;
      h2 {
        font-size: 50px;
        margin: 0 0 30px;
        text-transform: uppercase;
        // font-family: $project-title;
        &.m-b-5 {
          margin-bottom: 5px;
        }
      }
      h3 {
        font-size: 21px;
        // font-family: $project-title;
        margin-bottom: 30px;
        text-transform: uppercase;
      }
      h4 {
        // font-family: $project-title;
        font-size: 2.2rem;
        line-height: 1;
        margin-bottom: 15px;
        color: $project-color;
        text-transform: lowercase;
        font-style: italic;
      }
 
      &.no-border {
        border-top: 0;
      }
      &.p-t-25 {
        padding-top: 25px;
      }
    } 

  }
  .top-text {
    max-width: 800px;
    margin: 35px auto 10px;
    font-style: italic;
    font-size: 22px;
    text-align: center;
    color: #555;
    @media (max-width:767px) {
      font-size: 20px;
    }

  }


  @media (max-width: $break_tablet) {
    .logotype {
        img {
          width: 220px;  
        }
    }
  }
  @media (max-width: $break_pre_tablet) {
    .border-side {
        border-right: 0;
        border-left: 0; 
    }
  }
    
  @media (min-width: 426px) {
    .visible-xs {
      display: none !important;
    }
  }
  
  @media (max-width: $break_m_lg) {
    .visible-xs {
      display: block;
    }
  }


  .bg-primary {
      background: $project-color;
      background: -webkit-linear-gradient($project-color, #d37500);
      background: linear-gradient($project-color, #d37500);
  }
  .text-primary {
      color: $project-color;
  }
  .no-gutter > [class*=col-] {
      padding-right: 0;
      padding-left: 0;
  }
  .btn-outline {
      color: #fff;
      border: 1px solid;
      border-color: #fff;
      &.active, &:active, &:focus, &:hover {
          color: #fff;
          border-color: $project-color;
          background-color: $project-color;
      }
  }
  .btn {
      border-radius: 300px; 
      letter-spacing: 2px;
      text-transform: uppercase;
  }
  .btn-xl {
      font-size: 11px;
      padding: 15px 45px;
  }
  .nav-pills .nav-link.active {
      border: 1px solid $project-color;
      color: $project-color;
      background-color: transparent;
  }
  ul.list-unstyled li {
      margin-bottom: 20px;
      font-size: 13px;
      color: #666;
  }
  .ml-auto, .mx-auto {
      margin-left: auto !important;
  }
  .mr-auto, .mx-auto {
      margin-right: auto !important;
  }
 
  span {
    display: block;
    &.stories-title {
      font-size: 1.4rem;
      font-weight: bold;
    }
    &.stories-subtitle {
      font-size: 1.2rem;
    }
  } 
  ul {
    &.stories-list {
      li { 
        span {
          &.story-text {
            display: block; 
            font-weight: 600;
            font-size: 1.4rem;
            line-height: 1.5;
            margin: 0 auto;
            @media (min-width: $break_post_tablet) {
              width: 70%;
            }
          }
        }
        @media(min-width: $break_post_tablet) {
          min-height: 270px;
        }
        @media(min-width: $break_tablet) and (max-width: $break_tablet) {
          min-height: 262px;
        }
      }
    }
  }


 /*  @media (min-width: 768px) and (max-width: 991px) {
    .navbar-collapse.collapse {
      display: none !important;
      &.in {
        display: block !important;
      }
    }
    .navbar-header .collapse, .navbar-toggle {
      display: block !important;
    }
    .navbar-header {
      float: none;
    }
  } */
  select#select-years {
    font-size: 1.3rem;
    position: relative;
    background: #000;
    color: #fff;
    @media (min-width: $break_tablet) {
      top: 13px;
    }
  }

  .mobile-bg-odd {
    @media (max-width: 767px) {
      background: #333;
      position: relative;
      right: 10px;
      bottom: -6px;
      width: 102%;
      padding: 5px 0 5px 11px;
    }
  }
  .post-form-overlay {
    position: fixed;
    // top: 30%;
    bottom: -160%;
    height: 100vh;
    width: 100vw;
    background: rgba(0, 0, 0, 0.5);
    // width: 500px;
    // right: calc((100% - 500px) / 2);
    transition: all .5s .2s ease-in-out;
    .post-title {
      font-size: 2.4rem;
      margin-bottom: 25px;
      @media (min-width: 768px) {
        width: 80%;
        margin: 0 auto 25px;
      }
    }
    .sub-post-title {
      font-size: 1.8rem;
    }
    .post-form-close {
      position: absolute;
      top: 0;
      background: $bg-close-btn;
      color: white;
      right: calc((100% - 500px) / 2);
      @media (max-width: 991px) {
        right: calc((100% - 300px) / 2);
      }
      // @media (max-width: 767px) {
      //   right: calc((100% - 300px) / 2);
      // }
      padding: 0 10px;
      font-size: 28px;
      z-index: 1;
      cursor: pointer;
      &:hover {
        background: lighten($bg-close-btn, 8%);
      }
    }
    
    &-content {
      background: #fff;
      box-shadow: 0 4px 11px -5px #bfbfbf;
      border: 1px solid #ddd;
      text-align: center;
      margin: 13% auto 0;
      min-height: 220px;
      padding: 25px 20px 20px;
      width: 500px;
      right: calc((100% - 500px) / 2);
      // top: 33%;
      // position: fixed;
      @media (max-width: 991px) {  
        margin: 20% auto 0;
        width: 300px;
        right: calc((100% - 300px) / 2);
        padding: 20px;
      } 
    }
    &.in {
      // left: 0;
      bottom: 0;
      // bottom: 20%;
      transition: all .5s .2s ease-in-out;
    }
  }
  .fixed-bar {
    position: fixed;
    background: #fff;
    width: 100%;
    padding: 15px 60px;
    bottom: 0;
    left: 0;
    z-index: 4;
    box-shadow: 0 -5px 10px rgba(0,0,0,.24);
    @media (max-width: 991px) { 
      padding: 10px;
    }
    .align-items-center {
      align-items: center!important;
    }
    .bar-logo {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      img {
        height: 100%;
        max-height: 50px;
        width: auto;
        @media (max-width: 991px) {
          height: auto;
          width: 100%;
          max-width: 30px;
        }
      }
      .bar-text {
        margin-left: 10px;
        font-size: 16px;
        font-style: italic;
        font-weight: 700;
        color: #666;
        @media (max-width: 991px) {
          font-size: 10px;
          line-height: 1.2;
        }
      }
      @media (max-width: 991px) {
        width: 39%;
      }
      @media (min-width: 1024px) and (max-width: 1024px) {
        width: 82%;
      }
      @media (min-width: 1025px) {
        width: 52%;
      }
    }
    .fixed-buttons {
      .btn {
        color: #fff;
        padding: 8px 20px;
        font-size: 18px;
        letter-spacing: 0;
        border-radius:3px;
        @media (max-width: 991px) {
          font-size: 14px;
          padding: 6px 15px;

        }
        &.btn-enquire {
          background-color: $project-color;
          border-color: $project-color;
          &:hover {
            background: darken($project-color, 10%);
          }
        }
      }

    }
  } 
  // b[id^='docs-internal-guid'] {
  //   a {
  //     margin-left: 3px;
  //   }
  // }
 
}


// nippon
.page-content-1886214 { 
  .page-projectreview {
    #mainNav {
      &.navbar {
        background: #09428f;
      } 
      // .navbar-toggler {
      //   display: none;
      // }
    }
    section.page-hero {
      .news-hero-bg {
        .news-hero-main-component {
          top: 70px;
          @media (max-width: 767px) {
            padding-top: 10px;
          }
          .news-hero-text {
            margin-bottom: 50px;
            h1, h4 {
              text-shadow: 2px 6px 8px #000000;
            }
          }
        }
        background-image: url(https://media.edgeprop.my/page/project-review/nippon/nippon-hero.jpg) !important;
        @media (min-width: 768px) {
          background-position: bottom right;
        }
        .nbox-container {
          margin: 0 auto;
          text-align: center;
          .nbox-row {
            @media (min-width: 768px) {
              display: inline-block;
            }
            @media (max-width: 767px) {
              display: block;
            }
            &.mtop {
              @media (max-width: 767px) {
                margin-top: 3px;
              } 
            }
            span {
              @media (min-width: 1024px) { 
                padding-right: 0; 
              }
              img { 
                @media (max-width: 767px) {
                  width: 150px;
                }
              }
            } 
            &.second {
              span {
                @media (min-width: 1024px) { 
                  &:last-child {
                    padding-right: 0; 
                  }
                }

              }
            }
          } 
        }
        .nippon-logo {
          margin-bottom: 30px;
          @media (min-width: 769px) {
            width: 332px; 
          } 
          @media (max-width: 425px) {
            width: 200px;
          }
        }
      }
    } 
  }
}

// navbar color 
.page-content {
  &-1886214,
  &-1884779,
  &-1884770 {
    .page-projectreview {
      #mainNav {
        &.navbar {
          background: #09428f;
        } 
      }
    }
  }
}
//nippon


// global function to adjust year dropdown item color
@mixin yearDropDownItemHover($themeColor) {
  .navbar-nav {
    .nav-item {
      .wrap-year-select {
        .dropdown {
          .dropdown-menu {
            .dropdown-item {
              &:hover {
                color: $themeColor;
              }
            }
          }
        }
      }
    }
  }
}