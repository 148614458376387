//------------------------------
// Agent
//------------------------------
// Agent Phone
.agent-phone {
  display: table;
  position: relative;
  border-collapse: separate;
  font-weight: 400;
  line-height: 1.5;
  .agent-phone-addon {
    display: table-cell;
    width: 1%;
    padding: 0.5rem 1rem;
    white-space: nowrap;
    vertical-align: middle;
    border: 0.1rem solid #ddd;
    background-color: #eee;
    border-radius: $base-radius;
    &:first-child {
      border-right: 0;
      border-radius: $base-radius 0 0 $base-radius;
    }
    &:last-child {
      border-left: 0;
      border-radius: 0 $base-radius $base-radius 0;
    }
  }
  .agent-phone-number {
    display: table-cell;
    float: left;
    width: 100%;
    border: 0.1rem solid #ddd;
    text-align: left;
    overflow: hidden;
    position: relative;
    z-index: 2;
    border-radius: $base-radius;
    &:first-child {
      border-radius: $base-radius 0 0 $base-radius;
    }
    &:last-child {
      border-radius: 0 $base-radius $base-radius 0;
    }
  }
  .agent-phone-mask {
    display: block;
    padding: 0.5rem 1rem;
    background-color: #eee;
    color: #999;
    white-space: nowrap;
    cursor: pointer;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 4.8rem;
    right: 0;
    z-index: 1;
    box-shadow: 0 0 1.5rem 1rem #eee;
    transition: left 300ms ease-in-out;
  }
  .agent-phone-masked {
    > a {
      display: block;
      padding: 0.5rem 1rem;
      background-color: #fff;
      color: #666;
      text-decoration: none;
      white-space: nowrap;
    }
  }
}
// Search Result Page --------
.agent {
  .i-segment {
    border: 0.1rem solid #f7f6f6;
    box-shadow: 0 0 0 0;
  }
  .content {
    color:#2e3a52;
  }
  .title {
    font-weight:600;
  }
  .divided {
    border: 0.1rem solid #f7f6f6;
    padding: 1.5rem;
    margin:0 0 1.2rem 0;
  }
  .meta {
    .data {
      display: inline-block;
      padding-right: 0.3rem;
      color:#2e3a52;
    }
    .data + .data:before {
      content: '|';
      vertical-align: baseline;
      padding-right: 0.6rem;
    }
  }
  .item:hover {
    background-color: #fafafa;
  }
  img {
    border: 0.1rem solid #f1f1f1;
  }
  //- Responsive ---------------------------
  @media (max-width: 479px) {
    .i-segment {
      box-shadow: 0 0 0 0;
      border: none;
      .body {
        padding:0
      }
    }
    .divided {
      padding: 1rem 0.8rem;
    }
  }
}
.searchResult {
  color: $theme-color-alt;
  padding-left: 1.5rem;
  font-size: 1.2rem;
  font-weight: 600;
}
.unMask {
  padding-left:0.2rem;
  font-weight: 600;
}
.search-agent {
  background-image: linear-gradient(rgba(3,48,118, 0.45), rgba(3,48,118, 0.45)), url(https://media.edgeprop.my/assets/images/area-outlook-bg.png);
  background-position: center center;
  background-repeat: no-repeat;
  //- Layout
  .search-wrapper {
    padding: 10rem 0;
    @if $hero-border-color {
      border: 0.1rem solid $hero-border-color;
    }
  }
  .search-header {
    h2 {
      font-size: 2.5rem;
      color:white;
    }
  }  
  //- Components
  .i-form {
    .input,
    .select {
      min-height: auto;
      background-color: transparent;
      border: 0.1rem solid #fff;
      border-radius: 0.2rem;
      label {
        color: #fff;
        line-height: 4rem;
      }
    }
    .input input,
    .select select {
      min-height: 4rem;
      color: #fff;
    }
    .select {
      &:after {
        content: '\f123';
        width: auto;
        border: 0;
        font-family: $font-ionic;
        font-size: 1rem;
        top: 1.3rem;
        right: 1rem;
        color: #fff;
      }
      option {
        color: $theme-color;
      }
    }    
  }  
  //- Responsive ---------------------------
  @media (max-width: 767px) {
    .search-wrapper { 
      padding: 5.5rem 0;
    }
    .search-header {
      h2 {
        margin-top: 0;
        font-size: 1.7rem;
      }
    }
  }
  @media (min-width: 1600px) {
    .search-header {
      h2 {
        font-size: 3.6rem;
      }
    }
  }
}
// Block ---------------------
.block-agent-region {
  .card {
    &-box {
      width: 23.5rem;
      height: 15rem;
      margin-right: 0.5rem;
      margin-bottom: 0.5rem;
      float: left;
      position: relative;
      //background: url("http://media.dreamplango.com/resources/dpg/Feature/97d2accc-8a12-4844-81d6-47c0c764b29b.jpg");
      background-repeat: no-repeat;
      background-position: center; 
      &:first-child {
        height: 30.5rem;
        background-size: 46rem;
      }
      &:nth-child(n+2){
        background-size: 23.5rem;
      }
      &:nth-child(n+8) {        
        display: none;
      }
      $bg_img: "http://s3-ap-southeast-1.amazonaws.com/www.theedgeproperty.com.my/assets/images/regions/KL.jpg",
               "http://s3-ap-southeast-1.amazonaws.com/www.theedgeproperty.com.my/assets/images/regions/Selangor.jpg",
               "http://s3-ap-southeast-1.amazonaws.com/www.theedgeproperty.com.my/assets/images/regions/Penang.jpg",
               "http://s3-ap-southeast-1.amazonaws.com/www.theedgeproperty.com.my/assets/images/regions/Johor.jpg",
               "http://s3-ap-southeast-1.amazonaws.com/www.theedgeproperty.com.my/assets/images/regions/Putrajaya.jpg",
               "http://s3-ap-southeast-1.amazonaws.com/www.theedgeproperty.com.my/assets/images/regions/Ipoh.jpg",
               "http://s3-ap-southeast-1.amazonaws.com/www.theedgeproperty.com.my/assets/images/regions/Kedah.jpg",
               "http://s3-ap-southeast-1.amazonaws.com/www.theedgeproperty.com.my/assets/images/regions/Pahang.jpg",
               "http://s3-ap-southeast-1.amazonaws.com/www.theedgeproperty.com.my/assets/images/regions/NSembilan.jpg",
               "http://s3-ap-southeast-1.amazonaws.com/www.theedgeproperty.com.my/assets/images/regions/Melaka.jpg",
               "http://s3-ap-southeast-1.amazonaws.com/www.theedgeproperty.com.my/assets/images/regions/Terengganu.jpg",
               "http://s3-ap-southeast-1.amazonaws.com/www.theedgeproperty.com.my/assets/images/regions/Perlis.jpg",
               "http://s3-ap-southeast-1.amazonaws.com/www.theedgeproperty.com.my/assets/images/regions/Kelantan.jpg",
               "http://s3-ap-southeast-1.amazonaws.com/www.theedgeproperty.com.my/assets/images/regions/Sabah.jpg",
               "http://s3-ap-southeast-1.amazonaws.com/www.theedgeproperty.com.my/assets/images/regions/Sarawak.jpg",;
      @for $i from 1 through length($bg_img) {  
        &:nth-child(#{$i}) {
          background-image: url("#{nth($bg_img, $i)}");
        }
      }
    }
    &-label{
      background-color: rgba(0, 0, 0, 0.3); 
      color: white;  
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: center; 
      align-items: center;
      font-size: 2rem;
    }
  }
  .cover {
    width: 23.5rem;
    height: 15rem;
    margin-right: 0.5rem;
    margin-bottom: 0.5rem;
    float: left;
    position: relative;
    //background: url("http://media.dreamplango.com/resources/dpg/Feature/97d2accc-8a12-4844-81d6-47c0c764b29b.jpg");
    background-repeat: no-repeat;
    background-position: center;    
  }
  //Responsive
  @media (max-width: 1125px) {
    .card-box, .cover {
      width: 23rem;
    }
    .card-box {
      &:first-child {
        background-size: 46rem;
      }
      &:nth-child(n+2) {
        background-size: 23rem;
      }
    }
  }
  @media (max-width: 991px) {
    .card-box, .cover {
      width: 35.5rem;
    }
    .card-box {
      &:first-child {
        background-size: 46rem;
      }
      &:nth-child(n+2) {
        background-size: 35.5rem;
      }
    }
  }
  @media (max-width: 767px) {
    .card-box, .cover {
      width: 100%;
    }  
    .card-box {
      &:first-child {
        height:15rem;
        background-size: 100%;
      }
      &:nth-child(n) {
        background-size: 100%;
      }
    }
  }
}
.block-feature-agent {
  .i-segment {
    border-radius: 0;
    background-color: #fafafa;
    box-shadow: 0 0 0 0.1rem #f7f6f6;
    .content {
      .title {
        padding-bottom: 0.2rem;
      }
      ul {
        list-style: none;
        padding-left: 0;
        margin-bottom: 0;
        li {
          padding-bottom: 0.2rem;
          color: #2e3a52;
        }
      }
    }
  }
}
.block-specialist-agent {
  .i-segment {
    border-radius: 0;
    border: 0.1rem solid #f7f6f6;
    box-shadow: 0 0 0 0;
    .content {
      .title {
        padding-bottom: 0.2rem;
      }
      ul {
        list-style: none;
        padding-left: 0;
        margin-bottom: 0;
        li {
          padding-bottom: 0.2rem;
          color: #2e3a52;
        }
      }
    }
  }  
  .button.wide {
    padding: 1.5rem 3rem;
    border: 0.1rem solid;
    text-align: left;  
    border-radius: 0;
  }
  .button.wide .material-icons {
    color: #2e3a52;
    float: right;
    font-size: 1.8rem;   
  }
  .button.ghost:hover .material-icons {
    color: white;
  }
}
.agent-listing-content{
  .owl-loading {
    opacity: 1!important;
  }
}