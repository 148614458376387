@font-face {
    font-family: "TiemposTextWeb";
    src: url("fonts/tiempostextweb/TiemposTextWeb-Regular-subset.woff2") format('woff2');
    font-weight: normal;
}

@font-face {
    font-family: "TiemposTextWeb";
    src: url("fonts/tiempostextweb/TiemposTextWeb-Bold-subset.woff2") format('woff2');
    font-weight: bold;
}

@font-face {
    font-family: "TiemposTextWeb";
    src: url("fonts/tiempostextweb/TiemposTextWeb-BoldItalic-subset.woff2") format('woff2');
    font-weight: bold;
    font-style: italic;
}

@font-face {
    font-family: "TiemposTextWeb";
    src: url("fonts/tiempostextweb/TiemposTextWeb-RegularItalic-subset.woff2") format('woff2');
    font-style: italic;
}