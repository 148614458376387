.ads-in-article {
    // display: none;
    
    div[id^='div-gpt-ad'] {
        margin: 15px auto 0 !important;
        text-align: center;
    }
}
// .ads-in-article.guide > div[id^='div-gpt-ad'] {
//     margin: 15px auto 0;
// }