$outlook-banner-height: 155px;
$outlook-banner-tag-height: 120px;
//
//------------------------------
// Search
//------------------------------
//- Default Search boxes
.search-ace {
    letter-spacing: 0.1rem;
    .search-bar {
        background-color: rgba($theme-color, 0.65);
        position: relative;
        &:after {
            content: "";
            display: block;
            background-image: url("../img/sample/search-ace.jpeg");
            background-position: center center;
            background-size: cover;
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            z-index: -1;
        }
        .ion-icon {
            vertical-align: middle;
        }
    }
    .search-advanced {
        padding-top: 2rem;
        padding-bottom: 2rem;
        display: none;
        &.active {
            display: block;
        }
        &-link {
            color: #fff;
        }
        .form-group {
            margin-bottom: 1rem;
            &:last-child {
                margin-top: 2rem;
            }
            >label {
                padding-top: 1.25rem;
                font-weight: 600;
                line-height: 1.6;
                text-align: left;
                .material-icons {
                    margin-top: -0.12em;
                    margin-right: 0.24em;
                    font-size: 1.846em;
                }
            }
            .input,
            .select,
            .inline {
                margin-bottom: 0;
            }
            .inline {
                >.checkbox,
                >.radio {
                    padding-top: 1.25rem;
                    padding-bottom: 1.25rem;
                    line-height: 1;
                }
            }
        }
    } //- Component
    .i-form {
        label {
            color: inherit;
        }
        .input,
        .select {
            background-color: transparent;
            border: 0.1rem solid #fff;
            border-radius: 0.2rem;
        }
        .input input,
        .input label,
        .select select,
        .select label,
        .input[data-toggle="nav"] .fa {
            color: #fff;
        }
        .input {
            +.dropdown {
                .select {
                    border-color: $input-border-color;
                    color: $input-color;
                    &:after {
                        border-top-color: $input-theme;
                    }
                    select {
                        color: $input-color;
                    }
                }
            }
        }
        .select {
            &:after {
                border-color: #fff transparent transparent;
            }
            option {
                color: $theme-color;
            }
        }
        .helper {
            a {
                color: $grey-color1;
            }
            .popover {
                color: $base-color;
            }
        }
        .text-center {
            >.control-label {
                text-align: center;
            }
        }
        .button.theme {
            min-width: 16rem;
            background-color: $theme-color-alt;
            border-color: $theme-color-alt;
            border-radius: 0.2rem;
        }
    } //- Responsive ---------------------------
    @media (max-width: 479px) {
        .search-advanced {
            &-link {
                margin-top: 2rem;
                margin-left: 0;
                display: block;
            }
        }
    }
    @media (max-width: 991px) {
        .search-advanced {
            .form-group {
                >label {
                    .material-icons {
                        font-size: 1.385em;
                    }
                }
            }
        }
    }
}

//- Recent Search & Popular locations
.search-link {
    >.item {
        display: inline-block;
        padding-left: 0.5rem;
        padding-right: 0.5rem;
        +.item {
            padding-left: 0;
            &:before {
                content: ".";
                display: inline-block;
                margin-right: 0.8rem;
                line-height: 1;
                vertical-align: top;
            }
        }
    } //- Style
    &.comma {
        >.item {
            padding-left: 0;
            &:after {
                content: ",";
                vertical-align: baseline;
            }
            &:before,
            &:last-child:after {
                display: none;
            }
        }
    }
}

//- For Search Outlook [Area|Project]
.search-outlook {
    .search-wrapper {
        padding-top: 12rem;
        padding-bottom: 12rem; // background-color: rgba($hero-background-color, $hero-background-opacity);
        background-image: linear-gradient( rgba(3, 48, 118, 0.45), rgba(3, 48, 118, 0.45)), url(https://media.edgeprop.my/assets/images/area-outlook-bg.png);
        background-position: center center;
        background-repeat: no-repeat;
    }
    .search-header {
        h2 {
            margin-top: 0;
            margin-bottom: 0.6em;
            font-size: 2.5rem;
            line-height: 1.2;
            color: $white;
        }
    }
    .i-form {
        .input {
            background-color: transparent;
            border-color: $white;
            label,
            input {
                color: $white;
            }
            label {
                .material-icons {
                    margin-top: -0.2em;
                }
            }
        }
        .twitter-typeahead .tt-menu {
            width: 100%;
        }
    } //- Responsive ---------------------------
    @media (max-width: 479px) {
        .search-wrapper {
            padding: 6rem 0;
        }
    }
    @media (min-width: 1600px) {
        .search-header {
            h2 {
                font-size: 3.6rem;
            }
        }
    }
}

//------------------------------
// Twitter Typeahead
//------------------------------
.twitter-typeahead {
    width: 100%;
    .tt {
        &-menu {
            @if $debug=="true" {
                display: block !important;
            }
            width: 42rem;
            background-color: $white;
            position: relative;
            box-shadow: 0 0 0 0.1rem rgba(0,
            0,
            0,
            0.04),
            0 0.3rem 1rem -0.2rem rgba(0,
            0,
            0,
            0.1);
            &:before,
            &:after {
                content: "";
                display: block;
                width: 0;
                border-width: 0.7rem;
                border-style: solid;
                border-color: transparent transparent $white;
                position: absolute;
                left: 1.5rem;
                bottom: 100%;
                z-index: 1;
            }
            &:after {
                border-width: 0.8rem;
                border-color: transparent transparent $grey-color5;
                left: 1.4rem;
                z-index: 0;
            }
        }
        &-head {
            padding: 1rem;
            background-color: $border-color;
            color: $theme-color;
            h5 {
                margin: 0;
            }
        }
        &-wrap {
            position: relative;
        }
        &-title {
            margin-right: 10rem;
        }
        &-type {
            color: mix($theme-color, $white, 30%);
            font-size: smaller;
            font-style: italic;
            text-transform: capitalize;
            position: absolute;
            top: 50%;
            right: 0;
            transform: translateY(-50%);
        } // Highlight
        &-highlight {
            color: $theme-color-alt;
        } // Hovered
        &-suggestion {
            padding: 1rem 1.5rem;
            font-size: small;
            &:hover,
            &.tt-cursor {
                background-color: $theme-color-alt;
                color: $white;
                .tt {
                    &-icon,
                    &-type {
                        color: #fff;
                    }
                    &-highlight {
                        color: lighten($theme-color-alt, 60%);
                    }
                }
            }
        }
    }
    .empty-message {
        padding: 1rem * 1.5;
        color: #9a9b9d;
        font-size: 0.9em;
        text-align: center;
        .ion-icon {
            display: block;
            margin-bottom: 0.5rem;
            font-size: 3.2em;
            color: #ccc;
        }
        p {
            font-size: 1em;
            &:last-child {
                margin-bottom: 0.5rem;
            }
        }
    }
}

// ------------------------------
// Map:Search:Listing
// ------------------------------
.map {
    // Searchbar
    &-searchbar {
        width: 100%;
        padding: 0.5rem 1.5rem;
        background-color: $grey-color6;
        box-shadow: 0 0.2rem 0.5rem -0.1rem rgba($base-color, 0.2);
        position: absolute;
        top: 0;
        left: 0;
        z-index: 2;
        .search-form {
            $smap-input-height: 3.8rem;
            .input,
            .select {
                min-height: $smap-input-height + 0.2rem;
                margin-bottom: 0;
                >label {
                    font-size: inherit;
                    line-height: $smap-input-height;
                }
            }
            .input input,
            .select select {
                min-height: $smap-input-height;
            }
            .select:after {
                top: 1.7rem;
            }
            .actions .button {
                padding-top: 0.9rem;
                padding-bottom: 0.9rem;
            }
        }
        .autoHeader {
            padding: 0.2rem 1rem;
            position: relative;
            &text {
                display: inline-block;
                padding-right: 1rem;
                background-color: $white;
                color: #3b93d0;
                font-weight: 700;
                font-size: 1.4rem;
            }
            &line {
                width: 100%;
                height: 0.1rem;
                background-color: $white;
                position: absolute;
                top: 1.5rem;
            }
        }
    } // UI
    &-search,
    &-container,
    &-wrapper,
    &-gmap {
        height: 100%;
    }
    &-search {
        &.admin-menu.adminimal-menu {
            padding-top: 10.1rem;
            &:before {
                display: none;
            }
        }
    }
    &-container {
        padding-top: 5rem;
        position: relative;
        >.block-system {
            height: 100%;
        }
    }
    &-wrapper {
        width: 100%;
        position: relative;
    }
    &-list {
        width: 38rem;
        height: 100%;
        padding-top: 6.5rem;
        background-color: $white;
        position: absolute;
        left: 0;
        top: 0;
        z-index: 1;
        transform: translate3d(-100%, 0, 0);
        transition: all 200ms ease-in-out;
        &-toggle {
            position: absolute;
            top: 50%;
            left: 100%;
            z-index: 1;
            transform: translateY(-50%);
            >.btn {
                padding: 1rem 0;
                border: 0;
                background-color: $white;
                border-radius: 0 0.5rem 0.5rem 0;
                box-shadow: 0.1rem 0.1rem 0 0 rgba($base-color, 0.2);
                &:active {
                    background-color: darken($white, 5%);
                    box-shadow: none;
                }
            }
        }
        &-header {
            padding: 1rem;
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            .list-amount {
                margin-top: 1.6rem;
                font-weight: 600;
                line-height: 1;
                text-align: center;
                >span {
                    font-weight: normal;
                    color: $grey-color3;
                }
            }
            .list-button {
                padding-left: 1rem;
                padding-right: 1rem;
            }
        }
        &-body {
            height: 100%;
            .listing-items {
                .listing-title {
                    font-size: 1.3rem;
                    text-transform: capitalize;
                }
                .listing-description {
                    font-size: 1.2rem;
                }
            }
        }
        &-boxes {
            padding: 1rem;
        } // Active
        &.active {
            transform: translate3d(0, 0, 0);
        }
    }
    &-gmap {
        .map-frame {
            width: 100%;
            height: 100%;
            position: relative;
            iframe {
                width: 100%;
                height: 100%;
                position: absolute;
                top: 0;
                left: 0;
                right: 0;
                bottom: 0;
            }
        }
        .js-info-bubble-close {
            opacity: 0.5;
            transform: translate3d(0.4rem, -0.4rem, 0) scale(0.5);
            &:hover {
                opacity: 1;
            }
        }
    }
    &-card {
        margin: 0.5rem;
        border: 0;
        box-shadow: 0 0.2rem 0.8rem -0.1rem rgba($black, 0.2), 0 0 0 0.1rem rgba($black, 0.08);
        >.content {
            padding: 1rem;
            background-color: $theme-color;
            color: $white;
            text-align: center;
            border-radius: $base-radius $base-radius 0 0;
        }
        >.image {
            background-color: transparent;
            border-top: 0.2rem solid $theme-color-alt;
            border-bottom: 0.2rem solid $theme-color-alt;
        }
        >.buttons {
            font-size: 1.2rem;
            border-top: 0;
            >.button {
                cursor: default;
                &:active {
                    box-shadow: none;
                }
            }
        }
    }
    &-wrapper .outlook-banner-wrapper {
        flex: 0 0 $outlook-banner-height;
        padding: 25px 20px 20px;
        background-color: #f4f5f9;
        border-bottom: 0.1rem solid #ddd;
        @media (max-width: 992px) {
            display: none;
        }
    }
    &-wrapper .outlook-banner {
        height: $outlook-banner-height;
        border: 1px solid #ddd;
        display: flex;
        align-items: center;
        padding-right: 20px;
        background: #fff;
        position: relative;
        &:hover {
            text-decoration: none;
        }
        &-img {
            padding-top: 5px;
            padding-left: 10px;
            padding-right: 15px;
            &,
            img {
                height: 100%;
            }
        }
        &-tag {
            background: #444444;
            border-radius: 3px 0 0 3px;
            color: #fff;
            display: inline-block;
            height: $outlook-banner-tag-height;
            padding: 0 0 0 15px;
            position: relative;
            margin: 0;
            text-decoration: none;
            transition: color 0.2s;
            display: flex;
            justify-content: center;
            &-arrow {
                position: relative;
                top: -1px;
                border: 1px solid #fff;
                border-left-color: #444444;
                flex: 0 0 40px;
                &:after {
                    background: #fff;
                    border-bottom: $outlook-banner-tag-height/2 solid transparent;
                    border-left: 40px solid #444444;
                    border-top: $outlook-banner-tag-height/2 solid transparent;
                    content: "";
                    position: absolute;
                    right: 0;
                    top: 0;
                    left: 0;
                    bottom: 0;
                }
            }
            &:hover {
                background-color: #000;
                .outlook-banner-tag-arrow {
                    &,
                    &:after {
                        border-left-color: #000;
                    }
                }
            }
            &-text-wrapper {
                flex: 1 1 100%;
                display: flex;
                flex-direction: column;
                justify-content: center;
            }
            &-title,
            &-description {
                position: relative;
                z-index: 1;
            }
            &-title {
                margin: 10px 0 5px;
                font-size: 17px;
                font-weight: 700;
            }
            &-description {
                margin: 0 0 10px;
                font-size: 14px;
            }
        }
        &-close {
            position: absolute;
            right: 0;
            top: 0;
            padding: 3px 10px;
            >i {
                display: block;
                font-size: 20px;
                color: #444444;
            }
        }
        &:hover {
            .outlook-banner-close {
                i {
                    color: #000;
                }
            }
        }
    } // - Responsive ---------------------------
    @media (max-width: 991px) {
        &-list {
            &-header {
                .list-amount {
                    margin-top: 1rem;
                    >span {
                        display: block;
                        margin-top: 0.2rem;
                        font-size: 1.3rem;
                    }
                }
            }
        }
    }
    @media (max-width: 767px) and (orientation: portrait) {
        &-searchbar {
            display: none;
        }
        &-container {
            padding-top: 0;
        }
        &-list {
            width: 100%;
            height: 58%;
            padding-top: 5rem;
            top: 42%;
            transform: translate3d(0, 0, 0);
            &-toggle {
                display: none;
                transform: none;
                position: static;
            }
            &-header {
                padding: 0.5rem;
                background-color: $theme-color-alt;
                .list-amount {
                    margin-top: 0.6rem;
                    color: $white;
                    >span {
                        color: mix($theme-color-alt, $white, 35%);
                    }
                }
                .list-filter {
                    min-height: 4rem;
                    border: 0;
                    &:after {
                        top: 1.3em;
                    }
                    select {
                        min-height: 4rem;
                        padding-left: 1rem;
                        padding-right: 1rem;
                    }
                }
                .list-button {
                    padding-top: 0.9rem;
                    padding-bottom: 0.9rem;
                    border: 0;
                    .material-icons {
                        line-height: 0.9;
                    }
                }
            }
            &-body {
                .listing-items {
                    .image {
                        display: none;
                    }
                    .listing-description {
                        .listing-rooms {
                            margin-top: 1.03em;
                        }
                    }
                    .listing-price {
                        margin-top: -1.4rem;
                    }
                }
            }
        }
        &-gmap {
            height: 42%;
        }
    }
    @media (min-width: 1200px) {
        &-list {
            width: 48rem;
            +.map-gmap {
                transition: padding 200ms ease-in-out;
            } // Active
            &.active {
                +.map-gmap {
                    padding-left: 48rem;
                }
            }
        }
    }
}

.map-search-2018 {
    // @media (max-width: 767px) {
    //     #block-block-31 {
    //         display: none;
    //     }
    // }
    $ms2018-radius: 0.2rem;
    overflow-y: hidden;
    height: 100%;
    @media (min-width: 689px) {
        padding-top: 0;
        >.header {
            display: none;
        }
    }
    .map {
        &-container,
        &-wrapper {
            height: 100%;
        }
        &-container {
            padding-top: 0;
            display: flex;
            flex-flow: column nowrap;
            &.mobfilter-active {
                z-index: 99;
                .nanobar {
                    position: fixed !important;
                    top: 0;
                }
            }
        }
        &-search {
            &bar {
                display: block;
                padding: 0;
                background-color: white;
                border-bottom: 0.1rem solid $input-border-color;
                position: relative;
                top: auto;
                left: auto;
                z-index: 3;
                box-shadow: none;
            }
            &-form {
                display: flex;
                flex-flow: row nowrap;
                align-items: stretch;
                padding-left: 2rem;
                padding-right: 2rem;
            }
        }
        &-wrapper {
            display: flex;
            flex-direction: row;
            flex-grow: 1;
            overflow: hidden;
            @media (min-width: 689px) {
                height: 100%;
            }
        } // Components
        &-list,
        &-gmap {
            // flex: 1 1 50%;
            flex: auto;
            height: auto;
            padding-left: 0;
            position: relative;
            top: auto;
            opacity: 1;
            visibility: visible;
            transform: none;
            display: flex;
            flex-direction: column;
        } // gmap for 1440px screen size
        &-gmap {
            flex: 0 0 33%;
        }
        &-list {
            display: flex;
            flex-flow: column nowrap;
            width: auto;
            min-width: 0;
            padding-top: 0;
            position: relative;
            flex: 1 1 0;
            &-header {
                padding: 1rem 2.5rem;
                background-color: #eaebf0;
                position: relative;
                top: auto;
                left: auto;
                right: auto;
                z-index: 5;
            }
            &-body {
                display: flex;
                flex: 1 1 auto;
                flex-flow: column;
                height: auto;
                background-color: #f4f5f9;
                position: relative;
                .map-list-boxes {
                    display: flex;
                    flex-flow: column nowrap;
                    padding: 0;
                    position: absolute;
                    top: 0;
                    left: 0;
                    right: 0;
                    bottom: 0;
                    .i-scrollbar {
                        height: auto;
                    }
                    .i-paginator {
                        display: flex;
                        flex-wrap: nowrap;
                        flex-shrink: 0;
                        align-items: center;
                        margin: 0;
                        padding: 1rem 2.2rem;
                        border-top: 0.1rem solid #d3d7dc;
                        background-color: #eaebf0;
                        overflow: unset;
                        border-radius: 0;
                        box-shadow: none;
                        .btn {
                            padding: 0.4rem 0;
                            line-height: 0;
                            border-radius: $ms2018-radius;
                            svg {
                                width: 2rem;
                                height: 1.6rem;
                                fill: $theme-color;
                            }
                            .fa {
                                font-size: 18px;
                                margin-left: 3px;
                                margin-right: -3px;
                            }
                            &-clean {
                                background-color: transparent;
                                box-shadow: none;
                            }
                            &-default {
                                margin-left: 1.5rem;
                                margin-right: 1.5rem;
                                padding-left: 0.6rem;
                                padding-right: 0.6rem;
                                &:focus {
                                    background-color: $white;
                                    border-color: lighten($grey-color2, 20%);
                                    box-shadow: none;
                                }
                                &:hover {
                                    background-color: $white;
                                    border-color: lighten($grey-color2, 10%);
                                    box-shadow: 0 0.1rem 0.4rem -0.05rem rgba($base-color, 0.2);
                                }
                                &:active,
                                &:active:hover {
                                    box-shadow: 0 0.3rem 0.4rem -0.2rem rgba($base-color, 0.15) inset;
                                }
                            }
                        }
                        .paginator-text {
                            font-size: $base-font-size - 0.1;
                            span {
                                margin-left: 0.2em;
                                margin-right: 0.2em;
                            }
                        }
                        &.i-paginator-m {
                            position: fixed;
                            bottom: 0;
                            width: 100%;
                            z-index: 999;
                        }
                    }
                }
                .map-list-card {
                    display: flex;
                    flex-wrap: wrap;
                    padding: 3rem 1rem;
                    .no-result {
                        flex: 1 1 100%;
                        text-align: center;
                        font-size: 24px;
                        padding: 0 20px;
                    }
                }
            } // Filters
            &-filters {
                display: flex;
                flex-flow: row nowrap;
                align-items: center;
                font-size: 1.3rem;
                .list {
                    &-item {
                        padding: 0; // SVG Icons
                        svg {
                            display: block;
                            fill: $theme-color;
                        } // Font Icons
                        .ion-icon {
                            display: block;
                            color: $theme-color;
                            font-size: inherit;
                            line-height: 0;
                        } // Button Group
                        .btn-group {
                            display: flex;
                            +.btn-group {
                                margin-left: 1rem;
                            }
                            .btn {
                                &-first {
                                    border-radius: $ms2018-radius 0 0 $ms2018-radius;
                                }
                                &-last {
                                    border-radius: 0 $ms2018-radius $ms2018-radius 0;
                                }
                            } // Open / Active
                            &.open {
                                .dropdown-toggle {
                                    background-color: #cdced4;
                                    color: $base-color;
                                    box-shadow: 0 0.2rem 0.1rem -0.05rem rgba($base-color, 0.1) inset;
                                    border-color: $grey-text;
                                }
                            }
                        } // Button
                        .btn {
                            padding-left: 1.5rem;
                            padding-right: 1.5rem;
                            font-size: $base-font-size + 0.2;
                            position: relative;
                            border-radius: $ms2018-radius;
                            &:focus {
                                background-color: $white;
                                border-color: lighten($grey-color2, 20%);
                                box-shadow: none;
                            }
                            &:hover {
                                background-color: $white;
                                border-color: lighten($grey-color2, 10%);
                                box-shadow: 0 0.1rem 0.4rem -0.05rem rgba($base-color, 0.2);
                            }
                            &:active,
                            &:active:hover {
                                box-shadow: 0 0.3rem 0.4rem -0.2rem rgba($base-color, 0.15) inset;
                            } // Combobox
                            &-combobox {
                                line-height: 0;
                                pointer-events: none;
                                label {
                                    display: block;
                                    width: 1.6rem;
                                    height: 1.6rem;
                                    margin-bottom: 0;
                                    border: 0.1rem solid darken($input-border-color, 5%);
                                    background-color: $white;
                                    cursor: pointer;
                                    pointer-events: auto;
                                    position: relative;
                                    border-radius: $input-radius;
                                    &:hover {
                                        border-color: darken($input-border-color, 15%);
                                    }
                                }
                                input[type="checkbox"] {
                                    display: none;
                                    &:checked {
                                        +label {
                                            border-color: $theme-color;
                                            background-color: $theme-color;
                                            color: $white;
                                            &:before {
                                                content: "\f3fd";
                                                display: inline-block;
                                                color: inherit;
                                                font-size: 2.2rem;
                                                font-family: "Ionicons";
                                                position: absolute;
                                                top: 0.7rem;
                                                left: 0.3rem;
                                                text-align: center;
                                            }
                                        }
                                    }
                                } // Partly Selected
                                &.half-checked {
                                    label {
                                        &:before {
                                            content: "";
                                            display: block;
                                            background-color: $theme-color;
                                            position: absolute;
                                            top: calc(50% - 1px);
                                            bottom: calc(50% - 1px);
                                            left: 0.3rem;
                                            right: 0.3rem;
                                            opacity: 1;
                                        }
                                    }
                                }
                            } // Active
                            &.active {
                                background-color: #cdced4;
                                color: $base-color;
                                box-shadow: 0 0.2rem 0.1rem -0.05rem rgba($base-color, 0.1) inset;
                                border-color: $grey-text;
                            } // Disabled
                            &.disabled,
                            &[disabled] {
                                border-color: #ccc;
                                box-shadow: none;
                                &:active {
                                    box-shadow: none;
                                }
                            }
                        } // Tooltips
                        [data-tooltip] {
                            // CSS Tooltips
                            &:before,
                            &:after {
                                display: block;
                                background-color: $base-color;
                                color: $white;
                                font-size: $base-font-size - 0.2;
                                position: absolute;
                                top: 100%;
                                left: 50%;
                                z-index: 1;
                                white-space: nowrap;
                                visibility: hidden;
                            }
                            &:before {
                                content: "";
                                width: 1rem;
                                height: 1rem;
                                margin-top: 0.9rem;
                                transform: rotate(45deg) translateX(-50%);
                            }
                            &:after {
                                content: attr(data-tooltip);
                                margin-top: 1rem;
                                padding: 0.5rem 1rem;
                                transform: translateX(-50%);
                            } // Hover
                            &:hover {
                                &:before,
                                &:after {
                                    visibility: visible;
                                }
                            }
                        }
                    }
                    &-combo {
                        display: flex;
                        justify-content: flex-end;
                        margin-right: 1rem;
                        .btn {
                            padding-left: 0.9rem;
                            padding-right: 0.9rem;
                        }
                        .dropdown-toggle {
                            padding-left: 0.6rem;
                            padding-right: 0.6rem;
                        }
                        .save-listings,
                        .save-listings-divider {
                            display: none;
                        }
                    }
                    &-custom {
                        display: flex;
                        flex-flow: row nowrap;
                        .btn {
                            // display: flex;
                            font-size: inherit;
                            line-height: 1.3;
                            svg,
                            .ion-icon {
                                float: left;
                                margin-top: -0.1rem;
                                margin-right: 0.3em;
                                font-size: 1.6rem;
                            }
                        }
                    }
                    &-results {
                        flex-grow: 1;
                        margin-left: 1rem;
                        margin-right: 1rem;
                        font-size: 1.1rem;
                        letter-spacing: 0.03em;
                        color: #a9afb6;
                    }
                    &-sort {
                        .btn-sort {
                            display: none;
                        }
                        .form-select {
                            max-width: 16rem;
                            padding: 0.3rem 1rem;
                            height: 3rem;
                            font-size: inherit;
                            border-radius: $ms2018-radius;
                            box-shadow: none;
                            transition: none;
                            appearance: none;
                            &:focus {
                                background-color: $white;
                                border-color: lighten($grey-color2, 20%);
                                box-shadow: none;
                            }
                            &:hover {
                                background-color: $white;
                                border-color: lighten($grey-color2, 10%);
                                box-shadow: 0 0.1rem 0.4rem -0.05rem rgba($base-color, 0.2);
                            }
                            &-wrapper {
                                position: relative;
                                .indicator {
                                    display: flex;
                                    align-items: center;
                                    padding: 0 0.7rem 0 1.5rem;
                                    background-image: linear-gradient( to right, rgba(white, 0) 0%, rgba(white, 1) 30%);
                                    position: absolute;
                                    top: 0.1rem;
                                    right: 0.1rem;
                                    bottom: 0.1rem;
                                    border-radius: 0 0.2rem 0.2rem 0;
                                    pointer-events: none;
                                }
                            }
                        } // Mini
                        &-mini {
                            .btn-sort {
                                display: block;
                                border-radius: $ms2018-radius !important;
                            }
                            .form-select {
                                opacity: 0;
                                cursor: pointer;
                                &-wrapper {
                                    width: 4.8rem;
                                    position: absolute;
                                    top: 0;
                                    left: 0;
                                    z-index: 1;
                                    &:hover {
                                        +.btn-sort {
                                            &:before,
                                            &:after {
                                                visibility: visible;
                                            }
                                        }
                                    }
                                    .indicator {
                                        display: none;
                                    }
                                }
                            }
                        }
                    }
                    &-views {
                        display: flex;
                        justify-content: flex-end;
                        margin-left: 1rem;
                    }
                }
                .dropdown {
                    &-menu {
                        padding-top: 1rem;
                        padding-bottom: 1rem;
                        font-size: inherit;
                        border-radius: $ms2018-radius;
                        box-shadow: 0 0.1rem 0.1rem rgba($black, 0.1);
                        >li {
                            >a {
                                padding: 0.6rem 1rem;
                            }
                        }
                    }
                } // Results
                .results {
                    &-selected {
                        .badge {
                            float: right;
                            background-color: $color-danger;
                        }
                        &.disabled {
                            .badge {
                                display: none;
                            }
                        }
                    }
                } // Minibar
                &.mini {
                    .list {
                        &-item {
                            .btn {
                                padding-left: 0.7rem;
                                padding-right: 0.7rem;
                            }
                        }
                        &-combo {
                            margin-right: 0.7rem;
                            .save-listings,
                            .save-listings-divider {
                                display: block;
                            }
                        }
                        &-results {
                            margin-left: 0;
                            margin-right: 0.7rem;
                        }
                        &-views {
                            margin-left: 0.7rem;
                            .btn-group+.btn-group {
                                margin-left: 0.7rem;
                            }
                        }
                    }
                }
            }
        }
        &-gmap {
            border-left: 0.1rem solid $input-border-color;
            transition: none;
            .map-frame-wrapper {
                width: 100%;
                height: 100%;
                position: relative;
                flex: 1 1 100%;
                @media screen and (max-width: 689px) {
                    position: absolute;
                    top: 0px;
                }
            }
            .map-frame {
                width: 100%;
                height: 100%;
                iframe {
                    width: 100%;
                    height: 100%;
                    position: absolute;
                    top: 0;
                    left: 0;
                    right: 0;
                    bottom: 0;
                }
            }
            .js-info-bubble-close {
                opacity: 0.5;
                transform: translate3d(0.4rem, -0.4rem, 0) scale(0.5);
                &:hover {
                    opacity: 1;
                }
            }
            .search-by-container-desktop {
                position: absolute;
                top: 20px;
                background-color: #f3f6f9;
                padding: 0 8px;
                border-radius: 4px;
                border: #565656;
                box-shadow: 0 1px 4px -1px rgba(0, 0, 0, 0.3);
                display: block;
                margin-left: 10px;
                .search-by {
                    margin: 10px 0;
                }
                .button {
                    cursor: pointer;
                    background-color: #ffe204;
                    color: white;
                    width: 50px;
                    border-radius: 5px;
                    border: 1px solid transparent;
                    font-size: 14px;
                    font-weight: 300;
                    padding: 0px;
                    >img {
                        height: 48px;
                    }
                }
            }
        }
        &-detail {
            display: flex;
            flex-flow: column nowrap;
            flex-basis: 70%;
            background-color: $white;
            border-left: 0.1rem solid #dededf;
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            transition: all 300ms cubic-bezier(0.23, 0.18, 0.32, 1.02);
            transform: translate3d(100%, 0, 0);
            &.on {
                transform: translate3d(0, 0, 0); // position: relative;
                position: initial;
            }
            &-navi {
                display: flex;
                background-color: #f4f5f9;
                border-bottom: 1px solid #ddd;
            }
            &-frame {
                flex: 1 1 auto;
                position: relative;
                -webkit-overflow-scrolling: touch;
                overflow-y: scroll;
                iframe {
                    width: 100%;
                    height: 100%;
                    border: 0;
                    position: absolute;
                    top: 0;
                    left: 0;
                    right: 0;
                    bottom: 0;
                }
                .loading-overlay {
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 100%;
                    height: 100%;
                    background-color: rgba(0, 0, 0, 0.85);
                    img {
                        position: absolute;
                        top: 50%;
                        left: 50%;
                        transform: translate(-50%, -50%);
                    }
                }
            }
        }
        &-card {
            margin: 0.5rem;
            border: 0;
            box-shadow: 0 0.2rem 0.8rem -0.1rem rgba($black, 0.2), 0 0 0 0.1rem rgba($black, 0.08);
            >.content {
                padding: 1rem;
                background-color: $theme-color;
                color: $white;
                text-align: center;
                border-radius: $base-radius $base-radius 0 0;
            }
            >.image {
                background-color: transparent;
                border-top: 0.2rem solid $theme-color-alt;
                border-bottom: 0.2rem solid $theme-color-alt;
            }
            >.buttons {
                font-size: 1.2rem;
                border-top: 0;
                >.button {
                    cursor: default;
                    &:active {
                        box-shadow: none;
                    }
                }
            }
        }
        &-scrollbar {
            height: 100%;
            overflow-x: hidden;
            overflow-y: auto;
            -webkit-overflow-scrolling: touch; // Scrollbar
            .scroll {
                &-element_track {
                    background-color: transparent;
                }
                &-bar {
                    background-color: rgba(0, 10, 15, 0.3);
                    opacity: 1;
                    &:hover {
                        opacity: 1;
                    }
                }
            }
        }
        @media screen and (min-width: 690px) {
            &-list-filters {
                .list-item {
                    .btn {
                        &.active {
                            &.disable-map {
                                display: none;
                            }
                        }
                    }
                }
            }
        }
    } // UI:Offscreen
    .map-offscreen {
        position: fixed !important;
        top: auto !important;
        right: auto !important;
        bottom: auto !important;
        left: -1000vw !important;
        max-width: 1000vw !important;
        overflow: hidden !important;
        opacity: 0 !important;
        pointer-events: none !important;
    } // UI:Form:Checkboxes:Flex
    $input-type: ( checkboxes: checkbox,
    radios: radio);
    @each $group,
    $item in $input-type {
        .#{$group} {
            &.flex {
                display: flex;
                flex-wrap: wrap;
                justify-content: flex-start;
                >.#{$item} {
                    flex: 0 1 33.3333%;
                    margin-top: 1rem;
                    margin-bottom: 1rem;
                    padding-right: 1.5rem;
                }
            }
        }
    } // Map Search Form -- UI
    .msf {
        &-logo {
            display: none;
            @media (min-width: 689px) {
                display: flex;
                align-items: center;
                img {
                    height: 25px;
                    padding-right: 10px;
                }
            }
        }
        &-states,
        &-mobile-listing-type {
            display: flex;
            flex-flow: row nowrap;
            align-items: stretch;
            position: relative;
            @media (max-width: 689px) {
                position: absolute;
                top: -60%;
                left: auto;
                right: 35%;
            }
            .msf-menu-toggle {
                margin-left: 1.5rem;
                margin-right: 1rem;
                padding: 0.35rem 1rem;
                border: 0.1rem solid #ddd;
                border-radius: $ms2018-radius;
                @media (min-width: 690px) {
                    .fa {
                        display: none;
                    }
                }
                @media (max-width: 689px) {
                    padding: 2px 10px;
                    font-size: 12px;
                    border-color: $primary-color1;
                    font-weight: 500;
                    svg {
                        fill: #000;
                        stroke: #000;
                        display: none;
                    }
                    .fa {
                        margin-left: 6px;
                        font-size: 18px;
                    }
                }
                @media (max-width: 320px) {
                    margin: 3px 0 0 -14px;
                    font-size: 10px;
                }
            }
            .msf-droplet {
                @media (max-width: 916px) {
                    display: flex;
                }
                &.open {
                    .msf-menu-toggle {
                        background-color: #f2f2f5;
                        @media (max-width: 689px) {
                            background-color: transparent;
                            color: #fff;
                            svg {
                                fill: #fff;
                            }
                        }
                    }
                }
            }
            .invisible-select {
                display: none;
                @media (max-width: 689px) {
                    display: block;
                    position: absolute;
                    top: 0;
                    bottom: 0;
                    left: 0;
                    right: 0;
                    width: 100%;
                    opacity: 0;
                }
            }
            .dropdown-menu {
                top: 99%;
                left: 0;
            }
        }
        &-mobile-listing-type {
            @media (max-width: 689px) {
                right: 10%;
                left: auto;
            }
            @media (min-width: 689px) {
                display: none;
            }
        }
        &-omnibox {
            display: flex;
            align-items: center;
            flex-grow: 1;
            padding-right: 2.8rem;
            padding-left: 1.4rem;
            margin-right: 1.4rem;
            border-right: 0.1rem solid #ddd;
            >label {
                margin-bottom: 0;
                margin-right: 0.5rem;
                font-size: 3rem;
                line-height: 1;
                svg {
                    display: block;
                }
            }
            .form-control {
                height: 4.5rem;
                border: 0;
                color: $base-color;
                font-size: 1.3rem;
                font-weight: 300;
                box-shadow: none;
                flex-grow: 1;
                flex-basis: 100px;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
                &::placeholder {
                    color: $base-color;
                }
                &:focus {
                    &::placeholder {
                        color: $grey-text;
                    }
                }
            }
            &-tag {
                background: #eee;
                padding: 7px 15px;
                margin-right: 10px;
                border-radius: 20px;
                display: flex;
                align-items: center;
                max-width: 145px;
                overflow: hidden;
                text-overflow: ellipsis;
                display: block;
                white-space: nowrap;
                font-size: 12px;
                .remove {
                    font-size: 9px;
                    padding: 5px;
                    margin-left: 4px;
                    margin-right: -5px;
                    background: #d0d0d0;
                    color: #777;
                    border-radius: 50%;
                    line-height: 0;
                    align-items: center;
                    cursor: pointer;
                    display: none;
                }
            }
            &-tag-more {
                padding: 0 10px;
                font-weight: bold;
            }
            &-tag,
            &-tag-more {
                @media (max-width: 768px) {
                    display: none !important;
                    position: absolute;
                    left: -100000px;
                }
            }
            &-rightside {
                display: flex;
                align-items: center;
                flex-grow: 1;
                background: #fff;
            }
            &-rightside-wrapper {
                flex-grow: 1;
                align-self: center;
            }
            .expanded {
                flex-basis: 100%;
                position: relative;
                .msf-omnibox-rightside {
                    flex-wrap: wrap;
                    position: absolute;
                    top: -22px;
                    width: 100%;
                }
                .msf-omnibox-tag {
                    margin-top: 5px;
                    margin-bottom: 5px;
                    .remove {
                        display: flex;
                    }
                    &-text {
                        max-width: 90%;
                        display: block;
                        white-space: nowrap;
                        text-overflow: ellipsis;
                        overflow: hidden;
                    }
                }
            }
        }
        &-mobile {
            display: none;
            .msf-menu {
                &-toggle {
                    >span {
                        display: inline-flex;
                        flex-direction: row;
                        align-items: center;
                        padding: 1.4rem 1.5rem;
                        color: #252525;
                        font-size: 1.3rem;
                        font-weight: 400;
                        line-height: 2.1;
                        text-decoration: none;
                        user-select: none;
                        margin-left: 1.5rem;
                        margin-right: 1rem;
                        padding: .35rem 1rem;
                        border: .1rem solid #ddd;
                        border-radius: .2rem;
                        padding: 2px 10px;
                        font-size: 12px;
                        border-color: #005c98;
                        font-weight: 500;
                        margin: 0;
                    }
                }
            }
        }
        &-droplet {
            display: flex;
            align-items: center;
            position: static;
            &.open {
                .msf-menu-toggle {
                    color: $primary-color1;
                    svg {
                        fill: $primary-color1;
                    }
                }
            } // - Specify droplet
            // &-prices {}
            // &-beds {}
            // &-baths {}
            // &-locations {}
            // &-filters {}
        }
        &-droplet-states {
            @media (min-width: 689px) {
                border-left: 0.1rem solid #ddd;
                border-right: 0.1rem solid #ddd;
                .msf-menu-toggle {
                    border: none;
                    margin: 0;
                    height: 100%;
                    padding-left: 15px;
                    padding-right: 12px;
                }
            }
        }
        &-menu {
            &-toggle {
                display: inline-flex;
                flex-direction: row;
                align-items: center;
                padding: 1.4rem 1.5rem;
                color: $base-color;
                font-size: 1.3rem;
                font-weight: 400;
                line-height: 2.1;
                text-decoration: none;
                user-select: none;
                >.ion-icon {
                    font-size: 0.8em;
                    margin-left: 1.25em;
                }
            }
            &-dismiss {
                display: none;
                padding: 1rem;
                svg {
                    display: block;
                }
            }
            &-dropdown {
                max-height: calc(100vh - 13.5rem);
                margin-top: 0;
                padding: 0;
                left: auto;
                top: 5.9rem;
                right: 0.3rem;
                overflow-y: auto;
                box-shadow: 0 0.3rem 1.2rem -0.4rem rgba($black, 0.15);
                border-radius: $ms2018-radius;
            }
            &-form {
                padding: 1.5rem 2.5rem;
            } // - Specify dropdown menu
            &-price {
                .group {
                    &-flex {
                        display: flex;
                    }
                    &-separator {
                        align-self: flex-end;
                        padding-left: 1rem;
                        padding-right: 1rem;
                        padding-bottom: 1.2rem;
                    }
                }
            } // &-beds {}
            // &-baths {}
            &-locations {
                padding: 0;
            }
            &-filters,
            &-beds,
            &-baths {
                padding-top: 2.5rem;
                padding-bottom: 1rem;
                .group {
                    margin-bottom: 2rem;
                    &-label {
                        margin-top: 0;
                        margin-bottom: 0.5rem;
                        font-size: $base-font-size - 0.1;
                    }
                    &-flex {
                        display: flex;
                        align-items: center;
                    }
                    &-flex,
                    &-single {
                        margin-top: 1.5rem;
                    }
                    &-separator {
                        padding-left: 1rem;
                        padding-right: 1rem;
                    }
                }
                @each $group,
                $item in $input-type {
                    .#{$group} {
                        &.flex {
                            >.#{$item} {
                                flex-basis: 25%;
                            }
                        }
                        &.flex.flex-by-3 {
                            >.#{$item} {
                                flex-basis: 100/3*1%;
                            }
                        }
                        &.flex.free-width {
                            >.#{$item} {
                                flex-basis: auto;
                                flex-grow: 0;
                            }
                        }
                    }
                }
            }
            &-locations,
            &-filters {
                width: 100vw;
                min-width: 32rem;
                max-width: 70rem;
            }
            &-baths {
                padding-top: 0;
            }
        }
        &-accordion {
            &-header {
                margin: 0;
                padding: 1rem 1rem 1rem 2rem;
                background-color: #f6f7fa;
                font-size: 1.5rem;
                position: sticky;
                top: 0;
                z-index: 1;
            }
            &-body {
                padding: 1rem 2rem;
            }
        } // Mobile Menu
        &-mobile {
            &.active {
                position: absolute;
                .msf {
                    &-menu {
                        &-dismiss {
                            display: block;
                            padding: 1rem;
                            position: fixed;
                            top: 0;
                            right: 0;
                            z-index: 101;
                        }
                    }
                }
            }
        }
        &-wrapper {
            display: flex;
            flex-flow: row nowrap;
            align-items: stretch;
            &.active {
                flex-flow: column nowrap;
                width: 100%;
                height: 100%;
                background-color: $white;
                max-height: 100%;
                overflow-x: hidden;
                overflow-y: auto;
                position: fixed;
                top: 0;
                left: 0;
                z-index: 100;
                -webkit-overflow-scrolling: touch;
                .checkboxes {
                    >.checkbox {
                        display: block;
                        flex-basis: 100%;
                        margin-left: 0;
                        margin-top: 0.5rem;
                        margin-bottom: 0.5rem;
                        padding-right: 0;
                        >label {
                            display: block;
                        }
                    }
                }
                .radios {
                    >.radio {
                        display: block;
                        flex-basis: 100%;
                        margin-left: 0;
                        margin-top: 0.5rem;
                        margin-bottom: 0.5rem;
                        >label {
                            display: block;
                        }
                    }
                }
                .msf {
                    &-droplet {
                        display: block;
                    }
                    &-menu {
                        &-toggle {
                            display: flex;
                            background-color: $grey-color6;
                        }
                        &-dropdown {
                            float: none;
                            min-width: auto;
                            max-height: none;
                            border: 0;
                            position: relative;
                            top: auto;
                            left: auto;
                            right: auto;
                            bottom: auto;
                            box-shadow: none;
                            @media (min-width: 916px) {
                                display: block;
                            }
                        }
                        &-form {
                            max-width: none;
                            padding-left: 1.5rem;
                            padding-right: 1.5rem;
                        } // - Specify dropdown menu
                        &-locations {
                            padding-left: 0;
                            padding-right: 0;
                        }
                    }
                }
            }
        }
        &-suggest {
            position: absolute;
            top: 105%;
            left: 5px;
            background: #fff;
            margin-top: 5px;
            padding: 0;
            box-shadow: 0 3px 8px #888888;
            width: 100%;
            &-default {
                padding: 15px 15px 20px;
                .icon i {
                    font-size: 24px;
                }
                .desc {
                    font-size: 12px;
                    margin-bottom: 15px;
                }
                .example {
                    &-title {
                        color: #999;
                        font-size: 12px;
                    }
                    &-tags {
                        display: flex;
                        flex-wrap: wrap;
                    }
                    &-tag {
                        margin: 3px 6px 3px 0;
                        padding: 1px 5px;
                        font-size: 12px;
                        span {
                            background: #eee;
                        }
                    }
                }
            }
            &-results {
                max-height: 400px;
                overflow-y: auto;
                &-row {
                    padding: 13px;
                    &.rlabel {
                        font-weight: bold;
                        padding-left: 20px;
                        background: #eee;
                        border-bottom: 1px solid rgba(48, 51, 57, 0.1);
                        font-size: 12px;
                        >span.tips {
                            float: right;
                            font-weight: normal;
                        }
                    }
                    &.rname {
                        padding-left: 65px;
                        font-size: 14px;
                        &:hover {
                            background-color: #d3d7dc;
                        }
                    }
                }
            }
            &-selected-filters {
                padding: 15px 25px 20px;
                max-height: 80vh;
                overflow: auto;
                &-tags-wrapper {
                    display: flex;
                    flex-wrap: wrap;
                }
                .msf-omnibox-tag {
                    max-width: 100%;
                    display: flex;
                }
            }
        }
    } // Map Detail Navi -- UI
    .mdn {
        &-btn {
            display: flex;
            align-items: center;
            padding: 0.5rem 2rem;
            border: 0;
            border-right: 1px solid #ddd;
            background-color: transparent;
            font-size: 1.2rem;
            line-height: 2em;
            touch-action: manipulation;
            user-select: none;
            &:active {
                background-color: darken(#f4f5f9, 3%);
            }
            svg {
                display: block;
                margin-left: 0.3rem;
                margin-right: 0.3rem;
                fill: $theme-color;
            }
            &[disabled] {
                opacity: 0.3;
            }
        }
        &-current-listing {
            &:active {
                background-color: transparent;
            }
            input {
                display: none;
            }
            label {
                padding-left: 2.4rem;
                margin-bottom: 0;
                font-weight: normal;
                line-height: 1.4;
                position: relative;
                cursor: pointer;
                &:before {
                    content: "";
                    display: block;
                    width: 1.6rem;
                    height: 1.6rem;
                    margin-bottom: 0;
                    border: 0.1rem solid darken($input-border-color, 5%);
                    background-color: $white;
                    position: absolute;
                    left: 0;
                    top: 0;
                    border-radius: $input-radius;
                }
            }
            input:checked+label {
                &:before {
                    background-color: $theme-color;
                    border-color: darken($theme-color, 5%);
                }
                &:after {
                    content: "\f122";
                    font-size: 1rem;
                    font-family: Ionicons;
                    color: $white;
                    position: absolute;
                    top: 0.1em;
                    left: 0.4em;
                }
            }
        }
        &-prev-listing {
            margin-left: auto;
            border-left: 1px solid #ddd;
        } // Narrow Screen UI
        &-narrow {
            .mdn {
                &-btn {}
            }
        }
    } // ----- Responsive -----
    @media (max-width: 1023px) {
        .map {
            &-search {
                &-form {
                    padding-left: 1.5rem;
                    padding-right: 1.5rem;
                }
            } // Components
            &-list {
                &-header {
                    padding-left: 1.5rem;
                    padding-right: 1.5rem;
                }
                &-body {
                    .map-list-boxes {
                        .i-paginator {
                            justify-content: center;
                            padding: 0.8rem 1.5rem;
                        }
                    }
                    .map-list-card {
                        padding: 2rem;
                        .cp-card {
                            padding: 0;
                        }
                    }
                } // Filters
                &-filters {
                    .list {
                        &-combo {
                            .btn-group {
                                .btn {
                                    border-radius: $ms2018-radius;
                                }
                            }
                            .btn {
                                &-combobox {
                                    display: none;
                                }
                            }
                        }
                        &-custom {
                            display: none;
                        }
                    }
                }
            }
        }
    }
    @media (max-width: 929px) {
        // Map Detail Navi -- UI
        .mdn {
            &-btn {
                padding-left: 0.5rem;
                padding-right: 0.5rem;
                svg {
                    width: 2rem;
                    height: 2rem;
                    margin: 0;
                }
                .fa {
                    font-size: 18px;
                    margin-left: 3px;
                    margin-right: -3px;
                }
                span {
                    display: none;
                }
            }
            &-current-listing {
                padding-left: 1rem;
                padding-right: 1rem;
            }
        }
    }
    @media (max-width: 916px) {
        // Map Search Form -- UI
        .msf {
            &-omnibox {
                padding-right: 0;
                border-right: 0;
                border-left: 0;
            }
            &-mobile {
                display: flex;
                align-items: center;
            }
            &-droplet {
                display: none;
            }
        }
    }
    @media (max-width: 689px) {
        overflow-y: auto;
        height: auto;
        .map {
            &-search {
                &-form {
                    padding-right: 0;
                }
            }
        }
        .msf {
            &-omnibox {
                padding-left: 0;
            }
        }
        .map {
            &-container,
            &-wrapper {
                height: auto;
            }
            &-wrapper {
                &:not(.map-gmap-off) {
                    .map-list {
                        // display: none;
                        display: block;
                        &.on {
                            display: none;
                        }
                    }
                    .map-gmap {
                        // original code
                        // position: relative;
                        left: 0;
                        overflow: visible;
                        opacity: 1;
                        pointer-events: initial;
                        height: calc(100vh - 10.6rem); // original code
                        // newcode
                        position: absolute;
                        width: 100%;
                        z-index: 2;
                        top: 50px;
                        height: 430px; // newcode
                        @media screen and (min-width: 769px) {
                            &.xs-show {
                                display: none;
                                background: red;
                            }
                        }
                        @media screen and (max-width: 768px) {
                            &.xs-show {
                                display: block;
                            }
                        }
                    }
                }
                &.map-gmap-off {
                    .map-list {
                        display: block;
                        @media screen and (max-width: 689px) {
                            margin-top: 50px;
                        }
                    }
                    .map-gmap {
                        position: fixed;
                        left: -1000vh;
                        overflow: hidden;
                        opacity: 0;
                        pointer-events: none;
                    }
                }
            }
            &-search {
                &bar {
                    position: sticky;
                    top: 4rem;
                    z-index: 100;
                }
            } // Components
            &-list {
                &-body {
                    .map-list-boxes {
                        position: static;
                    }
                }
            }
            &-detail {
                flex-basis: 100%;
                height: calc(100vh - 106px);
                border-left: 0;
                position: fixed;
                top: 106px;
                z-index: 1;
            }
        }
    }
    @media (min-width: 1186px) and (max-width: 1390px) {
        // Map Detail Navi -- UI
        .mdn {
            &-btn {
                padding-left: 0.5rem;
                padding-right: 0.5rem;
                svg {
                    width: 2rem;
                    height: 2rem;
                    margin: 0;
                }
                .fa {
                    font-size: 18px;
                    margin-left: 3px;
                    margin-right: -3px;
                }
                span {
                    // display: none;
                }
            }
            &-current-listing {
                padding-left: 1rem;
                padding-right: 1rem;
            }
        }
    } // /// Map wrapper + Card per row + Map Detail /////
    @media (min-width: 768px) and (max-width: 929px) {
        // .map-gmap-off .map-list-card .cp-card { width: calc(100% / 2); }
        .map-gmap-off .map-list-card {
            padding-left: 1rem;
            padding-right: 1rem;
        }
        .map-gmap-off .map-list-card .cp-card {
            padding-left: 1rem;
            padding-right: 1rem;
        }
    }
    @media (min-width: 930px) and (max-width: 1023px) {
        .map-gmap {
            flex-basis: 70%;
            flex-grow: 0;
            flex-shrink: 0;
        }
        .map-gmap-off .map-list-card {
            padding-left: 1rem;
            padding-right: 1rem;
        } // .map-gmap-off .map-list-card .cp-card { width: calc(100% / 3); padding-left: 1rem; padding-right: 1rem; }
        .map-detail {
            width: 70%;
        }
    }
    @media (min-width: 1024px) and (max-width: 1390px) {
        // .map-list-body .map-list-card .cp-card { width: calc(100% / 3); }
        .map-gmap {
            flex-basis: 33%;
            flex-grow: 0;
            flex-shrink: 0;
        }
        .map-detail {
            width: 70%;
        }
    }
    @media (min-width: 1391px) {
        // .map-list-body .map-list-card .cp-card { width: calc(100% / 2); }
        // .map-gmap-off .map-list-card .cp-card { width: calc(100% / 4); }
    }
    @media (min-width: 1858px) {
        // .map-list-body .map-list-card .cp-card { width: calc(100% / 3); }
    }
    .map-list-card {
        &.one-col .cp-card {
            width: 100%;
        }
        &.two-col .cp-card {
            width: calc(100% / 2);
        }
        &.three-col .cp-card {
            width: calc(100% / 3);
        }
        &.four-col .cp-card {
            width: calc(100% / 4);
        }
    }
}

// Map:Search: Map List Card
.map-list-card {
    display: flex;
    flex-flow: row wrap;
    justify-content: flex-start;
    .cp-card {
        position: relative;
        margin-bottom: 2rem;
        display: flex;
        flex-flow: column nowrap;
        flex-basis: auto;
        padding: 0 1.5rem;
        width: 100%;
        .card-content {
            background: white;
        }
        .card-img {
            position: relative;
            .input {
                &.checkbox {
                    position: relative;
                    z-index: 2;
                    label {
                        position: absolute;
                        top: 0;
                        right: 0;
                        background: white;
                        border: 1px solid rgba(48, 51, 57, 0.15);
                        border-radius: 2px;
                        width: 1.6rem;
                        min-height: 1.6rem;
                        margin: 1rem;
                        cursor: pointer;
                        &:after {
                            font-family: Ionicons;
                            font-size: 1rem;
                            content: "\f122";
                            position: absolute;
                            top: 50%;
                            left: 50%;
                            transform: translate(-50%, -50%);
                            opacity: 0;
                            width: 100%;
                            height: 100%;
                            text-align: center;
                            border-radius: 2px;
                            color: white;
                        }
                    }
                    input[type="checkbox"] {
                        display: none;
                        &:checked+label {
                            background: $theme-color;
                        }
                        &:checked+label:after {
                            opacity: 1;
                        }
                    }
                }
            }
        }
        .agent-info {
            position: absolute;
            bottom: 0;
            background: rgba(0, 0, 0, 0.5);
            width: 100%;
            color: #fff;
            padding: 4px 10px 3px;
            font-size: 11px;
            display: flex;
            align-items: center;
            cursor: pointer;
            .fa {
                font-size: 18px;
                margin-right: 5px;
            }
        }
        .hide-agent {
            position: absolute;
            top: 0;
            left: 0;
            background: rgba(0, 0, 0, 0.5);
            color: #fff;
            font-size: 12px;
            padding: 5px 10px;
            cursor: pointer;
            display: none;
            justify-content: space-between;
            align-items: center;
            i {
                font-size: 16px;
                margin-right: 10px;
            }
        }
        &:hover {
            .hide-agent {
                display: flex;
            }
        }
        .card-info {
            padding: 1rem;
            cursor: pointer;
            ul {
                margin: 0;
                padding: 0;
                li {
                    list-style: none;
                    padding: 0;
                    display: inline-block;
                    &:after {
                        content: "|";
                        padding: 5px;
                    }
                    &:last-child {
                        &:after {
                            content: "";
                        }
                    }
                }
            }
            &>div:first-of-type {
                font-size: 15px;
                font-weight: 500;
                cursor: pointer;
            }
            &>div:nth-of-type(2) {
                font-size: 11px;
                color: #999;
            }
            &>div:nth-of-type(3) {
                margin-top: 10px;
                font-size: 14px;
                font-weight: 500;
                margin-bottom: 4px;
            }
            &>div:nth-of-type(4) {
                font-size: 11px;
            }
        }
        .card-action {
            display: flex;
            padding: 0.8rem 1rem;
            border-top: 1px solid #eee;
            cursor: pointer;
            background: $theme-color;
            .btn {
                display: flex;
                align-items: center;
                border: 0;
                padding: 0.3rem 0;
                color: #fff;
                border-radius: 0;
                box-shadow: none;
                font-size: 13px;
                background: $theme-color;
                &:focus,
                &:hover,
                &:focus:hover,
                &:active {
                    background-color: transparent;
                }
                svg {
                    margin-top: -0.2rem;
                    fill: $grey-text;
                    +span {
                        margin-left: 0.3em;
                    }
                }
            }
        } // 3:2 aspect ratio for image
        .as-r-3-2 {
            background-color: #eaebf0;
            position: relative;
            width: 100%;
            padding-top: 66.66%;
            >* {
                position: absolute;
                top: 0;
                left: 0;
                bottom: 0;
                right: 0;
                width: 100%;
            }
        }
        .flickity-carousel,
        .flickity-carousel-cell {
            height: 100%;
            cursor: pointer;
        }
        .flickity-carousel-cell {
            overflow: hidden;
            img {
                filter: brightness(120%);
            }
        }
        .flickity-enabled {
            .flickity-prev-next-button {
                background-color: rgba(0, 0, 0, 0);
                @media (min-width: 689px) {
                    display: none;
                }
                .arrow {
                    fill: #fff;
                }
                &.previous {
                    left: 0;
                }
                &.next {
                    right: 0;
                }
            }
            &:hover {
                .flickity-prev-next-button {
                    display: block;
                }
            }
            &.only-1-image {
                .flickity-prev-next-button {
                    display: none;
                }
            }
        }
        &.highlight {
            .card-content {
                transition: all 0.3s ease-in-out;
                outline: none;
                box-shadow: 0 0 10px #2e3a52;
            }
        }
        .indicator {
            .highlight {
                background: #ff5122;
                padding: 3px 9px;
                font-size: smaller;
            }
        }
    }
}

// mono search bar
.mono {
    .col-p-0 {
        padding: 0;
    }
    &.i-form {
        .input,
        .select {
            border-radius: 0;
            border-width: 0.1rem 0 0.1rem 0.1rem;
        }
        .input label {
            padding: 0 1.2rem;
        }
        .select select {
            padding: 1rem 1.2rem;
        }
        .select:after {
            right: 0.8rem;
        }
        .button {
            border-radius: 0;
        }
    }
}

.map-scrollbar {
    &.scrollbar-inner {
        &.scroll-content {
            height: 100% !important;
            -webkit-box-sizing: border-box;
            -moz-box-sizing: border-box;
            -ms-box-sizing: border-box;
            -o-box-sizing: border-box;
            box-sizing: border-box;
        }
    }
}

.all_clear {
    width: 32px;
    right: 0;
    margin-right: -15px;
}

#block-system-main {
    .map-search-2018 & {
        flex: 1 1 100%;
        height: auto;
        @media (min-width: 689px) {
            height: calc(100% - 54px);
        }
    }
    .map-wrapper {
        .map-list-body {
            &.top-list {
                top: 480px;
            }
        } // hide [data-tooltip] on mobile
        .map-list-header {
            &.header-m {
                display: none;
                .list-item [data-tooltip] {
                    hover {
                        &:after,
                        &:before {
                            display: none;
                            visibility: hidden;
                        }
                    }
                    &:after,
                    &:before {
                        display: none;
                    } // add new for map-list-header condition for z-index issue
                }
                @media screen and (max-width: 689px) {
                    display: block;
                    flex: none;
                    width: 100%;
                    height: 49px;
                    position: absolute;
                }
                &.detail-on {
                    display: none;
                }
            }
            &.hide-m {
                display: block;
                @media screen and (max-width: 689px) {
                    display: none !important;
                }
            }
        }
        &.map-gmap-off {
            .map-list-body {
                &.top-list {
                    top: 0;
                }
            }
        }
    }
}

.price-slider {
    flex: 0 0 250px;
    align-self: center;
    margin-right: 20px;
    margin-left: 20px;
    .vue-slider-component {
        .vue-slider-dot.vue-slider-always {
            &:first-child {
                z-index: 5;
            }
            &:focus,
            &:active {
                z-index: 6;
            }
            .vue-slider-tooltip-wrap {
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                width: auto;
            }
        }
        .vue-slider-tooltip {
            user-select: none;
            width: 60px;
            height: 40px;
            line-height: 280%;
            text-align: center;
            background-color: #fff;
            border: 1px solid #dfe8ee;
            border-radius: 20px;
            cursor: pointer;
            color: #000;
            padding-left: 15px;
            position: relative;
            font-size: 12px;
        }
        .vue-slider-tooltip.vue-slider-tooltip.vue-slider-tooltip {
            &:before {
                content: "RM ";
                display: block;
                position: absolute;
                left: auto;
                top: 0px;
                border: none;
                background: none;
                font-size: 8px;
                right: 88%;
            }
        }
        .vue-slider-piecewise {
            background-color: #fff;
            border: 1px solid #dfe8ee;
        }
        &.vue-slider-horizontal .vue-slider-process {
            background-color: #dfe8ee;
        }
    }
}

.caricon {
    background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAB8AAAAKCAYAAABMtYURAAAAAXNSR0IArs4c6QAAAg9JREFUOBGlk89rE1EQx/s2m91E9BDiD9qLB1EQFC8ePHoRWs+ittA9JfGkf4C0ZxEtKIg2G9I26EGilxQqPYi0tReFHgseFIVCpSBpEELZ3WTXzyx9sCQSiA4MM/Od+c7Mvn1PjQyQarV6qdvtXo+i6KxSyseepnzbMIwmvo3mwT2w5VKptD6g1V9Tqhctl8tpGt6m8T1yv1Kp1HPLsj44jtMmt8Dgt8Vi8Z3m1et1q9VqTVF/DewNSzTgRzo/yKparZb3PO8ChBM0uIw6EEYZMs2QV0my67oPiY8w4G4SF58ljrLER/gpuLNwG701vbGqVCq3wjB83ZdQ6juNfrCUHLdPPkDPocfBZKlAcAbp/AjxI3At+9T9JthDd9CvxJ/RtUKh0JQixdcUIbkSaKEAKOoSmxr7X0tPWdKiT4i/YZrmTRNgPNmYxDP0E9hJrMepzOHbyZphfHrIPdng4h7Dtuj3hJlXiSdMklcI4n748s8O0JcCYH+i98FlgaEF3hYDmwxaEjK9dsFmsC/QxwbYe0kcygpJuXBaRnH2dTCspdcqg29oHgPHtI/dNNLp9B2KJgnm2HIT+00XgEc8Nbkw/yQMy0H80kOWr28wy+1759z+84fHMkbBU/7RIuSLvPW9TqdzYNt2/I/woyAIYj+TycR+NpuNY9/3I9FcLue32+1T1M3T4wy6wDN9oJf5A4mvDe+PsMLPAAAAAElFTkSuQmCC);
    background-position: 0 0;
    background-repeat: no-repeat;
    display: inline-block;
    height: 16px;
    margin: 5px 6px 0 5px;
    padding: 0;
    vertical-align: middle;
    width: 35px;
}

// vue transition
.outlook-banner-slide-fade-enter-active,
.outlook-banner-slide-fade-leave-active {
    transition: all 0.5s ease;
}

.outlook-banner-slide-fade-enter,
.outlook-banner-slide-fade-leave-to {
    height: 0 !important;
    flex-basis: 0 !important;
    padding-top: 0 !important;
    padding-bottom: 0 !important;
    opacity: 0;
}

.enquiry-modal {
    text-align: center;
    padding: 0 !important;
    &:before {
        content: "";
        display: inline-block;
        height: 100%;
        vertical-align: middle;
        margin-right: -4px;
    }
    .modal-dialog {
        display: inline-block;
        text-align: left;
        vertical-align: middle;
        max-width: 500px;
    }
    .modal-content {
        background-color: #005c98;
        color: #fff;
    }
    .modal-body {
        .title {
            font-weight: 800;
            font-size: 19px;
            margin-bottom: 25px;
            letter-spacing: 0;
            text-transform: uppercase;
        }
    }
    .left-col {
        padding-right: 20px;
        .agent-pic {
            margin-bottom: 15px;
            img {
                width: 100px;
            }
        }
        .agent-name,
        .agent-id,
        .agent-company,
        .agent-phone {
            font-size: 15px;
        }
        .agent-name {
            font-weight: 800;
            font-size: 15px;
            margin-bottom: 5px;
        }
        .agent-company {
            text-transform: uppercase;
        }
        .agent-phone {
            margin-top: 20px;
        }
    }
    .right-col {
        .form-group {
            margin-bottom: 18px;
            .form-control {
                border: none;
                border-radius: 0;
                padding: 4px 4px;
                font-size: 11px;
                height: auto;
                line-height: 100%;
            }
            textarea.form-control {
                padding: 8px 4px;
                line-height: 130%;
            }
        }
        .submit {
            color: #fff;
            background-color: #222222;
            border: none;
            outline: 0;
            font-size: 15px;
            padding: 10px 20px;
            margin-left: 2px;
            border-radius: 2px;
        }
        .has-error .control-label {
            color: yellow;
            font-size: 11px;
        }
    }
    .close {
        position: absolute;
        right: 6px;
        top: 5px;
        color: #fff;
        filter: none;
        opacity: 1;
        background: #727272;
        border-radius: 50%;
        line-height: normal;
        font-weight: normal;
        float: none;
        font-size: 12px;
        padding: 2px 6px;
        text-shadow: none;
    }
    .response-msg {
        margin: 100px 0;
    }
}
