body.page-proppoints {
        
    div.lp-pom-root .lp-pom-form-field.legacy {
        position: absolute;
        left: 0;
        top: 0;
        margin-bottom: 12px
    }
    div.lp-pom-root .lp-pom-form-field.legacy label {
        position: absolute;
        left: 0;
        top: 0
    }
    div.lp-pom-root .lp-pom-form-field.legacy select {
        position: absolute;
        left: 0;
        top: 0;
        padding: 0;
        margin: 0;
        border: 1px solid #666
    }
    div.lp-pom-root .lp-pom-form-field.legacy select option {
        padding: 0.2em
    }
    div.lp-pom-root .lp-pom-form-field.legacy textarea,
    div.lp-pom-root .lp-pom-form-field.legacy input[type=text] {
        position: absolute;
        left: 0;
        top: 0;
        padding: 0;
        margin: 0;
        border: 1px solid #666
    }
    div.lp-pom-root .lp-pom-form-field.legacy input[type=text]::-moz-focus-inner {
        padding: 0;
        border: none
    }
    div.lp-pom-root .lp-pom-form-field.legacy select::-moz-focus-inner {
        padding: 0;
        border: none
    }
    div.lp-pom-root .lp-pom-form-field.legacy .optionsList {
        position: absolute;
        left: 0;
        top: 0;
        right: 0;
        padding: 0;
        margin: 0
    }
    div.lp-pom-root .lp-pom-form-field.legacy .option {
        position: relative;
        margin-bottom: 6px
    }
    div.lp-pom-root .lp-pom-form-field.legacy .option input {
        position: absolute
    }
    div.lp-pom-root .lp-pom-form-field.legacy .option label {
        position: relative;
        display: block
    }
    div.lp-pom-root .lp-pom-form input.submit {
        position: absolute;
        left: 0;
        top: 0
    }
    .lp-pom-form.has-axis * {
        box-sizing: border-box
    }
    .lp-pom-form.has-axis form {
        position: static !important
    }
    .lp-pom-form.has-axis .fields {
        display: flex;
        justify-content: flex-start
    }
    .lp-pom-form.has-axis input[type=text],
    .lp-pom-form.has-axis select,
    .lp-pom-form.has-axis textarea {
        width: 100%
    }
    .lp-pom-form.has-axis select {
        padding: 0.2em
    }
    .lp-pom-form.has-axis input[type=text]::-moz-focus-inner {
        padding: 0;
        border: none
    }
    .lp-pom-form.has-axis select::-moz-focus-inner {
        padding: 0;
        border: none
    }
    .lp-pom-form.has-axis .option {
        align-items: center;
        margin-bottom: 6px;
        padding-left: 20px;
        padding-right: 8px
    }
    .lp-pom-form.has-axis .option input {
        margin-right: 8px;
        margin-left: -20px
    }
    html {
        color: #000;
        background: transparent
    }
    body,
    div,
    dl,
    dt,
    dd,
    ul,
    ol,
    li,
    h1,
    h2,
    h3,
    h4,
    h5,
    h6,
    pre,
    code,
    form,
    fieldset,
    legend,
    input,
    button,
    textarea,
    p,
    blockquote,
    th,
    td {
        margin: 0;
        padding: 0
    }
    button::-moz-focus-inner {
        padding: 0;
        border: none
    }
    table {
        border-collapse: collapse;
        border-spacing: 0
    }
    fieldset,
    img {
        border: 0
    }
    address,
    caption,
    cite,
    code,
    dfn,
    em,
    strong,
    th,
    var,
    optgroup {
        font-style: inherit;
        font-weight: inherit
    }
    del,
    ins {
        text-decoration: none
    }
    li {
        list-style: none
    }
    caption,
    th {
        text-align: left
    }
    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
        font-size: 100%;
        font-weight: normal
    }
    q:before,
    q:after {
        content: ''
    }
    abbr,
    acronym {
        border: 0;
        font-variant: normal
    }
    sup {
        vertical-align: baseline
    }
    sub {
        vertical-align: baseline
    }
    legend {
        color: #000
    }
    input,
    button,
    textarea,
    select,
    optgroup,
    option {
        font-family: inherit;
        font-size: inherit;
        font-style: inherit;
        font-weight: inherit
    }
    :focus {
        outline: 0
    }
    blockquote,
    q {
        quotes: none
    }
    .clearfix:after {
        content: "";
        display: table;
        clear: both
    }
    html {
        opacity: 0
    }
    html {
        opacity: 1;
        transition: transform .4s ease-in-out, opacity .4s ease-in-out
    }
    html,
    body,
    .lp-pom-body {
        height: 100%
    }
    body.lp-pom-body.lp-sub-page {
        -webkit-transform: translateZ(0)
    }
    body.lp-pom-body:not(.lp-convertable-page) {
        background-color: #fff
    }
    body.lp-pom-body.lp-convertable-page {
        overflow: hidden
    }
    @media only screen and (max-width: 480px),
    only screen and (max-device-width: 480px) {
        * {
            -webkit-text-size-adjust: none
        }
    }
    div.lp-pom-root {
        min-height: 100%;
        position: relative;
        top: 0
    }
    div.lp-pom-root,
    .cke-text {
        font-size: 14px;
        line-height: 17px
    }
     
     
    .lp-positioned-content {
        position: absolute;
        left: 50%;
        top: 0;
        height: 0;
        z-index: 1
    }
    .lp-modal-screen {
        position: absolute;
        left: 0;
        top: 0;
        right: 0;
        bottom: 0;
        background: rgba(0, 0, 0, 0.5);
        z-index: 2
    }
    .lp-modal {
        overflow-y: auto
    }
    .lp-modal-content {
        position: absolute;
        left: 50%;
        height: 0;
        z-index: 1
    }
    .lp-pom-block-content {
        position: relative;
        height: 100%
    }
    div.lp-pom-root .lp-pom-button {
        text-decoration: none;
        display: block;
        cursor: pointer
    }
    div.lp-pom-root .lp-pom-button.disabled {
        opacity: 0.6;
        pointer-events: none
    }
    div.lp-pom-root .lp-pom-button span {
        position: absolute;
        top: 50%;
        display: block;
        text-align: center;
        left: 0;
        right: 0;
        padding: 0 8px 0 8px;
        cursor: pointer
    }
    div.lp-pom-root .lp-pom-button {
        *background-image: url(/images/transparent.gif);
        _background-image: url(/images/transparent.gif);
        background-image: url(/images/transparent.gif);
        background: transparent
    }
    .lp-pom-button .label strong {
        font-weight: bolder
    }
    .lp-pom-button .label em {
        font-style: italic
    }
    .lp-pom-text span[style~="color:"] a {
        color: inherit
    }
    div.lp-pom-root .lp-pom-text span {
    }
    div.lp-pom-root .lp-pom-text.inline span {
        line-height: 1
    }
    div.lp-pom-root .lp-pom-text p,
    .cke-text p {
        font-size: 14px;
        line-height: 17px
    }
    div.lp-pom-root .lp-pom-text.nlh p,
    .cke-text.nlh p {
        font-size: 14px;
        line-height: 22px
    }
    div.lp-pom-root .lp-pom-text a,
    .cke-text a {
        text-decoration: underline
    }
    div.lp-pom-root .lp-pom-text h1,
    .cke-text h1 {
        font-size: 28px;
        margin-bottom: 19px;
        line-height: 34px
    }
    div.lp-pom-root .lp-pom-text h2,
    .cke-text h2 {
        font-size: 21px;
        margin-bottom: 16px;
        line-height: 25px
    }
    div.lp-pom-root .lp-pom-text h3,
    .cke-text h3 {
        font-size: 16px;
        margin-bottom: 15px;
        line-height: 19px
    }
    div.lp-pom-root .lp-pom-text h4,
    .cke-text h4 {
        font-size: 14px;
        margin-bottom: 14px;
        line-height: 17px
    }
    div.lp-pom-root .lp-pom-text h5,
    .cke-text h5 {
        font-size: 12px;
        margin-bottom: 13px;
        line-height: 14px
    }
    div.lp-pom-root .lp-pom-text h6,
    .cke-text h6 {
        font-size: 10px;
        margin-bottom: 12px;
        line-height: 12px
    }
    div.lp-pom-root .lp-pom-text .lplh-8,
    div.lp-pom-root .lp-pom-text.nlh .lplh-8,
    .cke-text .lplh-8,
    .cke-text.nlh .lplh-8 {
        line-height: 8px
    }
    div.lp-pom-root .lp-pom-text .lplh-9,
    div.lp-pom-root .lp-pom-text.nlh .lplh-9,
    .cke-text .lplh-9,
    .cke-text.nlh .lplh-9 {
        line-height: 9px
    }
    div.lp-pom-root .lp-pom-text .lplh-10,
    div.lp-pom-root .lp-pom-text.nlh .lplh-10,
    .cke-text .lplh-10,
    .cke-text.nlh .lplh-10 {
        line-height: 10px
    }
    div.lp-pom-root .lp-pom-text .lplh-11,
    div.lp-pom-root .lp-pom-text.nlh .lplh-11,
    .cke-text .lplh-11,
    .cke-text.nlh .lplh-11 {
        line-height: 11px
    }
    div.lp-pom-root .lp-pom-text .lplh-12,
    div.lp-pom-root .lp-pom-text.nlh .lplh-12,
    .cke-text .lplh-12,
    .cke-text.nlh .lplh-12 {
        line-height: 12px
    }
    div.lp-pom-root .lp-pom-text .lplh-13,
    div.lp-pom-root .lp-pom-text.nlh .lplh-13,
    .cke-text .lplh-13,
    .cke-text.nlh .lplh-13 {
        line-height: 13px
    }
    div.lp-pom-root .lp-pom-text .lplh-14,
    div.lp-pom-root .lp-pom-text.nlh .lplh-14,
    .cke-text .lplh-14,
    .cke-text.nlh .lplh-14 {
        line-height: 14px
    }
    div.lp-pom-root .lp-pom-text .lplh-15,
    div.lp-pom-root .lp-pom-text.nlh .lplh-15,
    .cke-text .lplh-15,
    .cke-text.nlh .lplh-15 {
        line-height: 15px
    }
    div.lp-pom-root .lp-pom-text .lplh-16,
    div.lp-pom-root .lp-pom-text.nlh .lplh-16,
    .cke-text .lplh-16,
    .cke-text.nlh .lplh-16 {
        line-height: 16px
    }
    div.lp-pom-root .lp-pom-text .lplh-17,
    div.lp-pom-root .lp-pom-text.nlh .lplh-17,
    .cke-text .lplh-17,
    .cke-text.nlh .lplh-17 {
        line-height: 17px
    }
    div.lp-pom-root .lp-pom-text .lplh-18,
    div.lp-pom-root .lp-pom-text.nlh .lplh-18,
    .cke-text .lplh-18,
    .cke-text.nlh .lplh-18 {
        line-height: 18px
    }
    div.lp-pom-root .lp-pom-text .lplh-19,
    div.lp-pom-root .lp-pom-text.nlh .lplh-19,
    .cke-text .lplh-19,
    .cke-text.nlh .lplh-19 {
        line-height: 19px
    }
    div.lp-pom-root .lp-pom-text .lplh-20,
    div.lp-pom-root .lp-pom-text.nlh .lplh-20,
    .cke-text .lplh-20,
    .cke-text.nlh .lplh-20 {
        line-height: 20px
    }
    div.lp-pom-root .lp-pom-text .lplh-22,
    div.lp-pom-root .lp-pom-text.nlh .lplh-22,
    .cke-text .lplh-22,
    .cke-text.nlh .lplh-22 {
        line-height: 22px
    }
    div.lp-pom-root .lp-pom-text .lplh-24,
    div.lp-pom-root .lp-pom-text.nlh .lplh-24,
    .cke-text .lplh-24,
    .cke-text.nlh .lplh-24 {
        line-height: 24px
    }
    div.lp-pom-root .lp-pom-text .lplh-26,
    div.lp-pom-root .lp-pom-text.nlh .lplh-26,
    .cke-text .lplh-26,
    .cke-text.nlh .lplh-26 {
        line-height: 26px
    }
    div.lp-pom-root .lp-pom-text .lplh-29,
    div.lp-pom-root .lp-pom-text.nlh .lplh-29,
    .cke-text .lplh-29,
    .cke-text.nlh .lplh-29 {
        line-height: 29px
    }
    div.lp-pom-root .lp-pom-text .lplh-31,
    div.lp-pom-root .lp-pom-text.nlh .lplh-31,
    .cke-text .lplh-31,
    .cke-text.nlh .lplh-31 {
        line-height: 31px
    }
    div.lp-pom-root .lp-pom-text .lplh-32,
    div.lp-pom-root .lp-pom-text.nlh .lplh-32,
    .cke-text .lplh-32,
    .cke-text.nlh .lplh-32 {
        line-height: 32px
    }
    div.lp-pom-root .lp-pom-text .lplh-34,
    div.lp-pom-root .lp-pom-text.nlh .lplh-34,
    .cke-text .lplh-34,
    .cke-text.nlh .lplh-34 {
        line-height: 34px
    }
    div.lp-pom-root .lp-pom-text .lplh-35,
    div.lp-pom-root .lp-pom-text.nlh .lplh-35,
    .cke-text .lplh-35,
    .cke-text.nlh .lplh-35 {
        line-height: 35px
    }
    div.lp-pom-root .lp-pom-text .lplh-38,
    div.lp-pom-root .lp-pom-text.nlh .lplh-38,
    .cke-text .lplh-38,
    .cke-text.nlh .lplh-38 {
        line-height: 38px
    }
    div.lp-pom-root .lp-pom-text .lplh-40,
    div.lp-pom-root .lp-pom-text.nlh .lplh-40,
    .cke-text .lplh-40,
    .cke-text.nlh .lplh-40 {
        line-height: 40px
    }
    div.lp-pom-root .lp-pom-text .lplh-42,
    div.lp-pom-root .lp-pom-text.nlh .lplh-42,
    .cke-text .lplh-42,
    .cke-text.nlh .lplh-42 {
        line-height: 42px
    }
    div.lp-pom-root .lp-pom-text .lplh-43,
    div.lp-pom-root .lp-pom-text.nlh .lplh-43,
    .cke-text .lplh-43,
    .cke-text.nlh .lplh-43 {
        line-height: 43px
    }
    div.lp-pom-root .lp-pom-text .lplh-45,
    div.lp-pom-root .lp-pom-text.nlh .lplh-45,
    .cke-text .lplh-45,
    .cke-text.nlh .lplh-45 {
        line-height: 45px
    }
    div.lp-pom-root .lp-pom-text .lplh-58,
    div.lp-pom-root .lp-pom-text.nlh .lplh-58,
    .cke-text .lplh-58,
    .cke-text.nlh .lplh-58 {
        line-height: 58px
    }
    div.lp-pom-root .lp-pom-text .lplh-77,
    div.lp-pom-root .lp-pom-text.nlh .lplh-77,
    .cke-text .lplh-77,
    .cke-text.nlh .lplh-77 {
        line-height: 77px
    }
    div.lp-pom-root .lp-pom-text .lplh-86,
    div.lp-pom-root .lp-pom-text.nlh .lplh-86,
    .cke-text .lplh-86,
    .cke-text.nlh .lplh-86 {
        line-height: 86px
    }
    div.lp-pom-root .lp-pom-text .lplh-87,
    div.lp-pom-root .lp-pom-text.nlh .lplh-87,
    .cke-text .lplh-87,
    .cke-text.nlh .lplh-87 {
        line-height: 87px
    }
    div.lp-pom-root .lp-pom-text .lplh-115,
    div.lp-pom-root .lp-pom-text.nlh .lplh-115,
    .cke-text .lplh-115,
    .cke-text.nlh .lplh-115 {
        line-height: 115px
    }
    div.lp-pom-root .lp-pom-text p,
    .cke-text p,
    div.lp-pom-root .lp-pom-text blockquote,
    .cke-text blockquote,
    div.lp-pom-root .lp-pom-text ul,
    .cke-text ul,
    div.lp-pom-root .lp-pom-text dl,
    .cke-text dl {
        margin-bottom: 16px
    }
    div.lp-pom-root .lp-pom-text>p:last-child,
    div.lp-pom-root .lp-pom-text>blockquote:last-child,
    div.lp-pom-root .lp-pom-text>ul:last-child,
    div.lp-pom-root .lp-pom-text>dl:last-child,
    div.lp-pom-root .lp-pom-text>h1:last-child,
    div.lp-pom-root .lp-pom-text>h2:last-child,
    div.lp-pom-root .lp-pom-text>h3:last-child,
    div.lp-pom-root .lp-pom-text>h4:last-child,
    div.lp-pom-root .lp-pom-text>h5:last-child,
    div.lp-pom-root .lp-pom-text>h6:last-child,
    div.lp-pom-root .lp-pom-text .text-content>p:last-child,
    div.lp-pom-root .lp-pom-text .text-content>blockquote:last-child,
    div.lp-pom-root .lp-pom-text .text-content>ul:last-child,
    div.lp-pom-root .lp-pom-text .text-content>dl:last-child,
    div.lp-pom-root .lp-pom-text .text-content>h1:last-child,
    div.lp-pom-root .lp-pom-text .text-content>h2:last-child,
    div.lp-pom-root .lp-pom-text .text-content>h3:last-child,
    div.lp-pom-root .lp-pom-text .text-content>h4:last-child,
    div.lp-pom-root .lp-pom-text .text-content>h5:last-child,
    div.lp-pom-root .lp-pom-text .text-content>h6:last-child {
        margin-bottom: 0
    }
    div.lp-pom-root .lp-pom-text h1,
    .cke-text h1,
    div.lp-pom-root .lp-pom-text h2,
    .cke-text h2,
    div.lp-pom-root .lp-pom-text h3,
    .cke-text h3,
    div.lp-pom-root .lp-pom-text h4,
    .cke-text h4,
    div.lp-pom-root .lp-pom-text h5,
    .cke-text h5,
    div.lp-pom-root .lp-pom-text h6,
    .cke-text h6 {
        font-weight: bolder
    }
    div.lp-pom-root .lp-pom-text b,
    .cke-text b,
    div.lp-pom-root .lp-pom-text strong,
    .cke-text strong {
        font-weight: bolder
    }
    div.lp-pom-root .lp-pom-text optgroup,
    .cke-text optgroup {
        font-weight: normal
    }
    div.lp-pom-root .lp-pom-text abbr,
    .cke-text abbr,
    div.lp-pom-root .lp-pom-text acronym,
    .cke-text acronym {
        border-bottom: 1px dotted #000;
        cursor: help
    }
    div.lp-pom-root .lp-pom-text em,
    .cke-text em {
        font-style: italic
    }
    div.lp-pom-root .lp-pom-text del,
    .cke-text del {
        text-decoration: line-through
    }
    div.lp-pom-root .lp-pom-text blockquote,
    .cke-text blockquote,
    div.lp-pom-root .lp-pom-text ul,
    .cke-text ul,
    div.lp-pom-root .lp-pom-text ol,
    .cke-text ol,
    div.lp-pom-root .lp-pom-text dl,
    .cke-text dl {
        margin: 14px;
        font-size: 14px;
        line-height: 17px
    }
    div.lp-pom-root .lp-pom-text ul,
    .cke-text ul {
        margin-left: 16px
    }
    div.lp-pom-root .lp-pom-text ol,
    .cke-text ol {
        margin-left: 25px
    }
    div.lp-pom-root .lp-pom-text li,
    .cke-text li {
        display: list-item
    }
    div.lp-pom-root .lp-pom-text ol li,
    .cke-text ol li {
        list-style: decimal outside
    }
    div.lp-pom-root .lp-pom-text ul li,
    .cke-text ul li {
        list-style: disc outside
    }
    div.lp-pom-root .lp-pom-text dl dd,
    .cke-text dl dd {
        margin-left: 14px
    }
    div.lp-pom-root .lp-pom-text th,
    .cke-text th,
    div.lp-pom-root .lp-pom-text td,
    .cke-text td {
        border: 1px solid #000;
        padding: 7px
    }
    div.lp-pom-root .lp-pom-text th,
    .cke-text th {
        font-weight: bold;
        text-align: center
    }
    div.lp-pom-root .lp-pom-text caption,
    .cke-text caption {
        margin-bottom: 7px;
        text-align: center
    }
    div.lp-pom-root .lp-pom-text sup,
    .cke-text sup {
        vertical-align: super
    }
    div.lp-pom-root .lp-pom-text sub,
    .cke-text sub {
        vertical-align: sub
    }
    div.lp-pom-root #powered-by-unbounce {
        display: block !important;
        position: absolute !important;
        margin: 0 !important;
        padding: 0 !important;
        visibility: visible !important;
        text-indent: 0 !important;
        bottom: 0 !important;
        width: 100% !important;
        height: 48px !important;
        overflow: hidden !important;
        background: #0098db !important;
        z-index: 8675309 !important;
        text-align: center !important;
        font-size: 11px !important;
        color: #666 !important;
        font-weight: bold !important;
        transform: rotate(0deg) !important
    }
    div.lp-pom-root #powered-by-unbounce a {
        display: block !important;
        position: static !important;
        visibility: visible !important;
        z-index: 18885159161 !important;
        text-indent: 0 !important;
        height: 44px !important
    }
    div.lp-pom-root #powered-by-unbounce img {
        display: inline !important;
        position: static !important;
        visibility: visible !important;
        max-width: 440px;
        margin-top: 7px;
        width: 85% !important;
        vertical-align: bottom !important
    }
    .lp-form-errors {
        position: absolute;
        display: none;
        z-index: 999999
    }
    .lp-form-errors div.content {
        background-color: #ccc
    }
    .lp-form-errors div.content div.error {
        background-color: #DF3935;
        padding: 5px 20px;
        font-size: 12px;
        font-weight: bold;
        color: #FFFFFF
    }
    .lp-form-errors div.content div.error .error-msg {
        padding-right: 50px
    }
    .lp-form-errors div.content div.error .error-button {
        position: absolute;
        right: 20px;
        top: 5px
    }
    .lp-form-errors div.content div.error .error-button .error-toggle {
        float: right;
        margin-right: 3px;
        text-decoration: underline;
        cursor: pointer;
        color: #FFFFFF
    }
    .lp-form-errors div.content div.error .error-button .error-toggle-arrow {
        float: right;
        width: 9px;
        height: 10px;
        margin-top: 2px;
        cursor: pointer;
        background-image: url("/images/triangle-toggle.png");
        background-repeat: no-repeat;
        background-position: 0px 1px;
        background-size: cover
    }
    .lp-form-errors div.content ul {
        display: block !important;
        background-color: #FFD0D0;
        padding: 8px 15px;
        font-size: 12px;
        line-height: 16px;
        font-weight: normal;
        color: #DF3935
    }
    .lp-form-errors div.content ul.hide {
        display: none !important
    }
    .lp-form-errors div.content li {
        list-style: square;
        margin-left: 20px;
        margin-bottom: 2px
    }
    div.lp-pom-root .lp-element.lp-code {
        overflow: hidden
    }
    .lp-pom-root .lp-pom-image img {
        vertical-align: top
    }
    .lp-pom-root .lp-pom-image a img {
        cursor: pointer
    }
    .lp-pom-social-widget .horizontal {
        margin-left: 0;
        padding: 0
    }
    .lp-pom-social-widget .horizontal .facebook_container {
        margin-left: 0;
        overflow: hidden
    }
    .lp-pom-social-widget .widget {
        padding-right: 5px
    }
    .lp-pom-social-widget .fb_edge_widget_with_comment span.fb_edge_comment_widget iframe.fb_ltr {
        display: none !important
    }
    .lp-pom-social-widget iframe.fb_ltr {
        display: none !important
    }
    .fb_edge_widget_with_comment span {
        width: 120px !important
    }
    .fb_recommend .fb_edge_widget_with_comment iframe {
        width: 97px !important
    }
    body.ms-windows .fb_recommend {
        overflow: hidden;
        width: 88px
    }
    body.ms-windows .fb_like {
        overflow: hidden;
        width: 48px !important
    }
    body.ms-windows .lp-editor .fb_recommend {
        overflow: hidden;
        width: 90px
    }
    body.ms-windows .lp-editor .fb_like {
        overflow: hidden;
        width: 50px !important
    }
    @-moz-document url-prefix() {
        body.ms-windows .fb_recommend {
            width: 86px !important
        }
        body.ms-windows .lp-editor .fb_recommend {
            width: 90px !important
        }
        body.ms-windows .lp-editor .fb_like {
            width: 50px !important
        }
    }
    .fb_like .fb_edge_widget_with_comment iframe {
        width: 50px !important
    }
    .lp-pom-social-widget .vertical {
        margin-left: 0;
        overflow: hidden
    }
    .lp-pom-social-widget .horizontal.widget_container {
        margin-right: -5px
    }
    .lp-pom-social-widget .widget.horizontal.container {
        margin-right: 0;
        white-space: nowrap;
        float: left
    }
    .lp-pom-social-widget .facebook_container.horizontal.container {
        margin: 0
    }
    .lp-pom-social-widget .widget_container.horizontal.top .twitter_container {
        margin-top: 40px
    }
    .lp-pom-social-widget .widget_container.horizontal.top.widget-count-1 .twitter_container {
        margin-top: 0
    }
    div.lp-social-widget-overlay {
        position: absolute;
        z-index: 9999;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: #000000
    }
    #ubpoverlay-outer {
        height: auto !important
    }
    @media only screen and (max-width: 600px) {
        #ubpoverlay-content {
            overflow: auto !important;
            -webkit-overflow-scrolling: touch !important
        }
    }
        
}

