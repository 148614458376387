//------------------------------
// Edge Referecen Price
//------------------------------
.erp {
  //- Popup
  &-popup {
    display: none;
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 100;
    background-color: rgba($black, 0.5);
    &txt {
      display: none;
      width: 28rem;
      height: 5rem;
      line-height: 5rem;
      margin-top: -10rem;
      margin-left: -14rem;
      background-color: rgba($black, 0.95);
      color: $white;
      font-size: 2rem;
      text-align: center;
      position: fixed;
      top: 50%;
      left: 50%;
      z-index: 200;
      border-radius: 0.5rem;
    }
  }
  //- Form
  &-form {
    .input {
      input {
        &:focus {
          box-shadow: none;
        }
      }
    }
    .group {
      .input-group {
        &-addon {
          border: 0;
          background-color: transparent;
          color: $grey-color4;
          box-shadow: none;
        }
      }
    }
    .actions {
      .button {
        border-width: 0.1rem;
      }
    }
  }
  //- List
  //- table
  &-table {
    .i-table {
      th { position: relative; }
      th > .pos-right {
        white-space: nowrap;
        position: absolute;
        bottom: 0.8rem;
        right: 0.8rem;
      }
    }
  }
}