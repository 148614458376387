#bma-page {
  &.2020 {
    section {
      &.judges {
        background: $section-alt-bg;
      }
    }
  }
}

