.state_card .card-box {
  width: 23.5rem;
  height: 15rem;
  margin-right: 0.5rem;
  margin-bottom: 0.5rem;
  float: left;
  position: relative;
  background-repeat: no-repeat;
  background-position: center;
}

.state_card .card-box:first-child {
  height: 30.5rem;
  background-size: 46rem;
}

.state_card .card-box:nth-child(n+2) {
  background-size: 23.5rem;
}

.state_card .card-box:nth-child(n+8) {
  display: none;
}
 
.state_card .card-label {
  background-color: rgba(0, 0, 0, 0.3);
  color: white;
  width: 100%;
  height: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  font-size: 2rem;
}

.state_card .cover {
  width: 23.5rem;
  height: 15rem;
  margin-right: 0.5rem;
  margin-bottom: 0.5rem;
  float: left;
  position: relative;
  background-repeat: no-repeat;
  background-position: center;
}

@media (max-width: 1125px) {
  .state_card .card-box, .state_card .cover {
    width: 23rem;
  }
  .state_card .card-box:first-child {
    background-size: 46rem;
  }
  .state_card .card-box:nth-child(n+2) {
    background-size: 23rem;
  }
}

@media (max-width: 991px) {
  .state_card .card-box, .state_card .cover {
    width: 35.5rem;
  }
  .state_card .card-box:first-child {
    background-size: 46rem;
  }
  .state_card .card-box:nth-child(n+2) {
    background-size: 35.5rem;
  }
}

@media (max-width: 767px) {
  .state_card .card-box, .state_card .cover {
    width: 100%;
  }
  .state_card .card-box:first-child {
    height: 15rem;
    background-size: 100%;
  }
  .state_card .card-box:nth-child(n) {
    background-size: 100%;
  }
}



.state_card .card-box,
.state_card .covers{
  cursor:pointer;
}
.state_card .card-box:nth-child(1) {
  background-image:url('https://s3-ap-southeast-1.amazonaws.com/www.theedgeproperty.com.my/assets/images/regions/smaller/KL.jpg');
}
.state_card .card-box:nth-child(2) {
  background-image:url('https://s3-ap-southeast-1.amazonaws.com/www.theedgeproperty.com.my/assets/images/regions/smaller/Selangor.jpg');
}
.state_card .card-box:nth-child(3) {
  background-image:url('https://s3-ap-southeast-1.amazonaws.com/www.theedgeproperty.com.my/assets/images/regions/smaller/Penang.jpg');
}
.state_card .card-box:nth-child(4) {
  background-image:url('https://s3-ap-southeast-1.amazonaws.com/www.theedgeproperty.com.my/assets/images/regions/smaller/Johor.jpg');
}
.state_card .card-box:nth-child(5) {
  background-image:url('https://s3-ap-southeast-1.amazonaws.com/www.theedgeproperty.com.my/assets/images/regions/smaller/Putrajaya.jpg');
}
.state_card .card-box:nth-child(6) {
  background-image:url('https://s3-ap-southeast-1.amazonaws.com/www.theedgeproperty.com.my/assets/images/regions/smaller/Ipoh.jpg');
}

.state_card .card-box:nth-child(7) {
  background-image: url("https://s3-ap-southeast-1.amazonaws.com/www.theedgeproperty.com.my/assets/images/regions/smaller/Kedah.jpg");
}

.state_card .card-box:nth-child(8) {
  background-image:url('https://s3-ap-southeast-1.amazonaws.com/www.theedgeproperty.com.my/assets/images/regions/smaller/Pahang.jpg');
}
.state_card .card-box:nth-child(9) {
  background-image:url('https://s3-ap-southeast-1.amazonaws.com/www.theedgeproperty.com.my/assets/images/regions/smaller/NSembilan.jpg');
}
.state_card .card-box:nth-child(10) {
  background-image:url('https://s3-ap-southeast-1.amazonaws.com/www.theedgeproperty.com.my/assets/images/regions/smaller/Melaka.jpg');
}
.state_card .card-box:nth-child(11) {
  background-image:url('https://s3-ap-southeast-1.amazonaws.com/www.theedgeproperty.com.my/assets/images/regions/smaller/Terengganu.jpg');
}
.state_card .card-box:nth-child(12) {
  background-image:url('https://s3-ap-southeast-1.amazonaws.com/www.theedgeproperty.com.my/assets/images/regions/smaller/Perlis.jpg');
}
.state_card .card-box:nth-child(13) {
  background-image:url('https://s3-ap-southeast-1.amazonaws.com/www.theedgeproperty.com.my/assets/images/regions/smaller/Kelantan.jpg');
}
.state_card .card-box:nth-child(14) {
  background-image:url('https://s3-ap-southeast-1.amazonaws.com/www.theedgeproperty.com.my/assets/images/regions/smaller/Sabah.jpg');
}
.state_card .card-box:nth-child(15) {
  background-image:url('https://s3-ap-southeast-1.amazonaws.com/www.theedgeproperty.com.my/assets/images/regions/smaller/Sarawak.jpg');
}


.state_card .card-box:first-child{
      background-size: 46rem;
      background-repeat: no-repeat;
    }
.state_card .card-box:nth-child(n+2){
  background-size: 23.5rem;
  background-repeat: no-repeat;
}

.state_card .covers{
    width: 23.5rem;
    height: 15rem;
    margin-right: 0.5rem;
    margin-bottom: 0.5rem;
    float: left;
    position: relative;    
    background-size: 23.5rem;
    background-repeat: no-repeat;
    background-position: center;
    background-image: url("https://s3-ap-southeast-1.amazonaws.com/www.theedgeproperty.com.my/assets/images/regions/smaller/Kedah.jpg");
    }

.state_card .covers .card-label {
      width: 100%;
      height: 100%;
      font-size:2rem;
      display: flex;
      justify-content: center; 
      align-items: center;
      bottom: 0;
      background-color: rgba(0,0,0,.3);
    }

.state_card .covers .revert {
  /*width: 100%;
  padding: 1rem 1.5rem;
  bottom: 2rem;
  height:4rem;*/
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center; 
  align-items: center;
  bottom: 0;
  background-color: rgba(0,0,0,.3);
  font-size: 2rem;
}

@media (max-width: 1125px) {
    .state_card .covers {
      width: 23rem;
      background-size: 23rem;
      background-repeat: no-repeat;
    }
    .state_card .card-box:first-child{
      background-size: 46rem;
      background-repeat: no-repeat;
    }
    .state_card .card-box:nth-child(n+2){
      background-size: 23rem;
      background-repeat: no-repeat;
    }
  }
  @media (max-width: 991px) {
    .state_card .covers {
      width: 35.5rem;
      background-size: 35.5rem;
      background-repeat: no-repeat;
    }
    .state_card .card-box:first-child{
      background-size: 46rem;
      background-repeat: no-repeat;
    }
    .state_card .card-box:nth-child(n+2){
      background-size: 35.5rem;
      background-repeat: no-repeat;
    }
  }
  @media (max-width: 767px) {
    .state_card .covers {
      width: 100%;
      background-size: 100%;
      background-repeat: no-repeat;
    }
    .state_card .card-box:first-child{
      background-size: 100%;
      background-repeat: no-repeat;
      height:15rem;
    }
    .state_card .card-box:nth-child(n+2){
      background-size: 100%;
      background-repeat: no-repeat;
    }
  }

  .state_card .covers a{
    display:block;
  }

/*temporary*/
.state_card .card-box .card-label{
  background-color: rgba(0,0,0,.3);
  bottom: 0;
  color: #fff;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size:2rem;
}